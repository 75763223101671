import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { UserInfo } from "../apiClient/authGear";
import { AppConfig } from "../config";
import { useCustomModelImageService } from "../contexts/customModelImageService";
import errors from "../errors";
import { useGtm } from "../hooks/gtm";
import { useAppDispatch } from "../hooks/redux";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { useToast } from "../hooks/toast";
import {
  isDeletingCustomModelimage,
  isUploadingCustomModelImage,
} from "../reducers/helpers/customModel";
import { RootState } from "../redux/types";
import { ConfirmModalType } from "../types/confirmation";
import { SurveyForm } from "../types/survey";
import { User } from "../types/user";
import { PreferenceKey, getPreference } from "../utils/preference";
import { useConfirmModalActionCreator } from "./confirmModal";

export const UserLogin = createAction(
  "user/login",
  (user: User, resourceOwnerId: string | undefined, isTeam: boolean) => ({
    payload: { user, resourceOwnerId, isTeam },
  })
);

export const UserLoggingOut = createAction("user/loggingOut");

export const UserLogout = createAction("user/logout");

export const UserCreated = createAction<User>("user/created");

export const GotUserInfo = createAction<UserInfo>("user/gotUserInfo");

export const UserSurveySubmitted = createAction<SurveyForm>(
  "user/surveySubmitted"
);

export type UserAction =
  | ReturnType<typeof UserLogin>
  | ReturnType<typeof UserLoggingOut>
  | ReturnType<typeof UserLogout>
  | ReturnType<typeof UserCreated>
  | ReturnType<typeof GotUserInfo>
  | ReturnType<typeof UserSurveySubmitted>;

export function useUserActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();

  const { getParam, setParam } = useSearchParamUtils();
  const { requestUserConfirmation } = useConfirmModalActionCreator();
  const { cancelPendingOperations } = useCustomModelImageService();

  const getResourceOwnerIdAndTeamLookupIdFromUrl = useCallback(
    (user: User) => {
      const teamLookupId =
        getParam(URLParamsKey.team) ||
        getPreference(PreferenceKey.lastSelectedTeamLookupId);
      const briefTeam = user.teams.find(team => team.lookupId === teamLookupId);
      return briefTeam?.id ? [briefTeam.id, teamLookupId] : null;
    },
    [getParam]
  );

  const makeUserLoginAction = useCallback(
    (user: User): ReturnType<typeof UserLogin> => {
      const [resourceOwnerId, teamLookupId] =
        getResourceOwnerIdAndTeamLookupIdFromUrl(user) ||
          (user.resourceOwner && [user.resourceOwner.id, null]) ||
          (user.teams[0] && [user.teams[0].id, user.teams[0].lookupId]) || [
            null,
            null,
          ];

      if (teamLookupId && !window.location.pathname.startsWith("/admin")) {
        setParam(URLParamsKey.team, teamLookupId);
      }

      const isTeam =
        user.teams.find(team => team.id === resourceOwnerId) !== undefined;

      return UserLogin(user, resourceOwnerId ?? undefined, isTeam);
    },
    [setParam, getResourceOwnerIdAndTeamLookupIdFromUrl]
  );

  const { pushIdentifiedEvent, pushSurveyCompletedSurveyEvent } = useGtm();

  const createUser = useCallback(
    async (region: string) => {
      const user = await apiClient.createUser(region);
      dispatch(UserCreated(user));
      pushIdentifiedEvent();
      return user;
    },
    [dispatch, pushIdentifiedEvent]
  );

  const signUp = useCallback(
    async (username: string, email: string, password: string) => {
      await apiClient.signUp(username, email, password);
      const user = await apiClient.getUser(AppConfig.region);
      const action = makeUserLoginAction(user);
      dispatch(action);
    },
    [dispatch, makeUserLoginAction]
  );

  const login = useCallback(
    async (email: string, password: string, newPassword?: string) => {
      await apiClient.login(email, password, newPassword);
      const user = await apiClient.getUser(AppConfig.region);
      const action = makeUserLoginAction(user);
      pushIdentifiedEvent();
      dispatch(action);
    },
    [dispatch, makeUserLoginAction, pushIdentifiedEvent]
  );

  const { dismiss: dismissToast } = useToast();
  const logout = useCallback(async () => {
    const customModelState = getState().customModel;
    const hasPendingbackgroundTasks =
      isUploadingCustomModelImage(customModelState) ||
      isDeletingCustomModelimage(customModelState);
    if (hasPendingbackgroundTasks) {
      if (
        !(await requestUserConfirmation(
          {
            titleId: "user.logout.confirm.title",
            actionId: "common.confirm",
            messageId: "user.logout.confirm_cancel_custom_model_image_tasks",
            type: ConfirmModalType.Destory,
          },
          false
        ))
      ) {
        return;
      } else {
        dispatch(UserLoggingOut());
        await cancelPendingOperations();
      }
    } else {
      dispatch(UserLoggingOut());
    }

    await apiClient.logout();
    dismissToast();
    dispatch(UserLogout());
  }, [
    dispatch,
    dismissToast,
    getState,
    requestUserConfirmation,
    cancelPendingOperations,
  ]);

  const triggerSignupWithInvitation = useCallback(
    async (invitationCode: string) => {
      await apiClient.triggerSignupWithInvitation(invitationCode);
    },
    []
  );

  const triggerLoginWithInvitation = useCallback(
    async (invitationCode: string) => {
      await apiClient.triggerLoginWithInvitation(invitationCode);
    },
    []
  );

  const openUserSetting = useCallback(async () => {
    return await apiClient.openUserSetting({ openInSameTab: true });
  }, []);

  const changePassword = useCallback(
    async (oldPassword: string, newPassword: string) => {
      try {
        await apiClient.changePasword(oldPassword, newPassword);
      } catch (e) {
        if (e === errors.IncorrectEmailOrPassword) {
          throw errors.IncorrectPassword;
        } else {
          throw e;
        }
      }
    },
    []
  );

  const checkIsUserCreatedAtRegion = useCallback(
    async (region: string): Promise<boolean> => {
      try {
        await apiClient.getUser(region);
        return true;
      } catch (e) {
        return false;
      }
    },
    []
  );

  const submitSurvey = useCallback(
    async (surveyForm: SurveyForm, ip: string): Promise<void> => {
      const authGearAvailable = AppConfig.authGear?.endpoint !== undefined;

      if (authGearAvailable) {
        await apiClient.submitSurvey(surveyForm, ip ?? "");
      } else {
        console.log("Skip survey submission in local dev mode");
      }

      pushSurveyCompletedSurveyEvent(surveyForm);
      dispatch(UserSurveySubmitted(surveyForm));
    },
    [pushSurveyCompletedSurveyEvent, dispatch]
  );

  return useMemo(
    () => ({
      makeUserLoginAction,
      createUser,
      signUp,
      logout,
      login,
      triggerLoginWithInvitation,
      triggerSignupWithInvitation,
      openUserSetting,
      changePassword,
      checkIsUserCreatedAtRegion,
      submitSurvey,
    }),
    [
      makeUserLoginAction,
      createUser,
      signUp,
      logout,
      login,
      triggerLoginWithInvitation,
      triggerSignupWithInvitation,
      openUserSetting,
      changePassword,
      checkIsUserCreatedAtRegion,
      submitSurvey,
    ]
  );
}
