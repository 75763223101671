export function changeWaterMarkSetting(
  useWaterMark: boolean,
  extras?: any
): any {
  const hasOriginalValue =
    extras?.image_processing?.threshold_trunc !== undefined;

  // We don't want to change the original value because threshold_trunc might have a value other than auto.
  if (hasOriginalValue === useWaterMark) return extras;

  if (hasOriginalValue && !useWaterMark) {
    if (Object.keys(extras.image_processing).length === 1) {
      delete extras.image_processing;
    } else {
      delete extras.image_processing.threshold_trunc;
    }
  } else {
    const fieldToCombine = {
      image_processing: {
        threshold_trunc: "auto",
      },
    };

    if (extras) {
      if (extras.image_processing) {
        Object.assign(extras.image_processing, fieldToCombine.image_processing);
      } else {
        Object.assign(extras, fieldToCombine);
      }
    } else {
      extras = fieldToCombine;
    }
  }
  return extras;
}
