import {
  DetectionRegion,
  DetectionRegionFieldParams,
  DetectionRegionFieldParamsResp,
  DetectionRegionFieldResp,
  DetectionRegionResp,
} from "../detectionRegion";
import { KeywordsExtractionAnchorMapper } from "./keywordsExtraction";

export const DetectionRegionFieldParamsMapper = {
  toResp: (
    params: DetectionRegionFieldParams
  ): DetectionRegionFieldParamsResp => {
    const resp: DetectionRegionFieldParamsResp = {};
    if (params.api !== undefined) {
      resp.api = params.api;
    }
    if (params.code !== undefined) {
      resp.code = params.code;
    }
    if (params.engine !== undefined) {
      resp.engine = params.engine;
    }
    if (params.languages !== undefined) {
      resp.languages = params.languages;
    }
    if (params.keywords !== undefined) {
      resp.keywords = {
        type: params.keywords.type,
        before:
          params.keywords.before !== null
            ? KeywordsExtractionAnchorMapper.toResp(params.keywords.before)
            : null,
        after:
          params.keywords.after !== null
            ? KeywordsExtractionAnchorMapper.toResp(params.keywords.after)
            : null,
      };
    }
    if (params.llm_completion !== undefined) {
      resp.llm_completion = params.llm_completion;
    }
    return resp;
  },
};

export const DetectionRegionMapper = {
  toResp: (region: DetectionRegion): DetectionRegionResp => {
    return {
      id: region.id,
      bbox: region.bbox,
      form_id: region.formId,
      config: {
        version: region.config.version,
        fields: region.config.fields.map(field => {
          const fieldResp: DetectionRegionFieldResp = {
            label: field.label,
            type: field.type,
          };
          if (field.extras !== undefined) {
            fieldResp.extras = field.extras;
          }
          if (field.params !== undefined) {
            fieldResp.params = DetectionRegionFieldParamsMapper.toResp(
              field.params
            );
          }
          return fieldResp;
        }),
      },
    };
  },
};
