import { SurveyForm } from "../../types/survey";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface SurveyApiClient {
  submitSurvey: (surveyForm: SurveyForm, ipAddress: string) => Promise<void>;
}

export function withSurveyApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async submitSurvey(
      surveyForm: SurveyForm,
      ipAddress: string
    ): Promise<void> {
      const data = {
        name: surveyForm.name,
        company_name: surveyForm.companyName,
        phone: surveyForm.phoneNumber,
        use_cases: surveyForm.useCases,
        ip_address: ipAddress,
      };

      await this.lambda("survey:submit", data);
    }
  };
}
