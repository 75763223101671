import {
  type GoogleSheetList,
  type GoogleSpreadsheetList,
  googleSheetListSchema,
  googleSpreadsheetListSchema,
} from "../../types/googleSheet";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface GoogleSheetApiClient {
  listGoogleSheetColumns: (
    oauthCredentialId: string,
    spreadsheetId: string
  ) => Promise<GoogleSheetList>;
  listGoogleSheets: (
    oauthCredentialId: string,
    query: string | null
  ) => Promise<GoogleSpreadsheetList>;
}

export function withGoogleSheetApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async listGoogleSheetColumns(
      oauthCredentialId: string,
      spreadsheetId: string
    ): Promise<GoogleSheetList> {
      return this.lambda(
        "google_sheet:list-columns",
        {
          oauth_credential_id: oauthCredentialId,
          spreadsheet_id: spreadsheetId,
        },
        googleSheetListSchema,
        null
      );
    }

    async listGoogleSheets(
      oauthCredentialId: string,
      query: string | null = null
    ): Promise<GoogleSpreadsheetList> {
      return this.lambda(
        "google_sheet:list",
        { oauth_credential_id: oauthCredentialId, query },
        googleSpreadsheetListSchema,
        null
      );
    }
  };
}
