.remove-characters {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  .filter-group {
    margin-bottom: 16px;
  }

  .custom-value {
    :global(.ms-TextField-fieldGroup) {
      min-height: 150px;
    }
  }

  .info-icon {
    color: #25d0b1;
    margin-left: 8px;
  }
}
