import { isBuiltinFieldType } from "../types/advancedPatternMatching";
import {
  CellData,
  CellDataType,
  MenuItem,
  RowData,
  SettingCellData,
  TagAutoCompleteCellData,
  TagCellData,
  TokenCellData,
  isEmpty,
} from "../types/advancedTokenSetup/table";

type ValidationContext = {
  tableType: MenuItem;
  tagCount: Record<string, number>;
  allowEmpty: boolean;
  cellDataList: CellData[];
};

function validateTagAutoCompleteCell(
  data: TagAutoCompleteCellData,
  context: ValidationContext
): string | undefined {
  const { allowEmpty, tagCount } = context;

  if (!allowEmpty && isEmpty(data)) {
    return "advance_token_setup_table.validation.tag.should_not_empty";
  }

  if (
    [MenuItem.FieldReplacementMall, MenuItem.FieldReplacementMerchant].includes(
      context.tableType
    )
  ) {
    tagCount[data.tag] = (tagCount[data.tag] || 0) + 1;
    if (tagCount[data.tag] > 1) {
      return "advance_token_setup_table.validation.rule.should_not_duplicated";
    }
  }

  return;
}

function validateTagCell(
  data: TagCellData,
  allowEmpty: boolean = false
): string | undefined {
  if (!allowEmpty && isEmpty(data)) {
    return "advance_token_setup_table.validation.tag.should_not_empty";
  }
  return;
}

function validateTokenCell(
  data: TokenCellData,
  allowEmpty: boolean = false
): string | undefined {
  if (!allowEmpty && isEmpty(data)) {
    return "advance_token_setup_table.validation.token.should_not_empty";
  }
  return;
}

function validateSettingCell(
  _data: SettingCellData,
  context: ValidationContext
): string | undefined {
  // skip setting config validation as it is validated on advance token setup panel

  const { tableType, cellDataList } = context;

  if (
    [MenuItem.FieldReplacementMall, MenuItem.FieldReplacementMerchant].includes(
      tableType
    )
  ) {
    const hasNotEmptySetting = cellDataList.some(
      d => d.type === CellDataType.SettingCellData && d.config !== undefined
    );

    if (!hasNotEmptySetting) {
      return "advance_token_setup_table.validation.rule.must_set_setting";
    }
  }

  return;
}

function validateCell(
  cell: CellData,
  context: ValidationContext
): string | undefined {
  const { allowEmpty } = context;
  switch (cell.type) {
    case CellDataType.TagAutoCompleteCellData:
      return validateTagAutoCompleteCell(cell, context);
    case CellDataType.TagCellData:
      return validateTagCell(cell, allowEmpty);
    case CellDataType.TokenCellData:
      return validateTokenCell(cell, allowEmpty);
    case CellDataType.SettingCellData:
      return validateSettingCell(cell, context);
  }
}

export const AdvancedTokenSetupTableValidator = {
  validate(
    tableType: MenuItem,
    data: RowData[]
  ): [isPass: boolean, result: RowData[]] {
    let isPass = true;
    const tagCount = {} as Record<string, number>;

    const dataWithValidation = data.map(d => {
      const hasAtLeastOneToken =
        d.data.filter(
          d =>
            d.type === CellDataType.TokenCellData &&
            d.token.type &&
            isBuiltinFieldType(d.token.type) &&
            !isEmpty(d)
        ).length > 0;
      return {
        ...d,
        data: d.data.map((dd, i) => {
          const allowEmpty =
            ((tableType === MenuItem.ClosestMatchRuleMall ||
              tableType === MenuItem.ClosestMatchRuleMerchant) &&
              i > 1) ||
            (tableType === MenuItem.ExactMatchRule &&
              dd.type === CellDataType.TokenCellData &&
              (dd.token.type === "negative_token" ||
                dd.token.type === "positive_token" ||
                hasAtLeastOneToken));

          const error = validateCell(dd, {
            tableType,
            tagCount,
            allowEmpty,
            cellDataList: d.data,
          });
          isPass = isPass && error === undefined;
          return {
            ...dd,
            error,
          };
        }),
      };
    });
    return [isPass, dataWithValidation];
  },
};
