import { IBreadcrumbItem } from "@fluentui/react";
import React, { useCallback, useMemo, useState } from "react";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { MerchantPatternMatching } from "../types/advancedPatternMatching";
import {
  ConfigSnapshot,
  ConfigSnapshotSearchableItem,
  PaginatedConfigSnapshot,
} from "../types/configSnapshot";
import { ConfirmModalType } from "../types/confirmation";
import { DetailedForm } from "../types/form";
import { defaultLLMSetting } from "../types/mappers/advancedPatternMatching/merchant";

const defaultTags = {
  mall: [],
  merchant: [],
};

type Props = {
  children: React.ReactNode;
  isSavingForm: boolean;
  isVersionSnapshotsEnabled: boolean;
  updateMerchantPatternMatching: (
    merchantPatternMatching: MerchantPatternMatching
  ) => void;
  saveForm: (
    shouldIgnoreConflict?: boolean,
    releaseOption?: {
      snapshotName: string;
      snapshotNote?: string;
    }
  ) => Promise<void>;
  form: DetailedForm | undefined | null;
  extractorBreadcrumbLink?: IBreadcrumbItem;
  fetchConfigSnapshots: (
    form: DetailedForm,
    cursor?: string
  ) => Promise<PaginatedConfigSnapshot>;
  editConfigSnapshot: (
    configSnapshotId: string,
    retrievedAt: string,
    name: string,
    note?: string
  ) => Promise<ConfigSnapshot>;
  getConfigSnapshot: (configSnapshotId: string) => Promise<ConfigSnapshot>;
  bookmarkConfigSnapshot: (
    configSnapshotId: string,
    retrievedAt: string,
    name: string,
    note?: string
  ) => Promise<ConfigSnapshot>;
  deleteConfigSnapshot: (configSnapshotId: string) => Promise<void>;
  restoreConfigSnapshot: (configSnapshotId: string) => Promise<void>;
  fetchConfigSnapshotIndex: (
    form: DetailedForm
  ) => Promise<ConfigSnapshotSearchableItem[]>;
};

function useMakeContext(props: Props) {
  const { handleConflict, requestUserConfirmation } =
    useConfirmModalActionCreator();
  const {
    saveForm,
    updateMerchantPatternMatching,
    isSavingForm,
    form,
    fetchConfigSnapshots: fetchConfigSnapshotsImpl,
    bookmarkConfigSnapshot,
    editConfigSnapshot,
    deleteConfigSnapshot,
    restoreConfigSnapshot,
    getConfigSnapshot,
    fetchConfigSnapshotIndex: fetchConfigSnapshotIndexImpl,
  } = props;

  const {
    extractorBreadcrumbLink,
    isVersionSnapshotsEnabled: isSnapshotVersionEnabled,
  } = props;

  const [lastPaginatedConfigSnapshots, setLastPaginatedConfigSnapshots] =
    useState<PaginatedConfigSnapshot>();
  const [configSnapshots, setConfigSnapshots] = React.useState<
    ConfigSnapshot[]
  >([]);
  const [isDataChanged, setIsDataChanged] = useState(false);

  const fetchConfigSnapshots = useCallback(
    async (cursor?: string) => {
      if (!form) {
        return;
      }
      const r = await fetchConfigSnapshotsImpl(form, cursor);
      setLastPaginatedConfigSnapshots(r);
      if (!cursor || cursor.length === 0) {
        setConfigSnapshots(r.configSnapshots);
      } else {
        setConfigSnapshots(prev => prev.concat(r.configSnapshots));
      }
      return r;
    },
    [fetchConfigSnapshotsImpl, form]
  );

  const fetchInitialConfigSnapshots = React.useCallback(() => {
    if (!form) {
      return;
    }
    if (!lastPaginatedConfigSnapshots) {
      fetchConfigSnapshots();
    }
  }, [fetchConfigSnapshots, form, lastPaginatedConfigSnapshots]);

  const merchantPatternMatching = useMemo<MerchantPatternMatching>(() => {
    return (
      form?.config.advanced_pattern_matching?.merchant ?? {
        tags: defaultTags,
        fallback_token_groups: {},
        llm_settings: {
          default: defaultLLMSetting,
        },
      }
    );
  }, [form]);

  const save = useCallback(
    async (
      merchantPatternMatching: MerchantPatternMatching,
      releaseOption?: {
        snapshotName: string;
        snapshotNote?: string;
      }
    ): Promise<void> => {
      updateMerchantPatternMatching(merchantPatternMatching);
      return await handleConflict(
        async () => {
          await saveForm(false, releaseOption);
          await fetchConfigSnapshots();
        },
        async () => {
          await saveForm(true, releaseOption);
          await fetchConfigSnapshots();
        },
        {
          titleId: "form_editor.form_modifed_prompt.title",
          messageId: "form_editor.form_modifed_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );
    },
    [
      updateMerchantPatternMatching,
      saveForm,
      handleConflict,
      fetchConfigSnapshots,
    ]
  );

  const fetchConfigSnapshotIndex = useCallback(async () => {
    if (!form) {
      return [];
    }
    return fetchConfigSnapshotIndexImpl(form);
  }, [form, fetchConfigSnapshotIndexImpl]);

  const askForTagRemovalConfirmation = useCallback(async () => {
    return requestUserConfirmation(
      {
        titleId: "advance_token_setup.remove_tag_modal.title",
        actionId: "common.remove",
        messageId: "advance_token_setup.remove_tag_modal.message",
        type: ConfirmModalType.Normal,
      },
      true
    );
  }, [requestUserConfirmation]);

  return React.useMemo(
    () => ({
      askForTagRemovalConfirmation,
      merchantPatternMatching,
      updateMerchantPatternMatching,
      save,
      isSavingForm,
      extractorBreadcrumbLink,
      isSnapshotVersionEnabled,
      configSnapshots,
      setConfigSnapshots,
      bookmarkConfigSnapshot,
      editConfigSnapshot,
      deleteConfigSnapshot,
      restoreConfigSnapshot,
      fetchConfigSnapshots,
      getConfigSnapshot,
      isDataChanged,
      setIsDataChanged,
      lastPaginatedConfigSnapshots,
      fetchInitialConfigSnapshots,
      fetchConfigSnapshotIndex,
    }),
    [
      askForTagRemovalConfirmation,
      merchantPatternMatching,
      updateMerchantPatternMatching,
      save,
      isSavingForm,
      isSnapshotVersionEnabled,
      extractorBreadcrumbLink,
      configSnapshots,
      setConfigSnapshots,
      bookmarkConfigSnapshot,
      editConfigSnapshot,
      deleteConfigSnapshot,
      restoreConfigSnapshot,
      fetchConfigSnapshots,
      getConfigSnapshot,
      isDataChanged,
      setIsDataChanged,
      lastPaginatedConfigSnapshots,
      fetchInitialConfigSnapshots,
      fetchConfigSnapshotIndex,
    ]
  );
}

type AdvanceTokenSetupEditorContextValue = ReturnType<typeof useMakeContext>;
const AdvanceTokenSetupEditorContext =
  React.createContext<AdvanceTokenSetupEditorContextValue>(null as any);

const AdvanceTokenSetupEditorProviderImpl = (props: Props) => {
  const value = useMakeContext(props);
  return <AdvanceTokenSetupEditorContext.Provider {...props} value={value} />;
};

export const AdvanceTokenSetupEditorProvider = (props: Props) => {
  return <AdvanceTokenSetupEditorProviderImpl {...props} />;
};

export function useAdvanceTokenSetupEditor() {
  return React.useContext(AdvanceTokenSetupEditorContext);
}
