import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { RootState } from "../redux/types";
import { Usage, UsageRange } from "../types/usage";

export function useRequestLogActionCreator() {
  const { getState } = useStore<RootState>();

  const getUsage = useCallback(
    async (range: UsageRange, tokenIds: string[]): Promise<Usage[]> => {
      const { resourceOwnerId } = getState().resourceOwner;
      return apiClient.getUsage(range, tokenIds, resourceOwnerId);
    },
    [getState]
  );

  return useMemo(() => ({ getUsage }), [getUsage]);
}
