.advanced-settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  user-select: none;

  label {
    font-size: 10px;
  }

  .toggle-area {
    margin-top: 16px;
    margin-left: 2px;

    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    i {
      font-size: 10px;
      padding-right: 6px;
    }

    label {
      cursor: pointer;
    }

    .expanded {
      transform: scaleY(-1);
    }
  }

  .space {
    flex: 1;
  }

  .delete-button {
    padding: 4px;
    cursor: pointer;
  }
}
