import cntl from "cntl";
import * as React from "react";

import { useLocale } from "../../../contexts/locale";

type Props = {
  textId?: string;
  text?: string;
  isActive?: boolean;
  className?: string;
  onClick?: () => void;
};

export const Chip = React.memo((props: Props) => {
  const { localized } = useLocale();
  const { className } = props;
  const isActive = props.isActive ?? false;

  const label = props.textId ? localized(props.textId) : props.text;

  const classes = cntl`
    cursor-pointer
    select-none
    border-2
    border-solid
    text-label-medium
    ${
      isActive
        ? cntl`
          border-border-brand 
          text-chip-label-primary
          bg-chip-primary
          hover:bg-chip-focused-hovered
          hover:border-border-brand
          hover:text-chip-label-primary
          `
        : cntl`
          border-border-secondary 
          text-button-label-secondary
          hover:bg-grey-100
          hover:border-border-secondary
          hover:text-button-label-secondary
        `
    }
    rounded-[8px]
    h-[40px]
    flex
    justify-center
    items-center
    px-[16px]
    py-[14px]
    ${className}
    `;

  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      props.onClick?.();
    },
    [props]
  );

  return (
    <span className={classes} onClick={onClick}>
      {label}
    </span>
  );
});
