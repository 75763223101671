import React, { ReactElement, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { MicrosoftAuthCallback } from "../actions/microsoftAuth";
import LoadingModal from "../components/LoadingModal";

const SharepointAuthContainer = (): ReactElement => {
  // NOTE: expect this is opened as popup during microsoft sign in flow, and will be closed by parent when finish

  const [searchParams] = useSearchParams();
  useEffect(() => {
    // on render, pass query param to popup parent via message
    const data: MicrosoftAuthCallback = {
      state: searchParams.get("state"),
      idToken: searchParams.get("id_token"),
      accessToken: searchParams.get("access_token"),
      refreshToken: searchParams.get("refresh_token"),
      error: searchParams.get("error"),
      errorDescription: searchParams.get("error_description"),
    };
    window.opener.postMessage(data, window.location.origin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingModal isOpen={true} />;
};

export default SharepointAuthContainer;
