import React, { useCallback, useState } from "react";

import { useFormGroupActionCreator } from "../actions/formGroup";
import { FormGroupTable } from "../components/TeamDetailResources";
import { useFetchTeamFormGroups } from "../hooks/admin";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { AdminPathParam } from "../models";
import {
  getOffsetByPage,
  getOffsetOfBeginningOfPage,
} from "../utils/pagination";

const PAGE_SIZE = 20;

function _AdminTeamDetailResourcesFormGroupsContainer() {
  const { teamId, region } = useUnsafeParams<AdminPathParam>();
  const { formGroups, isFetching, pageInfo } = useAppSelector(
    state => state.admin.teamDetail.resourceState.formGroupsState
  );
  const { exportFormGroup } = useFormGroupActionCreator();

  const [offset, setOffset] = useState(
    getOffsetOfBeginningOfPage(pageInfo?.offset ?? 0, PAGE_SIZE)
  );
  const onNavigateToPage = useCallback((pageNumber: number) => {
    setOffset(getOffsetByPage(pageNumber));
  }, []);

  const handleExportFormGroup = useCallback(
    async (formGroupId: string) => {
      await exportFormGroup(formGroupId, teamId, region);
    },
    [teamId, region, exportFormGroup]
  );

  useFetchTeamFormGroups(PAGE_SIZE, offset, teamId, region);

  return (
    <FormGroupTable
      offset={offset}
      formGroups={formGroups}
      isFormGroupsFetching={isFetching}
      formGroupsPageInfo={pageInfo}
      pageSize={PAGE_SIZE}
      onNavigateToPage={onNavigateToPage}
      exportFormGroup={handleExportFormGroup}
    />
  );
}

export const AdminTeamDetailResourcesFormGroupsContainer = React.memo(
  _AdminTeamDetailResourcesFormGroupsContainer
);
export default AdminTeamDetailResourcesFormGroupsContainer;
