import * as React from "react";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router";
import { useOutlet } from "react-router-dom";

import { useAppActionCreator } from "../actions/app";
import { CommonConfirmModal } from "../components/ConfirmModal";
import SentryBoundary from "../components/SentryBoundary";
import SplashScreen from "../components/SplashScreen";
import { ActionToastContent } from "../components/Toast";
import { GOT_TEAM_INVITATION_TOAST_ID } from "../constants";
import {
  useEnsureCrossRegionLogout,
  useEnsureTeamLookupIdIsInURL,
} from "../hooks/app";
import { useHandleReadmeIOLogin } from "../hooks/readmeIO";
import { useAppSelector } from "../hooks/redux";
import { WorkingSearchParamsUpdater } from "../hooks/searchParamUtils";
import { useToast } from "../hooks/toast";

Modal.setAppElement("#root");

const InvitationToastPresenter = React.memo(
  (props: { onClick: () => void }) => {
    const { onClick } = props;
    const { info: notifyInfo, removeToast } = useToast();
    const { pathname } = useLocation();
    const hasDismissedRef = React.useRef(pathname === "/invitation");

    // Onboarding should not show the toast
    const shouldIgnore = React.useRef(pathname.startsWith("/onboarding"));

    const invitationsCount = useAppSelector(
      state =>
        state.user.invitationsByRegion &&
        Object.values(state.user.invitationsByRegion).flat().length
    );

    const onButtonClicked = React.useCallback(() => {
      removeToast(GOT_TEAM_INVITATION_TOAST_ID);
      hasDismissedRef.current = true;
      onClick();
    }, [onClick, removeToast]);

    const onDismiss = React.useCallback(() => {
      hasDismissedRef.current = true;
    }, []);

    const doNotify = React.useCallback(() => {
      if (invitationsCount && !shouldIgnore.current) {
        notifyInfo(
          <ActionToastContent
            messageId="app.toast.got_team_invitation"
            detail={{ count: invitationsCount }}
            actionId="app.toast.got_team_invitation.view"
            onClick={onButtonClicked}
          />,
          {
            id: GOT_TEAM_INVITATION_TOAST_ID,
            onDismiss: onDismiss,
          }
        );
      }
    }, [notifyInfo, invitationsCount, onButtonClicked, onDismiss]);

    const doNotifyRef = React.useRef(doNotify);
    doNotifyRef.current = doNotify;

    React.useEffect(() => {
      if (invitationsCount && !hasDismissedRef.current) {
        doNotifyRef.current();
      }
    }, [invitationsCount]);

    return null;
  }
);

export const App: React.FC = React.memo(() => {
  const isInitialized = useAppSelector(state => state.app.isInitialized);
  const isCurrentUserExist = useAppSelector(
    state => state.user.currentUser !== undefined
  );
  const isNoResourceOwnerExists = useAppSelector(
    state => state.resourceOwner.resourceOwnerId === undefined
  );
  const isLoggingOut = useAppSelector(state => state.user.isLoggingOut);

  const navigate = useNavigate();
  const { initialize } = useAppActionCreator();
  useEnsureTeamLookupIdIsInURL();
  useEnsureCrossRegionLogout();
  useHandleReadmeIOLogin();

  const redirectToInvitation = React.useCallback(() => {
    navigate("/invitation");
  }, [navigate]);

  React.useEffect(() => {
    if (isInitialized) return;
    initialize().then(result => {
      const { invitationCode } = result;
      if (
        invitationCode &&
        !window.location.pathname.startsWith("/login") &&
        !window.location.pathname.startsWith("/signup") &&
        !window.location.pathname.startsWith("/sharepoint-auth")
      ) {
        navigate(`/invitation?invitation-code=${invitationCode}`);
      }
    });
    // initialize() should be called only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const outlet = useOutlet();

  React.useEffect(() => {
    if (outlet === null && isInitialized) {
      navigate("/extractor");
    }
  }, [outlet, isInitialized, navigate]);

  return (
    <>
      <WorkingSearchParamsUpdater />
      <SentryBoundary>
        {!isInitialized || isLoggingOut ? (
          <SplashScreen />
        ) : (
          <>
            {outlet}
            {isCurrentUserExist && !isNoResourceOwnerExists && (
              <InvitationToastPresenter onClick={redirectToInvitation} />
            )}
          </>
        )}
        <CommonConfirmModal />
      </SentryBoundary>
    </>
  );
});

export default App;
