import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import AppBreadcrumb, { CustomBreadcrumbItem } from "../AppBreadcrumb";
import { NavTabBar } from "../NavTabBar";
import styles from "./styles.module.scss";

export enum WorkspaceTab {
  Documents = "document",
  Integrations = "integration",
  Api = "api",
  Settings = "setting",
}

export interface LayoutProps {
  children: React.ReactNode;
  rightPanel?: React.ReactNode;
  showRightPanel?: boolean;
  breadcrumbItems: CustomBreadcrumbItem[];
  selectedTab: WorkspaceTab;
  onTabSelect: (key: string) => void;
  extractorName: string | null;
  extractorHref: string | null;
}

export function WorkspaceNavBarLayout(props: LayoutProps) {
  const {
    children,
    rightPanel,
    showRightPanel,
    breadcrumbItems,
    selectedTab,
    onTabSelect,
  } = props;
  const tabs = React.useMemo(() => {
    return [
      {
        key: WorkspaceTab.Documents,
        labelId: "workspace.tab.documents",
      },
      {
        key: WorkspaceTab.Integrations,
        labelId: "workspace.tab.integrations",
      },
      {
        key: WorkspaceTab.Api,
        labelId: "workspace.tab.api",
      },
      {
        key: WorkspaceTab.Settings,
        labelId: "workspace.tab.settings",
      },
    ];
  }, []);

  return (
    <main className={styles["main-content-container"]}>
      <div className={styles["container"]}>
        <div className={styles["navbar-wrapper"]}>
          <div className={styles["navbar-main"]}>
            <div className={styles["navbar-top"]}>
              <AppBreadcrumb
                className={styles["nav-bar"]}
                items={breadcrumbItems}
              />
            </div>
            <div className={styles["navbar-bottom"]}>
              <NavTabBar
                tabs={tabs}
                selectedKey={selectedTab}
                onSelect={onTabSelect}
              />
            </div>
          </div>
          <Link
            to={props.extractorHref ?? "#"}
            className={styles["extractor-button"]}
          >
            <p className={styles["extractor-button-label"]}>
              <FormattedMessage id="workspace.nav_bar.extractor_button.label" />
            </p>
            <p className={styles["extractor-button-name"]}>
              {props.extractorName ?? "-"}
            </p>
          </Link>
        </div>

        <hr />
        <div className={styles.content}>{children}</div>
      </div>

      <div
        className={cn(styles["right-panel-container"], {
          [styles["right-panel-container--hidden"]]: !showRightPanel,
        })}
      >
        <div className={styles["right-panel"]}>{rightPanel}</div>
      </div>
    </main>
  );
}
