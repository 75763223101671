@import "../../styles/variables.scss";

$item-width: 225px;
$item-height: 225px;

.container {
  padding: 20px;
}

.ms-dropdown {
  width: 210px;

  :global(.ms-Dropdown-title) {
    border-left: none;
    border-top: none;
    border-right: none;
    color: #201f1e;
  }

  i {
    color: #201f1e;
  }
}

.control {
  margin-top: 18px;
  display: flex;
  gap: 16px;
  .dropdown {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      margin-bottom: 4px;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .title {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    > p {
      color: black;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .subtitle {
    color: #605e5c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .button {
    i {
      font-size: 10px;
      font-weight: 600;
    }
  }
}

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 15px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  cursor: pointer;
  width: $item-width;
  height: $item-height;
  padding: 12px 12px 0px 12px;
  background-color: $neutral-lighter-alt;

  .item-header {
    font-size: 14px;
    font-weight: 600;
    color: #201f1e;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .item-body {
    margin-top: auto;

    display: flex;
    padding: 6px 8px 8px 8px;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 4px;
    border: 1px solid var(--border-colors-divider, #edebe9);
    background: #fff;
  }

  .item-body-label {
    color: #27ae60;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }

  .item-body-text {
    color: var(--type-colors-secondary, #605e5c);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }

  .item-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    font-weight: 400;
    color: #a19f9d;
    border-top: 1px solid #edebe9;
    padding: 12px 0px 14px 0px;
    margin-top: 12px;

    > i {
      height: 12px;
      > svg {
        height: 12px;
      }
    }
  }
}

.grid-item.create {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: $neutral-lighter-alt;

  .icon {
    margin-right: 12px;
  }
}

.paginator {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  padding-bottom: 22px;
}

.grid-item.skeleton {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  cursor: default;
  padding: 12px 12px 0px 12px;

  .item-header {
    flex: 1 1;
  }

  .item-body {
    flex: 0 1 50px;
  }

  .item-footer {
    flex: 0 1 40px;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1.5s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.empty-view {
  background: #fafafa;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px;
  outline: 2px dashed var(--primary-color, #25d0b1);
  outline-offset: -20px;
}

.empty-view-title {
  color: var(--type-colors-primary, #323130);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
  text-align: center;
}

.empty-view-message {
  margin-top: 6px;

  color: #605e5c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-align: center;
}

.empty-view-create-button {
  margin-top: 20px;
}

.empty-view-flow-chart {
  width: 100%;
  max-width: 844px;
  height: auto;
}

.not-found {
  width: 100%;
  background-color: #faf9f8;
  text-align: center;
  vertical-align: middle;
  color: var(--type-colors-secondary, #605e5c);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  padding: 10px;
}
