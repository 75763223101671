@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
  .dropdown {
    max-width: 480px;
  }

  .enabled-features {
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .buttons {
    display: flex;
    align-items: center;
  }

  .enabled-features-title {
    font-weight: 600px;
    font-size: 16px;
  }

  .checkbox {
    margin-top: 10px;
  }
}
