import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  Label,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { AppConfig } from "../../config";
import { useLocale } from "../../contexts/locale";
import { AdminRegionTeamListState } from "../../reducers/admin";
import { Plan } from "../../types/plan";
import { capitalizeFirstLetter } from "../../utils/capitalize";
import RegionTabBar from "../RegionTabBar";
import TeamTable from "../TeamTable";
import { TeamTableRowInfo } from "../TeamTableRow";
import styles from "./styles.module.scss";

interface Props {
  teamsStateByRegion: {
    [key: string]: AdminRegionTeamListState;
  };
  plans: Plan[];
  offset: number;
  pageSize: number;
  selectedPlan?: string;
  regionOptions: { key: string; label: string }[];
  regionKey: string;
  setRegionKey: (key: string) => void;
  setSelectedPlan: (key: string) => void;
  fieldToSearch?: string;
  setFieldToSearch: (key: string) => void;
  searchText?: string;
  setSearchText: (text?: string) => void;
  onSubmitSearch: () => void;
  onNavigateToPage: (page: number) => void;
}

function _TeamListLayout(props: Props) {
  const {
    teamsStateByRegion,
    plans,
    offset,
    pageSize,
    selectedPlan,
    regionOptions,
    regionKey,
    setRegionKey,
    setSelectedPlan,
    searchText,
    setSearchText,
    onSubmitSearch,
    onNavigateToPage,
    fieldToSearch,
    setFieldToSearch,
  } = props;

  const { localized } = useLocale();

  const planOptions = React.useMemo(() => {
    return [
      {
        key: "",
        text: localized("team.list.all"),
      },
      ...AppConfig.admin.planOptions.map(option => {
        return {
          key: option,
          text: capitalizeFirstLetter(option),
        };
      }),
      ...plans
        .map(plan => {
          return {
            key: plan.id,
            text: capitalizeFirstLetter(plan.name),
          };
        })
        .filter(p => !AppConfig.admin.planOptions.includes(p.key)),
    ];
  }, [plans, localized]);

  const onPlanChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option?.key && option?.key !== "") {
        setSelectedPlan(`${option.key}`);
      } else {
        setSelectedPlan("");
      }
    },
    [setSelectedPlan]
  );

  const onFieldToSearchChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option?.key && option?.key !== "") {
        setFieldToSearch(`${option.key}`);
      } else {
        setFieldToSearch("");
      }
    },
    [setFieldToSearch]
  );

  const fieldtoSearchOptions = React.useMemo(() => {
    return [
      {
        key: "",
        text: localized("team.list.search_team_name"),
      },
      {
        key: "email",
        text: localized("team.list.search_team_email"),
      },
      {
        key: "lookup_id",
        text: localized("team.list.search_team_id"),
      },
    ];
  }, [localized]);

  const onSearchTextChange = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.stopPropagation();
      event.preventDefault();
      setSearchText(value);
    },
    [setSearchText]
  );

  const onSearchKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.stopPropagation();
        event.preventDefault();
        onSubmitSearch();
      }
    },
    [onSubmitSearch]
  );

  const { teams, isLoading, isEmpty, totalCount, error } = React.useMemo(() => {
    const teamState = teamsStateByRegion[regionKey];
    if (teamState === undefined) {
      return {
        teams: [],
        isLoading: true,
        isEmpty: true,
        totalCount: 0,
        error: undefined,
      };
    }
    return {
      teams: teamState.teams ?? [],
      isLoading: teamState.isFetching,
      isEmpty:
        !teamState.isFetching && (teamState.pageInfo?.totalCount ?? 0) === 0,
      totalCount: teamState.pageInfo?.totalCount ?? 0,
      error: teamState.error,
    };
  }, [teamsStateByRegion, regionKey]);

  return (
    <div className={styles["team-list"]}>
      <h3 className={styles["title"]}>
        <FormattedMessage id="team.list.title" />
      </h3>
      <div className={styles["search-filter"]}>
        <div className={styles["search-by-text"]}>
          <Label>
            <FormattedMessage id="team.list.search_by" />
          </Label>
        </div>
        <Dropdown
          className={styles["search-by-dropdown"]}
          placeholder={localized("team.list.search_by")}
          options={fieldtoSearchOptions}
          selectedKey={fieldToSearch}
          onChange={onFieldToSearchChange}
        />
        <TextField
          type="search-team-name"
          iconProps={{ iconName: "Search", className: styles["search-icon"] }}
          className={styles["search-input-field"]}
          placeholder={localized("team.list.search")}
          value={searchText}
          onKeyDown={onSearchKeyDown}
          onChange={onSearchTextChange}
        />
        <div className={styles["plan-filter-text"]}>
          <Label>
            <FormattedMessage id="team.list.plan" />
          </Label>
        </div>
        <Dropdown
          className={styles["plan-filter"]}
          placeholder={localized("team.list.all")}
          options={planOptions}
          selectedKey={selectedPlan}
          onChange={onPlanChange}
        />
        <DefaultButton
          className={styles["search-button"]}
          onClick={onSubmitSearch}
          text={localized("team.list.search")}
        />
      </div>
      <div className={styles["tab-bar"]}>
        <RegionTabBar
          selectedRegion={regionKey}
          setSelectedRegion={setRegionKey}
          regionOptions={regionOptions}
        />
      </div>
      <TeamTable
        teams={teams}
        regionKey={regionKey}
        isEmpty={isEmpty}
        offset={offset}
        pageSize={pageSize}
        totalCount={totalCount}
        isLoading={isLoading}
        error={error}
        onNavigateToPage={onNavigateToPage}
        displayInfos={[
          TeamTableRowInfo.ContactEmail,
          TeamTableRowInfo.Plan,
          TeamTableRowInfo.CreatedAt,
        ]}
      />
    </div>
  );
}

export const TeamListLayout = React.memo(_TeamListLayout);
export default TeamListLayout;
