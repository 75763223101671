import * as React from "react";

import { AppConfig } from "../config";
import { useToast } from "./toast";

function formatBytes(bytes: number, decimals: number = 0) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function useMasterImageSizeValidator() {
  const toast = useToast();

  const validateMasterImageSize = React.useCallback(
    (file: File) => {
      if (AppConfig.maxUploadImageSize !== undefined) {
        if (file.size > AppConfig.maxUploadImageSize) {
          toast.error(
            "error.fail_to_upload_image.exceed_max_size",
            {},
            {
              maxSize: formatBytes(AppConfig.maxUploadImageSize),
            }
          );
          return false;
        }
      }

      return true;
    },
    [toast]
  );

  return validateMasterImageSize;
}
