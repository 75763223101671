import * as yup from "yup";

export const oauthCredentialSchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    resourceServer: yup.string().required(),
  })
  .camelCase();

export const oauthCredentialListSchema = yup
  .object({
    oauthCredentials: yup.array().of(oauthCredentialSchema.defined()).defined(),
  })
  .camelCase();

export type OAuthCredential = yup.InferType<typeof oauthCredentialSchema>;

export type OAuthCredentialList = yup.InferType<
  typeof oauthCredentialListSchema
>;
