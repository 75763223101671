import * as React from "react";
import { useCallback } from "react";

import {
  CreateWorkspaceModal,
  useCreateWorkspaceModalHandle,
} from "../components/CreateWorkspaceModal";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { ExtractNote } from "../components/ExtractNote";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { README_IO_PAGE_URL } from "../constants";
import HeaderContainer from "../containers/Header";
import { useDesktopApp } from "../hooks/desktopApp";
import { useCommonFormContainerState } from "../hooks/form";
import { useUnsafeParams } from "../hooks/params";
import { useReadmeIO } from "../hooks/readmeIO";
import { BriefExtractorMapper } from "../types/mappers/extractor";
import { FormNavBarLayout, FormNavTabKey } from "./FormNavBarLayout";

type PathParam = {
  formId: string;
};

export const FormExtractContainer = React.memo(() => {
  const containerState = useCommonFormContainerState(
    useUnsafeParams<PathParam>().formId
  );

  const { workerToken, formId, formName } =
    containerState.state === "success"
      ? {
          workerToken: containerState.workerToken,
          formId: containerState.form.id,
          formName: containerState.form.name,
        }
      : { workerToken: undefined, formId: undefined, formName: undefined };

  const { onMacOsAppClick, onWindowsAppClick, onOpenInDesktopApp } =
    useDesktopApp(workerToken, formId, formName);

  const { onRedirectToReadmeIO } = useReadmeIO();

  const onOpenReference = useCallback(() => {
    onRedirectToReadmeIO(README_IO_PAGE_URL.referenceDocumentExtraction, {
      parameters: {
        formId,
      },
    });
  }, [formId, onRedirectToReadmeIO]);

  const {
    props: createWorkspaceModalProps,
    isCreating: isCreatingWorkspace,
    onCreateExtractorWorkspaceClick,
  } = useCreateWorkspaceModalHandle(
    containerState.state === "success"
      ? BriefExtractorMapper.fromDetailForm(containerState.form)
      : null
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form" />
        ) : containerState.state === "success" ? (
          <FormNavBarLayout
            selectedTab={FormNavTabKey.API}
            form={containerState.form}
          >
            <ExtractNote
              token={workerToken}
              tokens={containerState.workerTokens}
              onSelectToken={containerState.onSelectWorkerToken}
              extractorId={containerState.form.id}
              onMacOsAppClick={onMacOsAppClick}
              onWindowsAppClick={onWindowsAppClick}
              onOpenInDesktopApp={onOpenInDesktopApp}
              onOpenReference={onOpenReference}
              workspaceSectionProps={{
                extractorName: containerState.form.name,
                workspaces: containerState.form.workspaces,
                onCreateWorkspace: onCreateExtractorWorkspaceClick,
                isCreating: isCreatingWorkspace,
              }}
            />
          </FormNavBarLayout>
        ) : null}
        <CreateWorkspaceModal {...createWorkspaceModalProps} />
      </Main>
    </Layout>
  );
});

export default FormExtractContainer;
