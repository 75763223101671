import { Icon, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React from "react";
import * as uuid from "uuid";

import { DOCUMENT_DETECTION_TYPES } from "../../constants/formGroup";
import { useTeamPermission } from "../../hooks/permission";
import { ExtractorOptionForCombinedExtractor } from "../../types/extractor";
import {
  DetailFormGroup,
  DocumentDetectionType,
  FormGroupDocumentDetection,
  FormGroupDocumentDetectionBase,
} from "../../types/formGroup";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import { FormGroupDocumentDetectionModal } from "./FormGroupDocumentDetectionModal";
import styles from "./styles.module.scss";

interface Props {
  formGroup: DetailFormGroup;
  extractorOptions: ExtractorOptionForCombinedExtractor[];
  onUpdateDocumentDetections: (
    documentDetections: FormGroupDocumentDetectionBase[]
  ) => Promise<void>;
}

interface FormGroupDocumentDetectionRowProps {
  documentDetection: FormGroupDocumentDetection;
  shouldShowEditButton: boolean;
  onEditDocumentDetection: (
    documentDetection: FormGroupDocumentDetection
  ) => void;
  onRemoveDocumentDetection: (id: string) => Promise<void>;
}

function FormGroupDocumentDetectionRow(
  props: FormGroupDocumentDetectionRowProps
) {
  const {
    documentDetection,
    shouldShowEditButton,
    onEditDocumentDetection,
    onRemoveDocumentDetection,
  } = props;

  return (
    <tr className={styles["form-group-document-detection-table-row"]}>
      <td>
        <FormattedMessage
          id={`document_detection_type.${documentDetection.documentType}`}
        />
      </td>
      <td>
        {documentDetection.targetForm?.name ||
          documentDetection.targetFormGroup?.name}
      </td>
      {shouldShowEditButton && (
        <td className={styles["action-container"]}>
          <Icon
            iconName="Edit"
            className={styles["action-button"]}
            onClick={() => onEditDocumentDetection(documentDetection)}
          />
          <Icon
            iconName="Delete"
            className={styles["action-button"]}
            onClick={() => onRemoveDocumentDetection(documentDetection.id)}
          />
        </td>
      )}
    </tr>
  );
}

export const FormGroupDocumentDetectionList = React.memo((props: Props) => {
  const { formGroup, extractorOptions, onUpdateDocumentDetections } = props;

  const selectableDocumentTypes = React.useMemo(() => {
    return DOCUMENT_DETECTION_TYPES.filter(
      t =>
        !formGroup.documentDetections.find(
          documentDetection => documentDetection.documentType === t
        )
    );
  }, [formGroup.documentDetections]);

  const [isDocumentDetectionModalOpen, setIsDocumentDetectionModalOpen] =
    React.useState<boolean>(false);
  const [documentDetectionToEdit, setDocumentDetectionToEdit] = React.useState<
    FormGroupDocumentDetection | undefined
  >(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { hasPermissionToEditResource } = useTeamPermission();

  const onEditDocumentDetection = React.useCallback(
    (documentDetection: FormGroupDocumentDetection) => {
      setDocumentDetectionToEdit(documentDetection);
      setIsDocumentDetectionModalOpen(true);
    },
    []
  );

  const onRemoveDocumentDetection = React.useCallback(
    async (id: string) => {
      const newDocumentDetections = formGroup.documentDetections.filter(
        d => d.id !== id
      );
      try {
        setIsLoading(true);
        await onUpdateDocumentDetections(newDocumentDetections);
      } finally {
        setIsLoading(false);
      }
    },
    [formGroup.documentDetections, onUpdateDocumentDetections]
  );

  const onAddDocumentDetection = React.useCallback(() => {
    setIsDocumentDetectionModalOpen(true);
    setDocumentDetectionToEdit(undefined);
  }, []);

  const onCloseDocumentDetectionModal = React.useCallback(() => {
    setIsDocumentDetectionModalOpen(false);
    setDocumentDetectionToEdit(undefined);
  }, []);

  const onSaveDocumentDetections = React.useCallback(
    async (
      id: string | undefined,
      targetFormId: string | null | undefined,
      targetFormGroupId: string | null | undefined,
      documentType: DocumentDetectionType
    ) => {
      onCloseDocumentDetectionModal();
      const newDocumentDetections: FormGroupDocumentDetectionBase[] = [
        ...formGroup.documentDetections,
      ];
      if (id) {
        const index = newDocumentDetections.findIndex(d => d.id === id);
        if (index === -1) {
          console.error(`Cannot find document detection ${id} in form group`);
          return;
        }
        newDocumentDetections[index] = {
          id,
          targetFormId,
          targetFormGroupId,
          documentType,
        };
      } else {
        newDocumentDetections.push({
          id: uuid.v4(),
          targetFormId,
          targetFormGroupId,
          documentType,
        });
      }
      try {
        setIsLoading(true);
        await onUpdateDocumentDetections(newDocumentDetections);
      } finally {
        setIsLoading(false);
      }
    },
    [onCloseDocumentDetectionModal, formGroup, onUpdateDocumentDetections]
  );

  return (
    <div className={styles["form-group-form-list"]}>
      {hasPermissionToEditResource &&
        selectableDocumentTypes.length > 0 &&
        extractorOptions.length > 0 && (
          <div className={styles["add-new-area"]}>
            <div
              className={classNames(styles["add-new-form-button"], {
                [styles["add-new-form-button-empty-list"]]:
                  formGroup.documentDetections.length === 0,
              })}
              onClick={onAddDocumentDetection}
            >
              <Icon iconName="Add" className={styles["add-rule-icon"]} />
              <Text>
                <FormattedMessage id="form_group_document_detection_editor.add_a_rule" />
              </Text>
            </div>
          </div>
        )}
      {formGroup.documentDetections.length > 0 && (
        <Table
          columnIds={[
            "form_group_document_detection_editor.document_type",
            "form_group_document_detection_editor.extractor",
          ].concat(
            hasPermissionToEditResource
              ? ["form_group_document_detection_editor.action"]
              : []
          )}
          className={styles["form-group-table"]}
        >
          {formGroup.documentDetections.map(documentDetection => (
            <FormGroupDocumentDetectionRow
              key={documentDetection.id}
              documentDetection={documentDetection}
              shouldShowEditButton={hasPermissionToEditResource}
              onEditDocumentDetection={onEditDocumentDetection}
              onRemoveDocumentDetection={onRemoveDocumentDetection}
            />
          ))}
        </Table>
      )}

      {isDocumentDetectionModalOpen && (
        <FormGroupDocumentDetectionModal
          documentDetection={documentDetectionToEdit}
          extractorOptions={extractorOptions}
          onCloseModal={onCloseDocumentDetectionModal}
          onSave={onSaveDocumentDetections}
          documentTypes={selectableDocumentTypes}
        />
      )}
      {isLoading && (
        <div className={styles["loading-container"]}>
          <ShortSpinner />
        </div>
      )}
    </div>
  );
});
