import {
  CustomCell,
  CustomRenderer,
  GridCellKind,
  Item,
  Rectangle,
} from "@glideapps/glide-data-grid";

import { renderIcon } from "./Utils/Icon";
import { contains } from "./Utils/math";

interface DeleteCellProps {
  readonly kind: "delete-cell";
  item: Item;
  onDelete: (item: Item) => void;
}

export type DeleteCell = CustomCell<DeleteCellProps>;

export function isDeleteCell(cell: CustomCell): cell is DeleteCell {
  return (cell.data as any).kind === "delete-cell";
}

const bbox = [0, 0, 12, 12];
export const DeleteCellRenderer: CustomRenderer<DeleteCell> = {
  kind: GridCellKind.Custom,
  isMatch: isDeleteCell,
  draw: args => {
    const { ctx, theme, rect } = args;

    const drawArea: Rectangle = {
      x: rect.x + theme.cellHorizontalPadding,
      y: rect.y + theme.cellVerticalPadding,
      width: rect.width - 2 * theme.cellHorizontalPadding,
      height: rect.height - 2 * theme.cellVerticalPadding,
    };
    renderIcon(
      "delete",
      ctx,
      drawArea.x + (drawArea.width - bbox[2]) / 2,
      drawArea.y + (drawArea.height - bbox[3]) / 2,
      bbox[2],
      "#A4262C"
    );
  },
  onClick: args => {
    const { preventDefault, cell, posX, posY, bounds } = args;
    if (
      !cell.readonly &&
      contains(
        [
          (bounds.width - bbox[2]) / 2,
          (bounds.height - bbox[3]) / 2,
          bbox[2],
          bbox[3],
        ],
        posX,
        posY
      )
    ) {
      cell.data.onDelete(cell.data.item);
      preventDefault();
      return cell;
    }
    return undefined;
  },
};
