import * as yup from "yup";

import { pageInfoWithOffsetSchema } from "./pageInfo";

export const customModelImageInfo = yup.object({
  fsl_input: yup
    .mixed((_input): _input is string | string[] => true)
    .nullable(),
  fsl_output: yup.string().nullable(),
  is_fsl_image: yup.boolean().optional(),
  group_id: yup.string().optional().nullable(),
  group_item_index: yup.number().optional().nullable(),
});

export const customModelImageSchema = yup
  .object({
    id: yup.string().required(),
    createdAt: yup.date().defined(),
    updatedAt: yup.date().defined(),
    state: yup.string().required(),
    assetId: yup.string().required(),
    filename: yup.string().nullable(),
    url: yup.string().required(),
    info: customModelImageInfo.nullable(),
    reviewedAt: yup.date().nullable(),
  })
  .camelCase();

export type CustomModelImage = yup.InferType<typeof customModelImageSchema>;

export const paginatedCustomModelImageSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    images: yup.array(customModelImageSchema).defined(),
  })
  .camelCase();

export type PaginatedCustomModelImage = yup.InferType<
  typeof paginatedCustomModelImageSchema
>;
