import {
  CustomCell,
  CustomRenderer,
  GridCellKind,
  Item,
  ProvideEditorComponent,
  Rectangle,
  getMiddleCenterBias,
  measureTextCached,
} from "@glideapps/glide-data-grid";
import React, { useCallback, useEffect, useState } from "react";

import styles from "./RowOrderCell.module.scss";
import { truncatStr } from "./Utils/Text";

interface RowOrderCellProps {
  readonly kind: "row-order-cell";
  item: Item;
  order: number;
  onOrderChange: (item: Item, newOrder: number) => void;
  clickOnDrawItem?: boolean;
}

export type RowOrderCell = CustomCell<RowOrderCellProps>;

export function isRowOrderCell(cell: CustomCell): cell is RowOrderCell {
  return (cell.data as any).kind === "row-order-cell";
}

function getNumber(text: string): string {
  return (/\d+/i.exec(text) || [])[0] || "";
}

const RowOrderCellEditor: ProvideEditorComponent<RowOrderCell> = props => {
  const { value: cell, initialValue = "" } = props;
  const { data, readonly } = cell;
  const { onOrderChange, item } = data;
  const [value, setValue] = useState<string>(data.order.toString());
  const onTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(getNumber(e.target.value));
  }, []);
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" && value.length > 0) {
        onOrderChange(item, parseInt(value));
      }
    },
    [onOrderChange, item, value]
  );

  useEffect(() => {
    const initialNum = getNumber(initialValue);
    if (initialNum.length > 0) setValue(initialNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles["editor"]}>
      <input
        name="input"
        type="number"
        value={value}
        onChange={onTextChange}
        onKeyDown={onKeyDown}
        autoFocus
        readOnly={readonly}
      />
    </div>
  );
};

export const RowOrderCellRenderer: CustomRenderer<RowOrderCell> = {
  kind: GridCellKind.Custom,
  isMatch: isRowOrderCell,
  draw: (args, cell) => {
    const { ctx, theme, rect } = args;
    const { order } = cell.data;

    const drawArea: Rectangle = {
      x: rect.x + theme.cellHorizontalPadding,
      y: rect.y + theme.cellVerticalPadding,
      width: rect.width - 2 * theme.cellHorizontalPadding,
      height: rect.height - 2 * theme.cellVerticalPadding,
    };
    ctx.font = "12px";
    ctx.fillStyle = "black";
    if (order > 0) {
      const bias = getMiddleCenterBias(ctx, `12px ${theme.fontFamily}`);
      const [displayText] = truncatStr(order.toString(), ctx, 50);
      const metric = measureTextCached(displayText, ctx);
      ctx.fillText(
        displayText,
        drawArea.x + (drawArea.width - metric.width) / 2,
        drawArea.y + drawArea.height / 2 + bias
      );
    }
  },
  provideEditor: () => {
    return RowOrderCellEditor;
  },
};
