import * as React from "react";

import { ExtractorCreateSection } from "../components/ExtractorCreate";
import { ExtractorCreateModal } from "../components/ExtractorCreateModal";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import {
  NavBarLayout,
  useNavBarLayoutBreadcrumbItems,
} from "../components/NavBarLayout";
import { useLocale } from "../contexts/locale";
import { useCreateExtractor, useImportExtractor } from "../hooks/extractor";
import HeaderContainer from "./Header";

function _ExtractorCreateContainer() {
  const { onImportExtractor, isImportingExtractor } = useImportExtractor();

  const {
    isCreatingExtractor,
    extractorCreateModalHandle,
    requestToCreateExtractor,
  } = useCreateExtractor();

  const { localized } = useLocale();

  const breadcrumbItems = useNavBarLayoutBreadcrumbItems(
    localized("extractor.breadcrumb.create")
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <NavBarLayout
          breadcrumbItems={breadcrumbItems}
          tabs={[]}
          selectedTab=""
          tabBarVisible={false}
        >
          <ExtractorCreateSection
            onCreateCustomModel={() => {
              requestToCreateExtractor("custom_model");
            }}
            onCreateFixedLayoutExtractor={() => {
              requestToCreateExtractor("form");
            }}
            onCreateCombinedExtractor={() => {
              requestToCreateExtractor("form_group");
            }}
            onCreatePrebuiltExtractor={type => {
              requestToCreateExtractor(type, "");
            }}
            onImport={onImportExtractor}
          />
        </NavBarLayout>
        <ExtractorCreateModal {...extractorCreateModalHandle.props} />
        <LoadingModal
          messageId={
            isImportingExtractor ? "extractor.importing" : "extractor.creating"
          }
          isOpen={isCreatingExtractor || isImportingExtractor}
        />
      </Main>
    </Layout>
  );
}

const ExtractorCreateContainer = React.memo(_ExtractorCreateContainer);
export default ExtractorCreateContainer;
