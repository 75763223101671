.selectable-card {
  border-radius: 10px;
  background-color: #faf9f8;
  box-shadow: 0px 3.200000047683716px 7.199999809265137px 0px
    rgba(0, 0, 0, 0.13);
  cursor: pointer;
}

.selectable-card[disabled] {
  cursor: default;
  pointer-events: none;
}

.selectable-card:hover:not([disabled]) {
  background: #f4f2f1;
}

.selectable-card:disabled {
  background: #faf9f8;
}

.selectable-card-selected {
  border: 1px solid #25d0b1;
}

.action-button-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.image-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-preview {
  width: 236px;
  height: 270px;
  > img,
  > div {
    margin: 10px 0px;
    width: 236px;
    height: 250px;
  }

  > img {
    object-fit: contain;
    cursor: pointer;
  }
}

.checkbox {
  top: 12px;
  left: 12px;
  position: absolute;
  background-color: white;
  z-index: 1;

  :global(.ms-Checkbox-checkbox) {
    margin-right: 0px;
  }
}

.badge {
  margin-top: 12px;
}

.filename {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.uploaded-at {
  color: #605e5c;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 5px;
}

.sample-image-selectable-card {
  position: relative;
}

.selectable-card-is-deleting {
  opacity: 0.5;
  pointer-events: none;
}

.deleting-spinner {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 16px;
}
