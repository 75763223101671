import { Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";

interface RegionTabBarProps {
  regionOptions: { key: string; label: string }[];
  selectedRegion: string;
  setSelectedRegion: (region: string) => void;
}

export default function RegionTabBar(props: RegionTabBarProps) {
  const { regionOptions, selectedRegion, setSelectedRegion } = props;

  const onPivotItemClick = React.useCallback(
    (item?: PivotItem, e?: React.MouseEvent<HTMLElement>) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (item && item.props.itemKey) {
        setSelectedRegion(item.props.itemKey);
      }
    },
    [setSelectedRegion]
  );

  return (
    <Pivot onLinkClick={onPivotItemClick} selectedKey={selectedRegion}>
      {regionOptions.map(r => (
        <PivotItem key={r.key} headerText={r.label} itemKey={r.key} />
      ))}
    </Pivot>
  );
}
