.normal-icon-button {
  width: 34px;
  height: 34px;
  background-color: transparent;
}

.normal-icon-button:disabled {
  width: 34px;
  height: 34px;
  background-color: transparent;
}
