.container {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;

  .content {
    align-self: center;

    h3 {
      font-size: 16px;
      line-height: 22px;
      padding: 0;
      margin-bottom: 12px;
    }

    .message {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 28px;
    }

    .action {
      .default-button {
        margin-left: 18px;
      }
    }
  }
}
