import React from "react";
import { Navigate, Route, Routes } from "react-router";

import { Layout, Main, Top } from "../components/Layout";
import HeaderContainer from "../containers/Header";
import AdminReportContainer from "./AdminReport";
import AdminTeamDetailContainer from "./AdminTeamDetail";
import AdminTeamListContainer from "./AdminTeamList";

function _AdminContainer() {
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true} hasLeft={true}>
        <Routes>
          <Route
            path="team/:region/:teamId/*"
            element={<AdminTeamDetailContainer />}
          />
          <Route path="report" element={<AdminReportContainer />} />
          <Route path="team" element={<AdminTeamListContainer />} />
          <Route path="*" element={<Navigate to="/admin/report" replace />} />
        </Routes>
      </Main>
    </Layout>
  );
}

export const AdminContainer = React.memo(_AdminContainer);
export default AdminContainer;
