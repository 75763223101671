import * as yup from "yup";

import {
  CustomModel,
  CustomModelExtraFieldType,
  CustomModelOption,
  PaginatedBriefCustomModel,
  PaginatedWithOffsetBriefCustomModel,
  customModelOptionSchema,
  customModelSchema,
  getUploadURLResponseSchema,
  paginatedBriefCustomModelSchema,
  paginatedWithOffsetBriefCustomModelSchema,
} from "../../types/customModel";
import {
  PaginatedCustomModelImage,
  paginatedCustomModelImageSchema,
} from "../../types/customModelImage";
import { CustomModelMapper } from "../../types/mappers/customModel";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface CustomModelApiClient {
  listCustomModels: (
    size: number,
    cursor: string,
    resourceOwnerId?: string
  ) => Promise<PaginatedBriefCustomModel>;

  getCustomModel: (
    customModelId: string,
    resourceOwnerId?: string,
    region?: string,
    extraFields?: CustomModelExtraFieldType[]
  ) => Promise<CustomModel>;

  updateCustomModel: (
    customModel: CustomModel,
    shouldIgnoreConflict: boolean,
    resourceOwnerId?: string
  ) => Promise<CustomModel>;

  deleteCustomModel: (
    customModelId: string,
    resourceOwnerId?: string
  ) => Promise<void>;

  pinCustomModel: (
    customModelId: string,
    resourceOwnerId?: string
  ) => Promise<void>;

  unpinCustomModel: (
    customModelId: string,
    resourceOwnerId?: string
  ) => Promise<void>;

  createCustomModel: (
    customModel: CustomModel,
    remark: string,
    resourceOwnerId?: string
  ) => Promise<CustomModel>;

  triggerModelTraining: (
    customModelId: string,
    resourceOwnerId?: string
  ) => Promise<void>;

  deployModelVersion: (
    customModelId: string,
    modelVersionTag: string,
    resourceOwnerId?: string
  ) => Promise<void>;

  undeployModel: (
    customModelId: string,
    resourceOwnerId?: string
  ) => Promise<void>;

  uploadFileForCustomModel: (
    resourceOwnerId: string | undefined,
    customModelId: string,
    file: File,
    width?: number,
    height?: number
  ) => Promise<string>;

  listCustomModelOptions: (
    resourceOwnerId: string | undefined
  ) => Promise<CustomModelOption[]>;

  listCustomModelsWithOffset: (
    size: number,
    cursor: string,
    resourceOwnerId?: string,
    region?: string
  ) => Promise<PaginatedWithOffsetBriefCustomModel>;

  listCustomModelImages: (
    customModelId: string,
    size: number,
    offset: number,
    state?: string[],
    region?: string,
    infoFields?: string[],
    queryFsl?: boolean,
    groupId?: string
  ) => Promise<PaginatedCustomModelImage>;

  listAllCustomModels: (
    size: number,
    cursor: string,
    region?: string
  ) => Promise<PaginatedWithOffsetBriefCustomModel>;

  sendNotification: (
    eventType: string,
    customModelId: string,
    customModelUrl: string
  ) => Promise<void>;

  enableStandardModel: (customModelId: string) => Promise<void>;
}

export function withCustomModelApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async listCustomModels(
      size: number,
      cursor: string,
      resourceOwnerId?: string
    ): Promise<PaginatedBriefCustomModel> {
      return this.lambda(
        "custom_model:list",
        this.injectOptionalFields(
          {
            page_args: {
              size,
              cursor,
            },
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        paginatedBriefCustomModelSchema,
        null
      );
    }

    async getCustomModel(
      customModelId: string,
      resourceOwnerId?: string,
      region?: string,
      extraFields?: CustomModelExtraFieldType[]
    ): Promise<CustomModel> {
      return this.lambda<CustomModel>(
        "custom_model:get",
        this.injectOptionalFields(
          {
            custom_model_id: customModelId,
          },
          {
            resource_owner_id: resourceOwnerId,
            extra_fields: extraFields,
          }
        ),
        customModelSchema,
        undefined,
        region !== undefined ? { region } : undefined
      );
    }

    async updateCustomModel(
      customModel: CustomModel,
      shouldIgnoreConflict: boolean,
      resourceOwnerId?: string
    ): Promise<CustomModel> {
      const resp = CustomModelMapper.toResp(customModel);

      return this.lambda<CustomModel>(
        "custom_model:update",
        this.injectOptionalFields(
          {
            custom_model_id: customModel.id,
            name: resp.name,
            config: resp.config,
          },
          {
            resource_owner_id: resourceOwnerId,
            retrieved_at: shouldIgnoreConflict
              ? undefined
              : customModel.updatedAt,
          }
        ),
        customModelSchema
      );
    }

    async deleteCustomModel(
      customModelId: string,
      resourceOwnerId?: string
    ): Promise<void> {
      return this.lambda(
        "custom_model:delete",
        this.injectOptionalFields(
          {
            custom_model_id: customModelId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }

    async pinCustomModel(
      customModelId: string,
      resourceOwnerId?: string
    ): Promise<void> {
      return this.lambda(
        "custom_model:pin",
        this.injectOptionalFields(
          {
            custom_model_id: customModelId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }

    async unpinCustomModel(
      customModelId: string,
      resourceOwnerId?: string
    ): Promise<void> {
      return this.lambda(
        "custom_model:unpin",
        this.injectOptionalFields(
          {
            custom_model_id: customModelId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }

    async createCustomModel(
      customModel: CustomModel,
      remark: string,
      resourceOwnerId?: string
    ): Promise<CustomModel> {
      const resp = CustomModelMapper.toResp(customModel);
      resp.config.remark = remark;

      return this.lambda<CustomModel>(
        "custom_model:create",
        this.injectOptionalFields(
          {
            name: resp.name,
            config: resp.config,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        customModelSchema
      );
    }

    async triggerModelTraining(
      customModelId: string,
      resourceOwnerId?: string
    ): Promise<void> {
      return this.lambda(
        "custom_model:start-training",
        this.injectOptionalFields(
          {
            custom_model_id: customModelId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }

    async deployModelVersion(
      customModelId: string,
      modelVersionTag: string,
      resourceOwnerId?: string
    ): Promise<void> {
      return this.lambda(
        "custom_model:deploy-model",
        this.injectOptionalFields(
          {
            custom_model_id: customModelId,
            model_version: modelVersionTag,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }

    async undeployModel(
      customModelId: string,
      resourceOwnerId?: string
    ): Promise<void> {
      return this.lambda(
        "custom_model:undeploy-model",
        this.injectOptionalFields(
          {
            custom_model_id: customModelId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }

    async uploadFileForCustomModel(
      resourceOwnerId: string | undefined,
      customModelId: string,
      file: File,
      width?: number,
      height?: number
    ): Promise<string> {
      const args = this.injectOptionalFields(
        {
          custom_model_id: customModelId,
          filename: file.name,
          content_type: file.type,
          size: file.size,
          ...(width !== undefined && height !== undefined
            ? {
                width,
                height,
              }
            : {}),
        },
        {
          resource_owner_id: resourceOwnerId,
        }
      );

      const response = await this.lambda(
        "custom_model:get_upload_url",
        args,
        getUploadURLResponseSchema
      );

      const formData = new FormData();

      for (const [key, value] of Object.entries(response.data)) {
        formData.append(key, value);
      }
      formData.append(response.fileField, file);

      await fetch(response.url, {
        method: "POST",
        body: formData,
      });

      return response.assetId;
    }

    async listCustomModelOptions(
      resourceOwnerId: string | undefined
    ): Promise<CustomModelOption[]> {
      return this.lambda(
        "custom_model:list-options",
        this.injectOptionalFields(
          {},
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        yup.array(customModelOptionSchema).defined()
      );
    }

    async listCustomModelsWithOffset(
      size: number,
      cursor: string,
      resourceOwnerId?: string,
      region?: string
    ): Promise<PaginatedWithOffsetBriefCustomModel> {
      return this.lambda(
        "custom_model:list",
        this.injectOptionalFields(
          {
            page_args: {
              type: "offset",
              size,
              cursor,
            },
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        paginatedWithOffsetBriefCustomModelSchema,
        null,
        region !== undefined ? { region } : undefined
      );
    }

    async listCustomModelImages(
      customModelId: string,
      size: number,
      offset: number,
      state?: string[],
      region?: string,
      infoFields?: string[],
      queryFsl?: boolean,
      groupId?: string
    ): Promise<PaginatedCustomModelImage> {
      return this.lambda(
        "custom_model:list-images",
        this.injectOptionalFields(
          {
            page_args: {
              size,
              offset,
            },
            custom_model_id: customModelId,
            info_fields: infoFields,
            query_fsl: queryFsl === true,
          },
          {
            state: state,
            group_id: groupId,
          }
        ),
        paginatedCustomModelImageSchema,
        null,
        region !== undefined ? { region } : undefined
      );
    }

    async listAllCustomModels(
      size: number,
      cursor: string,
      region?: string
    ): Promise<PaginatedWithOffsetBriefCustomModel> {
      return this.lambda(
        "custom_model:list-all",
        this.injectOptionalFields(
          {
            page_args: {
              type: "offset",
              size,
              cursor,
            },
          },
          {
            sorted_by: "created_at",
          }
        ),
        paginatedWithOffsetBriefCustomModelSchema,
        null,
        region !== undefined ? { region } : undefined
      );
    }

    async enableStandardModel(customModelId: string): Promise<void> {
      return this.lambda(
        "custom_model:enable-standard-model",
        this.injectOptionalFields(
          {
            custom_model_id: customModelId,
          },
          {}
        )
      );
    }

    async sendNotification(
      eventType: string,
      customModelId: string,
      customModelUrl: string
    ): Promise<void> {
      return this.lambda(
        "custom_model:send-notification",
        this.injectOptionalFields(
          {
            event_type: eventType,
            custom_model_id: customModelId,
            custom_model_url: customModelUrl,
          },
          {}
        )
      );
    }
  };
}
