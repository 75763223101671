import * as yup from "yup";

export const auditLogJobSchema = yup
  .object({
    url: yup.string().nullable(),
    status: yup
      .string()
      .oneOf(["Pending", "Processing", "Completed", "Failed"])
      .defined(),
    year: yup.number().required(),
    month: yup.number().required(),
  })
  .camelCase();

export enum AuditLogJobStatusType {
  pending = "Pending",
  processing = "Processing",
  completed = "Completed",
  failed = "Failed",
}

export type AuditLogJob = yup.InferType<typeof auditLogJobSchema>;
