import * as React from "react";
import { useSelector } from "react-redux";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { WorkspaceIntegrationSection } from "../components/WorkspaceIntegration";
import { WorkspaceTab } from "../components/WorkspaceNavBar";
import { useOAuthCredential } from "../hooks/oauth";
import { useUnsafeParams } from "../hooks/params";
import { useCommonWorkspaceContainerState } from "../hooks/workspace";
import { useWorkspaceWebhookResource } from "../hooks/workspaceWebhook";
import {
  useGoogleSheetIntegration,
  useSharePointIntegration,
} from "../hooks/workspace_integration";
import { PathParam } from "../models";
import { RootState } from "../redux/types";
import HeaderContainer from "./Header";
import { WorkspaceNavBarLayoutContainer as WorkspaceNavBarLayout } from "./WorkspaceNavBarLayout";

function useWorkspaceIntegrationContainer() {
  const { workspaceId } = useUnsafeParams<PathParam>();
  const { workspace } = useCommonWorkspaceContainerState(workspaceId);
  const { workspaceWebhooks } = useSelector((state: RootState) => {
    return {
      workspaceWebhooks: state.workspaceWebhook.workspaceWebhooks,
    };
  });

  const { queryWorkspaceWebhook } = useWorkspaceWebhookResource();

  React.useEffect(() => {
    queryWorkspaceWebhook(workspaceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.useMemo(
    () => ({
      isLoading: workspace.state === "loading",
      workspace,
      workspaceWebhooks,
    }),
    [workspace, workspaceWebhooks]
  );
}

export function WorkspaceIntegrationContainer() {
  const googleSheetIntegrationProps = useGoogleSheetIntegration();
  const oauthProps = useOAuthCredential();
  const sharePointIntegrationProps = useSharePointIntegration();

  const { isLoading, workspace, workspaceWebhooks } =
    useWorkspaceIntegrationContainer();

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={isLoading} />
      <Main hasTop={true}>
        {workspace.state === "error" && (
          <ErrorPlaceholder messageId="common.fail_to_fetch_workspace" />
        )}
        {workspace.state === "success" && (
          <WorkspaceNavBarLayout selectedTab={WorkspaceTab.Integrations}>
            <WorkspaceIntegrationSection
              googleSheetIntegrationProps={googleSheetIntegrationProps}
              oauthProps={oauthProps}
              sharePointIntegrationProps={sharePointIntegrationProps}
              workspace={workspace.data}
              workspaceWebhooks={workspaceWebhooks}
            />
          </WorkspaceNavBarLayout>
        )}
      </Main>
    </Layout>
  );
}

export default React.memo(WorkspaceIntegrationContainer);
