import * as yup from "yup";

export const planSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  type: yup.string().defined(),
});

export const planListSchema = yup.array(planSchema).defined();

export type Plan = yup.InferType<typeof planSchema>;

export type SubscribablePlan = "basic" | "quota_plan";

export function isSubscribablePlan(plan: string): plan is SubscribablePlan {
  return ["basic", "quota_plan"].includes(plan);
}
