import React from "react";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useCustomModelActionCreator } from "../actions/customModel";
import CustomModelLabel from "../components/CustomModelLabel";
import CustomModelRemarkModal from "../components/CustomModelRemarkModal";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { UserFeatureFlag } from "../constants";
import { MinSampleImageForTraining } from "../constants/customModel";
import HeaderContainer from "../containers/Header";
import {
  useAutoReloadCustomModel,
  useCommonCustomModelContainerState,
} from "../hooks/custom_model";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { ConfirmModalType } from "../types/confirmation";
import { openLabeller } from "../utils/labeller";
import {
  CustomModelNavBarLayout,
  CustomModelNavTabKey,
} from "./CustomModelNavBarLayout";

type PathParam = {
  customModelId: string;
};

function _CustomModelLabelContainer() {
  const { customModelId } = useUnsafeParams<PathParam>();

  const toast = useToast();
  const { triggerModelTraining, requestCustomModelTraining } =
    useCustomModelActionCreator();
  const containerState = useCommonCustomModelContainerState(customModelId);
  const isCustomModelTrainingEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.CustomModelTraining
    )
  );

  const { requestUserConfirmation } = useConfirmModalActionCreator();

  const [isRemarkModalOpened, setIsRemarkModalOpened] = React.useState(false);

  const containerStateRef = React.useRef(containerState);
  containerStateRef.current = containerState;

  const onOpenLabeller = React.useCallback(async () => {
    if (containerStateRef.current.state === "success") {
      openLabeller(containerStateRef.current.customModel.id);
    }
  }, []);

  const isTriggeringModelTrainingRef = React.useRef(false);
  const [isTriggeringModelTraining, setITriggeringModelTraining] =
    React.useState(false);
  const doTriggerModelTraining = React.useCallback(async () => {
    if (isTriggeringModelTrainingRef.current) {
      return;
    }

    if (containerStateRef.current.state === "success") {
      const { customModel } = containerStateRef.current;

      if (customModel.noOfSampleImages < MinSampleImageForTraining) {
        await requestUserConfirmation(
          {
            titleId: "custom_model_label.too_few_sample.title",
            messageId: "custom_model_label.too_few_sample.message",
            actionId: "custom_model_label.too_few_sample.understand",
            type: ConfirmModalType.Notify,
            messageValues: { count: MinSampleImageForTraining },
          },
          false
        );
        return;
      }

      isTriggeringModelTrainingRef.current = true;
      setITriggeringModelTraining(true);
      try {
        await triggerModelTraining(customModel.id);
      } catch (e) {
        console.error(e);
        toast.error("custom_model_label.train.error");
      } finally {
        isTriggeringModelTrainingRef.current = false;
        setITriggeringModelTraining(false);
      }
    }
  }, [triggerModelTraining, requestUserConfirmation, toast]);

  const onRequestTraining = React.useCallback(() => {
    setIsRemarkModalOpened(true);
  }, []);

  const dismissRemarkModal = React.useCallback(() => {
    setIsRemarkModalOpened(false);
  }, []);

  const doRequestTraining = React.useCallback(
    (remark: string) => {
      setIsRemarkModalOpened(false);

      if (containerStateRef.current.state !== "success") {
        return;
      }

      (async () => {
        try {
          await requestCustomModelTraining(remark || "None");
          await requestUserConfirmation(
            {
              titleId: "custom_model_label.request_is_sent.title",
              messageId: "custom_model_label.request_is_sent.message",
              actionId: "custom_model_label.request_is_sent.dismiss",
              type: ConfirmModalType.Notify,
            },
            false
          );
        } catch (e) {
          toast.error("custom_model_label.request_training.error");
        }
      })();
    },
    [requestUserConfirmation, requestCustomModelTraining, toast]
  );

  useAutoReloadCustomModel(
    containerState.state === "success" ? containerState.customModel.id : null,
    containerState.state === "success" && containerState.isTrainingInProgress,
    30 * 1000
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
        ) : containerState.state === "success" ? (
          <CustomModelNavBarLayout
            selectedTab={CustomModelNavTabKey.Label}
            customModel={containerState.customModel}
          >
            <CustomModelLabel
              isTrainingInProgress={
                containerState.isTrainingInProgress || isTriggeringModelTraining
              }
              isTrainingRequested={
                !isCustomModelTrainingEnabled &&
                containerState.customModel.config.remark !== ""
              }
              openLabeller={onOpenLabeller}
              triggerModelTraining={
                isCustomModelTrainingEnabled
                  ? doTriggerModelTraining
                  : onRequestTraining
              }
              isTrainingFailed={containerState.customModel.isTrainingFailed}
              startTrainingAt={containerState.customModel.startTrainingAt}
            />
          </CustomModelNavBarLayout>
        ) : null}
      </Main>
      <CustomModelRemarkModal
        isOpen={isRemarkModalOpened}
        onSubmit={doRequestTraining}
        onCancel={dismissRemarkModal}
      />
    </Layout>
  );
}

export const CustomModelLabelContainer = React.memo(_CustomModelLabelContainer);
export default CustomModelLabelContainer;
