import * as yup from "yup";

import { pageInfoWithOffsetSchema } from "./pageInfo";
import { briefWorkerTokenSchema } from "./workerToken";

export interface UsageRange {
  start: string;
  end: string;
}

export type RequestLogApiName =
  | "extract"
  | "extract_fields"
  | "extract_receipt_info"
  | "detect_documents";

export const countByTokenSchema = yup
  .object({
    id: yup.string().nullable(),
    count: yup.number().optional().nullable(),
    unclassifiedCount: yup.number().optional().nullable(),
    totalCount: yup.number().optional().nullable(),
    syncCount: yup.number().optional().nullable(),
    asyncCount: yup.number().optional().nullable(),
    name: yup.string().defined(),
    isRevoked: yup.boolean().required(),
  })
  .camelCase();

export const usageCountSchema = yup
  .object({
    name: yup.string().required(),
    entityId: yup.string().defined().min(0),
    count: yup.number().optional(),
    totalCount: yup.number().optional(),
    syncCount: yup.number().optional(),
    asyncCount: yup.number().optional(),
    unclassifiedCount: yup.number().optional(),
    countByToken: yup.array(countByTokenSchema).defined(),
  })
  .camelCase();

export type UsageCount = yup.InferType<typeof usageCountSchema>;

export const usageSchema = yup
  .object({
    api: yup.string().required(),
    counts: yup.array(usageCountSchema).defined(),
    total: yup.number().required(),
  })
  .camelCase();

export type Usage = yup.InferType<typeof usageSchema>;

export const requestLogSchema = yup
  .object({
    id: yup.string().required(),
    createdAt: yup.date().required(),
    apiName: yup.string().required(),
    entityId: yup.string().defined(),
    entityName: yup.string().defined(),
    workerToken: briefWorkerTokenSchema.defined().nullable(),
    executionType: yup.string().optional().nullable(),
  })
  .camelCase();

export type RequestLog = yup.InferType<typeof requestLogSchema>;

export const teamUsageDetailSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    requestLogs: yup.array(requestLogSchema).required(),
  })
  .camelCase();

export type teamUsageDetail = yup.InferType<typeof teamUsageDetailSchema>;
