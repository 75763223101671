import * as React from "react";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useFormActionCreator } from "../actions/form";
import DataNotSavedPrompt from "../components/DataNotSavedPrompt";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import FormatterActionSelector from "../components/FormatterActionSelector";
import FormatterEditor from "../components/FormatterEditor";
import FormatterStepEditor from "../components/FormatterStepEditor";
import { Layout, Main, Right, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import {
  FormEditorProvider,
  buildFormSettings,
  useFormEditor,
} from "../contexts/formEditor";
import {
  FormatterEditorProvider,
  useFormatterEditor,
} from "../contexts/formatterEditor";
import { FSLCustomModelEditorProvider } from "../contexts/fslCustomModelEditor";
import errors, { FOCRError } from "../errors";
import { useCommonCustomModelContainerState } from "../hooks/custom_model";
import { useExtractorFieldSchema } from "../hooks/extractorFieldSchema";
import { useUnsafeParams } from "../hooks/params";
import { useToast } from "../hooks/toast";
import { CustomModel } from "../types/customModel";
import { FormatterStorage } from "../types/mappers/formatter";
import { FSLNavBarLayout, FSLTab } from "./FSLNavBarLayout";
import HeaderContainer from "./Header";

type PathParam = {
  customModelId: string;
};

interface CustomModelFormatterEditorProviderProps {
  children: React.ReactNode;
  customModel: CustomModel;
}

function _CustomModelFormatterEditorProvider(
  props: CustomModelFormatterEditorProviderProps
) {
  const { children, customModel } = props;
  const { getExtractorFieldSchema } = useExtractorFieldSchema();
  const { form, updateForm, setIsFailedToFetchForm, getForm } = useFormEditor();

  const { handleConflict } = useConfirmModalActionCreator();

  const { saveForm } = useFormActionCreator();

  const toast = useToast();

  const onSaveFormatter = React.useCallback(
    (formatterStorage: FormatterStorage) => {
      if (form !== undefined) {
        const formSetting = buildFormSettings(form, {
          formatter: formatterStorage,
        });
        updateForm(formSetting);
      }
      return handleConflict(
        async () => {
          await saveForm();
        },
        async () => {
          await saveForm(true);
        },
        {
          titleId: "form_editor.form_modifed_prompt.title",
          messageId: "form_editor.form_modifed_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      )
        .then(() => {
          toast.success("form_editor.form_is_saved");
        })
        .catch((e: unknown) => {
          if (e instanceof FOCRError) {
            toast.error(e.messageId, undefined, e.detail);
          } else {
            console.error(e);
            toast.error(
              errors.UnknownError.messageId,
              undefined,
              errors.UnknownError.detail
            );
          }
          throw e;
        });
    },
    [form, handleConflict, saveForm, toast, updateForm]
  );

  React.useEffect(() => {
    const formId = customModel.formID;
    if (formId !== null && (!form || form.id !== formId)) {
      getForm(formId).catch(() => {
        setIsFailedToFetchForm(true);
      });
    }
  }, [customModel.formID, form, getForm, setIsFailedToFetchForm]);

  React.useEffect(() => {
    getExtractorFieldSchema(customModel.id);
  }, [customModel.id, getExtractorFieldSchema]);

  return (
    <FormatterEditorProvider
      extractorId={customModel.id}
      formatterSource={form?.config.formatter}
      onSaveFormatter={onSaveFormatter}
    >
      {children}
    </FormatterEditorProvider>
  );
}

const CustomModelFormatterEditorProvider = React.memo(
  _CustomModelFormatterEditorProvider
);

const CustomModelFormatterContainerImpl = React.memo(() => {
  const { isAddingStep, selectedStepIndex, steps, isFormatterNotSaved } =
    useFormatterEditor();
  const { discardForm } = useFormActionCreator();
  const rightVisible = isAddingStep || selectedStepIndex >= 0;

  React.useEffect(() => {
    return () => {
      discardForm();
    };
  }, [discardForm]);

  return (
    <>
      <Main hasTop={true}>
        <FSLNavBarLayout selectedTab={FSLTab.Formatter}>
          <FormatterEditor />
        </FSLNavBarLayout>
        <DataNotSavedPrompt
          isDataChanged={isFormatterNotSaved}
          titleTextId="extractor_setting.form_not_saved_prompt.title"
          messageTextId="extractor_setting.form_not_saved_prompt.save_warning"
          backTextId="extractor_setting.form_not_saved_prompt.go_back"
          continueTextId="extractor_setting.form_not_saved_prompt.leave_page"
        />
      </Main>
      {rightVisible && (
        <Right hasTop={true}>
          {isAddingStep && <FormatterActionSelector />}
          {selectedStepIndex >= 0 && (
            <FormatterStepEditor
              step={steps[selectedStepIndex]}
              key={selectedStepIndex}
            />
          )}
        </Right>
      )}
    </>
  );
});

function _CustomModelFormatterContainer() {
  const { customModelId } = useUnsafeParams<PathParam>();

  const containerState = useCommonCustomModelContainerState(customModelId);

  return (
    <>
      <LoadingModal isOpen={containerState.state === "loading"} />
      {containerState.state === "error" ? (
        <Layout>
          <Top>
            <HeaderContainer />
          </Top>
          <Main hasTop={true}>
            <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
          </Main>
        </Layout>
      ) : containerState.state === "success" ? (
        <Layout>
          <Top>
            <HeaderContainer />
          </Top>
          <FSLCustomModelEditorProvider
            customModelId={customModelId}
            key={customModelId}
          >
            <FormEditorProvider>
              <CustomModelFormatterEditorProvider
                customModel={containerState.customModel}
              >
                <CustomModelFormatterContainerImpl />
              </CustomModelFormatterEditorProvider>
            </FormEditorProvider>
          </FSLCustomModelEditorProvider>
        </Layout>
      ) : null}
    </>
  );
}

export const CustomModelFormatterContainer = React.memo(
  _CustomModelFormatterContainer
);
export default CustomModelFormatterContainer;
