import {
  ActionButton,
  Dropdown,
  IDropdownOption,
  IDropdownProps,
  IRenderFunction,
  Icon,
  TooltipHost,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import produce from "immer";
import React, { useCallback, useMemo, useRef } from "react";

import { CustomModelLabelSchemaTypeDefintion } from "../../constants/customModelLabelSchema";
import { useLocale } from "../../contexts/locale";
import {
  CustomModelLabelSchema,
  CustomModelLabelSchemaType,
  CustomModelLabelSchemaTypeFormatType,
} from "../../types/customModel";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

function useLocalizedOptions() {
  const { localized } = useLocale();

  return useMemo(
    () => ({
      typeOptions: Object.keys(CustomModelLabelSchemaTypeDefintion).flatMap(
        item =>
          CustomModelLabelSchemaTypeDefintion[
            item as CustomModelLabelSchemaType
          ].hidden
            ? []
            : {
                key: item,
                text: localized(
                  `custom_model_editor.right_bar.label.edit_pane.type.${item}`
                ),
              }
      ),

      formatOptionsByType: Object.fromEntries(
        Object.entries(CustomModelLabelSchemaTypeDefintion).map(
          ([type, value]): [
            CustomModelLabelSchemaType,
            IDropdownOption[] | undefined
          ] => [
            type as CustomModelLabelSchemaType,
            value.format?.map(item => ({
              key: item,
              text: localized(
                `custom_model_editor.right_bar.label.edit_pane.type.${type}.${item}`
              ),
            })),
          ]
        )
      ),
    }),
    [localized]
  );
}

interface Props {
  index: number;
  labelSchema: CustomModelLabelSchema;
  error: string | null;

  onChange: (index: number, value: CustomModelLabelSchema) => void;
  onRemove: (index: number) => void;
}

function onRenderFormatDropdownLabel(
  props?: IDropdownProps,
  defaultRender?: IRenderFunction<IDropdownProps>
) {
  return (
    <div className={styles["format-label"]}>
      <span>{defaultRender?.(props)}</span>
      <TooltipHost
        content={
          <FormattedMessage id="custom_model_editor.right_bar.label.edit_pane.default_formating.label.tooltip" />
        }
      >
        <Icon className={styles["info-icon"]} iconName="Info" />
      </TooltipHost>
    </div>
  );
}

const CustomModelEditorFieldEditPane = (props: Props) => {
  const { index, labelSchema, error, onChange, onRemove } = props;
  const indexRef = useRef(index);
  indexRef.current = index;

  const { localized } = useLocale();

  const { formatOptionsByType, typeOptions } = useLocalizedOptions();

  const onChangeName = useCallback(
    (
      _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      if (value == null) {
        return;
      }
      onChange(
        indexRef.current,
        produce(labelSchema, draft => {
          draft.name = value;
          return draft;
        })
      );
    },
    [labelSchema, onChange]
  );

  const onChangeType = useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (
        option == null ||
        !(option.key in CustomModelLabelSchemaTypeDefintion)
      ) {
        return;
      }
      onChange(
        indexRef.current,
        produce(labelSchema, draft => {
          draft.type = option.key as CustomModelLabelSchemaType;
          const newFormatOptions =
            CustomModelLabelSchemaTypeDefintion[draft.type].format ?? [];

          if (newFormatOptions[0]) {
            draft.config = {
              ...draft.config,
              format: newFormatOptions[0],
            };
          }
          return draft;
        })
      );
    },
    [labelSchema, onChange]
  );

  const onChangeFormat = useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      const availableFormats: readonly string[] =
        CustomModelLabelSchemaTypeDefintion[labelSchema.type].format || [];

      if (
        option == null ||
        typeof option.key === "number" ||
        !availableFormats.includes(option.key)
      ) {
        return;
      }

      onChange(
        indexRef.current,
        produce(labelSchema, draft => {
          draft.config = {
            ...draft.config,
            format: option.key as CustomModelLabelSchemaTypeFormatType,
          };
          return draft;
        })
      );
    },
    [labelSchema, onChange]
  );

  const onRemoveClicked = useCallback(() => {
    onRemove(indexRef.current);
  }, [onRemove]);

  const showFormatDropdown =
    CustomModelLabelSchemaTypeDefintion[labelSchema.type].format !== undefined;

  return (
    <div className={styles["container"]}>
      <div className={styles["input-block"]}>
        <TextField
          labelId={
            "custom_model_editor.right_bar.label.edit_pane.field_name.label"
          }
          className={styles["text-field"]}
          placeholderId={
            "custom_model_editor.right_bar.label.edit_pane.field_name.placeholder"
          }
          errorMessageId={error ?? undefined}
          value={labelSchema.name}
          onChange={onChangeName}
        />
      </div>
      <div className={styles["input-block"]}>
        <Dropdown
          label={localized(
            "custom_model_editor.right_bar.label.edit_pane.type.label"
          )}
          options={typeOptions}
          selectedKey={labelSchema.type}
          onChange={onChangeType}
        />
      </div>
      {showFormatDropdown && (
        <div className={styles["input-block"]}>
          <Dropdown
            label={localized(
              `custom_model_editor.right_bar.label.edit_pane.${labelSchema.type}.default_format_label`
            )}
            options={formatOptionsByType[labelSchema.type] ?? []}
            selectedKey={labelSchema.config?.format}
            onChange={onChangeFormat}
            onRenderLabel={onRenderFormatDropdownLabel}
          />
        </div>
      )}
      <div>
        <ActionButton onClick={onRemoveClicked}>
          <FormattedMessage id="custom_model_editor.right_bar.label.edit_pane.discard_button" />
        </ActionButton>
      </div>
    </div>
  );
};

export default CustomModelEditorFieldEditPane;
