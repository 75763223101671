import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { useAppActionCreator } from "../actions/app";
import { useResourceOwnerActionCreator } from "../actions/resourceOwner";
import { useUserActionCreator } from "../actions/user";
import LoadingModal from "../components/LoadingModal";
import LoginChangePasswordForm from "../components/LoginChangePasswordForm";
import LoginForm from "../components/LoginForm";
import { ToastStyleUpdater } from "../components/Toast";
import { ToastStyle } from "../contexts/toastStyle";
import errors, { FOCRError } from "../errors";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";

type ValidationResult = {
  isValid: boolean;
  emailErrorMessageId?: string;
  passwordErrorMessageId?: string;
};

type FieldValidator = (email: string, password: string) => ValidationResult;

type ErrorMessageId = string | undefined;

function existenceCheck(email: string, password: string): ValidationResult {
  const result: ValidationResult = { isValid: true };
  let hasMissingFields = false;

  if (!email) {
    hasMissingFields = true;
    result.emailErrorMessageId = "common.email.empty";
  }

  if (!password) {
    hasMissingFields = true;
    result.passwordErrorMessageId = "common.password.empty";
  }

  if (hasMissingFields) {
    return {
      ...result,
      isValid: false,
    };
  } else {
    return result;
  }
}

function useLoginForm() {
  const [email, setEmail] = useState("");
  const [emailErrorMessageId, setEmailErrorMessageId] =
    useState<ErrorMessageId>(undefined);

  const [password, setPassword] = useState("");
  const [passwordErrorMessageId, setPasswordErrorMessageId] =
    useState<ErrorMessageId>(undefined);

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const emailRef = useRef(email);
  const passwordRef = useRef(password);
  const isLoggingInRef = useRef(isLoggingIn);

  const [isPasswordExpired, setIsPasswordExpired] = useState(false);

  emailRef.current = email;
  passwordRef.current = password;
  isLoggingInRef.current = isLoggingIn;

  const { login } = useUserActionCreator();
  const { checkIsPaymentRequired } = useResourceOwnerActionCreator();
  const { disablePaymentRequiredToast } = useAppActionCreator();
  const toast = useToast();
  const navigate = useNavigate();

  React.useEffect(() => {
    disablePaymentRequiredToast();
  }, [disablePaymentRequiredToast]);

  const onEmailChange = useCallback((value: string) => {
    setEmail(value);
    setEmailErrorMessageId(undefined);
  }, []);

  const onPasswordChange = useCallback((value: string) => {
    setPassword(value);
    setPasswordErrorMessageId(undefined);
  }, []);

  const validateInput = useCallback(
    (email: string, password: string): boolean => {
      const validators: FieldValidator[] = [existenceCheck];

      for (const validator of validators) {
        const result = validator(email, password);
        if (!result.isValid) {
          const { emailErrorMessageId, passwordErrorMessageId } = result;

          setEmailErrorMessageId(emailErrorMessageId);
          setPasswordErrorMessageId(passwordErrorMessageId);
          return false;
        }
      }

      return true;
    },
    []
  );

  const invitationCode = useSelector<RootState, string | undefined>(
    state => state.app.invitationCode
  );

  const loginAndChangeHistory = useCallback(
    async (email, password, newPassword = undefined) => {
      await login(email, password, newPassword);
      const isPaymentRequired = await checkIsPaymentRequired();
      disablePaymentRequiredToast();
      if (invitationCode) {
        navigate(`/invitation?invitation-code=${invitationCode}`);
      } else if (isPaymentRequired) {
        navigate("/payment/");
      }
    },
    [
      login,
      checkIsPaymentRequired,
      disablePaymentRequiredToast,
      invitationCode,
      navigate,
    ]
  );

  const onSubmit = useCallback(async () => {
    if (
      isLoggingInRef.current ||
      !validateInput(emailRef.current, passwordRef.current)
    ) {
      return;
    }

    setEmailErrorMessageId(undefined);
    setPasswordErrorMessageId(undefined);
    setIsLoggingIn(true);

    try {
      await loginAndChangeHistory(emailRef.current, passwordRef.current);
    } catch (e) {
      const error = e as FOCRError;

      if (error !== errors.PasswordExpired) {
        toast.error(error.messageId);
      } else {
        setIsPasswordExpired(true);
      }

      if (error === errors.UserNotFound) {
        setEmailErrorMessageId(undefined);
        setPasswordErrorMessageId(undefined);
      }

      setIsLoggingIn(false);
    }
  }, [validateInput, toast, loginAndChangeHistory]);

  const onLoginChangePasswordSubmit = useCallback(
    async (password, newPassword): Promise<void | FOCRError> => {
      setIsLoggingIn(true);
      try {
        await loginAndChangeHistory(emailRef.current, password, newPassword);
        setIsLoggingIn(false);
      } catch (e) {
        setIsLoggingIn(false);
        if (e instanceof FOCRError) {
          return e;
        } else {
          return errors.UnknownError;
        }
      }
    },
    [loginAndChangeHistory, setIsLoggingIn]
  );

  return React.useMemo(
    () => ({
      email,
      emailErrorMessageId,
      onEmailChange,
      password,
      passwordErrorMessageId,
      onPasswordChange,
      onSubmit,
      isLoggingIn,
      isPasswordExpired,
      onLoginChangePasswordSubmit,
    }),
    [
      email,
      emailErrorMessageId,
      onEmailChange,
      password,
      passwordErrorMessageId,
      onPasswordChange,
      onSubmit,
      isLoggingIn,
      isPasswordExpired,
      onLoginChangePasswordSubmit,
    ]
  );
}

function _LoginContainer() {
  const {
    email,
    emailErrorMessageId,
    onEmailChange,
    password,
    passwordErrorMessageId,
    onPasswordChange,
    onSubmit,
    isPasswordExpired,
    isLoggingIn,
    onLoginChangePasswordSubmit,
  } = useLoginForm();

  return (
    <>
      <ToastStyleUpdater toastStyle={ToastStyle.Login} />
      {!isPasswordExpired && (
        <LoginForm
          email={email}
          emailErrorMessageId={emailErrorMessageId}
          onEmailChange={onEmailChange}
          password={password}
          passwordErrorMessageId={passwordErrorMessageId}
          onPasswordChange={onPasswordChange}
          onSubmit={onSubmit}
        />
      )}
      {isPasswordExpired && (
        <LoginChangePasswordForm onSubmit={onLoginChangePasswordSubmit} />
      )}
      <LoadingModal isOpen={isLoggingIn} />
    </>
  );
}

export const LoginContainer = React.memo(_LoginContainer);
export default LoginContainer;
