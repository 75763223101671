import { IButtonProps, PrimaryButton } from "@fluentui/react";
import { Values } from "@oursky/react-messageformat";
import cn from "classnames";
import React from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

export interface DangerButtonProps extends IButtonProps {
  textId?: string;
  textValues?: Values;
}

export function DangerButton(props: DangerButtonProps) {
  const { localized } = useLocale();
  const { className, textId, textValues, text, ...restProps } = props;

  const label = textId ? localized(textId, textValues) : text;

  const customStyles = {
    root: {
      whiteSpace: "nowrap",
    },
  };

  return (
    <PrimaryButton
      className={cn(className, styles["danger-button"])}
      text={label}
      styles={customStyles}
      {...restProps}
    />
  );
}
