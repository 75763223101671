import { MessageBar, MessageBarType } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import FormExtractionModeSelector from "../components/FormExtractionModeSelector";
import { Layout, Main, Right, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { OCRTest } from "../components/OCRTest";
import HeaderContainer from "../containers/Header";
import { useCommonCustomModelContainerState } from "../hooks/custom_model";
import { useExtractTest } from "../hooks/extract_test";
import { useUnsafeParams } from "../hooks/params";
import {
  CustomModelNavBarLayout,
  CustomModelNavTabKey,
} from "./CustomModelNavBarLayout";

type PathParam = {
  customModelId: string;
};

function _CustomModelTestContainerV1() {
  const { customModelId } = useUnsafeParams<PathParam>();

  const containerState = useCommonCustomModelContainerState(customModelId);

  const {
    extractWithFile,
    extractWithURL,
    isRecognizing,
    isUploading,
    testReport,
    extractionMode,
    onExtractionModeChanged,
    shouldShowFormExtractionModeSelector,
    setShouldShowFormExtractionModeSelector,
  } = useExtractTest(
    containerState.state === "success" && containerState.customModel.formID
      ? containerState.customModel.formID
      : undefined
  );

  const hasDeployedModel =
    containerState.state === "success" &&
    containerState.customModel.modelVersions.find(x => x.isActive) !==
      undefined;

  const shouldShowWarning =
    containerState.state === "success" &&
    !containerState.isFSLModel &&
    (containerState.isDeploymentInProgress || !hasDeployedModel);

  const onCloseFormExtractionModeSelector = () => {
    setShouldShowFormExtractionModeSelector(false);
  };

  const onChangeExtractionModeClicked = () => {
    setShouldShowFormExtractionModeSelector(true);
  };

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
        ) : containerState.state === "success" ? (
          <>
            <CustomModelNavBarLayout
              selectedTab={CustomModelNavTabKey.TestExtractorV1}
              customModel={containerState.customModel}
            >
              {shouldShowWarning && (
                <MessageBar messageBarType={MessageBarType.warning}>
                  <FormattedMessage
                    id={
                      containerState.isDeploymentInProgress
                        ? "custom_model_test.warn.deployment_in_progress"
                        : "custom_model_test.warn.no_deployed_model"
                    }
                  />
                </MessageBar>
              )}
              <OCRTest
                resourceName={containerState.customModel.name}
                onSelectImage={extractWithFile}
                onSelectSampleImage={extractWithURL}
                ocrTestReport={testReport}
                extractionMode={extractionMode}
                onChangeExtractionModeClicked={onChangeExtractionModeClicked}
              />
            </CustomModelNavBarLayout>
            {isRecognizing && (
              <LoadingModal
                messageId="ocr_test.extracting"
                isOpen={isRecognizing}
              />
            )}
            {isUploading && (
              <LoadingModal messageId="common.uploading" isOpen={isUploading} />
            )}
          </>
        ) : null}
      </Main>
      {shouldShowFormExtractionModeSelector && (
        <Right hasTop={true}>
          <FormExtractionModeSelector
            extractionMode={extractionMode}
            onExtractionModeChanged={onExtractionModeChanged}
            onClose={onCloseFormExtractionModeSelector}
          />
        </Right>
      )}
    </Layout>
  );
}

export const CustomModelTestContainerV1 = React.memo(
  _CustomModelTestContainerV1
);
export default CustomModelTestContainerV1;
