export async function runWithRetry<T>(
  f: () => Promise<T>,
  shouldRetry: (error: any) => boolean = () => true,
  retryCount: number = 3
): Promise<T> {
  try {
    return await f();
  } catch (error) {
    if (shouldRetry(error) && retryCount > 1) {
      return runWithRetry(f, shouldRetry, retryCount - 1);
    }
    throw error;
  }
}
