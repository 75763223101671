import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  Icon,
  Label,
  Pivot,
  PivotItem,
  PrimaryButton,
} from "@fluentui/react";
import Editor from "@monaco-editor/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React, { useCallback, useMemo, useRef, useState } from "react";

import { DEFAULT_POST_PROCESS_SCRIPT, DEFAULT_SCRIPT } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { ScriptEditorModalPayload } from "../../models";
import EditDistanceContent from "../../scriptdoc/edit_distance";
import FuzzySearchContent from "../../scriptdoc/fuzzysearch";
import { DetailedForm } from "../../types/form";
import { DetailFormGroup } from "../../types/formGroup";
import {
  ScriptType,
  isScriptEditorModalPayloadForDetectionRegionField,
  isScriptEditorModalPayloadForForm,
} from "../../types/scriptEditorModalPayload";
import {
  PreferenceKey,
  getPreference,
  setPreference,
} from "../../utils/preference";
import DetectionRegionAdvancedSetting, {
  DetectionRegionAdvancedSettingHandle,
} from "../DetectionRegionAdvancedSetting";
import { DetectionRegionWaterMarkSetting } from "../DetectionRegionWaterMarkSetting";
import {
  AvaiableFunction,
  JavaScriptIoContentForDetectionRegionField,
  JavaScriptIoContentForPostProcessScript,
} from "../ScriptEditorInstruction";
import {
  typeDefinitionsForDetectionRegionField,
  typeDefinitionsForPostProcessScript,
} from "./builtTypeDef";
import useConfigMonaco from "./configMonaco";
import styles from "./styles.module.scss";

interface Props {
  onCloseModal: () => void;
  payload: ScriptEditorModalPayload;
}

enum Tab {
  JS_IO = "JS_IO",
  AVAILABLE_FUNCTION = "AVAILABLE_FUNCTION",
}

function getLastSelectedTab() {
  const lastSelectedTab = getPreference(PreferenceKey.lastSelectedScriptTab);
  return lastSelectedTab === null ? Tab.JS_IO : (lastSelectedTab as Tab);
}

function getIsScriptTabHidden() {
  const isScriptTabHidden = getPreference(PreferenceKey.isScriptTabHidden);
  return isScriptTabHidden === null ? false : JSON.parse(isScriptTabHidden);
}

export const useScriptEditorHandle = ({
  form,
  formGroup,
  scriptType,
  onSaveScript,
}: {
  form?: DetailedForm;
  formGroup?: DetailFormGroup;
  scriptType: ScriptType;
  onSaveScript: (newScript: string) => void;
}): {
  onOpenScriptModal: () => void;
  props?: Props;
} => {
  const [payload, setPayload] = useState<ScriptEditorModalPayload | null>(null);

  const onOpenScriptModal = useCallback(() => {
    if (form != null) {
      setPayload({
        form: form,
        scriptType: scriptType,
        onSave: onSaveScript,
      });
    } else if (formGroup != null) {
      setPayload({
        formGroup: formGroup,
        scriptType: scriptType,
        onSave: onSaveScript,
      });
    }
  }, [form, formGroup, scriptType, onSaveScript]);

  const onCloseScriptModal = useCallback(() => {
    setPayload(null);
  }, []);

  return {
    onOpenScriptModal,
    props:
      payload != null
        ? {
            payload,
            onCloseModal: onCloseScriptModal,
          }
        : undefined,
  };
};

const ScriptEditorModal = React.memo((props: Props) => {
  const { payload, onCloseModal } = props;

  const isForDetectionRegionField =
    isScriptEditorModalPayloadForDetectionRegionField(payload);

  useConfigMonaco(
    isForDetectionRegionField
      ? typeDefinitionsForDetectionRegionField
      : typeDefinitionsForPostProcessScript
  );

  const getScriptValue = React.useRef<(() => string) | null>(null);
  const [isTabHidden, setIsTabHidden] = useState<boolean>(
    getIsScriptTabHidden()
  );
  const { localized } = useLocale();
  const [extras, setExtras] = useState<any>(
    isScriptEditorModalPayloadForDetectionRegionField(payload)
      ? payload.field.extras
      : undefined
  );

  const advancedSettingsRef =
    useRef<DetectionRegionAdvancedSettingHandle>(null);
  const toggleIsTabHidden = useCallback(() => {
    const nextIsTabHidden = !isTabHidden;
    setIsTabHidden(nextIsTabHidden);
    setPreference(
      PreferenceKey.isScriptTabHidden,
      JSON.stringify(nextIsTabHidden)
    );
  }, [isTabHidden]);

  const onSave = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (getScriptValue.current) {
        if (isScriptEditorModalPayloadForDetectionRegionField(payload)) {
          payload.onSave(
            getScriptValue.current(),
            advancedSettingsRef.current
              ? advancedSettingsRef.current.getParams()
              : undefined,
            extras
          );
        } else {
          payload.onSave(getScriptValue.current());
        }
        onCloseModal();
      }
    },
    [onCloseModal, payload, extras]
  );

  const handleEditorDidMount = useCallback(editor => {
    getScriptValue.current = editor.getValue.bind(editor);
  }, []);

  const onTabClick = useCallback(
    (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
      if (!item || !ev || !item.props.itemKey) {
        return;
      }

      ev.stopPropagation();
      ev.preventDefault();

      setPreference(PreferenceKey.lastSelectedScriptTab, item.props.itemKey);
    },
    []
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        isForDetectionRegionField
          ? "form_inspector.detection_region_field_edit_script"
          : payload.scriptType === "transform_response_script"
          ? "script_editor_modal.title.edit_transform_response_script"
          : "script_editor_modal.title.edit_post_processing_script"
      ),
    }),
    [localized, isForDetectionRegionField, payload]
  );

  const modalProps: IModalProps = useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  const script = useMemo(() => {
    if (isScriptEditorModalPayloadForDetectionRegionField(payload))
      return payload.field.params?.code ?? DEFAULT_SCRIPT;
    else if (isScriptEditorModalPayloadForForm(payload))
      switch (payload.scriptType) {
        case "post_process_script":
          return (
            payload.form.config.post_process_script ??
            DEFAULT_POST_PROCESS_SCRIPT
          );
        case "transform_response_script":
          return (
            payload.form.config.transform_response_script ??
            DEFAULT_POST_PROCESS_SCRIPT
          );
      }
    else
      switch (payload.scriptType) {
        case "post_process_script":
          return (
            payload.formGroup.config.post_process_script ??
            DEFAULT_POST_PROCESS_SCRIPT
          );
        case "transform_response_script":
          return (
            payload.formGroup.config.transform_response_script ??
            DEFAULT_POST_PROCESS_SCRIPT
          );
      }
  }, [payload]);

  return (
    <Dialog
      hidden={false}
      onDismiss={onCloseModal}
      maxWidth={1200}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <form className={styles["container"]} onSubmit={onSave}>
        <Editor
          className={classnames(styles["editor-container"], {
            [styles["editor-expanded"]]: isTabHidden,
          })}
          value={script}
          onMount={handleEditorDidMount}
          language="javascript"
          options={{
            fontSize: 16,
            minimap: {
              enabled: false,
            },
            readOnly: !hasPermissionToEditResource,
          }}
        />

        <div className={styles["section-toggle"]} onClick={toggleIsTabHidden}>
          <Icon
            className={classnames({
              [styles["expanded"]]: !isTabHidden,
            })}
            iconName="ChevronDown"
          />
          <Label>
            <FormattedMessage id="scriptdoc.instruction.toggle" />
          </Label>
        </div>

        {!isTabHidden && (
          <Pivot
            onLinkClick={onTabClick}
            defaultSelectedKey={getLastSelectedTab()}
          >
            <PivotItem
              headerText={localized(
                "form_inspector.detection_region_field_script_editor.js_io_tab_title"
              )}
              itemKey={Tab.JS_IO}
            >
              <div className={styles["tab-content"]}>
                {isForDetectionRegionField ? (
                  <JavaScriptIoContentForDetectionRegionField />
                ) : (
                  <JavaScriptIoContentForPostProcessScript />
                )}
              </div>
            </PivotItem>
            <PivotItem
              headerText={localized(
                "form_inspector.detection_region_field_script_editor.function_tab_title"
              )}
              itemKey={Tab.AVAILABLE_FUNCTION}
            >
              <div className={styles["tab-content"]}>
                <AvaiableFunction {...FuzzySearchContent} />
                <AvaiableFunction {...EditDistanceContent} />
              </div>
            </PivotItem>
          </Pivot>
        )}
        {isScriptEditorModalPayloadForDetectionRegionField(payload) && (
          <>
            <div className={styles["water-mark-setting"]}>
              <DetectionRegionWaterMarkSetting
                onChange={setExtras}
                extras={payload.field.extras}
              />
            </div>
            <DetectionRegionAdvancedSetting
              fieldType={payload.field.type}
              fieldParams={payload.field.params}
              selectedDetectionRegionId={payload.selectedDetectionRegionId}
              fieldIndex={payload.index}
              ref={advancedSettingsRef}
            />
          </>
        )}

        <DialogFooter>
          <DefaultButton
            onClick={onCloseModal}
            text={localized(
              hasPermissionToEditResource ? "common.cancel" : "common.close"
            )}
          />
          {hasPermissionToEditResource && (
            <PrimaryButton type="submit" text={localized("common.ok")} />
          )}
        </DialogFooter>
      </form>
    </Dialog>
  );
});

export default ScriptEditorModal;
