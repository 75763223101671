.main-field-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 7px 7px 7px 12px;

  width: 100%;
  height: 46px;

  background: rgba(37, 208, 177, 0.1);
  border-radius: 4px;

  margin-top: 12px;
  margin-bottom: 10px;

  .main-field-item-name {
    font-style: bold;
    font-weight: 600;

    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: flex-end;

    color: #25d0b1;
  }

  .main-field-item-type {
    font-style: normal;
    font-weight: 400;

    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: flex-end;

    color: #25d0b1;
  }
}

.field-item {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 12px;
  gap: 10px;

  width: 100%;
  height: 52px;

  background: #faf9f8;
  border: 1px solid #edebe9;
  border-radius: 4px;
  margin-bottom: 10px;

  .field-item-name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #201f1e;
  }

  .field-item-type {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #201f1e;

    opacity: 0.4;
  }
}
