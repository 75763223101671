import * as yup from "yup";

export const formatterInputSelectionFieldSchema = yup
  .object({
    selector: yup.string().defined(),
  })
  .camelCase();

export const formatterInputSelectionSchema = yup
  .object({
    fields: yup.array(formatterInputSelectionFieldSchema).defined(),
  })
  .camelCase();

export const formatterMatchingConditionRuleSchema = yup
  .object({
    selector: yup.string().defined(),
    operator: yup
      .string()
      .oneOf([
        "exact_match",
        "contains",
        "not_contains",
        "in_list",
        "not_in_list",
      ])
      .defined(),
    value: yup.string().defined(),
  })
  .camelCase();

export const formatterMatchingConditionSchema = yup
  .object({
    enabled: yup.boolean().defined(),
    rules: yup.array(formatterMatchingConditionRuleSchema).defined(),
  })
  .camelCase();

export const formatterOutputAsFieldSchema = yup
  .object({
    name: yup.string().defined(),
  })
  .camelCase();

export const formatterOutputAsSchema = yup
  .object({
    enabled: yup.boolean().defined(),
    fields: yup.array(formatterOutputAsFieldSchema).defined(),
  })
  .camelCase();

export const formatterStepSchema = yup
  .object({
    id: yup.string().defined(),
    action: yup.string().defined(),
    inputSelection: formatterInputSelectionSchema.defined(),
    config: yup.mixed(),
    outputAs: formatterOutputAsSchema.defined(),
    matchingCondition: formatterMatchingConditionSchema.defined(),
  })
  .camelCase();

export const formatterSchema = yup
  .object({
    version: yup.string().defined(),
    steps: yup.array(formatterStepSchema).defined(),
  })
  .camelCase();

export type Formatter = yup.InferType<typeof formatterSchema>;

export type FormatterStep = yup.InferType<typeof formatterStepSchema>;

export type FormatterInputSelection = yup.InferType<
  typeof formatterInputSelectionSchema
>;

export type FormatterInputSelectionField = yup.InferType<
  typeof formatterInputSelectionFieldSchema
>;

export type FormatterOutputAs = yup.InferType<typeof formatterOutputAsSchema>;

export type FormatterOutputAsField = yup.InferType<
  typeof formatterOutputAsFieldSchema
>;

export enum FormatterMatchingConditionOperator {
  ExactMatch = "exact_match",
  Contains = "contains",
  NotContains = "not_contains",
  InList = "in_list",
  NotInList = "not_in_list",
}
export type FormatterMatchingCondition = yup.InferType<
  typeof formatterMatchingConditionSchema
>;

export type FormatterMatchingConditionRule = yup.InferType<
  typeof formatterMatchingConditionRuleSchema
>;

export enum FormatterActionType {
  RemoveCharacters = "remove_characters",
  KeepOnlyOneLanguage = "keep_only_one_language",
  SwapMonthDay = "swap_month_day",
}

export enum FormatterActionSelectorType {
  Field = "field",
  Value = "value",
}

export type FormatterActionOption = {
  name: string;
  selectorType: FormatterActionSelectorType;
};

export const FormatterActionInfo: Record<
  FormatterActionType,
  FormatterActionOption
> = {
  [FormatterActionType.RemoveCharacters]: {
    name: FormatterActionType.RemoveCharacters,
    selectorType: FormatterActionSelectorType.Value,
  },
  [FormatterActionType.KeepOnlyOneLanguage]: {
    name: FormatterActionType.RemoveCharacters,
    selectorType: FormatterActionSelectorType.Value,
  },
  [FormatterActionType.SwapMonthDay]: {
    name: FormatterActionType.RemoveCharacters,
    selectorType: FormatterActionSelectorType.Value,
  },
};

export enum FormatterDataSource {
  FormatterOutput = "formatter_output",
  Fields = "fields",
  AutoExtractionItems = "auto_extraction_items",
  KeyValues = "key_values",
  TokenGroups = "token_groups",
}
