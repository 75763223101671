import React, { useCallback, useState } from "react";

import { useFormActionCreator } from "../actions/form";
import { FormTable } from "../components/TeamDetailResources";
import { useFetchTeamForms } from "../hooks/admin";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { AdminPathParam } from "../models";
import {
  getOffsetByPage,
  getOffsetOfBeginningOfPage,
} from "../utils/pagination";

const PAGE_SIZE = 20;

function _AdminTeamDetailResourcesFormsContainer() {
  const { teamId, region } = useUnsafeParams<AdminPathParam>();
  const { forms, isFetching, pageInfo } = useAppSelector(
    state => state.admin.teamDetail.resourceState.formsState
  );
  const { exportForm } = useFormActionCreator();

  const [offset, setOffset] = useState(
    getOffsetOfBeginningOfPage(pageInfo?.offset ?? 0, PAGE_SIZE)
  );

  const onNavigateToPage = useCallback((pageNumber: number) => {
    setOffset(getOffsetByPage(pageNumber));
  }, []);

  const handleExportForm = useCallback(
    async (formId: string) => {
      await exportForm(formId, teamId, region);
    },
    [teamId, region, exportForm]
  );

  useFetchTeamForms(PAGE_SIZE, offset, teamId, region);

  return (
    <FormTable
      offset={offset}
      forms={forms}
      isFormsFetching={isFetching}
      formsPageInfo={pageInfo}
      pageSize={PAGE_SIZE}
      onNavigateToPage={onNavigateToPage}
      exportForm={handleExportForm}
    />
  );
}

export const AdminTeamDetailResourcesFormsContainer = React.memo(
  _AdminTeamDetailResourcesFormsContainer
);
export default AdminTeamDetailResourcesFormsContainer;
