export const Tesseract = "tesseract";
export const GoogleCloudVision = "google";
export const AzureComputerVision = "azure";
export const Abbyy = "abbyy";
export const Inherit = "inherit";

export type TesseractConfig = {
  engine: typeof Tesseract;
  param: {
    psm: number;
    oem: number;
  };
};

export const SymbolDetector = "symbol-detector";
export type SymbolDetectorConfig = {
  engine: typeof SymbolDetector;
  param: any;
};

export const ZBar = "zbar";
export type ZBarConfig = {
  engine: typeof ZBar;
  param: any;
};

export type GoogleCloudVisionConfig = {
  engine: typeof GoogleCloudVision;
  param: any;
};

export type AzureComputerVisionConfig = {
  engine: typeof AzureComputerVision;
  param: {
    mode: string;
  };
};

export type AbbyyConfig = {
  engine: typeof Abbyy;
  param: any;
};

export type InheritConfig = {
  engine: typeof Inherit;
  param: any;
};

export type OCRConfig =
  | TesseractConfig
  | SymbolDetectorConfig
  | ZBarConfig
  | GoogleCloudVisionConfig
  | AzureComputerVisionConfig
  | AbbyyConfig
  | InheritConfig;
