import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
  Text,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useFormActionCreator } from "../../actions/form";
import { MAX_NUMBER_OF_MERCHANT_NAMES } from "../../constants/formConfig";
import { buildFormSettings } from "../../contexts/formEditor";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { RootState } from "../../redux/types";
import styles from "./styles.module.scss";

interface Props {
  merchants?: string[];
  onClose: () => void;
  onConfirm: (merchantNames?: string[]) => void;
  isOpen: boolean;
}

export function useMerchantSimpleNameListModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openMerchantSimpleNameListModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const form = useSelector((state: RootState) => state.form.currentForm);
  const merchants = useSelector(
    (state: RootState) => state.form.currentForm?.config.merchant_names
  );

  const { updateForm } = useFormActionCreator();

  const onConfirm = useCallback(
    (merchantNames?: string[]) => {
      if (!form) {
        return;
      }
      const settings = buildFormSettings(form, {
        merchantNames,
      });
      updateForm(settings);
      onClose();
    },
    [form, onClose, updateForm]
  );

  const merchantCustomNameListPayload = useMemo(
    () => ({
      merchants,
      isOpen,
      onClose,
      onConfirm,
    }),
    [isOpen, merchants, onClose, onConfirm]
  );

  return useMemo(
    () => ({
      merchantCustomNameListPayload,
      openMerchantSimpleNameListModal,
    }),
    [merchantCustomNameListPayload, openMerchantSimpleNameListModal]
  );
}

export function MerchantSimpleNameListModal(props: Props) {
  const { merchants: existingMerchants, onClose, isOpen, onConfirm } = props;
  const { localized } = useLocale();
  const { hasPermissionToEditResource } = useTeamPermission();

  const modalProps: IModalProps = useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("merchant_list_settings_modal.title"),
    }),
    [localized]
  );

  const [merchants, setMerchants] = useState<string[] | undefined>(
    existingMerchants
  );
  const [error, setError] = useState<string | undefined>(undefined);

  const onSubmit = useCallback(() => {
    onConfirm(merchants);
  }, [merchants, onConfirm]);

  const onChange = useCallback(
    (
      _e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue) {
        const values = newValue
          .split("\n")
          .filter(s => s.length > 0)
          .map(s => s.trim());
        if (values.length > MAX_NUMBER_OF_MERCHANT_NAMES) {
          setError(
            localized("error.merchant_list_settings.too_many_merchants", {
              count: values.length,
              limit: MAX_NUMBER_OF_MERCHANT_NAMES,
            })
          );
        } else {
          setError(undefined);
        }
        setMerchants(values);
      } else {
        setMerchants(undefined);
      }
    },
    [localized]
  );

  return (
    <Dialog
      minWidth={854}
      hidden={!isOpen}
      onDismiss={onClose}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <Text>
        <FormattedMessage id="merchant_list_settings_modal.desc1" />
      </Text>

      <div className={styles["text-area"]}>
        <TextField
          placeholder={localized(
            "merchant_list_settings_modal.textfield.placeholder"
          )}
          defaultValue={existingMerchants?.join("\n")}
          description={localized(
            "merchant_list_settings_modal.merchants_count",
            {
              count: merchants?.length ?? 0,
              limit: MAX_NUMBER_OF_MERCHANT_NAMES,
            }
          )}
          rows={30}
          multiline
          onChange={onChange}
          errorMessage={error}
        />
      </div>

      <DialogFooter>
        <DefaultButton
          onClick={onClose}
          text={localized(
            hasPermissionToEditResource ? "common.cancel" : "common.close"
          )}
        />
        {hasPermissionToEditResource && (
          <PrimaryButton
            onClick={onSubmit}
            text={localized("common.ok")}
            disabled={error !== undefined}
          />
        )}
      </DialogFooter>
    </Dialog>
  );
}
