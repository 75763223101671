import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import classnames from "classnames";
import * as React from "react";

import { LoadingState, useLoadImage } from "../Img";
import styles from "./styles.module.scss";

interface ImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  frameClass?: string;
  boundingBox?: number[];
}

export const CroppedImage: React.FC<ImageProps> = (props: ImageProps) => {
  const loadingState = useLoadImage(props.src);

  const { frameClass, src, boundingBox, ...rest } = props;

  const [image, setImage] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (loadingState !== LoadingState.Loaded || src === undefined) {
      setImage(undefined);
      return;
    }

    if (!boundingBox) {
      setImage(src);
      return;
    }

    const tmp = new Image();
    tmp.crossOrigin = "anonymous";
    tmp.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        return;
      }
      const width = tmp.width;
      const height = tmp.height;

      const [left, top, right, bottom] =
        boundingBox !== undefined && boundingBox.length === 4
          ? boundingBox
          : [0, 0, 1, 1];

      canvas.width = (right - left) * width;
      canvas.height = (bottom - top) * height;

      ctx.drawImage(
        tmp,
        left * width,
        top * height,
        canvas.width,
        canvas.height,
        0,
        0,
        canvas.width,
        canvas.height
      );

      setImage(canvas.toDataURL());

      canvas.remove();
    };
    tmp.src = src;
  }, [src, loadingState, boundingBox]);

  switch (loadingState) {
    case LoadingState.Loading:
      return (
        <div className={classnames(styles["frame"], frameClass)}>
          <div>
            <Spinner size={SpinnerSize.medium} />
          </div>
        </div>
      );
    case LoadingState.Initial:
    case LoadingState.Loaded:
      if (frameClass) {
        return (
          <div className={frameClass}>
            {<img {...rest} src={image} alt={rest.alt || ""} />}
          </div>
        );
      }
      return <img {...rest} src={image} alt={rest.alt || ""} />;
    case LoadingState.Errored:
      return (
        <div
          className={classnames(styles["frame"], styles["errored"], frameClass)}
        >
          <div>
            <Icon iconName="Cancel" />
          </div>
        </div>
      );
  }
};
