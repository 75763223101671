import { PrimaryButton } from "@fluentui/react";
import classnames from "classnames";
import React from "react";

import { useFormEditor } from "../../contexts/formEditor";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { ConfirmModalType } from "../../types/confirmation";
import ConfirmModal from "../ConfirmModal";
import styles from "./styles.module.scss";

interface Props {
  children: React.ReactNode;
}

export const TabBarSaveButton = () => {
  const { localized } = useLocale();

  const {
    onSaveButtonClick,
    isFormNotSaved,
    isConfirmConflictModalOpen,
    cancelSaveForm,
    isSavingForm,
  } = useFormEditor();

  const { hasPermissionToEditResource } = useTeamPermission();

  if (!hasPermissionToEditResource) {
    return <></>;
  }

  return (
    <>
      <PrimaryButton
        className={classnames(styles["save-button"], {
          [styles["disabled"]]: !isFormNotSaved || isSavingForm,
        })}
        text={localized("common.save")}
        onClick={() => onSaveButtonClick()}
        disabled={!isFormNotSaved || isSavingForm}
      />
      <ConfirmModal
        isOpen={isConfirmConflictModalOpen}
        modalType={ConfirmModalType.Save}
        titleId="form_editor.form_modifed_prompt.title"
        messageId="form_editor.form_modifed_prompt.desc"
        actionId="common.save_and_overwrite"
        onCancel={cancelSaveForm}
        onConfirm={() =>
          onSaveButtonClick({
            ignoreConflict: true,
          })
        }
      />
    </>
  );
};

const TabBarContentLayout = (props: Props) => {
  const { children } = props;

  return (
    <div className={styles["container"]}>
      <div className={styles["separator"]} />
      <div className={styles["content"]}>{children}</div>
      <div className={styles["save-button-container"]}>
        <TabBarSaveButton />
      </div>
    </div>
  );
};

export default TabBarContentLayout;
