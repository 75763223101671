import {
  CustomModel,
  CustomModelExtraFields,
  CustomModelExtraFieldsResp,
  CustomModelLabelSchemaType,
  CustomModelResp,
  CustomModelVersion,
  CustomModelVersionResp,
} from "../customModel";
import {
  ExtractedContentSchema,
  ExtractedContentSchemaField,
  ExtractedContentSchemaFieldStorage,
  ExtractedContentSchemaStorage,
  ExtractedContentSchemaType,
} from "../extractedContentSchema";
import { FSLModelState, FSLModelStateSchemaStoage } from "../fslModelState";

function parseDateOrNull(value: any): number | null {
  return (value && Date.parse(value)) || null;
}

export const CustomModelVersionMapper = {
  fromResp: (
    resp: CustomModelVersionResp,
    deployedModelVersion: string | null,
    isInTraining: boolean
  ): CustomModelVersion => ({
    tag: resp.tag,
    createdAt: new Date(resp.created_at * 1000),
    fields: resp.fields,
    isActive: resp.tag === deployedModelVersion,
    isInTraining,
  }),
  toResp: (model: CustomModelVersion): CustomModelVersionResp => ({
    tag: model.tag,
    created_at: Math.round(model.createdAt.getTime() / 1000),
    fields: model.fields,
  }),
};

const ExtractedContentSchemaFieldMapper = {
  fromResp: (
    resp: ExtractedContentSchemaFieldStorage
  ): ExtractedContentSchemaField => {
    return {
      id: resp.id,
      name: resp.name,
      type: resp.type as ExtractedContentSchemaType,
      format: resp.format === null ? undefined : resp.format,
      definitionId:
        resp.definition_id === null ? undefined : resp.definition_id,
      isList: resp.is_list,
      description: resp.description === null ? undefined : resp.description,
    };
  },
  toResp: (
    field: ExtractedContentSchemaField
  ): ExtractedContentSchemaFieldStorage => {
    return {
      id: field.id,
      name: field.name,
      type: field.type as ExtractedContentSchemaType,
      format: field.format,
      definition_id: field.definitionId,
      is_list: field.isList,
      description: field.description,
    };
  },
};

export const ExtractedContentSchemaMapper = {
  fromResp: (
    resp?: ExtractedContentSchemaStorage
  ): ExtractedContentSchema | undefined => {
    if (!resp) {
      return undefined;
    }
    return {
      name: resp.name,
      modifiedAt: resp.modified_at,
      definitions: resp.definitions.map(definition => {
        return {
          id: definition.id,
          name: definition.name,
          fields: definition.fields.map(
            ExtractedContentSchemaFieldMapper.fromResp
          ),
        };
      }),
      payload: resp.payload.map(ExtractedContentSchemaFieldMapper.fromResp),
    };
  },
  toResp: (
    schema?: ExtractedContentSchema
  ): ExtractedContentSchemaStorage | undefined => {
    if (!schema) {
      return undefined;
    }
    return {
      name: schema.name,
      modified_at: schema.modifiedAt,
      definitions: schema.definitions.map(definition => {
        return {
          id: definition.id,
          name: definition.name,
          fields: definition.fields.map(
            ExtractedContentSchemaFieldMapper.toResp
          ),
        };
      }),
      payload: schema.payload.map(ExtractedContentSchemaFieldMapper.toResp),
    };
  },
};

export const FSLModelStateMapper = {
  fromResp: (resp?: FSLModelStateSchemaStoage): FSLModelState | undefined => {
    if (!resp) {
      return undefined;
    }
    return {
      isReadyToUse: resp.is_ready_to_use,
      isReadyToUseOnce: resp.is_ready_to_use_once,
      isStandardModelEnabled: resp.is_standard_model_enabled,
      isDocumentTooComplex: resp.is_document_too_complex,
      shouldFallbackToOriginalCustomModel:
        resp.should_fallback_to_original_custom_model,
    };
  },
  toResp: (
    modelState?: FSLModelState
  ): FSLModelStateSchemaStoage | undefined => {
    if (!modelState) {
      return undefined;
    }
    return {
      is_ready_to_use: modelState.isReadyToUse,
      is_ready_to_use_once: modelState.isReadyToUseOnce,
      is_standard_model_enabled: modelState.isStandardModelEnabled,
      is_document_too_complex: modelState.isDocumentTooComplex,
      should_fallback_to_original_custom_model:
        modelState.shouldFallbackToOriginalCustomModel,
    };
  },
};

export const CustomModelExtraFieldsMapper = {
  fromResp: (resp: CustomModelExtraFieldsResp): any => {
    const ret = {} as CustomModelExtraFields;

    if (resp.standard_model_labelled_image_count !== undefined) {
      ret.standardModelLabelledImageCount =
        resp.standard_model_labelled_image_count;
    }

    return ret;
  },
};

export const CustomModelMapper = {
  fromResp: (resp: CustomModelResp): CustomModel => {
    const trainingRequested =
      resp.config.training_requested === undefined
        ? false
        : resp.config.training_requested;

    const labellingStarted =
      resp.config.labelling_started === undefined
        ? false
        : resp.config.labelling_started;

    const labelSchema =
      resp.config.label_schema === undefined
        ? []
        : resp.config.label_schema.map(label => {
            return {
              name: label.name,
              color: label.color,
              type: label.type as CustomModelLabelSchemaType,
              attributes: label.attributes,
              config: label.config,
            };
          });

    const unfrozenLabelSchema =
      resp.config.unfrozen_label_schema === undefined
        ? []
        : resp.config.unfrozen_label_schema.map(label => {
            return {
              name: label.name,
              color: label.color,
              type: label.type as CustomModelLabelSchemaType,
              attributes: label.attributes,
              config: label.config,
            };
          });

    const extractedContentSchema = ExtractedContentSchemaMapper.fromResp(
      resp.config.extracted_content_schema
    );

    const config = {
      remark: resp.config.remark,
      fxcmType: resp.config.fxcm_type || "layoutlm",
      trainingRequested,
      labellingStarted,
      labelSchema,
      unfrozenLabelSchema,
      basedOn: resp.config.based_on,
      extractedContentSchema,
      fslModelState: FSLModelStateMapper.fromResp(resp.config.fsl_model_state),
      llmProvider: resp.config.llm_provider,
      llmParameters: resp.config.llm_parameters,
      documentTypeForSample: resp.config.document_type_for_sample,
    } as CustomModel["config"];

    if (resp.config.is_fsl_model) {
      config.isFSLModel = resp.config.is_fsl_model;
    }

    if (resp.config.standard_model_sample_updated_at) {
      config.standardModelSampleUpdatedAt =
        resp.config.standard_model_sample_updated_at;
    }

    const modelVersions = resp.model_versions
      ? resp.model_versions.map(x =>
          CustomModelVersionMapper.fromResp(
            x,
            resp.deployed_model_version,
            false
          )
        )
      : [];

    const modelVersionInTraining =
      resp.model_version_in_training &&
      CustomModelVersionMapper.fromResp(
        resp.model_version_in_training,
        resp.deployed_model_version,
        true
      );

    const ret = {
      id: resp.id,
      name: resp.name,
      createdAt: resp.created_at,
      updatedAt: resp.updated_at,
      CVATProjectID: resp.cvat_project_id,
      formID: resp.form_id,
      lastCVATProjectStartSyncAt: parseDateOrNull(
        resp.last_cvat_project_start_sync_at
      ),
      lastCVATProjectFinishSyncAt: parseDateOrNull(
        resp.last_cvat_project_finish_sync_at
      ),
      startTrainingAt: parseDateOrNull(resp.start_training_at),
      finishTrainingAt: parseDateOrNull(resp.finish_training_at),
      isTrainingFailed: resp.is_training_failed ?? false,
      startDeploymentAt: parseDateOrNull(resp.start_deployment_at),
      finishDeploymentAt: parseDateOrNull(resp.finish_deployment_at),
      config,
      modelVersions:
        modelVersionInTraining !== null
          ? [modelVersionInTraining, ...modelVersions]
          : modelVersions,
      resourceOwnerId: resp.resource_owner_id,
      status: resp.status,
      noOfSampleImages: resp.no_of_sample_images,
      workspaces: resp.workspaces,
      deployedModelVersion:
        resp.deployed_model_version === null
          ? undefined
          : resp.deployed_model_version,
    } as CustomModel;

    if (resp.extra_fields !== undefined) {
      ret.extraFields = CustomModelExtraFieldsMapper.fromResp(
        resp.extra_fields
      );
    }
    return ret;
  },
  toResp: (customModel: CustomModel): CustomModelResp => {
    const config = {
      remark: customModel.config.remark,
      fxcm_type: customModel.config.fxcmType,
      training_requested: customModel.config.trainingRequested,
      labelling_started: customModel.config.labellingStarted,
      label_schema: customModel.config.labelSchema,
      unfrozen_label_schema: customModel.config.unfrozenLabelSchema,
      based_on: customModel.config.basedOn,
      is_fsl_model: customModel.config.isFSLModel,
      extracted_content_schema: ExtractedContentSchemaMapper.toResp(
        customModel.config.extractedContentSchema
      ),
      fsl_model_state: FSLModelStateMapper.toResp(
        customModel.config.fslModelState
      ),
      standard_model_sample_updated_at:
        customModel.config.standardModelSampleUpdatedAt,
      llm_provider: customModel.config.llmProvider,
      llm_parameters: customModel.config.llmParameters,
      document_type_for_sample: customModel.config.documentTypeForSample,
    };

    const toISODateString = (timestamp: number | null | undefined) => {
      return timestamp ? new Date(timestamp).toISOString() : null;
    };

    const modelVersions = customModel.modelVersions
      .filter(x => !x.isInTraining)
      .map(CustomModelVersionMapper.toResp);

    const inTrainingModelVersionIndex = customModel.modelVersions.findIndex(
      x => x.isInTraining
    );

    const modelVersionInTraining =
      inTrainingModelVersionIndex >= 0
        ? CustomModelVersionMapper.toResp(
            customModel.modelVersions[inTrainingModelVersionIndex]
          )
        : null;

    const activeModelVersionIndex = customModel.modelVersions.findIndex(
      x => x.isActive
    );
    const deployedModelVersion =
      activeModelVersionIndex >= 0
        ? customModel.modelVersions[activeModelVersionIndex].tag
        : null;

    return {
      id: customModel.id,
      name: customModel.name,
      created_at: customModel.createdAt || "",
      updated_at: customModel.updatedAt || "",
      config,
      cvat_project_id: customModel.CVATProjectID,
      form_id: customModel.formID,
      last_cvat_project_start_sync_at: toISODateString(
        customModel.lastCVATProjectStartSyncAt
      ),
      last_cvat_project_finish_sync_at: toISODateString(
        customModel.lastCVATProjectFinishSyncAt
      ),
      start_training_at: toISODateString(customModel.startTrainingAt),
      finish_training_at: toISODateString(customModel.finishTrainingAt),
      is_training_failed: customModel.isTrainingFailed,
      start_deployment_at: toISODateString(customModel.startDeploymentAt),
      finish_deployment_at: toISODateString(customModel.finishDeploymentAt),
      deployed_model_version: deployedModelVersion,
      model_versions: modelVersions,
      model_version_in_training: modelVersionInTraining,
      resource_owner_id: customModel.resourceOwnerId,
      status: customModel.status,
      no_of_sample_images: customModel.noOfSampleImages,
      workspaces: customModel.workspaces,
    };
  },
};
