import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import * as extractionAction from "../actions/extraction";
import * as formAction from "../actions/form";
import * as formGroupAction from "../actions/formGroup";
import * as resourceOwnerAction from "../actions/resourceOwner";
import * as teamAction from "../actions/team";
import * as userAction from "../actions/user";
import * as workspaceAction from "../actions/workspace";
import { FOCRError } from "../errors";
import { PaginatedWorkspace, Workspace } from "../types/workspace";

export interface WorkspaceState {
  readonly currentWorkspace?: Workspace;
  readonly paginatedWorkspaceByPage: { [key in number]: PaginatedWorkspace };
  readonly lastListParams?: {
    page: number;
    filter: string;
  };
  readonly isListingWorkspaces: boolean;
  readonly workspaceError?: FOCRError;
  readonly isUploadWidgetCollapsed: boolean;
}

const defaultState: WorkspaceState = {
  currentWorkspace: undefined,
  paginatedWorkspaceByPage: {},
  isListingWorkspaces: false,
  isUploadWidgetCollapsed: false,
};

export const workspaceReducer = createReducer<WorkspaceState>(
  defaultState,
  builder => {
    builder
      .addCase(workspaceAction.GetWorkspaceSuccess, (state, action) => {
        state.currentWorkspace = action.payload.workspace;
      })
      .addCase(workspaceAction.CreateWorkspaceSuccess, (state, action) => {
        state.currentWorkspace = action.payload.workspace;
        state.paginatedWorkspaceByPage = {};
      })
      .addCase(workspaceAction.UpdateWorkspaceSuccess, (state, action) => {
        if (state.currentWorkspace?.id === action.payload.workspace.id) {
          state.currentWorkspace.config = action.payload.workspace.config;
        }
        state.paginatedWorkspaceByPage = {};
      })
      .addCase(workspaceAction.ListWorkspaces, (state, action) => {
        state.isListingWorkspaces = true;
        state.workspaceError = undefined;

        if (action.payload.filter !== state.lastListParams?.filter) {
          state.paginatedWorkspaceByPage = {};
        }

        state.lastListParams = action.payload;
      })
      .addCase(workspaceAction.GotWorkspaces, (state, action) => {
        state.paginatedWorkspaceByPage[(state.lastListParams?.page || 1) - 1] =
          action.payload;
        state.isListingWorkspaces = false;
      })
      .addCase(workspaceAction.ListWorkspacesFailed, (state, action) => {
        state.workspaceError = action.payload;
        state.isListingWorkspaces = false;
      })
      .addCase(workspaceAction.DeleteWorkspaceSuccess, (state, _action) => {
        state.paginatedWorkspaceByPage = {};
      })
      .addCase(workspaceAction.SetIsUploadWidgetCollapsed, (state, action) => {
        state.isUploadWidgetCollapsed = action.payload.isCollapsed;
      })
      .addCase(extractionAction.CreateExtraction, (state, _) => {
        state.isUploadWidgetCollapsed = false;
      })
      .addMatcher(
        isAnyOf(formAction.FormSaved, formGroupAction.FormGroupUpdated),
        (state, action) => {
          if (
            state.currentWorkspace?.extractor?.id === action.payload.id ||
            state.currentWorkspace?.extractor?.associatedExtractorId ===
              action.payload.id
          ) {
            state.currentWorkspace.extractor.name = action.payload.name;
            state.currentWorkspace.extractor.processingMode =
              action.payload.config.processing_mode;
          }

          for (const page in state.paginatedWorkspaceByPage) {
            for (const workspace of state.paginatedWorkspaceByPage[page]
              .workspaces) {
              if (
                workspace?.extractor?.id === action.payload.id ||
                workspace?.extractor?.associatedExtractorId ===
                  action.payload.id
              ) {
                workspace.extractor.name = action.payload.name;
                workspace.extractor.processingMode =
                  action.payload.config.processing_mode;
              }
            }
          }
        }
      )
      .addMatcher(
        isAnyOf(
          userAction.UserLogin,
          userAction.UserLogout,
          resourceOwnerAction.SelectTeam,
          teamAction.CreateTeam,
          teamAction.TeamInvitationAccepted,
          teamAction.TeamDeleted
        ),
        () => defaultState
      );
  }
);
