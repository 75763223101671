import * as yup from "yup";

export const googleSpreadsheetSchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
  })
  .camelCase();

export const googleSpreadsheetListSchema = yup
  .object({
    spreadsheets: yup.array().of(googleSpreadsheetSchema.defined()).defined(),
  })
  .camelCase();

export const googleSheetSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
    columns: yup.array().of(yup.string().defined()).defined(),
  })
  .camelCase();

export const googleSheetListSchema = yup
  .object({
    sheets: yup.array().of(googleSheetSchema.defined()).defined(),
  })
  .camelCase();

export const googleSheetFieldMappingSchema = yup
  .object({
    columnIndex: yup.number().required(),
    columnName: yup.string().required(),
    fieldName: yup.string().required(),
    isPrimaryKey: yup.boolean().required(),
  })
  .camelCase();

export type GoogleSpreadsheet = yup.InferType<typeof googleSpreadsheetSchema>;

export type GoogleSpreadsheetList = yup.InferType<
  typeof googleSpreadsheetListSchema
>;

export type GoogleSheet = yup.InferType<typeof googleSheetSchema>;

export type GoogleSheetList = yup.InferType<typeof googleSheetListSchema>;

export type GoogleSheetFieldMapping = yup.InferType<
  typeof googleSheetFieldMappingSchema
>;

export const workspaceGoogleSheetExportConfigSchema = yup
  .object({
    mappings: yup.array().of(googleSheetFieldMappingSchema.defined()).defined(),
    isUpsertMode: yup.boolean().defined(),
  })
  .camelCase();

export type WorkspaceGoogleSheetExportConfig = yup.InferType<
  typeof workspaceGoogleSheetExportConfigSchema
>;

export const workspaceGoogleSheetExportSchema = yup
  .object({
    workspaceGoogleSheetExport: yup
      .object({
        id: yup.string().defined(),
        config: workspaceGoogleSheetExportConfigSchema.defined(),
        googleSheetId: yup.string().defined(),
        googleSpreadsheetId: yup.string().defined(),
        oauthCredentialId: yup.string().defined(),
        workspaceId: yup.string().defined(),
      })
      .camelCase()
      .defined(),
  })
  .camelCase();

export type WorkspaceGoogleSheetExport = yup.InferType<
  typeof workspaceGoogleSheetExportSchema
>;
