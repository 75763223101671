import { createAction } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { AppConfig } from "../config";
import { useAppDispatch } from "../hooks/redux";
import { RootState } from "../redux/types";

export const MicrosoftAuthLogin = createAction(
  "microsoftAuth/login",
  (nonce: string) => ({
    payload: {
      nonce,
    },
  })
);

export const MicrosoftAuthAuthenticationFailed = createAction(
  "microsoftAuth/authenticationFailed",
  (error: string, description: string | null) => ({
    payload: {
      error,
      description,
    },
  })
);

export const MicrosoftAuthAuthenticated = createAction(
  "microsoftAuth/authenticated",
  (idToken: string, accessToken: string, refreshToken: string) => ({
    payload: {
      idToken,
      accessToken,
      refreshToken,
    },
  })
);

export type MicrosoftAuthAction =
  | ReturnType<typeof MicrosoftAuthLogin>
  | ReturnType<typeof MicrosoftAuthAuthenticated>
  | ReturnType<typeof MicrosoftAuthAuthenticationFailed>;

const getSiteSpecificScopes = (siteUrl: string) => {
  const oAuthScopes = ["AllSites.Read", "AllSites.Manage"];
  return oAuthScopes.map(
    s => `${siteUrl.replace(/\/$/, "")}/${s.replace(/^\//, "")}`
  );
};

export interface MicrosoftAuthCallback {
  state: string | null;
  idToken: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  error: string | null;
  errorDescription: string | null;
}

export function useMicrosoftAuthActionCreator() {
  const dispatch = useAppDispatch();

  const [loginWindow, setLoginWindow] = useState<Window | null>(null);

  const login = useCallback(
    async (siteUrl: string) => {
      if (AppConfig.microsoftAuth == null) {
        throw new Error("AppConfig.microsoftAuth not configured");
      }
      const scope = [
        "openid",
        "offline_access",
        ...getSiteSpecificScopes(siteUrl),
      ].join(" ");
      const nonce = uuidv4();
      dispatch(MicrosoftAuthLogin(nonce));
      const loginParams = new URLSearchParams({
        client_id: AppConfig.microsoftAuth.azureAppClientId,
        response_type: "code",
        redirect_uri: `${AppConfig.api.endpoint}sharepoint-auth`,
        response_mode: "query",
        scope: scope,
        state: nonce,
        prompt: "select_account",
      });
      const loginUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${loginParams.toString()}`;
      setLoginWindow(window.open(loginUrl, undefined, "width=500,height=400"));
    },
    [dispatch]
  );

  const { isLoggingIn, nonce } = useSelector(
    (state: RootState) => state.microsoftAuth
  );

  const onLoginCallback = useCallback(
    (payload: MicrosoftAuthCallback) => {
      const {
        state,
        idToken,
        accessToken,
        refreshToken,
        error,
        errorDescription,
      } = payload;
      if (!isLoggingIn) {
        return;
      }
      if (state !== nonce) {
        dispatch(
          MicrosoftAuthAuthenticationFailed("nonce_mismatch", "Nonce mismatch")
        );
        return;
      }
      if (idToken != null && accessToken != null && refreshToken != null) {
        dispatch(
          MicrosoftAuthAuthenticated(idToken, accessToken, refreshToken)
        );
      } else {
        dispatch(
          MicrosoftAuthAuthenticationFailed(
            error ?? "unknown",
            errorDescription ?? null
          )
        );
      }
    },
    [dispatch, isLoggingIn, nonce]
  );

  useEffect(() => {
    if (loginWindow == null) {
      return () => {};
    }
    const interval = setInterval(() => {
      if (loginWindow.closed) {
        setLoginWindow(null);
        dispatch(
          MicrosoftAuthAuthenticationFailed("user_cancelled", "User Cancelled.")
        );
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch, loginWindow]);

  useEffect(() => {
    if (loginWindow == null) {
      return () => {};
    }
    const handleEvent = (ev: MessageEvent) => {
      if (ev.origin !== window.location.origin) {
        return;
      }
      if (ev.source !== loginWindow) {
        return;
      }
      setLoginWindow(null);
      loginWindow.close();
      onLoginCallback(ev.data);
    };
    window.addEventListener("message", handleEvent);
    return () => {
      window.removeEventListener("message", handleEvent);
    };
  }, [loginWindow, onLoginCallback]);

  return useMemo(
    () => ({
      login,
    }),
    [login]
  );
}
