import { IBreadcrumbItem } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router";

import { NavBarLayout } from "../components/NavBarLayout";
import { NavTab } from "../components/NavTabBar";
import { useLocale } from "../contexts/locale";

export enum DetectPIINavTabKey {
  TestExtractor = "test",
  API = "extract",
}

export const DetectPIINavTab: Record<DetectPIINavTabKey, NavTab> = {
  [DetectPIINavTabKey.TestExtractor]: {
    key: DetectPIINavTabKey.TestExtractor,
    labelId: "extractor.tab.test",
  },
  [DetectPIINavTabKey.API]: {
    key: DetectPIINavTabKey.API,
    labelId: "extractor.tab.extract",
  },
};

function useDetectPIINavBarLayoutState(selectedTab: string) {
  const navigate = useNavigate();
  const { localized } = useLocale();

  const backToHome = React.useCallback(() => {
    navigate("/extractor");
  }, [navigate]);

  const breadcrumbItems = React.useMemo<IBreadcrumbItem[]>(
    () => [
      {
        text: localized("header.detect_pii"),
        key: "extractor",
        href: "/extractor",
        onClick: backToHome,
      },
    ],
    [backToHome, localized]
  );

  const tabs = React.useMemo(() => {
    const tabKeys = [DetectPIINavTabKey.TestExtractor, DetectPIINavTabKey.API];
    return tabKeys
      .filter(Boolean)
      .map(key => DetectPIINavTab[key as DetectPIINavTabKey]);
  }, []);

  const onTabSelect = React.useCallback(
    (key: string) => {
      const mapTable = {
        [DetectPIINavTabKey.TestExtractor]: "test",
        [DetectPIINavTabKey.API]: "extract",
      } as { [key in DetectPIINavTabKey]: string };

      const path = mapTable[key as DetectPIINavTabKey];
      navigate(`/detect-pii/${path}`);
    },
    [navigate]
  );
  return React.useMemo(
    () => ({
      breadcrumbItems,
      onTabSelect,
      tabs,
      selectedTab,
    }),
    [breadcrumbItems, onTabSelect, tabs, selectedTab]
  );
}

type DetectPIINavBarLayoutProps = {
  children: React.ReactNode;
  selectedTab?: string;
  tabBarVisible?: boolean;
};

export function DetectPIINavBarLayoutImpl(
  props: DetectPIINavBarLayoutProps &
    ReturnType<typeof useDetectPIINavBarLayoutState>
) {
  const {
    children,
    tabs,
    onTabSelect,
    breadcrumbItems,
    selectedTab,
    tabBarVisible,
  } = props;

  return (
    <>
      <NavBarLayout
        tabs={tabs}
        onTabSelect={onTabSelect}
        breadcrumbItems={breadcrumbItems}
        selectedTab={selectedTab}
        tabBarVisible={tabBarVisible}
      >
        {children}
      </NavBarLayout>
    </>
  );
}

export function DetectPIINavBarLayout(props: DetectPIINavBarLayoutProps) {
  const state = useDetectPIINavBarLayoutState(
    props.selectedTab ?? DetectPIINavTabKey.TestExtractor
  );

  return <DetectPIINavBarLayoutImpl {...props} {...state} />;
}
