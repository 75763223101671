import { ActionButton, Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useState } from "react";

import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { MinSampleImageForTraining } from "../../constants/customModel";
import { CUSTOM_MODEL_IMAGE_PAGE_SIZE } from "../../constants/layout";
import { useTeamPermission } from "../../hooks/permission";
import { CustomModelImage } from "../../types/customModelImage";
import { chooseFile } from "../../utils/file";
import CustomModelImageGridItem from "../CustomModelImageGridItem";
import CustomModelImageLightboxModal from "../CustomModelImageLightboxModal";
import Paginator from "../Paginator";
import ShortSpinner from "../ShortSpinner";
import styles from "./styles.module.scss";

interface Props {
  customModelImages: CustomModelImage[];
  numUploadingFile: number;
  totalFiles: number;
  isLoading: boolean;
  currentPage: number;
  deletingCustomModelImageIds: string[];
  uploadCustomModelImages: (files: File[]) => void;
  deleteImages: (imageIds: string[]) => void;
  navigateToPage: (page: number) => void;
  openLabeller?: (imageId: string) => void;
}

function _CustomModelEditorImageGrid(props: Props) {
  const {
    customModelImages,
    uploadCustomModelImages,
    deleteImages,
    isLoading,
    totalFiles,
    numUploadingFile,
    deletingCustomModelImageIds,
    currentPage,
    navigateToPage,
    openLabeller,
  } = props;
  const { hasPermissionToEditResource, hasPermissionToRemoveResource } =
    useTeamPermission();

  const [selectedImageIds, setSelectedImageIds] = useState<string[]>([]);
  const [imageIDToShowInLightBoxModal, setImageIDToShowInLightBoxModal] =
    useState<string | undefined>();

  const onSelectedImage = useCallback((id: string) => {
    setSelectedImageIds(selectedImageIds => {
      return selectedImageIds.includes(id)
        ? selectedImageIds.filter(x => x !== id)
        : [...selectedImageIds, id];
    });
  }, []);

  const onDeleteButtonClicked = useCallback(() => {
    deleteImages(selectedImageIds);
    setSelectedImageIds([]);
  }, [deleteImages, selectedImageIds]);

  const onAddButtonClicked = useCallback(async () => {
    const files = await chooseFile(SUPPORTED_EXTRACT_MIME.join(","), true);
    if (files) {
      uploadCustomModelImages(files);
    }
  }, [uploadCustomModelImages]);

  const onNavigateToPage = useCallback(
    (page: number) => {
      navigateToPage(page);
    },
    [navigateToPage]
  );

  const onLightBoxModalDismiss = useCallback(() => {
    setImageIDToShowInLightBoxModal(undefined);
  }, []);

  const onClickedImage = useCallback((id: string) => {
    setImageIDToShowInLightBoxModal(id);
  }, []);

  return (
    <div className={styles["container"]}>
      <div className={styles["title"]}>
        <FormattedMessage id="custom_model_editor.image_grid.title" />
      </div>

      <div className={styles["row"]}>
        <div className={styles["subtitle"]}>
          <FormattedMessage id="custom_model_editor.image_grid.subtitle" />
        </div>

        <div className={styles["button-bar"]}>
          {hasPermissionToEditResource && (
            <ActionButton
              iconProps={{ iconName: "Add" }}
              onClick={onAddButtonClicked}
            >
              <FormattedMessage id="custom_model_grid.add_samples_button" />
            </ActionButton>
          )}
          {hasPermissionToRemoveResource && (
            <ActionButton
              className={styles["delete-button"]}
              iconProps={{ iconName: "Delete" }}
              disabled={selectedImageIds.length === 0}
              onClick={onDeleteButtonClicked}
            >
              <FormattedMessage id="custom_model_grid.delete_files_button" />
            </ActionButton>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className={styles["loading-container"]}>
          <ShortSpinner />
        </div>
      ) : (
        <>
          <div className={styles["info-container"]}>
            {numUploadingFile > 0 && (
              <span className={styles["uploading-files"]}>
                <Icon iconName="Upload" />
                <FormattedMessage
                  id="custom_model_grid.uploading_files"
                  values={{ numUploadingFile }}
                />
              </span>
            )}
            <span className={styles["file-count"]}>
              {selectedImageIds.length > 0 ? (
                <FormattedMessage
                  id="custom_model_grid.selected_files"
                  values={{
                    numSelectedFiles: selectedImageIds.length,
                    totalFiles,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="custom_model_grid.total_files"
                  values={{ totalFiles }}
                />
              )}
            </span>
          </div>
          <div className={styles["grid"]}>
            {customModelImages.map(image => (
              <CustomModelImageGridItem
                imageId={image.id}
                name={image.filename}
                url={image.url}
                isEditable={hasPermissionToEditResource}
                checked={selectedImageIds.includes(image.id)}
                isDeleting={deletingCustomModelImageIds.includes(image.id)}
                onSelect={onSelectedImage}
                onClickPreview={onClickedImage}
                date={image.createdAt}
                key={image.id}
              />
            ))}
          </div>
          <div className={styles["paginator-container"]}>
            <Paginator
              currentPage={currentPage}
              totalCount={totalFiles}
              pageSize={CUSTOM_MODEL_IMAGE_PAGE_SIZE}
              navigateToPage={onNavigateToPage}
            />
          </div>
          {totalFiles < MinSampleImageForTraining && (
            <div className={styles["reminder"]}>
              <div>
                <FormattedMessage
                  id="custom_model_grid.reminder"
                  values={{ count: MinSampleImageForTraining }}
                />
              </div>
            </div>
          )}
        </>
      )}
      <CustomModelImageLightboxModal
        images={customModelImages}
        imageID={imageIDToShowInLightBoxModal}
        onDismiss={onLightBoxModalDismiss}
        onOpenLabeller={openLabeller}
      />
    </div>
  );
}

export const CustomModelEditorImageGrid = React.memo(
  _CustomModelEditorImageGrid
);
export default CustomModelEditorImageGrid;
