import { IBreadcrumbItem } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router";

import { useLocale } from "../../contexts/locale";
import AppBreadcrumb, { CustomBreadcrumbItem } from "../AppBreadcrumb";
import { NavTab, NavTabBar } from "../NavTabBar";
import styles from "./styles.module.scss";

interface NavBarLayoutProps {
  tabs: NavTab[];
  selectedTab: string;
  onTabSelect?: (key: string) => void;
  breadcrumbItems: CustomBreadcrumbItem[];
  children: React.ReactNode;
  tabBarVisible?: boolean;
}

export function useNavBarLayoutBreadcrumbItems(resourceName?: string) {
  const navigate = useNavigate();
  const { localized } = useLocale();

  const backToHome = React.useCallback(() => {
    navigate("/extractor");
  }, [navigate]);

  const breadcrumbItems = React.useMemo<IBreadcrumbItem[]>(() => {
    const res = [
      {
        text: localized("extractor.breadcrumb.extractors"),
        key: "extractor",
        href: "/extractor",
        onClick: backToHome,
      },
    ] as IBreadcrumbItem[];
    if (resourceName) {
      res.push({
        text: resourceName,
        key: "resource",
      });
    }
    return res;
  }, [backToHome, localized, resourceName]);

  return breadcrumbItems;
}

export function NavBarLayout(props: NavBarLayoutProps) {
  const { tabs, selectedTab, onTabSelect, breadcrumbItems, children } = props;
  const tabBarVisible = props.tabBarVisible ?? true;

  return (
    <div className={styles["container"]}>
      <div className={styles["navbar-top"]}>
        <AppBreadcrumb className={styles["nav-bar"]} items={breadcrumbItems} />
      </div>
      {tabBarVisible && (
        <>
          <div className={styles["navbar-bottom"]}>
            <NavTabBar
              tabs={tabs}
              selectedKey={selectedTab}
              onSelect={onTabSelect}
            />
          </div>
          <hr />
        </>
      )}
      <div className={styles["scrollable-view"]}>
        <div className={styles["content"]}>{children}</div>
      </div>
    </div>
  );
}
