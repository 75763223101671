import * as React from "react";
import { useSelector } from "react-redux";

import CreateWebhookBar from "../components/CreateWebhookBar";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import WebhookList from "../components/WebhookList";
import HeaderContainer from "../containers/Header";
import { useFetchWehhooks } from "../hooks/webhook";
import { RootState } from "../redux/types";
import { Webhook } from "../types/webhook";

interface ReduxProps {
  webhooks: Webhook[];
  isLeftBarCollapsed: boolean;
  isEmpty: boolean;
  isFetching: boolean;
  resourceOwnerId?: string;
}

function useRedux(): ReduxProps {
  const { webhooks, isLeftBarCollapsed, isEmpty, isFetching, resourceOwnerId } =
    useSelector((state: RootState) => {
      const { webhooks, isFetching } = state.webhook;
      const { isLeftBarCollapsed } = state.app;
      const { resourceOwnerId } = state.resourceOwner;
      const isEmpty = !!(!isFetching && webhooks.length === 0);
      return {
        webhooks,
        isLeftBarCollapsed,
        isFetching,
        isEmpty,
        resourceOwnerId,
      };
    });

  return {
    webhooks,
    isLeftBarCollapsed,
    isFetching,
    isEmpty,
    resourceOwnerId,
  };
}

const WebhookListContainer = React.memo(() => {
  const { webhooks, isFetching, isEmpty, resourceOwnerId } = useRedux();

  useFetchWehhooks(resourceOwnerId);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <CreateWebhookBar />
        <WebhookList webhooks={webhooks} isEmpty={isEmpty} />
        <LoadingModal isOpen={isFetching} />
      </Main>
    </Layout>
  );
});

export default WebhookListContainer;
