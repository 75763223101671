import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

interface ContentProps {
  onCancel(): void;
  onSubmit(name: string): void;
}

const Content = (props: ContentProps) => {
  const { onCancel, onSubmit } = props;

  const { localized } = useLocale();

  const [remark, setRemark] = useState("");

  const onRemarkChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value) {
        setRemark(value as string);
      } else {
        setRemark("");
      }
    },
    []
  );

  const _onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();
      onSubmit(remark);
    },
    [onSubmit, remark]
  );

  return (
    <form onSubmit={_onSubmit}>
      <div className={styles["desc"]}>
        <FormattedMessage id="custom_model_editor.remark_dialog.desc" />
      </div>

      <TextField
        placeholder={localized("custom_model_editor.remark_dialog.placeholder")}
        multiline
        rows={5}
        onChange={onRemarkChange}
      />

      <DialogFooter>
        <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
        <PrimaryButton type="submit" text={localized("common.done")} />
      </DialogFooter>
    </form>
  );
};

interface Props extends ContentProps {
  isOpen: boolean;
}

export enum CustomModelRemarkModalResultType {
  Accept,
  Cancel,
}

type CustomModelRemarkModalResult = {
  type: CustomModelRemarkModalResultType;
  remark?: string;
};

export function useCustomModelRemarkModalHandle() {
  const [isOpen, setIsOpen] = useState(false);
  const [callback, setCallback] =
    React.useState<
      (response: CustomModelRemarkModalResult) => void | undefined
    >();

  const open =
    React.useCallback(async (): Promise<CustomModelRemarkModalResult> => {
      setIsOpen(true);
      return new Promise(resolve => {
        setCallback(() => resolve);
      });
    }, [setIsOpen, setCallback]);

  const onSubmit = React.useCallback(
    (remark: string) => {
      callback?.({
        type: CustomModelRemarkModalResultType.Accept,
        remark,
      });
      setIsOpen(false);
    },
    [callback]
  );

  const onCancel = React.useCallback(() => {
    callback?.({
      type: CustomModelRemarkModalResultType.Cancel,
    });
    setIsOpen(false);
  }, [callback]);

  const props = React.useMemo(
    () => ({
      isOpen,
      onCancel,
      onSubmit,
    }),
    [isOpen, onCancel, onSubmit]
  );

  const methods = React.useMemo(
    () => ({
      open,
    }),
    [open]
  );

  return React.useMemo(
    () => ({
      props,
      methods,
    }),
    [props, methods]
  );
}

const CustomModelRemarkModal = (props: Props) => {
  const { onCancel, onSubmit, isOpen } = props;

  const { localized } = useLocale();

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("custom_model_editor.remark_dialog.title"),
      titleProps: {
        className: styles["title"],
      },
    }),
    [localized]
  );

  return (
    <Dialog
      minWidth={405}
      hidden={!isOpen}
      onDismiss={onCancel}
      dialogContentProps={dialogContentProps}
    >
      <Content onCancel={onCancel} onSubmit={onSubmit} />
    </Dialog>
  );
};

export default CustomModelRemarkModal;
