import React, { useCallback } from "react";

import {
  CreateWorkspaceModal,
  useCreateWorkspaceModalHandle,
} from "../components/CreateWorkspaceModal";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { ExtractNote } from "../components/ExtractNote";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { README_IO_PAGE_URL } from "../constants";
import { useCommonCustomModelContainerState } from "../hooks/custom_model";
import { useDesktopApp } from "../hooks/desktopApp";
import { useUnsafeParams } from "../hooks/params";
import { useReadmeIO } from "../hooks/readmeIO";
import { BriefExtractorMapper } from "../types/mappers/extractor";
import {
  CustomModelNavBarLayout,
  CustomModelNavTabKey,
} from "./CustomModelNavBarLayout";
import HeaderContainer from "./Header";

type PathParam = {
  customModelId: string;
};

function _CustomModelExtractContainer() {
  const { customModelId } = useUnsafeParams<PathParam>();

  const containerState = useCommonCustomModelContainerState(customModelId);

  const { workerToken, formId, formName } =
    containerState.state === "success"
      ? {
          workerToken: containerState.workerToken,
          formId: containerState.customModel.formID || "",
          formName: containerState.customModel.name,
        }
      : { workerToken: undefined, formId: undefined, formName: undefined };

  const { onMacOsAppClick, onWindowsAppClick, onOpenInDesktopApp } =
    useDesktopApp(workerToken, formId, formName);

  const { onRedirectToReadmeIO } = useReadmeIO();

  const onOpenReference = useCallback(() => {
    onRedirectToReadmeIO(README_IO_PAGE_URL.referenceDocumentExtraction, {
      parameters: {
        formId,
      },
    });
  }, [formId, onRedirectToReadmeIO]);

  const {
    props: createWorkspaceModalProps,
    isCreating: isCreatingWorkspace,
    onCreateExtractorWorkspaceClick,
  } = useCreateWorkspaceModalHandle(
    containerState.state === "success"
      ? BriefExtractorMapper.fromCustomModel(containerState.customModel)
      : null
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
        ) : containerState.state === "success" &&
          containerState.customModel.formID ? (
          <CustomModelNavBarLayout
            selectedTab={CustomModelNavTabKey.API}
            customModel={containerState.customModel}
          >
            <ExtractNote
              token={containerState.workerToken}
              tokens={containerState.workerTokens}
              onSelectToken={containerState.onSelectWorkerToken}
              extractorId={containerState.customModel.formID}
              onMacOsAppClick={onMacOsAppClick}
              onWindowsAppClick={onWindowsAppClick}
              onOpenInDesktopApp={onOpenInDesktopApp}
              onOpenReference={onOpenReference}
              workspaceSectionProps={{
                extractorName: containerState.customModel.name,
                workspaces: containerState.customModel.workspaces,
                onCreateWorkspace: onCreateExtractorWorkspaceClick,
                isCreating: isCreatingWorkspace,
              }}
            />
          </CustomModelNavBarLayout>
        ) : null}
        <CreateWorkspaceModal {...createWorkspaceModalProps} />
      </Main>
    </Layout>
  );
}

export const CustomModelExtractContainer = React.memo(
  _CustomModelExtractContainer
);
export default CustomModelExtractContainer;
