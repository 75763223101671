.mission-bar {
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  min-width: 0px;
  gap: 16px;

  .mission-bar-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0px;
  }

  .mission-bar-icon {
    width: 20px;
  }

  .mission-bar-text {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 0px;
  }

  .mission-bar-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #323130;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .mission-bar-subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #605e5c;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .mission-bar-right {
    flex: 1 0 content;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
  }
}

.mission-bar-type-info {
  background: #fff4ce;
}

.mission-bar-type-warning {
  background: #fed9cc;
}

.mission-bar-type-success {
  background: #dff6dd;
}
