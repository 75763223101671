@import "../../styles/variables.scss";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.tableContainer {
  position: relative;
  flex: 1 1;
  overflow: visible;

  margin: 20px 20px 0px 20px;

  display: flex;
  flex-direction: column;
  :global(.ms-Viewport) {
    flex: 1 1 0px; /* 0px instead of 0% to make scroll work */
    overflow: auto;
  }

  :global(.ms-DetailsList) {
    overflow: unset; /* for sticky header to work */
  }
  :global(.ms-DetailsList-headerWrapper) {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  :global(.ms-DetailsHeader) {
    padding-top: 0; /* remove stock padding */
  }
}

.notFoundMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: #605e5c;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.uploadOverlay {
  position: absolute;
  top: 42;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px;

  background: #f6fdfc;
  outline: 2px dashed var(--primary-color, #25d0b1);
  outline-offset: -24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uploadWidget {
  position: absolute;
  bottom: 0;
  right: 0;
}

.loadingSpinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
}

.dragDropMessage {
  margin-top: 10px;

  color: #00bd9b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.emptyTitle {
  max-width: 370px;
  text-align: center;
  margin-top: 10px;

  color: #00bd9b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.emptyMessage {
  max-width: 370px;
  white-space: pre-line;
  text-align: center;
  margin-bottom: 20px;

  color: var(--type-colors-primary, #323130);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
