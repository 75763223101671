import cn from "classnames";
import * as React from "react";

import { JSON_INDENTATION } from "../../constants/format";
import styles from "./styles.module.scss";

const DEFAULT_HIGHLIGHT_SEPARATOR =
  "HIGHLIGHTED_SEPARATOR_7c84f593-7303-4c33-9e93-dbb80d6c0125";

interface Props {
  rawObject: object;
}

type Line = {
  content: string;
  isHighlighted: boolean;
  isFirst: boolean;
  isLast: boolean;
};

export function insertHighlightSeparatorToList(
  list: any[],
  pos: number
): any[] {
  const listWithSeparator = list
    .slice(0, pos)
    .concat([DEFAULT_HIGHLIGHT_SEPARATOR])
    .concat(list.slice(pos, pos + 1))
    .concat([DEFAULT_HIGHLIGHT_SEPARATOR])
    .concat(list.slice(pos + 1, list.length));
  return listWithSeparator;
}

export function useJsonRawViewer(props: Props) {
  const { rawObject } = props;

  const lines = React.useMemo(() => {
    const plaintext = JSON.stringify(rawObject, null, JSON_INDENTATION);
    const plaintextLines = plaintext.split("\n");
    let isHighlighted = false;

    const lines: Line[] = plaintextLines
      .map(line => {
        let content: string | undefined = line;
        if (line.includes(DEFAULT_HIGHLIGHT_SEPARATOR)) {
          isHighlighted = !isHighlighted;
          content = undefined;
        }
        return {
          content,
          isHighlighted,
          isFirst: false,
          isLast: false,
        } as Line;
      })
      .filter(line => line.content !== undefined);

    lines.forEach((line, index) => {
      if (line.isHighlighted) {
        if (index === 0 || !lines[index - 1].isHighlighted) {
          lines[index].isFirst = true;
        }
        if (index + 1 < lines.length && !lines[index + 1].isHighlighted) {
          lines[index].isLast = true;
        }
      }
    });
    return lines;
  }, [rawObject]);

  return React.useMemo(() => {
    return {
      lines,
    };
  }, [lines]);
}

export function JsonRawViewerImpl(props: ReturnType<typeof useJsonRawViewer>) {
  return (
    <div className={styles["container"]}>
      {props.lines.map((line, index) => {
        const className = cn(styles["line"], {
          [styles["highlighted-line"]]: line.isHighlighted,
          [styles["first-highlighted-line"]]: line.isFirst,
          [styles["last-highlighted-line"]]: line.isLast,
        });
        return (
          <div key={`line/${index}`} className={className}>
            {line.content}
          </div>
        );
      })}
    </div>
  );
}

export function JsonRawViewer(props: Props) {
  const states = useJsonRawViewer(props);
  return <JsonRawViewerImpl {...states} />;
}
