.beta-tag {
  display: inline-block;
  padding: 3px 5px;

  color: #000000;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
}
