import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";

import { apiClient } from "../apiClient";
import { useAppDispatch } from "../hooks/redux";
import type {
  GoogleSheetFieldMapping,
  GoogleSheetList,
  GoogleSpreadsheetList,
} from "../types/googleSheet";

export const ClearSheetData = createAction("googleSheet/clearSheetData");

export const GotSpreadsheetList = createAction<{
  oauthCredentialId: string;
  data: GoogleSpreadsheetList;
}>("googleSheet/gotSpreadsheetList");

export const GotSpreadsheetColumnList = createAction<{
  spreadsheetId: string;
  data: GoogleSheetList;
}>("googleSheet/gotSpreadsheetColumnList");

export function useGoogleSheetActionCreator() {
  const dispatch = useAppDispatch();

  const clearSheetData = useCallback(() => {
    dispatch(ClearSheetData());
  }, [dispatch]);

  const createWorkspaceIntegration = useCallback(
    async (
      worksapceId: string,
      oauthCredentialId: string,
      spreadsheetId: string,
      sheetId: string | null,
      mappings: GoogleSheetFieldMapping[],
      isUpsertMode: boolean
    ) => {
      const response = await apiClient.createGoogleSheetExport(
        worksapceId,
        oauthCredentialId,
        spreadsheetId,
        sheetId,
        {
          mappings,
          isUpsertMode,
        }
      );
      return response;
    },
    []
  );

  const listSpreadsheets = useCallback(
    async (oauthCredentialId: string, query: string | null) => {
      const response = await apiClient.listGoogleSheets(
        oauthCredentialId,
        query
      );
      dispatch(GotSpreadsheetList({ data: response, oauthCredentialId }));
    },
    [dispatch]
  );

  const listSheetColumns = useCallback(
    async (oauthCredentialId: string, spreadsheetId: string) => {
      const response = await apiClient.listGoogleSheetColumns(
        oauthCredentialId,
        spreadsheetId
      );
      dispatch(GotSpreadsheetColumnList({ data: response, spreadsheetId }));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      clearSheetData,
      createWorkspaceIntegration,
      listSpreadsheets,
      listSheetColumns,
    }),
    [
      clearSheetData,
      createWorkspaceIntegration,
      listSpreadsheets,
      listSheetColumns,
    ]
  );
}
