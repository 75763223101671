@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.image-token-modal {
  :global(.ms-ChoiceField) {
    display: inline-block;
    margin-right: 60px;
    margin-top: 10px;
  }

  :global(.ms-Dialog-title) {
    padding-bottom: 8px;
  }

  .image {
    height: 32px;
    width: 122px;
    object-fit: contain;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 5px;
    border: 0;

    thead {
      border-collapse: separate;
      border-spacing: 0px 50px;

      td {
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 1px solid #edebe9;
        border-bottom: 1px solid #edebe9;

        span {
          font-weight: 600;
        }
      }
    }
  }

  .name-value-cell {
    width: 400px;
  }

  td {
    padding-top: 10px;
    padding-right: 20px;
  }

  .name-value-field {
    max-width: 148px;
  }

  .separator {
    height: 1px;
    width: 100%;
    margin: 16px 0 16px;
    background-color: $separator-color-1;
  }

  .add-tokens-session {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .add-tokens-title {
      font-weight: 600;
    }

    :global(.ms-Button) {
      margin-left: auto;
    }
  }

  .invisible-image-upload-input {
    display: none;
  }

  .error-td {
    padding: 0px;
  }
}
