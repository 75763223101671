import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate } from "react-router";

import DocumentTabPane from "../components/DocumentTabPane";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import FormInspectorV2 from "../components/FormInspectorV2";
import FormNotSavedPrompt from "../components/FormNotSavedPrompt";
import Labeller, { ToolLabel } from "../components/Labeller";
import {
  ContentLayout,
  ContentMain,
  ContentRight,
  Layout,
  Main,
  Top,
} from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import NoFormImagePlaceholder from "../components/NoFormImagePlaceholder";
import {
  isEditablePrebuiltExtractors,
  isPrebuiltExtractors,
} from "../constants/prebuiltExtractor";
import HeaderContainer from "../containers/Header";
import { FormEditorProvider, useFormEditor } from "../contexts/formEditor";
import { useUnsafeParams } from "../hooks/params";
import { FormEditorTab, PathParam } from "../models";
import FixedLayoutFormTutorialContainer from "./FixedLayoutFormTutorial";
import { FormNavBarLayout, FormNavTabKey } from "./FormNavBarLayout";

function useSetUpForm(formId: string) {
  const { form, getForm, getFormImageSize, imageSize, setIsFailedToFetchForm } =
    useFormEditor();

  useEffect(() => {
    if (!form || form.id !== formId) {
      getForm(formId)
        .then(getFormImageSize)
        .catch(() => {
          setIsFailedToFetchForm(true);
        });
    } else if (!imageSize) {
      getFormImageSize();
    }
  }, [
    form,
    getForm,
    getFormImageSize,
    imageSize,
    setIsFailedToFetchForm,
    formId,
  ]);
}

function useFormInspectorTabSelection() {
  const { selectedTab, setSelectedTab } = useFormEditor();

  const onSelectFormInspectorTab = useCallback(
    tabClicked => {
      setSelectedTab(tabClicked);
    },
    [setSelectedTab]
  );

  return { selectedTab, setSelectedTab, onSelectFormInspectorTab };
}

function useAutoSelectFormInspectorTab({
  formId,
  setSelectedTab,
}: {
  formId: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<FormEditorTab>>;
}) {
  const { form } = useFormEditor();

  const [previousSelectedFormId, setPreviousSelectedFormId] =
    useState<string>("");

  useEffect(() => {
    if (form && form.id === formId && previousSelectedFormId !== formId) {
      const tab = form.image === "" ? "document" : "region";
      setSelectedTab(tab);
      setPreviousSelectedFormId(formId);
    }
  }, [form, formId, setSelectedTab, previousSelectedFormId]);
}

function useTemplateSentry(formId: string) {
  const navigate = useNavigate();
  const { form } = useFormEditor();

  useEffect(() => {
    if (formId === form?.id && form?.isTemplate) {
      navigate(`/form/${formId}/test`);
    }
  }, [formId, form, navigate]);
}

const FormEditorContainerImpl = React.memo(() => {
  const {
    isFailedToFetchForm,
    form,
    canvasStore,
    imageSize,
    onSelectAnchor,
    onSelectField,
    onSelectDetectionRegion,
    selectedAnchorId,
    selectedFieldId,
    selectedDetectionRegionId,
    isSavingForm,
  } = useFormEditor();

  const [showAllSetTutorial, setShowAllSetTutorial] = useState(false);
  const [enableInfoIcon, setEnableInfoIcon] = useState(false);

  const { formId } = useUnsafeParams<PathParam>();

  useTemplateSentry(formId);
  useSetUpForm(formId);

  const { selectedTab, setSelectedTab, onSelectFormInspectorTab } =
    useFormInspectorTabSelection();

  useAutoSelectFormInspectorTab({
    formId,
    setSelectedTab,
  });

  const isFormImageReady =
    form !== undefined && form.image && imageSize === undefined;
  const isFormLoaded = form !== undefined && form.id === formId;
  const isLoading = (!isFormLoaded || isFormImageReady) && !isFailedToFetchForm;

  const isNotEditable =
    form?.config.prebuilt_extractor &&
    isPrebuiltExtractors(form.config.prebuilt_extractor) &&
    !isEditablePrebuiltExtractors(form.config.prebuilt_extractor);

  const defaultSelectedTool: ToolLabel = useMemo(() => {
    if (form && form.anchors.length < 3) {
      return "anchor-tool";
    }
    return "select-tool";
  }, [form]);

  if (isNotEditable && !isLoading) {
    return <Navigate to="../test" replace />;
  }

  const isEditablePrebuiltExtractor =
    form?.config.prebuilt_extractor &&
    isEditablePrebuiltExtractors(form.config.prebuilt_extractor);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      {isLoading ? (
        <LoadingModal isOpen={isLoading} />
      ) : isFailedToFetchForm ? (
        <ErrorPlaceholder messageId="common.fail_to_fetch_form" />
      ) : (
        form && (
          <>
            <Main hasTop={true}>
              <FormNavBarLayout
                selectedTab={FormNavTabKey.ManageRules}
                form={form}
              >
                <ContentLayout>
                  <ContentMain>
                    {isEditablePrebuiltExtractor ? (
                      <DocumentTabPane
                        isPrebuiltExtractor={true}
                        showSaveButton={true}
                      />
                    ) : form.image && imageSize ? (
                      <>
                        <FixedLayoutFormTutorialContainer
                          showAllSetTutorial={showAllSetTutorial}
                          setShowAllSetTutorial={setShowAllSetTutorial}
                          setEnableInfoIcon={setEnableInfoIcon}
                        />
                        <Labeller
                          canvasStore={canvasStore}
                          imageURL={form.image}
                          width={imageSize.width}
                          height={imageSize.height}
                          anchors={form.anchors}
                          fields={form.fields}
                          defaultSelectedTool={defaultSelectedTool}
                          detectionRegions={form.detectionRegions}
                          onSelectAnchor={onSelectAnchor}
                          onSelectField={onSelectField}
                          onSelectDetectionRegion={onSelectDetectionRegion}
                          setShowAllSetTutorial={setShowAllSetTutorial}
                          setSelectedTab={setSelectedTab}
                          selectedAnchorId={selectedAnchorId}
                          selectedFieldId={selectedFieldId}
                          selectedDetectionRegionId={selectedDetectionRegionId}
                          enableInfoIcon={enableInfoIcon}
                        />
                      </>
                    ) : (
                      <NoFormImagePlaceholder />
                    )}
                  </ContentMain>
                  <ContentRight>
                    <FormInspectorV2
                      form={form}
                      selectedTab={selectedTab}
                      onSelectFormInspectorTab={onSelectFormInspectorTab}
                    />
                  </ContentRight>
                </ContentLayout>
              </FormNavBarLayout>
            </Main>
            <FormNotSavedPrompt />
            <LoadingModal isOpen={isSavingForm} messageId="common.saving" />
          </>
        )
      )}
    </Layout>
  );
});

const FormEditorContainer = React.memo(() => {
  return (
    <FormEditorProvider>
      <FormEditorContainerImpl />
    </FormEditorProvider>
  );
});

export default FormEditorContainer;
