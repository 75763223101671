@import "../../styles/variables.scss";

.container {
  background-color: #fff;
  box-shadow: 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13),
    0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 350px;
  z-index: 100;
  transition: left 0.3s ease;
  display: flex;
  flex-direction: column;
}

.container-closed {
  position: fixed;
  top: 0px;
  left: -$left-bar-full-width-small-device;
  bottom: 0px;
  width: $left-bar-full-width-small-device;
  z-index: 100;
}

.header {
  height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-colors-divider, #edebe9);
}

.logo {
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.backdrop {
  z-index: 50;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.content {
  flex: 1 1 0;
  overflow-y: auto;
  overflow-x: hidden;
}
