import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useAuditLogActionCreator } from "../actions/auditLog";
import AuditLog from "../components/AuditLog";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import { AuditLogJob } from "../types/auditLog";
import HeaderContainer from "./Header";

function _AuditLogContainer() {
  const [auditLogJobs, setAuditLogJobs] = useState([] as AuditLogJob[]);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const states = useSelector((state: RootState) => state);
  const { resourceOwnerId } = states.resourceOwner;
  const currentTeam = states.user.currentUser?.teams.find(
    team => team.id === resourceOwnerId
  );
  const teamCreatedAt = useMemo(
    () => currentTeam?.createdAt || new Date(),
    [currentTeam]
  );

  const { generateAuditLogCsv, listAuditLogJobs } = useAuditLogActionCreator();

  const refreshAuditLogJobs = useCallback(() => {
    // Increase counter to reset the effect to refresh audit log jobs
    setRefreshCounter(refreshCounter + 1);
  }, [refreshCounter]);

  const getAuditLogJobs = useCallback(async () => {
    try {
      const jobs = await listAuditLogJobs();
      setAuditLogJobs(jobs);
      return jobs.every(x => ["Completed", "Failed"].includes(x.status));
    } catch {
      toast.error("error.audit_log.failed_to_load_audit_logs");
      return false;
    }
  }, [listAuditLogJobs, toast]);

  useEffect(() => {
    if (refreshCounter > 0) {
      let timer: ReturnType<typeof setTimeout> | null = null;
      getAuditLogJobs().then(isAllJobFinished => {
        if (isAllJobFinished) {
          setIsLoading(false);
        } else {
          timer = setTimeout(refreshAuditLogJobs, 5000);
        }
      });

      return () => {
        if (timer !== null) {
          clearTimeout(timer);
        }
      };
    }
    return () => {};
  }, [refreshCounter, getAuditLogJobs, refreshAuditLogJobs]);

  useEffect(() => {
    setIsLoading(true);
    getAuditLogJobs()
      .then(isAllJobFinished => {
        if (!isAllJobFinished) {
          refreshAuditLogJobs();
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [resourceOwnerId, getAuditLogJobs, refreshAuditLogJobs]);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <AuditLog
          startYear={teamCreatedAt.getFullYear()}
          startMonth={teamCreatedAt.getMonth()}
          auditLogJobs={auditLogJobs}
          generateAuditLogCsv={generateAuditLogCsv}
          refreshAuditLogJobs={refreshAuditLogJobs}
          setIsLoading={setIsLoading}
        />
      </Main>
      <LoadingModal messageId="common.loading" isOpen={isLoading} />
    </Layout>
  );
}

export const AuditLogContainer = _AuditLogContainer;
export default AuditLogContainer;
