import * as React from "react";
import { useNavigate } from "react-router";

import {
  NavBarLayout,
  useNavBarLayoutBreadcrumbItems,
} from "../components/NavBarLayout";
import { NavTab } from "../components/NavTabBar";
import { UserFeatureFlag } from "../constants";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { PathParam } from "../models";
import { CustomModel } from "../types/customModel";

/* The original custom model nav bar layout
 */

export enum CustomModelNavTabKey {
  Setup = "setup",
  Label = "label",
  Model = "model",
  TestExtractor = "test",
  TestExtractorV1 = "test-v1",
  API = "extract",
  Settings = "setting",
}

export const CustomModelNavTab: Record<CustomModelNavTabKey, NavTab> = {
  [CustomModelNavTabKey.Setup]: {
    key: CustomModelNavTabKey.Setup,
    labelId: "header.setup",
  },
  [CustomModelNavTabKey.Label]: {
    key: CustomModelNavTabKey.Label,
    labelId: "header.label",
  },
  [CustomModelNavTabKey.Model]: {
    key: CustomModelNavTabKey.Model,
    labelId: "header.model",
  },
  [CustomModelNavTabKey.TestExtractor]: {
    key: CustomModelNavTabKey.TestExtractor,
    labelId: "extractor.tab.test",
  },
  [CustomModelNavTabKey.TestExtractorV1]: {
    key: CustomModelNavTabKey.TestExtractorV1,
    labelId: "extractor.tab.test_v1",
  },
  [CustomModelNavTabKey.API]: {
    key: CustomModelNavTabKey.API,
    labelId: "extractor.tab.extract",
  },
  [CustomModelNavTabKey.Settings]: {
    key: CustomModelNavTabKey.Settings,
    labelId: "extractor.tab.setting",
  },
};

function useCustomModelNavBarLayout(
  selectedTab: string,
  customModel?: CustomModel
) {
  const { customModelId } = useUnsafeParams<PathParam>();
  const navigate = useNavigate();

  const breadcrumbItems = useNavBarLayoutBreadcrumbItems(customModel?.name);

  const { isV1TestTabEnabled } = useAppSelector(state => ({
    isV1TestTabEnabled: state.resourceOwner.isFeatureEnabled.apply(
      state.resourceOwner
    )(UserFeatureFlag.V1TestTab),
  }));

  const tabs = React.useMemo(() => {
    const shouldHideV1Tab = !isV1TestTabEnabled;

    const isReadyToLabel =
      customModel?.config.labellingStarted ||
      customModel?.startTrainingAt !== null;
    const hasModelVerison =
      customModel !== undefined && customModel?.modelVersions.length > 0;
    const hasDeployedModel =
      customModel !== undefined &&
      customModel?.modelVersions.findIndex(x => x.isActive) >= 0 &&
      customModel?.formID;

    const tabKeys = [
      CustomModelNavTabKey.Setup,
      (isReadyToLabel || hasModelVerison) && CustomModelNavTabKey.Label,
      hasModelVerison && CustomModelNavTabKey.Model, //@FIXME
      hasDeployedModel && CustomModelNavTabKey.TestExtractor,
      hasDeployedModel &&
        !shouldHideV1Tab &&
        CustomModelNavTabKey.TestExtractorV1,
      hasDeployedModel && CustomModelNavTabKey.API,
      hasDeployedModel && CustomModelNavTabKey.Settings,
    ];

    return tabKeys
      .filter(Boolean)
      .map(key => CustomModelNavTab[key as CustomModelNavTabKey]);
  }, [isV1TestTabEnabled, customModel]);

  const onTabSelect = React.useCallback(
    (key: string) => {
      const mapTable = {
        [CustomModelNavTabKey.Setup]: "",
        [CustomModelNavTabKey.Label]: "label",
        [CustomModelNavTabKey.Model]: "model",
        [CustomModelNavTabKey.TestExtractor]: "test",
        [CustomModelNavTabKey.TestExtractorV1]: "test-v1",
        [CustomModelNavTabKey.API]: "extract",
        [CustomModelNavTabKey.Settings]: "setting",
      } as { [key in CustomModelNavTabKey]: string };

      const path = mapTable[key as CustomModelNavTabKey];
      navigate(`/custom-model/${customModelId}/${path}`);
    },
    [navigate, customModelId]
  );

  const result = React.useMemo(() => {
    return {
      onTabSelect,
      tabs,
      breadcrumbItems,
      selectedTab,
    };
  }, [breadcrumbItems, onTabSelect, selectedTab, tabs]);

  return result;
}

type CustomModelNavBarLayoutProps = {
  children: React.ReactNode;
  selectedTab?: string;
  tabBarVisible?: boolean;
  customModel?: CustomModel;
};

export function CustomModelNavBarLayoutImpl(
  props: CustomModelNavBarLayoutProps &
    ReturnType<typeof useCustomModelNavBarLayout>
) {
  const {
    children,
    tabs,
    onTabSelect,
    breadcrumbItems,
    selectedTab,
    tabBarVisible,
  } = props;

  return (
    <>
      <NavBarLayout
        tabs={tabs}
        onTabSelect={onTabSelect}
        breadcrumbItems={breadcrumbItems}
        selectedTab={selectedTab}
        tabBarVisible={tabBarVisible}
      >
        {children}
      </NavBarLayout>
    </>
  );
}

export function CustomModelNavBarLayout(props: CustomModelNavBarLayoutProps) {
  const state = useCustomModelNavBarLayout(
    props.selectedTab ?? CustomModelNavTabKey.Setup,
    props.customModel
  );

  return <CustomModelNavBarLayoutImpl {...props} {...state} />;
}
