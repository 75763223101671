@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.webhook-form {
  overflow-y: auto;
  background-color: #fff;
  padding: 32px 26px;

  .title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
  }

  .fields-container {
    max-width: 532px;
    padding: 15px 6px;

    .input-field {
      margin-top: 15px;

      .connect-container {
        border: 1px solid #edebe9;
        border-radius: 2px;
        padding: 0 10px;
        height: 160px;
        overflow-y: auto;

        &.error {
          border-color: $input-error-color;
        }

        .connect-list-header {
          margin-top: 5px;
          border-bottom: 1px solid #cccccc;
          margin-bottom: 13px;
        }

        .row {
          margin-bottom: 8px;
        }
      }
    }
  }

  .error-message {
    font-size: 12px;
    font-weight: 400;
    color: $input-error-color;
  }

  .custom-headers-title-bar {
    border-top: 1px solid #edebe9;
    border-bottom: 1px solid #edebe9;
    margin-top: 13px;
    height: 42px;
    line-height: 40px;

    .heading1 {
      width: 248px;
      display: inline-block;
      padding: 0px 0px;
    }

    .heading2 {
      width: 240px;
      display: inline-block;
      padding: 0px 0px;
    }
  }

  .custom-headers-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .name-column {
      display: inline-block;
      width: 240px;
    }

    .value-column {
      display: inline-block;
      padding-left: 8px;
      width: 240px;
    }

    .delete-button {
      cursor: pointer;
      padding-top: 18px;
      padding-left: 12px;
      padding-right: 12px;
      user-select: none;
    }
  }

  .add-custom-header-button {
    cursor: pointer;
    color: #25d0b1;
    margin-top: 12px;
    display: inline-block;
    user-select: none;
  }

  .add-custom-header-disabled {
    cursor: pointer;
    color: #a19f9d;
    margin-top: 12px;
    display: inline-block;
    user-select: none;
  }
}
