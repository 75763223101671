import { Icon, Link, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React, { useCallback } from "react";

import { useTokenGroupTabPane } from "../../contexts/tokenGroupTabPane";
import { useTeamPermission } from "../../hooks/permission";
import {
  TokenGroup,
  TokenGroupImage,
  TokenGroupText,
} from "../../types/tokenGroup";
import { Img } from "../Img";
import styles from "./styles.module.scss";

interface TextTokenCardProps {
  textToken: TokenGroupText;
  onDelete: (e: React.MouseEvent<HTMLDivElement>) => void;
  onEditTextToken: (textToken: TokenGroupText) => () => void;
}

export const TextTokenCard = React.memo((props: TextTokenCardProps) => {
  const { textToken, onDelete, onEditTextToken } = props;
  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div
      className={classnames(styles["text-token-card"], styles["token-card"])}
    >
      <Text onClick={onEditTextToken(textToken)}>{textToken.value}</Text>
      {hasPermissionToEditResource && (
        <Icon
          className={styles["delete-button"]}
          onClick={onDelete}
          iconName="StatusCircleErrorX"
        />
      )}
    </div>
  );
});

interface ImageTokenCardProps {
  imageToken: TokenGroupImage;
  onRemove: () => void;
  onEditImageToken: (imageToken: TokenGroupImage) => () => void;
}

export const ImageTokenCard = React.memo(
  ({ imageToken, onRemove, onEditImageToken }: ImageTokenCardProps) => {
    const { hasPermissionToEditResource } = useTeamPermission();

    return (
      <div
        className={classnames(styles["image-token-card"], styles["token-card"])}
      >
        <Img
          src={imageToken.url}
          frameClass={styles["image"]}
          onClick={onEditImageToken(imageToken)}
        />
        <div className={styles["bottom-bar"]}>
          <Text>{imageToken.name}</Text>

          {hasPermissionToEditResource && (
            <Icon
              className={styles["delete-button"]}
              onClick={onRemove}
              iconName="StatusCircleErrorX"
            />
          )}
        </div>
      </div>
    );
  }
);

interface Props {
  tokenGroup: TokenGroup;
  tokenGroupIndex: number;
}
const TokenGroupContent = React.memo((props: Props) => {
  const { tokenGroup, tokenGroupIndex } = props;

  const {
    onCreateTextToken,
    onCreateImageToken,
    onDeleteTextToken,
    onDeleteImageToken,
    uploadingImageTokenCount,
    onEditTextToken,
    onEditImageToken,
  } = useTokenGroupTabPane();

  const { hasPermissionToEditResource } = useTeamPermission();

  const _onDeleteTextToken = useCallback(
    (index: number) => () => {
      onDeleteTextToken(tokenGroupIndex, index);
    },
    [onDeleteTextToken, tokenGroupIndex]
  );

  const _onDeleteImageToken = useCallback(
    (index: number) => () => {
      onDeleteImageToken(tokenGroupIndex, index);
    },
    [onDeleteImageToken, tokenGroupIndex]
  );

  const { shouldShowTextToken, shouldShowImageToken } = React.useMemo(
    () => ({
      shouldShowTextToken: !!(
        !tokenGroup.tokenType || tokenGroup.tokenType === "texts"
      ),
      shouldShowImageToken: !!(
        !tokenGroup.tokenType || tokenGroup.tokenType === "images"
      ),
    }),
    [tokenGroup]
  );

  return (
    <div className={styles["token-group-content"]}>
      {shouldShowTextToken && (
        <>
          <div className={styles["token-row"]}>
            {tokenGroup.texts.map((textToken, i) => (
              <TextTokenCard
                key={textToken.id}
                textToken={textToken}
                onDelete={_onDeleteTextToken(i)}
                onEditTextToken={onEditTextToken}
              />
            ))}
          </div>

          {hasPermissionToEditResource && (
            <Link className={styles["link"]} onClick={onCreateTextToken}>
              <FormattedMessage id="add.text.token.link" />
            </Link>
          )}
        </>
      )}
      {shouldShowImageToken && (
        <>
          <div
            className={classnames(
              styles["token-row"],
              shouldShowTextToken && styles["token-row-second"]
            )}
          >
            {shouldShowImageToken &&
              tokenGroup.images.map((imageToken, i) => (
                <ImageTokenCard
                  key={imageToken.id}
                  imageToken={imageToken}
                  onRemove={_onDeleteImageToken(i)}
                  onEditImageToken={onEditImageToken}
                />
              ))}
            {uploadingImageTokenCount > 0 && (
              <Spinner
                className={styles["image-token-spinner"]}
                size={SpinnerSize.medium}
              />
            )}
          </div>
          {hasPermissionToEditResource && (
            <Link className={styles["link"]} onClick={onCreateImageToken}>
              <FormattedMessage id="add.image.token.link" />
            </Link>
          )}
        </>
      )}
    </div>
  );
});

export default TokenGroupContent;
