@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
  display: flex;
  height: 100%;
  overflow: auto;
  background-color: $neutral-lighter-alt;
}

.content {
  width: 100%;
  max-width: 618px;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: $bgcolor;
  padding: 24px 20px;
  margin-top: 45px;

  .upload-icon {
    @include no-select();
    font-size: 60px;
  }

  .instruction1 {
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 24px;
    font-size: 20px;
  }

  .drop-zone {
    width: 100%;
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $neutral-lighter-alt;
    border: 1px dashed #d2d0ce;
  }

  .separator {
    width: 100%;
    margin-top: 24px;
    border-top: 1px solid #edebe9;
    margin-bottom: 24px;
  }

  .instruction2 {
    font-weight: 600;
    line-height: 14px;
    font-size: 16px;
  }

  .instruction3 {
    margin-top: 8px;
    color: #605e5c;
    line-height: 140%;
    font-size: 14px;

    .highlights {
      color: #000;
    }
  }

  .dos-and-donts {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .dos,
  .donts {
    width: calc(50% - 12px);
  }

  .dos-title,
  .donts-title {
    font-weight: 600;
    font-size: 14px;
  }

  .dos-title {
    color: $theme-primary;
  }

  .donts-title {
    color: $input-error-color;
  }

  .dos-image,
  .donts-image {
    margin-top: 13px;
    width: 100%;
    height: 166px;
    object-fit: cover;
  }

  .dos-and-donts-item {
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
  }

  .tick,
  .cross {
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
    height: 13px;
    width: 15px;
    display: inline-block;
    margin-right: 11px;
    margin-bottom: -1px;
  }

  .tick {
    background-image: url("../../images/icon-green-tick.svg");
  }

  .cross {
    background-image: url("../../images/icon-red-cross.svg");
  }
}
