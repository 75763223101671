export const PubliclyAvailableLLMModels = [
  "gpt-4o",
  "gpt-4o-mini",
  "gpt-3.5-turbo",
  "claude-3-5-sonnet",
] as const;
export const RestrictedLLMModels = [
  "gpt-4",
  "gemini-1.5-flash",
  "gemini-1.5-pro",
  "claude-3-haiku",
  "claude-3-sonnet",
  "gpt-3.5-turbo-test",
  "gpt-4o-test",
  "gpt-4o-mini-test",
  "formx-self-host-llm",
] as const;
export const LLMModelsWithVision = [
  "claude-3-haiku",
  "claude-3-sonnet",
  "claude-3-5-sonnet",
  "gpt-4o",
  "gpt-4o-test",
  "gpt-4o-mini",
  "gpt-4o-mini-test",
  "gemini-1.5-flash",
  "gemini-1.5-pro",
];
export const AllLLMModels = [
  ...PubliclyAvailableLLMModels,
  ...RestrictedLLMModels,
] as const;

export const DefaultLLMModel = "gpt-4o-mini" as const;

export const LLMModelNameKey: Record<(typeof AllLLMModels)[number], string> = {
  "gpt-3.5-turbo": "llm_model.gpt-3.5-turbo",
  "gpt-4": "llm_model.gpt-4",
  "gemini-1.5-flash": "llm_model.gemini-1.5-flash",
  "gemini-1.5-pro": "llm_model.gemini-1.5-pro",
  "claude-3-haiku": "llm_model.claude-3-haiku",
  "claude-3-sonnet": "llm_model.claude-3-sonnet",
  "claude-3-5-sonnet": "llm_model.claude-3-5-sonnet",
  "gpt-3.5-turbo-test": "llm_model.gpt-3.5-turbo-test",
  "gpt-4o": "llm_model.gpt-4o",
  "gpt-4o-test": "llm_model.gpt-4o-test",
  "gpt-4o-mini": "llm_model.gpt-4o-mini",
  "gpt-4o-mini-test": "llm_model.gpt-4o-mini-test",
  "formx-self-host-llm": "llm_model.formx-self-host-llm",
};
