@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.container {
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  background-color: #f6fdfc;
  border-left: 3px solid $theme-dark;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  width: 305px;
  position: fixed;
  margin-left: 35px;
  margin-top: 12px;
}

.title {
  display: flex;
  font-size: 14px;
  align-items: center;
  padding: 0;
}

.title-icon {
  font-size: 12px;
  margin-right: 7.5px;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.close-button {
  font-size: 12px;
  color: $even-darker-primary-color;
  height: 16px;
  padding: 0;

  &:hover {
    opacity: 0.7;
  }
}

.highlight {
  font-weight: 600;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

.remind-button {
  cursor: pointer;
  user-select: none;
  margin-top: 8px;
  color: #53b2a1;

  &:hover {
    opacity: 0.7;
  }
}
