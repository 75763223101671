.container {
  background: rgba(207, 241, 234, 0.7);
  border: 2px dashed var(--primary-color, #25d0b1);
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.icon {
  color: #25d0b1;
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text {
  color: #25d0b1;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
