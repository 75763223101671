import { Icon, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React from "react";
import * as uuid from "uuid";

import { useTeamPermission } from "../../hooks/permission";
import { ExtractorOptionForCombinedExtractor } from "../../types/extractor";
import {
  DetailFormGroup,
  FormGroupAnchor,
  FormGroupAnchorBase,
} from "../../types/formGroup";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import { FormGroupAnchorModal } from "./FormGroupAnchorModal";
import styles from "./styles.module.scss";

interface Props {
  formGroup: DetailFormGroup;
  extractorOptions: ExtractorOptionForCombinedExtractor[];
  onUpdateAnchors: (anchors: FormGroupAnchorBase[]) => Promise<void>;
}

interface FormGroupAnchorTableRowProps {
  anchor: FormGroupAnchor;
  shouldShowEditButton: boolean;
  onEditAnchor: (anchor: FormGroupAnchor) => void;
  onRemoveAnchor: (anchorId: string) => Promise<void>;
}

function FormGroupAnchorTableRow(props: FormGroupAnchorTableRowProps) {
  const { anchor, shouldShowEditButton, onEditAnchor, onRemoveAnchor } = props;
  return (
    <tr className={styles["form-group-anchor-table-row"]}>
      <td>{anchor.form.name}</td>
      {shouldShowEditButton && (
        <td className={styles["action-container"]}>
          <Icon
            iconName="Edit"
            className={styles["action-button"]}
            onClick={() => onEditAnchor(anchor)}
          />
          <Icon
            iconName="Delete"
            className={styles["action-button"]}
            onClick={() => onRemoveAnchor(anchor.id)}
          />
        </td>
      )}
    </tr>
  );
}

export const FormGroupAnchorList = React.memo((props: Props) => {
  const { formGroup, extractorOptions, onUpdateAnchors } = props;

  const [isAnchorModalOpen, setIsAnchorModalOpen] =
    React.useState<boolean>(false);
  const [anchorToEdit, setAnchorToEdit] = React.useState<
    FormGroupAnchor | undefined
  >(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { hasPermissionToEditResource } = useTeamPermission();

  const onEditAnchor = React.useCallback((anchor: FormGroupAnchor) => {
    setAnchorToEdit(anchor);
    setIsAnchorModalOpen(true);
  }, []);

  const onRemoveAnchor = React.useCallback(
    async (anchorId: string) => {
      const newAnchors = formGroup.anchors.filter(
        anchor => anchor.id !== anchorId
      );
      try {
        setIsLoading(true);
        await onUpdateAnchors(newAnchors);
      } finally {
        setIsLoading(false);
      }
    },
    [formGroup.anchors, onUpdateAnchors]
  );

  const onAddAnchor = React.useCallback(() => {
    setIsAnchorModalOpen(true);
    setAnchorToEdit(undefined);
  }, []);

  const onCloseAnchorModal = React.useCallback(() => {
    setIsAnchorModalOpen(false);
    setAnchorToEdit(undefined);
  }, []);

  const onSaveAnchor = React.useCallback(
    async (anchorId: string | undefined, formId: string) => {
      onCloseAnchorModal();
      const newAnchors: FormGroupAnchorBase[] = [...formGroup.anchors];
      if (anchorId) {
        const anchorIndex = newAnchors.findIndex(
          anchor => anchor.id === anchorId
        );
        if (anchorIndex === -1) {
          console.error(`Cannot find anchor ${anchorId} in form group`);
          return;
        }
        newAnchors[anchorIndex] = { id: anchorId, formId };
      } else {
        newAnchors.push({
          id: uuid.v4(),
          formId,
        });
      }
      try {
        setIsLoading(true);
        await onUpdateAnchors(newAnchors);
      } finally {
        setIsLoading(false);
      }
    },
    [onCloseAnchorModal, formGroup, onUpdateAnchors]
  );

  return (
    <div className={styles["form-group-form-list"]}>
      {hasPermissionToEditResource && (
        <div className={styles["add-new-area"]}>
          <div
            className={classNames(styles["add-new-form-button"], {
              [styles["add-new-form-button-empty-list"]]:
                formGroup.anchors.length === 0,
            })}
            onClick={onAddAnchor}
          >
            <Icon iconName="Add" className={styles["add-rule-icon"]} />
            <Text>
              <FormattedMessage id="form_group_anchor_editor.add_an_extractor" />
            </Text>
          </div>
        </div>
      )}
      {formGroup.anchors.length > 0 && (
        <Table
          columnIds={["form_group_anchor_editor.form"].concat(
            hasPermissionToEditResource
              ? ["form_group_anchor_editor.action"]
              : []
          )}
          className={styles["form-group-table"]}
        >
          {formGroup.anchors.map(anchor => (
            <FormGroupAnchorTableRow
              key={anchor.id}
              anchor={anchor}
              shouldShowEditButton={hasPermissionToEditResource}
              onEditAnchor={onEditAnchor}
              onRemoveAnchor={onRemoveAnchor}
            />
          ))}
        </Table>
      )}
      {isAnchorModalOpen && (
        <FormGroupAnchorModal
          anchor={anchorToEdit}
          onCloseModal={onCloseAnchorModal}
          onSave={onSaveAnchor}
          extractorOptions={extractorOptions}
        />
      )}
      {isLoading && (
        <div className={styles["loading-container"]}>
          <ShortSpinner />
        </div>
      )}
    </div>
  );
});
