import { Image, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useMemo, useState } from "react";

import { ToolBoxTutorialTargetIds } from "../../constants";
import markAnchorImg from "../../images/tutorial/mark-anchor.gif";
import FixedLayoutFormTutorialBubble from "../FixedLayoutFormTutorialBubble";
import styles from "./styles.module.scss";

const FirstStep = React.memo(() => {
  return (
    <div className={styles["extra-padding"]}>
      <Image src={markAnchorImg} className={styles["image"]} />
      <div className={styles["text-content"]}>
        <Text variant="medium" className={styles["title"]}>
          <FormattedMessage id="tutorial.fixed_layout_form.anchor.title1" />
        </Text>
        <Text variant="medium" className={styles["description"]}>
          <FormattedMessage id="tutorial.fixed_layout_form.anchor.description1" />
        </Text>
      </div>
    </div>
  );
});

const SecondStep = React.memo(() => {
  return (
    <>
      <Image src={markAnchorImg} className={styles["image"]} />
      <div className={styles["text-content"]}>
        <Text variant="medium" className={styles["title"]}>
          <FormattedMessage id="tutorial.fixed_layout_form.anchor.title2" />
        </Text>
        <Text variant="medium" className={styles["description"]}>
          <FormattedMessage id="tutorial.fixed_layout_form.anchor.description2" />
        </Text>
        <Text variant="medium" className={styles["description"]}>
          <FormattedMessage
            id="tutorial.fixed_layout_form.anchor.description2.with_hightlight"
            values={{
              hightlight: (
                <span className={styles["highlight"]}>
                  <FormattedMessage id="tutorial.fixed_layout_form.anchor.description2.hightlight" />
                </span>
              ),
            }}
          />
        </Text>
      </div>
    </>
  );
});

interface FixedLayoutFormTutorialAnchorBubbleProps {
  proceedToNextStage: () => void;
  skipAllTutorials: () => void;
}

const FixedLayoutFormTutorialAnchorBubble = React.memo(
  (props: FixedLayoutFormTutorialAnchorBubbleProps) => {
    const { proceedToNextStage, skipAllTutorials } = props;
    const [currentStep, setCurrentStep] = useState(0);

    const onSkipAll = useCallback(() => {
      skipAllTutorials();
    }, [skipAllTutorials]);

    const contents = useMemo(() => {
      return [<FirstStep />, <SecondStep />];
    }, []);

    const isLastStep = currentStep === contents.length - 1;

    const onNavigateToStep = useCallback(
      (step: number) => {
        if (step >= contents.length) {
          proceedToNextStage();
          return;
        }
        setCurrentStep(step);
      },
      [contents.length, proceedToNextStage]
    );

    return (
      <FixedLayoutFormTutorialBubble
        isLastStep={isLastStep}
        currentStep={currentStep}
        targetId={ToolBoxTutorialTargetIds.AnchorToolBox}
        onSkipAll={onSkipAll}
        onNavigateToStep={onNavigateToStep}
        contents={contents}
      />
    );
  }
);

export default FixedLayoutFormTutorialAnchorBubble;
