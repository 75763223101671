import * as yup from "yup";

import { ResourceOwnerSetting, resourceOwnerSchema } from "./resourceOwner";
import { briefTeamSchema, teamPermissionSchema } from "./team";

export const paymentMethodSchema = yup.object({
  brand: yup.string().required(),
  last4: yup.string().required(),
});

export const subscriptionDataSchema = yup
  .object({
    brand: yup.string().optional(),
    last4: yup.string().optional(),
    subscriptionStatus: yup.string().optional(),
    billingEmail: yup.string().optional(),
  })
  .camelCase()
  .transform((current, _original) => {
    const { brand, last4, ...rest } = current;
    return current.brand && current.last4
      ? {
          ...rest,
          paymentMethod: { brand, last4 },
        }
      : { ...rest, paymentMethod: null };
  })
  .omit(["brand", "last4"])
  .shape({
    paymentMethod: paymentMethodSchema.nullable(),
  });

export const userSchema = yup
  .object({
    id: yup.string().required(),
    username: yup.string().defined().nullable(),
    email: yup.string().required(),
    teams: yup.array(briefTeamSchema).defined(),
    permissions: yup.array(teamPermissionSchema).defined(),
    resourceOwner: resourceOwnerSchema.nullable(),
    isAdmin: yup.boolean().defined(),
  })
  .camelCase();

export type User = yup.InferType<typeof userSchema>;
export type UserSetting = ResourceOwnerSetting;
export type PaymentMethod = yup.InferType<typeof paymentMethodSchema>;
export type SubscriptionData = yup.InferType<typeof subscriptionDataSchema>;
