import {
  WorkerTokenList,
  WorkerTokenResp,
  workerTokenListSchema,
  workerTokenRespSchema,
} from "../../types/workerToken";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface WorkerTokenApiClient {
  listWorkerTokens: (
    teamId: string,
    includeRevoked: boolean,
    region?: string
  ) => Promise<WorkerTokenList>;
  createWorkerToken: (name: string, teamId: string) => Promise<WorkerTokenResp>;
  updateWorkerToken: (
    name: string,
    tokenId: string
  ) => Promise<WorkerTokenResp>;
  revokeWorkerToken: (tokenId: string) => Promise<WorkerTokenResp>;
}

export function withWorkerTokenApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async listWorkerTokens(
      teamId: string,
      includeRevoked: boolean,
      region?: string
    ): Promise<WorkerTokenList> {
      return this.lambda(
        "worker_token:list",
        {
          team_id: teamId,
          include_revoked: includeRevoked,
        },
        workerTokenListSchema,
        null,
        region !== undefined ? { region } : undefined
      );
    }

    async createWorkerToken(
      name: string,
      teamId: string
    ): Promise<WorkerTokenResp> {
      return this.lambda(
        "worker_token:create",
        {
          name,
          team_id: teamId,
        },
        workerTokenRespSchema,
        null
      );
    }

    async updateWorkerToken(
      name: string,
      tokenId: string
    ): Promise<WorkerTokenResp> {
      return this.lambda(
        "worker_token:update",
        {
          name,
          token_id: tokenId,
        },
        workerTokenRespSchema,
        null
      );
    }

    async revokeWorkerToken(tokenId: string): Promise<WorkerTokenResp> {
      return this.lambda(
        "worker_token:revoke",
        {
          token_id: tokenId,
        },
        workerTokenRespSchema,
        null
      );
    }
  };
}
