.uploadWidget {
  width: 340px;
  background: #ffffff;
  z-index: 10;
  box-shadow: 0px -6.4px 14.4px 0px rgba(0, 0, 0, 0.13),
    0px -1.2px 3.6px 0px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  padding: 8px 10px;
  justify-content: space-between;
  align-items: center;
  background: #faf9f8;
}

.headerText {
  color: #201f1e;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: unset; /* unset default css */
}

.headerButtonGroup {
  display: flex;
  align-items: center;
  gap: 2px;
}

.listAnimation {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.listOpen {
  max-height: 300px;
  transition: max-height 1s ease-in-out;
  overflow: auto;
}

.list {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
}

.listHeaderItem {
  background: #deecf9;
}

.listHeaderItemFailed {
  background: #f9dede;
}

.itemLabel {
  display: flex;
  align-items: flex-start;
  gap: 12px;

  /* for text ellipsis in itemText */
  flex: 1;
  min-width: 0;
}

.itemText {
  color: #201f1e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-overflow: ellipsis;
  overflow: hidden;
}

.errorText {
  color: #a4262c;
}

.actionButton {
  text-align: end;

  all: unset;
  cursor: pointer;
  color: #0078d4;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:hover {
    opacity: 75%;
  }
  &:active {
    opacity: 50%;
  }
}

.status {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  max-width: 40%;
}

.statusSuccess {
  color: #107c10;
}

.statusFail {
  color: #a4262c;
}

.banner--uploading-for-other-workspace {
  padding: 10px;
  background: #fff4ce;

  color: var(--grey-palette-grey-190, #201f1e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
