import * as yup from "yup";

import {
  AcceptTeamInvitationResp,
  TeamInvitationIsValidResp,
  TeamInvitationListItem,
  acceptTeamInvitationRespSchema,
  teamInvitationIsValidRespSchema,
  teamInvitationListItemSchema,
} from "../../types/team";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface InvitationApiClient {
  acceptTeamInvitation: (
    invitationCode: string,
    region: string
  ) => Promise<AcceptTeamInvitationResp>;
  removeInvitation: (invitationCode: string, region: string) => Promise<void>;
  rejectInvitation: (invitationCode: string, region: string) => Promise<void>;
  invitationIsValid: (
    invitationCode: string
  ) => Promise<TeamInvitationIsValidResp>;
  listInvitation: (region: string) => Promise<TeamInvitationListItem[]>;
}

export function withInvitationApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async acceptTeamInvitation(
      invitationCode: string,
      region: string
    ): Promise<AcceptTeamInvitationResp> {
      return this.lambda(
        "invitation:accept",
        {
          invitation_id: invitationCode,
        },
        acceptTeamInvitationRespSchema.defined(),
        undefined,
        {
          region,
        }
      );
    }

    async removeInvitation(
      invitationCode: string,
      region: string
    ): Promise<void> {
      return this.lambda(
        "invitation:remove",
        {
          invitation_id: invitationCode,
        },
        undefined,
        undefined,
        { region }
      );
    }

    async rejectInvitation(
      invitationCode: string,
      region: string
    ): Promise<void> {
      return this.lambda(
        "invitation:reject",
        {
          invitation_id: invitationCode,
        },
        undefined,
        undefined,
        { region }
      );
    }

    async invitationIsValid(
      invitationCode: string
    ): Promise<TeamInvitationIsValidResp> {
      return this.lambda(
        "invitation:check",
        {
          invitation_id: invitationCode,
        },
        teamInvitationIsValidRespSchema.defined()
      );
    }

    async listInvitation(region: string): Promise<TeamInvitationListItem[]> {
      return this.lambda(
        "invitation:list",
        {},
        yup.array(teamInvitationListItemSchema).defined(),
        undefined,
        {
          region,
        }
      );
    }
  };
}
