import * as React from "react";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { FSLOCRTest } from "../components/FSLOCRTest";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import { FSLCustomModelEditorProvider } from "../contexts/fslCustomModelEditor";
import { useCommonCustomModelContainerState } from "../hooks/custom_model";
import { useUnsafeParams } from "../hooks/params";
import CustomModelTestContainerV1 from "./CustomModelTestV1";
import { FSLNavBarLayout, FSLTab } from "./FSLNavBarLayout";

type PathParam = {
  customModelId: string;
};

function _FSLCustomModelTestContainerV1() {
  const { customModelId } = useUnsafeParams<PathParam>();

  const containerState = useCommonCustomModelContainerState(customModelId);

  return (
    <>
      <LoadingModal isOpen={containerState.state === "loading"} />
      {containerState.state === "error" ? (
        <Layout>
          <Top>
            <HeaderContainer />
          </Top>
          <Main hasTop={true}>
            <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
          </Main>
        </Layout>
      ) : containerState.state === "success" ? (
        <>
          {containerState.isFSLModel ? (
            <Layout>
              <Top>
                <HeaderContainer />
              </Top>
              <Main hasTop={true}>
                <FSLCustomModelEditorProvider
                  customModelId={customModelId}
                  key={customModelId}
                >
                  <FSLNavBarLayout selectedTab={FSLTab.TestExtractorV1}>
                    <FSLOCRTest
                      customModel={containerState.customModel}
                      useV1API={true}
                    />
                  </FSLNavBarLayout>
                </FSLCustomModelEditorProvider>
              </Main>
            </Layout>
          ) : (
            <CustomModelTestContainerV1 />
          )}
        </>
      ) : null}
    </>
  );
}

export const FSLCustomModelTestContainerV1 = React.memo(
  _FSLCustomModelTestContainerV1
);
export default FSLCustomModelTestContainerV1;
