import { FormattedMessage } from "@oursky/react-messageformat";
import cntl from "cntl";
import * as React from "react";
import { useNavigate } from "react-router";

import { SUPPORTED_EXTRACT_MIME } from "../../../constants";
import {
  PrebuiltExtractorDefinition,
  isPrebuiltExtractors,
} from "../../../constants/prebuiltExtractor";
import {
  TemplatedInstantModelExtractorDefinition,
  isTemplatedInstantModelExtractors,
} from "../../../constants/templatedInstantModelExtractor";
import { useLocale } from "../../../contexts/locale";
import { FOCRError } from "../../../errors";
import { useWorkerToken } from "../../../hooks/app";
import { useDragAndDropFiles } from "../../../hooks/drag_and_drop";
import { readFileAsImageDataUris } from "../../../hooks/extract_test";
import {
  useCreateCustomModel,
  useCreatePrebuiltTemplatedExtractor,
} from "../../../hooks/extractor";
import { useGtm } from "../../../hooks/gtm";
import { useToast } from "../../../hooks/toast";
import sampleAirwayBill1Preview from "../../../images/sample/sample-airway-bill-1.jpg";
import sampleAirwayBill1 from "../../../images/sample/sample-airway-bill-1.pdf";
import samplePurchaseOrder1Preview from "../../../images/sample/sample-purchase-order-1.jpg";
import samplePurchaseOrder1 from "../../../images/sample/sample-purchase-order-1.pdf";
import sampleQuotation1Preview from "../../../images/sample/sample-quotation-1.jpg";
import sampleQuotation1 from "../../../images/sample/sample-quotation-1.pdf";
import {
  GtmEvent,
  GtmEventType,
  GtmServiceInstance,
} from "../../../services/gtmService";
import { PrebuiltExtractor, SampleImage } from "../../../types/extractor";
import { OnboardingAvailblePrebuiltExtractorType } from "../../../types/onboarding";
import { chooseFile } from "../../../utils/file";
import { imageURLToFile } from "../../../utils/image";
import { GuidanceMode } from "../../FirstTimeGuidance";
import { Img } from "../../Img";
import { Button } from "../Button";
import { Chip } from "../Chip";
import { Content } from "./Content";

const MAX_SAMPLES = 3;

const AvailbleFinancePrebuiltExtractors: OnboardingAvailblePrebuiltExtractorType[] =
  [
    "bank_statement_llm",
    "invoice_llm",
    "receipt",
    "purchase_order_llm",
    "quotation_llm",
  ];

const AvailbleKycPrebuiltExtractors: OnboardingAvailblePrebuiltExtractorType[] =
  ["gov_id_passport", "address_proof"];

const AvailbleOtherPrebuiltExtractors: OnboardingAvailblePrebuiltExtractorType[] =
  ["bill_of_lading_instant_model", "air_waybill_llm", "resume_llm", "others"];

const TemplatedCustomModelExtractors = [
  "purchase_order_llm",
  "quotation_llm",
  "air_waybill_llm",
  "resume_llm",
];

const OthersExtractorDefinition = {
  others: {
    sampleImages: [
      {
        src: samplePurchaseOrder1,
        preview: samplePurchaseOrder1Preview,
      },
      {
        src: sampleQuotation1,
        preview: sampleQuotation1Preview,
      },
      {
        src: sampleAirwayBill1,
        preview: sampleAirwayBill1Preview,
      },
    ] as SampleImage[],
  },
};

type GtmFirstLinkEventData = {
  first_page_url?: string;
  extractor_id?: string;
  team_id: string;
  doc_type: OnboardingAvailblePrebuiltExtractorType | null;
};

function trackPageChangeEvent(
  eventType: GtmEventType,
  eventData: GtmFirstLinkEventData,
  threshold: number = 2
) {
  let pageViewEventCount = 0;

  GtmServiceInstance.addEventListener((event: GtmEvent) => {
    if (event.event !== GtmEventType.PageView) {
      return true;
    }
    pageViewEventCount++;
    if (pageViewEventCount < threshold) {
      return true;
    }
    const first_page_url = window.location.href;

    setTimeout(() => {
      GtmServiceInstance.push({
        event: eventType,
        event_data: { ...eventData, first_page_url },
      } as GtmEvent);
    }, 0);
    return false;
  });
}

export function useExtractorCreationPageState() {
  const [isCreatingExtractor, setIsCreatingExtractor] = React.useState(false);
  const {
    createCustomModelByDetectionThenPreviewResult,
    createCustomModelThenOpenLink,
  } = useCreateCustomModel();
  const { createPrebuiltTemplatedExtractorThenTest } =
    useCreatePrebuiltTemplatedExtractor();
  const { token } = useWorkerToken();

  // Always turn on the AutoDetectInstantModelSchema
  const isAutoDetectInstantModelSchemaEnabled = true;

  const [selectedChip, setSelectedChip] = React.useState<
    OnboardingAvailblePrebuiltExtractorType | undefined
  >(undefined);

  const { localized } = useLocale();
  const { teamIdForTracking } = useGtm();

  const selectChip = React.useCallback(
    (chip: OnboardingAvailblePrebuiltExtractorType) => {
      setSelectedChip(chip);
      setSelectedImage(undefined);
      setSelectedSource(undefined);
    },
    []
  );

  const [selectedImage, setSelectedImage] = React.useState<
    string | undefined
  >();
  const [selectedSource, setSelectedSource] = React.useState<
    File | string | undefined
  >();
  const [isSelectedImageVertical, setIsSelectedImageVertical] =
    React.useState<boolean>(true);

  const navigate = useNavigate();
  const toast = useToast();
  const {
    pushOnboardingAddSampleEvent,
    pushOnboardingSkipExtract,
    pushOnboardingExtractEvent,
    pushOnboardingCreateCustomModel,
  } = useGtm();

  const skip = React.useCallback(() => {
    pushOnboardingSkipExtract(
      (selectedChip as OnboardingAvailblePrebuiltExtractorType) ?? null
    );
    trackPageChangeEvent(GtmEventType.OnboardingSkipFirstLink, {
      team_id: teamIdForTracking,
      doc_type: selectedChip ?? null,
    });
    navigate("/extractor");
  }, [navigate, pushOnboardingSkipExtract, selectedChip, teamIdForTracking]);

  const updateIsSelectedImageVertical = React.useCallback((image: string) => {
    const imageObject = new Image();
    imageObject.addEventListener("load", () => {
      setIsSelectedImageVertical(imageObject.height > imageObject.width);
    });
    imageObject.src = image;
  }, []);

  const onPickedFile = React.useCallback(
    async (files?: File[]) => {
      if (!files || files.length === 0) {
        return;
      }

      const images = await readFileAsImageDataUris(files[0]);

      if (images.length > 0) {
        setSelectedImage(images[0]);
        setSelectedSource(files[0]);
        updateIsSelectedImageVertical(images[0]);
        pushOnboardingAddSampleEvent("self-upload");
      }
    },
    [updateIsSelectedImageVertical, pushOnboardingAddSampleEvent]
  );

  useDragAndDropFiles(onPickedFile, SUPPORTED_EXTRACT_MIME);

  const requestToPickFile = React.useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const files = await chooseFile(SUPPORTED_EXTRACT_MIME.join(","), true);
      if (files) {
        onPickedFile(files);
      }
    },
    [onPickedFile]
  );

  const pickSampleImage = React.useCallback(
    (image: SampleImage) => {
      setSelectedImage(image.preview ?? image.src);
      setSelectedSource(image.src);
      updateIsSelectedImageVertical(image.preview ?? image.src);
      pushOnboardingAddSampleEvent("formx-sample");
    },
    [
      setSelectedImage,
      setSelectedSource,
      updateIsSelectedImageVertical,
      pushOnboardingAddSampleEvent,
    ]
  );

  const loadSelectedSampleImageFile = React.useCallback(async () => {
    try {
      if (selectedSource instanceof File) {
        return selectedSource;
      }
      return await imageURLToFile(selectedSource ?? "");
    } catch (e) {
      throw new Error("error.cannot_load_image");
    }
  }, [selectedSource]);

  const extractCustomModelWithFile = React.useCallback(async () => {
    try {
      setIsCreatingExtractor(true);
      const file = await loadSelectedSampleImageFile();
      if (!token) {
        return;
      }

      const createdCustomModel =
        await createCustomModelByDetectionThenPreviewResult(token, file, {
          guidance: GuidanceMode.confirmDetectionValid,
        });

      if (createdCustomModel) {
        pushOnboardingCreateCustomModel();
        trackPageChangeEvent(
          GtmEventType.OnboardingCreateCustomModelFirstLink,
          {
            extractor_id: createdCustomModel.id,
            team_id: teamIdForTracking,
            doc_type: "others",
          },
          1 // threshold is 1 because the page change event trigger first
        );
      } else {
        // Prevent React state update on an unmounted component
        setIsCreatingExtractor(false);
      }
    } catch (e) {
      if (e instanceof FOCRError) {
        toast.error(e.messageId);
      } else {
        toast.error("error.fail_to_create_extractor");
      }
      setIsCreatingExtractor(false);
    }
  }, [
    createCustomModelByDetectionThenPreviewResult,
    loadSelectedSampleImageFile,
    pushOnboardingCreateCustomModel,
    teamIdForTracking,
    toast,
    token,
  ]);

  const extractCustomModelWithoutFile = React.useCallback(async () => {
    try {
      setIsCreatingExtractor(true);
      const name = localized(
        "onboarding.create_extractor.others_type_default_name"
      );
      const createdCustomModel = await createCustomModelThenOpenLink(name);

      if (createdCustomModel) {
        pushOnboardingCreateCustomModel();
        trackPageChangeEvent(
          GtmEventType.OnboardingCreateCustomModelFirstLink,
          {
            extractor_id: createdCustomModel.id,
            team_id: teamIdForTracking,
            doc_type: "others",
          },
          1 // threshold is 1 because the page change event trigger first
        );
      } else {
        // Prevent React state update on an unmounted component
        setIsCreatingExtractor(false);
      }
    } catch (e) {
      toast.error("error.fail_to_create_extractor");
      setIsCreatingExtractor(false);
    }
  }, [
    createCustomModelThenOpenLink,
    localized,
    pushOnboardingCreateCustomModel,
    teamIdForTracking,
    toast,
  ]);

  const extractCustomModel = React.useCallback(async () => {
    if (isAutoDetectInstantModelSchemaEnabled) {
      extractCustomModelWithFile();
    } else {
      extractCustomModelWithoutFile();
    }
  }, [
    isAutoDetectInstantModelSchemaEnabled,
    extractCustomModelWithFile,
    extractCustomModelWithoutFile,
  ]);

  const extractPrebuiltTemplatedExtractor = React.useCallback(async () => {
    try {
      setIsCreatingExtractor(true);

      const name = TemplatedCustomModelExtractors.includes(
        selectedChip as string
      )
        ? localized(
            "fsl_custom_model.field_schema_editor.fields.extractor." +
              selectedChip
          )
        : localized("extractor.prebuilt." + selectedChip);

      const file = await loadSelectedSampleImageFile();

      const extractor = await createPrebuiltTemplatedExtractorThenTest(
        name,
        selectedChip as PrebuiltExtractor,
        file,
        {
          guidance: GuidanceMode.firstTimeGuidance,
        }
      );

      if (extractor != null) {
        pushOnboardingExtractEvent(
          selectedChip as OnboardingAvailblePrebuiltExtractorType
        );
        trackPageChangeEvent(
          GtmEventType.OnboardingExtractFirstLink,
          {
            extractor_id: extractor.id,
            team_id: teamIdForTracking,
            doc_type: selectedChip as OnboardingAvailblePrebuiltExtractorType,
          },
          1 // threshold is 1 because the page change event trigger first
        );
      } else {
        // Prevent React state update on an unmounted component
        setIsCreatingExtractor(false);
      }
    } catch (e) {
      if (e instanceof Error && e.name === "error.cannot_load_image") {
        toast.error("error.cannot_load_image");
      } else {
        toast.error("error.fail_to_create_extractor");
      }
      setIsCreatingExtractor(false);
    }
  }, [
    createPrebuiltTemplatedExtractorThenTest,
    loadSelectedSampleImageFile,
    localized,
    pushOnboardingExtractEvent,
    selectedChip,
    teamIdForTracking,
    toast,
  ]);

  const extract = React.useCallback(async () => {
    if (selectedChip === "others") {
      extractCustomModel();
    } else {
      extractPrebuiltTemplatedExtractor();
    }
  }, [extractCustomModel, extractPrebuiltTemplatedExtractor, selectedChip]);

  const { extractLabel, extractDisabled, isDropZoneVisible } =
    React.useMemo(() => {
      if (!isAutoDetectInstantModelSchemaEnabled) {
        return {
          isDropZoneVisible:
            selectedChip !== undefined && selectedChip !== "others",
          extractDisabled:
            (selectedImage === undefined && selectedChip !== "others") ||
            isCreatingExtractor,
          extractLabel: isCreatingExtractor
            ? ""
            : selectedChip === "others"
            ? "onboarding.create_extractor.create_custom_model"
            : "common.extract",
        };
      }
      return {
        extractDisabled: selectedImage === undefined || isCreatingExtractor,

        // Since FX2524, the label of the extract button is changed to "Extract" for all extractors
        extractLabel: isCreatingExtractor ? "" : "common.extract",
        isDropZoneVisible: selectedChip !== undefined,
      };
    }, [
      isCreatingExtractor,
      isAutoDetectInstantModelSchemaEnabled,
      selectedChip,
      selectedImage,
    ]);

  return React.useMemo(() => {
    return {
      isCreatingExtractor,
      selectedChip,
      selectChip,
      selectedImage,
      setSelectedImage,
      skip,
      extract,
      extractDisabled,
      extractLabel,
      requestToPickFile,
      pickSampleImage,
      isSelectedImageVertical,
      skipVisible: false,
      isDropZoneVisible,
      isAutoDetectInstantModelSchemaEnabled,
    };
  }, [
    selectedChip,
    selectChip,
    selectedImage,
    setSelectedImage,
    skip,
    isCreatingExtractor,
    extract,
    extractDisabled,
    extractLabel,
    requestToPickFile,
    pickSampleImage,
    isSelectedImageVertical,
    isDropZoneVisible,
    isAutoDetectInstantModelSchemaEnabled,
  ]);
}

export function ExtractorCreationPage() {
  const states = useExtractorCreationPageState();
  return <ExtractorCreationPageImpl {...states} />;
}

function DropZone(props: ReturnType<typeof useExtractorCreationPageState>) {
  const { selectedImage, selectedChip, pickSampleImage } = props;

  const sampleImages = React.useMemo(() => {
    if (selectedChip === undefined) {
      return [];
    }
    const def = isPrebuiltExtractors(selectedChip)
      ? PrebuiltExtractorDefinition[
          selectedChip as keyof typeof PrebuiltExtractorDefinition
        ]
      : isTemplatedInstantModelExtractors(selectedChip)
      ? TemplatedInstantModelExtractorDefinition[
          selectedChip as keyof typeof TemplatedInstantModelExtractorDefinition
        ]
      : OthersExtractorDefinition[
          selectedChip as keyof typeof OthersExtractorDefinition
        ];

    if (def === undefined) {
      return [];
    }

    return def.sampleImages?.slice(0, MAX_SAMPLES) ?? [];
  }, [selectedChip]);

  return (
    <>
      {selectedImage === undefined ? (
        <div className="h-[192px] flex flex-col gap-[16px] items-center w-full px-[40px]">
          <div
            className={cntl`h-[160px] bg-surface-secondary w-full
                                  border-border-brand border-[1px] border-dashed rounded-[12px]
                                  flex flex-col justify-center items-center`}
            onClick={props.requestToPickFile}
          >
            {selectedChip === "others" ? (
              <>
                <div className="text-label-medium text-text-primary text-center">
                  <div>
                    <FormattedMessage id="onboarding.create_extractor.custom_drop.title1" />
                  </div>
                  <FormattedMessage id="onboarding.create_extractor.custom_drop.title2" />
                </div>
                <div className="text-body-small text-text-secondary mt-[16px]">
                  <div>
                    <FormattedMessage id="onboarding.create_extractor.custom_drop.message" />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="text-label-medium text-text-primary">
                  <FormattedMessage id="onboarding.create_extractor.drop.title" />
                </div>
                <div className="text-body-small text-text-secondary mt-[16px]">
                  {sampleImages.length > 0 && (
                    <div>
                      <FormattedMessage id="onboarding.create_extractor.drop.message" />
                    </div>
                  )}
                </div>
              </>
            )}
            {sampleImages.length > 0 && (
              <div className="flex flex-row gap-[16px] mt-[16px]">
                {sampleImages.map((image: SampleImage, index: number) => (
                  <Img
                    className={cntl`
                    cursor-pointer
                    w-[40px] h-[40px] object-cover rounded-[8px]
                    origin-center
                    transition-transform
                    translate-x-0	translate-y-0	skew-x-0 skew-y-0	rotate-0 hover:scale-[2]
                  `}
                    key={index}
                    src={image.preview ?? image.src}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                      e.stopPropagation();
                      e.preventDefault();
                      pickSampleImage(image);
                    }}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="text-body-small text-text-placeholder">
            <FormattedMessage id="onboarding.create_extractor.upload_size_hints" />
          </div>
        </div>
      ) : (
        <div className="h-[192px] flex flex-row justify-center items-center w-full">
          <Img
            src={selectedImage}
            className={cntl`
              ${
                props.isSelectedImageVertical
                  ? "w-[156px] h-[208px]"
                  : "w-[208px] h-[156px]"
              }
              object-cover
              `}
          />
        </div>
      )}
    </>
  );
}

export function Column(props: {
  titleId: string;
  extractors: string[];
  selectedChip?: OnboardingAvailblePrebuiltExtractorType;
  width: number;
  selectChip: (chip: OnboardingAvailblePrebuiltExtractorType) => void;
}) {
  return (
    <div
      className="h-[204px] max-h-[204px]"
      style={{
        width: `${props.width}px`,
      }}
    >
      <div className="text-label-large mb-[28px]">
        <FormattedMessage id={props.titleId} />
      </div>
      <div className="flex flex-row flex-wrap gap-[16px]">
        {props.extractors.map(extractor => {
          return (
            <Chip
              key={extractor}
              isActive={extractor === props.selectedChip}
              textId={`onboarding.create_extractor.type.${extractor}`}
              onClick={() => {
                props.selectChip(
                  extractor as OnboardingAvailblePrebuiltExtractorType
                );
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export function ExtractorCreationPageImpl(
  props: ReturnType<typeof useExtractorCreationPageState>
) {
  const {
    selectedChip,
    selectChip,
    skip,
    extract,
    extractDisabled,
    skipVisible,
  } = props;

  return (
    <Content
      sizeConstraint={`max-w-[1020px] min-w-[1020px]`}
      footer={
        <>
          {skipVisible && (
            <Button type="borderless" textId="common.skip" onClick={skip} />
          )}
          <Button
            type="primary"
            textId={props.extractLabel}
            onClick={extract}
            disabled={extractDisabled}
            isLoading={props.isCreatingExtractor}
          />
        </>
      }
    >
      <div className="flex flex-col justify-center items-center">
        <div className="text-headline-medium text-primary pt-[48px]">
          <FormattedMessage id="onboarding.create_extractor.title" />
        </div>
        <div className="text-title-medium text-text-secondary pt-[16px] flex flex-col justify-center items-center">
          <FormattedMessage id="onboarding.create_extractor.message" />
        </div>
        <div className="flex flex-row gap-[24px] mt-[60px] mx-[48px]">
          <Column
            titleId="onboarding.create_extractor.column.finance.title"
            width={320}
            extractors={AvailbleFinancePrebuiltExtractors}
            selectedChip={selectedChip}
            selectChip={selectChip}
          />
          <Column
            titleId="onboarding.create_extractor.column.kyc.title"
            width={260}
            extractors={AvailbleKycPrebuiltExtractors}
            selectedChip={selectedChip}
            selectChip={selectChip}
          />
          <Column
            titleId="onboarding.create_extractor.column.other.title"
            width={310}
            extractors={AvailbleOtherPrebuiltExtractors}
            selectedChip={selectedChip}
            selectChip={selectChip}
          />
        </div>

        {props.isDropZoneVisible ? (
          <div className="pt-[32px] flex flex-row gap-[16px] w-full mb-[24px]">
            <DropZone {...props} />
          </div>
        ) : (
          <div className="h-[72px]"></div>
        )}
      </div>
    </Content>
  );
}
