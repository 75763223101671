import { CustomModelState } from "../customModel";

export function isUploadingCustomModelImage(state: CustomModelState) {
  for (const customModelId in state.customModelImageUploadStatistics) {
    const { totalCount, failedCount, successCount } =
      state.customModelImageUploadStatistics[customModelId];
    if (totalCount > failedCount + successCount) {
      return true;
    }
  }
  return false;
}

export function isDeletingCustomModelimage(state: CustomModelState) {
  for (const customModelId in state.deletingCustomModelImageIds) {
    if (state.deletingCustomModelImageIds[customModelId].length > 0) {
      return true;
    }
  }
  return false;
}
