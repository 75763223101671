import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import React, { useCallback, useRef, useState } from "react";

import { useDetectionRegionInspector } from "../../contexts/detectionRegionInspector";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { MinimalFieldModalPayload } from "../../models";
import DetectionRegionAdvancedSetting, {
  DetectionRegionAdvancedSettingHandle,
} from "../DetectionRegionAdvancedSetting";
import { DetectionRegionWaterMarkSetting } from "../DetectionRegionWaterMarkSetting";
import styles from "./styles.module.scss";

interface Props {
  payload: MinimalFieldModalPayload;
  onCloseModal(): void;
}

const MinimalFieldModal = React.memo((props: Props) => {
  const { onCloseModal, payload } = props;
  const { index, field, selectedDetectionRegionId, titleId } = payload;
  const { localized } = useLocale();
  const { updateDetectionRegionField } = useDetectionRegionInspector();
  const advancedSettingsRef =
    useRef<DetectionRegionAdvancedSettingHandle>(null);
  const [extras, setExtras] = useState<any>(field.extras);

  const onSave = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (advancedSettingsRef.current) {
        const params = advancedSettingsRef.current.getParams();

        updateDetectionRegionField(index, {
          type: field.type,
          params: params,
          extras: extras,
        });
      }
      onCloseModal();
    },
    [
      extras,
      field,
      onCloseModal,
      index,
      advancedSettingsRef,
      updateDetectionRegionField,
    ]
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("minimal_field_modal.title", {
        field_type: localized(titleId),
      }),
    }),
    [localized, titleId]
  );

  const modalProps: IModalProps = React.useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <Dialog
      minWidth={405}
      hidden={false}
      onDismiss={onCloseModal}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <form onSubmit={onSave}>
        <div className={styles["water-mark-setting"]}>
          <DetectionRegionWaterMarkSetting
            onChange={setExtras}
            extras={field.extras}
          />
        </div>
        <DetectionRegionAdvancedSetting
          fieldType={field.type}
          fieldParams={field.params}
          selectedDetectionRegionId={selectedDetectionRegionId}
          fieldIndex={index}
          isAlwaysOpen={true}
          ref={advancedSettingsRef}
        />
        <DialogFooter>
          <DefaultButton
            onClick={onCloseModal}
            text={localized(
              hasPermissionToEditResource ? "common.cancel" : "common.close"
            )}
          />
          {hasPermissionToEditResource && (
            <PrimaryButton type="submit" text={localized("common.ok")} />
          )}
        </DialogFooter>
      </form>
    </Dialog>
  );
});

export default MinimalFieldModal;
