.inspector-note {
  display: flex;
  flex-direction: column;

  > span {
    margin: 6px 0;
  }

  margin-bottom: 16px;
}
