import {
  CustomCell,
  CustomRenderer,
  GridCellKind,
  Item,
  ProvideEditorComponent,
  Rectangle,
  getMiddleCenterBias,
} from "@glideapps/glide-data-grid";
import React, { useCallback, useEffect } from "react";

import { TagKey } from "../../../types/advancedTokenSetup/table";
import styles from "./EditTagCell.module.scss";
import { renderErrorState } from "./ErrorState";
import { truncatStr } from "./Utils/Text";

interface EditTagCellProps {
  readonly kind: "edit-tag-cell";
  item: Item;
  tag: string;
  tagKey: TagKey;
  error?: string;
}

export type EditTagCell = CustomCell<EditTagCellProps>;

export function isEditTagCell(cell: CustomCell): cell is EditTagCell {
  return (cell.data as any).kind === "edit-tag-cell";
}

const EditTagCellEditor: ProvideEditorComponent<EditTagCell> = props => {
  const { value: cell, onChange, initialValue = "" } = props;
  const { data, readonly } = cell;
  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...cell,
        data: {
          ...cell.data,
          tag: e.target.value,
        },
      });
    },
    [onChange, cell]
  );
  useEffect(() => {
    onChange({
      ...cell,
      data: {
        ...cell.data,
        tag: cell.data.tag + initialValue,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles["editor"]}>
      <input
        name="input"
        value={data.tag}
        onChange={onTextChange}
        autoFocus
        readOnly={readonly}
      />
    </div>
  );
};

export const EditTagCellRenderer: CustomRenderer<EditTagCell> = {
  kind: GridCellKind.Custom,
  isMatch: isEditTagCell,
  draw: (args, cell) => {
    const { ctx, theme, rect } = args;
    const { tag, error } = cell.data;

    const drawArea: Rectangle = {
      x: rect.x + theme.cellHorizontalPadding,
      y: rect.y + theme.cellVerticalPadding,
      width: rect.width - 2 * theme.cellHorizontalPadding,
      height: rect.height - 2 * theme.cellVerticalPadding,
    };
    let textOffset = 0;
    if (error) {
      renderErrorState(args, error);
      textOffset += 20;
    }
    ctx.font = "12px";
    ctx.fillStyle = "black";

    const bias = getMiddleCenterBias(ctx, `12px ${theme.fontFamily}`);
    const [displayText] = truncatStr(tag, ctx, 205);
    ctx.fillText(
      displayText,
      textOffset + drawArea.x,
      drawArea.y + drawArea.height / 2 + bias
    );
  },
  provideEditor: () => {
    return EditTagCellEditor;
  },
};
