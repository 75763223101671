import { Icon, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { Link } from "react-router-dom";

import { useTeamPermission } from "../../hooks/permission";
import styles from "./styles.module.scss";

export const CreateWebhookBar = React.memo(() => {
  const { hasPermissionToCreateResource } = useTeamPermission();

  if (!hasPermissionToCreateResource) {
    return <></>;
  }

  return (
    <div className={styles["top-bar"]}>
      <Link to="/webhook/new" className={styles["button-area"]}>
        <Icon iconName="CirclePlus" />
        <Text>
          <FormattedMessage id="add.new.webhook" />
        </Text>
      </Link>
    </div>
  );
});

export default CreateWebhookBar;
