import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { SUPPORTED_EXTRACT_MIME, UserFeatureFlag } from "../../constants";
import { STANDARD_MODEL_MIN_LABELLED_COUNT } from "../../constants/fslCustomModel";
import { CUSTOM_MODEL_IMAGE_PAGE_SIZE } from "../../constants/layout";
import { useFSLCustomModelEditor } from "../../contexts/fslCustomModelEditor";
import { useFSLStandardModelEditorContainer } from "../../contexts/fslStandardModelEditor";
import { useLocale } from "../../contexts/locale";
import {
  useDragAndDropDefaultOptions,
  useDragAndDropFiles,
} from "../../hooks/drag_and_drop";
import { useGtm } from "../../hooks/gtm";
import { useAppSelector } from "../../hooks/redux";
import { StandardModelImageState } from "../../types/customModel";
import { openLabeller } from "../../utils/labeller";
import CustomModelImageLightboxModal from "../CustomModelImageLightboxModal";
import CustomModelRemarkModal from "../CustomModelRemarkModal";
import { DangerButton } from "../DangerButton";
import { Dropzone } from "../Dropzone";
import { FSLActiveModelPicker } from "../FSLActiveModelPicker";
import { FSLSchemaNotSetWarning } from "../FSLSchemaNotSetWarning";
import { FSLSplitView } from "../FSLSplitView";
import { DangerLoadingButton } from "../LoadingButton";
import { MissionBar, MissionBarType } from "../MissionBar";
import { PageHeader } from "../PageHeader";
import Paginator from "../Paginator";
import { SampleImageSelectableCard } from "../SampleImageSelectableCard";
import { ActionButton, DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

const MAX_PROGRESS_VALUE = 10;

function StyledMissionBar(props: {
  type: MissionBarType;
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  right?: React.ReactElement;
}) {
  return <MissionBar {...props} className={styles["styled-mission-bar"]} />;
}

function UploadingMissionBar(
  props: ReturnType<typeof useFSLStandardModelEditor>
) {
  const { customModelImages } = props;

  const progressCount = customModelImages?.length ?? 0;

  return (
    <StyledMissionBar
      type={MissionBarType.Info}
      title={
        <>
          <b>
            <FormattedMessage id="fsl_standard_model_editor.mission_bar.upload_samples.title1" />
          </b>
          <FormattedMessage
            id="fsl_standard_model_editor.mission_bar.upload_samples.title2"
            values={{
              progress:
                progressCount < MAX_PROGRESS_VALUE
                  ? progressCount
                  : MAX_PROGRESS_VALUE,
            }}
          />
        </>
      }
    />
  );
}

function LabellingMissionBar() {
  return (
    <StyledMissionBar
      type={MissionBarType.Info}
      title={
        <>
          <b>
            <FormattedMessage id="fsl_standard_model_editor.mission_bar.labelling.title1" />
          </b>
          <FormattedMessage id="fsl_standard_model_editor.mission_bar.labelling.title2" />
        </>
      }
    />
  );
}

function TrainModelMissionBar() {
  return (
    <StyledMissionBar
      type={MissionBarType.Info}
      title={
        <>
          <b>
            <FormattedMessage id="fsl_standard_model_editor.mission_bar.train_model.title1" />
          </b>
          <FormattedMessage id="fsl_standard_model_editor.mission_bar.train_model.title2" />
        </>
      }
    />
  );
}

function TrainedModelMissionBar() {
  return (
    <StyledMissionBar
      type={MissionBarType.Success}
      title="fsl_standard_model_editor.mission_bar.trained_model.title"
      subtitle="fsl_standard_model_editor.mission_bar.trained_model.subtitle"
    />
  );
}

function TrainingExpiredMissionBar() {
  return (
    <StyledMissionBar
      type={MissionBarType.Warning}
      title="fsl_standard_model_editor.mission_bar.trained_but_expired.title"
    />
  );
}

export enum Progress {
  UploadSamplesState,
  LabelSamplesState,
  TrainModelState,
}

type SampleImage = {
  id: string;
  actionLabel?: string;
  image?: string;
  filename?: string;
  createdAt?: Date;
  isCorrected?: boolean;
  isChecked?: boolean;
  isDeleting: boolean;
  badgeLabel?: string;
  onClick: () => void;
};

export function useFSLStandardModelEditor() {
  const [checkedCustomModelIds, setCheckedCustomModelIds] = React.useState<
    string[]
  >([]);
  const {
    extractedContentSchema,
    requestToDeleteCustomModelImages,
    requestToUploadStandardModelImages,
    uploadStandardModelImages,
    uploadingCustomModelImageCount,
    standardModelVersions,
  } = useFSLCustomModelEditor();

  const {
    customModel,
    isStandardModelTrainingInProgress,
    isStandardModelTrainingRequested,
    requestModelTraining,
    customModelRemarkModalProps,
    isStandardModelEnabled,
    enableStandardModel,
    isStandardModelTrainingFinished,
    isStandardModelTrainingExpired: isStandardModelTrainingExpiredGlobalState,
    customModelImages,
    totalCustomModelImageCount,
    navigateToCustomModelImagePage,
    currentCustomModelImagePage,
    deletingCustomModelImageIds,
    standardModelLabelledImageCount,
    isPreparingTraining,
  } = useFSLStandardModelEditorContainer();

  const [openedImageId, setOpenedImageId] = React.useState<
    string | undefined
  >();

  // #3048 Custom Model Labelling is always enabled
  const isCustomModelLabellingEnabled = true;

  const isCustomModelTrainingEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.CustomModelTraining)
  );

  const labelledImageCountPassed =
    standardModelLabelledImageCount >= STANDARD_MODEL_MIN_LABELLED_COUNT;

  const currentProgress = React.useMemo(() => {
    if (isCustomModelLabellingEnabled) {
      return labelledImageCountPassed || standardModelVersions.length > 0
        ? Progress.TrainModelState
        : (customModelImages?.length ?? 0) >= MAX_PROGRESS_VALUE
        ? Progress.LabelSamplesState
        : Progress.UploadSamplesState;
    } else {
      return (customModelImages?.length ?? 0) >= MAX_PROGRESS_VALUE ||
        standardModelVersions.length > 0
        ? Progress.TrainModelState
        : Progress.UploadSamplesState;
    }
  }, [
    isCustomModelLabellingEnabled,
    labelledImageCountPassed,
    customModelImages,
    standardModelVersions.length,
  ]);

  const isSchemaNotSet = extractedContentSchema === undefined;

  const isStandardModelReady = !isSchemaNotSet && isStandardModelEnabled;

  const shouldHandleStandardModelTrainingRequested =
    isCustomModelTrainingEnabled ? false : isStandardModelTrainingRequested;
  // Ref: #2654

  const isTrainingButtonEnabled =
    currentProgress === Progress.TrainModelState &&
    !(
      isStandardModelTrainingInProgress ||
      shouldHandleStandardModelTrainingRequested
    );
  const isLabelButtonVisible = isCustomModelLabellingEnabled;

  const trainingButtonLabel = React.useMemo(() => {
    return isStandardModelTrainingInProgress || isPreparingTraining
      ? "fsl_standard_model_editor.command_bar.model_training_in_progress"
      : shouldHandleStandardModelTrainingRequested
      ? "custom_model_label.train.button.requested"
      : !isCustomModelLabellingEnabled || !isCustomModelTrainingEnabled
      ? "fsl_standard_model_editor.command_bar.request_model_training"
      : "fsl_standard_model_editor.command_bar.train_model";
  }, [
    isStandardModelTrainingInProgress,
    shouldHandleStandardModelTrainingRequested,
    isCustomModelLabellingEnabled,
    isCustomModelTrainingEnabled,
    isPreparingTraining,
  ]);

  const requestToDeleteSampleImages = React.useCallback(() => {
    requestToDeleteCustomModelImages(checkedCustomModelIds, false).then(
      (res: boolean) => {
        if (res) {
          setCheckedCustomModelIds([]);
        }
      }
    );
  }, [checkedCustomModelIds, requestToDeleteCustomModelImages]);

  const setCheckedCustomModelId = React.useCallback(
    (id: string, checked: boolean) => {
      if (checked) {
        setCheckedCustomModelIds([...checkedCustomModelIds, id]);
      } else {
        setCheckedCustomModelIds(
          checkedCustomModelIds.filter(_id => _id !== id)
        );
      }
    },
    [checkedCustomModelIds]
  );

  const dragAndDropDefaultOptions = useDragAndDropDefaultOptions();
  const { isFileOver } = dragAndDropDefaultOptions;

  useDragAndDropFiles(
    React.useCallback(
      (files?: File[]) => {
        if (files) {
          uploadStandardModelImages(files);
        }
      },
      [uploadStandardModelImages]
    ),
    SUPPORTED_EXTRACT_MIME,
    undefined,
    dragAndDropDefaultOptions.options
  );

  const sampleImages = React.useMemo(() => {
    if (
      customModel == null ||
      customModelImages == null ||
      customModelImages.length === 0
    ) {
      return [
        {
          id: "default-sample-image-card",
          actionLabel: "fsl_standard_model_editor.command_bar.add_sample",
          isCorrected: false,
          isChecked: false,
          onClick: requestToUploadStandardModelImages,
          isDeleting: false,
        } as SampleImage,
      ];
    }

    return customModelImages.map((item, index) => {
      const isCorrected =
        item.state === StandardModelImageState.Reviewed ||
        item.state === StandardModelImageState.Annotated;
      const isChecked = checkedCustomModelIds.includes(
        customModelImages?.[index]?.id ?? ""
      );
      const onClick = () => {
        setOpenedImageId(item.id);
      };

      const isDeleting =
        deletingCustomModelImageIds[customModel.id] !== undefined
          ? deletingCustomModelImageIds[customModel.id].includes(item.id ?? "")
          : false;

      return {
        ...item,
        actionLabel: "",
        image: item.url,
        isCorrected,
        isChecked,
        onClick,
        isDeleting,
        badgeLabel: `fsl_custom_model.sample_image.${item.state}`,
      } as SampleImage;
    });
  }, [
    customModel,
    customModelImages,
    checkedCustomModelIds,
    requestToUploadStandardModelImages,
    deletingCustomModelImageIds,
  ]);

  const { pushCustomClickedMslStartLabellingEvent } = useGtm();

  const requestToOpenLabeller = React.useCallback(
    (imageId?: string) => {
      pushCustomClickedMslStartLabellingEvent(customModel?.id ?? "");
      openLabeller(customModel?.id ?? "", imageId);
    },
    [customModel?.id, pushCustomClickedMslStartLabellingEvent]
  );

  const changingSampleImageCountRef = React.useRef(0);
  const [
    isStandardModelTrainingExpiredLocalState,
    setIsStandardModelTrainingExpiredLocalState,
  ] = React.useState(false);

  const customModelId = customModel?.id ?? "";
  const deletingCustomModelImageCount =
    deletingCustomModelImageIds[customModelId] !== undefined
      ? deletingCustomModelImageIds[customModelId].length
      : 0;

  React.useEffect(() => {
    const newValue =
      uploadingCustomModelImageCount + deletingCustomModelImageCount;

    if (
      changingSampleImageCountRef.current > 0 &&
      newValue === 0 &&
      isStandardModelTrainingFinished
    ) {
      // Set local training expired flag when uploaded or deleted image
      setIsStandardModelTrainingExpiredLocalState(true);
    }
    changingSampleImageCountRef.current = newValue;
  }, [
    uploadingCustomModelImageCount,
    deletingCustomModelImageCount,
    isStandardModelTrainingFinished,
  ]);

  const isStandardModelTrainingExpired =
    isStandardModelTrainingExpiredGlobalState ||
    isStandardModelTrainingExpiredLocalState;

  return React.useMemo(() => {
    return {
      isSchemaNotSet,
      checkedCustomModelIds,
      setCheckedCustomModelIds,
      isStandardModelEnabled,
      isStandardModelReady,
      currentProgress,
      requestToDeleteSampleImages,
      setCheckedCustomModelId,
      sampleImages,
      requestToOpenLabeller,
      totalCustomModelImageCount,
      navigateToCustomModelImagePage,
      currentCustomModelImagePage,
      isTrainingButtonEnabled,
      trainingButtonLabel,
      requestModelTraining,
      customModelRemarkModalProps,
      enableStandardModel,
      isStandardModelTrainingFinished,
      openedImageId,
      setOpenedImageId,
      customModelImages,
      uploadingCustomModelImageCount,
      standardModelLabelledImageCount,
      isLabelButtonVisible,
      isCustomModelLabellingEnabled,
      isStandardModelTrainingExpired,
      isStandardModelTrainingInProgress,
      isFileOver,
      isPreparingTraining,
    };
  }, [
    isSchemaNotSet,
    checkedCustomModelIds,
    setCheckedCustomModelIds,
    isStandardModelEnabled,
    isStandardModelReady,
    currentProgress,
    requestToDeleteSampleImages,
    setCheckedCustomModelId,
    sampleImages,
    requestToOpenLabeller,
    totalCustomModelImageCount,
    navigateToCustomModelImagePage,
    currentCustomModelImagePage,
    isTrainingButtonEnabled,
    trainingButtonLabel,
    requestModelTraining,
    customModelRemarkModalProps,
    enableStandardModel,
    isStandardModelTrainingFinished,
    openedImageId,
    setOpenedImageId,
    customModelImages,
    uploadingCustomModelImageCount,
    standardModelLabelledImageCount,
    isLabelButtonVisible,
    isCustomModelLabellingEnabled,
    isStandardModelTrainingExpired,
    isStandardModelTrainingInProgress,
    isFileOver,
    isPreparingTraining,
  ]);
}

function CommandBar(props: ReturnType<typeof useFSLStandardModelEditor>) {
  const {
    currentProgress,
    requestToOpenLabeller,
    isTrainingButtonEnabled,
    trainingButtonLabel,
    requestModelTraining,
    isLabelButtonVisible,
    isStandardModelTrainingInProgress,
    isPreparingTraining,
  } = props;
  const { requestToUploadStandardModelImages } = useFSLCustomModelEditor();

  const onOpenLabeller = React.useCallback(() => {
    requestToOpenLabeller();
  }, [requestToOpenLabeller]);

  const trainModelPrefixNumber = isLabelButtonVisible ? 3 : 2;

  return (
    <div className={styles["command-bar"]}>
      {currentProgress === Progress.UploadSamplesState ? (
        <DangerButton
          textId="fsl_standard_model_editor.command_bar.upload_samples"
          onClick={requestToUploadStandardModelImages}
        />
      ) : (
        <DefaultButton
          textId="fsl_standard_model_editor.command_bar.upload_samples"
          onClick={requestToUploadStandardModelImages}
        />
      )}
      {!isLabelButtonVisible ? null : currentProgress ===
        Progress.LabelSamplesState ? (
        <DangerButton
          textId="fsl_standard_model_editor.command_bar.label_samples"
          onClick={onOpenLabeller}
        />
      ) : (
        <DefaultButton
          textId="fsl_standard_model_editor.command_bar.label_samples"
          disabled={currentProgress === Progress.UploadSamplesState}
          onClick={onOpenLabeller}
        />
      )}
      <DangerLoadingButton
        isLoading={isStandardModelTrainingInProgress || isPreparingTraining}
        loadingMessageId={trainingButtonLabel}
        textId={trainingButtonLabel}
        textValues={{
          prefix: trainModelPrefixNumber,
        }}
        onClick={requestModelTraining}
        disabled={!isTrainingButtonEnabled}
      />
    </div>
  );
}

function NotEnabledWarning(
  props: ReturnType<typeof useFSLStandardModelEditor>
) {
  const { enableStandardModel } = props;
  return (
    <div className={styles["not-enabled-warning"]}>
      <div className={styles["not-enabled-warning-content"]}>
        <div className={styles["not-enabled-warning-title"]}>
          <FormattedMessage id="fsl_standard_model_editor.not_enabled_warning.title" />
        </div>
        <div className={styles["not-enabled-warning-desc"]}>
          <FormattedMessage
            id="fsl_standard_model_editor.not_enabled_warning.desc1"
            values={{
              br: <br />,
            }}
          />
        </div>
        <div className={styles["not-enabled-warning-desc"]}>
          <FormattedMessage id="fsl_standard_model_editor.not_enabled_warning.desc2" />
        </div>
        <DangerButton
          textId="fsl_standard_model_editor.not_enabled_warning.button"
          className={styles["not-eanbled-warning-button"]}
          onClick={enableStandardModel}
        />
      </div>
    </div>
  );
}

function StatusText(props: ReturnType<typeof useFSLStandardModelEditor>) {
  const {
    uploadingCustomModelImageCount,
    totalCustomModelImageCount,
    standardModelLabelledImageCount,
    checkedCustomModelIds,
    isCustomModelLabellingEnabled,
  } = props;

  const { localized } = useLocale();

  const normalStatusText = React.useMemo(() => {
    const token = [];

    token.push(
      localized("fsl_standard_model_editor.status.samples", {
        count: totalCustomModelImageCount,
      })
    );

    if (isCustomModelLabellingEnabled) {
      token.push(
        localized("fsl_standard_model_editor.status.labelled", {
          count: standardModelLabelledImageCount,
        })
      );
    }

    const selectedCount = checkedCustomModelIds.length;
    if (selectedCount > 0) {
      token.push(
        localized("fsl_standard_model_editor.status.selected", {
          count: selectedCount,
        })
      );
    }

    return token.join(" - ");
  }, [
    checkedCustomModelIds,
    isCustomModelLabellingEnabled,
    localized,
    totalCustomModelImageCount,
    standardModelLabelledImageCount,
  ]);

  return (
    <span className={styles["status-text"]}>
      {uploadingCustomModelImageCount > 0 ? (
        <>
          <Icon iconName="Upload" />
          <FormattedMessage
            id="custom_model_grid.uploading_files"
            values={{ numUploadingFile: uploadingCustomModelImageCount }}
          />
        </>
      ) : (
        <>{normalStatusText}</>
      )}
    </span>
  );
}

function SampleImagesPanel(
  props: ReturnType<typeof useFSLStandardModelEditor>
) {
  const {
    checkedCustomModelIds,
    requestToDeleteSampleImages,
    sampleImages,
    setCheckedCustomModelId,
    totalCustomModelImageCount,
    navigateToCustomModelImagePage,
    currentCustomModelImagePage,
    isFileOver,
  } = props;

  return (
    <div className={styles["sample-images-panel"]}>
      <div className={styles["sample-images-panel-toolbar"]}>
        <ActionButton
          isDestroy={true}
          iconName="trash"
          textId="fsl_instant_model_editor.delete_samples"
          disabled={checkedCustomModelIds.length === 0}
          onClick={requestToDeleteSampleImages}
        />

        <StatusText {...props} />
      </div>
      <div className={styles["sample-images-panel-image-list-container"]}>
        <div className={styles["sample-images-panel-image-list-scrollable"]}>
          <div className={styles["sample-images-panel-image-list"]}>
            {sampleImages?.map(sampleImage => {
              return (
                <SampleImageSelectableCard
                  key={sampleImage.id}
                  actionLabel={sampleImage.actionLabel}
                  onClick={sampleImage.onClick}
                  image={sampleImage.image}
                  filename={sampleImage.filename ?? ""}
                  uploadedAt={sampleImage.createdAt}
                  isCorrected={sampleImage.isCorrected}
                  isDeleting={sampleImage.isDeleting}
                  onCheckboxChange={checked => {
                    setCheckedCustomModelId(sampleImage.id ?? "", checked);
                  }}
                  isDisabled={false}
                  isChecked={sampleImage.isChecked}
                  badgeLabel={sampleImage.badgeLabel}
                />
              );
            })}
          </div>
        </div>
        {isFileOver && <Dropzone className={styles["dropzone"]} />}
      </div>
      <div className={styles["paginator"]}>
        <Paginator
          currentPage={currentCustomModelImagePage}
          pageSize={CUSTOM_MODEL_IMAGE_PAGE_SIZE}
          totalCount={totalCustomModelImageCount}
          navigateToPage={navigateToCustomModelImagePage}
        />
      </div>
      <CustomModelRemarkModal {...props.customModelRemarkModalProps} />
    </div>
  );
}

export function TrainingBanner() {
  return (
    <div className={styles["training-banner"]}>
      <Spinner
        size={SpinnerSize.medium}
        className={styles["training-banner-spinner"]}
      />
      <div className={styles["training-banner-spinner-title"]}>
        <FormattedMessage id="fsl_standard_model_editor.training_banner.title" />
      </div>
      <div className={styles["training-banner-spinner-desc"]}>
        <FormattedMessage id="fsl_standard_model_editor.training_banner.desc" />
      </div>
    </div>
  );
}

export function MissionBarHolder(
  props: ReturnType<typeof useFSLStandardModelEditor>
) {
  const {
    isStandardModelReady,
    currentProgress,
    isStandardModelTrainingFinished,
    isStandardModelTrainingExpired,
  } = props;

  return (
    <>
      {isStandardModelReady &&
        (isStandardModelTrainingExpired ? (
          <TrainingExpiredMissionBar />
        ) : isStandardModelTrainingFinished ? (
          <TrainedModelMissionBar />
        ) : currentProgress === Progress.UploadSamplesState ? (
          <UploadingMissionBar {...props} />
        ) : currentProgress === Progress.LabelSamplesState ? (
          <LabellingMissionBar />
        ) : (
          <TrainModelMissionBar />
        ))}
    </>
  );
}

export function FSLStandardModelEditorImpl(
  props: ReturnType<typeof useFSLStandardModelEditor>
) {
  const {
    isSchemaNotSet,
    isStandardModelEnabled,
    isStandardModelReady,
    customModelImages,
    setOpenedImageId,
    openedImageId,
    requestToOpenLabeller,
    isStandardModelTrainingInProgress,
  } = props;

  const onLightboxDismiss = React.useCallback(() => {
    setOpenedImageId(undefined);
  }, [setOpenedImageId]);

  const onOpenLabeller = React.useCallback(() => {
    requestToOpenLabeller(openedImageId);
    setOpenedImageId(undefined);
  }, [setOpenedImageId, openedImageId, requestToOpenLabeller]);

  return (
    <div className={styles["fsl-standard-model-editor"]}>
      <MissionBarHolder {...props} />
      <FSLSplitView
        className={styles["split-view"]}
        left={
          <div className={styles["fsl-standard-model-editor-left"]}>
            <PageHeader
              title="fsl_standard_model_editor.page.title"
              subtitle="fsl_standard_model_editor.page.subtitle"
              right={isStandardModelReady && <CommandBar {...props} />}
              dividerVisible={false}
            />
            {isSchemaNotSet ? (
              <FSLSchemaNotSetWarning />
            ) : !isStandardModelEnabled ? (
              <NotEnabledWarning {...props} />
            ) : (
              <SampleImagesPanel {...props} />
            )}
          </div>
        }
        right={
          <>
            {isStandardModelTrainingInProgress && <TrainingBanner />}
            <FSLActiveModelPicker />
          </>
        }
        rightMaxWidth={284}
        rightMinWidth={284}
        rightVisible={true}
        isDividerVisible={true}
      />
      <CustomModelImageLightboxModal
        images={customModelImages ?? []}
        imageID={openedImageId}
        onDismiss={onLightboxDismiss}
        onOpenLabeller={onOpenLabeller}
      />
    </div>
  );
}

export function FSLStandardModelEditor() {
  const states = useFSLStandardModelEditor();
  return <FSLStandardModelEditorImpl {...states} />;
}
