@import "../../styles/variables.scss";

.container {
  max-width: 800px;
  padding: 8px 20px;
}

.section {
  margin-bottom: 10px;

  h3 {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 18px;
  }
}
@media (max-width: 600px) {
  .section:first-child {
    h3 {
      display: none;
    }
  }
}

.section:not(:last-child) {
  padding-bottom: 20px;
}

.header {
  font-size: 18px;
  font-weight: 600;
}

.large-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .icon {
    margin-bottom: 0px;
  }
}

.inline-header {
  font-size: 16px;
  font-weight: 600;
  margin-left: 8px;
}

.icon {
  border-radius: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  background-color: #faae68;

  i {
    color: #fff;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1),
    0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  cursor: pointer;

  .description,
  .alt-description {
    font-size: 14px;
    line-height: 26.64px;
  }

  .alt-description {
    color: $neutral-secondary;
    margin-top: 8px;
  }
}

.grid-item:hover {
  background-color: $neutral-lighter-alt;
}

.pre-built-grid {
  display: grid;
  grid-gap: 16px;

  .grid-item {
    .icon {
      background-color: #6888fa;
    }
    .large-header {
      margin-bottom: 0px;
    }
  }
}

@media (min-width: 600px) {
  .pre-built-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.large-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  .grid-item {
    padding: 24px;
  }
}

.fixed-layout-block .icon {
  background-color: #ff6d7d;
}

.custom-model-block .icon {
  background-color: #25d0b1;
}
