import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo, useRef } from "react";
import { useStore } from "react-redux";
import { useLocation } from "react-router";

import { apiClient } from "../apiClient";
import { AuthGearAPIClient } from "../apiClient/authGear";
import { AppConfig } from "../config";
import errors from "../errors";
import { useGtm } from "../hooks/gtm";
import { useAppDispatch } from "../hooks/redux";
import { useRedirectToRegionForTeam } from "../hooks/region";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { RootState } from "../redux/types";
import { Plan } from "../types/plan";
import { User } from "../types/user";
import { useResourceOwnerActionCreator } from "./resourceOwner";
import { GotUserInfo, UserLogout, useUserActionCreator } from "./user";

export const Initialized = createAction("app/initialized");

export const Authenticated = createAction("app/authenticated");

export const DisablePaymentRequiredToast = createAction(
  "app/disablePaymentRequiredToast"
);

export const ToggleLeftBarCollapsed = createAction<boolean | undefined>(
  "app/toggleLeftBarCollapsed"
);

export const ToggleLeftBarCollapsedSmallScreen = createAction<
  boolean | undefined
>("app/toggleLeftBarCollapsedSmallScreen");

export const UpgradeBarDismissed = createAction("app/upgradeBarDismissed");

export const GotPlanList = createAction("app/gotPlanList", (plans: Plan[]) => ({
  payload: { plans },
}));

export const GotInvitationCode = createAction<string>("app/gotInvitationCode");

export const InvitationsIgnoredInOnboarding = createAction(
  "app/InvitationsIgnoredInOnboarding"
);

interface InitializeResult {
  invitationCode?: string;
}

export type AppAction =
  | ReturnType<typeof Initialized>
  | ReturnType<typeof DisablePaymentRequiredToast>
  | ReturnType<typeof ToggleLeftBarCollapsed>
  | ReturnType<typeof UpgradeBarDismissed>
  | ReturnType<typeof GotPlanList>
  | ReturnType<typeof GotInvitationCode>
  | ReturnType<typeof Authenticated>
  | ReturnType<typeof InvitationsIgnoredInOnboarding>;

export function useAppActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();

  const { checkIsPaymentRequired } = useResourceOwnerActionCreator();
  const { makeUserLoginAction } = useUserActionCreator();

  const { pathname } = useLocation();
  const pathnameRef = useRef(pathname);
  pathnameRef.current = pathname;

  const { redirectToRegionForTeam } = useRedirectToRegionForTeam();

  const { getParam, setParam, removeParam } = useSearchParamUtils();
  const redirectToRegionForTeamIfNeeded = useCallback(async () => {
    const teamLookupId = getParam(URLParamsKey.team);
    if (teamLookupId) {
      try {
        const { region } = await apiClient.lookupTeam(teamLookupId);
        if (region !== AppConfig.region) {
          redirectToRegionForTeam(region, teamLookupId);
          return true;
        }
      } catch (e) {
        return false;
      }
    }

    return false;
  }, [getParam, redirectToRegionForTeam]);

  const setTeamLookupIdInUrl = useCallback(
    (user: User, resourceOwnerId: string | undefined, isTeam: boolean) => {
      const teamLookupId = isTeam
        ? user.teams.find(team => team.id === resourceOwnerId)?.lookupId
        : null;

      if (teamLookupId) {
        setParam(URLParamsKey.team, teamLookupId, true);
      } else {
        removeParam(URLParamsKey.team, true);
      }
    },
    [setParam, removeParam]
  );

  const { pushIdentifiedEvent } = useGtm();
  const pushIdentifiedEventRef = useRef(pushIdentifiedEvent);

  const initialize = useCallback(async (): Promise<InitializeResult> => {
    let _invitationCode: string | undefined;
    let _isAuthenticated = false;
    let shouldDispatchInitialized = true;
    try {
      const { isAuthenticated, invitationCode, userInfo } =
        await apiClient.initialize();
      _invitationCode = invitationCode;
      _isAuthenticated = isAuthenticated;

      if (userInfo) {
        dispatch(GotUserInfo(userInfo));
        pushIdentifiedEventRef.current();
      }

      if (invitationCode) {
        dispatch(GotInvitationCode(invitationCode));
      }

      if (isAuthenticated) {
        if (await redirectToRegionForTeamIfNeeded()) {
          shouldDispatchInitialized = false;
          return {};
        }

        const user = await apiClient.getUser(AppConfig.region);
        const action = makeUserLoginAction(user);
        if (!pathnameRef.current.startsWith("/admin")) {
          setTeamLookupIdInUrl(
            user,
            action.payload.resourceOwnerId,
            action.payload.isTeam
          );
        }
        dispatch(action);
        pushIdentifiedEventRef.current();

        if (action.payload.resourceOwnerId) {
          await checkIsPaymentRequired();
        }

        if (apiClient instanceof AuthGearAPIClient) {
          apiClient.onNoSession = () => {
            dispatch(UserLogout());
          };
        }
      }
    } catch (e) {
      if (e !== errors.UserNotFound) {
        shouldDispatchInitialized = false;
        console.error(e);
      }
    } finally {
      if (_isAuthenticated) {
        dispatch(Authenticated());
      }
      if (shouldDispatchInitialized) {
        dispatch(Initialized());
      }
      return {
        invitationCode: _invitationCode,
      };
    }
  }, [
    dispatch,
    checkIsPaymentRequired,
    redirectToRegionForTeamIfNeeded,
    setTeamLookupIdInUrl,
    makeUserLoginAction,
  ]);

  const disablePaymentRequiredToast = useCallback(() => {
    dispatch(DisablePaymentRequiredToast());
  }, [dispatch]);

  const toggleLeftBarCollapsed = useCallback(
    (value: boolean | undefined) => {
      dispatch(ToggleLeftBarCollapsed(value));
    },
    [dispatch]
  );

  const toggleLeftBarCollapsedSmallScreen = useCallback(
    (value: boolean | undefined) => {
      dispatch(ToggleLeftBarCollapsedSmallScreen(value));
    },
    [dispatch]
  );

  const dismissUpgradeBar = useCallback(() => {
    dispatch(UpgradeBarDismissed());
  }, [dispatch]);

  const listPlan = useCallback(async () => {
    if (getState().app.plans.length > 0) {
      return Promise.resolve();
    }

    const plans = await apiClient.listPlan();
    dispatch(GotPlanList(plans));
  }, [dispatch, getState]);

  const ignoreInvitationsInOnboarding = useCallback(() => {
    dispatch(InvitationsIgnoredInOnboarding());
  }, [dispatch]);

  return useMemo(
    () => ({
      initialize,
      disablePaymentRequiredToast,
      toggleLeftBarCollapsed,
      toggleLeftBarCollapsedSmallScreen,
      dismissUpgradeBar,
      listPlan,
      ignoreInvitationsInOnboarding,
    }),
    [
      initialize,
      disablePaymentRequiredToast,
      toggleLeftBarCollapsed,
      toggleLeftBarCollapsedSmallScreen,
      dismissUpgradeBar,
      listPlan,
      ignoreInvitationsInOnboarding,
    ]
  );
}
