import { replaceImageUrl } from "../../utils/replaceImageUrl";
import { BriefForm, DetailedForm } from "../form";

export const BriefFormMapper = {
  fromDetailedForm: (form: DetailedForm): BriefForm => ({
    id: form.id,
    name: form.name,
    image: replaceImageUrl(form.image),
    isReadyToRectifyImage: form.isReadyToRectifyImage,
    isTemplate: form.isTemplate,
    isSample: form.isSample,
    isForCustomModelTesting: form.isForCustomModelTesting,
    isAvailableInAnchorFormGroup: form.isAvailableInAnchorFormGroup,
    documentType: form.config.document_type,
    version: form.version,
    updatedAt: form.updatedAt,
  }),
};
