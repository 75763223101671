import * as React from "react";

import { useFSLCustomModelEditor } from "../../contexts/fslCustomModelEditor";
import { FSLFieldSchemaList } from "../FSLFieldSchemaList";
import { MissionBar, MissionBarType } from "../MissionBar";
import { PageHeader } from "../PageHeader";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

function StyledMissionBar(props: {
  type: MissionBarType;
  title: string | React.ReactElement;
  right?: React.ReactElement;
}) {
  return <MissionBar {...props} className={styles["styled-mission-bar"]} />;
}

function UpdatedMissionBar(props: ReturnType<typeof useFSLFieldSchemaViewer>) {
  const { dismissUpdatedMissionBar } = props;

  return (
    <StyledMissionBar
      type={MissionBarType.Warning}
      title="fsl_custom_model.mission_bar.manage_fields.updated"
      right={
        <DefaultButton
          textId="common.dismiss"
          onClick={dismissUpdatedMissionBar}
        />
      }
    />
  );
}

function CreatedMissionBar(props: ReturnType<typeof useFSLFieldSchemaViewer>) {
  const { navigateToInstantModel } = props;

  return (
    <StyledMissionBar
      type={MissionBarType.Success}
      title="fsl_custom_model.mission_bar.manage_fields.created"
      right={
        <DefaultButton
          iconName="Forward"
          reverseIconPosition={true}
          textId="fsl_custom_model.field_schema_viewer.setup_model_button"
          onClick={navigateToInstantModel}
        />
      }
    />
  );
}

function Header(props: { right?: React.ReactNode }) {
  return (
    <PageHeader
      title="fsl_custom_model.field_schema_viewer.title"
      subtitle="fsl_custom_model.field_schema_viewer.desc"
      right={props.right}
      dividerVisible={true}
    />
  );
}

export function FSLFieldSchemaViewerContent(
  props: ReturnType<typeof useFSLFieldSchemaViewer>
) {
  const { extractedContentSchema, editSchema } = useFSLCustomModelEditor();
  const { updatedMissionBarVisible, createdMissionBarVisible } = props;

  return (
    <div className={styles["fsl-schema-viewer"]}>
      {createdMissionBarVisible && <CreatedMissionBar {...props} />}
      {updatedMissionBarVisible && <UpdatedMissionBar {...props} />}
      <Header
        right={
          <DefaultButton
            textId="fsl_custom_model.field_schema_viewer.edit_schema_button"
            onClick={editSchema}
          />
        }
      />
      <FSLFieldSchemaList
        extractedContentSchema={extractedContentSchema}
        nameVisible={true}
      />
    </div>
  );
}

export function useFSLFieldSchemaViewer() {
  const {
    extractedContentSchema,
    fslFieldSchemaViewerHandle,
    navigateToInstantModel,
  } = useFSLCustomModelEditor();
  const hasPayloadFields = (extractedContentSchema?.payload.length ?? 0) > 0;

  return React.useMemo(() => {
    return {
      hasPayloadFields,
      ...fslFieldSchemaViewerHandle.props,
      navigateToInstantModel,
    };
  }, [hasPayloadFields, fslFieldSchemaViewerHandle, navigateToInstantModel]);
}

export function FSLFieldSchemaViewerImpl(
  props: ReturnType<typeof useFSLFieldSchemaViewer>
) {
  return <FSLFieldSchemaViewerContent {...props} />;
}

export function FSLFieldSchemaViewer() {
  const states = useFSLFieldSchemaViewer();
  return <FSLFieldSchemaViewerImpl {...states} />;
}
