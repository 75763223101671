import {
  type WorkspaceGoogleSheetExport,
  type WorkspaceGoogleSheetExportConfig,
  workspaceGoogleSheetExportSchema,
} from "../../types/googleSheet";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface WorksapceGoogleSheetExportApiClient {
  createGoogleSheetExport: (
    workspaceId: string,
    oauthCredentialId: string,
    googleSpreadsheetId: string,
    googleSheetId: string | null,
    config: WorkspaceGoogleSheetExportConfig
  ) => Promise<WorkspaceGoogleSheetExport>;
}

export function withWorksapceGoogleSheetExportApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async createGoogleSheetExport(
      workspaceId: string,
      oauthCredentialId: string,
      googleSpreadsheetId: string,
      googleSheetId: string | null,
      config: WorkspaceGoogleSheetExportConfig
    ): Promise<WorkspaceGoogleSheetExport> {
      return this.lambda(
        "workspace_google_sheet_export:create",
        {
          config: {
            is_upsert_mode: config.isUpsertMode,
            mappings: config.mappings.map(mapping => ({
              column_index: mapping.columnIndex,
              column_name: mapping.columnName,
              field_name: mapping.fieldName,
              is_primary_key: mapping.isPrimaryKey,
            })),
          },
          google_sheet_id: googleSheetId,
          google_spreadsheet_id: googleSpreadsheetId,
          oauth_credential_id: oauthCredentialId,
          workspace_id: workspaceId,
        },
        workspaceGoogleSheetExportSchema,
        null
      );
    }
  };
}
