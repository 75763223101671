import { AppConfig } from "../config";

export function getCookie(name: string): string | undefined {
  const value = `; ${window.document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  } else {
    return undefined;
  }
}

export function setCookie(key: CookieKey, value: string) {
  window.document.cookie = `${key}=${value};${
    AppConfig.cookieDomain ? `domain=${AppConfig.cookieDomain};` : ""
  }`;
}

export function removeCookie(key: CookieKey) {
  window.document.cookie = `${key}=;${
    AppConfig.cookieDomain ? `domain=${AppConfig.cookieDomain};` : ""
  }Max-Age=0;`;
}

export enum CookieKey {
  authGearDefaultRefreshToken = "__authgear_default_refreshToken",
  shouldReload = "__should_reload",
}
