import { createReducer } from "@reduxjs/toolkit";

import * as workspaceIntegrationAction from "../actions/workspaceIntegration";
import { FOCRError } from "../errors";
import "../types/workspaceIntegration";

export interface WorkspaceSharePointSubscriptionState {
  isCreatingSharePointSubscription: boolean;
  workspaceSharePointSubscriptionError?: FOCRError;
}

const defaultState: WorkspaceSharePointSubscriptionState = {
  isCreatingSharePointSubscription: false,
};

export const workspaceIntegrationReducer =
  createReducer<WorkspaceSharePointSubscriptionState>(defaultState, builder => {
    builder
      .addCase(
        workspaceIntegrationAction.CreatingWorkspaceSharePointSubscription,
        (state, _action) => {
          state.isCreatingSharePointSubscription = true;
        }
      )
      .addCase(
        workspaceIntegrationAction.CreateWorkspaceSharePointSubscriptionSuccess,
        (state, _action) => {
          state.isCreatingSharePointSubscription = false;
          state.workspaceSharePointSubscriptionError = undefined;
        }
      )
      .addCase(
        workspaceIntegrationAction.CreateWorkspaceSharePointSubscriptionFailed,
        (state, action) => {
          const { error } = action.payload;
          state.isCreatingSharePointSubscription = false;
          state.workspaceSharePointSubscriptionError = error;
        }
      );
  });
