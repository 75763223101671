.detailsListOverride {
  :global(.ms-DetailsRow-cell) {
    color: #323130;
    &:last-child {
      position: sticky;
      inset: 0;
      z-index: 1;
      background-color: inherit;
    }
  }
  :global(.ms-DetailsHeader-cell) {
    color: #000000;
    background-color: #faf9f8;
    &:first-child,
    &:last-child {
      position: sticky;
      inset: 0;
      z-index: 1;
    }
  }
  :global(.ms-DetailsRow-fields) {
    align-items: center;
    background-color: inherit;
    /* for passing row bg color for cell to inherit */
  }
  :global(.ms-DetailsRow-cellCheck) {
    display: flex;
    align-items: center;
    margin-top: unset;
    padding: unset;
    position: sticky;
    inset: 0;
    background-color: inherit;
    z-index: 1;
  }
  :global(.detailsRowTopBorder.ms-DetailsRow) {
    border-top: 1px solid #edebe9;
  }
  :global(.detailsRowBottomBorder.ms-DetailsRow) {
    border-bottom: 1px solid #edebe9;
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 4px;
}
.statusExtracting {
  color: #d83b01;
}
.statusExtracted {
  color: #107c10;
}
.statusFailed {
  color: #a4262c;
}

.actionButton:hover {
  background-color: #faf9f8;
}

.fileNameDisabled {
  color: #a19f9d;
}

.documentItemDisabled {
  color: #a19f9d;
}

.fileNameLink {
  display: block;
  width: 100%;
  height: 20px;
  color: #0078d4;
  &:visited {
    color: #0078d4;
  }
  &:hover {
    text-decoration: underline;
  }
}

.disabledSelectionCheckbox {
  margin: 10px 15px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #edebe9;
}

.documentItemActionButtonGroup {
  display: flex;
}

.documentItemActionButtonDownload {
  margin-right: auto;
}

.documentItemActionButtonDelete {
  margin-left: auto;
}

.retryButton {
  all: unset;
  cursor: pointer;
  color: #0078d4;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:hover {
    opacity: 75%;
  }
  &:active {
    opacity: 50%;
  }
}

.statusCell {
  display: flex;
  align-items: center;
  gap: 8px;
}
