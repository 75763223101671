import { PDFDocumentProxy } from "pdfjs-dist";

import { getWorkerSrc } from "./pdfLib";

type PdfJsLib = typeof import("pdfjs-dist");

export interface PDFInfo {
  isPdf: boolean;
  numPages: number;
}

export class PdfService {
  pdfJsLib?: PdfJsLib;

  static instance: PdfService;

  canvas: HTMLCanvasElement | null = null;
  renderingContext: CanvasRenderingContext2D | null = null;

  async getPDFDocumentProxy(file: File): Promise<PDFDocumentProxy | undefined> {
    const pdfJsLib = await this.getPdfJsLib();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async event => {
        if (!event.target?.result) {
          reject();
          return;
        }

        try {
          const pdf = await pdfJsLib.getDocument(event.target.result).promise;
          resolve(pdf);
        } catch {
          reject();
        }
      };
      reader.onerror = event => {
        reject(event.target?.error);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  async renderPDFAsDataUris(file: File): Promise<string[]> {
    try {
      const pdf = await this.getPDFDocumentProxy(file);
      if (!pdf || pdf.numPages === 0) {
        return [];
      }
      const dataUris: string[] = [];
      if (this.canvas === null) {
        this.canvas = document.createElement("canvas");
      }
      if (this.renderingContext === null) {
        this.renderingContext = this.canvas.getContext("2d");
      }
      if (!this.renderingContext) {
        return [];
      }

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: 2 });
        this.canvas.height = viewport.height;
        this.canvas.width = viewport.width;
        this.renderingContext.clearRect(
          0,
          0,
          this.canvas.width,
          this.canvas.height
        );
        await page.render({ canvasContext: this.renderingContext, viewport })
          .promise;
        dataUris.push(this.canvas.toDataURL());
      }

      return dataUris;
    } catch {
      return [];
    }
  }

  async getPDFInfo(file: File): Promise<PDFInfo> {
    try {
      const pdf = await this.getPDFDocumentProxy(file);
      return {
        isPdf: pdf !== undefined,
        numPages: pdf?.numPages ?? 0,
      };
    } catch {
      return {
        isPdf: false,
        numPages: 0,
      };
    }
  }

  private getPdfJsLib = async (): Promise<PdfJsLib> => {
    if (this.pdfJsLib !== undefined) {
      return this.pdfJsLib;
    }

    const pdfJsLib = await import("pdfjs-dist");
    pdfJsLib.GlobalWorkerOptions.workerSrc = getWorkerSrc();

    this.pdfJsLib = pdfJsLib;

    return pdfJsLib;
  };

  static getInstance() {
    if (!this.instance) {
      this.instance = new PdfService();
    }
    return this.instance;
  }
}
