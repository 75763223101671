import { AppConfig, RegionsConfig } from "../config";
import { AuthGearAPIClient } from "./authGear";
import { BaseApiClient } from "./base";
import { ApiClientMixin, applyMixins } from "./mixin";
import { SimpleAPIClient } from "./simple";

export type ApiClient = BaseApiClient & ApiClientMixin;

export function makeApiClient(): ApiClient {
  const apiEndpoint = RegionsConfig.endpoints[AppConfig.region].api;
  const regionalEndpoints: { [Key: string]: string } = {};

  Object.keys(RegionsConfig.endpoints).forEach(key => {
    regionalEndpoints[key] = RegionsConfig.endpoints[key].api;
  });

  const client = AppConfig.authGear
    ? new (applyMixins(AuthGearAPIClient))(
        apiEndpoint,
        regionalEndpoints,
        AppConfig.authGear
      )
    : new (applyMixins(SimpleAPIClient))(apiEndpoint, regionalEndpoints);

  return client;
}

export const apiClient = makeApiClient();
