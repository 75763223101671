import { v4 as uuidv4 } from "uuid";

import { CONTROL_POINT_SIZE } from "../constants";
import { BaseInteractionHandler } from "./handler";

interface Params {
  onCreateAnchor: (anchorId: string) => void;
}

export class AnchorToolHandler extends BaseInteractionHandler {
  onCreateAnchor: (anchorId: string) => void;

  constructor(params: Params) {
    super();
    this.onCreateAnchor = params.onCreateAnchor;
  }

  uninstall(canvas: HTMLCanvasElement) {
    if (this.store) {
      this.store.partialAnchorVertices = [];
    }
    super.uninstall(canvas);
  }

  handleMouseDown = (e: MouseEvent) => {
    if (!this.store) return;

    const cursorCoord = this.getCoord(e);
    const { partialAnchorVertices } = this.store;

    if (partialAnchorVertices.length >= 3) {
      const firstVertex = partialAnchorVertices[0];
      if (
        this.isVertexNearPoint(firstVertex, cursorCoord, CONTROL_POINT_SIZE)
      ) {
        const newId = uuidv4();
        this.store.upsertAnchor({
          id: newId,
          vertices: partialAnchorVertices.map(x => ({ ...x })),
          is_enabled: true,
        });
        this.onCreateAnchor(newId);

        return;
      }
    }

    this.store.addPartialAnchorVertex(cursorCoord);
  };

  cursorStyle = () => {
    return "crosshair";
  };
}
