import * as React from "react";

type EventListener = {
  onDragStart?: (x: number, y: number) => void;
  onDragMove?: (dx: number, dy: number) => void;
  onDragEnd?: () => void;
};

export function useDragTracker(
  dragHandleRef: React.RefObject<HTMLElement>,
  eventListener: EventListener
) {
  const { onDragStart, onDragMove, onDragEnd } = eventListener;
  const [counter, setCounter] = React.useState(0);

  const reinstallEventListener = React.useCallback(() => {
    setCounter(prev => prev + 1);
  }, []);

  React.useEffect(() => {
    if (!dragHandleRef.current) {
      return;
    }

    let isDown = false;
    let startY = 0;
    let startX = 0;
    const dragHandle = dragHandleRef.current;

    const onMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      isDown = true;
      startY = e.clientY;
      startX = e.clientX;
      onDragStart?.(startX, startY);
    };

    const onMouseUp = (e: MouseEvent) => {
      if (isDown) {
        e.preventDefault();
        e.stopPropagation();
        isDown = false;
        onDragEnd?.();
      }
    };

    const onMouseMove = (e: MouseEvent) => {
      if (!isDown) return;

      e.preventDefault();
      e.stopPropagation();
      const dy = e.clientY - startY;
      const dx = e.clientX - startX;
      onDragMove?.(dx, dy);
    };

    dragHandle.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", onMouseMove);

    return () => {
      dragHandle.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [dragHandleRef, onDragStart, onDragMove, onDragEnd, counter]);

  return reinstallEventListener;
}
