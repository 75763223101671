@import "../../styles/variables.scss";

.title {
  font-weight: 600;
}

.desc {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}
