import { getMiddleCenterBias } from "@glideapps/glide-data-grid";

export function truncatStr(
  text: string,
  context: CanvasRenderingContext2D,
  fitWidth: number,
  tailSymbol: string = "..."
): [result: string, truncatedAt?: number] {
  const { width } = context.measureText(text);
  if (width <= fitWidth) {
    return [text, undefined];
  }
  let left = 0;
  let right = text.length;
  let end;
  while (true) {
    end = parseInt(((right + left) / 2).toFixed());
    const truncatedStr = text.substring(0, end) + tailSymbol;
    const { width } = context.measureText(truncatedStr);
    if (width > fitWidth) {
      right = end;
    }
    if (width < fitWidth) {
      left = end;
    }
    if (right - left < 2) {
      return [text.substring(0, left) + tailSymbol, left];
    }
  }
}

export function drawText(
  context: CanvasRenderingContext2D,
  text: string,
  posX: number,
  posY: number,
  font: string,
  fillStyle?: string
) {
  context.font = font;
  context.fillStyle = fillStyle ?? "black";
  const bias = getMiddleCenterBias(context, font);
  context.fillText(text, posX, posY + bias);
}
