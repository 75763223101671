@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.token-group-content {
  display: flex;
  flex-direction: column;
  padding: 4px 0 4px 8px;
  width: 100%;
  border-bottom: 1px solid $tab-bar-separator;

  .token-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .token-row-second {
    border-top: 1px solid $tab-bar-separator;
  }

  .link {
    margin-left: 8px;
    padding: 8px 0 6px;
    font-size: 14px;
  }
}

.token-card {
  @include cardBoxBorderRadius;
  margin: 8px;
  max-width: 260px;
  > span {
    display: inline-block;
    max-width: 246px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.text-token-card {
  background-color: #f3f2f1;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 4px;
  margin-right: 8px;
  height: 24px;

  cursor: pointer;

  > span {
    font-size: 14px;
    padding-right: 6px;
  }
}

.delete-button {
  cursor: pointer;
  font-size: 14px;
}

.image-token-card {
  margin-right: 8px;
  display: flex;
  flex-direction: column;

  .image {
    height: 48px;
    width: 72px;
    cursor: pointer;
    border: 1px solid $tab-bar-separator;
    margin: 0;
    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .bottom-bar {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > span {
      display: block;
      margin-top: 6px;
      width: 58px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }
  }

  .delete-button {
    padding-top: 2px;
  }
}

.image-token-spinner {
  width: 72px;
  height: 70px;
  margin: 8px;
}
