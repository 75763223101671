@import "../../styles/variables.scss";

.model-description {
  color: $neutral-primary-alt;
}

.action-button {
  color: $theme-primary;
  cursor: pointer;
  float: right;
  margin-bottom: 20px;
}

.team-area {
  margin: 16px 0px;
  padding-bottom: 8px;
  border: 1px solid $separator-color;
  max-height: 232px;
  overflow-y: auto;

  .region-title {
    .text {
      color: $theme-primary;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
    }

    padding: 8px 12px;
    position: relative;
  }

  .team-entry {
    padding: 8px 12px;

    .team-button {
      cursor: pointer;
      font-size: 14px;
    }

    .team-button:hover {
      color: $theme-secondary;
    }
  }
}
