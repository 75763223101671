import * as yup from "yup";

export const authResponseSchema = yup
  .object({
    accessToken: yup.string().required(),
  })
  .camelCase();

export const authStateSchema = yup
  .object({
    invitationCode: yup.string().optional().nullable(),
    region: yup.string().optional().nullable(),
    distinctId: yup.string().optional().nullable(),
    doNotTrack: yup.boolean().optional().nullable(),
  })
  .camelCase();

export const authEncodeResponseSchema = yup
  .object({
    state: yup.string().required(),
  })
  .camelCase();

export type AuthResponse = yup.InferType<typeof authResponseSchema>;
export type AuthState = yup.InferType<typeof authStateSchema>;
export type AuthEncodeStateResponse = yup.InferType<
  typeof authEncodeResponseSchema
>;
