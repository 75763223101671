import { createAction } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { AppConfig } from "../config";
import { useAppDispatch } from "../hooks/redux";
import { RootState } from "../redux/types";
import type { OAuthCallback } from "./oauth";

export const GoogleAuthReset = createAction("googleAuth/reset", () => ({
  payload: null,
}));

export const GoogleAuthLogin = createAction(
  "googleAuth/login",
  (nonce: string) => ({
    payload: { nonce },
  })
);

export const GoogleAuthAuthenticationFailed = createAction(
  "googleAuth/authenticationFailed",
  (error: string, errorDescription: string | null) => ({
    payload: { error, errorDescription },
  })
);

export const GoogleAuthAuthenticated = createAction(
  "googleAuth/authenticated",
  (nonce: string) => ({
    payload: { nonce },
  })
);

export type GoogleAuthAction =
  | ReturnType<typeof GoogleAuthLogin>
  | ReturnType<typeof GoogleAuthAuthenticated>
  | ReturnType<typeof GoogleAuthAuthenticationFailed>;

export function useGoogleAuthActionCreator() {
  const dispatch = useAppDispatch();

  const [dialog, setDialog] = useState<Window | null>(null);

  const { isAuthorizing, nonce } = useSelector(
    (state: RootState) => state.googleAuth
  );
  const { currentUser } = useSelector((state: RootState) => state.user);

  const auth = useCallback(
    async (scopes: string[]) => {
      if (AppConfig.googleAuth === undefined) {
        throw new Error("AppConfig.googleAuth not configured");
      }

      if (currentUser === undefined) {
        throw new Error("User not logged-in");
      }

      const width = Math.min(500, screen.width - 40);
      const height = Math.min(550, screen.height - 40);

      const features = [
        "copyhistory=no",
        "directories=no",
        "location=no",
        "menubar=no",
        "resizable=no",
        "scrollbars=no",
        "status=no",
        "toolbar=no",
        `height=${height}`,
        `left=${screen.width / 2 - width / 2}`,
        `top=${screen.height / 2 - width / 2}`,
        `width=${width}`,
      ];

      const nonce = uuidv4();

      // NOTE: prompt set to "consent" to make sure it always response "refresh_token"
      const params = new URLSearchParams({
        access_type: "offline",
        client_id: AppConfig.googleAuth.clientId,
        gsiwebsdk: "3",
        include_granted_scopes: "true",
        prompt: ["consent"].join(" "),
        redirect_uri: `${AppConfig.api.endpoint}oauth`,
        response_type: "code",
        scope: [...AppConfig.googleAuth.oauthScopes, ...scopes].join(" "),
        state: Buffer.from(
          JSON.stringify({
            nonce,
            resource_server: "google",
            user_id: currentUser.id,
          }),
          "utf8"
        ).toString("base64"),
      });

      dispatch(GoogleAuthLogin(nonce));

      const url = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;
      const dialog = window.open(url, "google_auth_dialog", features.join(","));
      if (!dialog || dialog.closed || typeof dialog.closed === "undefined") {
        throw new Error(
          `Failed to open popup window on url: ${url}. Maybe blocked by the browser?`
        );
      }
      dialog.focus();

      setDialog(dialog);
    },
    [currentUser, dispatch]
  );

  const reset = useCallback(() => {
    dispatch(GoogleAuthReset());
  }, [dispatch]);

  const onAuthCallback = useCallback(
    (payload: OAuthCallback) => {
      const { error, errorDescription } = payload;
      if (!isAuthorizing) {
        return;
      }
      if (payload.nonce !== null) {
        if (payload.nonce !== nonce) {
          dispatch(
            GoogleAuthAuthenticationFailed("nonce_mismatch", "Nonce mismatch")
          );
        } else {
          dispatch(GoogleAuthAuthenticated(nonce));
        }
      } else {
        dispatch(
          GoogleAuthAuthenticationFailed(
            error ?? "unknown",
            errorDescription ?? null
          )
        );
      }
    },
    [dispatch, isAuthorizing, nonce]
  );

  useEffect(() => {
    if (dialog === null) {
      return () => {};
    }

    const interval = setInterval(() => {
      if (dialog.closed) {
        setDialog(null);
        dispatch(
          GoogleAuthAuthenticationFailed("user_cancelled", "User Cancelled.")
        );
      }
    }, 500);

    return () => clearInterval(interval);
  }, [dispatch, dialog]);

  useEffect(() => {
    if (dialog === null) {
      return () => {};
    }

    const handleEvent = ({ data, origin, source }: MessageEvent) => {
      if (origin === window.location.origin && source === dialog) {
        setDialog(null);
        dialog.close();
        onAuthCallback(data);
      }
    };

    window.addEventListener("message", handleEvent);
    return () => window.removeEventListener("message", handleEvent);
  }, [dialog, onAuthCallback]);

  return useMemo(() => ({ auth, reset }), [auth, reset]);
}
