.formatter-step-item {
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  max-width: 836px;
  width: calc(100% - 40px);
  cursor: pointer;

  &:hover {
    background-color: #f3f2f1;
  }

  .left {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;

    .label {
      padding: 4px 10px 5px;
      background-color: #edebe9;
      border-radius: 4px;

      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #605e5c;

      margin-left: 6px;
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }

    .trash-icon {
      margin-left: 12px;
      margin-right: 15px;
    }
  }

  .drag-icon {
    /* Hide vertical dot until we have drag and drop support */
    opacity: 0;
    color: #bdbdbd;
    margin-top: 2px;
    margin-left: 12px;
    /* margin-right: 22px; */
  }

  .index {
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 145%;
    color: #a19f9d;
    margin-right: 8px;
  }

  .item-title {
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 145%;
    color: #323130;
  }
}

.formatter-step-item-error {
  border: 1px solid #a4262c;

  .index {
    color: #a4262c;
  }

  .item-title {
    color: #a4262c;
  }
}

.formatter-step-item-selected {
  border: 1px solid #25d0b1;
}

.formatter-editor {
  background-color: #faf9f8;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .title {
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
    background-color: #fff;
    padding-bottom: 16px;

    .heading {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 8px;
      color: #323130;
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      gap: 4px;
    }

    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #a19f9d;
    }

    .error {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #a4262c;
    }
  }

  .vertical-line {
    background-color: #cccccc;
    height: 30px;
    width: 1px;
    margin: 10px auto;
  }

  .add-button {
    display: block;
    color: #fff;
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin-left: auto;
    margin-right: auto;

    :global(.ms-Icon) {
      color: #fff;
    }
  }

  .add-step {
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #25d0b1;
  }

  .placeholder {
    margin-top: 24px;
    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #a19f9d;
    }
  }

  .step-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
