import { IBreadcrumbItem } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router";

import { useLocale } from "../../contexts/locale";
import AppBreadcrumb from "../AppBreadcrumb";
import styles from "./styles.module.scss";

interface WebhookNavBarProps {
  isCreate: boolean;
}

const WebhookNavBar = React.memo((props: WebhookNavBarProps) => {
  const { isCreate } = props;
  const { localized } = useLocale();
  const navigate = useNavigate();

  const onBreadcrumbItemClick = React.useCallback(
    (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
      if (ev === undefined || item === undefined || item.href === undefined) {
        return;
      }
      ev.preventDefault();
      navigate(item.href);
    },
    [navigate]
  );

  // TODO: Show Edit Webhook
  const breadcrumbItems: IBreadcrumbItem[] = React.useMemo(() => {
    if (isCreate) {
      return [
        {
          text: localized("webhook.title"),
          key: "webhooks",
          href: "/webhook",
          onClick: onBreadcrumbItemClick,
        },
        {
          text: localized("webhook.new.title"),
          key: "new_webhook",
        },
      ];
    }

    return [
      {
        text: localized("webhook.title"),
        key: "webhooks",
        href: "/webhook",
        onClick: onBreadcrumbItemClick,
      },
      {
        text: localized("webhook.edit.title"),
        key: "edit_webhook",
      },
    ];
  }, [localized, onBreadcrumbItemClick, isCreate]);

  return (
    <AppBreadcrumb className={styles["nav-bar"]} items={breadcrumbItems} />
  );
});

export default WebhookNavBar;
