import * as yup from "yup";

import { anchorSchema } from "./anchor";
import { briefWorkspaceSchema } from "./briefWorkspace";
import { detectionRegionSchema } from "./detectionRegion";
import { fieldSchema } from "./field";
import { FormConfig } from "./formConfig";
import { keyValueSchema } from "./keyValue";
import { pageInfoSchema, pageInfoWithOffsetSchema } from "./pageInfo";
import { tokenGroupSchema } from "./tokenGroup";

const documentTypeSchema = yup
  .string()
  .oneOf([
    "general",
    "receipt",
    "bank_statement",
    "address_proof",
    "invoice",
    "international_id",
    "invoice_llm",
    "bank_statement_llm",
    "bill_of_lading_llm",
  ])
  .defined();

export const briefFormSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
    version: yup.string().defined(),
    image: yup.string().optional().nullable(),
    imageId: yup.string().optional().nullable(),
    documentType: documentTypeSchema.required(),
    isTemplate: yup.boolean().required(),
    isSample: yup.boolean().required(),
    isReadyToRectifyImage: yup.boolean().required(),
    isForCustomModelTesting: yup.boolean().required(),
    isAvailableInAnchorFormGroup: yup.boolean().required(),
    updatedAt: yup.string().defined(),
  })
  .transform((value, _original) => {
    if (value === null) {
      return value;
    }
    const { _image, ...rest } = value;
    return {
      ...rest,
      image: value.image === null ? undefined : value.image,
    };
  })
  .shape({
    image: yup.string().optional().nullable(),
  })
  .camelCase();

export const formSchema = briefFormSchema
  .shape({
    config: yup.mixed((_input): _input is FormConfig => true).defined(),
    tokenGroups: yup.array(tokenGroupSchema).defined(),
    keyValues: yup.array(keyValueSchema).defined(),
    customModelIds: yup.array(yup.string().defined()).defined(),
  })
  .camelCase();

export const detailedFormSchema = formSchema
  .shape({
    anchors: yup.array(anchorSchema).defined(),
    fields: yup.array(fieldSchema).defined(),
    detectionRegions: yup.array(detectionRegionSchema).defined(),
    resourceOwnerId: yup.string().nullable(),
    workspaces: yup.array(briefWorkspaceSchema).defined(),
  })
  .camelCase();

export const paginatedBriefFormSchema = yup
  .object({
    pageInfo: pageInfoSchema.required(),
    forms: yup.array(briefFormSchema).defined(),
  })
  .camelCase();

export type DocumentType = yup.InferType<typeof documentTypeSchema>;

export type BriefForm = yup.InferType<typeof briefFormSchema>;
export type BriefFormWithType = BriefForm & {
  type: "form";
};

export type Form = yup.InferType<typeof formSchema>;

export type DetailedForm = yup.InferType<typeof detailedFormSchema>;

export type PaginatedBriefForm = yup.InferType<typeof paginatedBriefFormSchema>;

export type Dimension = {
  width: number;
  height: number;
};

export type ImageInfo = {
  imageId: string | null;
  size: Dimension | null;
};

export enum FormExtractionMode {
  singlePage = "single_page",
  multiPagePdf = "multi_page_pdf",
  singlePageMultiDocument = "single_page_multi_document",
  combineMultiPagePdf = "combine_multi_page_pdf",
  multiPagePdfMultiDocument = "multi_page_pdf_multi_document",
}

export const paginatedWithOffsetBriefFormSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    forms: yup.array(briefFormSchema).defined(),
  })
  .camelCase();

export type PaginatedWithOffsetBriefForm = yup.InferType<
  typeof paginatedWithOffsetBriefFormSchema
>;
