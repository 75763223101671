%action-btn {
  font-size: 12px;
}
.header {
  border-bottom: 1px solid #edebe9;
  display: flex;
  align-items: center;

  .nav-menu-btn {
    color: #a19f9d;
    &--toggled {
      color: #25d0b1;
    }
  }
  .ordering-btn {
    @extend %action-btn;
    margin-left: 12px;
  }
  .csv-file-input {
    display: none;
  }
}

.nav-bar {
  display: flex;
  padding: 8px 12px;
  border-bottom: 1px solid #edebe9;
  align-items: center;
  .breadcrumb {
    margin: 0;
  }
  .button-group {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      margin-left: 8px;
    }

    .save-button {
      color: black;
      background-color: #ffd633;
      border-color: #ffd633;
    }
  }
}
