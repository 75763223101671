@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.form-inspector {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  width: 340px;
  height: 100%;
  @include ms-depth-8;

  > :global(div.ms-Pivot--tabs) {
    margin-left: 16px;
    padding: 12px 0;
    > button {
      font-size: 14px;
      width: 88px;

      > span {
        display: block;
      }
    }

    :global(.is-disabled) {
      background-color: #f4f4f4;
      color: rgb(180, 178, 176);
    }
  }

  > div[role="tabpanel"] {
    flex: 1;
    display: flex;
    overflow-y: hidden;

    > div {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

.nothing-detected-desc {
  margin-top: 10px;
  padding-left: 6px;
  padding-right: 24px;
}
