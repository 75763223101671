import { createReducer } from "@reduxjs/toolkit";

import * as microsoftAuthAction from "../actions/microsoftAuth";

interface MicrosoftAuthState {
  isLoggingIn: boolean;
  nonce: string | null;
  tokens: {
    idToken: string;
    accessToken: string;
    refreshToken: string;
  } | null;
  loginError: {
    error: string;
    description: string | null;
  } | null;
}

const defaultState = {
  isLoggingIn: false,
  nonce: null,
  tokens: null,
  loginError: null,
};

export const microsoftAuthReducer = createReducer<MicrosoftAuthState>(
  defaultState,
  builder => {
    builder
      .addCase(microsoftAuthAction.MicrosoftAuthLogin, (state, action) => {
        state.isLoggingIn = true;
        state.nonce = action.payload.nonce;
        state.tokens = null;
        state.loginError = null;
      })
      .addCase(
        microsoftAuthAction.MicrosoftAuthAuthenticated,
        (state, action) => {
          state.isLoggingIn = false;
          state.nonce = null;
          state.tokens = {
            idToken: action.payload.idToken,
            accessToken: action.payload.accessToken,
            refreshToken: action.payload.refreshToken,
          };
          state.loginError = null;
        }
      )
      .addCase(
        microsoftAuthAction.MicrosoftAuthAuthenticationFailed,
        (state, action) => {
          state.isLoggingIn = false;
          state.nonce = null;
          state.tokens = null;
          state.loginError = {
            error: action.payload.error,
            description: action.payload.description,
          };
        }
      );
  }
);
