@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.team-plan {
  .team-plan-info {
    margin-top: 20px;
    text-transform: capitalize;
    color: $dark-color;

    .team-plan-info-title {
      font-weight: 600px;
      font-size: 16px;
    }

    .team-plan-info-content {
      font-weight: 300;
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .team-plan-buttons {
    margin-top: 30px;

    .team-plan-button {
      display: block;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  .team-remove-plan-button {
    background-color: #e13d3d;
    color: white;
    border-width: 0;

    &:hover {
      background-color: #bd1c1c;
      color: white;
    }
  }
}
