import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import errors, { FOCRError } from "../errors";
import { PendingState, useMergeableAsyncCallback } from "../hooks/common";
import { useAppDispatch } from "../hooks/redux";
import { useCreateOrigin } from "../hooks/tracking";
import { ListExtractionsParams } from "../reducers/extraction";
import { RootState } from "../redux/types";
import { AbortControllerService } from "../services/abortController";
import { TaskRunner } from "../services/taskRunner";
import { UploadFileKeeperService } from "../services/uploadFileKeeper";
import { Sort } from "../types/api";
import {
  Extraction,
  ExtractionFilterableField,
  ExtractionResult,
  ExtractionResultPageInfo,
  PaginatedExtractions,
} from "../types/extraction";
import { Workspace } from "../types/workspace";
import { ensureFOCRError } from "../utils/errors";
import { triggerFileSave } from "../utils/file";
import { PreferenceKey, getPreference } from "../utils/preference";
import {
  ExtractV2Options,
  V2AsyncExtractionResponse,
  WorkerResponseSuccess,
  workerClient,
} from "../workerClient";

export const ClearListExtractionsParamsShouldOverrideUrl = createAction(
  "extraction/list/clearParamsShouldOverrideUrl",
  (payload: { workspaceId: string }) => ({ payload })
);

export const ListExtractions = createAction(
  "extraction/list",
  (payload: { workspaceId: string; params: ListExtractionsParams }) => ({
    payload,
  })
);

export const ListExtractionsSuccess = createAction<{
  workspaceId: string;
  params: ListExtractionsParams;
  result: PaginatedExtractions;
}>("extraction/list/success");

export const ListExtractionsFailed = createAction<{
  workspaceId: string;
  error: FOCRError;
}>("extraction/list/failed");

export const ListProcessingExtractions = createAction(
  "extraction/listProcessing",
  (payload: { workspaceId: string }) => ({
    payload,
  })
);

export const ListProcessingExtractionsSuccess = createAction<{
  workspaceId: string;
  extractions: Extraction[];
  extractionResults: ExtractionResult[];
}>("extraction/listProcessing/success");

export const ListProcessingExtractionsFailed = createAction<{
  workspaceId: string;
  error: FOCRError;
}>("extraction/listProcessing/failed");

export const CreateExtraction = createAction(
  "extraction/create",
  (payload: {
    uploadId: string;
    fileName: string;
    fileKey: string;
    workspaceId: string;
  }) => ({
    payload,
  })
);

export const CreateExtractionSuccess = createAction(
  "extraction/create/success",
  (payload: { uploadId: string; workspaceId: string }) => ({
    payload,
  })
);

export const CreateExtractionFailed = createAction(
  "extraction/create/failed",
  (payload: { uploadId: string; error: FOCRError; workspaceId: string }) => ({
    payload,
  })
);

export const ReExtractExtraction = createAction(
  "extraction/re-extract",
  (payload: { workspaceId: string; extractionResult: ExtractionResult }) => ({
    payload,
  })
);

export const ReExtractExtractionFailed = createAction(
  "extraction/re-extract/failed",
  (payload: {
    error: FOCRError;
    workspaceId: string;
    extractionResultId: string;
  }) => ({
    payload,
  })
);

export const CleanupUploadQueue = createAction(
  "extraction/create/cleanup",
  (payload: { workspaceId: string }) => ({ payload })
);

export const DeleteUploadQueueEntry = createAction(
  "extraction/create/deleteEntry",
  (payload: { workspaceId: string; uploadId: string }) => ({ payload })
);

export interface DeleteExtractionIdTuple {
  workspaceId: string;
  extractionId: string;
}

export const DeleteExtraction = createAction(
  "extraction/delete",
  (payload: { idTuples: DeleteExtractionIdTuple[] }) => ({ payload })
);

export const DeleteAllExtractions = createAction(
  "extraction/deleteAll",
  (payload: { workspaceId: string }) => ({ payload })
);

export const DeleteExtractionResults = createAction(
  "extraction/deleteResults",
  (payload: {
    workspaceId: string;
    resultKeys: {
      extractionId: string;
      pageNumber: number;
      sliceNumber: number;
    }[];
  }) => ({ payload })
);

export const ExportExtraction = createAction(
  "extraction/export",
  (payload: { workspaceId: string }) => ({ payload })
);

export const ExportExtractionComplete = createAction(
  "extraction/export/complete",
  (payload: { workspaceId: string }) => ({ payload })
);

export const GetExtraction = createAction(
  "extraction/get",
  (payload: { extractionId: string }) => ({ payload })
);

export const GetExtractionSuccess = createAction(
  "extraction/get/success",
  (payload: { extractionId: string; extraction: Extraction }) => ({ payload })
);

export const GetExtractionFailed = createAction(
  "extraction/get/failed",
  (payload: { extractionId: string; error: FOCRError }) => ({ payload })
);

export const GetExtractionResult = createAction(
  "extraction/result/get",
  (payload: { extractionResultId: string }) => ({ payload })
);

export const GetExtractionResultSuccess = createAction(
  "extraction/result/get/success",
  (payload: {
    extractionResultId: string;
    extractionResult: ExtractionResult;
    extractionResultPageInfo: ExtractionResultPageInfo;
  }) => ({
    payload,
  })
);

export const GetExtractionResultFailed = createAction(
  "extraction/result/get/failed",
  (payload: { extractionResultId: string; error: FOCRError }) => ({ payload })
);

const listExtractionsPendingState: PendingState<
  PaginatedExtractions,
  [
    {
      workspaceId: string;
      page: number;
      size: number;
      fileName: string;
      sort: Sort<ExtractionFilterableField>;
      shouldOverrideUrlState?: boolean;
    }
  ]
> = {
  isRunning: false,
  promises: [],
  params: null,
};

const listProcessingExtractionsPendingState: PendingState<
  void,
  [
    {
      workspaceId: string;
    }
  ]
> = {
  isRunning: false,
  promises: [],
  params: null,
};

const extractForWorkspace = async (
  token: string,
  workspaceId: string,
  file: File,
  abortSignal?: AbortSignal,
  options?: ExtractV2Options
): Promise<V2AsyncExtractionResponse> => {
  return await workerClient(token).extractForWorkspace(
    workspaceId,
    file,
    abortSignal,
    options
  );
};

const reExtractForWorkspace = async (
  token: string,
  workspaceId: string,
  extractionResultId: string
): Promise<WorkerResponseSuccess> => {
  return await workerClient(token).reExtractForWorkspace(
    workspaceId,
    [extractionResultId],
    getPreference(PreferenceKey.allowReExtractSuccessResult) === "true"
      ? false
      : true
  );
};

export function useExtractionActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();

  const clearListParamsShouldOverrideUrl = useCallback(
    (workspaceId: string) => {
      dispatch(ClearListExtractionsParamsShouldOverrideUrl({ workspaceId }));
    },
    [dispatch]
  );

  const listExtractions = useMergeableAsyncCallback(
    () => listExtractionsPendingState,
    async ({
      workspaceId,
      page,
      size,
      fileName,
      sort,
      shouldOverrideUrlState = false,
    }: {
      workspaceId: string;
      page: number;
      size: number;
      fileName: string;
      sort: Sort<ExtractionFilterableField>;
      shouldOverrideUrlState?: boolean;
    }): Promise<PaginatedExtractions> => {
      dispatch(
        ListExtractions({
          workspaceId,
          params: {
            page,
            size,
            fileName: fileName,
            sort: sort,
            shouldOverrideUrlState,
          },
        })
      );

      try {
        const result = await apiClient.listExtractions({
          workspaceId,
          size: size,
          offset: (page - 1) * size,
          fileName: fileName.length > 0 ? fileName : undefined,
          sort: [sort],
        });

        dispatch(
          ListExtractionsSuccess({
            workspaceId,
            params: {
              page,
              size,
              fileName,
              sort,
              shouldOverrideUrlState,
            },
            result,
          })
        );

        return result;
      } catch (e) {
        dispatch(
          ListExtractionsFailed({ workspaceId, error: ensureFOCRError(e) })
        );
        throw ensureFOCRError(e);
      }
    },
    [dispatch]
  );

  const listProcessingExtractions = useMergeableAsyncCallback(
    () => listProcessingExtractionsPendingState,
    async ({ workspaceId }: { workspaceId: string }) => {
      dispatch(ListProcessingExtractions({ workspaceId }));

      try {
        const { extractions, extractionResults } =
          await apiClient.listProcessingExtractions({
            workspaceId,
          });

        dispatch(
          ListProcessingExtractionsSuccess({
            workspaceId,
            extractions,
            extractionResults,
          })
        );
      } catch (e) {
        dispatch(
          ListProcessingExtractionsFailed({
            workspaceId,
            error: ensureFOCRError(e),
          })
        );
      }
    },
    [dispatch]
  );

  const createOrigin = useCreateOrigin();

  const createExtraction = useCallback(
    async (args: { file: File; workspace: Workspace; token: string }) => {
      const extractorId =
        args.workspace.extractor?.associatedExtractorId ??
        args.workspace.extractor?.id;
      if (extractorId == null) {
        return;
      }
      const origin = await createOrigin("formx.portal.workspace");
      const [uploadId, doUpload] =
        AbortControllerService.getInstance().withAbortController(
          abortController =>
            extractForWorkspace(
              args.token,
              args.workspace.id,
              args.file,
              abortController.signal,
              {
                shouldOutputOrientation: true,
                origin,
              }
            )
        );
      UploadFileKeeperService.getInstance().setFile(uploadId, args.file);
      dispatch(
        CreateExtraction({
          uploadId,
          fileName: args.file.name,
          fileKey: uploadId,
          workspaceId: args.workspace.id,
        })
      );
      try {
        await TaskRunner.getWorkspaceUploadRunner().enqueue(doUpload);
        dispatch(
          CreateExtractionSuccess({ uploadId, workspaceId: args.workspace.id })
        );
        // upload succeeded and no retry will be needed, remove file from keeper
        UploadFileKeeperService.getInstance().deleteFile(uploadId);
      } catch (e) {
        if (e instanceof FOCRError) {
          dispatch(
            CreateExtractionFailed({
              uploadId,
              error: e,
              workspaceId: args.workspace.id,
            })
          );
        } else {
          dispatch(
            CreateExtractionFailed({
              uploadId,
              error: errors.UnknownError,
              workspaceId: args.workspace.id,
            })
          );
        }
      }
    },
    [dispatch, createOrigin]
  );

  const retryExtraction = useCallback(
    async (args: { uploadId: string; workspace: Workspace; token: string }) => {
      const uploadQueueEntry =
        getState().extraction.uploadQueueByWorkspace[args.workspace.id]?.[
          args.uploadId
        ];
      if (uploadQueueEntry == null) {
        return;
      }
      if (uploadQueueEntry.state !== "errored") {
        throw new Error(
          "The upload queue entry is not in error state. This should not happen."
        );
      }
      const file = UploadFileKeeperService.getInstance().getFile(
        uploadQueueEntry.fileKey
      );
      if (file == null) {
        throw new Error(
          "The original file is missing in file keeper service. This should not happen."
        );
      }
      dispatch(
        DeleteUploadQueueEntry({
          workspaceId: args.workspace.id,
          uploadId: args.uploadId,
        })
      );
      // old upload queue entry is retired, delete old entry from file keeper
      // the file will be added to keeper again at createExtraction
      UploadFileKeeperService.getInstance().deleteFile(
        uploadQueueEntry.fileKey
      );
      return createExtraction({
        file,
        workspace: args.workspace,
        token: args.token,
      });
    },
    [createExtraction, dispatch, getState]
  );

  const retryAllFailedExtractions = useCallback(
    (args: { workspace: Workspace; token: string }): Promise<void>[] => {
      const uploadQueue =
        getState().extraction.uploadQueueByWorkspace[args.workspace.id];
      if (uploadQueue == null) {
        return [];
      }
      return Object.values(uploadQueue).reduce((prev, curr) => {
        if (curr.state !== "errored") {
          return prev;
        }
        return [
          ...prev,
          retryExtraction({
            uploadId: curr.id,
            workspace: args.workspace,
            token: args.token,
          }),
        ];
      }, [] as Promise<void>[]);
    },
    [getState, retryExtraction]
  );

  const exportExtractions = useCallback(
    async (args: {
      workspaceId: string;
      resultIndexesByExtractionId: Record<string, number[]>;
    }) => {
      dispatch(ExportExtraction({ workspaceId: args.workspaceId }));
      try {
        await TaskRunner.getWorkspaceExportRunner().enqueue(async () => {
          const response = await apiClient.exportExtractions(args);
          await triggerFileSave(response);
        });
      } catch (e: unknown) {
        throw e;
      } finally {
        dispatch(ExportExtractionComplete({ workspaceId: args.workspaceId }));
      }
    },
    [dispatch]
  );

  const exportAllExtractions = useCallback(
    async (args: { workspaceId: string }) => {
      dispatch(ExportExtraction({ workspaceId: args.workspaceId }));
      try {
        await TaskRunner.getWorkspaceExportRunner().enqueue(async () => {
          const response = await apiClient.exportAllExtractions(args);
          await triggerFileSave(response);
        });
      } catch (e: unknown) {
        throw e;
      } finally {
        dispatch(ExportExtractionComplete({ workspaceId: args.workspaceId }));
      }
    },
    [dispatch]
  );

  const cleanupUploadQueue = useCallback(
    ({ workspaceId }: { workspaceId: string }) => {
      dispatch(CleanupUploadQueue({ workspaceId }));
    },
    [dispatch]
  );

  const deleteExtraction = useCallback(
    async ({ idTuples }: { idTuples: DeleteExtractionIdTuple[] }) => {
      try {
        await apiClient.deleteExtractions({
          extractionIds: idTuples.map(tuple => tuple.extractionId),
        });
        dispatch(DeleteExtraction({ idTuples }));
      } catch (e: unknown) {
        throw e;
      }
    },
    [dispatch]
  );

  const deleteAllExtractions = useCallback(
    async (workspaceId: string) => {
      try {
        await apiClient.deleteAllExtractions({
          workspaceId,
        });
        dispatch(DeleteAllExtractions({ workspaceId }));
      } catch (e: unknown) {
        throw e;
      }
    },
    [dispatch]
  );

  const deleteExtractionResults = useCallback(
    async (
      workspaceId: string,
      results: {
        extractionId: string;
        extractionResult: ExtractionResult;
      }[]
    ) => {
      const resultKeys: {
        extractionId: string;
        pageNumber: number;
        sliceNumber: number;
        extractionResultId?: string;
      }[] = results.map(res => ({
        extractionId: res.extractionId,
        pageNumber: res.extractionResult.info.pageNumber,
        sliceNumber: res.extractionResult.info.sliceNumber,
        extractionResultId: res.extractionResult.id,
      }));
      try {
        const resultKeysInInfo = resultKeys.filter(
          key => key.extractionResultId == null
        );
        const resultIdsInModel = resultKeys
          .filter(key => key.extractionResultId != null)
          .map(key => key.extractionResultId as string);
        if (
          resultKeysInInfo.length + resultIdsInModel.length !==
          resultKeys.length
        ) {
          throw new Error(
            "Results length mismatch after splitting old/new records, this should not happen"
          );
        }
        // call old api to delete results in info json
        if (resultKeysInInfo.length > 0) {
          await apiClient.deleteExtractionResultsInInfo({
            resultKeys: resultKeysInInfo,
          });
        }
        // call new api to delete results in model
        if (resultIdsInModel.length > 0) {
          await apiClient.deleteExtractionResults({
            extractionResultIds: resultIdsInModel,
          });
        }
        dispatch(
          DeleteExtractionResults({
            workspaceId,
            resultKeys,
          })
        );
      } catch (e: unknown) {
        throw e;
      }
    },
    [dispatch]
  );

  const reExtractExtraction = useCallback(
    async (
      token: string,
      workspaceId: string,
      extractionResult: ExtractionResult
    ) => {
      const extractionResultId = extractionResult.id;
      if (extractionResultId == null) {
        throw new Error(
          "extraction result has no id, i.e. re-extract is called with old result data stored in info. this should not happen."
        );
      }
      // Add temp record to processing list state to trigger polling
      dispatch(
        ReExtractExtraction({
          workspaceId,
          extractionResult,
        })
      );
      try {
        // re-use upload task queue since the rate limit is shared
        await TaskRunner.getWorkspaceUploadRunner().enqueue(() =>
          reExtractForWorkspace(token, workspaceId, extractionResultId)
        );
        // enqueued re-extract job, let polling continue, no need to mutate state manually
      } catch (e: unknown) {
        // failed to enqueue re-extract job, remove temp record added above to stop polling
        if (e instanceof FOCRError) {
          dispatch(
            ReExtractExtractionFailed({
              workspaceId,
              extractionResultId,
              error: e,
            })
          );
        } else {
          dispatch(
            ReExtractExtractionFailed({
              workspaceId,
              extractionResultId,
              error: errors.UnknownError,
            })
          );
        }
        throw e;
      }
    },
    [dispatch]
  );

  const getExtraction = useCallback(
    async (extractionId: string) => {
      dispatch(GetExtraction({ extractionId }));
      try {
        const extraction = await apiClient.getExtraction({ extractionId });
        dispatch(GetExtractionSuccess({ extractionId, extraction }));
      } catch (e: unknown) {
        dispatch(
          GetExtractionFailed({
            extractionId,
            error: ensureFOCRError(e),
          })
        );
      }
    },
    [dispatch]
  );

  const getExtractionResult = useCallback(
    async (extractionResultId: string) => {
      dispatch(GetExtractionResult({ extractionResultId }));
      try {
        const res = await apiClient.getExtractionResult({
          extractionResultId,
        });
        dispatch(
          GetExtractionResultSuccess({
            extractionResultId,
            extractionResult: res.extractionResult,
            extractionResultPageInfo: {
              previousExtractionResultId:
                res.previousExtractionResultId ?? null,
              nextExtractionResultId: res.nextExtractionResultId ?? null,
            },
          })
        );
      } catch (e: unknown) {
        dispatch(
          GetExtractionResultFailed({
            extractionResultId,
            error: ensureFOCRError(e),
          })
        );
      }
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      listExtractions,
      listProcessingExtractions,
      createExtraction,
      retryExtraction,
      retryAllFailedExtractions,
      exportExtractions,
      exportAllExtractions,
      cleanupUploadQueue,
      deleteExtraction,
      deleteAllExtractions,
      deleteExtractionResults,
      reExtractExtraction,
      getExtraction,
      getExtractionResult,
      clearListParamsShouldOverrideUrl,
    }),
    [
      listExtractions,
      listProcessingExtractions,
      createExtraction,
      retryExtraction,
      retryAllFailedExtractions,
      exportExtractions,
      exportAllExtractions,
      cleanupUploadQueue,
      deleteExtraction,
      deleteAllExtractions,
      deleteExtractionResults,
      reExtractExtraction,
      getExtraction,
      getExtractionResult,
      clearListParamsShouldOverrideUrl,
    ]
  );
}
