import { DEFAULT_FIELD_LABEL } from "../constants/formConfig";
import {
  DetectionRegion,
  DetectionRegionField,
  DetectionRegionFieldEngine,
} from "../types/detectionRegion";

export const getDefaultDetectionRegionField: (engineByFieldTypeMap: {
  [x: string]: DetectionRegionFieldEngine[] | undefined;
}) => DetectionRegionField = engineByFieldTypeMap => {
  const type = "single-line-eng";

  const engineOptions = engineByFieldTypeMap[
    type
  ] as DetectionRegionFieldEngine[];
  const defaultEngine = engineOptions.includes("google")
    ? "google"
    : engineOptions[0];

  const defaultParams: DetectionRegionField["params"] = {};
  if (defaultEngine) {
    defaultParams.engine = defaultEngine;
  }

  return {
    label: DEFAULT_FIELD_LABEL,
    type: type,
    params: defaultParams,
  };
};

export const getDefaultDetectionRegionConfig: (engineByFieldTypeMap?: {
  [x: string]: DetectionRegionFieldEngine[] | undefined;
}) => DetectionRegion["config"] = engineByFieldTypeMap => {
  const fields = engineByFieldTypeMap
    ? [getDefaultDetectionRegionField(engineByFieldTypeMap)]
    : [];

  return {
    version: 1,
    fields,
  };
};
