.container {
  .auto-extraction-items {
    width: 100%;
    margin-bottom: 12px;
    padding-bottom: 4px;

    .auto-extraction-items-label {
      margin-top: 15px;
      label {
        display: inline;
        margin-right: 4px;
      }

      i {
        font-size: 14px;
      }
    }

    .row {
      display: flex;
    }

    .check-box {
      margin-top: 8px;
      white-space: nowrap;
    }

    .setting {
      margin-top: 8px;
      height: inherit;
    }

    .setting:hover {
      background-color: transparent;
    }
  }

  label {
    font-size: 16px;
  }
}

.prebuilt-extractor {
  .auto-extraction-items {
    .auto-extraction-items-label {
      margin-top: 0;
      margin-bottom: 12px;
    }

    .items {
      max-width: 1200px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 14px;
    }

    @media screen and (max-width: 850px) {
      .items {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    @media screen and (min-width: 850px) and (max-width: 1025px) {
      .items {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    @media screen and (min-width: 1025px) and (max-width: 1200px) {
      .items {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
