import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { MAX_RECEIPT_GROUPS } from "../constants";
import { useAppDispatch } from "../hooks/redux";
import { RootState } from "../redux/types";
import { BriefForm, BriefFormWithType, DetailedForm } from "../types/form";
import { KeyValue } from "../types/keyValue";
import { PageInfo } from "../types/pageInfo";
import {
  CustomField,
  ReceiptGroup,
  ReceiptGroupWithType,
} from "../types/receiptGroup";
import { TokenGroup } from "../types/tokenGroup";
import { useConfirmModalActionCreator } from "./confirmModal";

export const GotReceiptGroupList = createAction(
  "receiptGroup/gotReceiptGroupList",
  (receiptGroups: ReceiptGroup[], forms: BriefForm[], pageInfo: PageInfo) => ({
    payload: {
      receiptGroups,
      forms,
      pageInfo,
    },
  })
);

export const FetchReceiptGroupListStarted = createAction(
  "receiptGroup/fetchReceiptGroupListStarted"
);

export const ReceiptGroupSelected = createAction<
  ReceiptGroupWithType | BriefFormWithType
>("receiptGroup/receiptGroupSelected");

export const FirstReceiptGroupSelected = createAction(
  "receiptGroup/firstReceiptGroupSelected"
);

export const ReceiptGroupCreated = createAction<BriefForm>(
  "receiptGroup/receiptGroupCreated"
);

export const GotReceiptGroup = createAction<ReceiptGroup>(
  "receiptGroup/gotReceiptGroup"
);

export const UpdatedReceiptGroup = createAction<ReceiptGroup>(
  "receiptGroup/updatedReceiptGroup"
);

export const UpdatedReceiptForm = createAction<BriefForm>(
  "receiptGroup/updatedReceiptForm"
);

export const ReceiptGroupRemoved = createAction(
  "receiptGroup/receiptGroupRemoved",
  (receiptGroupId: string) => ({ payload: { receiptGroupId } })
);

export const ReceiptFormRemoved = createAction(
  "receiptGroup/receiptFormRemoved",
  (formId: string) => ({ payload: { formId } })
);

function getReceiptGroupFromState(state: RootState, receiptGroupId: string) {
  let receiptGroup = state.receiptGroup.receiptGroups.filter(
    x => x.id === receiptGroupId
  )[0];

  if (!receiptGroup) {
    const { currentReceiptGroup } = state.receiptGroup;
    if (
      currentReceiptGroup &&
      currentReceiptGroup.type === "receipt_group" &&
      currentReceiptGroup.id === receiptGroupId
    ) {
      receiptGroup = currentReceiptGroup;
    }
  }

  return receiptGroup;
}

export type ReceiptGroupAction =
  | ReturnType<typeof GotReceiptGroupList>
  | ReturnType<typeof ReceiptGroupSelected>
  | ReturnType<typeof FirstReceiptGroupSelected>
  | ReturnType<typeof ReceiptGroupCreated>
  | ReturnType<typeof GotReceiptGroup>
  | ReturnType<typeof ReceiptGroupRemoved>
  | ReturnType<typeof ReceiptFormRemoved>
  | ReturnType<typeof UpdatedReceiptGroup>
  | ReturnType<typeof UpdatedReceiptForm>
  | ReturnType<typeof FetchReceiptGroupListStarted>;

export function useReceiptGroupActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();

  const { handleConflict } = useConfirmModalActionCreator();

  const listReceiptGroup = useCallback(
    async (shouldSelecteFirstReceiptIfNeeded = true) => {
      dispatch(FetchReceiptGroupListStarted());

      const isNoReceiptGroupSelected =
        getState().receiptGroup.currentReceiptGroup === undefined;
      const { resourceOwnerId, isTeam } = getState().resourceOwner;

      // We always fetch the first MAX_RECEIPT_GROUPS receipt groups
      const [listReceiptGroupResult, listFormResp] = await Promise.all([
        isTeam
          ? Promise.resolve(undefined)
          : apiClient.listReceiptGroup(MAX_RECEIPT_GROUPS, ""),
        apiClient.listForm(
          MAX_RECEIPT_GROUPS,
          "",
          {
            isReceipt: true,
          },
          resourceOwnerId
        ),
      ]);

      dispatch(
        GotReceiptGroupList(
          listReceiptGroupResult?.receiptGroups ?? [],
          listFormResp.forms,
          listReceiptGroupResult?.pageInfo ?? listFormResp.pageInfo
        )
      );

      if (shouldSelecteFirstReceiptIfNeeded && isNoReceiptGroupSelected) {
        dispatch(FirstReceiptGroupSelected());
      }
    },
    [dispatch, getState]
  );

  const selectReceiptGroup = useCallback(
    (receiptGroup: ReceiptGroupWithType | BriefFormWithType) => {
      dispatch(ReceiptGroupSelected(receiptGroup));
    },
    [dispatch]
  );

  const getReceiptGroup = useCallback(
    async (receiptGroupId: string) => {
      const receiptGroup = await apiClient.getReceiptGroup(receiptGroupId);
      dispatch(GotReceiptGroup(receiptGroup));
    },
    [dispatch]
  );

  const removeReceiptGroup = useCallback(
    async (receiptGroupId: string) => {
      await apiClient.deleteReceiptGroup(receiptGroupId);
      dispatch(ReceiptGroupRemoved(receiptGroupId));
      dispatch(FirstReceiptGroupSelected());
    },
    [dispatch]
  );

  const renameReceiptGroup = useCallback(
    async (receiptGroupId: string, newName: string) => {
      const receiptGroup = await apiClient.updateReceiptGroup(receiptGroupId, {
        name: newName,
      });

      dispatch(UpdatedReceiptGroup(receiptGroup));
    },
    [dispatch]
  );

  const createReceiptForm = useCallback(
    async (receiptGroupName: string): Promise<BriefForm> => {
      const { resourceOwnerId } = getState().resourceOwner;

      const form = await apiClient.createForm(
        receiptGroupName,
        {
          isReceipt: true,
        },
        resourceOwnerId
      );

      dispatch(ReceiptGroupCreated(form));

      return form;
    },
    [dispatch, getState]
  );

  const getReceiptForm = useCallback(
    async (formId: string): Promise<DetailedForm> => {
      return await apiClient.getForm(formId);
    },
    []
  );

  const updateReceiptForm = useCallback(
    async (
      formId: string,
      newValues: {
        name?: string;
        tokenGroups?: TokenGroup[];
        keyValues?: KeyValue[];
        updatedAt: string;
      }
    ): Promise<BriefForm> => {
      const form = await handleConflict(
        () =>
          apiClient.updateForm(formId, {
            name: newValues.name,
            tokenGroups: newValues.tokenGroups,
            keyValues: newValues.keyValues,
            shouldOverwrite: false,
            lastRetrieved: newValues.updatedAt,
          }),
        () =>
          apiClient.updateForm(formId, {
            name: newValues.name,
            tokenGroups: newValues.tokenGroups,
            keyValues: newValues.keyValues,
            shouldOverwrite: true,
            lastRetrieved: newValues.updatedAt,
          }),
        {
          titleId: "receipt_group.modified_prompt.title",
          messageId: "receipt_group.modified_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );

      dispatch(UpdatedReceiptForm(form));
      return form;
    },
    [dispatch, handleConflict]
  );

  const removeReceiptForm = useCallback(
    async (formId: string) => {
      await apiClient.deleteForm(formId);
      dispatch(ReceiptFormRemoved(formId));
      dispatch(FirstReceiptGroupSelected());
    },
    [dispatch]
  );

  const addToken = useCallback(
    async (receiptGroupId: string, token: string) => {
      const receiptGroup = getReceiptGroupFromState(getState(), receiptGroupId);
      if (!receiptGroup) return;

      const updatedTokens = receiptGroup.tokens;
      const tokensToAppend = token.split(",").map(x => x.toLowerCase().trim());

      for (const token of tokensToAppend) {
        if (updatedTokens.indexOf(token) === -1) {
          updatedTokens.push(token);
        }
      }

      const updatedReceiptGroup = await apiClient.updateReceiptGroup(
        receiptGroupId,
        {
          tokens: updatedTokens,
        }
      );

      dispatch(UpdatedReceiptGroup(updatedReceiptGroup));
    },
    [dispatch, getState]
  );

  const removeToken = useCallback(
    async (receiptGroupId: string, token: string) => {
      const receiptGroup = getReceiptGroupFromState(getState(), receiptGroupId);
      if (!receiptGroup) return;
      const updatedTokens = receiptGroup.tokens.filter(x => x !== token);

      const updatedReceiptGroup = await apiClient.updateReceiptGroup(
        receiptGroupId,
        { tokens: updatedTokens }
      );

      dispatch(UpdatedReceiptGroup(updatedReceiptGroup));
    },
    [dispatch, getState]
  );

  const addCustomField = useCallback(
    async (
      receiptGroupId: string,
      customField: CustomField,
      originalFieldName?: string
    ) => {
      const receiptGroup = getReceiptGroupFromState(getState(), receiptGroupId);
      if (!receiptGroup) return;

      const updatedCustomFields = receiptGroup.custom_fields.map(x =>
        x.name === originalFieldName ? customField : x
      );

      if (!updatedCustomFields.find(x => x.name === customField.name)) {
        updatedCustomFields.push(customField);
      }

      const updatedReceiptGroup = await apiClient.updateReceiptGroup(
        receiptGroupId,
        {
          custom_fields: updatedCustomFields,
        }
      );

      dispatch(UpdatedReceiptGroup(updatedReceiptGroup));
    },
    [dispatch, getState]
  );

  const removeCustomField = useCallback(
    async (receiptGroupId: string, fieldName: string) => {
      const receiptGroup = getReceiptGroupFromState(getState(), receiptGroupId);
      if (!receiptGroup) return new Promise<void>(() => {});

      const updatedCustomFields = receiptGroup.custom_fields.filter(
        x => x.name !== fieldName
      );

      const updatedReceiptGroup = await apiClient.updateReceiptGroup(
        receiptGroupId,
        {
          custom_fields: updatedCustomFields,
        }
      );

      dispatch(UpdatedReceiptGroup(updatedReceiptGroup));
    },
    [dispatch, getState]
  );

  return useMemo(
    () => ({
      listReceiptGroup,
      selectReceiptGroup,
      getReceiptGroup,
      removeReceiptGroup,
      renameReceiptGroup,
      createReceiptForm,
      getReceiptForm,
      updateReceiptForm,
      removeReceiptForm,
      addToken,
      removeToken,
      addCustomField,
      removeCustomField,
    }),
    [
      listReceiptGroup,
      selectReceiptGroup,
      getReceiptGroup,
      removeReceiptGroup,
      renameReceiptGroup,
      createReceiptForm,
      getReceiptForm,
      updateReceiptForm,
      removeReceiptForm,
      addToken,
      removeToken,
      addCustomField,
      removeCustomField,
    ]
  );
}
