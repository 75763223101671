import { Icon, Link, PrimaryButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import _ from "lodash";
import * as React from "react";

import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { useDragAndDropFiles } from "../../hooks/drag_and_drop";
import extractErrorIcon from "../../images/extract-error.svg";
import {
  OCRTestReport,
  OCRTestReportError,
  OCRTestReportMultipleDocument,
  OCRTestReportSingleDocument,
} from "../../models";
import { SampleImage } from "../../types/extractor";
import { FormExtractionMode } from "../../types/form";
import { downloadJsonStringFile } from "../../utils/json";
import { ExternalServicesAlertBoxForForm } from "../ExternalServiceAlertBox";
import ImageSelector from "../ImageSelector";
import { OCRTestPlaceholder } from "../OCRTestPlaceholder";
import { OCRTestReportTable } from "./OCRTestReportTable";
import styles from "./styles.module.scss";

interface Props {
  resourceName: string;
  samples?: readonly SampleImage[];
  description?: { url?: string; messageId: string };
  onSelectImage: (file?: File) => void;
  onSelectSampleImage: (url: string) => void;
  ocrTestReport?: OCRTestReport;
  extractionMode: FormExtractionMode;
  onChangeExtractionModeClicked: () => void;
  changeExtractionModeVisible?: boolean;
}

function _convertOCRReportMultipleDocumentToJSONStringList(
  ocrTestReport: OCRTestReportMultipleDocument
): string[] {
  const documents = ocrTestReport.documents.map(d => {
    let result = "";
    if ("error" in d) {
      result = JSON.stringify(d, null, 2);
    } else {
      const { status, ...data }: any =
        _convertOCRReportSingleDocumentToOutputJSON(d);
      if (d.bbox !== undefined) {
        data.bbox = d.bbox;
      }
      if (d.type !== undefined) {
        data.type = d.type;
      }
      result = JSON.stringify(data, null, 2);
    }
    return result
      .split("\n")
      .map(s => "    " + s)
      .join("\n");
  });
  return documents;
}

function _convertOCRReportSingleDocumentToOutputJSON(
  ocrTestReport: OCRTestReportSingleDocument
) {
  return {
    status: "ok",
    form_id: ocrTestReport.form_id,
    fields: ocrTestReport.fields.map(x => _.omit(x, "image")),
    auto_extraction_items: ocrTestReport.auto_extraction_items?.map(x =>
      _.omit(x, "image")
    ),
    key_values: ocrTestReport.key_values,
    token_groups: ocrTestReport.token_groups,
    custom_models: ocrTestReport.custom_models,
    formatter_output: ocrTestReport.formatter_output,
  };
}

function convertOCRReportSingleDocumentToOutputJSON(
  ocrTestReport: OCRTestReportSingleDocument
) {
  return (
    <pre>
      {JSON.stringify(
        _convertOCRReportSingleDocumentToOutputJSON(ocrTestReport),
        null,
        2
      )}
    </pre>
  );
}

function convertOCRReportMultipleDocumentToOutputJSON(
  ocrTestReport: OCRTestReportMultipleDocument,
  cursor: number,
  currentJsonRef: React.RefObject<HTMLPreElement>
) {
  const documents =
    _convertOCRReportMultipleDocumentToJSONStringList(ocrTestReport);

  return (
    <pre>
      <pre>{"{"}</pre>
      <pre>{'  "status": "ok",'}</pre>
      <pre>{'  "documents": ['}</pre>
      {documents.map((d, index) => (
        <pre
          key={`${index}`}
          ref={cursor === index ? currentJsonRef : undefined}
          className={cursor === index ? styles["highlighted"] : undefined}
        >
          {d}
          {index < documents.length - 1 && ","}
        </pre>
      ))}
      <pre>{"  ]"}</pre>
      <pre>{"}"}</pre>
    </pre>
  );
}

function _OCRTest(props: Props) {
  useDragAndDropFiles(
    (files?: File[]) => props.onSelectImage(files && files[0]),
    SUPPORTED_EXTRACT_MIME
  );

  const {
    description,
    samples,
    ocrTestReport,
    onSelectImage,
    onSelectSampleImage,
    extractionMode,
    onChangeExtractionModeClicked,
    resourceName,
  } = props;

  const changeExtractionModeVisible = props.changeExtractionModeVisible ?? true;

  return (
    <div className={styles["ocr-test"]}>
      <ExternalServicesAlertBoxForForm />
      {samples && samples.length > 0 ? (
        <div className={styles["sample-image-box"]}>
          <h1>
            <FormattedMessage id="ocr_test.try_sample" />
            {description &&
              (description.url ? (
                <a
                  href={description.url}
                  target="_blank"
                  className={styles["template-description"]}
                >
                  <Icon iconName="Lightbulb" />
                  <FormattedMessage id={description.messageId} />
                </a>
              ) : (
                <span className={styles["template-description"]}>
                  <Icon iconName="Lightbulb" />
                  <FormattedMessage id={description.messageId} />
                </span>
              ))}
          </h1>
          <ImageSelector images={samples} onSelectImage={onSelectSampleImage} />
        </div>
      ) : (
        <div />
      )}

      {changeExtractionModeVisible && (
        <div>
          <div className={styles["extraction-mode"]}>
            <h3>
              <FormattedMessage id={`form.extraction_mode.${extractionMode}`} />
            </h3>
            <Link onClick={onChangeExtractionModeClicked}>
              <FormattedMessage id="ocr_test.extraction_mode.change" />
            </Link>
          </div>
        </div>
      )}

      {ocrTestReport ? (
        <OCRReportView report={ocrTestReport} resourceName={resourceName} />
      ) : (
        <OCRTestPlaceholder onSelectImage={onSelectImage} />
      )}
    </div>
  );
}

interface OCRReportViewProps {
  report: OCRTestReport;
  resourceName: string;
}

function OCRReportView(props: OCRReportViewProps) {
  const { report, resourceName } = props;

  const currentJsonRef = React.useRef<HTMLPreElement>(null);

  const isMultiDocument = React.useMemo(() => "documents" in report, [report]);

  const documentCount = React.useMemo(
    () => ("documents" in report ? report.documents.length : 1),
    [report]
  );

  const [cursor, setCursor] = React.useState(0);

  React.useEffect(() => {
    setCursor(0);
  }, [report]);

  const currentDocument = React.useMemo<
    OCRTestReportSingleDocument | OCRTestReportError | undefined
  >(() => {
    if ("documents" in report) {
      if (cursor < report.documents.length) {
        const document = report.documents[cursor];
        return document;
      }
      return undefined;
    } else {
      return report;
    }
  }, [report, cursor]);

  const jumpToJson = React.useCallback(() => {
    if (currentJsonRef && currentJsonRef.current) {
      currentJsonRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [currentJsonRef]);

  const downloadJson = React.useCallback(() => {
    if ("documents" in report) {
      const stringPrefix = '{\n  "status": "ok",\n  "documents": [\n';
      const stringPostFix = "\n  ]\n}";
      const documents =
        _convertOCRReportMultipleDocumentToJSONStringList(report);
      downloadJsonStringFile(
        stringPrefix + documents.join(",\n") + stringPostFix,
        "json-output-" + resourceName.replace(/\s+/g, "-").toLowerCase()
      );
    } else {
      downloadJsonStringFile(
        JSON.stringify(
          _convertOCRReportSingleDocumentToOutputJSON(report),
          null,
          2
        ),
        "json-output-" + resourceName.replace(/\s+/g, "-").toLowerCase()
      );
    }
  }, [report, resourceName]);

  const hideJSON = false;

  return (
    <div className={styles["report"]}>
      <div className={styles["section"]}>
        <h1>
          <FormattedMessage id="ocr_test.warped_image" />
        </h1>
        <div className={styles["warped-image-wrapper"]}>
          {isMultiDocument && (
            <PrimaryButton
              className={styles["control-button"]}
              iconProps={{ iconName: "ChevronLeft" }}
              onClick={() => setCursor(cursor - 1)}
              disabled={cursor === 0}
            />
          )}

          {currentDocument &&
            ("error" in currentDocument ? (
              <div className={styles["extract-error"]}>
                <img src={extractErrorIcon} alt="error" />
                <p>
                  <FormattedMessage id="ocr_test.extract_error" />
                </p>
                <p className={styles["reason"]}>
                  <FormattedMessage
                    id="ocr_test.extract_error.reason"
                    values={{ message: currentDocument.error.message }}
                  />
                </p>
              </div>
            ) : (
              <img src={currentDocument.warped_image} alt="warped" />
            ))}

          {isMultiDocument && (
            <>
              <PrimaryButton
                className={styles["control-button"]}
                iconProps={{ iconName: "ChevronRight" }}
                onClick={() => setCursor(cursor + 1)}
                disabled={cursor === documentCount - 1}
              />
              <div className={styles["pagination"]}>
                {`${cursor + 1} / ${documentCount}`}
              </div>
              {hideJSON ? null : (
                <div className={styles["jump-to-json"]}>
                  <Link onClick={jumpToJson}>
                    <FormattedMessage id="ocr_test.jump_to_json" />
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {currentDocument && !("error" in currentDocument) && (
        <OCRTestReportTable report={currentDocument} />
      )}

      {hideJSON ? null : (
        <div className={styles["section"]}>
          <h1>
            <FormattedMessage id="ocr_test.json" />
          </h1>
          {"documents" in report
            ? convertOCRReportMultipleDocumentToOutputJSON(
                report,
                cursor,
                currentJsonRef
              )
            : convertOCRReportSingleDocumentToOutputJSON(report)}
          <Link className={styles["download-json"]} onClick={downloadJson}>
            <Icon iconName="Download" />
            <FormattedMessage id="ocr_test.download_json_as_file" />
          </Link>
        </div>
      )}
    </div>
  );
}

export const OCRTest = React.memo(_OCRTest);
export default OCRTest;
