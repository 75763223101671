import { MessageBar, MessageBarType } from "@fluentui/react";
import { PrimaryButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import { FOCRError } from "../../errors";
import {
  ChangePasswordPane,
  useChangePasswordPane,
} from "../ChangePasswordPane";
import styles from "./styles.module.scss";

interface Props {
  onSubmit: (
    password: string,
    newPassword: string
  ) => Promise<void | FOCRError>;
}

function _LoginChangePasswordForm(props: Props) {
  const { onSubmit } = props;

  const { localized } = useLocale();

  const [showErrorMessage, setShowErrorMessage] = React.useState(true);

  const changePasswordPaneProps = useChangePasswordPane();
  const {
    oldPassword,
    newPassword,
    verifyNewPassword,
    validateInputs,
    setError,
  } = changePasswordPaneProps;

  const onFormSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!validateInputs(oldPassword, newPassword, verifyNewPassword)) {
        return;
      }

      const error = await onSubmit(oldPassword, newPassword);
      if (error !== undefined) {
        setError(error);
      }
    },
    [
      onSubmit,
      validateInputs,
      oldPassword,
      newPassword,
      verifyNewPassword,
      setError,
    ]
  );

  const hideErrorMessageBar = React.useCallback(() => {
    setShowErrorMessage(false);
  }, [setShowErrorMessage]);

  return (
    <>
      {showErrorMessage && (
        <MessageBar
          className={styles["message-bar"]}
          messageBarType={MessageBarType.error}
          onDismiss={hideErrorMessageBar}
        >
          <FormattedMessage id="error.password_expired" />
        </MessageBar>
      )}

      <form
        className={styles["login-change-password-form"]}
        onSubmit={onFormSubmit}
      >
        <div className={styles["title"]}>
          <FormattedMessage id="login_change_password_form.title" />
        </div>
        <ChangePasswordPane {...changePasswordPaneProps} />
        <div className={styles["footer"]}>
          <PrimaryButton
            type="submit"
            text={localized("login_change_password_form.submit_button")}
          />
        </div>
      </form>
    </>
  );
}

export const LoginChangePasswordForm = React.memo(_LoginChangePasswordForm);
export default LoginChangePasswordForm;
