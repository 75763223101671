import { IBreadcrumbItem } from "@fluentui/react";
import React, { useMemo } from "react";

import { useNavigationStack } from "../../contexts/navigationStack";
import { Workspace } from "../../types/workspace";
import AppBreadcrumb from "../AppBreadcrumb";
import { SmallPaginator } from "../SmallPaginator";
import styles from "./styles.module.scss";

interface WorkspaceDocumentDetailsSectionHeaderProps {
  workspace: Workspace;
  documentFileName: string;
  pagination: {
    hasPrevPage: boolean;
    hasNextPage: boolean;
    onClickPrevPage: () => void;
    onClickNextPage: () => void;
  } | null;
}
export function WorkspaceDocumentDetailsSectionHeader(
  props: WorkspaceDocumentDetailsSectionHeaderProps
) {
  const { workspace, documentFileName, pagination } = props;

  const { popUntil } = useNavigationStack();

  const onBackToDocumentListClick = React.useCallback(
    (ev?: React.MouseEvent<HTMLElement>) => {
      ev?.preventDefault();
      ev?.stopPropagation();
      popUntil(
        location =>
          location.pathname.startsWith(`/workspace/${workspace.id}/document`),
        `/workspace/${workspace.id}/document`
      );
    },
    [popUntil, workspace.id]
  );

  const beadcrumbItems = useMemo<IBreadcrumbItem[]>(
    () => [
      {
        text: workspace.config.name,
        key: "workspace",
        onClick: onBackToDocumentListClick,
      },
      {
        key: documentFileName,
        text: documentFileName,
      },
    ],
    [onBackToDocumentListClick, workspace, documentFileName]
  );

  return (
    <div className={styles.header}>
      <div className={styles.breadcrumb}>
        <AppBreadcrumb items={beadcrumbItems} />
      </div>

      {pagination != null ? (
        <SmallPaginator
          pageNumberLabelId="workspace.document_detail.header.pagination.page_number"
          hasPrevPage={pagination.hasPrevPage}
          hasNextPage={pagination.hasNextPage}
          onClickNextPage={pagination.onClickNextPage}
          onClickPrevPage={pagination.onClickPrevPage}
        />
      ) : null}
    </div>
  );
}
