import * as React from "react";

import Spinner from "../Spinner";
import styles from "./styles.module.scss";

export default class SplashScreen extends React.PureComponent<any> {
  render() {
    return (
      <div className={styles["splash-screen"]}>
        <Spinner />
        <div className={styles["logo"]}>
          <img src="./logo.svg" alt="" />
        </div>
      </div>
    );
  }
}
