import { Checkbox } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import * as React from "react";

import { ExtractorOption } from "../../types/extractor";
import styles from "./styles.module.scss";

interface WebhookConnectFormFieldProps {
  extractorOptions: ExtractorOption[];
  connectWithFormIds: string[];
  connectWithFormGroupIds: string[];
  onConnectFormChange: (
    formId: string
  ) => (
    ev?: React.FormEvent<unknown> | undefined,
    checked?: boolean | undefined
  ) => void;
  onConnectFormGroupChange: (
    formGroupId: string
  ) => (
    ev?: React.FormEvent<unknown> | undefined,
    checked?: boolean | undefined
  ) => void;
  connectWithErrorMessage?: string;
}

export const WebhookConnectFormField = React.memo(
  (props: WebhookConnectFormFieldProps) => {
    const {
      extractorOptions,
      connectWithFormIds,
      connectWithFormGroupIds,
      onConnectFormChange,
      onConnectFormGroupChange,
      connectWithErrorMessage,
    } = props;

    return (
      <>
        <div
          className={classNames(
            styles["connect-container"],
            connectWithErrorMessage && styles["error"]
          )}
        >
          {extractorOptions.map(x => (
            <div
              className={styles["row"]}
              key={x.associatedExtractorId ?? x.id}
            >
              <Checkbox
                className={styles["check-box"]}
                label={x.name}
                checked={
                  x.resourceType === "form" || x.resourceType === "custom_model"
                    ? connectWithFormIds.includes(
                        x.associatedExtractorId ?? x.id
                      )
                    : connectWithFormGroupIds.includes(x.id)
                }
                onChange={
                  x.resourceType === "form" || x.resourceType === "custom_model"
                    ? onConnectFormChange(x.associatedExtractorId ?? x.id)
                    : onConnectFormGroupChange(x.id)
                }
              />
            </div>
          ))}
        </div>
        {connectWithErrorMessage && (
          <span className={styles["error-message"]}>
            <FormattedMessage id={connectWithErrorMessage} />
          </span>
        )}
      </>
    );
  }
);

export default WebhookConnectFormFieldProps;
