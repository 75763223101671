import { PrimaryButton, TeachingBubble } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import _ from "lodash";
import React, { ReactNode, useCallback, useMemo } from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

const teachingBubbleStyle = {
  bodyContent: {
    background: "#fff",
    padding: 0,
  },
  subText: {
    color: "#000",
  },
  subComponentStyles: {
    callout: {
      beak: {
        background: "#fff",
      },
    },
  },
};

interface ContentStepProps {
  currentStep: number;
  numberOfSteps: number;
  handleNavigateToStep?: (step: number) => void;
}

const ContentStep = React.memo((props: ContentStepProps) => {
  const { numberOfSteps, currentStep, handleNavigateToStep } = props;

  const steps = useMemo(() => {
    return _.range(0, numberOfSteps);
  }, [numberOfSteps]);

  const onNavigateToStep = useCallback(
    (step: number) => {
      if (handleNavigateToStep) {
        handleNavigateToStep(step);
      }
    },
    [handleNavigateToStep]
  );

  return (
    <div className={styles["steps"]}>
      {steps.map(step => {
        return (
          <div
            key={step}
            onClick={() => onNavigateToStep(step)}
            className={classNames(styles["step"], {
              [styles["active"]]: step === currentStep,
            })}
          ></div>
        );
      })}
    </div>
  );
});

interface FixedLayoutFormTutorialBubbleProps {
  currentStep: number;
  isLastStep?: boolean;
  targetId: string;
  onSkipAll?: () => void;
  onNavigateToStep?: (step: number) => void;
  contents?: ReactNode[];
  hideButtons?: boolean;
}

const FixedLayoutFormTutorialBubble = React.memo(
  (props: FixedLayoutFormTutorialBubbleProps) => {
    const {
      isLastStep,
      targetId,
      onSkipAll,
      onNavigateToStep,
      currentStep,
      contents,
      hideButtons,
    } = props;
    const { localized } = useLocale();

    const onNextClick = useCallback(() => {
      if (onNavigateToStep) {
        onNavigateToStep(currentStep + 1);
      }
    }, [onNavigateToStep, currentStep]);

    return (
      <TeachingBubble
        styles={teachingBubbleStyle}
        target={`#${targetId}`}
        focusTrapZoneProps={{
          forceFocusInsideTrap: false,
        }}
      >
        {contents !== undefined && contents[currentStep]}
        {(contents?.length ?? 0) > 1 && (
          <ContentStep
            handleNavigateToStep={onNavigateToStep}
            currentStep={currentStep}
            numberOfSteps={contents?.length ?? 0}
          />
        )}
        {!hideButtons && (
          <div className={styles["buttons"]}>
            <div className={styles["skip-button"]} onClick={onSkipAll}>
              <FormattedMessage id="tutorial.fixed_layout_form.skip_all" />
            </div>
            <PrimaryButton
              type="button"
              onClick={onNextClick}
              text={localized(
                isLastStep
                  ? "tutorial.fixed_layout_form.got_it"
                  : "teaching_bubble.next"
              )}
            />
          </div>
        )}
      </TeachingBubble>
    );
  }
);

export default FixedLayoutFormTutorialBubble;
