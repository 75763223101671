export enum ButtonId {
  HeaderBookDemoButton = "header_book_demo_button",

  OverlayPaginatorPrevButton = "overlay_paginator_prev_button",

  ExtractorTestPanelReuploadButton = "extractor_test_panel_reupload_button",

  ManageRulesTab = "manage_rules_tab",

  ManageFieldsTab = "manage_fields_tab",

  ManageFieldsDescription = "manage_fields_description",

  ManageFieldsAddNewField = "manage_fields_add_new_field",

  ManageFieldsSaveSchema = "manage_fields_save_schema",
}
