import * as React from "react";
import { AppearanceTypes, useToasts } from "react-toast-notifications";

import { useLocale } from "../contexts/locale";
import { useGtm } from "../hooks/gtm";
import { LocalizerValue, ToastOptions } from "../models";

export function useToast() {
  const { localized } = useLocale();
  const { addToast, removeAllToasts, removeToast } = useToasts();

  const makeToastEmitter = React.useCallback(
    (appearance: AppearanceTypes) =>
      (
        key: string | React.ReactNode,
        options?: ToastOptions,
        detail?: LocalizerValue
      ) => {
        addToast(typeof key === "string" ? localized(key, detail) : key, {
          appearance,
          ...options,
        });
      },
    [addToast, localized]
  );

  const errorEmitter = React.useMemo(() => {
    return makeToastEmitter("error");
  }, [makeToastEmitter]);

  const { pushViewedErrorMessageEvent } = useGtm();

  const error = React.useCallback(
    (
      key: string | React.ReactNode,
      options?: ToastOptions,
      detail?: LocalizerValue
    ) => {
      const errorMessage: string | React.ReactNode =
        typeof key === "string" ? localized(key, detail) : key;
      pushViewedErrorMessageEvent(
        typeof errorMessage === "string" ? errorMessage : undefined
      );
      errorEmitter(key, options, detail);
    },
    [errorEmitter, localized, pushViewedErrorMessageEvent]
  );

  return React.useMemo(
    () => ({
      info: makeToastEmitter("info"),
      success: makeToastEmitter("success"),
      warn: makeToastEmitter("warning"),
      error: error,
      dismiss: removeAllToasts,
      removeToast,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
