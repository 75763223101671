@import "../../styles/variables.scss";

.image-selector {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .image-frame {
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 5px;
    border-radius: 2px;
  }

  img {
    width: 100%;
    height: 100%;
    transform: scale(1);
    /* stylelint-disable-next-line */
    transition: all 0.15s ease;
    cursor: pointer;
    z-index: auto;
    /* stylelint-disable-next-line */
    object-fit: cover;
  }

  img:hover {
    position: relative;
    /* stylelint-disable-next-line */
    transition: all 0.15s ease;
    transform: scale(1.5);
    z-index: 1000;
  }
}
