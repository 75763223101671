import * as React from "react";

import { useFormGroupActionCreator } from "../actions/formGroup";
import DataNotSavedPrompt from "../components/DataNotSavedPrompt";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import FormatterActionSelector from "../components/FormatterActionSelector";
import FormatterEditor from "../components/FormatterEditor";
import FormatterStepEditor from "../components/FormatterStepEditor";
import { Layout, Main, Right, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import {
  FormatterEditorProvider,
  useFormatterEditor,
} from "../contexts/formatterEditor";
import { useExtractorFieldSchema } from "../hooks/extractorFieldSchema";
import { useCommonFormGroupContainerState } from "../hooks/form_group";
import { useUnsafeParams } from "../hooks/params";
import { useToast } from "../hooks/toast";
import { DetailFormGroup } from "../types/formGroup";
import { FormatterStorage } from "../types/mappers/formatter";
import {
  FormGroupNavBarLayout,
  FormGroupNavTabKey,
} from "./FormGroupNavBarLayout";
import HeaderContainer from "./Header";

type PathParam = {
  formGroupId: string;
};

const FormGroupFormatterContainerImpl = React.memo(() => {
  const { isAddingStep, selectedStepIndex, steps, isFormatterNotSaved } =
    useFormatterEditor();
  const rightVisible = isAddingStep || selectedStepIndex >= 0;

  return (
    <>
      <Main hasTop={true}>
        <FormGroupNavBarLayout selectedTab={FormGroupNavTabKey.Formatter}>
          <FormatterEditor />
        </FormGroupNavBarLayout>
        <DataNotSavedPrompt
          isDataChanged={isFormatterNotSaved}
          titleTextId="extractor_setting.form_not_saved_prompt.title"
          messageTextId="extractor_setting.form_not_saved_prompt.save_warning"
          backTextId="extractor_setting.form_not_saved_prompt.go_back"
          continueTextId="extractor_setting.form_not_saved_prompt.leave_page"
        />
      </Main>
      {rightVisible && (
        <Right hasTop={true}>
          {isAddingStep && <FormatterActionSelector />}
          {selectedStepIndex >= 0 && (
            <FormatterStepEditor
              step={steps[selectedStepIndex]}
              key={selectedStepIndex}
            />
          )}
        </Right>
      )}
    </>
  );
});

interface FormGroupFormatterEditorProviderProps {
  children: React.ReactNode;
  formGroup: DetailFormGroup;
}

function _FormGroupFormatterEditorProvider(
  props: FormGroupFormatterEditorProviderProps
) {
  const { children, formGroup } = props;
  const { getExtractorFieldSchema } = useExtractorFieldSchema();
  const { updateFormGroup } = useFormGroupActionCreator();
  const toast = useToast();

  const formGroupRef = React.useRef(formGroup);
  formGroupRef.current = formGroup;

  const onSaveFormatter = React.useCallback(
    (formatterStorage: FormatterStorage) => {
      return updateFormGroup(
        formGroup.id,
        {
          config: {
            ...formGroupRef.current.config,
            formatter: formatterStorage,
          },
        },
        formGroup.updatedAt
      )
        .then(() => {
          toast.success("form_editor.form_is_saved");
        })
        .catch(e => {
          console.error("Update form group script error: ", e);
          toast.error("error.fail_to_update_form_group");
          throw e;
        });
    },
    [formGroup.id, formGroup.updatedAt, toast, updateFormGroup]
  );

  React.useEffect(() => {
    getExtractorFieldSchema(formGroup.id);
  }, [formGroup.id, getExtractorFieldSchema]);

  return (
    <FormatterEditorProvider
      extractorId={formGroup.id}
      formatterSource={formGroup.config.formatter}
      onSaveFormatter={onSaveFormatter}
    >
      {children}
    </FormatterEditorProvider>
  );
}

const FormGroupFormatterEditorProvider = React.memo(
  _FormGroupFormatterEditorProvider
);

const FormGroupFormatterContainer = React.memo(() => {
  const { formGroupId } = useUnsafeParams<PathParam>();
  const containerState = useCommonFormGroupContainerState(formGroupId);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      {containerState.state === "success" ? (
        <FormGroupFormatterEditorProvider formGroup={containerState.formGroup}>
          <FormGroupFormatterContainerImpl />
        </FormGroupFormatterEditorProvider>
      ) : containerState.state === "error" ? (
        <ErrorPlaceholder messageId="common.fail_to_fetch_form_group" />
      ) : null}
    </Layout>
  );
});

export default FormGroupFormatterContainer;
