@import "../../styles/variables.scss";

.container {
  padding: 20px;
  margin: 0;
  position: relative;
  height: 100%;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }

  .subtitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #605e5c;
    margin-top: 8px;
    display: inline-block;
  }

  .button-bar {
    padding-top: 4px;
    padding-left: 8px;
    border-bottom: 1px solid #edebe9;

    .delete-button:not(:disabled) {
      :global(.ms-Icon) {
        color: #d83b01;
      }
      color: #d83b01;
    }

    .delete-button:hover {
      opacity: 0.7;
    }

    .delete-button:active {
      opacity: unset;
    }

    .delete-button:disabled {
      opacity: unset;
    }
  }

  .loading-container {
    height: calc(100vh - 300px);
  }

  .info-container {
    padding: 8px 12px;
    margin-bottom: 12px;
    height: 32px;

    font-size: 12px;
    line-height: 16px;
    color: #605e5c;

    .uploading-files {
      i {
        float: left;
        font-size: 16px;
        margin-right: 8px;
      }
    }

    .file-count {
      float: right;
    }
  }
}

.reminder {
  position: sticky;
  left: 0px;
  right: 0px;
  bottom: 24px;
  margin-top: calc(100vh - 758px);

  text-align: center;

  > div {
    display: inline-block;
    color: black;
    background-color: #ffd633;
    padding: 12px 24px;
  }
}
