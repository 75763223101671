import * as React from "react";

import { AppConfig } from "../config";

export function useDesktopApp(
  token?: string,
  entityId?: string,
  entityName?: string
) {
  const onMacOsAppClick = React.useCallback(() => {
    if (AppConfig.desktopApp && AppConfig.desktopApp.macOSUrl) {
      window.open(AppConfig.desktopApp.macOSUrl, "_blank");
    }
  }, []);

  const onWindowsAppClick = React.useCallback(() => {
    if (AppConfig.desktopApp && AppConfig.desktopApp.windowsUrl) {
      window.open(AppConfig.desktopApp.windowsUrl, "_blank");
    }
  }, []);

  const onOpenInDesktopApp = React.useCallback(() => {
    if (
      token !== undefined &&
      entityId !== undefined &&
      entityName !== undefined
    ) {
      const desktopAppURI = new URL("form-extractor://open");
      desktopAppURI.searchParams.append("form_id", entityId);
      desktopAppURI.searchParams.append("access_token", token);
      desktopAppURI.searchParams.append("form_name", entityName);

      if (AppConfig.useCustomEndpointForDesktopApp) {
        desktopAppURI.searchParams.append("region", "custom_endpoint");
        desktopAppURI.searchParams.append(
          "custom_endpoint",
          AppConfig.worker.endpoint + "extract"
        );
      } else {
        desktopAppURI.searchParams.append("region", AppConfig.region);
      }
      window.location.href = desktopAppURI.toString();
    }
  }, [entityId, entityName, token]);

  return React.useMemo(
    () => ({ onWindowsAppClick, onMacOsAppClick, onOpenInDesktopApp }),
    [onMacOsAppClick, onWindowsAppClick, onOpenInDesktopApp]
  );
}
