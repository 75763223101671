@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.empty-state-container {
  display: flex;
  width: 100%;

  .empty-state {
    @include no-select();

    .empty-state-text {
      display: flex;
      flex-direction: column;
      justify-content: center;

      label {
        margin-top: 0;
        font-weight: 500;
      }
    }
  }
}

.bottom-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.total-count {
  font-size: 14px;
  font-weight: 400;
  margin-top: 13px;
  text-transform: lowercase;
}
