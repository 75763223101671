import React, { useCallback, useState } from "react";

import { useDetectPIIActionCreator } from "../actions/detectPII";
import DetectPIITest from "../components/DetectPIITest";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { FOCRError } from "../errors";
import { useWorkerToken } from "../hooks/app";
import { useToast } from "../hooks/toast";
import { DetectPIIReport } from "../models";
import {
  DetectPIINavBarLayout,
  DetectPIINavTabKey,
} from "./DetectPIINavLayout";
import HeaderContainer from "./Header";

function useExtractInfo(token: string | undefined) {
  const [testReport, setTestReport] = useState<DetectPIIReport | undefined>(
    undefined
  );
  const { detectPII } = useDetectPIIActionCreator();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const toast = useToast();

  const clearOcrTestReport = useCallback(() => {
    setTestReport(undefined);
  }, []);

  const extractWithImageFile = useCallback(
    (file?: File) => {
      if (file && token) {
        setIsUploading(true);
        setIsLoading(true);
        const promise = detectPII(token, file).then(x => x as DetectPIIReport);

        promise
          .then(resp => {
            setIsLoading(false);
            setIsUploading(false);
            setTestReport(resp as DetectPIIReport);
          })
          .catch(error => {
            setIsUploading(false);
            setIsLoading(false);
            if (error instanceof FOCRError) {
              toast.error(error.messageId, undefined, error.detail);
            } else {
              toast.error("error.cannot_load_image");
            }
          });
      }
    },
    [token, toast, detectPII]
  );

  return React.useMemo(
    () => ({
      testReport,
      clearOcrTestReport,
      extractWithImageFile,
      isLoading,
      isUploading,
    }),
    [
      testReport,
      clearOcrTestReport,
      extractWithImageFile,
      isLoading,
      isUploading,
    ]
  );
}

function _DetectPIITestContainer() {
  const { token } = useWorkerToken();

  const { testReport, extractWithImageFile, isLoading, isUploading } =
    useExtractInfo(token);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        {isLoading ? (
          <LoadingModal
            messageId="detect_documents_test.detecting"
            isOpen={isLoading}
          />
        ) : (
          <span />
        )}
        {isUploading ? (
          <LoadingModal messageId="common.uploading" isOpen={isUploading} />
        ) : (
          <span />
        )}
        <DetectPIINavBarLayout selectedTab={DetectPIINavTabKey.TestExtractor}>
          <DetectPIITest
            onSelectImage={extractWithImageFile}
            testReport={testReport}
          />
        </DetectPIINavBarLayout>
      </Main>
    </Layout>
  );
}

export const DetectPIITestContainer = React.memo(_DetectPIITestContainer);
export default DetectPIITestContainer;
