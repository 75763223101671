import { BriefForm } from "../form";
import {
  BriefFormGroup,
  DetailFormGroup,
  FormGroupAnchorBase,
  FormGroupAnchorRespBase,
  FormGroupDocumentDetectionBase,
  FormGroupDocumentDetectionRespBase,
  FormGroupTokenGroupBase,
  FormGroupTokenGroupRequest,
} from "../formGroup";
import { TokenGroupImageMapper, TokenGroupTextMapper } from "./tokenGroup";

export const BriefFormGroupMapper = {
  fromDetail: (detailGroup: DetailFormGroup): BriefFormGroup => {
    const formsById: { [id: string]: BriefForm } = {};
    [...detailGroup.anchors, ...detailGroup.tokenGroups].forEach(
      groupOrAnchor => {
        formsById[groupOrAnchor.form.id] = groupOrAnchor.form;
      }
    );
    return {
      id: detailGroup.id,
      name: detailGroup.name,
      type: detailGroup.type,
      isTemplate: detailGroup.isTemplate,
      forms: Object.values(formsById),
      updatedAt: detailGroup.updatedAt,
    };
  },
};

export const FormGroupTokenGroupMapper = {
  toRequest: (
    tokenGroup: FormGroupTokenGroupBase
  ): FormGroupTokenGroupRequest => {
    return {
      id: tokenGroup.id,
      form_id: tokenGroup.formId,
      order: tokenGroup.order,
      match_mode: tokenGroup.matchMode,
      texts: TokenGroupTextMapper.toRequest(tokenGroup.texts),
      images: TokenGroupImageMapper.toRequest(tokenGroup.images),
    };
  },
};

export const FormGroupAnchorMapper = {
  toResp: (anchor: FormGroupAnchorBase): FormGroupAnchorRespBase => {
    return {
      id: anchor.id,
      form_id: anchor.formId,
    };
  },
};

export const FormGroupDocumentDetectionMapper = {
  toResp: (
    documentDetection: FormGroupDocumentDetectionBase
  ): FormGroupDocumentDetectionRespBase => {
    return {
      id: documentDetection.id,
      target_form_id: documentDetection.targetFormId,
      target_form_group_id: documentDetection.targetFormGroupId,
      document_type: documentDetection.documentType,
    };
  },
};
