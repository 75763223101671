@import "../../styles/variables.scss";

.frozen-field {
  display: flex;
  flex-direction: column;
  background-color: $neutral-lighter;
  margin-bottom: 10px;
  padding: 8px;
  padding-top: 6px;

  span {
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .field-subtitle {
    color: #a19f9d;

    .format::before {
      content: " - ";
    }
  }
}
