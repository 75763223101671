import { Spinner } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useMemo } from "react";

import { ExtractAPIV2Document } from "../../models";
import { Extraction, ExtractionResult } from "../../types/extraction";
import { Workspace } from "../../types/workspace";
import { getWorkerRequestError } from "../../utils/errors";
import { ExtractTableDataBottomSheet } from "../ExtractTableDataBottomSheet";
import { HSplitView } from "../HSplitView";
import { DocumentNotFoundPlaceholder } from "./DocumentNotFoundPlaceholder";
import { ImageAssetSection } from "./ImageAssetSection";
import { ResultAssetSection } from "./ResultAssetSection";
import { WorkspaceDocumentDetailsSectionHeader } from "./WorkspaceDocumentDetailsSectionHeader";
import styles from "./styles.module.scss";

interface WorkspaceDocumentDetailsSectionProps {
  workspace: Workspace;
  extraction: Extraction | null;
  extractionResult: ExtractionResult | null;
  resultAsset: ExtractAPIV2Document | null;
  onDownloadClick: (resultIndex: number) => Promise<void>;
  onReExtractExtraction: (extractionResult: ExtractionResult) => Promise<void>;
  pagination?: {
    hasPrevPage: boolean;
    hasNextPage: boolean;
    onClickPrevPage: () => void;
    onClickNextPage: () => void;
  };
  additionalImageUrls?: string[];
  isResultLoading: boolean;
  isImageLoading: boolean;
}

function useWorkspaceDocumentDetailsSection(
  args: WorkspaceDocumentDetailsSectionProps
) {
  const {
    workspace,
    extraction,
    extractionResult,
    resultAsset,
    onDownloadClick: _onDownloadClick,
    onReExtractExtraction,
    pagination,
    additionalImageUrls,
    isResultLoading,
    isImageLoading,
  } = args;

  const onDownloadClick = React.useCallback(() => {
    if (extractionResult?.extractionIndex == null) {
      return;
    }
    _onDownloadClick(extractionResult.extractionIndex);
  }, [_onDownloadClick, extractionResult?.extractionIndex]);

  const onClickReExtract = useCallback(() => {
    if (extractionResult?.id == null) {
      return;
    }
    onReExtractExtraction(extractionResult);
  }, [extractionResult, onReExtractExtraction]);

  const extractionError = useMemo(() => {
    const _resultAsset = resultAsset as any;
    if (_resultAsset != null && _resultAsset["error"] != null) {
      return getWorkerRequestError(_resultAsset["error"]);
    }
    if (extractionResult?.info.error != null) {
      return getWorkerRequestError(extractionResult.info.error);
    }
    if (extraction?.info.error != null) {
      return getWorkerRequestError(extraction.info.error);
    }
    return null;
  }, [extraction?.info.error, extractionResult?.info.error, resultAsset]);

  const fileName = extractionResult?.fileName ?? extraction?.fileName ?? "File";

  const imageUrls = useMemo(() => {
    return [
      ...(extractionResult?.info.imageAssetUrl != null
        ? [extractionResult.info.imageAssetUrl]
        : []),
      ...(additionalImageUrls ?? []),
    ];
  }, [additionalImageUrls, extractionResult?.info.imageAssetUrl]);

  const orientation = resultAsset?.metadata?.orientation;

  return useMemo(
    () => ({
      workspace,
      extractionResult,
      resultAsset,
      onDownloadClick,
      onClickReExtract,
      canReExtract: extractionResult?.id != null,
      extractionError,
      fileName,
      pagination,
      imageUrls,
      isResultLoading,
      isImageLoading,
      orientation,
    }),
    [
      workspace,
      extractionResult,
      resultAsset,
      onDownloadClick,
      onClickReExtract,
      extractionError,
      fileName,
      pagination,
      imageUrls,
      isResultLoading,
      isImageLoading,
      orientation,
    ]
  );
}

export function WorkspaceDocumentDetailsSectionImpl(
  props: ReturnType<typeof useWorkspaceDocumentDetailsSection>
) {
  const {
    workspace,
    extractionResult,
    resultAsset,
    onDownloadClick,
    onClickReExtract,
    canReExtract,
    extractionError,
    fileName,
    pagination,
    imageUrls,
    isResultLoading,
    isImageLoading,
    orientation,
  } = props;

  return (
    <div className={styles.container}>
      <WorkspaceDocumentDetailsSectionHeader
        workspace={workspace}
        documentFileName={fileName}
        pagination={pagination ?? null}
      />
      <div className={styles["content"]}>
        <HSplitView
          leftFlexGrow={1.5}
          leftMinWidth={320}
          left={
            <section className={styles.imageAssetSectionContainer}>
              {isImageLoading ? (
                <div className={styles.loading}>
                  <Spinner />
                  <p className={styles.loadingText}>
                    <FormattedMessage id="workspace.document_detail.loading.image" />
                  </p>
                </div>
              ) : extractionResult != null ? (
                <ImageAssetSection
                  sliceNumber={extractionResult.info.sliceNumber}
                  assetUrls={imageUrls}
                  orientation={orientation}
                />
              ) : null}
            </section>
          }
          rightFlexGrow={1}
          rightMinWidth={480}
          right={
            <section className={styles.resultAssetContainer}>
              {isResultLoading ? (
                <div className={styles.loading}>
                  <Spinner />
                  <p className={styles.loadingText}>
                    <FormattedMessage id="workspace.document_detail.loading.result" />
                  </p>
                </div>
              ) : extractionResult?.status !== "processed" ||
                extractionError != null ? (
                <p className={styles.extractionFailedPlaceholder}>
                  <FormattedMessage id="workspace.document_detail.extractionFailed.message" />
                  {extractionError != null ? (
                    <>
                      :&nbsp;
                      <FormattedMessage
                        id={extractionError.messageId}
                        values={extractionError.detail}
                      />
                    </>
                  ) : null}
                  {canReExtract ? (
                    <button
                      className={styles.retryButton}
                      onClick={onClickReExtract}
                    >
                      <FormattedMessage id="workspace.document_detail.retry_extraction" />
                    </button>
                  ) : null}
                </p>
              ) : extractionResult == null || resultAsset == null ? (
                <DocumentNotFoundPlaceholder showAlert={true} />
              ) : (
                <ResultAssetSection
                  resultAsset={resultAsset}
                  onDownloadClick={onDownloadClick}
                  assetUrl={imageUrls?.[0]}
                  onClickReExtract={onClickReExtract}
                  canReExtract={canReExtract}
                />
              )}
            </section>
          }
        />
      </div>
      <ExtractTableDataBottomSheet />
    </div>
  );
}

export function WorkspaceDocumentDetailsSection(
  args: WorkspaceDocumentDetailsSectionProps
) {
  const props = useWorkspaceDocumentDetailsSection(args);
  return <WorkspaceDocumentDetailsSectionImpl {...props} />;
}
