import { writeToString } from "@fast-csv/format";
import * as React from "react";
import { useStore } from "react-redux";

import { RootState } from "../redux/types";

/** A hook function for creating the `origin` parameter for the extract API.
 */
export function useCreateOrigin() {
  const { getState } = useStore<RootState>();

  const createOrigin = React.useCallback(
    async (source: string) => {
      const currentUser = getState().user.currentUser;
      const userId = currentUser?.id ?? "";
      const email = currentUser?.email ?? "";

      return writeToString([
        [`source=${source}`, `user_id=${userId}`, `email=${email}`],
      ]);
    },
    [getState]
  );

  return createOrigin;
}
