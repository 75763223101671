import * as yup from "yup";

export const fslModelStateSchema = yup.object().shape({
  is_ready_to_use: yup.boolean().required(),
  is_ready_to_use_once: yup.boolean().optional(),
  is_standard_model_enabled: yup.boolean().optional(),
  is_document_too_complex: yup.boolean().optional(),
  should_fallback_to_original_custom_model: yup.boolean().optional(),
});

export type FSLModelStateSchemaStoage = yup.InferType<
  typeof fslModelStateSchema
>;

export type FSLModelState = {
  isReadyToUse: boolean;
  isReadyToUseOnce?: boolean;
  isStandardModelEnabled?: boolean;
  isDocumentTooComplex?: boolean;
  shouldFallbackToOriginalCustomModel?: boolean;
};
