import { Permission, PermissionResp } from "../team";

export const PermissionMapper = {
  toResp: (permission: Permission): PermissionResp => {
    return {
      edit_resource: permission.editResource,
      view_resource: permission.viewResource,
      create_resource: permission.createResource,
      edit_membership: permission.editMembership,
      remove_resource: permission.removeResource,
      view_membership: permission.viewMembership,
      edit_subscription: permission.editSubscription,
      edit_team_setting: permission.editTeamSetting,
      view_subscription: permission.viewSubscription,
      view_team_setting: permission.viewTeamSetting,
      view_audit_log: permission.viewAuditLog,
    };
  },
};
