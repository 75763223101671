import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useRef, useState } from "react";

import { useDetectionRegionInspector } from "../../contexts/detectionRegionInspector";
import { useLocale } from "../../contexts/locale";
import { useDetectionRegionFieldTypeHierarchy } from "../../hooks/detection_region_field";
import { useTeamPermission } from "../../hooks/permission";
import { BaseFieldSettingModalPayload } from "../../models";
import { DetectionRegionFieldType } from "../../types/detectionRegion";
import DetectionRegionAdvancedSetting, {
  DetectionRegionAdvancedSettingHandle,
} from "../DetectionRegionAdvancedSetting";
import { DetectionRegionWaterMarkSetting } from "../DetectionRegionWaterMarkSetting";
import styles from "./styles.module.scss";

interface Props {
  payload: BaseFieldSettingModalPayload;
  onCloseModal(): void;
}

const TextFieldModal = React.memo((props: Props) => {
  const { onCloseModal, payload } = props;
  const { index, field, selectedDetectionRegionId } = payload;
  const { localized } = useLocale();
  const { findDetectionRegionFieldTypeHierarchySubTypeKeyTexts } =
    useDetectionRegionFieldTypeHierarchy();
  const detectionRegionTextFieldTypeOptions =
    findDetectionRegionFieldTypeHierarchySubTypeKeyTexts("text");
  const { updateDetectionRegionField } = useDetectionRegionInspector();
  const [fieldKey, setFieldKey] = useState<DetectionRegionFieldType>(
    field.type
  );
  const [extras, setExtras] = useState<any>(field.extras);

  const advancedSettingsRef =
    useRef<DetectionRegionAdvancedSettingHandle>(null);

  const onSave = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const option = { key: fieldKey } as IDropdownOption;
      let params;
      if (advancedSettingsRef.current) {
        params = advancedSettingsRef.current.getParams();
      }

      updateDetectionRegionField(index, {
        type: option.key as DetectionRegionFieldType,
        params: params,
        extras: extras,
      });

      onCloseModal();
    },
    [
      extras,
      onCloseModal,
      index,
      fieldKey,
      updateDetectionRegionField,
      advancedSettingsRef,
    ]
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        "form_inspector.detection_region_field_text_setting_editor.title"
      ),
    }),
    [localized]
  );

  const modalProps: IModalProps = React.useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  const onFieldTypeChange = useCallback(
    (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option) {
        setFieldKey(option.key as DetectionRegionFieldType);
      }
    },
    [setFieldKey]
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <Dialog
      minWidth={405}
      hidden={false}
      onDismiss={onCloseModal}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <form onSubmit={onSave}>
        <div className={styles["description"]}>
          <FormattedMessage id="form_inspector.detection_region_field_text_setting_editor.desc" />
        </div>

        <Dropdown
          selectedKey={fieldKey}
          options={detectionRegionTextFieldTypeOptions.map(({ key, text }) => ({
            key,
            text: localized(text),
          }))}
          onChange={onFieldTypeChange}
          placeholder={localized("dropdown.placeholder")}
          disabled={!hasPermissionToEditResource}
        />

        <div className={styles["water-mark-setting"]}>
          <DetectionRegionWaterMarkSetting
            onChange={setExtras}
            extras={field.extras}
          />
        </div>

        <DetectionRegionAdvancedSetting
          fieldType={fieldKey}
          fieldParams={field.params}
          selectedDetectionRegionId={selectedDetectionRegionId}
          fieldIndex={index}
          ref={advancedSettingsRef}
        />
        <DialogFooter>
          <DefaultButton
            onClick={onCloseModal}
            text={localized(
              hasPermissionToEditResource ? "common.cancel" : "common.close"
            )}
          />
          {hasPermissionToEditResource && (
            <PrimaryButton type="submit" text={localized("common.ok")} />
          )}
        </DialogFooter>
      </form>
    </Dialog>
  );
});

export default TextFieldModal;
