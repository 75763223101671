import { Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";

import { useFormEditor } from "../../contexts/formEditor";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

const MerchantPatternMatchingLauncher = () => {
  const navigate = useNavigate();
  const { form } = useFormEditor();
  const goToAdvanceMerchantSetupPage = useCallback(() => {
    navigate(`/form/${form?.id}/edit/advanceMerchantSetup`);
  }, [navigate, form]);
  return (
    <div className={styles["container"]}>
      <Label className={styles["label"]}>
        <FormattedMessage id="advance_token_setup_launcher.title" />
      </Label>
      <p className={styles["description"]}>
        <FormattedMessage id="advance_token_setup_launcher.description" />
      </p>
      <DefaultButton
        textId="advance_token_setup_launcher.setup_button"
        styles={{
          root: {
            padding: 8,
          },
          flexContainer: {
            flexDirection: "row-reverse",
          },
        }}
        iconProps={{ iconName: "Forward" }}
        onClick={goToAdvanceMerchantSetupPage}
      />
    </div>
  );
};

export default MerchantPatternMatchingLauncher;
