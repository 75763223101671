import { Link } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";

import { useLocale } from "../../contexts/locale";
import { ConfirmModalType } from "../../types/confirmation";
import ConfirmModal from "../ConfirmModal";
import styles from "./styles.module.scss";

interface Props {
  showAlert: boolean;
}
export function DocumentNotFoundPlaceholder(props: Props) {
  const { showAlert } = props;
  const { localized } = useLocale();

  const navigate = useNavigate();

  const onClickModal = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className={styles.documentNotFoundInfoContainer}>
      <p className={styles.notFoundText}>
        <FormattedMessage
          id="error.workspace.extraction_not_found.info"
          values={{
            url: (
              <Link
                href="https://www.formx.ai/talk-with-us"
                styles={{
                  root: {
                    color: "#0078D4",
                  },
                }}
              >
                {localized("common.contact_us")}
              </Link>
            ),
          }}
        />
      </p>
      <ConfirmModal
        isOpen={showAlert}
        modalType={ConfirmModalType.Notify}
        titleId={"error.workspace.extraction_not_found.modal.title"}
        messageId={"error.workspace.extraction_not_found.modal.message"}
        actionId={"error.workspace.extraction_not_found.modal.action"}
        onCancel={onClickModal}
        onConfirm={onClickModal}
      />
    </div>
  );
}
