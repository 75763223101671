import cn from "classnames";
import * as React from "react";

import { useStateRef } from "../../hooks/common";
import { useDragTracker } from "../../hooks/dragTracker";
import {
  ExtractTableDataField,
  ExtractTableDataType,
} from "../ExtractTableDataField";
import { ActionButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

type Props = {
  className?: string;
};

const MIN_HEIGHT_PX = 300;
const MAX_HEIGHT_PERCENT = 0.75;

function getMaxHeight() {
  return window.innerHeight * MAX_HEIGHT_PERCENT;
}

export function useExtractTableDataBottomSheetState(props: Props) {
  const { className } = props;
  const [label, setLabel] = React.useState("");
  const [value, setValue] = React.useState<any>(undefined);
  const [type, setType] = React.useState<ExtractTableDataType>(
    ExtractTableDataType.ListTable
  );
  const [isEditable, setIsEditable] = React.useState(false);
  const [height, setHeight, heightRef] = useStateRef(getMaxHeight());

  const [onChange, setOnChange] =
    React.useState<(value: any) => void | undefined>();

  const holderRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const dragHandleRef = React.useRef<HTMLDivElement>(null);

  const [isVisible, setIsVisible] = React.useState(false);
  const open = React.useCallback(
    (
      label: string,
      value: any,
      type: ExtractTableDataType,
      isEditable?: boolean,
      onChange?: (value: any) => void
    ) => {
      setLabel(label);
      setValue(value);
      setType(type);
      setIsVisible(true);
      setIsEditable(isEditable ?? false);
      const maxHeight = getMaxHeight();
      containerRef.current?.style.setProperty("max-height", `${maxHeight}px`);
      containerRef.current?.style.setProperty("height", `${maxHeight}px`);
      setHeight(getMaxHeight);
      setOnChange(() => {
        return onChange;
      });
    },
    [setHeight]
  );

  const close = React.useCallback(() => {
    setIsVisible(false);
    setHeight(MIN_HEIGHT_PX);
  }, [setHeight]);

  const updateValue = React.useCallback((value: any) => {
    setValue(value);
  }, []);

  React.useEffect(() => {
    const holder = holderRef.current;
    const container = containerRef.current;

    if (!holder || !container) return;

    const observer = new ResizeObserver(() => {
      container.style.width = `${holder.clientWidth}px`;
    });

    observer.observe(holder);

    return () => {
      observer.disconnect();
    };
  }, [holderRef, containerRef]);

  const dragStartHeight = React.useRef(0);

  const onDragStart = React.useCallback(() => {
    dragStartHeight.current = heightRef.current;
  }, [heightRef]);

  const onDragMove = React.useCallback(
    (_dx: number, dy: number) => {
      let newHeight = dragStartHeight.current - dy;
      const maxHeight = getMaxHeight();

      if (newHeight < MIN_HEIGHT_PX) {
        newHeight = MIN_HEIGHT_PX;
      } else if (newHeight > maxHeight) {
        newHeight = maxHeight;
      }
      containerRef.current?.style.setProperty("height", `${newHeight}px`);
      containerRef.current?.style.setProperty("max-height", `${newHeight}px`);
      setHeight(newHeight);
    },
    [setHeight]
  );

  useDragTracker(dragHandleRef, {
    onDragStart,
    onDragMove,
  });

  return React.useMemo(() => {
    return {
      label,
      isVisible,
      value,
      close,
      open,
      updateValue,
      className,
      type,
      isEditable,
      holderRef,
      containerRef,
      onChange,
      height,
      dragHandleRef,
    };
  }, [
    label,
    isVisible,
    value,
    open,
    close,
    updateValue,
    className,
    type,
    holderRef,
    isEditable,
    containerRef,
    onChange,
    height,
    dragHandleRef,
  ]);
}

export function ExtractTableDataBottomSheetImpl(
  props: ReturnType<typeof useExtractTableDataBottomSheetState>
) {
  const {
    label,
    value,
    isVisible,
    close,
    className,
    type,
    isEditable,
    onChange,
    dragHandleRef,
    containerRef,
  } = props;

  const holderClass = cn(className);

  const containerClassName = cn(
    styles["container"],
    {
      [styles["container-closed"]]: !isVisible,
      [styles["container-open"]]: isVisible,
    },
    className
  );

  return (
    <div className={holderClass} ref={props.holderRef}>
      <div
        className={containerClassName}
        ref={containerRef}
        style={{
          bottom: -(isVisible ? 0 : containerRef.current?.clientHeight ?? 0),
        }}
      >
        <div className={styles["z-layout"]}>
          <div className={styles["table-layout"]}>
            <ExtractTableDataField
              label={label}
              value={value}
              type={type}
              isEditable={isEditable}
              className={styles["table"]}
              onChange={onChange}
              actions={
                <ActionButton
                  iconName="ChromeClose"
                  textId="common.hide"
                  style={{ height: "32px" }}
                  onClick={close}
                />
              }
            />
          </div>
          <div className={styles["drag-handle"]} ref={dragHandleRef}></div>
        </div>
      </div>
    </div>
  );
}

let singletonRef = null as null | {
  open: ReturnType<typeof useExtractTableDataBottomSheetState>["open"];
  updateValue: ReturnType<
    typeof useExtractTableDataBottomSheetState
  >["updateValue"];
};

export function ExtractTableDataBottomSheet(props: Props) {
  const states = useExtractTableDataBottomSheetState(props);
  const { open, updateValue } = states;

  const instance = React.useMemo(() => {
    return { open, updateValue };
  }, [open, updateValue]);

  React.useEffect(() => {
    singletonRef = instance;

    return () => {
      if (singletonRef === instance) singletonRef = null;
    };
  }, [instance]);

  return <ExtractTableDataBottomSheetImpl {...states} />;
}

ExtractTableDataBottomSheet.open = function (
  label: string,
  value: any,
  type: ExtractTableDataType,
  isEditable?: boolean,
  onChange?: (value: any) => void
) {
  singletonRef?.open(label, value, type, isEditable, onChange);
};

ExtractTableDataBottomSheet.updateValue = function (value: any) {
  singletonRef?.updateValue(value);
};
