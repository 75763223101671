.pagination {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.pageNumber {
  color: #323130;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.paginationButtonGroup {
  display: flex;
  gap: 8px;
}

.paginationButton {
  width: 32px;
  height: 32px;
}

.actionButtonGroup {
  display: flex;
  gap: 8px;
}
