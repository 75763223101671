import { Text } from "@fluentui/react";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { AppConfig } from "../../config";
import { useLocale } from "../../contexts/locale";
import { useToast } from "../../hooks/toast";
import { AuditLogJob, AuditLogJobStatusType } from "../../types/auditLog";
import AuditLogJobTable from "../AuditLogJobTable";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface Props {
  startYear: number;
  startMonth: number;
  auditLogJobs: Array<AuditLogJob>;
  generateAuditLogCsv: (year: number, month: number) => Promise<void>;
  refreshAuditLogJobs: () => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function getYearOptions(yearLowerLimit: number, currentYear: number) {
  const yearOptions: IDropdownOption[] = [];
  for (let year = yearLowerLimit; year <= currentYear; year++) {
    yearOptions.push({
      key: year.toString(),
      text: year.toString(),
    });
  }

  return yearOptions;
}

function getMonthOptions(
  year: number,
  yearLowerLimit: number,
  monthLowerLimit: number,
  currentYear: number,
  currentMonth: number,
  localized: (id: string) => string
) {
  const monthOptions: IDropdownOption[] = [];
  for (let month = 0; month <= 11; month++) {
    monthOptions.push({
      key: month,
      text: localized(`audit.datepicker.month.option.m${month + 1}`),
      hidden:
        (year === yearLowerLimit && month < monthLowerLimit) ||
        (year === currentYear && month > currentMonth),
    });
  }
  return monthOptions;
}

function getJobStatus(
  auditLogJobs: Array<AuditLogJob>,
  year: number,
  month: number
): string | null {
  for (const job of auditLogJobs) {
    if (job.year === year && job.month === month) {
      return job.status;
    }
  }
  return null;
}

const AuditLog = React.memo((props: Props) => {
  const { localized } = useLocale();
  const toast = useToast();
  const {
    startYear,
    startMonth,
    auditLogJobs,
    generateAuditLogCsv,
    refreshAuditLogJobs,
    setIsLoading,
  } = props;

  const currentDate = useMemo(() => new Date(), []);
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear.toString());
  const currentJobStatus = useMemo(
    () => getJobStatus(auditLogJobs, Number(year), month + 1),
    [auditLogJobs, year, month]
  );

  const [yearLowerLimit, monthLowerLimit] = useMemo(() => {
    const teamCreatedAt = new Date(startYear, startMonth, 1);
    // Logs expires after a year.
    const expiredLogDate = new Date();
    expiredLogDate.setUTCMonth(
      expiredLogDate.getUTCMonth() - AppConfig.auditLogRetentionPeriod
    );
    expiredLogDate.setUTCDate(1);
    expiredLogDate.setUTCHours(0, 0, 0, 0);

    let maxDate = teamCreatedAt;
    if (expiredLogDate.getTime() > maxDate.getTime()) {
      maxDate = expiredLogDate;
    }

    return [maxDate.getFullYear(), maxDate.getMonth()];
  }, [startYear, startMonth]);

  const yearOptions = useMemo(
    () => getYearOptions(yearLowerLimit, currentYear),
    [yearLowerLimit, currentYear]
  );
  const monthOptions = useMemo(
    () =>
      getMonthOptions(
        Number(year),
        yearLowerLimit,
        monthLowerLimit,
        currentYear,
        currentMonth,
        localized
      ),
    [
      year,
      yearLowerLimit,
      monthLowerLimit,
      currentYear,
      currentMonth,
      localized,
    ]
  );

  const textId = useMemo(() => {
    switch (currentJobStatus) {
      case AuditLogJobStatusType.pending:
        return "audit.download.generating.file";
      case AuditLogJobStatusType.processing:
        return "audit.download.generating.file";
      case AuditLogJobStatusType.completed:
        if (Number(year) === currentYear && month === currentMonth) {
          return "audit.download.update";
        }
        return "audit.download";
      case AuditLogJobStatusType.failed:
        return "audit.download.generate";
      default:
        return "audit.download.generate";
    }
  }, [currentJobStatus, year, month, currentYear, currentMonth]);

  const buttonDisabled = useMemo(() => {
    switch (currentJobStatus) {
      case AuditLogJobStatusType.pending:
        return true;
      case AuditLogJobStatusType.processing:
        return true;
      case AuditLogJobStatusType.completed:
        return false;
      case AuditLogJobStatusType.failed:
        return false;
      default:
        return false;
    }
  }, [currentJobStatus]);

  useEffect(() => {
    let firstValidOption: IDropdownOption | null = null;
    let needReset = false;
    for (const monthOption of monthOptions) {
      if (monthOption.key === month && monthOption.hidden) {
        needReset = true;
      } else if (!monthOption.hidden && firstValidOption === null) {
        firstValidOption = monthOption;
      }
    }
    if (needReset && firstValidOption != null) {
      setMonth(firstValidOption.key as number);
    }
  }, [year, month, monthOptions, currentJobStatus, auditLogJobs]);

  const onYearChange = useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option) {
        setYear(option.key as string);
      }
    },
    []
  );

  const onMonthChange = useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option) {
        setMonth(option.key as number);
      }
    },
    []
  );

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (
        currentJobStatus === null ||
        (currentJobStatus === AuditLogJobStatusType.completed &&
          Number(year) === currentYear &&
          month === currentMonth)
      ) {
        try {
          setIsLoading(true);
          await generateAuditLogCsv(Number(year), month + 1);
        } catch (e) {
          toast.error("error.audit_log.failed_to_generate_csv_file");
        }

        // Loading state is set to false after calling refreshAuditLogJobs() and listAuditLogJobs api calling is completed
        refreshAuditLogJobs();
      } else {
        for (const job of auditLogJobs) {
          if (job.year === Number(year) && job.month === month + 1) {
            if (job.url != null) {
              // trigger csv file download
              window.location.href = job.url;
            }
            break;
          }
        }
      }
    },
    [
      year,
      month,
      currentYear,
      currentMonth,
      generateAuditLogCsv,
      refreshAuditLogJobs,
      auditLogJobs,
      currentJobStatus,
      setIsLoading,
      toast,
    ]
  );

  return (
    <div>
      <div className={styles["section"]}>
        <div className={styles["headMessage"]}>
          <Text as="h3" className={styles["head"]}>
            <FormattedMessage id="audit.header.title" />
          </Text>
          <Text as="p" className={styles["message"]}>
            <FormattedMessage id="audit.header.description" />
          </Text>
        </div>
        <div>
          <form className={styles["form"]} onSubmit={onSubmit}>
            <Dropdown
              label={localized("audit.datepicker.year.label")}
              options={yearOptions}
              className={styles["dropdown"]}
              selectedKey={year}
              onChange={onYearChange}
            />
            <Dropdown
              label={localized("audit.datepicker.month.label")}
              options={monthOptions}
              className={styles["dropdown"]}
              selectedKey={month}
              onChange={onMonthChange}
            />

            <DefaultButton
              type="submit"
              textId={textId}
              disabled={buttonDisabled}
            />
          </form>
        </div>
        {currentJobStatus === AuditLogJobStatusType.pending ||
        currentJobStatus === AuditLogJobStatusType.processing ? (
          <Text as="p" className={styles["message"]}>
            <FormattedMessage id="audit.download.generating.message" />
          </Text>
        ) : null}
        <AuditLogJobTable
          auditLogJobs={auditLogJobs}
          currentYear={currentYear}
          currentMonth={currentMonth}
        />
      </div>
    </div>
  );
});

export default AuditLog;
