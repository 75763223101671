import { Text } from "@fluentui/react";
import classnames from "classnames";
import React from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

export const KeyValueExample = React.memo(() => {
  const { localized } = useLocale();

  return (
    <div className={styles["example"]}>
      <div className={styles["example-key-value-table"]}>
        <div className={styles["example-column"]}>
          <Text className={styles["example-title-text"]}>
            {localized("key_value.example.key")}
          </Text>
          <Text
            className={classnames(
              styles["example-cell-text"],
              styles["key-cell"]
            )}
          >
            {localized("key_value.example.key.example")}
          </Text>
        </div>
        <div className={styles["example-column"]}>
          <Text className={styles["example-title-text"]}>
            {localized("key_value.example.value")}
          </Text>
          <Text
            className={classnames(
              styles["example-cell-text"],
              styles["value-cell"]
            )}
          >
            {localized("key_value.example.value.example")}
          </Text>
        </div>
      </div>
      <Text className={styles["example-desc-text"]}>
        {localized("key_value.example.desc")}
      </Text>
    </div>
  );
});
