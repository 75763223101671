import * as React from "react";

import { useExtractorActionCreator } from "../actions/extractor";
import { useAppSelector } from "./redux";

export function useExtractorFieldSchema() {
  const { getExtractorFieldSchemaTable } = useExtractorActionCreator();
  const {
    extractorFieldSchemaTables,
    isGettingExtractorFieldSchemaTables,
    getExtractorFieldSchemaErrors,
  } = useAppSelector(state => state.extractor);

  const isGettingExtractorFieldSchemaRef = React.useRef(
    isGettingExtractorFieldSchemaTables
  );
  isGettingExtractorFieldSchemaRef.current =
    isGettingExtractorFieldSchemaTables;

  const getExtractorFieldSchema = React.useCallback(
    async (extractorId: string) => {
      if (
        extractorFieldSchemaTables[extractorId] === undefined &&
        !isGettingExtractorFieldSchemaRef.current[extractorId]
      ) {
        await getExtractorFieldSchemaTable(extractorId);
      }
    },
    [extractorFieldSchemaTables, getExtractorFieldSchemaTable]
  );

  const getExtractorFieldSchemaTableByExtractorId = React.useCallback(
    (extractorId?: string) => {
      if (extractorId !== undefined) {
        return extractorFieldSchemaTables[extractorId];
      }
      return undefined;
    },
    [extractorFieldSchemaTables]
  );

  const isFailedToGetExtractorFieldSchema = React.useCallback(
    (extractorId?: string) => {
      return (
        extractorId !== undefined &&
        getExtractorFieldSchemaErrors[extractorId] !== undefined
      );
    },
    [getExtractorFieldSchemaErrors]
  );

  return React.useMemo(
    () => ({
      extractorFieldSchemaTables,
      getExtractorFieldSchema,
      isFailedToGetExtractorFieldSchema,
      getExtractorFieldSchemaTableByExtractorId,
    }),
    [
      extractorFieldSchemaTables,
      getExtractorFieldSchema,
      isFailedToGetExtractorFieldSchema,
      getExtractorFieldSchemaTableByExtractorId,
    ]
  );
}
