export enum SurveyUseCase {
  ConvertToExcels = "convert_to_excels",
  AddDocProcessingToApp = "add_doc_processing_to_app",
  AutoDocProcessing = "integrate_with_other_tools",
  Other = "other",
}

export type SurveyForm = {
  name: string;
  companyName: string;
  useCases: SurveyUseCase[];
  phoneNumber: string;
};
