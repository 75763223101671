@import "../../styles/variables.scss";

$top-height: 43px;
$bottom-height: 64px;

.layout {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100vh;
}

.main {
  flex-grow: 1;

  display: flex;
  flex-direction: row;
  min-width: 0;
  position: relative;

  .main-inner {
    display: flex;
    flex-direction: row;
    width: 100%;

    > .content {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    > .with-leftbar {
      width: calc(100% - #{$left-bar-collapsed-width});
    }

    > .with-full-leftbar {
      width: calc(100% - #{$left-bar-full-width});
    }

    @media screen and (max-width: 848px) {
      > .with-full-leftbar,
      > .with-leftbar {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 848px) {
    padding-left: 0px;
  }
}

.top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $top-height;
  z-index: 10;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: $bottom-height;
  z-index: 2;
}

.right {
  position: relative;
  z-index: 3;
  display: inline-block;
  flex-shrink: 0;

  .right-inner {
    display: inline-block;
    height: 100%;
  }
}

.has-top {
  margin-top: $top-height;
}

.has-bottom {
  margin-bottom: $bottom-height;
}

.content-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.content-main {
  flex: 1;
  min-width: 0px;
}

.content-right {
  flex: 0;
}
