@import "../../styles/variables.scss";

$gray-color: #a19f9d;

.container {
  min-height: 438px;
  width: 216px;

  .list {
    height: 406px;
    position: relative;
    border: 1px solid #edebe9;
    box-sizing: border-box;
  }

  .placeholder {
    color: #a19f9d;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .empty-placeholder {
    text-align: center;
    vertical-align: middle;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    overflow-wrap: anywhere;
    overflow-y: hidden;

    max-width: 216px;
    max-height: 420px;
  }

  .buttons {
    :global(.ms-Button-icon) {
      color: #000000;
    }
  }

  .focus-area:focus-visible {
    outline: none;
  }
}

.list-item {
  width: 100%;
  padding: 16px 8px 16px 8px;

  .match-info {
    color: $gray-color;
    font-size: 10px;
    line-height: 12px;
  }

  .title {
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 4px;
  }

  .default-title {
    color: $gray-color;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .available-info {
    font-size: 12px;
    line-height: 16px;
    color: #605e5c;
    margin-bottom: 4px;
  }

  .error {
    font-size: 12px;
    line-height: 16px;
    color: #a4262c;
  }
}

.selected-list-item {
  background: #edebe9;
}
