import { CONTROL_POINT_SIZE } from "../constants";
import { Dimension } from "../models";
import { BoundingBox } from "../types/boundingBox";
import { Vertex } from "../types/vertex";
import { BaseInteractionHandler } from "./handler";
import { CanvasStore } from "./store";

export interface SelectToolHandlerDelegate {
  onSelectAnchor: (anchorId?: string) => void;
  onSelectField: (fieldId?: string) => void;
  onSelectDetectionRegion: (detectionRegionId?: string) => void;
}

export interface SelectAnchor {
  anchorId: string;
}

export interface SelectField {
  fieldId: string;
}

export interface SelectDetectionRegion {
  detectionRegionId: string;
}

export type SelectToolSelection =
  | SelectAnchor
  | SelectDetectionRegion
  | SelectField;

export class SelectToolHandler extends BaseInteractionHandler {
  delegate: SelectToolHandlerDelegate;
  bound: Dimension;
  selectedFieldId?: string;
  selectedAnchorId?: string;
  selectedDetectionRegionId?: string;

  draggingAnchorVertexIndex?: number;
  draggingAnchorVertices?: Vertex[];
  draggingFieldOriginalBbox?: BoundingBox;
  draggingFieldVertex?: Vertex;
  draggingDetectionRegionOriginalBbox?: BoundingBox;
  draggingDetectionRegionVertex?: Vertex;

  lastMouseDownCoord?: Vertex;

  movementEnabled: boolean;

  constructor(
    delegate: SelectToolHandlerDelegate,
    bound: Dimension,
    movementEnabled: boolean,
    initialSelection?: SelectToolSelection
  ) {
    super();
    this.delegate = delegate;
    this.movementEnabled = movementEnabled;
    this.bound = bound;

    if (initialSelection) {
      this.doSelection(initialSelection);
    }
  }

  doSelection = (selection: SelectToolSelection) => {
    if ("anchorId" in selection) {
      this.onSelectAnchor(selection.anchorId);
    } else if ("detectionRegionId" in selection) {
      this.onSelectDetectionRegion(selection.detectionRegionId);
    } else if ("fieldId" in selection) {
      this.onSelectField(selection.fieldId);
    }
  };

  onSelectAnchor = (anchorId?: string) => {
    this.selectedAnchorId = anchorId;
    this.selectedFieldId = undefined;
    this.selectedDetectionRegionId = undefined;
    this.delegate.onSelectAnchor(anchorId);
  };

  onSelectField = (fieldId?: string) => {
    this.selectedAnchorId = undefined;
    this.selectedFieldId = fieldId;
    this.selectedDetectionRegionId = undefined;
    this.delegate.onSelectField(fieldId);
  };

  onSelectDetectionRegion = (detectionRegionId?: string) => {
    this.selectedAnchorId = undefined;
    this.selectedFieldId = undefined;
    this.selectedDetectionRegionId = detectionRegionId;
    this.delegate.onSelectDetectionRegion(detectionRegionId);
  };

  uninstall(canvas: HTMLCanvasElement) {
    if (this.delegate) {
      if (this.selectedFieldId) {
        this.delegate.onSelectField(undefined);
      }

      if (this.selectedAnchorId) {
        this.delegate.onSelectAnchor(undefined);
      }

      if (this.selectedDetectionRegionId) {
        this.delegate.onSelectDetectionRegion(undefined);
      }
    }

    super.uninstall(canvas);
  }

  handleMouseDown = (e: MouseEvent) => {
    if (!this.store) return;
    const point = this.getCoord(e);
    this.lastMouseDownCoord = point;
    if (this.handleDetectionRegionSelection(this.store, point)) {
      e.preventDefault();
      return;
    }
    if (this.handleFieldSelection(this.store, point)) {
      e.preventDefault();
      return;
    }
    if (this.handleAnchorSelection(this.store, point)) {
      e.preventDefault();
      return;
    }
    if (this.selectedFieldId) {
      this.onSelectField(undefined);
    }

    if (this.selectedAnchorId) {
      this.onSelectAnchor(undefined);
    }

    if (this.selectedDetectionRegionId) {
      this.onSelectDetectionRegion(undefined);
    }
  };

  private handleAnchorSelection = (
    store: CanvasStore,
    cursorCoord: Vertex
  ): boolean => {
    for (const anchor of store.anchors) {
      if (anchor.id === this.selectedAnchorId) {
        for (let i = 0; i < anchor.vertices.length; i++) {
          if (
            this.isVertexNearPoint(
              anchor.vertices[i],
              cursorCoord,
              CONTROL_POINT_SIZE
            )
          ) {
            this.draggingAnchorVertexIndex = i;
            return true;
          }
        }
      }

      if (this.isVertexInPolygon(cursorCoord, anchor.vertices)) {
        if (this.selectedAnchorId !== anchor.id) {
          this.onSelectAnchor(anchor.id);
        }
        this.draggingAnchorVertices = anchor.vertices.map(x => ({ ...x }));
        return true;
      }
    }

    return false;
  };

  private handleFieldSelection = (
    store: CanvasStore,
    cursorCoord: Vertex
  ): boolean => {
    for (const field of store.fields) {
      if (field.id === this.selectedFieldId) {
        const bottomRight = {
          x: field.bbox.right,
          y: field.bbox.bottom,
        };
        if (
          this.isVertexNearPoint(bottomRight, cursorCoord, CONTROL_POINT_SIZE)
        ) {
          this.draggingFieldVertex = bottomRight;
          return true;
        }
      }

      if (this.isVertexInBbox(cursorCoord, field.bbox)) {
        if (this.selectedFieldId !== field.id) {
          this.onSelectField(field.id);
        }
        this.draggingFieldOriginalBbox = { ...field.bbox };
        return true;
      }
    }
    return false;
  };

  private handleDetectionRegionSelection = (
    store: CanvasStore,
    cursorCoord: Vertex
  ): boolean => {
    for (const detectionRegion of store.detectionRegions) {
      if (detectionRegion.id === this.selectedDetectionRegionId) {
        const bottomRight = {
          x: detectionRegion.bbox.right,
          y: detectionRegion.bbox.bottom,
        };
        if (
          this.isVertexNearPoint(bottomRight, cursorCoord, CONTROL_POINT_SIZE)
        ) {
          this.draggingDetectionRegionVertex = bottomRight;
          return true;
        }
      }

      if (this.isVertexInBbox(cursorCoord, detectionRegion.bbox)) {
        if (this.selectedDetectionRegionId !== detectionRegion.id) {
          this.onSelectDetectionRegion(detectionRegion.id);
        }
        this.draggingDetectionRegionOriginalBbox = { ...detectionRegion.bbox };
        return true;
      }
    }
    return false;
  };

  handleMouseUp = (_e: MouseEvent) => {
    this.handleReleasingAnchorVertex();
    this.handleReleasingAnchor();
    this.handleReleasingField();
    this.handleReleasingFieldVertex();
    this.handleReleasingDetectionRegion();
    this.handleReleasingDetectionRegionVertex();
  };

  private handleReleasingAnchorVertex = () => {
    if (this.draggingAnchorVertexIndex !== undefined) {
      this.draggingAnchorVertexIndex = undefined;
    }
  };

  private handleReleasingAnchor = () => {
    if (this.draggingAnchorVertices) {
      this.draggingAnchorVertices = undefined;
    }
  };

  private handleReleasingField = () => {
    if (this.draggingFieldOriginalBbox) {
      this.draggingFieldOriginalBbox = undefined;
    }
  };

  private handleReleasingFieldVertex = () => {
    if (this.draggingFieldVertex) {
      this.draggingFieldVertex = undefined;
    }
  };

  private handleReleasingDetectionRegion = () => {
    if (this.draggingDetectionRegionOriginalBbox) {
      this.draggingDetectionRegionOriginalBbox = undefined;
    }
  };

  private handleReleasingDetectionRegionVertex = () => {
    if (this.draggingDetectionRegionVertex) {
      this.draggingDetectionRegionVertex = undefined;
    }
  };

  handleMouseMove = (e: MouseEvent) => {
    if (!this.store || !this.lastMouseDownCoord || !this.movementEnabled)
      return;
    const cursorCoord = this.getCoord(e);
    const delta = {
      x: cursorCoord.x - this.lastMouseDownCoord.x,
      y: cursorCoord.y - this.lastMouseDownCoord.y,
    };
    this.handleDraggingAnchor(this.store, delta);
    this.handleDraggingAnchorVertex(this.store, cursorCoord);
    this.handleDraggingField(this.store, delta);
    this.handleDraggingFieldVertex(this.store, cursorCoord);
    this.handleDraggingDetectionRegion(this.store, delta);
    this.handleDraggingDetectionRegionVertex(this.store, cursorCoord);
  };

  private handleDraggingAnchorVertex = (
    store: CanvasStore,
    cursorCoord: Vertex
  ) => {
    if (this.draggingAnchorVertexIndex !== undefined) {
      if (this.selectedAnchorId) {
        const anchor = store.getAnchor(this.selectedAnchorId);
        anchor.vertices[this.draggingAnchorVertexIndex].x = cursorCoord.x;
        anchor.vertices[this.draggingAnchorVertexIndex].y = cursorCoord.y;

        store.upsertAnchor(anchor);
      }
    }
  };

  private handleDraggingAnchor = (store: CanvasStore, delta: Vertex) => {
    if (this.draggingAnchorVertices && this.selectedAnchorId) {
      const anchor = store.getAnchor(this.selectedAnchorId);
      anchor.vertices = this.draggingAnchorVertices.map(v => ({
        x: v.x + delta.x,
        y: v.y + delta.y,
      }));
      store.upsertAnchor(anchor);
    }
  };

  private handleDraggingField = (store: CanvasStore, delta: Vertex) => {
    if (this.draggingFieldOriginalBbox && this.selectedFieldId) {
      const field = store.getField(this.selectedFieldId);
      field.bbox = {
        top: this.draggingFieldOriginalBbox.top + delta.y,
        left: this.draggingFieldOriginalBbox.left + delta.x,
        bottom: this.draggingFieldOriginalBbox.bottom + delta.y,
        right: this.draggingFieldOriginalBbox.right + delta.x,
      };

      store.upsertField(field);
    }
  };

  private handleDraggingFieldVertex = (
    store: CanvasStore,
    cursorCoord: Vertex
  ) => {
    if (this.draggingFieldVertex && this.selectedFieldId) {
      const field = store.getField(this.selectedFieldId);
      const minSize = 16;

      field.bbox = {
        ...field.bbox,
        right:
          field.bbox.left + minSize < cursorCoord.x
            ? cursorCoord.x
            : field.bbox.left + minSize,
        bottom:
          field.bbox.top + minSize < cursorCoord.y
            ? cursorCoord.y
            : field.bbox.top + minSize,
      };
      store.upsertField(field);
    }
  };

  private handleDraggingDetectionRegion = (
    store: CanvasStore,
    delta: Vertex
  ) => {
    if (
      this.draggingDetectionRegionOriginalBbox &&
      this.selectedDetectionRegionId
    ) {
      const detectionRegion = store.getDetectionRegion(
        this.selectedDetectionRegionId
      );
      detectionRegion.bbox = {
        top: this.draggingDetectionRegionOriginalBbox.top + delta.y,
        left: this.draggingDetectionRegionOriginalBbox.left + delta.x,
        bottom: this.draggingDetectionRegionOriginalBbox.bottom + delta.y,
        right: this.draggingDetectionRegionOriginalBbox.right + delta.x,
      };

      store.upsertDetectionRegion(detectionRegion);
    }
  };

  private handleDraggingDetectionRegionVertex = (
    store: CanvasStore,
    cursorCoord: Vertex
  ) => {
    if (this.draggingDetectionRegionVertex && this.selectedDetectionRegionId) {
      const detectionRegion = store.getDetectionRegion(
        this.selectedDetectionRegionId
      );
      const minSize = 16;

      detectionRegion.bbox = {
        ...detectionRegion.bbox,
        right:
          detectionRegion.bbox.left + minSize < cursorCoord.x
            ? cursorCoord.x
            : detectionRegion.bbox.left + minSize,
        bottom:
          detectionRegion.bbox.top + minSize < cursorCoord.y
            ? cursorCoord.y
            : detectionRegion.bbox.top + minSize,
      };
      store.upsertDetectionRegion(detectionRegion);
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    if (!this.store || !this.movementEnabled) return;
    if (e.target && e.target !== document.body) return;
    switch (e.code) {
      case "Backspace":
      case "Delete":
        this.handleDeleteAnchor(this.store);
        this.handleDeleteField(this.store);
        this.handleDeleteDetectionRegion(this.store);
        e.preventDefault();
        break;

      case "ArrowUp":
        this.handleMovingAnchor(this.store, 0, -1);
        this.handleMovingField(this.store, 0, -1);
        this.handleMovingDetectionRegion(this.store, 0, -1);
        break;
      case "ArrowDown":
        this.handleMovingAnchor(this.store, 0, 1);
        this.handleMovingField(this.store, 0, 1);
        this.handleMovingDetectionRegion(this.store, 0, 1);
        break;
      case "ArrowLeft":
        this.handleMovingAnchor(this.store, -1, 0);
        this.handleMovingField(this.store, -1, 0);
        this.handleMovingDetectionRegion(this.store, -1, 0);

        break;
      case "ArrowRight":
        this.handleMovingAnchor(this.store, 1, 0);
        this.handleMovingField(this.store, 1, 0);
        this.handleMovingDetectionRegion(this.store, 1, 0);

        break;

      case "Escape":
        this.handleDeselect();
        break;
    }

    if (
      ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) >=
        0 &&
      (this.selectedFieldId ||
        this.selectedAnchorId ||
        this.selectedDetectionRegionId)
    ) {
      e.preventDefault();
    }
  };

  private handleDeleteAnchor = (store: CanvasStore) => {
    if (this.selectedAnchorId) {
      const anchorId = this.selectedAnchorId;
      this.selectedAnchorId = undefined;
      this.onSelectAnchor(undefined);
      store.deleteAnchor(anchorId);
    }
  };

  private handleDeleteField = (store: CanvasStore) => {
    if (this.selectedFieldId) {
      const fieldId = this.selectedFieldId;
      this.selectedFieldId = undefined;
      this.onSelectField(undefined);
      store.deleteField(fieldId);
    }
  };

  private handleDeleteDetectionRegion = (store: CanvasStore) => {
    if (this.selectedDetectionRegionId) {
      const detectionRegionId = this.selectedDetectionRegionId;
      this.selectedDetectionRegionId = undefined;
      this.onSelectDetectionRegion(undefined);
      store.deleteDetectionRegion(detectionRegionId);
    }
  };

  private handleMovingAnchor = (
    store: CanvasStore,
    deltaX: number,
    deltaY: number
  ) => {
    if (this.selectedAnchorId && this.ref) {
      const anchor = store.getAnchor(this.selectedAnchorId);
      const bbox = this.bboxOfPolygon(anchor.vertices);

      if (!this.willBboxMoveOutsize(bbox, deltaX, deltaY, this.bound)) {
        anchor.vertices = anchor.vertices.map(v => ({
          x: v.x + deltaX,
          y: v.y + deltaY,
        }));
        store.upsertAnchor(anchor);
      }
    }
  };

  private handleMovingField = (
    store: CanvasStore,
    deltaX: number,
    deltaY: number
  ) => {
    if (this.selectedFieldId && this.ref) {
      const field = store.getField(this.selectedFieldId);

      if (!this.willBboxMoveOutsize(field.bbox, deltaX, deltaY, this.bound)) {
        field.bbox = {
          top: field.bbox.top + deltaY,
          left: field.bbox.left + deltaX,
          bottom: field.bbox.bottom + deltaY,
          right: field.bbox.right + deltaX,
        };
        store.upsertField(field);
      }
    }
  };

  private handleMovingDetectionRegion = (
    store: CanvasStore,
    deltaX: number,
    deltaY: number
  ) => {
    if (this.selectedDetectionRegionId && this.ref) {
      const detectionRegion = store.getDetectionRegion(
        this.selectedDetectionRegionId
      );

      if (
        !this.willBboxMoveOutsize(
          detectionRegion.bbox,
          deltaX,
          deltaY,
          this.bound
        )
      ) {
        detectionRegion.bbox = {
          top: detectionRegion.bbox.top + deltaY,
          left: detectionRegion.bbox.left + deltaX,
          bottom: detectionRegion.bbox.bottom + deltaY,
          right: detectionRegion.bbox.right + deltaX,
        };
        store.upsertDetectionRegion(detectionRegion);
      }
    }
  };

  private willBboxMoveOutsize = (
    bbox: BoundingBox,
    deltaX: number,
    deltaY: number,
    bound: Dimension
  ) => {
    return (
      bbox.left + deltaX < 0 ||
      bbox.top + deltaY < 0 ||
      bbox.right + deltaX > bound.width ||
      bbox.bottom + deltaY > bound.height
    );
  };

  private bboxOfPolygon = (vertices: Vertex[]): BoundingBox => {
    return vertices.reduce(
      (bbox: BoundingBox, v: Vertex): BoundingBox => {
        return {
          top: Math.min(bbox.top, v.y),
          left: Math.min(bbox.left, v.x),
          bottom: Math.max(bbox.bottom, v.y),
          right: Math.max(bbox.right, v.x),
        };
      },
      {
        top: Number.MAX_SAFE_INTEGER,
        left: Number.MAX_SAFE_INTEGER,
        right: Number.MIN_SAFE_INTEGER,
        bottom: Number.MIN_SAFE_INTEGER,
      }
    );
  };

  private handleDeselect = () => {
    if (this.selectedAnchorId) {
      this.selectedAnchorId = undefined;
      this.onSelectAnchor(undefined);
    }

    if (this.selectedFieldId) {
      this.selectedFieldId = undefined;
      this.onSelectField(undefined);
    }

    if (this.selectedDetectionRegionId) {
      this.selectedDetectionRegionId = undefined;
      this.onSelectDetectionRegion(undefined);
    }
  };
}
