import {
  ITextFieldProps,
  Label,
  TextField as MSTextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

import { useLocale } from "../../../contexts/locale";

export interface Props extends Omit<ITextFieldProps, "onRendeLabel" | "label"> {
  labelId: string;
  placeholderId?: string;
  errorMessageId?: string;
}

export default function TextField(props: Props) {
  const {
    required,
    labelId,
    disabled,
    placeholder,
    placeholderId,
    errorMessage,
    errorMessageId,
    ...otherProps
  } = props;
  const { localized } = useLocale();
  const onRenderLabel = useCallback(() => {
    return (
      <Label required={required} disabled={disabled}>
        <FormattedMessage id={labelId} />
      </Label>
    );
  }, [labelId, required, disabled]);

  return (
    <MSTextField
      {...otherProps}
      required={required}
      label=" " // dummy label for no duplicated asterisks
      onRenderLabel={onRenderLabel}
      disabled={disabled}
      placeholder={(placeholderId && localized(placeholderId)) || placeholder}
      errorMessage={
        (errorMessageId && localized(errorMessageId)) || errorMessage
      }
    />
  );
}
