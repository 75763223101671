.container {
  width: 166px;
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  border: 1px solid #edebe9;
  box-shadow: 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13),
    0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.1);
}

.text {
  flex: 1;
  color: #a19f9d;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  border-left: 1px solid #edebe9;
  border-right: 1px solid #edebe9;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
