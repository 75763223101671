import React from "react";

import { DetectionRegionInspectorTargetIds } from "../../constants/layout";
import { useDetectionRegionInspector } from "../../contexts/detectionRegionInspector";
import { useFormEditor } from "../../contexts/formEditor";
import { useDetectionRegionFieldTypeHierarchy } from "../../hooks/detection_region_field";
import {
  DetectionRegionField,
  DetectionRegionFieldExtra,
  DetectionRegionFieldParams,
} from "../../types/detectionRegion";
import FieldCard from "../FieldCard";
import styles from "./styles.module.scss";

interface DetectionRegionFieldIndexProps {
  fieldIndex: number;
}

interface DetectionRegionTrashButtonProps
  extends DetectionRegionFieldIndexProps {
  selectedDetectionRegionId: string;
}

interface Props extends DetectionRegionTrashButtonProps {
  field: DetectionRegionField;
}

const useDetectionRegionFieldCard = (props: Props) => {
  const { field, fieldIndex, selectedDetectionRegionId } = props;

  const {
    getDetectionRegionFieldTypeHierarchyKeyTexts,
    findDetectionRegionFieldTypeHierarchyRootKey,
    getSubKeysOfDetectionRegionFieldWithSetting,
    getSubTypeBySubKey,
  } = useDetectionRegionFieldTypeHierarchy();

  const settingActionButtonEnabled =
    getSubKeysOfDetectionRegionFieldWithSetting().includes(field.type);

  const detectionRegionFieldTypeOptions =
    getDetectionRegionFieldTypeHierarchyKeyTexts();

  const { detectDetectionRegionFieldLabelError } =
    useDetectionRegionInspector();

  const {
    onOpenScriptModal,
    onOpenDetectionRegionFieldKeywordModal,
    onOpenTextFieldModal,
    onOpenDateTimeFieldModal,
    onOpenMinimalFieldModal,
    onOpenLLMCompletionFieldModal,
  } = useFormEditor();

  const { updateDetectionRegionField } = useDetectionRegionInspector();

  const onSave = React.useCallback(
    (
      script: string,
      detectionRegionFieldParams?: DetectionRegionFieldParams,
      extras?: DetectionRegionFieldExtra
    ) => {
      const fieldParams: DetectionRegionFieldParams = {};
      if (detectionRegionFieldParams) {
        Object.assign(fieldParams, detectionRegionFieldParams);
      }

      fieldParams.code = script;

      updateDetectionRegionField(fieldIndex, {
        params: fieldParams,
        extras: extras,
      });
    },
    [updateDetectionRegionField, fieldIndex]
  );

  const openScriptEditor = onOpenScriptModal({
    field,
    index: fieldIndex,
    selectedDetectionRegionId,
    onSave,
  });

  const fieldType = getSubTypeBySubKey(field.type);
  const fieldRootType = findDetectionRegionFieldTypeHierarchyRootKey(
    field.type
  );

  const fieldRootTypeText = detectionRegionFieldTypeOptions.filter(item => {
    return item.key === fieldRootType;
  })[0].text;

  const onOpenTextInParagraphModal = React.useCallback(
    () =>
      onOpenDetectionRegionFieldKeywordModal({
        field,
        index: fieldIndex,
        selectedDetectionRegionId,
      })(),
    [
      onOpenDetectionRegionFieldKeywordModal,
      field,
      fieldIndex,
      selectedDetectionRegionId,
    ]
  );

  const openTextFieldModal = React.useCallback(
    () =>
      onOpenTextFieldModal({
        field,
        index: fieldIndex,
        selectedDetectionRegionId,
      })(),
    [field, fieldIndex, onOpenTextFieldModal, selectedDetectionRegionId]
  );

  const openDateTimeFieldModal = React.useCallback(
    () =>
      onOpenDateTimeFieldModal({
        field,
        index: fieldIndex,
        selectedDetectionRegionId,
      })(),
    [field, fieldIndex, onOpenDateTimeFieldModal, selectedDetectionRegionId]
  );

  const openMinimalFieldModal = React.useCallback(
    (titleId: string) =>
      onOpenMinimalFieldModal({
        field,
        index: fieldIndex,
        selectedDetectionRegionId,
        titleId,
      })(),
    [field, fieldIndex, onOpenMinimalFieldModal, selectedDetectionRegionId]
  );

  const openLLMCompletionModal = React.useCallback(
    () =>
      onOpenLLMCompletionFieldModal({
        field,
        index: fieldIndex,
        selectedDetectionRegionId,
      })(),
    [
      field,
      fieldIndex,
      onOpenLLMCompletionFieldModal,
      selectedDetectionRegionId,
    ]
  );

  const onSettingClicked = React.useCallback(() => {
    switch (fieldRootType) {
      case "script":
        openScriptEditor();
        break;
      case "text-in-paragraph":
        onOpenTextInParagraphModal();
        break;
      case "text":
        openTextFieldModal();
        break;
      case "date-time":
        openDateTimeFieldModal();
        break;
      case "llm-completion":
        openLLMCompletionModal();
        break;
      default:
        if (fieldType !== undefined) {
          openMinimalFieldModal(fieldType.label);
        }
        break;
    }
  }, [
    openScriptEditor,
    onOpenTextInParagraphModal,
    openTextFieldModal,
    openDateTimeFieldModal,
    openMinimalFieldModal,
    fieldRootType,
    fieldType,
    openLLMCompletionModal,
  ]);

  const fieldLabelError = detectDetectionRegionFieldLabelError(fieldIndex);

  return {
    settingActionButtonEnabled,
    onOpenTextInParagraphModal,
    detectionRegionFieldTypeOptions,
    onSettingClicked,
    fieldRootType,
    fieldRootTypeText,
    fieldLabelError,
  };
};

const DetectionRegionFieldCard = React.memo((props: Props) => {
  const { selectedDetectionRegionId, fieldIndex, field } = props;
  const { deleteDetectionRegionField, updateDetectionRegionField } =
    useDetectionRegionInspector();

  const {
    settingActionButtonEnabled,
    onSettingClicked,
    fieldRootTypeText,
    fieldLabelError,
  } = useDetectionRegionFieldCard(props);

  const onDetectionRegionFieldLabelChange = React.useCallback(
    (index: number) =>
      (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        event.preventDefault();
        event.stopPropagation();

        updateDetectionRegionField(index, {
          label: newValue ?? "",
        });
      },
    [updateDetectionRegionField]
  );

  const onDeleteClicked = React.useCallback(() => {
    deleteDetectionRegionField(selectedDetectionRegionId, fieldIndex);
  }, [deleteDetectionRegionField, fieldIndex, selectedDetectionRegionId]);

  return (
    <div
      key={fieldIndex}
      className={styles["detection-region-field"]}
      id={
        fieldIndex === 0
          ? DetectionRegionInspectorTargetIds.LabelExtractionField
          : undefined
      }
    >
      <FieldCard
        field1Id="form_inspector.label"
        field1Value={field.label}
        onField1ValueChange={onDetectionRegionFieldLabelChange(fieldIndex)}
        field1ErrorMessage={fieldLabelError}
        field2Label="form_inspector.detection_region_type"
        field2Key={fieldRootTypeText}
        settingEnabled={settingActionButtonEnabled}
        onSettingTrigger={onSettingClicked}
        onDeleteClick={onDeleteClicked}
      />
    </div>
  );
});

export default DetectionRegionFieldCard;
