import { Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { CustomModelLabelSchemaTypeDefintion } from "../../constants/customModelLabelSchema";
import { CustomModelLabelSchemaType } from "../../types/customModel";
import styles from "./styles.module.scss";

interface Props {
  name: string;
  type: CustomModelLabelSchemaType;
  format?: string;
}

const CustomModelEditorFrozenField = (props: Props) => {
  const { name, type, format } = props;

  if (CustomModelLabelSchemaTypeDefintion[type].hidden) {
    return null;
  }

  return (
    <div className={styles["frozen-field"]}>
      <Text variant="medium">{name}</Text>
      <Text className={styles["field-subtitle"]} variant="smallPlus">
        <FormattedMessage
          id={`custom_model_editor.right_bar.label.frozen_field.type.${type}`}
        />
        {format ? (
          <span className={styles["format"]}>
            <FormattedMessage
              id={`custom_model_editor.right_bar.label.frozen_field.type.${type}.${format}`}
            />
          </span>
        ) : null}
      </Text>
    </div>
  );
};

export default CustomModelEditorFrozenField;
