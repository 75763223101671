import { MessageBar, MessageBarType } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { PAYMENT_REQUIRED_TOAST_ID } from "../../constants";
import { useToast } from "../../hooks/toast";
import styles from "./styles.module.scss";

const PaymentRequiredMessageBar = React.memo(() => {
  const { removeToast } = useToast();
  React.useEffect(() => {
    removeToast(PAYMENT_REQUIRED_TOAST_ID);
  }, [removeToast]);

  return (
    <MessageBar
      className={styles["message-bar"]}
      messageBarType={MessageBarType.error}
    >
      <FormattedMessage id="payment.alert.box.reminder" />
    </MessageBar>
  );
});

export default PaymentRequiredMessageBar;
