import { PrimaryButton, TextField } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { Link } from "react-router-dom";

import { AppConfig } from "../../config";
import { useLocale } from "../../contexts/locale";
import { useTextFieldChange } from "../../hooks/component";
import { focusTextField } from "../../utils/components";
import styles from "./styles.module.scss";

interface Props {
  username: string;
  usernameErrorMessageId?: string;
  onUsernameChange: (value: string) => void;

  email: string;
  emailErrorMessageId?: string;
  onEmailChange: (value: string) => void;

  password: string;
  passwordErrorMessageId?: string;
  onPasswordChange: (value: string) => void;

  retypePassword: string;
  retypePasswordErrorMessageId?: string;
  onRetypePasswordChange: (value: string) => void;

  onSubmit: () => void;
}

function _SignUpForm(props: Props) {
  const { localized } = useLocale();

  const {
    username,
    email,
    password,
    retypePassword,
    usernameErrorMessageId,
    emailErrorMessageId,
    passwordErrorMessageId,
    retypePasswordErrorMessageId,
    onUsernameChange,
    onEmailChange,
    onPasswordChange,
    onRetypePasswordChange,
    onSubmit,
  } = props;

  const onFormSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onSubmit();
    },
    [onSubmit]
  );

  const onUsernameFieldChange = useTextFieldChange(onUsernameChange);
  const onEmailFieldChange = useTextFieldChange(onEmailChange);
  const onPasswordFieldChange = useTextFieldChange(onPasswordChange);
  const onRetypePasswordFieldChange = useTextFieldChange(
    onRetypePasswordChange
  );

  return (
    <form className={styles["signup-form"]} onSubmit={onFormSubmit}>
      <div className={styles["logo"]}>
        <img src="/logo-color.svg" alt="" />
      </div>
      <TextField
        label={localized("common.username")}
        placeholder={localized("common.username.placeholder")}
        value={username}
        errorMessage={
          usernameErrorMessageId && localized(usernameErrorMessageId)
        }
        onChange={onUsernameFieldChange}
        tabIndex={1}
        componentRef={focusTextField}
      />
      <TextField
        type="email"
        iconProps={{ iconName: "Mail" }}
        label={localized("common.email")}
        placeholder={localized("common.email.placeholder")}
        value={email}
        errorMessage={emailErrorMessageId && localized(emailErrorMessageId)}
        onChange={onEmailFieldChange}
        tabIndex={2}
      />
      <TextField
        type="password"
        iconProps={{ iconName: "PasswordField" }}
        label={localized("common.password")}
        placeholder={localized("common.password.placeholder")}
        value={password}
        errorMessage={
          passwordErrorMessageId &&
          localized(passwordErrorMessageId, {
            passwordMinLength: AppConfig.auth.passwordMinLength,
          })
        }
        onChange={onPasswordFieldChange}
        tabIndex={3}
      />
      <TextField
        type="password"
        iconProps={{ iconName: "PasswordField" }}
        label={localized("common.retype_password")}
        placeholder={localized("common.retype_password.placeholder")}
        value={retypePassword}
        errorMessage={
          retypePasswordErrorMessageId &&
          localized(retypePasswordErrorMessageId)
        }
        onChange={onRetypePasswordFieldChange}
        tabIndex={4}
      />
      <div className={styles["link"]}>
        <Link to="/" tabIndex={-1}>
          <FormattedMessage id="signup.have_account" />
        </Link>
      </div>
      <div className={styles["button"]}>
        <PrimaryButton
          tabIndex={5}
          type="submit"
          text={localized("signup.name")}
        />
      </div>
    </form>
  );
}

export const SignUpForm = React.memo(_SignUpForm);
export default SignUpForm;
