.section {
  margin-left: 20px;
  margin-top: 18px;
  margin-bottom: 18px;
}

.form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.form > * {
  margin-right: 12px;
}

.headMessage {
  margin-bottom: 21px;
}

.head {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: block;
}

.message {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 18px;
  display: block;
}

.dropdown {
  width: 200px;
}
