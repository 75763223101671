@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.report {
  overflow-y: auto;
  background-color: #fff;
  padding: 32px;
  margin-bottom: 48px;

  .tab-bar {
    margin-left: -10px;
    margin-bottom: 24px;
  }

  .section-title {
    margin-bottom: 10px;
    margin-top: 25px;
  }
}
