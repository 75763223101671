import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { AppConfig } from "../../../config";
import { useTeamRefsByRegion } from "../../../hooks/teamRefs";
import { generateTeamLookupId } from "../../../types/team";
import { CreateRenameTeamForm } from "../../CreateTeamModal";
import { OnboardingConfirmModal } from "../../OnboardingConfirmModal";

type Props = {
  onConfirmTeamCreation: (
    name: string,
    region: string,
    lookupId: string
  ) => Promise<void>;
  defaultTeamName: string;
  isUserHasTeamInOtherRegions: boolean;
  isTeamRefsByRegionFetchedOnce: boolean;
  isNewUser: boolean | null;
  teamRefsByRegion: ReturnType<typeof useTeamRefsByRegion>["teamRefsByRegion"];
};

function useOnboardingConfirmModal() {
  const [hasDismissed, sethasHasDismissed] = React.useState(false);
  const onCancel = React.useCallback(() => {
    sethasHasDismissed(true);
  }, []);

  return {
    hasDismissed,
    onCancel,
  };
}

export function useTeamCreationPageHandle(props: Props) {
  const {
    onConfirmTeamCreation,
    defaultTeamName,
    isUserHasTeamInOtherRegions,
    isTeamRefsByRegionFetchedOnce,
    isNewUser,
    teamRefsByRegion,
  } = props;
  const { hasDismissed, onCancel } = useOnboardingConfirmModal();

  return React.useMemo(
    () => ({
      hasDismissed,
      onCancel,
      isUserHasTeamInOtherRegions,
      onConfirmTeamCreation,
      defaultTeamName,
      isTeamRefsByRegionFetchedOnce,
      isNewUser,
      teamRefsByRegion,
    }),
    [
      hasDismissed,
      onCancel,
      isUserHasTeamInOtherRegions,
      onConfirmTeamCreation,
      defaultTeamName,
      isTeamRefsByRegionFetchedOnce,
      isNewUser,
      teamRefsByRegion,
    ]
  );
}

export function TeamCreationPage(
  props: ReturnType<typeof useTeamCreationPageHandle>
) {
  const {
    onConfirmTeamCreation,
    defaultTeamName,
    hasDismissed,
    onCancel,
    teamRefsByRegion,
    isUserHasTeamInOtherRegions,
    isTeamRefsByRegionFetchedOnce,
    isNewUser,
  } = props;

  const { region: currentRegion } = AppConfig;

  React.useEffect(() => {
    if (!isTeamRefsByRegionFetchedOnce) {
      return;
    }
    if (isNewUser) {
      const teamLookupId = generateTeamLookupId(defaultTeamName);
      onConfirmTeamCreation(defaultTeamName, currentRegion, teamLookupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTeamRefsByRegionFetchedOnce, isNewUser]);

  return (
    <div className="flex items-center justify-center h-full w-full">
      {isTeamRefsByRegionFetchedOnce && !isNewUser && (
        <div className="max-w-[720px] card p-[40px]">
          <h3 className="pb-[10px] font-semibold">
            <FormattedMessage id="onboarding.welcome_message" />
          </h3>
          <CreateRenameTeamForm
            isRename={false}
            defaultName={defaultTeamName}
            onConfirm={onConfirmTeamCreation}
          />
          <OnboardingConfirmModal
            isOpen={isUserHasTeamInOtherRegions && !hasDismissed}
            onCancel={onCancel}
            teamRefs={teamRefsByRegion}
          />
        </div>
      )}
    </div>
  );
}
