import {
  WORKSPACE_EXPORT_CONCURRENCY,
  WORKSPACE_EXPORT_COOL_DOWN_MS,
  WORKSPACE_UPLOAD_CONCURRENCY,
  WORKSPACE_UPLOAD_COOL_DOWN_MS,
} from "../constants/workspace";
import errors, { FOCRError } from "../errors";

export class TaskRunner {
  private currIdx: number;
  private queues: Promise<void>[];
  private beforeTask: () => Promise<void>;
  private afterTask: (error?: any) => Promise<void>;
  private _taskCount: number = 0;
  public get taskCount() {
    return this._taskCount;
  }
  constructor(
    concurrency: number,
    beforeTask: () => Promise<void> = () => Promise.resolve(),
    afterTask: (error?: any) => Promise<void> = () => Promise.resolve()
  ) {
    this.queues = [];
    for (let i = 0; i < concurrency; i++) {
      this.queues.push(Promise.resolve());
    }
    this.currIdx = 0;
    (this.beforeTask = beforeTask), (this.afterTask = afterTask);
  }

  enqueue<T>(task: () => Promise<T>) {
    return new Promise<T>((resolve, reject) => {
      this._taskCount += 1;
      this.queues[this.currIdx] = this.queues[this.currIdx]
        .then(() => this.beforeTask?.())
        .catch(() => this.beforeTask?.())
        .then(() =>
          task()
            .then(resolve)
            .catch(e => {
              reject(e);
              throw e;
            })
        )
        .catch(() =>
          task()
            .then(resolve)
            .catch(e => {
              reject(e);
              throw e;
            })
        )
        .then(() => this.afterTask?.())
        .catch(e => this.afterTask?.(e))
        .then(() => {
          this._taskCount -= 1;
        })
        .catch(() => {
          this._taskCount -= 1;
        });
      this.currIdx = (this.currIdx + 1) % this.queues.length;
    });
  }

  private static WorkspaceUploadRunner: TaskRunner;
  static getWorkspaceUploadRunner() {
    if (!this.WorkspaceUploadRunner) {
      this.WorkspaceUploadRunner = new TaskRunner(
        WORKSPACE_UPLOAD_CONCURRENCY,
        undefined,
        (error?: any) => {
          if (
            error != null &&
            ((error instanceof Error && error.name === "AbortError") ||
              (error instanceof FOCRError && error === errors.CancelledByUser))
          ) {
            // do not apply cool down if task was cancelled
            return Promise.resolve();
          }
          return new Promise((res, _) => {
            setTimeout(res, WORKSPACE_UPLOAD_COOL_DOWN_MS);
          });
        }
      );
    }
    return this.WorkspaceUploadRunner;
  }

  private static WorkspaceExportRunner: TaskRunner;
  static getWorkspaceExportRunner() {
    if (!this.WorkspaceExportRunner) {
      this.WorkspaceExportRunner = new TaskRunner(
        WORKSPACE_EXPORT_CONCURRENCY,
        undefined,
        () => {
          return new Promise((res, _) => {
            setTimeout(res, WORKSPACE_EXPORT_COOL_DOWN_MS);
          });
        }
      );
    }
    return this.WorkspaceExportRunner;
  }
}
