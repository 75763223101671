import { createReducer } from "@reduxjs/toolkit";

import * as confirmModalAction from "../actions/confirmModal";
import { ConfirmationTask } from "../types/confirmation";

export interface ConfirmModalState {
  readonly isShowingConfirmModal: boolean;
  readonly pendingConfirmationTask?: ConfirmationTask;
  readonly comfirmModalCleanUpTimerId?: number;
}

export const defaultState = {
  isShowingConfirmModal: false,
};

export const confirmModalReducer = createReducer<ConfirmModalState>(
  defaultState,
  builder => {
    builder
      .addCase(confirmModalAction.ShowConfirmModal, (state, action) => {
        state.isShowingConfirmModal = true;
        state.pendingConfirmationTask = action.payload.task;
      })
      .addCase(confirmModalAction.HideConfirmModal, (state, action) => {
        state.isShowingConfirmModal = false;
        state.comfirmModalCleanUpTimerId = action.payload.cleanUpTimerId;
      })
      .addCase(confirmModalAction.CleanUpConfirmModal, state => {
        state.pendingConfirmationTask = undefined;
      });
  }
);
