@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.anchor-group {
  margin-top: 10px;

  .option-checkbox {
    padding-top: 10px;
  }
}

.description {
  padding-bottom: 10px;
  white-space: pre-wrap;
}

.water-mark-setting {
  margin-top: 30px;
}
