import React from "react";

import { useWorkspaceActionCreator } from "../actions/workspace";
import { Workspace } from "../types/workspace";
import { useAppSelector } from "./redux";

type CurrentWorkspaceState =
  | { state: "error"; error: any }
  | { state: "success"; data: Workspace }
  | { state: "loading" };

export function useWorkspace(workspaceId: string): CurrentWorkspaceState {
  const { getWorkspace } = useWorkspaceActionCreator();
  const workspace = useAppSelector(state => state.workspace.currentWorkspace);
  const [error, setError] = React.useState();

  React.useEffect(() => {
    if (workspace == null || workspace.id !== workspaceId) {
      getWorkspace({ workspaceId }).catch(e => {
        setError(e);
      });
    }
  }, [getWorkspace, workspace, workspaceId]);

  return React.useMemo(() => {
    if (error != null) {
      return {
        state: "error",
        error,
      };
    }
    if (workspace != null && workspace.id === workspaceId) {
      return {
        state: "success",
        data: workspace,
      };
    }
    return {
      state: "loading",
    };
  }, [error, workspace, workspaceId]);
}

export function useCommonWorkspaceContainerState(workspaceId: string) {
  const workspace = useWorkspace(workspaceId);
  return React.useMemo(
    () => ({
      workspace,
    }),
    [workspace]
  );
}
