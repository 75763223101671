@import "../../styles/variables.scss";

.preview-image {
  display: flex;
  background: #faf9f8;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 500px;

  .toggle-button-holder {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.type-column {
  min-width: 120px;
  width: 120px;
}

.value-column {
  min-width: 120px;
  width: 120px;
}

.confidence-column {
  min-width: 150px;
  width: 150px;
}

.bboxes-column {
  min-width: 190px;
  width: 190px;
}
