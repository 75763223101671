.cursor-pointer {
  cursor: pointer;
}

.formatter-step-editor-section {
  margin: 16px 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
  }

  .section-header-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #323130;
  }

  .section-header-expand-button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    :global(.ms-Icon) {
      height: 16px;
      width: 16px;
    }

    > svg {
      color: #212121;
      height: 16px;
      width: 16px;
    }
  }

  .section-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #a19f9d;
    padding-top: 4px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    a {
      color: #25d0b1;
    }

    a:visited {
      color: #25d0b1;
    }
  }

  .section-content {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
  }

  .section-footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
  }
}

.formatter-step-editor-section-error {
  .section-header-text {
    color: #a4262c;
  }
}

.formatter-field-selector {
  .error {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #a4262c;
    margin-top: 8px;
  }

  :global(.ms-Dropdown-title) {
    height: auto;
    padding-left: 0px;
  }

  :global(.ms-Dropdown-titleIsPlaceHolder) {
    padding-left: 8px;
  }
}

.formatter-condition-matcher {
  .condition-matcher-desc {
    margin-top: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #a19f9d;
  }

  :global(.ms-Dropdown-container) {
    margin-top: 14px;
  }

  :global(.ms-TextField) {
    margin-top: 14px;
  }
}

.formatter-output-settings {
  .output-choice-group {
    margin-bottom: 8px;
    :global(.ms-ChoiceFieldGroup-flexContainer) {
      justify-content: space-between;
      display: flex;
    }

    :global(.ms-ChoiceField) {
      display: inline-block;
      border: 1px solid #d2d0ce;
      border-radius: 5px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 10px;
      width: 228px;
    }
  }

  .new-field {
    margin-top: 18px;
    margin-bottom: 18px;
  }

  .output-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #a19f9d;
  }
}

.formatter-step-editor {
  width: 550px;
  height: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  background: #fff;
  display: flex;
  flex-direction: column;

  .editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px 20px;
    border-bottom: 1px solid #edebe9;
  }

  .editor-header-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #323130;
  }

  .sections {
    overflow-y: scroll;
  }
}

.formatter-dropdown-field-list {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 16px 10px;
  border-radius: 0px 0px 3px 2px;

  .error {
    color: #a4262c;
    .try-again {
      border: none;
      background-color: inherit;
      cursor: pointer;
      display: inline-block;
      color: #0078d4;
    }
  }

  .empty {
    color: #a19f9d;
  }
}

.formatter-selection-field-tag-container {
  display: flex;
  gap: 3px;
  padding: 2px;
  flex-wrap: wrap;
}
