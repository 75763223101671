import * as React from "react";

import DataNotSavedPrompt from "../components/DataNotSavedPrompt";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { FSLExtractionReviewer } from "../components/FSLExtractionReviewer";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import {
  FSLCustomModelEditorProvider,
  useFSLCustomModelEditor,
} from "../contexts/fslCustomModelEditor";
import {
  FSLInstantSampleEditorProvider,
  useFSLInstantSampleEditorContainer,
} from "../contexts/fslInstantSampleEditor";
import { useUnsafeParams } from "../hooks/params";
import { FSLNavBarLayout, FSLTab } from "./FSLNavBarLayout";
import HeaderContainer from "./Header";

type PathParam = {
  customModelId: string;
  customModelImageId: string;
};

function FSLInstantSampleEditorLayout() {
  const { isFSLModel, isFailedToFetchCustomModel } = useFSLCustomModelEditor();

  const {
    isLoading,
    isProcessing,
    customModelImageId,
    customModelImageError,
    isUploadingFSLImage,
    shouldShowUploadingSampleStatus,
    numberOfProcessedPage,
    totalPagesToUpload,
  } = useFSLInstantSampleEditorContainer();

  const shouldShowOriginalEditor = isFSLModel === false;

  return (
    <>
      <LoadingModal isOpen={isLoading || isProcessing} />
      <LoadingModal
        isOpen={shouldShowUploadingSampleStatus && isUploadingFSLImage}
        messageId="fsl_instant_model_editor.uploading_status"
        messageValues={{
          total: totalPagesToUpload,
          processed: numberOfProcessedPage + 1,
        }}
      />
      {isFailedToFetchCustomModel ||
      shouldShowOriginalEditor ||
      customModelImageError !== undefined ? (
        <Main hasTop={true}>
          <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
        </Main>
      ) : (
        <Main hasTop={true}>
          <FSLNavBarLayout selectedTab={FSLTab.InstantModel}>
            <FSLExtractionReviewer key={customModelImageId} />
          </FSLNavBarLayout>
        </Main>
      )}
      <DataNotSavedPrompt
        isDataChanged={isUploadingFSLImage}
        titleTextId="fsl_instant_model_editor.sample_uploading_prompt.title"
        messageTextId="fsl_instant_model_editor.sample_uploading_prompt.save_warning"
        backTextId="fsl_instant_model_editor.sample_uploading_prompt.go_back"
        continueTextId="fsl_instant_model_editor.sample_uploading_prompt.leave_page"
      />
    </>
  );
}

function _FSLInstantSampleEditorContainer() {
  const { customModelId, customModelImageId } = useUnsafeParams<PathParam>();

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <FSLCustomModelEditorProvider
        customModelId={customModelId}
        key={customModelId}
      >
        <FSLInstantSampleEditorProvider customModelImageId={customModelImageId}>
          <FSLInstantSampleEditorLayout />
        </FSLInstantSampleEditorProvider>
      </FSLCustomModelEditorProvider>
    </Layout>
  );
}

export const FSLInstantSampleEditorContainer = React.memo(
  _FSLInstantSampleEditorContainer
);
export default FSLInstantSampleEditorContainer;
