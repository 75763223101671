import classnames from "classnames";
import * as React from "react";

import { useLeftBar } from "../../hooks/component";
import styles from "./styles.module.scss";

interface Props {
  children: React.ReactNode;
}

const StickyButtonBox = React.memo(({ children }: Props) => {
  const { isCollapsed: isLeftBarCollapsed } = useLeftBar();

  return (
    <div
      className={classnames(styles["sticky-button-box"], {
        [styles["collapsed"]]: isLeftBarCollapsed,
      })}
    >
      {children}
    </div>
  );
});

export default StickyButtonBox;
