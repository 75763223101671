@import "../../styles/variables.scss";

.container {
  display: inline-flex;
  background-color: $neutral-lighter;
  border-radius: 2px;
  padding: 0px;
  align-items: center;
  color: $dark-color;
  line-height: 20px;
}

.container-error {
  background-color: #ffe7e7;
}

.icon-label-group {
  display: inline-flex;
  padding: 3px 8px;
  gap: 6px;
}

.icon {
  size: 12px;
  color: #0078d4;
}

.icon-error {
  color: $red-icon-color;
}

.label {
  color: $dark-color;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cancel-button {
  display: inline-flex;
  padding: 7px 5px;
  gap: 10px;
}

.cancel-button:focus,
.cancel-button:hover {
  background-color: inherit;
}
