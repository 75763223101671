import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useResourceOwnerActionCreator } from "../actions/resourceOwner";
import { useTeamActionCreator } from "../actions/team";
import { useUserActionCreator } from "../actions/user";
import ChangePasswordModal from "../components/ChangePasswordModal";
import CreateRenameTeamModal from "../components/CreateTeamModal";
import Header from "../components/Header";
import LoadingModal from "../components/LoadingModal";
import { AppConfig, RegionsConfig } from "../config";
import errors, { FOCRError } from "../errors";
import { useRedirectToRegionForTeam } from "../hooks/region";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import { DetailFormGroup } from "../types/formGroup";
import { BriefTeam } from "../types/team";

function useChangePasswordModal() {
  const { changePassword } = useUserActionCreator();
  const [isChangePasswordModalOpened, setIsChangePasswordModalOpened] =
    React.useState(false);
  const openChangePasswordModal = React.useCallback(() => {
    setIsChangePasswordModalOpened(true);
  }, []);
  const closeChangePasswordModal = React.useCallback(() => {
    setIsChangePasswordModalOpened(false);
  }, []);
  const onChangePassword = React.useCallback(
    async (oldPassword: string, newPassword: string) => {
      await changePassword(oldPassword, newPassword);
      closeChangePasswordModal();
    },
    [closeChangePasswordModal, changePassword]
  );

  return React.useMemo(
    () => ({
      isChangePasswordModalOpened,
      openChangePasswordModal,
      closeChangePasswordModal,
      onChangePassword,
    }),
    [
      isChangePasswordModalOpened,
      openChangePasswordModal,
      closeChangePasswordModal,
      onChangePassword,
    ]
  );
}

function useCreateTeamModal() {
  const navigate = useNavigate();
  const { createTeam } = useTeamActionCreator();

  const [isCreateTeamModalOpened, setIsCreateTeamModalOpened] =
    React.useState(false);
  const [isCreatingTeam, setIsCreatingTeam] = React.useState(false);
  const openCreateTeamModal = React.useCallback(() => {
    setIsCreateTeamModalOpened(true);
  }, []);
  const closeCreateTeamModal = React.useCallback(() => {
    setIsCreateTeamModalOpened(false);
  }, []);
  const toast = useToast();

  const { redirectToRegionForTeam } = useRedirectToRegionForTeam();

  const onCreateTeam = React.useCallback(
    async (name: string, region: string, preferredLookupId: string) => {
      closeCreateTeamModal();
      setIsCreatingTeam(true);
      const { region: currentRegion } = AppConfig;

      try {
        const { lookupId } = await createTeam(name, region, preferredLookupId);

        if (currentRegion === region) {
          navigate("/team");
        } else {
          redirectToRegionForTeam(region, lookupId, "/team");
        }
      } catch (e) {
        if (e instanceof FOCRError && e !== errors.UnknownError) {
          toast.error(e.messageId);
        } else {
          toast.error("error.create_team.failed");
        }
      } finally {
        setIsCreatingTeam(false);
      }
    },
    [closeCreateTeamModal, createTeam, navigate, toast, redirectToRegionForTeam]
  );

  return React.useMemo(
    () => ({
      isCreateTeamModalOpened,
      isCreatingTeam,
      openCreateTeamModal,
      closeCreateTeamModal,
      onCreateTeam,
    }),
    [
      isCreateTeamModalOpened,
      isCreatingTeam,
      openCreateTeamModal,
      closeCreateTeamModal,
      onCreateTeam,
    ]
  );
}

function useGetTeamRefsFromAllRegions() {
  const { listTeamRefs } = useTeamActionCreator();
  const endPointTeamRefs = useSelector(
    (state: RootState) => state.user.teamRefsByRegion
  );

  React.useEffect(() => {
    const regions = Object.keys(RegionsConfig.endpoints).filter(
      key => key !== AppConfig.region
    );
    if (regions.length === Object.keys(endPointTeamRefs).length) {
      return;
    }
    regions.forEach(region => {
      listTeamRefs(region).catch(() => {});
    });
  }, [endPointTeamRefs, listTeamRefs]);

  return React.useMemo(
    () => ({
      endPointTeamRefs,
    }),
    [endPointTeamRefs]
  );
}

export function useTeamSelection() {
  const { selectTeam } = useResourceOwnerActionCreator();

  const { pathname } = useLocation();

  const pathnameRef = React.useRef(pathname);
  pathnameRef.current = pathname;

  const navigate = useNavigate();

  const teams = useSelector<RootState, BriefTeam[]>(state =>
    state.user.currentUser !== undefined ? state.user.currentUser.teams : []
  );

  const currentFormGroup = useSelector<RootState, DetailFormGroup | undefined>(
    state => state.formGroup.currentFormGroup
  );
  const currentFormGroupRef = React.useRef(currentFormGroup);
  currentFormGroupRef.current = currentFormGroup;

  const replacePath = React.useCallback(() => {
    const isOnTemplate = pathnameRef.current.match(
      /^\/(?:form-group|form)-template\/?/
    );

    const isOnReceiptTemplate = pathnameRef.current.match(
      /^\/receipt-(?:edit|api|test|batch)\/?/
    );

    const isOnAdminPortal = pathnameRef.current.match(/^\/admin\/?/);

    const shouldRedirectToFormList =
      isOnTemplate || isOnReceiptTemplate || isOnAdminPortal;

    const targetPath = shouldRedirectToFormList
      ? "/form"
      : `/${pathnameRef.current.split("/")[1]}`;

    navigate(targetPath, { replace: true });
  }, [navigate]);

  const onChangeResourceOwner = React.useCallback(
    (id: string) => {
      selectTeam(id);
      replacePath();
    },
    [selectTeam, replacePath]
  );

  const { endPointTeamRefs } = useGetTeamRefsFromAllRegions();

  return React.useMemo(
    () => ({
      teams,
      onChangeResourceOwner,
      endPointTeamRefs,
    }),
    [teams, onChangeResourceOwner, endPointTeamRefs]
  );
}

function _HeaderContainer() {
  const {
    isChangePasswordModalOpened,
    openChangePasswordModal,
    closeChangePasswordModal,
    onChangePassword,
  } = useChangePasswordModal();

  const {
    isCreateTeamModalOpened,
    openCreateTeamModal,
    closeCreateTeamModal,
    onCreateTeam,
    isCreatingTeam,
  } = useCreateTeamModal();

  const { logout, openUserSetting } = useUserActionCreator();

  const navigate = useNavigate();
  const location = useLocation();

  const isOnAdminPortal = React.useMemo(() => {
    return location.pathname.includes("admin");
  }, [location.pathname]);

  const onToggleAdminPortal = React.useCallback(() => {
    if (isOnAdminPortal) {
      navigate("/");
    } else {
      navigate("/admin");
    }
  }, [isOnAdminPortal, navigate]);

  const onOpenUserSetting = React.useCallback(() => {
    openUserSetting().catch(e => {
      console.error(e);
    });
  }, [openUserSetting]);

  const displayName = useSelector<RootState, string>(
    state =>
      state.user.userInfo?.preferredUsername ||
      state.user.userInfo?.email?.replace(/@.+$/, "") ||
      state.user.currentUser?.username ||
      state.user.currentUser?.email.replace(/@.+$/, "") ||
      ""
  );

  const isAdmin = useSelector<RootState, boolean>(
    state => state.user.currentUser?.isAdmin === true
  );

  return (
    <div>
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpened}
        onCancel={closeChangePasswordModal}
        onChangePassword={onChangePassword}
      />
      <CreateRenameTeamModal
        isRename={false}
        isOpen={isCreateTeamModalOpened}
        onCancel={closeCreateTeamModal}
        onConfirm={onCreateTeam}
      />
      <LoadingModal isOpen={isCreatingTeam} messageId={"team.creating_team"} />
      <Header
        username={displayName}
        isAdmin={isAdmin}
        isOnAdminPortal={isOnAdminPortal}
        onLogout={logout}
        onChangePassword={openChangePasswordModal}
        onCreateTeam={openCreateTeamModal}
        onToggleAdminPortal={onToggleAdminPortal}
        onOpenUserSetting={onOpenUserSetting}
      />
    </div>
  );
}

export const HeaderContainer = React.memo(_HeaderContainer);
export default HeaderContainer;
