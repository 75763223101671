import * as yup from "yup";

export const pageInfoSchema = yup
  .object({
    hasNext: yup.boolean().required(),
    cursor: yup.string().defined(),
  })
  .camelCase();

export type PageInfo = yup.InferType<typeof pageInfoSchema>;

export const pageInfoWithOffsetSchema = yup
  .object({
    offset: yup.number().required(),
    totalCount: yup.number().required(),
  })
  .camelCase();

export type PageInfoWithOffset = yup.InferType<typeof pageInfoWithOffsetSchema>;
