import { useCallback, useEffect, useMemo } from "react";

import { useResourceOwnerActionCreator } from "../actions/resourceOwner";
import {
  PreferenceKey,
  getPreference,
  removePreference,
} from "../utils/preference";
import { redirectToReadmeIO } from "../utils/readmeIO";
import { useAppSelector } from "./redux";

export function useReadmeIO() {
  const { getReadmeIOAuthToken } = useResourceOwnerActionCreator();

  const onRedirectToReadmeIO = useCallback(
    async (
      redirectURI?: string,
      options?: {
        openInNewTab?: boolean;
        parameters?: {
          formId?: string;
          workspaceId?: string;
        };
      }
    ) => {
      const authToken = await getReadmeIOAuthToken(
        options?.parameters?.formId,
        options?.parameters?.workspaceId
      );
      redirectToReadmeIO(authToken, redirectURI, options?.openInNewTab);
    },
    [getReadmeIOAuthToken]
  );

  return useMemo(
    () => ({
      onRedirectToReadmeIO,
    }),
    [onRedirectToReadmeIO]
  );
}

export function useHandleReadmeIOLogin() {
  const { onRedirectToReadmeIO } = useReadmeIO();
  const isCurrentUserExist = useAppSelector(
    state => state.user.currentUser !== undefined
  );
  const readmeIORedirectURI = getPreference(PreferenceKey.readmeIORedirectURI);

  useEffect(() => {
    if (isCurrentUserExist && readmeIORedirectURI !== null) {
      removePreference(PreferenceKey.readmeIORedirectURI);
      onRedirectToReadmeIO(readmeIORedirectURI, { openInNewTab: false });
    }
  }, [isCurrentUserExist, readmeIORedirectURI, onRedirectToReadmeIO]);
}
