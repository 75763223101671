import { IBreadcrumbItem, Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import { useLocation, useNavigate } from "react-router";

import { useLocale } from "../../contexts/locale";
import { TeamDetail } from "../../types/team";
import AppBreadcrumb from "../AppBreadcrumb";
import TeamInfo from "../TeamInfo";
import styles from "./styles.module.scss";

interface Props {
  region: string;
  team: TeamDetail;
  currentTab: TeamDetailTab;
  onTabSelected: (tab: TeamDetailTab) => void;
}

export enum TeamDetailTab {
  usage = "usage",
  members = "members",
  resources = "resources",
  plan = "plan",
  features = "features",
}

const TAB_VALUES = Object.keys(TeamDetailTab);

function isOfTeamDetailTab(keyInput?: string): keyInput is TeamDetailTab {
  return keyInput !== undefined && TAB_VALUES.includes(keyInput);
}

function _TeamDetailBasicContent(props: Props) {
  const { region, team, currentTab, onTabSelected } = props;
  const { localized } = useLocale();
  const navigate = useNavigate();
  const location = useLocation();

  const onBreadcrumbItemClick = React.useCallback(
    (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
      if (ev === undefined || item === undefined || item.href === undefined) {
        return;
      }
      ev.preventDefault();
      navigate(item.href);
    },
    [navigate]
  );

  const breadcrumbItems: IBreadcrumbItem[] = React.useMemo(() => {
    return [
      {
        text: localized("team.list.title"),
        key: "teams",
        href: `/admin/team${location.search}`,
        onClick: onBreadcrumbItemClick,
      },
      {
        text: localized("team.detail.title"),
        key: "detail",
      },
    ];
  }, [localized, location.search, onBreadcrumbItemClick]);

  const onPivotItemClick = React.useCallback(
    (item?: PivotItem, e?: React.MouseEvent<HTMLElement>) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (item && isOfTeamDetailTab(item.props.itemKey)) {
        onTabSelected(item.props.itemKey);
      }
    },
    [onTabSelected]
  );

  return (
    <>
      <AppBreadcrumb className={styles["nav-bar"]} items={breadcrumbItems} />
      <TeamInfo
        region={region}
        name={team.name}
        contactEmail={team.contactEmail}
        lookupId={team.lookupId}
        createdAt={team.createdAt}
        totalUsage={team.totalUsage}
        lastUseAt={team.lastUseAt}
      />
      <div className={styles["tab-bar"]}>
        <Pivot onLinkClick={onPivotItemClick} selectedKey={currentTab}>
          {TAB_VALUES.map(tab => {
            return (
              <PivotItem
                key={tab}
                headerText={localized(`team.detail.${tab}`)}
                itemKey={tab}
              />
            );
          })}
        </Pivot>
      </div>
    </>
  );
}

export const TeamDetailBasicContent = React.memo(_TeamDetailBasicContent);
export default TeamDetailBasicContent;
