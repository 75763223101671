@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.request-logs-history {
  margin: 0 34px;
  margin-top: -4px;
  table {
    tr {
      td:not(:first-child) {
        font-size: 12px;
        color: #605e5c;
      }
    }
  }
  .paginator-wrapper {
    padding: 8px 6px;
  }
}

.updated_time {
  font-size: 14px;
  padding: 16px 4px;
}

.empty-state-container {
  padding: 16px 4px;
}
