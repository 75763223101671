import { useCallback, useMemo } from "react";

import { DetectMultiDocumentResponse, workerClient } from "../workerClient";

export function useDetectMultiDocumentActionCreator() {
  const detectMultiDocument = useCallback(
    async (
      token: string,
      image: File
    ): Promise<DetectMultiDocumentResponse> => {
      return await workerClient(token).detectMultiDocument(image);
    },
    []
  );

  return useMemo(() => ({ detectMultiDocument }), [detectMultiDocument]);
}
