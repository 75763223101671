import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { URLParamsKey } from "../../hooks/searchParamUtils";
import { RootState } from "../../redux/types";

interface Props {
  className?: string;
  to: string;
  children: React.ReactNode;
}

export function useUrlWithTeamRef(to: string) {
  const resourceOwnerId = useSelector((state: RootState) => {
    return state.resourceOwner.resourceOwnerId;
  });
  const user = useSelector((state: RootState) => state.user.currentUser);

  const lookupId = user?.teams.find(
    team => team.id === resourceOwnerId
  )?.lookupId;

  return lookupId ? `${to}?${URLParamsKey.team}=${lookupId}` : to;
}

export function LinkWithTeamRef(props: Props) {
  const resourceOwnerId = useSelector((state: RootState) => {
    return state.resourceOwner.resourceOwnerId;
  });

  const urlWithTeamRef = useUrlWithTeamRef(props.to);
  return (
    <Link className={props.className} to={urlWithTeamRef} key={resourceOwnerId}>
      {props.children}
    </Link>
  );
}
