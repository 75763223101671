import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { useCustomModelActionCreatorForCustomModelImageService } from "../actions/customModel";
import { useAppSelector } from "../hooks/redux";
import {
  isDeletingCustomModelimage,
  isUploadingCustomModelImage,
} from "../reducers/helpers/customModel";
import {
  CustomModelImageService,
  UploadImagesCallbacks,
} from "../services/customModelImage";
import { CustomModelImageExtraInfo } from "../types/customModel";
import { useLocale } from "./locale";

function useMakeContext(service: CustomModelImageService) {
  const serviceRef = useRef(service);

  const uploadImages = useCallback(
    (
      customModelId: string,
      files: File[],
      info?: CustomModelImageExtraInfo,
      callbacks?: UploadImagesCallbacks
    ) => {
      serviceRef.current.uploadImages(customModelId, files, info, callbacks);
    },
    []
  );

  const deleteImages = useCallback(
    (
      customModelId: string,
      customeModelImageIds: string[],
      shouldDeleteGroup?: boolean
    ) => {
      serviceRef.current.deleteImages(
        customModelId,
        customeModelImageIds,
        shouldDeleteGroup
      );
    },
    []
  );

  const cancelPendingOperations = useCallback(
    async () => await serviceRef.current.cancelPendingOperations(),
    []
  );

  return useMemo(
    () => ({ uploadImages, deleteImages, cancelPendingOperations }),
    [uploadImages, deleteImages, cancelPendingOperations]
  );
}

type CustomModelImageServiceContextValue = ReturnType<typeof useMakeContext>;
const CustomModelImageServiceContext =
  createContext<CustomModelImageServiceContextValue>(null as any);

interface Props {
  children: React.ReactNode;
  service: CustomModelImageService;
}

export function CustomModelImageServiceProvider(props: Props) {
  const { service } = props;
  const {
    notifyCustomModelImageUploaded,
    notifyCustomModelImageUploadFailed,
    notifyCustomModelImageDeleted,
    notifyCustomModelImageDeleteFailed,
    notifyAdditionalCutomModelImageAddedForUpload,
  } = useCustomModelActionCreatorForCustomModelImageService();

  const serviceRef = useRef(service);
  const value = useMakeContext(service);

  useEffect(() => {
    serviceRef.current.onImageUploaded(notifyCustomModelImageUploaded);
    serviceRef.current.onImageUploadFailed((customModelId, file, error) =>
      notifyCustomModelImageUploadFailed(customModelId, file.name, error)
    );
    serviceRef.current.onImageDeleted(notifyCustomModelImageDeleted);
    serviceRef.current.onImageDeleteFailed(notifyCustomModelImageDeleteFailed);
    serviceRef.current.onAdditionalImagedAdded(
      notifyAdditionalCutomModelImageAddedForUpload
    );
  }, [
    notifyCustomModelImageUploaded,
    notifyCustomModelImageUploadFailed,
    notifyCustomModelImageDeleted,
    notifyCustomModelImageDeleteFailed,
    notifyAdditionalCutomModelImageAddedForUpload,
  ]);

  return <CustomModelImageServiceContext.Provider {...props} value={value} />;
}

export function CustomModelImageServiceInUsePrompt() {
  const hasPendingOperations = useAppSelector(
    state =>
      isDeletingCustomModelimage(state.customModel) ||
      isUploadingCustomModelImage(state.customModel)
  );
  const hasPendingOperationsRef = useRef(hasPendingOperations);
  const { localized } = useLocale();
  hasPendingOperationsRef.current = hasPendingOperations;

  const promptMessage = useMemo(
    () => localized("app.custom_model_image_service_in_use"),
    [localized]
  );

  const handleBeforeUnload = useCallback(
    (event: BeforeUnloadEvent): void | string => {
      if (hasPendingOperationsRef.current) {
        event.returnValue = promptMessage;
        return promptMessage;
      }
    },
    [promptMessage]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  return null;
}

export function useCustomModelImageService() {
  return useContext(CustomModelImageServiceContext);
}
