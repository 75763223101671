import { Icon, Link } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { useDragAndDropFiles } from "../../hooks/drag_and_drop";
import { useTeamPermission } from "../../hooks/permission";
import { chooseFile } from "../../utils/file";
import styles from "./styles.module.scss";

function EmptyStateUI() {
  return (
    <div className={styles["empty-state"]}>
      <div className={styles["subtitle"]}>
        <FormattedMessage id={"custom_model_editor.upload_image.empty"} />
      </div>
    </div>
  );
}

interface Props {
  uploadCustomModelImages: (files: File[]) => void;
}

const InstructionsDos = [
  "custom_model_editor.upload_image.dos.capture_in_well_lit_env",
  "custom_model_editor.upload_image.dos.hold_steadily",
  "custom_model_editor.upload_image.dos.clear_text",
];

const InstructionsDonts = [
  "custom_model_editor.upload_image.donts.image_from_google",
  "custom_model_editor.upload_image.donts.fake_image",
  "custom_model_editor.upload_image.donts.blur_image",
];

function _CustomModelEditorUploadPane(props: Props) {
  const { uploadCustomModelImages } = props;
  const { hasPermissionToEditResource } = useTeamPermission();

  const onUploadClicked = useCallback(async () => {
    const files = await chooseFile(SUPPORTED_EXTRACT_MIME.join(","), true);
    if (files) {
      uploadCustomModelImages(files);
    }
  }, [uploadCustomModelImages]);

  const values: { [key in string]: any } = {
    link: (
      <Link onClick={onUploadClicked}>
        <FormattedMessage id="custom_model_editor.upload_image.center.subtitle.link" />
      </Link>
    ),
  };

  useDragAndDropFiles(
    useCallback(
      (files?: File[]) => {
        if (files) {
          uploadCustomModelImages(files);
        }
      },
      [uploadCustomModelImages]
    ),
    SUPPORTED_EXTRACT_MIME
  );

  return (
    <div className={styles["container"]}>
      {hasPermissionToEditResource ? (
        <div className={styles["content"]}>
          <div className={styles["header"]}>
            <div className={styles["title"]}>
              <FormattedMessage id="custom_model_editor.upload_image.desc.title" />
            </div>

            <div className={styles["subtitle"]}>
              <FormattedMessage id="custom_model_editor.upload_image.desc.subtitle" />
            </div>
          </div>
          <div className={styles["dotted-box"]}>
            <div className={styles["title"]}>
              <FormattedMessage id="custom_model_editor.upload_image.center.title" />
            </div>
            <div className={styles["subtitle"]}>
              <FormattedMessage
                id="custom_model_editor.upload_image.center.subtitle"
                values={values}
              />
            </div>
          </div>
          <div className={styles["instruction"]}>
            <div className={styles["dos"]}>
              <div className={styles["title"]}>
                <FormattedMessage id="custom_model_editor.upload_image.dos.title" />
              </div>
              <ul>
                {InstructionsDos.map((x, i) => (
                  <li key={`dos-${i}`}>
                    <Icon iconName="Accept" />
                    <FormattedMessage id={x} />
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles["donts"]}>
              <div className={styles["title"]}>
                <FormattedMessage id="custom_model_editor.upload_image.donts.title" />
              </div>
              <ul>
                {InstructionsDonts.map((x, i) => (
                  <li key={`donts-${i}`}>
                    <Icon iconName="ChromeClose" />
                    <FormattedMessage id={x} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <EmptyStateUI />
      )}
    </div>
  );
}

export const CustomModelEditorUploadPane = React.memo(
  _CustomModelEditorUploadPane
);
export default CustomModelEditorUploadPane;
