import * as React from "react";
import { useNavigate } from "react-router";

import { useWebhookActionCreator } from "../actions/webhook";
import ConfirmModal from "../components/ConfirmModal";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import WebhookEditor from "../components/WebhookEditor";
import errors from "../errors";
import { useUnsafeParams } from "../hooks/params";
import { useToast } from "../hooks/toast";
import { useCommonWebhookContainerState } from "../hooks/webhook";
import { ConfirmModalType } from "../types/confirmation";
import { WebhookCustomHeader } from "../types/webhook";
import HeaderContainer from "./Header";

type PathParam = {
  webhookId: string;
};

const WebhookEditContainer = React.memo(() => {
  const { updateWebhook, removeWebhook } = useWebhookActionCreator();
  const { webhookId } = useUnsafeParams<PathParam>();
  const containerState = useCommonWebhookContainerState(webhookId);
  const toast = useToast();
  const navigate = useNavigate();
  const [isUpdatingWebhook, setIsUpdatingWebhook] =
    React.useState<boolean>(false);
  const [isRemoveConfirmationModalOpened, setIsRemoveConfirmationModalOpened] =
    React.useState<boolean>(false);

  const doUpdateWebhook = React.useCallback(
    async (
      name: string,
      url: string,
      formIds: string[],
      formGroupIds: string[],
      customHeaders: WebhookCustomHeader[]
    ) => {
      if (
        containerState.state === "error" ||
        containerState.state === "loading"
      ) {
        return;
      }

      setIsUpdatingWebhook(true);
      try {
        await updateWebhook(
          containerState.webhook.id,
          name,
          url,
          formIds,
          formGroupIds,
          customHeaders,
          containerState.webhook.updatedAt
        );
        toast.success("webhook.edit.success");
        navigate("/webhook", { replace: true });
      } catch (e) {
        console.error("Failed to update webhook: ", e);
        toast.error("webhook.edit.fail");
      } finally {
        setIsUpdatingWebhook(false);
      }
    },
    [updateWebhook, toast, containerState, navigate]
  );

  const doRemoveWebhook = React.useCallback(async () => {
    setIsRemoveConfirmationModalOpened(false);
    if (
      containerState.state === "error" ||
      containerState.state === "loading"
    ) {
      return;
    }
    setIsUpdatingWebhook(true);
    try {
      await removeWebhook(containerState.webhook.id);
      toast.success("webhook.edit.remove.success");
      navigate("/webhook", { replace: true });
    } catch (e) {
      if (e !== errors.ConflictFound) {
        console.error("Failed to remove webhook: ", e);
        toast.error("webhook.edit.remove.fail");
      }
    } finally {
      setIsUpdatingWebhook(false);
    }
  }, [containerState, removeWebhook, toast, navigate]);

  const onRemoveClicked = React.useCallback(() => {
    setIsRemoveConfirmationModalOpened(true);
  }, []);

  const closeRemoveModal = React.useCallback(() => {
    setIsRemoveConfirmationModalOpened(false);
  }, []);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        {containerState.state === "error" && (
          <ErrorPlaceholder messageId="common.fail_to_fetch_webhook" />
        )}
        {containerState.state === "success" && (
          <>
            <WebhookEditor
              webhook={containerState.webhook}
              submit={doUpdateWebhook}
              onRemoveClicked={onRemoveClicked}
            />
            <LoadingModal
              isOpen={isUpdatingWebhook}
              messageId="webhook.edit.updating"
            />
            <ConfirmModal
              isOpen={isRemoveConfirmationModalOpened}
              modalType={ConfirmModalType.Destory}
              titleId="webhook.edit.remove.title"
              messageId="webhook.edit.remove.message"
              actionId="webhook.edit.remove.action"
              onCancel={closeRemoveModal}
              onConfirm={doRemoveWebhook}
            />
          </>
        )}
        {containerState.state === "loading" && (
          <LoadingModal isOpen={true} messageId="webhook.edit.fetching" />
        )}
      </Main>
    </Layout>
  );
});

export default WebhookEditContainer;
