import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { current } from "immer";

import * as customModelAction from "../actions/customModel";
import * as extractorAction from "../actions/extractor";
import * as formAction from "../actions/form";
import * as formGroupAction from "../actions/formGroup";
import * as resourceOwnerAction from "../actions/resourceOwner";
import * as teamAction from "../actions/team";
import * as userAction from "../actions/user";
import { FOCRError } from "../errors";
import {
  PaginatedAsyncResult,
  accessPaginatedAsyncResult,
} from "../types/asyncResult";
import {
  ExtractorFieldSchemaTable,
  ExtractorOption,
  PaginatedExtractor,
  accessPaginatedExtractor,
} from "../types/extractor";

export interface ExtractorState {
  readonly paginatedExtractorList: PaginatedAsyncResult<PaginatedExtractor>;

  readonly extractorOptions?: ExtractorOption[];
  readonly isListingExtractorOptions: boolean;
  readonly extractorOptionsError?: FOCRError;

  readonly extractorFieldSchemaTables: {
    [key in string]: ExtractorFieldSchemaTable;
  };
  readonly isGettingExtractorFieldSchemaTables: { [key in string]: boolean };
  readonly getExtractorFieldSchemaErrors: {
    [key in string]: FOCRError | undefined;
  };
}

const defaultState: ExtractorState = {
  paginatedExtractorList: accessPaginatedAsyncResult<PaginatedExtractor>().data,
  extractorFieldSchemaTables: {},
  isListingExtractorOptions: false,
  isGettingExtractorFieldSchemaTables: {},
  getExtractorFieldSchemaErrors: {},
};

export const extractorReducer = createReducer<ExtractorState>(
  defaultState,
  builder => {
    builder
      .addCase(extractorAction.ListingExtractors, (state, action) => {
        state.paginatedExtractorList =
          accessPaginatedAsyncResult<PaginatedExtractor>(
            current(state.paginatedExtractorList)
          ).setFetchingPage(
            action.payload.page,
            action.payload.filter,
            action.payload.search
          ).data;
      })
      .addCase(extractorAction.GotExtractors, (state, action) => {
        const { page, result } = action.payload;
        state.paginatedExtractorList =
          accessPaginatedAsyncResult<PaginatedExtractor>(
            current(state.paginatedExtractorList)
          ).setSuccessFetchedPaged(page, result).data;
      })
      .addCase(extractorAction.ListExtractorsFailed, (state, action) => {
        const { page, error } = action.payload;
        state.paginatedExtractorList =
          accessPaginatedAsyncResult<PaginatedExtractor>(
            current(state.paginatedExtractorList)
          ).setFailedFetchedPaged(page, error).data;
      })
      .addCase(extractorAction.ListingExtractorOptions, state => {
        state.isListingExtractorOptions = true;
        state.extractorOptionsError = undefined;
      })
      .addCase(extractorAction.GotExtractorOptions, (state, action) => {
        state.isListingExtractorOptions = false;
        state.extractorOptions = action.payload;
      })
      .addCase(extractorAction.ListExtractorOptionsFailed, (state, action) => {
        state.isListingExtractorOptions = false;
        state.extractorOptionsError = action.payload;
      })
      .addCase(
        extractorAction.GettingExtractorFieldSchemaTable,
        (state, action) => {
          const extractorId = action.payload;
          state.isGettingExtractorFieldSchemaTables[extractorId] = true;
          state.getExtractorFieldSchemaErrors[extractorId] = undefined;
        }
      )
      .addCase(
        extractorAction.GotExtractorFieldSchemaTable,
        (state, action) => {
          const { data, extractorId } = action.payload;
          if (extractorId !== undefined) {
            state.extractorFieldSchemaTables[extractorId] = data;
            state.isGettingExtractorFieldSchemaTables[extractorId] = false;
          }
        }
      )
      .addCase(
        extractorAction.GetExtractorFieldSchemaTableFailed,
        (state, action) => {
          const { error, extractorId } = action.payload;
          if (extractorId !== undefined) {
            state.isGettingExtractorFieldSchemaTables[extractorId] = false;
            state.getExtractorFieldSchemaErrors[extractorId] = error;
          }
        }
      )
      .addCase(
        customModelAction.UpdateCustomModelExtractedContentSchema,
        (state, _) => {
          state.extractorFieldSchemaTables = {};
          state.isGettingExtractorFieldSchemaTables = {};
          state.getExtractorFieldSchemaErrors = {};
        }
      )
      .addMatcher(
        isAnyOf(
          formAction.FormSaved,
          formAction.FormRemoved,
          formAction.FormsInvalidated,
          formGroupAction.FormGroupDeleted,
          formGroupAction.FormGroupUpdated,
          formGroupAction.FormGroupsInvalidated,
          customModelAction.DeleteCustomModel,
          customModelAction.CustomModelInvalidated
        ),
        state => {
          state.paginatedExtractorList =
            accessPaginatedAsyncResult<PaginatedExtractor>().data;
          state.extractorOptions = undefined;
          state.extractorFieldSchemaTables = {};
          state.isGettingExtractorFieldSchemaTables = {};
          state.getExtractorFieldSchemaErrors = {};
        }
      )
      .addMatcher(
        isAnyOf(
          formAction.FormCreated,
          formAction.FormRenamed,
          formGroupAction.FormGroupCreated,
          customModelAction.CreateCustomModel,
          customModelAction.RenameCustomModel
        ),
        state => {
          state.paginatedExtractorList =
            accessPaginatedAsyncResult<PaginatedExtractor>().data;
          state.extractorOptions = undefined;
        }
      )
      .addMatcher(
        isAnyOf(
          userAction.UserLogin,
          userAction.UserLogout,
          resourceOwnerAction.SelectTeam,
          teamAction.CreateTeam,
          teamAction.TeamInvitationAccepted,
          teamAction.TeamDeleted
        ),
        () => {
          return {
            ...defaultState,
          };
        }
      )
      .addMatcher(
        isAnyOf(
          formAction.FormPinned,
          formAction.FormUnpinned,
          formGroupAction.FormGroupPinned,
          formGroupAction.FormGroupUnpinned,
          customModelAction.CustomModelPinned,
          customModelAction.CustomModelUnpinned
        ),
        (state, action) => {
          const extractorId =
            "formId" in action.payload
              ? action.payload.formId
              : "formGroupId" in action.payload
              ? action.payload.formGroupId
              : action.payload.customModelId;

          const data = accessPaginatedExtractor(
            current(state.paginatedExtractorList)
          ).setExtractorPinned(extractorId, action.payload.value).data;

          state.paginatedExtractorList = data;
        }
      );
  }
);
