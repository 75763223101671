import {
  MerchantSetting,
  MerchantSettingInfo,
  MerchantSettingInfoResp,
  MerchantSettingResp,
} from "./types/merchantSetting";

export const MerchantSettingsInfoMapper = {
  toResp: (infos: MerchantSettingInfo[]): MerchantSettingInfoResp[] =>
    infos.map(({ type, pattern, isExactMatchOnly, isPreset }) => {
      const result: MerchantSettingInfoResp = {
        type,
        pattern,
        is_exact_match_only: isExactMatchOnly,
        is_preset: isPreset,
      };
      return result;
    }),
  fromResp: (infos: MerchantSettingInfoResp[]): MerchantSettingInfo[] =>
    infos.map(({ type, pattern, is_exact_match_only, is_preset }) => {
      const result: MerchantSettingInfo = {
        type,
        pattern,
        isExactMatchOnly: is_exact_match_only,
        isPreset: is_preset,
      };
      return result;
    }),
};

export const MerchantSettingsMapper = {
  toResp: (merchantSettings: MerchantSetting[]): MerchantSettingResp[] =>
    merchantSettings.map(({ id, name, matchMode, infos, updatedAt }) => {
      const result: MerchantSettingResp = {
        id,
        name,
        match_mode: matchMode,
        infos: MerchantSettingsInfoMapper.toResp(infos),
        updated_at: updatedAt,
      };
      return result;
    }),
  fromResp: (merchantSettingsResp?: MerchantSettingResp[]): MerchantSetting[] =>
    (merchantSettingsResp || []).map(
      ({ id, name, match_mode, infos, updated_at }) => {
        const result: MerchantSetting = {
          id,
          name,
          infos: MerchantSettingsInfoMapper.fromResp(infos),
          updatedAt: updated_at || 0,
          matchMode: match_mode,
        };
        return result;
      }
    ),
};
