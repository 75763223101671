import React, { useCallback } from "react";

import { DetectionPIINote } from "../components/ExtractNote";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { README_IO_PAGE_URL } from "../constants";
import { useWorkerToken } from "../hooks/app";
import { useReadmeIO } from "../hooks/readmeIO";
import {
  DetectPIINavBarLayout,
  DetectPIINavTabKey,
} from "./DetectPIINavLayout";
import HeaderContainer from "./Header";

function _DetectPIIExtractContainer() {
  const { token, tokens, onSelectToken } = useWorkerToken();
  const { onRedirectToReadmeIO } = useReadmeIO();

  const onOpenReference = useCallback(() => {
    onRedirectToReadmeIO(README_IO_PAGE_URL.referenceDetectPII);
  }, [onRedirectToReadmeIO]);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        {tokens === undefined ? (
          <LoadingModal isOpen={true} />
        ) : (
          <DetectPIINavBarLayout selectedTab={DetectPIINavTabKey.API}>
            <DetectionPIINote
              token={token || ""}
              tokens={tokens}
              onSelectToken={onSelectToken}
              onOpenReference={onOpenReference}
            />
          </DetectPIINavBarLayout>
        )}
      </Main>
    </Layout>
  );
}

export const DetectPIIExtractContainer = React.memo(_DetectPIIExtractContainer);
export default DetectPIIExtractContainer;
