.header {
  font-weight: 400;
}

.extractor-type {
  margin-bottom: 20px;
}

.form-group-mode-choice-group {
  margin-top: 15px;

  :global(.ms-ChoiceFieldLabel) {
    white-space: pre-wrap;
  }

  :global(.ms-ChoiceField) {
    & + :global(.ms-ChoiceField) {
      margin-top: 25px;
    }
  }
}

.form-group-mode-title {
  margin-top: 20px;
  margin-bottom: 2px;
}

.form-group-mode-desc {
  margin-bottom: 28px;
}

.form-group-type-option {
  margin-left: 28px;
}

.form-group-type-option-title {
  margin-bottom: 6px;
}

.custom-model-extra {
  margin-top: 20px;

  :global(.ms-Toggle) {
    label {
      font-weight: 600;
    }
  }

  .description {
    font-size: 12px;
    color: #605e5c;
  }
}

.footer {
  margin-top: 30px;
}
