import {
  ChoiceGroup,
  Dropdown,
  IChoiceGroupOption,
  IDropdownOption,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useFSLCustomModelEditor } from "../../contexts/fslCustomModelEditor";
import { useLocale } from "../../contexts/locale";
import { FSLModelType } from "../../types/customModel";
import { DangerLoadingButton } from "../LoadingButton";
import styles from "./styles.module.scss";

export function useFSLActiveModelPicker() {
  const {
    extractedContentSchema,
    customModel,
    updateActiveModel,
    standardModelVersions,
  } = useFSLCustomModelEditor();

  const [selectedModelType, setSelectedModelType] =
    React.useState<FSLModelType>(FSLModelType.InstantModel);
  const [selectedModelVersion, setSelectedModelVersion] = React.useState<
    string | undefined
  >(undefined);
  const [isRunning, setIsRunning] = React.useState<boolean>(false);

  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  const initializedRef = React.useRef(false);

  const isStandardModelAvailable =
    (customModel?.modelVersions?.length ?? 0) > 0;

  React.useEffect(() => {
    if (initializedRef.current || customModel === undefined) {
      return;
    }
    initializedRef.current = true;
    setSelectedModelType(
      customModel.config.fslModelState?.shouldFallbackToOriginalCustomModel ??
        false
        ? FSLModelType.StandardModel
        : FSLModelType.InstantModel
    );
    const deployedModelVersionInModelVersions =
      customModel.modelVersions.find(
        version => version.tag === customModel.deployedModelVersion
      ) ?? false;

    if (!deployedModelVersionInModelVersions) {
      setSelectedModelVersion(standardModelVersions[0]?.key);
    } else {
      setSelectedModelVersion(customModel.deployedModelVersion);
    }
  }, [customModel, standardModelVersions]);

  const isAnyModelAvailable = extractedContentSchema !== undefined;
  const isSavable =
    isDirty &&
    (selectedModelType === FSLModelType.StandardModel
      ? selectedModelVersion !== undefined
      : true);

  const onModelTypeChange = React.useCallback(
    (
      _?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      if (option) {
        setSelectedModelType(option.key as FSLModelType);
        setIsDirty(true);
      }
    },
    [setSelectedModelType]
  );

  const onModelVersionChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option) {
        setSelectedModelVersion(option.key as string);
        setIsDirty(true);
      }
    },
    [setIsDirty, setSelectedModelVersion]
  );

  const doUpdateActiveModel = React.useCallback(async () => {
    if (isRunning) {
      return;
    }
    setIsRunning(true);
    try {
      await updateActiveModel(selectedModelType, selectedModelVersion);
      setIsDirty(false);
    } finally {
      setIsRunning(false);
    }
  }, [
    isRunning,
    selectedModelType,
    selectedModelVersion,
    setIsRunning,
    setIsDirty,
    updateActiveModel,
  ]);

  return React.useMemo(
    () => ({
      isAnyModelAvailable,
      isStandardModelAvailable,
      standardModelVersions,
      selectedModelType,
      onModelTypeChange,
      isSavable,
      selectedModelVersion,
      onModelVersionChange,
      doUpdateActiveModel,
      isRunning,
    }),
    [
      isAnyModelAvailable,
      isStandardModelAvailable,
      standardModelVersions,
      selectedModelType,
      onModelTypeChange,
      isSavable,
      selectedModelVersion,
      onModelVersionChange,
      doUpdateActiveModel,
      isRunning,
    ]
  );
}

export function FSLActiveModelPickerImpl(
  props: ReturnType<typeof useFSLActiveModelPicker>
) {
  const {
    selectedModelType,
    isAnyModelAvailable,
    isStandardModelAvailable,
    standardModelVersions,
    onModelTypeChange,
    isSavable,
    selectedModelVersion,
    onModelVersionChange,
    doUpdateActiveModel,
    isRunning,
  } = props;
  const { localized } = useLocale();

  const options = React.useMemo(
    () => [
      {
        key: FSLModelType.InstantModel,
        text: localized("fsl_active_model_picker.instant_model"),
      },
      {
        key: FSLModelType.StandardModel,
        text: localized("fsl_active_model_picker.standard_model"),
        disabled: !isStandardModelAvailable,
      },
    ],
    [localized, isStandardModelAvailable]
  );

  return (
    <div className={styles["fsl-active-model-picker"]}>
      <div className={styles["fsl-active-model-picker-title"]}>
        <FormattedMessage id="fsl_active_model_picker.title" />
      </div>

      {!isAnyModelAvailable ? (
        <div className={styles["fsl-active-model-picker-desc"]}>
          <FormattedMessage id="fsl_active_model_picker.no_model_set" />
        </div>
      ) : (
        <>
          <div className={styles["fsl-active-model-picker-desc"]}>
            <FormattedMessage id="fsl_active_model_picker.desc" />
          </div>

          <div className={styles["fsl-active-model-picker-model-type"]}>
            <ChoiceGroup
              className={styles["fsl-active-model-picker-choice-group"]}
              options={options}
              selectedKey={selectedModelType}
              onChange={onModelTypeChange}
            />
          </div>

          {isStandardModelAvailable && (
            <div className={styles["fsl-active-model-picker-model-version"]}>
              <Dropdown
                label={localized("fsl_active_model_picker.model_version")}
                options={standardModelVersions}
                selectedKey={selectedModelVersion}
                disabled={selectedModelType !== FSLModelType.StandardModel}
                onChange={onModelVersionChange}
              />
            </div>
          )}

          <div className={styles["fsl-active-model-picker-model-button"]}>
            <DangerLoadingButton
              textId="fsl_active_model_picker.button"
              disabled={!isSavable}
              isLoading={isRunning}
              onClick={doUpdateActiveModel}
            />
          </div>
        </>
      )}
    </div>
  );
}

export function FSLActiveModelPicker() {
  const states = useFSLActiveModelPicker();
  return <FSLActiveModelPickerImpl {...states} />;
}
