ul.breadcrumb {
  display: block;
  list-style: none;
  font-size: 16px;
  line-height: 24px;

  white-space: nowrap;
  overflow: hidden;

  > li {
    display: inline-block;
    font-weight: 600;

    > i {
      font-size: 12px;
      color: #605e5c;
    }

    > div {
      display: inline-block;
      padding: 6px 8px;
      cursor: default;

      &.pressable {
        cursor: pointer;
        font-weight: normal;
        color: #605e5c;
        &:hover {
          background-color: #f3f2f1;
        }
      }
    }
  }
}
