import React, { useCallback, useState } from "react";

import { useDetectMultiDocumentActionCreator } from "../actions/detectMultiDocument";
import DetectMultiDocumentTest from "../components/DetectMultiDocumentTest";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import { FOCRError } from "../errors";
import { useWorkerToken } from "../hooks/app";
import { useToast } from "../hooks/toast";
import { DetectMultiDocumentReport } from "../models";
import {
  DetectMultiDocumentNavBarLayout,
  DetectMultiDocumentNavTabKey,
} from "./DetectMultiDocumentNavLayout";

function useExtractInfo(token: string | undefined) {
  const [testReport, setOcrTestReport] = useState<
    DetectMultiDocumentReport | undefined
  >(undefined);
  const { detectMultiDocument } = useDetectMultiDocumentActionCreator();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const toast = useToast();

  const clearOcrTestReport = useCallback(() => {
    setOcrTestReport(undefined);
  }, []);

  const extractWithImageFile = useCallback(
    (file?: File) => {
      if (file && token) {
        setIsUploading(true);
        setIsLoading(true);
        const promise = detectMultiDocument(token, file).then(
          x => x as DetectMultiDocumentReport
        );

        promise
          .then(resp => {
            setIsLoading(false);
            setIsUploading(false);
            setOcrTestReport(resp as DetectMultiDocumentReport);
          })
          .catch(error => {
            setIsUploading(false);
            setIsLoading(false);
            if (error instanceof FOCRError) {
              toast.error(error.messageId, undefined, error.detail);
            } else {
              toast.error("error.cannot_load_image");
            }
          });
      }
    },
    [token, toast, detectMultiDocument]
  );

  return React.useMemo(
    () => ({
      testReport,
      clearOcrTestReport,
      extractWithImageFile,
      isLoading,
      isUploading,
    }),
    [
      testReport,
      clearOcrTestReport,
      extractWithImageFile,
      isLoading,
      isUploading,
    ]
  );
}

function _DetectMultiDocumentTestContainer() {
  const { token } = useWorkerToken();

  const { testReport, extractWithImageFile, isLoading, isUploading } =
    useExtractInfo(token);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        {isLoading ? (
          <LoadingModal
            messageId="detect_documents_test.detecting"
            isOpen={isLoading}
          />
        ) : (
          <span />
        )}
        {isUploading ? (
          <LoadingModal messageId="common.uploading" isOpen={isUploading} />
        ) : (
          <span />
        )}
        <DetectMultiDocumentNavBarLayout
          selectedTab={DetectMultiDocumentNavTabKey.TestExtractor}
        >
          <DetectMultiDocumentTest
            onSelectImage={extractWithImageFile}
            testReport={testReport}
          />
        </DetectMultiDocumentNavBarLayout>
      </Main>
    </Layout>
  );
}

export const DetectMultiDocumentTestContainer = React.memo(
  _DetectMultiDocumentTestContainer
);
export default DetectMultiDocumentTestContainer;
