@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.webhook-list {
  overflow-y: auto;
  background-color: #fff;
  padding: 32px;

  .title {
    margin-bottom: 26px;
  }

  .empty-state-container {
    display: flex;
    width: 100%;

    .empty-state {
      @include no-select();

      .add-button {
        max-width: 140px;
        padding: 0 10px;
        margin-top: 10px;
      }

      .empty-state-text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        label {
          margin-top: 0;
          font-weight: 500;
        }
      }
    }
  }
}
