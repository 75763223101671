import { Icon, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import styles from "./styles.module.scss";

interface SaveWebhookBarProps {
  isCreate: boolean;
  onRemoveClicked?: () => void;
}

export const SaveWebhookBar = React.memo((props: SaveWebhookBarProps) => {
  const { isCreate, onRemoveClicked } = props;

  return (
    <div className={styles["top-bar"]}>
      <button type="submit" className={styles["button-area"]}>
        <Icon iconName="Save" />
        <Text>
          {isCreate && <FormattedMessage id="create_form.create" />}
          {!isCreate && <FormattedMessage id="common.save" />}
        </Text>
      </button>
      {!isCreate && (
        <button
          type="button"
          onClick={onRemoveClicked}
          className={styles["button-area"]}
        >
          <Icon className={styles["red-icon"]} iconName="Delete" />
          <Text>
            <FormattedMessage id="common.remove" />
          </Text>
        </button>
      )}
    </div>
  );
});

export default SaveWebhookBar;
