import * as React from "react";
import { useNavigate } from "react-router";

import { useWebhookActionCreator } from "../actions/webhook";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import WebhookEditor from "../components/WebhookEditor";
import { useToast } from "../hooks/toast";
import HeaderContainer from "./Header";

const WebhookCreateContainer = React.memo(() => {
  const { createWebhook } = useWebhookActionCreator();

  const toast = useToast();
  const navigate = useNavigate();
  const [isCreatingWebhook, setIsCreatingWebhook] =
    React.useState<boolean>(false);

  const doCreateWebhook = React.useCallback(
    async (
      name: string,
      url: string,
      formIds: string[],
      formGroupIds: string[]
    ) => {
      setIsCreatingWebhook(true);
      try {
        const webhook = await createWebhook(name, url, formIds, formGroupIds);
        toast.success("webhook.created");
        navigate(`/webhook/${webhook.id}/edit`, { replace: true });
      } catch (e) {
        console.error("Failed to create webhook: ", e);
        toast.error("error.fail_to_create_webhook");
      } finally {
        setIsCreatingWebhook(false);
      }
    },
    [createWebhook, toast, navigate]
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <WebhookEditor submit={doCreateWebhook} />
        <LoadingModal
          isOpen={isCreatingWebhook}
          messageId="webhook.creating_webhook"
        />
      </Main>
    </Layout>
  );
});

export default WebhookCreateContainer;
