import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import { useExtractionActionCreator } from "../actions/extraction";
import { apiClient } from "../apiClient";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { WorkspaceDocumentDetailsSection } from "../components/WorkspaceDocumentDetailsSection";
import { DocumentNotFoundPlaceholder } from "../components/WorkspaceDocumentDetailsSection/DocumentNotFoundPlaceholder";
import { ADDITIONAL_IMAGE_PAGE_SIZE } from "../constants/layout";
import { useNavigationStack } from "../contexts/navigationStack";
import errors, { FOCRError } from "../errors";
import { useWorkerToken } from "../hooks/app";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { useCommonWorkspaceContainerState } from "../hooks/workspace";
import { ExtractAPIV2Document, PathParam } from "../models";
import { Extraction, ExtractionResult } from "../types/extraction";
import { ensureFOCRError } from "../utils/errors";
import HeaderContainer from "./Header";

const listAllAdditionalImages = async (
  extractionResultId: string
): Promise<string[]> => {
  let hasNextPage = true;
  let offset = 0;
  let urls: string[] = [];
  try {
    while (hasNextPage) {
      const res = await apiClient.listAdditionalImages({
        extractionResultId,
        size: ADDITIONAL_IMAGE_PAGE_SIZE,
        offset,
      });
      urls = [...urls, ...res.additionalImageUrls];
      hasNextPage =
        res.additionalImageUrls.length >= ADDITIONAL_IMAGE_PAGE_SIZE;
      offset = res.pageInfo.offset;
    }
  } catch (e: unknown) {
    throw ensureFOCRError(e);
  }
  return urls;
};

const useExtractionListPagination = (
  workspaceId: string,
  extractionResultId: string
) => {
  const { listParamsByWorkspace, paginatedExtractionsByWorkspace } =
    useAppSelector(state => state.extraction);

  const { listExtractions } = useExtractionActionCreator();

  const toast = useToast();

  const navigate = useNavigate();

  const reduceResultsFromExtractions = useCallback(
    (extractions: Extraction[]): ExtractionResult[] => {
      return extractions.reduce(
        (prev: ExtractionResult[], curr: Extraction) => {
          return [...prev, ...curr.results.filter(result => result.id != null)];
        },
        []
      );
    },
    []
  );

  const resultsOnExtractionList = useMemo(() => {
    return reduceResultsFromExtractions(
      paginatedExtractionsByWorkspace[workspaceId]?.extractions ?? []
    );
  }, [
    paginatedExtractionsByWorkspace,
    reduceResultsFromExtractions,
    workspaceId,
  ]);

  const currentResultIdx = useMemo(() => {
    return resultsOnExtractionList.findIndex(
      result => result.id === extractionResultId
    );
  }, [extractionResultId, resultsOnExtractionList]);

  const canPaginateOnList =
    listParamsByWorkspace[workspaceId] != null &&
    paginatedExtractionsByWorkspace[workspaceId] != null &&
    currentResultIdx !== -1;

  const hasPrev =
    canPaginateOnList &&
    (currentResultIdx > 0 || listParamsByWorkspace[workspaceId].page > 1);

  const lastPage = Math.ceil(
    (paginatedExtractionsByWorkspace[workspaceId]?.pageInfo.totalCount ?? 1) /
      (listParamsByWorkspace[workspaceId]?.size ?? 1)
  );

  const hasNext =
    canPaginateOnList &&
    (currentResultIdx < resultsOnExtractionList.length - 1 ||
      listParamsByWorkspace[workspaceId].page < lastPage);

  const goToPrev = useCallback(async () => {
    if (!hasPrev) {
      return;
    }

    if (currentResultIdx === 0) {
      // current result is top of list, need to get previous list page

      const getPrevResultAcrossPages = async (
        pageOffset: number = -1
      ): Promise<ExtractionResult | null> => {
        const targetPage = listParamsByWorkspace[workspaceId].page + pageOffset;
        if (targetPage < 1) {
          return null;
        }
        const res = await listExtractions({
          workspaceId,
          page: targetPage,
          size: listParamsByWorkspace[workspaceId].size,
          fileName: listParamsByWorkspace[workspaceId].fileName,
          sort: listParamsByWorkspace[workspaceId].sort,
          shouldOverrideUrlState: true,
        });
        const targetPageResults = reduceResultsFromExtractions(res.extractions);
        if (targetPageResults.length <= 0) {
          return getPrevResultAcrossPages(pageOffset - 1);
        } else {
          return targetPageResults[targetPageResults.length - 1];
        }
      };

      const prevResult = await getPrevResultAcrossPages();
      if (prevResult == null) {
        toast.error(
          "workspace.document_detail.pagination.error.no_results_on_previous_page"
        );
        return;
      }
      navigate(`/workspace/${workspaceId}/result/${prevResult.id}`);
      return;
    } else {
      // current result is middle
      navigate(
        `/workspace/${workspaceId}/result/${
          resultsOnExtractionList[currentResultIdx - 1].id
        }`
      );
      return;
    }
  }, [
    currentResultIdx,
    hasPrev,
    listExtractions,
    listParamsByWorkspace,
    navigate,
    reduceResultsFromExtractions,
    resultsOnExtractionList,
    toast,
    workspaceId,
  ]);

  const goToNext = useCallback(async () => {
    if (!hasNext) {
      return;
    }

    if (currentResultIdx >= resultsOnExtractionList.length - 1) {
      // current result is last of list, need to get next list page

      const getNextResultAcrossPages = async (
        pageOffset: number = 1
      ): Promise<ExtractionResult | null> => {
        const targetPage = listParamsByWorkspace[workspaceId].page + pageOffset;
        if (targetPage > lastPage) {
          return null;
        }
        const res = await listExtractions({
          workspaceId,
          page: targetPage,
          size: listParamsByWorkspace[workspaceId].size,
          fileName: listParamsByWorkspace[workspaceId].fileName,
          sort: listParamsByWorkspace[workspaceId].sort,
          shouldOverrideUrlState: true,
        });
        const targetPageResults = reduceResultsFromExtractions(res.extractions);
        if (targetPageResults.length <= 0) {
          return getNextResultAcrossPages(pageOffset + 1);
        } else {
          return targetPageResults[0];
        }
      };

      const nextResult = await getNextResultAcrossPages();
      if (nextResult == null) {
        toast.error(
          "workspace.document_detail.pagination.error.no_results_on_next_page"
        );
        return;
      }
      navigate(`/workspace/${workspaceId}/result/${nextResult.id}`);
      return;
    } else {
      // current result is middle
      navigate(
        `/workspace/${workspaceId}/result/${
          resultsOnExtractionList[currentResultIdx + 1].id
        }`
      );
      return;
    }
  }, [
    currentResultIdx,
    hasNext,
    lastPage,
    listExtractions,
    listParamsByWorkspace,
    navigate,
    reduceResultsFromExtractions,
    resultsOnExtractionList,
    toast,
    workspaceId,
  ]);

  return useMemo(
    () => ({
      canPaginateOnList,
      hasPrevOnList: hasPrev,
      hasNextOnList: hasNext,
      goToPrevOnList: goToPrev,
      goToNextOnList: goToNext,
    }),
    [canPaginateOnList, goToNext, goToPrev, hasNext, hasPrev]
  );
};

function useWorkspaceDocumentResultContainer() {
  const toast = useToast();
  const { workspaceId, extractionResultId } = useUnsafeParams<PathParam>();
  const { workspace } = useCommonWorkspaceContainerState(workspaceId);
  const {
    extractionResultById,
    isGettingExtractionResultById,
    getExtractionResultErrorById,
    extractionResultPageInfoById,
  } = useAppSelector(state => state.extraction);
  const { getExtractionResult, exportExtractions, reExtractExtraction } =
    useExtractionActionCreator();

  useEffect(() => {
    getExtractionResult(extractionResultId);
  }, [extractionResultId, getExtractionResult]);

  const extractionResult: ExtractionResult | null =
    extractionResultById[extractionResultId] ?? null;

  const [isResultAssetLoading, setIsResultAssetLoading] = useState(false);
  const [resultAsset, setResultAsset] = useState<ExtractAPIV2Document | null>(
    null
  );
  useEffect(() => {
    if (
      extractionResult == null ||
      extractionResult.info.resultAssetUrl == null
    ) {
      setIsResultAssetLoading(false);
      setResultAsset(null);
      return;
    }
    setIsResultAssetLoading(true);
    fetch(extractionResult.info.resultAssetUrl)
      .then(async res => {
        if (res.ok) {
          setResultAsset(await res.json());
        } else {
          setResultAsset(null);
        }
      })
      .catch(e => {
        console.error(e);
        setResultAsset(null);
      })
      .finally(() => {
        setIsResultAssetLoading(false);
      });
  }, [extractionResult]);

  const [isAdditionalImageUrlsLoading, setIsAdditionalImageUrlsLoading] =
    useState(false);
  const [additionalImageUrls, setAdditionalImageUrls] = useState<string[]>([]);
  useEffect(() => {
    setIsAdditionalImageUrlsLoading(true);
    listAllAdditionalImages(extractionResultId)
      .then(urls => {
        setAdditionalImageUrls(urls);
      })
      .catch((e: FOCRError) => {
        toast.error(e.messageId, undefined, e.detail);
      })
      .finally(() => {
        setIsAdditionalImageUrlsLoading(false);
      });
  }, [extractionResultId, toast]);

  const [isDownloading, setIsDownloading] = React.useState(false);

  const onDownloadClick = React.useCallback(async () => {
    if (
      extractionResult?.extractionId == null ||
      extractionResult?.extractionIndex == null
    ) {
      return;
    }
    setIsDownloading(true);
    try {
      await exportExtractions({
        workspaceId,
        resultIndexesByExtractionId: {
          [extractionResult?.extractionId]: [extractionResult?.extractionIndex],
        },
      });
    } catch {
      toast.error("error.workspace.fail_to_download_extraction");
    } finally {
      setIsDownloading(false);
    }
  }, [
    exportExtractions,
    extractionResult?.extractionId,
    extractionResult?.extractionIndex,
    toast,
    workspaceId,
  ]);

  const { token } = useWorkerToken();
  const { popUntil } = useNavigationStack();

  const onReExtractExtraction = useCallback(async () => {
    try {
      if (token == null) {
        throw new Error("token null, should not happen");
      }
      if (extractionResult.id == null) {
        throw new Error("extraction result id null, should not happen");
      }
      await reExtractExtraction(token, workspaceId, extractionResult);
      popUntil(
        location =>
          location.pathname.startsWith(`/workspace/${workspaceId}/document`),
        `/workspace/${workspaceId}/document`
      );
      toast.success("workspace.document_detail.retry_extraction.success");
    } catch (e: unknown) {
      console.error(e);
      if (e instanceof FOCRError) {
        toast.error(e.messageId, undefined, e.detail);
      } else {
        toast.error(
          errors.UnknownError.messageId,
          undefined,
          errors.UnknownError.detail
        );
      }
    }
  }, [
    extractionResult,
    popUntil,
    reExtractExtraction,
    toast,
    token,
    workspaceId,
  ]);

  const navigate = useNavigate();

  const onClickPrevPage = useCallback(() => {
    const prevResultId =
      extractionResultPageInfoById[extractionResultId]
        ?.previousExtractionResultId;
    if (prevResultId == null) {
      return;
    }
    navigate(`/workspace/${workspaceId}/result/${prevResultId}`);
  }, [extractionResultPageInfoById, navigate, extractionResultId, workspaceId]);

  const onClickNextPage = useCallback(() => {
    const nextResultId =
      extractionResultPageInfoById[extractionResultId]?.nextExtractionResultId;
    if (nextResultId == null) {
      return;
    }
    navigate(`/workspace/${workspaceId}/result/${nextResultId}`);
  }, [extractionResultPageInfoById, navigate, extractionResultId, workspaceId]);

  const {
    canPaginateOnList,
    hasNextOnList,
    hasPrevOnList,
    goToNextOnList,
    goToPrevOnList,
  } = useExtractionListPagination(workspaceId, extractionResultId);

  const pagination = useMemo(
    () =>
      canPaginateOnList
        ? {
            // there is list state on redux, paginate across the list
            hasPrevPage: hasPrevOnList,
            hasNextPage: hasNextOnList,
            onClickPrevPage: goToPrevOnList,
            onClickNextPage: goToNextOnList,
          }
        : {
            // fallback to paginate using page info from api
            hasPrevPage:
              extractionResultPageInfoById[extractionResultId]
                ?.previousExtractionResultId != null,
            hasNextPage:
              extractionResultPageInfoById[extractionResultId]
                ?.nextExtractionResultId != null,
            onClickPrevPage: onClickPrevPage,
            onClickNextPage: onClickNextPage,
          },
    [
      canPaginateOnList,
      extractionResultId,
      extractionResultPageInfoById,
      goToNextOnList,
      goToPrevOnList,
      hasNextOnList,
      hasPrevOnList,
      onClickNextPage,
      onClickPrevPage,
    ]
  );

  return useMemo(
    () => ({
      workspace,
      extractionResult,
      resultAsset,
      isResultLoading:
        (isGettingExtractionResultById[extractionResultId] ?? false) ||
        isResultAssetLoading,
      isImageLoading:
        (isGettingExtractionResultById[extractionResultId] ?? false) ||
        isAdditionalImageUrlsLoading,
      error: getExtractionResultErrorById[extractionResultId] ?? null,
      onDownloadClick,
      isDownloading,
      onReExtractExtraction,
      pagination,
      additionalImageUrls,
    }),
    [
      additionalImageUrls,
      extractionResult,
      getExtractionResultErrorById,
      isAdditionalImageUrlsLoading,
      isDownloading,
      isGettingExtractionResultById,
      isResultAssetLoading,
      onDownloadClick,
      onReExtractExtraction,
      pagination,
      resultAsset,
      extractionResultId,
      workspace,
    ]
  );
}

export default function WorkspaceDocumentResultContainer() {
  const props = useWorkspaceDocumentResultContainer();
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop>
        {props.error != null ? (
          props.error === errors.ExtractionNotFound ? (
            <DocumentNotFoundPlaceholder showAlert={true} />
          ) : (
            <ErrorPlaceholder
              messageId={props.error.messageId}
              messageValues={props.error.detail}
            />
          )
        ) : null}
        {props.workspace.state === "success" ? (
          <WorkspaceDocumentDetailsSection
            workspace={props.workspace.data}
            extraction={null}
            extractionResult={props.extractionResult}
            resultAsset={props.resultAsset}
            onDownloadClick={props.onDownloadClick}
            onReExtractExtraction={props.onReExtractExtraction}
            pagination={props.pagination}
            additionalImageUrls={props.additionalImageUrls}
            isResultLoading={props.isResultLoading}
            isImageLoading={props.isImageLoading}
          />
        ) : null}
        <LoadingModal isOpen={props.isDownloading} />
      </Main>
    </Layout>
  );
}
