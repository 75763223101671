@import "../../styles/variables.scss";

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.searchBox {
  flex: 0 1 300px;
}

.rightGroup {
  display: flex;
  align-items: center;
  gap: 6px;
}

.outOfQuota {
  color: #a4262c;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
