import { DropdownMenuItemType, IDropdownOption } from "@fluentui/react";
import produce from "immer";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useExtractorFieldSchema } from "../hooks/extractorFieldSchema";
import { ConfirmModalType } from "../types/confirmation";
import {
  ExtractorFieldSchemaTable,
  ExtractorFieldValueType,
  ExtractorGetSchemaFields,
} from "../types/extractor";
import {
  Formatter,
  FormatterActionInfo,
  FormatterActionSelectorType,
  FormatterActionType,
  FormatterDataSource,
  FormatterInputSelectionField,
  FormatterMatchingConditionOperator,
  FormatterMatchingConditionRule,
  FormatterOutputAsField,
  FormatterStep,
} from "../types/formatter";
import { FormatterMapper, FormatterStorage } from "../types/mappers/formatter";
import {
  FormatterValidator,
  createFormatterSelector,
  normalizeSelector,
} from "../validators/formatterValidator";
import { useLocale } from "./locale";

export type FormatterInputField = {
  type: string;
  selector: string;
  hasMultipleValue: boolean;
};

export interface FormatterDropdownOptionField extends IDropdownOption {
  isFormatterOutput?: boolean;
}

function extractFormatterOutputItems(
  steps: FormatterStep[],
  selectedStepIndex: number,
  type: FormatterActionSelectorType
): FormatterInputField[] {
  let selectors: string[] = [];
  if (steps.length === 0) {
    return [];
  }

  steps.slice(0, selectedStepIndex).forEach(step => {
    const output = step.outputAs;
    if (!output.enabled) {
      return;
    }

    selectors = selectors.concat(
      output.fields.map(field => {
        return generateSelectors(type, field.name)[0];
      })
    );
  });

  return selectors.map(selector => ({
    type,
    selector: selector,
    hasMultipleValue: false,
  }));
}

function generateSelectors(
  type: string,
  name: string,
  properties?: string[],
  filterFn?: (type: string, name: string, property?: string) => boolean
) {
  const normalizedName = normalizeSelector(name);
  if (properties !== undefined && properties.length > 0) {
    return properties
      .filter(
        property =>
          filterFn === undefined || filterFn(type, normalizedName, property)
      )
      .map(property =>
        [type, `'${normalizedName}'`, property].filter(Boolean).join(".")
      );
  }
  if (filterFn === undefined || filterFn(type, normalizedName, undefined)) {
    return [[type, `'${normalizedName}'`].filter(Boolean).join(".")];
  }
  return [];
}

function getExtractorFieldSchemaTableSelectors(
  extractorFieldSchemaTable?: ExtractorFieldSchemaTable,
  type?: FormatterActionSelectorType,
  filterFn?: (type: string, name: string, property?: string) => boolean
) {
  if (extractorFieldSchemaTable === undefined || type === undefined) {
    return [];
  }
  const selectors = Object.values(extractorFieldSchemaTable).reduce(
    (previousSelectors, currentFields) => {
      const currentSelectors = currentFields.reduce(
        (_previousSelectors, _currentField) => {
          const { name, properties, valueType } = _currentField;
          if (
            type === FormatterActionSelectorType.Value &&
            properties === undefined &&
            (valueType === ExtractorFieldValueType.Dict ||
              valueType === ExtractorFieldValueType.ListOfDict)
          ) {
            return _previousSelectors;
          }
          const fieldSelectors = generateSelectors(
            type,
            name,
            type === FormatterActionSelectorType.Field ? undefined : properties,
            filterFn
          ).filter(
            selector =>
              !_previousSelectors.includes(selector) &&
              !_previousSelectors.includes(selector)
          );
          return _previousSelectors.concat(fieldSelectors);
        },
        [] as string[]
      );
      return previousSelectors.concat(currentSelectors);
    },
    [] as string[]
  );
  return selectors;
}

function formatDropdownOptions(
  fieldsWithHeader: {
    header?: IDropdownOption;
    fields: FormatterDropdownOptionField[];
  }[]
) {
  return fieldsWithHeader
    .map(value => {
      const { header, fields } = value;
      const dropdownOptions: FormatterDropdownOptionField[] = [];
      if (header !== undefined) {
        dropdownOptions.push(header);
      }
      return fields.length > 0 ? dropdownOptions.concat(fields) : [];
    })
    .flat();
}

export function removeFormatterOutputFromExtractorField(
  extractorFieldSchemaTable: ExtractorGetSchemaFields,
  formatterOutputs: string[]
) {
  return Object.values(extractorFieldSchemaTable ?? {})
    .map(extractorFields => {
      return extractorFields.filter(
        field => !formatterOutputs.includes(field.name)
      );
    })
    .flat();
}

interface FormatterContextProps {
  extractorId: string;
  formatterSource?: FormatterStorage;
  onUpdateFormatter?: (formatter: Formatter) => any;
  onSaveFormatter: (formatterStorage: FormatterStorage) => Promise<any>;
}

function useMakeContext(props: FormatterContextProps) {
  const { extractorId, formatterSource, onUpdateFormatter, onSaveFormatter } =
    props;
  const { localized } = useLocale();
  const { getExtractorFieldSchemaTableByExtractorId } =
    useExtractorFieldSchema();

  const extractorFieldSchemaTable = React.useMemo(
    () => getExtractorFieldSchemaTableByExtractorId(extractorId),
    [extractorId, getExtractorFieldSchemaTableByExtractorId]
  );

  const [isFailedToSaveFormatter, setIsFailedToSaveFormatter] =
    React.useState(false);

  const [isFormatterNotSaved, setIsFormatterNotSaved] = React.useState(false);

  const [formatter, setFormatter] = React.useState<Formatter | undefined>(
    undefined
  );

  React.useEffect(() => {
    return setFormatter(FormatterMapper.fromStorage(formatterSource));
  }, [formatterSource]);

  const steps = React.useMemo(() => {
    return formatter?.steps ?? [];
  }, [formatter]);

  const [isAddingStep, setIsAddingStep] = React.useState(false);

  const [selectedStepIndex, setSelectedStepIndex] = React.useState(-1);

  const selectStep = React.useCallback(
    (index: number) => {
      setSelectedStepIndex(index);
      setIsAddingStep(false);
    },
    [setSelectedStepIndex, setIsAddingStep]
  );

  const selectedStep = React.useMemo(() => {
    if (selectedStepIndex < 0) {
      return undefined;
    }

    return steps[selectedStepIndex];
  }, [selectedStepIndex, steps]);

  const requestToAddStep = React.useCallback(() => {
    setIsAddingStep(true);
  }, [setIsAddingStep]);

  const updateFormatter = React.useCallback(
    (steps: FormatterStep[]) => {
      const newFormatter = {
        version: "1",
        steps,
      };
      setFormatter(newFormatter);
      setIsFailedToSaveFormatter(false);
      setIsFormatterNotSaved(true);
      onUpdateFormatter && onUpdateFormatter(newFormatter);
    },
    [onUpdateFormatter]
  );

  const addStep = React.useCallback(
    async (action: string) => {
      const newStep = {
        id: uuidv4(),
        action,
        inputSelection: {
          fields: [],
        },
        config: {},
        outputAs: {
          enabled: false,
          fields: [],
        },
        matchingCondition: {
          enabled: false,
          rules: [],
        },
      };

      const newSteps = produce(steps, draft => {
        draft.push(newStep);
      });
      setIsAddingStep(false);
      updateFormatter(newSteps);
      setSelectedStepIndex(newSteps.length - 1);
    },
    [steps, setIsAddingStep, updateFormatter, setSelectedStepIndex]
  );

  const localizeSelector = React.useCallback(
    (selector: string, noProperty?: boolean): string => {
      const object = createFormatterSelector(selector, noProperty);
      const translateType: { [key: string]: string } = {
        [FormatterDataSource.AutoExtractionItems]: "auto_extraction_item",
      };
      if (Object.hasOwn(translateType, object.type)) {
        const type = translateType[object.type];
        const tokens = [type, object.name];
        const translation = localized(tokens.join("."));

        return [translation, object.property].filter(Boolean).join(" - ");
      } else if (object.type === FormatterDataSource.FormatterOutput) {
        return normalizeSelector(createFormatterSelector(selector, true).name);
      } else {
        const properties =
          object.property !== undefined ? [object.property] : object.property;
        const result = normalizeSelector(
          generateSelectors("", object.name, properties)[0]
        );
        return result;
      }
    },
    [localized]
  );

  const getInputFieldsDropdownOptions = React.useCallback(
    (
      _: number,
      options?: {
        withoutMultipleValueType?: boolean;
        withSelectors?: string[];
      }
    ) => {
      const defaultOptions = {
        withoutMultipleValueType: false,
        withSelectors: [],
      };
      const { withoutMultipleValueType, withSelectors } = {
        ...defaultOptions,
        ...options,
      };

      const selectorType =
        selectedStep?.action !== undefined
          ? FormatterActionInfo[selectedStep.action as FormatterActionType]
              .selectorType
          : FormatterActionSelectorType.Value;

      const formatterOutputHeader = {
        key: FormatterDataSource.FormatterOutput as string,
        text: localized(
          "formatter.fields.header." + FormatterDataSource.FormatterOutput
        ),
        itemType: DropdownMenuItemType.Header,
      };

      const formatterOutputItems = extractFormatterOutputItems(
        steps,
        selectedStepIndex,
        selectorType
      );

      const formatterOutputFields = formatterOutputItems
        .filter(field => !withoutMultipleValueType || !field.hasMultipleValue)
        .map(
          formatterInputField =>
            ({
              key: formatterInputField.selector,
              text: localizeSelector(formatterInputField.selector, true),
              itemType: DropdownMenuItemType.Normal,
              isFormatterOutput: true,
            } as FormatterDropdownOptionField)
        );

      const originalFormatterOutputs =
        FormatterMapper.fromStorage(formatterSource)
          ?.steps.filter(step => step.outputAs.enabled)
          .map(step => step.outputAs.fields.map(field => field.name))
          .flat() ?? [];

      const prebuiltSelectors = getExtractorFieldSchemaTableSelectors(
        extractorFieldSchemaTable,
        selectorType,
        (_, name, __) => !originalFormatterOutputs.includes(name)
      );

      const prebuiltFields = prebuiltSelectors.reduce((fields, selector) => {
        const fieldTexts = fields.map(field => field.text);
        const selectorText = localizeSelector(selector);
        if (fieldTexts.includes(selectorText)) {
          return fields;
        }
        const field = {
          key: selector,
          text: selectorText,
          itemType: DropdownMenuItemType.Normal,
        } as FormatterDropdownOptionField;
        return fields.concat([field]);
      }, [] as FormatterDropdownOptionField[]);

      const extraFields = withSelectors
        .filter(selector => {
          return (
            createFormatterSelector(selector).type === selectorType &&
            !prebuiltSelectors.includes(selector) &&
            !formatterOutputItems.some(item => item.selector === selector)
          );
        })
        .map(
          selector =>
            ({
              key: selector,
              text: localizeSelector(selector),
              itemType: DropdownMenuItemType.Normal,
            } as FormatterDropdownOptionField)
        );

      return formatDropdownOptions([
        {
          fields: prebuiltFields
            .concat(extraFields)
            .sort((a, b) => a.text.localeCompare(b.text)),
        },
        {
          fields: formatterOutputFields.sort((a, b) =>
            a.text.localeCompare(b.text)
          ),
          header: formatterOutputHeader,
        },
      ]);
    },
    [
      selectedStep?.action,
      localized,
      steps,
      selectedStepIndex,
      formatterSource,
      extractorFieldSchemaTable,
      localizeSelector,
    ]
  );

  const createOutputFieldNamme = React.useCallback(
    (name: string) => {
      let index = 1;
      const chosenNames = steps
        .map(step => {
          return step.outputAs.fields.map(field => {
            return field.name;
          });
        })
        .flat();

      while (true) {
        const newFieldName = name + index;
        if (!chosenNames.includes(newFieldName)) {
          return newFieldName;
        }
        index++;
      }
    },
    [steps]
  );

  const addInputSelection = React.useCallback(
    (selector: string) => {
      if (
        steps[selectedStepIndex].inputSelection.fields.some(
          field => field.selector === selector
        )
      ) {
        return;
      }

      const newSteps = produce(steps, draft => {
        draft[selectedStepIndex].inputSelection.fields.push({
          selector,
        });
        if (
          steps[selectedStepIndex].outputAs.fields.length <=
          steps[selectedStepIndex].inputSelection.fields.length
        ) {
          const { name } = createFormatterSelector(selector, true);
          draft[selectedStepIndex].outputAs.fields.push({
            name: createOutputFieldNamme(name),
          });
        }
      });

      updateFormatter(newSteps);
    },
    [steps, selectedStepIndex, updateFormatter, createOutputFieldNamme]
  );

  const removeInputSelection = React.useCallback(
    (selector: string) => {
      const newSteps = produce(steps, draft => {
        const index = draft[selectedStepIndex].inputSelection.fields.findIndex(
          field => field.selector === selector
        );

        draft[selectedStepIndex].inputSelection.fields.splice(index, 1);
        draft[selectedStepIndex].outputAs.fields.splice(index, 1);
      });
      updateFormatter(newSteps);
    },
    [selectedStepIndex, steps, updateFormatter]
  );

  const setSelectedMatchingConditionEnabled = React.useCallback(
    (enabled: boolean) => {
      const newSteps = produce(steps, draft => {
        draft[selectedStepIndex].matchingCondition.enabled = enabled;
      });
      updateFormatter(newSteps);
    },
    [steps, selectedStepIndex, updateFormatter]
  );

  const setSelectedMatchingConditionRule = React.useCallback(
    (selector: string, operator: string, value: string) => {
      const newSteps = produce(steps, draft => {
        const newRule: FormatterMatchingConditionRule = {
          selector,
          operator: operator as FormatterMatchingConditionOperator,
          value,
        };

        if (draft[selectedStepIndex].matchingCondition.rules.length > 0) {
          draft[selectedStepIndex].matchingCondition.rules[0] = newRule;
        } else {
          draft[selectedStepIndex].matchingCondition.rules.push(newRule);
        }
      });
      updateFormatter(newSteps);
    },
    [selectedStepIndex, steps, updateFormatter]
  );

  const setSelectedOutputAsEnabled = React.useCallback(
    (enabled: boolean) => {
      const newSteps = produce(steps, draft => {
        draft[selectedStepIndex].outputAs.enabled = enabled;
      });
      updateFormatter(newSteps);
    },
    [steps, selectedStepIndex, updateFormatter]
  );

  const saveFormatter = React.useCallback(() => {
    const formatterStorage = FormatterMapper.toStorage(formatter);
    onSaveFormatter(formatterStorage)
      .then(() => {
        setIsFailedToSaveFormatter(false);
        setIsFormatterNotSaved(false);
      })
      .catch(() => {
        setIsFailedToSaveFormatter(true);
        setIsFormatterNotSaved(true);
      });
  }, [formatter, onSaveFormatter]);

  const selectedOutputAsFields = React.useMemo(() => {
    if (!selectedStep) {
      return [];
    }
    const zip = (rows: any) =>
      rows[0].map((_: any, c: any) => rows.map((row: any) => row[c]));

    const items = zip([
      selectedStep.inputSelection.fields,
      selectedStep.outputAs.fields,
    ]);

    return items.map(
      ([input, output]: [
        FormatterInputSelectionField,
        FormatterOutputAsField
      ]) => {
        return {
          input: input.selector,
          output: output.name,
        };
      }
    );
  }, [selectedStep]);

  const setSelectedOutputAsFieldName = React.useCallback(
    (index: number, name: string) => {
      const newSteps = produce(steps, draft => {
        draft[selectedStepIndex].outputAs.fields[index].name = name;
      });
      updateFormatter(newSteps);
    },
    [selectedStepIndex, steps, updateFormatter]
  );

  const setSelectedConfig = React.useCallback(
    (config: { [key: string]: any }) => {
      const newSteps = produce(steps, draft => {
        draft[selectedStepIndex].config = config;
      });
      updateFormatter(newSteps);
    },
    [selectedStepIndex, steps, updateFormatter]
  );

  const { requestUserConfirmation } = useConfirmModalActionCreator();

  const removeStep = React.useCallback(
    (index: number) => {
      const newSteps = produce(steps, draft => {
        draft.splice(index, 1);
      });
      selectStep(-1);
      updateFormatter(newSteps);
    },
    [steps, updateFormatter, selectStep]
  );

  const requestToRemoveStep = React.useCallback(
    (index: number) => {
      requestUserConfirmation({
        titleId: "formatter.confirm_delete_step.title",
        actionId: "common.delete",
        messageId: "formatter.confirm_delete_step.message",
        type: ConfirmModalType.Destory,
      })
        .then(value => {
          if (value) {
            removeStep(index);
          }
        })
        .catch(() => {});
    },
    [removeStep, requestUserConfirmation]
  );

  const supportedActions = React.useMemo(() => {
    const keys = Object.keys(FormatterActionType);
    return keys.map(key => {
      const action =
        FormatterActionType[key as keyof typeof FormatterActionType];
      return {
        action,
        title: `formatter.actions.${action}.title`,
        desc: `formatter.actions.${action}.card.desc`,
      };
    });
  }, []);

  const validationResult = React.useMemo(() => {
    if (!formatter) {
      return {
        hasError: false,
        steps: [],
      };
    }
    const originalFormatterOutputs =
      FormatterMapper.fromStorage(formatterSource)
        ?.steps.filter(step => step.outputAs.enabled)
        .map(step => step.outputAs.fields.map(field => field.name))
        .flat() ?? [];
    const existingInputFields = removeFormatterOutputFromExtractorField(
      extractorFieldSchemaTable ?? {},
      originalFormatterOutputs
    );

    const validator = new FormatterValidator();
    return validator.validateFormatter(formatter, existingInputFields);
  }, [formatter, formatterSource, extractorFieldSchemaTable]);

  return React.useMemo(
    () => ({
      extractorId,
      extractorFieldSchemaTable,
      steps,
      addStep,
      supportedActions,
      requestToAddStep,
      isAddingStep,
      saveFormatter,
      selectedStepIndex,
      getInputFieldsDropdownOptions,
      addInputSelection,
      removeInputSelection,
      selectedStep,
      setSelectedMatchingConditionEnabled,
      setSelectedMatchingConditionRule,
      setSelectedOutputAsEnabled,
      selectedOutputAsFields,
      localizeSelector,
      setSelectedOutputAsFieldName,
      selectStep,
      setSelectedConfig,
      requestToRemoveStep,
      validationResult,
      isFailedToSaveFormatter,
      isFormatterNotSaved,
    }),
    [
      extractorId,
      extractorFieldSchemaTable,
      steps,
      addStep,
      supportedActions,
      requestToAddStep,
      isAddingStep,
      saveFormatter,
      selectedStepIndex,
      getInputFieldsDropdownOptions,
      addInputSelection,
      removeInputSelection,
      selectedStep,
      setSelectedMatchingConditionEnabled,
      setSelectedMatchingConditionRule,
      setSelectedOutputAsEnabled,
      selectedOutputAsFields,
      localizeSelector,
      setSelectedOutputAsFieldName,
      selectStep,
      setSelectedConfig,
      requestToRemoveStep,
      validationResult,
      isFailedToSaveFormatter,
      isFormatterNotSaved,
    ]
  );
}

type FormatterEditorContextValue = ReturnType<typeof useMakeContext>;
const FormatterEditorContext = React.createContext<FormatterEditorContextValue>(
  null as any
);

interface Props extends FormatterContextProps {
  children: React.ReactNode;
}

export const FormatterEditorProvider = (props: Props) => {
  const {
    extractorId,
    formatterSource,
    onSaveFormatter,
    onUpdateFormatter,
    ...rest
  } = props;
  const value = useMakeContext({
    extractorId,
    formatterSource,
    onSaveFormatter,
    onUpdateFormatter,
  });
  return <FormatterEditorContext.Provider {...rest} value={value} />;
};

export function useFormatterEditor() {
  return React.useContext(FormatterEditorContext);
}
