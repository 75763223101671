import { IPivotItemProps, Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

import { isEditablePrebuiltExtractors } from "../../constants/prebuiltExtractor";
import { DetectionRegionInspectorProvider } from "../../contexts/detectionRegionInspector";
import { useFormEditor } from "../../contexts/formEditor";
import { FormEditorTab } from "../../models";
import { Anchor } from "../../types/anchor";
import { DetectionRegion } from "../../types/detectionRegion";
import { DetailedForm } from "../../types/form";
import AnchorInspector from "../AnchorInspector";
import DateTimeFieldModal from "../DateTimeFieldModal";
import DetectionRegionInspectorV2 from "../DetectionRegionInspectorV2";
import DocumentTabPane from "../DocumentTabPane";
import EditKeywordsModal from "../EditKeywordsModal";
import InfoNote from "../InfoNote";
import LLMCompletionFieldModal from "../LLMCompletionFieldModal";
import MinimalFieldModal from "../MinimalFieldModal";
import ScriptEditorModal from "../ScriptEditorModal";
import TabBarContentLayout from "../TabBarContentLayout";
import TextFieldModal from "../TextFieldModal";
import styles from "./styles.module.scss";

enum StateType {
  error,
  anchorSelected,
  detectionRegionSelected,
  nothingSelected,
}

interface Error {
  type: StateType.error;
}

interface AnchorSelected {
  type: StateType.anchorSelected;
  selectedAnchorId: string;
  selectedAnchor: Anchor;
}
interface NothingSelected {
  type: StateType.nothingSelected;
}

interface DetectionRegionSelected {
  type: StateType.detectionRegionSelected;
  selectedDetectionRegion: DetectionRegion;
  selectedDetectionRegionId: string;
}

const error: Error = {
  type: StateType.error,
};

const anchorSelected = (
  selectedAnchor: Anchor,
  selectedAnchorId: string
): AnchorSelected => ({
  type: StateType.anchorSelected,
  selectedAnchor,
  selectedAnchorId,
});

const detectionRegionSelected = (
  selectedDetectionRegion: DetectionRegion,
  selectedDetectionRegionId: string
): DetectionRegionSelected => ({
  type: StateType.detectionRegionSelected,
  selectedDetectionRegion,
  selectedDetectionRegionId,
});

const nothingSelected: NothingSelected = {
  type: StateType.nothingSelected,
};

type State = Error | AnchorSelected | DetectionRegionSelected | NothingSelected;

const useFormInspectorState = (form: DetailedForm): State => {
  const { selectedAnchorId, selectedDetectionRegionId } = useFormEditor();

  if (selectedAnchorId) {
    const selectedAnchor = form.anchors.find(
      ({ id }) => id === selectedAnchorId
    );
    if (!selectedAnchor) {
      return error;
    }
    return anchorSelected(selectedAnchor, selectedAnchorId);
  }

  if (selectedDetectionRegionId) {
    const selectedDetectionRegion = form.detectionRegions.find(
      ({ id }) => id === selectedDetectionRegionId
    );

    if (!selectedDetectionRegion) {
      return error;
    }

    return detectionRegionSelected(
      selectedDetectionRegion,
      selectedDetectionRegionId
    );
  }
  return nothingSelected;
};

interface Props {
  form: DetailedForm;
  selectedTab: FormEditorTab;
  onSelectFormInspectorTab: (tabClicked: string) => void;
}

const FormInspectorV2 = React.memo((props: Props) => {
  const { form, selectedTab, onSelectFormInspectorTab } = props;

  const {
    selectedAnchorId,
    updateAnchor,
    deleteAnchor,
    onSelectAnchor,
    scriptModalPayload,
    onCloseDetectionRegionFieldScriptModal,
    keywordsModalPayload,
    onCloseDetectionRegionFieldKeywordModal,
    textFieldModalPayload,
    onCloseTextFieldModal,
    dateTimeFieldModalPayload,
    onCloseDateTimeFieldModal,
    minimalFieldModalPayload,
    onCloseMinimalFieldModal,
    llmCompletionFieldModalPayload,
    onCloseLLMCompletionFieldModal,
  } = useFormEditor();

  const state = useFormInspectorState(form);

  const onTabBarItemClicked = useCallback(
    (item?: PivotItem) => {
      if (item !== undefined && item.props.itemKey) {
        onSelectFormInspectorTab(item.props.itemKey);
      }
    },
    [onSelectFormInspectorTab]
  );

  const renderTab = useCallback(
    (
      link?: IPivotItemProps,
      defaultRenderer?: (link: IPivotItemProps) => JSX.Element | null
    ): JSX.Element | null => {
      if (!link || !defaultRenderer || !link.headerText || !link.itemKey) {
        return null;
      }
      return <FormattedMessage id={link.headerText} />;
    },
    []
  );

  const onChangeAnchorEnabled = useCallback(
    (checked: boolean) => {
      if (!selectedAnchorId) {
        return;
      }

      const selectedAnchor = form.anchors.find(
        ({ id }) => id === selectedAnchorId
      );

      if (selectedAnchor) {
        selectedAnchor.is_enabled = checked;
        updateAnchor(selectedAnchor);
      }
    },
    [form, updateAnchor, selectedAnchorId]
  );

  const onDeleteAnchor = useCallback(() => {
    if (!selectedAnchorId) {
      return;
    }
    onSelectAnchor(undefined);
    deleteAnchor(selectedAnchorId);
  }, [selectedAnchorId, onSelectAnchor, deleteAnchor]);

  const isEditablePrebuiltExtractor =
    form.config.prebuilt_extractor &&
    isEditablePrebuiltExtractors(form.config.prebuilt_extractor);

  if (isEditablePrebuiltExtractor) {
    return null;
  }

  return (
    <DetectionRegionInspectorProvider>
      {scriptModalPayload && (
        <ScriptEditorModal
          payload={scriptModalPayload}
          onCloseModal={onCloseDetectionRegionFieldScriptModal}
        />
      )}
      {keywordsModalPayload && (
        <EditKeywordsModal
          payload={keywordsModalPayload}
          onCloseModal={onCloseDetectionRegionFieldKeywordModal}
        />
      )}
      {textFieldModalPayload && (
        <TextFieldModal
          payload={textFieldModalPayload}
          onCloseModal={onCloseTextFieldModal}
        />
      )}
      {dateTimeFieldModalPayload && (
        <DateTimeFieldModal
          payload={dateTimeFieldModalPayload}
          onCloseModal={onCloseDateTimeFieldModal}
        />
      )}
      {minimalFieldModalPayload && (
        <MinimalFieldModal
          payload={minimalFieldModalPayload}
          onCloseModal={onCloseMinimalFieldModal}
        />
      )}
      {llmCompletionFieldModalPayload && (
        <LLMCompletionFieldModal
          payload={llmCompletionFieldModalPayload}
          onCloseModal={onCloseLLMCompletionFieldModal}
        />
      )}
      <Pivot
        className={styles["form-inspector"]}
        linkFormat={"tabs"}
        selectedKey={isEditablePrebuiltExtractor ? "settings" : selectedTab}
        onLinkClick={onTabBarItemClicked}
      >
        <PivotItem
          itemKey="region"
          headerText={"form_inspector.tab.header.region"}
          onRenderItemLink={renderTab}
        >
          <TabBarContentLayout>
            {state.type === StateType.detectionRegionSelected && (
              <DetectionRegionInspectorV2
                selectedDetectionRegionId={state.selectedDetectionRegionId}
                selectedDetectionRegion={state.selectedDetectionRegion}
              />
            )}
            {state.type === StateType.anchorSelected && (
              <AnchorInspector
                isEnabled={state.selectedAnchor.is_enabled}
                onChangeAnchorEnabled={onChangeAnchorEnabled}
                onDeleteAnchor={onDeleteAnchor}
              />
            )}
            {state.type === StateType.nothingSelected && (
              <div className={styles["nothing-detected-desc"]}>
                <InfoNote notes={["form_inspector.nothing_detected.desc"]} />
              </div>
            )}
          </TabBarContentLayout>
        </PivotItem>
        <PivotItem
          itemKey="document"
          headerText={"form_inspector.tab.header.document"}
          onRenderItemLink={renderTab}
        >
          {form && (
            <TabBarContentLayout>
              <DocumentTabPane />
            </TabBarContentLayout>
          )}
        </PivotItem>
      </Pivot>
    </DetectionRegionInspectorProvider>
  );
});

export default FormInspectorV2;
