import amexCardIcon from "../images/card/icon-card-amex.svg";
import dinersCardIcon from "../images/card/icon-card-diners.svg";
import discoverCardIcon from "../images/card/icon-card-discover.svg";
import jcbCardIcon from "../images/card/icon-card-jcb.svg";
import mastercardCardIcon from "../images/card/icon-card-mastercard.svg";
import unionpayCardIcon from "../images/card/icon-card-unionpay.svg";
import visaCardIcon from "../images/card/icon-card-visa.svg";

export const CARD_BRAND_ICON_MAP: {
  [key: string]: string;
} = {
  amex: amexCardIcon,
  diners: dinersCardIcon,
  discover: discoverCardIcon,
  jcb: jcbCardIcon,
  mastercard: mastercardCardIcon,
  unionpay: unionpayCardIcon,
  visa: visaCardIcon,
};
