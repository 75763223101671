import cn from "classnames";
import * as React from "react";

import { useDragTracker } from "../../hooks/dragTracker";
import styles from "./styles.module.scss";

const DEFAULT_MIN_WIDTH = 100;

function HandlePanel(props: {
  dragHandleRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}) {
  return (
    <div className={styles["handle-panel-container"]}>
      <div className={styles["handle-panel-content"]}>{props.children}</div>
      <div
        className={styles["handle-panel-handle"]}
        ref={props.dragHandleRef}
      ></div>
    </div>
  );
}

type Props = {
  className?: string;
  left?: React.ReactNode;
  leftMinWidth?: number;
  leftFlexGrow?: number;

  right?: React.ReactNode;
  rightMinWidth?: number;
  rightFlexGrow?: number;
};

export function useHSplitViewState(props: Props) {
  const dragHandleRef = React.useRef<HTMLDivElement>(null);
  const leftHandleRef = React.useRef<HTMLDivElement>(null);
  const rightHandleRef = React.useRef<HTMLDivElement>(null);

  const leftStartWidth = React.useRef<number>(0);
  const rightStartWidth = React.useRef<number>(0);
  const leftMinWidth = props.leftMinWidth ?? DEFAULT_MIN_WIDTH;
  const rightMinWidth = props.rightMinWidth ?? DEFAULT_MIN_WIDTH;
  const leftFlexGrow = props.leftFlexGrow ?? 1;
  const rightFlexGrow = props.rightFlexGrow ?? 1;

  const onDragStart = React.useCallback(() => {
    leftStartWidth.current = leftHandleRef.current?.clientWidth ?? 0;
    rightStartWidth.current = rightHandleRef.current?.clientWidth ?? 0;
  }, []);

  const onDragMove = React.useCallback(
    (dx: number) => {
      if (rightHandleRef.current === null || leftHandleRef.current === null) {
        return;
      }
      const totalWidth = leftStartWidth.current + rightStartWidth.current;

      let newLeftWidth = leftStartWidth.current + dx;
      let newRightWidth = rightStartWidth.current - dx;

      if (newLeftWidth < leftMinWidth) {
        newLeftWidth = leftMinWidth;
        newRightWidth = totalWidth - newLeftWidth;
      }

      if (newRightWidth < rightMinWidth) {
        newRightWidth = rightMinWidth;
        newLeftWidth = totalWidth - newRightWidth;
      }

      leftHandleRef.current.style.flex = "none";
      leftHandleRef.current.style.flexGrow = `${
        leftHandleRef.current.clientWidth / totalWidth
      }`;

      rightHandleRef.current.style.flex = "none";
      rightHandleRef.current.style.flexGrow = `${
        rightHandleRef.current.clientWidth / totalWidth
      }`;

      leftHandleRef.current.style.width = `${newLeftWidth}px`;
      rightHandleRef.current.style.width = `${newRightWidth}px`;
    },
    [leftMinWidth, rightMinWidth]
  );

  useDragTracker(dragHandleRef, { onDragStart, onDragMove });

  React.useEffect(() => {
    if (leftHandleRef.current === null || rightHandleRef.current === null) {
      return;
    }

    leftHandleRef.current.style.flex = `${leftFlexGrow}`;
    rightHandleRef.current.style.flex = `${rightFlexGrow}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.useMemo(() => {
    return {
      ...props,
      leftMinWidth,
      rightMinWidth,
      dragHandleRef,
      leftHandleRef,
      rightHandleRef,
      leftFlexGrow,
      rightFlexGrow,
    };
  }, [
    props,
    dragHandleRef,
    leftHandleRef,
    rightHandleRef,
    leftMinWidth,
    rightMinWidth,
    leftFlexGrow,
    rightFlexGrow,
  ]);
}

export function HSplitView(props: Props) {
  const states = useHSplitViewState(props);
  return <HSplitViewImpl {...states} />;
}

export function HSplitViewImpl(props: ReturnType<typeof useHSplitViewState>) {
  const { left, right, dragHandleRef, leftHandleRef, rightHandleRef } = props;

  const items = [left, right].filter(item => item !== undefined);
  const containerClasses = cn(styles["container"], props.className);

  if (items.length === 0) {
    return <div className={containerClasses}></div>;
  } else if (items.length === 1) {
    return (
      <div className={containerClasses}>
        <div className={styles["panel"]}>{items[0]}</div>
      </div>
    );
  } else {
    return (
      <div className={containerClasses}>
        <div
          className={styles["panel"]}
          ref={leftHandleRef}
          style={{
            minWidth: props.leftMinWidth,
          }}
        >
          {left}
        </div>
        <div
          className={styles["panel"]}
          ref={rightHandleRef}
          style={{
            minWidth: props.rightMinWidth,
          }}
        >
          <HandlePanel dragHandleRef={dragHandleRef}>{right}</HandlePanel>
        </div>
      </div>
    );
  }
}
