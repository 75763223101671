import { IBreadcrumbItem } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import {
  WorkspaceNavBarLayout,
  WorkspaceTab,
} from "../components/WorkspaceNavBar";
import { useLocale } from "../contexts/locale";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { PathParam } from "../models";
import { getExtractorHref } from "../types/extractor";

interface WorkspaceNavBarLayoutContainerProps {
  selectedTab: WorkspaceTab;
  children?: React.ReactNode;
  rightPanel?: React.ReactNode;
  showRightPanel?: boolean;
}

function useWorkspaceNavBarLayoutContainer(
  args: WorkspaceNavBarLayoutContainerProps
) {
  const { selectedTab, children, rightPanel, showRightPanel } = args;
  const { workspaceId } = useUnsafeParams<PathParam>();
  const currentWorkspace = useAppSelector(
    state => state.workspace.currentWorkspace
  );
  const navigate = useNavigate();
  const searchParam = useSearchParams()[0];
  const { localized } = useLocale();

  const onBreadcrumbItemClick = React.useCallback(
    (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
      if (ev === undefined || item === undefined || item.href === undefined) {
        return;
      }
      ev.preventDefault();
      navigate(item.href);
    },
    [navigate]
  );

  const breadcrumbItems = React.useMemo<IBreadcrumbItem[]>(
    () => [
      {
        text: localized("workspace.breadcrumb.root"),
        key: "workspace",
        href: "/workspace",
        onClick: onBreadcrumbItemClick,
      },
      {
        key: workspaceId,
        text: currentWorkspace?.config.name ?? "",
      },
    ],
    [
      localized,
      onBreadcrumbItemClick,
      workspaceId,
      currentWorkspace?.config.name,
    ]
  );

  const onTabSelect = React.useCallback(
    (tab: string) => {
      navigate(`/workspace/${workspaceId}/${tab}?${searchParam}`);
    },
    [navigate, workspaceId, searchParam]
  );

  return React.useMemo(
    () => ({
      selectedTab,
      breadcrumbItems,
      onTabSelect,
      children,
      rightPanel,
      showRightPanel,
      extractorName: currentWorkspace?.extractor?.name ?? null,
      extractorHref:
        currentWorkspace?.extractor != null
          ? getExtractorHref(currentWorkspace.extractor)
          : null,
    }),
    [
      breadcrumbItems,
      children,
      currentWorkspace,
      onTabSelect,
      rightPanel,
      selectedTab,
      showRightPanel,
    ]
  );
}

export function WorkspaceNavBarLayoutContainer(
  args: WorkspaceNavBarLayoutContainerProps
) {
  const props = useWorkspaceNavBarLayoutContainer(args);
  return (
    <WorkspaceNavBarLayout
      breadcrumbItems={props.breadcrumbItems}
      onTabSelect={props.onTabSelect}
      selectedTab={props.selectedTab}
      rightPanel={props.rightPanel}
      showRightPanel={props.showRightPanel}
      extractorName={props.extractorName}
      extractorHref={props.extractorHref}
    >
      {props.children}
    </WorkspaceNavBarLayout>
  );
}

export default WorkspaceNavBarLayoutContainer;
