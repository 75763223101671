const commonTypeDefinitions = `
interface Symbol {
  vertices: number[];
  value: string;
}

interface Word {
  vertices: number[];
  value: string;
  symbols: Symbol[];
  confidence: number | null;
}

interface TextBox {
  vertices: number[];
  orientation: "Up" | "Left" | "Right" | "Down";
  lines: {
    words: Word[];
  };
}

declare let result: any;

interface Match {
  start: number;
  end: number;
  dist: number;
  matched: string;
}

interface FuzzySearchOptions {
  max_subs: number;
  max_insert: number;
  max_delete: number;
  max_l_dist: number;
}

declare function fuzzy_search(
  pattern: string,
  text: string,
  option: FuzzySearchOptions
): Match[];

declare function edit_distance(string1: string, string2: string): number;
`;

export const typeDefinitionsForDetectionRegionField =
  commonTypeDefinitions +
  `
declare const input: {
  text: string;
  textbox: TextBox;
};
`;

export const typeDefinitionsForPostProcessScript =
  commonTypeDefinitions +
  `
declare const input: any;
`;
