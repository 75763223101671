import {
  Checkbox,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { ButtonId } from "../../constants/buttonids";
import { useFSLCustomModelEditor } from "../../contexts/fslCustomModelEditor";
import { useLocale } from "../../contexts/locale";
import tableExampleImage from "../../images/fsl/table-example.svg";
import {
  ExtractedContentSchemaAccessor,
  ExtractedContentSchemaField,
  ExtractedContentSchemaType,
} from "../../types/extractedContentSchema";
import {
  ExtractedContentSchemaValidationResult,
  ExtractedContentSchemaValidationResultAccessor,
} from "../../validators/extractedContentSchemaValidator";
import { Img } from "../Img";
import { ActionButton } from "../WrappedMSComponents/Buttons";
import TextField from "../WrappedMSComponents/TextField";
import { GridView, SubFieldRow } from "./GridView";
import styles from "./styles.module.scss";

function onRenderFieldItemEditPanelItem(option: IDropdownOption | undefined) {
  const text = option?.text ?? "";
  const data = option?.data ?? {};
  const subtext = data["subtext"] ?? "";

  return (
    <>
      {subtext !== "" ? (
        <div className={styles["field-item-edit-panel-complex-option"]}>
          <div>{text}</div>
          <div
            className={styles["field-item-edit-panel-complex-option-sub-text"]}
          >
            {subtext}
          </div>
        </div>
      ) : (
        <div>{text}</div>
      )}
    </>
  );
}

interface FieldItemEditPanelProps {
  editingExtractedContentSchemaValidationResult: ExtractedContentSchemaValidationResult;
}

export function useFieldItemEditPanelState(props: FieldItemEditPanelProps) {
  const { localized } = useLocale();

  const {
    editingExtractedContentSchema,
    selectedEditingSchemaFieldIndex,
    setSelectedEditingSchemaField,
  } = useFSLCustomModelEditor();

  const { editingExtractedContentSchemaValidationResult } = props;

  const editingField = React.useMemo(() => {
    return (
      editingExtractedContentSchema?.payload[
        selectedEditingSchemaFieldIndex ?? 0
      ] ?? {
        id: "",
        name: "",
        type: "",
        isList: false,
        definitionId: null,
        description: undefined,
      }
    );
  }, [editingExtractedContentSchema, selectedEditingSchemaFieldIndex]);

  const { name, type, isList, id, description } = editingField;

  const options = React.useMemo(() => {
    return Object.entries(ExtractedContentSchemaType).map(([key, value]) => {
      const data = {} as Record<string, string>;

      if (value === ExtractedContentSchemaType.FieldGroup) {
        data["subtext"] = localized(
          "fsl_custom_model.field_type.field_group.desc"
        );
      }

      return {
        key: value,
        text: localized(
          `fsl_custom_model.field_type.${
            ExtractedContentSchemaType[
              key as keyof typeof ExtractedContentSchemaType
            ]
          }`
        ),
        data,
      };
    }) as IDropdownOption[];
  }, [localized]);

  const onNameChanged = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newField = {
        ...editingField,
        name: event.currentTarget.value,
      } as ExtractedContentSchemaField;
      setSelectedEditingSchemaField(newField);
    },
    [setSelectedEditingSchemaField, editingField]
  );

  const onTypeChanged = React.useCallback(
    (_ev?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      const newField = {
        ...editingField,
        type: option?.key as ExtractedContentSchemaType,
      } as ExtractedContentSchemaField;
      setSelectedEditingSchemaField(newField);
    },
    [setSelectedEditingSchemaField, editingField]
  );

  const onIsListChanged = React.useCallback(
    (
      _ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
      checked?: boolean
    ) => {
      const newField = {
        ...editingField,
        isList: checked ?? false,
      } as ExtractedContentSchemaField;
      setSelectedEditingSchemaField(newField);
    },
    [setSelectedEditingSchemaField, editingField]
  );

  const onDescriptionChanged = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newField = {
        ...editingField,
        description: event.currentTarget.value,
      } as ExtractedContentSchemaField;
      setSelectedEditingSchemaField(newField);
    },
    [setSelectedEditingSchemaField, editingField]
  );

  const nameFieldError = React.useMemo(() => {
    const validationResultAccessor =
      new ExtractedContentSchemaValidationResultAccessor(
        editingExtractedContentSchemaValidationResult
      );
    const result = validationResultAccessor.getPayloadResultFromId(
      editingField.id
    );
    if (result.isEmptyFieldName) {
      return localized(
        "fsl_custom_model.field_schema_editor.error.field_required_error"
      );
    }

    if (result.isDuplicatedFieldName) {
      return localized(
        "fsl_custom_model.field_schema_editor.error.duplicated_field_name_error"
      );
    }

    if (result.isInvalidFormatName) {
      return localized(
        "fsl_custom_model.field_schema_editor.error.invalid_field_name_error"
      );
    }
    return "";
  }, [
    editingExtractedContentSchemaValidationResult,
    localized,
    editingField.id,
  ]);

  const subFields = React.useMemo(() => {
    if (editingExtractedContentSchema == null) {
      return [];
    }

    const definition = new ExtractedContentSchemaAccessor(
      editingExtractedContentSchema
    ).findDefinitionById(editingField.definitionId ?? "");

    const fields = definition?.fields ?? [];
    const validationResultAccessor =
      new ExtractedContentSchemaValidationResultAccessor(
        editingExtractedContentSchemaValidationResult
      );
    const payloadResult = validationResultAccessor.getPayloadResultFromId(
      editingField.id
    );

    return fields.map(field => {
      const hasDuplicatedFieldError =
        payloadResult.duplicatedSubFieldNames.includes(field.name);
      const hasInvalidFormatFieldError =
        payloadResult.invalidFormatSubFieldNames.includes(field.name);

      return {
        ...field,
        hasEmptyFieldError:
          hasInvalidFormatFieldError && field.name.trim().length === 0,
        hasDuplicatedFieldError,
        hasInvalidFormatFieldError,
      };
    });
  }, [
    editingExtractedContentSchema,
    editingExtractedContentSchemaValidationResult,
    editingField.id,
    editingField.definitionId,
  ]);

  return React.useMemo(() => {
    return {
      id,
      name,
      type,
      isList,
      options,
      description,
      nameFieldError,
      onNameChanged,
      onTypeChanged,
      onIsListChanged,
      onDescriptionChanged,
      subFields,
    };
  }, [
    id,
    name,
    type,
    isList,
    options,
    description,
    nameFieldError,
    onNameChanged,
    onTypeChanged,
    onIsListChanged,
    onDescriptionChanged,
    subFields,
  ]);
}

export function FieldItemEditPanelImpl(
  props: ReturnType<typeof useFieldItemEditPanelState>
) {
  const {
    name,
    type,
    description,
    isList,
    options,
    nameFieldError,
    onNameChanged,
    onTypeChanged,
    onIsListChanged,
    onDescriptionChanged,
    subFields,
  } = props;
  const { localized } = useLocale();
  const { addEditingSchemaSubField } = useFSLCustomModelEditor();

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdownItem: {
      height: "auto",
    },
    dropdownOptionText: { overflow: "visible", whiteSpace: "normal" },
  };

  return (
    <div className={styles["field-item-edit-panel-container"]}>
      <div className={styles["field-item-edit-panel"]}>
        <div className={styles["field-item-edit-panel-header"]}>
          <FormattedMessage id="fsl_custom_model.field_schema_editor.field_edit_panel.title" />
        </div>

        <div className={styles["field-item-edit-panel-content"]}>
          <TextField
            className={styles["field-item-edit-panel-name-field"]}
            labelId="fsl_custom_model.field_schema_editor.field_edit_panel.name.label"
            value={name}
            onChange={onNameChanged}
            errorMessage={nameFieldError}
            placeholder={localized(
              "fsl_custom_model.field_schema_editor.fields.field_name_placeholder"
            )}
            autoFocus={true}
          />

          <Dropdown
            className={styles["field-item-edit-panel-type-field"]}
            label={localized(
              "fsl_custom_model.field_schema_editor.field_edit_panel.type.label"
            )}
            styles={dropdownStyles}
            options={options}
            selectedKey={type}
            onChange={onTypeChanged}
            onRenderOption={onRenderFieldItemEditPanelItem}
          />

          {type !== ExtractedContentSchemaType.FieldGroup && (
            <>
              <Checkbox
                className={styles["field-item-edit-panel-is-list-field"]}
                label={localized(
                  "fsl_custom_model.field_schema_editor.field_edit_panel.is_list.label"
                )}
                checked={isList}
                onChange={onIsListChanged}
              />
            </>
          )}

          <TextField
            id={ButtonId.ManageFieldsDescription}
            className={styles["field-item-edit-panel-name-field"]}
            labelId="fsl_custom_model.field_schema_editor.field_edit_panel.description.label"
            value={description ?? ""}
            onChange={onDescriptionChanged}
            placeholder={localized(
              "fsl_custom_model.field_schema_editor.field_edit_panel.description.placeholder"
            )}
            multiline
            rows={4}
          />
          {type === ExtractedContentSchemaType.FieldGroup && (
            <>
              <div className={styles["field-item-edit-panel-header"]}>
                <FormattedMessage id="fsl_custom_model.field_schema_editor.field_edit_panel.subfield.title" />

                <ActionButton
                  iconName="Add"
                  textId="fsl_custom_model.field_schema_editor.field_edit_panel.subfield.add_sub_fields"
                  onClick={addEditingSchemaSubField}
                />
              </div>
              <GridView>
                {subFields.map((subField, index) => {
                  return (
                    <SubFieldRow {...subField} index={index} key={index} />
                  );
                })}
              </GridView>

              <div className={styles["field-item-edit-panel-what-is-table"]}>
                <div
                  className={
                    styles["field-item-edit-panel-what-is-table-title"]
                  }
                >
                  <FormattedMessage id="fsl_custom_model.field_schema_editor.field_edit_panel.what_is_table.title" />
                </div>
                <div
                  className={styles["field-item-edit-panel-what-is-table-desc"]}
                >
                  <FormattedMessage id="fsl_custom_model.field_schema_editor.field_edit_panel.what_is_table.desc" />
                </div>
                <div>
                  <Img src={tableExampleImage} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export function FieldItemEditPanel(props: FieldItemEditPanelProps) {
  const states = useFieldItemEditPanelState(props);
  return <FieldItemEditPanelImpl {...states} />;
}
