import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { EXTRACTOR_PAGE_SIZE } from "../constants/layout";
import { FOCRError } from "../errors";
import { PendingState, useMergeableAsyncCallback } from "../hooks/common";
import { useGtm } from "../hooks/gtm";
import { useAppDispatch } from "../hooks/redux";
import { RootState } from "../redux/types";
import { accessPaginatedAsyncResult } from "../types/asyncResult";
import {
  ExtractorFieldSchemaTable,
  ExtractorOption,
  PaginatedExtractor,
  PrebuiltExtractor,
} from "../types/extractor";
import { ensureFOCRError } from "../utils/errors";
import { FormCreated, FormsInvalidated } from "./form";
import { FormGroupsInvalidated } from "./formGroup";

export const ListingExtractors = createAction(
  "extractor/listing",
  (page: number, filter: string, search?: string) => ({
    payload: {
      page,
      filter,
      search,
    },
  })
);

export const GotExtractors = createAction<{
  page: number;
  result: PaginatedExtractor;
}>("extractor/gotExtractors");

export const ListExtractorsFailed = createAction<{
  page: number;
  error: FOCRError;
}>("extractor/listFailed");

export const ListingExtractorOptions = createAction(
  "extractor/listingExtractorOptions"
);

export const GotExtractorOptions = createAction<ExtractorOption[]>(
  "extractor/gotExtractorOptions"
);

export const ListExtractorOptionsFailed = createAction<FOCRError>(
  "extractor/listExtractorOptionsFailed"
);

export const GettingExtractorFieldSchemaTable = createAction<string>(
  "extractor/gettingExtractorFieldSchemaTable"
);

export const GotExtractorFieldSchemaTable = createAction<{
  data: ExtractorFieldSchemaTable;
  extractorId: string;
}>("extractor/gotExtractorFieldSchemaTable");

export const GetExtractorFieldSchemaTableFailed = createAction<{
  error: FOCRError;
  extractorId: string;
}>("extractor/getExtractorFieldSchemaTableFailed");

const listExtractorsPendingState: PendingState<
  void,
  [string, number, number, string[], string | undefined]
> = {
  isRunning: false,
  promises: [],
  params: null,
};

const listExtractorOptionsPendingState: PendingState<void, [string]> = {
  isRunning: false,
  promises: [],
  params: null,
};

export function useExtractorActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();

  const importExtractor = useCallback(
    async (file: File) => {
      const { resourceOwnerId } = getState().resourceOwner;
      const url = apiClient.getEffectiveEndpoint("/import-extractor");

      const formData = new FormData();
      formData.append("file", file);
      if (resourceOwnerId) {
        formData.append("resource_owner_id", resourceOwnerId);
      }

      const response = await apiClient.fetch(url, {
        method: "POST",
        body: formData,
      });

      const { result } = await response.json();

      if (!result || result.status !== "ok") {
        throw result;
      }

      dispatch(FormGroupsInvalidated());
      dispatch(FormsInvalidated());

      return result;
    },
    [dispatch, getState]
  );

  const { pushCreatedExtractorEvent } = useGtm();

  const createPrebuiltExtractor = useCallback(
    async (name: string, type: PrebuiltExtractor) => {
      const { resourceOwnerId } = getState().resourceOwner;

      if (!resourceOwnerId) {
        return Promise.reject();
      }

      const result = await apiClient.createPrebuiltExtractor(
        resourceOwnerId,
        name,
        type
      );

      pushCreatedExtractorEvent(
        result.form?.id ?? result.formGroup?.id ?? "",
        name,
        "prebuilt",
        type
      );

      if (result.form) {
        dispatch(FormCreated(result.form));
      } else if (result.formGroup) {
        dispatch(FormGroupsInvalidated());
      }

      return result;
    },
    [dispatch, getState, pushCreatedExtractorEvent]
  );

  const listExtractors = useMergeableAsyncCallback(
    () => listExtractorsPendingState,
    async (
      resourceOwnerId: string,
      page: number,
      size: number = EXTRACTOR_PAGE_SIZE,
      types: string[],
      searchString?: string
    ) => {
      const state = getState();
      const isSameListParams = accessPaginatedAsyncResult<PaginatedExtractor>(
        state.extractor.paginatedExtractorList
      ).isSameCurrentPageParams(page - 1, types.join(","), searchString);

      if (isSameListParams) {
        return;
      }

      dispatch(ListingExtractors(page - 1, types.join(","), searchString));

      const cachedResult = accessPaginatedAsyncResult<PaginatedExtractor>(
        getState().extractor.paginatedExtractorList
      ).getCachedResult(page - 1);

      if (cachedResult) {
        dispatch(GotExtractors({ page: page - 1, result: cachedResult }));
        return;
      }

      try {
        const result = await apiClient.listExtractor(
          size,
          (page - 1) * size,
          resourceOwnerId,
          types,
          searchString
        );

        dispatch(GotExtractors({ page: page - 1, result }));
      } catch (e) {
        dispatch(
          ListExtractorsFailed({ page: page - 1, error: ensureFOCRError(e) })
        );
      }
    },
    [dispatch, getState]
  );

  const listExtractorOptions = useMergeableAsyncCallback(
    () => listExtractorOptionsPendingState,
    async (resourceOwnerId: string) => {
      dispatch(ListingExtractorOptions());

      try {
        const { extractors: extractorOptions } =
          await apiClient.listExtractorAsOption(0, 0, resourceOwnerId);

        dispatch(GotExtractorOptions(extractorOptions));
      } catch (e) {
        dispatch(ListExtractorOptionsFailed(ensureFOCRError(e)));
      }
    },
    [dispatch, getState]
  );

  const requestPreviewExtractor = useCallback(
    async (
      type: PrebuiltExtractor,
      resourceOwnerId: string | undefined
    ): Promise<void> => {
      return apiClient.requestPreviewExtractor(type, resourceOwnerId);
    },
    []
  );

  const getExtractorFieldSchemaTable = useCallback(
    async (extractorId: string) => {
      dispatch(GettingExtractorFieldSchemaTable(extractorId));
      try {
        const result = await apiClient.getExtractorFieldSchema(extractorId);
        const payload = {
          data: result,
          extractorId: extractorId,
        };
        dispatch(GotExtractorFieldSchemaTable(payload));
        return result;
      } catch (e) {
        const payload = {
          error: ensureFOCRError(e),
          extractorId: extractorId,
        };
        dispatch(GetExtractorFieldSchemaTableFailed(payload));
        throw e;
      }
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      importExtractor,
      createPrebuiltExtractor,
      listExtractors,
      listExtractorOptions,
      requestPreviewExtractor,
      getExtractorFieldSchemaTable,
    }),
    [
      createPrebuiltExtractor,
      importExtractor,
      listExtractors,
      listExtractorOptions,
      requestPreviewExtractor,
      getExtractorFieldSchemaTable,
    ]
  );
}
