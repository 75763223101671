import { createReducer } from "@reduxjs/toolkit";

import * as oauthAction from "../actions/oauth";
import { OAuthCredential } from "../types/oauth";

export interface OAuthState {
  readonly oauthCredentials: OAuthCredential[];
}

const defaultState: OAuthState = {
  oauthCredentials: [],
};

export const oauthReducer = createReducer<OAuthState>(defaultState, builder => {
  builder
    .addCase(oauthAction.OAuthCredentialDeleted, (state, action) => {
      const { id: oauthCredentialId } = action.payload;
      state.oauthCredentials = state.oauthCredentials.filter(
        ({ id }) => id !== oauthCredentialId
      );
    })
    .addCase(oauthAction.OAuthGotCredentialList, (state, action) => {
      const { oauthCredentials } = action.payload;
      state.oauthCredentials = oauthCredentials;
    });
});
