import { Icon, PrimaryButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useFormatterEditor } from "../../contexts/formatterEditor";
import { useLocale } from "../../contexts/locale";
import { useExtractorFieldSchema } from "../../hooks/extractorFieldSchema";
import { ConfirmModalType } from "../../types/confirmation";
import { FormatterInputSelectionField } from "../../types/formatter";
import BetaTag from "../BetaTag";
import ConfirmModal from "../ConfirmModal";
import { SmallIconButton } from "../SmallIconButton";
import styles from "./styles.module.scss";

interface FormatterStepItemProps {
  name: string;
  title: string;
  index: number;
}

function _FormatterStepItem(props: FormatterStepItemProps) {
  const { title, index } = props;
  const {
    extractorId,
    selectStep,
    requestToRemoveStep,
    selectedStepIndex,
    steps,
    localizeSelector,
    validationResult,
  } = useFormatterEditor();
  const {
    isFailedToGetExtractorFieldSchema,
    getExtractorFieldSchemaTableByExtractorId,
  } = useExtractorFieldSchema();

  const hasExtractorFieldSchemaError = React.useMemo(
    () => isFailedToGetExtractorFieldSchema(extractorId),
    [extractorId, isFailedToGetExtractorFieldSchema]
  );

  const extractorFieldSchemaTable = React.useMemo(
    () => getExtractorFieldSchemaTableByExtractorId(extractorId),
    [extractorId, getExtractorFieldSchemaTableByExtractorId]
  );

  const isLoadingExtractorFieldSchema =
    !hasExtractorFieldSchemaError && extractorFieldSchemaTable === undefined;

  const onItemClicked = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation();
      selectStep(index);
    },
    [selectStep, index]
  );

  const onTrashClicked = React.useCallback(() => {
    requestToRemoveStep(index);
  }, [index, requestToRemoveStep]);

  const selectors = React.useMemo(() => {
    if (index >= steps.length) {
      return [];
    }
    return steps[index].inputSelection.fields.map(
      (item: FormatterInputSelectionField) => item.selector
    );
  }, [index, steps]);

  const hasError = React.useMemo(() => {
    return (
      !isLoadingExtractorFieldSchema &&
      ((index < validationResult.steps.length
        ? validationResult.steps[index].hasError
        : false) ||
        hasExtractorFieldSchemaError)
    );
  }, [
    isLoadingExtractorFieldSchema,
    index,
    validationResult.steps,
    hasExtractorFieldSchemaError,
  ]);

  const baseClasses = React.useMemo(() => {
    const classes = [styles["formatter-step-item"]];
    if (selectedStepIndex === index) {
      classes.push(styles["formatter-step-item-selected"]);
    }

    if (hasError) {
      classes.push(styles["formatter-step-item-error"]);
    }

    return classes.join(" ");
  }, [selectedStepIndex, hasError, index]);

  return (
    <div className={baseClasses} onClick={onItemClicked}>
      <div className={styles["left"]}>
        <Icon className={styles["drag-icon"]} iconName="GripperDotsVertical" />
        <div className={styles["index"]}> {index + 1}. </div>
        <div className={styles["item-title"]}>
          <FormattedMessage id={title} />
        </div>
      </div>
      <div className={styles["right"]}>
        {selectors.length > 0 && (
          <>
            <div className={styles["label"]}>
              {localizeSelector(selectors[0])}
            </div>
            {selectors.length > 1 && (
              <div className={styles["label"]}>
                <FormattedMessage
                  id="formatter.step_item.more_label"
                  values={{
                    count: selectors.length - 1,
                  }}
                />
              </div>
            )}
          </>
        )}
        <div className={styles["trash-icon"]}>
          <SmallIconButton iconName="IconTrash" onClick={onTrashClicked} />
        </div>
      </div>
    </div>
  );
}

export const FormatterStepItem = React.memo(_FormatterStepItem);

function _FormatterEditorPlaceholder() {
  return (
    <div className={styles["placeholder"]}>
      <div className={styles["text"]}>
        <FormattedMessage id="formatter.editor.placeholder" />
      </div>
      <div className={styles["vertical-line"]}></div>
    </div>
  );
}

const FormatterEditorPlaceholder = React.memo(_FormatterEditorPlaceholder);

function _FormatterEditorTitle() {
  const { localized } = useLocale();
  const { extractorId, isFormatterNotSaved, saveFormatter, validationResult } =
    useFormatterEditor();
  const onSaveClicked = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation();
      saveFormatter();
    },
    [saveFormatter]
  );
  const {
    isFailedToGetExtractorFieldSchema,
    getExtractorFieldSchemaTableByExtractorId,
  } = useExtractorFieldSchema();

  const hasExtractorFieldSchemaError = React.useMemo(
    () => isFailedToGetExtractorFieldSchema(extractorId),
    [extractorId, isFailedToGetExtractorFieldSchema]
  );

  const extractorFieldSchemaTable = React.useMemo(
    () => getExtractorFieldSchemaTableByExtractorId(extractorId),
    [extractorId, getExtractorFieldSchemaTableByExtractorId]
  );

  const isLoadingExtractorFieldSchema =
    !hasExtractorFieldSchemaError && extractorFieldSchemaTable === undefined;

  const saveButtonEnabled =
    isFormatterNotSaved &&
    !validationResult.hasError &&
    extractorFieldSchemaTable !== undefined &&
    !hasExtractorFieldSchemaError;

  return (
    <div className={styles["title"]}>
      <div>
        <div className={styles["heading"]}>
          <FormattedMessage id="formatter.editor.title" />
          <BetaTag />
        </div>
        <div className={styles["desc"]}>
          <FormattedMessage id="formatter.editor.desc" />
        </div>
        {validationResult.hasError && !isLoadingExtractorFieldSchema && (
          <div className={styles["error"]}>
            <FormattedMessage id="formatter.editor.error" />
          </div>
        )}
      </div>
      <div>
        <PrimaryButton
          type="submit"
          text={localized("common.save")}
          disabled={!saveButtonEnabled}
          onClick={onSaveClicked}
        />
      </div>
    </div>
  );
}

function _FormatterEditorStepList() {
  const { steps } = useFormatterEditor();

  return (
    <>
      {steps.map((step, index) => {
        const title = `formatter.actions.${step.action}.title`;
        return (
          <div className={styles["step-list"]} key={index}>
            <FormatterStepItem name={step.action} index={index} title={title} />
            <div className={styles["vertical-line"]}></div>
          </div>
        );
      })}
    </>
  );
}

const FormatterEditorStepList = React.memo(_FormatterEditorStepList);

const FormatterEditorTitle = React.memo(_FormatterEditorTitle);

interface FormatterEditorProps {
  isConfirmConflictModalOpen?: boolean;
  onCancelConflictModal?: () => void;
}

export function _FormatterEditor(props: FormatterEditorProps) {
  const { isConfirmConflictModalOpen, onCancelConflictModal } = props;
  const { steps, saveFormatter } = useFormatterEditor();
  const { requestToAddStep } = useFormatterEditor();

  return (
    <div className={styles["formatter-editor"]}>
      <FormatterEditorTitle />
      {steps.length > 0 ? (
        <FormatterEditorStepList />
      ) : (
        <FormatterEditorPlaceholder />
      )}
      <PrimaryButton
        className={styles["add-button"]}
        iconProps={{ iconName: "Add" }}
        type="submit"
        onClick={requestToAddStep}
      ></PrimaryButton>
      <div className={styles["add-step"]}>
        <FormattedMessage id="formatter.editor.add_step" />
      </div>
      <ConfirmModal
        isOpen={isConfirmConflictModalOpen || false}
        modalType={ConfirmModalType.Save}
        titleId="form_editor.form_modifed_prompt.title"
        messageId="form_editor.form_modifed_prompt.desc"
        actionId="common.save_and_overwrite"
        onCancel={onCancelConflictModal || (() => {})}
        onConfirm={() => {
          saveFormatter();
        }}
      />
    </div>
  );
}

export const FormatterEditor = React.memo(_FormatterEditor);
export default FormatterEditor;
