import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";
import { useCallback, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";
import TextField from "../WrappedMSComponents/TextField";

interface Props {
  type: "create" | "rename";
  isOpen: boolean;
  onCancel(): void;
  onSubmit(name: string): Promise<void>;
}

function _CreateRenameWorkerTokenModal(props: Props) {
  const { isOpen, onCancel, onSubmit, type } = props;
  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState<
    string | undefined
  >();

  const { localized } = useLocale();

  const onNameChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();

      if (value !== undefined) {
        setName(value);
        setNameErrorMessage(undefined);
      }
    },
    []
  );

  const _onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (name.length === 0) {
        setNameErrorMessage("error.team.tokens.empty_name");
      } else {
        onSubmit(name);
      }
    },
    [name, onSubmit]
  );

  const onDismissed = useCallback(() => {
    setName("");
  }, []);

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
    }),
    [onDismissed]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        type === "rename" ? "team.tokens.rename" : "team.tokens.create"
      ),
    }),
    [localized, type]
  );

  return (
    <Dialog
      minWidth={500}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={_onSubmit}>
        <TextField
          labelId={"team.tokens.name"}
          value={name}
          onChange={onNameChange}
          errorMessage={nameErrorMessage && localized(nameErrorMessage)}
        />
        <DialogFooter>
          <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
          <PrimaryButton
            type="submit"
            text={localized(
              type === "rename"
                ? "team.tokens.button.rename"
                : "team.tokens.button.create"
            )}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
}

export const CreateRenameWorkerTokenModal = React.memo(
  _CreateRenameWorkerTokenModal
);
export default CreateRenameWorkerTokenModal;
