import {
  CreateWorkspaceResp,
  DocumentExportFlattenOption,
  GetWorkspaceResp,
  PaginatedWorkspace,
  UpdateWorkspaceResp,
  createWorkspaceRespSchema,
  getWorkspaceRespSchema,
  paginatedWorkspaceSchema,
  updateWorkspaceRespSchema,
} from "../../types/workspace";
import { ApiClientConstructor, _BaseApiClient } from "../base";

type GetWorkspaceArgs = {
  workspaceId: string;
};

type CreateWorkspaceArgs = {
  resourceOwnerId: string;
  name: string;
  formId?: string;
  formGroupId?: string;
};

type ListExtractorArgs = {
  size: number;
  offset: number;
  resourceOwnerId: string;
  extractorTypes?: string[];
};

type UpdateWorkspaceArgs = {
  workspaceId: string;
  name: string;
  documentExportFlattenOption: DocumentExportFlattenOption;
};

type DeleteWorkspacesArgs = {
  workspaceIds: string[];
};

export interface WorkspaceApiClient {
  getWorkspace: (args: GetWorkspaceArgs) => Promise<GetWorkspaceResp>;
  createWorkspace: (args: CreateWorkspaceArgs) => Promise<CreateWorkspaceResp>;
  listWorkspace: (args: ListExtractorArgs) => Promise<PaginatedWorkspace>;
  updateWorkspace: (args: UpdateWorkspaceArgs) => Promise<UpdateWorkspaceResp>;
  deleteWorkspaces: (args: DeleteWorkspacesArgs) => Promise<void>;
}

export function withWorkspaceApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async getWorkspace(args: GetWorkspaceArgs): Promise<GetWorkspaceResp> {
      return this.lambda(
        "workspace:get",
        { workspace_id: args.workspaceId },
        getWorkspaceRespSchema
      );
    }

    async createWorkspace(
      args: CreateWorkspaceArgs
    ): Promise<CreateWorkspaceResp> {
      return this.lambda(
        "workspace:create",
        this.injectOptionalFields(
          {
            resource_owner_id: args.resourceOwnerId,
            config: {
              name: args.name,
            },
          },
          {
            form_id: args.formId,
            form_group_id: args.formGroupId,
          }
        ),
        createWorkspaceRespSchema
      );
    }

    async listWorkspace(args: ListExtractorArgs): Promise<PaginatedWorkspace> {
      const { size, offset, resourceOwnerId, extractorTypes } = args;

      return this.lambda(
        "workspace:list",
        this.injectOptionalFields(
          {
            page_args: {
              size,
              offset,
            },
            resource_owner_id: resourceOwnerId,
          },
          { extractor_types: extractorTypes }
        ),
        paginatedWorkspaceSchema
      );
    }

    async updateWorkspace(
      args: UpdateWorkspaceArgs
    ): Promise<UpdateWorkspaceResp> {
      return this.lambda(
        "workspace:update",
        this.injectOptionalFields(
          {
            workspace_id: args.workspaceId,
            config: {
              name: args.name,
              document_export_flatten_option: args.documentExportFlattenOption,
            },
          },
          {}
        ),
        updateWorkspaceRespSchema
      );
    }

    async deleteWorkspaces(args: DeleteWorkspacesArgs): Promise<void> {
      await this.lambda(
        "workspace:delete",
        this.injectOptionalFields(
          {
            workspace_ids: args.workspaceIds,
          },
          {}
        )
      );
    }
  };
}
