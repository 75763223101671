import * as yup from "yup";

import {
  RequestLogApiName,
  Usage,
  UsageRange,
  teamUsageDetail,
  teamUsageDetailSchema,
  usageSchema,
} from "../../types/usage";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface RequestLogApiClient {
  getUsage: (
    range: UsageRange,
    tokenIds: string[],
    resourceOwnerId?: string,
    region?: string
  ) => Promise<Usage[]>;

  listRequestLogs(
    size: number,
    offset: number,
    startFrom: Date,
    range: UsageRange,
    resourceOwnerId?: string,
    apiNames?: RequestLogApiName[],
    region?: string,
    tokenIds?: (string | null)[]
  ): Promise<teamUsageDetail>;
}

export function withRequestLogApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async getUsage(
      range: UsageRange,
      tokenIds: string[],
      resourceOwnerId?: string,
      region?: string
    ): Promise<Usage[]> {
      const result = await this.lambda(
        "request_log:count",
        this.injectOptionalFields(
          {
            range,
          },
          {
            resource_owner_id: resourceOwnerId,
            token_ids: tokenIds,
          }
        ),
        yup.array(usageSchema).defined(),
        undefined,
        region !== undefined ? { region } : undefined
      );

      return result.map(item => {
        const counts = item.counts.map(item => {
          return {
            ...item,
            totalCount: item.totalCount ?? item.count ?? 0,
            asyncCount: item.asyncCount ?? 0,
            syncCount: item.syncCount ?? 0,
            unclassifiedCount: item.unclassifiedCount ?? item.count ?? 0,
          };
        });

        return {
          ...item,
          counts,
        };
      });
    }

    async listRequestLogs(
      size: number,
      offset: number,
      startFrom: Date,
      range: UsageRange,
      resourceOwnerId?: string,
      apiNames?: RequestLogApiName[],
      region?: string,
      tokenIds?: (string | null)[]
    ): Promise<teamUsageDetail> {
      const cursor = `${offset},${startFrom.toISOString()}`;

      return this.lambda(
        "request_log:list",
        this.injectOptionalFields(
          {
            range,
            page_args: { size, cursor },
          },
          {
            resource_owner_id: resourceOwnerId,
            api_names: apiNames,
            token_ids: tokenIds,
          }
        ),
        teamUsageDetailSchema,
        undefined,
        region !== undefined ? { region } : undefined
      );
    }
  };
}
