import { Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React from "react";

import { useLocale } from "../../contexts/locale";
import {
  CustomModelFieldResult,
  CustomModelResult,
  OCRKeyValue,
  OCRTestField,
  OCRTestReportSingleDocument,
  OCRToken,
  OCRTokenGroup,
  OCRWorkflowOutput,
} from "../../models";
import styles from "./OCRTestReportTable.module.scss";
import { ValueRenderer } from "./ValueRenderer";

interface OCRTestReportTableProps {
  report: OCRTestReportSingleDocument;
}

interface Value {
  value: any;
}

type ValueList = Value[];

function isValueList(value: any): value is ValueList {
  return (
    value instanceof Array &&
    value.every(x => x instanceof Object && "value" in x)
  );
}

function formatConfidence(value: number | null | undefined) {
  return value === null || value === undefined ? null : value.toFixed(2);
}

function HeaderCell(props: { headerId: string; columnClassName?: string }) {
  const { headerId, columnClassName } = props;
  return (
    <td
      className={cn(
        styles["header-cell"],
        styles["table-column"],
        columnClassName
      )}
    >
      <Text className={styles["header-text"]}>
        <FormattedMessage id={headerId} />
      </Text>
    </td>
  );
}

function BodyCell(props: {
  text: string;
  columnClassName?: string;
  alt?: string;
}) {
  const { text, columnClassName, alt } = props;
  return (
    <td
      className={cn(
        styles["body-cell"],
        styles["table-column"],
        columnClassName
      )}
    >
      <Text title={alt} className={styles["body-text"]}>
        {text}
      </Text>
    </td>
  );
}

function ValueCell(props: {
  value: any;
  columnClassName?: string;
  shouldCollapseSingleItemArray?: boolean;
  shouldNotSpecialHandleArray?: boolean;
}) {
  const {
    value,
    columnClassName,
    shouldCollapseSingleItemArray,
    shouldNotSpecialHandleArray,
  } = props;

  const shouldRenderAsList = !(
    shouldNotSpecialHandleArray ||
    (Array.isArray(value) &&
      value.length === 1 &&
      shouldCollapseSingleItemArray)
  );

  return (
    <td
      className={cn(
        styles["body-cell"],
        styles["table-column"],
        columnClassName
      )}
    >
      {Array.isArray(value) && shouldRenderAsList ? (
        <ul className={styles["value-list"]}>
          {value.map((val, index) => (
            <li key={index}>
              <ValueRenderer value={val} />
            </li>
          ))}
        </ul>
      ) : (
        <ValueRenderer
          value={
            Array.isArray(value) &&
            value.length === 1 &&
            shouldCollapseSingleItemArray
              ? value[0]
              : value
          }
        />
      )}
    </td>
  );
}

function TableCell(props: { value: any }) {
  const { value } = props;

  const rows = value ? (value.header || []).concat(value.cells) : [];
  const data = rows.map((row: any) =>
    row.map((cell: any) =>
      cell
        ? {
            row: cell.start_row,
            col: cell.start_col,
            rowSpan: cell.end_row - cell.start_row + 1,
            colSpan: cell.end_col - cell.start_col + 1,
            text: cell.text,
          }
        : { rowSpan: 1, colSpan: 1, value: null }
    )
  );

  for (const row of data) {
    for (const cell of row) {
      if (cell) {
        for (let i = 0; i < cell.rowSpan; i++) {
          for (let j = 0; j < cell.colSpan; j++) {
            if (i !== 0 || j !== 0) {
              data[cell.row + i][cell.col + j] = undefined;
            }
          }
        }
      }
    }
  }

  return (
    <td>
      <table className={styles["auto-extraction-table"]}>
        <tbody>
          {data.map((row: string[], i: number) => (
            <tr key={i}>
              {row.map((cell: any, j: number) => {
                if (cell) {
                  return (
                    <td key={j} rowSpan={cell.rowSpan} colSpan={cell.colSpan}>
                      {cell.text}
                    </td>
                  );
                }
                return <React.Fragment key={j} />;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </td>
  );
}

function ImageCell(props: { imageSrc: string; columnClassName?: string }) {
  const { imageSrc, columnClassName } = props;

  const openImgInNewWindow = React.useCallback(() => {
    const image = new Image();
    image.src = imageSrc;

    const newWin = window.open("");
    if (newWin) {
      newWin.document.write(image.outerHTML);
    }
  }, [imageSrc]);

  return (
    <td
      className={cn(
        styles["body-cell"],
        styles["image-cell"],
        styles["table-column"],
        columnClassName
      )}
      onClick={openImgInNewWindow}
    >
      <img src={imageSrc} alt="" />
    </td>
  );
}

function HeaderRow() {
  return (
    <tr className={cn(styles["table-row"], styles["header-row"])}>
      <HeaderCell
        headerId="ocr_test_table.header.type"
        columnClassName={styles["type-column"]}
      />
      <HeaderCell
        headerId="ocr_test_table.header.name"
        columnClassName={styles["name-column"]}
      />
      <HeaderCell headerId="ocr_test_table.header.value" />
      <HeaderCell
        headerId="ocr_test_table.header.confidence"
        columnClassName={styles["confidence-column"]}
      />
      <HeaderCell headerId="ocr_test_table.header.image" />
    </tr>
  );
}

function FieldRow(props: { field: OCRTestField }) {
  const { field } = props;
  const { localized } = useLocale();
  return (
    <tr className={cn(styles["table-row"], styles["body-row"])}>
      <BodyCell
        text={localized("ocr_test_table.type.field")}
        columnClassName={styles["type-column"]}
      />
      <BodyCell text={field.name} columnClassName={styles["name-column"]} />
      {field.type === "table" ? (
        <TableCell value={field.value} />
      ) : (
        <ValueCell
          value={field.value}
          shouldNotSpecialHandleArray={field.type === "script"}
        />
      )}
      <ValueCell
        value={formatConfidence(field.confidence)}
        columnClassName={styles["confidence-column"]}
      />
      <ImageCell imageSrc={field.image} />
    </tr>
  );
}

function AutoExtractionItemRow(props: { item: OCRKeyValue }) {
  const { item } = props;
  const { localized } = useLocale();
  return (
    <tr className={cn(styles["table-row"], styles["body-row"])}>
      <BodyCell
        text={localized("ocr_test_table.type.auto")}
        columnClassName={styles["type-column"]}
      />
      <BodyCell text={item.name} columnClassName={styles["name-column"]} />
      {item.name === "table" ? (
        <TableCell value={item.value} />
      ) : (
        <ValueCell value={item.value} shouldCollapseSingleItemArray={true} />
      )}
      <ValueCell
        value={formatConfidence(item.confidence)}
        columnClassName={styles["confidence-column"]}
      />
      {item.image ? (
        <ImageCell imageSrc={item.image} />
      ) : (
        <BodyCell text={""} />
      )}
    </tr>
  );
}

function KeyValueRow(props: {
  keyValue: OCRKeyValue;
  typeLabelId?: string;
  nameAlt?: string;
}) {
  const { typeLabelId, keyValue, nameAlt } = props;
  const { localized } = useLocale();
  return (
    <tr className={cn(styles["table-row"], styles["body-row"])}>
      <BodyCell
        text={localized(typeLabelId || "ocr_test_table.type.key_value")}
        columnClassName={styles["type-column"]}
      />
      <BodyCell
        text={keyValue.name}
        alt={nameAlt}
        columnClassName={styles["name-column"]}
      />
      <ValueCell value={keyValue.value} />
      <ValueCell
        value={formatConfidence(keyValue.confidence)}
        columnClassName={styles["confidence-column"]}
      />
      <BodyCell text={""} />
    </tr>
  );
}

function CustomModelFieldRow(props: { keyValue: OCRKeyValue; alt: string }) {
  return (
    <KeyValueRow
      typeLabelId="ocr_test_table.type.custom_model"
      nameAlt={props.alt}
      keyValue={props.keyValue}
    />
  );
}

function TokenRow(props: { name: string; token: OCRToken }) {
  const { name, token } = props;
  const { localized } = useLocale();
  return (
    <tr className={cn(styles["table-row"], styles["body-row"])}>
      <BodyCell
        text={localized("ocr_test_table.type.token")}
        columnClassName={styles["type-column"]}
      />
      <BodyCell text={name} columnClassName={styles["name-column"]} />
      <ValueCell value={token.value} />
      <ValueCell
        value={formatConfidence(token.confidence)}
        columnClassName={styles["confidence-column"]}
      />
      <BodyCell text={""} />
    </tr>
  );
}

function FieldSection(props: { fields: OCRTestField[] }) {
  const { fields } = props;
  return (
    fields && (
      <>
        {props.fields.map((field, index) =>
          isValueList(field.value) ? (
            field.value.map((subitem, subindex) => {
              return (
                <AutoExtractionItemRow
                  key={`field/${index}-${subindex}`}
                  item={{ ...subitem, name: field.name }}
                />
              );
            })
          ) : (
            <FieldRow field={field} key={`field/${index}`} />
          )
        )}
      </>
    )
  );
}

function AutoExtractionItemSection(props: {
  autoExtractionItems: OCRKeyValue[] | undefined;
}) {
  const { autoExtractionItems } = props;
  return autoExtractionItems ? (
    <>
      {autoExtractionItems.map((item, index) => {
        return isValueList(item.value)
          ? item.value.map((subitem, subindex) => {
              return (
                <AutoExtractionItemRow
                  key={`auto/${index}-${subindex}`}
                  item={{ ...subitem, name: item.name }}
                />
              );
            })
          : [<AutoExtractionItemRow key={`auto/${index}`} item={item} />];
      })}
    </>
  ) : null;
}

function KeyValueSection(props: { keyValues: OCRKeyValue[] | undefined }) {
  const { keyValues } = props;
  return keyValues ? (
    <>
      {keyValues.map((keyValue, index) => {
        return <KeyValueRow key={`kv/${index}`} keyValue={keyValue} />;
      })}
    </>
  ) : null;
}

function TokenGroupSection(props: {
  tokenGroups: OCRTokenGroup[] | undefined;
}) {
  const { tokenGroups } = props;
  return tokenGroups ? (
    <>
      {tokenGroups.map((tokenGroup, groupIndex) => {
        return (
          <React.Fragment key={`tokenGroup/${groupIndex}`}>
            {tokenGroup.images &&
              tokenGroup.images.map((token, index) => {
                return (
                  <TokenRow
                    name={tokenGroup.name}
                    token={token}
                    key={`tokenGroup/${groupIndex}/imgs/${index}`}
                  />
                );
              })}
            {tokenGroup.texts &&
              tokenGroup.texts.map((token, index) => {
                return (
                  <TokenRow
                    name={tokenGroup.name}
                    token={token}
                    key={`tokenGroup/${groupIndex}/texts/${index}`}
                  />
                );
              })}
          </React.Fragment>
        );
      })}
    </>
  ) : null;
}

function CustomModelSectionResult(props: {
  sectionResult: any;
  sectionName: string;
  customModel: any;
}) {
  const { sectionName, sectionResult, customModel } = props;

  const isArray = sectionResult instanceof Array;
  const isObject = sectionResult instanceof Object;

  return (
    <>
      {isArray ? (
        (sectionResult as any[]).map((fieldResult, fieldIndex) => (
          <CustomModelFieldRow
            key={`${sectionName}/${fieldIndex}`}
            keyValue={{
              name: sectionName,
              value: fieldResult,
              confidence: null,
            }}
            alt={`${customModel.name}/${sectionName}/${fieldIndex}`}
          />
        ))
      ) : isObject ? (
        Object.entries(sectionResult).map(([fieldName, fieldResults]) => {
          return (fieldResults as unknown as CustomModelFieldResult[]).map(
            (fieldResult, fieldIndex) => (
              <CustomModelFieldRow
                key={`${sectionName}/${fieldName}/${fieldIndex}`}
                keyValue={{
                  name: fieldName,
                  value: fieldResult.value,
                  confidence: fieldResult.confidence,
                }}
                alt={`${customModel.name}/${sectionName}/${fieldName}`}
              />
            )
          );
        })
      ) : (
        <CustomModelFieldRow
          key={`${sectionName}`}
          keyValue={{
            name: sectionName,
            value: sectionResult,
            confidence: null,
          }}
          alt={`${customModel.name}/${sectionName}`}
        />
      )}
    </>
  );
}

function CustomModelSection(props: {
  customModels: CustomModelResult[] | undefined;
}) {
  const { customModels } = props;

  return customModels ? (
    <>
      {customModels.map((customModel, customModelIndex) => {
        return (
          <React.Fragment key={`customModel/${customModelIndex}`}>
            {Object.entries(customModel.result).map(
              ([sectionName, sectionResult]) => {
                return (
                  <CustomModelSectionResult
                    key={`customModel/${customModelIndex}/${sectionName}`}
                    sectionName={sectionName}
                    sectionResult={sectionResult}
                    customModel={customModel}
                  />
                );
              }
            )}
          </React.Fragment>
        );
      })}
    </>
  ) : null;
}

function FormatterRow(props: { field: OCRWorkflowOutput }) {
  const { field } = props;
  const { localized } = useLocale();
  return (
    <tr className={cn(styles["table-row"], styles["body-row"])}>
      <BodyCell
        text={localized("ocr_test_table.type.formatter")}
        columnClassName={styles["type-column"]}
      />
      <BodyCell text={field.name} columnClassName={styles["name-column"]} />
      <ValueCell value={field.value} />
      <ValueCell value="" columnClassName={styles["confidence-column"]} />
      <ValueCell value="" />
    </tr>
  );
}

function FormatterOutputSection(props: {
  formatterOutput: OCRWorkflowOutput[] | undefined;
}) {
  const { formatterOutput } = props;
  return formatterOutput ? (
    <>
      {formatterOutput.map((item, index) => {
        return <FormatterRow key={`formatterOutput/${index}`} field={item} />;
      })}
    </>
  ) : null;
}

export const OCRTestReportTable = React.memo(
  (props: OCRTestReportTableProps) => {
    const { report } = props;

    return (
      <table className={styles["ocr-test-report-table"]}>
        <thead>
          <HeaderRow />
        </thead>
        <tbody>
          <FieldSection fields={report.fields} />
          <AutoExtractionItemSection
            autoExtractionItems={report.auto_extraction_items}
          />
          <KeyValueSection keyValues={report.key_values} />
          <TokenGroupSection tokenGroups={report.token_groups} />
          <CustomModelSection customModels={report.custom_models} />
          <FormatterOutputSection formatterOutput={report.formatter_output} />
        </tbody>
      </table>
    );
  }
);
