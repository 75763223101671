import {
  CommandButton,
  ContextualMenuItemType,
  IButtonProps,
  IButtonStyles,
  IContextualMenuItem,
  IContextualMenuProps,
  Icon,
  Label,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { AppConfig } from "../../config";
import { ButtonId } from "../../constants/buttonids";
import { useTeamSelection } from "../../containers/Header";
import { useLocale } from "../../contexts/locale";
import { useLocalizeRegion } from "../../hooks/app";
import { useLeftBar } from "../../hooks/component";
import { useGtm } from "../../hooks/gtm";
import { useGetTeamInvitationsFromAllRegions } from "../../hooks/invitation";
import { useRedirectToRegionForTeam } from "../../hooks/region";
import { RootState } from "../../redux/types";
import theme from "../../theme";
import { LinkWithTeamRef } from "../LinkWithTeamRef";
import styles from "./styles.module.scss";

interface Props {
  username: string;
  isAdmin: boolean;
  isOnAdminPortal: boolean;
  onLogout: () => void;
  onChangePassword: () => void;
  onCreateTeam: () => void;
  onToggleAdminPortal: () => void;
  onOpenUserSetting: () => void;
}

export interface LocationState {
  newUser: boolean;
}

function useTeamSelectionMenu(openCreateTeamModal: () => void) {
  const navigate = useNavigate();

  const getInvitationState = useGetTeamInvitationsFromAllRegions();
  const invitationCount =
    getInvitationState.type === "success"
      ? getInvitationState.invitationCount
      : undefined;

  const hasPendingInvitation =
    getInvitationState.type === "success"
      ? getInvitationState.hasPendingInvitation
      : undefined;

  const resourceOwnerId = useSelector<RootState, string | undefined>(
    state => state.resourceOwner.resourceOwnerId
  );
  const userId = useSelector<RootState, string>(state =>
    state.user.currentUser !== undefined ? state.user.currentUser.id : ""
  );
  const { teams, onChangeResourceOwner, endPointTeamRefs } = useTeamSelection();
  const userHasResourceOwner = useSelector<RootState, boolean>(
    state => state.user.currentUser?.resourceOwner !== null
  );
  const { localized } = useLocale();
  const localizeRegion = useLocalizeRegion();

  const currentResourceOwnerName = useMemo(() => {
    if (resourceOwnerId === userId || !resourceOwnerId) {
      return localized("header.personal");
    }

    const currentTeam = teams.find(team => team.id === resourceOwnerId);

    return currentTeam ? currentTeam.name : "";
  }, [resourceOwnerId, userId, teams, localized]);

  const localRegionMenuItems: IContextualMenuItem[] = useMemo(
    () => [
      ...(userHasResourceOwner
        ? [
            {
              key: "personal",
              text: localized("header.personal"),
              iconProps:
                resourceOwnerId === userId || !resourceOwnerId
                  ? {
                      iconName: "Accept",
                    }
                  : {},
              onClick: () => onChangeResourceOwner(userId),
            },
          ]
        : []),
      ...teams.map(team => ({
        key: team.id,
        text: team.name,
        iconProps:
          resourceOwnerId === team.id
            ? {
                iconName: "Accept",
              }
            : {},
        onClick: () => onChangeResourceOwner(team.id),
      })),
    ],
    [
      localized,
      onChangeResourceOwner,
      resourceOwnerId,
      teams,
      userHasResourceOwner,
      userId,
    ]
  );

  const { redirectToRegionForTeam } = useRedirectToRegionForTeam();

  const teamMenuProps: IContextualMenuProps = useMemo(
    () => ({
      items: [
        ...(Object.keys(endPointTeamRefs).length > 0
          ? [
              {
                key: "Header",
                itemType: ContextualMenuItemType.Section,
                sectionProps: {
                  title: localizeRegion(AppConfig.region),
                  items: localRegionMenuItems,
                },
              },
            ]
          : localRegionMenuItems),
        ...Object.keys(endPointTeamRefs).map(key => ({
          key: key,
          itemType: ContextualMenuItemType.Section,
          sectionProps: {
            title: localizeRegion(key),
            items: endPointTeamRefs[key].map(ref => ({
              key: ref.lookupId,
              text: ref.name,
              onClick: () => {
                redirectToRegionForTeam(
                  key,
                  ref.lookupId,
                  window.location.pathname.split("/")[1]
                );
              },
            })),
          },
        })),
        { key: "divider", itemType: ContextualMenuItemType.Divider },
        {
          key: "new-team",
          text: localized("header.new_team"),
          iconProps: {
            iconName: "AddTo",
          },
          onClick: () => {
            openCreateTeamModal();
          },
        },
        ...(invitationCount
          ? [
              {
                key: "invitations",
                text: localized("team.invitations.team_invite_count", {
                  count: invitationCount,
                }),
                iconProps: {
                  iconName: "PeopleAdd",
                },
                onClick: () => {
                  navigate("/invitation");
                },
              },
            ]
          : []),
      ],
      className: styles["team-menu"],
    }),
    [
      endPointTeamRefs,
      localizeRegion,
      localRegionMenuItems,
      localized,
      invitationCount,
      openCreateTeamModal,
      navigate,
      redirectToRegionForTeam,
    ]
  );

  return useMemo(
    () => ({
      teamMenuProps,
      currentResourceOwnerName,
      hasPendingInvitation,
    }),
    [teamMenuProps, currentResourceOwnerName, hasPendingInvitation]
  );
}

export function useHeader(props: Props) {
  const {
    username,
    isAdmin,
    isOnAdminPortal,
    onChangePassword,
    onLogout,
    onCreateTeam,
    onToggleAdminPortal,
    onOpenUserSetting,
  } = props;
  const { localized } = useLocale();

  const userMenuProps: IContextualMenuProps = useMemo(
    () => ({
      items: [
        AppConfig.authGear
          ? {
              key: "user-setting",
              text: localized("header.user_setting"),
              onClick: onOpenUserSetting,
            }
          : {
              key: "change-password",
              text: localized("header.change_password"),
              onClick: onChangePassword,
            },
        ...(isAdmin
          ? [
              {
                key: "admin",
                text: localized(
                  isOnAdminPortal ? "header.back_to_client" : "header.admin"
                ),
                onClick: onToggleAdminPortal,
              },
            ]
          : []),
        {
          key: "sign-out",
          text: localized("header.sign_out"),
          onClick: onLogout,
        },
      ],
      className: styles["dropdown-menu"],
    }),
    [
      localized,
      onChangePassword,
      onLogout,
      onOpenUserSetting,
      onToggleAdminPortal,
      isAdmin,
      isOnAdminPortal,
    ]
  );

  const { currentResourceOwnerName, teamMenuProps, hasPendingInvitation } =
    useTeamSelectionMenu(onCreateTeam);

  const teachingBubblePrimaryButtonProps: IButtonProps = useMemo(
    () => ({
      children: (
        <Label styles={{ root: { color: theme.palette.themePrimary } }}>
          <FormattedMessage id="teaching_bubble.lets_go" />
        </Label>
      ),
      href: AppConfig.helpPageUrl,
      target: "_blank",
      rel: "noopener noreferrer",
    }),
    []
  );
  const isAuthenticated = useSelector<RootState>(
    state => state.user.isAuthenticated
  );
  const isResourceOwnerExists = useSelector<RootState, boolean>(
    state => state.resourceOwner.resourceOwnerId !== undefined
  );

  const shouldShowTeamMenu =
    !AppConfig.hideTeamSelectionMenu &&
    isAuthenticated &&
    isResourceOwnerExists;

  const { pushClickedContactUsEvent } = useGtm();

  const openDocs = React.useCallback(() => {
    window.open("https://help.formx.ai/docs/getting-started", "_blank");
  }, []);

  const bookDemo = React.useCallback(() => {
    window.open("https://www.formx.ai/schedule-demo", "_blank");
    pushClickedContactUsEvent("book_a_demo");
  }, [pushClickedContactUsEvent]);

  return React.useMemo(
    () => ({
      isAuthenticated,
      shouldShowTeamMenu,
      currentResourceOwnerName,

      username,
      hasPendingInvitation,
      teamMenuProps,
      userMenuProps,
      teachingBubblePrimaryButtonProps,
      openDocs,
      bookDemo,
    }),
    [
      isAuthenticated,
      shouldShowTeamMenu,
      currentResourceOwnerName,

      username,
      hasPendingInvitation,
      teamMenuProps,

      userMenuProps,
      teachingBubblePrimaryButtonProps,
      openDocs,
      bookDemo,
    ]
  );
}

function Header(props: Props) {
  const states = useHeader(props);
  return <HeaderImpl {...states} />;
}

export const HeaderImpl = (props: ReturnType<typeof useHeader>) => {
  const {
    isAuthenticated,
    shouldShowTeamMenu,
    currentResourceOwnerName,
    username,
    hasPendingInvitation,
    teamMenuProps,
    userMenuProps,
    openDocs,
    bookDemo,
  } = props;

  const commandButtonStyle: IButtonStyles = {
    rootExpanded: {
      background: theme.palette.themeDarkAlt,
    },
    rootHovered: {
      background: theme.palette.themeDarkAlt,
    },
    rootPressed: {
      background: theme.palette.themeDark,
    },
  };

  const { toggleCollapsed, isCollapsed } = useLeftBar();

  const onCollapseMenuClick = useCallback(
    (ev?: React.MouseEvent<HTMLElement>) => {
      ev?.preventDefault();
      ev?.stopPropagation();
      toggleCollapsed(!isCollapsed);
    },
    [toggleCollapsed, isCollapsed]
  );

  const { localized } = useLocale();

  return (
    <div
      className={styles["header"]}
      style={{ backgroundColor: theme.palette.themePrimary }}
    >
      <div
        className={styles["collapse-menu-button"]}
        onClick={onCollapseMenuClick}
      >
        <Icon iconName="CollapseMenu"></Icon>
      </div>

      {isAuthenticated ? (
        <LinkWithTeamRef to={`/`}>
          <div className={styles["logo"]}>
            <img src="/logo.svg" alt="" draggable={false} />
          </div>
        </LinkWithTeamRef>
      ) : (
        <a href="https://formx.ai" className={styles["logo"]}>
          <img src="/logo.svg" alt="" draggable={false} />
        </a>
      )}

      {shouldShowTeamMenu && currentResourceOwnerName && (
        <div
          className={classnames({
            [styles["team-menu-with-notification"]]: hasPendingInvitation,
          })}
        >
          <CommandButton
            className={styles["command-button"]}
            text={currentResourceOwnerName}
            menuProps={teamMenuProps}
            styles={commandButtonStyle}
          />
        </div>
      )}
      <div className={styles["space"]} />

      {isAuthenticated && (
        <>
          <CommandButton
            id={ButtonId.HeaderBookDemoButton}
            className={classnames(
              styles["command-button"],
              styles["user-button"]
            )}
            styles={commandButtonStyle}
            text={localized("header.book_demo")}
            onClick={bookDemo}
          />
          <CommandButton
            className={classnames(
              styles["command-button"],
              styles["user-button"],
              "ml-[8px]"
            )}
            styles={commandButtonStyle}
            text={localized("header.docs")}
            onClick={openDocs}
          />
          <CommandButton
            className={classnames(
              styles["command-button"],
              styles["user-button"],
              "ml-[8px]"
            )}
            text={username}
            menuProps={userMenuProps}
            menuIconProps={{ iconName: "Contact" }}
            styles={commandButtonStyle}
          />
        </>
      )}
    </div>
  );
};

export default Header;
