import { createReducer } from "@reduxjs/toolkit";

import * as adminAction from "../actions/admin";
import { ListTeamOptions } from "../apiClient/mixin/team";
import { FOCRError } from "../errors";
import { BriefCustomModel, CustomModel } from "../types/customModel";
import { PaginatedCustomModelImage } from "../types/customModelImage";
import { BriefForm } from "../types/form";
import { BriefFormGroup } from "../types/formGroup";
import { PageInfoWithOffset } from "../types/pageInfo";
import {
  TeamDetail,
  TeamListItem,
  TeamMembersAndInvitations,
  TeamUsageItem,
} from "../types/team";
import { RequestLog, Usage } from "../types/usage";
import { deepClone } from "../utils/deepClone";

export interface AdminRegionTeamListState {
  readonly teams: TeamListItem[];
  readonly pageInfo?: PageInfoWithOffset;
  readonly isFetching: boolean;
  readonly error?: FOCRError;
}

export interface AdminTeamListState {
  readonly teamsStateByRegion: {
    [key: string]: AdminRegionTeamListState;
  };
  readonly teamsListOptions?: ListTeamOptions;
  readonly activeRegion?: string;
}

export interface AdminTeamDetailMemberState {
  readonly membersAndInvitations?: TeamMembersAndInvitations;
  readonly isFetching: boolean;
  readonly error?: FOCRError;
}

export interface AdminTeamDetailUsageState {
  readonly usages?: Usage[];
  readonly isFetchingUsages: boolean;
  readonly isFetchingRequestLogs: boolean;
  readonly requestLogs?: RequestLog[];
  readonly pageInfo?: PageInfoWithOffset;
  readonly usageError?: FOCRError;
  readonly requestLogError?: FOCRError;
}

export interface AdminTeamDetailResourceFormsState {
  readonly forms?: BriefForm[];
  readonly pageInfo?: PageInfoWithOffset;
  readonly isFetching: boolean;
  readonly error?: FOCRError;
}

export interface AdminTeamDetailResourceFormGroupsState {
  readonly formGroups?: BriefFormGroup[];
  readonly pageInfo?: PageInfoWithOffset;
  readonly isFetching: boolean;
  readonly error?: FOCRError;
}

export interface AdminTeamDetailResourceCustomModelDetailState {
  readonly customModel?: CustomModel;
  readonly isFetching: boolean;
  readonly error?: FOCRError;
  readonly isGettingCustomModelImage: boolean;
  readonly paginatedCustomModelImages?: PaginatedCustomModelImage;
  readonly customModelImageError?: FOCRError;
}

export interface AdminTeamDetailResourceCustomModelsState {
  readonly customModels?: BriefCustomModel[];
  readonly pageInfo?: PageInfoWithOffset;
  readonly isFetching: boolean;
  readonly error?: FOCRError;
  readonly customModelDetailState: AdminTeamDetailResourceCustomModelDetailState;
}

export interface AdminTeamDetailResourceState {
  readonly formsState: AdminTeamDetailResourceFormsState;
  readonly formGroupsState: AdminTeamDetailResourceFormGroupsState;
  readonly customModelsState: AdminTeamDetailResourceCustomModelsState;
}

export interface AdminTeamDetailState {
  readonly team?: TeamDetail;
  readonly isFetching: boolean;
  readonly membersState: AdminTeamDetailMemberState;
  readonly usageState: AdminTeamDetailUsageState;
  readonly resourceState: AdminTeamDetailResourceState;
  readonly error?: FOCRError;
}

export interface AdminTeamRankingListState {
  readonly teamsUsages?: TeamUsageItem[];
  readonly isFetching: boolean;
  readonly error?: FOCRError;
}

export interface AdminNewTeamListState {
  readonly teams?: TeamListItem[];
  readonly pageInfo?: PageInfoWithOffset;
  readonly isFetching: boolean;
  readonly error?: FOCRError;
}

export interface AdminNewCustomModelListState {
  readonly customModels?: BriefCustomModel[];
  readonly pageInfo?: PageInfoWithOffset;
  readonly isFetching: boolean;
  readonly error?: FOCRError;
}

export interface AdminReportState {
  readonly region?: string;
  readonly teamRankingListByRegion: {
    [key: string]: AdminTeamRankingListState;
  };
  readonly newTeamListByRegion: {
    [key: string]: AdminNewTeamListState;
  };
  readonly newCustomModelByRegion: {
    [key: string]: AdminNewCustomModelListState;
  };
}

export interface AdminState {
  readonly teamDetail: AdminTeamDetailState;
  readonly teamList: AdminTeamListState;
  readonly report: AdminReportState;
}

const defaultCustomModelDetailState: AdminTeamDetailResourceCustomModelDetailState =
  {
    isFetching: false,
    isGettingCustomModelImage: false,
  };

const defaultTeamDetailState: AdminTeamDetailState = {
  isFetching: false,
  membersState: {
    isFetching: false,
  },
  usageState: {
    isFetchingRequestLogs: false,
    isFetchingUsages: false,
  },
  resourceState: {
    formsState: {
      isFetching: false,
    },
    formGroupsState: {
      isFetching: false,
    },
    customModelsState: {
      isFetching: false,
      customModelDetailState: defaultCustomModelDetailState,
    },
  },
};

const defaultReportState: AdminReportState = {
  teamRankingListByRegion: {},
  newTeamListByRegion: {},
  newCustomModelByRegion: {},
};

const defaultState: AdminState = {
  teamList: {
    teamsStateByRegion: {},
  },
  teamDetail: defaultTeamDetailState,
  report: defaultReportState,
};

export const adminReducer = createReducer<AdminState>(defaultState, builder => {
  builder
    .addCase(adminAction.AdminGettingTeamList, (state, action) => {
      const { region, options, saveActiveRegion } = action.payload;
      state.teamList.teamsListOptions = options;
      if (saveActiveRegion) {
        state.teamList.activeRegion = region;
      }
      state.teamDetail = defaultTeamDetailState;

      if (state.teamList.teamsStateByRegion[region]) {
        state.teamList.teamsStateByRegion[region].isFetching = true;
        state.teamList.teamsStateByRegion[region].error = undefined;
      } else {
        state.teamList.teamsStateByRegion[region] = {
          teams: [],
          isFetching: true,
        };
      }
    })
    .addCase(adminAction.AdminGotTeamList, (state, action) => {
      const { region, teams, pageInfo } = action.payload;
      state.teamList.teamsStateByRegion[region].teams = teams;
      state.teamList.teamsStateByRegion[region].pageInfo = pageInfo;
      state.teamList.teamsStateByRegion[region].isFetching = false;
    })
    .addCase(adminAction.AdminGotTeamListFailed, (state, action) => {
      const { region, error } = action.payload;
      if (state.teamList.teamsStateByRegion[region]) {
        state.teamList.teamsStateByRegion[region].isFetching = false;
        state.teamList.teamsStateByRegion[region].error = error;
      }
    })
    .addCase(adminAction.AdminGettingTeamDetail, (state, _action) => {
      state.teamDetail = defaultTeamDetailState;
      state.teamDetail.isFetching = true;
      state.teamDetail.error = undefined;
    })
    .addCase(adminAction.AdminGotTeamDetail, (state, action) => {
      state.teamDetail.team = action.payload;
      state.teamDetail.isFetching = false;
    })
    .addCase(adminAction.AdminGotTeamDetailFailed, (state, action) => {
      state.teamDetail.isFetching = false;
      state.teamDetail.error = action.payload;
    })
    .addCase(adminAction.AdminGettingTeamMembers, (state, _action) => {
      state.teamDetail.membersState.isFetching = true;
      state.teamDetail.membersState.error = undefined;
    })
    .addCase(adminAction.AdminGotTeamMembers, (state, action) => {
      const { membersAndInvitations } = action.payload;
      state.teamDetail.membersState.membersAndInvitations =
        membersAndInvitations;
      state.teamDetail.membersState.isFetching = false;
    })
    .addCase(adminAction.AdminGotTeamMembersFailed, (state, action) => {
      state.teamDetail.membersState.isFetching = false;
      state.teamDetail.membersState.error = action.payload;
    })
    .addCase(adminAction.AdminGettingTeamUsage, (state, _action) => {
      state.teamDetail.usageState.isFetchingUsages = true;
      state.teamDetail.usageState.usageError = undefined;
    })
    .addCase(adminAction.AdminGotTeamUsage, (state, action) => {
      const { usages } = action.payload;
      state.teamDetail.usageState.isFetchingUsages = false;
      state.teamDetail.usageState.usages = usages;
    })
    .addCase(adminAction.AdminGotTeamUsageFailed, (state, action) => {
      state.teamDetail.usageState.isFetchingUsages = false;
      state.teamDetail.usageState.usageError = action.payload;
    })
    .addCase(adminAction.AdminGettingRequestLogs, (state, _action) => {
      state.teamDetail.usageState.isFetchingRequestLogs = true;
      state.teamDetail.usageState.requestLogError = undefined;
    })
    .addCase(adminAction.AdminGotRequestLogs, (state, action) => {
      const { pageInfo, requestLogs } = action.payload;

      state.teamDetail.usageState.isFetchingRequestLogs = false;
      state.teamDetail.usageState.requestLogs = requestLogs;
      state.teamDetail.usageState.pageInfo = pageInfo;
    })
    .addCase(adminAction.AdminGotRequestLogsFailed, (state, action) => {
      state.teamDetail.usageState.isFetchingRequestLogs = false;
      state.teamDetail.usageState.requestLogError = action.payload;
    })
    .addCase(adminAction.AdminGettingTeamForms, (state, _action) => {
      state.teamDetail.resourceState.formsState.isFetching = true;
      state.teamDetail.resourceState.formsState.error = undefined;
    })
    .addCase(adminAction.AdminGotTeamForms, (state, action) => {
      const { forms, pageInfo } = action.payload;

      state.teamDetail.resourceState.formsState.isFetching = false;
      state.teamDetail.resourceState.formsState.pageInfo = pageInfo;
      state.teamDetail.resourceState.formsState.forms = forms;
    })
    .addCase(adminAction.AdminGotTeamFormsFailed, (state, action) => {
      state.teamDetail.resourceState.formsState.isFetching = false;
      state.teamDetail.resourceState.formsState.error = action.payload;
    })
    .addCase(adminAction.AdminGettingTeamFormGroups, (state, _action) => {
      state.teamDetail.resourceState.formGroupsState.isFetching = true;
      state.teamDetail.resourceState.formGroupsState.error = undefined;
    })
    .addCase(adminAction.AdminGotTeamFormGroups, (state, action) => {
      const { formGroups, pageInfo } = action.payload;

      state.teamDetail.resourceState.formGroupsState.isFetching = false;
      state.teamDetail.resourceState.formGroupsState.pageInfo = pageInfo;
      state.teamDetail.resourceState.formGroupsState.formGroups = formGroups;
    })
    .addCase(adminAction.AdminGotTeamFormGroupsFailed, (state, action) => {
      state.teamDetail.resourceState.formGroupsState.isFetching = false;
      state.teamDetail.resourceState.formGroupsState.error = action.payload;
    })
    .addCase(adminAction.AdminGettingTeamCustomModels, (state, _action) => {
      state.teamDetail.resourceState.customModelsState.isFetching = true;
      state.teamDetail.resourceState.customModelsState.error = undefined;
    })
    .addCase(adminAction.AdminGotTeamCustomModels, (state, action) => {
      const { customModels, pageInfo } = action.payload;

      state.teamDetail.resourceState.customModelsState.isFetching = false;
      state.teamDetail.resourceState.customModelsState.pageInfo = pageInfo;
      state.teamDetail.resourceState.customModelsState.customModels =
        customModels;
      state.teamDetail.resourceState.customModelsState.customModelDetailState =
        defaultCustomModelDetailState;
    })
    .addCase(adminAction.AdminGotTeamCustomModelsFailed, (state, action) => {
      state.teamDetail.resourceState.customModelsState.isFetching = false;
      state.teamDetail.resourceState.customModelsState.error = action.payload;
    })
    .addCase(adminAction.AdminGettingTeamCustomModel, (state, _action) => {
      state.teamDetail.resourceState.customModelsState.customModelDetailState.customModel =
        undefined;
      state.teamDetail.resourceState.customModelsState.customModelDetailState.isFetching =
        true;
      state.teamDetail.resourceState.customModelsState.customModelDetailState.error =
        undefined;
    })
    .addCase(adminAction.AdminGotTeamCustomModel, (state, action) => {
      const customModel = action.payload;

      state.teamDetail.resourceState.customModelsState.customModelDetailState.isFetching =
        false;
      state.teamDetail.resourceState.customModelsState.customModelDetailState.customModel =
        customModel;
    })
    .addCase(adminAction.AdminGotTeamCustomModelFailed, (state, action) => {
      state.teamDetail.resourceState.customModelsState.customModelDetailState.isFetching =
        false;
      state.teamDetail.resourceState.customModelsState.customModelDetailState.error =
        action.payload;
    })
    .addCase(
      adminAction.AdminGettingTeamCustomModelImages,
      (state, _action) => {
        state.teamDetail.resourceState.customModelsState.customModelDetailState.paginatedCustomModelImages =
          undefined;
        state.teamDetail.resourceState.customModelsState.customModelDetailState.isGettingCustomModelImage =
          true;
        state.teamDetail.resourceState.customModelsState.customModelDetailState.customModelImageError =
          undefined;
      }
    )
    .addCase(adminAction.AdminGotTeamCustomModelImages, (state, action) => {
      const paginatedCustomModelImages = action.payload;

      state.teamDetail.resourceState.customModelsState.customModelDetailState.isGettingCustomModelImage =
        false;
      state.teamDetail.resourceState.customModelsState.customModelDetailState.paginatedCustomModelImages =
        paginatedCustomModelImages;
    })
    .addCase(
      adminAction.AdminGotTeamCustomModelImagesFailed,
      (state, action) => {
        state.teamDetail.resourceState.customModelsState.customModelDetailState.isGettingCustomModelImage =
          false;
        state.teamDetail.resourceState.customModelsState.customModelDetailState.customModelImageError =
          action.payload;
      }
    )
    .addCase(adminAction.AdminGettingReportTeamsUsages, (state, action) => {
      const { region } = action.payload;
      state.report.region = region;

      if (!state.report.teamRankingListByRegion[region]) {
        state.report.teamRankingListByRegion[region] = { isFetching: true };
      } else {
        state.report.teamRankingListByRegion[region].isFetching = true;
        state.report.teamRankingListByRegion[region].teamsUsages = undefined;
      }
    })
    .addCase(adminAction.AdminGotReportTeamsUsages, (state, action) => {
      const { teamsUsages, region } = action.payload;

      state.report.teamRankingListByRegion[region].isFetching = false;
      state.report.teamRankingListByRegion[region].teamsUsages = teamsUsages;
    })
    .addCase(adminAction.AdminGotReportTeamsUsagesFailed, (state, action) => {
      const { region, error } = action.payload;

      state.report.teamRankingListByRegion[region].isFetching = false;
      state.report.teamRankingListByRegion[region].error = error;
    })
    .addCase(adminAction.AdminGettingReportNewTeamList, (state, action) => {
      const { region } = action.payload;
      state.teamDetail = defaultTeamDetailState;

      if (!state.report.newTeamListByRegion[region]) {
        state.report.newTeamListByRegion[region] = {
          isFetching: true,
        };
      } else {
        state.report.newTeamListByRegion[region].isFetching = true;
        state.report.newTeamListByRegion[region].error = undefined;
      }
    })
    .addCase(adminAction.AdminGotReportNewTeamList, (state, action) => {
      const { region, teams, pageInfo } = action.payload;
      state.report.newTeamListByRegion[region].teams = teams;
      state.report.newTeamListByRegion[region].pageInfo = pageInfo;
      state.report.newTeamListByRegion[region].isFetching = false;
    })
    .addCase(adminAction.AdminGotReportNewTeamListFailed, (state, action) => {
      const { region, error } = action.payload;
      state.report.newTeamListByRegion[region].isFetching = false;
      state.report.newTeamListByRegion[region].error = error;
    })
    .addCase(adminAction.AdminGettingReportCustomModels, (state, action) => {
      const { region } = action.payload;

      if (!state.report.newCustomModelByRegion[region]) {
        state.report.newCustomModelByRegion[region] = {
          isFetching: true,
        };
      } else {
        state.report.newCustomModelByRegion[region].isFetching = true;
        state.report.newCustomModelByRegion[region].error = undefined;
      }
    })
    .addCase(adminAction.AdminGotReportCustomModels, (state, action) => {
      const { region, customModels, pageInfo } = action.payload;
      state.report.newCustomModelByRegion[region].customModels = customModels;
      state.report.newCustomModelByRegion[region].pageInfo = pageInfo;
      state.report.newCustomModelByRegion[region].isFetching = false;
    })
    .addCase(adminAction.AdminGotReportCustomModelsFailed, (state, action) => {
      const { region, error } = action.payload;
      state.report.newCustomModelByRegion[region].isFetching = false;
      state.report.newCustomModelByRegion[region].error = error;
    })
    .addCase(adminAction.AdminTeamUserInvited, (state, action) => {
      if (state.teamDetail.membersState.membersAndInvitations) {
        state.teamDetail.membersState.membersAndInvitations.invitations.push(
          deepClone(action.payload)
        );
      }
    })
    .addCase(adminAction.AdminTeamInvitationRemoved, (state, action) => {
      const { invitationId } = action.payload;

      if (state.teamDetail.membersState.membersAndInvitations) {
        state.teamDetail.membersState.membersAndInvitations.invitations =
          state.teamDetail.membersState.membersAndInvitations.invitations.filter(
            x => x.id !== invitationId
          );
      }
    })
    .addCase(adminAction.AdminUserPermissionUpdated, (state, action) => {
      const { permission, userId, updatedAt } = action.payload;

      if (state.teamDetail.membersState.membersAndInvitations) {
        state.teamDetail.membersState.membersAndInvitations.members =
          state.teamDetail.membersState.membersAndInvitations.members.map(
            user => {
              if (user.userId === userId) {
                return { ...user, permission };
              }
              return user;
            }
          );
        state.teamDetail.membersState.membersAndInvitations.updatedAt =
          updatedAt;
      }
    })
    .addCase(adminAction.AdminTeamUserRemoved, (state, action) => {
      const { userId } = action.payload;

      if (state.teamDetail.membersState.membersAndInvitations) {
        state.teamDetail.membersState.membersAndInvitations.members =
          state.teamDetail.membersState.membersAndInvitations.members.filter(
            x => x.userId !== userId
          );
      }
    });
});
