import { Dropdown, IDropdownOption, Text, TextField } from "@fluentui/react";
import classNames from "classnames";
import produce from "immer";
import React, { useCallback, useMemo, useState } from "react";

import { CUSTOM_FIELD_POSITION_MENU_OPTIONS } from "../../../constants";
import { DATE_INPUT_FORMAT_OPTIONS } from "../../../constants/formConfig";
import { useLocale } from "../../../contexts/locale";
import { DateInputFormatType } from "../../../types/formConfig";
import { KeyValueResp } from "../../../types/keyValue";
import { KeyValuePosition, KeyValueTokenResp } from "../../../types/keyValue";
import { KeyValueExample } from "../../KeyValueExample";
import { KeySection } from "./KeySection";
import styles from "./styles.module.scss";

export function useKeyValuesConfigViewHandle(
  defaultKeyValue?: KeyValueResp,
  shouldShowDateInputDropdown?: boolean
) {
  const { localized } = useLocale();
  const [keyValue, setKeyValue] = useState<KeyValueResp>(
    defaultKeyValue ?? EmptyKeyValue
  );
  const [keysErrorMessageId, setKeysErrorMessageId] = useState<
    string | undefined
  >();
  const [valuesErrorMessageId, setValuesErrorMessageId] = useState<
    string | undefined
  >();
  const [tokenErrorMessages, setTokenErrorMessages] = useState<
    (string | null)[]
  >([]);
  const [patternErrorMessageId, setPatternErrorMessageId] = useState<string>();

  const resetErrorMessages = useCallback(() => {
    setKeysErrorMessageId(undefined);
    setValuesErrorMessageId(undefined);
    setTokenErrorMessages([]);
  }, []);

  const onTokensChanged = useCallback(
    (newTokens: KeyValueTokenResp[]) => {
      setKeyValue(
        produce(keyValue, draftKeyValue => {
          draftKeyValue.tokens = newTokens;
        })
      );
    },
    [keyValue]
  );

  const onPatternChange = useCallback(
    (
      event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      if (newValue !== undefined) {
        setKeyValue(
          produce(keyValue, draftKeyValue => {
            draftKeyValue.pattern = newValue;
          })
        );
        setPatternErrorMessageId(undefined);
      }
    },
    [keyValue]
  );

  const onPositionChange = useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option) {
        setKeyValue(
          produce(keyValue, draftKeyValue => {
            draftKeyValue.position = option.key as KeyValuePosition;
          })
        );
      }
    },
    [keyValue]
  );

  const onDateInputFormatChange = useCallback(
    (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option && option.id) {
        setKeyValue(
          produce(keyValue, draftKeyValue => {
            draftKeyValue.date_input_format = option.id as DateInputFormatType;
          })
        );
      }
    },
    [keyValue]
  );

  const validate = useCallback(() => {
    resetErrorMessages();
    let isValid = true;
    const tokenErrorMessages = keyValue.tokens.map(token => {
      if (token.token.trim().length === 0) {
        return localized("key_value.modal.key.empty_error");
      }
      try {
        new RegExp(token.token);
      } catch (err) {
        return localized("key_value.modal.key.invalid_regex_error");
      }
      return null;
    });
    setTokenErrorMessages(tokenErrorMessages);

    if (tokenErrorMessages.some(message => message != null)) {
      isValid = false;
    }

    if (!keyValue.pattern.trim() && keyValue.tokens.length === 0) {
      setKeysErrorMessageId("key_value.modal.pattern_or_key_is_required_error");
      setValuesErrorMessageId(
        "key_value.modal.pattern_or_key_is_required_error"
      );
      isValid = false;
    }

    try {
      new RegExp(keyValue.pattern);
    } catch (err) {
      setPatternErrorMessageId("key_value.modal.pattern.invalid_regex_error");
      isValid = false;
    }

    return isValid;
  }, [keyValue, resetErrorMessages, localized]);

  const submit = useCallback(() => {
    if (validate()) {
      return keyValue;
    }
    return undefined;
  }, [validate, keyValue]);
  return useMemo(
    () => ({
      triggerProps: {
        onPositionChange,
        onPatternChange,
        onTokensChanged,
        onDateInputFormatChange,
        patternErrorMessageId,
        keysErrorMessageId,
        valuesErrorMessageId,
        tokenErrorMessages,
        keyValue,
        shouldShowDateInputDropdown,
      },
      submit,
    }),
    [
      onPositionChange,
      onPatternChange,
      onTokensChanged,
      onDateInputFormatChange,
      patternErrorMessageId,
      keysErrorMessageId,
      valuesErrorMessageId,
      tokenErrorMessages,
      keyValue,
      shouldShowDateInputDropdown,
      submit,
    ]
  );
}

type BaseProps = ReturnType<
  typeof useKeyValuesConfigViewHandle
>["triggerProps"];
interface Props extends BaseProps {
  className?: string;
}

const EmptyKeyValue: KeyValueResp = {
  name: "",
  tokens: [],
  pattern: "",
  position: "right",
  created_at: 0,
};

const KeyValuesConfigView = React.memo<Props>(props => {
  const { localized } = useLocale();
  const {
    className,
    keyValue,
    onTokensChanged,
    onPatternChange,
    onPositionChange,
    onDateInputFormatChange,
    valuesErrorMessageId,
    tokenErrorMessages,
    keysErrorMessageId,
    patternErrorMessageId,
    shouldShowDateInputDropdown,
  } = props;

  const dateInputFormatOptions: IDropdownOption[] = useMemo(() => {
    return DATE_INPUT_FORMAT_OPTIONS.map(f => ({
      id: f,
      key: f,
      text: localized(`date_input_format.${f}`),
    }));
  }, [localized]);

  return (
    <div className={className}>
      <KeyValueExample />
      <KeySection
        tokens={keyValue.tokens}
        onChange={onTokensChanged}
        tokenErrorMessages={tokenErrorMessages}
        sectionErrorMessage={
          keysErrorMessageId && localized(keysErrorMessageId)
        }
      />
      <div className={styles["values-title-row"]}>
        <Text className={styles["values-title-text"]}>
          {localized("key_value.modal.value")}
        </Text>
        {valuesErrorMessageId && (
          <Text className={styles["values-error-text"]}>
            {localized(valuesErrorMessageId)}
          </Text>
        )}
      </div>
      <Dropdown
        className={styles["input-field"]}
        label={localized("edit_custom_field.position")}
        options={CUSTOM_FIELD_POSITION_MENU_OPTIONS.map(({ key, text }) => ({
          key,
          text: localized(text),
        }))}
        selectedKey={keyValue.position}
        onChange={onPositionChange}
        disabled={keyValue.tokens.length === 0}
      />
      <div className={classNames(styles["input-field"], styles["pattern-row"])}>
        <TextField
          className={styles["regex-input"]}
          label={localized("key_value.modal.pattern")}
          onChange={onPatternChange}
          value={keyValue.pattern}
          errorMessage={
            patternErrorMessageId && localized(patternErrorMessageId)
          }
        />
      </div>

      {shouldShowDateInputDropdown && (
        <Dropdown
          label={localized(
            "advance_token_setup_field_replacement_setting_panel.panel.change_date_input.convert_date_order"
          )}
          selectedKey={
            keyValue.date_input_format || DATE_INPUT_FORMAT_OPTIONS[0]
          }
          onChange={onDateInputFormatChange}
          options={dateInputFormatOptions}
        />
      )}
    </div>
  );
});
export default KeyValuesConfigView;
