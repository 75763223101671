@import "../../styles/variables.scss";

.container {
  height: 100%;
  background-color: #faf9f8;

  .content {
    .header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 20px;

      .title {
        color: #323130;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
      }

      .close-button {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .icon {
          color: #201f1e;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 100%;
        }
      }
    }

    .sections {
      padding: 0 20px 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .section {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 12px;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13),
          0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.1);

        .info {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .title {
            color: #323130;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }

          .description {
            color: #a19f9d;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
        }

        .samples {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .sample {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .label {
              color: #a19f9d;
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
            }

            .image {
              > img {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
