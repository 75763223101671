export function downloadJsonStringFile(jsonString: string, filename: string) {
  const dataStr =
    "data:text/json;charset=utf-8," + encodeURIComponent(jsonString);
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute(
    "download",
    (filename !== "" ? filename : "default") + ".json"
  );
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}
