import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { ExtractorOptionForCombinedExtractor } from "../../types/extractor";
import {
  DocumentDetectionType,
  FormGroupDocumentDetection,
} from "../../types/formGroup";
import styles from "./styles.module.scss";

interface Props {
  extractorOptions: ExtractorOptionForCombinedExtractor[];
  documentTypes: DocumentDetectionType[];
  documentDetection: FormGroupDocumentDetection | undefined;
  onCloseModal: () => void;
  onSave: (
    id: string | undefined,
    formId: string | null | undefined,
    formGroupId: string | null | undefined,
    documentType: DocumentDetectionType
  ) => void;
}

const FormGroupDocumentDetectionModal = React.memo((props: Props) => {
  const {
    documentDetection,
    onSave,
    onCloseModal,
    extractorOptions,
    documentTypes,
  } = props;

  const isEdit = documentDetection !== undefined;

  const { localized } = useLocale();

  const extractorDropdownOptions = React.useMemo((): IDropdownOption[] => {
    return extractorOptions
      .map(x => ({
        key: x.extractorId,
        text: x.isCurrentCombinedExtractor
          ? localized("form_group_editor.no_self_reference", { name: x.name })
          : x.isInUse
          ? localized("form_group_editor.in_use", { name: x.name })
          : x.name,
        disabled: x.isInUse || x.isCurrentCombinedExtractor,
      }))
      .sort((a, b) =>
        !a.disabled && b.disabled ? -1 : a.disabled && !b.disabled ? 1 : 0
      );
  }, [extractorOptions, localized]);

  const documentTypeOptions = React.useMemo((): IDropdownOption[] => {
    const options: IDropdownOption[] = documentTypes.map(t => {
      return {
        key: t,
        text: localized(`document_detection_type.${t}`),
      };
    });

    if (documentDetection && documentDetection.documentType) {
      options.splice(0, 0, {
        key: documentDetection.documentType,
        text: localized(
          `document_detection_type.${documentDetection.documentType}`
        ),
      });
    }

    return options;
  }, [documentTypes, documentDetection, localized]);

  const [isFormIdInvalid, setIsFormIdInvalid] = React.useState<boolean>(false);
  const [isDocumentTypeInvalid, setIsDocumentTypeInvalid] =
    React.useState<boolean>(false);

  const [formId, setFormId] = React.useState<string | null | undefined>(
    documentDetection ? documentDetection.targetFormId : undefined
  );

  const [documentType, setDocumentType] = React.useState<
    DocumentDetectionType | undefined
  >(documentDetection ? documentDetection.documentType : undefined);

  const [formGroupId, setFormGroupId] = React.useState<
    string | null | undefined
  >(documentDetection ? documentDetection.targetFormGroupId : undefined);

  const onSaveClicked = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!formId && !formGroupId) {
        setIsFormIdInvalid(true);
        return;
      }

      if (!documentType) {
        setIsDocumentTypeInvalid(true);
        return;
      }

      onSave(
        documentDetection && documentDetection.id,
        formId,
        formGroupId,
        documentType
      );
      onCloseModal();
    },
    [onSave, formId, formGroupId, documentDetection, onCloseModal, documentType]
  );

  const onSelectedExtractorChange = React.useCallback(
    (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (!option) return;
      if (
        option.key === documentDetection?.targetFormId ||
        extractorOptions.find(
          x =>
            x.extractorId === option.key &&
            (x.resourceType === "form" || x.resourceType === "custom_model")
        )
      ) {
        setFormId(option.key as string);
        setFormGroupId(null);
      }
      if (
        option.key === documentDetection?.targetFormGroupId ||
        extractorOptions.find(
          x => x.extractorId === option.key && x.resourceType === "form_group"
        )
      ) {
        setFormGroupId(option.key as string);
        setFormId(null);
      }
    },
    [documentDetection, extractorOptions]
  );

  const onDocumentTypeChange = React.useCallback(
    (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (!option) return;
      setDocumentType(option.key as DocumentDetectionType);
    },
    []
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        isEdit
          ? "form_group_document_detection_modal.title.edit"
          : "form_group_document_detection_modal.title.add"
      ),
    }),
    [localized, isEdit]
  );

  const modalProps: IModalProps = React.useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  return (
    <Dialog
      hidden={false}
      onDismiss={onCloseModal}
      minWidth={400}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <form onSubmit={onSaveClicked}>
        <Dropdown
          className={styles["document-detection-modal-document-type-dropdown"]}
          selectedKey={documentType}
          options={documentTypeOptions}
          onChange={onDocumentTypeChange}
          label={localized(
            "form_group_document_detection_modal.if_document_type_is"
          )}
          placeholder={localized(
            "form_group_document_detection_modal.please_select_a_document_type"
          )}
          errorMessage={
            isDocumentTypeInvalid
              ? localized(
                  "form_group_document_detection_modal.please_select_a_document_type"
                )
              : undefined
          }
        />
        <Dropdown
          selectedKey={formId || formGroupId}
          options={extractorDropdownOptions}
          onChange={onSelectedExtractorChange}
          label={localized(
            "form_group_document_detection_modal.use_this_extractor"
          )}
          placeholder={localized(
            "form_group_document_detection_modal.please_select_an_extractor"
          )}
          errorMessage={
            isFormIdInvalid
              ? localized(
                  "form_group_document_detection_modal.please_select_an_extractor"
                )
              : undefined
          }
        />
        <DialogFooter className={styles["document-detection-modal-footer"]}>
          <DefaultButton
            onClick={onCloseModal}
            text={localized("common.cancel")}
          />
          <PrimaryButton
            type="submit"
            text={localized(
              isEdit
                ? "form_group_document_detection_modal.button.save"
                : "form_group_document_detection_modal.button.add"
            )}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
});

export { FormGroupDocumentDetectionModal };
