import {
  CommandButton,
  ContextualMenuItemType,
  IButtonStyles,
  IContextualMenuProps,
} from "@fluentui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useLocale } from "../../contexts/locale";
import { RootState } from "../../redux/types";
import theme from "../../theme";
import { ConfirmModalType } from "../../types/confirmation";
import {
  Permission,
  adminPermission,
  editorPermission,
  getTeamRoleMessageId,
  readOnlyPermission,
} from "../../types/team";
import { deepEqual } from "../../utils/deepEqual";
import ConfirmModal from "../ConfirmModal";
import styles from "./styles.module.scss";

export interface DisplayTeamUser {
  email: string;
  permission: Permission;
  userId?: string;
  invitationId?: string;
}
interface Props {
  user: DisplayTeamUser;
  setTeamUserPermission: (userId: string, permission: Permission) => void;
  removeTeamUser: (userId: string) => void;
  removeInvitation: (invitationId: string) => void;
}

export function TeamPermissionControl(props: Props) {
  const [
    isDeleteTeamUserConfirmationModalOpened,
    setIsDeleteTeamUserConfirmationModalOpened,
  ] = useState(false);
  const [
    isDeleteInvitationConfirmationModalOpened,
    setIsDeleteInvitationConfirmationModalOpened,
  ] = useState(false);

  const { user, setTeamUserPermission, removeTeamUser, removeInvitation } =
    props;

  const teamName = useSelector<RootState, string>(
    state => state.resourceOwner.teamName ?? ""
  );

  const { localized } = useLocale();

  const onRemoveTeamUser = useCallback(() => {
    if (user.userId) {
      removeTeamUser(user.userId);
    }
    setIsDeleteTeamUserConfirmationModalOpened(false);
  }, [removeTeamUser, user]);

  const onRemoveInvitation = useCallback(() => {
    if (user.invitationId) {
      removeInvitation(user.invitationId);
    }
    setIsDeleteInvitationConfirmationModalOpened(false);
  }, [removeInvitation, user]);

  const accessMenuProps = useMemo((): IContextualMenuProps => {
    const isInvitation = user.userId === undefined;

    const items = [adminPermission, editorPermission, readOnlyPermission].map(
      permission => {
        const name = localized(getTeamRoleMessageId(permission));
        const isChecked = deepEqual(permission, user.permission);

        return {
          key: name,
          text: name,
          iconProps: isChecked ? { iconName: "Accept" } : {},
          onClick: () => {
            if (isChecked) {
              return;
            }
            if (user.userId) {
              setTeamUserPermission(user.userId, permission);
            }
          },
        };
      }
    );

    const removeMemberItem = {
      key: "remove-member",
      text: localized(isInvitation ? "team.invitation.remove" : "team.remove"),
      iconProps: {
        iconName: "UserRemove",
        styles: {
          root: {
            color: "#e13d3d",
          },
        },
      },
      onClick: () => {
        if (isInvitation) {
          setIsDeleteInvitationConfirmationModalOpened(true);
        } else {
          setIsDeleteTeamUserConfirmationModalOpened(true);
        }
      },
    };

    if (isInvitation) {
      return { items: [removeMemberItem] };
    } else {
      return {
        items: [
          ...items,
          { key: "divider", itemType: ContextualMenuItemType.Divider },
          removeMemberItem,
        ],
        useTargetWidth: true,
      };
    }
  }, [localized, setTeamUserPermission, user]);

  const commandButtonStyle: IButtonStyles = {
    rootExpanded: {
      background: "#EDEBE9",
    },
    rootHovered: {
      background: "#EDEBE9",
    },
    rootPressed: {
      background: "#EDEBE9",
    },
    labelHovered: {
      color: theme.palette.black,
    },
  };
  return (
    <div>
      <CommandButton
        text={localized(getTeamRoleMessageId(user.permission))}
        menuProps={accessMenuProps}
        className={styles["permission-toggle"]}
        styles={commandButtonStyle}
      />
      <ConfirmModal
        isOpen={isDeleteTeamUserConfirmationModalOpened}
        modalType={ConfirmModalType.Destory}
        titleId="team.remove_user"
        messageId="team.remove_user.confirm"
        actionId="common.remove"
        onCancel={() => setIsDeleteTeamUserConfirmationModalOpened(false)}
        onConfirm={onRemoveTeamUser}
        messageValues={{ email: user.email, team: teamName }}
      />
      <ConfirmModal
        isOpen={isDeleteInvitationConfirmationModalOpened}
        modalType={ConfirmModalType.Destory}
        titleId="team.invitation.remove"
        messageId="team.invitation.remove.confirm"
        actionId="common.remove"
        onCancel={() => setIsDeleteInvitationConfirmationModalOpened(false)}
        onConfirm={onRemoveInvitation}
        messageValues={{ email: user.email, team: teamName }}
      />
    </div>
  );
}
