import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useCustomModelActionCreator } from "../actions/customModel";
import {
  CustomModelRemarkModalResultType,
  useCustomModelRemarkModalHandle,
} from "../components/CustomModelRemarkModal";
import { UserFeatureFlag } from "../constants";
import { CUSTOM_MODEL_IMAGE_PAGE_SIZE } from "../constants/layout";
import { useAppSelector } from "../hooks/redux";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { useToast } from "../hooks/toast";
import { ConfirmModalType } from "../types/confirmation";
import { useFSLCustomModelEditor } from "./fslCustomModelEditor";

function useFetchStandardCustomModelImages(customModelId: string) {
  const { listCustomModelImages } = useCustomModelActionCreator();
  const [searchParam] = useSearchParams();
  const { setParam } = useSearchParamUtils();
  const [page, setPage] = React.useState(
    parseInt(searchParam.get(URLParamsKey.page) || "1") || 1
  );

  const customModelImages = useAppSelector(
    state => state.customModel.paginatedCustomModelImages?.images
  );

  const pageInfo = useAppSelector(
    state => state.customModel.paginatedCustomModelImages?.pageInfo
  );

  const totalCustomModelImageCount = pageInfo?.totalCount ?? 0;

  const navigateToCustomModelImagePage = React.useCallback(
    (page: number) => {
      setParam(URLParamsKey.page, page.toString());
    },
    [setParam]
  );

  React.useEffect(() => {
    setPage(parseInt(searchParam.get(URLParamsKey.page) || "1") || 1);
  }, [searchParam]);

  const reloadCustomModelImages = React.useCallback(() => {
    const pageSize = CUSTOM_MODEL_IMAGE_PAGE_SIZE;
    const offset = (page - 1) * pageSize;

    listCustomModelImages(customModelId, pageSize, offset, undefined, []);
  }, [listCustomModelImages, customModelId, page]);

  React.useEffect(() => {
    reloadCustomModelImages();
  }, [reloadCustomModelImages]);

  return React.useMemo(
    () => ({
      customModelImages,
      totalCustomModelImageCount,
      navigateToCustomModelImagePage,
      currentCustomModelImagePage: page,
      reloadCustomModelImages,
    }),
    [
      customModelImages,
      totalCustomModelImageCount,
      navigateToCustomModelImagePage,
      page,
      reloadCustomModelImages,
    ]
  );
}

function useMakeContext() {
  const {
    customModel,
    isStandardModelEnabled,
    customModelId,
    isLoading: isFSLCustomModelEditorLoading,
    containerState,
  } = useFSLCustomModelEditor();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [localTrainingInProgress, setLocalTrainingInProgress] =
    React.useState(false);
  const toast = useToast();

  const {
    triggerModelTraining,
    requestCustomModelTraining,
    enableStandardModel: triggerEnableStandardModel,
  } = useCustomModelActionCreator();
  const { requestUserConfirmation } = useConfirmModalActionCreator();
  const customModelRemarkModalHandle = useCustomModelRemarkModalHandle();

  const customModelRemarkModalProps = customModelRemarkModalHandle.props;

  const standardModelLabelledImageCount =
    customModel?.extraFields?.standardModelLabelledImageCount ?? 0;

  const {
    customModelImages,
    totalCustomModelImageCount,
    navigateToCustomModelImagePage,
    currentCustomModelImagePage,
    reloadCustomModelImages,
  } = useFetchStandardCustomModelImages(customModelId);

  // #3048 - Custom Model Laballing is always enabled
  const isCustomModelLabellingEnabled = true;

  const isCustomModelTrainingEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.CustomModelTraining
    )
  );

  const deletingCustomModelImageIds = useAppSelector(
    state => state.customModel.deletingCustomModelImageIds
  );

  const [isPreparingTraining, setIsPreparingTraining] = React.useState(false);

  const requestModelTraining = React.useCallback(async () => {
    if (!customModel) {
      return;
    }
    setIsPreparingTraining(true);

    if (!isCustomModelTrainingEnabled || !isCustomModelLabellingEnabled) {
      const result = await customModelRemarkModalHandle.methods.open();
      if (result.type === CustomModelRemarkModalResultType.Cancel) {
        setIsPreparingTraining(false);
        return;
      }

      try {
        await requestCustomModelTraining(
          result.remark === "" || result.remark === undefined
            ? "None"
            : result.remark
        );
        await requestUserConfirmation(
          {
            titleId: "custom_model_label.request_is_sent.title",
            messageId: "custom_model_label.request_is_sent.message",
            actionId: "custom_model_label.request_is_sent.dismiss",
            type: ConfirmModalType.Notify,
          },
          false
        );
      } catch (e) {
        toast.error("custom_model_label.request_training.error");
      } finally {
        setIsPreparingTraining(false);
      }
      return;
    }

    try {
      await triggerModelTraining(customModel.id);
      setLocalTrainingInProgress(true);
    } catch (e) {
      console.error(e);
      toast.error("custom_model_label.train.error");
    } finally {
      setIsPreparingTraining(false);
    }
  }, [
    triggerModelTraining,
    customModel,
    toast,
    isCustomModelTrainingEnabled,
    requestCustomModelTraining,
    requestUserConfirmation,
    customModelRemarkModalHandle.methods,
    setLocalTrainingInProgress,
    isCustomModelLabellingEnabled,
  ]);

  const isStandardModelTrainingInProgress =
    containerState.state === "success" &&
    (containerState.isTrainingInProgress || localTrainingInProgress);

  const isStandardModelTrainingRequested =
    customModel?.config.trainingRequested ?? false;

  const isStandardModelTrainingFailed = customModel?.isTrainingFailed ?? false;

  const finishTrainingAt = customModel?.finishTrainingAt ?? 0;

  const extractedContentSchemaModifiedAt =
    customModel?.config.extractedContentSchema?.modifiedAt ?? 0;

  const standardModelSampleUpdatedAt =
    customModel?.config.standardModelSampleUpdatedAt ?? 0;

  const isStandardModelTrainingFinished = finishTrainingAt > 0;

  const isStandardModelTrainingExpired =
    isStandardModelTrainingFinished &&
    (standardModelSampleUpdatedAt * 1000 > finishTrainingAt ||
      extractedContentSchemaModifiedAt * 1000 > finishTrainingAt);

  const enableStandardModel = React.useCallback(async () => {
    if (customModel === undefined) {
      return;
    }
    try {
      setIsProcessing(true);
      await triggerEnableStandardModel(customModel);
      reloadCustomModelImages();
    } catch (e) {
      toast.error("error.failed_to_enable_standard_model");
    } finally {
      setIsProcessing(false);
    }
  }, [toast, triggerEnableStandardModel, customModel, reloadCustomModelImages]);

  const isLoading =
    isFSLCustomModelEditorLoading ||
    isProcessing ||
    customModelImages === undefined;

  return React.useMemo(
    () => ({
      customModel,
      customModelRemarkModalProps,
      requestModelTraining,
      isStandardModelTrainingInProgress,
      isStandardModelTrainingRequested,
      isStandardModelTrainingFailed,
      isStandardModelEnabled,
      isStandardModelTrainingFinished,
      enableStandardModel,
      customModelImages,
      totalCustomModelImageCount,
      navigateToCustomModelImagePage,
      currentCustomModelImagePage,
      isLoading,
      deletingCustomModelImageIds,
      standardModelLabelledImageCount,
      isStandardModelTrainingExpired,
      isPreparingTraining,
    }),
    [
      customModel,
      customModelRemarkModalProps,
      requestModelTraining,
      isStandardModelTrainingInProgress,
      isStandardModelTrainingRequested,
      isStandardModelTrainingFailed,
      isStandardModelEnabled,
      isStandardModelTrainingFinished,
      enableStandardModel,
      customModelImages,
      totalCustomModelImageCount,
      navigateToCustomModelImagePage,
      currentCustomModelImagePage,
      isLoading,
      deletingCustomModelImageIds,
      standardModelLabelledImageCount,
      isStandardModelTrainingExpired,
      isPreparingTraining,
    ]
  );
}

type FSLStandardModelEditorContextValue = ReturnType<typeof useMakeContext>;
const FSLStandardModelEditorContext =
  React.createContext<FSLStandardModelEditorContextValue>(null as any);

interface Props {
  children: React.ReactNode;
}

export const FSLStandardModelEditorProvider = (props: Props) => {
  const value = useMakeContext();
  return <FSLStandardModelEditorContext.Provider {...props} value={value} />;
};

export function useFSLStandardModelEditorContainer() {
  return React.useContext(FSLStandardModelEditorContext);
}
