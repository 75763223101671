import { Anchor } from "../../types/anchor";
import { DetectionRegion } from "../../types/detectionRegion";
import { Field } from "../../types/field";
import {
  DetailedForm,
  Form,
  ImageInfo,
  PaginatedBriefForm,
  PaginatedWithOffsetBriefForm,
  detailedFormSchema,
  formSchema,
  paginatedBriefFormSchema,
  paginatedWithOffsetBriefFormSchema,
} from "../../types/form";
import { DetectionRegionMapper } from "../../types/mappers/detectionRegion";
import { KeyValueMapper } from "../../types/mappers/keyValue";
import { TokenGroupMapper } from "../../types/mappers/tokenGroup";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface FormApiClient {
  listForm: (
    size: number,
    cursor?: string,
    options?: ListFormOptions,
    resourceOwnerId?: string
  ) => Promise<PaginatedBriefForm>;
  createForm: (
    name: string,
    options?: CreateFormOptions,
    resourceOwnerId?: string
  ) => Promise<Form>;
  getForm: (formId: string, resourceOwnerId?: string) => Promise<DetailedForm>;
  updateForm: (
    formId: string,
    updateParams: FormUpdateParams,
    resourceOwnerId?: string
  ) => Promise<Form>;
  deleteForm: (formId: string, resourceOwnerId?: string) => Promise<void>;
  pinForm: (formId: string, resourceOwnerId?: string) => Promise<void>;
  unpinForm: (formId: string, resourceOwnerId?: string) => Promise<void>;
  listFormWithOffset: (
    size: number,
    cursor?: string,
    options?: ListFormOptions,
    resourceOwnerId?: string,
    region?: string
  ) => Promise<PaginatedWithOffsetBriefForm>;
  restoreFormConfig: (
    formId: string,
    formConfigSnapshotId: string,
    retrievedAt: string,
    shouldIgnoreConflict: boolean,
    resourceOwnerId?: string
  ) => Promise<Form>;
}

export interface ListFormOptions {
  isReceipt?: boolean;
  isTemplate?: boolean;
}

export interface CreateFormOptions {
  masterImageInfo?: ImageInfo;
  isReceipt?: boolean;
}

export type FormUpdateParams = Partial<
  Pick<
    Form,
    "name" | "config" | "tokenGroups" | "keyValues" | "customModelIds"
  > & {
    masterImageInfo: ImageInfo;
    shouldRecomputeFeatures: boolean;
    data: {
      detectionRegions: DetectionRegion[];
      fields: Field[];
      anchors: Anchor[];
    };
  }
> & {
  lastRetrieved: string;
  shouldOverwrite: boolean;
  snapshotName?: string;
  snapshotNote?: string;
};

export function withFormApi<TBase extends ApiClientConstructor<_BaseApiClient>>(
  Base: TBase
) {
  return class extends Base {
    async listForm(
      size: number,
      cursor?: string,
      options?: ListFormOptions,
      resourceOwnerId?: string
    ): Promise<PaginatedBriefForm> {
      const args = this.injectOptionalFields(
        {
          page_args: {
            size,
            cursor: cursor || "",
          },
        },
        {
          resource_owner_id: resourceOwnerId,
          is_receipt: options?.isReceipt,
          is_template: options?.isTemplate,
        }
      );

      return this.lambda("form:list", args, paginatedBriefFormSchema);
    }

    async createForm(
      name: string,
      options?: CreateFormOptions,
      resourceOwnerId?: string
    ): Promise<Form> {
      const args = this.injectOptionalFields(
        { name },
        {
          resource_owner_id: resourceOwnerId,
          image_id: options?.masterImageInfo?.imageId,
          image_size: options?.masterImageInfo?.size,
          is_receipt: options?.isReceipt,
        }
      );

      return this.lambda("form:create", args, formSchema);
    }

    async getForm(
      formId: string,
      resourceOwnerId?: string
    ): Promise<DetailedForm> {
      return this.lambda<DetailedForm>(
        "form:get",
        this.injectOptionalFields(
          {
            form_id: formId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        detailedFormSchema
      );
    }

    async deleteForm(formId: string, resourceOwnerId?: string): Promise<void> {
      return this.lambda(
        "form:delete",
        this.injectOptionalFields(
          {
            form_id: formId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }

    async pinForm(formId: string, resourceOwnerId?: string): Promise<void> {
      return this.lambda(
        "form:pin",
        this.injectOptionalFields(
          {
            form_id: formId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }

    async unpinForm(formId: string, resourceOwnerId?: string): Promise<void> {
      return this.lambda(
        "form:unpin",
        this.injectOptionalFields(
          {
            form_id: formId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }

    async updateForm(
      formId: string,
      updateParams: FormUpdateParams,
      resourceOwnerId?: string
    ): Promise<Form> {
      const args = this.injectOptionalFields(
        {
          form_id: formId,
          retrieved_at: updateParams.lastRetrieved,
        },
        {
          resource_owner_id: resourceOwnerId,
          name: updateParams.name,
          config: updateParams.config,
          should_recompute_features: updateParams.shouldRecomputeFeatures,
          token_groups:
            updateParams.tokenGroups &&
            TokenGroupMapper.toRequest(updateParams.tokenGroups),
          key_values:
            updateParams.keyValues &&
            KeyValueMapper.toResp(updateParams.keyValues),
          custom_model_ids: updateParams.customModelIds,
          image_id: updateParams.masterImageInfo?.imageId,
          image_size: updateParams.masterImageInfo?.size,
          data: updateParams.data
            ? {
                detection_regions: updateParams.data.detectionRegions.map(
                  region => DetectionRegionMapper.toResp(region)
                ),
                fields: updateParams.data.fields,
                anchors: updateParams.data.anchors,
              }
            : undefined,
          should_overwrite: updateParams.shouldOverwrite,
          snapshot_name: updateParams.snapshotName,
          snapshot_note: updateParams.snapshotNote,
        }
      );

      return this.lambda("form:update", args, formSchema);
    }

    async listFormWithOffset(
      size: number,
      cursor?: string,
      options?: ListFormOptions,
      resourceOwnerId?: string,
      region?: string
    ): Promise<PaginatedWithOffsetBriefForm> {
      const args = this.injectOptionalFields(
        {
          page_args: {
            type: "offset",
            size,
            cursor: cursor || "",
          },
        },
        {
          resource_owner_id: resourceOwnerId,
          is_receipt: options?.isReceipt,
          is_template: options?.isTemplate,
        }
      );

      return this.lambda(
        "form:list",
        args,
        paginatedWithOffsetBriefFormSchema,
        null,
        region !== undefined ? { region } : undefined
      );
    }

    async restoreFormConfig(
      formId: string,
      formConfigSnapshotId: string,
      retrievedAt: string,
      shouldIgnoreConflict: boolean,
      resourceOwnerId?: string
    ): Promise<Form> {
      return this.lambda(
        "form:restore-config-snapshot",
        {
          form_id: formId,
          form_config_snapshot_id: formConfigSnapshotId,
          resource_owner_id: resourceOwnerId,
          retrieved_at: retrievedAt,
          should_overwrite: shouldIgnoreConflict,
        },
        formSchema
      );
    }
  };
}
