import { Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useState } from "react";

import { SUPPORTED_IMAGE_MIME } from "../../constants";
import { useFormEditor } from "../../contexts/formEditor";
import { useDragAndDropFiles } from "../../hooks/drag_and_drop";
import { useTeamPermission } from "../../hooks/permission";
import { useToast } from "../../hooks/toast";
import rightSample from "../../images/tutorial/right-sample.png";
import wrongSample from "../../images/tutorial/wrong-sample.png";
import { FilePrimaryButton } from "../FileButton";
import { Img } from "../Img";
import LoadingModal from "../LoadingModal";
import styles from "./styles.module.scss";

const DOS_ID_LIST = [
  "form.editor.no.image.placeholder.dos.item1",
  "form.editor.no.image.placeholder.dos.item2",
  "form.editor.no.image.placeholder.dos.item3",
];

const DONTS_ID_LIST = [
  "form.editor.no.image.placeholder.donts.item1",
  "form.editor.no.image.placeholder.donts.item2",
  "form.editor.no.image.placeholder.donts.item3",
];

const NoFormImagePlaceholder = React.memo(() => {
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const { updateImage } = useFormEditor();

  const onFiles = useCallback(
    (files?: File[]) => {
      setIsLoading(true);

      if (!files || files[0] === undefined) {
        toast.error("form.editor.no.image.missing.image");
        setIsLoading(false);
        return;
      }

      updateImage(files[0]).catch(() => {
        toast.error("error.master_image.cannot_load_image");
        setIsLoading(false);
      });
    },
    [toast, updateImage]
  );

  useDragAndDropFiles(onFiles, SUPPORTED_IMAGE_MIME);

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <Text variant="large" className={styles["instruction1"]}>
          <FormattedMessage id="form.editor.no.image.placeholder.instruction1" />
        </Text>
        {hasPermissionToEditResource && (
          <div className={styles["drop-zone"]}>
            <FilePrimaryButton
              textId={"form.editor.no.image.upload.button"}
              onFiles={onFiles}
            />
          </div>
        )}
        <div className={styles["separator"]}></div>
        <Text variant="medium" className={styles["instruction2"]}>
          <FormattedMessage id="form.editor.no.image.placeholder.instruction2" />
        </Text>
        <Text variant="small" className={styles["instruction3"]}>
          <FormattedMessage
            id="form.editor.no.image.placeholder.instruction3"
            values={{
              highlights: (
                <span className={styles["highlights"]}>
                  <FormattedMessage id="form.editor.no.image.placeholder.instruction3.highlights" />
                </span>
              ),
            }}
          />
        </Text>
        <div className={styles["dos-and-donts"]}>
          <div className={styles["dos"]}>
            <Text variant="small" className={styles["dos-title"]}>
              <FormattedMessage id="form.editor.no.image.placeholder.dos" />
            </Text>
            <Img
              className={styles["dos-image"]}
              src={rightSample}
              draggable="false"
            />
            {DOS_ID_LIST.map(doItem => {
              return (
                <div className={styles["dos-and-donts-item"]} key={doItem}>
                  <div className={styles["tick"]}></div>
                  <FormattedMessage id={doItem} />
                </div>
              );
            })}
          </div>
          <div className={styles["donts"]}>
            <Text variant="small" className={styles["donts-title"]}>
              <FormattedMessage id="form.editor.no.image.placeholder.donts" />
            </Text>
            <Img
              className={styles["donts-image"]}
              src={wrongSample}
              draggable="false"
            />
            {DONTS_ID_LIST.map(dontItem => {
              return (
                <div className={styles["dos-and-donts-item"]} key={dontItem}>
                  <div className={styles["cross"]}></div>
                  <FormattedMessage id={dontItem} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <LoadingModal
        messageId="form.editor.no.image.uploading"
        isOpen={isLoading}
      />
    </div>
  );
});

export default NoFormImagePlaceholder;
