import { useCallback, useMemo } from "react";

import { DetectPIIResponse, workerClient } from "../workerClient";

export function useDetectPIIActionCreator() {
  const detectPII = useCallback(
    async (token: string, image: File): Promise<DetectPIIResponse> => {
      return await workerClient(token).detectPII(image);
    },
    []
  );

  return useMemo(() => ({ detectPII }), [detectPII]);
}
