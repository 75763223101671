import { Link, TextField, Toggle } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import cntl from "cntl";
import * as React from "react";

import { DataLogExtractorType } from "../../apiClient/mixin/dataLog";
import { README_IO_PAGE_URL, UserFeatureFlag } from "../../constants";
import {
  DEFAULT_DATA_LOG_RETENTION_PERIOD,
  MAX_DATA_LOG_RETENTION_PERIOD,
  MIN_DATA_LOG_RETENTION_PERIOD,
} from "../../constants/dataLog";
import { LocalStorageKeys } from "../../constants/localStorage";
import {
  useDataLogCreateRemovalRequest,
  useDataLogListResource,
} from "../../hooks/dataLog";
import { useN8N } from "../../hooks/n8n";
import { useReadmeIO } from "../../hooks/readmeIO";
import { useAppSelector } from "../../hooks/redux";
import { useToast } from "../../hooks/toast";
import { useUserInfo } from "../../hooks/user";
import { BriefExtractor } from "../../types/extractor";
import { ExtractorSettings } from "../../types/extractorSettings";
import { ensureFOCRError } from "../../utils/errors";
import BetaTag from "../BetaTag";
import { DestroyButton } from "../DestroyButton";
import {
  MultiChoiceModal,
  useMultiChoiceModalAcceptButton,
  useMultiChoiceModalHandle,
} from "../MultiChoiceModal";
import { DefaultButton, PrimaryButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

type Props = {
  extractorSettings: ExtractorSettings;
  extractorType: BriefExtractor["extractorType"];
  onDataLogEnabledChange: (checked: boolean) => void;
  onDataLogRetentionPeriodChange: (period: number | undefined) => void;
  setHasError: (hasError: boolean) => void;
  dataLogSettingHandle: ReturnType<typeof useDataLogSettingHandle>;
};

export function useDataLogSettingHandle() {
  // It is not suggested to split handle and state function
  // But the ExtractorSetting has splitted into handle and state
  // so we keep the same pattern but new code should use the same function
  const [formOrFormGroupId, setFormOrFormGroupId] = React.useState<
    string | undefined
  >(undefined);
  const [extractorType, setExtractorType] = React.useState<
    DataLogExtractorType | undefined
  >(undefined);

  const dataLogDisabledWarningModalHandle = useMultiChoiceModalHandle();

  const openDataLogDisabledWarningModal = React.useCallback(() => {
    dataLogDisabledWarningModalHandle.methods.open();
  }, [dataLogDisabledWarningModalHandle.methods]);

  const [
    dataLogDisableInstructionVisible,
    setDataLogDisableInstructionVisible,
  ] = React.useState(false);

  const toast = useToast();

  const isDataLogWorkable = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.DagaLogWorkable
    )
  );

  const [requestDataLogRemovalEnabled, setRequestDataLogRemovalEnabled] =
    React.useState<boolean>(false);

  const dataLogListResource = useDataLogListResource();

  const queryDataLogSettingIfEnabled = React.useCallback(
    (extractorId: string, formOrFormGroupId: DataLogExtractorType) => {
      if (!isDataLogWorkable) {
        return;
      }
      setFormOrFormGroupId(extractorId);
      setExtractorType(formOrFormGroupId);
      dataLogListResource
        .query(
          extractorId,
          1 // Get the first item to check if the extractor has data log
        )
        .then(({ response, error }) => {
          if (response) {
            if (response.dataLogList.length > 0) {
              setRequestDataLogRemovalEnabled(true);
            }
          } else if (error) {
            toast.error(error.messageId, undefined, error.detail);
          }
        });
    },
    [dataLogListResource, isDataLogWorkable, toast]
  );

  const { createDataLogRemovalRequest: _createDataLogRemovalRequest } =
    useDataLogCreateRemovalRequest();

  const createDataLogRemovalRequest = React.useCallback(async () => {
    const success = await _createDataLogRemovalRequest(formOrFormGroupId ?? "");
    if (success) {
      setRequestDataLogRemovalEnabled(false);
    }
  }, [_createDataLogRemovalRequest, formOrFormGroupId]);

  const { onRedirectToReadmeIO } = useReadmeIO();

  const openLearnHow = React.useCallback(() => {
    onRedirectToReadmeIO(README_IO_PAGE_URL.referenceV2DatalogFile, {});
  }, [onRedirectToReadmeIO]);

  return React.useMemo(
    () => ({
      createDataLogRemovalRequest,
      isDataLogWorkable,
      queryDataLogSettingIfEnabled,
      requestDataLogRemovalEnabled,
      dataLogDisableInstructionVisible,
      setDataLogDisableInstructionVisible,
      extractorType,
      openDataLogDisabledWarningModal,
      openLearnHow,
      dataLogDisabledWarningModalProps: dataLogDisabledWarningModalHandle.props,
    }),
    [
      createDataLogRemovalRequest,
      isDataLogWorkable,
      queryDataLogSettingIfEnabled,
      requestDataLogRemovalEnabled,
      dataLogDisableInstructionVisible,
      setDataLogDisableInstructionVisible,
      extractorType,
      openDataLogDisabledWarningModal,
      openLearnHow,
      dataLogDisabledWarningModalHandle.props,
    ]
  );
}

export function useDataLogSettingState(props: Props) {
  const { submitDataLogRequest } = useN8N();
  const toast = useToast();

  const {
    extractorSettings,
    extractorType,
    onDataLogEnabledChange,
    onDataLogRetentionPeriodChange,
    setHasError,
  } = props;

  const { userId, displayName, email } = useUserInfo();

  const [requestButtonEnabled, setRequestButtonEnabled] = React.useState(() => {
    const requested = localStorage.getItem(
      LocalStorageKeys.DataLogFeatureRequested
    );
    return requested == null;
  });
  const [hasDataLogValidationError, _setHasDataLogValidationError] =
    React.useState(false);
  const [retentionPeriodError, setRetentionPeriodError] = React.useState<
    string | undefined
  >();

  const setHasDataLogValidationError = React.useCallback(
    (hasError: boolean) => {
      setHasError(hasError);
      _setHasDataLogValidationError(hasError);
      setRetentionPeriodError(
        hasError
          ? "extractor.setting.block.data_log_settings.retention_period.error"
          : undefined
      );
    },
    [setHasError]
  );

  const sendRequestMessage = React.useCallback(async () => {
    try {
      setRequestButtonEnabled(false);
      const res = await submitDataLogRequest(
        userId ?? "",
        displayName,
        email ?? "",
        extractorType
      );
      if (res) {
        localStorage.setItem(LocalStorageKeys.DataLogFeatureRequested, "true");
        toast.success(
          "extractor.setting.block.data_log_settings.request_access_message"
        );
      } else {
        setRequestButtonEnabled(true);
        toast.error("error.network_error");
      }
    } catch (e) {
      setRequestButtonEnabled(true);
      const error = ensureFOCRError(e);
      toast.error(error.messageId);
    }
  }, [displayName, email, submitDataLogRequest, toast, userId, extractorType]);

  const onRetentionEnabledChange = React.useCallback(
    (_e: React.MouseEvent<HTMLElement>, checked?: boolean) => {
      const value = checked ?? false;
      onDataLogEnabledChange(value);
      if (!value && hasDataLogValidationError) {
        setHasDataLogValidationError(false);
      }
    },
    [
      onDataLogEnabledChange,
      setHasDataLogValidationError,
      hasDataLogValidationError,
    ]
  );

  const onRetentionPeriodChange = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      if (value !== undefined) {
        const num = parseInt(value);
        if (value === "") {
          onDataLogRetentionPeriodChange(undefined);
          setHasDataLogValidationError(true);
          return;
        }

        if (!value.match(/^[0-9]*$/g)) {
          return;
        }
        onDataLogRetentionPeriodChange(num);

        if (
          num < MIN_DATA_LOG_RETENTION_PERIOD ||
          num > MAX_DATA_LOG_RETENTION_PERIOD
        ) {
          setHasDataLogValidationError(true);
        } else {
          setHasDataLogValidationError(false);
        }
      }
    },
    [onDataLogRetentionPeriodChange, setHasDataLogValidationError]
  );

  const retentionPeriod = React.useMemo(() => {
    if (extractorSettings.extractionRetentionPeriod === undefined) {
      return extractorSettings.extractionRetentionEnabled !== true
        ? DEFAULT_DATA_LOG_RETENTION_PERIOD.toString()
        : "";
    }
    return extractorSettings.extractionRetentionPeriod.toString();
  }, [extractorSettings]);

  return React.useMemo(() => {
    return {
      requestButtonEnabled,
      sendRequestMessage,
      extractorSettings,
      onRetentionPeriodChange,
      onRetentionEnabledChange,
      retentionPeriodError,
      retentionPeriod,
      ...props.dataLogSettingHandle,
    };
  }, [
    requestButtonEnabled,
    sendRequestMessage,
    extractorSettings,
    onRetentionPeriodChange,
    onRetentionEnabledChange,
    retentionPeriodError,
    retentionPeriod,
    props.dataLogSettingHandle,
  ]);
}

export function DataLogSetting(props: Props) {
  return <DataLogSettingImpl {...useDataLogSettingState(props)} />;
}

export function DataLogSettingImpl(
  props: ReturnType<typeof useDataLogSettingState>
) {
  const isWorkable = props.isDataLogWorkable;
  const {
    requestButtonEnabled,
    sendRequestMessage,
    requestDataLogRemovalEnabled,
    extractorSettings,
    onRetentionPeriodChange,
    onRetentionEnabledChange,
    retentionPeriodError,
    retentionPeriod,
    createDataLogRemovalRequest,
  } = props;

  const dataLogEnabled = extractorSettings.extractionRetentionEnabled ?? false;

  return (
    <div className={cn(styles["block"])}>
      <div className={cn(styles["block-content"])}>
        <div
          className={cn(
            styles["block-title"],
            "flex flex-row items-start gap-[4px]"
          )}
        >
          <FormattedMessage id="extractor.setting.block.data_log_settings.title" />
          <BetaTag textId="extractor.setting.block.data_log_settings.new_tag" />
        </div>

        <div
          className={cntl`${styles["subtitle"]}
              pt-[8px] pb-[4px]`}
        >
          <div className="flex flex-row">
            <div>
              <FormattedMessage id="extractor.setting.block.data_log_settings.description" />
              <div className="pt-[16px]">
                <FormattedMessage id="extractor.setting.block.data_log_settings.instruction" />
                <Link onClick={props.openLearnHow}>
                  <FormattedMessage id="extractor.setting.block.data_log_settings.learn_more" />
                </Link>
              </div>
            </div>
            <div className="pl-[8px]">
              <Toggle
                disabled={!props.isDataLogWorkable}
                checked={dataLogEnabled}
                onChange={onRetentionEnabledChange}
              />
            </div>
          </div>
        </div>
        {isWorkable ? (
          <>
            <div
              className={cntl`${styles["block-title"]}
            pt-[8px] pb-[4px]`}
            >
              <FormattedMessage id="extractor.setting.block.data_log_settings.retention_period.title" />
            </div>
            <div className={cntl`${styles["subtitle"]} pt-[4px]`}>
              <FormattedMessage id="extractor.setting.block.data_log_settings.retention_period.description" />
            </div>
            <div className="pt-[16px] flex flex-row gap-[4px] items-center">
              <div className="max-w-[80px]">
                <TextField
                  value={retentionPeriod}
                  onChange={onRetentionPeriodChange}
                  disabled={!dataLogEnabled}
                ></TextField>
              </div>
              <div className={cntl`${styles["subtitle"]}`}>
                <FormattedMessage id="extractor.setting.block.data_log_settings.retention_period.days" />
              </div>
            </div>
            {retentionPeriodError && (
              <div className="text-[#A4262C] text-[12px] font-normal pt-[2px]">
                <FormattedMessage id={retentionPeriodError} />
              </div>
            )}
            {!dataLogEnabled && props.dataLogDisableInstructionVisible && (
              <div
                className={cntl`${styles["subtitle"]} pt-[6px] color-[#605E5C]`}
              >
                <FormattedMessage id="extractor.setting.block.data_log_settings.retention_period.instruction" />
              </div>
            )}
            <div className="pt-[24px]">
              <DestroyButton
                textId="extractor.setting.block.data_log_settings.retention_period.delete_data"
                disabled={!requestDataLogRemovalEnabled}
                onClick={createDataLogRemovalRequest}
              />
            </div>
          </>
        ) : (
          <>
            <div className="pt-[24px]">
              <DefaultButton
                textId="extractor.setting.block.data_log_settings.request_access"
                onClick={sendRequestMessage}
                disabled={!requestButtonEnabled}
              />
            </div>
            <div className={cntl`${styles["subtitle"]} pt-[8px]`}>
              <FormattedMessage id="extractor.setting.block.data_log_settings.sign_up" />
            </div>
          </>
        )}
      </div>

      <MultiChoiceModal
        titleId={"data_log_disable_warning.title"}
        messageId={"data_log_disable_warning.message"}
        {...props.dataLogDisabledWarningModalProps}
      >
        <PrimaryButton
          textId="common.ok"
          onClick={useMultiChoiceModalAcceptButton(
            props.dataLogDisabledWarningModalProps,
            ""
          )}
        />
      </MultiChoiceModal>
    </div>
  );
}
