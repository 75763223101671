.message-bar {
  position: absolute;
  max-width: 250px;
  min-width: 100px;
  left: 0;
  bottom: 0px;
  z-index: 100;

  .upgrade-button {
    margin-top: 8px;
  }
}
