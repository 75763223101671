import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useLocalizeRegion } from "../../hooks/app";
import {
  TeamInvitationListItemStore,
  TeamInvitationStatus,
  getTeamRoleMessageId,
} from "../../types/team";
import { DefaultLoadingButton, PrimaryLoadingButton } from "../LoadingButton";
import { PrimaryButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface InvitationCardProps {
  invitation: TeamInvitationListItemStore;
  region: string;
  teamId?: string;
  onAccept: (invitationId: string, region: string) => Promise<void>;
  onDelete: (invitationId: string, region: string) => Promise<void>;
  onViewTeam: (teamId: string, region: string) => void;
}

function InvitationCard(props: InvitationCardProps) {
  const { invitation, region, teamId, onAccept, onDelete, onViewTeam } = props;
  const [isAccepting, setIsAccepting] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const localizeRegion = useLocalizeRegion();

  const invitationId = invitation.id;

  const onAcceptButtonClick = React.useCallback(async () => {
    try {
      setIsAccepting(true);
      await onAccept(invitationId, region);
    } finally {
      setIsAccepting(false);
    }
  }, [onAccept, invitationId, region]);

  const onDeleteButtonClick = React.useCallback(async () => {
    try {
      setIsDeleting(true);
      await onDelete(invitationId, region);
    } finally {
      setIsDeleting(false);
    }
  }, [onDelete, invitationId, region]);

  const onViewTeamButtonClick = React.useCallback(() => {
    if (teamId === undefined) {
      return;
    }
    onViewTeam(teamId, region);
  }, [onViewTeam, teamId, region]);

  return (
    <div className={styles["invitation"]}>
      <h1>{invitation.teamName}</h1>
      <h2>
        <FormattedMessage
          id={getTeamRoleMessageId(invitation.permission, false)}
        />
      </h2>
      <h3>
        <FormattedMessage
          id="team.invitation.list.region"
          values={{
            region: localizeRegion(region),
          }}
        />
      </h3>
      <div className={styles["bottom"]}>
        {invitation.status === TeamInvitationStatus.Pending && (
          <>
            <DefaultLoadingButton
              isLoading={isDeleting}
              loadingMessageId="common.deleting"
              compact
              className={styles["loading-button"]}
              onClick={onDeleteButtonClick}
              disabled={isAccepting}
            >
              <FormattedMessage id="common.delete" />
            </DefaultLoadingButton>
            <PrimaryLoadingButton
              isLoading={isAccepting}
              loadingMessageId="common.accepting"
              compact
              className={styles["loading-button"]}
              onClick={onAcceptButtonClick}
            >
              <FormattedMessage id="common.accept" />
            </PrimaryLoadingButton>
          </>
        )}
        {invitation.status === TeamInvitationStatus.Accepted && (
          <PrimaryButton
            textId="team.invitation.list.view_team"
            onClick={onViewTeamButtonClick}
          />
        )}
        {invitation.status === TeamInvitationStatus.Deleted && (
          <PrimaryButton textId="team.invitation.list.deleted" disabled />
        )}
      </div>
    </div>
  );
}

interface Props {
  invitationsByRegion: { [Key: string]: TeamInvitationListItemStore[] };
  isError?: boolean;
  selectedInvitationId?: string;
  hasTeamInRegion: boolean;
  onAccept: (invitationId: string, region: string) => Promise<void>;
  onDelete: (invitationId: string, region: string) => Promise<void>;
  onViewTeam: (teamId: string, region: string) => void;
  onSkip: () => void;
}

interface InvitationWithRegion {
  invitation: TeamInvitationListItemStore;
  region: string;
}

export function ExistingTeamInvitations(props: Props) {
  const {
    invitationsByRegion,
    selectedInvitationId,
    isError,
    hasTeamInRegion,
    onAccept,
    onDelete,
    onViewTeam,
    onSkip,
  } = props;

  const { selectedInvitation, otherInvitations } = React.useMemo(() => {
    const invitations: InvitationWithRegion[] = Object.keys(invitationsByRegion)
      .map(region => {
        const invitations = invitationsByRegion[region];
        return invitations.map(invitation => ({
          invitation,
          region,
        }));
      })
      .flat();

    const selectedInvitationIndex = invitations.findIndex(
      ({ invitation: { id } }) => id === selectedInvitationId
    );

    return {
      otherInvitations:
        selectedInvitationIndex >= 0
          ? invitations
              .slice(0, selectedInvitationIndex)
              .concat(invitations.slice(selectedInvitationIndex + 1))
          : invitations,

      selectedInvitation:
        selectedInvitationIndex >= 0
          ? invitations[selectedInvitationIndex]
          : undefined,
    };
  }, [invitationsByRegion, selectedInvitationId]);

  const hasEndpointInvitations = React.useMemo(() => {
    return Object.values(invitationsByRegion).flat().length > 0;
  }, [invitationsByRegion]);

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <h1>
          <FormattedMessage id="team.invitation.list.title" />
        </h1>

        {hasEndpointInvitations && !isError && (
          <>
            <h2>
              <FormattedMessage id="team.invitation.list.header" />
            </h2>
            <div className={styles["invitations"]}>
              {selectedInvitation && (
                <>
                  <InvitationCard
                    key={selectedInvitation.invitation.id}
                    invitation={selectedInvitation.invitation}
                    region={selectedInvitation.region}
                    teamId={selectedInvitation.invitation.teamId}
                    onAccept={onAccept}
                    onDelete={onDelete}
                    onViewTeam={onViewTeam}
                  />
                  {otherInvitations.length > 0 && <hr />}
                </>
              )}
              {otherInvitations.map(({ region, invitation }) => (
                <InvitationCard
                  key={invitation.id}
                  invitation={invitation}
                  region={region}
                  teamId={invitation.teamId}
                  onAccept={onAccept}
                  onDelete={onDelete}
                  onViewTeam={onViewTeam}
                />
              ))}
            </div>
          </>
        )}

        {!hasEndpointInvitations && !isError && (
          <div className={styles["message"]}>
            <h3>
              <FormattedMessage id="team.invitation.list.no_invitation_yet" />
            </h3>
          </div>
        )}

        {isError && (
          <div className={styles["message"]}>
            <h3>
              <FormattedMessage id="team.invitation.list.failed_to_get_invitations" />
            </h3>
          </div>
        )}

        <div className={styles["action"]} onClick={onSkip}>
          <FormattedMessage
            id={
              hasTeamInRegion
                ? "team.invitation.list.skip_and_go_to_extractor"
                : "onboarding.ignore_invitations.action"
            }
          />
        </div>
      </div>
    </div>
  );
}
