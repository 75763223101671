import React, { useCallback, useMemo } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";

import {
  TeamDetailResourcesLayout,
  TeamResourceTab,
} from "../components/TeamDetailResources";
import { useUnsafeParams } from "../hooks/params";
import { AdminPathParam } from "../models";
import { AdminTeamDetailResourcesCustomModelDetailContainer } from "./AdminTeamDetailResourcesCustomModelDetail";
import AdminTeamDetailResourcesCustomModelsContainer from "./AdminTeamDetailResourcesCustomModels";
import AdminTeamDetailResourcesFormGroupsContainer from "./AdminTeamDetailResourcesFormGroups";
import AdminTeamDetailResourcesFormsContainer from "./AdminTeamDetailResourcesForms";

function _AdminTeamDetailResourcesContainer() {
  const { teamId, region } = useUnsafeParams<AdminPathParam>();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentTab: TeamResourceTab = useMemo(() => {
    if (pathname.includes("form-groups")) {
      return TeamResourceTab.formGroups;
    }
    if (pathname.includes("custom-models")) {
      return TeamResourceTab.customModels;
    }
    return TeamResourceTab.forms;
  }, [pathname]);

  const onResourceTabSelected = useCallback(
    (resourceTab: TeamResourceTab) => {
      if (resourceTab === TeamResourceTab.forms) {
        navigate(`/admin/team/${region}/${teamId}/resources`);
        return;
      }
      navigate(
        `/admin/team/${region}/${teamId}/resources/${resourceTab.replace(
          "_",
          "-"
        )}`
      );
    },
    [navigate, teamId, region]
  );

  return (
    <TeamDetailResourcesLayout
      onTabSelected={onResourceTabSelected}
      currentTab={currentTab}
      teamId={teamId}
      region={region}
    >
      <Routes>
        <Route path="" element={<AdminTeamDetailResourcesFormsContainer />} />
        <Route
          path="form-groups"
          element={<AdminTeamDetailResourcesFormGroupsContainer />}
        />
        <Route
          path="custom-models"
          element={<AdminTeamDetailResourcesCustomModelsContainer />}
        />
        <Route
          path="custom-models/:customModelId"
          element={<AdminTeamDetailResourcesCustomModelDetailContainer />}
        />
      </Routes>
    </TeamDetailResourcesLayout>
  );
}

export const AdminTeamDetailResourcesContainer = React.memo(
  _AdminTeamDetailResourcesContainer
);
export default _AdminTeamDetailResourcesContainer;
