import {
  ActionButton,
  DefaultButton,
  IContextualMenuItem,
  IContextualMenuProps,
  ISearchBox,
  PrimaryButton,
  SearchBox,
  isMac,
} from "@fluentui/react";
import { Item } from "@glideapps/glide-data-grid";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useAdvanceTokenSetupEditor } from "../../contexts/advanceTokenSetupEditor";
import { useLocale } from "../../contexts/locale";
import {
  CellData,
  CellDataType,
  LocalizedHeader,
  MenuItem,
  RowData,
  SheetData,
  TableAccessor,
  Header as TableHeader,
  TagCellData,
  TagKey,
  getDefaultCellValue,
  getRowDataTagCellAt,
  hasSheetTag,
  isEmpty,
  removeRowDataAt,
} from "../../types/advancedTokenSetup/table";
import { deepClone } from "../../utils/deepClone";
import { getOrderingTextId } from "../AdvanceTokenSetupEditor";
import AdvanceTokenSetupTable, { Ordering } from "../AdvanceTokenSetupTable";
import styles from "./styles.module.scss";

export interface Props {
  sheetData: SheetData;
  menu: MenuItem;
  tags: Record<TagKey, string[]>;
  mergedHeaders: LocalizedHeader[];
  mergedData: RowData[];
  freezeColumns: number;
  onMergedDone: (headers: LocalizedHeader[], data: RowData[]) => void;
  onCancel: () => void;
}

function useAdvanceTokenSetupMergerEditorState(props: Props) {
  const {
    menu,
    mergedHeaders,
    mergedData,
    tags: defaultTags,
    onCancel,
    onMergedDone,
    freezeColumns,
    sheetData,
  } = props;

  const { askForTagRemovalConfirmation } = useAdvanceTokenSetupEditor();

  const { localized } = useLocale();
  const [ordering, setOrdering] = useState(Ordering.priorityDesc);
  const [query, setQuery] = useState<string>();
  const [tags, setTags] = useState(defaultTags);
  const [_headers, setHeaders] = useState<LocalizedHeader[]>(mergedHeaders);
  const [data, setData] = useState<RowData[]>(mergedData);
  const { focusSearchInput, triggerProps: headerTriggerProps } =
    useHeaderHandle();

  const headers = useMemo((): TableHeader[] => {
    return _headers.map(item => ({
      ...item,
      label: localized(item.labelId),
    }));
  }, [localized, _headers]);
  const showRowNumberCell =
    ordering !== Ordering.lastModifiedAsc &&
    ordering !== Ordering.lastModifiedDesc &&
    menu === MenuItem.ExactMatchRule;

  const onCreateTag = useCallback(
    (tagKey: TagKey, newTag: string) => {
      setTags(prev => ({
        ...prev,
        [tagKey]: prev[tagKey].concat(newTag),
      }));

      if (menu === MenuItem.TagMall || menu === MenuItem.TagMerchant) {
        setData(prev =>
          prev.concat([
            {
              order: prev.length + 1,
              modifiedAt: new Date().getTime(),
              data: [
                {
                  type: CellDataType.TagCellData,
                  tag: newTag,
                  tagKey,
                } as TagCellData,
              ],
            },
          ])
        );
      }
    },
    [menu]
  );

  const onDeleteTag = useCallback(
    (tagKey: TagKey, tag: string) => {
      setTags(prev => ({
        ...prev,
        [tagKey]: prev[tagKey].filter(t => t !== tag),
      }));
      if (menu === MenuItem.TagMall || menu === MenuItem.TagMerchant) {
        setData(prev =>
          prev.filter(
            d =>
              d.data.findIndex(
                dd => dd.type === CellDataType.TagCellData && dd.tag === tag
              ) === -1
          )
        );
      }
    },
    [menu]
  );

  const onRenameTag = useCallback(
    (tagKey: TagKey, row: number, oldName: string, newName: string) => {
      setTags(prev => ({
        ...prev,
        [tagKey]: prev[tagKey].map(t => (t === oldName ? newName : t)),
      }));
      if (menu === MenuItem.TagMall || menu === MenuItem.TagMerchant) {
        setData(prev => {
          return new TableAccessor(prev).renameTagAtRow(tagKey, row, newName);
        });
      }
    },
    [menu]
  );

  const onDelete = useCallback(
    (item: Item) => {
      if (!data[item[1]]) {
        // Ignore to remove empty row
        return;
      }
      const row = item[1];
      const tagCell = getRowDataTagCellAt(data, row);

      const removeExistingTag = hasSheetTag(
        sheetData,
        tagCell?.tagKey ?? "",
        tagCell?.tag ?? ""
      );

      if (removeExistingTag) {
        askForTagRemovalConfirmation()
          .then(() => {
            const { newData } = removeRowDataAt(data, row);
            setData(newData as RowData[]);
            const { tag, tagKey } = tagCell as TagCellData;
            onDeleteTag(tagKey, tag);
          })
          .catch(() => {});
      } else {
        setData(removeRowDataAt(data, row).newData);
      }
    },
    [data, setData, onDeleteTag, askForTagRemovalConfirmation, sheetData]
  );

  const defaultCellValue = useCallback(
    (cellType: CellDataType, item: Item): CellData => {
      const [col] = item;
      const header = _headers[col];
      return getDefaultCellValue(header, cellType);
    },
    [_headers]
  );

  const autoAppendColumn = useCallback(
    (labelId: string) => {
      const header = _headers;
      const lastIndex =
        header.length -
        deepClone(header)
          .reverse()
          .findIndex(h => h.labelId === labelId) -
        1;
      if (lastIndex >= 0 && data.find(d => !isEmpty(d.data[lastIndex]))) {
        const newHeader: LocalizedHeader = deepClone(header[lastIndex]);
        const insertedColumn = lastIndex + 1;
        header.splice(insertedColumn, 0, newHeader);
        const newData = data.map((d, i) => {
          d.data.splice(
            insertedColumn,
            0,
            defaultCellValue(newHeader.type, [insertedColumn, i])
          );
          return {
            ...d,
            data: [...d.data],
          };
        });
        setHeaders([...header]);
        setData(newData);
      }
    },
    [data, _headers, defaultCellValue]
  );

  const onConfirmImport = useCallback(() => {
    onMergedDone(_headers, data);
  }, [onMergedDone, data, _headers]);

  useEffect(() => {
    if (menu === MenuItem.ExactMatchRule) {
      autoAppendColumn("advance_token_setup_table.header.positive_tokens");
      autoAppendColumn("advance_token_setup_table.header.negative_tokens");
    }
  }, [autoAppendColumn, menu]);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key.toLowerCase() === "/") {
        event.preventDefault();
        focusSearchInput();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [focusSearchInput]);

  return useMemo(() => {
    return {
      data,
      setData,
      headers,
      showRowNumberCell,
      ordering,
      setOrdering,
      query,
      setQuery,
      tags,
      onCreateTag,
      onDelete,
      onRenameTag,
      defaultCellValue,
      headerTriggerProps,
      localized,
      onCancel,
      onConfirmImport,
      freezeColumns,
    };
  }, [
    data,
    setData,
    headers,
    showRowNumberCell,
    ordering,
    setOrdering,
    query,
    setQuery,
    tags,
    onCreateTag,
    onDelete,
    onRenameTag,
    defaultCellValue,
    headerTriggerProps,
    localized,
    onCancel,
    onConfirmImport,
    freezeColumns,
  ]);
}

type MergedProps = ReturnType<typeof useAdvanceTokenSetupMergerEditorState>;

const useHeaderHandle = () => {
  const searchInputRef = useRef<ISearchBox>(null);

  const focusSearchInput = useCallback(() => {
    searchInputRef.current?.focus();
  }, []);
  return useMemo(
    () => ({
      triggerProps: {
        componentRef: searchInputRef,
      },
      focusSearchInput,
    }),
    [focusSearchInput]
  );
};

interface HeaderProps {
  className?: string;
  ordering: Ordering;
  onSetOrdering: (ordering: Ordering) => void;
  onSearch: (keyword?: string) => void;
  triggerProps: ReturnType<typeof useHeaderHandle>["triggerProps"];
}

const Header = React.memo<HeaderProps>(props => {
  const { className, ordering, onSetOrdering, onSearch, triggerProps } = props;
  const { localized } = useLocale();
  const placeholder = useMemo(() => {
    return isMac()
      ? localized("advance_token_setup.searchbar.placeholder.mac")
      : localized("advance_token_setup.searchbar.placeholder");
  }, [localized]);
  const onClickOrderingMenuItem = useCallback(
    (_: any, item?: IContextualMenuItem) => {
      if (item) {
        onSetOrdering(parseInt(item.key));
      }
    },
    [onSetOrdering]
  );

  const orderingMenu: IContextualMenuProps = useMemo(() => {
    return {
      items: [
        Ordering.priorityAsc,
        Ordering.priorityDesc,
        Ordering.lastModifiedAsc,
        Ordering.lastModifiedDesc,
      ].map(o => ({
        key: o.toString(),
        text: localized(getOrderingTextId(o)),
        onClick: onClickOrderingMenuItem,
      })),
    };
  }, [localized, onClickOrderingMenuItem]);

  const onClearSearch = useCallback(() => {
    onSearch(undefined);
  }, [onSearch]);

  const onTextChange = useCallback(
    (_, newValue?: string) => {
      onSearch(newValue);
    },
    [onSearch]
  );

  return (
    <div className={className}>
      <SearchBox
        {...triggerProps}
        placeholder={placeholder}
        styles={{
          root: {
            flex: 1,
            maxWidth: 350,
            height: "100%",
            border: 0,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 10,
            paddingRight: 10,
            background: "#FAF9F8",
          },
        }}
        onClear={onClearSearch}
        onSearch={onSearch}
        onChange={onTextChange}
      />
      <ActionButton
        className={styles["ordering-btn"]}
        iconProps={{ iconName: "SwitcherStartEnd" }}
        styles={{
          root: {
            border: 0,
          },
        }}
        text={localized(getOrderingTextId(ordering))}
        menuProps={orderingMenu}
      />
    </div>
  );
});

const AdvanceTokenSetupMergerEditorImpl = (props: MergedProps) => {
  const {
    setData,
    data,
    headers,
    query,
    showRowNumberCell,
    ordering,
    tags,
    onCreateTag,
    onDelete,
    onRenameTag,
    defaultCellValue,
    setQuery,
    setOrdering,
    headerTriggerProps,
    localized,
    onCancel,
    onConfirmImport,
    freezeColumns,
  } = props;
  return (
    <div>
      <div className={styles["nav-bar"]}>
        <div className={styles["button-group"]}>
          <DefaultButton
            text={localized(
              "advance_token_setup.merge_editor.action.cancel_import"
            )}
            onClick={onCancel}
          />
          <PrimaryButton
            className={styles["save-button"]}
            text={localized(
              "advance_token_setup.merge_editor.action.confirm_import"
            )}
            onClick={onConfirmImport}
          />
        </div>
      </div>
      <Header
        className={styles["header"]}
        ordering={ordering}
        onSearch={setQuery}
        onSetOrdering={setOrdering}
        triggerProps={headerTriggerProps}
      />
      <AdvanceTokenSetupTable
        headers={headers}
        data={data}
        setData={setData}
        showRowOrder={showRowNumberCell}
        defaultCellValue={defaultCellValue}
        jumpToErrorCell={false}
        ordering={ordering}
        filter={query}
        tags={tags}
        onCreateTag={onCreateTag}
        onDelete={onDelete}
        onRenameTag={onRenameTag}
        freezeColumns={freezeColumns}
      />
    </div>
  );
};

const AdvanceTokenSetupMergeEditor = (props: Props) => {
  return (
    <AdvanceTokenSetupMergerEditorImpl
      {...useAdvanceTokenSetupMergerEditorState(props)}
    />
  );
};

export default AdvanceTokenSetupMergeEditor;
