@import "../../styles/variables.scss";

.usage-date-range-selector {
  margin: 24px 30px;
  min-width: 689px;

  h1 {
    padding: 10px 0;
    font-size: 18px;
    margin: 10px 0;
    font-weight: 600;
    line-height: 24px;
  }

  .date-picker-row {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .date-picker-selectors {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex: 1;
  }

  .date-picker,
  .dropdown {
    padding: 0 5px;
    max-width: 200px;
    height: 62px;
    flex: 1;
  }

  .search-button {
    margin-left: 5px;
  }

  .dropdown-title {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
