.schema-not-set-warning {
  border-radius: 10px;
  background: #fff4ce;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px;
  height: 352px;
  justify-content: center;
  margin: 24px 0px;
}

.schema-not-set-warning-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 540px;
}

.schema-not-set-warning-title {
  color: #323130;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
}

.schema-not-set-warning-desc {
  color: #323130;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
