import { FormattedMessage } from "@oursky/react-messageformat";
import cntl from "cntl";
import * as React from "react";
import { useNavigate } from "react-router";

import { useLocalizeRegion } from "../../../hooks/app";
import {
  useAcceptRejectInvitation,
  useGetTeamInvitationsFromAllRegions,
} from "../../../hooks/invitation";
import {
  TeamInvitationListItemStore,
  TeamInvitationStatus,
  getTeamRoleMessageId,
} from "../../../types/team";
import {
  DefaultLoadingButton,
  PrimaryLoadingButton,
} from "../../LoadingButton";
import { PrimaryButton } from "../../WrappedMSComponents/Buttons";
import { Button } from "../Button";
import { Content } from "./Content";

type InvitationItem = TeamInvitationListItemStore & {
  region: string;
};

const EMPTY_OBJECT = {};

export function InvitationCard(props: {
  invitation: InvitationItem;
  teamId: string;
  isAccepting: boolean;
  isDeleting: boolean;
  onAccept: (invitationId: string, region: string) => Promise<void>;
  onDelete: (invitationId: string, region: string) => Promise<void>;
  onViewTeam: (teamId: string, region: string) => void;
}) {
  const localizeRegion = useLocalizeRegion();
  const { invitation, teamId } = props;
  const { region } = invitation;

  const onAccept = React.useCallback(() => {
    props.onAccept(invitation.id, region);
  }, [props, invitation.id, region]);

  const onDelete = React.useCallback(() => {
    props.onDelete(invitation.id, region);
  }, [props, invitation.id, region]);

  const onViewTeam = React.useCallback(() => {
    props.onViewTeam(teamId, region);
  }, [props, teamId, region]);

  return (
    <div
      className={cntl`w-[560px] max-w-[560px] h-[140px] max-h[140px] 
      border-1 border-solid border-grey-100 rounded-[4px] p-[20px] flex flex-col gap-[2px]`}
    >
      <div className="text-body-large">{props.invitation.teamName}</div>
      <div className="text-body-medium">
        <FormattedMessage
          id={getTeamRoleMessageId(invitation.permission, false)}
        />
      </div>

      <div className="text-body-small text-grey-300">
        <FormattedMessage
          id="team.invitation.list.region"
          values={{
            region: localizeRegion(region),
          }}
        />
      </div>
      <div className="flex-end self-end flex flex-row gap-[4px]">
        {invitation.status === TeamInvitationStatus.Deleted ? (
          <PrimaryButton textId="team.invitation.list.deleted" disabled />
        ) : invitation.status === TeamInvitationStatus.Accepted ? (
          <PrimaryButton
            textId="team.invitation.list.view_team"
            onClick={onViewTeam}
          />
        ) : (
          <>
            <DefaultLoadingButton
              isLoading={props.isDeleting}
              loadingMessageId="common.deleting"
              compact
              onClick={onDelete}
            >
              <FormattedMessage id="common.delete" />
            </DefaultLoadingButton>

            <PrimaryLoadingButton
              isLoading={props.isAccepting}
              loadingMessageId="common.accepting"
              compact
              onClick={onAccept}
            >
              <FormattedMessage id="common.accept" />
            </PrimaryLoadingButton>
          </>
        )}
      </div>
    </div>
  );
}

type Props = {
  onViewTeam: (teamId: string, region: string) => void;
};

export function useTeamInvitationPageState(props: Props) {
  const { onViewTeam } = props;
  const getInvitationState = useGetTeamInvitationsFromAllRegions();
  const invitationsByRegion: { [key: string]: TeamInvitationListItemStore[] } =
    getInvitationState.type === "success"
      ? getInvitationState.invitationsByRegion
      : EMPTY_OBJECT;
  const navigate = useNavigate();

  const {
    acceptTeamInvitation,
    acceptingInvitationIds,
    rejectTeamInvitation,
    rejectingInvitationIds,
  } = useAcceptRejectInvitation();

  const skip = React.useCallback(() => {
    navigate("/onboarding/team_creation");
  }, [navigate]);

  const invitations = React.useMemo(() => {
    const invitations: InvitationItem[] = Object.keys(invitationsByRegion)
      .map(region => {
        const invitations = invitationsByRegion[region];
        return invitations.map(invitation => ({
          ...invitation,
          region,
        }));
      })
      .flat();
    return invitations;
  }, [invitationsByRegion]);

  return React.useMemo(() => {
    return {
      skip,
      invitationsByRegion,
      invitations,
      acceptTeamInvitation,
      rejectTeamInvitation,
      rejectingInvitationIds,
      acceptingInvitationIds,
      onViewTeam,
    };
  }, [
    skip,
    invitationsByRegion,
    invitations,
    acceptTeamInvitation,
    rejectTeamInvitation,
    rejectingInvitationIds,
    acceptingInvitationIds,
    onViewTeam,
  ]);
}

export function TeamInvitationPage(props: Props) {
  const states = useTeamInvitationPageState(props);
  return <TeamInvitationPageImpl {...states} />;
}

export function TeamInvitationPageImpl(
  props: ReturnType<typeof useTeamInvitationPageState>
) {
  const {
    skip,
    invitations,
    acceptTeamInvitation,
    rejectTeamInvitation,
    rejectingInvitationIds,
    acceptingInvitationIds,
    onViewTeam,
  } = props;

  return (
    <Content
      sizeConstraint="max-w-[921px] min-w-[921px]"
      footer={
        <Button
          type="primary"
          textId="onboarding.ignore_invitations.action"
          onClick={skip}
        />
      }
    >
      <div className="flex flex-col justify-center items-center pb-[48px]">
        <div className="text-headline-medium text-primary pt-[48px]">
          <FormattedMessage id="team.invitation.list.title" />
        </div>

        {invitations.length === 0 && (
          <div className="text-title-medium text-text-secondary pt-[48px] flex flex-col justify-center items-center">
            <FormattedMessage id="team.invitation.list.no_invitation_yet" />
          </div>
        )}
        <div
          className={cntl`mt-[34px] 
          flex-col flex gap-[12px] max-h-[300px] h-[300px] overflow-y-auto`}
        >
          {invitations.map(invitation => (
            <InvitationCard
              key={invitation.id}
              invitation={invitation}
              teamId={invitation.teamId ?? ""}
              isAccepting={acceptingInvitationIds[invitation.id]}
              onAccept={acceptTeamInvitation}
              onDelete={rejectTeamInvitation}
              isDeleting={rejectingInvitationIds[invitation.id]}
              onViewTeam={onViewTeam}
            />
          ))}
        </div>
      </div>
    </Content>
  );
}
