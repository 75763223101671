import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useCustomModelActionCreator } from "../actions/customModel";
import CustomModelEditorImageGrid from "../components/CustomModelEditorImageGrid";
import CustomModelEditorUploadPane from "../components/CustomModelEditorUploadPane";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Right, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { SUPPORTED_EXTRACT_MIME, UserFeatureFlag } from "../constants";
import { CUSTOM_MODEL_IMAGE_PAGE_SIZE } from "../constants/layout";
import HeaderContainer from "../containers/Header";
import { useDelayFirstTransition } from "../hooks/common";
import { useCustomModel } from "../hooks/custom_model";
import { useNotifyError } from "../hooks/error";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { useToast } from "../hooks/toast";
import { CustomModel } from "../types/customModel";
import { CustomModelImage } from "../types/customModelImage";
import { openLabeller } from "../utils/labeller";
import CustomModelEditorRightBarContainer from "./CustomModelEditorRightBar";
import {
  CustomModelNavBarLayout,
  CustomModelNavTabKey,
} from "./CustomModelNavBarLayout";

/* The original custom model editor
 */

type PathParam = {
  customModelId: string;
};

interface Loading {
  type: "loading";
  numUploadingFile: number;
}

interface ShowImageGrid {
  type: "showImageGrid";
  customModel: CustomModel;
  customModelImages: CustomModelImage[];
  totalCustomModelImage: number;
  isGettingCustomModelImage: boolean;
  page: number;
  numUploadingFile: number;
  deletingCustomModelImageIds: string[];
  navigateToPage(page: number): void;
}

interface ShowImageUploadPane {
  type: "showImageUploadPane";
  customModel: CustomModel;
}

interface Failed {
  type: "failed";
}

type CustomModelEditorState =
  | Loading
  | ShowImageGrid
  | ShowImageUploadPane
  | Failed;

function useFetchCustomModelImage(customModelId: string) {
  const { listCustomModelImages } = useCustomModelActionCreator();

  const { setParam } = useSearchParamUtils();
  const [searchParam] = useSearchParams();
  const [page, setPage] = useState(
    parseInt(searchParam.get(URLParamsKey.page) || "1") || 1
  );
  const pageRef = useRef(page);
  const [forceRefreshCounter, setForceRefreshCounter] = useState(0);
  const hasFetchedOnce = useRef(false);

  const numImageInCurrentPage = useAppSelector(
    state => state.customModel.paginatedCustomModelImages?.images.length || 0
  );

  const navigateToPage = useCallback(
    (page: number) => {
      if (customModelId) {
        setParam(URLParamsKey.page, page.toString(), false);
      }
    },
    [setParam, customModelId]
  );

  useNotifyError(state => state.customModel.customModelImageError);

  useEffect(() => {
    if (numImageInCurrentPage === 0) {
      if (pageRef.current > 1) {
        setParam(URLParamsKey.page, "1", true);
      } else {
        setForceRefreshCounter(x => x + 1);
      }
    }
  }, [numImageInCurrentPage, setParam]);

  useEffect(() => {
    setPage(parseInt(searchParam.get(URLParamsKey.page) || "1") || 1);
  }, [searchParam]);

  useEffect(() => {
    if (customModelId) {
      listCustomModelImages(
        customModelId,
        CUSTOM_MODEL_IMAGE_PAGE_SIZE,
        (page - 1) * CUSTOM_MODEL_IMAGE_PAGE_SIZE
      );
      hasFetchedOnce.current = true;
    }
  }, [page, listCustomModelImages, customModelId, forceRefreshCounter]);

  return {
    page,
    navigateToPage,
    hasFetchedOnce,
  };
}

function useCustomModelEditorState(
  customModelId: string
): CustomModelEditorState {
  const { customModel, isFailedToFetchCustomModel } =
    useCustomModel(customModelId);

  const paginatedCustomModelImages = useAppSelector(
    state => state.customModel.paginatedCustomModelImages
  );
  const { page, navigateToPage, hasFetchedOnce } =
    useFetchCustomModelImage(customModelId);

  const numUploadingFile = useAppSelector(state => {
    if (customModelId in state.customModel.customModelImageUploadStatistics) {
      const stats =
        state.customModel.customModelImageUploadStatistics[customModelId];
      return stats.totalCount - stats.failedCount - stats.successCount;
    }
    return 0;
  });

  const deletingCustomModelImageIds = useAppSelector(state =>
    customModelId in state.customModel.deletingCustomModelImageIds
      ? state.customModel.deletingCustomModelImageIds[customModelId]
      : []
  );

  const isLoading =
    useDelayFirstTransition(
      customModelId === "" && !isFailedToFetchCustomModel
    ) ||
    paginatedCustomModelImages === undefined ||
    (paginatedCustomModelImages.pageInfo.totalCount === 0 &&
      numUploadingFile > 0);

  const isGettingCustomModelImage =
    useAppSelector(state => state.customModel.isGettingCustomModelImage) ||
    !hasFetchedOnce.current;

  const showImageGrid: ShowImageGrid | null =
    customModel &&
    !isLoading &&
    paginatedCustomModelImages &&
    paginatedCustomModelImages.pageInfo.totalCount > 0
      ? {
          type: "showImageGrid",
          customModel,
          customModelImages: paginatedCustomModelImages.images,
          totalCustomModelImage: paginatedCustomModelImages.pageInfo.totalCount,
          isGettingCustomModelImage:
            isGettingCustomModelImage ||
            paginatedCustomModelImages.images.length === 0,
          page,
          navigateToPage,
          numUploadingFile,
          deletingCustomModelImageIds,
        }
      : null;

  const showImageUploadPane: ShowImageUploadPane | null =
    customModel &&
    !isLoading &&
    paginatedCustomModelImages &&
    paginatedCustomModelImages.pageInfo.totalCount === 0
      ? {
          type: "showImageUploadPane",
          customModel,
        }
      : null;

  const failed: Failed | null = isFailedToFetchCustomModel
    ? {
        type: "failed",
      }
    : null;

  return (
    failed ||
    showImageGrid ||
    showImageUploadPane || {
      type: "loading",
      numUploadingFile,
    }
  );
}
interface Props {
  customModelId: string;
}
export function CustomModelEditor(props: Props) {
  const { customModelId } = props;
  const editorState = useCustomModelEditorState(customModelId);
  const { uploadCustomModelImages, deleteCustomModelImages } =
    useCustomModelActionCreator();
  const { error: notifyError, info: notifyInfo } = useToast();

  const customModelIdRef = useRef<string | null>(null);
  customModelIdRef.current =
    "customModel" in editorState ? editorState.customModel.id : null;

  const onUploadCustomModelImages = useCallback(
    (files: File[]) => {
      if (customModelIdRef.current === null) {
        return;
      }

      const filesExcludingDotFiles = files.filter(file => {
        return !file.name.startsWith(".");
      });

      const filteredFiles = filesExcludingDotFiles.filter(file => {
        return SUPPORTED_EXTRACT_MIME.includes(file.type);
      });

      if (filteredFiles.length !== filesExcludingDotFiles.length) {
        if (filteredFiles.length === 0) {
          notifyError("error.custom_model.image_format_not_supported");
        } else {
          notifyInfo("error.custom_model.some_image_not_supported");
        }
      }

      uploadCustomModelImages(customModelIdRef.current, filteredFiles);
    },
    [notifyError, notifyInfo, uploadCustomModelImages]
  );

  const onDeleteCustomModelImages = useCallback(
    (customModelImageIds: string[]) => {
      if (customModelIdRef.current === null) {
        return;
      }
      deleteCustomModelImages(customModelIdRef.current, customModelImageIds);
    },
    [deleteCustomModelImages]
  );

  const onOpenLabeller = useCallback((imageId: string) => {
    if (customModelIdRef.current) {
      openLabeller(customModelIdRef.current, imageId);
    }
  }, []);

  const isCustomModelTrainingOrLabellingEnabled = useAppSelector(state =>
    // #3048 - CustomModelTraining is removed from feature flags
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.CustomModelTraining)
  );

  if (editorState.type === "loading") {
    return (
      <LoadingModal
        isOpen={true}
        messageId={
          editorState.numUploadingFile ? "common.uploading" : undefined
        }
      />
    );
  }

  if (editorState.type === "failed") {
    return (
      <Main hasTop={true}>
        <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
      </Main>
    );
  }

  return (
    <>
      {editorState.type === "showImageUploadPane" && (
        <Main hasTop={true}>
          <CustomModelNavBarLayout
            selectedTab={CustomModelNavTabKey.Setup}
            customModel={editorState.customModel}
          >
            <CustomModelEditorUploadPane
              uploadCustomModelImages={onUploadCustomModelImages}
            />
          </CustomModelNavBarLayout>
        </Main>
      )}

      {editorState.type === "showImageGrid" && (
        <Main hasTop={true}>
          <CustomModelNavBarLayout
            selectedTab={CustomModelNavTabKey.Setup}
            customModel={editorState.customModel}
          >
            <CustomModelEditorImageGrid
              customModelImages={editorState.customModelImages}
              isLoading={editorState.isGettingCustomModelImage}
              totalFiles={editorState.totalCustomModelImage}
              currentPage={editorState.page}
              navigateToPage={editorState.navigateToPage}
              numUploadingFile={editorState.numUploadingFile}
              deletingCustomModelImageIds={
                editorState.deletingCustomModelImageIds
              }
              uploadCustomModelImages={onUploadCustomModelImages}
              deleteImages={onDeleteCustomModelImages}
              openLabeller={
                isCustomModelTrainingOrLabellingEnabled
                  ? onOpenLabeller
                  : undefined
              }
            />
          </CustomModelNavBarLayout>
        </Main>
      )}
      {["showImageUploadPane", "showImageGrid"].includes(editorState.type) && (
        <Right hasTop={true}>
          <CustomModelEditorRightBarContainer
            customModel={editorState.customModel}
          />
        </Right>
      )}
    </>
  );
}

function _CustomModelEditorContainer() {
  const { customModelId } = useUnsafeParams<PathParam>();

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <CustomModelEditor customModelId={customModelId} />
    </Layout>
  );
}

export const CustomModelEditorContainer = React.memo(
  _CustomModelEditorContainer
);
export default CustomModelEditorContainer;
