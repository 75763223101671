import * as React from "react";
import { useSelector } from "react-redux";

import { useFormActionCreator } from "../actions/form";
import { RootState } from "../redux/types";
import { DetailedForm } from "../types/form";
import { WorkerToken } from "../types/workerToken";
import { useWorkerToken } from "./app";

export function useForm(formId: string) {
  const { getForm } = useFormActionCreator();
  const form = useSelector((state: RootState) => state.form.currentForm);
  const [isFailedToFetchForm, setIsFailedToFetchForm] = React.useState(false);

  React.useEffect(() => {
    if (!form || form.id !== formId) {
      getForm(formId).catch(() => {
        setIsFailedToFetchForm(true);
      });
    }
  }, [form, getForm, formId]);

  return {
    form,
    isFailedToFetchForm,
  };
}

interface CommonFormContainerSuccessState {
  state: "success";
  workerTokens: WorkerToken[];
  workerToken: string | undefined;
  onSelectWorkerToken: (token: string) => void;
  form: DetailedForm;
}

interface CommonFormContainerErrorState {
  state: "error";
}

interface CommonFormContainerLoadingState {
  state: "loading";
}

export type CommonFormContainerState =
  | CommonFormContainerLoadingState
  | CommonFormContainerErrorState
  | CommonFormContainerSuccessState;

export function useCommonFormContainerState(formId: string) {
  const { form, isFailedToFetchForm } = useForm(formId);

  const {
    token: workerToken,
    tokens: workerTokens,
    onSelectToken: onSelectWorkerToken,
    isFailed: isFailedToGetWorkerToken,
  } = useWorkerToken();

  const containerState: CommonFormContainerState =
    isFailedToGetWorkerToken || isFailedToFetchForm
      ? { state: "error" }
      : form !== undefined && form.id === formId && workerTokens !== undefined
      ? {
          state: "success",
          form,
          workerToken,
          workerTokens,
          onSelectWorkerToken,
        }
      : { state: "loading" };

  return containerState;
}
