import { IconButton, Link, Pivot, PivotItem } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import copy from "copy-to-clipboard";
import React from "react";
import { useSelector } from "react-redux";

import { AppConfig } from "../../config";
import { useLocale } from "../../contexts/locale";
import { useWorkerToken } from "../../hooks/app";
import { useTeamPermission } from "../../hooks/permission";
import { RemoteData } from "../../hooks/remoteData";
import { useToast } from "../../hooks/toast";
import { ResourceOwnerState } from "../../reducers/resourceOwner";
import { RootState } from "../../redux/types";
import { Permission, TeamMembersAndInvitations } from "../../types/team";
import { WorkerToken } from "../../types/workerToken";
import ErrorPlaceholder from "../ErrorPlaceholder";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import TeamDetailMemberList from "../TeamDetailMemberList";
import TeamInfo from "../TeamInfo";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface WorkerTableRowProps {
  token: WorkerToken;
  onRename: () => void;
  onRevoke: () => void;
}

function TokenTableRow(props: WorkerTableRowProps) {
  const {
    token: { name, token, id },
    onRename,
    onRevoke,
  } = props;

  const toast = useToast();

  const onCopy = React.useCallback(() => {
    copy(token);
    toast.success("ocrapi.token_copied");
  }, [token, toast]);

  const { hasPermissionToEditTeamSetting } = useTeamPermission();

  return (
    <tr className={styles["token-table-row"]}>
      <td>
        {name}
        {hasPermissionToEditTeamSetting && id && (
          <IconButton
            iconProps={{ iconName: "Edit" }}
            onClick={onRename}
            className={styles["button"]}
          />
        )}
      </td>
      <td>
        ********************************************
        <IconButton
          iconProps={{ iconName: "Copy" }}
          onClick={onCopy}
          className={styles["button"]}
        />
      </td>
      <td>
        {hasPermissionToEditTeamSetting && id && (
          <Link className={styles["revoke-btn"]} onClick={onRevoke}>
            <FormattedMessage id="team.tokens.revoke" />
          </Link>
        )}
      </td>
    </tr>
  );
}

interface TeamMemberTabProps {
  teamMembersData: RemoteData<TeamMembersAndInvitations>;
  setTeamUserPermission: (userId: string, permission: Permission) => void;
  removeTeamUser: (userId: string) => void;
  removeInvitation: (invitationId: string) => void;
  onInviteMember: () => void;
}

export function TeamMemberTab(props: TeamMemberTabProps) {
  const teamMembersData = props.teamMembersData;
  return (
    <div className={styles["tab"]}>
      <TeamDetailMemberList
        {...{
          ...props,
          teamMembersData:
            teamMembersData.state === "success"
              ? teamMembersData.value
              : undefined,
          isFetching: teamMembersData.state === "loading",
          hasError: teamMembersData.state === "error",
          isAdminPage: false,
        }}
      />
    </div>
  );
}

interface WorkerTokenTabProps {
  onCreateToken: () => void;
  onRenameToken: (tokenId: string) => void;
  onRevokeToken: (tokenId: string) => void;
}

export function WorkerTokenTab(props: WorkerTokenTabProps) {
  const { onCreateToken, onRenameToken, onRevokeToken } = props;

  const { hasPermissionToEditTeamSetting } = useTeamPermission();
  const { tokens, isFailed: fetchTokenFailed } = useWorkerToken();

  return (
    <div className={styles["tab"]}>
      {hasPermissionToEditTeamSetting && tokens && (
        <DefaultButton
          className={styles["create-token-btn"]}
          iconProps={{ iconName: "CirclePlus" }}
          textId="team.tokens.create"
          onClick={onCreateToken}
        />
      )}

      {!tokens && !fetchTokenFailed && <ShortSpinner key={0} />}
      {tokens && (
        <Table
          columnIds={["team.tokens.name", "team.tokens.access_token", ""]}
          className={styles["token-table"]}
        >
          {tokens.map(token => (
            <TokenTableRow
              token={token}
              key={token.token}
              onRename={() => token.id && onRenameToken(token.id)}
              onRevoke={() => token.id && onRevokeToken(token.id)}
            />
          ))}
        </Table>
      )}
      {tokens && tokens.length === 0 && (
        <div className={styles["no-token"]}>
          <FormattedMessage
            id="team.tokens.no_token"
            values={{
              text: (
                <span>
                  <FormattedMessage id="team.tokens.create_token" />
                </span>
              ),
            }}
          />
        </div>
      )}
      {fetchTokenFailed && (
        <ErrorPlaceholder messageId="team.tokens.fail_to_fetch" />
      )}
    </div>
  );
}

export type TabType = "members" | "tokens";

interface TeamLayoutProps {
  children: React.ReactNode;
  onRenameTeam: () => void;
  onRemoveTeam: () => void;
  selectedTab: TabType;
  onSelectTab: (tab: TabType) => void;
}

export function TeamLayout(props: TeamLayoutProps) {
  const { children, onRenameTeam, onRemoveTeam, selectedTab, onSelectTab } =
    props;
  const { hasPermissionToEditTeamSetting } = useTeamPermission();
  const { localized } = useLocale();

  const resourceOwner = useSelector<RootState, ResourceOwnerState>(
    state => state.resourceOwner
  );

  const onPivotItemClick = React.useCallback(
    (item?: PivotItem) => {
      if (item?.props.itemKey) {
        onSelectTab(item.props.itemKey as TabType);
      }
    },
    [onSelectTab]
  );

  return (
    <div className={styles["content"]}>
      <div className={styles["top"]}>
        <h3>
          <FormattedMessage id="team.members.header" />
        </h3>
        <div className={styles["team-info-row"]}>
          <TeamInfo
            region={AppConfig.region}
            name={resourceOwner.teamName}
            contactEmail={resourceOwner.teamContactEmail}
            lookupId={resourceOwner.teamLookupId}
          />
          {hasPermissionToEditTeamSetting && (
            <div className={styles["team-control-btns"]}>
              <DefaultButton
                className={styles["rename-btn"]}
                iconProps={{ iconName: "Edit" }}
                textId="team.rename"
                onClick={onRenameTeam}
              />
              <DefaultButton
                className={styles["delete-btn"]}
                iconProps={{ iconName: "Delete" }}
                textId="team.delete"
                onClick={onRemoveTeam}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles["pivot-container"]}>
        <Pivot
          onLinkClick={onPivotItemClick}
          selectedKey={selectedTab}
          className={styles["pivot"]}
        >
          <PivotItem
            headerText={localized("team.team_members")}
            itemKey="members"
            itemIcon="IconPeopleTeam"
          />
          <PivotItem
            headerText={localized("team.tokens.header")}
            itemKey="tokens"
            itemIcon="IconKey"
          />
        </Pivot>
      </div>
      {children}
    </div>
  );
}
