import { Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { DateTime } from "luxon";
import * as React from "react";

import { useLocalizeTokenName } from "../../hooks/usage";
import { RequestLog } from "../../types/usage";
import AutoRefreshUpdatedAt from "../AutoRefreshUpdatedAt";
import Paginator from "../Paginator";
import { Table } from "../Table";
import styles from "./styles.module.scss";

interface Props {
  requestLogs?: RequestLog[];
  startFrom: Date;
  currentPage: number;
  pageSize: number;
  onNavigateToPage: (pageNumber: number) => void;
  totalCount: number;
}

function _RequestLogsView(props: Props) {
  const {
    requestLogs,
    startFrom,
    currentPage,
    pageSize,
    onNavigateToPage,
    totalCount,
  } = props;
  const localizeTokenName = useLocalizeTokenName();

  return (
    <div className={styles["request-logs-history"]}>
      {totalCount > 0 ? (
        <>
          <Table
            columnIds={[
              "request_logs.table.name",
              "request_logs.table.entity_id",
              "request_logs.table.type",
              "request_logs.table.access_token",
              "request_logs.table.timestamp",
            ]}
            className={styles["table"]}
          >
            {(requestLogs || []).map(
              (requestLog: RequestLog, requestLogIndex: number) => (
                <tr key={requestLogIndex}>
                  <td>{requestLog.entityName}</td>
                  <td>{requestLog.entityId}</td>
                  <td>{requestLog.executionType}</td>
                  <td>{localizeTokenName(requestLog.workerToken)}</td>
                  <td>
                    {DateTime.fromJSDate(requestLog.createdAt).toFormat(
                      "yyyy-MM-dd HH:mm:ss"
                    )}
                  </td>
                </tr>
              )
            )}
          </Table>
          <div className={styles["paginator-wrapper"]}>
            <Paginator
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={pageSize}
              navigateToPage={onNavigateToPage}
            />
          </div>
        </>
      ) : (
        <div className={styles["empty-state-container"]}>
          <Label>
            <FormattedMessage id={"request_logs.table.empty"} />
          </Label>
        </div>
      )}
      <div className={styles["updated_time"]}>
        <AutoRefreshUpdatedAt baseDate={startFrom} />
      </div>
    </div>
  );
}

export const RequestLogsView = React.memo(_RequestLogsView);
export default RequestLogsView;
