import React, { useCallback, useMemo, useState } from "react";
import { Navigate } from "react-router";

import TeamDetailResourcesCustomModelDetailLayout from "../components/TeamDetailResourcesCustomModelDetail";
import { CUSTOM_MODEL_IMAGE_PAGE_SIZE } from "../constants/layout";
import {
  useFetchCustomModel,
  useFetchTeamCustomModelImages,
} from "../hooks/admin";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { AdminPathParam } from "../models";
import { openLabeller } from "../utils/labeller";
import {
  getOffsetByPage,
  getOffsetOfBeginningOfPage,
  getPageByOffset,
} from "../utils/pagination";

function _AdminTeamDetailResourcesCustomModelDetailContainer() {
  const { customModelId, region, teamId } = useUnsafeParams<AdminPathParam>();
  const {
    isFetching,
    error,
    customModel,
    paginatedCustomModelImages,
    isGettingCustomModelImage,
  } = useAppSelector(
    state =>
      state.admin.teamDetail.resourceState.customModelsState
        .customModelDetailState
  );

  const [imageGridOffset, setImageGridOffset] = useState(
    getOffsetOfBeginningOfPage(
      paginatedCustomModelImages?.pageInfo.offset ?? 0,
      CUSTOM_MODEL_IMAGE_PAGE_SIZE
    )
  );

  const imageGridCurrentPage = useMemo(() => {
    return getPageByOffset(imageGridOffset, CUSTOM_MODEL_IMAGE_PAGE_SIZE);
  }, [imageGridOffset]);

  const navigateImageGridToPage = useCallback((page: number) => {
    setImageGridOffset(getOffsetByPage(page, CUSTOM_MODEL_IMAGE_PAGE_SIZE));
  }, []);

  const listLink = useMemo(() => {
    return `/admin/team/${region}/${teamId}/resources/custom-models`;
  }, [region, teamId]);

  const onOpenLabeller = useCallback(
    (imageId: string) => {
      openLabeller(customModelId, imageId);
    },
    [customModelId]
  );

  useFetchCustomModel(customModelId, region);
  useFetchTeamCustomModelImages(
    CUSTOM_MODEL_IMAGE_PAGE_SIZE,
    imageGridOffset,
    customModelId,
    region
  );

  if (!isFetching && error) {
    return <Navigate to={listLink} replace />;
  }

  return (
    <TeamDetailResourcesCustomModelDetailLayout
      previousPageLink={listLink}
      previousPageMessageId="team.detail.resources.custom_model.all"
      customModel={customModel}
      isLoading={isFetching}
      imageGridCurrentPage={imageGridCurrentPage}
      navigateImageGridToPage={navigateImageGridToPage}
      paginatedCustomModelImages={paginatedCustomModelImages}
      isGettingCustomModelImage={isGettingCustomModelImage}
      openLabeller={onOpenLabeller}
    />
  );
}

export const AdminTeamDetailResourcesCustomModelDetailContainer = React.memo(
  _AdminTeamDetailResourcesCustomModelDetailContainer
);
export default _AdminTeamDetailResourcesCustomModelDetailContainer;
