import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  Label,
  PrimaryButton,
  Text,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { SUPPORTED_IMAGE_MIME } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { TokenGroupImage } from "../../types/tokenGroup";
import ErrorText from "../WrappedMSComponents/ErrorText";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

interface Props {
  checkIsNameUnique: (id: string) => boolean;
  onCancel(): void;
  onSubmit(id: string, image?: File): void;
  edittingImageToken?: TokenGroupImage;
  isOpen: boolean;
}

const ImageTokenModal = React.memo((props: Props) => {
  const { edittingImageToken, onCancel, onSubmit, checkIsNameUnique, isOpen } =
    props;
  const { localized } = useLocale();

  const isEditting = edittingImageToken !== undefined;

  const [imageTokenName, setImageTokenName] = useState<string | undefined>();
  const [imageTokenUrl, setImageTokenUrl] = useState<string | undefined>();
  const [imageTokenInputErrorMessageId, setImageTokenInputErrorMessageId] =
    useState<string | undefined>();
  const [imageErrorMessage, setImageErrorMessage] = useState<
    string | undefined
  >(undefined);
  const [image, setImage] = useState<File | undefined>();

  useEffect(() => {
    setImageTokenName(edittingImageToken && edittingImageToken.name);
    setImageTokenUrl(edittingImageToken && edittingImageToken.url);
  }, [edittingImageToken]);

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const onChooseImageButtonClick = useCallback(() => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  }, []);

  const onNameChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      id?: string | undefined
    ) => {
      if (id === undefined) {
        return;
      }
      event.stopPropagation();
      event.preventDefault();
      setImageTokenName(id.trim());
      setImageTokenInputErrorMessageId(undefined);
    },
    []
  );

  const onImageChange = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      if (
        !(
          e.target instanceof HTMLInputElement &&
          e.target.files &&
          e.target.files.length > 0
        )
      ) {
        return;
      }
      e.persist();
      const selectedFile = e.target.files[0];
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          const image = new Image();
          image.addEventListener("load", () => {
            setImageTokenUrl(reader.result as string);
            setImageErrorMessage(undefined);
            if (
              e.target instanceof HTMLInputElement &&
              e.target.files &&
              e.target.files.length > 0
            ) {
              setImage(selectedFile);
            }
          });

          image.addEventListener("error", () => {
            setImageErrorMessage("error.cannot_load_image");
          });
          image.src = reader.result as string;
        },
        false
      );
      reader.readAsDataURL(selectedFile);
    },
    []
  );

  const _onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!imageTokenName) {
        setImageTokenInputErrorMessageId("error.image_token_modal.empty_name");
        return;
      }

      if (!checkIsNameUnique(imageTokenName)) {
        setImageTokenInputErrorMessageId(
          "error.image_token_modal.duplicated_name"
        );
        return;
      }

      if (!isEditting && !image) {
        setImageErrorMessage("error.image_token_modal.no_image");
        return;
      }

      onSubmit(imageTokenName, image);
    },
    [checkIsNameUnique, imageTokenName, image, onSubmit, isEditting]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        isEditting ? "edit.image.token.title" : "add.image.token.title"
      ),
    }),
    [localized, isEditting]
  );

  const onDismissed = useCallback(() => {
    setImageTokenInputErrorMessageId(undefined);
    setImage(undefined);
    setImageTokenName(undefined);
    setImageTokenUrl(undefined);
    setImageErrorMessage(undefined);
  }, []);

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
    }),
    [onDismissed]
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <Dialog
      minWidth={500}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <form className={styles["modal"]} onSubmit={_onSubmit}>
        <TextField
          labelId="label.image_token"
          onChange={onNameChange}
          value={imageTokenName}
          errorMessage={
            imageTokenInputErrorMessageId &&
            localized(imageTokenInputErrorMessageId)
          }
          disabled={!hasPermissionToEditResource}
        />

        <div className={styles["upload-image-field"]}>
          <Label>
            <FormattedMessage
              id={isEditting ? "common.replace_image" : "common.upload_image"}
            />
          </Label>

          <div className={styles["file-section"]}>
            <div className={styles["file-name-container"]}>
              <Text>{image && image.name}</Text>
            </div>
            <DefaultButton
              text={localized("common.choose_a_file")}
              onClick={onChooseImageButtonClick}
              disabled={!hasPermissionToEditResource}
            />
          </div>

          {imageErrorMessage && (
            <ErrorText>
              <FormattedMessage id={imageErrorMessage} />
            </ErrorText>
          )}
        </div>
        <input
          ref={uploadInputRef}
          className={styles["invisible-image-upload-input"]}
          type="file"
          accept={SUPPORTED_IMAGE_MIME.join(",")}
          onChange={onImageChange}
        />

        {imageTokenUrl && (
          <div
            style={{ backgroundImage: `url("${imageTokenUrl}")` }}
            className={styles["existing-image"]}
          />
        )}

        <DialogFooter>
          <DefaultButton
            onClick={onCancel}
            text={localized(
              hasPermissionToEditResource ? "common.cancel" : "common.close"
            )}
          />
          {hasPermissionToEditResource && (
            <PrimaryButton
              type="submit"
              text={localized("token.modal.confirm.button")}
            />
          )}
        </DialogFooter>
      </form>
    </Dialog>
  );
});

export default ImageTokenModal;
