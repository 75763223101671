import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { useTemplateActionCreator } from "../actions/template";
import LeftBar, { AdminLeftBar } from "../components/LeftBar";
import { NavDrawer } from "../components/NavDrawer";
import { useLeftBar } from "../hooks/component";
import { RootState } from "../redux/types";
import { AvailableTemplateType } from "../types/template";
import { runWithRetry } from "../utils/retry";

export type LeftBarSelectedPage = "forms" | "receipts" | AvailableTemplateType;

function useSelectedPage() {
  const [selectedPage, setSelectedPage] = useState<
    LeftBarSelectedPage | undefined
  >();
  const { pathname } = useLocation();

  const [isAdminPage, setIsAdminPage] = useState<boolean>(false);

  const onLocationChange = useCallback(pathname => {
    const pathData = pathname.split("/");

    if (pathData.length < 2) {
      setSelectedPage(undefined);
      return;
    }

    if (["admin"].includes(pathData[1])) {
      setIsAdminPage(true);

      if (["team"].includes(pathData[2])) {
        setSelectedPage("team-list");
        return;
      }

      if (["report"].includes(pathData[2])) {
        setSelectedPage("admin-report");
        return;
      }
    }

    setSelectedPage(pathData[1]);
  }, []);

  useEffect(() => {
    onLocationChange(pathname);
  }, [pathname, onLocationChange]);

  return { isAdminPage, selectedPage };
}

export function useLoadTemplate() {
  const templates = useSelector((state: RootState) => state.form.templates);
  const templatesRef = useRef(templates);
  templatesRef.current = templates;

  const { listTemplate } = useTemplateActionCreator();

  useEffect(() => {
    if (templatesRef.current === undefined) {
      runWithRetry(listTemplate).catch(e => {
        console.error("List template error:", e);
      });
    }
  }, [listTemplate]);
}

const LeftBarContainer = React.memo(() => {
  const {
    isCollapsed: isLeftBarCollapsed,
    toggleCollapsed: toggleLeftBarCollapsed,
    isSmallScreen,
  } = useLeftBar();

  const { isAdminPage, selectedPage } = useSelectedPage();

  useLoadTemplate();

  const adminLeftBar = (
    <AdminLeftBar
      isLeftBarCollapsed={isLeftBarCollapsed}
      selectedPage={selectedPage}
      toggleLeftBarCollapsed={toggleLeftBarCollapsed}
    />
  );

  const leftBar = (
    <LeftBar
      isLeftBarCollapsed={isLeftBarCollapsed}
      selectedPage={selectedPage}
      toggleLeftBarCollapsed={toggleLeftBarCollapsed}
    />
  );

  if (isSmallScreen) {
    if (isAdminPage) {
      return (
        <NavDrawer isVisible={!isLeftBarCollapsed}>{adminLeftBar}</NavDrawer>
      );
    }
    return <NavDrawer isVisible={!isLeftBarCollapsed}>{leftBar}</NavDrawer>;
  }

  if (isAdminPage) {
    return adminLeftBar;
  } else {
    return leftBar;
  }
});

export default LeftBarContainer;
