import { Dialog, DialogFooter } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import { FOCRError } from "../../errors";
import { useToast } from "../../hooks/toast";
import { chooseFile } from "../../utils/file";
import { DefaultButton, PrimaryButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface ConfirmModalProps {
  file: File | undefined;
  onCancel(): void;
  onConfirm(): void;
}

export const useImportFormConfirmModalHandle = (
  onConfirmImport: (file: File) => Promise<void>
): {
  onClickImport: () => void;
  isImporting: boolean;
  props: ConfirmModalProps;
} => {
  const toast = useToast();

  const [isImporting, setIsImporting] = React.useState(false);

  const [fileToConfirm, setFileToImport] = React.useState<File>();

  const onClickImport = React.useCallback(async () => {
    const files = await chooseFile("application/zip");
    if (!files || files.length === 0) return;
    setFileToImport(files[0]);
  }, []);

  const onConfirm = React.useCallback(async () => {
    if (isImporting || fileToConfirm == null) {
      return;
    }
    setIsImporting(true);
    try {
      setFileToImport(undefined); // Hide the dialog
      await onConfirmImport(fileToConfirm);
      toast.success("common.import_extractor_success");
    } catch (e) {
      if (e instanceof FOCRError) {
        toast.error(e.messageId, e.detail);
      } else {
        toast.error("error.fail_to_import_extractor");
      }
    }
    setIsImporting(false);
  }, [fileToConfirm, isImporting, toast, onConfirmImport]);

  const onCancel = React.useCallback(() => {
    setFileToImport(undefined);
  }, []);

  return React.useMemo(
    () => ({
      onClickImport,
      isImporting,
      props: {
        file: fileToConfirm,
        onConfirm,
        onCancel,
      },
    }),
    [fileToConfirm, isImporting, onCancel, onClickImport, onConfirm]
  );
};

const ImportFormConfirmModal: React.FC<ConfirmModalProps> = props => {
  const { localized } = useLocale();
  const { onCancel, onConfirm, file } = props;

  return (
    <Dialog
      title={localized("extractor_setting.confirm_replace_dialog.title")}
      className={styles["confirm_replace_dialog"]}
      hidden={!file}
      onDismiss={onCancel}
    >
      <div className={styles["title"]}>
        <FormattedMessage id="extractor_setting.file" />
      </div>
      <p className={styles["filename"]}>{file?.name}</p>
      <p className={styles["message"]}>
        <FormattedMessage id="extractor_setting.confirm_replace_current_form" />
      </p>
      <DialogFooter>
        <DefaultButton onClick={onCancel} textId={"common.cancel"} />
        <PrimaryButton
          onClick={onConfirm}
          textId={"extractor_setting.import"}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default ImportFormConfirmModal;
