import { IBreadcrumbItem } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router";

import { FirstTimeGuidance } from "../components/FirstTimeGuidance";
import { NavBarLayout } from "../components/NavBarLayout";
import { NavTab } from "../components/NavTabBar";
import { UserFeatureFlag } from "../constants";
import { ButtonId } from "../constants/buttonids";
import { isPrebuiltExtractors } from "../constants/prebuiltExtractor";
import { useLocale } from "../contexts/locale";
import {
  useExtractorOptionsForCombinedExtractor,
  useFormGroup,
} from "../hooks/form_group";
import { useGtm } from "../hooks/gtm";
import { useUnsafeParams } from "../hooks/params";
import { useTeamPermission } from "../hooks/permission";
import { useAppSelector } from "../hooks/redux";
import { PathParam } from "../models";

export enum FormGroupNavTabKey {
  ManageRules = "edit",
  Formatter = "format",
  TestExtractor = "test",
  TestExtractorV1 = "test-v1",
  API = "extract",
  Settings = "setting",
}

export const FormGroupNavTab: Record<FormGroupNavTabKey, NavTab> = {
  [FormGroupNavTabKey.ManageRules]: {
    id: ButtonId.ManageRulesTab,
    key: FormGroupNavTabKey.ManageRules,
    labelId: "extractor.tab.manage_rules",
  },
  [FormGroupNavTabKey.Formatter]: {
    key: FormGroupNavTabKey.Formatter,
    labelId: "extractor.tab.formatter",
  },
  [FormGroupNavTabKey.TestExtractor]: {
    key: FormGroupNavTabKey.TestExtractor,
    labelId: "extractor.tab.test",
  },
  [FormGroupNavTabKey.TestExtractorV1]: {
    key: FormGroupNavTabKey.TestExtractorV1,
    labelId: "extractor.tab.test_v1",
  },
  [FormGroupNavTabKey.API]: {
    key: FormGroupNavTabKey.API,
    labelId: "extractor.tab.extract",
  },
  [FormGroupNavTabKey.Settings]: {
    key: FormGroupNavTabKey.Settings,
    labelId: "extractor.tab.setting",
  },
};

function useFormGroupNavBarLayout(selectedTab: string) {
  const { formGroupId } = useUnsafeParams<PathParam>();
  const { formGroup, isFailedToFetchFormGroup } = useFormGroup(formGroupId);
  const { extractorOptions, isFailedToFetchExtractorOptions } =
    useExtractorOptionsForCombinedExtractor();
  const { hasPermissionToEditResource } = useTeamPermission();
  const { localized } = useLocale();
  const navigate = useNavigate();

  const backToHome = React.useCallback(() => {
    navigate("/extractor");
  }, [navigate]);

  const breadcrumbItems = React.useMemo<IBreadcrumbItem[]>(
    () => [
      {
        text: localized("extractor.breadcrumb.extractors"),
        key: "form-group",
        href: "/form-group",
        onClick: backToHome,
      },
      {
        key: formGroup?.id || "",
        text: formGroup?.name || "",
      },
    ],
    [backToHome, formGroup?.id, formGroup?.name, localized]
  );

  const { isV1TestTabEnabled, isFormatterEnabled } = useAppSelector(state => ({
    isV1TestTabEnabled: state.resourceOwner.isFeatureEnabled.apply(
      state.resourceOwner
    )(UserFeatureFlag.V1TestTab),
    isFormatterEnabled: state.resourceOwner.isFeatureEnabled.apply(
      state.resourceOwner
    )(UserFeatureFlag.Formatter),
  }));

  const tabs = React.useMemo(() => {
    const isPrebuiltExtractorFlag =
      formGroup !== undefined &&
      formGroup.config.prebuilt_extractor &&
      isPrebuiltExtractors(formGroup.config.prebuilt_extractor);
    const shouldHideEdit =
      !hasPermissionToEditResource || isPrebuiltExtractorFlag;

    const shouldHideFormatter = !isFormatterEnabled;

    const shouldHideV1Tab = !isV1TestTabEnabled;

    const tabKeys = [
      !shouldHideEdit && FormGroupNavTabKey.ManageRules,
      !shouldHideFormatter && FormGroupNavTabKey.Formatter,
      FormGroupNavTabKey.TestExtractor,
      !shouldHideV1Tab && FormGroupNavTabKey.TestExtractorV1,
      FormGroupNavTabKey.API,
      FormGroupNavTabKey.Settings,
    ];

    return tabKeys
      .filter(Boolean)
      .map(key => FormGroupNavTab[key as FormGroupNavTabKey]);
  }, [
    formGroup,
    hasPermissionToEditResource,
    isFormatterEnabled,
    isV1TestTabEnabled,
  ]);

  const { pushClickedManagedRulesEvent } = useGtm();

  const onTabSelect = React.useCallback(
    (key: string) => {
      const mapTable = {
        [FormGroupNavTabKey.ManageRules]: "/",
        [FormGroupNavTabKey.Formatter]: "/format",
        [FormGroupNavTabKey.TestExtractor]: "/test",
        [FormGroupNavTabKey.TestExtractorV1]: "/test-v1",
        [FormGroupNavTabKey.API]: "/extract",
        [FormGroupNavTabKey.Settings]: "/setting",
      } as { [key in FormGroupNavTabKey]: string };

      if (key === FormGroupNavTabKey.ManageRules) {
        pushClickedManagedRulesEvent(
          FirstTimeGuidance.isManageRulesStepVisible()
        );
      }
      const path = mapTable[key as FormGroupNavTabKey];
      navigate(`/form-group/${formGroupId}${path}`);
    },
    [navigate, formGroupId, pushClickedManagedRulesEvent]
  );

  const result = React.useMemo(() => {
    const defaultOptions = {
      resource: undefined,
      onTabSelect: () => {},
      tabs: [],
      breadcrumbItems: [],
      selectedTab: "",
      hasPermissionToEditResource: false,
    };
    if (
      isFailedToFetchFormGroup ||
      isFailedToFetchExtractorOptions ||
      formGroup === undefined ||
      formGroup.id !== formGroupId ||
      extractorOptions === undefined
    ) {
      return defaultOptions;
    }
    return {
      resource: formGroup,
      onTabSelect,
      tabs,
      breadcrumbItems,
      selectedTab,
      hasPermissionToEditResource,
    };
  }, [
    breadcrumbItems,
    extractorOptions,
    formGroup,
    formGroupId,
    hasPermissionToEditResource,
    isFailedToFetchExtractorOptions,
    isFailedToFetchFormGroup,
    onTabSelect,
    selectedTab,
    tabs,
  ]);

  return result;
}

type FormGroupNavBarLayoutProps = {
  children: React.ReactNode;
  selectedTab?: string;
  tabBarVisible?: boolean;
};

export function FormGroupNavBarLayoutImpl(
  props: FormGroupNavBarLayoutProps &
    ReturnType<typeof useFormGroupNavBarLayout>
) {
  const {
    children,
    tabs,
    onTabSelect,
    breadcrumbItems,
    selectedTab,
    tabBarVisible,
  } = props;

  return (
    <>
      <NavBarLayout
        tabs={tabs}
        onTabSelect={onTabSelect}
        breadcrumbItems={breadcrumbItems}
        selectedTab={selectedTab}
        tabBarVisible={tabBarVisible}
      >
        {children}
      </NavBarLayout>
    </>
  );
}

export function FormGroupNavBarLayout(props: FormGroupNavBarLayoutProps) {
  const state = useFormGroupNavBarLayout(
    props.selectedTab ?? FormGroupNavTabKey.ManageRules
  );

  return <FormGroupNavBarLayoutImpl {...props} {...state} />;
}
