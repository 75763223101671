import { useEffect } from "react";

import { useAppActionCreator } from "../actions/app";

export function useAutoHideLeftBar() {
  const { toggleLeftBarCollapsed } = useAppActionCreator();
  useEffect(() => {
    toggleLeftBarCollapsed(true);
    return () => {
      toggleLeftBarCollapsed(false);
    };
  }, [toggleLeftBarCollapsed]);
}

export function useAutoShowLeftBar() {
  const { toggleLeftBarCollapsed } = useAppActionCreator();
  useEffect(() => {
    toggleLeftBarCollapsed(false);
  }, [toggleLeftBarCollapsed]);
}
