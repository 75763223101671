.page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px 0px;
  flex-wrap: wrap;
}

.page-header-divider {
  border-bottom: 1px solid #edebe9;
}

.page-header-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.page-header-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #323130;
}

.page-header-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #a19f9d;
  padding-top: 4px;
}
