import {
  ChoiceGroup,
  DefaultButton,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  IDialogContentProps,
  IModalProps,
  IconButton,
  PrimaryButton,
  Text,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { produce } from "immer";
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as uuid from "uuid";

import { SUPPORTED_IMAGE_MIME } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { MatchMode, TokenGroup } from "../../types/tokenGroup";
import { uploadAsset } from "../../utils/file";
import { Img } from "../Img";
import { InspectorNote } from "../InspectorNote";
import ScrollableModal from "../ScrollableModal";
import ErrorText from "../WrappedMSComponents/ErrorText";
import styles from "./styles.module.scss";

interface Props {
  onCancel: () => void;
  onSubmit: (tokenGroup: TokenGroup) => void;
  inputTokenGroup: TokenGroup | undefined;
}

interface MatchModeOptions extends IChoiceGroupOption {
  key: MatchMode;
}

const ImageHolder = ({
  assetId,
  url,
  onImageSet,
  setProcessing,
  setError,
}: {
  assetId: string;
  url: string;
  onImageSet: (assetId: string, url: string) => void;
  setProcessing: (on: boolean) => void;
  setError: (errorMessage: string) => void;
}) => {
  const { localized } = useLocale();
  const uploadInputRef = useRef<HTMLInputElement | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const onImageChange = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      if (
        !(
          e.target instanceof HTMLInputElement &&
          e.target.files &&
          e.target.files.length > 0
        )
      ) {
        return;
      }
      e.persist();
      const selectedFile = e.target.files[0];
      const reader = new FileReader();
      setIsUploading(true);
      setProcessing(true);
      reader.addEventListener(
        "load",
        () => {
          const image = new Image();
          image.addEventListener("load", async () => {
            if (
              e.target instanceof HTMLInputElement &&
              e.target.files &&
              e.target.files.length > 0
            ) {
              let asset;
              try {
                asset = await uploadAsset(selectedFile);
              } catch (e) {
                console.error("Failed to upload asset: ", e);
              }
              if (asset) {
                onImageSet(asset.name, asset.url as string);
              }
            }
            setIsUploading(false);
            setProcessing(false);
            setError("");
          });

          image.addEventListener("error", () => {
            setIsUploading(false);
            setProcessing(false);
            setError("error.cannot_load_image");
          });

          image.src = reader.result as string;
        },
        false
      );
      reader.readAsDataURL(selectedFile);
    },
    [onImageSet, setIsUploading, setProcessing, setError]
  );

  const onChooseImageButtonClick = useCallback(() => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  }, [uploadInputRef]);

  return (
    <React.Fragment>
      <input
        ref={uploadInputRef}
        className={styles["invisible-image-upload-input"]}
        type="file"
        accept={SUPPORTED_IMAGE_MIME.join(",")}
        onChange={onImageChange}
      />

      {assetId !== "" && <Img src={url} className={styles["image"]} />}
      {assetId === "" && (
        <DefaultButton
          text={localized(
            isUploading ? "common.uploading" : "common.choose_a_file"
          )}
          onClick={onChooseImageButtonClick}
          disabled={isUploading}
        />
      )}
    </React.Fragment>
  );
};

interface Error {
  errorPosition: number;
  errorMessage: string;
}

const useImageTokenModalState = (props: Props) => {
  const { localized } = useLocale();

  const { inputTokenGroup, onSubmit } = props;

  const [editingTokenGroup, setEditingTokenGroup] = useState<
    TokenGroup | undefined
  >(inputTokenGroup);

  const [loadingSpinnerVisible, setLoadingSpinnerVisible] =
    useState<boolean>(false);

  const [errors, setErrors] = useState<Map<number, Error>>(
    new Map<number, Error>()
  );

  const setError = useCallback(
    (index: number) =>
      (errorMessage: string, errorPosition: number = 0) => {
        const draft = new Map<number, Error>(errors);
        if (errorMessage === "") {
          draft.delete(index);
        } else {
          const error = {
            errorPosition,
            errorMessage,
          };
          draft.set(index, error);
        }
        setErrors(draft);
      },
    [errors, setErrors]
  );

  const { matchMode, tokens, savaButtonEnabled, isOpen } = useMemo(() => {
    const { images, matchMode } = (editingTokenGroup as TokenGroup) || {
      images: [],
      matchMode: "all",
    };
    const isOpen = editingTokenGroup !== undefined;

    const savaButtonEnabled = true;

    const tokens = images.map((image, index) => {
      const error = errors.get(index);

      return { ...image, error };
    });

    return { matchMode, tokens, savaButtonEnabled, isOpen };
  }, [editingTokenGroup, errors]);

  const matchModeOptions = useMemo(
    (): MatchModeOptions[] => [
      { key: "all", text: localized("label.match_all") },
      { key: "best", text: localized("label.match_one_only") },
    ],
    [localized]
  );

  const onMatchModeChange = useCallback(
    (
      event?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
      option?: IChoiceGroupOption | undefined
    ) => {
      if (event === undefined || option === undefined) {
        return;
      }
      event.stopPropagation();
      setEditingTokenGroup(
        produce(editingTokenGroup as TokenGroup, (draft: TokenGroup) => {
          draft.matchMode = (option as MatchModeOptions).key;
        })
      );
    },
    [editingTokenGroup, setEditingTokenGroup]
  );

  useEffect(() => {
    if (inputTokenGroup) {
      setEditingTokenGroup({ ...inputTokenGroup });
    } else {
      setEditingTokenGroup(undefined);
    }
  }, [inputTokenGroup]);

  const _onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const draftErrors = new Map<number, Error>();

      tokens.forEach((token, index) => {
        if (token.assetId === "") {
          const error = {
            errorMessage: "error.image_token_modal.no_image",
            errorPosition: 0,
          };
          draftErrors.set(index, error);
        } else if (token.name.trim().length === 0) {
          const error = {
            errorMessage: "error.image_token_modal.empty_name",
            errorPosition: 1,
          };
          draftErrors.set(index, error);
        }
      });

      if (draftErrors.size > 0) {
        setErrors(draftErrors);
      } else {
        onSubmit(editingTokenGroup as TokenGroup);
      }
    },
    [editingTokenGroup, onSubmit, tokens]
  );

  const onDismissed = useCallback(() => {
    setEditingTokenGroup(undefined);
  }, [setEditingTokenGroup]);

  const modalProps: IModalProps = useMemo(
    () => ({
      isBlocking: true,
      onDismissed,
    }),
    [onDismissed]
  );

  const formProps = useMemo(
    () => ({
      onSubmit: _onSubmit,
    }),
    [_onSubmit]
  );

  const onImageTokenNameChange = useCallback(
    (index: number) =>
      (
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue === undefined) {
          return;
        }
        setError(index)("");
        setEditingTokenGroup(
          produce(editingTokenGroup as TokenGroup, (draft: TokenGroup) => {
            draft.images[index].name = newValue;
          })
        );
      },
    [editingTokenGroup, setEditingTokenGroup, setError]
  );

  const onRemoveClick = useCallback(
    (index: number) => () => {
      setEditingTokenGroup(
        produce(editingTokenGroup as TokenGroup, (draft: TokenGroup) => {
          draft.images.splice(index, 1);
        })
      );
    },
    [setEditingTokenGroup, editingTokenGroup]
  );

  const onAddNewToken = useCallback(() => {
    setEditingTokenGroup(
      produce(editingTokenGroup as TokenGroup, (draft: TokenGroup) => {
        draft.images.push({
          id: uuid.v4(),
          name: "",
          assetId: "",
          url: "",
        });
      })
    );
  }, [editingTokenGroup, setEditingTokenGroup]);

  const onImageSet = useCallback(
    (index: number) => (assetId: string, url: string) => {
      setEditingTokenGroup(
        produce(editingTokenGroup as TokenGroup, (draft: TokenGroup) => {
          draft.images[index].url = url;
          draft.images[index].assetId = assetId;
        })
      );
    },
    [editingTokenGroup]
  );

  return {
    editingTokenGroup,
    setEditingTokenGroup,
    tokens,
    modalProps,
    formProps,
    onDismissed,
    matchMode,
    matchModeOptions,
    onMatchModeChange,
    onRemoveClick,
    onAddNewToken,
    onImageTokenNameChange,
    savaButtonEnabled,
    onImageSet,
    isOpen,
    loadingSpinnerVisible,
    setLoadingSpinnerVisible,
    setError,
  };
};

const ImageTokenModalV2 = React.memo((props: Props) => {
  const { onCancel } = props;

  const { localized } = useLocale();

  const {
    tokens,
    modalProps,
    formProps,
    matchMode,
    matchModeOptions,
    onMatchModeChange,
    onRemoveClick,
    onAddNewToken,
    onImageTokenNameChange,
    savaButtonEnabled,
    isOpen,
    onImageSet,
    loadingSpinnerVisible: processing,
    setLoadingSpinnerVisible: setProcessing,
    setError,
  } = useImageTokenModalState(props);

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("image_token.model.title"),
    }),
    [localized]
  );

  return (
    <ScrollableModal
      minWidth={710}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      formProps={formProps}
      dialogContentProps={dialogContentProps}
      className={styles["image-token-modal"]}
      footerItem={
        <DialogFooter>
          <DefaultButton onClick={onCancel} text={localized("common.cancel")} />

          <PrimaryButton
            type="submit"
            text={localized("common.ok")}
            disabled={!savaButtonEnabled || processing}
          />
        </DialogFooter>
      }
    >
      <React.Fragment>
        <InspectorNote notes={["image_token.modal.description"]} />

        <ChoiceGroup
          className={styles["match-mode-choice-group"]}
          selectedKey={matchMode}
          options={matchModeOptions}
          label={localized("label.match_mode")}
          onChange={onMatchModeChange}
        />

        <div className={styles["separator"]} />

        <div className={styles["add-tokens-session"]}>
          <Text variant="large" className={styles["add-tokens-title"]}>
            {localized("text_token.modal.add_tokens")}
          </Text>

          <PrimaryButton
            text={localized("text_token.modal.add_token_button")}
            onClick={onAddNewToken}
          />
        </div>

        <div className={styles["separator"]} />

        <table>
          <thead>
            <tr>
              <td>
                <Text>
                  <FormattedMessage id="image_token.modal.header_1" />
                </Text>
              </td>
              <td className={styles["name-value-cell"]}>
                <Text>
                  <FormattedMessage id="image_token.modal.header_2" />
                </Text>
              </td>
              <td>
                <Text>
                  <FormattedMessage id="image_token.modal.header_3" />
                </Text>
              </td>
            </tr>
          </thead>
          <tbody>
            {tokens.map((token, i) => (
              <React.Fragment key={i}>
                <tr>
                  <td>
                    <ImageHolder
                      url={token.url}
                      onImageSet={onImageSet(i)}
                      assetId={token.assetId}
                      setProcessing={setProcessing}
                      setError={setError(i)}
                    />
                  </td>
                  <td>
                    <TextField
                      value={token.name}
                      className={styles["name-value-field"]}
                      onChange={onImageTokenNameChange(i)}
                    />
                  </td>
                  <td>
                    <IconButton
                      styles={{
                        icon: { color: "#201F1E" },
                      }}
                      iconProps={{ iconName: "Delete" }}
                      onClick={onRemoveClick(i)}
                    />
                  </td>
                </tr>
                {token.error !== undefined && (
                  <tr>
                    {token.error.errorPosition === 0 && (
                      <td colSpan={2} className={styles["error-td"]}>
                        <ErrorText>
                          <FormattedMessage id={token.error.errorMessage} />
                        </ErrorText>
                      </td>
                    )}
                    {token.error.errorPosition === 1 && (
                      <React.Fragment>
                        <td />
                        <td colSpan={2} className={styles["error-td"]}>
                          <ErrorText>
                            <FormattedMessage id={token.error.errorMessage} />
                          </ErrorText>
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    </ScrollableModal>
  );
});
export default ImageTokenModalV2;
