.data-item-table {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
  background: #faf9f8;
  border: 1px solid #edebe9;
  border-radius: 4px;
}

.data-item-table-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #323130;

  opacity: 0.5;
  min-width: 190px;
}

.data-item-table-topbar {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.data-item-table-scrollable {
  overflow-x: auto;
  width: 100%;
}

.data-item-table-content {
  flex: 1 1 0;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: 1px solid #edebe9;
}

.data-item-table-header-cell {
  background-color: #f3f2f1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 10px 8px;
  color: #3231307f;
  position: sticky;
  top: 0;
  z-index: 1;
  height: 36px;
}

.data-item-table-header-cell:not(:last-child) {
  border-right: 1px solid #edebe9;
}
.data-item-table-cell {
  color: #323130;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background: #fff;
  padding: 10px 8px;
  min-width: 150px;
  min-height: 36px;
  border-top: 1px solid #edebe9;
  word-break: break-all;
  white-space: pre-wrap;
}

.data-item-table-cell:not(:last-child) {
  border-right: 1px solid #edebe9;
}

.data-item-table-edit-cell {
  color: #323130;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 10px 8px;
  min-width: 150px;
  min-height: 36px;
}

.data-item-table-trash-cell {
  color: #323130;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 10px 8px;
  max-width: 44px;
  min-height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
