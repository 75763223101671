@import "../../styles/variables.scss";

.pattern-range {
  display: flex;
  flex-direction: row;
  flex: 1;

  .spin-separator {
    width: 48px;
  }

  .spin-button {
    width: 100px;
  }
}

.reminder {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  > span {
    font-size: 10px;
  }
}
