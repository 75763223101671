import React, { ReactElement, useCallback, useEffect } from "react";

import { useLocale } from "../../contexts/locale";
import { TaskRunner } from "../../services/taskRunner";

const WorkspaceTaskRunningPrompt = (): ReactElement => {
  const { localized } = useLocale();
  const onBeforeUnload = useCallback(
    (ev: BeforeUnloadEvent): void | string => {
      const promptMessages: string[] = [];
      const uploadCount = TaskRunner.getWorkspaceUploadRunner().taskCount;
      if (uploadCount > 0) {
        promptMessages.push(
          localized("workspace.uploading_exit_warning", {
            count: uploadCount,
          })
        );
      }
      const exportCount = TaskRunner.getWorkspaceExportRunner().taskCount;
      if (exportCount > 0) {
        promptMessages.push(
          localized("workspace.exporting_exit_warning", {
            count: uploadCount,
          })
        );
      }
      if (promptMessages.length <= 0) {
        return;
      }
      ev.preventDefault();
      const promptMessage = promptMessages.join("\n");
      // Most modern browsers ignore this message
      ev.returnValue = promptMessage;
      return promptMessage;
    },
    [localized]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload, { capture: true });
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload, {
        capture: true,
      });
    };
  }, [onBeforeUnload]);

  return <></>;
};

export default WorkspaceTaskRunningPrompt;
