import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import * as customModelAction from "../actions/customModel";
import * as resourceOwnerAction from "../actions/resourceOwner";
import * as teamAction from "../actions/team";
import * as userAction from "../actions/user";
import * as workspaceAction from "../actions/workspace";
import { DefaultCustomModel } from "../constants";
import { CUSTOM_MODEL_IMAGE_PAGE_SIZE } from "../constants/layout";
import { FOCRError } from "../errors";
import { BriefWorkspace } from "../types/briefWorkspace";
import { CustomModel } from "../types/customModel";
import {
  CustomModelImage,
  PaginatedCustomModelImage,
} from "../types/customModelImage";
import { BriefWorkspaceMapper } from "../types/mappers/workspace";
import { freezeLabelSchema } from "../utils/labelSchema";

type CustomModelID = string;
type CustomModelImageGroupID = string;

export type UploadingImagesState = {
  totalPagesToUpload?: number;
  numberOfUploadedPage?: number;
  numberOfPageFailedToUpload?: number;
};

export interface CustomModelState {
  readonly currentCustomModel?: CustomModel;
  readonly isGettingCustomModel: boolean;
  readonly customModelError?: FOCRError;

  readonly isGettingCustomModelImage: boolean;
  readonly paginatedCustomModelImages?: PaginatedCustomModelImage;
  readonly paginatedFSLCustomModelImages?: PaginatedCustomModelImage;
  readonly paginatedFSLGroupedCustomModelImages?: {
    [key: CustomModelImageGroupID]: PaginatedCustomModelImage;
  };
  readonly customModelImageError?: FOCRError;
  readonly fslUploadingSampleImagesState: {
    [key: CustomModelID]: UploadingImagesState;
  };

  readonly customModelImageUploadStatistics: {
    [key: CustomModelID]: {
      totalCount: number;
      successCount: number;
      failedCount: number;
    };
  };
  readonly deletingCustomModelImageIds: { [key: CustomModelID]: string[] };
  readonly customModelImagelastUploadError?: FOCRError;
  readonly customModelImagelastDeleteError?: FOCRError;
}

const defaultState: CustomModelState = {
  isGettingCustomModel: false,
  isGettingCustomModelImage: false,
  customModelImageUploadStatistics: {},
  deletingCustomModelImageIds: {},
  fslUploadingSampleImagesState: {},
};

function removeFromPaginatedCustomModelImages(
  paginatedCustomModelImages: any,
  customModelImageId: string
) {
  if (paginatedCustomModelImages === undefined) {
    return;
  }

  const length = paginatedCustomModelImages.images.length;

  paginatedCustomModelImages.images = paginatedCustomModelImages.images.filter(
    (x: any) => {
      return x.id !== customModelImageId;
    }
  );

  if (paginatedCustomModelImages.images.length !== length) {
    paginatedCustomModelImages.pageInfo.totalCount -= 1;
  }
}

function addToPaginatedCustomModelImages(
  paginatedCustomModelImages: any,
  customModelImage: CustomModelImage
) {
  paginatedCustomModelImages.pageInfo.totalCount += 1;

  if (
    paginatedCustomModelImages.pageInfo.offset <= CUSTOM_MODEL_IMAGE_PAGE_SIZE
  ) {
    paginatedCustomModelImages.images = [
      customModelImage,
      ...paginatedCustomModelImages.images,
    ].slice(0, CUSTOM_MODEL_IMAGE_PAGE_SIZE);
  }
}

export const customModelReducer = createReducer<CustomModelState>(
  defaultState,
  builder => {
    builder
      .addCase(customModelAction.ResetDefaultCustomModel, (state, action) => {
        const customModel = { ...DefaultCustomModel };
        customModel.name = action.payload.name;
        state.currentCustomModel = customModel;
      })
      .addCase(customModelAction.CreateCustomModel, (state, action) => {
        state.currentCustomModel = action.payload;
        state.paginatedCustomModelImages = {
          pageInfo: { offset: 0, totalCount: 0 },
          images: [],
        };
        state.paginatedFSLCustomModelImages = {
          pageInfo: { offset: 0, totalCount: 0 },
          images: [],
        };
      })
      .addCase(customModelAction.GettingCustomModel, state => {
        state.isGettingCustomModel = true;
        state.customModelError = undefined;
      })
      .addCase(customModelAction.GetCustomModelFailed, (state, action) => {
        state.isGettingCustomModel = false;
        state.customModelError = action.payload;
      })
      .addCase(customModelAction.GetCustomModel, (state, action) => {
        if (state.currentCustomModel?.id !== action.payload.id) {
          state.paginatedCustomModelImages = undefined;
          state.paginatedFSLCustomModelImages = undefined;
          state.paginatedFSLGroupedCustomModelImages = undefined;
        }
        state.currentCustomModel = action.payload;
        state.isGettingCustomModel = false;
      })
      .addCase(customModelAction.UpdatedCustomModel, (state, action) => {
        if (state.currentCustomModel) {
          state.currentCustomModel.updatedAt = action.payload.updatedAt;
        }
      })
      .addCase(customModelAction.GettingCustomModelImages, state => {
        state.isGettingCustomModelImage = true;
        state.customModelImageError = undefined;
      })
      .addCase(customModelAction.GotCustomModelImages, (state, action) => {
        state.isGettingCustomModelImage = false;
        state.paginatedCustomModelImages = action.payload;
      })
      .addCase(customModelAction.GotFSLCustomModelImages, (state, action) => {
        state.paginatedFSLCustomModelImages = action.payload;

        state.paginatedFSLCustomModelImages.images =
          state.paginatedFSLCustomModelImages.images.sort((a, b) => {
            const aIndex = a.createdAt.getTime();
            const bIndex = b.createdAt.getTime();
            return aIndex - bIndex;
          });
      })
      .addCase(
        customModelAction.GotFSLGroupedCustomModelImages,
        (state, action) => {
          const { paginatedCustomModelImage, groupId } = action.payload;

          if (state.paginatedFSLGroupedCustomModelImages === undefined) {
            state.paginatedFSLGroupedCustomModelImages = {
              [groupId]: paginatedCustomModelImage,
            };
          } else if (groupId in state.paginatedFSLGroupedCustomModelImages) {
            state.paginatedFSLGroupedCustomModelImages[groupId].images =
              state.paginatedFSLGroupedCustomModelImages[groupId].images
                .concat(paginatedCustomModelImage.images)
                .sort(
                  (a, b) =>
                    (a.info?.group_item_index ?? 0) -
                    (b.info?.group_item_index ?? 0)
                );

            const seen = new Set();
            state.paginatedFSLGroupedCustomModelImages[groupId].images =
              state.paginatedFSLGroupedCustomModelImages[groupId].images.filter(
                (item: any) => {
                  const k = item.id;
                  return seen.has(k) ? false : seen.add(k);
                }
              );

            state.paginatedFSLGroupedCustomModelImages[
              groupId
            ].pageInfo.offset = Math.max(
              state.paginatedFSLGroupedCustomModelImages[groupId].pageInfo
                .offset,
              paginatedCustomModelImage.pageInfo.offset
            );
          } else {
            state.paginatedFSLGroupedCustomModelImages[groupId] =
              paginatedCustomModelImage;
          }
        }
      )
      .addCase(
        customModelAction.GotFSLCustomModelImagesFailed,
        (state, action) => {
          state.customModelImageError = action.payload;
        }
      )
      .addCase(
        customModelAction.GotCustomModelImagesFailed,
        (state, action) => {
          state.isGettingCustomModelImage = false;
          state.customModelImageError = action.payload;
        }
      )
      .addCase(customModelAction.UploadingCustomModelImage, (state, action) => {
        const { customModelId, count } = action.payload;
        if (customModelId in state.customModelImageUploadStatistics) {
          state.customModelImageUploadStatistics[customModelId].totalCount +=
            count;
        } else {
          state.customModelImageUploadStatistics[customModelId] = {
            totalCount: count,
            failedCount: 0,
            successCount: 0,
          };
        }
      })
      .addCase(customModelAction.UploadedCustomModelImage, (state, action) => {
        const { customModelId, customModelImage } = action.payload;
        if (customModelId in state.customModelImageUploadStatistics) {
          state.customModelImageUploadStatistics[
            customModelId
          ].successCount += 1;
        }
        if (state.currentCustomModel?.id === customModelId) {
          const isFSLImage =
            customModelImage.info?.fsl_output !== undefined ||
            customModelImage.info?.is_fsl_image === true;

          const groupId = customModelImage.info?.group_id;
          const groupItemIndex = customModelImage.info?.group_item_index;

          if (isFSLImage) {
            if (groupItemIndex === undefined || groupItemIndex === 0) {
              addToPaginatedCustomModelImages(
                state.paginatedFSLCustomModelImages,
                customModelImage
              );
            }

            if (groupId) {
              if (state.paginatedFSLGroupedCustomModelImages === undefined)
                state.paginatedFSLGroupedCustomModelImages = {};

              if (!(groupId in state.paginatedFSLGroupedCustomModelImages)) {
                state.paginatedFSLGroupedCustomModelImages[groupId] = {
                  pageInfo: { offset: 0, totalCount: 0 },
                  images: [],
                };
              }
              state.paginatedFSLGroupedCustomModelImages[
                groupId
              ].pageInfo.totalCount += 1;
              state.paginatedFSLGroupedCustomModelImages[groupId].images.push(
                customModelImage
              );
            }
          } else {
            addToPaginatedCustomModelImages(
              state.paginatedCustomModelImages,
              customModelImage
            );
          }
          state.currentCustomModel.noOfSampleImages += 1;
        }
      })
      .addCase(
        customModelAction.UploadCustomModelImageFailed,
        (state, action) => {
          const { customModelId, error } = action.payload;
          if (customModelId in state.customModelImageUploadStatistics) {
            state.customModelImageUploadStatistics[
              customModelId
            ].failedCount += 1;
          }
          state.customModelImagelastUploadError = error;
        }
      )
      .addCase(customModelAction.DeletingCustomModelImage, (state, action) => {
        const { customModelId, customModelImageIds } = action.payload;
        if (customModelId in state.deletingCustomModelImageIds) {
          state.deletingCustomModelImageIds[customModelId].push(
            ...customModelImageIds
          );
        } else {
          state.deletingCustomModelImageIds[customModelId] = [
            ...customModelImageIds,
          ];
        }
      })
      .addCase(customModelAction.DeletedCustomModelImage, (state, action) => {
        const { customModelId, customModelImageId } = action.payload;
        if (customModelId in state.deletingCustomModelImageIds) {
          state.deletingCustomModelImageIds[customModelId] =
            state.deletingCustomModelImageIds[customModelId].filter(
              x => x !== customModelImageId
            );
        }
        if (state.currentCustomModel?.id === customModelId) {
          removeFromPaginatedCustomModelImages(
            state.paginatedCustomModelImages,
            customModelImageId
          );
          removeFromPaginatedCustomModelImages(
            state.paginatedFSLCustomModelImages,
            customModelImageId
          );
          state.currentCustomModel.noOfSampleImages -= 1;
        }
      })
      .addCase(
        customModelAction.DeleteCustomModelImageFailed,
        (state, action) => {
          const { customModelId, customModelImageId, error } = action.payload;
          if (customModelId in state.deletingCustomModelImageIds) {
            state.deletingCustomModelImageIds[customModelId] =
              state.deletingCustomModelImageIds[customModelId].filter(
                x => x === customModelImageId
              );
          }
          state.customModelImagelastDeleteError = error;
        }
      )
      .addCase(
        customModelAction.PatchedCustomModelImageInfo,
        (state, action) => {
          const { customModelImageId, info } = action.payload;
          const callback = (image: any) => {
            if (image.id === customModelImageId) {
              image.info = Object.assign(image.info ?? {}, info);
            }
          };
          state.paginatedCustomModelImages?.images.forEach(callback);
          state.paginatedFSLCustomModelImages?.images.forEach(callback);
        }
      )
      .addCase(
        customModelAction.UpdateCustomModelLabelSchema,
        (state, action) => {
          if (state.currentCustomModel) {
            state.currentCustomModel.config.unfrozenLabelSchema =
              action.payload;
            state.currentCustomModel.config.trainingRequested = false;
          }
        }
      )
      .addCase(
        customModelAction.UpdateCustomModelExtractedContentSchema,
        (state, action) => {
          if (state.currentCustomModel) {
            state.currentCustomModel.config.extractedContentSchema =
              action.payload;
          }
        }
      )
      .addCase(
        customModelAction.UpdateCustomModelFSLModelState,
        (state, action) => {
          if (state.currentCustomModel) {
            state.currentCustomModel.config.fslModelState = action.payload;
          }
        }
      )
      .addCase(
        customModelAction.UpdateCustomModelIsFSLModel,
        (state, action) => {
          if (state.currentCustomModel) {
            state.currentCustomModel.config.isFSLModel = action.payload;
          }
        }
      )
      .addCase(customModelAction.RenameCustomModel, (state, action) => {
        if (state.currentCustomModel) {
          state.currentCustomModel.name = action.payload;
          state.currentCustomModel.config.trainingRequested = false;
        }
      })
      .addCase(
        customModelAction.RequestCustomModelTraining,
        (state, action) => {
          const { remark } = action.payload;
          if (state.currentCustomModel) {
            if (state.currentCustomModel.config.remark === "") {
              state.currentCustomModel.config.remark = remark;
            }
            state.currentCustomModel.config.trainingRequested = true;
          }
        }
      )
      .addCase(customModelAction.FreezeFieldsForLabelling, state => {
        if (state.currentCustomModel) {
          const { labelSchema, unfrozenLabelSchema } =
            state.currentCustomModel.config;

          state.currentCustomModel.config.labelSchema = labelSchema.concat(
            unfrozenLabelSchema.map(freezeLabelSchema)
          );
          state.currentCustomModel.config.unfrozenLabelSchema = [];
          state.currentCustomModel.config.trainingRequested = true;
          state.currentCustomModel.config.labellingStarted = true;
        }
      })
      .addCase(customModelAction.TriggerSyncWithCVAT, state => {
        if (state.currentCustomModel) {
          state.currentCustomModel.lastCVATProjectStartSyncAt =
            new Date().getTime();
        }
      })
      .addCase(customModelAction.TriggerModelTraining, state => {
        if (state.currentCustomModel) {
          state.currentCustomModel.startTrainingAt = new Date().getTime();
          state.currentCustomModel.isTrainingFailed = false;
        }
      })
      .addCase(customModelAction.DeployModelVersion, (state, action) => {
        if (state.currentCustomModel) {
          state.currentCustomModel.startDeploymentAt = new Date().getTime();
          state.currentCustomModel.modelVersions =
            state.currentCustomModel.modelVersions.map(x => ({
              ...x,
              isActive: x.tag === action.payload,
            }));
        }
      })
      .addCase(
        customModelAction.PatchFslUploadingSampleImagesState,
        (state, action) => {
          const { customModelId, reset } = action.payload;

          if (!(customModelId in state.fslUploadingSampleImagesState)) {
            state.fslUploadingSampleImagesState[customModelId] = {};
          }

          Object.keys(action.payload.changes).forEach(field => {
            const orig =
              (state.fslUploadingSampleImagesState[customModelId] as any)[
                field
              ] ?? 0;
            const src = (action.payload.changes as any)[field];
            (state.fslUploadingSampleImagesState[customModelId] as any)[field] =
              reset ? src : orig + src;
          });
        }
      )
      .addCase(customModelAction.CustomModelInvalidated, state => {
        state.currentCustomModel = undefined;
      })
      .addCase(customModelAction.UpdatedCustomModelConfig, (state, action) => {
        const { config } = action.payload;
        if (state.currentCustomModel) {
          state.currentCustomModel.config = config;
        }
      })
      .addCase(teamAction.TeamUserRemoved, (state, action) => {
        const { removedUserId, currentUser } = action.payload;
        if (removedUserId === currentUser.id) {
          return {
            ...defaultState,
            customModelImageUploadStatistics:
              state.customModelImageUploadStatistics,
            deletingCustomModelImageIds: state.deletingCustomModelImageIds,
            customModelImagelastUploadError:
              state.customModelImagelastUploadError,
            customModelImagelastDeleteError:
              state.customModelImagelastDeleteError,
          };
        } else {
          return state;
        }
      })
      .addCase(workspaceAction.CreateWorkspaceSuccess, (state, action) => {
        if (
          state.currentCustomModel?.formID != null &&
          state.currentCustomModel.formID === action.payload.formId
        ) {
          state.currentCustomModel.workspaces = [
            ...state.currentCustomModel.workspaces,
            BriefWorkspaceMapper.fromWorkspace(action.payload.workspace),
          ];
        }
      })
      .addCase(workspaceAction.DeleteWorkspaceSuccess, (state, action) => {
        if (state.currentCustomModel != null) {
          state.currentCustomModel.workspaces =
            state.currentCustomModel.workspaces.filter(
              (w: BriefWorkspace) => w.id !== action.payload.workspaceId
            );
        }
      })
      .addMatcher(isAnyOf(userAction.UserLogin, userAction.UserLogout), () => ({
        ...defaultState,
      }))
      .addMatcher(
        isAnyOf(
          teamAction.CreateTeam,
          teamAction.TeamInvitationAccepted,
          teamAction.TeamDeleted,
          resourceOwnerAction.SelectTeam
        ),
        state => ({
          ...defaultState,
          customModelImageUploadStatistics:
            state.customModelImageUploadStatistics,
          deletingCustomModelImageIds: state.deletingCustomModelImageIds,
          customModelImagelastUploadError:
            state.customModelImagelastUploadError,
          customModelImagelastDeleteError:
            state.customModelImagelastDeleteError,
        })
      );
  }
);
