import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import styles from "./styles.module.scss";

export function FSLSchemaNotSetWarning() {
  return (
    <div className={styles["schema-not-set-warning"]}>
      <div className={styles["schema-not-set-warning-content"]}>
        <div className={styles["schema-not-set-warning-title"]}>
          <FormattedMessage id="fsl_custom_model.schema_not_set_warning.title" />
        </div>
        <div className={styles["schema-not-set-warning-desc"]}>
          <FormattedMessage id="fsl_custom_model.schema_not_set_warning.desc" />
        </div>
      </div>
    </div>
  );
}
