import { UAParser } from "ua-parser-js";

const parser = new UAParser();

enum KeyModifier {
  Alt = "alt",
  Ctrl = "ctrl",
  Meta = "meta",
  Shift = "shift",
}

function getModifiers(
  event: React.KeyboardEvent<HTMLElement> | KeyboardEvent
): KeyModifier[] {
  const modifiers: KeyModifier[] = [];
  if (event.altKey) {
    modifiers.push(KeyModifier.Alt);
  }
  if (event.ctrlKey) {
    modifiers.push(KeyModifier.Ctrl);
  }
  if (event.metaKey) {
    modifiers.push(KeyModifier.Meta);
  }
  if (event.shiftKey) {
    modifiers.push(KeyModifier.Shift);
  }
  return modifiers;
}

export function getKeyName(
  event: React.KeyboardEvent<HTMLElement> | KeyboardEvent
): string {
  const keyName = event.key.toLowerCase();
  if (keyName === " ") {
    return "space";
  }
  const modifiers = getModifiers(event) as string[];
  return modifiers.concat([keyName]).join("+");
}

type OSKey = {
  mac?: string;
  defaults: string;
};

export function matchEventKey(
  event: React.KeyboardEvent<HTMLElement> | KeyboardEvent,
  keys: OSKey
): boolean {
  if (!event) {
    return false;
  }
  const os = parser.getOS();
  const keyName = getKeyName(event);

  if (os.name === "Mac OS" && keys.mac !== undefined) {
    return keyName === keys.mac;
  }

  return keyName === keys.defaults;
}
