@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.custom-model-detail {
  margin-top: 20px;
  width: 100%;

  .custom-model-list-link {
    font-weight: 400;
    font-size: 12px;
    color: #25d0b1;
  }

  .custom-model-list-link-arrow {
    font-size: 10px;
    color: #a19f9d;
    margin-left: 4px;
  }

  .custom-model-detail-name {
    font-weight: 600;
    font-size: 20px;
    color: #000;
    margin-top: 8px;
  }

  .custom-model-detail-field {
    margin-top: 12px;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    color: #201f1e;
    padding: 4px;
    border-radius: 2px;
    border: 1px solid #f3f2f1;
    margin-right: 16px;

    &.custom-model-detail-field-frozen {
      background-color: #f3f2f1;
    }
  }
}
