import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { PlanQuota } from "../../types/quota";
import styles from "./styles.module.scss";

function isQuotaEmpty(quota: PlanQuota): boolean {
  return (
    quota.aggregate == null &&
    quota.annually == null &&
    quota.monthly == null &&
    quota.daily == null
  );
}

interface QuotaTableProps {
  quota: PlanQuota | null;
}

function _QuotaTable(props: QuotaTableProps) {
  const { quota } = props;

  if (quota == null || isQuotaEmpty(quota)) {
    return (
      <div className={styles["plan-quota-table"]}>
        <div className={styles["plan-quota-table-title"]}>
          <FormattedMessage id="team.detail.quota" />
        </div>
        <div className={styles["plan-quota"]}>
          <FormattedMessage id="team.detail.plan.quota.unlimited" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles["plan-quota-table"]}>
      <div className={styles["plan-quota-table-title"]}>
        <FormattedMessage id="team.detail.quota" />
      </div>
      {quota.aggregate != null && (
        <div className={styles["plan-quota"]}>{quota.aggregate.hardLimit}</div>
      )}
      {quota.annually != null && (
        <div className={styles["plan-quota"]}>
          <FormattedMessage
            id="team.detail.plan.quota.annually"
            values={{ quota: quota.annually.hardLimit }}
          />
        </div>
      )}
      {quota.monthly != null && (
        <div className={styles["plan-quota"]}>
          <FormattedMessage
            id="team.detail.plan.quota.monthly"
            values={{ quota: quota.monthly.hardLimit }}
          />
        </div>
      )}
      {quota.daily != null && (
        <div className={styles["plan-quota"]}>
          <FormattedMessage
            id="team.detail.plan.quota.daily"
            values={{ quota: quota.daily.hardLimit }}
          />
        </div>
      )}
    </div>
  );
}

export const QuotaTable = React.memo(_QuotaTable);
export default _QuotaTable;
