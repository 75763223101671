import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { Link } from "react-router-dom";

import { Webhook } from "../../types/webhook";
import styles from "./styles.module.scss";

interface Props {
  webhook: Webhook;
  key: number;
  showEditButton: boolean;
}

export function _WebhookTableRow(props: Props) {
  const { webhook, showEditButton } = props;

  const editLink = React.useMemo(() => {
    return `/webhook/${webhook.id}/edit`;
  }, [webhook]);

  const connetWithListString = React.useMemo(() => {
    let result = "";
    for (const form of webhook.forms ?? []) {
      if (result.length > 0) {
        result += ", ";
      }
      result += form.name;
    }
    for (const formGroup of webhook.formGroups ?? []) {
      if (result.length > 0) {
        result += ", ";
      }
      result += formGroup.name;
    }
    return result;
  }, [webhook]);

  return (
    <tr className={styles["webhook-table-row"]}>
      <td>
        <div className={styles["basic-info"]}>
          <div className={styles["name"]}>{webhook.name}</div>
          <div className={styles["url"]}>{webhook.webhookUrl}</div>
        </div>
      </td>
      <td>
        <div className={styles["connect-with-list"]}>
          {connetWithListString}
        </div>
      </td>
      <td>
        {showEditButton && (
          <div className={styles["action"]}>
            <Link className={styles["edit-link"]} to={editLink}>
              <FormattedMessage id="webhook.list.edit" />
            </Link>
          </div>
        )}
      </td>
    </tr>
  );
}

export const WebhookTableRow = React.memo(_WebhookTableRow);
export default WebhookTableRow;
