.small-icon-button {
  width: 32px;
  height: 32px;
  color: #a19f9d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;

  > i {
    height: 16px;
    width: 16px;
    display: block;

    > svg {
      height: 16px;
      width: 16px;
    }
  }

  &:hover {
    background-color: #ffe7e7;
    color: #a4262c;
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      #ffe7e7;
    color: #a4262c;
  }
}
