import { IconButton } from "@fluentui/react";
import cn from "classnames";
import Logo from "jsx:../../images/logo/logo-white.svg";
import * as React from "react";
import { useLocation } from "react-router";

import { usePrevious } from "../../hooks/common";
import { useLeftBar } from "../../hooks/component";
import styles from "./styles.module.scss";

type Props = {
  children?: React.ReactNode;
  isVisible: boolean;
};

export function useNavDrawer(props: Props) {
  const { toggleCollapsed } = useLeftBar();

  const { pathname } = useLocation();

  const previousPathName = usePrevious(pathname);

  React.useEffect(() => {
    // Auto close nav drawer on path change
    if (previousPathName !== pathname) {
      toggleCollapsed(true);
    }
  }, [pathname, previousPathName, toggleCollapsed]);

  const close = React.useCallback(() => {
    toggleCollapsed(true);
  }, [toggleCollapsed]);

  const { children, isVisible } = props;

  return React.useMemo(
    () => ({
      children,
      isVisible,
      close,
    }),
    [children, isVisible, close]
  );
}

export function NavDrawerImpl(props: ReturnType<typeof useNavDrawer>) {
  const { children, close, isVisible } = props;

  const containerClassName = cn(styles["container"], {
    [styles["container-closed"]]: !props.isVisible,
  });

  const onBackdropClose = React.useCallback(
    (ev?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev?.stopPropagation();
      close();
    },
    [close]
  );

  return (
    <>
      {isVisible && (
        <div className={styles["backdrop"]} onClick={onBackdropClose} />
      )}
      <div className={containerClassName}>
        <div className={styles["header"]}>
          <IconButton
            styles={{
              icon: { color: "#201F1E" },
            }}
            iconProps={{ iconName: "ChromeClose" }}
            onClick={close}
          />
          <div className={styles["logo"]}>
            <Logo />
          </div>
        </div>
        <div className={styles["content"]}>{children}</div>
      </div>
    </>
  );
}

export function NavDrawer(props: Props) {
  const states = useNavDrawer(props);
  return <NavDrawerImpl {...states} />;
}
