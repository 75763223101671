import {
  Dialog,
  IDialogProps,
  IScrollablePaneStyles,
  ScrollablePane,
} from "@fluentui/react";
import { ResizeObserver } from "@juggle/resize-observer";
import classnames from "classnames";
import * as React from "react";
import { useState } from "react";
import useDimensions from "react-cool-dimensions";

import styles from "./styles.module.scss";

const MIN_SCROLLABLE_CONTAINER_HEIGHT = 176;
const MAX_SCROLLABLE_CONTAINER_HEIGHT_VH = 0.7;
const SCROLLABLE_CONTAINER_MARGIN = 10;

interface ScrollableModalProps extends IDialogProps {
  children: JSX.Element;
  formProps?: Omit<
    React.DetailedHTMLProps<
      React.FormHTMLAttributes<HTMLFormElement>,
      HTMLFormElement
    >,
    "children"
  >;
  footerItem: JSX.Element | undefined;
  className: string | undefined;
}

const useScrollablePane = () => {
  const [scrollableContainerHeight, setScrollableContainerHeight] =
    useState<number>(MIN_SCROLLABLE_CONTAINER_HEIGHT);

  const { observe } = useDimensions({
    onResize: ({ height }) => {
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );

      const targetHeight = Math.min(
        MAX_SCROLLABLE_CONTAINER_HEIGHT_VH * vh,
        height
      );

      setScrollableContainerHeight(targetHeight + SCROLLABLE_CONTAINER_MARGIN);
    },
    polyfill: ResizeObserver,
  });

  return {
    scrollableContainerHeight,
    scrollableContentRef: observe as (instance: HTMLDivElement | null) => void,
  };
};

const ScrollableModal = React.memo((props: ScrollableModalProps) => {
  const {
    dialogContentProps,
    minWidth,
    hidden,
    modalProps,
    onDismiss,
    children,
    footerItem,
    className,
    formProps,
  } = props;

  const { scrollableContainerHeight, scrollableContentRef } =
    useScrollablePane();

  const scrollablePaneStyles: Partial<IScrollablePaneStyles> = {
    root: styles["scrollable-root"],
    contentContainer: styles["scrollable-contentContainer"],
  };

  return (
    <Dialog
      minWidth={minWidth}
      hidden={hidden}
      onDismiss={onDismiss}
      modalProps={{
        ...modalProps,
        className: classnames(className, styles["scrollable-modal"]),
      }}
      dialogContentProps={dialogContentProps}
    >
      <form {...(formProps || {})}>
        <div
          id="scrollableContainer"
          className={styles["scrollable-container"]}
          style={{
            height: scrollableContainerHeight,
          }}
        >
          <ScrollablePane styles={scrollablePaneStyles}>
            <div ref={scrollableContentRef}>{children}</div>
          </ScrollablePane>
        </div>
        <div className={styles["footer"]}>{footerItem}</div>
      </form>
    </Dialog>
  );
});
export default ScrollableModal;
