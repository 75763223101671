import errors, {
  AuditLogError,
  FOCRError,
  PluginError,
  UnmappedFOCRError,
  WorkerErrorMap,
} from "../errors";

export const PluginStripeCardErrorMap: { [key: string]: FOCRError } = {
  processing_error: errors.StripeCardProcessingError,
  card_declined: errors.StripeCardDeclinedError,
  incorrect_cvc: errors.StripeCardIncorrectCvcError,
  expired_card: errors.StripeCardExpiredError,
};

export function getPluginStripeError(error: any) {
  if (
    !error.code ||
    ![PluginError.StripeCardError, PluginError.StripeError].includes(error.code)
  ) {
    return errors.UnknownError;
  }

  if (error.code === PluginError.StripeError) {
    return errors.StripeError;
  }

  if (!error.stripe_code) {
    return errors.UnknownError;
  }

  const cardError = PluginStripeCardErrorMap[error.stripe_code];
  if (!cardError) {
    return errors.StripeError;
  }
  return cardError;
}

export function getAuditLogError(error: any) {
  if (error.code === AuditLogError.AuditLogNotFound) {
    return errors.AuditLogNotFound;
  } else {
    return errors.UnknownError;
  }
}

export function getWorkerRequestError(error: any) {
  if (error instanceof TypeError) {
    return errors.NetworkError;
  }
  if (error instanceof Error && error.name === "AbortError") {
    return errors.CancelledByUser;
  }
  const workerError =
    (error.response && error.response.body && error.response.body.error) ||
    error.error ||
    error;
  if (!workerError) {
    return errors.UnknownError;
  } else if (workerError.code && workerError.code in WorkerErrorMap) {
    const errorCode: number = workerError.code;
    const focrError = WorkerErrorMap[errorCode];
    if (workerError.info) {
      focrError.detail = workerError.info;
    }
    return focrError;
  } else if (workerError.info && workerError.info.message) {
    return new UnmappedFOCRError(workerError.info.message);
  } else if (workerError.message) {
    return new UnmappedFOCRError(workerError.message);
  } else {
    return errors.UnknownError;
  }
}

export function ensureFOCRError(
  error: any,
  defaultError: FOCRError = errors.UnknownError
) {
  if (error instanceof FOCRError) {
    return error;
  }

  return defaultError;
}
