import { Label, Modal, TextField } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useWorkspaceActionCreator } from "../../actions/workspace";
import { useLocale } from "../../contexts/locale";
import { useToast } from "../../hooks/toast";
import { BriefExtractor, ResourceType } from "../../types/extractor";
import { DefaultButton, PrimaryButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

type CreateWorkspaceModalProps = ReturnType<
  typeof useCreateWorkspaceModalHandle
>["props"];

export function useCreateWorkspaceModalHandle(
  extractor: BriefExtractor | null
) {
  const toast = useToast();

  const { createWorkspace } = useWorkspaceActionCreator();

  const [isCreating, setIsCreating] = React.useState(false);

  const [isOpen, setIsOpen] = React.useState(false);

  const onCreate = React.useCallback(
    async (workspaceName: string) => {
      if (extractor == null) {
        return;
      }
      setIsCreating(true);
      try {
        await createWorkspace({
          name: workspaceName,
          formId:
            extractor.resourceType !== ResourceType.FormGroup
              ? extractor.associatedExtractorId ?? extractor.id
              : undefined,
          formGroupId:
            extractor.resourceType === ResourceType.FormGroup
              ? extractor.associatedExtractorId ?? extractor.id
              : undefined,
        });
      } catch {
        toast.error("error.fail_to_create_workspace");
      } finally {
        setIsCreating(false);
      }
    },
    [createWorkspace, extractor, toast]
  );

  const dismiss = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const onCreateExtractorWorkspaceClick = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  return React.useMemo(
    () => ({
      props: {
        isOpen,
        onCreate,
        dismiss,
      },
      isCreating,
      onCreateExtractorWorkspaceClick,
    }),
    [dismiss, isOpen, isCreating, onCreateExtractorWorkspaceClick, onCreate]
  );
}

export function useCreateWorkspaceModal(args: CreateWorkspaceModalProps) {
  const { isOpen, onCreate, dismiss } = args;
  const { localized } = useLocale();

  const [workspaceName, setWorkspaceName] = React.useState("");
  const [workspaceNameErrorMessageId, setWorkspaceNameErrorMessageId] =
    React.useState<string>();

  const workspaceNameErrorMessage = React.useMemo(() => {
    return workspaceNameErrorMessageId != null
      ? localized(workspaceNameErrorMessageId)
      : undefined;
  }, [localized, workspaceNameErrorMessageId]);

  const validateWorkspaceName = React.useCallback(
    (input?: string): null | { data: string } => {
      const data = (input ?? workspaceName).trim();
      if (data.length === 0) {
        setWorkspaceNameErrorMessageId("common.error.field_required");
        return null;
      }
      setWorkspaceNameErrorMessageId(undefined);
      return { data };
    },
    [workspaceName]
  );

  const onWorkspaceNameChange = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: string = ""
    ) => {
      event.preventDefault();
      event.stopPropagation();
      setWorkspaceName(value);
      validateWorkspaceName(value);
    },
    [validateWorkspaceName]
  );

  const onCreateClick = React.useCallback(
    (e: React.MouseEvent<HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();

      const workspaceName = validateWorkspaceName();
      if (workspaceName == null) {
        return;
      }

      onCreate(workspaceName.data);
      dismiss();
    },
    [dismiss, onCreate, validateWorkspaceName]
  );

  const reset = React.useCallback(() => {
    setWorkspaceName("");
    setWorkspaceNameErrorMessageId(undefined);
  }, []);

  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return React.useMemo(
    () => ({
      isOpen,
      workspaceName,
      workspaceNameErrorMessage,
      onWorkspaceNameChange,
      onCancel: dismiss,
      onCreate: onCreateClick,
    }),
    [
      dismiss,
      isOpen,
      onCreateClick,
      workspaceName,
      workspaceNameErrorMessage,
      onWorkspaceNameChange,
    ]
  );
}

export function CreateWorkspaceModalImpl(
  props: ReturnType<typeof useCreateWorkspaceModal>
) {
  return (
    <Modal isOpen={props.isOpen}>
      <form className={styles["content"]} onSubmit={props.onCreate}>
        <div className={styles["header"]}>
          <FormattedMessage id="extractor.setting.modal.create_workspace.title" />
        </div>
        <div className={styles["field"]}>
          <Label>
            <FormattedMessage id="extractor.setting.modal.create_workspace.field.workspace_name.label" />
          </Label>
          <TextField
            value={props.workspaceName}
            onChange={props.onWorkspaceNameChange}
            errorMessage={props.workspaceNameErrorMessage}
          />
        </div>
        <div className={styles["footer"]}>
          <DefaultButton onClick={props.onCancel} textId={"common.cancel"} />
          <PrimaryButton onClick={props.onCreate} textId={"common.create"} />
        </div>
      </form>
    </Modal>
  );
}

export function CreateWorkspaceModal(args: CreateWorkspaceModalProps) {
  const props = useCreateWorkspaceModal(args);
  return <CreateWorkspaceModalImpl {...props} />;
}
