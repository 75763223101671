@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.webhook-table-row {
  td:nth-child(1) {
    vertical-align: top;
  }

  td:nth-child(3) {
    width: 53px;
  }

  .basic-info {
    max-width: 272px;
    .name {
      height: 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .url {
      height: 16px;
      overflow: hidden;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $light-gray-color;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .connect-with-list {
    color: $light-gray-color;
  }

  .action {
    .edit-link {
      color: $form-extractor-logo-color;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
