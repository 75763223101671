import { ExtractedContentSchemaType } from "../types/extractedContentSchema";

export const EXTRACTED_CONTENT_SCHEMA_TEMPLATE = {
  business_registration: {
    name: "BusinessRegistration",
    definitions: [],
    payload: [
      {
        id: "",
        name: "date_of_commencement",
        type: "date",
        isList: false,
      },

      {
        id: "",
        name: "date_of_expiry",
        type: "date",
        isList: false,
      },
      {
        id: "",
        name: "certification_number",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "address",
        type: "multi_line_text",
        isList: false,
      },
      {
        id: "",
        name: "nature_of_business",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "company_name",
        type: "single_line_text",
        isList: false,
      },
    ],
  },
  passport: {
    name: "Passport",
    definitions: [],
    payload: [
      {
        id: "",
        name: "country_code",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "name_full",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "name_surname",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "name_given",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "nationality",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "authority",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "gender",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "date_issue",
        type: "date",
        isList: false,
      },
      {
        id: "",
        name: "date_birth",
        type: "date",
        isList: false,
      },
      {
        id: "",
        name: "date_expiry",
        type: "date",
        isList: false,
      },
      {
        id: "",
        name: "place_of_birth",
        type: "single_line_text",
        isList: false,
      },
    ],
  },
  air_waybill_llm: {
    name: "AirwayBill",
    definitions: [
      {
        id: "ItemDefID",
        name: "Item",
        fields: [
          {
            name: "nature_of_goods",
            type: "single_line_text",
            isList: false,
            description: "do not include total",
          },
          {
            name: "quantity",
            type: "number",
            isList: false,
          },
          {
            name: "chargeable_weight",
            type: "number",
            isList: false,
          },
          {
            name: "piece_number",
            type: "number",
            isList: false,
          },
          {
            name: "dimensions",
            type: "single_line_text",
            isList: false,
          },
        ],
      },
    ],
    payload: [
      {
        id: "",
        name: "tracking_number",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "shipment_date",
        type: "date",
        isList: false,
      },

      {
        id: "",
        name: "shipper_name",
        type: "single_line_text",
        isList: false,
      },

      {
        id: "",
        name: "shipper_address",
        type: "multi_line_text",
        isList: false,
      },
      {
        id: "",
        name: "consignee_name",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "consignee_address",
        type: "multi_line_text",
        isList: false,
      },
      {
        id: "",
        name: "items",
        type: ExtractedContentSchemaType.FieldGroup,
        isList: true,
        definitionId: "ItemDefID",
      },
      {
        id: "",
        name: "airport_of_departure",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "airport_of_destination",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "issuing_carrier_name",
        type: "single_line_text",
        isList: false,
      },
    ],
  },
  bank_statement: {
    name: "BankStatement",
    definitions: [
      {
        id: "TransactionDefID",
        name: "Transaction",
        fields: [
          {
            name: "date",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "description",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "withdrawal",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "deposit",
            type: "single_line_text",
            isList: false,
          },

          {
            name: "balance",
            type: "single_line_text",
            isList: false,
          },
        ],
      },
    ],
    payload: [
      {
        id: "",
        name: "bank_name",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "account_holder_name",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "account_number",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "statement_date",
        type: "date",
        isList: false,
      },
      {
        id: "",
        name: "transactions",
        type: ExtractedContentSchemaType.FieldGroup,
        isList: true,
        definitionId: "TransactionDefID",
      },
    ],
  },
  resume_llm: {
    name: "Resume",
    definitions: [
      {
        id: "EducationItemDefID",
        name: "EducationItem",
        fields: [
          {
            name: "start_date",
            type: "date",
            isList: false,
          },
          {
            name: "end_date",
            type: "date",
            isList: false,
          },
          {
            name: "institution",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "degree",
            type: "single_line_text",
            isList: false,
          },
        ],
      },
      {
        id: "EmploymentHistoryItemDefID",
        name: "WorkingExperienceItem",
        fields: [
          {
            name: "employment_start_date",
            type: "date",
            isList: false,
          },
          {
            name: "employment_end_date",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "employer",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "job_title",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "job_description",
            type: "multi_line_text",
            isList: false,
          },
        ],
      },
      {
        id: "SkillItemDefID",
        name: "SkillItem",
        fields: [
          {
            name: "description",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "level",
            type: "single_line_text",
            isList: false,
            description: "return null if it's not available",
          },
        ],
      },
      {
        id: "LanguageItemDefID",
        name: "LanguageItem",
        fields: [
          {
            name: "language",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "level",
            type: "single_line_text",
            isList: false,
            description: "return null if it's not available",
          },
        ],
      },
    ],
    payload: [
      {
        id: "",
        name: "name",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "phone_number",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "email",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "employment_history",
        type: ExtractedContentSchemaType.FieldGroup,
        isList: true,
        definitionId: "EmploymentHistoryItemDefID",
      },
      {
        id: "",
        name: "education",
        type: ExtractedContentSchemaType.FieldGroup,
        isList: true,
        definitionId: "EducationItemDefID",
      },

      {
        id: "",
        name: "skills",
        type: ExtractedContentSchemaType.FieldGroup,
        isList: true,
        definitionId: "SkillItemDefID",
      },
      {
        id: "",
        name: "professional_summary",
        type: "multi_line_text",
        isList: false,
      },
      {
        id: "language",
        name: "language",
        type: ExtractedContentSchemaType.FieldGroup,
        isList: true,
        definitionId: "LanguageItemDefID",
      },
    ],
  },
  invoice: {
    name: "Invoice",
    definitions: [
      {
        id: "PurchaseItemDefID",
        name: "PurchaseItem",
        fields: [
          {
            name: "date",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "description",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "unit_price",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "quantity",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "subtotal",
            type: "single_line_text",
            isList: false,
          },
        ],
      },
      {
        id: "BankInfoRefID",
        name: "BankInfo",
        fields: [
          {
            name: "account_name",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "account_number",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "swift_or_bic",
            type: "single_line_text",
            isList: false,
          },
        ],
      },
    ],
    payload: [
      {
        id: "",
        name: "supplier_name",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "invoice_number",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "invoice_date",
        type: "date",
        isList: false,
      },
      {
        id: "",
        name: "total_amount",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "vat",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "payment_terms",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "purchase_items",
        type: ExtractedContentSchemaType.FieldGroup,
        isList: true,
        definitionId: "PurchaseItemDefID",
      },
      {
        id: "",
        name: "bank_info",
        type: ExtractedContentSchemaType.FieldGroup,
        isList: true,
        definitionId: "BankInfoRefID",
      },
    ],
  },
  purchase_order_llm: {
    name: "PurchaseOrder",
    definitions: [
      {
        id: "LineItemRefID",
        name: "LineItem",
        fields: [
          {
            name: "description",
            type: "multi_line_text",
            isList: false,
          },
          {
            name: "quantity",
            type: "number",
            isList: false,
          },
          {
            name: "unit_price",
            type: "number",
            isList: false,
          },
          {
            name: "subtotal",
            type: "number",
            isList: false,
          },
        ],
      },
    ],
    payload: [
      {
        id: "",
        name: "vendor_name",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "customer_id",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "order_number",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "date",
        type: "date",
        isList: false,
      },
      {
        id: "",
        name: "line_items",
        type: ExtractedContentSchemaType.FieldGroup,
        isList: true,
        definitionId: "LineItemRefID",
      },
      {
        id: "",
        name: "total_amount",
        type: "number",
        isList: false,
      },
      {
        id: "",
        name: "tax_amount",
        type: "number",
        isList: false,
      },
      {
        id: "",
        name: "remarks",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "delivery_address",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "vendor_address",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "delivery_date",
        type: "date",
        isList: false,
      },
    ],
  },
  quotation_llm: {
    name: "Quotation",
    definitions: [
      {
        id: "LineItemRefID",
        name: "LineItem",
        fields: [
          {
            name: "description",
            type: "multi_line_text",
            isList: false,
          },
          {
            name: "quantity",
            type: "number",
            isList: false,
          },
          {
            name: "unit_price",
            type: "number",
            isList: false,
          },
          {
            name: "unit",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "subtotal",
            type: "number",
            isList: false,
          },
        ],
      },
    ],
    payload: [
      {
        id: "",
        name: "vendor_name",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "customer_name",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "quotation_number",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "quotation_date",
        type: "date",
        isList: false,
      },
      {
        id: "",
        name: "quotation_expiry_date",
        type: "date",
        isList: false,
      },
      {
        id: "",
        name: "line_items",
        type: ExtractedContentSchemaType.FieldGroup,
        isList: true,
        definitionId: "LineItemRefID",
      },
      {
        id: "",
        name: "total_amount",
        type: "number",
        isList: false,
      },
      {
        id: "",
        name: "tax_amount",
        type: "number",
        isList: false,
      },
      {
        id: "",
        name: "remarks",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "payment_terms",
        type: "single_line_text",
        isList: false,
      },
    ],
  },
  bill_of_lading_instant_model: {
    name: "BillOfLading",
    payload: [
      {
        id: "",
        name: "shipper_company_name",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "shipper_details",
        type: "multi_line_text",
        isList: false,
        description:
          "everything related to the company info else except company name ",
      },
      {
        id: "",
        name: "consignee_company_name",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "consignee_details",
        type: "single_line_text",
        isList: false,
        description:
          "everything related to the company info else except company name ",
      },
      {
        id: "",
        name: "notify_party_company_name",
        type: "single_line_text",
        isList: false,
        description:
          'return "Same as Consignee" if it\'s printed as "Same as Consignee". ',
      },
      {
        id: "",
        name: "notify_party_details",
        type: "multi_line_text",
        isList: false,
        description:
          'return "Same as Consignee" if it\'s printed as "Same as Consignee". ',
      },
      {
        id: "",
        name: "vessel_name",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "voyage_no",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "port_of_loading",
        type: "single_line_text",
        isList: false,
        description:
          "port of loading, POL.\ndo not translate. if it's in chinese, return chinese. if it's english, return english.",
      },
      {
        id: "",
        name: "port_of_discharge",
        type: "single_line_text",
        isList: false,
        description:
          "could be named as port of departure or port of discharge.\ndo not translate. if it's in chinese, return chinese. if it's english, return english.",
      },
      {
        id: "",
        name: "place_of_delivery",
        type: "single_line_text",
        isList: false,
        description: "place of delivery, POD",
      },
      {
        id: "",
        name: "place_of_receipt",
        type: "single_line_text",
        isList: false,
        description: "place of receipt, port of receipt, POR",
      },
      {
        id: "",
        name: "final_destination",
        type: "single_line_text",
        isList: false,
      },
      {
        id: "",
        name: "no_of_original_copy",
        type: "number",
        isList: false,
      },
      {
        id: "",
        name: "freight_status",
        type: "single_line_text",
        isList: false,
        description: "either 'Prepaid' or 'Collect';",
      },
      {
        id: "",
        name: "cargo_ready_date",
        type: "date",
        isList: false,
        description:
          "the date the cargo is ready for pick up, do not return the date of the document. return null if cargo ready date is not found.",
      },
      {
        id: "",
        name: "marks_and_descriptions_table",
        type: "field_group",
        isList: true,
        description:
          "the marks and descriptions table has a table layout. \nwhitespaces are added between columns. if there's a considerable amount of whitespaces between two chunk of words, they are likely to be in two different, adjacent columns.",
        definitionId: "MarksAndDescriptionsTableItemDefId",
      },
      {
        id: "",
        name: "container_table",
        type: "field_group",
        isList: true,
        definitionId: "ContainerTableItemDefId",
      },
    ],
    definitions: [
      {
        id: "MarksAndDescriptionsTableItemDefId",
        name: "MarksAndDescriptionsTableType",
        fields: [
          {
            name: "mark_and_no",
            type: "multi_line_text",
            isList: false,
            description: "return all raw text in the mark and no column.",
          },
          {
            name: "description",
            type: "multi_line_text",
            isList: false,
            description:
              "return all raw text in the description column. including all sort of S/C numbers and codes..",
          },
          {
            name: "no_of_package",
            type: "number",
            isList: false,
          },
          {
            name: "package_unit",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "package_raw_text",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "gross_weight",
            type: "number",
            isList: false,
          },
          {
            name: "gross_weight_unit",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "gross_weight_raw_text",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "actual_weight",
            type: "number",
            isList: false,
          },
          {
            name: "actual_weight_unit",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "actual_weight_raw_text",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "measurement",
            type: "number",
            isList: false,
          },
          {
            name: "measurement_unit",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "measurement_raw_text",
            type: "single_line_text",
            isList: false,
          },
        ],
      },
      {
        id: "ContainerTableItemDefId",
        name: "ContainerTableType",
        fields: [
          {
            name: "container_no",
            type: "single_line_text",
            isList: false,
          },
          {
            name: "seal_no",
            type: "single_line_text",
            isList: false,
          },
        ],
      },
    ],
  },
};
