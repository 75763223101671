.container {
  .label {
    font-size: 16px;
  }
  .description {
    font-size: 14px;
    color: #605e5c;
    margin-bottom: 16px;
  }
}
