import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { PrimaryButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface InvitationLoggedOutProps {
  teamName?: string;
  signup: () => void;
}

export function InvitationLoggedOut(props: InvitationLoggedOutProps) {
  const { teamName, signup } = props;

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        {teamName && (
          <h3>
            <FormattedMessage
              id={"team.invitation.logged_out"}
              values={{ team: teamName }}
            />
          </h3>
        )}

        <div className={styles["message"]}>
          <FormattedMessage id={"team.invitation.logged_out.message"} />
        </div>

        <div className={styles["action"]}>
          <PrimaryButton textId="common.continue" onClick={signup} />
        </div>
      </div>
    </div>
  );
}

interface InvitationAcceptNotMatchProps {
  loginWithAnotherUser: () => void;
}

export function InvitationAccountNotMatch(
  props: InvitationAcceptNotMatchProps
) {
  const { loginWithAnotherUser } = props;

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <h3>
          <FormattedMessage id={"team.invitation.account_not_match"} />
        </h3>

        <div className={styles["message"]}>
          <FormattedMessage id={"team.invitation.account_not_match.message"} />
        </div>

        <div className={styles["action"]}>
          <PrimaryButton
            textId="team.invitation.account_not_match.login"
            onClick={loginWithAnotherUser}
          />
        </div>
      </div>
    </div>
  );
}
