import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useResourceOwnerActionCreator } from "../actions/resourceOwner";
import { Layout, Main, Top } from "../components/Layout";
import SettingForm from "../components/SettingForm";
import HeaderContainer from "../containers/Header";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";
import { UserSetting } from "../types/user";

function _SettingContainer() {
  const { getSetting, updateSetting } = useResourceOwnerActionCreator();

  const setting = useSelector<RootState, UserSetting | undefined>(
    state => state.resourceOwner.setting
  );
  const [settingToPreserve, setSettingToPreserve] = useState(setting);
  const toast = useToast();

  useEffect(() => {
    if (!setting) getSetting();
  }, [getSetting, setting]);

  const onSubmit = React.useCallback(async () => {
    if (!settingToPreserve) {
      return;
    }
    try {
      await updateSetting(settingToPreserve);
      toast.success("setting.update_success");
    } catch (error) {
      toast.error("setting.update_fail");
      throw error;
    }
  }, [updateSetting, settingToPreserve, toast]);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <SettingForm
          setting={setting}
          onSettingChange={setSettingToPreserve}
          onSubmit={onSubmit}
        />
      </Main>
    </Layout>
  );
}

export const SettingContainer = React.memo(_SettingContainer);
export default SettingContainer;
