import produce from "immer";
import * as yup from "yup";

import {
  PrebuiltExtractorDefinition,
  PrebuiltExtractors,
  isPrebuiltExtractors,
} from "../constants/prebuiltExtractor";
import {
  PrebuiltTemplatedExtractorDefinition,
  isPrebuiltTemplatedExtractors,
} from "../constants/templatedInstantModelExtractor";
import { PaginatedAsyncResult } from "./asyncResult";
import { detailedFormSchema } from "./form";
import { detailFormGroupSchema } from "./formGroup";
import { pageInfoWithOffsetSchema } from "./pageInfo";

export const extractorTypes = ["custom", "combine"] as const;
export const extractorTypeOptions = [
  "combine",
  "custom_form",
  "custom_model",
] as const;

export enum ResourceType {
  Form = "form",
  FormGroup = "form_group",
  CustomModel = "custom_model",
}

export enum ExtractorFieldValueType {
  Scalar = "scalar",
  ListOfScalar = "list_of_scalar",
  Dict = "dict",
  ListOfDict = "list_of_dict",
}

export function castExtractorFieldValueType(
  valueType: ExtractorFieldValueType,
  value: any
): any {
  if (
    valueType === ExtractorFieldValueType.ListOfScalar ||
    valueType === ExtractorFieldValueType.ListOfDict
  ) {
    if (Array.isArray(value)) {
      return value;
    }
    return [value];
  }

  if (
    valueType === ExtractorFieldValueType.Dict ||
    valueType === ExtractorFieldValueType.Scalar
  ) {
    if (
      Array.isArray(value) &&
      !(typeof value === "string" || value instanceof String)
    ) {
      return value[0];
    }
    return value;
  }
  return value;
}

export const ExtractorTypeValues: string[] = [
  ...PrebuiltExtractors,
  ...extractorTypes,
];

const extractorTypeSchema = yup.string().oneOf(ExtractorTypeValues);

const resourceTypeSchema = yup.string().oneOf(Object.values(ResourceType));

const extractorFieldValueTypeSchema = yup
  .string()
  .oneOf(Object.values(ExtractorFieldValueType))
  .defined();

export const briefExtractorSchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    resourceType: resourceTypeSchema.required(),
    extractorType: extractorTypeSchema.required(),
    associatedExtractorId: yup.string().notRequired(),
    processingMode: yup.string().notRequired(),
  })
  .camelCase();

export const extractorSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
    resourceType: resourceTypeSchema.defined(),
    extractorType: extractorTypeSchema.defined(),
    processingMode: yup.string().notRequired(),
    updatedAt: yup.string().defined(),
    createdAt: yup.string().defined(),
    image: yup.string().defined().nullable(),
    isPinned: yup.boolean().defined(),
  })
  .camelCase();

export const extractorOptionSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
    resourceType: resourceTypeSchema.defined(),
    extractorType: extractorTypeSchema.defined(),
    associatedExtractorId: yup.string().nullable(),
    processingMode: yup.string().notRequired(),
    hasFeatures: yup.boolean().nullable(),
  })
  .camelCase();

export const paginatedExtractorSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    extractors: yup.array(extractorSchema).defined(),
  })
  .camelCase();

export const paginatedExtractorOptionSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    extractors: yup.array(extractorOptionSchema).defined(),
  })
  .camelCase();

export const createPrebuiltExtractorRespSchema = yup
  .object({
    form: detailedFormSchema.optional().default(undefined),
    formGroup: detailFormGroupSchema.optional().default(undefined),
  })
  .camelCase();

export const extractorFieldSchema = yup
  .object({
    name: yup.string().required(),
    valueType: extractorFieldValueTypeSchema.required(),
    extractedBy: yup.string().required(),
    properties: yup
      .array(yup.string().required())
      .optional()
      .default(undefined),
  })
  .camelCase()
  .required();

export const extractorFieldSchemaTableSchema = yup
  .mixed((_input): _input is ExtractorGetSchemaFields => {
    return (
      typeof _input === "object" &&
      !Array.isArray(_input) &&
      _input !== null &&
      Object.values(_input).every(
        fields =>
          Array.isArray(fields) &&
          fields.every(item => extractorFieldSchema.isValidSync(item))
      )
    );
  })
  .transform(value => {
    return Object.entries(value as ExtractorGetSchemaFields).reduce(
      (prevValue, curValue) => {
        const [formId, fields] = curValue;
        return {
          ...prevValue,
          [formId]: fields.map(field => extractorFieldSchema.cast(field)),
        };
      },
      {}
    );
  })
  .required();

export type CreatePrebuiltExtractorResp = yup.InferType<
  typeof createPrebuiltExtractorRespSchema
>;

export type PrebuiltExtractor = keyof typeof PrebuiltExtractorDefinition;
export type PrebuiltTemplatedExtractor =
  keyof typeof PrebuiltTemplatedExtractorDefinition;

export { isPrebuiltExtractors };
export type ExtractorType = yup.InferType<typeof extractorTypeSchema>;
export type BriefExtractor = yup.InferType<typeof briefExtractorSchema>;
export type Extractor = yup.InferType<typeof extractorSchema>;
export type ExtractorOption = yup.InferType<typeof extractorOptionSchema>;
export type PaginatedExtractor = yup.InferType<typeof paginatedExtractorSchema>;
export type PaginatedExtractorOption = yup.InferType<
  typeof paginatedExtractorOptionSchema
>;
export type ExtractorField = yup.InferType<typeof extractorFieldSchema>;
export type ExtractorGetSchemaFields = {
  [key: string]: ExtractorField[];
};
export type ExtractorFieldSchemaTable = yup.InferType<
  typeof extractorFieldSchemaTableSchema
>;

export interface ExtractorOptionForCombinedExtractor {
  extractorId: string;
  name: string;
  resourceType: ResourceType;
  extractorType: ExtractorType;
  isInUse: boolean;
  isCurrentCombinedExtractor: boolean;
  hasFeatures: boolean;
}

export function mapExtractorTypeToMessageId(
  extractorType: NonNullable<ExtractorType>
): string {
  return isPrebuiltTemplatedExtractors(extractorType)
    ? `extractor.prebuilt.${extractorType}`
    : `extractor.${extractorType}`;
}

export function getExtractorHref(
  extractor: Extractor | BriefExtractor
): string {
  switch (extractor.resourceType) {
    case "custom_model":
      return `/custom-model/${extractor.id}/setup`;

    case "form":
      return `/form/${extractor.id}/${
        isPrebuiltExtractors(extractor.extractorType) ? "test" : "edit"
      }`;
    case "form_group":
      return `/form-group/${extractor.id}/${
        isPrebuiltExtractors(extractor.extractorType) ? "test" : "edit"
      }`;
  }
  throw new Error("Unexpected extractor resource type");
}

export type SampleImage = {
  src: string;
  preview?: string;
};

export class PaginatedExtractorAccessor {
  data: PaginatedAsyncResult<PaginatedExtractor>;

  constructor(data: PaginatedAsyncResult<PaginatedExtractor>) {
    this.data = data;
  }

  setExtractorPinned(id: string, isPinned: boolean) {
    let found = false;

    this.data = produce(this.data, draft => {
      for (const page of Object.keys(draft.pages).map(k => Number(k))) {
        const result = draft.pages[page].result;
        for (const extractor of result?.extractors ?? []) {
          if (extractor.id === id) {
            extractor.isPinned = isPinned;

            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
      return draft;
    });

    return this;
  }
}

export function accessPaginatedExtractor(
  data?: PaginatedAsyncResult<PaginatedExtractor>
) {
  return new PaginatedExtractorAccessor(
    data ?? {
      pages: {},
      currentPageParams: undefined,
    }
  );
}
