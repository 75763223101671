@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.plan-quota-table {
  margin-top: 20px;
  text-transform: capitalize;
  color: $dark-color;

  .plan-quota-table-title {
    font-weight: 600px;
    font-size: 16px;
  }

  .plan-quota {
    font-weight: 300;
    font-size: 14px;
    margin-top: 5px;
  }
}
