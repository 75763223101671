import * as yup from "yup";

import { pageInfoWithOffsetSchema } from "./pageInfo";

export const extractionResultInfoSchema = yup
  .object({
    status: yup.string().required(),
    error: yup.object().optional(),
    extractorId: yup.string().optional(),
    extractorType: yup.string().optional(),
    pageNumber: yup.number().integer().required(),
    sliceNumber: yup.number().integer().required(),
    resultAssetId: yup.string().required(),
    imageAssetId: yup.string().required(),
    resultAssetUrl: yup.string().optional(),
    imageAssetUrl: yup.string().optional(),
    isDeleted: yup.bool().required(),
  })
  .camelCase();

export const extractionInfoSchema = yup
  .object({
    extractorId: yup.string().optional(),
    extractJobId: yup.string().optional(),
    error: yup.object().optional(),
  })
  .camelCase();

export type ExtractionInfo = yup.InferType<typeof extractionInfoSchema>;

export type ExtractionResultInfo = yup.InferType<
  typeof extractionResultInfoSchema
>;

export const extractionResultSchema = yup
  .object({
    id: yup.string().optional(),
    workspaceId: yup.string().required(),
    extractionId: yup.string().required(),
    extractionIndex: yup.number().required(),
    fileName: yup.string().required(),
    info: extractionResultInfoSchema.required(),
    status: yup.string().required(),
  })
  .camelCase();

export type ExtractionResult = yup.InferType<typeof extractionResultSchema>;

export interface ExtractionResultPageInfo {
  previousExtractionResultId: string | null;
  nextExtractionResultId: string | null;
}

export const getExtractionResultRespSchema = yup
  .object({
    extractionResult: extractionResultSchema.required(),
    previousExtractionResultId: yup.string().nullable(),
    nextExtractionResultId: yup.string().nullable(),
  })
  .camelCase();

export type GetExtractionResultResp = yup.InferType<
  typeof getExtractionResultRespSchema
>;

export const extractionSchema = yup
  .object({
    id: yup.string().required(),
    createdAt: yup.date().required(),
    updatedAt: yup.date().required(),
    workspaceId: yup.string().required(),
    fileName: yup.string().required(),
    info: extractionInfoSchema.required(),
    results: yup.array(extractionResultSchema.required()).required(),
    processedAt: yup.date().nullable(),
    erroredAt: yup.date().nullable(),
  })
  .camelCase();

export type Extraction = yup.InferType<typeof extractionSchema>;

export const listProcessingExtractionsRespSchema = yup
  .object({
    extractions: yup.array(extractionSchema.required()).required(),
    extractionResults: yup.array(extractionResultSchema.required()).required(),
  })
  .camelCase();

export type ListProcessingExtractionsResp = yup.InferType<
  typeof listProcessingExtractionsRespSchema
>;

export const getExtractionRespSchema = extractionSchema.required();

export type GetExtractionResp = yup.InferType<typeof getExtractionRespSchema>;

export const paginatedExtractionsSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    extractions: yup.array(extractionSchema).required(),
  })
  .camelCase();

export type PaginatedExtractions = yup.InferType<
  typeof paginatedExtractionsSchema
>;

export const extractionFilterableFields = Object.freeze([
  "createdAt",
  "fileName",
]);
export type ExtractionFilterableField = "createdAt" | "fileName";

export const exportExtractionsRespSchema = yup
  .object({
    downloadUrl: yup.string().required(),
    extractionResultCount: yup.number().integer().required(),
  })
  .camelCase();

export type ExportExtractionsResp = yup.InferType<
  typeof exportExtractionsRespSchema
>;

export const listAdditionalImagesRespSchema = yup
  .object({
    pageInfo: pageInfoWithOffsetSchema.required(),
    additionalImageUrls: yup.array(yup.string().required()).required(),
  })
  .camelCase();

export type ListAdditionalImagesResp = yup.InferType<
  typeof listAdditionalImagesRespSchema
>;
