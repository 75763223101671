import { DateTime } from "luxon";
import React, { useCallback, useRef, useState } from "react";

import { DateRange, TeamDetailUsage } from "../components/TeamDetailUsage";
import {
  useFetchTeam,
  useFetchTeamRequestLogs,
  useFetchTeamUsage,
} from "../hooks/admin";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { useWorkerTokenDropdown } from "../hooks/usage";
import { AdminPathParam } from "../models";

function dateRangeToUsageRange(dateRange: DateRange) {
  return {
    start: DateTime.fromJSDate(dateRange.start).startOf("day").toUTC().toISO(),
    end: DateTime.fromJSDate(dateRange.end).endOf("day").toUTC().toISO(),
  };
}

function useDateRangeSelector() {
  const [dateRange, setDateRange] = React.useState<DateRange>({
    start: DateTime.now().minus({ days: 7 }).toJSDate(),
    end: new Date(),
  });

  const onDateRangeChange = React.useCallback(
    (dateFrom: Date, dateTo: Date) => {
      setDateRange({
        start: dateFrom,
        end: dateTo,
      });
    },
    []
  );

  return React.useMemo(
    () => ({
      dateRange,
      onDateRangeChange,
    }),
    [dateRange, onDateRangeChange]
  );
}

const PAGE_SIZE = 10;

function _AdminTeamDetailUsageContainer() {
  const { teamId, region } = useUnsafeParams<AdminPathParam>();
  const {
    usages,
    requestLogs,
    pageInfo,
    isFetchingUsages,
    isFetchingRequestLogs,
  } = useAppSelector(state => state.admin.teamDetail.usageState);

  useFetchTeam(teamId, region);

  const { dateRange, onDateRangeChange } = useDateRangeSelector();

  const [usageRange, setUsageRange] = useState(
    dateRangeToUsageRange(dateRange)
  );
  const [currentPage, setCurrentPage] = useState(1);

  const startFromRef = useRef(new Date());

  const searchDateRange = useCallback(() => {
    setUsageRange(dateRangeToUsageRange(dateRange));
    setCurrentPage(1);
    startFromRef.current = new Date();
  }, [dateRange]);

  const {
    onChange: onTokenDropdownChange,
    options: tokenDropdownOptions,
    selectedKeys: tokenDropdownSelectedKey,
    selectedTokenIds,
    isWorkerTokensLoaded,
  } = useWorkerTokenDropdown(teamId, region);

  useFetchTeamUsage(
    usageRange,
    teamId,
    region,
    selectedTokenIds,
    isWorkerTokensLoaded
  );
  useFetchTeamRequestLogs(
    currentPage,
    startFromRef.current,
    usageRange,
    teamId,
    region,
    selectedTokenIds,
    isWorkerTokensLoaded,
    PAGE_SIZE
  );

  return (
    <TeamDetailUsage
      usages={usages}
      requestLogs={requestLogs}
      pageInfo={pageInfo}
      isFetchingUsages={isFetchingUsages}
      isFetchingRequestLogs={isFetchingRequestLogs}
      pageSize={PAGE_SIZE}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      dateRange={dateRange}
      onDateRangeChange={onDateRangeChange}
      searchDateRange={searchDateRange}
      startFromRef={startFromRef}
      onTokenDropdownChange={onTokenDropdownChange}
      tokenDropdownOptions={tokenDropdownOptions}
      tokenDropdownSelectedKey={tokenDropdownSelectedKey}
    />
  );
}

export const AdminTeamDetailUsageContainer = React.memo(
  _AdminTeamDetailUsageContainer
);
export default AdminTeamDetailUsageContainer;
