import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { useUserActionCreator } from "../actions/user";
import LoadingModal from "../components/LoadingModal";
import SignUpForm from "../components/SignUpForm";
import { ToastStyleUpdater } from "../components/Toast";
import { AppConfig } from "../config";
import { ToastStyle } from "../contexts/toastStyle";
import errors, { FOCRError } from "../errors";
import { useToast } from "../hooks/toast";
import { RootState } from "../redux/types";

type ValidationResult = {
  isValid: boolean;
  usernameErrorMessageId?: string;
  emailErrorMessageId?: string;
  passwordErrorMessageId?: string;
  retypePasswordErrorMessageId?: string;
};

type FieldValidator = (
  username: string,
  email: string,
  password: string,
  retypePassword: string
) => ValidationResult;

type ErrorMessageId = string | undefined;

function existenceCheck(
  username: string,
  email: string,
  password: string,
  _retypePassword: string
): ValidationResult {
  const result: ValidationResult = { isValid: true };
  let hasMissingFields = false;
  if (!username) {
    hasMissingFields = true;
    result.usernameErrorMessageId = "common.username.empty";
  }

  if (!email) {
    hasMissingFields = true;
    result.emailErrorMessageId = "common.email.empty";
  }

  if (!password) {
    hasMissingFields = true;
    result.passwordErrorMessageId = "common.password.empty";
  }

  if (hasMissingFields) {
    return {
      ...result,
      isValid: false,
    };
  } else {
    return result;
  }
}

function validateEmail(
  _username: string,
  email: string,
  _password: string,
  _retypePassword: string
): ValidationResult {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(email).toLowerCase())) {
    return { isValid: true };
  } else {
    return {
      isValid: false,
      emailErrorMessageId: "error.invalid_email",
    };
  }
}

function validatePassword(
  _username: string,
  _email: string,
  password: string,
  retypePassword: string
): ValidationResult {
  if (password !== retypePassword) {
    return {
      isValid: false,
      retypePasswordErrorMessageId: "error.password_not_match",
    };
  }
  if (
    password.length < 8 ||
    !/[0-9]/.test(password) ||
    !/[a-z]/.test(password) ||
    !/[A-Z]/.test(password)
  ) {
    return {
      isValid: false,
      passwordErrorMessageId: "error.password_too_simple",
    };
  } else {
    return { isValid: true };
  }
}

function useSignUpForm() {
  const [username, setUsername] = useState("");
  const [usernameErrorMessageId, setUsernameErrorMessageId] =
    useState<ErrorMessageId>(undefined);

  const [email, setEmail] = useState("");
  const [emailErrorMessageId, setEmailErrorMessageId] =
    useState<ErrorMessageId>(undefined);

  const [password, setPassword] = useState("");
  const [passwordErrorMessageId, setPasswordErrorMessageId] =
    useState<ErrorMessageId>(undefined);

  const [retypePassword, setRetypePassword] = useState("");
  const [retypePasswordErrorMessageId, setRetypePasswordErrorMessageId] =
    useState<ErrorMessageId>(undefined);

  const [isSigningUp, setIsSigningUp] = useState(false);

  const usernameRef = useRef(username);
  const emailRef = useRef(email);
  const passwordRef = useRef(password);
  const retypePasswordRef = useRef(retypePassword);
  const isSigningUpRef = useRef(isSigningUp);

  usernameRef.current = username;
  emailRef.current = email;
  passwordRef.current = password;
  retypePasswordRef.current = retypePassword;
  isSigningUpRef.current = isSigningUp;

  const navigate = useNavigate();
  const { signUp } = useUserActionCreator();
  const toast = useToast();

  const onUsernameChange = useCallback((value: string) => {
    setUsername(value);
    setUsernameErrorMessageId(undefined);
  }, []);

  const onEmailChange = useCallback((value: string) => {
    setEmail(value);
    setEmailErrorMessageId(undefined);
  }, []);

  const onPasswordChange = useCallback((value: string) => {
    setPassword(value);
    setPasswordErrorMessageId(undefined);
  }, []);

  const onRetypePasswordChange = useCallback((value: string) => {
    setRetypePassword(value);
    setRetypePasswordErrorMessageId(undefined);
  }, []);

  const validateInput = useCallback(
    (
      username: string,
      email: string,
      password: string,
      retypePassword: string
    ): boolean => {
      const validators: FieldValidator[] = [
        existenceCheck,
        validateEmail,
        validatePassword,
      ];

      for (const validator of validators) {
        const result = validator(username, email, password, retypePassword);
        if (!result.isValid) {
          setUsernameErrorMessageId(result.usernameErrorMessageId);
          setEmailErrorMessageId(result.emailErrorMessageId);
          setPasswordErrorMessageId(result.passwordErrorMessageId);
          setRetypePasswordErrorMessageId(result.retypePasswordErrorMessageId);
          return false;
        }
      }

      return true;
    },
    []
  );

  const invitationCode = useSelector<RootState, string | undefined>(
    state => state.app.invitationCode
  );

  const onSubmit = useCallback(async () => {
    if (
      isSigningUpRef.current ||
      !validateInput(
        usernameRef.current,
        emailRef.current,
        passwordRef.current,
        retypePasswordRef.current
      )
    ) {
      return;
    }

    setUsernameErrorMessageId(undefined);
    setEmailErrorMessageId(undefined);
    setPasswordErrorMessageId(undefined);
    setRetypePasswordErrorMessageId(undefined);
    setIsSigningUp(true);

    try {
      await signUp(usernameRef.current, emailRef.current, passwordRef.current);
      if (invitationCode) {
        navigate(`/invitation?invitation-code=${invitationCode}`);
      } else {
        navigate("/form", { state: { newUser: true } });
      }
    } catch (e) {
      const error = e as FOCRError;
      if (error === errors.DuplicatedUser) {
        setEmailErrorMessageId(error.messageId);
      } else {
        toast.error(
          error.messageId,
          {},
          {
            passwordMinLength: AppConfig.auth.passwordMinLength,
          }
        );
      }
      setIsSigningUp(false);
    }
  }, [validateInput, signUp, invitationCode, navigate, toast]);

  return React.useMemo(
    () => ({
      username,
      usernameErrorMessageId,
      onUsernameChange,
      email,
      emailErrorMessageId,
      onEmailChange,
      password,
      passwordErrorMessageId,
      onPasswordChange,
      retypePassword,
      retypePasswordErrorMessageId,
      onRetypePasswordChange,
      onSubmit,
      isSigningUp,
    }),
    [
      username,
      usernameErrorMessageId,
      onUsernameChange,
      email,
      emailErrorMessageId,
      onEmailChange,
      password,
      passwordErrorMessageId,
      onPasswordChange,
      retypePassword,
      retypePasswordErrorMessageId,
      onRetypePasswordChange,
      onSubmit,
      isSigningUp,
    ]
  );
}

function _SignUpContainer() {
  const {
    username,
    usernameErrorMessageId,
    onUsernameChange,
    email,
    emailErrorMessageId,
    onEmailChange,
    password,
    passwordErrorMessageId,
    onPasswordChange,
    retypePassword,
    retypePasswordErrorMessageId,
    onRetypePasswordChange,
    onSubmit,
    isSigningUp,
  } = useSignUpForm();

  return (
    <>
      <ToastStyleUpdater toastStyle={ToastStyle.Signup} />
      <SignUpForm
        username={username}
        usernameErrorMessageId={usernameErrorMessageId}
        onUsernameChange={onUsernameChange}
        email={email}
        emailErrorMessageId={emailErrorMessageId}
        onEmailChange={onEmailChange}
        password={password}
        passwordErrorMessageId={passwordErrorMessageId}
        onPasswordChange={onPasswordChange}
        retypePassword={retypePassword}
        retypePasswordErrorMessageId={retypePasswordErrorMessageId}
        onRetypePasswordChange={onRetypePasswordChange}
        onSubmit={onSubmit}
      />
      <LoadingModal isOpen={isSigningUp || false} />
    </>
  );
}

export const SignUpContainer = React.memo(_SignUpContainer);
export default SignUpContainer;
