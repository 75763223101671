import { DateTime } from "luxon";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";

import AdminReportLayout from "../components/AdminReport";
import { getRegionKeys } from "../config";
import {
  CUSTOM_MODELS_PAGE_SIZE,
  NEW_TEAMS_CREATED_AT_DAYS,
  NEW_TEAMS_PAGE_SIZE,
  RANKING_LIST_DAY_RANGE,
  RANKING_LIST_LIMIT,
} from "../constants";
import {
  useFetchReportCustomModels,
  useFetchReportNewTeams,
  useFetchReportTeamsUsages,
} from "../hooks/admin";
import { useLocalizeRegion } from "../hooks/app";
import { useAppSelector } from "../hooks/redux";
import { BriefCustomModel } from "../types/customModel";
import { PageInfoWithOffset } from "../types/pageInfo";
import { UsageRange } from "../types/usage";
import {
  getOffsetByPage,
  getOffsetOfBeginningOfPage,
} from "../utils/pagination";

function getInitialOffset(
  pageSize: number,
  pageInfo?: PageInfoWithOffset,
  fallbackValue: number = 0
) {
  const currentOffset = pageInfo?.offset;

  if (currentOffset) {
    return getOffsetOfBeginningOfPage(currentOffset, pageSize);
  }

  return fallbackValue;
}

function _AdminReportContainer() {
  const { report } = useAppSelector(state => state.admin);
  const [selectedRegionKey, setSelectedRegionKey] = React.useState(
    report.region ?? getRegionKeys()[0]
  );
  const [newTeamListOffset, setNewTeamListOffset] = React.useState(
    getInitialOffset(
      NEW_TEAMS_PAGE_SIZE,
      report.newTeamListByRegion[selectedRegionKey]?.pageInfo,
      0
    )
  );
  const [newCustomModelListOffset, setNewCustomModelListOffset] =
    React.useState(
      getInitialOffset(
        CUSTOM_MODELS_PAGE_SIZE,
        report.newCustomModelByRegion[selectedRegionKey]?.pageInfo,
        0
      )
    );

  const localizeRegion = useLocalizeRegion();
  const regionOptions = React.useMemo(
    () =>
      getRegionKeys().map(r => ({
        key: r,
        label: localizeRegion(r),
      })),
    [localizeRegion]
  );

  const { rankingListUsageRange, newTeamsMinCreatedAt } = React.useMemo(() => {
    // TODO: Make endDate an input query param
    const endDate = DateTime.now().endOf("day").toUTC();
    const rankingListUsageRange: UsageRange = {
      start: endDate.minus({ days: RANKING_LIST_DAY_RANGE }).toISO(),
      end: endDate.toISO(),
    };
    const newTeamsMinCreatedAt = endDate
      .minus({ days: NEW_TEAMS_CREATED_AT_DAYS })
      .toISO();
    return {
      rankingListUsageRange,
      newTeamsMinCreatedAt,
    };
  }, []);

  const navigate = useNavigate();
  const onCustomModelItemClick = useCallback(
    (customModel: BriefCustomModel) => {
      navigate(
        `/admin/team/${selectedRegionKey}/${customModel.resourceOwnerId}/resources/custom-models/${customModel.id}`
      );
    },
    [navigate, selectedRegionKey]
  );

  useFetchReportTeamsUsages(
    rankingListUsageRange,
    RANKING_LIST_LIMIT,
    selectedRegionKey
  );

  const { refetchRegionNewTeamsWithOffset } = useFetchReportNewTeams(
    newTeamsMinCreatedAt,
    NEW_TEAMS_PAGE_SIZE,
    newTeamListOffset,
    selectedRegionKey
  );

  const { refetchRegionCustomModelWithOffset } = useFetchReportCustomModels(
    CUSTOM_MODELS_PAGE_SIZE,
    newCustomModelListOffset,
    selectedRegionKey
  );

  const onNewTeamListNavigateToPage = useCallback(
    (page: number) => {
      const newOffset = getOffsetByPage(page, NEW_TEAMS_PAGE_SIZE);
      setNewTeamListOffset(newOffset);
      refetchRegionNewTeamsWithOffset(selectedRegionKey, newOffset);
    },
    [selectedRegionKey, refetchRegionNewTeamsWithOffset]
  );

  const onNewCustomModelListNavigateToPage = useCallback(
    (page: number) => {
      const newOffset = getOffsetByPage(page, CUSTOM_MODELS_PAGE_SIZE);
      setNewTeamListOffset(newOffset);
      refetchRegionCustomModelWithOffset(selectedRegionKey, newOffset);
    },
    [selectedRegionKey, refetchRegionCustomModelWithOffset]
  );

  const onSelectedRegionKeyChange = useCallback(
    (newRegionKey: string) => {
      if (newRegionKey === selectedRegionKey) {
        return;
      }

      setSelectedRegionKey(newRegionKey);

      const newTeamListState = report.newTeamListByRegion[newRegionKey];
      const newTeamsCurrentOffset = newTeamListState?.pageInfo?.offset ?? 0;

      if (newTeamsCurrentOffset >= NEW_TEAMS_PAGE_SIZE) {
        setNewTeamListOffset(newTeamsCurrentOffset);
      } else {
        // Fetch first page
        setNewTeamListOffset(0);
        refetchRegionNewTeamsWithOffset(newRegionKey, 0);
      }

      const newCustomModelListState =
        report.newCustomModelByRegion[newRegionKey];
      const newCustomModelsCurrentOffset =
        newCustomModelListState?.pageInfo?.offset ?? 0;

      if (newCustomModelsCurrentOffset >= CUSTOM_MODELS_PAGE_SIZE) {
        setNewCustomModelListOffset(newCustomModelsCurrentOffset);
      } else {
        // Fetch first page
        setNewCustomModelListOffset(0);
        refetchRegionCustomModelWithOffset(newRegionKey, 0);
      }
    },
    [
      selectedRegionKey,
      report.newTeamListByRegion,
      report.newCustomModelByRegion,
      refetchRegionNewTeamsWithOffset,
      refetchRegionCustomModelWithOffset,
    ]
  );

  return (
    <AdminReportLayout
      selectedRegionKey={selectedRegionKey}
      onSelectedRegionKeyChange={onSelectedRegionKeyChange}
      regionOptions={regionOptions}
      reportState={report}
      newTeamListOffset={newTeamListOffset}
      onNewTeamListNavigateToPage={onNewTeamListNavigateToPage}
      newTeamListPageSize={NEW_TEAMS_PAGE_SIZE}
      newCustomModelListOffset={newCustomModelListOffset}
      onNewCustomModelListNavigateToPage={onNewCustomModelListNavigateToPage}
      newCustomModelPageSize={CUSTOM_MODELS_PAGE_SIZE}
      onCustomModelItemClick={onCustomModelItemClick}
    />
  );
}

export const AdminReportContainer = React.memo(_AdminReportContainer);
export default AdminReportContainer;
