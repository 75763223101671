import { BoundingBox } from "../types/boundingBox";
import { Vertex } from "../types/vertex";

export function bbox2polygon(bbox: BoundingBox): Vertex[] {
  return [
    { x: bbox.left, y: bbox.top },
    { x: bbox.right, y: bbox.top },
    { x: bbox.right, y: bbox.bottom },
    { x: bbox.left, y: bbox.bottom },
  ];
}
