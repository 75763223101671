export const CustomModelLabelSchemaTypeDefintion = {
  // First format is the default format
  text: {
    attributes: [{ name: "ocr" }, { name: "value" }],
    format: undefined,
    hidden: false,
  },
  date: {
    attributes: [{ name: "ocr" }, { name: "value" }],
    format: ["US", "UK", "ISO"],
    hidden: false,
  },
  time: {
    attributes: [{ name: "ocr" }, { name: "value" }],
    format: ["hhmm", "hhmmss", "hmmAMPM"],
    hidden: false,
  },
  amount: {
    attributes: [{ name: "ocr" }, { name: "value" }],
    format: [
      "USD",
      "EUR",
      "JPY",
      "GBP",
      "CNY",
      "AUD",
      "CAD",
      "CHF",
      "HKD",
      "SGD",
      "SEK",
      "KRW",
      "NOK",
      "NZD",
      "INR",
      "MXN",
      "TWD",
      "ZAR",
      "BRL",
      "DKK",
    ],
    hidden: false,
  },
  numbers: {
    attributes: [{ name: "ocr" }, { name: "value" }],
    format: undefined,
    hidden: false,
  },
  region: {
    attributes: [],
    format: undefined,
    hidden: true,
  },
} as const;
