import { Icon, Label } from "@fluentui/react";
import { FormattedMessage, Values } from "@oursky/react-messageformat";
import * as React from "react";

import styles from "./styles.module.scss";

interface Props {
  messageId: string;
  messageValues?: Values;
  children?: React.ReactNode;
}

const ErrorPlaceholder = React.memo(
  ({ messageId, messageValues, children }: Props) => (
    <div className={styles["container"]}>
      <div className={styles["icon-and-text"]}>
        <Icon iconName="Error" />
        <Label>
          <FormattedMessage id={messageId} values={messageValues} />
        </Label>
      </div>
      {children || null}
    </div>
  )
);

export default ErrorPlaceholder;
