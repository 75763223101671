import { AutoCompleteCellRenderer } from "./AutoCompleteCell";
import { DeleteCellRenderer } from "./DeleteCell";
import { EditCellRenderer } from "./EditCell";
import { EditTagCellRenderer } from "./EditTagCell";
import { RowOrderCellRenderer } from "./RowOrderCell";
import { SettingCellRenderer } from "./SettingCell";

export const customCellRenders = [
  EditCellRenderer,
  AutoCompleteCellRenderer,
  DeleteCellRenderer,
  SettingCellRenderer,
  RowOrderCellRenderer,
  EditTagCellRenderer,
];
