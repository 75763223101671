@import "../../styles/variables.scss";

.container {
  background-color: $neutral-lighter;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  min-width: calc(min(100vw - 40px, 450px));
  padding: 12px 20px;
  background-color: $theme-dark-alt;
  color: $theme-dark-alt;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .title {
    color: $white;
  }

  .labeller-button {
    background-color: transparent;
    color: $white;
    border-color: $white;
    padding: 6px 20px 6px 20px;
    transition: 0.25s;
  }

  .labeller-button:hover {
    background-color: $white;
    color: inherit;
  }

  .close-button {
    height: 24px;
    width: 24px;
    color: $white;
  }

  .close-button:hover {
    color: inherit;
  }
}

.image-preview-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .image {
    > img {
      width: 100%;
      max-height: calc(100vh - 152px - 103px + 28px);
      object-fit: contain;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    color: #605e5c;
  }
}
