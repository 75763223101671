@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 22px 12px 6px;
  height: 100%;
}

.sub-content {
  width: 100%;
  padding-right: 10px;
  padding-bottom: 20px;
  flex: 1;
}

.tab-bar {
  width: 100%;
  height: 100%;
  padding-top: 8px;
  display: flex;
  flex-direction: column;

  > div > button {
    width: 92px;
  }

  > div:nth-child(2) {
    flex: 1 1 0;
    overflow-y: auto;
  }
}

.custom_extraction_items {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;

  .custom_extraction_items_label {
    margin-right: 4px;
    font-size: 16px;
  }

  i {
    font-size: 14px;
  }
}

.custom_extraction_items_dropdown {
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  margin-top: 8px;
  margin-bottom: 20px;
  padding-right: 16px;
}

%separator {
  height: 1px;
  width: 100%;
  margin: 16px 0 16px;
  background-color: $separator-color-1;
}

.separator {
  @extend %separator;

  &--before-advance-token-setup {
    @extend %separator;
    margin: 10px 0 30px;
  }
}

.prebuilt-extractor {
  padding: 20px;
  min-width: 300px;
  .custom_extraction_items_dropdown {
    width: 300px;
  }

  .document-field-cards {
    display: flex;
    flex-wrap: wrap;
    column-gap: 14px;
    max-width: 1200px;
  }

  .document-field-card {
    width: calc(100% / 3 - 10px);
  }

  @media screen and (max-width: 1200px) {
    .document-field-card {
      width: calc(50% - 7px);
    }
  }

  @media screen and (max-width: 850px) {
    .document-field-cards {
      flex-flow: column;
    }
    .document-field-card {
      width: 100%;
    }
  }
}

.footer {
  width: calc(100% + 40px);
  margin: 0 -20px 0;
  padding: 0 20px 10px;
}

.footer-separator {
  height: 1px;
  background-color: $separator-color-2;
  margin-bottom: 11px;
}

.footer-buttons {
  display: flex;
  justify-content: flex-end;
}
