import { DirectionalHint, IconButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cntl from "cntl";
import * as React from "react";
import { useNavigate } from "react-router";

import { ButtonId } from "../../constants/buttonids";
import { useFirstSession, useGtm } from "../../hooks/gtm";
import { useUnsafeParams } from "../../hooks/params";
import { useUserInfo } from "../../hooks/user";
import { PathParam } from "../../models";
import {
  ExitTooltip,
  GtmEventType,
  GtmOnboardingFtgTooltipViewed,
} from "../../services/gtmService";
import { Guidance, GuidanceStep, useGuidanceHandle } from "../Guidance";
import { ActionButton, PrimaryButton } from "../WrappedMSComponents/Buttons";

type Props = {
  isFrozen?: boolean;
};

export enum GuidanceMode {
  firstTimeGuidance = "ftg",
  confirmDetectionValid = "cdv",
}

const START_DELAY = {
  [GuidanceMode.firstTimeGuidance]: 3000,
  [GuidanceMode.confirmDetectionValid]: 10000,
};

export function StartGuidancePopup(props: {
  name: string;
  close: () => void;
  start: () => void;
}) {
  const { close, start } = props;
  const [opacity, setOpacity] = React.useState("opacity-0");

  React.useEffect(() => {
    setOpacity("opacity-100");
  }, []);

  return (
    <div
      className={cntl`card fixed right-[24px] bottom-[24px] w-[320px] h-[180px] p-[24px]
        flex flex-col z-50
        transition-opacity duration-500 ${opacity}
    `}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="truncate text-[#242424] leading-[20px] text-[20px] font-semibold h-[24px]">
          <FormattedMessage
            id="first_time_guidance.start_guidance_popup.title"
            values={{
              name: props.name,
            }}
          />
        </div>
        <IconButton
          onClick={close}
          iconProps={{
            iconName: "ChromeClose",
          }}
          styles={{
            root: {
              width: 28,
              height: 32,
            },
            icon: {
              color: "#000",
              fontSize: 12,
            },
          }}
        />
      </div>
      <div className="flex-1 flex flex-col justify-center">
        <div className="text-[#605E5C] leading-[20px] text-[14px] font-normal">
          <FormattedMessage id="first_time_guidance.start_guidance_popup.message" />
        </div>
      </div>
      <div className="flex flex-row justify-end items-center gap-[8px]">
        <ActionButton
          textId="first_time_guidance.start_guidance_popup.no_thanks"
          onClick={close}
        />
        <PrimaryButton
          textId="first_time_guidance.start_guidance_popup.show_me"
          onClick={start}
        />
      </div>
    </div>
  );
}

export function ConfirmDetectionValidPopup(props: {
  close: () => void;
  start: () => void;
}) {
  const { close, start } = props;
  const [opacity, setOpacity] = React.useState("opacity-0");

  React.useEffect(() => {
    setOpacity("opacity-100");
  }, []);

  return (
    <div
      className={cntl`card fixed right-[24px] bottom-[24px] w-[320px] h-[180px] p-[24px]
        flex flex-col z-50
        transition-opacity duration-500 ${opacity}
    `}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="truncate text-[#242424] leading-[20px] text-[20px] font-semibold h-[24px]">
          <FormattedMessage id="first_time_guidance.confirm_detection_valid_popup.title" />
        </div>
        <IconButton
          onClick={close}
          iconProps={{
            iconName: "ChromeClose",
          }}
          styles={{
            root: {
              width: 28,
              height: 32,
            },
            icon: {
              color: "#000",
              fontSize: 12,
            },
          }}
        />
      </div>
      <div className="flex-1 flex flex-col justify-center">
        <div className="text-[#605E5C] leading-[20px] text-[14px] font-normal">
          <FormattedMessage id="first_time_guidance.confirm_detection_valid_popup.message" />
        </div>
      </div>
      <div className="flex flex-row justify-end items-center gap-[8px]">
        <ActionButton
          textId="first_time_guidance.confirm_detection_valid_popup.all_good"
          onClick={close}
        />
        <PrimaryButton
          textId="first_time_guidance.confirm_detection_valid_popup.manage_field"
          onClick={start}
        />
      </div>
    </div>
  );
}

enum Step {
  OverlayPaginatorStep,
  ManageRulesStep,
  ReuploadStep,
  BookDemoStep,
}

const SCRIPT = [
  {
    id: Step.OverlayPaginatorStep,
    anchorIds: [ButtonId.OverlayPaginatorPrevButton],
    messageId: "first_time_guidance.step1",
  },
  {
    id: Step.ManageRulesStep,
    anchorIds: [ButtonId.ManageRulesTab, ButtonId.ManageFieldsTab],
    messageId: "first_time_guidance.step2",
  },
  {
    id: Step.ReuploadStep,
    anchorIds: [ButtonId.ExtractorTestPanelReuploadButton],
    messageId: "first_time_guidance.step3",
    directionHint: DirectionalHint.bottomLeftEdge,
  },
  {
    id: Step.BookDemoStep,
    anchorIds: [ButtonId.HeaderBookDemoButton],
    messageId: "first_time_guidance.step4",
  },
] as GuidanceStep[];

const StepToExitTooltipMap = {
  [Step.OverlayPaginatorStep]: "zoom",
  [Step.ManageRulesStep]: "manage_rules",
  [Step.ReuploadStep]: "reupload",
  [Step.BookDemoStep]: "book_demo",
};

const StepToGtmEventMap = {
  [Step.OverlayPaginatorStep]: GtmEventType.OnboardingFtgZoomInOutTooltipViewed,
  [Step.ManageRulesStep]: GtmEventType.OnboardingFtgManageRulesTooltipViewed,
  [Step.ReuploadStep]: GtmEventType.OnboardingFtgReuploadTooltipViewed,
  [Step.BookDemoStep]: GtmEventType.OnboardingFtgBookDemoTooltipViewed,
};

let singletonRef = null as null | {
  getCurrentStep: () => GuidanceStep | undefined;
};

export function useFirstTimeGuidanceState(props: Props) {
  const [isPopupVisible, setIsPopupVisible] = React.useState(false);
  const isFrozen = props.isFrozen ?? false;
  const { customModelId } = useUnsafeParams<PathParam>();

  const {
    pushOnboardingFtgExitEvent,
    pushOnboardingFtgTooltipViewedEvent,
    pushOnboardingFtgFirstDialogShowMeEvent,
  } = useGtm();

  const onGuidanceClose = React.useCallback(
    (lastStep: GuidanceStep) => {
      const tooltip = StepToExitTooltipMap[lastStep.id as Step];
      pushOnboardingFtgExitEvent(tooltip as ExitTooltip);
    },
    [pushOnboardingFtgExitEvent]
  );

  const onGuidanceStepShown = React.useCallback(
    (step: GuidanceStep) => {
      pushOnboardingFtgTooltipViewedEvent(
        StepToGtmEventMap[
          step.id as Step
        ] as GtmOnboardingFtgTooltipViewed["event"]
      );
    },
    [pushOnboardingFtgTooltipViewedEvent]
  );

  const { triggerProps: guidanceTriggerProps, start } = useGuidanceHandle(
    SCRIPT,
    onGuidanceStepShown,
    onGuidanceClose
  );

  const guidanceTriggerPropsRef = React.useRef(guidanceTriggerProps);
  guidanceTriggerPropsRef.current = guidanceTriggerProps;

  const { displayName: name } = useUserInfo();
  const navigate = useNavigate();

  const showGuidance = React.useCallback(() => {
    pushOnboardingFtgFirstDialogShowMeEvent();
    setIsPopupVisible(false);
    start();
  }, [start, pushOnboardingFtgFirstDialogShowMeEvent]);

  const manageFields = React.useCallback(() => {
    navigate(`/custom-model/${customModelId}/setup?guidance=true`);
  }, [customModelId, navigate]);

  const closePopup = React.useCallback(() => {
    setIsPopupVisible(false);
    pushOnboardingFtgExitEvent("first_dialog");
  }, [pushOnboardingFtgExitEvent]);

  const [guidanceMode, setGuidanceMode] = React.useState<
    GuidanceMode | undefined
  >();

  const { getIsFirstSession, getGuidanceMode } = useFirstSession();

  const startedOnce = React.useRef(false);

  React.useEffect(() => {
    if (isFrozen || startedOnce.current) {
      return;
    }
    startedOnce.current = true;
    if (getIsFirstSession()) {
      const guidanceMode = (getGuidanceMode() ??
        GuidanceMode.firstTimeGuidance) as GuidanceMode;
      setGuidanceMode(guidanceMode);
      setTimeout(() => {
        setIsPopupVisible(true);
      }, START_DELAY[guidanceMode]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFrozen]);

  const getCurrentStep = React.useCallback(() => {
    return guidanceTriggerPropsRef.current.currentStep;
  }, []);

  const instance = React.useMemo(() => {
    return {
      getCurrentStep,
    };
  }, [getCurrentStep]);

  React.useEffect(() => {
    singletonRef = instance;
    return () => {
      if (singletonRef === instance) {
        singletonRef = null;
      }
    };
  }, [instance]);

  return React.useMemo(
    () => ({
      guidanceTriggerProps,
      isPopupVisible,
      closePopup,
      showGuidance,
      name,
      guidanceMode,
      manageFields,
    }),
    [
      guidanceTriggerProps,
      isPopupVisible,
      closePopup,
      showGuidance,
      name,
      guidanceMode,
      manageFields,
    ]
  );
}

export function FirstTimeGuidance(props: Props) {
  const states = useFirstTimeGuidanceState(props);

  return <FirstTimeGuidanceImpl {...states} />;
}

export function FirstTimeGuidanceImpl(
  props: ReturnType<typeof useFirstTimeGuidanceState>
) {
  const {
    manageFields,
    isPopupVisible,
    closePopup,
    showGuidance,
    name,
    guidanceMode,
  } = props;
  return (
    <>
      {isPopupVisible &&
        (guidanceMode === GuidanceMode.confirmDetectionValid ? (
          <ConfirmDetectionValidPopup close={closePopup} start={manageFields} />
        ) : (
          <StartGuidancePopup
            name={name ?? ""}
            close={closePopup}
            start={showGuidance}
          />
        ))}
      <Guidance {...props.guidanceTriggerProps} />
    </>
  );
}

FirstTimeGuidance.getCurrentStep = function () {
  return singletonRef?.getCurrentStep?.();
};

FirstTimeGuidance.isManageRulesStepVisible = function () {
  return singletonRef?.getCurrentStep?.()?.id === Step.ManageRulesStep;
};
