@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

$create-form-bar-height: 42px;

.content {
  height: calc(100% - #{$create-form-bar-height});
  position: relative;
}

.token-table {
  td {
    padding: 0px 12px;
  }

  td:nth-child(3) {
    min-width: 140px;
  }
}

.token-table-row {
  height: 43px;
  td:first-child {
    width: 50%;
  }
  td:nth-child(2) {
    width: 50%;
  }
  td:nth-child(3) {
    min-width: 80px;
  }

  td {
    white-space: nowrap;
  }

  .button {
    i {
      font-size: 14px;
      color: #3a3b39;
    }
  }
}

.revoke-btn {
  font-weight: 600;
  font-size: 14px;
  color: #c4151e !important;
}

.tab {
  padding: 24px 32px;
  vertical-align: middle;
}

.top {
  padding: 32px;
}

.team-info-row {
  display: flex;
  justify-content: space-between;
}

.team-control-btns {
  align-self: flex-end;
  display: flex;
}

.rename-btn,
.delete-btn,
.create-token-btn {
  border: 1px solid #e1dfdd;
  color: #605e5c;
}

.rename-btn {
  margin-right: 8px;
}

.delete-btn {
  color: #c4151e;
  border-color: #c4151e;

  &:hover {
    color: #c4151e;
    background-color: #c4151e10;
  }
}

.invite-btn,
.create-token-btn {
  margin-bottom: 16px;
}

.pivot-container {
  height: 44px;

  .pivot {
    margin-left: 24px;
    box-sizing: content-box;
  }
  border-bottom: 2px solid #edebe9;

  :global(.ms-Pivot-icon) {
    vertical-align: middle;
  }
}

.no-token {
  text-align: center;
  font-size: 14px;
  margin-top: 25px;
  color: #605e5c;
  span {
    font-weight: 600;
  }
}
