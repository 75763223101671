.container {
  .hint {
    display: flex;
    height: 50px;
    flex-direction: row;
    padding: 10px;
    background-color: white;
    border-bottom: 1px solid #edebe9;

    .title {
      font-size: 10px;
      color: #323130;
      font-weight: 700;
      margin-right: 16px;
    }
    .description {
      flex: 1;
      font-size: 10px;
      color: #323130;

      &--bold {
        font-weight: 700;
      }

      &--highlight {
        color: #25d0b1;
      }
    }
  }
  .table {
    max-height: calc(100vh - 250px);
    outline: 0.5px solid #edebe9;

    &--no-result {
      display: none;
    }
  }

  .empty-view {
    display: flex;
    padding: 20px;
    justify-content: center;
    .title {
      font-size: 14px;
    }
  }
}

.tooltip {
  text-align: center;
  min-width: 60px;
  padding: 8px;
  font-size: 12px;
  color: #605e5c;
  align-items: center;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0px 6.400000095367432px 14.399999618530273px 0px
      rgba(0, 0, 0, 0.13),
    0px 1.2000000476837158px 3.5999999046325684px 0px rgba(0, 0, 0, 0.1);
  .tooltip-highlight-text {
    color: #0078d4;
  }
}
