@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.form-group-table,
.form-group-table-no-hover {
  padding-top: 16px;
}

.form-group-table-no-hover {
  tbody > tr:hover {
    background-color: transparent;
  }
}

.form-group-token-group-table-row {
  td {
    vertical-align: text-top;
  }

  td:nth-child(1) {
    min-width: 100px;
  }

  td:nth-child(2) {
    min-width: 150px;
  }

  td:nth-child(3) {
    min-width: 100px;
  }

  td:nth-child(4) {
    width: 100px;
  }

  .action-container {
    display: flex;
  }

  .action-button {
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
  }
}

.form-group-anchor-table-row {
  td:nth-child(1) {
    width: 100%;
  }
  td:nth-child(2) {
    width: 100px;
  }

  .action-container {
    display: flex;
  }

  .action-button {
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
  }
}

.form-group-document-detection-table-row {
  td:nth-child(1) {
    width: 30%;
    white-space: nowrap;
  }

  td:nth-child(2) {
    width: 70%;
    color: #605e5c;
  }

  .action-container {
    display: flex;
  }

  .action-button {
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
  }
}

.document-detection-modal-document-type-dropdown {
  margin-bottom: 20px;
}

.document-detection-modal-footer {
  margin-top: 20px;
}

.form-group-editor {
  padding: 20px;
  height: 100%;
  overflow: auto;

  h1 {
    margin-top: 12px;
    padding: 12px 0;
    font-size: 18px;
    border-bottom: 1px solid #ccc;
  }

  .description {
    margin: 10px 20px;
    padding: 10px 0 0;
    display: block;
  }

  .section {
    padding: 0 12px;
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .group-name {
    font-size: 16px;
    line-height: 20pt;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.form-group-form-list {
  position: relative;

  :global(.ms-DetailsRow-cell) {
    font-size: 14px;
    color: black;
  }

  :global(.ms-DetailsRow):hover {
    background-color: white;
  }

  .add-new-form-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .add-new-form-button-empty-list {
    justify-content: center;
    padding: 24px;
    width: 220px;
    height: 131px;
    background: #faf9f8;
    border: 1px solid #d2d0ce;
    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1),
      0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
    span {
      font-size: 16px;
    }
    .form-group-document-detection-add-rule-icon {
      color: $dark-color;
      font-size: 20px;
    }
  }

  .add-new-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    margin: 22px 0 2px;
  }

  .add-rule-icon {
    color: $theme-primary;
    margin-right: 10px;
  }

  .add-new-form-button-icon {
    margin-right: 10px;
  }

  .action-container {
    display: flex;
  }

  .action-button {
    cursor: pointer;
    margin-right: 20px;
    font-size: 16px;
  }

  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: white, $alpha: 0.5);
  }
}

.anchor-modal-description {
  margin: 10px 0px;
}

.form-group-token-group-content {
  display: flex;
  flex-direction: column;
  padding: 4px 0 4px 8px;
  width: 100%;

  .token-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .link {
    margin-left: 8px;
    padding: 8px 0 6px;
    font-size: 14px;
  }

  .token-form-name {
    padding-bottom: 10px;
  }
}

.form-group-token-group-modal-form {
  .match-mode-choice-group {
    margin-top: 20px;

    :global(.ms-ChoiceFieldLabel) {
      white-space: pre-wrap;
    }

    :global(.ms-ChoiceField) {
      & + :global(.ms-ChoiceField) {
        margin-top: 25px;
      }
    }
  }
}

.form-group-fallback-form-content {
  padding: 15px 16px 15px 20px;
  border-bottom: 1px solid #edebe9;

  .title {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #201f1e;
    margin-bottom: 10px;
  }

  .link {
    font-size: 14px;
  }

  .action-button {
    font-size: 16px;
    margin-right: 20px;
    cursor: pointer;
  }

  .fallback-form-item {
    margin-left: 7px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}
