import { Text } from "@fluentui/react";
import React from "react";

import {
  AutoExtractionName,
  LLMCompletionResultWithPrompt,
  isAutoExtractionName,
  isLLMCompletionResultWithPrompt,
} from "../../models";
import styles from "./OCRTestReportTable.module.scss";

export type Renderer = React.FC<{ value: any }>;

const AutoExtractionNameRenderer: Renderer = (props: {
  value: AutoExtractionName;
}) => (
  <>
    <span className={styles["title"]}>{props.value.title}</span>
    {props.value.name}
  </>
);

const JSONRenderer: Renderer = (props: { value: any }) => (
  <pre>{JSON.stringify(props.value, null, 2)}</pre>
);

const TextRenderer: Renderer = (props: { value: any }) => (
  <>
    {props.value === undefined || props.value === null ? (
      <i>none</i>
    ) : (
      props.value.toString()
    )}
  </>
);

const LLMCompletionResultWithPromptRenderer: Renderer = (props: {
  value: LLMCompletionResultWithPrompt;
}) => (
  <>
    <pre className={styles["prompt"]}>{props.value.prompt}</pre>
    <ValueRenderer value={props.value.result} />
  </>
);

function getRenderer(value: any): Renderer {
  if (
    ["string", "number", "boolean"].includes(typeof value) ||
    value === null ||
    value === undefined
  ) {
    return TextRenderer;
  }

  if (isAutoExtractionName(value)) {
    return AutoExtractionNameRenderer;
  }

  if (isLLMCompletionResultWithPrompt(value)) {
    return LLMCompletionResultWithPromptRenderer;
  }

  return JSONRenderer;
}

export const ValueRenderer = (props: { value: any }) => {
  const { value } = props;
  const Renderer = getRenderer(value);
  return (
    <Text className={styles["body-text"]}>
      <Renderer value={value} />
    </Text>
  );
};
