.footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.footer-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
