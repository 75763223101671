import {
  DirectionalHint,
  ITooltipHostStyles,
  ITooltipProps,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { useLocale } from "../../contexts/locale";
import {
  ExtractAPIV2Document,
  ExtractV2TableValue,
  HiddenBoundingBoxIndices,
  accessHiddenBoundingBoxIndices,
} from "../../models";
import { accessNumberListBoundingBox } from "../../types/boundingBox";
import {
  ExtractDataFieldType,
  ExtractHTMLTableDataAccessor,
  ExtractListTableDataAccessor,
  ExtractListTableRowData,
} from "../../types/extractDataField";
import { CroppedImage } from "../CroppedImage";
import { ExtractDataField } from "../ExtractDataField";
import { ExtractTableDataBottomSheet } from "../ExtractTableDataBottomSheet";
import { ExtractTableDataType } from "../ExtractTableDataField";
import {
  ActionButton,
  ActionButtonStyleType,
} from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

type DetectedRegionButtonProps = {
  image: string;
  boundingBox?: number[];
};

function DetectedRegionButton(props: DetectedRegionButtonProps) {
  const { image, boundingBox } = props;

  const buttonId = useId("detected-region-button");

  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <div className={styles["callout-image-frame"]}>
        <CroppedImage src={image} boundingBox={boundingBox} />
      </div>
    ),
    maxWidth: "500px",
    styles: {
      root: {
        padding: "10px 10px 6px 10px",
      },
      content: {
        padding: 0,
      },
    },
    calloutProps: {
      isBeakVisible: false,
    },
  };

  const hostStyles: Partial<ITooltipHostStyles> = {
    root: {
      display: "inline-block",
    },
  };

  return (
    <TooltipHost
      tooltipProps={tooltipProps}
      delay={TooltipDelay.long}
      id={buttonId}
      directionalHint={DirectionalHint.rightTopEdge}
      styles={hostStyles}
    >
      <ActionButton
        id={buttonId}
        iconName="ImageSearch"
        textId="extract_api_v2_response.detected_region_button"
        styleType={ActionButtonStyleType.TableRow}
      />
    </TooltipHost>
  );
}

type ExtractAPIV2ResponseViewerProps = {
  response: ExtractAPIV2Document;
  sourceImage?: string;
  isItemImageVisible?: boolean;
  hiddenBoundingBoxIndices?: HiddenBoundingBoxIndices;
  onToggleBoundingBox?: (field: string, index: number) => void;
};

export function useExtractAPIV2ResponseViewerState(
  props: ExtractAPIV2ResponseViewerProps
) {
  const {
    response,
    sourceImage,
    isItemImageVisible,
    onToggleBoundingBox,
    hiddenBoundingBoxIndices,
  } = props;
  const { localized } = useLocale();

  const data = React.useMemo(() => {
    const detailedDataList = Object.entries(response.detailed_data ?? {});
    return detailedDataList.map(([label, object]) => {
      let itemImage = null as string | null | undefined;

      try {
        if (object.length === 0) {
          return {
            label,
            value: null,
            type: ExtractDataFieldType.Basic,
            boundingBox: null,
            itemImage,
          };
        }

        if (!Array.isArray(object)) {
          return {
            label,
            value: [],
            type: ExtractDataFieldType.Basic,
            boundingBox: null,
            itemImage,
          };
        }

        // Only process the first object
        const first = object[0];

        if (isItemImageVisible) {
          itemImage = first.image;
        }

        let type =
          first.extracted_by === "prebuilt.table_extraction"
            ? ExtractDataFieldType.HTMLTable
            : first.value_type === "scalar" || first.value_type === "dict"
            ? ExtractDataFieldType.Basic
            : first.value_type === "list_of_scalar"
            ? ExtractDataFieldType.List
            : ExtractDataFieldType.ListTable;

        // Post processing
        let { value, bounding_box: boundingBox } = first;
        if (type === ExtractDataFieldType.HTMLTable) {
          return {
            label,
            value: ExtractHTMLTableDataAccessor.fromExtractAPIV2ResponseValue(
              value as ExtractV2TableValue,
              true
            ),
            type,
            boundingBox,
            itemImage,
          };
        }

        if (type === ExtractDataFieldType.ListTable) {
          return {
            label,
            value: ExtractListTableDataAccessor.createFromRows(
              value as ExtractListTableRowData[]
            ).data,
            type,
            boundingBox,
          };
        }

        if (
          sourceImage !== undefined &&
          first.bounding_box != null &&
          [
            "prebuilt.signature",
            "prebuilt.portrait",
            "prebuilt.barcode",
            "prebuilt.detect_document_manipulation",
          ].includes(first.extracted_by)
        ) {
          value = object.map((item, index) => {
            const transformedBoundingBox = accessNumberListBoundingBox(
              response.bounding_box ?? [0, 0, 1, 1]
            ).subRegion(item.bounding_box ?? [0, 0, 1, 1]).data;

            const normalziedBoundingBox = accessNumberListBoundingBox(
              transformedBoundingBox
            ).toFixed(2).data;

            const text = [];
            if (item.extracted_by === "prebuilt.barcode") {
              text.push(
                localized("extract_api_v2_response.barcode_result", {
                  value: item.value as string,
                })
              );
            }

            if (item.extracted_by === "prebuilt.detect_document_manipulation") {
              text.push(
                localized(
                  "extract_api_v2_response.detect_document_manipulation_result",
                  {
                    value: item.value as string,
                  }
                )
              );
            }

            text.push(
              localized("extract_api_v2_response.boundingbox_result", {
                value: JSON.stringify(normalziedBoundingBox),
              })
            );

            return {
              imageUrl: sourceImage,
              boundingBox: transformedBoundingBox,
              value: text.join("\n\n"),
              onToggleBoundingBox: onToggleBoundingBox
                ? () => {
                    if (onToggleBoundingBox) {
                      onToggleBoundingBox(label, index);
                    }
                  }
                : undefined,
              isToggleBoundingBoxOn: onToggleBoundingBox
                ? !accessHiddenBoundingBoxIndices(
                    hiddenBoundingBoxIndices
                  ).includes(label, index)
                : undefined,
            };
          });
          type = ExtractDataFieldType.ListImage;

          boundingBox = undefined; // Don't show "Detected Region for ListImage type
        }

        return {
          label,
          value,
          type,
          boundingBox,
          itemImage,
        };
      } catch {
        return {
          label,
          value: undefined,
          type: ExtractDataFieldType.Basic,
          boundingBox: null,
          itemImage,
        };
      }
    });
  }, [
    sourceImage,
    localized,
    response.detailed_data,
    isItemImageVisible,
    response.bounding_box,
    onToggleBoundingBox,
    hiddenBoundingBoxIndices,
  ]);

  return React.useMemo(
    () => ({
      data,
      sourceImage,
      isItemImageVisible: isItemImageVisible ?? false,
    }),
    [data, sourceImage, isItemImageVisible]
  );
}

export function ExtractAPIV2ResponseViewerImpl(
  props: ReturnType<typeof useExtractAPIV2ResponseViewerState>
) {
  const { data, sourceImage, isItemImageVisible } = props;

  if (data.length === 0) {
    return (
      <div className={styles["placeholder"]}>
        <FormattedMessage id="extract_api_v2_response.empty" />
      </div>
    );
  }

  return (
    <div className={styles["container"]}>
      {data.map((datum, index) => {
        const { type, boundingBox } = datum;
        const actionItems = [];

        if (
          type === ExtractDataFieldType.ListTable ||
          type === ExtractDataFieldType.HTMLTable
        ) {
          actionItems.push(
            <ActionButton
              iconName="FullScreen"
              textId="extract_data_field.show_full_size_table"
              styleType={ActionButtonStyleType.TableRow}
              onClick={() => {
                ExtractTableDataBottomSheet.open(
                  datum.label,
                  datum.value,
                  type === ExtractDataFieldType.ListTable
                    ? ExtractTableDataType.ListTable
                    : ExtractTableDataType.HTMLTable
                );
              }}
            />
          );
        }

        if (boundingBox != null && sourceImage != null) {
          actionItems.push(
            <DetectedRegionButton
              image={sourceImage}
              boundingBox={boundingBox}
            />
          );
        } else if (isItemImageVisible && datum.itemImage != null) {
          actionItems.push(<DetectedRegionButton image={datum.itemImage} />);
        }

        const actions = (
          <div className={styles["action-button-bar"]}>
            {actionItems.map((item, index) => {
              return <React.Fragment key={index}>{item}</React.Fragment>;
            })}
          </div>
        );

        return (
          <ExtractDataField
            key={`${datum.label}/${index}`}
            label={datum.label}
            value={datum.value}
            type={datum.type}
            actions={actions}
          />
        );
      })}
    </div>
  );
}

export function ExtractAPIV2ResponseViewer(
  props: ExtractAPIV2ResponseViewerProps
) {
  const states = useExtractAPIV2ResponseViewerState(props);
  return <ExtractAPIV2ResponseViewerImpl {...states} />;
}
