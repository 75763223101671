import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";

import { apiClient } from "../apiClient";
import { FOCRError } from "../errors";
import { useAppDispatch } from "../hooks/redux";
import { SharePointSubscription } from "../types/workspace";
import { ensureFOCRError } from "../utils/errors";

export const CreatingWorkspaceSharePointSubscription = createAction(
  "workspace-integration/create-sharepoint-subscription"
);

export const CreateWorkspaceSharePointSubscriptionSuccess = createAction(
  "workspace-integration/create-sharepoint-subscription/success"
);

export const CreateWorkspaceSharePointSubscriptionFailed = createAction(
  "workspace-integration/create-sharepoint-subscription/failed",
  (error: FOCRError) => ({ payload: { error } })
);

export function useWorkspaceIntegrationActionCreator() {
  const dispatch = useAppDispatch();

  const createWorkspaceSharePointSubscription = useCallback(
    async (args: {
      workspaceId: string;
      siteUrl: string;
      listId: string;
      listItemId: string;
      refreshToken: string;
    }): Promise<SharePointSubscription> => {
      dispatch(CreatingWorkspaceSharePointSubscription());
      try {
        const result = await apiClient.createSharePointSubscription(
          args.workspaceId,
          args.siteUrl,
          args.listId,
          args.listItemId,
          args.refreshToken
        );

        dispatch(CreateWorkspaceSharePointSubscriptionSuccess());
        return result;
      } catch (e) {
        dispatch(
          CreateWorkspaceSharePointSubscriptionFailed(ensureFOCRError(e))
        );
        throw e;
      }
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      createWorkspaceSharePointSubscription,
    }),
    [createWorkspaceSharePointSubscription]
  );
}
