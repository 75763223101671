import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useLocalizeRegion } from "../../hooks/app";
import styles from "./styles.module.scss";

interface Props {
  region: string;
  name?: string;
  contactEmail?: string | null;
  lookupId?: string | null;
  createdAt?: Date;
  totalUsage?: number | null;
  lastUseAt?: Date | null;
}

export default function TeamInfo(props: Props) {
  const {
    name,
    contactEmail,
    lookupId,
    createdAt,
    totalUsage,
    lastUseAt,
    region,
  } = props;

  const localizeRegion = useLocalizeRegion();

  return (
    <div className={styles["team-info"]}>
      <div className={styles["team-icon"]}>
        <Icon iconName="People" />
      </div>
      <div className={styles["team-info-text-container"]}>
        <div>{name}</div>
        <div>{contactEmail}</div>
        <div className={styles["team-info-detail-columns"]}>
          <div className={styles["team-info-detail-column"]}>
            <div>
              <FormattedMessage
                id="team.detail.region"
                values={{ region: localizeRegion(region) }}
              />
            </div>
            {lookupId && (
              <div>
                <FormattedMessage
                  id="team.detail.lookup_id"
                  values={{ lookupId: lookupId }}
                />
              </div>
            )}
            {createdAt && (
              <div>
                <FormattedMessage
                  id="team.detail.created_at"
                  values={{
                    createdAt: `${createdAt.toLocaleDateString()} ${createdAt.toLocaleTimeString()}`,
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles["team-info-detail-column"]}>
            {totalUsage !== null && totalUsage !== undefined && (
              <div>
                <FormattedMessage
                  id="team.detail.total_usage"
                  values={{ totalUsage: totalUsage ?? 0 }}
                />
              </div>
            )}
            {lastUseAt && (
              <div>
                <FormattedMessage
                  id="team.detail.last_usage_at"
                  values={{
                    lastUsageAt: `${lastUseAt.toLocaleDateString()} ${lastUseAt.toLocaleTimeString()}`,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
