.anchor-inspector {
  margin: 10px 24px 10px 8px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .delete-button {
    margin: 5px 0px;
    font-size: 11px;
    align-self: flex-end;
  }
}
