import { createAction } from "@reduxjs/toolkit";
import { filter } from "lodash";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { FOCRError } from "../errors";
import { PendingState, useMergeableAsyncCallback } from "../hooks/common";
import { useAppDispatch } from "../hooks/redux";
import { RootState } from "../redux/types";
import {
  DocumentExportFlattenOption,
  PaginatedWorkspace,
  Workspace,
} from "../types/workspace";
import { ensureFOCRError } from "../utils/errors";

export const GetWorkspace = createAction(
  "workspace/get",
  (payload: { workspaceId: string }) => ({ payload })
);
export const GetWorkspaceSuccess = createAction(
  "workspace/get/success",
  (payload: { workspace: Workspace }) => ({ payload })
);

export const ListWorkspaces = createAction(
  "workspace/listing",
  (payload: { page: number; filter: string }) => ({ payload, filter })
);

export const CreateWorkspace = createAction(
  "workspace/create",
  (payload: { name: string; formId?: string; formGroupId?: string }) => ({
    payload,
  })
);

export const ListWorkspacesFailed = createAction<FOCRError>(
  "workspace/listFailed"
);

export const GotWorkspaces = createAction<PaginatedWorkspace>(
  "workspace/gotWorkspaces"
);

export const CreateWorkspaceSuccess = createAction(
  "workspace/create/success",
  (payload: {
    workspace: Workspace;
    formId?: string;
    formGroupId?: string;
  }) => ({ payload })
);

const listWorkspacesPendingState: PendingState<
  void,
  [
    {
      resourceOwnerId: string;
      page: number;
      size: number;
      extractorTypesFilter: string[];
    }
  ]
> = {
  isRunning: false,
  promises: [],
  params: null,
};

export const UpdateWorkspace = createAction(
  "workspace/update",
  (payload: {
    workspaceId: string;
    name: string;
    documentExportFlattenOption: DocumentExportFlattenOption;
  }) => ({
    payload,
  })
);
export const UpdateWorkspaceSuccess = createAction(
  "workspace/update/success",
  (payload: { workspace: Workspace }) => ({ payload })
);

export const DeleteWorkspaceSuccess = createAction(
  "workspace/delete/success",
  (payload: { workspaceId: string }) => ({ payload })
);

export const SetIsUploadWidgetCollapsed = createAction(
  "workspace/uploadWidget/setIsCollapsed",
  (payload: { isCollapsed: boolean }) => ({ payload })
);

export function useWorkspaceActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();

  const getWorkspace = useCallback(
    async (args: { workspaceId: string }) => {
      const workspace = await apiClient.getWorkspace({
        workspaceId: args.workspaceId,
      });
      dispatch(GetWorkspaceSuccess({ workspace }));
    },
    [dispatch]
  );

  const createWorkspace = useCallback(
    async (args: { name: string; formId?: string; formGroupId?: string }) => {
      const { resourceOwnerId } = getState().resourceOwner;
      if (!resourceOwnerId) {
        return Promise.reject();
      }

      const workspace = await apiClient.createWorkspace({
        resourceOwnerId,
        name: args.name,
        formId: args.formId,
        formGroupId: args.formGroupId,
      });

      dispatch(
        CreateWorkspaceSuccess({
          workspace,
          formId: args.formId,
          formGroupId: args.formGroupId,
        })
      );

      return workspace;
    },
    [dispatch, getState]
  );

  const listWorkspaces = useMergeableAsyncCallback(
    () => listWorkspacesPendingState,
    async ({
      resourceOwnerId,
      page,
      size,
      extractorTypesFilter,
    }: {
      resourceOwnerId: string;
      page: number;
      size: number;
      extractorTypesFilter: string[];
    }) => {
      const state = getState();
      const { lastListParams } = state.workspace;
      const hasFetchedOnce =
        Object.keys(state.workspace.paginatedWorkspaceByPage).length > 0;
      const isSameListParams =
        page === lastListParams?.page &&
        extractorTypesFilter.join(",") === (lastListParams?.filter || "") &&
        hasFetchedOnce;

      if (isSameListParams) {
        return;
      }

      dispatch(
        ListWorkspaces({ page, filter: extractorTypesFilter.join(",") })
      );

      const cachedResult =
        getState().workspace.paginatedWorkspaceByPage[page - 1];
      if (cachedResult) {
        dispatch(GotWorkspaces(cachedResult));
        return;
      }

      try {
        const result = await apiClient.listWorkspace({
          resourceOwnerId,
          offset: (page - 1) * size,
          size,
          extractorTypes: extractorTypesFilter,
        });

        dispatch(GotWorkspaces(result));
      } catch (e) {
        dispatch(ListWorkspacesFailed(ensureFOCRError(e)));
      }
    },
    [dispatch, getState]
  );

  const updateWorkspace = useCallback(
    async (args: {
      workspaceId: string;
      name: string;
      documentExportFlattenOption: DocumentExportFlattenOption;
    }) => {
      const workspace = await apiClient.updateWorkspace({
        workspaceId: args.workspaceId,
        name: args.name,
        documentExportFlattenOption: args.documentExportFlattenOption,
      });
      dispatch(UpdateWorkspaceSuccess({ workspace }));
      return workspace;
    },
    [dispatch]
  );

  const deleteWorkspace = useCallback(
    async (args: { workspaceId: string }) => {
      await apiClient.deleteWorkspaces({
        workspaceIds: [args.workspaceId],
      });
      dispatch(DeleteWorkspaceSuccess({ workspaceId: args.workspaceId }));
    },
    [dispatch]
  );

  const setIsUploadWidgetCollapsed = useCallback(
    (isCollapsed: boolean) => {
      dispatch(SetIsUploadWidgetCollapsed({ isCollapsed }));
    },
    [dispatch]
  );

  const toggleIsUploadWidgetCollapsed = useCallback(() => {
    dispatch(
      SetIsUploadWidgetCollapsed({
        isCollapsed: !getState().workspace.isUploadWidgetCollapsed,
      })
    );
  }, [dispatch, getState]);

  return useMemo(
    () => ({
      getWorkspace,
      createWorkspace,
      listWorkspaces,
      updateWorkspace,
      deleteWorkspace,
      setIsUploadWidgetCollapsed,
      toggleIsUploadWidgetCollapsed,
    }),
    [
      createWorkspace,
      deleteWorkspace,
      getWorkspace,
      listWorkspaces,
      updateWorkspace,
      setIsUploadWidgetCollapsed,
      toggleIsUploadWidgetCollapsed,
    ]
  );
}
