import { Dropdown, IDropdownOption, PrimaryButton } from "@fluentui/react";
import React from "react";

import { UserFeatureFlag } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { TeamDetail } from "../../types/team";
import {
  UserFeatureGroup,
  UserFeatureGroupAccessor,
} from "../../types/userFeature";
import UserFeatureFlagsChecboxList from "../UserFeatureFlagsChecboxList";
import styles from "./styles.module.scss";

interface Props {
  team: TeamDetail;
  region: string;
  onSetTeamFeatures?: (
    features: UserFeatureFlag[],
    enableAuditLog: boolean
  ) => Promise<void>;
}

function _TeamDetailEnabledFeatures(props: Props) {
  const { team, onSetTeamFeatures } = props;
  const { localized } = useLocale();

  const [enabledFeatures, setEnabledFeatures] = React.useState<
    UserFeatureFlag[]
  >(
    team.enabledAuditLog
      ? (team.enabledFeatures as UserFeatureFlag[]).concat([
          UserFeatureFlag.AuditLog,
        ])
      : (team.enabledFeatures as UserFeatureFlag[])
  );
  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  const availableFeatureGroup = React.useMemo(() => {
    return Object.values(UserFeatureGroup).map(flag => ({
      key: flag,
      text: localized(`team.detial.assigned_features.${flag}`),
    }));
  }, [localized]);

  const userFeatureGroup = React.useMemo(() => {
    return UserFeatureGroupAccessor.fromUserFeatureFlags(
      enabledFeatures as UserFeatureFlag[]
    );
  }, [enabledFeatures]);

  const onUserGroupChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option?.key && option?.key !== "") {
        const newFeatures = UserFeatureGroupAccessor.toUserFeatureFlags(
          option.key as UserFeatureGroup
        );
        setEnabledFeatures(newFeatures);
        setIsDirty(true);
      }
    },
    [setEnabledFeatures]
  );

  const save = React.useCallback(async () => {
    await onSetTeamFeatures?.(
      enabledFeatures.filter(flag => flag !== UserFeatureFlag.AuditLog),
      enabledFeatures.includes(UserFeatureFlag.AuditLog)
    );
    setIsDirty(false);
  }, [enabledFeatures, onSetTeamFeatures]);

  const onFeatureCheckboxClicked = React.useCallback(
    (feature: UserFeatureFlag, checked: boolean) => {
      setEnabledFeatures(prev => {
        return prev
          .filter(flag => flag !== feature)
          .concat(checked ? [feature] : []);
      });
      setIsDirty(true);
    },
    []
  );

  return (
    <div className={styles["container"]}>
      <div className={styles["enabled-features"]}>
        <Dropdown
          label={localized("team.detial.assigned_features.title")}
          className={styles["dropdown"]}
          options={availableFeatureGroup}
          selectedKey={userFeatureGroup}
          onChange={onUserGroupChange}
        />
        <UserFeatureFlagsChecboxList
          disableAllFeatures={true}
          enabledFeatures={enabledFeatures}
          userFeatureGroup={userFeatureGroup}
          onFeatureCheckboxClicked={onFeatureCheckboxClicked}
        />
      </div>
      <div className={styles["buttons"]}>
        <PrimaryButton
          type="button"
          text={localized("common.save")}
          disabled={!isDirty}
          onClick={save}
        />
      </div>
    </div>
  );
}

export const TeamDetailEnabledFeatures = React.memo(_TeamDetailEnabledFeatures);
export default TeamDetailEnabledFeatures;
