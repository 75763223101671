export class UploadFileKeeperService {
  private static instance: UploadFileKeeperService;

  static getInstance() {
    if (!this.instance) {
      this.instance = new UploadFileKeeperService();
    }
    return this.instance;
  }

  private files = new Map<string, File>();
  private metadata = new Map<string, any>();

  setFile(key: string, file: File, metadata?: any) {
    this.files.set(key, file);
    this.metadata.set(key, metadata);
  }

  getFile(key: string): File | undefined {
    return this.files.get(key);
  }

  getMetadata(key: string): any | undefined {
    return this.metadata.get(key);
  }

  hasFile(key: string): boolean {
    return this.files.has(key);
  }

  deleteFile(key: string) {
    this.files.delete(key);
    this.metadata.delete(key);
  }
}
