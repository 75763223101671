import * as yup from "yup";

export const briefWorkerTokenSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
    isRevoked: yup.boolean().defined(),
  })
  .camelCase();

export type BriefWorkerToken = yup.InferType<typeof briefWorkerTokenSchema>;

export const workerTokenSchema = briefWorkerTokenSchema
  .shape({
    token: yup.string().defined(),
  })
  .camelCase();

export const workerTokenRespSchema = yup
  .object({
    token: workerTokenSchema.defined(),
  })
  .camelCase();
export type WorkerTokenResp = yup.InferType<typeof workerTokenRespSchema>;

export type WorkerToken = yup.InferType<typeof workerTokenSchema>;

export const workerTokenListSchema = yup
  .object({
    tokens: yup.array(workerTokenSchema.defined()).defined(),
  })
  .camelCase();

export type WorkerTokenList = yup.InferType<typeof workerTokenListSchema>;
