@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.grid-item {
  @include ms-depth-4;

  display: inline-block;
  width: 282px;
  height: 368px;
  border-radius: 10px;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;

  .image-preview {
    > img,
    > div {
      margin: 16px;
      width: 250px;
      height: 250px;
    }

    > img {
      object-fit: contain;
      cursor: pointer;
    }
  }

  .checkbox {
    top: 16px;
    left: 16px;
    position: absolute;
    background-color: white;

    :global(.ms-Checkbox-checkbox) {
      margin-right: 0px;
    }
  }

  .label {
    width: 90%;
    margin-left: 16px;
    margin-right: 16px;
    height: 40px;
    position: absolute;
    top: 278px;
    text-align: center;
    overflow: hidden;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #201f1e;
  }

  .date {
    position: absolute;
    top: 330px;

    left: 50%;
    transform: translate(-50%, 0%);

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #605e5c;
  }
}

.deleting {
  opacity: 0.5;
  img {
    filter: grayscale(1);
  }
}

.deleting-spinner {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 16px;
}
