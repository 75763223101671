import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import * as receiptGroupAction from "../actions/receiptGroup";
import * as resourceOwnerAction from "../actions/resourceOwner";
import * as teamAction from "../actions/team";
import * as userAction from "../actions/user";
import { BriefForm, BriefFormWithType } from "../types/form";
import { PageInfo } from "../types/pageInfo";
import { ReceiptGroup, ReceiptGroupWithType } from "../types/receiptGroup";

export interface ReceiptGroupState {
  readonly receiptGroups: ReceiptGroupWithType[];
  readonly forms: BriefFormWithType[];
  readonly areReceiptGroupsFetched: boolean;
  readonly pageInfo?: PageInfo;
  readonly isFetching: boolean;
  readonly currentReceiptGroup?: ReceiptGroupWithType | BriefFormWithType;
}

const defaultState: ReceiptGroupState = {
  receiptGroups: [],
  forms: [],
  areReceiptGroupsFetched: false,
  isFetching: false,
};

function addTypeToReceiptGroup(
  receiptGroup: ReceiptGroup
): ReceiptGroupWithType {
  return {
    ...receiptGroup,
    type: "receipt_group",
  };
}

function addTypeToForm(form: BriefForm): BriefFormWithType {
  return {
    ...form,
    type: "form",
  };
}

export const receiptGroupReducer = createReducer<ReceiptGroupState>(
  defaultState,
  builder => {
    builder
      .addCase(receiptGroupAction.FetchReceiptGroupListStarted, state => {
        state.isFetching = true;
      })
      .addCase(receiptGroupAction.GotReceiptGroupList, (state, action) => {
        const { receiptGroups, forms, pageInfo } = action.payload;

        state.areReceiptGroupsFetched = true;
        state.pageInfo = pageInfo;
        state.isFetching = false;

        receiptGroups.forEach(receiptGroup => {
          const receiptGroupWithType = addTypeToReceiptGroup(receiptGroup);
          const existingReceiptGroupIndex = state.receiptGroups.findIndex(
            x => x.id === receiptGroup.id
          );
          if (existingReceiptGroupIndex >= 0) {
            state.receiptGroups[existingReceiptGroupIndex] =
              receiptGroupWithType;
          } else {
            state.receiptGroups.push(receiptGroupWithType);
          }
        });

        forms.forEach(form => {
          const formWithType = addTypeToForm(form);
          const existingFormIndex = state.forms.findIndex(
            x => x.id === form.id
          );
          if (existingFormIndex >= 0) {
            state.forms[existingFormIndex] = formWithType;
          } else {
            state.forms.push(formWithType);
          }
        });
      })
      .addCase(receiptGroupAction.ReceiptGroupSelected, (state, action) => {
        state.currentReceiptGroup = action.payload;
      })
      .addCase(receiptGroupAction.FirstReceiptGroupSelected, state => {
        state.currentReceiptGroup = state.forms[0] || state.receiptGroups[0];
      })
      .addCase(receiptGroupAction.ReceiptGroupCreated, (state, action) => {
        const form = addTypeToForm(action.payload);
        state.forms.unshift(form);
        state.currentReceiptGroup = form;
      })
      .addCase(receiptGroupAction.GotReceiptGroup, (state, action) => {
        state.currentReceiptGroup = addTypeToReceiptGroup(action.payload);
      })
      .addCase(receiptGroupAction.ReceiptGroupRemoved, (state, action) => {
        state.receiptGroups = state.receiptGroups.filter(
          x => x.id !== action.payload.receiptGroupId
        );
        if (state.currentReceiptGroup?.id === action.payload.receiptGroupId) {
          state.currentReceiptGroup = undefined;
        }
      })
      .addCase(receiptGroupAction.ReceiptFormRemoved, (state, action) => {
        state.forms = state.forms.filter(x => x.id !== action.payload.formId);
        if (state.currentReceiptGroup?.id === action.payload.formId) {
          state.currentReceiptGroup = undefined;
        }
      })
      .addCase(receiptGroupAction.UpdatedReceiptGroup, (state, action) => {
        const receiptGroupWithType = addTypeToReceiptGroup(action.payload);
        state.currentReceiptGroup = receiptGroupWithType;
        state.receiptGroups = state.receiptGroups.map(x =>
          x.id === receiptGroupWithType.id ? receiptGroupWithType : x
        );
      })
      .addCase(receiptGroupAction.UpdatedReceiptForm, (state, action) => {
        const receiptFormWithType = addTypeToForm(action.payload);
        state.currentReceiptGroup = receiptFormWithType;
        state.forms = state.forms.map(x =>
          x.id === receiptFormWithType.id ? receiptFormWithType : x
        );
      })
      .addCase(teamAction.TeamUserRemoved, (state, action) => {
        const { removedUserId, currentUser } = action.payload;
        if (removedUserId === currentUser.id) {
          return {
            ...defaultState,
          };
        } else {
          return state;
        }
      })
      .addMatcher(
        isAnyOf(
          userAction.UserLogin,
          userAction.UserLogout,
          resourceOwnerAction.SelectTeam,
          teamAction.CreateTeam,
          teamAction.TeamInvitationAccepted,
          teamAction.TeamDeleted
        ),
        _ => ({ ...defaultState })
      );
  }
);
