import { Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useTeamPermission } from "../../hooks/permission";
import { ExtractorOptionForCombinedExtractor } from "../../types/extractor";
import {
  DetailFormGroup,
  FormGroupAnchorBase,
  FormGroupDocumentDetectionBase,
  FormGroupTokenGroupBase,
} from "../../types/formGroup";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import { FormGroupAnchorList } from "./FormGroupAnchorList";
import { FormGroupDocumentDetectionList } from "./FormGroupDocumentDetectionList";
import { FormGroupFallbackForm } from "./FormGroupFallbackForm";
import { FormGroupTokenGroupList } from "./FormGroupTokenGroupList";
import styles from "./styles.module.scss";

interface Props {
  formGroup: DetailFormGroup;
  extractorOptions: ExtractorOptionForCombinedExtractor[];
  onExport: () => void;
  onOpenFormGroupScriptModal: () => void;
  onUpdateAnchors: (anchors: FormGroupAnchorBase[]) => Promise<void>;
  onUpdateTokenGroups: (
    tokenGroups: FormGroupTokenGroupBase[]
  ) => Promise<void>;
  onUpdateDocumentDetections: (
    documentDetections: FormGroupDocumentDetectionBase[]
  ) => Promise<void>;
  onUpdateFallbackForm: (formId: string | null) => Promise<void>;
}

const FormGroupEditor = React.memo((props: Props) => {
  const {
    formGroup,
    extractorOptions,
    onExport,
    onUpdateAnchors,
    onUpdateTokenGroups,
    onOpenFormGroupScriptModal,
    onUpdateDocumentDetections,
    onUpdateFallbackForm,
  } = props;

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["form-group-editor"]}>
      <div className={styles["buttons"]}>
        <DefaultButton textId="form_group_editor.export" onClick={onExport} />
        {hasPermissionToEditResource && (
          <DefaultButton
            textId="form_group_editor.edit_post_process_script"
            onClick={onOpenFormGroupScriptModal}
          />
        )}
      </div>
      <h1>
        <FormattedMessage
          id={
            formGroup.type === "document_detection"
              ? "form_group_editor.rules"
              : "form_group_editor.extractors"
          }
        />
      </h1>
      <Text className={styles["description"]}>
        <FormattedMessage id={`form_group_${formGroup.type}_editor.desc`} />
      </Text>
      <div className={styles["section"]}>
        {formGroup.type === "anchor" && (
          <FormGroupAnchorList
            formGroup={formGroup}
            extractorOptions={extractorOptions}
            onUpdateAnchors={onUpdateAnchors}
          />
        )}
        {formGroup.type === "token" && (
          <FormGroupTokenGroupList
            formGroup={formGroup}
            extractorOptions={extractorOptions}
            onUpdateTokenGroups={onUpdateTokenGroups}
          />
        )}
        {formGroup.type === "document_detection" && (
          <FormGroupDocumentDetectionList
            formGroup={formGroup}
            extractorOptions={extractorOptions}
            onUpdateDocumentDetections={onUpdateDocumentDetections}
          />
        )}
        {formGroup.type !== "document_detection" && (
          <FormGroupFallbackForm
            formGroup={formGroup}
            extractorOptions={extractorOptions}
            onUpdateFallbackForm={onUpdateFallbackForm}
          />
        )}
      </div>
    </div>
  );
});

export default FormGroupEditor;
