import { IconButton, Spinner, SpinnerSize } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React, { ReactElement } from "react";

import styles from "./styles.module.scss";

interface WorkspaceDocumentBottomBarProps {
  className?: string;
  currentPage: number;
  totalPage: number;
  onClickPrevPage: () => void;
  onClickNextPage: () => void;
  processingCount: number;
}

const WorkspaceDocumentBottomBar = (
  props: WorkspaceDocumentBottomBarProps
): ReactElement => {
  const {
    className,
    currentPage,
    totalPage,
    onClickPrevPage,
    onClickNextPage,
    processingCount,
  } = props;
  const _onClickPrevPage = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    onClickPrevPage();
  };
  const _onClickNextPage = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    onClickNextPage();
  };

  return (
    <div className={cn(styles.bottomBar, className)}>
      {processingCount > 0 ? (
        <div className={styles.processing}>
          <Spinner size={SpinnerSize.small} />
          <p className={styles.processingText}>
            <FormattedMessage
              id="workspace.document.bottom_bar.pagination.processing_count"
              values={{ count: processingCount }}
            />
          </p>
        </div>
      ) : (
        <div />
      )}
      <div className={styles.pagination}>
        <p className={styles.pageNumber}>
          <FormattedMessage
            id="workspace.document.bottom_bar.pagination.page_number"
            values={{
              current: currentPage,
              total: totalPage,
            }}
          />
        </p>
        <div className={styles.paginationButtonGroup}>
          <IconButton
            className={styles.paginationButton}
            iconProps={{
              iconName: "ChevronLeft",
            }}
            styles={{
              icon: {
                color: "#A19F9D",
              },
            }}
            onClick={_onClickPrevPage}
            disabled={currentPage === 1}
          />
          <IconButton
            className={styles.paginationButton}
            iconProps={{
              iconName: "ChevronRight",
            }}
            styles={{
              icon: {
                color: "#A19F9D",
              },
            }}
            onClick={_onClickNextPage}
            disabled={currentPage === totalPage}
          />
        </div>
      </div>
    </div>
  );
};

export default WorkspaceDocumentBottomBar;
