@import "../../styles/variables.scss";

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
}

.footer {
  text-align: right;
  margin-top: 20px;
}

.login-change-password-form {
  position: absolute;
  width: 350px;

  top: 50vh;
  left: 50vw;
  margin-top: -176px;
  margin-left: -175px;

  @media only screen and (max-width: 350px) {
    width: calc(100% - 20px); // stylelint-disable-line
    margin-left: 10px;
    margin-right: 10px;
    left: 0;
  }
}
