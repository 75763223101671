import { CustomModelLabelSchema } from "../types/customModel";

export function getLabelSchemaDisplayName(labelSchema: CustomModelLabelSchema) {
  return labelSchema.name.replace(/^field\./, "").replace(/\.layout$/, "");
}

export function freezeLabelSchema(
  labelSchema: CustomModelLabelSchema
): CustomModelLabelSchema {
  return {
    ...labelSchema,
    name:
      labelSchema.type !== "region"
        ? `field.${labelSchema.name}`
        : `${labelSchema.name}.layout`,
  };
}
