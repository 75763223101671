import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";

import { apiClient } from "../apiClient";
import { TEMPLATES_TO_HIDE, TEMPLATE_ORDERINGS } from "../constants";
import { useAppDispatch } from "../hooks/redux";
import { Template } from "../types/template";

export const GotTemplateList = createAction<Template[]>(
  "template/gotTemplateList"
);

function sortTemplates(templates: Template[]): Template[] {
  const output: Template[] = [];
  const templateMap = new Map<string, Template>(
    templates
      .filter(x => !TEMPLATES_TO_HIDE.includes(x.name))
      .map(x => [x.name, x])
  );
  TEMPLATE_ORDERINGS.forEach(group => {
    group.forEach(templateName => {
      const template = templateMap.get(templateName);
      if (template) {
        output.push(template);
        templateMap.delete(templateName);
      }
    });
  });

  return [...templateMap.values()].concat(output);
}

export function useTemplateActionCreator() {
  const dispatch = useAppDispatch();
  const listTemplate = useCallback(async () => {
    const templates = await apiClient.listTemplates();
    dispatch(GotTemplateList(sortTemplates(templates)));
  }, [dispatch]);

  return useMemo(() => ({ listTemplate }), [listTemplate]);
}

export type TemplateAction = ReturnType<typeof GotTemplateList>;
