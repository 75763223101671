import { v4 as uuidv4 } from "uuid";

import * as constants from "../constants";
import { DetectionRegionFieldEngine } from "../types/detectionRegion";
import { deepClone } from "../utils/deepClone";
import { getDefaultDetectionRegionConfig } from "../utils/detectionRegion";
import { BaseInteractionHandler } from "./handler";

interface Params {
  onCreateDetectionRegion: (detectionRegionId: string) => void;
  engineByFieldTypeMap: {
    [x: string]: DetectionRegionFieldEngine[] | undefined;
  };
}

export class DetectionRegionToolHandler extends BaseInteractionHandler {
  engineByFieldTypeMap: {
    [x: string]: DetectionRegionFieldEngine[] | undefined;
  };

  onCreateDetectionRegion: (detectionRegionId: string) => void;

  constructor(params: Params) {
    super();
    this.engineByFieldTypeMap = params.engineByFieldTypeMap;
    this.onCreateDetectionRegion = params.onCreateDetectionRegion;
  }

  handleMouseDown = (e: MouseEvent) => {
    if (!this.store) return;

    const cursorCoord = this.getCoord(e);
    this.store.setCreatingDetectionRegion({
      id: uuidv4(),
      bbox: {
        left: cursorCoord.x,
        top: cursorCoord.y,
        right: cursorCoord.x + constants.MIN_DETECTION_REGION,
        bottom: cursorCoord.y + constants.MIN_DETECTION_REGION,
      },
      config: deepClone(getDefaultDetectionRegionConfig()),
    });
  };

  handleMouseMove = (e: MouseEvent) => {
    if (!this.store) return;

    const cursorCoord = this.getCoord(e);
    const { creatingDetectionRegion } = this.store;

    if (creatingDetectionRegion) {
      this.store.setCreatingDetectionRegion({
        ...creatingDetectionRegion,
        bbox: {
          ...creatingDetectionRegion.bbox,
          right: Math.max(
            creatingDetectionRegion.bbox.left + constants.MIN_DETECTION_REGION,
            cursorCoord.x
          ),
          bottom: Math.max(
            creatingDetectionRegion.bbox.top + constants.MIN_DETECTION_REGION,
            cursorCoord.y
          ),
        },
      });
    }
  };

  handleMouseUp = (_e: MouseEvent) => {
    if (!this.store) return;
    if (this.store.creatingDetectionRegion) {
      const newRegion = this.store.creatingDetectionRegion;
      this.store.upsertDetectionRegion(newRegion);
      this.store.setCreatingDetectionRegion(undefined);
      this.onCreateDetectionRegion(newRegion.id);
    }
  };

  cursorStyle = () => {
    return "crosshair";
  };
}
