import React, { useEffect } from "react";

import { ListTeamOptions } from "../apiClient/mixin/team";
import { TeamListLayout } from "../components/TeamList";
import { getRegionKeys } from "../config";
import { useFetchAllRegionTeams } from "../hooks/admin";
import { useFetchPlans, useLocalizeRegion } from "../hooks/app";
import { useAppSelector } from "../hooks/redux";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { AdminRegionTeamListState } from "../reducers/admin";
import {
  getOffsetByPage,
  getOffsetOfBeginningOfPage,
  getPageByOffset,
} from "../utils/pagination";
import { isUUID } from "../utils/uuid";

const PAGE_SIZE = 20;

type TeamsStateByRegion = {
  [key: string]: AdminRegionTeamListState;
};

function getFilterOptions(
  searchText?: string,
  fieldToSearch?: string,
  selectedPlan?: string
): ListTeamOptions {
  const filterOptions: ListTeamOptions = {
    searchString: searchText === "" ? undefined : searchText,
    fieldToSearch: fieldToSearch === "" ? undefined : fieldToSearch,
  };
  if (selectedPlan !== undefined && selectedPlan !== "") {
    if (isUUID(selectedPlan)) {
      filterOptions.planId = selectedPlan;
    } else {
      filterOptions.plan = selectedPlan;
    }
  }
  return filterOptions;
}

function getInitialOffsetFromState(
  region: string,
  teamsStateByRegion: TeamsStateByRegion,
  fallbackValue: number = 0
): number {
  const teamsState = teamsStateByRegion[region];
  const currentOffset = teamsState?.pageInfo?.offset;

  if (currentOffset) {
    return getOffsetOfBeginningOfPage(currentOffset, PAGE_SIZE);
  }

  return fallbackValue;
}

function _AdminTeamListContainer() {
  const plans = useAppSelector(state => state.app.plans);
  const { teamsStateByRegion, activeRegion, teamsListOptions } = useAppSelector(
    state => state.admin.teamList
  );

  const { getParam, removeParam, setParams, setParam } = useSearchParamUtils();

  const [regionKey, setRegionKey] = React.useState(
    activeRegion ?? getParam(URLParamsKey.region) ?? getRegionKeys()[0]
  );
  const localizeRegion = useLocalizeRegion();

  const getRegionLabel = React.useCallback(
    regionKey => {
      const isFetching = teamsStateByRegion[regionKey]?.isFetching ?? false;
      const totalCount =
        teamsStateByRegion[regionKey]?.pageInfo?.totalCount ?? 0;
      return `${localizeRegion(regionKey)} (${
        isFetching && totalCount === 0 ? "..." : totalCount
      })`;
    },
    [localizeRegion, teamsStateByRegion]
  );

  const regionOptions = React.useMemo(
    () =>
      getRegionKeys().map(r => ({
        key: r,
        label: getRegionLabel(r),
      })),
    [getRegionLabel]
  );
  const [fieldToSearch, setFieldToSearch] = React.useState<string>(
    teamsListOptions?.fieldToSearch ?? getParam(URLParamsKey.filterType) ?? ""
  );
  const [searchText, setSearchText] = React.useState<string | undefined>(
    teamsListOptions?.searchString ?? getParam(URLParamsKey.search) ?? undefined
  );
  const [selectedPlan, setSelectedPlan] = React.useState<string>(
    teamsListOptions?.planId ??
      teamsListOptions?.plan ??
      getParam(URLParamsKey.plan) ??
      ""
  );
  const [filterOptions, setFilterOptions] = React.useState<ListTeamOptions>(
    getFilterOptions(searchText, fieldToSearch, selectedPlan)
  );
  const [offset, setOffset] = React.useState<number>(
    getInitialOffsetFromState(
      regionKey,
      teamsStateByRegion,
      getOffsetByPage(parseInt(getParam(URLParamsKey.page) ?? "1"), PAGE_SIZE)
    )
  );

  const onSubmitSearch = React.useCallback(() => {
    setOffset(0);
    setFilterOptions(getFilterOptions(searchText, fieldToSearch, selectedPlan));
  }, [searchText, selectedPlan, fieldToSearch]);

  const { refetchRegionTeamsWithOffset } = useFetchAllRegionTeams(
    filterOptions,
    PAGE_SIZE,
    offset,
    regionKey
  );

  const onNavigateToPage = React.useCallback(
    (page: number) => {
      const newOffset = getOffsetByPage(page, PAGE_SIZE);
      setOffset(newOffset);
      if (page === 1) {
        removeParam(URLParamsKey.page);
      } else {
        setParam(URLParamsKey.page, page.toString());
      }
      refetchRegionTeamsWithOffset(regionKey, newOffset);
    },
    [regionKey, refetchRegionTeamsWithOffset, setParam, removeParam]
  );

  const onRegionKeyChange = React.useCallback(
    (newRegionKey: string) => {
      if (newRegionKey === regionKey) {
        return;
      }

      setRegionKey(newRegionKey);

      const teamsState = teamsStateByRegion[newRegionKey];
      const newOffset = teamsState?.pageInfo?.offset ?? 0;

      if (newOffset > PAGE_SIZE) {
        setOffset(newOffset);
      } else {
        // Fetch first page
        setOffset(0);
        refetchRegionTeamsWithOffset(newRegionKey, 0);
      }
    },
    [regionKey, teamsStateByRegion, refetchRegionTeamsWithOffset]
  );

  // Update URL params if needed
  useEffect(() => {
    const page = getPageByOffset(offset, PAGE_SIZE);
    setParams(
      new Map([
        [URLParamsKey.page, page === 1 ? undefined : page.toString()],
        [
          URLParamsKey.region,
          activeRegion === getRegionKeys()[0] ? undefined : activeRegion,
        ],
        [URLParamsKey.search, teamsListOptions?.searchString],
        [URLParamsKey.filterType, teamsListOptions?.fieldToSearch],
        [URLParamsKey.plan, teamsListOptions?.planId ?? teamsListOptions?.plan],
      ])
    );
  }, [activeRegion, teamsListOptions, offset, setParams]);

  useFetchPlans();

  return (
    <TeamListLayout
      regionOptions={regionOptions}
      teamsStateByRegion={teamsStateByRegion}
      plans={plans}
      offset={offset}
      pageSize={PAGE_SIZE}
      regionKey={regionKey}
      setRegionKey={onRegionKeyChange}
      searchText={searchText}
      setSearchText={setSearchText}
      selectedPlan={selectedPlan}
      setSelectedPlan={setSelectedPlan}
      onSubmitSearch={onSubmitSearch}
      onNavigateToPage={onNavigateToPage}
      fieldToSearch={fieldToSearch}
      setFieldToSearch={setFieldToSearch}
    />
  );
}

export const AdminTeamListContainer = React.memo(_AdminTeamListContainer);
export default AdminTeamListContainer;
