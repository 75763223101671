.formatter-step-card {
  background-color: #fff;
  width: 248px;
  max-width: 248px;
  min-height: 94px;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    border: 1px solid #25d0b1;
    border-radius: 5px;
  }

  .title {
    padding-top: 14px;
    margin-left: 14px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #323130;
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #a19f9d;
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 4px;
    margin-bottom: 14px;
  }
}

.formatter-step-selector {
  width: 550px;
  height: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

  .heading {
    padding: 26px 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #323130;
    border-bottom: 1px solid #edebe9;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    margin: 24px 20px;
  }
}
