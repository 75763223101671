import {
  DefaultButton,
  Icon,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { DateTime } from "luxon";
import React from "react";

import { CustomModelVersion } from "../../types/customModel";
import InfoNote from "../InfoNote";
import styles from "./styles.module.scss";

enum ModelVersionSortBy {
  createdAtAsc,
  createdAtDesc,
}

interface ModelVersionTableProps {
  modelVersions: CustomModelVersion[];
  isDeploymentInProgress: boolean;
  deployModelVersion: (modelVersionTag: string) => void;
}

function ModelVersionTable(props: ModelVersionTableProps) {
  const { modelVersions, isDeploymentInProgress, deployModelVersion } = props;

  const [sortBy, setSortBy] = React.useState(ModelVersionSortBy.createdAtDesc);

  const onCreatedAtHeaderClicked = React.useCallback(() => {
    setSortBy(x =>
      x === ModelVersionSortBy.createdAtDesc
        ? ModelVersionSortBy.createdAtAsc
        : ModelVersionSortBy.createdAtDesc
    );
  }, []);

  const sortingFn = React.useCallback(
    (a: CustomModelVersion, b: CustomModelVersion): number => {
      switch (sortBy) {
        case ModelVersionSortBy.createdAtDesc:
          return b.createdAt.getTime() - a.createdAt.getTime();
        case ModelVersionSortBy.createdAtAsc:
          return a.createdAt.getTime() - b.createdAt.getTime();
      }

      return a.createdAt.getTime() - b.createdAt.getTime();
    },
    [sortBy]
  );

  return (
    <table className={styles["model-version-table"]}>
      <thead>
        <tr>
          <th className={styles["tag-column"]}>
            <FormattedMessage id="custom_model_model.model_version" />
          </th>
          <th className={styles["created-at-column"]}>
            <span onClick={onCreatedAtHeaderClicked}>
              <FormattedMessage id="custom_model_model.created_at" />
              {sortBy === ModelVersionSortBy.createdAtAsc ? (
                <Icon iconName="SortUp" />
              ) : (
                <Icon iconName="SortDown" />
              )}
            </span>
          </th>
          <th className={styles["labels-column"]}>
            <FormattedMessage id="custom_model_model.labels" />
          </th>
          <th className={styles["actions-column"]}>
            <FormattedMessage id="custom_model_model.actions" />
          </th>
        </tr>
      </thead>
      <tbody>
        {[...modelVersions].sort(sortingFn).map(modelVersion => (
          <tr key={modelVersion.tag}>
            <td className={styles["tag-column"]}>{modelVersion.tag}</td>
            <td className={styles["created-at-column"]}>
              {DateTime.fromJSDate(modelVersion.createdAt).toFormat(
                "dd/LL/yyyy HH:mm"
              )}
            </td>
            <td className={styles["labels-column"]}>
              <div
                title={
                  modelVersion.fields ? modelVersion.fields.join(", ") : ""
                }
              >
                <div>
                  {modelVersion.fields ? modelVersion.fields.join(", ") : ""}
                </div>
              </div>
            </td>
            <td
              className={styles["actions-column"]}
              data-tag={modelVersion.tag}
            >
              {modelVersion.isInTraining ? (
                <DefaultButton disabled={true}>
                  <>
                    <Spinner size={SpinnerSize.small} />
                    <FormattedMessage id="custom_model_model.button.model_training" />
                  </>
                </DefaultButton>
              ) : modelVersion.isActive ? (
                isDeploymentInProgress ? (
                  <DefaultButton disabled={true}>
                    <>
                      <Spinner size={SpinnerSize.small} />
                      <FormattedMessage id="custom_model_model.button.deploying" />
                    </>
                  </DefaultButton>
                ) : (
                  <PrimaryButton
                    className={styles["no-interaction"]}
                    iconProps={{ iconName: "CheckMark" }}
                  >
                    <FormattedMessage id="custom_model_model.button.active_model" />
                  </PrimaryButton>
                )
              ) : (
                <DefaultButton
                  disabled={isDeploymentInProgress}
                  onClick={() => deployModelVersion(modelVersion.tag)}
                >
                  <FormattedMessage id="custom_model_model.button.set_active" />
                </DefaultButton>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

type Props = ModelVersionTableProps;

export default function CustomModelModel(props: Props) {
  return (
    <div className={styles["container"]}>
      <ModelVersionTable {...props} />
      <div className={styles["info"]}>
        <InfoNote
          notes={["custom_model_model.note1", "custom_model_model.note2"]}
          variant={"medium"}
        />
      </div>
    </div>
  );
}
