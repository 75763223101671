import { Theme } from "@glideapps/glide-data-grid";

import { HeaderGroupKey } from "../../types/advancedTokenSetup/table";

export function getHeaderGroupTheme(groupName: string): Partial<Theme> {
  switch (groupName) {
    case HeaderGroupKey.ReturningTags:
    case HeaderGroupKey.TagGroup:
    case HeaderGroupKey.RowOrder:
      return {
        bgHeader: "#E6F7F4",
        bgHeaderHovered: "#E6F7F4",
        bgHeaderHasFocus: "#E6F7F4",
      };
    default:
      return {
        bgHeader: "#EFF6FC",
        bgHeaderHovered: "#EFF6FC",
        bgHeaderHasFocus: "#EFF6FC",
      };
  }
}

const headerBaseStyle: Partial<Theme> = {
  headerFontStyle: "600 12px",
};

export function getHeaderTheme(groupName: string): Partial<Theme> {
  switch (groupName) {
    case HeaderGroupKey.ReturningTags:
    case HeaderGroupKey.TagGroup:
    case HeaderGroupKey.RowOrder:
      return {
        ...headerBaseStyle,
        ...getHeaderGroupTheme(groupName),
      };
    default:
      return {
        ...headerBaseStyle,
        ...getHeaderGroupTheme(groupName),
      };
  }
}

export const baseTheme: Partial<Theme> = {
  accentColor: "#25D0B1",
  borderColor: "#EDEBE9",
  textDark: "#201F1E",
  textHeader: "#201F1E",
  textGroupHeader: "#201F1E",
  headerFontStyle: "10px",
  baseFontStyle: "12px",
  bgIconHeader: "#201F1E",
  cellVerticalPadding: 10,
  cellHorizontalPadding: 10,
};
