import * as React from "react";
import { useNavigate } from "react-router";

import { TeamUsageItem } from "../../types/team";
import styles from "./styles.module.scss";

interface Props {
  teamUsage: TeamUsageItem;
  key: number;
  regionKey: string;
}

export function _TeamUsageTableRow(props: Props) {
  const { teamUsage, regionKey } = props;
  const { team, usageCount } = teamUsage;
  const navigate = useNavigate();

  const onRowClick = React.useCallback(() => {
    navigate(`/admin/team/${regionKey}/${team.id}`);
  }, [navigate, regionKey, team.id]);

  return (
    <tr onClick={onRowClick} className={styles["team-usage-table-row"]}>
      <td>
        <div className={styles["basic-info"]}>
          <div className={styles["name"]}>{team.name}</div>
          <div className={styles["lookup-id"]}>{team.lookupId}</div>
        </div>
      </td>
      <td>
        <div className={styles["detail-info"]}>{team.contactEmail}</div>
      </td>
      <td>
        <div className={styles["detail-info"]}>
          {team.createdAt.toLocaleDateString()}{" "}
          {team.createdAt.toLocaleTimeString()}
        </div>
      </td>
      <td>
        <div className={styles["detail-info"]}>{usageCount}</div>
      </td>
    </tr>
  );
}

export const TeamUsageTableRow = React.memo(_TeamUsageTableRow);
export default TeamUsageTableRow;
