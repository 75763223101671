import { DefaultButton, IButtonProps, PrimaryButton } from "@fluentui/react";
import * as React from "react";

import { SUPPORTED_IMAGE_MIME } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { chooseFile } from "../../utils/file";
import { DangerButton } from "../DangerButton";

interface Props extends Omit<IButtonProps, "text"> {
  textId: string;
  accept?: string;
  onFiles?: (files?: File[]) => void;
  isMultiple?: boolean;
}

function FileButtonWrapper(
  Component: React.ComponentType<IButtonProps | any>
): React.FC<Props> {
  return function WrappedFileButton(props: Props) {
    const { accept, onFiles, textId, isMultiple, ...rest } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { localized } = useLocale();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const onButtonClick = React.useCallback(() => {
      chooseFile(accept || SUPPORTED_IMAGE_MIME.join(","), isMultiple)
        .then(onFiles)
        .catch(() => {});
    }, [accept, isMultiple, onFiles]);

    return (
      <Component text={localized(textId)} {...rest} onClick={onButtonClick} />
    );
  };
}

export const FileDefaultButton = FileButtonWrapper(DefaultButton);
export const FilePrimaryButton = FileButtonWrapper(PrimaryButton);
export const FileDangerButton = FileButtonWrapper(DangerButton);
