.team-usage {
  margin: -18px -30px;
}

.date-range-selector-with-toggle {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  .toggle-container {
    padding: 22px 30px;
    padding-left: 0px;
    white-space: nowrap;
  }
}
