import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { ExtractorOptionForCombinedExtractor } from "../../types/extractor";
import { BriefForm } from "../../types/form";
import styles from "./styles.module.scss";

interface Props {
  fallbackForm: BriefForm | undefined;
  extractorOptions: ExtractorOptionForCombinedExtractor[];
  onCloseModal: () => void;
  onSave: (formId: string) => void;
}

const FormGroupFallbackFormModal = React.memo((props: Props) => {
  const { fallbackForm, extractorOptions, onSave, onCloseModal } = props;

  const { localized } = useLocale();

  const extractorDropdownOptions = React.useMemo((): IDropdownOption[] => {
    return extractorOptions
      .map(x => ({
        key: x.extractorId,
        text:
          x.resourceType === "form_group"
            ? localized("form_group_editor.combined_extractor", {
                name: x.name,
              })
            : x.name,
        disabled: x.resourceType === "form_group",
      }))
      .sort((a, b) =>
        !a.disabled && b.disabled ? -1 : a.disabled && !b.disabled ? 1 : 0
      );
  }, [extractorOptions, localized]);

  const [isFormIdInvalid, setIsFormIdInvalid] = React.useState<boolean>(false);
  const [formId, setFormId] = React.useState<string | undefined>(
    fallbackForm ? fallbackForm.id : undefined
  );

  const onSaveClicked = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!formId) {
        setIsFormIdInvalid(true);
        return;
      }
      onSave(formId);
      onCloseModal();
    },
    [onSave, formId, onCloseModal]
  );

  const onSelectedFormChange = React.useCallback(
    (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (!option) return;
      setFormId(option.key as string);
    },
    []
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("form_group_fallback_form_modal.title"),
    }),
    [localized]
  );

  const modalProps: IModalProps = React.useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  return (
    <Dialog
      hidden={false}
      onDismiss={onCloseModal}
      minWidth={400}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <form
        onSubmit={onSaveClicked}
        className={styles["form-group-fallback-form-modal-form"]}
      >
        <Dropdown
          selectedKey={formId}
          options={extractorDropdownOptions}
          onChange={onSelectedFormChange}
          label={localized("form_group_fallback_form_modal.extract_with")}
          placeholder={localized(
            "form_group_fallback_form_modal.select_an_extractor"
          )}
          errorMessage={
            isFormIdInvalid
              ? localized("form_group_fallback_form_modal.select_an_extractor")
              : undefined
          }
        />
        <DialogFooter>
          <DefaultButton
            onClick={onCloseModal}
            text={localized("common.cancel")}
          />
          <PrimaryButton type="submit" text={localized("common.save")} />
        </DialogFooter>
      </form>
    </Dialog>
  );
});

export { FormGroupFallbackFormModal };
