.styled-mission-bar {
  margin-left: 0px;
  margin-right: 0px;
}

.fsl-standard-model-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.fsl-standard-model-editor-left {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.not-enabled-warning {
  border-radius: 10px;
  background: #deecf9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px;
  height: 352px;
  justify-content: center;
  margin: 24px 0px;
}

.not-enabled-warning-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 540px;
}

.not-enabled-warning-title {
  color: #323130;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
}

.not-enabled-warning-desc {
  color: #323130;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.not-eanbled-warning-button {
  margin-top: 24px;
}
.command-bar {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.sample-images-panel {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sample-images-panel-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sample-images-panel-image-list-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}

.sample-images-panel-image-list-scrollable {
  position: relative;
  overflow-y: auto;
  flex: 1 1 0;
}
.sample-images-panel-image-list {
  display: flex;
  gap: 20px;
  margin-top: 28px;
  flex-flow: wrap;
}

.status-text {
  color: #a19f9d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.paginator {
  margin: 20px 0px;
}

.training-banner {
  background: #ecf0ff;
  position: relative;

  padding: 10px 10px 10px 38px;

  .training-banner-spinner {
    position: absolute;
    top: 10px;
    left: 10px;

    :global(.ms-Spinner-circle) {
      border-color: #c7e0f4 #0078d4 #c7e0f4 #c7e0f4;
    }
  }

  .training-banner-spinner-title {
    color: #0078d4;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .training-banner-spinner-desc {
    color: #605e5c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.dropzone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.split-view {
  margin: 0px 20px;
}
