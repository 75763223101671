@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.list-container {
  margin-top: 20px;
}

.resource-table-row {
  cursor: pointer;
}

.resource-table-row-info {
  font-size: 12px;
  color: #605e5c;
}

.spinner {
  min-width: 100px;
  div {
    align-items: baseline;
  }
}

.empty-state-text {
  font-weight: normal;
}
