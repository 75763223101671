import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import styles from "./styles.module.scss";

interface InfoBlockProps {
  iconName?: string;
  labelId: string;
  descriptionId: string;
}
export function InfoBlock(props: InfoBlockProps) {
  const { iconName, labelId, descriptionId } = props;

  return (
    <div className={styles["info-block"]}>
      <h3 className={styles["info-block-label-container"]}>
        <Icon iconName={iconName ?? "Lightbulb"} />
        <FormattedMessage id={labelId} />
      </h3>
      <p className={styles["info-block-description"]}>
        <FormattedMessage id={descriptionId} />
      </p>
    </div>
  );
}
