import { Toggle } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import { DetectPIIEntity, DetectPIIReport } from "../../models";
import { DetectionTest } from "../DetectionTest";
import {
  BodyCell,
  DetectionTestReportTable,
  HeaderCell,
  Row,
  ValueCell,
  formatConfidence,
} from "../DetectionTestReportTable";
import styles from "./styles.module.scss";

interface Props {
  onSelectImage: (file?: File) => void;
  testReport?: DetectPIIReport;
}

function convertToJSON(testReport: DetectPIIReport) {
  return {
    status: "ok",
    piiEntities: testReport.pii_entities,
  };
}

function HeaderContent() {
  return (
    <>
      <HeaderCell
        headerId="detect_pii_test_table.header.type"
        columnClassName={styles["type-column"]}
      />
      <HeaderCell
        headerId="detect_pii_test_table.header.bboxes"
        columnClassName={styles["bboxes-column"]}
      />
      <HeaderCell
        headerId="detect_pii_test_table.header.value"
        columnClassName={styles["value-column"]}
      />
      <HeaderCell
        headerId="detect_pii_test_table.header.confidence"
        columnClassName={styles["confidence-column"]}
      />
    </>
  );
}

interface RowContentProps {
  entity: DetectPIIEntity;
}

function RowContent(props: RowContentProps) {
  const { entity } = props;
  const bboxes = `[${entity.bboxes.map(bbox => `[${bbox.join(", ")}]`)}]`;

  return (
    <>
      <BodyCell text={entity.type} columnClassName={styles["name-column"]} />
      <ValueCell value={bboxes} columnClassName={styles["bboxes-column"]} />
      <ValueCell
        value={entity.value}
        columnClassName={styles["value-column"]}
      />
      <ValueCell
        value={formatConfidence(entity.confidence)}
        columnClassName={styles["confidence-column"]}
      />
    </>
  );
}

interface TableContentProps {
  entities?: DetectPIIEntity[];
}

function TableContent(props: TableContentProps) {
  const { entities } = props;

  return (
    <>
      {entities &&
        entities.map((entity, index) => (
          <Row
            content={
              <>
                <RowContent entity={entity} />
              </>
            }
            key={`field/${index}`}
          />
        ))}
    </>
  );
}

interface PreviewContentProps {
  debugImage: string;
  redactImage: string;
}

function PreviewContent(props: PreviewContentProps) {
  const { debugImage, redactImage } = props;
  const { localized } = useLocale();
  const [redactImageEnabled, setRedactImageEnabled] =
    React.useState<boolean>(true);

  const onToggleButtonClicked = React.useCallback(() => {
    setRedactImageEnabled(!redactImageEnabled);
  }, [redactImageEnabled]);

  return (
    <>
      <h1>
        <FormattedMessage id="detect_pii.preview_title" />
      </h1>
      <div className={styles["preview-image"]}>
        <img
          src={redactImageEnabled ? redactImage : debugImage}
          alt="preview"
        />
        <div className={styles["toggle-button-holder"]}>
          <Toggle
            onText={localized("detect_pii.preview_image.toggle_button.label")}
            offText={localized("detect_pii.preview_image.toggle_button.label")}
            checked={redactImageEnabled}
            inlineLabel
            onChange={onToggleButtonClicked}
          />
        </div>
      </div>
    </>
  );
}

function _DetectPIITest(props: Props) {
  const { testReport, onSelectImage } = props;

  const { reportTable, previewContent } = React.useMemo(() => {
    const headerContent = (
      <>
        <HeaderContent />
      </>
    );

    const tableContent = (
      <>
        <TableContent entities={testReport?.pii_entities} />
      </>
    );
    const previewContent = (
      <>
        <PreviewContent
          debugImage={testReport?.debug_image ?? ""}
          redactImage={testReport?.redacted_image ?? ""}
        />
      </>
    );

    const reportTable = (
      <>
        <DetectionTestReportTable
          headerContent={headerContent}
          tableContent={tableContent}
        />
      </>
    );

    return { reportTable, previewContent };
  }, [testReport]);

  const reportVisible = testReport !== undefined;

  const jsonContent = testReport
    ? JSON.stringify(convertToJSON(testReport), null, 2)
    : "";

  const childProps = {
    onSelectImage,
    previewContent,
    reportVisible,
    reportTable,
    jsonContent,
  };

  return <DetectionTest {...childProps} />;
}

export const DetectPIITest = React.memo(_DetectPIITest);
export default DetectPIITest;
