import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { useAppDispatch } from "../hooks/redux";
import { RootState } from "../redux/types";
import { Webhook, WebhookCustomHeader } from "../types/webhook";
import { useConfirmModalActionCreator } from "./confirmModal";
import { useResourceOwnerActionCreator } from "./resourceOwner";

export const StartedFetchingWebhookList = createAction(
  "webhook/startedFetchingWebhookList"
);

export const GotWebhookList = createAction<Webhook[]>("webhook/gotWebhookList");

export const WebhookCreated = createAction<Webhook>("webhook/webhookCreated");

export const GotWebhook = createAction<Webhook>("webhook/gotWebhook");

export const WebhookUpdated = createAction<Webhook>("webhook/webhookUpdated");

export const WebhookRemoved = createAction(
  "webhook/webhookRemoved",
  (webhookId: string) => ({ payload: { webhookId } })
);

export type WebhookAction =
  | ReturnType<typeof WebhookCreated>
  | ReturnType<typeof GotWebhookList>
  | ReturnType<typeof GotWebhook>
  | ReturnType<typeof WebhookUpdated>
  | ReturnType<typeof WebhookRemoved>
  | ReturnType<typeof StartedFetchingWebhookList>;

export function useWebhookActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();
  const { handleConflict } = useConfirmModalActionCreator();
  const { selectTeam } = useResourceOwnerActionCreator();

  const listWebhooks = useCallback(async () => {
    const state = getState();
    const { resourceOwnerId } = state.resourceOwner;

    if (!resourceOwnerId) {
      return;
    }

    dispatch(StartedFetchingWebhookList());

    const listResult = await apiClient.listWebhooks(resourceOwnerId);

    dispatch(GotWebhookList(listResult.webhooks));

    return Promise.resolve();
  }, [dispatch, getState]);

  const createWebhook = useCallback(
    async (
      name: string,
      url: string,
      formIds: string[],
      formGroupIds: string[]
    ) => {
      const { resourceOwnerId } = getState().resourceOwner;
      const webhook = await apiClient.createWebhook(
        name,
        url,
        formIds,
        formGroupIds,
        resourceOwnerId
      );

      dispatch(WebhookCreated(webhook));

      return webhook;
    },
    [dispatch, getState]
  );

  const getWebhook = useCallback(
    async (webhookId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      const webhook = await apiClient.getWebhook(webhookId, resourceOwnerId);

      selectTeam(webhook.resourceOwnerId);

      dispatch(GotWebhook(webhook));
    },
    [dispatch, getState, selectTeam]
  );

  const updateWebhook = useCallback(
    async (
      webhookId: string,
      name: string,
      url: string,
      formIds: string[],
      formGroupIds: string[],
      customHeaders: WebhookCustomHeader[],
      retrievedAt: string
    ) => {
      const { resourceOwnerId } = getState().resourceOwner;

      const webhook = await handleConflict(
        () =>
          apiClient.updateWebhook(
            webhookId,
            name,
            url,
            formIds,
            formGroupIds,
            customHeaders,
            retrievedAt,
            resourceOwnerId
          ),
        () =>
          apiClient.updateWebhook(
            webhookId,
            name,
            url,
            formIds,
            formGroupIds,
            customHeaders,
            undefined,
            resourceOwnerId
          ),
        {
          titleId: "webhook.modified_prompt.title",
          messageId: "webhook.modified_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );

      dispatch(WebhookCreated(webhook));

      return webhook;
    },
    [dispatch, getState, handleConflict]
  );

  const removeWebhook = useCallback(
    async (webhookId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.deleteWebhook(webhookId, resourceOwnerId);

      dispatch(WebhookRemoved(webhookId));
    },
    [dispatch, getState]
  );

  return useMemo(
    () => ({
      listWebhooks,
      createWebhook,
      getWebhook,
      updateWebhook,
      removeWebhook,
    }),
    [listWebhooks, createWebhook, getWebhook, updateWebhook, removeWebhook]
  );
}
