import { Dropdown, IDropdownOption, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useMemo, useState } from "react";

import { DATE_INPUT_FORMAT_OPTIONS } from "../../../constants/formConfig";
import { useLocale } from "../../../contexts/locale";
import { CustomModelTargetConfig } from "../../../types/advancedTokenSetup/table";
import { DateInputFormatType } from "../../../types/formConfig";
import styles from "./styles.module.scss";

type BaseProps = ReturnType<
  typeof useCustomModelConfigViewHandle
>["triggerProps"];

type Props = BaseProps;

export function useCustomModelConfigViewHandle(
  fields: string[],
  customModelId?: string,
  defaultSelectedValue?: string,
  defaultDateInputFormat?: DateInputFormatType,
  shouldShowDateInputDropdown?: boolean
) {
  const [selectedValue, setSelectedValue] = useState(
    defaultSelectedValue || fields[0]
  );

  const [dateInputFormat, setDateInputFormat] = useState<DateInputFormatType>(
    defaultDateInputFormat || DATE_INPUT_FORMAT_OPTIONS[0]
  );
  const onChange = useCallback(
    (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option && option.id) {
        setSelectedValue(option.id);
      }
    },
    []
  );

  const onDateInputFormatChange = useCallback(
    (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option && option.id) {
        setDateInputFormat(option.id as DateInputFormatType);
      }
    },
    []
  );

  const options: IDropdownOption[] = useMemo(() => {
    return fields.map(f => ({
      id: f,
      key: f,
      text: f,
    }));
  }, [fields]);

  const submit = useCallback(
    (): CustomModelTargetConfig | undefined =>
      customModelId
        ? {
            custom_model_id: customModelId,
            field_name: selectedValue,
            date_input_format: dateInputFormat,
          }
        : undefined,
    [customModelId, selectedValue, dateInputFormat]
  );
  return useMemo(
    () => ({
      triggerProps: {
        selectedValue,
        onChange,
        options,
        customModelId,
        dateInputFormat,
        onDateInputFormatChange,
        shouldShowDateInputDropdown,
      },
      submit,
    }),
    [
      selectedValue,
      onChange,
      options,
      customModelId,
      dateInputFormat,
      onDateInputFormatChange,
      submit,
      shouldShowDateInputDropdown,
    ]
  );
}

const CustomModelConfigView = React.memo<Props>(props => {
  const {
    options,
    customModelId,
    selectedValue,
    onChange,
    dateInputFormat,
    onDateInputFormatChange,
    shouldShowDateInputDropdown,
  } = props;
  const { localized } = useLocale();

  const dateInputFormatOptions: IDropdownOption[] = useMemo(() => {
    return DATE_INPUT_FORMAT_OPTIONS.map(f => ({
      id: f,
      key: f,
      text: localized(`date_input_format.${f}`),
    }));
  }, [localized]);

  return (
    <div className={styles["custom-model-config"]}>
      <Text className={styles["model-id-title"]}>
        <FormattedMessage id="advance_token_setup_field_replacement_setting_panel.panel.custom_model.custom_model_id" />
      </Text>
      <Text>{customModelId}</Text>

      <Text className={styles["replace-with-field-title"]}>
        <FormattedMessage id="advance_token_setup_field_replacement_setting_panel.panel.custom_model.replace_with_field" />
      </Text>
      <Dropdown
        options={options}
        selectedKey={selectedValue}
        onChange={onChange}
      />

      {shouldShowDateInputDropdown && (
        <Dropdown
          label={localized(
            "advance_token_setup_field_replacement_setting_panel.panel.change_date_input.convert_date_order"
          )}
          selectedKey={dateInputFormat}
          onChange={onDateInputFormatChange}
          options={dateInputFormatOptions}
          className={styles["change-date-format-dropdown"]}
        />
      )}
    </div>
  );
});

export default CustomModelConfigView;
