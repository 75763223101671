import { Icon, Label, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useCallback, useState } from "react";

import { VALID_URL_REGEX } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { useExtractorOptions } from "../../hooks/extractorOption";
import { Webhook, WebhookCustomHeader } from "../../types/webhook";
import ErrorPlaceholder from "../ErrorPlaceholder";
import SaveWebhookBar from "../SaveWebhookBar";
import ShortSpinner from "../ShortSpinner";
import { WebhookConnectFormField } from "../WebhookConnectFormField";
import WebhookNavBar from "../WebhookNavBar";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

const MAX_CUSTOM_HEADER = 5;
const MAX_CUSTOM_HEADER_BYTES = 1024 * 3;

const CustomHeaderTitle = React.memo(() => {
  return (
    <div className={styles["custom-headers-title-bar"]}>
      <Label className={styles["heading1"]}>
        <FormattedMessage id="webhook.custom_headers.header_name" />
      </Label>
      <Label className={styles["heading2"]}>
        <FormattedMessage id="webhook.custom_headers.header_value" />
      </Label>
    </div>
  );
});

const CustomHeaderRow = React.memo(
  (props: {
    index: number;
    name: string;
    nameError: string;
    value: string;
    valueError: string;
    onNameChanged: (index: number, newName: string) => void;
    onValueChanged: (index: number, newValue: string) => void;
    onRemoveClicked: (index: number) => void;
  }) => {
    const { index, name, value } = props;
    const { localized } = useLocale();

    const onNameChanged = useCallback(
      (
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newHeader?: string
      ) => {
        props.onNameChanged(index, newHeader ?? "");
      },
      [index, props]
    );

    const onValueChanged = useCallback(
      (
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        props.onValueChanged(index, newValue ?? "");
      },
      [index, props]
    );

    const onRemoveClicked = useCallback(() => {
      props.onRemoveClicked(index);
    }, [index, props]);

    const nameError = props.nameError !== "" ? localized(props.nameError) : "";
    const valueError =
      props.valueError !== ""
        ? localized(props.valueError, {
            bracket: "{}",
          })
        : "";

    return (
      <div className={styles["custom-headers-row"]}>
        <TextField
          labelId=""
          className={styles["name-column"]}
          value={name}
          onChange={onNameChanged}
          errorMessage={nameError}
          placeholder={localized(
            "webhook.custom_headers.header_name_placeholder"
          )}
        />

        <TextField
          className={styles["value-column"]}
          labelId=""
          value={value}
          onChange={onValueChanged}
          errorMessage={valueError}
          placeholder={localized(
            "webhook.custom_headers.header_value_placeholder"
          )}
        />

        <Icon
          className={styles["delete-button"]}
          iconName="Delete"
          onClick={onRemoveClicked}
        />
      </div>
    );
  }
);

interface Props {
  webhook?: Webhook;
  submit: (
    name: string,
    url: string,
    formIds: string[],
    formGroupIds: string[],
    customHeaders: WebhookCustomHeader[]
  ) => void;
  onRemoveClicked?: () => void;
}

const WebhookEditor = React.memo((props: Props) => {
  const { webhook, submit, onRemoveClicked } = props;
  const { extractorOptions, isFailedToFetchExtractorOptions } =
    useExtractorOptions();
  const { localized } = useLocale();
  const [webhookName, setWebhookName] = useState(webhook?.name ?? "");
  const [webhookUrl, setWebhookUrl] = useState(webhook?.webhookUrl ?? "");
  const [connectWithFormIds, setConnectWithFormIds] = useState<string[]>(
    webhook?.forms?.map(f => f.id) ?? []
  );
  const [connectWithFormGroupIds, setConnectWithFormGroupIds] = useState<
    string[]
  >(webhook?.formGroups?.map(f => f.id) ?? []);
  const [webhookNameErrorMessage, setWebhookNameErrorMessage] = useState<
    string | undefined
  >();
  const [webhookUrlErrorMessage, setWebhookUrlErrorMessage] = useState<
    string | undefined
  >();
  const [connectWithErrorMessage, setConnectWithErrorMessage] = useState<
    string | undefined
  >();

  const isCreate = React.useMemo(() => webhook == null, [webhook]);

  const onWebhookNameChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value !== undefined) {
        setWebhookName(value);
        setWebhookNameErrorMessage(undefined);
      }
    },
    []
  );

  const onWebhookUrlChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value !== undefined) {
        setWebhookUrl(value);
        setWebhookUrlErrorMessage(undefined);
      }
    },
    []
  );

  const onConnectFormGroupChange = useCallback(
    (formGroupId: string) =>
      (ev?: React.FormEvent<unknown>, checked?: boolean) => {
        if (ev) {
          ev.stopPropagation();
        }

        if (checked === undefined) {
          return;
        }

        let connectWithFormGroupsList = [...connectWithFormGroupIds];
        if (checked) {
          connectWithFormGroupsList = connectWithFormGroupsList.concat([
            formGroupId,
          ]);
        } else {
          connectWithFormGroupsList = connectWithFormGroupsList.filter(
            x => x !== formGroupId
          );
        }

        setConnectWithFormGroupIds(connectWithFormGroupsList);
        setConnectWithErrorMessage(undefined);
      },
    [connectWithFormGroupIds]
  );

  const onConnectFormChange = useCallback(
    (formId: string) => (ev?: React.FormEvent<unknown>, checked?: boolean) => {
      if (ev) {
        ev.stopPropagation();
      }

      if (checked === undefined) {
        return;
      }

      let connectWithFormsList = [...connectWithFormIds];
      if (checked) {
        connectWithFormsList = connectWithFormsList.concat([formId]);
      } else {
        connectWithFormsList = connectWithFormsList.filter(x => x !== formId);
      }

      setConnectWithFormIds(connectWithFormsList);
      setConnectWithErrorMessage(undefined);
    },
    [connectWithFormIds]
  );

  const validationExistenceCheck = useCallback(
    (webhookName: string, webhookUrl: string): boolean => {
      let result = true;

      if (!webhookName) {
        result = false;
        setWebhookNameErrorMessage("error.webhook.empty_webhook_name");
      }
      if (!webhookUrl) {
        result = false;
        setWebhookUrlErrorMessage("error.webhook.empty_webhook_url");
      }

      return result;
    },
    []
  );

  const validationUrl = useCallback((webhookUrl: string): boolean => {
    if (!webhookUrl.match(VALID_URL_REGEX)) {
      setWebhookUrlErrorMessage("error.webhook.invalid_webhook_url");
      return false;
    }

    return true;
  }, []);

  const [customHeaders, seCustomHeaders] = useState(
    webhook?.customHeaders ?? []
  );

  const [customHeaderErrors, setCustomHeaderErrors] = useState<
    WebhookCustomHeader[]
  >(
    Array.from(Array(customHeaders.length).keys()).map(() => ({
      name: "",
      value: "",
    }))
  );

  const clearCustomHeaderNameError = useCallback(
    (index: number) => {
      if (customHeaderErrors[index]?.name ?? "" !== "") {
        const newErrors = [...customHeaderErrors];
        newErrors[index].name = "";
        setCustomHeaderErrors(newErrors);
      }
    },
    [customHeaderErrors]
  );

  const clearCustomHeaderValueError = useCallback(
    (index: number) => {
      if (customHeaderErrors[index]?.value ?? "" !== "") {
        const newErrors = [...customHeaderErrors];
        newErrors[index].value = "";
        setCustomHeaderErrors(newErrors);
      }
    },
    [customHeaderErrors]
  );

  const validationCustomHeaders = useCallback(
    (customHeaders: WebhookCustomHeader[]): boolean => {
      let hasError = false;
      const errors = Array.from(Array(customHeaders.length).keys()).map(() => ({
        name: "",
        value: "",
      }));
      const names = customHeaders.map(h => h.name);
      const duplicateHeaderNames = names.filter(
        (name, index) => names.indexOf(name) !== index
      );

      names.forEach((name, index) => {
        if (duplicateHeaderNames.includes(name)) {
          errors[index].name = "error.webhook.custom_headers.duplicated_name";
          hasError = true;
        }

        if (name.match(/^[0-9a-zA-Z_-]+$/) === null) {
          errors[index].name = "error.webhook.custom_headers.invalid_name";
          hasError = true;
        }

        if (name.trim() === "") {
          errors[index].name = "error.webhook.custom_headers.empty_name";
          hasError = true;
        }

        if (name.toUpperCase().match(/^X-WORKER/)) {
          errors[index].name =
            "error.webhook.custom_headers.x_worker_forbidden";
          hasError = true;
        }
      });

      const values = customHeaders.map(h => h.value);
      values.forEach((value, index) => {
        if (
          value.match(
            /^[0-9a-zA-Z_ :;.,\\\/\"\'?!\(\)\{\}\[\]@<>=\-\+\*#$&`\|~^%]*$/
          ) === null
        ) {
          errors[index].value = "error.webhook.custom_headers.invalid_value";
          hasError = true;
        }
      });

      const totalSize = customHeaders.reduce(
        (acc, cur) => acc + cur.name.length + cur.value.length,
        0
      );

      if (totalSize > MAX_CUSTOM_HEADER_BYTES) {
        errors[customHeaders.length - 1].name =
          "error.webhook.custom_headers.too_large";
        hasError = true;
      }

      setCustomHeaderErrors(errors);
      return !hasError;
    },
    []
  );

  const validateInputs = useCallback(
    (webhookName: string, webhookUrl: string): boolean => {
      if (!validationExistenceCheck(webhookName, webhookUrl)) {
        return false;
      }

      if (!validationUrl(webhookUrl)) {
        return false;
      }

      if (!validationCustomHeaders(customHeaders)) {
        return false;
      }

      return true;
    },
    [
      validationExistenceCheck,
      validationUrl,
      validationCustomHeaders,
      customHeaders,
    ]
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!validateInputs(webhookName, webhookUrl)) {
        return;
      }

      submit(
        webhookName,
        webhookUrl,
        connectWithFormIds,
        connectWithFormGroupIds,
        customHeaders
      );
    },
    [
      webhookName,
      webhookUrl,
      connectWithFormIds,
      connectWithFormGroupIds,
      customHeaders,
      validateInputs,
      submit,
    ]
  );

  const addNewCustomHeaderEnabled = customHeaders.length < MAX_CUSTOM_HEADER;

  const addNewCustomHeader = useCallback(() => {
    if (!addNewCustomHeaderEnabled) {
      return;
    }
    seCustomHeaders([
      ...customHeaders,
      {
        name: "",
        value: "",
      },
    ]);
  }, [customHeaders, addNewCustomHeaderEnabled]);

  const updateCustomHeaderName = useCallback(
    (index: number, newName: string) => {
      const newHeaders = [...customHeaders];
      newHeaders[index].name = newName;
      seCustomHeaders(newHeaders);
      clearCustomHeaderNameError(index);
    },
    [customHeaders, clearCustomHeaderNameError]
  );

  const updateCustomHeaderValue = useCallback(
    (index: number, newValue: string) => {
      const newHeaders = [...customHeaders];
      newHeaders[index].value = newValue;
      seCustomHeaders(newHeaders);
      clearCustomHeaderValueError(index);
    },
    [customHeaders, clearCustomHeaderValueError]
  );

  const removeCustomHeader = useCallback(
    (index: number) => {
      const newHeaders = [...customHeaders];
      newHeaders.splice(index, 1);
      seCustomHeaders(newHeaders);
    },
    [customHeaders]
  );

  return (
    <form onSubmit={onSubmit}>
      <SaveWebhookBar isCreate={isCreate} onRemoveClicked={onRemoveClicked} />
      <div className={styles["webhook-form"]}>
        <WebhookNavBar isCreate={isCreate} />
        <div className={styles["fields-container"]}>
          <TextField
            type="text"
            className={styles["input-field"]}
            labelId="webhook.name"
            placeholder={localized("webhook.name.placeholder")}
            value={webhookName}
            onChange={onWebhookNameChange}
            errorMessage={
              webhookNameErrorMessage && localized(webhookNameErrorMessage)
            }
          />
          <TextField
            type="text"
            className={styles["input-field"]}
            labelId="webhook.url"
            value={webhookUrl}
            onChange={onWebhookUrlChange}
            errorMessage={
              webhookUrlErrorMessage && localized(webhookUrlErrorMessage)
            }
          />
          {/* Only show connect with field on Edit Page */}
          {!isCreate && (
            <>
              <div className={styles["input-field"]}>
                <Label>
                  <FormattedMessage id="webhook.connect_with" />
                </Label>
                {isFailedToFetchExtractorOptions ? (
                  <ErrorPlaceholder messageId="webhook.edit.fetch_forms_form_groups_fail" />
                ) : extractorOptions === undefined ? (
                  <ShortSpinner />
                ) : (
                  <WebhookConnectFormField
                    extractorOptions={extractorOptions}
                    connectWithFormIds={connectWithFormIds}
                    connectWithFormGroupIds={connectWithFormGroupIds}
                    onConnectFormChange={onConnectFormChange}
                    onConnectFormGroupChange={onConnectFormGroupChange}
                    connectWithErrorMessage={connectWithErrorMessage}
                  />
                )}
              </div>
              <div className={styles["input-field"]}>
                <Label>
                  <FormattedMessage id="webhook.custom_headers.title" />
                </Label>
              </div>
              <div>
                {customHeaders.length > 0 && <CustomHeaderTitle />}
                {customHeaders.map((header, index) => (
                  <CustomHeaderRow
                    key={index}
                    index={index}
                    name={header.name}
                    nameError={customHeaderErrors[index]?.name ?? ""}
                    value={header.value}
                    valueError={customHeaderErrors[index]?.value ?? ""}
                    onNameChanged={updateCustomHeaderName}
                    onValueChanged={updateCustomHeaderValue}
                    onRemoveClicked={removeCustomHeader}
                  />
                ))}
              </div>
              <div>
                <Text
                  onClick={addNewCustomHeader}
                  className={
                    styles[
                      addNewCustomHeaderEnabled
                        ? "add-custom-header-button"
                        : "add-custom-header-disabled"
                    ]
                  }
                >
                  <FormattedMessage id="webhook.custom_headers.add_header" />
                </Text>
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
});

export default WebhookEditor;
