@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

$bottom-height: 64px;
$button-color: #ffd633;
$button-width: 100%;
$border-color: #cccccc;

.container {
  width: 340px;
  height: 100%;
  @include ms-depth-8;
}

.content {
  width: 100%;
  overflow-y: auto;
  padding: 20px 20px 20px 20px;
  height: calc(100% - #{$bottom-height});

  > :global(div.ms-Pivot--tabs) {
    > button {
      font-size: 14px;
    }

    padding-bottom: 10px;
    border-bottom: 1px solid $border-color;
  }

  .title {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 22px;
    border-bottom: 1px solid $border-color;
  }

  .desc {
    font-size: 14px;
    margin-top: 12px;
    line-height: 20px;
  }

  .subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 9px;
  }

  .info {
    margin-top: 4px;
    color: #a19f9d;
    font-size: 14px;
  }

  .preset {
    margin-top: 16px;
    margin-bottom: 15px;
  }

  .add-new-field-button {
    margin-top: 15px;
    width: $button-width;
    color: $theme-primary;
    :global(span.ms-Button-textContainer) {
      flex-grow: 0;
    }
    border: 1.5px solid $theme-primary;
    font-weight: 600;
  }

  .field-list {
    margin-top: 16px;
    height: calc(100vh - 330px - 64px - 20px);
    overflow-y: auto;
  }
  .field-list.with-preset {
    margin-top: 0;
    height: calc(100vh - 330px - 64px - 20px - 66px);
  }
}

$bottom-side-margin: 20px;
.bottom {
  width: calc(100% - (#{$bottom-side-margin} * 2));
  height: $bottom-height;
  max-height: $bottom-height;

  border-top: 1px solid $border-color;
  margin: 0 $bottom-side-margin;

  .next-step-button {
    float: right;
    margin-top: 10px;
  }

  .next-step-button:not(:disabled) {
    color: #000000;
    background-color: $button-color;
    border-color: $button-color;
    font-weight: 600;
  }

  .next-step-button:hover:not(:disabled) {
    background-color: darken($button-color, 30%);
    border-color: darken($button-color, 30%);
  }

  .next-step-button:active:not(:disabled) {
    background-color: darken($button-color, 20%);
    border-color: darken($button-color, 20%);
  }
}

.teaching-bubble-desc {
  color: $white;
  line-height: 20px;

  .teaching-bubble-desc-bold {
    font-weight: 800;
  }
}
