import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo } from "react";

import { useLocale } from "../../contexts/locale";
import { FOCRError } from "../../errors";
import {
  ChangePasswordPane,
  useChangePasswordPane,
} from "../ChangePasswordPane";

interface Props {
  isOpen: boolean;
  onCancel(): void;
  onChangePassword(oldPassword: string, newPassword: string): Promise<void>;
}

const ChangePasswordModal = React.memo((props: Props) => {
  const { isOpen, onChangePassword, onCancel } = props;
  const { localized } = useLocale();
  const changePasswordPaneProps = useChangePasswordPane();
  const {
    oldPassword,
    newPassword,
    verifyNewPassword,
    onDismissed,
    setError,
    validateInputs,
    isDisabled,
    setIsDisabled,
  } = changePasswordPaneProps;

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!validateInputs(oldPassword, newPassword, verifyNewPassword)) {
        return;
      }
      setIsDisabled(true);

      try {
        await onChangePassword(oldPassword, newPassword);
      } catch (e) {
        setError(e as FOCRError);
      }
      setIsDisabled(false);
    },
    [
      newPassword,
      oldPassword,
      onChangePassword,
      verifyNewPassword,
      validateInputs,
      setError,
      setIsDisabled,
    ]
  );

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
    }),
    [onDismissed]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("change_password.title"),
    }),
    [localized]
  );

  return (
    <Dialog
      minWidth={500}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={onSubmit}>
        <ChangePasswordPane {...changePasswordPaneProps} />
        <DialogFooter>
          <DefaultButton
            onClick={onCancel}
            text={localized("common.cancel")}
            disabled={isDisabled}
          />
          <PrimaryButton
            type="submit"
            text={localized("common.save")}
            disabled={isDisabled}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
});
export default ChangePasswordModal;
