.content {
  min-width: 405px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.header {
  color: #201f1e;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
