import { PreferenceKey, setPreference } from "../utils/preference";
import { ApiClientInitializeResult, _BaseApiClient } from "./base";

export class SimpleAPIClient extends _BaseApiClient {
  static AccessTokenKey = "formx.accessToken";
  static LegacyAccessTokenKey = "skygear-accesstoken";

  private accessToken: string | null = null;

  constructor(endpoint: string, regionalEndpoints: { [Key: string]: string }) {
    super(endpoint, regionalEndpoints);
  }

  async fetch(resource: RequestInfo, init?: RequestInit): Promise<Response> {
    const request = new Request(resource, init);
    if (this.accessToken !== null) {
      request.headers.set("authorization", `bearer ${this.accessToken}`);
    }
    return fetch(request);
  }

  async initialize(): Promise<ApiClientInitializeResult> {
    return new Promise<ApiClientInitializeResult>((resolve, _reject) => {
      this.accessToken = window.localStorage.getItem(
        SimpleAPIClient.LegacyAccessTokenKey
      );

      if (this.accessToken !== null) {
        this.clearLegacyLocalStorageItems();
        window.localStorage.setItem(
          SimpleAPIClient.AccessTokenKey,
          this.accessToken
        );
      } else {
        this.accessToken = window.localStorage.getItem(
          SimpleAPIClient.AccessTokenKey
        );
      }

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const invitationCode =
        urlParams.get("invitation-code") || urlParams.get("state") || undefined;

      const redirectURIParam = urlParams.get("redirect");
      if (window.location.pathname.startsWith("/readme-io-login")) {
        setPreference(
          PreferenceKey.readmeIORedirectURI,
          redirectURIParam ?? ""
        );
      }

      resolve({
        isAuthenticated: this.accessToken !== null,
        invitationCode: invitationCode,
      });
    });
  }

  clearLegacyLocalStorageItems() {
    const itemsToRemove = [
      SimpleAPIClient.LegacyAccessTokenKey,
      "_skygear_purgeable_keys_",
      "skygear-user",
    ];
    for (const item of itemsToRemove) {
      console.debug(item);
      window.localStorage.removeItem(item);
    }
  }

  setAssetToken(accessToken: string) {
    this.accessToken = accessToken;
    window.localStorage.setItem(SimpleAPIClient.AccessTokenKey, accessToken);
  }

  clearAccessToken() {
    this.accessToken = null;
    window.localStorage.removeItem(SimpleAPIClient.AccessTokenKey);
  }
}
