import { Anchor } from "../types/anchor";
import { DetectionRegion } from "../types/detectionRegion";
import { Field } from "../types/field";
import { Vertex } from "../types/vertex";
import { deepClone } from "../utils/deepClone";

export interface CanvasStoreDelegate {
  anchorUpdated?: (anchor: Anchor) => void;
  anchorDeleted?: (anchorId: string) => void;
  anchorAdded?: (anchor: Anchor) => void;

  fieldUpdated?: (field: Field) => void;
  fieldDeleted?: (fieldId: string) => void;
  fieldAdded?: (field: Field) => void;

  detectionRegionUpdated?: (detectionRegion: DetectionRegion) => void;
  detectionRegionDeleted?: (detectionRegionId: string) => void;
  detectionRegionAdded?: (detectionRegion: DetectionRegion) => void;

  anchorVertexAdded?: (vertex: Vertex) => void;
  creatingFieldUpdated?: () => void;
  creatingDetectionRegionUpdated?: () => void;

  loaded?: () => void;
}

export class CanvasStore {
  delegates: CanvasStoreDelegate[] = [];

  anchorMap: Map<string, Anchor> = new Map<string, Anchor>();
  fieldMap: Map<string, Field> = new Map<string, Field>();
  detectionRegionMap: Map<string, DetectionRegion> = new Map<
    string,
    DetectionRegion
  >();

  partialAnchorVertices: Vertex[] = [];
  creatingField?: Field;
  creatingDetectionRegion?: DetectionRegion;

  get anchors(): Anchor[] {
    return Array.from(this.anchorMap.values());
  }

  get fields(): Field[] {
    return Array.from(this.fieldMap.values());
  }

  get detectionRegions(): DetectionRegion[] {
    return Array.from(this.detectionRegionMap.values());
  }

  clear = () => {
    this.delegates = [];

    this.anchorMap.clear();
    this.fieldMap.clear();
    this.detectionRegionMap.clear();

    this.partialAnchorVertices = [];
    this.creatingField = undefined;
    this.creatingDetectionRegion = undefined;
  };

  load = (
    anchors: Anchor[],
    fields: Field[],
    detectionRegions: DetectionRegion[]
  ) => {
    this.anchorMap.clear();
    this.fieldMap.clear();
    this.detectionRegionMap.clear();

    for (const anchor of anchors) {
      this.anchorMap.set(anchor.id, deepClone(anchor));
    }

    for (const field of fields) {
      this.fieldMap.set(field.id, deepClone(field));
    }

    for (const detectionRegion of detectionRegions) {
      this.detectionRegionMap.set(
        detectionRegion.id,
        deepClone(detectionRegion)
      );
    }

    for (const delegate of this.delegates) {
      if (delegate.loaded) {
        delegate.loaded();
      }
    }
  };

  getAnchor = (anchorId: string): Anchor => {
    const anchor = this.anchorMap.get(anchorId);
    if (anchor) {
      return anchor;
    } else {
      throw new Error(`Anchor(${anchorId}) not found in canvas store`);
    }
  };

  upsertAnchor = (anchor: Anchor) => {
    if (this.anchorMap.get(anchor.id)) {
      for (const delegate of this.delegates) {
        if (delegate.anchorUpdated) {
          delegate.anchorUpdated(anchor);
        }
      }
    } else {
      this.partialAnchorVertices = [];
      for (const delegate of this.delegates) {
        if (delegate.anchorAdded) {
          delegate.anchorAdded(anchor);
        }
      }
    }
  };

  deleteAnchor = (anchorId: string) => {
    if (this.anchorMap.delete(anchorId)) {
      for (const delegate of this.delegates) {
        if (delegate.anchorDeleted) {
          delegate.anchorDeleted(anchorId);
        }
      }
    }
  };

  getField = (fieldId: string): Field => {
    const field = this.fieldMap.get(fieldId);
    if (field) {
      return field;
    } else {
      throw new Error(`Field(${fieldId}) not found in canvas store`);
    }
  };

  upsertField = (field: Field) => {
    if (this.fieldMap.get(field.id)) {
      for (const delegate of this.delegates) {
        if (delegate.fieldUpdated) {
          delegate.fieldUpdated(field);
        }
      }
    } else {
      for (const delegate of this.delegates) {
        if (delegate.fieldAdded) {
          delegate.fieldAdded(field);
        }
      }
    }
  };

  deleteField = (fieldId: string) => {
    if (this.fieldMap.delete(fieldId)) {
      for (const delegate of this.delegates) {
        if (delegate.fieldDeleted) {
          delegate.fieldDeleted(fieldId);
        }
      }
    }
  };

  getDetectionRegion = (detectionRegionId: string): DetectionRegion => {
    const detectionRegion = this.detectionRegionMap.get(detectionRegionId);
    if (detectionRegion) {
      return detectionRegion;
    } else {
      throw new Error(
        `DetectionRegion(${detectionRegionId}) not found in canvas store`
      );
    }
  };

  upsertDetectionRegion = (detectionRegion: DetectionRegion) => {
    if (this.detectionRegionMap.get(detectionRegion.id)) {
      for (const delegate of this.delegates) {
        if (delegate.detectionRegionUpdated) {
          delegate.detectionRegionUpdated(detectionRegion);
        }
      }
    } else {
      for (const delegate of this.delegates) {
        if (delegate.detectionRegionAdded) {
          delegate.detectionRegionAdded(detectionRegion);
        }
      }
    }
  };

  deleteDetectionRegion = (detectionRegionId: string) => {
    if (this.detectionRegionMap.delete(detectionRegionId)) {
      for (const delegate of this.delegates) {
        if (delegate.detectionRegionDeleted) {
          delegate.detectionRegionDeleted(detectionRegionId);
        }
      }
    }
  };

  addPartialAnchorVertex = (vertex: Vertex) => {
    this.partialAnchorVertices.push(vertex);
    for (const delegate of this.delegates) {
      if (delegate.anchorVertexAdded) {
        delegate.anchorVertexAdded(vertex);
      }
    }
  };

  setCreatingField = (creatingField?: Field) => {
    this.creatingField = creatingField;
    for (const delegate of this.delegates) {
      if (delegate.creatingFieldUpdated) {
        delegate.creatingFieldUpdated();
      }
    }
  };

  setCreatingDetectionRegion = (creatingDetectionRegion?: DetectionRegion) => {
    this.creatingDetectionRegion = creatingDetectionRegion;
    for (const delegate of this.delegates) {
      if (delegate.creatingDetectionRegionUpdated) {
        delegate.creatingDetectionRegionUpdated();
      }
    }
  };
}
