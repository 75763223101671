/* tslint:disable: jsx-use-translation-function */
import { ScriptFunctionContent } from "../models";

const EditDistanceContent: ScriptFunctionContent = {
  name: "edit_distance",
  parameters: [
    {
      name: "string1",
      type: "string",
    },
    {
      name: "string2",
      type: "string",
    },
  ],
  returnValue: {
    name: "edit_distance",
    type: "number",
  },
};

export default EditDistanceContent;
