import { Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

import { DetectionRegionInspectorTargetIds } from "../../constants/layout";
import markConfigureDetectionRegionImg from "../../images/tutorial/add-extraction-field.gif";
import FixedLayoutFormTutorialBubble from "../FixedLayoutFormTutorialBubble";
import { Img } from "../Img";
import styles from "./styles.module.scss";

const FirstStep = React.memo(() => {
  return (
    <>
      <Img src={markConfigureDetectionRegionImg} className={styles["image"]} />
      <div className={styles["text-content"]}>
        <Text variant="medium" className={styles["title"]}>
          <FormattedMessage id="tutorial.fixed_layout_form.configure_detection_region.title" />
        </Text>
        <Text variant="medium" className={styles["description"]}>
          <FormattedMessage id="tutorial.fixed_layout_form.configure_detection_region.description" />
        </Text>
      </div>
    </>
  );
});

interface FixedLayoutFormTutorialConfigureDetectionRegionBubbleProps {
  proceedToNextStage: () => void;
  skipAllTutorials: () => void;
}

const FixedLayoutFormTutorialConfigureDetectionRegionBubble = React.memo(
  (props: FixedLayoutFormTutorialConfigureDetectionRegionBubbleProps) => {
    const { proceedToNextStage, skipAllTutorials } = props;

    const onSkipAll = useCallback(() => {
      skipAllTutorials();
    }, [skipAllTutorials]);

    const onNavigateToStep = useCallback(
      (_: number) => {
        proceedToNextStage();
      },
      [proceedToNextStage]
    );

    return (
      <FixedLayoutFormTutorialBubble
        isLastStep={true}
        currentStep={0}
        targetId={DetectionRegionInspectorTargetIds.AddExtractionField}
        onSkipAll={onSkipAll}
        onNavigateToStep={onNavigateToStep}
        contents={[<FirstStep />]}
      />
    );
  }
);

export default FixedLayoutFormTutorialConfigureDetectionRegionBubble;
