import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { useDragAndDropFiles } from "../../hooks/drag_and_drop";
import { OCRTestPlaceholder } from "../OCRTestPlaceholder";
import styles from "./styles.module.scss";

interface Props {
  onSelectImage: (file?: File) => void;
  reportVisible: boolean;
  reportTable: React.ReactNode;
  previewContent: React.ReactNode;
  jsonContent: string;
}

function _DetectionTest(props: Props) {
  useDragAndDropFiles(
    (files?: File[]) => props.onSelectImage(files && files[0]),
    SUPPORTED_EXTRACT_MIME
  );

  const {
    onSelectImage,
    reportVisible,
    jsonContent,
    reportTable,
    previewContent,
  } = props;

  return (
    <div className={styles["detection-test"]}>
      {reportVisible ? (
        <ReportView
          reportTable={reportTable}
          jsonContent={jsonContent}
          previewContent={previewContent}
        />
      ) : (
        <OCRTestPlaceholder onSelectImage={onSelectImage} />
      )}
    </div>
  );
}

interface ReportViewProps {
  reportTable: React.ReactNode;
  previewContent: React.ReactNode;
  jsonContent: string;
}

function ReportView(props: ReportViewProps) {
  const { reportTable, jsonContent, previewContent } = props;

  return (
    <div className={styles["report"]}>
      <div className={styles["section"]}>{previewContent}</div>

      {reportTable}

      <div className={styles["section"]}>
        <h1>
          <FormattedMessage id="ocr_test.json" />
        </h1>
        <pre>{jsonContent}</pre>
      </div>
    </div>
  );
}

export const DetectionTest = React.memo(_DetectionTest);
export default DetectionTest;
