import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { useCustomModelActionCreator } from "../actions/customModel";
import { useFormActionCreator } from "../actions/form";
import {
  CreateWorkspaceModal,
  useCreateWorkspaceModalHandle,
} from "../components/CreateWorkspaceModal";
import DataNotSavedPrompt from "../components/DataNotSavedPrompt";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import {
  AdvancedPatternMatchingSetting,
  ExtractorSettingSection,
  FormImageSettingProps,
  useExtractorSettingHandle,
} from "../components/ExtractorSetting";
import ImportFormConfirmModal, {
  useImportFormConfirmModalHandle,
} from "../components/ImportFormConfirmModal";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import ScriptEditorModal, {
  useScriptEditorHandle,
} from "../components/ScriptEditorModal";
import { UserFeatureFlag } from "../constants";
import {
  PubliclyAvailableLLMModels,
  RestrictedLLMModels,
} from "../constants/llmModel";
import HeaderContainer from "../containers/Header";
import errors, { FOCRError } from "../errors";
import { useCommonCustomModelContainerState } from "../hooks/custom_model";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { PathParam } from "../models";
import { RootState } from "../redux/types";
import { ExtractorSettings } from "../types/extractorSettings";
import { BriefExtractorMapper } from "../types/mappers/extractor";
import { ProcessingMode } from "../types/processingMode";
import {
  CustomModelNavBarLayout,
  CustomModelNavTabKey,
} from "./CustomModelNavBarLayout";
import { FSLNavBarLayout, FSLTab } from "./FSLNavBarLayout";

function useCustomModelSettingContainer() {
  const { customModelId } = useUnsafeParams<PathParam>();
  const containerState = useCommonCustomModelContainerState(customModelId);

  // we need underlying form for settings
  const { getForm } = useFormActionCreator();
  const { currentForm: underlyingForm } = useSelector(
    (state: RootState) => state.form
  );
  const [isLoadingUnderlyingForm, setIsLoadingUnderlyingForm] =
    React.useState(true);

  const {
    props: createWorkspaceModalProps,
    isCreating: isCreatingWorkspace,
    onCreateExtractorWorkspaceClick,
  } = useCreateWorkspaceModalHandle(
    containerState.state === "success"
      ? BriefExtractorMapper.fromCustomModel(containerState.customModel)
      : null
  );

  const isFSLModel =
    containerState.state === "success" &&
    containerState.customModel.config.isFSLModel;

  const isStandardModelEnabled =
    containerState.state === "success" &&
    (containerState.customModel?.config.fslModelState?.isStandardModelEnabled ??
      false);

  const { updateFormExtractorSettings, handleConflictAndSaveForm } =
    useFormActionCreator();

  const {
    updateCustomModelExtractorSettings,
    renameCustomModel,
    exportCustomModel,
  } = useCustomModelActionCreator();

  const toast = useToast();

  const onSaveExtractorSettings = React.useCallback(
    async (extractorSettings: ExtractorSettings) => {
      try {
        await updateFormExtractorSettings(extractorSettings, {
          updateMerchantAutoExtractionItem: true,
        });
        const { didOverwrite } = await handleConflictAndSaveForm();
        await updateCustomModelExtractorSettings(
          extractorSettings,
          didOverwrite
        );
        renameCustomModel(extractorSettings.extractorName);
        toast.success("form_editor.setting_is_saved");
      } catch (e: unknown) {
        if (e instanceof FOCRError) {
          toast.error(e.messageId, undefined, e.detail);
        } else {
          console.error(e);
          toast.error(
            errors.UnknownError.messageId,
            undefined,
            errors.UnknownError.detail
          );
        }
      }
    },
    [
      handleConflictAndSaveForm,
      renameCustomModel,
      toast,
      updateCustomModelExtractorSettings,
      updateFormExtractorSettings,
    ]
  );

  const useExtractorSettingHandleReturns = useExtractorSettingHandle(
    containerState.state === "success" &&
      containerState.customModel?.formID === underlyingForm?.id
      ? {
          customModel: containerState.customModel,
          form: underlyingForm,
          onSave: onSaveExtractorSettings,
        }
      : { onSave: async () => {} }
  );

  const { onOpenScriptModal, props: scriptModalProps } = useScriptEditorHandle({
    scriptType: "post_process_script",
    ...(containerState.state === "success" &&
    containerState.customModel?.formID === underlyingForm?.id
      ? {
          form: {
            ...underlyingForm,
            config: {
              ...underlyingForm.config,
              post_process_script:
                useExtractorSettingHandleReturns.extractorSettings
                  .postProcessingScript ??
                underlyingForm.config.post_process_script,
            },
          },
          onSaveScript:
            useExtractorSettingHandleReturns.onChangePostProcessingScript,
        }
      : { onSaveScript: () => {} }),
  });

  const {
    onOpenScriptModal: onOpenTransformResponseScriptModal,
    props: transformResponseScriptModalProps,
  } = useScriptEditorHandle({
    scriptType: "transform_response_script",
    ...(containerState.state === "success" &&
    containerState.customModel?.formID === underlyingForm?.id
      ? {
          form: {
            ...underlyingForm,
            config: {
              ...underlyingForm.config,
              transform_response_script:
                useExtractorSettingHandleReturns.extractorSettings
                  .transformResponseScript ??
                underlyingForm.config.transform_response_script,
            },
          },
          onSaveScript:
            useExtractorSettingHandleReturns.onChangeTransformResponseScript,
        }
      : { onSaveScript: () => {} }),
  });

  const formImageProps: FormImageSettingProps | undefined =
    React.useMemo(() => {
      if (
        containerState.state !== "success" ||
        containerState.customModel?.formID !== underlyingForm?.id
      ) {
        return undefined;
      }
      return {
        imageType: "thumbnail",
        imageSrc: underlyingForm.image ?? null,
      };
    }, [
      // @ts-expect-error no data if state not success
      containerState.customModel?.formID,
      containerState.state,
      underlyingForm?.id,
      underlyingForm?.image,
    ]);

  const canUseRestrictedLLMModels = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.MoreLLMModels)
  );

  const { resourceOwnerId } = useSelector(
    (state: RootState) => state.resourceOwner
  );

  const [isExporting, setIsExporting] = React.useState(false);

  const onExport = React.useCallback(async () => {
    if (containerState.state !== "success") {
      return;
    }
    if (resourceOwnerId == null) {
      return;
    }
    setIsExporting(true);
    try {
      await exportCustomModel(containerState.customModel.id, resourceOwnerId);
    } catch {
      toast.error("error.fail_to_export_extractor");
    }
    setIsExporting(false);
  }, [resourceOwnerId, containerState, exportCustomModel, toast]);

  const { importCustomModel } = useCustomModelActionCreator();

  const shouldFetchUnderlyingForm = React.useRef(true);

  const onConfirmImportCustomModel = React.useCallback(
    async (file: File) => {
      if (containerState.state !== "success") {
        return;
      }
      const res = await importCustomModel(file, containerState.customModel.id);
      shouldFetchUnderlyingForm.current = true;
      return res;
    },
    [importCustomModel, containerState]
  );

  const {
    onClickImport: onClickImportCustomModel,
    isImporting: isImportingCustomModel,
    props: importCustomModelConfirmModalProps,
  } = useImportFormConfirmModalHandle(onConfirmImportCustomModel);

  const navigate = useNavigate();

  const navigateToEditAdvanceMerchantSetup = React.useCallback(() => {
    navigate(`/custom-model/${customModelId}/edit/advanceMerchantSetup`);
  }, [customModelId, navigate]);

  React.useEffect(() => {
    // Fetch underlying form
    // (1) init loading
    // (2) after imported
    if (
      containerState.state !== "success" ||
      containerState.customModel?.formID == null ||
      !shouldFetchUnderlyingForm.current
    ) {
      return;
    }
    setIsLoadingUnderlyingForm(true);
    getForm(containerState.customModel?.formID)
      .then(() => {
        setIsLoadingUnderlyingForm(false);
        useExtractorSettingHandleReturns.dataLogSettingHandle.queryDataLogSettingIfEnabled(
          containerState.customModel?.formID ?? "",
          "form"
        );
      })
      .finally(() => {
        shouldFetchUnderlyingForm.current = false;
      });
  }, [
    // @ts-expect-error containerState only has customModel if success
    containerState.customModel?.formID,
    containerState.state,
    getForm,
    useExtractorSettingHandleReturns.dataLogSettingHandle,
  ]);

  return React.useMemo(
    () => ({
      customModelId,
      containerState,
      highlightWorkspaceSetups:
        containerState.state === "success" &&
        containerState.customModel?.workspaces.length === 0,
      createWorkspaceModalProps,
      onCreateExtractorWorkspaceClick,
      isCreatingWorkspace,
      useExtractorSettingHandleReturns,
      onOpenScriptModal,
      onOpenTransformResponseScriptModal,
      scriptModalProps,
      transformResponseScriptModalProps,
      formImageProps,
      isLoadingUnderlyingForm,
      canUseRestrictedLLMModels,
      isFSLModel,
      isExporting,
      onExport,
      onClickImportCustomModel,
      isImportingCustomModel,
      importCustomModelConfirmModalProps,
      isStandardModelEnabled,
      navigateToEditAdvanceMerchantSetup,
    }),
    [
      customModelId,
      containerState,
      createWorkspaceModalProps,
      onCreateExtractorWorkspaceClick,
      isCreatingWorkspace,
      useExtractorSettingHandleReturns,
      onOpenScriptModal,
      onOpenTransformResponseScriptModal,
      scriptModalProps,
      transformResponseScriptModalProps,
      formImageProps,
      isLoadingUnderlyingForm,
      canUseRestrictedLLMModels,
      isFSLModel,
      isExporting,
      onExport,
      onClickImportCustomModel,
      isImportingCustomModel,
      importCustomModelConfirmModalProps,
      isStandardModelEnabled,
      navigateToEditAdvanceMerchantSetup,
    ]
  );
}

export default function CustomModelSettingContainer() {
  const props = useCustomModelSettingContainer();

  const settings =
    props.containerState.state === "success" &&
    !props.isLoadingUnderlyingForm ? (
      <ExtractorSettingSection
        isCustomModel={true}
        extractor={BriefExtractorMapper.fromCustomModel(
          props.containerState.customModel
        )}
        displayExtractorId={
          props.containerState.customModel?.formID ?? undefined
        }
        workspaces={props.containerState.customModel?.workspaces}
        showWorkspaceSetups={true}
        onCreateExtractorWorkspaceClick={props.onCreateExtractorWorkspaceClick}
        useHandleReturns={props.useExtractorSettingHandleReturns}
        onClickEditPostProcessingScript={props.onOpenScriptModal}
        onClickEditTransformResponseScript={
          props.onOpenTransformResponseScriptModal
        }
        isImportingForm={props.isImportingCustomModel}
        isExportingForm={props.isExporting}
        onClickImportForm={
          props.isFSLModel ? props.onClickImportCustomModel : undefined
        }
        importDisabled={props.isStandardModelEnabled}
        onClickExportForm={props.isFSLModel ? props.onExport : undefined}
        formImageProps={props.formImageProps}
        allowedProcessingModes={[
          ProcessingMode.PerPage,
          ...(props.containerState.isFSLModel ? [ProcessingMode.PerFile] : []),
          ProcessingMode.MultipleDocumentsPerPage,
        ]}
        allowedLLMModels={
          props.containerState.isFSLModel
            ? [
                ...PubliclyAvailableLLMModels,
                ...(props.canUseRestrictedLLMModels ? RestrictedLLMModels : []),
              ]
            : undefined
        }
      >
        {/* 
            The items to be visible vary depending on different item types. 
            So far, the order is consistent, but it can't be guaranteed. 
            Using a HOC style is more flexible. It should migrate the old code 
            to the new style
          */}
        <AdvancedPatternMatchingSetting
          extractorSettings={
            props.useExtractorSettingHandleReturns.extractorSettings
          }
          setExtractorSettings={
            props.useExtractorSettingHandleReturns.setExtractorSettings
          }
          onSetupClick={props.navigateToEditAdvanceMerchantSetup}
        />
      </ExtractorSettingSection>
    ) : null;

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={props.containerState.state === "loading"} />
      <LoadingModal
        messageId="common.importing"
        isOpen={props.isImportingCustomModel}
      />
      <Main hasTop={true}>
        {props.containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form" />
        ) : props.containerState.state === "success" &&
          !props.isLoadingUnderlyingForm ? (
          <>
            {props.isFSLModel ? (
              <FSLNavBarLayout selectedTab={FSLTab.Settings}>
                {settings}
              </FSLNavBarLayout>
            ) : (
              <CustomModelNavBarLayout
                selectedTab={CustomModelNavTabKey.Settings}
                customModel={props.containerState.customModel}
              >
                {settings}
              </CustomModelNavBarLayout>
            )}
            <CreateWorkspaceModal {...props.createWorkspaceModalProps} />
            <LoadingModal
              messageId="common.uploading"
              isOpen={props.isCreatingWorkspace}
            />
            {props.scriptModalProps != null ? (
              <ScriptEditorModal {...props.scriptModalProps} />
            ) : null}

            {props.importCustomModelConfirmModalProps.file != null ? (
              <ImportFormConfirmModal
                {...props.importCustomModelConfirmModalProps}
              />
            ) : null}
            {props.transformResponseScriptModalProps != null ? (
              <ScriptEditorModal {...props.transformResponseScriptModalProps} />
            ) : null}

            <DataNotSavedPrompt
              isDataChanged={props.useExtractorSettingHandleReturns.isFormDirty}
              titleTextId="extractor_setting.form_not_saved_prompt.title"
              messageTextId="extractor_setting.form_not_saved_prompt.save_warning"
              backTextId="extractor_setting.form_not_saved_prompt.go_back"
              continueTextId="extractor_setting.form_not_saved_prompt.leave_page"
            />
          </>
        ) : null}
        <LoadingModal
          messageId="common.loading"
          isOpen={props.isLoadingUnderlyingForm}
        />
      </Main>
    </Layout>
  );
}
