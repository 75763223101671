import { Link as FluentLink, ILinkProps } from "@fluentui/react";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

interface Props extends Omit<ILinkProps, "onAbort"> {
  to: string;
}

const Link: React.FC<Props> = (props: Props) => {
  const { to, children, ...rest } = props;

  return to ? (
    <FluentLink as={RouterLink as any} to={to} {...rest}>
      {children}
    </FluentLink>
  ) : (
    <FluentLink {...rest}>{children}</FluentLink>
  );
};

export default Link;
