import * as React from "react";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import FormExtractionModeSelector from "../components/FormExtractionModeSelector";
import { Layout, Main, Right, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { OCRTest } from "../components/OCRTest";
import {
  PrebuiltExtractorDefinition,
  isPrebuiltExtractors,
} from "../constants/prebuiltExtractor";
import HeaderContainer from "../containers/Header";
import { useExtractTest } from "../hooks/extract_test";
import { useCommonFormContainerState } from "../hooks/form";
import { useUnsafeParams } from "../hooks/params";
import { FormNavBarLayout, FormNavTabKey } from "./FormNavBarLayout";

type PathParam = {
  formId: string;
};

export const FormTestContainerV1 = React.memo(() => {
  const { formId } = useUnsafeParams<PathParam>();

  const containerState = useCommonFormContainerState(formId);

  const {
    extractWithFile,
    extractWithURL,
    isRecognizing,
    isUploading,
    testReport,
    extractionMode,
    onExtractionModeChanged,
    shouldShowFormExtractionModeSelector,
    setShouldShowFormExtractionModeSelector,
  } = useExtractTest(
    containerState.state === "success" ? containerState.form.id : undefined
  );

  const prebuiltExtractorDefinition =
    containerState.state === "success" &&
    containerState.form.config.prebuilt_extractor &&
    isPrebuiltExtractors(containerState.form.config.prebuilt_extractor)
      ? PrebuiltExtractorDefinition[
          containerState.form.config.prebuilt_extractor
        ]
      : undefined;

  const onCloseFormExtractionModeSelector = () => {
    setShouldShowFormExtractionModeSelector(false);
  };

  const onChangeExtractionModeClicked = () => {
    setShouldShowFormExtractionModeSelector(true);
  };

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form" />
        ) : containerState.state === "success" ? (
          <>
            <FormNavBarLayout
              selectedTab={FormNavTabKey.TestExtractorV1}
              form={containerState.form}
            >
              <OCRTest
                resourceName={containerState.form.name}
                samples={
                  prebuiltExtractorDefinition &&
                  prebuiltExtractorDefinition.sampleImages
                }
                description={
                  prebuiltExtractorDefinition?.helpMessageId
                    ? {
                        url: prebuiltExtractorDefinition.helpUrl,
                        messageId: prebuiltExtractorDefinition.helpMessageId,
                      }
                    : undefined
                }
                onSelectImage={extractWithFile}
                onSelectSampleImage={extractWithURL}
                ocrTestReport={testReport}
                extractionMode={extractionMode}
                onChangeExtractionModeClicked={onChangeExtractionModeClicked}
              />
            </FormNavBarLayout>
            {isRecognizing && (
              <LoadingModal
                messageId="ocr_test.extracting"
                isOpen={isRecognizing}
              />
            )}
            {isUploading && (
              <LoadingModal messageId="common.uploading" isOpen={isUploading} />
            )}
          </>
        ) : null}
      </Main>
      {shouldShowFormExtractionModeSelector && (
        <Right hasTop={true}>
          <FormExtractionModeSelector
            extractionMode={extractionMode}
            onExtractionModeChanged={onExtractionModeChanged}
            onClose={onCloseFormExtractionModeSelector}
          />
        </Right>
      )}
    </Layout>
  );
});

export default FormTestContainerV1;
