@import "../../styles/variables.scss";

.form-extraction-mode-selector {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}

.top {
  display: flex;
  flex: 0;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 20px 20px 0;

  h3 {
    font-size: 16px;
    padding-bottom: 0;
  }
  button {
    color: $dark-color;
    i {
      font-size: 12px;
      font-weight: bolder;
    }
  }
}

.tabs {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  align-self: stretch;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 15px 20px;
  user-select: none;
}

.tab {
  cursor: pointer;
  padding: 10px;
  border: 3px solid transparent;

  .inner {
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
    width: 176px;
    border-radius: 4px;
  }

  .bottom {
    padding: 12px;

    h3 {
      font-size: 14px;
      color: #323130;
    }

    span {
      font-size: 12px;
      color: #605e5c;
      line-height: 16px;
      display: inline-block;
    }
  }

  &.selected {
    border: 3px solid #25d0b1;
    border-radius: 8px;
  }
}
