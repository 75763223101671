@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
  flex-shrink: 0;
  font-size: 10pt;
  display: flex;
  flex-direction: row;
  width: $left-bar-full-width;
  background-color: #fff;
  margin-left: 0;

  .collapse-button-bar {
    height: inherit;
    background-color: #f6f6f6;
    border-left: 1px solid $separator-color;
    border-right: 1px solid $separator-color;
    width: calc(#{$left-bar-collapsed-width} - 6px);

    cursor: pointer;
  }
  .collapse-button-bar:hover {
    background-color: darken($color: #f8f8f8, $amount: 5%);
  }

  .button-collapsed {
    width: $left-bar-collapsed-width;
    background-image: url("../../images/icon-expand-right.png");
    background-size: 8px 8px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.container-collapsed {
  margin-left: calc(16px - $left-bar-full-width);
}

@media screen and (max-width: 848px) {
  .container {
    width: $left-bar-full-width-small-device;

    .collapse-button-bar {
      display: none;
    }
  }

  .container-collapsed {
    margin-left: calc(16px - $left-bar-full-width-small-device);
  }
}

.content {
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  .list {
    display: flex;
    flex-direction: column;
    .items {
      .section-header {
        margin-top: 0px;
        padding: 12px 10px;
        > p {
          font-size: 10px;
          font-weight: 700;
          vertical-align: middle;
          line-height: 20px;
        }
        height: 44px;
        display: flex;
        align-items: center;
      }

      .section-header:not(:first-child) {
        margin-top: 10px;
      }

      .tawk-padding {
        height: 100px;
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
}

.powered-by-formx {
  margin: 16px 12px;
  font-size: 12px;
  color: #605e5c;
}

.left-bar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $separator-color;

  &.selected {
    border-left: 5px solid #25d0b1;
    background: #edebe9;

    .content {
      padding-left: 25px; /* 30px - 5px */
    }
  }

  a {
    cursor: pointer;
    display: block;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 5px;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    padding: 12px 30px;
  }
}

.beta-tag {
  flex: 0 0 0%;
  height: min-content;
}
