import * as yup from "yup";

export const keywordsExtractionType = yup
  .string()
  .oneOf(["between", "after", "before"])
  .defined();

export const keywordsExtractionAnchorSchema = yup
  .object({
    text: yup.string().defined(),
    useFuzzySearch: yup.boolean().defined(),
    isIgnoreWhiteSpace: yup.boolean().defined(),
  })
  .camelCase();

export const keywordsExtractionParamsSchema = yup.object({
  type: keywordsExtractionType.defined(),
  before: keywordsExtractionAnchorSchema.defined().nullable(),
  after: keywordsExtractionAnchorSchema.defined().nullable(),
});

export type KeywordsExtractionType = yup.InferType<
  typeof keywordsExtractionType
>;

export type KeywordsExtractionAnchor = yup.InferType<
  typeof keywordsExtractionAnchorSchema
>;
export type KeywordsExtractionAnchorResp = {
  text: string;
  use_fuzzy_search: boolean;
  is_ignore_white_space: boolean;
};

export type KeywordsExtractionParams = yup.InferType<
  typeof keywordsExtractionParamsSchema
>;
export interface KeywordsExtractionParamsResp {
  type: KeywordsExtractionType;
  before: KeywordsExtractionAnchorResp | null;
  after: KeywordsExtractionAnchorResp | null;
}
