import { Icon } from "@fluentui/react";
import classnames from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";

import { AppConfig } from "../../config";
import { RESTRICTED_PREBUILT_EXTRACTOR_FLAGS } from "../../constants";
import { PrebuiltTemplatedExtractorDefinition } from "../../constants/templatedInstantModelExtractor";
import { CREATABLE_PREBUILT_TEMPLATED_EXTRACTORS } from "../../constants/userFeature";
import { useLocale } from "../../contexts/locale";
import { useGtm } from "../../hooks/gtm";
import { RootState } from "../../redux/types";
import {
  PrebuiltExtractor,
  PrebuiltTemplatedExtractor,
  isPrebuiltExtractors,
  mapExtractorTypeToMessageId,
} from "../../types/extractor";
import RequestPreviewExtractorDialog from "../RequestPreviewExtractorDialog";
import styles from "./styles.module.scss";

interface BlockProps {
  title: string;
  icon: string;
  onClick?(): void;
}

function PrebuiltExtractorBlock(props: BlockProps) {
  return (
    <div className={styles["grid-item"]} onClick={props.onClick}>
      <div className={styles["large-header"]}>
        <div className={styles["icon"]}>
          <Icon iconName={props.icon} />
        </div>
        <div className={styles["inline-header"]}>{props.title}</div>
      </div>
    </div>
  );
}

interface LargeBlockProps extends BlockProps {
  description: string;
  alternateDescription?: string;
  className?: string;
}

function LargeBlock(props: LargeBlockProps) {
  return (
    <div
      className={classnames(styles["grid-item"], props.className)}
      onClick={props.onClick}
    >
      <div className={styles["large-header"]}>
        <div className={styles["icon"]}>
          <Icon iconName={props.icon} />
        </div>
        <div className={styles["inline-header"]}>{props.title}</div>
      </div>

      <div className={styles["description"]}>{props.description}</div>
      {props.alternateDescription && (
        <div className={styles["alt-description"]}>
          {props.alternateDescription}
        </div>
      )}
    </div>
  );
}

interface Props {
  onCreateCustomModel(): void;
  onCreateFixedLayoutExtractor(): void;
  onCreateCombinedExtractor(): void;
  onCreatePrebuiltExtractor(type: PrebuiltTemplatedExtractor): void;
  onImport(): void;
}

export function ExtractorCreateSection(props: Props) {
  const {
    onCreateCustomModel,
    onCreateFixedLayoutExtractor,
    onCreateCombinedExtractor,
    onCreatePrebuiltExtractor,
  } = props;

  const { localized } = useLocale();

  const [requestedPreviewExtractor, setRequestedPreviewExtractor] =
    React.useState<PrebuiltExtractor | null>(null);

  const isFeatureEnabled = useSelector((state: RootState) =>
    state.resourceOwner.isFeatureEnabled()
  );

  const isPrebuiltExtractorsAvailable = React.useCallback(
    extractor => {
      const flag = RESTRICTED_PREBUILT_EXTRACTOR_FLAGS.get(extractor);
      return !flag || isFeatureEnabled(flag);
    },
    [isFeatureEnabled]
  );

  const { pushClickedImportExtractor } = useGtm();

  const onImport = React.useCallback(() => {
    props.onImport();
    pushClickedImportExtractor();
  }, [props, pushClickedImportExtractor]);

  const onClick = React.useCallback(
    (extractor: PrebuiltTemplatedExtractor) => {
      if (isPrebuiltExtractorsAvailable(extractor)) {
        onCreatePrebuiltExtractor(extractor);
      } else if (isPrebuiltExtractors(extractor)) {
        setRequestedPreviewExtractor(extractor);
      }
    },
    [isPrebuiltExtractorsAvailable, onCreatePrebuiltExtractor]
  );

  return (
    <div className={styles["container"]}>
      <div className={styles["section"]}>
        <h3>{localized("extractor.prebuilt.title")}</h3>

        <div className={styles["pre-built-grid"]}>
          {CREATABLE_PREBUILT_TEMPLATED_EXTRACTORS.map(extractor => {
            const definition = PrebuiltTemplatedExtractorDefinition[extractor];

            return (
              <PrebuiltExtractorBlock
                title={localized(mapExtractorTypeToMessageId(extractor))}
                icon={definition.icon}
                key={extractor}
                onClick={() => {
                  onClick(extractor);
                }}
              />
            );
          })}
        </div>
      </div>

      <div className={styles["section"]}>
        <h3>{localized("extractor.custom.title")}</h3>

        <div className={styles["large-grid"]}>
          <LargeBlock
            className={styles["fixed-layout-block"]}
            title={localized("extractor.fixed_layout_extractor")}
            description={localized("extractor.fixed_layout_extractor.desc.1")}
            alternateDescription={localized(
              "extractor.fixed_layout_extractor.desc.2"
            )}
            icon={"IconFixedLayout"}
            onClick={onCreateFixedLayoutExtractor}
          />
          {AppConfig.disableCustomModel ? null : (
            <LargeBlock
              className={styles["custom-model-block"]}
              title={localized("extractor.custom_model_extractor")}
              description={localized("extractor.custom_model_extractor.desc.1")}
              alternateDescription={localized(
                "extractor.custom_model_extractor.desc.2"
              )}
              icon={"IconBoxModel"}
              onClick={onCreateCustomModel}
            />
          )}
        </div>
      </div>

      <div className={styles["section"]}>
        <h3>{localized("extractor.others.title")}</h3>
        <div className={styles["large-grid"]}>
          <LargeBlock
            title={localized("extractor.combine_extractors")}
            description={localized("extractor.combine_extractors.desc")}
            icon={"IconPuzzle"}
            onClick={onCreateCombinedExtractor}
          />
          <LargeBlock
            title={localized("extractor.import_extractors")}
            description={localized("extractor.import_extractors.desc")}
            icon={"IconFileImport"}
            onClick={onImport}
          />
        </div>
      </div>
      <RequestPreviewExtractorDialog
        type={requestedPreviewExtractor}
        onClose={() => setRequestedPreviewExtractor(null)}
      />
    </div>
  );
}
