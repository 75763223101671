import { CONTROL_POINT_SIZE } from "../constants";
import { Anchor } from "../types/anchor";
import { DetectionRegion } from "../types/detectionRegion";
import { Field } from "../types/field";
import { Vertex } from "../types/vertex";
import { CanvasStore } from "./store";
import { bbox2polygon } from "./utils";

export class Renderer {
  context: CanvasRenderingContext2D;
  width: number;
  height: number;

  constructor(canvas: HTMLCanvasElement) {
    const context = canvas.getContext("2d");

    if (!context) {
      throw new Error("Cannot create 2d context from canvas");
    }

    this.context = context;
    this.width = canvas.width;
    this.height = canvas.height;
  }

  clear = () => {
    this.context.clearRect(0, 0, this.width, this.height);
  };

  drawDot = (
    center: Vertex,
    radius: number,
    fillStyle: string | CanvasGradient | CanvasPattern
  ) => {
    if (radius <= 0) return;

    this.context.save();
    this.context.beginPath();
    this.context.arc(center.x, center.y, radius, 0, 2 * Math.PI, false);
    this.context.fillStyle = fillStyle;
    this.context.fill();
    this.context.restore();
  };

  drawPolygon = (
    vertices: Vertex[],
    options: {
      fillStyle?: string | CanvasGradient | CanvasPattern;
      strokeStyle?: string | CanvasGradient | CanvasPattern;
      lineWidth?: number;
      lineDash?: number[];
      isOpenPath?: boolean;
    }
  ) => {
    if (vertices.length < 2) return;

    this.context.save();
    this.context.beginPath();
    this.context.moveTo(vertices[0].x, vertices[0].y);
    for (let i = 1; i < vertices.length; i++) {
      this.context.lineTo(vertices[i].x, vertices[i].y);
    }

    if (!options.isOpenPath) {
      this.context.closePath();
    }

    if (options.fillStyle) {
      this.context.fillStyle = options.fillStyle;
      this.context.fill();
    }

    if (options.strokeStyle) {
      this.context.strokeStyle = options.strokeStyle;
    }

    if (options.lineWidth) {
      this.context.lineWidth = options.lineWidth;
      if (options.lineDash) {
        this.context.setLineDash(options.lineDash);
      }
      this.context.stroke();
    }

    this.context.restore();
  };

  drawText = (
    origin: Vertex,
    text: string,
    options: {
      fillStyle?: string | CanvasGradient | CanvasPattern;
      font?: string;
    }
  ) => {
    this.context.save();

    if (options.fillStyle) {
      this.context.fillStyle = options.fillStyle;
    }

    if (options.font) {
      this.context.font = options.font;
    }

    this.context.fillText(text, origin.x, origin.y);

    this.context.restore();
  };

  renderAnchors = (anchors: Anchor[], selectedAnchorId?: string) => {
    for (const anchor of anchors) {
      this.drawPolygon(anchor.vertices, {
        fillStyle: "rgba(255,0,0,0.3)",
      });

      if (selectedAnchorId === anchor.id) {
        for (const vertex of anchor.vertices) {
          this.drawDot(vertex, CONTROL_POINT_SIZE, "rgba(255,0,0,0.75)");
        }
      }
    }
  };

  renderFields = (fields: Field[], selectedFieldId?: string) => {
    let selectedField;
    for (const field of fields) {
      if (field.id === selectedFieldId) {
        selectedField = field;
      }

      this.renderField(field);
    }

    if (selectedField) {
      this.drawPolygon(bbox2polygon(selectedField.bbox), {
        strokeStyle: "rgba(28,120,0,0.75)",
        lineWidth: 3,
      });

      const bottomRight = {
        x: selectedField.bbox.right,
        y: selectedField.bbox.bottom,
      };
      this.drawDot(bottomRight, CONTROL_POINT_SIZE, "rgba(28,120,0,0.75)");
    }
  };

  renderDetectionRegions = (
    detectionRegions: DetectionRegion[],
    selectedDetectionRegionId?: string
  ) => {
    let selectedDetectionRegion;
    for (const detectionRegion of detectionRegions) {
      if (detectionRegion.id === selectedDetectionRegionId) {
        selectedDetectionRegion = detectionRegion;
      }

      this.renderDetectionRegion(detectionRegion);
    }

    if (selectedDetectionRegion) {
      this.drawPolygon(bbox2polygon(selectedDetectionRegion.bbox), {
        strokeStyle: "rgba(0,62,156,0.75)",
        lineWidth: 3,
      });

      const bottomRight = {
        x: selectedDetectionRegion.bbox.right,
        y: selectedDetectionRegion.bbox.bottom,
      };
      this.drawDot(bottomRight, CONTROL_POINT_SIZE, "rgba(0,62,156,0.75)");
    }
  };

  renderField = (field: Field) => {
    this.drawPolygon(bbox2polygon(field.bbox), {
      fillStyle: "rgba(0,255,0,0.3)",
    });

    this.drawText(
      {
        x: field.bbox.left + 4,
        y: field.bbox.top + 16,
      },
      field.label,
      {
        fillStyle: "rgba(28,120,0,0.5)",
        font: "14px monospace",
      }
    );
  };

  renderDetectionRegion = (detectionRegion: DetectionRegion) => {
    this.drawPolygon(bbox2polygon(detectionRegion.bbox), {
      fillStyle: "rgba(0,0,255,0.3)",
    });
  };

  renderPartialAnchor = (vertices: Vertex[]) => {
    this.drawPolygon(vertices, {
      strokeStyle: "rgba(255,0,0,0.3)",
      lineWidth: 2,
      lineDash: [5, 15],
      isOpenPath: true,
    });

    for (const vertex of vertices) {
      this.drawDot(vertex, CONTROL_POINT_SIZE, "rgba(255,0,0,0.75)");
    }
  };

  render = (
    store: CanvasStore,
    selectedAnchorId?: string,
    selectedFieldId?: string,
    selectedDetectionRegionId?: string
  ) => {
    this.clear();
    this.renderAnchors(store.anchors, selectedAnchorId);
    this.renderFields(store.fields, selectedFieldId);
    this.renderDetectionRegions(
      store.detectionRegions,
      selectedDetectionRegionId
    );
    this.renderPartialAnchor(store.partialAnchorVertices);

    if (store.creatingField) {
      this.renderField(store.creatingField);
    }

    if (store.creatingDetectionRegion) {
      this.renderDetectionRegion(store.creatingDetectionRegion);
    }
  };
}
