@import "../../styles/variables.scss";

.modal {
  h2 {
    text-transform: capitalize;
    margin-bottom: 20px;
  }

  .message {
    margin-bottom: 20px;
    text-align: center;
  }
  .buttons {
    text-align: center;
  }
}

.challenge {
  color: $light-gray-color;
}

.destroy-button {
  background-color: #e13d3d;
  color: white;
  border-width: 0;
}

.destroy-button:hover {
  background-color: #bd1c1c;
  color: white;
}

.destroy-button:disabled {
  background-color: $button-disabled-color;
  color: $disabled-color;
}

.save-button {
  background-color: $attention-color;
  border-width: 0;
}

.save-button:hover {
  background-color: darken($color: $attention-color, $amount: 20%);
}
