@import "../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $neutral-lighter;
  padding: 12px 16px;
  margin-bottom: 10px;

  button:last-child {
    height: 32px;
    margin-bottom: 3px;
  }
}

.input-block {
  width: 100%;
  margin-bottom: 12px;

  :global(label.ms-Dropdown-label) {
    font-size: 16px;
  }
}

.format-label {
  display: flex;
  align-items: center;

  .info-icon {
    padding-top: 5px;
    padding-left: 5px;
    cursor: default;
  }
}
