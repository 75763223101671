import { TextField } from "@fluentui/react";
import cn from "classnames";
import * as React from "react";

import { JSON_INDENTATION } from "../../constants/format";
import {
  ExtractDataFieldType,
  ExtractHTMLTableData,
  ExtractListTableData,
} from "../../types/extractDataField";
import { SmallIconButton } from "../SmallIconButton";
import { ActionButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

function RawValue({ value }: { value: any }) {
  if (value === null) {
    return <></>;
  }

  return (
    <pre className={styles["raw-json-viewer"]}>
      {JSON.stringify(value, null, JSON_INDENTATION)}
    </pre>
  );
}

type HTMLTableProps = {
  label: string;
  value: ExtractHTMLTableData;
  className?: string;
  actions?: React.ReactNode;
};

export function ExtractHTMLTableDataField(props: HTMLTableProps) {
  const { label, value, actions } = props;
  const className = cn(styles["data-item-table"], props.className);

  let content = null;
  try {
    content = value.map((row, rowIndex) => {
      return row.map((cell, index) => {
        if (cell == null) {
          return null;
        }
        return (
          <div
            className={styles["data-item-table-cell"]}
            key={`row/${rowIndex}/cell/${index}`}
            style={{
              gridColumn: `${cell.colStart} / ${cell.colEnd}`,
              gridRow: `${cell.rowStart} / ${cell.rowEnd}`,
            }}
          >
            {cell.text}
          </div>
        );
      });
    });
  } catch {}

  return (
    <div className={className}>
      <div className={styles["data-item-table-topbar"]}>
        <div className={styles["data-item-table-label"]}>{label}</div>
        {actions}
      </div>
      <div className={styles["data-item-table-scrollable"]}>
        <div
          className={styles["data-item-table-content"]}
          style={{
            display: "grid",
            gridTemplateRows: "repeat(32px)",
          }}
        >
          {content}
        </div>
      </div>
    </div>
  );
}

type ListTableProps = {
  label: string;
  value: ExtractListTableData;
  className?: string;
  actions?: React.ReactNode;
  isEditable?: boolean;
  onChange?: (value: any) => void;
};

const TRASH_HEADER_KEY = "<<<TRASH-HEADER-VALUE>>>";

export function ExtractListTableDataField(props: ListTableProps) {
  const { label, value, actions, onChange } = props;
  const tableClassName = cn(styles["data-item-table"], props.className);
  const isEditable = props.isEditable ?? false;

  const listValue = React.useMemo(() => {
    const { rows } = value;
    const normalized = Array.isArray(rows) ? rows : rows == null ? [] : [rows];
    return normalized.map(item => {
      return item == null ? {} : item;
    });
  }, [value]);

  const headers = React.useMemo(() => {
    const ret = [...value.headers];

    if (isEditable) {
      ret.push(TRASH_HEADER_KEY);
    }
    return ret;
  }, [value.headers, isEditable]);

  const appendRow = React.useCallback(() => {
    const newValue = [...listValue, {}];
    onChange?.(newValue);
  }, [listValue, onChange]);

  const removeRow = React.useCallback(
    (index: number) => {
      const newValue = [...listValue];
      newValue.splice(index, 1);
      onChange?.(newValue);
    },
    [onChange, listValue]
  );

  const setField = React.useCallback(
    (index: number, key: string, value: string) => {
      const newValue = [...listValue];
      const newRow = { ...newValue[index], [key]: value };
      newValue[index] = newRow;
      onChange?.(newValue);
    },
    [onChange, listValue]
  );

  return (
    <div className={tableClassName}>
      <div className={styles["data-item-table-topbar"]}>
        <div className={styles["data-item-table-label"]}>{label}</div>
        {actions}
      </div>
      {listValue.length > 0 && (
        <div className={styles["data-item-table-scrollable"]}>
          <div
            className={styles["data-item-table-content"]}
            style={{
              display: "grid",
              gridTemplateColumns: isEditable
                ? `repeat(${headers.length - 1}, 1fr) 44px`
                : `repeat(${headers.length}, 1fr)`,
              gridTemplateRows: "repeat(32px)",
            }}
          >
            {headers.map(header => {
              if (header === TRASH_HEADER_KEY) {
                return (
                  <div
                    className={styles["data-item-table-header-cell"]}
                    key={`header/${header}`}
                  ></div>
                );
              }
              return (
                <div
                  className={styles["data-item-table-header-cell"]}
                  key={`header/${header}`}
                >
                  {header}
                </div>
              );
            })}
            {listValue.map((row, index) => {
              return headers.map(header => {
                const cellValue = row[header];
                const className =
                  header === TRASH_HEADER_KEY
                    ? styles["data-item-table-trash-cell"]
                    : isEditable
                    ? styles["data-item-table-edit-cell"]
                    : styles["data-item-table-cell"];

                return (
                  <div
                    className={className}
                    key={`row/${index}/cell/${header}`}
                  >
                    <>
                      {header === TRASH_HEADER_KEY ? (
                        <SmallIconButton
                          iconName="IconTrash"
                          onClick={() => removeRow(index)}
                        />
                      ) : isEditable ? (
                        <TextField
                          value={cellValue}
                          onChange={(_, newValue) => {
                            if (newValue == null) {
                              return;
                            }
                            setField(index, header, newValue);
                          }}
                        />
                      ) : typeof cellValue === "string" ? (
                        cellValue
                      ) : (
                        <RawValue value={cellValue} />
                      )}
                    </>
                  </div>
                );
              });
            })}
          </div>
          {isEditable && (
            <ActionButton
              className={styles["content-list-value-field-add-button"]}
              iconName="Add"
              textId="extract_table_data_field.add_new_row"
              onClick={appendRow}
            />
          )}
        </div>
      )}
    </div>
  );
}

export enum ExtractTableDataType {
  ListTable = ExtractDataFieldType.ListTable,
  HTMLTable = ExtractDataFieldType.HTMLTable,
}

type Props = {
  type: ExtractTableDataType;
  label: string;
  value: ExtractHTMLTableData | ExtractListTableData | undefined;
  className?: string;
  actions?: React.ReactNode;
  isEditable?: boolean;
  onChange?: (value: any) => void;
};

export function ExtractTableDataField(props: Props) {
  const { type, value, onChange } = props;
  if (value === undefined) {
    return <></>;
  }

  switch (type) {
    case ExtractTableDataType.HTMLTable:
      return (
        <ExtractHTMLTableDataField
          {...props}
          value={value as ExtractHTMLTableData}
        />
      );
    case ExtractTableDataType.ListTable:
      return (
        <ExtractListTableDataField
          {...props}
          value={value as ExtractListTableData}
          onChange={onChange}
        />
      );
    default:
      return <></>;
  }
}
