import * as React from "react";

import { useAdminActionCreator } from "../actions/admin";
import { ListTeamOptions } from "../apiClient/mixin/team";
import { AppConfig, RegionsConfig, getRegionKeys } from "../config";
import errors, { FOCRError } from "../errors";
import { RootState } from "../redux/types";
import { CustomModel } from "../types/customModel";
import { Permission } from "../types/team";
import { UsageRange } from "../types/usage";
import { useNotifyError } from "./error";
import { useAppSelector } from "./redux";

const REGION_KEYS = getRegionKeys();

export function isPermissionError(error?: FOCRError): boolean {
  if (error === errors.PermissionDenied || error === errors.NotAuthenticated) {
    return true;
  }
  return false;
}

export function useFetchAllRegionTeams(
  options?: ListTeamOptions,
  size: number = 20,
  offset: number = 0,
  initialRegion?: string
): {
  refetchRegionTeamsWithOffset: (regions: string, offset: number) => void;
} {
  const { listTeams } = useAdminActionCreator();

  useNotifyError((state: RootState) =>
    Object.values(state.admin.teamList.teamsStateByRegion)
      .map(x => (isPermissionError(x.error) ? undefined : x.error))
      .reduce((acc, cur) => acc || cur, undefined)
  );

  React.useEffect(
    () => {
      for (const region of REGION_KEYS) {
        if (region === initialRegion) {
          listTeams(region, options, offset, size);
        } else {
          listTeams(region, options, 0, size);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, size]
  );

  const refetchRegionTeamsWithOffset = React.useCallback(
    (region, offset) => {
      listTeams(region, options, offset, size);
    },
    [listTeams, options, size]
  );

  return { refetchRegionTeamsWithOffset };
}

export function useFetchTeam(teamId: string, region: string) {
  const { getTeam } = useAdminActionCreator();

  useNotifyError((state: RootState) => state.admin.teamDetail.error);

  React.useEffect(() => {
    getTeam(teamId, region);
  }, [getTeam, teamId, region]);
}

export function useFetchTeamMembers(teamId: string, region: string) {
  const { getTeamMembers } = useAdminActionCreator();

  useNotifyError(
    (state: RootState) => state.admin.teamDetail.membersState.error
  );

  React.useEffect(() => {
    getTeamMembers(teamId, region);
  }, [getTeamMembers, teamId, region]);
}

export function useFetchTeamUsage(
  range: UsageRange,
  teamId: string,
  region: string,
  tokenIds: string[],
  isWorkerTokensLoaded: boolean
) {
  const { getTeamUsage } = useAdminActionCreator();

  useNotifyError(
    (state: RootState) => state.admin.teamDetail.usageState.usageError
  );

  React.useEffect(() => {
    if (isWorkerTokensLoaded) {
      getTeamUsage(range, teamId, region, tokenIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTeamUsage, range, teamId, region, isWorkerTokensLoaded]);
}

export function useFetchTeamRequestLogs(
  page: number,
  startFrom: Date,
  range: UsageRange,
  teamId: string,
  region: string,
  tokenIds: (string | null)[] | undefined,
  isWorkerTokensLoaded: boolean,
  size: number = 10
) {
  const { getRequestLogs } = useAdminActionCreator();

  useNotifyError(
    (state: RootState) => state.admin.teamDetail.usageState.requestLogError
  );

  React.useEffect(() => {
    if (isWorkerTokensLoaded) {
      const offset = isNaN(page) || page <= 0 ? 0 : size * (page - 1);
      getRequestLogs(
        size,
        offset,
        startFrom,
        range,
        teamId,
        region,
        ["extract", "extract_receipt_info", "extract_fields"],
        tokenIds
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getRequestLogs,
    page,
    startFrom,
    size,
    range,
    teamId,
    region,
    isWorkerTokensLoaded,
  ]);
}

export function useFetchTeamForms(
  size: number = 20,
  offset: number = 0,
  teamId: string,
  region: string
) {
  const { listTeamForms } = useAdminActionCreator();

  useNotifyError(
    (state: RootState) => state.admin.teamDetail.resourceState.formsState.error
  );

  React.useEffect(() => {
    listTeamForms(teamId, region, offset, size);
  }, [listTeamForms, teamId, region, offset, size]);
}

export function useFetchTeamFormGroups(
  size: number = 20,
  offset: number = 0,
  teamId: string,
  region: string
) {
  const { listTeamFormGroups } = useAdminActionCreator();

  useNotifyError(
    (state: RootState) =>
      state.admin.teamDetail.resourceState.formGroupsState.error
  );

  React.useEffect(() => {
    listTeamFormGroups(teamId, region, offset, size);
  }, [listTeamFormGroups, teamId, region, offset, size]);
}

export function useFetchTeamCustomModels(
  size: number = 20,
  offset: number = 0,
  teamId: string,
  region: string
) {
  const { listTeamCustomModels } = useAdminActionCreator();
  const [fetchedOnce, setFetchedOnce] = React.useState(false);

  useNotifyError(
    (state: RootState) =>
      state.admin.teamDetail.resourceState.customModelsState.error
  );

  React.useEffect(() => {
    listTeamCustomModels(teamId, region, offset, size).then(() => {
      setFetchedOnce(true);
    });
  }, [listTeamCustomModels, teamId, region, offset, size]);

  return React.useMemo(() => ({ fetchedOnce }), [fetchedOnce]);
}

export function useFetchCustomModel(customModelId: string, region: string) {
  const { getTeamCustomModel } = useAdminActionCreator();

  useNotifyError(
    (state: RootState) =>
      state.admin.teamDetail.resourceState.customModelsState
        .customModelDetailState.error
  );

  React.useEffect(() => {
    getTeamCustomModel(customModelId, region);
  }, [getTeamCustomModel, customModelId, region]);
}

export function useFetchTeamCustomModelImages(
  size: number = 20,
  offset: number = 0,
  customModelId: string,
  region: string
) {
  const { listTeamCustomModelImages } = useAdminActionCreator();

  useNotifyError(
    (state: RootState) =>
      state.admin.teamDetail.resourceState.customModelsState
        .customModelDetailState.customModelImageError
  );

  React.useEffect(() => {
    listTeamCustomModelImages(customModelId, region, offset, size);
  }, [listTeamCustomModelImages, customModelId, region, offset, size]);
}

export function useGetCustomModelAdminURL() {
  const user = useAppSelector(state => state.user.currentUser);

  const getCustomModelAdminURL = React.useCallback(
    (customModel: CustomModel) => {
      const region = AppConfig.region;
      const portalEndpoint = RegionsConfig.endpoints[region].portal;
      const team = user?.teams.find(
        team => team.id === customModel.resourceOwnerId
      );
      if (!team) {
        return "<no team>";
      }
      return `${portalEndpoint}/admin/team/${region}/${team.id}/resources/custom-models/${customModel.id}`;
    },
    [user]
  );

  return getCustomModelAdminURL;
}

export function useFetchReportTeamsUsages(
  range: UsageRange,
  limit: number,
  region: string
) {
  const { listTeamsUsages } = useAdminActionCreator();

  useNotifyError((state: RootState) =>
    Object.values(state.admin.report.teamRankingListByRegion)
      .map(x => (isPermissionError(x.error) ? undefined : x.error))
      .reduce((acc, cur) => acc || cur, undefined)
  );

  React.useEffect(() => {
    listTeamsUsages(range, limit, region);
  }, [listTeamsUsages, range, limit, region]);
}

export function useFetchReportNewTeams(
  minCreatedAt: string,
  size: number = 20,
  offset: number = 0,
  region: string
): {
  refetchRegionNewTeamsWithOffset: (regions: string, offset: number) => void;
} {
  const { listNewTeams } = useAdminActionCreator();

  useNotifyError((state: RootState) =>
    Object.values(state.admin.teamList.teamsStateByRegion)
      .map(x => (isPermissionError(x.error) ? undefined : x.error))
      .reduce((acc, cur) => acc || cur, undefined)
  );

  React.useEffect(
    () => {
      listNewTeams(region, minCreatedAt, offset, size);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const refetchRegionNewTeamsWithOffset = React.useCallback(
    (region, offset) => {
      listNewTeams(region, minCreatedAt, offset, size);
    },
    [listNewTeams, minCreatedAt, size]
  );

  return { refetchRegionNewTeamsWithOffset };
}

export function useFetchReportCustomModels(
  size: number = 20,
  offset: number = 0,
  region: string
): {
  refetchRegionCustomModelWithOffset: (regions: string, offset: number) => void;
} {
  const { listReportCustomModels } = useAdminActionCreator();

  useNotifyError((state: RootState) =>
    Object.values(state.admin.teamList.teamsStateByRegion)
      .map(x => (isPermissionError(x.error) ? undefined : x.error))
      .reduce((acc, cur) => acc || cur, undefined)
  );

  React.useEffect(
    () => {
      listReportCustomModels(region, offset, size);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const refetchRegionCustomModelWithOffset = React.useCallback(
    (region, offset) => {
      listReportCustomModels(region, offset, size);
    },
    [listReportCustomModels, size]
  );

  return { refetchRegionCustomModelWithOffset };
}

export function useInviteTeamUserModal(teamId: string, region: string) {
  const { inviteTeamUser } = useAdminActionCreator();

  const [isInviteTeamUserModalOpened, setIsInviteTeamUserModalOpened] =
    React.useState(false);
  const openInviteTeamUserModal = React.useCallback(() => {
    setIsInviteTeamUserModalOpened(true);
  }, []);
  const closeInviteTeamUserModal = React.useCallback(() => {
    setIsInviteTeamUserModalOpened(false);
  }, []);
  const onInviteTeamUser = React.useCallback(
    async (email: string, permission: Permission) => {
      return inviteTeamUser(email, permission, teamId, region);
    },
    [teamId, inviteTeamUser, region]
  );

  return React.useMemo(
    () => ({
      isInviteTeamUserModalOpened,
      openInviteTeamUserModal,
      closeInviteTeamUserModal,
      onInviteTeamUser,
    }),
    [
      isInviteTeamUserModalOpened,
      openInviteTeamUserModal,
      closeInviteTeamUserModal,
      onInviteTeamUser,
    ]
  );
}
