import { README_IO_PAGE_ORIGIN } from "../constants";
import { URLParamsKey } from "../hooks/searchParamUtils";

export function redirectToReadmeIO(
  authToken?: string,
  redirectURI?: string,
  openInNewTab: boolean = true
) {
  const destination = new URL(redirectURI ?? "", README_IO_PAGE_ORIGIN);
  destination.searchParams.append(URLParamsKey.authToken, authToken ?? "");

  const url = destination.toString();

  if (openInNewTab) {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.click();
  } else window.location.href = url;
}
