import { ActionButton, Label, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

interface Props {
  field1Id: string;
  field1Value: string;
  onField1ValueChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => void;
  field1ErrorMessage: string;

  field2Label: string;
  field2Key: string;

  onDeleteClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onSettingTrigger: () => void;
  settingEnabled: boolean;
}

const FieldCard = React.memo((props: Props) => {
  const { localized } = useLocale();
  const {
    field1Id,
    field1Value,
    onField1ValueChange,
    field1ErrorMessage,

    field2Label: field2Id,
    field2Key: field2Value,
    settingEnabled,

    onSettingTrigger: onSettingClick,
    onDeleteClick,
  } = props;

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["field-card"]}>
      <TextField
        labelId={field1Id}
        value={field1Value}
        onChange={onField1ValueChange}
        errorMessage={field1ErrorMessage}
        disabled={!hasPermissionToEditResource}
      />
      <div className={styles["field-extra-container"]}>
        <div className={styles["field2-container"]}>
          <Label className={styles["field2-label"]}>
            <FormattedMessage id={field2Id} />
          </Label>
          <Text className={styles["field2-text"]}>
            <FormattedMessage id={field2Value} />
          </Text>
        </div>
        <div className={styles["action-buttons-container"]}>
          <ActionButton
            iconProps={{ iconName: "Settings" }}
            className={classnames(
              styles["action-button"],
              styles["setting-button"]
            )}
            disabled={!settingEnabled}
            onClick={onSettingClick}
          >
            {localized(
              hasPermissionToEditResource
                ? "form_inspector.settings"
                : "form_inspector.view_settings"
            )}
          </ActionButton>
          {hasPermissionToEditResource && (
            <ActionButton
              iconProps={{ iconName: "Delete" }}
              className={classnames(
                styles["action-button"],
                styles["delete-button"]
              )}
              onClick={onDeleteClick}
              disabled={!hasPermissionToEditResource}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default FieldCard;
