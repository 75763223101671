@import "../../styles/variables.scss";

.signup-form {
  position: absolute;
  width: 350px;
  height: 476px;

  top: 50vh;
  left: 50vw;

  margin-top: -238px;
  margin-left: -175px;

  @media only screen and (max-width: 350px) {
    width: calc(100% - 20px); // stylelint-disable-line
    margin-left: 10px;
    margin-right: 10px;
    left: 0;
  }

  .logo {
    margin-bottom: 20px;
  }

  .link {
    text-align: right;
    font-size: 14px;
    margin-bottom: 14px;
  }

  > :global(.ms-TextField) {
    min-height: 82px;
  }

  .button {
    text-align: right;
  }
}
