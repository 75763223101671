.team-table-row {
  height: 43px;
  td:first-child {
    width: 100%;
  }
  td:nth-child(2) {
    min-width: 140px;
  }
}

.invite-btn {
  border: 1px solid #e1dfdd;
  color: #605e5c;
}

.team-table {
  td {
    padding: 0px 12px;
  }

  td:nth-child(3) {
    min-width: 140px;
  }
}

.team-members {
  margin-top: 20px;
}
