.panel {
  .option-detail {
    border-top: 1px solid #edebe9;
    margin-top: 20px;

    .key-value-config-view {
      padding: 26px 0;
    }
  }
  .footer {
    display: flex;
    justify-content: end;
    padding: 10px 0 30px;
    margin: 0 16px;
    border-top: 1px solid #edebe9;

    button {
      margin: 0 6px;
      &:last-child {
        margin: 0 0 0 6px;
      }
    }
  }
}
