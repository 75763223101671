import * as React from "react";

export enum ToastStyle {
  Default = "default", // default style
  Signup = "signup", // style for signup page
  Login = "login", // style for login page
}

function useMakeContext() {
  const [toastStyle, setToastStyle] = React.useState(ToastStyle.Default);

  return React.useMemo(
    () => ({
      toastStyle,
      setToastStyle,
    }),
    [toastStyle, setToastStyle]
  );
}

type ToastStyleContextValue = ReturnType<typeof useMakeContext>;
const ToastStyleContext = React.createContext<ToastStyleContextValue>(
  null as any
);

interface Props {
  children: React.ReactNode;
}

export const ToastStyleProvider = (props: Props) => {
  const value = useMakeContext();
  return <ToastStyleContext.Provider {...props} value={value} />;
};

export function useToastStyle() {
  return React.useContext(ToastStyleContext);
}
