import { Checkbox, Icon, PrimaryButton, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React, { useCallback, useState } from "react";

import { ToolBoxTutorialTargetIds } from "../../constants/layout";
import { TutorialStep } from "../../containers/FixedLayoutFormTutorial";
import { useLocale } from "../../contexts/locale";
import FixedLayoutFormTutorialBubble from "../FixedLayoutFormTutorialBubble";
import styles from "./styles.module.scss";

interface ContentProps {
  shouldNotShowAgain: boolean;
  setShouldNotShowAgainChange: (shouldNotShowAgain: boolean) => void;
  onGotItClicked: () => void;
  navigateToStep: (step: TutorialStep) => void;
  disableConfigureDetectionRegionButton: boolean;
}

const Content = React.memo((props: ContentProps) => {
  const {
    shouldNotShowAgain,
    setShouldNotShowAgainChange,
    onGotItClicked,
    navigateToStep,
    disableConfigureDetectionRegionButton,
  } = props;
  const { localized } = useLocale();

  const onShouldNotShowAgainCheckboxChange = useCallback(
    (
      _ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
      checked?: boolean
    ) => {
      setShouldNotShowAgainChange(checked ?? false);
    },
    [setShouldNotShowAgainChange]
  );

  return (
    <>
      <div className={styles["text-content"]}>
        <Text variant="medium" className={styles["title"]}>
          <FormattedMessage id="tutorial.fixed_layout_form.all_set.title" />
        </Text>
        <Text variant="medium" className={styles["description"]}>
          <FormattedMessage id="tutorial.fixed_layout_form.all_set.description" />
        </Text>
      </div>
      <div className={styles["separator"]}></div>
      <div className={styles["navigate-step-content"]}>
        <Text variant="medium" className={styles["step-title"]}>
          <FormattedMessage id="tutorial.fixed_layout_form.all_set.review_all_steps" />
        </Text>
        <div
          className={styles["step-button"]}
          onClick={() => navigateToStep(TutorialStep.anchor)}
        >
          <FormattedMessage id="tutorial.fixed_layout_form.all_set.anchors" />
          <Icon className={styles["step-arrow"]} iconName="ChevronRight" />
        </div>
        <div
          className={styles["step-button"]}
          onClick={() => navigateToStep(TutorialStep.detectionRegion)}
        >
          <FormattedMessage id="tutorial.fixed_layout_form.all_set.detection_region" />
          <Icon className={styles["step-arrow"]} iconName="ChevronRight" />
        </div>
        <div
          className={classNames(styles["step-button"], {
            [styles["disabled"]]: disableConfigureDetectionRegionButton,
          })}
          onClick={() => navigateToStep(TutorialStep.configureDetectionRegion)}
        >
          <FormattedMessage id="tutorial.fixed_layout_form.all_set.configure_your_fields" />
          <Icon className={styles["step-arrow"]} iconName="ChevronRight" />
        </div>
      </div>
      <div className={styles["separator"]}></div>
      <div className={styles["buttons"]}>
        <Checkbox
          className={styles["checkbox"]}
          label={localized(
            "tutorial.fixed_layout_form.all_set.do_not_show_tutorials"
          )}
          checked={shouldNotShowAgain}
          onChange={onShouldNotShowAgainCheckboxChange}
        />
        <PrimaryButton
          type="button"
          text={localized("tutorial.fixed_layout_form.got_it")}
          onClick={onGotItClicked}
        />
      </div>
    </>
  );
});

interface FixedLayoutFormTutorialAllSetdBubbleProps {
  finishTutorial: (skipAll: boolean) => void;
  navigateToStep: (step: TutorialStep) => void;
  disableConfigureDetectionRegionButton: boolean;
  shouldSkipAllTutorial: boolean;
}

const FixedLayoutFormTutorialAllSetdBubble = React.memo(
  (props: FixedLayoutFormTutorialAllSetdBubbleProps) => {
    const {
      finishTutorial,
      navigateToStep,
      disableConfigureDetectionRegionButton,
      shouldSkipAllTutorial,
    } = props;
    const [shouldNotShowAgain, setShouldNotShowAgainChange] = useState(
      shouldSkipAllTutorial
    );

    const onGotItClicked = useCallback(() => {
      finishTutorial(shouldNotShowAgain);
    }, [finishTutorial, shouldNotShowAgain]);

    return (
      <FixedLayoutFormTutorialBubble
        isLastStep={true}
        currentStep={0}
        targetId={ToolBoxTutorialTargetIds.InfoIcon}
        contents={[
          <Content
            shouldNotShowAgain={shouldNotShowAgain}
            setShouldNotShowAgainChange={setShouldNotShowAgainChange}
            onGotItClicked={onGotItClicked}
            navigateToStep={navigateToStep}
            disableConfigureDetectionRegionButton={
              disableConfigureDetectionRegionButton
            }
          />,
        ]}
        hideButtons={true}
      />
    );
  }
);

export default FixedLayoutFormTutorialAllSetdBubble;
