import {
  KeywordsExtractionAnchor,
  KeywordsExtractionAnchorResp,
} from "../keywordsExtraction";

export const KeywordsExtractionAnchorMapper = {
  toResp: (anchor: KeywordsExtractionAnchor): KeywordsExtractionAnchorResp => {
    return {
      text: anchor.text,
      is_ignore_white_space: anchor.isIgnoreWhiteSpace,
      use_fuzzy_search: anchor.useFuzzySearch,
    };
  },
};
