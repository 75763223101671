import React, { useCallback, useRef } from "react";

import { useCustomModelActionCreator } from "../actions/customModel";
import CustomModelModel from "../components/CustomModelModel";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import {
  useAutoReloadCustomModel,
  useCommonCustomModelContainerState,
} from "../hooks/custom_model";
import { useUnsafeParams } from "../hooks/params";
import { useToast } from "../hooks/toast";
import {
  CustomModelNavBarLayout,
  CustomModelNavTabKey,
} from "./CustomModelNavBarLayout";

type PathParam = {
  customModelId: string;
};

function _CustomModelModelContainer() {
  const { customModelId } = useUnsafeParams<PathParam>();

  const toast = useToast();
  const { deployModelVersion } = useCustomModelActionCreator();
  const containerState = useCommonCustomModelContainerState(customModelId);

  const containerStateRef = useRef(containerState);
  containerStateRef.current = containerState;

  const isTriggeringModelDeploymentRef = React.useRef(false);
  const [modelVersionTagToDeploy, setModelVersionTagToDeploy] = React.useState<
    string | null
  >(null);
  const doDeployModelVersion = useCallback(
    async (modelVersionTag: string) => {
      if (
        containerStateRef.current.state !== "success" ||
        isTriggeringModelDeploymentRef.current
      ) {
        return;
      }

      const { customModel } = containerStateRef.current;
      isTriggeringModelDeploymentRef.current = true;
      setModelVersionTagToDeploy(modelVersionTag);
      try {
        await deployModelVersion(customModel.id, modelVersionTag);
      } catch (e) {
        console.error(e);
        toast.error("custom_model_model.deploy.error");
      } finally {
        isTriggeringModelDeploymentRef.current = false;
        setModelVersionTagToDeploy(null);
      }
    },
    [deployModelVersion, toast]
  );

  useAutoReloadCustomModel(
    containerState.state === "success" ? containerState.customModel.id : null,
    containerState.state === "success" && containerState.isDeploymentInProgress,
    5 * 60 * 1000
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
        ) : containerState.state === "success" ? (
          <CustomModelNavBarLayout
            selectedTab={CustomModelNavTabKey.Model}
            customModel={containerState.customModel}
          >
            <CustomModelModel
              modelVersions={containerState.customModel.modelVersions.map(
                modelVersion => ({
                  ...modelVersion,
                  isActive:
                    modelVersionTagToDeploy !== null
                      ? modelVersion.tag === modelVersionTagToDeploy
                      : modelVersion.isActive,
                })
              )}
              isDeploymentInProgress={
                containerState.isDeploymentInProgress ||
                modelVersionTagToDeploy !== null
              }
              deployModelVersion={doDeployModelVersion}
            />
          </CustomModelNavBarLayout>
        ) : null}
      </Main>
    </Layout>
  );
}

export const CustomModelModelContainer = React.memo(_CustomModelModelContainer);
export default CustomModelModelContainer;
