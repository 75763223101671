import {
  ChoiceGroup,
  DefaultButton,
  Dropdown,
  type IChoiceGroupOption,
  type IComboBox,
  type IComboBoxOption,
  type IDropdownOption,
  type IRenderFunction,
  type ISelectableOption,
  Icon,
  Spinner,
  SpinnerSize,
  VirtualizedComboBox,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import type { OAuthError } from "../../actions/oauth";
import { AppConfig } from "../../config";
import { useLocale } from "../../contexts/locale";
import type {
  GoogleSheet,
  GoogleSheetFieldMapping,
  GoogleSpreadsheet,
} from "../../types/googleSheet";
import type { OAuthCredential } from "../../types/oauth";
import { WorkspaceExportGoogleSheetsIntegrationConfiguration } from "../../types/workspaceIntegration";
import { PrimaryLoadingButton } from "../LoadingButton";
import ShortSpinner from "../ShortSpinner";
import { ConfigurationCardLayout } from "./ConfigurationCardLayout";

const FORMX_PRIMARY_KEY_FIELD_NAME = "__FORMX_EXTRACTION_ID__";

const onRenderComboboxOption: IRenderFunction<ISelectableOption> = (
  props,
  render
) => {
  return (
    <div className="flex flex-row gap-2">
      {props?.key === "" ? (
        <>
          <Icon className="text-primary-500" iconName="Add" />
          <div className="text-primary-500">
            {render ? render(props) : null}
          </div>
        </>
      ) : (
        <>
          <Icon className="text-primary-500" iconName="CircleRing" />
          {render ? render(props) : null}
        </>
      )}
    </div>
  );
};

interface AuthSectionProps {
  accounts: OAuthCredential[];
  errorMessage: string | null;
  isAuthorizing: boolean;
  isFetching: boolean;
  selectedAccountId: string | null;
  onAccountChange: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => void;
  onAuthClick: () => void;
}

function AuthSection(props: AuthSectionProps) {
  const {
    accounts,
    errorMessage,
    isAuthorizing,
    isFetching,
    selectedAccountId,
    onAccountChange,
    onAuthClick,
  } = props;

  const { localized } = useLocale();

  return (
    <div>
      <div className="font-semibold text-sm mb-1">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.connectedAccounts.title" />
      </div>
      <div className="text-xs mb-3">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.connectedAccounts.description" />
      </div>
      {errorMessage !== null ? (
        <div className="text-[#D05C25] text-xs mb-3">{errorMessage}</div>
      ) : null}
      {!isFetching ? (
        accounts.length > 0 ? (
          <>
            <VirtualizedComboBox
              className="mb-3"
              allowFreeform={true}
              allowFreeInput={true}
              caretDownButtonStyles={{
                rootChecked: { backgroundColor: "white" },
                rootCheckedHovered: { backgroundColor: "white" },
                rootCheckedPressed: { backgroundColor: "white" },
                rootHovered: { backgroundColor: "white" },
                rootPressed: { backgroundColor: "white" },
              }}
              disabled={accounts.length === 0}
              onChange={onAccountChange}
              options={accounts.map(({ id, name }) => ({
                key: id,
                text: name,
              }))}
              placeholder={localized("dropdown.placeholder")}
              selectedKey={selectedAccountId}
              useComboBoxAsMenuWidth={true}
              onRenderOption={onRenderComboboxOption}
            />
            <a
              className={`text-primary-500 hover:text-[#2B2D2C] cursor-pointer ${
                isAuthorizing && "pointer-events-none"
              }`}
              onClick={onAuthClick}
            >
              <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.connectedAccounts.new" />
            </a>
          </>
        ) : (
          <GoogleAuthButton onClick={onAuthClick} isLoading={isAuthorizing} />
        )
      ) : (
        <ShortSpinner />
      )}
    </div>
  );
}

interface SpreadsheetSelectionProps {
  isFetching: boolean;
  items: GoogleSpreadsheet[];
  selectedKey: string | null;
  onChange: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => void;
  onSearch: (query: string | null) => void;
}

function SpreadsheetSelection(props: SpreadsheetSelectionProps) {
  const { isFetching, items, selectedKey, onChange, onSearch } = props;

  const { localized } = useLocale();

  const spreadsheetOptions = useMemo<IComboBoxOption[]>(
    () => [
      {
        key: "",
        text: localized(
          "workspace.integrations.configuration.export_googleSheets.createNewSpreadsheet"
        ),
      },
      ...items.map(({ id, name }) => ({
        key: id,
        text: name,
      })),
    ],
    [items, localized]
  );

  const onPendingValueChanged = useCallback(
    (_option?: IComboBoxOption, _index?: number, value?: string) => {
      if (value) {
        onSearch(value);
      }
    },
    [onSearch]
  );

  return (
    <div className="mt-3">
      <div className="text-xs">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.selectSpreadsheet" />
      </div>
      {!isFetching ? (
        <VirtualizedComboBox
          className="mt-1"
          allowFreeform={true}
          allowFreeInput={true}
          caretDownButtonStyles={{
            rootChecked: { backgroundColor: "white" },
            rootCheckedHovered: { backgroundColor: "white" },
            rootCheckedPressed: { backgroundColor: "white" },
            rootHovered: { backgroundColor: "white" },
            rootPressed: { backgroundColor: "white" },
          }}
          disabled={items.length === 0}
          options={spreadsheetOptions}
          placeholder={localized("dropdown.placeholder")}
          selectedKey={selectedKey}
          onChange={onChange}
          useComboBoxAsMenuWidth={true}
          onPendingValueChanged={onPendingValueChanged}
          onRenderOption={onRenderComboboxOption}
        />
      ) : (
        <ShortSpinner />
      )}
    </div>
  );
}

interface SheetSelectionProps {
  isFetching: boolean;
  items: GoogleSheet[];
  selectedKey: string | null;
  onChange: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => void;
}

function SheetSelection(props: SheetSelectionProps) {
  const { isFetching, items, selectedKey, onChange } = props;

  const { localized } = useLocale();

  const sheetOptions = useMemo<IComboBoxOption[]>(
    () =>
      items.map(({ id, name }) => ({
        key: id,
        text: name,
      })),
    [items]
  );

  return (
    <div className="mt-3">
      <div className="text-xs">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.selectSheet" />
      </div>
      {!isFetching ? (
        <VirtualizedComboBox
          className="mt-1"
          allowFreeform={true}
          allowFreeInput={true}
          caretDownButtonStyles={{
            rootChecked: { backgroundColor: "white" },
            rootCheckedHovered: { backgroundColor: "white" },
            rootCheckedPressed: { backgroundColor: "white" },
            rootHovered: { backgroundColor: "white" },
            rootPressed: { backgroundColor: "white" },
          }}
          disabled={items.length === 0}
          options={sheetOptions}
          placeholder={localized("dropdown.placeholder")}
          selectedKey={selectedKey}
          onChange={onChange}
          useComboBoxAsMenuWidth={true}
          onRenderOption={onRenderComboboxOption}
        />
      ) : (
        <ShortSpinner />
      )}
    </div>
  );
}

interface FieldMappingProps {
  fields: string[];
  mappings: GoogleSheetFieldMapping[];
  onChange: (
    columnIndex: number,
    event: React.FormEvent<HTMLElement>,
    option?: IDropdownOption
  ) => void;
}

function FieldMapping(props: FieldMappingProps) {
  const { fields, mappings, onChange } = props;

  const { localized } = useLocale();

  const fieldOptions = useMemo<IDropdownOption[]>(
    () => [
      {
        key: "",
        text: localized(
          "workspace.integrations.configuration.export_googleSheets.dropdown.clear"
        ),
      },
      ...fields.map(field => ({
        key: field,
        text: field,
      })),
    ],
    [fields, localized]
  );

  return (
    <div className="flex flex-col gap-3 border border-solid border-gray-200 bg-[#faf9f8] p-3 mt-3">
      <div className="text-xs">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.fieldMappings.fields" />
      </div>
      {mappings
        .filter(({ isPrimaryKey }) => !isPrimaryKey)
        .map(({ columnName, columnIndex, fieldName }) => (
          <div
            key={`${columnName}-${columnIndex}`}
            className="flex flex-row items-center gap-3"
          >
            <div className="flex-1 overflow-hidden">
              <div className="text-xs">
                <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.fieldMappings.sheet_column" />
              </div>
              <Dropdown
                className="mt-1"
                disabled={true}
                options={[
                  {
                    key: columnIndex,
                    text: columnName,
                  },
                ]}
                placeholder={localized("dropdown.placeholder")}
                selectedKey={columnIndex}
              />
            </div>
            <div className="flex-initial">
              <Icon iconName="IconFieldMapping" />
            </div>
            <div className="flex-1 overflow-hidden">
              <div className="text-xs">
                <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.fieldMappings.formx_field" />
              </div>
              <Dropdown
                className="mt-1"
                disabled={fields.length === 0}
                options={fieldOptions}
                placeholder={localized("dropdown.placeholder")}
                selectedKey={fieldName}
                onChange={onChange.bind(null, columnIndex)}
              />
            </div>
          </div>
        ))}
    </div>
  );
}

interface ConfigurationSectionProps {
  fields: string[];
  isFetchingSheets: boolean;
  isFetchingSpreadsheets: boolean;
  isSelectedSheet: boolean;
  isSelectedSpreadsheet: boolean;
  isUpsertMode: boolean;
  mappings: GoogleSheetFieldMapping[];
  selectedSheetId: string | null;
  selectedSpreadsheetId: string | null;
  sheets: GoogleSheet[];
  spreadsheets: GoogleSpreadsheet[];
  onMappingChange: (
    columnIndex: number,
    event: React.FormEvent<HTMLElement>,
    option?: IDropdownOption
  ) => void;
  onModeChange: (
    event?: React.FormEvent<HTMLElement>,
    option?: IChoiceGroupOption
  ) => void;
  onRefreshClick: () => void;
  onSheetChange: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => void;
  onSpreadsheetChange: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => void;
  onSpreadsheetSearch: (query: string | null) => void;
}

function ConfigurationSection(props: ConfigurationSectionProps) {
  const {
    fields,
    isFetchingSheets,
    isFetchingSpreadsheets,
    isSelectedSheet,
    isSelectedSpreadsheet,
    isUpsertMode,
    mappings,
    selectedSheetId,
    selectedSpreadsheetId,
    sheets,
    spreadsheets,
    onMappingChange,
    onModeChange,
    onRefreshClick,
    onSheetChange,
    onSpreadsheetChange,
    onSpreadsheetSearch,
  } = props;

  const { localized } = useLocale();

  const exportActionOptions = useMemo<IChoiceGroupOption[]>(
    () => [
      {
        key: "insert",
        text: "",
        onRenderLabel: (props, render) => {
          return (
            <div className="flex gap-2">
              {render ? render(props) : null}
              <div className="flex flex-col gap-1">
                <span className="text-sm">
                  <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.exportAction.option.insert.title" />
                </span>
                <span className="text-[#605E5C] text-xs">
                  <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.exportAction.option.insert.description" />
                </span>
              </div>
            </div>
          );
        },
      },
      {
        key: "upsert",
        text: "",
        onRenderField: (props, render) => {
          return <div className="mt-1">{render ? render(props) : null}</div>;
        },
        onRenderLabel: (props, render) => {
          return (
            <div className="flex gap-2">
              {render ? render(props) : null}
              <div className="flex flex-col gap-1">
                <span className="text-sm">
                  <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.exportAction.option.upsert.title" />
                </span>
                <span className="text-[#605E5C] text-xs">
                  <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.exportAction.option.upsert.description" />
                </span>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="border border-b-0 border-x-0 border-solid border-gray-200 my-5">
      <div className="font-semibold text-sm mt-5 mb-1">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.fieldMappings.title" />
      </div>
      <div className="text-xs mb-3">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.fieldMappings.description" />
      </div>
      <SpreadsheetSelection
        isFetching={isFetchingSpreadsheets}
        items={spreadsheets}
        selectedKey={selectedSpreadsheetId}
        onChange={onSpreadsheetChange}
        onSearch={onSpreadsheetSearch}
      />
      {isSelectedSpreadsheet ? (
        <SheetSelection
          isFetching={isFetchingSheets}
          items={sheets}
          selectedKey={selectedSheetId}
          onChange={onSheetChange}
        />
      ) : null}
      {isSelectedSheet ? (
        <FieldMapping
          fields={fields}
          mappings={mappings}
          onChange={onMappingChange}
        />
      ) : null}
      <DefaultButton
        className="mt-3"
        iconProps={{ iconName: "Refresh" }}
        text={localized(
          "workspace.integrations.configuration.export_googleSheets.refresh_data"
        )}
        onClick={onRefreshClick}
      />
      <div className="font-semibold text-sm mt-5 mb-1">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.exportAction.title" />
      </div>
      <div className="text-xs mb-3">
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.exportAction.description" />
      </div>
      <ChoiceGroup
        options={exportActionOptions}
        selectedKey={isUpsertMode ? "upsert" : "insert"}
        onChange={onModeChange}
      />
    </div>
  );
}

export interface GoogleAuthButtonProps {
  isLoading?: boolean;
  onClick: () => void;
}

export function GoogleAuthButton(props: GoogleAuthButtonProps) {
  const { onClick, isLoading = false } = props;

  return (
    <DefaultButton
      className="flex font-semibold color-black bg-[#f2f2f2] rounded border-0 h-10 px-3 py-2.5"
      disabled={isLoading}
      onClick={onClick}
    >
      {isLoading ? (
        <>
          <div className="w-5 h-5 mr-2.5">
            <Spinner size={SpinnerSize.medium} />
          </div>
          <span>
            <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.login_button.loading" />
          </span>
        </>
      ) : (
        <>
          <Icon iconName="IconGoogle" className="w-5 h-5 mr-2.5" />
          <span>
            <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.login_button" />
          </span>
        </>
      )}
    </DefaultButton>
  );
}

interface ExportGoogleSheetsConfigurationCardProps {
  configuration: WorkspaceExportGoogleSheetsIntegrationConfiguration;
  extractorId: string | null;
  isAuthorized: boolean;
  isAuthorizing: boolean;
  isFetchingSheets: boolean;
  isFetchingSpreadsheets: boolean;
  isSaving: boolean;
  oauthCredentials: OAuthCredential[];
  oauthError: OAuthError | null;
  workspaceId: string;
  onAuth: (scopes: string[]) => Promise<void>;
  onConfigurationRemoved: (
    configuration: WorkspaceExportGoogleSheetsIntegrationConfiguration
  ) => void;
  onConfigurationSaved: (
    oauthCredentialId: string,
    spreadsheetId: string,
    sheetId: string | null,
    mappings: GoogleSheetFieldMapping[],
    isUpsertMode: boolean
  ) => void;
  onGetFields: (extractorId: string) => Promise<string[]>;
  onGetOAuthCredentialList: () => Promise<void>;
  onGetSheetColumns: (
    oauthCredential: string,
    spreadsheetId: string,
    sheetId: string
  ) => Promise<string[]>;
  onGetSheetList: (
    oauthCredential: string,
    spreadsheetId: string
  ) => Promise<GoogleSheet[]>;
  onGetSpreadsheetList: (
    oauthCredential: string,
    query: string | null
  ) => Promise<GoogleSpreadsheet[]>;
  onOAuthCredentialCreated: () => Promise<void>;
  onRefresh: () => Promise<void>;
}

export function useExportGoogleSheetsConfigurationCard(
  props: ExportGoogleSheetsConfigurationCardProps
) {
  const {
    configuration,
    extractorId,
    isAuthorized,
    isAuthorizing,
    isFetchingSheets,
    isFetchingSpreadsheets,
    isSaving,
    oauthCredentials,
    oauthError,
    onAuth,
    onConfigurationRemoved,
    onConfigurationSaved,
    onGetFields,
    onGetOAuthCredentialList,
    onGetSheetColumns,
    onGetSheetList,
    onGetSpreadsheetList,
    onOAuthCredentialCreated,
    onRefresh,
  } = props;

  const { localized } = useLocale();

  const [isFetchingOAuthCredentials, setIsFetchingOAuthCredentials] =
    useState<boolean>(false);
  const [sheets, setSheets] = useState<GoogleSheet[]>([]);
  const [fields, setFields] = useState<string[]>([]);
  const [spreadsheets, setSpreadsheets] = useState<GoogleSpreadsheet[]>([]);

  const [mappings, setMappings] = useState<GoogleSheetFieldMapping[]>(
    configuration.mappings
  );
  const [isUpsertMode, setIsUpsertMode] = useState<boolean>(
    configuration.isUpsertMode
  );
  const [selectedOAuthCredential, setSelectedOAuthCredential] = useState<
    string | null
  >(configuration.oauthCredentialId);
  const [selectedSheetId, setSelectedSheetId] = useState<string | null>(
    configuration.sheetId
  );
  const [selectedSpreadsheetId, setSelectedSpreadsheetId] = useState<
    string | null
  >(configuration.spreadsheetId);

  const authErrorMessage = useMemo<string | null>(() => {
    if (oauthError === null) {
      return null;
    }

    const { error, errorDescription } = oauthError;

    console.error(`OAuth error (${error}): ${errorDescription}`);
    switch (error) {
      case "already_exists":
        return localized("error.google_auth.already_exists");
      case "invalid_access_token":
        return localized("error.google_auth.invalid_access_token");
      case "invalid_state":
        return localized("error.google_auth.invalid_state");
      case "missing_code":
        return localized("error.google_auth.missing_code");
      case "missing_state_key":
        return localized("error.google_auth.missing_state_key");
      case "nonce_mismatch":
        return localized("error.google_auth.nonce_mismatch");
      case "unexpected_state_key":
        return localized("error.google_auth.unexpected_state_key");
      case "unknown":
        return localized("error.google_auth.unknown");
      case "user_cancelled":
        return localized("error.google_auth.user_cancelled");
      // Google OAuth errors
      case "access_denied":
        return localized("error.google_auth.access_denied");
      case "invalid_request":
        return localized("error.google_auth.invalid_request");
      case "invalid_scope":
        return localized("error.google_auth.invalid_scope");
      case "server_error":
        return localized("error.google_auth.server_error");
      case "temporarily_unavailable":
        return localized("error.google_auth.temporarily_unavailable");
      case "unauthorized_client":
        return localized("error.google_auth.unauthorized_client");
      case "unsupported_response_type":
        return localized("error.google_auth.unsupported_response_type");
      // Google OAuth errors (when exchanging tokens)
      case "invalid_grant":
        return localized("error.google_auth.invalid_grant");
      default:
        return localized("error.google_auth.unknown");
    }
  }, [localized, oauthError]);

  const isCreatingSpreadsheet = useMemo<boolean>(
    () => selectedSpreadsheetId === "",
    [selectedSpreadsheetId]
  );

  const isSetupFinished = useMemo<boolean>(() => {
    return (
      selectedOAuthCredential !== null &&
      (isCreatingSpreadsheet ||
        (selectedSheetId !== null && selectedSpreadsheetId !== null))
    );
  }, [
    isCreatingSpreadsheet,
    selectedOAuthCredential,
    selectedSheetId,
    selectedSpreadsheetId,
  ]);

  const refreshFieldList = useCallback(
    async (extractorId: string) => {
      setFields(await onGetFields(extractorId));
    },
    [onGetFields]
  );

  const refreshSheetColumnList = useCallback(
    async (oauthCredential: string, spreadsheetId: string, sheetId: string) => {
      const columns = await onGetSheetColumns(
        oauthCredential,
        spreadsheetId,
        sheetId
      );
      setMappings([
        {
          columnIndex: columns.length,
          columnName: FORMX_PRIMARY_KEY_FIELD_NAME,
          fieldName: "ID",
          isPrimaryKey: true,
        },
        ...columns
          .filter(columnName => columnName !== FORMX_PRIMARY_KEY_FIELD_NAME)
          .map((columnName, columnIndex) => ({
            columnIndex,
            columnName,
            fieldName: "",
            isPrimaryKey: false,
          })),
      ]);
    },
    [onGetSheetColumns]
  );

  const refreshSheetList = useCallback(
    async (oauthCredential: string, spreadsheetId: string) => {
      setSheets(await onGetSheetList(oauthCredential, spreadsheetId));
    },
    [onGetSheetList]
  );

  const refreshSpreadsheetList = useCallback(
    async (oauthCredential: string) => {
      setSpreadsheets(await onGetSpreadsheetList(oauthCredential, null));
    },
    [onGetSpreadsheetList]
  );

  const refreshOAuthCredentials = useCallback(
    async (isAuthorized: boolean) => {
      if (isAuthorized) {
        await onOAuthCredentialCreated();
      } else {
        setIsFetchingOAuthCredentials(true);
        await onGetOAuthCredentialList();
        setIsFetchingOAuthCredentials(false);
      }
    },
    [onGetOAuthCredentialList, onOAuthCredentialCreated]
  );

  const onAuthClick = useCallback(() => {
    if (AppConfig.googleAuth === undefined) {
      throw new Error("AppConfig.googleAuth not configured");
    }

    if (!isAuthorizing) {
      onAuth(AppConfig.googleAuth.sheetsExportScopes);
    }
  }, [isAuthorizing, onAuth]);

  const onChangeOAuthCredential = useCallback(
    (
      _event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      _index?: number,
      _value?: string
    ) => {
      if (option) {
        setSelectedOAuthCredential(option.key.toString());
      } else {
        setSelectedOAuthCredential(null);
      }
    },
    []
  );

  const onChangeMapping = useCallback(
    (
      columnIndex: number,
      _event: React.FormEvent<HTMLElement>,
      option?: IDropdownOption
    ) => {
      const fieldName = option?.key.toString() ?? "";
      setMappings(mappings => {
        return mappings.map(mapping => {
          if (columnIndex === mapping.columnIndex) {
            mapping.fieldName = fieldName;
          }
          return mapping;
        });
      });
    },
    []
  );

  const onChangeMode = useCallback(
    (_event?: React.FormEvent<HTMLElement>, option?: IChoiceGroupOption) => {
      setIsUpsertMode(option?.key === "upsert");
    },
    []
  );

  const onChangeSheet = useCallback(
    (
      _event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      _index?: number,
      _value?: string
    ) => {
      if (option) {
        setSelectedSheetId(option.key.toString());
      } else {
        setSelectedSheetId(null);
      }
    },
    []
  );

  const onChangeSpreadsheet = useCallback(
    (
      _event: React.FormEvent<IComboBox>,
      option?: IComboBoxOption,
      _index?: number,
      _value?: string
    ) => {
      if (option) {
        setSelectedSpreadsheetId(option.key.toString());
      } else {
        setSelectedSpreadsheetId(null);
      }
    },
    []
  );

  const onRefreshClick = useCallback(() => {
    onRefresh();
    setSelectedSheetId(null);
    setSelectedSpreadsheetId(null);
  }, [onRefresh]);

  const onRemoveClick = useCallback(() => {
    onConfigurationRemoved(configuration);
  }, [configuration, onConfigurationRemoved]);

  const onSaveClick = useCallback(() => {
    if (
      !isSetupFinished ||
      selectedOAuthCredential === null ||
      selectedSpreadsheetId === null
    ) {
      // Unexpected, as button should be disabled if neccesary information is missing
      throw new Error(
        "Expect `oauthCredential`, `spreadsheet`, and `sheet` must be selected"
      );
    }

    if (isCreatingSpreadsheet) {
      onConfigurationSaved(
        selectedOAuthCredential,
        selectedSpreadsheetId,
        selectedSheetId,
        [
          {
            columnIndex: fields.length,
            columnName: FORMX_PRIMARY_KEY_FIELD_NAME,
            fieldName: "ID",
            isPrimaryKey: true,
          },
          ...fields.map((fieldName, fieldIndex) => ({
            columnIndex: fieldIndex,
            columnName: fieldName,
            fieldName,
            isPrimaryKey: false,
          })),
        ],
        isUpsertMode
      );
    } else {
      onConfigurationSaved(
        selectedOAuthCredential,
        selectedSpreadsheetId,
        selectedSheetId,
        mappings,
        isUpsertMode
      );
    }
  }, [
    fields,
    isCreatingSpreadsheet,
    isSetupFinished,
    isUpsertMode,
    mappings,
    onConfigurationSaved,
    selectedOAuthCredential,
    selectedSheetId,
    selectedSpreadsheetId,
  ]);

  const onSearchSpreadsheets = useCallback(
    async (query: string | null) => {
      if (selectedOAuthCredential) {
        setSpreadsheets(
          await onGetSpreadsheetList(selectedOAuthCredential, query)
        );
      }
    },
    [selectedOAuthCredential, onGetSpreadsheetList]
  );

  useEffect(() => {
    refreshOAuthCredentials(isAuthorized);
  }, [isAuthorized, refreshOAuthCredentials]);

  useEffect(() => {
    if (extractorId !== null) {
      refreshFieldList(extractorId);
    }
  }, [extractorId, refreshFieldList]);

  useEffect(() => {
    if (!isFetchingSpreadsheets && selectedOAuthCredential !== null) {
      refreshSpreadsheetList(selectedOAuthCredential);
    }
  }, [isFetchingSpreadsheets, refreshSpreadsheetList, selectedOAuthCredential]);

  useEffect(() => {
    if (
      !isFetchingSheets &&
      selectedOAuthCredential !== null &&
      selectedSpreadsheetId !== null
    ) {
      refreshSheetList(selectedOAuthCredential, selectedSpreadsheetId);
    }
  }, [
    isFetchingSheets,
    refreshSheetList,
    selectedOAuthCredential,
    selectedSpreadsheetId,
  ]);

  useEffect(() => {
    if (
      selectedOAuthCredential !== null &&
      selectedSpreadsheetId !== null &&
      selectedSheetId !== null
    ) {
      refreshSheetColumnList(
        selectedOAuthCredential,
        selectedSpreadsheetId,
        selectedSheetId
      );
    }
  }, [
    refreshSheetColumnList,
    selectedOAuthCredential,
    selectedSheetId,
    selectedSpreadsheetId,
  ]);

  return React.useMemo(
    () => ({
      authErrorMessage,
      fields,
      isAuthorizing,
      isFetchingOAuthCredentials,
      isFetchingSheets,
      isFetchingSpreadsheets,
      isSaving,
      isSelectedAccount: selectedOAuthCredential !== null,
      isSelectedSheet: selectedSheetId !== null,
      isSelectedSpreadsheet:
        selectedSpreadsheetId !== null && selectedSpreadsheetId !== "",
      isSetupFinished,
      isUpsertMode,
      mappings,
      oauthCredentials,
      selectedOAuthCredential,
      selectedSheetId,
      selectedSpreadsheetId,
      sheets,
      spreadsheets,
      onAuthClick,
      onChangeMapping,
      onChangeMode,
      onChangeOAuthCredential,
      onChangeSheet,
      onChangeSpreadsheet,
      onRefreshClick,
      onRemoveClick,
      onSaveClick,
      onSearchSpreadsheets,
    }),
    [
      authErrorMessage,
      fields,
      isAuthorizing,
      isFetchingOAuthCredentials,
      isFetchingSheets,
      isFetchingSpreadsheets,
      isSaving,
      isSetupFinished,
      isUpsertMode,
      mappings,
      oauthCredentials,
      onAuthClick,
      onChangeMapping,
      onChangeMode,
      onChangeOAuthCredential,
      onChangeSheet,
      onChangeSpreadsheet,
      onRefreshClick,
      onRemoveClick,
      onSaveClick,
      onSearchSpreadsheets,
      selectedOAuthCredential,
      selectedSheetId,
      selectedSpreadsheetId,
      sheets,
      spreadsheets,
    ]
  );
}

export function ExportGoogleSheetsConfigurationCardImpl(
  props: ReturnType<typeof useExportGoogleSheetsConfigurationCard>
) {
  const {
    authErrorMessage,
    fields,
    isAuthorizing,
    isFetchingOAuthCredentials,
    isFetchingSheets,
    isFetchingSpreadsheets,
    isSaving,
    isSelectedAccount,
    isSelectedSheet,
    isSelectedSpreadsheet,
    isSetupFinished,
    isUpsertMode,
    mappings,
    oauthCredentials,
    onAuthClick,
    onChangeMapping,
    onChangeMode,
    onChangeOAuthCredential,
    onChangeSheet,
    onChangeSpreadsheet,
    onRefreshClick,
    onRemoveClick,
    onSaveClick,
    onSearchSpreadsheets,
    selectedOAuthCredential,
    selectedSheetId,
    selectedSpreadsheetId,
    sheets,
    spreadsheets,
  } = props;

  return (
    <ConfigurationCardLayout
      title={
        <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.title" />
      }
      onRemoveClick={onRemoveClick}
    >
      <AuthSection
        accounts={oauthCredentials}
        errorMessage={authErrorMessage}
        isAuthorizing={isAuthorizing}
        isFetching={isFetchingOAuthCredentials}
        onAccountChange={onChangeOAuthCredential}
        onAuthClick={onAuthClick}
        selectedAccountId={selectedOAuthCredential}
      />
      {isSelectedAccount ? (
        <ConfigurationSection
          fields={fields}
          isFetchingSheets={isFetchingSheets}
          isFetchingSpreadsheets={isFetchingSpreadsheets}
          isSelectedSheet={isSelectedSheet}
          isSelectedSpreadsheet={isSelectedSpreadsheet}
          isUpsertMode={isUpsertMode}
          mappings={mappings}
          onMappingChange={onChangeMapping}
          onModeChange={onChangeMode}
          onRefreshClick={onRefreshClick}
          onSheetChange={onChangeSheet}
          onSpreadsheetChange={onChangeSpreadsheet}
          onSpreadsheetSearch={onSearchSpreadsheets}
          selectedSheetId={selectedSheetId}
          selectedSpreadsheetId={selectedSpreadsheetId}
          sheets={sheets}
          spreadsheets={spreadsheets}
        />
      ) : null}
      {isSelectedAccount ? (
        <PrimaryLoadingButton
          className="block ml-auto"
          disabled={!isSetupFinished}
          isLoading={isSaving}
          onClick={onSaveClick}
        >
          <FormattedMessage id="workspace.integrations.configuration.export_googleSheets.save" />
        </PrimaryLoadingButton>
      ) : null}
    </ConfigurationCardLayout>
  );
}

export function ExportGoogleSheetsConfigurationCard(
  props: ExportGoogleSheetsConfigurationCardProps
) {
  const states = useExportGoogleSheetsConfigurationCard(props);
  return <ExportGoogleSheetsConfigurationCardImpl {...states} />;
}
