@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.text-content {
  padding: 20px;
  padding-bottom: 0;
}

.highlight {
  color: #6888fa;
}

.title {
  font-weight: 600;
  font-size: 18px;
  color: #323130;
  width: 100%;
  display: block;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #605e5c;
  width: 100%;
  margin-top: 10px;
  display: block;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.extra-padding {
  margin-bottom: 20px;
}

.image {
  width: 100%;
  height: 182px;
}
