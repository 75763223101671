import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../redux/types";

export function useTeamPermission() {
  const hasPermissionToViewSubscription = useSelector<RootState, boolean>(
    state => state.resourceOwner.permissions.viewSubscription
  );

  const hasPermissionToEditSubscription = useSelector<RootState, boolean>(
    state => state.resourceOwner.permissions.editSubscription
  );

  const hasPermissionToViewMembership = useSelector<RootState, boolean>(
    state => state.resourceOwner.permissions.viewMembership
  );

  const hasPermissionToEditMembership = useSelector<RootState, boolean>(
    state => state.resourceOwner.permissions.editMembership
  );

  const hasPermissionToViewResource = useSelector<RootState, boolean>(
    state => state.resourceOwner.permissions.viewResource
  );

  const hasPermissionToEditResource = useSelector<RootState, boolean>(
    state => state.resourceOwner.permissions.editResource
  );

  const hasPermissionToCreateResource = useSelector<RootState, boolean>(
    state => state.resourceOwner.permissions.createResource
  );

  const hasPermissionToRemoveResource = useSelector<RootState, boolean>(
    state => state.resourceOwner.permissions.removeResource
  );

  const hasPermissionToViewTeamSetting = useSelector<RootState, boolean>(
    state => state.resourceOwner.permissions.viewTeamSetting
  );

  const hasPermissionToEditTeamSetting = useSelector<RootState, boolean>(
    state => state.resourceOwner.permissions.editTeamSetting
  );

  const hasPermissionToViewAuditLog = useSelector<RootState, boolean>(
    state => state.resourceOwner.permissions.viewAuditLog
  );

  return useMemo(
    () => ({
      hasPermissionToViewMembership,
      hasPermissionToEditMembership,
      hasPermissionToViewResource,
      hasPermissionToEditResource,
      hasPermissionToCreateResource,
      hasPermissionToRemoveResource,
      hasPermissionToViewSubscription,
      hasPermissionToEditSubscription,
      hasPermissionToViewTeamSetting,
      hasPermissionToEditTeamSetting,
      hasPermissionToViewAuditLog,
    }),
    [
      hasPermissionToViewMembership,
      hasPermissionToEditMembership,
      hasPermissionToViewResource,
      hasPermissionToEditResource,
      hasPermissionToCreateResource,
      hasPermissionToRemoveResource,
      hasPermissionToViewSubscription,
      hasPermissionToEditSubscription,
      hasPermissionToViewTeamSetting,
      hasPermissionToEditTeamSetting,
      hasPermissionToViewAuditLog,
    ]
  );
}
