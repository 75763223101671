import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useFormatterEditor } from "../../contexts/formatterEditor";
import { useLocale } from "../../contexts/locale";
import { FormatterActionType } from "../../types/formatter";
import { FormatterRemoveCharactersConfigPanel } from "../FormatterRemoveCharactersConfigPanel";
import styles from "./styles.module.scss";

const FormatterActionConfigPanel = React.memo(_FormatterActionConfigPanel);
export default FormatterActionConfigPanel;

function _SwapMonthDayConfigPanel() {
  const { localized } = useLocale();

  const options = React.useMemo(
    () => [
      {
        key: "",
        text: localized("formatter.actions.swap_month_day.option"),
      },
    ],
    [localized]
  );

  return (
    <div className={styles["swap-month-day"]}>
      <ChoiceGroup
        className={styles["choice-group"]}
        options={options}
        selectedKey=""
      />
    </div>
  );
}

export const SwapMonthDayConfigPanel = React.memo(_SwapMonthDayConfigPanel);

enum KeepOnlyOneLanguageSupportedType {
  English = "en",
  Chinese = "chi",
}

function _KeepOnlyOneLanguage() {
  const { localized } = useLocale();
  const { selectedStep, setSelectedConfig } = useFormatterEditor();

  const selectedLanguage =
    selectedStep?.config.language ?? KeepOnlyOneLanguageSupportedType.English;

  const options = React.useMemo(
    () => [
      {
        key: KeepOnlyOneLanguageSupportedType.English,
        text: localized("formatter.actions.keep_only_one_language.en"),
      },
      {
        key: KeepOnlyOneLanguageSupportedType.Chinese,
        text: localized("formatter.actions.keep_only_one_language.chi"),
      },
    ],
    [localized]
  );

  const onChoiceChanged = React.useCallback(
    (
      event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      event?.preventDefault();
      event?.stopPropagation();
      if (option == null) {
        return;
      }
      const language = option.key as KeepOnlyOneLanguageSupportedType;
      setSelectedConfig({
        language,
      });
    },
    [setSelectedConfig]
  );

  return (
    <div className={styles["keep-only-one-language"]}>
      <ChoiceGroup
        className={styles["choice-group"]}
        options={options}
        selectedKey={selectedLanguage}
        onChange={onChoiceChanged}
      />
      <div className={styles["hint-text"]}>
        <FormattedMessage id="formatter.actions.keep_only_one_language.hint" />
      </div>
    </div>
  );
}

export const KeepOnlyOneLanguageConfigPanel = React.memo(_KeepOnlyOneLanguage);

const REGISTERED_ACTIONS = {
  [FormatterActionType.SwapMonthDay]: SwapMonthDayConfigPanel,
  [FormatterActionType.RemoveCharacters]: FormatterRemoveCharactersConfigPanel,
  [FormatterActionType.KeepOnlyOneLanguage]: KeepOnlyOneLanguageConfigPanel,
};

interface FormatterActionConfigPanelProps {
  action: FormatterActionType;
}

function _FormatterActionConfigPanel(props: FormatterActionConfigPanelProps) {
  const { action } = props;
  if (!Object.hasOwn(REGISTERED_ACTIONS, action)) {
    return <></>;
  }

  const ActionComponent = REGISTERED_ACTIONS[
    action
  ] as React.VoidFunctionComponent;

  return (
    <div>
      <ActionComponent />
    </div>
  );
}
