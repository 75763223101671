.dropdown {
  display: flex;
  align-items: center;
  gap: 10px;

  label {
    margin-bottom: 4px;
  }
}

.ms-dropdown {
  width: 210px;

  :global(.ms-Dropdown-title) {
    border-left: none;
    border-top: none;
    border-right: none;
    color: #201f1e;
  }

  i {
    color: #201f1e;
  }
}
