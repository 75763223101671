import {
  Dialog,
  DialogType,
  IDialogContentProps,
  IModalProps,
} from "@fluentui/react";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

export enum MultiChoiceModalResponseType {
  Accepted,
  Cancelled,
}

export interface MultiChoiceModalResponse {
  type: MultiChoiceModalResponseType;
  acceptedValue?: any;
}

export function useMultiChoiceModalHandle() {
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

  const [callback, setCallback] =
    React.useState<(response: MultiChoiceModalResponse) => void | undefined>();

  const open =
    React.useCallback(async (): Promise<MultiChoiceModalResponse> => {
      setIsDialogOpen(true);

      return new Promise(resolve => {
        setCallback(() => resolve);
      });
    }, [setIsDialogOpen, setCallback]);

  const close = React.useCallback(() => {
    setIsDialogOpen(false);
    callback?.({
      type: MultiChoiceModalResponseType.Cancelled,
    });
  }, [setIsDialogOpen, callback]);

  const confirm = React.useCallback(
    (value: any) => {
      setIsDialogOpen(false);
      callback?.({
        type: MultiChoiceModalResponseType.Accepted,
        acceptedValue: value,
      });
    },
    [callback]
  );

  const props = React.useMemo(() => {
    return {
      isDialogOpen,
      close,
      confirm,
    };
  }, [isDialogOpen, close, confirm]);

  const methods = React.useMemo(
    () => ({
      open,
    }),
    [open]
  );

  return React.useMemo(() => {
    return {
      props,
      methods,
    };
  }, [props, methods]);
}

export function useMultiChoiceModalAcceptButton(
  props: ReturnType<typeof useMultiChoiceModalHandle>["props"],
  value: any
) {
  const { confirm } = props;
  const onAccept = React.useCallback(() => {
    confirm(value);
  }, [confirm, value]);
  return onAccept;
}

export type MultiChoiceModalProps = ReturnType<
  typeof useMultiChoiceModalHandle
>["props"] & {
  titleId: string;
  messageId: string;
  left?: React.ReactNode;
  children?: React.ReactNode;
};

export function MultiChoiceModal(props: MultiChoiceModalProps) {
  const { titleId, messageId, isDialogOpen, close } = props;
  const { localized } = useLocale();

  const modalProps: IModalProps = React.useMemo(
    () => ({
      isBlocking: true,
      onDismissed: close,
      className: styles["async-confirm-modal"],
    }),
    [close]
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(titleId),
      subText: localized(messageId),
    }),
    [titleId, messageId, localized]
  );

  return (
    <Dialog
      minWidth={405}
      hidden={!isDialogOpen}
      onDismiss={close}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <div className={styles["footer"]}>
        <div className={styles["footer-section"]}>{props.left}</div>
        <div className={styles["footer-section"]}>{props.children}</div>
      </div>
    </Dialog>
  );
}
