@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.team-resource {
  margin-top: 20px;

  .resource-tab-item {
    cursor: pointer;
    color: $dark-color;
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #cecece;
    border-radius: 2px;
    display: inline-block;
    margin-right: 10px;

    &.active {
      border-color: $theme-primary;
      font-weight: 600;
    }
  }

  .list-container {
    margin-top: 20px;
  }

  .resource-table-row {
    cursor: pointer;
  }

  .resource-table-row-info {
    font-size: 12px;
    color: #605e5c;
  }

  .spinner {
    min-width: 100px;
    div {
      align-items: baseline;
    }
  }

  .download-button {
    min-width: 100px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    color: $theme-primary;
  }
}
