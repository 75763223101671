@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.handle-panel-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.handle-panel-content {
  width: 100%;
  height: 100%;
}

.handle-panel-handle {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  cursor: ew-resize;
}

.handle-panel-handle:hover {
  border-left: 3px solid $theme-primary;
}
