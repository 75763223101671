import {
  DependencyList,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useDelayFirstTransition<T>(
  value: T,
  minDelayInMilliseconds: number = 300
): T {
  const initialValueRef = useRef<T>(value);
  const initialTimestamp = useRef(new Date());
  const [output, setOutput] = useState<T>(value);
  useEffect(() => {
    if (value !== initialValueRef.current) {
      const delta = new Date().getTime() - initialTimestamp.current.getTime();
      if (delta >= minDelayInMilliseconds) {
        setOutput(value);
      } else {
        setTimeout(() => {
          setOutput(value);
        }, minDelayInMilliseconds - delta);
      }
    }
  }, [value, minDelayInMilliseconds]);
  return output;
}

export interface PendingState<T, P extends any[]> {
  params: P | null;
  promises: {
    resolve: (value: T) => void;
    reject: (reason?: any) => void;
  }[];
  isRunning: boolean;
}

export function useMergeableAsyncCallback<
  T,
  F extends (...args: any[]) => Promise<T>
>(
  getPendingState: () => PendingState<T, Parameters<F>>,
  fn: F,
  deps: DependencyList
) {
  const getPendingStateRef = useRef(getPendingState);
  getPendingStateRef.current = getPendingState;

  const fnRef = useRef(fn);
  fnRef.current = fn;

  return useCallback(
    async (...args: Parameters<typeof fn>): Promise<T> => {
      const pendingState = getPendingStateRef.current();
      pendingState.params = args;

      if (pendingState.isRunning) {
        const x = await new Promise<T>((resolve, reject) => {
          pendingState.promises.push({ resolve, reject });
        });
        return x;
      }

      try {
        pendingState.isRunning = true;

        const result = await (async () => {
          while (pendingState.params) {
            const params = pendingState.params;
            pendingState.params = null;

            const result = await fnRef.current(...params);
            if (pendingState.params === null) {
              return result;
            }
          }
          throw "Should not be reachable";
        })();

        pendingState.promises.forEach(p => p.resolve(result));
        return result;
      } catch (e) {
        pendingState.promises.forEach(p => p.reject(e));
        throw e;
      } finally {
        pendingState.promises = [];
        pendingState.isRunning = false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
}

export function useImageExists(imageUrl?: string) {
  const [isImageExist, setIsImageExist] = useState<boolean | null>(null);

  useEffect(() => {
    if (!imageUrl) {
      setIsImageExist(null);
      return;
    }

    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setIsImageExist(true);
    };
    img.onerror = () => {
      setIsImageExist(false);
    };

    return () => {
      if (img) {
        img.onload = null;
        img.onerror = null;
      }
    };
  }, [imageUrl]);

  return isImageExist;
}

export function useStateRef<T>(initalValue: T | (() => T)) {
  const [state, setState] = useState(initalValue);
  const stateRef = useRef(state);

  const setter = useCallback((value: T | ((prevState: T) => T)) => {
    if (typeof value === "function") {
      setState(prevState => {
        const newValue = (value as (prevState: T) => T)(prevState);
        stateRef.current = newValue;
        return newValue;
      });
    } else {
      stateRef.current = value;
      setState(value);
    }
  }, []) as typeof setState;

  return [state, setter, stateRef] as const;
}

export function useWaitUntil(value: boolean) {
  const latestValue = useRef(value);
  const resolvers = useRef<((value: any) => void)[]>([]);

  const waitUntil = useCallback(() => {
    if (latestValue.current === true) {
      return Promise.resolve();
    }
    return new Promise(resolve => {
      resolvers.current.push(resolve);
    });
  }, []);

  latestValue.current = value;

  useEffect(() => {
    if (value === true) {
      const oldResolvers = resolvers.current;
      resolvers.current = [];
      oldResolvers.forEach(resolve => {
        resolve(value);
      });
    }
  }, [value]);
  return waitUntil;
}
