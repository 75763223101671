import { Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { RANKING_LIST_DAY_RANGE } from "../../constants";
import { FOCRError } from "../../errors";
import { TeamUsageItem } from "../../types/team";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import TeamUsageTableRow from "../TeamUsageTableRow";
import styles from "./styles.module.scss";

interface EmptyStateUIProps {
  messageId?: string;
}

function EmptyStateUI(props: EmptyStateUIProps) {
  return (
    <div className={styles["empty-state-container"]}>
      <div className={styles["empty-state"]}>
        <div className={styles["empty-state-text"]}>
          <Label>
            <FormattedMessage
              id={props.messageId ?? "team.ranking.list.no_usage_yet"}
            />
          </Label>
        </div>
      </div>
    </div>
  );
}

interface TeamRankingListProps {
  isLoading: boolean;
  teamsUsages: TeamUsageItem[];
  regionKey: string;
  error?: FOCRError;
}

export default function TeamRankingList(props: TeamRankingListProps) {
  const { isLoading, teamsUsages, regionKey, error } = props;

  const isEmpty = !isLoading && teamsUsages.length === 0;

  if (isLoading) {
    return (
      <div className={styles["loading-container"]}>
        <ShortSpinner />
      </div>
    );
  }

  if (isEmpty) {
    return <EmptyStateUI messageId={error?.messageId} />;
  }

  return (
    <Table
      columnIds={[
        "team.ranking.list.team_name",
        "team.ranking.list.contact_email",
        "team.ranking.list.created_at",
        "team.ranking.list.usage_in_range",
      ]}
      columnIdValues={[{}, {}, {}, { days: RANKING_LIST_DAY_RANGE }]}
    >
      {teamsUsages.map((teamUsage, index) => (
        <TeamUsageTableRow
          teamUsage={teamUsage}
          key={index}
          regionKey={regionKey}
        />
      ))}
    </Table>
  );
}
