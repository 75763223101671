import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import * as React from "react";

import styles from "./styles.module.scss";

type Props = {
  className?: string;
};

export function Dropzone(props: Props) {
  const className = cn(styles["container"], props.className);
  return (
    <div className={className}>
      <Icon iconName="Add" className={styles["icon"]} />
      <div className={styles["text"]}>
        <FormattedMessage id="dropzone.drag_drop_message" />
      </div>
    </div>
  );
}
