import { Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useMemo, useState } from "react";

import { CUSTOM_MODEL_IMAGE_PAGE_SIZE } from "../../constants/layout";
import { PaginatedCustomModelImage } from "../../types/customModelImage";
import CustomModelImageGridItem from "../CustomModelImageGridItem";
import CustomModelImageLightboxModal from "../CustomModelImageLightboxModal";
import Paginator from "../Paginator";
import ShortSpinner from "../ShortSpinner";
import styles from "./styles.module.scss";

function EmptyStateUI() {
  return (
    <div className={styles["empty-state-container"]}>
      <div className={styles["empty-state"]}>
        <div className={styles["empty-state-text"]}>
          <Label>
            <FormattedMessage id="custom_model_grid.no_images" />
          </Label>
        </div>
      </div>
    </div>
  );
}

interface Props {
  paginatedCustomModelImage?: PaginatedCustomModelImage;
  isLoading: boolean;
  currentPage: number;
  navigateToPage: (page: number) => void;
  openLabeller: (imageId: string) => void;
}

function _CustomModelImageGrid(props: Props) {
  const {
    paginatedCustomModelImage,
    isLoading,
    currentPage,
    navigateToPage,
    openLabeller,
  } = props;

  const [imageIDToShowInLightBoxModal, setImageIDToShowInLightBoxModal] =
    useState<string | undefined>();

  const onNavigateToPage = useCallback(
    (page: number) => {
      navigateToPage(page);
    },
    [navigateToPage]
  );

  const { images, totalCount } = useMemo(() => {
    return {
      images: paginatedCustomModelImage?.images ?? [],
      totalCount: paginatedCustomModelImage?.pageInfo.totalCount ?? 0,
    };
  }, [paginatedCustomModelImage]);

  const onClickedImage = useCallback((id: string) => {
    setImageIDToShowInLightBoxModal(id);
  }, []);

  const onLightBoxModalDismiss = useCallback(() => {
    setImageIDToShowInLightBoxModal(undefined);
  }, []);

  if (isLoading) {
    return (
      <div className={styles["container"]}>
        <div className={styles["row"]}>
          <div className={styles["loading-container"]}>
            <ShortSpinner />
          </div>
        </div>
      </div>
    );
  }

  if (!isLoading && totalCount === 0) {
    return <EmptyStateUI />;
  }

  return (
    <div className={styles["container"]}>
      <div className={styles["row"]}>
        <div className={styles["info-container"]}>
          <span className={styles["file-count"]}>
            <FormattedMessage
              id="custom_model_grid.total_files"
              values={{ totalFiles: totalCount }}
            />
          </span>
        </div>
        <div className={styles["grid"]}>
          {images.map(image => (
            <CustomModelImageGridItem
              imageId={image.id}
              name={image.filename}
              url={image.url}
              date={image.createdAt}
              key={image.id}
              onClickPreview={onClickedImage}
            />
          ))}
        </div>
        <div className={styles["paginator-container"]}>
          <Paginator
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={CUSTOM_MODEL_IMAGE_PAGE_SIZE}
            navigateToPage={onNavigateToPage}
          />
        </div>
      </div>
      <CustomModelImageLightboxModal
        images={images}
        imageID={imageIDToShowInLightBoxModal}
        onDismiss={onLightBoxModalDismiss}
        onOpenLabeller={openLabeller}
      />
    </div>
  );
}

export const CustomModelImageGrid = React.memo(_CustomModelImageGrid);
export default CustomModelImageGrid;
