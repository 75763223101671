import { IconButton } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import styles from "./styles.module.scss";

export function SmallPaginator(props: {
  currentPage?: number;
  totalPage?: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  onClickPrevPage: () => void;
  onClickNextPage: () => void;
  pageNumberLabelId: string;
}) {
  const {
    currentPage,
    totalPage,
    hasPrevPage,
    hasNextPage,
    onClickPrevPage,
    onClickNextPage,
    pageNumberLabelId,
  } = props;

  return (
    <div className={styles.pagination}>
      {currentPage != null && totalPage != null ? (
        <p className={styles.pageNumber}>
          <FormattedMessage
            id={pageNumberLabelId}
            values={{
              current: currentPage,
              total: totalPage,
            }}
          />
        </p>
      ) : null}

      <div className={styles.paginationButtonGroup}>
        <IconButton
          className={styles.paginationButton}
          iconProps={{
            iconName: "ChevronLeft",
          }}
          styles={{
            icon: {
              color: "#A19F9D",
            },
          }}
          onClick={onClickPrevPage}
          disabled={!hasPrevPage}
        />
        <IconButton
          className={styles.paginationButton}
          iconProps={{
            iconName: "ChevronRight",
          }}
          styles={{
            icon: {
              color: "#A19F9D",
            },
          }}
          onClick={onClickNextPage}
          disabled={!hasNextPage}
        />
      </div>
    </div>
  );
}
