import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";

import CustomModelTable from "../components/CustomModelTable";
import { useFetchTeamCustomModels } from "../hooks/admin";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { AdminPathParam } from "../models";
import { BriefCustomModel } from "../types/customModel";
import {
  getOffsetByPage,
  getOffsetOfBeginningOfPage,
} from "../utils/pagination";

const PAGE_SIZE = 20;

function _AdminTeamDetailResourcesCustomModelsContainer() {
  const { teamId, region } = useUnsafeParams<AdminPathParam>();
  const { customModels, isFetching, pageInfo } = useAppSelector(
    state => state.admin.teamDetail.resourceState.customModelsState
  );
  const navigate = useNavigate();

  const [offset, setOffset] = useState(
    getOffsetOfBeginningOfPage(pageInfo?.offset ?? 0, PAGE_SIZE)
  );
  const onNavigateToPage = useCallback((pageNumber: number) => {
    setOffset(getOffsetByPage(pageNumber));
  }, []);

  const onItemClick = useCallback(
    (customModel: BriefCustomModel) => {
      navigate(
        `/admin/team/${region}/${teamId}/resources/custom-models/${customModel.id}`
      );
    },
    [navigate, region, teamId]
  );

  const { fetchedOnce } = useFetchTeamCustomModels(
    PAGE_SIZE,
    offset,
    teamId,
    region
  );

  return (
    <CustomModelTable
      offset={offset}
      customModels={customModels}
      isCustomModelsFetching={isFetching || !fetchedOnce}
      customModelsPageInfo={pageInfo}
      pageSize={PAGE_SIZE}
      onNavigateToPage={onNavigateToPage}
      onItemClick={onItemClick}
    />
  );
}

export const AdminTeamDetailResourcesCustomModelsContainer = React.memo(
  _AdminTeamDetailResourcesCustomModelsContainer
);
export default AdminTeamDetailResourcesCustomModelsContainer;
