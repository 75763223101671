import { Icon, Text, TextField, Toggle } from "@fluentui/react";
import cn from "classnames";
import produce from "immer";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { KeyValueToken } from "../../types/keyValue";
import RegexBuilder from "../RegexBuilder";
import styles from "./styles.module.scss";

interface KeySectionProps {
  tokens: KeyValueToken[];
  tokenErrorMessages: (string | null)[];
  onChange: (tokens: KeyValueToken[]) => void;
  sectionErrorMessage?: string;
}

const TableHeader = React.memo(
  (props: { text: string; columnClassName: string }) => {
    const { text, columnClassName } = props;
    return (
      <div className={cn(columnClassName, styles["table-header-cell"])}>
        <Text className={styles["table-header-text"]}>{text}</Text>
      </div>
    );
  }
);

const TokenRow = React.memo(
  (props: {
    token: KeyValueToken;
    onTokenChange: (token: KeyValueToken) => void;
    onDeleteToken: (token: KeyValueToken) => void;
    errorMessage?: string;
  }) => {
    const { token, onTokenChange, onDeleteToken, errorMessage } = props;
    const { localized } = useLocale();
    const { hasPermissionToEditResource } = useTeamPermission();
    const [isRegexBuilderOpen, setIsRegexBuilderOpen] = React.useState(false);

    const onTokenValueChanged = React.useCallback(
      (
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue !== undefined) {
          onTokenChange(
            produce(token, draftToken => {
              draftToken.token = newValue;
            })
          );
        }
      },
      [token, onTokenChange]
    );

    const onUseFuzzyChanged = React.useCallback(
      (
        _event: React.MouseEvent<HTMLElement, MouseEvent>,
        checked?: boolean
      ) => {
        if (checked !== undefined) {
          onTokenChange(
            produce(token, draftToken => {
              draftToken.useFuzzySearch = checked;
            })
          );
        }
      },
      [token, onTokenChange]
    );

    const onDelete = React.useCallback(() => {
      onDeleteToken(token);
    }, [onDeleteToken, token]);

    const openRegexBuilder = React.useCallback(() => {
      setIsRegexBuilderOpen(true);
    }, []);

    const closeRegexBuilder = React.useCallback(() => {
      setIsRegexBuilderOpen(false);
    }, []);

    const applyRegex = React.useCallback(
      (regex: string) => {
        onTokenChange(
          produce(token, draftToken => {
            draftToken.token = regex;
          })
        );
        closeRegexBuilder();
      },
      [token, onTokenChange, closeRegexBuilder]
    );

    return (
      <div className={styles["token-row"]}>
        <div className={cn(styles["key-column"], styles["table-cell"])}>
          <TextField
            className={styles["key-input"]}
            placeholder={localized(
              "key_value.modal.keys.key_input.placeholder"
            )}
            onChange={onTokenValueChanged}
            value={token.token}
            errorMessage={errorMessage}
            disabled={!hasPermissionToEditResource}
          />
          {hasPermissionToEditResource && (
            <Text
              className={styles["regex-builder-button"]}
              onClick={openRegexBuilder}
            >
              {localized("key_value.modal.regex_builder")}
            </Text>
          )}
        </div>
        <div
          className={cn(
            styles["fuzzy-column"],
            styles["table-cell"],
            styles["fuzzy-cell"]
          )}
        >
          <Toggle
            className={styles["fuzzy-toggle"]}
            checked={token.useFuzzySearch}
            onChange={onUseFuzzyChanged}
            onText={localized("common.on")}
            offText={localized("common.off")}
            disabled={!hasPermissionToEditResource}
          />
          {hasPermissionToEditResource && (
            <Icon
              onClick={onDelete}
              iconName="Delete"
              className={styles["delete-button"]}
            />
          )}
        </div>
        {isRegexBuilderOpen && (
          <RegexBuilder
            isOpen={true}
            onApply={applyRegex}
            onCancel={closeRegexBuilder}
          />
        )}
      </div>
    );
  }
);

export const KeySection = React.memo((props: KeySectionProps) => {
  const { tokens, onChange, tokenErrorMessages, sectionErrorMessage } = props;
  const { localized } = useLocale();

  const onAddNew = React.useCallback(() => {
    onChange([...tokens, { token: "", useFuzzySearch: false }]);
  }, [tokens, onChange]);

  const deleteToken = React.useCallback(
    (index: number) => {
      const newTokens = [...tokens];
      newTokens.splice(index, 1);
      onChange(newTokens);
    },
    [tokens, onChange]
  );

  const updateToken = React.useCallback(
    (index: number, token: KeyValueToken) => {
      onChange(
        produce(tokens, draftTokens => {
          draftTokens[index] = token;
        })
      );
    },
    [tokens, onChange]
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["key-section"]}>
      <div className={styles["title-section"]}>
        <div className={styles["title-row"]}>
          <Text className={styles["title-text"]}>
            {localized("key_value.modal.keys.title")}
          </Text>
          {hasPermissionToEditResource && (
            <Text className={styles["new-key-button"]} onClick={onAddNew}>
              {localized("key_value.modal.keys.add")}
            </Text>
          )}
        </div>
        {sectionErrorMessage && (
          <Text className={styles["section-error"]}>{sectionErrorMessage}</Text>
        )}
      </div>
      {tokens.length > 0 && (
        <div className={styles["table-header-row"]}>
          <TableHeader
            columnClassName={styles["key-column"]}
            text={localized("key_value.modal.keys.columns.key")}
          />
          <TableHeader
            columnClassName={styles["fuzzy-column"]}
            text={localized("key_value.modal.keys.columns.fuzzy")}
          />
        </div>
      )}
      {tokens.length > 0 ? (
        tokens.map((token, index) => (
          <TokenRow
            key={index}
            token={token}
            onDeleteToken={() => deleteToken(index)}
            onTokenChange={token => updateToken(index, token)}
            errorMessage={tokenErrorMessages[index] || undefined}
          />
        ))
      ) : (
        <Text className={styles["empty-row"]}>
          {localized("key_value.modal.keys.empty")}
        </Text>
      )}
    </div>
  );
});
