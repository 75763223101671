import {
  ITextFieldProps,
  Label,
  TextField as MSTextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

import { useLocale } from "../../../contexts/locale";

export interface Props extends Omit<ITextFieldProps, "onRendeLabel" | "label"> {
  labelId?: string;
  placeholderId?: string;
  errorMessageId?: string;
}

export function TextField(props: Props) {
  const {
    required,
    labelId,
    disabled,
    placeholder,
    placeholderId,
    errorMessage,
    errorMessageId,
    ...otherProps
  } = props;
  const { localized } = useLocale();
  const onRenderLabel = useCallback(() => {
    if (labelId == null) {
      return <></>;
    }
    return (
      <Label required={required} disabled={disabled}>
        <FormattedMessage id={labelId} />
      </Label>
    );
  }, [labelId, required, disabled]);

  const customStyles = React.useMemo(() => {
    return {
      root: [
        {
          whiteSpace: "nowrap",
        },
      ],
      fieldGroup: [
        {
          borderRadius: 8,
          selectors: {
            ":hover": {
              borderColor: "",
            },
            ":after": {
              // Override focus style
              borderRadius: 8,
              border: "0px",
            },
          },
        },
        "border-border-primary",
        "h-[48px] py-[8px] px-[16px]",
      ],
      field: ["text-body-medium", "text-text-primary", "caret-text-brand"],
    };
  }, []);

  return (
    <MSTextField
      styles={customStyles}
      {...otherProps}
      required={required}
      label=" " // dummy label for no duplicated asterisks
      onRenderLabel={onRenderLabel}
      disabled={disabled}
      placeholder={(placeholderId && localized(placeholderId)) || placeholder}
      errorMessage={
        (errorMessageId && localized(errorMessageId)) || errorMessage
      }
    />
  );
}
