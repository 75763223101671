import { Values } from "@oursky/react-messageformat";
import * as React from "react";
import { useCallback, useState } from "react";

import { AppConfig } from "../../config";
import { useLocale } from "../../contexts/locale";
import errors, { FOCRError } from "../../errors";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

const messageValues: Values = {
  passwordMinLength: AppConfig.auth.passwordMinLength,
};

type FieldValidator = (
  oldPassword: string,
  newPassword: string,
  verifyNewPassword: string
) => boolean;

export function useChangePasswordPane() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verifyNewPassword, setVerifyNewPassword] = useState("");
  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState<
    string | undefined
  >();
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState<
    string | undefined
  >();
  const [verfiyNewPasswordErrorMessage, setVerifyNewPasswordErrorMessage] =
    useState<string | undefined>();
  const [isDisabled, setIsDisabled] = useState(false);

  const onOldPasswordChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value !== undefined) {
        setOldPassword(value);
        setOldPasswordErrorMessage(undefined);
      }
    },
    []
  );

  const onNewPasswordChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value !== undefined) {
        setNewPassword(value);
        setNewPasswordErrorMessage(undefined);
      }
    },
    []
  );

  const onVerifyNewPasswordChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value !== undefined) {
        setVerifyNewPassword(value);
        setVerifyNewPasswordErrorMessage(undefined);
      }
    },
    []
  );

  const _existenceCheck = useCallback(
    (
      oldPassword: string,
      newPassword: string,
      _verifyNewPassword: string
    ): boolean => {
      let result = true;

      if (!oldPassword) {
        result = false;
        setOldPasswordErrorMessage("error.change_password.empty_old_password");
      }
      if (!newPassword) {
        result = false;
        setNewPasswordErrorMessage("error.change_password.empty_new_password");
      }

      return result;
    },
    []
  );

  const _validatePassword = useCallback(
    (
      _oldPassword: string,
      newPassword: string,
      verifyNewPassword: string
    ): boolean => {
      let result = true;

      if (newPassword !== verifyNewPassword) {
        result = false;
        setVerifyNewPasswordErrorMessage(
          "error.change_password.inconsistent_new_password"
        );
      }
      if (
        newPassword.length < AppConfig.auth.passwordMinLength ||
        !/[0-9]/.test(newPassword) ||
        !/[a-z]/.test(newPassword) ||
        !/[A-Z]/.test(newPassword)
      ) {
        result = false;
        setNewPasswordErrorMessage("error.password_too_simple");
      }
      return result;
    },
    []
  );

  const validateInputs = useCallback(
    (
      oldPassword: string,
      newPassword: string,
      verifyNewPassword: string
    ): boolean => {
      const validators: FieldValidator[] = [_existenceCheck, _validatePassword];

      for (const validator of validators) {
        const result = validator(oldPassword, newPassword, verifyNewPassword);

        if (!result) {
          return false;
        }
      }
      return true;
    },
    [_existenceCheck, _validatePassword]
  );

  const onDismissed = useCallback(() => {
    setOldPassword("");
    setNewPassword("");
    setVerifyNewPassword("");
    setOldPasswordErrorMessage(undefined);
    setNewPasswordErrorMessage(undefined);
    setVerifyNewPasswordErrorMessage(undefined);
  }, []);

  const setError = useCallback(
    (e: FOCRError) => {
      if (e.messageId === errors.IncorrectPassword.messageId) {
        setOldPasswordErrorMessage(e.messageId);
      } else {
        setNewPasswordErrorMessage(e.messageId);
      }
    },
    [setOldPasswordErrorMessage, setNewPasswordErrorMessage]
  );

  return React.useMemo(
    () => ({
      oldPassword,
      newPassword,
      verifyNewPassword,
      oldPasswordErrorMessage,
      newPasswordErrorMessage,
      verfiyNewPasswordErrorMessage,
      onVerifyNewPasswordChange,
      onNewPasswordChange,
      onOldPasswordChange,
      validateInputs,
      onDismissed,
      setError,
      isDisabled,
      setIsDisabled,
    }),
    [
      oldPassword,
      newPassword,
      verifyNewPassword,
      oldPasswordErrorMessage,
      newPasswordErrorMessage,
      verfiyNewPasswordErrorMessage,
      onVerifyNewPasswordChange,
      onNewPasswordChange,
      onOldPasswordChange,
      validateInputs,
      onDismissed,
      setError,
      isDisabled,
      setIsDisabled,
    ]
  );
}

export const ChangePasswordPane = React.memo(
  (props: ReturnType<typeof useChangePasswordPane>) => {
    const {
      oldPassword,
      newPassword,
      verifyNewPassword,
      oldPasswordErrorMessage,
      newPasswordErrorMessage,
      verfiyNewPasswordErrorMessage,
      onVerifyNewPasswordChange,
      onNewPasswordChange,
      onOldPasswordChange,
      isDisabled,
    } = props;

    const { localized } = useLocale();

    return (
      <>
        <TextField
          type="password"
          className={styles["input-field"]}
          labelId="change_password.old_password"
          value={oldPassword}
          errorMessage={
            oldPasswordErrorMessage && localized(oldPasswordErrorMessage)
          }
          onChange={onOldPasswordChange}
          disabled={isDisabled}
        />
        <TextField
          type="password"
          className={styles["input-field"]}
          labelId="change_password.new_password"
          value={newPassword}
          errorMessage={
            newPasswordErrorMessage &&
            localized(newPasswordErrorMessage, messageValues)
          }
          onChange={onNewPasswordChange}
          disabled={isDisabled}
        />
        <TextField
          type="password"
          className={styles["input-field"]}
          labelId="change_password.verify_new_password"
          value={verifyNewPassword}
          errorMessage={
            verfiyNewPasswordErrorMessage &&
            localized(verfiyNewPasswordErrorMessage)
          }
          onChange={onVerifyNewPasswordChange}
          disabled={isDisabled}
        />
      </>
    );
  }
);
