import * as React from "react";
import { useSelector } from "react-redux";

import { useWebhookActionCreator } from "../actions/webhook";
import { RootState } from "../redux/types";
import { Webhook } from "../types/webhook";

export function useFetchWehhooks(resourceOwnerId: string | undefined) {
  const { listWebhooks } = useWebhookActionCreator();
  React.useEffect(
    () => {
      listWebhooks();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourceOwnerId]
  );
}

export function useWebhook(webhookId: string) {
  const { getWebhook } = useWebhookActionCreator();
  const webhook = useSelector(
    (state: RootState) => state.webhook.currentWebhook
  );
  const [isFailedToFetchWebhook, setIsFailedToFetchWebhook] =
    React.useState(false);

  React.useEffect(() => {
    if (!webhook || webhook.id !== webhookId) {
      getWebhook(webhookId)
        .then(() => {})
        .catch(() => {
          setIsFailedToFetchWebhook(true);
        });
    }
  }, [getWebhook, webhookId, webhook]);

  return {
    webhook,
    isFailedToFetchWebhook,
  };
}

interface CommonWebhookContainerSuccessState {
  state: "success";
  webhook: Webhook;
}

interface CommonWebhookContainerErrorState {
  state: "error";
}

interface CommonWebhookContainerLoadingState {
  state: "loading";
}

export type CommonWebhookContainerState =
  | CommonWebhookContainerLoadingState
  | CommonWebhookContainerErrorState
  | CommonWebhookContainerSuccessState;

export function useCommonWebhookContainerState(webhookId: string) {
  const { webhook, isFailedToFetchWebhook } = useWebhook(webhookId);

  const containerState: CommonWebhookContainerState = isFailedToFetchWebhook
    ? { state: "error" }
    : webhook !== undefined && webhook.id === webhookId
    ? { state: "success", webhook }
    : { state: "loading" };

  return containerState;
}
