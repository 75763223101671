import * as React from "react";
import { Navigate, useNavigate } from "react-router";

import { useAppActionCreator } from "../actions/app";
import { useResourceOwnerActionCreator } from "../actions/resourceOwner";
import { useTeamActionCreator } from "../actions/team";
import { useUserActionCreator } from "../actions/user";
import {
  InvitationAccountNotMatch,
  InvitationLoggedOut,
} from "../components/InvitationAccept";
import { ExistingTeamInvitations } from "../components/InvitationList";
import { Layout, Main, Top } from "../components/Layout";
import ShortSpinner from "../components/ShortSpinner";
import { AppConfig } from "../config";
import {
  GetTeamInvitationsFromAllRegionsErrorState,
  GetTeamInvitationsFromAllRegionsSuccessState,
  useGetTeamInvitationsFromAllRegions,
} from "../hooks/invitation";
import { useAppSelector } from "../hooks/redux";
import { useRedirectToRegionForTeam } from "../hooks/region";
import { useToast } from "../hooks/toast";
import HeaderContainer from "./Header";

type ValidatedInvitationCode =
  | {
      type: "noInvitationCode";
    }
  | {
      type: "notAuthenticated";
      teamName?: string;
      invitationCode?: string;
    }
  | {
      type: "validated" | "validating" | "emailNotMatch";
      invitationCode: string;
    };

function useValidatedInvitationCode(): ValidatedInvitationCode {
  const { invitationIsValid } = useTeamActionCreator();
  const invitationCode = useAppSelector(state => state.app.invitationCode);
  const isAuthenticatedRef = React.useRef(
    useAppSelector(state => state.user.isAuthenticated)
  );
  const [validationState, setValidationState] =
    React.useState<ValidatedInvitationCode>(
      invitationCode === undefined
        ? isAuthenticatedRef.current
          ? { type: "noInvitationCode" }
          : { type: "notAuthenticated" }
        : { type: "validating", invitationCode }
    );

  React.useEffect(() => {
    if (invitationCode !== undefined) {
      invitationIsValid(invitationCode)
        .then(({ reason, isValid, teamName }) => {
          if (isValid) {
            if (isAuthenticatedRef.current) {
              setValidationState({ type: "validated", invitationCode });
            } else {
              setValidationState({
                type: "notAuthenticated",
                teamName,
                invitationCode,
              });
            }
          } else if (reason === "email_not_match") {
            setValidationState({ type: "emailNotMatch", invitationCode });
          } else if (!isAuthenticatedRef.current) {
            setValidationState({ type: "notAuthenticated", invitationCode });
          } else {
            setValidationState({ type: "noInvitationCode" });
          }
        })
        .catch(() => {
          if (isAuthenticatedRef.current) {
            setValidationState({ type: "noInvitationCode" });
          } else {
            setValidationState({ type: "notAuthenticated", invitationCode });
          }
        });
    }
  }, [invitationCode, invitationIsValid]);

  return validationState;
}

type InvitationContainerState = (
  | {
      type: "loading" | "emailNotMatch";
    }
  | {
      type: "notAuthenticated";
      teamName?: string;
    }
  | GetTeamInvitationsFromAllRegionsErrorState
  | GetTeamInvitationsFromAllRegionsSuccessState
) & {
  invitationCode?: string;
};

function useInvitationState(): InvitationContainerState {
  const getInvitationsState = useGetTeamInvitationsFromAllRegions();
  const validatedInvitationCode = useValidatedInvitationCode();

  return React.useMemo(() => {
    switch (validatedInvitationCode.type) {
      case "validating":
        return {
          type: "loading",
          invitationCode: validatedInvitationCode.invitationCode,
        };

      case "noInvitationCode":
      case "validated": {
        if (getInvitationsState.type === "success") {
          return {
            ...getInvitationsState,
            invitationCode:
              validatedInvitationCode.type === "validated"
                ? validatedInvitationCode.invitationCode
                : undefined,
          };
        }
        return getInvitationsState;
      }

      case "emailNotMatch":
        return {
          type: "emailNotMatch",
          invitationCode: validatedInvitationCode.invitationCode,
        };

      case "notAuthenticated":
        return {
          type: "notAuthenticated",
          teamName: validatedInvitationCode.teamName,
          invitationCode: validatedInvitationCode.invitationCode,
        };
    }
  }, [getInvitationsState, validatedInvitationCode]);
}

export default function InvitationContainer() {
  const { acceptTeamInvitation, rejectTeamInvitation } = useTeamActionCreator();
  const toast = useToast();
  const state = useInvitationState();
  const { ignoreInvitationsInOnboarding } = useAppActionCreator();
  const hasTeamInRegion = useAppSelector(
    state => state.resourceOwner.resourceOwnerId !== undefined
  );
  const { triggerLoginWithInvitation, triggerSignupWithInvitation } =
    useUserActionCreator();

  const invitationCode = state.invitationCode;

  const navigate = useNavigate();
  const { selectTeam } = useResourceOwnerActionCreator();

  const onAccept = React.useCallback(
    async (invitationId: string, region: string): Promise<void> => {
      try {
        await acceptTeamInvitation(invitationId, region);
      } catch (e) {
        toast.error("error.invite.fail_to_accept");
        throw e;
      }
    },
    [acceptTeamInvitation, toast]
  );

  const onDelete = React.useCallback(
    async (invitationId: string, region: string): Promise<void> => {
      try {
        await rejectTeamInvitation(invitationId, region);
      } catch (e) {
        toast.error("team.invitation.remove.unexpected_error");
        throw e;
      }
    },
    [rejectTeamInvitation, toast]
  );

  const { redirectToRegionForTeam } = useRedirectToRegionForTeam();

  const onViewTeam = React.useCallback(
    (teamId: string, region: string) => {
      if (region === AppConfig.region) {
        selectTeam(teamId);
        navigate("/team");
      } else {
        redirectToRegionForTeam(region, teamId);
      }
    },
    [selectTeam, navigate, redirectToRegionForTeam]
  );

  const onSkip = React.useCallback(() => {
    ignoreInvitationsInOnboarding();
    navigate(hasTeamInRegion ? "/" : "/onboarding");
  }, [hasTeamInRegion, navigate, ignoreInvitationsInOnboarding]);

  const loginWithAnotherUser = React.useCallback(() => {
    if (!invitationCode) {
      return;
    }
    triggerLoginWithInvitation(invitationCode);
  }, [invitationCode, triggerLoginWithInvitation]);

  const signup = React.useCallback(() => {
    if (!invitationCode) {
      return;
    }
    triggerSignupWithInvitation(invitationCode);
  }, [invitationCode, triggerSignupWithInvitation]);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true} hasLeft={false}>
        {state.type === "notAuthenticated" && state.invitationCode && (
          <InvitationLoggedOut teamName={state.teamName} signup={signup} />
        )}

        {state.type === "notAuthenticated" &&
          state.invitationCode === undefined && <Navigate to="/" replace />}

        {state.type === "emailNotMatch" && (
          <InvitationAccountNotMatch
            loginWithAnotherUser={loginWithAnotherUser}
          />
        )}
        {state.type === "success" && (
          <ExistingTeamInvitations
            invitationsByRegion={state.invitationsByRegion}
            selectedInvitationId={state.invitationCode}
            hasTeamInRegion={hasTeamInRegion}
            onAccept={onAccept}
            onDelete={onDelete}
            onViewTeam={onViewTeam}
            onSkip={onSkip}
          />
        )}
        {state.type === "error" && (
          <ExistingTeamInvitations
            invitationsByRegion={{}}
            isError={true}
            hasTeamInRegion={hasTeamInRegion}
            onAccept={onAccept}
            onDelete={onDelete}
            onViewTeam={onViewTeam}
            onSkip={onSkip}
          />
        )}
        {state.type === "loading" && <ShortSpinner />}
      </Main>
    </Layout>
  );
}
