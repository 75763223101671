@import "../../styles/variables.scss";

.info-block {
  background-color: #f6fdfc;
  border-left: 3px solid $theme-dark;
  border-radius: 2px;
  padding: 8px;
  display: inline-block;
  font-size: 14px;
}

.info-block-container {
  margin-bottom: 16px;
}

.info-block-label-container {
  padding-bottom: 12px;
  font-weight: 600;
  font-size: 14px;

  display: flex;
  align-items: center;

  i {
    margin-right: 8px;
  }
}

.info-block-description {
  font-size: 14px;
  color: #323130;
}
