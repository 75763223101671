import * as React from "react";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useFormActionCreator } from "../actions/form";
import DataNotSavedPrompt from "../components/DataNotSavedPrompt";
import FormatterActionSelector from "../components/FormatterActionSelector";
import FormatterEditor from "../components/FormatterEditor";
import FormatterStepEditor from "../components/FormatterStepEditor";
import { Layout, Main, Right, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import {
  FormEditorProvider,
  buildFormSettings,
  useFormEditor,
} from "../contexts/formEditor";
import {
  FormatterEditorProvider,
  useFormatterEditor,
} from "../contexts/formatterEditor";
import errors, { FOCRError } from "../errors";
import { useExtractorFieldSchema } from "../hooks/extractorFieldSchema";
import { useUnsafeParams } from "../hooks/params";
import { useToast } from "../hooks/toast";
import { FormatterStorage } from "../types/mappers/formatter";
import { FormNavBarLayout, FormNavTabKey } from "./FormNavBarLayout";
import HeaderContainer from "./Header";

function useSetUpForm(formId: string) {
  const { form, getForm, setIsFailedToFetchForm } = useFormEditor();

  React.useEffect(() => {
    if (!form || form.id !== formId) {
      getForm(formId).catch(() => {
        setIsFailedToFetchForm(true);
      });
    }
  }, [form, getForm, setIsFailedToFetchForm, formId]);
}

type PathParam = {
  formId: string;
};

const FormatterContainerImpl = React.memo(() => {
  const { formId } = useUnsafeParams<PathParam>();
  const { discardForm } = useFormActionCreator();
  const {
    form,
    isFailedToFetchForm,
    isConfirmConflictModalOpen,
    cancelSaveForm,
  } = useFormEditor();
  useSetUpForm(formId);
  const { isAddingStep, selectedStepIndex, steps, isFormatterNotSaved } =
    useFormatterEditor();
  const { getExtractorFieldSchema } = useExtractorFieldSchema();

  React.useEffect(() => {
    if (form !== undefined) {
      getExtractorFieldSchema(form?.id);
    }
  }, [form, getExtractorFieldSchema]);

  const isFormLoaded = form !== undefined && form.id === formId;
  const isLoading = !isFormLoaded && !isFailedToFetchForm;
  const rightVisible = isAddingStep || selectedStepIndex >= 0;

  React.useEffect(() => {
    return () => {
      discardForm();
    };
  }, [discardForm]);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={isLoading} />
      {form && (
        <Main hasTop={true}>
          <FormNavBarLayout selectedTab={FormNavTabKey.Formatter} form={form}>
            <FormatterEditor
              isConfirmConflictModalOpen={isConfirmConflictModalOpen}
              onCancelConflictModal={cancelSaveForm}
            />
          </FormNavBarLayout>
        </Main>
      )}
      {rightVisible && (
        <Right hasTop={true}>
          {isAddingStep && <FormatterActionSelector />}
          {selectedStepIndex >= 0 && (
            <FormatterStepEditor
              step={steps[selectedStepIndex]}
              key={selectedStepIndex}
            />
          )}
        </Right>
      )}
      <DataNotSavedPrompt
        isDataChanged={isFormatterNotSaved}
        titleTextId="extractor_setting.form_not_saved_prompt.title"
        messageTextId="extractor_setting.form_not_saved_prompt.save_warning"
        backTextId="extractor_setting.form_not_saved_prompt.go_back"
        continueTextId="extractor_setting.form_not_saved_prompt.leave_page"
      />
    </Layout>
  );
});

function _FormFormatterEditorProvider(props: { children: React.ReactNode }) {
  const { children } = props;
  const { formId } = useUnsafeParams<PathParam>();
  useSetUpForm(formId);
  const { form, updateForm } = useFormEditor();

  const { getExtractorFieldSchema } = useExtractorFieldSchema();

  const { handleConflict } = useConfirmModalActionCreator();

  const { saveForm } = useFormActionCreator();

  const toast = useToast();

  const onSaveFormatter = React.useCallback(
    (formatterStorage: FormatterStorage) => {
      if (form !== undefined) {
        const formSetting = buildFormSettings(form, {
          formatter: formatterStorage,
        });
        updateForm(formSetting);
      }
      return handleConflict(
        async () => {
          await saveForm();
        },
        async () => {
          await saveForm(true);
        },
        {
          titleId: "form_editor.form_modifed_prompt.title",
          messageId: "form_editor.form_modifed_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      )
        .then(() => {
          toast.success("form_editor.form_is_saved");
        })
        .catch((e: unknown) => {
          if (e instanceof FOCRError) {
            toast.error(e.messageId, undefined, e.detail);
          } else {
            console.error(e);
            toast.error(
              errors.UnknownError.messageId,
              undefined,
              errors.UnknownError.detail
            );
          }
          throw e;
        });
    },
    [form, handleConflict, saveForm, toast, updateForm]
  );

  React.useEffect(() => {
    if (form !== undefined) {
      getExtractorFieldSchema(form.id);
    }
  }, [form, getExtractorFieldSchema]);

  return (
    <FormatterEditorProvider
      extractorId={formId}
      formatterSource={form?.config.formatter}
      onSaveFormatter={onSaveFormatter}
    >
      {children}
    </FormatterEditorProvider>
  );
}

const FormFormatterEditorProvider = React.memo(_FormFormatterEditorProvider);

const FormatterContainer = React.memo(() => {
  return (
    <FormEditorProvider>
      <FormFormatterEditorProvider>
        <FormatterContainerImpl />
      </FormFormatterEditorProvider>
    </FormEditorProvider>
  );
});

export default FormatterContainer;
