import {
  DefaultButton,
  DirectionalHint,
  IButtonProps,
  ICalloutProps,
  Pivot,
  PivotItem,
  PrimaryButton,
  TeachingBubble,
  Text,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React, { useCallback, useMemo, useRef, useState } from "react";

import { CustomModelEditorRightBarTargetIds } from "../../constants/customModel";
import { CustomModelLabelSchemaTypeDefintion } from "../../constants/customModelLabelSchema";
import { useLocale } from "../../contexts/locale";
import { CustomModelLabelSchema } from "../../types/customModel";
import { CustomModelPreset } from "../../types/customModelPreset";
import { getLabelSchemaDisplayName } from "../../utils/labelSchema";
import {
  PreferenceKey,
  getPreference,
  setPreference,
} from "../../utils/preference";
import CustomModelEditorFieldEditPane from "../CustomModelEditorFieldEditPane";
import CustomModelEditorFrozenField from "../CustomModelEditorFrozenField";
import InfoNote from "../InfoNote";
import styles from "./styles.module.scss";

function getIsNextStepBubbleDismissed(): boolean {
  const isNextStepBubbleDismissed = getPreference(
    PreferenceKey.isCustomModelEditorRightBarNextStepBubbleDismissed
  );
  return isNextStepBubbleDismissed === null
    ? false
    : JSON.parse(isNextStepBubbleDismissed);
}

interface NextStepBubbleProps {
  targetId: string;
  onDismiss: (ev?: any) => void;
  onProceed: () => void;
}

const NextStepBubble = React.memo((props: NextStepBubbleProps) => {
  const { targetId, onDismiss, onProceed } = props;

  const onProceedButtonClicked = useCallback(() => {
    onProceed();
    onDismiss();
  }, [onDismiss, onProceed]);

  const primaryButtonProps: IButtonProps = useMemo(
    () => ({
      onClick: onProceedButtonClicked,
      children: (
        <Text variant="medium">
          <FormattedMessage id="custom_model_editor.right_bar.next_step.bubble.process_button" />
        </Text>
      ),
    }),
    [onProceedButtonClicked]
  );

  const calloutProps: ICalloutProps = useMemo(
    () => ({
      directionalHint: DirectionalHint.leftBottomEdge,
    }),
    []
  );

  return (
    <TeachingBubble
      target={`#${targetId}`}
      primaryButtonProps={primaryButtonProps}
      calloutProps={calloutProps}
    >
      <Text className={styles["teaching-bubble-desc"]} variant="medium">
        <FormattedMessage
          id="custom_model_editor.right_bar.next_step.bubble.desc"
          values={{
            boldTextClassName: styles["teaching-bubble-desc-bold"],
          }}
        />
      </Text>
    </TeachingBubble>
  );
});

interface FieldsPaneProps {
  preset?: CustomModelPreset;
  labelSchema: CustomModelLabelSchema[];
  unfrozenLabelSchema: CustomModelLabelSchema[];
  appendLabelField: () => void;
  updateLabelField: (index: number, field: CustomModelLabelSchema) => void;
  deleteLabelField: (index: number) => void;
  labelFieldErrors: (string | null)[];
  canEdit: boolean;
}

const FieldsPane = React.memo((props: FieldsPaneProps) => {
  const {
    labelSchema,
    unfrozenLabelSchema,
    appendLabelField,
    updateLabelField,
    deleteLabelField,
    labelFieldErrors,
    preset,
    canEdit,
  } = props;

  const fieldListRef = useRef<HTMLDivElement | null>(null);
  const onAddField = useCallback(() => {
    appendLabelField();
    fieldListRef.current?.scrollTo(0, 0);
  }, [appendLabelField]);

  return (
    <>
      <div className={styles["desc"]}>
        <InfoNote notes={["custom_model_editor.right_bar.label.desc"]} />
      </div>
      <div className={styles["subtitle"]}>
        <FormattedMessage id="custom_model_editor.right_bar.label.subtitle" />
      </div>

      {canEdit && (
        <DefaultButton
          iconProps={{ iconName: "add" }}
          className={styles["add-new-field-button"]}
          onClick={onAddField}
        >
          <FormattedMessage id="custom_model_editor.right_bar.add_new_field_button" />
        </DefaultButton>
      )}

      {preset && (
        <div className={styles["preset"]}>
          <div className={styles["subtitle"]}>
            <FormattedMessage id="custom_model_editor.right_bar.label.predefined_fields" />
          </div>
          <div className={styles["info"]}>
            <FormattedMessage
              id="custom_model_editor.right_bar.label.preset"
              values={{
                preset: (
                  <FormattedMessage id={`prebuilt_custom_model.${preset}`} />
                ),
              }}
            />
          </div>
        </div>
      )}

      <div
        ref={fieldListRef}
        className={classnames(styles["field-list"], {
          [styles["with-preset"]]: preset !== undefined,
        })}
      >
        {unfrozenLabelSchema.map((value, index) =>
          canEdit ? (
            <CustomModelEditorFieldEditPane
              index={index}
              labelSchema={value}
              key={`label-field-${index}`}
              error={labelFieldErrors[index]}
              onChange={updateLabelField}
              onRemove={deleteLabelField}
            />
          ) : value.name.trim() ? (
            <CustomModelEditorFrozenField
              name={getLabelSchemaDisplayName(value)}
              type={value.type}
              format={value.config?.format}
              key={`label-field-${index}`}
            />
          ) : null
        )}
        {labelSchema.map((value, index) => (
          <CustomModelEditorFrozenField
            name={getLabelSchemaDisplayName(value)}
            type={value.type}
            format={value.config?.format}
            key={`frozen-field-${index}`}
          />
        ))}

        {!canEdit &&
          labelSchema.filter(
            x => !CustomModelLabelSchemaTypeDefintion[x.type].hidden
          ).length +
            unfrozenLabelSchema.filter(x => x.name.trim()).length ===
            0 && (
            <div className={styles["desc"]}>
              <FormattedMessage id="custom_model_editor.right_bar.label.empty" />
            </div>
          )}
      </div>
    </>
  );
});

interface Props extends FieldsPaneProps {
  initialTrainingRequested: boolean;
  isCustomModelTrainingOrLabellingEnabled: boolean;
  canNext: boolean;
  onNext: () => void;
  canRequestTraining: boolean;
  onRequestTraining: () => void;
}

const CustomModelEditorRightBar = React.memo((props: Props) => {
  const {
    labelSchema,
    unfrozenLabelSchema,
    appendLabelField,
    updateLabelField,
    deleteLabelField,
    labelFieldErrors,
    canEdit,
    canRequestTraining,
    onRequestTraining,
    canNext,
    onNext,
    isCustomModelTrainingOrLabellingEnabled,
    initialTrainingRequested,
    preset,
  } = props;

  const { localized } = useLocale();
  const [isNextStepBubbleVisible, setIsNextStepBubbleVisible] =
    useState<boolean>(false);

  const onNextStepBubbleDismiss = useCallback((_e?: any) => {
    setIsNextStepBubbleVisible(false);
    setPreference(
      PreferenceKey.isCustomModelEditorRightBarNextStepBubbleDismissed,
      "true"
    );
  }, []);

  const onNextButtonClick = useCallback(() => {
    if (getIsNextStepBubbleDismissed()) {
      onNext();
    } else {
      setIsNextStepBubbleVisible(true);
    }
  }, [onNext]);

  return (
    <div className={styles["container"]}>
      <Pivot className={styles["content"]} linkFormat={"tabs"}>
        <PivotItem
          itemKey="fields"
          headerText={localized("custom_model_editor.right_bar.tab.fields")}
        >
          <FieldsPane
            preset={preset}
            labelSchema={labelSchema}
            unfrozenLabelSchema={unfrozenLabelSchema}
            appendLabelField={appendLabelField}
            updateLabelField={updateLabelField}
            deleteLabelField={deleteLabelField}
            labelFieldErrors={labelFieldErrors}
            canEdit={canEdit}
          />
        </PivotItem>
      </Pivot>
      {canEdit && (
        <div className={styles["bottom"]}>
          {isCustomModelTrainingOrLabellingEnabled ? (
            <PrimaryButton
              id={CustomModelEditorRightBarTargetIds.NextStepButton}
              className={styles["next-step-button"]}
              onClick={onNextButtonClick}
              disabled={!canNext}
            >
              <FormattedMessage id="custom_model_editor.right_bar.next_step" />
            </PrimaryButton>
          ) : (
            <PrimaryButton
              className={styles["next-step-button"]}
              onClick={onRequestTraining}
              disabled={!canRequestTraining}
            >
              <FormattedMessage
                id={
                  initialTrainingRequested
                    ? "custom_model_editor.right_bar.update_training_button"
                    : "custom_model_editor.right_bar.request_training_button"
                }
              />
            </PrimaryButton>
          )}
        </div>
      )}
      {isNextStepBubbleVisible && (
        <NextStepBubble
          targetId={CustomModelEditorRightBarTargetIds.NextStepButton}
          onDismiss={onNextStepBubbleDismiss}
          onProceed={onNext}
        />
      )}
    </div>
  );
});

export default CustomModelEditorRightBar;
