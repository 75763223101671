import * as React from "react";

import theme from "../../theme";
import styles from "./styles.module.scss";

const AnimationDot = React.memo(() => (
  <div style={{ backgroundColor: theme.palette.neutralPrimary }} />
));

function _Spinner() {
  return (
    <div className={styles["spinner"]}>
      <AnimationDot />
      <AnimationDot />
      <AnimationDot />
      <AnimationDot />
      <AnimationDot />
      <AnimationDot />
      <AnimationDot />
      <AnimationDot />
      <AnimationDot />
    </div>
  );
}

export const Spinner = React.memo(_Spinner);
export default Spinner;
