import {
  CheckboxVisibility,
  DetailsList,
  DetailsRow,
  DetailsRowFields,
  IColumn,
  IDetailsRowFieldsProps,
  IDetailsRowProps,
  IObjectWithKey,
  Icon,
  IconButton,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  TooltipHost,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import { DateTime } from "luxon";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { useLocale } from "../../contexts/locale";
import { Sort, SortOrder } from "../../types/api";
import {
  Extraction,
  ExtractionFilterableField,
  ExtractionResult,
  extractionFilterableFields,
} from "../../types/extraction";
import { getWorkerRequestError } from "../../utils/errors";
import { PreferenceKey, getPreference } from "../../utils/preference";
import styles from "./styles.module.scss";

export interface WorkspaceDocumentTableItem {
  id: string;
  workspaceId: string;
  extractionId: string;
  resultIndex: number;
  fileName: string;
  createdAt: Date;
  status: "extracting" | "failed" | "extracted";
  errorMessage: string | null;
  pageNumber?: number;
  sliceNumber?: number;
  isFirstResultInExtraction: boolean;
  extractionResult?: ExtractionResult;
}

interface WorkspaceDocumentTableItemWithKey
  extends WorkspaceDocumentTableItem,
    IObjectWithKey {}

const getKeyForWorkspaceDocumentTableItem = (
  item: WorkspaceDocumentTableItem
): string => {
  return `${item.workspaceId}/${item.extractionId}/${item.resultIndex}`;
};

const injectKeyForWorkspaceDocumentTableItem = (
  item: WorkspaceDocumentTableItem
): WorkspaceDocumentTableItemWithKey => {
  return {
    ...item,
    key: getKeyForWorkspaceDocumentTableItem(item),
  };
};

export const useWorkspaceDocumentTableItems = (
  extractions: Extraction[],
  processingExtractionResults: ExtractionResult[]
): WorkspaceDocumentTableItem[] => {
  const { localized } = useLocale();
  const processingExtractionIds: Set<string> = useMemo(() => {
    const set = new Set<string>();
    processingExtractionResults.forEach(res => {
      if (res.id != null) {
        set.add(res.id);
      }
    });
    return set;
  }, [processingExtractionResults]);
  return useMemo(() => {
    const items: WorkspaceDocumentTableItem[] = [];
    extractions.forEach(extraction => {
      const baseItem: WorkspaceDocumentTableItem = {
        id: extraction.id,
        workspaceId: extraction.workspaceId,
        extractionId: extraction.id,
        resultIndex: 1,
        isFirstResultInExtraction: true,
        fileName: extraction.fileName,
        createdAt: extraction.createdAt,
        status:
          extraction.erroredAt != null
            ? "failed"
            : extraction.processedAt != null
            ? "extracted"
            : "extracting",
        errorMessage:
          extraction.erroredAt != null
            ? localized(
                getWorkerRequestError(extraction.info.error).messageId,
                getWorkerRequestError(extraction.info.error).detail
              ) ?? null
            : null,
      };
      if (extraction.processedAt != null && extraction.erroredAt == null) {
        // Flatten result slices into rows
        extraction.results.forEach((result, index) => {
          items.push({
            ...baseItem,
            status:
              result.id != null && processingExtractionIds.has(result.id)
                ? "extracting"
                : result.status === "processed"
                ? "extracted"
                : "failed",
            errorMessage:
              result.info.error != null
                ? localized(
                    getWorkerRequestError(result.info.error).messageId,
                    getWorkerRequestError(result.info.error).detail
                  )
                : null,
            resultIndex: result.extractionIndex + 1,
            isFirstResultInExtraction: index === 0,
            pageNumber: result.info.pageNumber,
            sliceNumber: result.info.sliceNumber,
            extractionResult: result,
          });
        });
      } else {
        // No results yet, or errored, show 1 row only
        items.push(baseItem);
      }
    });
    return items;
  }, [extractions, localized, processingExtractionIds]);
};

interface DocumentItemIconButtonProps {
  iconName: string;
  item: WorkspaceDocumentTableItem;
  onClick: (item: WorkspaceDocumentTableItem) => void;
  disabled?: boolean;
  className?: string;
}
const DocumentItemIconButton = React.forwardRef<
  HTMLButtonElement,
  DocumentItemIconButtonProps
>((props: DocumentItemIconButtonProps, ref): ReactElement => {
  const { iconName, item, onClick, disabled = false, className } = props;
  const _onClick = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      onClick(item);
    },
    [item, onClick]
  );

  return (
    <IconButton
      elementRef={ref}
      className={cn(styles.actionButton, className)}
      iconProps={{
        iconName: iconName,
        styles: {
          root: {
            color: "#605E5C",
          },
        },
      }}
      onClick={_onClick}
      disabled={disabled}
    />
  );
});

interface RetryButtonProps {
  item: WorkspaceDocumentTableItem;
  onClick: (item: WorkspaceDocumentTableItem) => void;
  disabled?: boolean;
  className?: string;
}

const RetryButton = (props: RetryButtonProps): ReactElement => {
  const { item, onClick, disabled = false, className } = props;
  const _onClick = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      onClick(item);
    },
    [item, onClick]
  );

  return (
    <button
      className={cn(styles.retryButton, className)}
      onClick={_onClick}
      disabled={disabled}
    >
      <FormattedMessage id="workspace.document.table.retry" />
    </button>
  );
};

const useWorkspaceDocumentTableColumns = ({
  onExportSingle,
  isPreparingExport,
  onClickDelete,
  sort,
  onColumnSort = () => {},
  onClickRetry,
}: {
  onExportSingle: (item: WorkspaceDocumentTableItem) => void;
  isPreparingExport: boolean;
  onClickDelete: (item: WorkspaceDocumentTableItem) => void;
  sort: Sort<ExtractionFilterableField>;
  onColumnSort?: (args: {
    field: ExtractionFilterableField;
    order: SortOrder;
  }) => void;
  onClickRetry: (item: WorkspaceDocumentTableItem) => void;
}) => {
  const { localized } = useLocale();

  const btnRef = useRef<HTMLButtonElement>(null);

  const columns = useMemo(
    () => [
      {
        key: "resultIndex",
        fieldName: "resultIndex",
        name: localized("workspace.document.table.columns.index"),
        minWidth: 80,
        maxWidth: 80,
        onRender: (item: WorkspaceDocumentTableItem) => {
          return item.status === "extracting" ? (
            <Spinner
              styles={{ root: { width: 18, height: 18 } }}
              size={SpinnerSize.small}
            />
          ) : (
            <p>{item.resultIndex}</p>
          );
        },
      },
      {
        key: "fileName",
        fieldName: "fileName",
        name: localized("workspace.document.table.columns.file_name"),
        minWidth: 200,
        isSorted: sort.field === "fileName",
        isSortedDescending: sort.field === "fileName" && sort.order === "desc",
        onRender: (item: WorkspaceDocumentTableItem) => {
          if (
            item.status === "extracting" ||
            item.extractionResult?.id == null
            // data is not in result data model, should not happen after migration
          ) {
            return (
              <span className={styles.fileNameDisabled}>{item.fileName}</span>
            );
          }
          return (
            <Link
              to={`/workspace/${item.workspaceId}/result/${item.extractionResult?.id}`}
              className={styles.fileNameLink}
            >
              {item.fileName}
            </Link>
          );
        },
      },
      {
        key: "status",
        fieldName: "status",
        name: localized("workspace.document.table.columns.status"),
        minWidth: 200,
        maxWidth: 200,
        onRender: (item: WorkspaceDocumentTableItem) => {
          return (
            <div className={styles.statusCell}>
              <TooltipHost content={item.errorMessage ?? undefined}>
                <div
                  className={cn(styles.status, {
                    [styles.statusExtracting]: item.status === "extracting",
                    [styles.statusExtracted]: item.status === "extracted",
                    [styles.statusFailed]: item.status === "failed",
                  })}
                >
                  {item.status === "failed" ? <Icon iconName="Info" /> : null}
                  <span>
                    <FormattedMessage
                      id={`workspace.document.table.columns.status.${item.status}`}
                    />
                  </span>
                </div>
              </TooltipHost>
              {item.extractionResult != null &&
              item.extractionResult.id != null &&
              (item.status === "failed" ||
                (item.status === "extracted" &&
                  getPreference(PreferenceKey.allowReExtractSuccessResult) ===
                    "true")) ? (
                <RetryButton item={item} onClick={onClickRetry} />
              ) : null}
            </div>
          );
        },
      },
      {
        key: "createdAt",
        name: localized("workspace.document.table.columns.uploaded_at"),
        minWidth: 200,
        maxWidth: 200,
        isSorted: sort.field === "createdAt",
        isSortedDescending: sort.field === "createdAt" && sort.order === "desc",
        onRender: (item: WorkspaceDocumentTableItem) => {
          return (
            <p
              style={{
                color: item.status === "extracting" ? "#a19f9d" : "inherit",
              }}
            >
              {DateTime.fromJSDate(item.createdAt).toFormat("dd/MM/yyyy")}
            </p>
          );
        },
      },

      {
        key: "actions",
        name: "",
        minWidth: 64,
        maxWidth: 64,
        onRender: (item: WorkspaceDocumentTableItem) => {
          return (
            <div className={styles.documentItemActionButtonGroup}>
              {item.status !== "extracting" && (
                <DocumentItemIconButton
                  iconName="Download"
                  item={item}
                  onClick={onExportSingle}
                  disabled={isPreparingExport}
                  className={styles.documentItemActionButtonDownload}
                />
              )}
              <DocumentItemIconButton
                ref={btnRef}
                iconName="Delete"
                item={item}
                onClick={item => {
                  // Hot fix for delete button gets focused after modal dismiss
                  btnRef.current?.focus({
                    preventScroll: true,
                  });
                  btnRef.current?.blur();
                  onClickDelete(item);
                }}
                className={styles.documentItemActionButtonDelete}
              />
            </div>
          );
        },
      },
    ],
    [
      isPreparingExport,
      localized,
      onClickDelete,
      onClickRetry,
      onExportSingle,
      sort.field,
      sort.order,
    ]
  );

  const onColumnClick = useCallback(
    (
      _ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
      column?: IColumn
    ) => {
      if (column == null || !extractionFilterableFields.includes(column.key)) {
        return;
      }

      onColumnSort({
        field: column.key as ExtractionFilterableField,
        order: column.isSortedDescending ? "asc" : "desc",
      });
    },

    [onColumnSort]
  );

  return useMemo(
    () => ({
      columns,
      onColumnClick,
    }),
    [columns, onColumnClick]
  );
};

function useWorkspaceDocumentTableRow({ itemCount }: { itemCount: number }) {
  const renderRowFields = useCallback((props: IDetailsRowFieldsProps) => {
    // Avoid select row on click row area
    // https://stackoverflow.com/a/53896515
    return (
      <div data-selection-disabled={true}>
        <DetailsRowFields {...props} />
      </div>
    );
  }, []);
  const onRenderRow = useCallback(
    (props?: IDetailsRowProps) => {
      if (!props) return null;

      const hasBottomBorder = props?.itemIndex === itemCount - 1;
      const hasTopBorder =
        (props?.item as WorkspaceDocumentTableItem).isFirstResultInExtraction &&
        props?.itemIndex > 0;

      return (
        <DetailsRow
          {...props}
          rowFieldsAs={renderRowFields}
          className={cn({
            detailsRowBottomBorder: hasBottomBorder,
            detailsRowTopBorder: hasTopBorder,
          })}
          onRenderCheck={
            props.item.status === "extracting"
              ? () => <div className={styles.disabledSelectionCheckbox}></div>
              : undefined
          }
        />
      );
    },
    [itemCount, renderRowFields]
  );

  return useMemo(
    () => ({
      onRenderRow,
    }),
    [onRenderRow]
  );
}

interface WorkspaceDocumentTableProps {
  className?: string;
  items: WorkspaceDocumentTableItem[];
  onSelection: (selectedItems: WorkspaceDocumentTableItem[]) => void;
  onExportSingle: (item: WorkspaceDocumentTableItem) => void;
  isPreparingExport: boolean;
  onDelete: (item: WorkspaceDocumentTableItem) => void;
  sort: Sort<ExtractionFilterableField>;
  onColumnSort: (args: {
    field: ExtractionFilterableField;
    order: SortOrder;
  }) => void;
  disableSelection: boolean;
  onReExtractExtraction: (extractionResult: ExtractionResult) => Promise<void>;
}

const WorkspaceDocumentTable = (
  props: WorkspaceDocumentTableProps
): ReactElement => {
  const {
    className,
    items: _items,
    onSelection,
    onExportSingle,
    isPreparingExport,
    onDelete,
    sort,
    onColumnSort,
    disableSelection,
    onReExtractExtraction,
  } = props;

  const items = useMemo(() => {
    return _items.map(injectKeyForWorkspaceDocumentTableItem);
  }, [_items]);

  const onClickRetry = useCallback(
    (item: WorkspaceDocumentTableItem) => {
      if (item.extractionResult == null || item.extractionResult.id == null) {
        return;
      }
      onReExtractExtraction(item.extractionResult);
    },
    [onReExtractExtraction]
  );

  const { columns, onColumnClick } = useWorkspaceDocumentTableColumns({
    onExportSingle: onExportSingle,
    isPreparingExport,
    onClickDelete: onDelete,
    sort,
    onColumnSort,
    onClickRetry,
  });

  const [selectedItems, setSelectedItems] = useState<
    WorkspaceDocumentTableItem[]
  >([]);

  useEffect(() => {
    onSelection(selectedItems);
  }, [onSelection, selectedItems]);

  const itemsRef = useRef(items);

  useEffect(() => {
    itemsRef.current = items;
    if (selection.current.isAllSelected()) {
      setSelectedItems(
        selection.current.getSelectedIndices().map(idx => itemsRef.current[idx])
      );
    }
  }, [items]);

  const selection = useRef(
    new Selection({
      onSelectionChanged: () => {
        setSelectedItems(
          selection.current
            .getSelectedIndices()
            .map(idx => itemsRef.current[idx])
            .filter(item => item)
        );
      },
    })
  );

  const { onRenderRow } = useWorkspaceDocumentTableRow({
    itemCount: items.length,
  });

  return (
    <DetailsList
      compact={true}
      className={cn(className, styles.detailsListOverride)}
      columns={columns}
      items={items}
      selection={selection.current}
      selectionMode={SelectionMode.multiple}
      checkboxVisibility={CheckboxVisibility.always}
      onColumnHeaderClick={onColumnClick}
      onRenderRow={onRenderRow}
      setKey="" // avoid selection reset on item change
      disableSelectionZone={disableSelection}
    />
  );
};

export default WorkspaceDocumentTable;
