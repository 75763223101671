/* tslint:disable: jsx-use-translation-function */
import { ScriptFunctionContent } from "../models";

const FuzzySearchContent: ScriptFunctionContent = {
  name: "fuzzy_search",
  parameters: [
    {
      name: "pattern",
      type: "string",
    },
    {
      name: "text",
      type: "string",
    },
    {
      name: "options",
      type: "FuzzySearchOptions",
      isOptional: true,
      fields: [
        {
          name: "max_subs",
          type: "number",
          isOptional: true,
        },
        {
          name: "max_insert",
          type: "number",
          isOptional: true,
        },
        {
          name: "max_delete",
          type: "number",
          isOptional: true,
        },
        {
          name: "max_l_dist",
          type: "number",
          isOptional: true,
        },
      ],
    },
  ],
  returnValue: {
    name: "matches",
    type: "Match[]",
    fields: [
      {
        name: "start",
        type: "number",
      },
      {
        name: "end",
        type: "number",
      },
      {
        name: "dist",
        type: "number",
      },
      {
        name: "matched",
        type: "string",
      },
    ],
  },
};

export default FuzzySearchContent;
