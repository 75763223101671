import {
  Dialog,
  DialogType,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import React, { useCallback, useMemo, useState } from "react";
import { UAParser } from "ua-parser-js";

import { SUPPORTED_BROWSER_COMBINATIONS } from "../../constants";
import { useLocale } from "../../contexts/locale";
import {
  PreferenceKey,
  getPreference,
  setPreference,
} from "../../utils/preference";
import styles from "./styles.module.scss";

const parser = new UAParser();

const UnsupportedBrowserDialog = React.memo(() => {
  const { name, version } = parser.getBrowser();

  const isNotDesktop = parser.getDevice().type !== undefined;
  const isNotSupportedBrowser =
    SUPPORTED_BROWSER_COMBINATIONS.filter(
      x =>
        x.browser === name &&
        version &&
        parseInt(x.version.split(".")[0]) <= parseInt(version)
    ).length === 0 || isNotDesktop;

  const [isVisible, setIsVisisble] = useState<boolean>(
    isNotSupportedBrowser &&
      getPreference(PreferenceKey.isUnsupportedBrowserDialogDismissed) !==
        "true"
  );

  const onDialogDismissed = useCallback(() => {
    setIsVisisble(false);
    setPreference(PreferenceKey.isUnsupportedBrowserDialogDismissed, "true");
  }, []);

  const { localized } = useLocale();

  const modalProps: IModalProps = useMemo(
    () => ({
      isBlocking: false,
      className: styles["form-non-saved-prompt-dialog"],
    }),
    []
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("unsupport_browser_dialog.title"),
      subText: localized("unsupport_browser_dialog.message"),
    }),
    [localized]
  );

  return (
    <Dialog
      hidden={!isVisible}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <div className={styles["button-actions"]}>
        <PrimaryButton
          onClick={onDialogDismissed}
          text={localized("unsupport_browser_dialog.dismiss")}
        />
      </div>
    </Dialog>
  );
});

export default UnsupportedBrowserDialog;
