import * as React from "react";

import { useCustomModelActionCreator } from "../actions/customModel";
import { FSL_CUSTOM_MODEL_IMAGE_MAX_COUNT } from "../constants";
import { CustomModel } from "../types/customModel";
import { useAppSelector } from "./redux";

export function useFetchFSLCustomModelImages(
  customModel: CustomModel | undefined
) {
  const { listFSLCustomModelImages } = useCustomModelActionCreator();

  const customModelImages = useAppSelector(
    state => state.customModel.paginatedFSLCustomModelImages?.images
  );

  const reloadCustomModelImages = React.useCallback(() => {
    if (customModel !== undefined) {
      listFSLCustomModelImages(
        customModel.id,
        FSL_CUSTOM_MODEL_IMAGE_MAX_COUNT,
        0,
        undefined,
        ["fsl_output", "group_id"]
      );
    }
  }, [customModel, listFSLCustomModelImages]);

  React.useEffect(() => {
    reloadCustomModelImages();
  }, [reloadCustomModelImages]);

  return React.useMemo(
    () => ({
      customModelImages,
      reloadCustomModelImages,
    }),
    [customModelImages, reloadCustomModelImages]
  );
}
