import React, { useCallback } from "react";
import { useRef } from "react";

import { FOCRError } from "../errors";

export type RemoteData<T> =
  | RemoteDataLoading
  | RemoteDataError
  | RemoteDataSuccess<T>;
export interface RemoteDataLoading {
  state: "loading";
}
export interface RemoteDataError {
  state: "error";
  error?: FOCRError;
}
export interface RemoteDataSuccess<T> {
  state: "success";
  value: T;
}

export function useRemoteData<T>(
  fetchFunction: () => Promise<T>,
  getCachedResult: () => null | T = () => null,
  autoFetch: boolean = true
) {
  const fetchFunctionRef = useRef(fetchFunction);
  fetchFunctionRef.current = fetchFunction;

  const getCachedResultRef = useRef(getCachedResult);
  getCachedResultRef.current = getCachedResult;

  const [remoteData, setRemoteData] = React.useState<RemoteData<T>>({
    state: "loading",
  });

  const fetch = useCallback(async () => {
    const cachedResult = getCachedResultRef.current();

    if (cachedResult !== null) {
      setRemoteData({ state: "success", value: cachedResult });
      return;
    }

    setRemoteData({ state: "loading" });

    try {
      const data = await fetchFunction();
      setRemoteData({ state: "success", value: data });
    } catch (e) {
      if (e instanceof FOCRError) {
        setRemoteData({ state: "error", error: e });
      } else {
        setRemoteData({ state: "error", error: undefined });
      }
    }
  }, [fetchFunction]);

  const setSuccessfulRemoteDataValue = useCallback(
    (value: T) => {
      setRemoteData({ state: "success", value: value });
    },
    [setRemoteData]
  );

  React.useEffect(() => {
    if (autoFetch) {
      fetch();
    }
  }, [fetch, autoFetch]);

  return {
    remoteData,
    setSuccessfulRemoteDataValue,
    reloadRemoteData: fetch,
  };
}
