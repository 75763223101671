import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";
import { useSelector } from "react-redux";

import { useTeamPermission } from "../../hooks/permission";
import { RootState } from "../../redux/types";
import {
  Permission,
  TeamMembersAndInvitations,
  getTeamRoleMessageId,
} from "../../types/team";
import ErrorPlaceholder from "../ErrorPlaceholder";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import {
  DisplayTeamUser,
  TeamPermissionControl,
} from "../TeamPermissionControl";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface TeamTableRowProps {
  user: DisplayTeamUser;
  hasSingleAdmin: boolean;
  setTeamUserPermission: (userId: string, permission: Permission) => void;
  removeTeamUser: (userId: string) => void;
  removeInvitation: (invitationId: string) => void;
  editable: boolean;
}

function TeamTableRow(props: TeamTableRowProps) {
  const {
    user,
    hasSingleAdmin,
    setTeamUserPermission,
    removeTeamUser,
    removeInvitation,
    editable,
  } = props;
  const userEmail = useSelector<RootState, string | undefined>(
    state => state.user.currentUser?.email
  );
  const isInvitation = user.userId === undefined;
  const isLastAdmin = hasSingleAdmin && user.permission.editMembership;

  return (
    <tr className={styles["team-table-row"]}>
      <td>
        {userEmail === user.email ? (
          <FormattedMessage
            id={"team.invitation.email.oneself"}
            values={{ email: user.email }}
          />
        ) : (
          user.email
        )}
      </td>
      <td>
        {isInvitation ? (
          <FormattedMessage id={"team.invitation.pending"} />
        ) : (
          <FormattedMessage id={"team.invitation.accepted"} />
        )}
      </td>
      <td>
        {(!isInvitation && isLastAdmin) || !editable ? (
          <FormattedMessage id={getTeamRoleMessageId(user.permission)} />
        ) : (
          <TeamPermissionControl
            user={user}
            setTeamUserPermission={setTeamUserPermission}
            removeTeamUser={removeTeamUser}
            removeInvitation={removeInvitation}
          />
        )}
      </td>
    </tr>
  );
}

interface TeamDetailMemberListProps {
  teamMembersData?: TeamMembersAndInvitations;
  setTeamUserPermission: (userId: string, permission: Permission) => void;
  removeTeamUser: (userId: string) => void;
  removeInvitation: (invitationId: string) => void;
  onInviteMember: () => void;
  hasError: boolean;
  isFetching: boolean;
  isAdminPage: boolean;
}

export default function TeamDetailMemberList(props: TeamDetailMemberListProps) {
  const {
    teamMembersData,
    setTeamUserPermission,
    removeTeamUser,
    removeInvitation,
    onInviteMember,
    isFetching,
    hasError,
    isAdminPage,
  } = props;

  const { hasPermissionToEditMembership } = useTeamPermission();
  const editable = hasPermissionToEditMembership || isAdminPage;

  const hasSingleAdmin = !!(
    teamMembersData &&
    teamMembersData.members.filter(user => user.permission.editMembership)
      .length === 1
  );

  const displayUsers = React.useMemo(() => {
    if (teamMembersData) {
      const displayUsers = teamMembersData.members.map(user => {
        return {
          email: user.email,
          userId: user.userId,
          permission: user.permission,
        };
      });

      const displayInvitations = teamMembersData.invitations.map(invitation => {
        return {
          email: invitation.email,
          invitationId: invitation.id,
          permission: invitation.permission,
        };
      });

      return [...displayUsers, ...displayInvitations];
    } else {
      return [];
    }
  }, [teamMembersData]);

  return (
    <div className={isAdminPage ? styles["team-members"] : undefined}>
      {editable && (
        <DefaultButton
          className={styles["invite-btn"]}
          iconProps={{ iconName: "CirclePlus" }}
          textId="team.members.invite"
          onClick={onInviteMember}
        />
      )}

      {isFetching && <ShortSpinner key={0} />}
      {!isFetching && !hasError && (
        <Table
          columnIds={["common.email", "team.column.status", "team.column.role"]}
          className={styles["team-table"]}
        >
          {displayUsers.map(user => (
            <TeamTableRow
              user={user}
              hasSingleAdmin={hasSingleAdmin}
              setTeamUserPermission={setTeamUserPermission}
              removeTeamUser={removeTeamUser}
              removeInvitation={removeInvitation}
              key={user.email}
              editable={editable}
            />
          ))}
        </Table>
      )}
      {hasError && (
        <ErrorPlaceholder messageId="common.fail_to_fetch_team_members" />
      )}
    </div>
  );
}
