@import "../../styles/variables.scss";

.modal {
  .upload-image-field {
    margin-top: 12px;
    .file-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .file-name-container {
        position: relative;
        display: inline-block;
        border-bottom: 1px solid $text-field-border-color;
        height: 30px;
        width: 320px;
        padding-bottom: 2px;

        > span {
          width: 320px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          position: absolute;
          bottom: 4px;
        }
      }
    }
  }
  .existing-image {
    border: 1px solid $border-color;
    background-size: contain;
    background-repeat: no-repeat;
    height: 200px;
    background-position: center center;
    margin: 30px 20px 20px;
  }
}

.invisible-image-upload-input {
  display: none;
}
