import { AppConfig } from "../../config";
import { authResponseSchema } from "../../types/auth";
import { AuthGearAPIClient, SettingOptions } from "../authGear";
import { ApiClientConstructor, _BaseApiClient } from "../base";
import { SimpleAPIClient } from "../simple";

export interface AuthApiClient {
  signUp: (username: string, email: string, password: string) => Promise<void>;
  login: (
    email: string,
    password: string,
    newPassword: string | undefined
  ) => Promise<void>;
  logout: () => Promise<void>;
  changePasword: (oldPassword: string, newPassword: string) => Promise<void>;
  openUserSetting: (options?: SettingOptions) => Promise<void>;
  triggerSignupWithInvitation: (invitationCode: string) => Promise<void>;
  triggerLoginWithInvitation: (invitationCode: string) => Promise<void>;
}

export function withAuthApi<TBase extends ApiClientConstructor<_BaseApiClient>>(
  Base: TBase
) {
  return class extends Base {
    async signUp(
      username: string,
      email: string,
      password: string
    ): Promise<void> {
      const { accessToken } = await this.lambda(
        "auth:signup",
        {
          username,
          email,
          password,
        },
        authResponseSchema,
        null
      );

      if (this instanceof SimpleAPIClient) {
        this.setAssetToken(accessToken);
      }
    }

    async login(
      email: string,
      password: string,
      newPassword: string | undefined
    ): Promise<void> {
      const { accessToken } = await this.lambda(
        "auth:login",
        {
          email,
          password,
          new_password: newPassword,
        },
        authResponseSchema,
        null
      );

      if (this instanceof SimpleAPIClient) {
        this.setAssetToken(accessToken);
      }
    }

    async logout(): Promise<void> {
      if (this instanceof SimpleAPIClient) {
        this.clearAccessToken();
      } else if (this instanceof AuthGearAPIClient) {
        await this.authgearLogout(true);
        window.location.href = "https://formx.ai";
      }
    }

    async triggerSignupWithInvitation(invitationCode: string): Promise<void> {
      if (this instanceof SimpleAPIClient) {
        this.clearAccessToken();
        window.location.href = "/signup?invitation-code=" + invitationCode;
      } else if (this instanceof AuthGearAPIClient) {
        const { redirectURI } = this.authGearConfig;

        const encodedState = await this.encodeState({
          invitationCode,
          region: AppConfig.region,
        });

        this.container.startAuthentication({
          redirectURI,
          state: encodedState,
          page: "signup",
        });
      }
    }

    async triggerLoginWithInvitation(invitationCode: string): Promise<void> {
      if (this instanceof SimpleAPIClient) {
        this.clearAccessToken();
        window.location.href = "/login?invitation-code=" + invitationCode;
      } else if (this instanceof AuthGearAPIClient) {
        const { redirectURI } = this.authGearConfig;

        const encodedState = await this.encodeState({
          invitationCode,
          region: AppConfig.region,
        });

        this.container.startAuthentication({
          redirectURI,
          state: encodedState,
          page: "login",
        });
      }
    }

    async changePasword(
      oldPassword: string,
      newPassword: string
    ): Promise<void> {
      await this.lambda("auth:change-password", {
        password: oldPassword,
        new_password: newPassword,
      });
    }

    async openUserSetting(options?: SettingOptions): Promise<void> {
      if (this instanceof AuthGearAPIClient) {
        return await this.authgearOpenUserSetting(options);
      } else {
        return Promise.resolve();
      }
    }
  };
}
