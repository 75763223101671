import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IChoiceGroupOption,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { ExtractorOptionForCombinedExtractor } from "../../types/extractor";
import {
  FormGroupTokenGroup,
  FormGroupTokenGroupMatchMode,
} from "../../types/formGroup";
import styles from "./styles.module.scss";

interface Props {
  extractorOptions: ExtractorOptionForCombinedExtractor[];
  tokenGroup: FormGroupTokenGroup | undefined;
  onCloseModal: () => void;
  onSave: (
    tokenGroupId: string | undefined,
    formId: string,
    matchMode: FormGroupTokenGroupMatchMode
  ) => void;
}

interface FormGroupTokenGroupMatchModeOption extends IChoiceGroupOption {
  key: FormGroupTokenGroupMatchMode;
}

const FormGroupTokenGroupModal = React.memo((props: Props) => {
  const { tokenGroup, onSave, onCloseModal, extractorOptions } = props;

  const isEdit = tokenGroup !== undefined;

  const { localized } = useLocale();

  const extractorDropdownOptions = React.useMemo((): IDropdownOption[] => {
    return extractorOptions
      .map(x => ({
        key: x.extractorId,
        text:
          x.resourceType === "form_group"
            ? localized("form_group_editor.combined_extractor", {
                name: x.name,
              })
            : x.name,
        disabled: x.resourceType === "form_group",
      }))
      .sort((a, b) =>
        !a.disabled && b.disabled ? -1 : a.disabled && !b.disabled ? 1 : 0
      );
  }, [extractorOptions, localized]);

  const matchModeOptions =
    React.useMemo((): FormGroupTokenGroupMatchModeOption[] => {
      return [
        {
          key: "all",
          text: localized("form_group_token_group_modal.match_mode.all"),
        },
        {
          key: "any",
          text: localized("form_group_token_group_modal.match_mode.any"),
        },
      ];
    }, [localized]);

  const [isFormIdInvalid, setIsFormIdInvalid] = React.useState<boolean>(false);
  const [formId, setFormId] = React.useState<string | undefined>(
    tokenGroup ? tokenGroup.formId : undefined
  );
  const [matchMode, setMatchMode] =
    React.useState<FormGroupTokenGroupMatchMode>(
      tokenGroup ? tokenGroup.matchMode : "all"
    );

  const onSaveClicked = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!formId) {
        setIsFormIdInvalid(true);
        return;
      }
      onSave(tokenGroup && tokenGroup.id, formId, matchMode);
      onCloseModal();
    },
    [onSave, formId, tokenGroup, onCloseModal, matchMode]
  );

  const onSelectedFormChange = React.useCallback(
    (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (!option) return;
      setFormId(option.key as string);
    },
    []
  );

  const onChangeMatchMode = React.useCallback(
    (
      _?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      if (!option) return;
      setMatchMode(option.key as FormGroupTokenGroupMatchMode);
    },
    []
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        isEdit
          ? "form_group_token_group_modal.title.edit"
          : "form_group_token_group_modal.title.add"
      ),
    }),
    [localized, isEdit]
  );

  const modalProps: IModalProps = React.useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  return (
    <Dialog
      hidden={false}
      onDismiss={onCloseModal}
      minWidth={400}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <form
        onSubmit={onSaveClicked}
        className={styles["form-group-token-group-modal-form"]}
      >
        <Dropdown
          selectedKey={formId}
          options={extractorDropdownOptions}
          onChange={onSelectedFormChange}
          label={localized(
            "form_group_token_group_modal.extractor_to_be_matched"
          )}
          placeholder={localized(
            "form_group_token_group_modal.select_an_extractor"
          )}
          errorMessage={
            isFormIdInvalid
              ? localized("form_group_token_group_modal.select_an_extractor")
              : undefined
          }
        />
        <ChoiceGroup
          label={localized("form_group_token_group_modal.match_mode")}
          className={styles["match-mode-choice-group"]}
          options={matchModeOptions}
          selectedKey={matchMode}
          onChange={onChangeMatchMode}
        />
        <DialogFooter>
          <DefaultButton
            onClick={onCloseModal}
            text={localized("common.cancel")}
          />
          <PrimaryButton type="submit" text={localized("common.save")} />
        </DialogFooter>
      </form>
    </Dialog>
  );
});

export { FormGroupTokenGroupModal };
