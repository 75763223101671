import { produce } from "immer";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";

export enum ExtractedContentSchemaType {
  SingleLineText = "single_line_text",
  MultiLineText = "multi_line_text",
  Number = "number",
  Percentage = "percentage",
  Date = "date",
  Time = "time",
  Currency = "currency",

  // The field group type must be the last item
  // It will affect the option rendering order
  FieldGroup = "field_group",
}

const extractedContentSchemaFieldSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  type: yup
    .string()
    .oneOf(Object.values(ExtractedContentSchemaType))
    .required(),
  format: yup.string().nullable().optional(),
  definition_id: yup.string().nullable().optional(),
  is_list: yup.boolean().required(),
  description: yup.string().nullable().optional(),
});

export type ExtractedContentSchemaFieldStorage = yup.InferType<
  typeof extractedContentSchemaFieldSchema
>;

export type ExtractedContentSchemaField = {
  id: string;
  name: string;
  type: `${ExtractedContentSchemaType}`;
  format?: string | undefined;
  definitionId?: string | undefined;
  isList: boolean;
  description?: string | undefined;
};

const extractedContentSchemaDefinitionSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  fields: yup.array().of(extractedContentSchemaFieldSchema).required(),
});

export type ExtractedContentSchemaDefinition = {
  id: string;
  name: string;
  fields: ExtractedContentSchemaField[];
};

export const extractedContentSchemaSchema = yup.object().shape({
  name: yup.string().required(),
  modifiedAt: yup.number().optional(),
  definitions: yup
    .array()
    .of(extractedContentSchemaDefinitionSchema)
    .required(),
  payload: yup.array().of(extractedContentSchemaFieldSchema).required(),
});

export const extractedContentSchemaStorageSchema = yup.object().shape({
  name: yup.string().required(),
  modified_at: yup.number().optional(),
  definitions: yup
    .array()
    .of(extractedContentSchemaDefinitionSchema)
    .required(),
  payload: yup.array().of(extractedContentSchemaFieldSchema).required(),
});

export type ExtractedContentSchemaStorage = yup.InferType<
  typeof extractedContentSchemaStorageSchema
>;

export type ExtractedContentSchema = {
  name: string;
  modifiedAt?: number;
  definitions: ExtractedContentSchemaDefinition[];
  payload: ExtractedContentSchemaField[];
};

export function normalizeExtractedContentSchemaFieldName(name: string) {
  return (
    name
      .trim()
      .replace(/ +/g, "_")
      .replace(/-+/g, "_")
      .replace(/_+/g, "_")
      .toLowerCase()
      .match(/[a-z][a-z0-9_]*/g)
      ?.join("") ?? ""
  );
}

export class ExtractedContentSchemaAccessor {
  data: ExtractedContentSchema;

  constructor(data: ExtractedContentSchema) {
    this.data = data;
  }

  appendPayload(id?: string) {
    const newField = {
      id: id ?? uuidv4(),
      name: "",
      type: ExtractedContentSchemaType.SingleLineText,
      isList: false,
      definitionId: undefined,
    };
    this.data = produce(this.data, draft => {
      if (!draft) {
        return {
          name: "",
          definitions: [],
          payload: [newField],
        };
      }
      draft.payload.push(newField);
      return draft;
    });
    return this;
  }

  changePayload(index: number, updates: Partial<ExtractedContentSchemaField>) {
    const payload = this.data.payload[index];
    if (!payload) {
      return this;
    }

    const name = updates.name ?? payload.name;
    const type = updates.type ?? payload.type;

    if (type === ExtractedContentSchemaType.FieldGroup) {
      const payloadItem = this.data.payload[index];
      const definitionId = payloadItem.definitionId ?? uuidv4();

      const definitionName = this.generateDefinitionName(name);
      const definition = this.getOrCreateDefinition(
        definitionId,
        this.generateDefinitionName(definitionName)
      );
      definition.name = definitionName;

      this.data = produce(this.data, draft => {
        draft.payload[index] = {
          ...payload,
          ...updates,
          isList: true,
          definitionId,
        };
        return draft;
      });
    } else {
      this.data = produce(this.data, draft => {
        draft.payload[index] = {
          ...payload,
          ...updates,
        };
        return draft;
      });
    }

    return this;
  }

  changePayloadName(index: number, name: string) {
    return this.changePayload(index, { name });
  }

  changePayloadType(index: number, type: ExtractedContentSchemaType) {
    return this.changePayload(index, { type });
  }

  generateDefinitionName(name: string) {
    const tokens = name
      .split(/[ _]+/)
      .filter(item => item.trim() !== "")
      .map(item => {
        const res = item.trim().toLowerCase();
        return res[0].toUpperCase() + res.slice(1);
      });

    const definitionName = `${tokens.join("")}Type`;

    return definitionName[0].toUpperCase() + definitionName.slice(1);
  }

  findDefinitionById(id: string) {
    return this.data.definitions.find(definition => definition.id === id);
  }

  getOrCreateDefinition(id: string, name: string) {
    const definition = this.findDefinitionById(id);
    if (definition) {
      return definition;
    }
    this.data = produce(this.data, draft => {
      const newDefinition = {
        id,
        name,
        fields: Array(3).fill({
          id: uuidv4(),
          name: "",
          type: ExtractedContentSchemaType.SingleLineText,
          isList: false,
        }),
      };
      draft.definitions.push(newDefinition);
      return draft;
    });

    return this.data.definitions[this.data.definitions.length - 1];
  }

  changePayloadSubField(
    payloadIndex: number,
    subFieldIndex: number,
    updates: Partial<ExtractedContentSchemaField>
  ) {
    try {
      const payload = this.data.payload[payloadIndex];

      if (payload?.type !== ExtractedContentSchemaType.FieldGroup) {
        return this;
      }

      const definitionId = payload.definitionId;
      if (!definitionId) {
        return this;
      }

      const definitionName = this.generateDefinitionName(payload.name);
      this.getOrCreateDefinition(definitionId, definitionName);

      const definitionIndex = this.data.definitions.findIndex(
        definition => definition.id === definitionId
      );

      this.data = produce(this.data, draft => {
        const definition = draft.definitions[definitionIndex];
        definition.fields[subFieldIndex] = {
          ...definition.fields[subFieldIndex],
          ...updates,
        };
        return draft;
      });
    } catch {}
    return this;
  }

  addPayloadSubField(payloadIndex: number) {
    const definitionId = this.data.payload[payloadIndex].definitionId;
    if (!definitionId) {
      return this;
    }

    const definitionIndex = this.data.definitions.findIndex(
      definition => definition.id === definitionId
    );

    this.data = produce(this.data, draft => {
      const definition = draft.definitions[definitionIndex];
      definition.fields.push({
        id: uuidv4(),
        name: "",
        type: ExtractedContentSchemaType.SingleLineText,
        isList: false,
      });
      return draft;
    });

    return this;
  }

  removePayloadSubField(payloadIndex: number, subFieldIndex: number) {
    const definitionId = this.data.payload[payloadIndex].definitionId;
    if (!definitionId) {
      return this;
    }

    const definitionIndex = this.data.definitions.findIndex(
      definition => definition.id === definitionId
    );

    this.data = produce(this.data, draft => {
      const definition = draft.definitions[definitionIndex];
      definition.fields.splice(subFieldIndex, 1);
      return draft;
    });

    return this;
  }

  movePayloadSubField(
    payloadIndex: number,
    fromIndex: number,
    toIndex: number
  ) {
    const definitionId = this.data.payload[payloadIndex].definitionId;
    if (!definitionId) {
      return this;
    }

    const definitionIndex = this.data.definitions.findIndex(
      definition => definition.id === definitionId
    );

    this.data = produce(this.data, draft => {
      const definition = draft.definitions[definitionIndex];
      const tmp = definition.fields[fromIndex];
      definition.fields.splice(fromIndex, 1);
      const target = toIndex > fromIndex ? toIndex - 1 : toIndex;
      definition.fields.splice(target, 0, tmp);
      return draft;
    });

    return this;
  }

  moveToLastPayloadSubField(payloadIndex: number, fromIndex: number) {
    const definitionId = this.data.payload[payloadIndex].definitionId;
    if (!definitionId) {
      return this;
    }

    const definitionIndex = this.data.definitions.findIndex(
      definition => definition.id === definitionId
    );

    this.data = produce(this.data, draft => {
      const definition = draft.definitions[definitionIndex];
      const tmp = definition.fields[fromIndex];
      definition.fields.splice(fromIndex, 1);
      definition.fields.push(tmp);
      return draft;
    });

    return this;
  }
  getPayloadSubFields(payloadIndex: number) {
    const payloadItem = this.data.payload[payloadIndex];
    if (
      !payloadItem ||
      payloadItem.type !== ExtractedContentSchemaType.FieldGroup
    ) {
      return [];
    }

    const definitionId = payloadItem.definitionId;
    if (!definitionId) {
      return [];
    }

    const definition = this.findDefinitionById(definitionId);
    return definition?.fields ?? [];
  }

  getPayloadSubFieldNormalizedNames(payloadIndex: number) {
    return this.getPayloadSubFields(payloadIndex).map(field =>
      normalizeExtractedContentSchemaFieldName(field.name)
    );
  }

  randomizeIds() {
    const idMapping = {} as Record<string, string>;

    this.data = produce(this.data, draft => {
      draft.definitions.forEach(definition => {
        definition.fields.forEach(field => {
          field.id = uuidv4();
        });

        if (!(definition.id in idMapping)) {
          idMapping[definition.id] = uuidv4();
        }
        definition.id = idMapping[definition.id];
      });
      draft.payload.forEach(field => {
        field.id = uuidv4();
        if (
          field.definitionId !== undefined &&
          field.definitionId in idMapping
        ) {
          field.definitionId = idMapping[field.definitionId];
        }
      });
      return draft;
    });

    return this;
  }

  updateTimestamp() {
    this.data = produce(this.data, draft => {
      draft.modifiedAt = Date.now() / 1000;
      return draft;
    });
    return this;
  }
}

export function accessExtractedContentSchema(data: ExtractedContentSchema) {
  return new ExtractedContentSchemaAccessor(data);
}
