import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { useRequestLogActionCreator } from "../actions/requestLog";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import UsageView from "../components/Usage";
import UsageDateRangeSelector from "../components/UsageDateRangeSelector";
import { AppConfig } from "../config";
import HeaderContainer from "../containers/Header";
import { useLocale } from "../contexts/locale";
import { useToast } from "../hooks/toast";
import { useWorkerTokenDropdown } from "../hooks/usage";
import { RootState } from "../redux/types";
import { Template } from "../types/template";
import { Usage } from "../types/usage";

function useDateRangeSelector() {
  const [dateFrom, setDateFrom] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  });
  const [dateTo, setDateTo] = useState(new Date());

  const dateFromRef = useRef(dateFrom);
  dateFromRef.current = dateFrom;

  const dateToRef = useRef(dateTo);
  dateToRef.current = dateTo;

  const onDateRangeChange = useCallback((dateFrom: Date, dateTo: Date) => {
    setDateFrom(dateFrom);
    setDateTo(dateTo);
  }, []);

  return useMemo(
    () => ({
      dateFromRef,
      dateToRef,
      onDateRangeChange,
    }),
    [dateFromRef, dateToRef, onDateRangeChange]
  );
}

function _UsageContainer() {
  const { localized } = useLocale();
  const toast = useToast();
  const templates = useSelector<RootState, Template[]>(
    state => state.form.templates || []
  );
  const templatesRef = useRef(templates);
  templatesRef.current = templates;

  const { dateFromRef, dateToRef, onDateRangeChange } = useDateRangeSelector();

  const [isLoading, setIsLoading] = useState(true);
  const [usages, setUsages] = useState<Usage[]>([]);

  const minDate = useMemo(
    () =>
      new Date(
        Date.now() -
          (AppConfig.usageDateRangeSelectorMinDate || 365) * 24 * 60 * 60 * 1000
      ),
    []
  );

  const { getUsage } = useRequestLogActionCreator();
  const resourceOwnerId = useSelector<RootState, string | undefined>(
    state => state.resourceOwner.resourceOwnerId
  );

  const getUsageEntityName = useCallback(
    (name: string, entityId: string) => {
      const templateFormTranslationMap = templatesRef.current.reduce<
        Record<string, string>
      >((map, template) => {
        Object.entries(template.templateMetadata.formTranslationIdMap).forEach(
          ([formId, formNameTranslationId]) => {
            map[formId] = formNameTranslationId;
          }
        );
        return map;
      }, {});

      if (templateFormTranslationMap[entityId]) {
        return localized(
          `form_template.${templateFormTranslationMap[entityId]}`
        );
      } else if (name === "DELETED") {
        return localized("usage.table.deleted_entity");
      }

      return name;
    },
    [localized]
  );

  const transformUsageEntityName = useCallback(
    (usages: Usage[]) => {
      return usages.map(usage => ({
        ...usage,
        counts: usage.counts.map(x => ({
          ...x,
          name: getUsageEntityName(x.name, x.entityId),
        })),
      }));
    },
    [getUsageEntityName]
  );

  const {
    onChange: onTokenDropdownChange,
    options: tokenDropdownOptions,
    selectedKeys: tokenDropdownSelectedKey,
    selectedTokenIds,
    isWorkerTokensLoaded,
  } = useWorkerTokenDropdown(resourceOwnerId);

  const loadUsage = useCallback(
    async (from: Date, to: Date) => {
      try {
        const usages = await getUsage(
          {
            start: new Date(from.setHours(0, 0, 0, 0)).toISOString(),
            end: new Date(to.setHours(23, 59, 59, 999)).toISOString(),
          },
          selectedTokenIds
        );
        setIsLoading(false);
        setUsages(transformUsageEntityName(usages));
      } catch {
        toast.error("error.usage.unknown_error");
        setIsLoading(false);
      }
    },
    [toast, transformUsageEntityName, getUsage, selectedTokenIds]
  );

  const searchDateRange = useCallback(() => {
    setIsLoading(true);
    loadUsage(dateFromRef.current, dateToRef.current);
  }, [dateFromRef, dateToRef, loadUsage]);

  useEffect(() => {
    if (isWorkerTokensLoaded) {
      searchDateRange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceOwnerId, isWorkerTokensLoaded]);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        {isLoading ? (
          <LoadingModal messageId="common.loading" isOpen={isLoading} />
        ) : (
          <div className="date-range-selector">
            <UsageDateRangeSelector
              showTitle={true}
              onDateRangeChange={onDateRangeChange}
              onDateRangeSearch={searchDateRange}
              dateFrom={dateFromRef.current}
              dateTo={dateToRef.current}
              minDate={minDate}
              onTokenDropdownChange={onTokenDropdownChange}
              tokenDropdownOptions={tokenDropdownOptions}
              tokenDropdownSelectedKey={tokenDropdownSelectedKey}
            />
            <UsageView usages={usages} />
          </div>
        )}
      </Main>
    </Layout>
  );
}

export const UsageContainer = React.memo(_UsageContainer);
export default UsageContainer;
