import { AppConfig } from "../config";
import {
  GtmServiceImpl,
  NullGtmService,
  initializeGtmServiceInstance,
} from "../services/gtmService";

const GTM_SCRIPT =
  "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-TAG');";

export function initalizeGTM() {
  const gtm = AppConfig.gtm;

  const id = gtm?.id;
  if (id === undefined) {
    initializeGtmServiceInstance(new NullGtmService());
    return;
  }

  const scriptElem = document.createElement("script");
  const script = GTM_SCRIPT.replace("GTM-TAG", id);
  scriptElem.innerHTML = script;

  document.head.insertBefore(scriptElem, document.head.childNodes[0]);

  initializeGtmServiceInstance(new GtmServiceImpl());
}
