@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";
@import "../../components/Layout/styles.module.scss";

.sticky-button-box {
  position: fixed;
  bottom: 0;
  text-align: right;
  width: calc(100vw - #{$left-bar-full-width});
  background-color: rgba(200, 200, 200, 0.4);
  height: 60px;
  line-height: 60px;
  padding-right: 24px;
  z-index: 101;
}

.collapsed {
  width: calc(100vw - #{$left-bar-collapsed-width});
}
