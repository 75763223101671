import * as yup from "yup";

type BuiltinTokenType =
  | "brand_name"
  | "phone_number"
  | "fax_number"
  | "url"
  | "email"
  | "token";
export type CustomTokenType =
  | BuiltinTokenType
  | "negative_token"
  | "positive_token";

export function isBuiltinFieldType(type: string): type is BuiltinTokenType {
  return (
    type === "brand_name" ||
    type === "phone_number" ||
    type === "fax_number" ||
    type === "url"
  );
}

const TagsSchema = yup.object({
  merchant: yup.array().of(yup.string().required()),
  mall: yup.array().of(yup.string().required()),
});

const TagIdSchema = yup.object({
  merchant_id: yup.string(),
  mall_id: yup.string(),
});

const InfoFieldSchema = yup.object({
  type: yup.string(),
  pattern: yup.string(),
  is_exact_match_only: yup.boolean(),
  is_negative_match: yup.boolean().optional(),
  is_regex: yup.boolean().optional(),
  is_ignore_white_space: yup.boolean().optional(),
});

const MatchingRuleSchema = yup.object({
  tags: TagIdSchema,
  match_mode: yup.string().oneOf(["best", "all"]).optional(),
  infos: yup.array().of(InfoFieldSchema.required()),
});

const Token = yup.object({
  value: yup.string().required(),
  is_exact_match_only: yup.boolean().required(),
  is_ignore_white_space: yup.boolean().required(),
});

const FallbackTokenGroupSchema = yup.object({
  tag: yup.string().required(),
  tokens: yup.array().of(Token.required()).required(),
});

const FallbackTokenGroupsMapSchema = yup.object({
  merchant_id: yup.array().of(FallbackTokenGroupSchema.required()),
  mall_id: yup.array().of(FallbackTokenGroupSchema.required()),
});

const FieldReplacementSchema = yup.object({
  tags: TagIdSchema.required(),
  type: yup
    .string()
    .oneOf(["key_value", "custom_model", "change_date_input", "llm"])
    .required(),
  target: yup
    .string()
    .oneOf(["invoice_number", "total_amount", "date"])
    .required(),
  config: yup.mixed().required(),
});

const LLMSettingSchema = yup.object({
  llm_parameters: yup.mixed().optional(),
  llm_provider: yup.string().optional(),
});

const MerchantPatternMatchingSchema = yup.object({
  tags: TagsSchema.optional(),
  matching_rules: yup.array().of(MatchingRuleSchema.required()),
  fallback_token_groups: FallbackTokenGroupsMapSchema.optional(),
  field_replacements: yup
    .array()
    .of(FieldReplacementSchema.required())
    .optional(),
  llm_settings: yup
    .mixed((_input): _input is { [key: string]: LLMSetting } => true)
    .optional(),
  field_replacement_selector_axi_source: yup
    .array()
    .of(yup.string().required())
    .optional(),
});

const AdvancedPatternMatchingSchema = yup.object({
  merchant: MerchantPatternMatchingSchema.optional(),
});

export type MerchantPatternMatching = yup.InferType<
  typeof MerchantPatternMatchingSchema
>;

export type AdvancedPatternMatching = yup.InferType<
  typeof AdvancedPatternMatchingSchema
>;
export type MatchingRule = yup.InferType<typeof MatchingRuleSchema>;
export type InfoField = yup.InferType<typeof InfoFieldSchema>;
export type FieldReplacement = yup.InferType<typeof FieldReplacementSchema>;
export type FallbackTokenGroup = yup.InferType<typeof FallbackTokenGroupSchema>;
export type LLMSetting = yup.InferType<typeof LLMSettingSchema>;
export type MerchantPatternMatchingAuxData = Pick<
  MerchantPatternMatching,
  "llm_settings" | "field_replacement_selector_axi_source"
>;
