import * as React from "react";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { FSLStandardModelEditor } from "../components/FSLStandardModelEditor";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import {
  FSLCustomModelEditorProvider,
  useFSLCustomModelEditor,
} from "../contexts/fslCustomModelEditor";
import {
  FSLStandardModelEditorProvider,
  useFSLStandardModelEditorContainer,
} from "../contexts/fslStandardModelEditor";
import { useUnsafeParams } from "../hooks/params";
import { FSLNavBarLayout, FSLTab } from "./FSLNavBarLayout";
import HeaderContainer from "./Header";

type PathParam = {
  customModelId: string;
};

function FSLStandardModelEditorLayout() {
  const { isFSLModel, isFailedToFetchCustomModel, isProcessing } =
    useFSLCustomModelEditor();
  const { isLoading } = useFSLStandardModelEditorContainer();

  const shouldShowFSLCustomModel = isFSLModel === true && !isLoading;
  const shouldShowOriginalEditor = isFSLModel === false;

  return (
    <>
      {(isFailedToFetchCustomModel || shouldShowOriginalEditor) && (
        <Main hasTop={true}>
          <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
        </Main>
      )}
      {!shouldShowOriginalEditor && (
        <LoadingModal isOpen={isLoading || isProcessing} />
      )}
      {shouldShowFSLCustomModel && (
        <Main hasTop={true}>
          <FSLNavBarLayout selectedTab={FSLTab.StandardModel}>
            <FSLStandardModelEditor />
          </FSLNavBarLayout>
        </Main>
      )}
    </>
  );
}

function _FSLStandardModelEditorContainer() {
  const { customModelId } = useUnsafeParams<PathParam>();

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <FSLCustomModelEditorProvider
        customModelId={customModelId}
        key={customModelId}
      >
        <FSLStandardModelEditorProvider>
          <FSLStandardModelEditorLayout />
        </FSLStandardModelEditorProvider>
      </FSLCustomModelEditorProvider>
    </Layout>
  );
}

export const FSLStandardModelEditorContainer = React.memo(
  _FSLStandardModelEditorContainer
);
export default FSLStandardModelEditorContainer;
