import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { ApiError, ApiErrorMap } from "../errors";
import { useGtm } from "../hooks/gtm";
import { useAppDispatch } from "../hooks/redux";
import { useMasterImageSizeValidator } from "../hooks/validators";
import {
  Dimension,
  ExtractAPIV2Response,
  OCRTestReportMultipleDocument,
  OCRTestReportSingleDocument,
  UpdateImagePayload,
} from "../models";
import { RootState } from "../redux/types";
import {
  AdvancedPatternMatching,
  MerchantPatternMatching,
} from "../types/advancedPatternMatching";
import { Anchor } from "../types/anchor";
import { LambdaSuccess } from "../types/api";
import { DetectionRegion } from "../types/detectionRegion";
import { ExtractorSettings } from "../types/extractorSettings";
import { Field } from "../types/field";
import {
  BriefForm,
  DetailedForm,
  Form,
  FormExtractionMode,
} from "../types/form";
import { FormSettings } from "../types/formConfig";
import { KeyValue } from "../types/keyValue";
import { BriefFormMapper } from "../types/mappers/form";
import {
  MerchantExtractionFallbackStrategry,
  MerchantSetting,
} from "../types/merchantSetting";
import { TokenGroup } from "../types/tokenGroup";
import { deepClone } from "../utils/deepClone";
import { triggerFileSave, uploadAsset } from "../utils/file";
import { isDetectorSettingChanged } from "../utils/formConfig";
import { getImageSizeFromFile, rotateAndCompressImage } from "../utils/image";
import { ExtractV2Options, workerClient } from "../workerClient";
import { useConfirmModalActionCreator } from "./confirmModal";
import { useResourceOwnerActionCreator } from "./resourceOwner";

interface OCRTestResponseSingleDocument
  extends LambdaSuccess,
    OCRTestReportSingleDocument {}

interface OCRTestResponseMultipleDocument
  extends LambdaSuccess,
    OCRTestReportMultipleDocument {}

type OCRTestResponse =
  | OCRTestResponseSingleDocument
  | OCRTestResponseMultipleDocument;

export const FormCreated = createAction<DetailedForm>("form/formCreated");

export const GotForm = createAction<DetailedForm>("form/gotForm");

export const DiscardForm = createAction("form/discardForm");

export const FormRemoved = createAction(
  "form/formRemoved",
  (formId: string) => ({ payload: { formId } })
);

export const FormPinned = createAction("form/pinnedForm", (formId: string) => ({
  payload: { formId, value: true },
}));

export const FormUnpinned = createAction(
  "form/unpinnedForm",
  (formId: string) => ({
    payload: { formId, value: false },
  })
);

export const GotFormImageSize = createAction<Dimension>(
  "form/gotFormImageSize"
);

export const AnchorAdded = createAction<Anchor>("form/anchorAdded");

export const AnchorUpdated = createAction<Anchor>("form/anchorUpdated");

export const AnchorDeleted = createAction(
  "form/anchorDeleted",
  (anchorId: string) => ({ payload: { anchorId } })
);

export const FieldAdded = createAction<Field>("form/fieldAdded");

export const FieldUpdated = createAction<Field>("form/fieldUpdated");

export const FieldDeleted = createAction(
  "form/fieldDeleted",
  (fieldId: string) => ({ payload: { fieldId } })
);

export const DetectionRegionAdded = createAction<DetectionRegion>(
  "form/detectionRegionAdded"
);

export const DetectionRegionUpdated = createAction<DetectionRegion>(
  "form/detectionRegionUpdated"
);

export const DetectionRegionDeleted = createAction(
  "form/detectionRegionDeleted",
  (detectionRegionId: string) => ({ payload: { detectionRegionId } })
);

export const FormUpdated = createAction(
  "form/formUpdated",
  (payload: {
    formId: string;
    imagePayload?: UpdateImagePayload;
    settings?: FormSettings;
    keyValues?: KeyValue[];
    tokenGroups?: TokenGroup[];
    customMerchants?: MerchantSetting[];
    cutomMerchantsFallback?: MerchantExtractionFallbackStrategry;
    customModelIds?: string[];
    advancedPatternMatching?: AdvancedPatternMatching;
    extractorSettings?: ExtractorSettings;
    updateMerchantAutoExtractionItem?: boolean;
  }) => ({ payload })
);

export const FormRenamed = createAction(
  "form/renamed",
  (formId: string, name: string, updatedAt: string) => ({
    payload: { formId, name, updatedAt },
  })
);
export const FormSaved = createAction<Form>("form/formSaved");

export const FormsInvalidated = createAction("form/formsInvalidated");

export type FormAction =
  | ReturnType<typeof GotForm>
  | ReturnType<typeof DiscardForm>
  | ReturnType<typeof FormCreated>
  | ReturnType<typeof FormRemoved>
  | ReturnType<typeof GotFormImageSize>
  | ReturnType<typeof AnchorAdded>
  | ReturnType<typeof AnchorUpdated>
  | ReturnType<typeof AnchorDeleted>
  | ReturnType<typeof FieldAdded>
  | ReturnType<typeof FieldUpdated>
  | ReturnType<typeof FieldDeleted>
  | ReturnType<typeof DetectionRegionAdded>
  | ReturnType<typeof DetectionRegionUpdated>
  | ReturnType<typeof DetectionRegionDeleted>
  | ReturnType<typeof FormUpdated>
  | ReturnType<typeof FormSaved>
  | ReturnType<typeof FormsInvalidated>;

export function useFormActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();

  const { selectTeam } = useResourceOwnerActionCreator();

  const getForm = useCallback(
    async (formId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      const form = await apiClient.getForm(formId, resourceOwnerId);

      if (form.resourceOwnerId) {
        selectTeam(form.resourceOwnerId);
      }

      dispatch(GotForm(form));
    },
    [dispatch, getState, selectTeam]
  );

  const { pushCreatedExtractorEvent } = useGtm();

  const createForm = useCallback(
    async (formName: string, image?: File): Promise<BriefForm> => {
      const { resourceOwnerId } = getState().resourceOwner;

      const options = image
        ? {
            masterImageInfo: {
              size: await getImageSizeFromFile(image),
              imageId: (await apiClient.createAsset(image)).name,
            },
          }
        : undefined;

      const formId = (
        await apiClient.createForm(formName, options, resourceOwnerId)
      ).id;

      pushCreatedExtractorEvent(formId, formName, "zonal", undefined);

      const detailedForm = await apiClient.getForm(formId, resourceOwnerId);

      dispatch(FormCreated(detailedForm));

      const currentForm = getState().form.currentForm;
      if (!currentForm) {
        throw "impossible";
      }

      return BriefFormMapper.fromDetailedForm(currentForm);
    },
    [dispatch, getState, pushCreatedExtractorEvent]
  );

  const removeForm = useCallback(
    async (formId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.deleteForm(formId, resourceOwnerId);

      dispatch(FormRemoved(formId));
    },
    [dispatch, getState]
  );

  const pinForm = useCallback(
    async (formId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.pinForm(formId, resourceOwnerId);

      dispatch(FormPinned(formId));
    },
    [dispatch, getState]
  );

  const unpinForm = useCallback(
    async (formId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.unpinForm(formId, resourceOwnerId);

      dispatch(FormUnpinned(formId));
    },
    [dispatch, getState]
  );

  const importForm = useCallback(
    async (file: File, formId: string | null = null): Promise<any> => {
      const url = apiClient.getEffectiveEndpoint("/import-form");
      const { resourceOwnerId } = getState().resourceOwner;

      const formData = new FormData();
      formData.append("file", file);
      if (formId !== null) {
        formData.append("form_id", formId);
      }

      if (resourceOwnerId) {
        formData.append("resource_owner_id", resourceOwnerId);
      }

      const response = await apiClient.fetch(url, {
        method: "POST",
        body: formData,
      });

      const { result } = await response.json();

      if (!result || result.status !== "ok") {
        if (result?.error?.code === ApiError.ImportArchiveTypeMismatched) {
          throw ApiErrorMap[ApiError.ImportArchiveTypeMismatched];
        }
        throw result;
      }
      dispatch(FormsInvalidated());
      return result;
    },
    [dispatch, getState]
  );

  const getFormImageSize = useCallback(async () => {
    const currentForm = getState().form.currentForm;

    return new Promise<void>((resolve, reject) => {
      if (!currentForm || !currentForm.image) {
        resolve();
        return;
      }

      const image = new Image();
      image.onload = () => {
        dispatch(
          GotFormImageSize({ width: image.width, height: image.height })
        );
        resolve();
      };
      image.onerror = _ => {
        reject("Cannot loading master form image");
      };
      image.src = currentForm.image;
    });
  }, [dispatch, getState]);

  const discardForm = useCallback(() => {
    dispatch(DiscardForm());
  }, [dispatch]);

  const addAnchor = useCallback(
    (anchor: Anchor) => {
      dispatch(AnchorAdded(anchor));
    },
    [dispatch]
  );

  const updateAnchor = useCallback(
    (anchor: Anchor) => {
      dispatch(AnchorUpdated(anchor));
    },
    [dispatch]
  );

  const deleteAnchor = useCallback(
    (anchorId: string) => {
      dispatch(AnchorDeleted(anchorId));
    },
    [dispatch]
  );

  const addField = useCallback(
    (field: Field) => {
      dispatch(FieldAdded(field));
    },
    [dispatch]
  );

  const updateField = useCallback(
    (field: Field) => {
      dispatch(FieldUpdated(field));
    },
    [dispatch]
  );

  const deleteField = useCallback(
    (fieldId: string) => {
      dispatch(FieldDeleted(fieldId));
    },
    [dispatch]
  );

  const addDetectionRegion = useCallback(
    (detectionRegion: DetectionRegion) => {
      dispatch(DetectionRegionAdded(detectionRegion));
    },
    [dispatch]
  );

  const updateDetectionRegion = useCallback(
    (detectionRegion: DetectionRegion) => {
      dispatch(DetectionRegionUpdated(detectionRegion));
    },
    [dispatch]
  );

  const deleteDetectionRegion = useCallback(
    (detectionRegionId: string) => {
      dispatch(DetectionRegionDeleted(detectionRegionId));
    },
    [dispatch]
  );

  const updateForm = useCallback(
    (settings: FormSettings) => {
      const { currentForm } = getState().form;
      if (!currentForm) return;

      dispatch(
        FormUpdated({
          formId: currentForm.id,
          settings: settings,
        })
      );
    },
    [dispatch, getState]
  );

  const updateFormKeyValues = useCallback(
    (keyValues: KeyValue[]) => {
      const { currentForm } = getState().form;
      if (!currentForm) return;

      dispatch(
        FormUpdated({
          formId: currentForm.id,
          keyValues,
        })
      );
    },
    [dispatch, getState]
  );

  const updateFormTokenGroups = useCallback(
    (tokenGroups: TokenGroup[]) => {
      const { currentForm } = getState().form;
      if (!currentForm) return;

      dispatch(
        FormUpdated({
          formId: currentForm.id,
          tokenGroups,
        })
      );
    },
    [dispatch, getState]
  );

  const updateCustomMerchants = useCallback(
    (customMerchants: MerchantSetting[]) => {
      const { currentForm } = getState().form;
      if (!currentForm) return;

      dispatch(
        FormUpdated({
          formId: currentForm.id,
          customMerchants,
        })
      );
    },
    [dispatch, getState]
  );

  const updateMerchantPatternMatching = useCallback(
    (merchantPatternMatching?: MerchantPatternMatching) => {
      const { currentForm } = getState().form;
      if (!currentForm) return;

      dispatch(
        FormUpdated({
          formId: currentForm.id,
          advancedPatternMatching: {
            ...currentForm.config.advanced_pattern_matching,
            merchant: merchantPatternMatching,
          },
        })
      );
    },
    [dispatch, getState]
  );

  const updateFormCustomModels = useCallback(
    (customModelIds: string[]) => {
      const { currentForm } = getState().form;
      if (!currentForm) return;

      dispatch(
        FormUpdated({
          formId: currentForm.id,
          customModelIds,
        })
      );
    },
    [dispatch, getState]
  );

  const saveForm = useCallback(
    async (
      shouldIgnoreConflict: boolean = false,
      formConfigSnapshotOption?: {
        snapshotName: string;
        snapshotNote?: string;
      }
    ) => {
      const state = getState();
      const {
        currentForm,
        originalForm,
        isAnyAnchorChanged,
        isAnyFieldOrDetectionRegionChanged,
        currentFormImageSize,
      } = state.form;

      const { resourceOwnerId } = state.resourceOwner;

      if (!currentForm || !originalForm) throw "No form is selected";

      const {
        id: formId,
        name: formName,
        config,
        tokenGroups,
        keyValues,
        imageId,
        customModelIds,
        updatedAt,
      } = currentForm;

      const shouldRecomputeFeatures =
        isAnyAnchorChanged ||
        isDetectorSettingChanged(currentForm.config, originalForm.config) ||
        (imageId !== undefined && imageId !== null);

      const updatedForm = await apiClient.updateForm(
        formId,
        {
          name: formName.trim(),
          config: deepClone(config),
          shouldRecomputeFeatures,
          tokenGroups,
          keyValues,
          customModelIds,
          lastRetrieved: updatedAt,
          data:
            isAnyAnchorChanged || isAnyFieldOrDetectionRegionChanged
              ? {
                  detectionRegions: currentForm.detectionRegions,
                  anchors: currentForm.anchors,
                  fields: currentForm.fields,
                }
              : undefined,
          shouldOverwrite: shouldIgnoreConflict,
          ...(currentFormImageSize !== undefined && imageId !== undefined
            ? {
                masterImageInfo: {
                  imageId,
                  size: currentFormImageSize,
                },
              }
            : imageId === null
            ? {
                masterImageInfo: {
                  imageId: null,
                  size: null,
                },
              }
            : {}),
          snapshotName: formConfigSnapshotOption?.snapshotName,
          snapshotNote: formConfigSnapshotOption?.snapshotNote,
        },
        resourceOwnerId
      );

      dispatch(FormSaved(updatedForm));
    },
    [dispatch, getState]
  );

  const restoreFormConfig = useCallback(
    async (
      shouldIgnoreConflict: boolean = false,
      formConfigSnapshotId: string
    ) => {
      const state = getState();
      const { currentForm, originalForm } = state.form;

      if (!currentForm || !originalForm) throw "No form is selected";
      const { resourceOwnerId } = state.resourceOwner;

      const { id } = currentForm;
      const { updatedAt } = originalForm;

      const restoredForm = await apiClient.restoreFormConfig(
        id,
        formConfigSnapshotId,
        updatedAt,
        shouldIgnoreConflict,
        resourceOwnerId
      );
      dispatch(FormSaved(restoredForm));
    },
    [dispatch, getState]
  );

  const { handleConflict } = useConfirmModalActionCreator();
  const handleConflictAndSaveForm = useCallback(async (): Promise<{
    didOverwrite: boolean;
  }> => {
    let didOverwrite = false;
    await handleConflict(
      async () => {
        return await saveForm(false);
      },
      async () => {
        didOverwrite = true;
        return await saveForm(true);
      },
      {
        titleId: "form_editor.form_modifed_prompt.title",
        messageId: "form_editor.form_modifed_prompt.desc",
        actionId: "common.save_and_overwrite",
      }
    );
    // Return didOverwrite for save custom model setting flow, pass the shouldIgnoreConflict to custom model save call
    return {
      didOverwrite,
    };
  }, [handleConflict, saveForm]);

  const renameForm = useCallback(
    async (name: string, shouldIgnoreConflict: boolean) => {
      const state = getState();
      const { originalForm } = state.form;
      const { resourceOwnerId } = state.resourceOwner;

      if (originalForm === undefined) {
        return;
      }

      const {
        id: formId,
        config,
        tokenGroups,
        keyValues,
        updatedAt,
      } = originalForm;

      const udpatedForm = await apiClient.updateForm(
        formId,
        {
          name,
          config: deepClone(config),
          shouldRecomputeFeatures: false,
          tokenGroups,
          keyValues,
          lastRetrieved: updatedAt,
          shouldOverwrite: shouldIgnoreConflict,
        },
        resourceOwnerId
      );

      dispatch(FormRenamed(formId, name, udpatedForm.updatedAt));
    },
    [getState, dispatch]
  );

  const updateImage = useCallback(
    async (image: File) => {
      const { currentForm } = getState().form;
      if (!currentForm) return;

      const imageDimension = await getImageSizeFromFile(image);
      const { url: imageUrl, name: imageId } = await apiClient.createAsset(
        image
      );

      if (!imageUrl || !imageId) {
        return;
      }

      dispatch(
        GotFormImageSize({
          ...imageDimension,
        })
      );

      dispatch(
        FormUpdated({
          formId: currentForm.id,
          imagePayload: {
            imageUrl,
            imageId,
          },
        })
      );
    },
    [dispatch, getState]
  );

  const removeImage = useCallback(async () => {
    const { currentForm } = getState().form;
    if (!currentForm) return;

    dispatch(
      FormUpdated({
        formId: currentForm.id,
        imagePayload: {
          imageUrl: null,
          imageId: null,
        },
      })
    );
  }, [dispatch, getState]);

  const createImageToken = useCallback(async (file: File) => {
    const { url, name } = await uploadAsset(file);
    return {
      url,
      assetId: name,
    };
  }, []);

  const fetchCustomModelOptions = useCallback(
    async (resourceOwnerId: string | undefined) => {
      return await apiClient.listCustomModelOptions(resourceOwnerId);
    },
    []
  );

  const extractFieldInTestMode = useCallback(
    async (
      token: string,
      entityId: string,
      image: File,
      extractionMode: FormExtractionMode,
      startsRecognizing: () => void
    ): Promise<OCRTestResponse> => {
      return await workerClient(token).extractFieldInTestMode(
        entityId,
        image,
        extractionMode,
        startsRecognizing
      );
    },
    []
  );

  const { pushTestedExtractionEvent } = useGtm();

  const extractFieldV2 = useCallback(
    async (
      token: string,
      extractorId: string,
      image: File,
      options: ExtractV2Options,
      startsRecognizing: () => void,
      useAsync: boolean = true,
      hasTransformResponseScript: boolean
    ): Promise<ExtractAPIV2Response> => {
      try {
        const result = await workerClient(token).extractV2(
          extractorId,
          image,
          options,
          startsRecognizing,
          useAsync
        );

        const getStatus = () => {
          if (!hasTransformResponseScript) {
            if (result.documents.length === 0) {
              return "failure";
            } else if (result.documents.find(d => d.data == null) != null) {
              return "partial-success";
            }
            return "success";
          } else {
            return "transform-success";
          }
        };

        pushTestedExtractionEvent(extractorId, getStatus());

        return result;
      } catch (e: any) {
        pushTestedExtractionEvent(extractorId, "failure");
        throw e;
      }
    },
    [pushTestedExtractionEvent]
  );

  const exportForm = useCallback(
    async (
      formId: string,
      resourceOwnerId?: string,
      region?: string
    ): Promise<void> => {
      const path =
        `/export-form?form_id=${formId}` +
        (resourceOwnerId ? `&resource_owner_id=${resourceOwnerId}` : "");

      const url = apiClient.getEffectiveEndpoint(path, region);
      const response = await apiClient.fetch(url);
      await triggerFileSave(response);
    },
    []
  );

  const validateUploadImageSize = useMasterImageSizeValidator();

  const updateFormExtractorSettings = useCallback(
    async (
      extractorSettings: ExtractorSettings,
      options?: {
        updateMerchantAutoExtractionItem?: boolean;
      }
    ) => {
      const updateMerchantAutoExtractionItem =
        options?.updateMerchantAutoExtractionItem ?? false;
      const { currentForm } = getState().form;
      if (!currentForm) return;

      if (extractorSettings.newImage === null) {
        await removeImage();
      }

      if (
        // undefined = no change
        extractorSettings.newImage !== undefined &&
        // null = remove image
        extractorSettings.newImage !== null &&
        validateUploadImageSize(extractorSettings.newImage)
      ) {
        const _image = await rotateAndCompressImage(extractorSettings.newImage);
        await updateImage(_image);
      }

      dispatch(
        FormUpdated({
          formId: currentForm.id,
          extractorSettings,
          updateMerchantAutoExtractionItem,
        })
      );
    },
    [dispatch, getState, removeImage, updateImage, validateUploadImageSize]
  );

  return useMemo(
    () => ({
      getForm,
      createForm,
      removeForm,
      pinForm,
      unpinForm,
      importForm,
      getFormImageSize,
      discardForm,
      addAnchor,
      updateAnchor,
      deleteAnchor,
      addField,
      updateField,
      deleteField,
      addDetectionRegion,
      updateDetectionRegion,
      deleteDetectionRegion,
      updateForm,
      updateFormExtractorSettings,
      updateFormKeyValues,
      updateFormTokenGroups,
      updateCustomMerchants,
      updateMerchantPatternMatching,
      updateFormCustomModels,
      saveForm,
      updateImage,
      removeImage,
      createImageToken,
      fetchCustomModelOptions,
      extractFieldInTestMode,
      exportForm,
      renameForm,
      restoreFormConfig,
      extractFieldV2,
      handleConflictAndSaveForm,
    }),
    [
      getForm,
      createForm,
      removeForm,
      pinForm,
      unpinForm,
      importForm,
      getFormImageSize,
      discardForm,
      addAnchor,
      updateAnchor,
      deleteAnchor,
      addField,
      updateField,
      deleteField,
      addDetectionRegion,
      updateDetectionRegion,
      deleteDetectionRegion,
      updateForm,
      updateFormExtractorSettings,
      updateFormKeyValues,
      updateFormTokenGroups,
      updateCustomMerchants,
      updateMerchantPatternMatching,
      updateFormCustomModels,
      saveForm,
      updateImage,
      removeImage,
      createImageToken,
      fetchCustomModelOptions,
      extractFieldInTestMode,
      exportForm,
      renameForm,
      restoreFormConfig,
      extractFieldV2,
      handleConflictAndSaveForm,
    ]
  );
}
