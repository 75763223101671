@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.steps {
  padding: 24px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.step {
  background: #8a8886;
  opacity: 0.3;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 4.5px;
  cursor: pointer;

  &.active {
    opacity: 1;
    background: #323130;
  }
}

.buttons {
  padding: 26px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skip-button {
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  font-size: 14px;
  color: $even-darker-primary-color;

  &:hover {
    opacity: 0.7;
  }
}
