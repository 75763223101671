import { Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { FOCRError } from "../../errors";
import { TeamListItem } from "../../types/team";
import { getPageByOffset } from "../../utils/pagination";
import Paginator from "../Paginator";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import { TeamTableRow, TeamTableRowInfo } from "../TeamTableRow";
import styles from "./styles.module.scss";

const teamTableRowInfoIdDict = {
  [TeamTableRowInfo.ContactEmail]: "team.list.contact_email",
  [TeamTableRowInfo.CreatedAt]: "team.list.created_at",
  [TeamTableRowInfo.TotalUsage]: "team.list.total_usage",
  [TeamTableRowInfo.Plan]: "team.list.plan",
};

interface EmptyStateUIProps {
  messageId?: string;
}

function EmptyStateUI(props: EmptyStateUIProps) {
  return (
    <div className={styles["empty-state-container"]}>
      <div className={styles["empty-state"]}>
        <div className={styles["empty-state-text"]}>
          <Label>
            <FormattedMessage id={props.messageId ?? "team.list.no_team_yet"} />
          </Label>
        </div>
      </div>
    </div>
  );
}

interface TeamTableProps {
  regionKey: string;
  isEmpty: boolean;
  offset: number;
  pageSize: number;
  totalCount: number;
  onNavigateToPage: (page: number) => void;
  teams: TeamListItem[];
  isLoading?: boolean;
  displayInfos: TeamTableRowInfo[];
  showTotalCount?: boolean;
  error?: FOCRError;
}

export default function TeamTable(props: TeamTableProps) {
  const {
    isEmpty,
    regionKey,
    teams,
    onNavigateToPage,
    offset,
    pageSize,
    totalCount,
    isLoading,
    displayInfos,
    showTotalCount,
    error,
  } = props;

  const currentPage: number = React.useMemo(() => {
    return getPageByOffset(offset, pageSize);
  }, [offset, pageSize]);

  const columnIds = React.useMemo(() => {
    return [
      "team.list.team",
      ...displayInfos.map(info => teamTableRowInfoIdDict[info]),
    ];
  }, [displayInfos]);

  if (isLoading) {
    return (
      <div className={styles["loading-container"]}>
        <ShortSpinner />
      </div>
    );
  }

  if (isEmpty) {
    return <EmptyStateUI messageId={error?.messageId} />;
  }

  return (
    <>
      <Table columnIds={columnIds}>
        {teams.map((team, index) => (
          <TeamTableRow
            team={team}
            key={index}
            regionKey={regionKey}
            displayInfos={displayInfos}
          />
        ))}
      </Table>
      <div className={styles["bottom-content"]}>
        <Paginator
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          navigateToPage={onNavigateToPage}
        />
        {showTotalCount && (
          <span className={styles["total-count"]}>
            {totalCount} <FormattedMessage id="team.list.title" />
          </span>
        )}
      </div>
    </>
  );
}
