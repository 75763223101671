import React, { useCallback, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";

import TeamDetailLayout from "../components/TeamDetail";
import { TeamDetailTab } from "../components/TeamDetailBasicContent";
import { useFetchTeam } from "../hooks/admin";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { AdminPathParam } from "../models";
import AdminTeamDetailEnabledFeaturesContainer from "./AdminTeamDetailEnabledFeatures";
import AdminTeamDetailMembersContainer from "./AdminTeamDetailMembers";
import AdminTeamDetailPlanContainer from "./AdminTeamDetailPlan";
import { AdminTeamDetailResourcesContainer } from "./AdminTeamDetailResource";
import AdminTeamDetailUsageContainer from "./AdminTeamDetailUsage";

function _AdminTeamDetailContainer() {
  const { teamId, region } = useUnsafeParams<AdminPathParam>();
  const navigate = useNavigate();
  const { team, isFetching, error } = useAppSelector(
    state => state.admin.teamDetail
  );
  const { pathname } = useLocation();

  const currentTab: TeamDetailTab = useMemo(() => {
    if (pathname.includes("members")) {
      return TeamDetailTab.members;
    }
    if (pathname.includes("resources")) {
      return TeamDetailTab.resources;
    }
    if (pathname.includes("plan")) {
      return TeamDetailTab.plan;
    }
    if (pathname.includes("features")) {
      return TeamDetailTab.features;
    }
    return TeamDetailTab.usage;
  }, [pathname]);

  const onTabSelected = useCallback(
    (tab: TeamDetailTab) => {
      if (tab === TeamDetailTab.usage) {
        navigate(`/admin/team/${region}/${teamId}`);
        return;
      }
      navigate(`/admin/team/${region}/${teamId}/${tab}`);
    },
    [navigate, teamId, region]
  );

  useFetchTeam(teamId, region);

  if (!isFetching && error !== undefined) {
    return <Navigate to="/admin/team/" replace />;
  }

  return (
    <TeamDetailLayout
      region={region}
      team={team}
      isLoading={isFetching}
      currentTab={currentTab}
      onTabSelected={onTabSelected}
    >
      <Routes>
        <Route path="" element={<AdminTeamDetailUsageContainer />} />
        <Route path="members" element={<AdminTeamDetailMembersContainer />} />
        <Route
          path="resources/*"
          element={<AdminTeamDetailResourcesContainer />}
        />
        <Route path="plan" element={<AdminTeamDetailPlanContainer />} />
        <Route
          path="features"
          element={<AdminTeamDetailEnabledFeaturesContainer />}
        />
      </Routes>
    </TeamDetailLayout>
  );
}

export const AdminTeamDetailContainer = React.memo(_AdminTeamDetailContainer);
export default AdminTeamDetailContainer;
