.field-item-edit-panel-complex-option {
  padding: 10px 0px;
}

.field-item-edit-panel-complex-option-sub-text {
  color: #605e5c;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.field-item-edit-panel-container {
  padding: 20px 20px;
  background-color: #fff;
}

.field-item-edit-panel {
  padding: 0px 16px 0px 16px;
  border: 1px solid #edebe9;

  .field-item-edit-panel-header {
    background: #faf9f8;
    margin: 0px -16px;
    padding: 12px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .field-item-edit-panel-name-field {
    margin-top: 12px;
    margin-bottom: 16px;
    width: 400px;
  }

  .field-item-edit-panel-type-field {
    margin-bottom: 22px;
    width: 400px;
  }

  .field-item-edit-panel-is-list-field {
    margin-bottom: 20px;
  }
}

.field-item-edit-panel-what-is-table {
  padding: 16px 16px;
  background: #faf9f8;
  margin: 0px -16px;

  .field-item-edit-panel-what-is-table-title {
    color: #323130;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .field-item-edit-panel-what-is-table-desc {
    color: #323130;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-bottom: 16px;
  }
}

.field-item-edit-panel-subfield-grid {
  padding: 16px 0px 8px 0px;
  display: grid;
  grid-template-columns: 0fr 1fr 1fr 1fr fit-content(80px);
  column-gap: 8px;
}

.field-item-edit-panel-subfield-grid-header {
  color: #201f1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
