import { useCallback } from "react";

import { useLocale } from "../contexts/locale";
import messages from "../locale-data/en.json";
import { AutoExtractionItem, FormConfig } from "../types/formConfig";

export function useLocalizeAutoExtractionItemName() {
  const { localized } = useLocale();

  return useCallback(
    (axi: AutoExtractionItem, formConfig: FormConfig) => {
      const documentType = formConfig.document_type || "general";

      // #3048 - Special handling of address_proof date
      if (documentType === "address_proof" && axi === "date") {
        return localized("auto_extraction_item.other_dates");
      }

      const localeId = `auto_extraction_item.${axi}.${formConfig.document_type}`;
      if (localeId in messages) {
        return localized(localeId);
      }
      return localized(`auto_extraction_item.${axi}`);
    },
    [localized]
  );
}
