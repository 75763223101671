@import "../../styles/variables.scss";

.loading-content {
  padding-left: 25px;
  position: relative;

  .spinner-container {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .loading-label {
    color: $form-extractor-logo-color;
  }
}
