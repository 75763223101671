.table-container {
  overflow-y: auto;
  background-color: #fff;
  width: 100%;
  color: rgb(32, 31, 30);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  border-collapse: collapse;

  th,
  td {
    border: none;
    font-size: 14px;
    padding: 11px 12px;
  }

  th {
    text-align: left;
    font-weight: 600;
  }

  tbody > tr:hover {
    background-color: rgb(243, 242, 241);
  }

  tr {
    border-bottom: 1px solid #edebe9;
  }
}
