import { PrimaryButton, TextField } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import { useTextFieldChange } from "../../hooks/component";
import { focusTextField } from "../../utils/components";
import Link from "../WrappedMSComponents/Link";
import styles from "./styles.module.scss";

interface Props {
  email: string;
  emailErrorMessageId?: string;
  onEmailChange: (value: string) => void;

  password: string;
  passwordErrorMessageId?: string;
  onPasswordChange: (value: string) => void;

  onSubmit: () => void;
}

function _LoginForm(props: Props) {
  const { localized } = useLocale();

  const {
    email,
    emailErrorMessageId,
    password,
    passwordErrorMessageId,
    onEmailChange,
    onPasswordChange,
    onSubmit,
  } = props;

  const onFormSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onSubmit();
    },
    [onSubmit]
  );

  const onEmailFieldChange = useTextFieldChange(onEmailChange);
  const onPasswordFieldChange = useTextFieldChange(onPasswordChange);

  const emailErrorMessage =
    emailErrorMessageId && localized(emailErrorMessageId);
  const passwordErrorMessage =
    passwordErrorMessageId && localized(passwordErrorMessageId);

  return (
    <form className={styles["login-form"]} onSubmit={onFormSubmit}>
      <div className={styles["logo"]} />
      <TextField
        type="email"
        iconProps={{ iconName: "Mail" }}
        label={localized("common.email")}
        placeholder={localized("common.email.placeholder")}
        value={email}
        errorMessage={emailErrorMessage}
        onChange={onEmailFieldChange}
        tabIndex={1}
        componentRef={focusTextField}
      />
      <TextField
        type="password"
        iconProps={{ iconName: "PasswordField" }}
        label={localized("common.password")}
        placeholder={localized("common.password.placeholder")}
        value={password}
        errorMessage={passwordErrorMessage}
        onChange={onPasswordFieldChange}
        tabIndex={2}
      />
      <div className={styles["link"]}>
        <Link to="/signup" tabIndex={-1}>
          <FormattedMessage id="login.no_account_yet" />
        </Link>
      </div>
      <div className={styles["button"]}>
        <PrimaryButton
          tabIndex={3}
          type="submit"
          text={localized("login.name")}
        />
      </div>
    </form>
  );
}

export const LoginForm = React.memo(_LoginForm);
export default LoginForm;
