import React from "react";
import { useSelector } from "react-redux";

import { useGoogleAuthActionCreator } from "../actions/googleAuth";
import type { OAuthError } from "../actions/oauth";
import { RootState } from "../redux/types";

export interface useGoogleAuthReturnValues {
  googleAuthError: OAuthError | null;
  isGoogleAuthorized: boolean;
  isGoogleAuthorizing: boolean;
  onGoogleAuth: (scopes: string[]) => Promise<void>;
  resetGoogleAuth: () => void;
}

export function useGoogleAuth(): useGoogleAuthReturnValues {
  const { auth, reset } = useGoogleAuthActionCreator();
  const { authError, isAuthorized, isAuthorizing } = useSelector(
    (state: RootState) => state.googleAuth
  );

  return React.useMemo(
    () => ({
      googleAuthError: authError,
      isGoogleAuthorized: isAuthorized,
      isGoogleAuthorizing: isAuthorizing,
      onGoogleAuth: auth,
      resetGoogleAuth: reset,
    }),
    [auth, authError, isAuthorized, isAuthorizing, reset]
  );
}
