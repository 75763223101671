import React, { useCallback, useState } from "react";

import { useAdminActionCreator } from "../actions/admin";
import LoadingModal from "../components/LoadingModal";
import TeamDetailEnabledFeatures from "../components/TeamDetailEnabledFeatures";
import { UserFeatureFlag } from "../constants";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { AdminPathParam } from "../models";

function _AdminTeamDetailEnabledFeaturesContainer() {
  const { teamId, region } = useUnsafeParams<AdminPathParam>();
  const { team } = useAppSelector(state => state.admin.teamDetail);
  const { setTeamEnabledFeatures } = useAdminActionCreator();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toast = useToast();

  const onSetTeamFeatures = useCallback(
    async (features: UserFeatureFlag[], enabledAuditLog: boolean) => {
      setIsSubmitting(true);
      try {
        await setTeamEnabledFeatures(teamId, region, features, enabledAuditLog);
        toast.success("team.detail.features.set_features.success");
      } catch (e) {
        console.error("Failed to set features: ", e);
        toast.error("team.detail.features.set_features.fail");
      } finally {
        setIsSubmitting(false);
      }
    },
    [setTeamEnabledFeatures, teamId, region, toast]
  );

  return (
    <>
      {team && (
        <>
          <TeamDetailEnabledFeatures
            team={team}
            key={team.id}
            region={region}
            onSetTeamFeatures={onSetTeamFeatures}
          />
        </>
      )}
      <LoadingModal isOpen={isSubmitting} />
    </>
  );
}

export const AdminTeamDetailEnabledFeaturesContainer = React.memo(
  _AdminTeamDetailEnabledFeaturesContainer
);
export default AdminTeamDetailEnabledFeaturesContainer;
