import { Link as FluentLink, IconButton, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import theme from "../../theme";
import { UserSetting } from "../../types/user";
import { disableDefaultFormSubmit, readFile } from "../../utils/file";
import { FileDefaultButton } from "../FileButton";
import StickyButtonBox from "../StickyButtonBox";
import { PrimaryButton } from "../WrappedMSComponents/Buttons";
import ErrorText from "../WrappedMSComponents/ErrorText";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

interface Props {
  setting?: UserSetting;
  onSettingChange: (setting: UserSetting) => void;
  onSubmit: () => void;
}

type LocalState = {
  setting?: UserSetting;
  fileName: string;
  isFormChanged: boolean;
  isGoogleServiceAccountFieldValid: boolean;
};

export default class SettingForm extends React.PureComponent<
  Props,
  LocalState
> {
  state: LocalState = {
    isFormChanged: false,
    fileName: "",
    isGoogleServiceAccountFieldValid: true,
    setting: this.props.setting,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.setting !== this.props.setting) {
      this.setState({
        setting: this.props.setting,
        isFormChanged: false,
      });
    }
  }

  onGoogleServiceKeyChange = (files?: File[]) => {
    if (!files || !files[0]) return;

    readFile(files[0])
      .then(content => {
        if (!this.state.setting) {
          return;
        }
        const updatedSetting = {
          ...this.state.setting,
          googleServiceAccountKey: JSON.parse(content as any),
        };

        this.setState({
          isGoogleServiceAccountFieldValid: true,
          isFormChanged: true,
          fileName: files[0].name,
          setting: updatedSetting,
        });
        const { onSettingChange } = this.props;
        onSettingChange(updatedSetting);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isGoogleServiceAccountFieldValid: false,
        });
      });
  };

  onClearGoogleServiceAccountKey = () => {
    if (!this.state.setting) {
      return;
    }

    const { onSettingChange } = this.props;
    const setting = { ...this.state.setting };
    if (setting.googleServiceAccountKey !== undefined) {
      setting.googleServiceAccountKey = null;
    }
    onSettingChange(setting);
    this.setState({
      setting: setting,
      isFormChanged: true,
    });
  };

  onAzureSubscriptionKeyChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (!this.state.setting) {
      return;
    }

    if (newValue !== undefined) {
      const updatedSetting = {
        ...this.state.setting,
        azureSubscriptionKey: newValue,
      };
      this.setState({
        setting: updatedSetting,
        isFormChanged: true,
      });
      this.props.onSettingChange(updatedSetting);
    }
  };

  onAzureEndpointChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (!this.state.setting) {
      return;
    }

    if (newValue !== undefined) {
      const updatedSetting = {
        ...this.state.setting,
        azureEndpoint: newValue,
      };
      this.setState({
        setting: updatedSetting,
        isFormChanged: true,
      });
      this.props.onSettingChange(updatedSetting);
    }
  };

  onSave = () => {
    if (this.state.isFormChanged) {
      this.setState({
        fileName: "",
      });
      this.props.onSubmit();
    }
  };

  render() {
    const { isFormChanged } = this.state;
    const { setting, isGoogleServiceAccountFieldValid } = this.state;
    return (
      <form onSubmit={disableDefaultFormSubmit}>
        <div className={styles["setting-form"]}>
          {this.renderGoogleCloudVisionSetting(setting)}
          {this.renderAzureComputerVisionSetting(setting)}
          <div className={styles["bottom-padding"]} />
        </div>
        <StickyButtonBox>
          <PrimaryButton
            textId="common.save"
            disabled={!isFormChanged || !isGoogleServiceAccountFieldValid}
            type="submit"
            onClick={this.onSave}
          />
        </StickyButtonBox>
      </form>
    );
  }

  private renderGoogleCloudVisionSetting(setting?: UserSetting) {
    const googleServiceAccountKey =
      setting?.googleServiceAccountKey &&
      setting?.googleServiceAccountKey["private_key_id"]
        ? setting.googleServiceAccountKey["private_key_id"]
        : "";
    return (
      <div>
        <h1>
          <FormattedMessage id="setting.google_cloud_vision" />
        </h1>
        <pre>
          <FormattedMessage id="setting.google_cloud_vision_description" />
        </pre>
        {this.renderReferences([
          [
            "setting.how_to_get_google_service_account_key",
            "https://cloud.google.com/iam/docs/creating-managing-service-account-keys#creating_service_account_keys",
          ],
          [
            "setting.how_to_enable_google_cloud_vision_api",
            "https://cloud.google.com/vision/docs/before-you-begin",
          ],
          ["setting.pricing", "https://cloud.google.com/vision/pricing"],
          [
            "setting.contact_us_about_getting_the_key",
            "mailto:hello@oursky.com",
          ],
        ])}
        <div className={styles["google-key-textfield-container"]}>
          <TextField
            className={styles["google-key-textfield"]}
            labelId="setting.google_service_account_key"
            type="text"
            value={googleServiceAccountKey}
            readOnly={true}
          />
          {googleServiceAccountKey && (
            <IconButton
              iconProps={{ iconName: "Delete" }}
              onClick={this.onClearGoogleServiceAccountKey}
            />
          )}
        </div>

        <div className={styles["file-section"]}>
          <div className={styles["file-name-container"]}>
            <Text>{this.state.fileName.trim()}</Text>
          </div>
          <FileDefaultButton
            accept="text/json"
            textId="setting.choose_service_account_key"
            onFiles={this.onGoogleServiceKeyChange}
          />
        </div>

        {!this.state.isGoogleServiceAccountFieldValid && (
          <ErrorText>
            <FormattedMessage id="setting.uploaded_invalid_file" />
          </ErrorText>
        )}
      </div>
    );
  }

  private renderAzureComputerVisionSetting(setting?: UserSetting) {
    const azureSubscriptionKey = setting?.azureSubscriptionKey || "";
    const azureEndpoint = setting?.azureEndpoint || "";
    return (
      <div>
        <h1>
          <FormattedMessage id="setting.azure_computer_vision" />
        </h1>
        <pre>
          <FormattedMessage id="setting.azure_computer_vision_description" />
        </pre>
        {this.renderReferences([
          [
            "setting.how_to_get_azure_subscription_key",
            "https://docs.microsoft.com/en-us/azure/cognitive-services/computer-vision/vision-api-how-to-topics/howtosubscribe",
          ],
          [
            "setting.pricing",
            "https://azure.microsoft.com/en-us/pricing/details/cognitive-services/computer-vision/",
          ],
          [
            "setting.contact_us_about_getting_the_key",
            "mailto:hello@oursky.com",
          ],
        ])}

        <TextField
          className={styles["textfield"]}
          labelId="setting.azure_subscription_key"
          type="text"
          value={azureSubscriptionKey}
          onChange={this.onAzureSubscriptionKeyChange}
        />

        <TextField
          className={styles["textfield"]}
          labelId="setting.azure_endpoint"
          type="text"
          value={azureEndpoint}
          onChange={this.onAzureEndpointChange}
        />
      </div>
    );
  }

  link = {
    root: {
      selectors: {
        ":visited": {
          color: theme.palette.themePrimary,
        },
      },
    },
  };

  private renderReferences(references: string[][]) {
    return (
      <ul className={styles["references"]}>
        {references.map((pair, idx) => {
          return (
            <li key={idx}>
              <FluentLink href={pair[1]} styles={this.link} target="_blank">
                <FormattedMessage id={pair[0]} />
              </FluentLink>
            </li>
          );
        })}
      </ul>
    );
  }
}
