import businessRegistrationSample1 from "../images/sample/sample-business-registration.jpg";
import sampleForm1 from "../images/sample/sample-form-1.jpg";
import sampleForm2 from "../images/sample/sample-form-2.jpg";
import hkidNewSample1 from "../images/sample/sample-hkid-new.jpg";
import hkidOldSample1 from "../images/sample/sample-hkid-old.jpg";
import moidSample1 from "../images/sample/sample-moid-1.jpg";
import passportSample1 from "../images/sample/sample-passport-1.jpg";
import passportSample2 from "../images/sample/sample-passport-2.jpg";
import passportSample3 from "../images/sample/sample-passport-3.jpg";
import passportSample4 from "../images/sample/sample-passport-4.jpg";
import travelAgencyChinese1 from "../images/sample/sample-travel-agency-chinese.jpg";
import twidSample from "../images/sample/sample-twid.jpg";
import { AvailableTemplateType } from "../types/template";

export const TemplateSampleMapping: {
  [key in AvailableTemplateType]: string[];
} = {
  business_registration: [businessRegistrationSample1],
  hkid: [hkidNewSample1, hkidOldSample1],
  moid: [moidSample1],
  twid: [twidSample],
  passport: [
    passportSample1,
    passportSample2,
    passportSample3,
    passportSample4,
  ],
  travel_agency_chinese: [travelAgencyChinese1],
  travel_agency_english: [travelAgencyChinese1], //TODO - replace once have the new sample image
  intl_id: [
    hkidNewSample1,
    hkidOldSample1,
    moidSample1,
    twidSample,
    passportSample1,
    passportSample2,
  ],
};

interface TemplateDescription {
  label: string;
  url?: string;
}

export const TemplateDescriptionMapping: {
  [key in AvailableTemplateType]: TemplateDescription;
} = {
  intl_id: {
    label: "form_template.desc.intl_id",
    url: "https://help.formx.ai/docs/international-idpassports",
  },
};

export const TEMPLATES_TO_HIDE = ["passport", "hkid", "moid", "twid", "sgid"];
export const TEMPLATE_ORDERINGS = [["hkid", "moid", "twid", "sgid"]];
export const SampleFormImageMapping = [sampleForm1, sampleForm2];
