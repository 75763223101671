// NOTE: separated from ./workspace.ts to avoid circular dependencies
import * as yup from "yup";

export const briefWorkspaceSchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
  })
  .camelCase();

export type BriefWorkspace = yup.InferType<typeof briefWorkspaceSchema>;
