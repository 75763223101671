export const CUSTOM_FIELD_POSITION_MENU_OPTIONS = [
  {
    key: "left",
    text: "edit_custom_field.position.left",
  },
  {
    key: "right",
    text: "edit_custom_field.position.right",
  },
  {
    key: "above",
    text: "edit_custom_field.position.above",
  },
  {
    key: "below",
    text: "edit_custom_field.position.below",
  },
];

export const CUSTOM_FIELD_PATTERN_OPTIONS = [
  {
    label: "regex_builder.pattern_choice.lowercase_alphabet",
    value: "lowercase_alphabet",
  },
  {
    label: "regex_builder.pattern_choice.uppercase_alphabet",
    value: "uppercase_alphabet",
  },
  {
    label: "regex_builder.pattern_choice.chinese_char",
    value: "chinese_char",
  },
  {
    label: "regex_builder.pattern_choice.number",
    value: "number",
  },
  {
    label: "regex_builder.pattern_choice.dot",
    value: "dot",
  },
];
