.frame {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin: 2px;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.errored {
  border-radius: 2px;
  background-color: #f8f8f8;
}
