import { IconButton, ScrollablePane } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";
import { useMerchantSettings } from "../../contexts/merchantSettings";
import { useTeamPermission } from "../../hooks/permission";
import { MerchantSetting } from "../../types/merchantSetting";
import styles from "./styles.module.scss";

interface ListItemProps {
  merchant: MerchantSetting;
}

const FIELD_TEXT_MAX_CHAR = 26;

function MerchantNameListItem(props: ListItemProps) {
  const { merchant } = props;
  const {
    selectedMerchantId,
    selectMerchant,
    getPresetFieldTranslateId,
    merchantErrors,
    getErrorTranslateId,
  } = useMerchantSettings();
  const { localized } = useLocale();

  const onClick = useCallback(() => {
    selectMerchant(merchant.id);
  }, [selectMerchant, merchant]);

  const availableInfoText = useMemo(() => {
    const { infos } = merchant;
    const tokens = [];
    let count = 0;
    const nonEmptyFields = infos
      .filter(info => info.pattern.trim() !== "" && info.type.trim() !== "")
      .map(info => {
        return info.isPreset
          ? localized(getPresetFieldTranslateId(info.type))
          : info.type;
      });

    if (nonEmptyFields.length === 0) {
      return "";
    }

    let i = 0;
    for (; i < nonEmptyFields.length; i++) {
      const field = nonEmptyFields[i];
      if (count + field.length > FIELD_TEXT_MAX_CHAR) {
        break;
      }
      count += field.length;
      tokens.push(field);
    }

    const joined = tokens.join(" , ");
    return i >= nonEmptyFields.length
      ? joined
      : joined +
          localized("merchant_settings_modal.list.more_info", {
            count: nonEmptyFields.length - i,
          });
  }, [merchant, getPresetFieldTranslateId, localized]);

  const errors = merchantErrors[merchant.id];

  return (
    <div
      onClick={onClick}
      className={classnames(styles["list-item"], "merchant-name-list-item", {
        [styles["selected-list-item"]]: selectedMerchantId === merchant.id,
      })}
    >
      {merchant.name === "" ? (
        <div className={styles["default-title"]}>
          <FormattedMessage id="merchant_settings_modal.list.default_merchant_name" />
        </div>
      ) : (
        <div className={styles["title"]}>{merchant.name}</div>
      )}

      {errors !== undefined ? (
        <>
          {errors.map(error => (
            <div className={styles["error"]} key={error}>
              <FormattedMessage id={getErrorTranslateId(error)} />
            </div>
          ))}
        </>
      ) : (
        <>
          {availableInfoText.length > 0 && (
            <div className={styles["available-info"]}>{availableInfoText}</div>
          )}

          <div className={styles["match-info"]}>
            {merchant.matchMode === "best" ? (
              <FormattedMessage id="merchant_settings_modal.match_one_info" />
            ) : (
              <FormattedMessage id="merchant_settings_modal.match_all_info" />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default function MerchantNameList() {
  const {
    rawCustomMerchants,
    customMerchants,
    addMerchant,
    merchantFilter,
    removeSelectedMerchant,
    merchantNameListRootRef,
    moveSelectedMerchantByKeyEvent,
    merchantNameListFocusAreaRef,
    focusMerchantNameList,
  } = useMerchantSettings();

  const placeholderVisible =
    rawCustomMerchants.length === 0 && merchantFilter === "";

  const emptyPlaceHolderVisible =
    customMerchants.length === 0 && merchantFilter !== "";

  const [isFocus, setIsFocus] = useState<boolean>(false);

  useEffect(() => {
    if (isFocus) {
      focusMerchantNameList();
    }
  });

  // Auto focus on startup
  useEffect(focusMerchantNameList, []); //eslint-disable-line react-hooks/exhaustive-deps

  const onFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsFocus(false);
  }, []);

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["container"]}>
      <div className={styles["list"]} ref={merchantNameListRootRef}>
        {placeholderVisible ? (
          <div className={styles["placeholder"]}>
            {[1, 2, 3].map(item => (
              <p key={`key${item}`}>
                <FormattedMessage
                  id={`merchant_settings_modal.list_placeholder${item}`}
                />
              </p>
            ))}
          </div>
        ) : emptyPlaceHolderVisible ? (
          <div className={styles["empty-placeholder"]}>
            <FormattedMessage
              id="merchant_settings_modal.list_filter_empty"
              values={{
                filter: merchantFilter,
              }}
            />
          </div>
        ) : (
          <ScrollablePane>
            <div
              onKeyDown={moveSelectedMerchantByKeyEvent}
              tabIndex={0}
              ref={merchantNameListFocusAreaRef}
              onClick={focusMerchantNameList}
              className={styles["focus-area"]}
              onBlur={onBlur}
              onFocus={onFocus}
            >
              {customMerchants.map(merchant => (
                <MerchantNameListItem merchant={merchant} key={merchant.id} />
              ))}
            </div>
          </ScrollablePane>
        )}
      </div>
      {hasPermissionToEditResource && (
        <div className={styles["buttons"]}>
          <IconButton iconProps={{ iconName: "Add" }} onClick={addMerchant} />
          <IconButton
            iconProps={{ iconName: "Delete" }}
            onClick={removeSelectedMerchant}
          />
        </div>
      )}
    </div>
  );
}
