@import "../../styles/variables.scss";

.container {
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 12px;
  }

  h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  h3 {
    font-weight: 400;
    font-size: 14px;
    color: #888888;
    margin-top: 4px;
  }

  .content {
    width: 560px;
    padding: 32px 0;
    margin: auto;

    .action {
      color: $theme-primary;
      cursor: pointer;
      font-size: 14px;
      line-height: 20px;
    }

    .invitations {
      margin-top: 12px;
      margin-bottom: 28px;
      gap: 12px;
      display: flex;
      flex-wrap: wrap;

      .invitation {
        width: 100%;
        box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1),
          0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
        padding: 20px;

        .bottom {
          justify-content: flex-end;
          gap: 12px;
          display: flex;
          flex-wrap: wrap;

          .loading-button {
            width: 86px;
          }
        }

        h1,
        h3 {
          margin-bottom: 2px;
        }
      }

      hr {
        width: 100%;
        border-top: 1px solid #edebe9;
        border-bottom: 0;
        margin: 12px 0;
      }
    }
  }

  .message {
    padding-bottom: 18px;
    h3 {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }

  @media screen and (max-width: 580px) {
    .content {
      padding: 20px;

      .invitations {
        .invitation {
          padding: 12px;
        }
      }
    }
  }
}
