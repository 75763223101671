import React, { useCallback, useMemo } from "react";

import { useDetectionRegionInspector } from "../../contexts/detectionRegionInspector";
import { BaseFieldSettingModalPayload } from "../../models";
import { LLMCompletion } from "../../types/llmCompletion";
import { LLMCompletionSettingsModal } from "../LLMCompletionSettingsModal";

interface Props {
  payload: BaseFieldSettingModalPayload;
  onCloseModal(): void;
}

const DEFAULT_TASK_NAME = "default";

export function useLLMCompletionFieldModal(props: Props) {
  const { onCloseModal, payload } = props;
  const { index, field } = payload;

  const { updateDetectionRegionField } = useDetectionRegionInspector();

  const onConfirm = useCallback(
    (setting?: { [key: string]: LLMCompletion }) => {
      updateDetectionRegionField(index, {
        type: field.type,
        params: { llm_completion: setting && setting[DEFAULT_TASK_NAME] },
      });
      onCloseModal();
    },
    [field, onCloseModal, index, updateDetectionRegionField]
  );

  const llmCompletions = useMemo(() => {
    return {
      [DEFAULT_TASK_NAME]: field.params?.llm_completion || { prompt: "" },
    };
  }, [field.params?.llm_completion]);

  return React.useMemo(
    () => ({
      llmCompletions,
      onConfirm,
      onClose: onCloseModal,
    }),
    [llmCompletions, onCloseModal, onConfirm]
  );
}

export function LLMCompletionFieldModalImpl(
  props: ReturnType<typeof useLLMCompletionFieldModal>
) {
  return <LLMCompletionSettingsModal {...props} isOpen shouldHideTaskName />;
}

export default function LLMCompletionFieldModal(args: Props) {
  const props = useLLMCompletionFieldModal(args);
  return <LLMCompletionFieldModalImpl {...props} />;
}
