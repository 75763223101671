@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.team-usage-table-row {
  cursor: pointer;

  td:nth-child(1) {
    vertical-align: top;
  }

  .basic-info {
    max-width: 272px;
    .name {
      height: 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .lookup-id {
      height: 16px;
      overflow: hidden;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $light-gray-color;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .detail-info,
  .detail-info-capitalize {
    color: $light-gray-color;
  }

  .detail-info-capitalize {
    text-transform: capitalize;
  }
}
