import {
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  PrimaryButton,
} from "@fluentui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useExtractorActionCreator } from "../../actions/extractor";
import { useLocale } from "../../contexts/locale";
import { FOCRError } from "../../errors";
import { useToast } from "../../hooks/toast";
import { ResourceOwnerState } from "../../reducers/resourceOwner";
import { RootState } from "../../redux/types";
import { PrebuiltExtractor } from "../../types/extractor";
import {
  PreferenceKey,
  getPreference,
  setPreference,
} from "../../utils/preference";

interface Props {
  type: PrebuiltExtractor | null;
  onClose: () => void;
}

function getIsPreviewExtractorRequested(teamLookupId: string): boolean {
  try {
    return !!JSON.parse(
      getPreference(PreferenceKey.isPreviewExtractorRequested) || "{}"
    )[teamLookupId];
  } catch {
    return false;
  }
}

function setIsPreviewExtractorRequested(teamLookupId: string) {
  let value = {};
  try {
    value = JSON.parse(
      getPreference(PreferenceKey.isPreviewExtractorRequested) || "{}"
    );
  } catch {}

  setPreference(
    PreferenceKey.isPreviewExtractorRequested,
    JSON.stringify({
      ...value,
      [teamLookupId]: true,
    })
  );
}

const RequestPreviewExtractorDialog = (props: Props) => {
  const { type, onClose } = props;
  const { localized } = useLocale();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const resourceOwner = useSelector<RootState, ResourceOwnerState>(
    state => state.resourceOwner
  );

  const [isSubmitted, setIsSubmitted] = useState<boolean>(
    !!(
      resourceOwner.teamLookupId &&
      getIsPreviewExtractorRequested(resourceOwner.teamLookupId)
    )
  );

  const { requestPreviewExtractor } = useExtractorActionCreator();
  const toast = useToast();

  const onSubmit = useCallback(async () => {
    if (type !== null) {
      setIsSubmitting(true);

      try {
        await requestPreviewExtractor(type, resourceOwner.resourceOwnerId);

        if (resourceOwner.teamLookupId) {
          setIsPreviewExtractorRequested(resourceOwner.teamLookupId);
        }

        setIsSubmitted(true);
      } catch (error) {
        if (error instanceof FOCRError) {
          toast.error(error.messageId, undefined, error.detail);
        } else {
          toast.error("extractor.request_preview_extractor.error");
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  }, [
    type,
    toast,
    requestPreviewExtractor,
    resourceOwner.teamLookupId,
    resourceOwner.resourceOwnerId,
  ]);

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        isSubmitted
          ? "extractor.request_preview_extractor.success.title"
          : "extractor.request_preview_extractor.title"
      ),
      subText: localized(
        isSubmitted
          ? "extractor.request_preview_extractor.success.desc"
          : "extractor.request_preview_extractor.desc"
      ),
    }),
    [localized, isSubmitted]
  );

  return (
    <Dialog
      hidden={type == null}
      dialogContentProps={dialogContentProps}
      onDismiss={onClose}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={isSubmitted ? onClose : onSubmit}
          text={localized(
            isSubmitted
              ? "extractor.request_preview_extractor.dismiss"
              : "extractor.request_preview_extractor.join"
          )}
          disabled={isSubmitting}
        />
      </DialogFooter>
    </Dialog>
  );
};

export default RequestPreviewExtractorDialog;
