import {
  Checkbox,
  ChoiceGroup,
  DatePicker,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo } from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

const MIN_DATE = new Date();
const INITIAL_QUOTA = 100;

interface Props {
  isOpen: boolean;
  onCancel(): void;
  onSetPlan(quota?: number, endTrialAt?: Date): void;
  titleMessageId: string;
  indefinitetlyMessageId: string;
  planModeMessageId: string;
}

type PlanMode = "unlimited" | "quota";

const SetPlanModal = (props: Props) => {
  const {
    onCancel,
    isOpen,
    onSetPlan,
    titleMessageId,
    indefinitetlyMessageId,
    planModeMessageId,
  } = props;
  const [planMode, setPlanMode] = React.useState<PlanMode>("unlimited");
  const [quota, setQuota] = React.useState<number>(INITIAL_QUOTA);
  const [isQuotaFieldValid, setIsQuotaFieldValid] =
    React.useState<boolean>(true);
  const [endAt, setEndAt] = React.useState<Date>(MIN_DATE);
  const [isIndefinitely, setIndefinitely] = React.useState<boolean>(true);

  const { localized } = useLocale();

  const onDismissed = useCallback(() => {
    setPlanMode("unlimited");
    setQuota(INITIAL_QUOTA);
    setIsQuotaFieldValid(true);
    setEndAt(MIN_DATE);
    setIndefinitely(true);
  }, []);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      let submitQuota: number | undefined = undefined;
      let submitEndAt: Date | undefined = undefined;

      if (planMode === "quota") {
        submitQuota = quota;
      }
      if (!isIndefinitely) {
        submitEndAt = endAt;
      }

      onSetPlan(submitQuota, submitEndAt);
    },
    [planMode, quota, endAt, isIndefinitely, onSetPlan]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(titleMessageId),
    }),
    [localized, titleMessageId]
  );

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
    }),
    [onDismissed]
  );

  const options: IChoiceGroupOption[] = useMemo(() => {
    return [
      {
        key: "unlimited",
        text: localized("team.detail.plan.quota.unlimited"),
      },
      {
        key: "quota",
        text: localized("team.detail.quota"),
      },
    ];
  }, [localized]);

  const onPlanModelChange = useCallback(
    (
      _ev: React.FormEvent<HTMLInputElement | HTMLElement> | undefined,
      option?: IChoiceGroupOption
    ) => {
      if (option) {
        setPlanMode(option.key as PlanMode);
      }
    },
    []
  );

  const onQuotaChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value === undefined || isNaN(parseInt(value))) {
        return;
      }
      setQuota(parseInt(value));
      setIsQuotaFieldValid(true);
    },
    []
  );

  const onEndAtChange = useCallback((date?: Date | null) => {
    if (date) setEndAt(date);
  }, []);

  const onIsDefinitely = useCallback(
    (
      event?: React.FormEvent<HTMLInputElement | HTMLElement>,
      checked?: boolean
    ) => {
      if (event === undefined || checked === undefined) {
        return;
      }
      event.stopPropagation();
      setIndefinitely(checked);
    },
    []
  );

  const formatDate = useCallback((date?: Date) => {
    return date?.toLocaleDateString("en-GB") ?? "";
  }, []);

  return (
    <Dialog
      minWidth={400}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={onSubmit}>
        <ChoiceGroup
          defaultSelectedKey={planMode}
          options={options}
          onChange={onPlanModelChange}
          label={localized(planModeMessageId)}
        />
        {planMode === "quota" && (
          <TextField
            label={localized("team.detail.plan.no_of_pages")}
            type="number"
            min="1"
            onChange={onQuotaChange}
            value={quota.toString()}
            errorMessage={
              isQuotaFieldValid
                ? undefined
                : localized("error.give_trial.empty_quota")
            }
          />
        )}
        {!isIndefinitely && (
          <DatePicker
            label={localized("team.detail.end_at")}
            className={styles["set-plan-end-at"]}
            onSelectDate={onEndAtChange}
            value={endAt}
            minDate={MIN_DATE}
            formatDate={formatDate}
          />
        )}
        <Checkbox
          checked={isIndefinitely}
          className={styles["set-plan-indefinitely"]}
          label={localized(indefinitetlyMessageId)}
          onChange={onIsDefinitely}
        />
        <DialogFooter>
          <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
          <PrimaryButton
            type="submit"
            text={localized("team.detail.plan.save")}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
};

export default SetPlanModal;
