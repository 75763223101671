import * as yup from "yup";

import { Template, templateSchema } from "../../types/template";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface TemplateApiClient {
  listTemplates: () => Promise<Template[]>;
}

export function withTemplateApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async listTemplates(): Promise<Template[]> {
      return this.lambda(
        "template:list",
        {},
        yup.array(templateSchema).defined()
      );
    }
  };
}
