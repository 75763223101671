import { useSelector } from "react-redux";

import { useOAuthActionCreator } from "../actions/oauth";
import { RootState } from "../redux/types";
import type { OAuthCredential } from "../types/oauth";

export interface useOAuthCredentialReturnValues {
  deleteOAuthCredential: (id: string) => Promise<void>;
  listOAuthCredentials: (resource_server: string) => Promise<void>;
  oauthCredentials: OAuthCredential[];
}

export function useOAuthCredential(): useOAuthCredentialReturnValues {
  const oauthCredentials = useSelector(
    (state: RootState) => state.oauth.oauthCredentials
  );

  const { deleteOAuthCredential, listOAuthCredentials } =
    useOAuthActionCreator();

  return {
    deleteOAuthCredential,
    listOAuthCredentials,
    oauthCredentials,
  };
}
