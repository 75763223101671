import classnames from "classnames";
import _ from "lodash";
import * as React from "react";

import styles from "./styles.module.scss";

function getPageList(totalPages: number, currentPage: number) {
  const displayPages = Math.min(4, totalPages);
  if (currentPage === 1) {
    return _.range(1, displayPages + 1);
  }
  if (currentPage === totalPages) {
    return _.range(Math.max(totalPages + 1 - displayPages, 1), totalPages + 1);
  }
  if (currentPage > 2) {
    return _.range(currentPage - 2, currentPage + displayPages - 2);
  }
  return _.range(currentPage - 1, currentPage + displayPages - 1);
}

interface Props {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  navigateToPage: (page: number) => void;
}

function _Paginator(props: Props) {
  const { totalCount, pageSize, currentPage, navigateToPage } = props;

  const totalPages = React.useMemo(() => {
    return Math.ceil(totalCount / pageSize);
  }, [totalCount, pageSize]);

  const hasNextPage = React.useMemo(() => {
    return currentPage < totalPages;
  }, [currentPage, totalPages]);

  const hasPreviousPage = React.useMemo(() => {
    return currentPage > 1;
  }, [currentPage]);

  const pageList = React.useMemo(() => {
    return getPageList(totalPages, currentPage);
  }, [totalPages, currentPage]);

  const onPreviousPageClick = React.useCallback(() => {
    navigateToPage(currentPage - 1);
  }, [navigateToPage, currentPage]);

  const onNextPageClick = React.useCallback(() => {
    navigateToPage(currentPage + 1);
  }, [navigateToPage, currentPage]);

  const onFirstPageClick = React.useCallback(() => {
    navigateToPage(1);
  }, [navigateToPage]);

  const onLastPageClick = React.useCallback(() => {
    navigateToPage(totalPages);
  }, [navigateToPage, totalPages]);

  if (totalCount <= pageSize) {
    return <div></div>;
  }

  return (
    <div className={styles["paginator-container"]}>
      <span
        onClick={onFirstPageClick}
        className={classnames(styles["page-navigator"], {
          [styles["active"]]: hasPreviousPage,
        })}
      >
        ❮❮
      </span>
      <span
        onClick={onPreviousPageClick}
        className={classnames(styles["page-navigator"], {
          [styles["active"]]: hasPreviousPage,
        })}
      >
        ❮
      </span>
      {pageList.map(pageNumber => {
        return (
          <span
            key={pageNumber}
            onClick={() => navigateToPage(pageNumber)}
            className={classnames(styles["page-index"], {
              [styles["current-page"]]: pageNumber === currentPage,
            })}
          >
            {pageNumber}
          </span>
        );
      })}
      <span
        onClick={onNextPageClick}
        className={classnames(styles["page-navigator"], {
          [styles["active"]]: hasNextPage,
        })}
      >
        ❯
      </span>
      <span
        onClick={onLastPageClick}
        className={classnames(styles["page-navigator"], {
          [styles["active"]]: hasNextPage,
        })}
      >
        ❯❯
      </span>
    </div>
  );
}

export const Paginator = React.memo(_Paginator);
export default Paginator;
