import produce from "immer";
import * as React from "react";
import { useNavigate } from "react-router";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useCustomModelActionCreator } from "../actions/customModel";
import { apiClient } from "../apiClient";
import {
  FSL_CUSTOM_MODEL_IMAGE_MAX_COUNT,
  FSL_GROUPED_CUSTOM_MODEL_IMAGE_PAGE_SIZE,
} from "../constants";
import { useFetchFSLCustomModelImages } from "../hooks/customModelImages";
import { useGtm } from "../hooks/gtm";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { FSLCustomModelSessionResult } from "../models";
import { ConfirmModalType } from "../types/confirmation";
import { CustomModelImage } from "../types/customModelImage";
import { deepEqual } from "../utils/deepEqual";
import {
  DEFAULT_FSL_MODEL_STATE,
  useFSLCustomModelEditor,
} from "./fslCustomModelEditor";

const EMPTY_OBJECT = {};

function useFetchCustomModelImage(
  customModelId: string,
  customModelImageId: string
) {
  const [customModelImage, setCustomModelImage] = React.useState<
    CustomModelImage | undefined
  >();
  const [error, setError] = React.useState<any>();
  const toast = useToast();

  const { listFSLGroupedCustomModelImages } = useCustomModelActionCreator();

  const fslGroupedCustomModelImages = useAppSelector(
    state =>
      state.customModel.paginatedFSLGroupedCustomModelImages ?? EMPTY_OBJECT
    // Return a constant empty object to avoid unnecessary re-rendering
  );

  const fslGroupedCustomModelImagesRef = React.useRef(
    fslGroupedCustomModelImages
  );
  fslGroupedCustomModelImagesRef.current = fslGroupedCustomModelImages;

  const fetchFSLGroupedCustomModelImages = React.useCallback(
    async (customModelId: string, groupId: string) => {
      const pageInfo =
        fslGroupedCustomModelImagesRef.current[groupId]?.pageInfo;

      let hasMore =
        pageInfo !== undefined ? pageInfo.offset < pageInfo.totalCount : true;
      let offset = pageInfo?.offset ?? 0;

      while (hasMore) {
        try {
          const { pageInfo } = await listFSLGroupedCustomModelImages(
            customModelId,
            groupId,
            2 || FSL_GROUPED_CUSTOM_MODEL_IMAGE_PAGE_SIZE,
            offset
          );
          hasMore = pageInfo.offset < pageInfo.totalCount;
          offset = pageInfo.offset;
        } catch (e) {
          toast.error(
            "fsl_instant_model_editor.error.failed_to_fetch_grouped_custom_model_image"
          );
          break;
        }
      }
    },
    [listFSLGroupedCustomModelImages, toast]
  );

  React.useEffect(() => {
    async function fetchCustomModelImage() {
      try {
        const customModelImage = await apiClient.getCustomModelImage(
          customModelImageId,
          true,
          true,
          true
        );

        setCustomModelImage(customModelImage);

        if (customModelImage.info?.group_item_index !== undefined) {
          await fetchFSLGroupedCustomModelImages(
            customModelId,
            customModelImage.info.group_id ?? ""
          );
        }
      } catch (e: any) {
        console.error(e);
        setError(e);
      }
    }
    fetchCustomModelImage();
  }, [customModelId, customModelImageId, fetchFSLGroupedCustomModelImages]);

  const assetFiles = React.useMemo(() => {
    if (customModelImage === undefined) {
      return undefined;
    }
    const groupId = customModelImage.info?.group_id ?? "";

    return fslGroupedCustomModelImages[groupId] !== undefined
      ? fslGroupedCustomModelImages[groupId].images.map(image => image.url)
      : [customModelImage.url];
  }, [fslGroupedCustomModelImages, customModelImage]);

  return React.useMemo(
    () => ({
      customModelImage,
      error,
      fslGroupedCustomModelImages,
      assetFiles,
    }),
    [customModelImage, error, fslGroupedCustomModelImages, assetFiles]
  );
}

function useWaitUntil(value: boolean) {
  const latestValue = React.useRef(value);
  const resolvers = React.useRef<((value: any) => void)[]>([]);

  const waitUntil = React.useCallback(() => {
    if (latestValue.current === true) {
      return Promise.resolve();
    }
    return new Promise(resolve => {
      resolvers.current.push(resolve);
    });
  }, []);

  latestValue.current = value;

  React.useEffect(() => {
    if (value === true) {
      resolvers.current.forEach(resolve => {
        resolve(value);
      });
      resolvers.current = [];
    }
  }, [value]);
  return waitUntil;
}

function useMakeContext(customModelImageId: string) {
  const {
    customModel,
    customModelId,
    navigateToStandardModel,
    updateIsDocumentTooComplex,
    isLoading: isFSLCustoModelEditorLoading,
  } = useFSLCustomModelEditor();

  const { requestUserConfirmation } = useConfirmModalActionCreator();

  const { patchCustomModelImageInfo, updateCustomModelFSLModelState } =
    useCustomModelActionCreator();

  const {
    customModelImage,
    fslGroupedCustomModelImages,
    error: customModelImageError,
    assetFiles,
  } = useFetchCustomModelImage(customModelId, customModelImageId);

  const { customModelImages } = useFetchFSLCustomModelImages(customModel);

  const toast = useToast();

  const navigate = useNavigate();

  const isReadyToUseOnce =
    customModel?.config.fslModelState?.isReadyToUseOnce ?? false;

  const noOfSampleImages = customModelImages ? customModelImages.length : 0;

  const [extractionResult, setExtractionResult] = React.useState<
    FSLCustomModelSessionResult | undefined
  >(undefined);

  const [editingExtractionResult, setEditingExtractionResult] = React.useState<
    FSLCustomModelSessionResult | undefined
  >(undefined);

  const [shouldShowUploadingSampleStatus, setShouldShowUploadingSampleStatus] =
    React.useState(false);

  const [isProcessing, setIsProcessing] = React.useState(false);

  const [isCorrecting, setIsCorrecting] = React.useState<boolean>(false);
  const [isExistingImage, setIsExistingImage] = React.useState<boolean>(true);

  const initializedRef = React.useRef(false);

  React.useEffect(() => {
    if (customModelImage === undefined) {
      setExtractionResult(undefined);
    } else {
      try {
        const extractionResult = JSON.parse(
          customModelImage.info?.fsl_output ?? "{}"
        );
        setExtractionResult(extractionResult);
        setEditingExtractionResult(extractionResult);
      } catch (e) {
        console.error(e);
      }
    }
  }, [customModelImage]);

  React.useEffect(() => {
    if (initializedRef.current === true || customModelImage === undefined) {
      return;
    }
    if (customModelImage.state !== "reviewed") {
      setIsExistingImage(false);
    }
    initializedRef.current = true;
  }, [customModelImage, extractionResult]);

  const fslUploadingSampleImagesState = useAppSelector(
    state => state.customModel.fslUploadingSampleImagesState
  );

  const {
    totalPagesToUpload,
    numberOfUploadedPage,
    numberOfPageFailedToUpload,
  } = React.useMemo(() => {
    const totalPagesToUpload =
      fslUploadingSampleImagesState[customModelId]?.totalPagesToUpload ?? 0;
    const numberOfUploadedPage =
      fslUploadingSampleImagesState[customModelId]?.numberOfUploadedPage ?? 0;
    const numberOfPageFailedToUpload =
      fslUploadingSampleImagesState[customModelId]
        ?.numberOfPageFailedToUpload ?? 0;

    return {
      totalPagesToUpload,
      numberOfUploadedPage,
      numberOfPageFailedToUpload,
    };
  }, [fslUploadingSampleImagesState, customModelId]);

  const numberOfProcessedPage =
    numberOfUploadedPage + numberOfPageFailedToUpload;

  const isUploadingFSLImage =
    totalPagesToUpload > 0 && totalPagesToUpload !== numberOfProcessedPage;

  const goToInstantModelViewer = React.useCallback(() => {
    navigate(`/custom-model/${customModelId}/instant-model`);
  }, [customModelId, navigate]);

  const waitUntilUploaded = useWaitUntil(!isUploadingFSLImage);

  const isLoading =
    isFSLCustoModelEditorLoading ||
    customModelImages === undefined ||
    customModelImage === undefined ||
    assetFiles === undefined;

  const finishUploadingAllSamplesPromiseRef = React.useRef(Promise.resolve());

  const saveExtractionResult = React.useCallback(
    async (
      customModelImageId: string,
      extractionResult: FSLCustomModelSessionResult,
      isExtractionDisabled: boolean = false
    ) => {
      const fsl_output = JSON.stringify(extractionResult);
      setIsProcessing(true);

      await finishUploadingAllSamplesPromiseRef.current;

      try {
        await patchCustomModelImageInfo(customModelImageId, {
          // Edited custom model image become `bad sample` #2552
          fsl_output,
          is_extraction_disabled: isExtractionDisabled,
        });
      } catch (e) {
        toast.error("error.fail_to_save_extractor");
      }
      setIsProcessing(false);
    },
    [patchCustomModelImageInfo, toast]
  );

  const discard = React.useCallback(() => {
    setIsCorrecting(false);
  }, []);

  const requestToDiscard = React.useCallback(async () => {
    if (deepEqual(extractionResult, editingExtractionResult)) {
      discard();
      return;
    }

    const confirmDiscard = await requestUserConfirmation(
      {
        actionId: "fsl_custom_model.extraction_reviewer.discard_update.action",
        titleId: "fsl_custom_model.extraction_reviewer.discard_update.title",
        messageId:
          "fsl_custom_model.extraction_reviewer.discard_update.message",
        type: ConfirmModalType.Normal,
      },
      false
    );

    if (confirmDiscard) {
      discard();
    }
  }, [
    discard,
    requestUserConfirmation,
    extractionResult,
    editingExtractionResult,
  ]);

  const { pushCustomFslReviewedSampleEvent } = useGtm();

  const save = React.useCallback(() => {
    // This is not called in more general component state
    // (e.g. updateCustomModelFSLModelState) because there is no good way
    // to easily tell whether an update is caused by reviewing/correcting etc
    pushCustomFslReviewedSampleEvent(customModelId, true);
    setExtractionResult(editingExtractionResult);
    saveExtractionResult(customModelImageId, editingExtractionResult ?? {});
    goToInstantModelViewer();
  }, [
    saveExtractionResult,
    editingExtractionResult,
    customModelImageId,
    customModelId,
    goToInstantModelViewer,
    pushCustomFslReviewedSampleEvent,
  ]);

  const editExtractionResultMaySetTooComplexFlag = React.useCallback(() => {
    if (noOfSampleImages < FSL_CUSTOM_MODEL_IMAGE_MAX_COUNT) {
      setIsCorrecting(true);
      setEditingExtractionResult(JSON.parse(JSON.stringify(extractionResult)));
      return;
    }

    const run = async () => {
      goToInstantModelViewer();
      await updateIsDocumentTooComplex(true);
      const res = await requestUserConfirmation(
        {
          titleId: "fsl_instant_model.switch_to_standard_model_modal.title",
          messageId: "fsl_instant_model.switch_to_standard_model_modal.message",
          actionId: "fsl_instant_model.switch_to_standard_model_modal.action",
          type: ConfirmModalType.Normal,
        },
        false
      );
      if (res) {
        navigateToStandardModel();
      }
    };
    run();
  }, [
    goToInstantModelViewer,
    navigateToStandardModel,
    requestUserConfirmation,
    noOfSampleImages,
    updateIsDocumentTooComplex,
    setEditingExtractionResult,
    extractionResult,
  ]);

  const editExtractionResult = React.useCallback(() => {
    setIsCorrecting(true);
    setEditingExtractionResult(JSON.parse(JSON.stringify(extractionResult)));
  }, [extractionResult]);

  const updateExtractionResult = React.useCallback(
    (key: string, value: string | string[]) => {
      setEditingExtractionResult(
        produce(editingExtractionResult, draft => {
          if (!draft) {
            return {
              [key]: value,
            };
          }
          draft[key] = value;
          return draft;
        })
      );
    },
    [editingExtractionResult, setEditingExtractionResult]
  );

  const acceptExtractionResult = React.useCallback(async () => {
    if (!customModel) {
      return;
    }

    const fslModelState = {
      ...DEFAULT_FSL_MODEL_STATE,
      ...customModel.config.fslModelState,
      isReadyToUse: true,
      isReadyToUseOnce: true,
    };

    await saveExtractionResult(
      customModelImageId,
      extractionResult ?? {},
      true
    );
    try {
      setIsProcessing(true);
      await updateCustomModelFSLModelState(fslModelState);
      // This is not called in more general component state
      // (e.g. updateCustomModelFSLModelState) because there is no good way
      // to easily tell whether an update is caused by reviewing/correcting etc
      pushCustomFslReviewedSampleEvent(customModelId, false);
      setIsProcessing(false);
      setShouldShowUploadingSampleStatus(true);
      await waitUntilUploaded();
      goToInstantModelViewer();
      toast.success("custom_model_editor.custom_model_is_saved");
    } catch (e) {
      toast.error("error.fail_to_save_extractor");
      setIsProcessing(false);
    }
  }, [
    customModel,
    extractionResult,
    saveExtractionResult,
    customModelImageId,
    updateCustomModelFSLModelState,
    customModelId,
    goToInstantModelViewer,
    toast,
    waitUntilUploaded,
    pushCustomFslReviewedSampleEvent,
  ]);

  return React.useMemo(
    () => ({
      isReadyToUseOnce,
      noOfSampleImages,
      isCorrecting,
      isExistingImage,
      editExtractionResult,
      save,
      isProcessing,
      updateExtractionResult,
      acceptExtractionResult,
      extractionResult,
      goToInstantModelViewer,
      customModelImages,
      isLoading,
      shouldShowUploadingSampleStatus,
      fslGroupedCustomModelImages,
      editingExtractionResult,
      requestToDiscard,
      customModelImageId,
      editExtractionResultMaySetTooComplexFlag,
      customModelImageError,
      assetFiles,
      isUploadingFSLImage,
      numberOfProcessedPage,
      totalPagesToUpload,
    }),
    [
      isReadyToUseOnce,
      noOfSampleImages,
      isCorrecting,
      isExistingImage,
      editExtractionResult,
      save,
      isProcessing,
      updateExtractionResult,
      acceptExtractionResult,
      extractionResult,
      goToInstantModelViewer,
      customModelImages,
      isLoading,
      shouldShowUploadingSampleStatus,
      fslGroupedCustomModelImages,
      editingExtractionResult,
      requestToDiscard,
      customModelImageId,
      editExtractionResultMaySetTooComplexFlag,
      customModelImageError,
      assetFiles,
      isUploadingFSLImage,
      numberOfProcessedPage,
      totalPagesToUpload,
    ]
  );
}

type FSLInstantSampleEditorContextValue = ReturnType<typeof useMakeContext>;
const FSLInstantSampleEditorContext =
  React.createContext<FSLInstantSampleEditorContextValue>(null as any);

interface Props {
  customModelImageId: string;
  children: React.ReactNode;
}

export const FSLInstantSampleEditorProvider = (props: Props) => {
  const value = useMakeContext(props.customModelImageId);
  return <FSLInstantSampleEditorContext.Provider {...props} value={value} />;
};

export function useFSLInstantSampleEditorContainer() {
  return React.useContext(FSLInstantSampleEditorContext);
}
