import {
  Checkbox,
  Dropdown,
  IDropdownOption,
  Icon,
  IconButton,
  Label,
  TooltipHost,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import {
  AUTO_EXTRACTION_ITEMS,
  DEFAULT_AUTO_EXTRACTION_ITEM_MAP,
  DOCUMENT_TYPES,
  RESTRICTED_AUTO_EXTRACTION_ITEMS_FLAGS,
  RESTRICTED_DOCUMENT_TYPES_FLAGS,
  RESTRICTED_GENERIC_AUTO_EXTRACTION_ITEMS_FLAGS,
} from "../../constants";
import { buildFormSettings, useFormEditor } from "../../contexts/formEditor";
import { useLocale } from "../../contexts/locale";
import { useLocalizeAutoExtractionItemName } from "../../hooks/autoExtractionItems";
import { useTeamPermission } from "../../hooks/permission";
import { RootState } from "../../redux/types";
import { DetailedForm, DocumentType } from "../../types/form";
import { AutoExtractionItem } from "../../types/formConfig";
import {
  DateFormatSettingsModal,
  useDateFormatSettingModal,
} from "../DateFormatSettingsModal";
import {
  LLMCompletionSettingsModal,
  useLLMCompletionSettingsModal,
} from "../LLMCompletionSettingsModal";
import MerchantSettingsModal, {
  useMerchantSettingsModal,
} from "../MerchantSettingsModal";
import {
  MerchantSimpleNameListModal,
  useMerchantSimpleNameListModal,
} from "../MerchantSimpleNameListModal";
import styles from "./styles.module.scss";

export function useDocumentTypeState(form: DetailedForm) {
  const { localized } = useLocale();
  const { updateForm } = useFormEditor();

  const isFeatureEnabled = useSelector((state: RootState) =>
    state.resourceOwner.isFeatureEnabled()
  );

  const documentTypeOptions = DOCUMENT_TYPES.filter(x => {
    const flag = RESTRICTED_DOCUMENT_TYPES_FLAGS.get(x);
    return !flag || isFeatureEnabled(flag);
  }).map(x => ({
    key: x,
    text: localized(`document_type.${x}`),
  }));

  const formSettings = buildFormSettings(form);
  const localizeAutoExtractionItemName = useLocalizeAutoExtractionItemName();

  const onDocumentTypeChange = useCallback(
    (
      _event: React.FormEvent<unknown>,
      option?: IDropdownOption,
      _n?: number
    ) => {
      if (option === undefined) {
        return;
      }

      const updatedDocumentType = option.key as DocumentType;

      const formSettings = buildFormSettings(form, {
        documentType: updatedDocumentType,
      });

      formSettings.autoExtractionItems =
        DEFAULT_AUTO_EXTRACTION_ITEM_MAP[updatedDocumentType] ||
        formSettings.autoExtractionItems.filter(x =>
          AUTO_EXTRACTION_ITEMS[updatedDocumentType].includes(x)
        );

      updateForm(formSettings);
    },
    [form, updateForm]
  );

  const autoExtractionItemOptions = useCallback(
    (documentType: DocumentType) => {
      return AUTO_EXTRACTION_ITEMS[documentType]
        .filter(x => {
          let flag: undefined | string = undefined;
          const flags =
            RESTRICTED_AUTO_EXTRACTION_ITEMS_FLAGS.get(documentType);
          if (flags) {
            flag = flags.get(x);
          }
          if (!flag) {
            flag = RESTRICTED_GENERIC_AUTO_EXTRACTION_ITEMS_FLAGS.get(x);
          }
          return !flag || isFeatureEnabled(flag);
        })
        .map((x: AutoExtractionItem) => {
          return {
            key: x,
            label: localizeAutoExtractionItemName(x, form.config),
          };
        });
    },
    [isFeatureEnabled, form.config, localizeAutoExtractionItemName]
  );

  const onAutoExtractionItemChange = useCallback(
    (key: AutoExtractionItem) =>
      (ev?: React.FormEvent<unknown>, checked?: boolean) => {
        if (ev) {
          ev.stopPropagation();
        }

        if (checked === undefined) {
          return;
        }

        const formSettings = buildFormSettings(form);

        if (checked) {
          formSettings.autoExtractionItems =
            formSettings.autoExtractionItems.concat([key]);
        } else {
          formSettings.autoExtractionItems =
            formSettings.autoExtractionItems.filter(x => x !== key);
        }

        updateForm(formSettings);
      },
    [form, updateForm]
  );

  return {
    documentTypeOptions,
    onDocumentTypeChange,
    autoExtractionItemOptions,
    onAutoExtractionItemChange,
    formSettings,
  };
}

interface Props {
  form: DetailedForm;
  isPrebuiltExtractor?: boolean;
}

const DocumentTypeTabPane = React.memo((props: Props) => {
  const { form, isPrebuiltExtractor } = props;
  const { localized } = useLocale();
  const {
    documentTypeOptions,
    onDocumentTypeChange,
    autoExtractionItemOptions,
    onAutoExtractionItemChange,
    formSettings,
  } = useDocumentTypeState(form);

  const { merchantSettingsModalPayload, openMerchantSettingsModal } =
    useMerchantSettingsModal();
  const { dateFormatSettingsModalPayload, openDateFormatSettingsModal } =
    useDateFormatSettingModal();
  const { merchantCustomNameListPayload, openMerchantSimpleNameListModal } =
    useMerchantSimpleNameListModal();

  const { llmCompletionSettingsPayload, openLLMCompletionSettingsModal } =
    useLLMCompletionSettingsModal();

  const { hasPermissionToEditResource } = useTeamPermission();

  const settingOnClickForItem: { [key: string]: () => void } = {
    merchant: openMerchantSettingsModal,
    date: openDateFormatSettingsModal,
    merchant_name: openMerchantSimpleNameListModal,
    llm_completion: openLLMCompletionSettingsModal,
  };

  const infoTooltip = localized(
    "document.tab.auto_extraction_item.title_tooltip"
  );

  return (
    <div
      className={classnames(styles["container"], {
        [styles["prebuilt-extractor"]]: isPrebuiltExtractor,
      })}
    >
      {!isPrebuiltExtractor && (
        <Dropdown
          label={localized("form_inspector.document_type")}
          selectedKey={formSettings.documentType}
          className={styles["input-field"]}
          options={documentTypeOptions}
          onChange={onDocumentTypeChange}
          placeholder={localized("dropdown.placeholder")}
          disabled={!hasPermissionToEditResource}
        />
      )}

      <div className={styles["auto-extraction-items"]}>
        <div className={styles["auto-extraction-items-label"]}>
          <Label>
            <FormattedMessage id="document.tab.auto_extraction_item.title" />
          </Label>
          <TooltipHost
            content={infoTooltip}
            calloutProps={{
              isBeakVisible: true,
            }}
          >
            <Icon iconName="Info" />
          </TooltipHost>
        </div>
        <div className={styles["items"]}>
          {autoExtractionItemOptions(formSettings.documentType).map(item => (
            <div className={styles["row"]} key={item.key}>
              <Checkbox
                className={styles["check-box"]}
                label={item.label}
                checked={formSettings.autoExtractionItems.includes(item.key)}
                onChange={onAutoExtractionItemChange(item.key)}
                disabled={!hasPermissionToEditResource}
              />
              {Object.keys(settingOnClickForItem).includes(item.key) && (
                <IconButton
                  className={styles["setting"]}
                  iconProps={{ iconName: "Settings" }}
                  onClick={settingOnClickForItem[item.key]}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <MerchantSettingsModal {...merchantSettingsModalPayload} />
      <DateFormatSettingsModal {...dateFormatSettingsModalPayload} />
      <MerchantSimpleNameListModal {...merchantCustomNameListPayload} />
      <LLMCompletionSettingsModal {...llmCompletionSettingsPayload} />
    </div>
  );
});

export default DocumentTypeTabPane;
