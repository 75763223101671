import * as yup from "yup";

export type TemplateType = "form" | "form_group";

export const templateMetadataSchema = yup
  .object({
    formTranslationIdMap: yup.mixed<Record<string, string>>().required(),
  })
  .camelCase();

export type TemplateMetadata = yup.InferType<typeof templateMetadataSchema>;

export const templateSchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    type: yup.mixed<TemplateType>().required(),
    version: yup.string().required(),
    formId: yup.string().defined().nullable(),
    formGroupId: yup.string().defined().nullable(),
    templateMetadata: templateMetadataSchema.required(),
  })
  .camelCase();

export type Template = yup.InferType<typeof templateSchema>;

export const AvailableTemplates = [
  "business_registration",
  // "travel_agency_chinese",
  // "travel_agency_english",
  "food_license",
  "hkid",
  "twid",
  "moid",
  "sgid",
  "passport",
];

export type AvailableTemplateType = (typeof AvailableTemplates)[number];
