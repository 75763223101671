import { Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { AuditLogJob, AuditLogJobStatusType } from "../../types/auditLog";
import styles from "./styles.module.scss";

interface Props {
  auditLogJobs: AuditLogJob[];
  currentYear: number;
  currentMonth: number;
}

const AuditLogJobTable = React.memo((props: Props) => {
  const { localized } = useLocale();
  const { auditLogJobs, currentYear, currentMonth } = props;

  return (
    <>
      {auditLogJobs.length !== 0 && (
        <div className={styles["audit-log-job-table"]}>
          <div className={styles["table-header"]}>
            <Text as="p">
              <FormattedMessage id="audit.table.header" />
            </Text>
          </div>
          {auditLogJobs.map((job: AuditLogJob, index) => {
            return (
              <div key={index} className={styles["available-csv"]}>
                <Text as="p" className={styles["csv-date"]}>
                  {job.year}-{job.month}
                  {job.year === currentYear &&
                    job.month === currentMonth + 1 &&
                    localized("audit.table.incomplete")}
                </Text>
                {job.status === AuditLogJobStatusType.completed ? (
                  <a
                    href={job.url as string}
                    className={styles["csv-download"]}
                  >
                    {localized("audit.download")}
                  </a>
                ) : (
                  <Text as="p" className={styles["generating"]}>
                    {localized("audit.download.generating")}
                  </Text>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
});

export default AuditLogJobTable;
