import { ITextField } from "@fluentui/react";
import { produce } from "immer";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as uuid from "uuid";

import { MerchantSettingsMapper } from "../modelMapper";
import {
  MerchantSetting,
  MerchantSettingInfoPresetType,
} from "../types/merchantSetting";
import { useFormEditor } from "./formEditor";

interface Props {
  children: React.ReactNode;
}

export enum SortingOrderType {
  SortByNone = "SortByNone",
  SortByAlphabet = "SortByAlphabet",
  SortByAlphabetReversed = "SortByAlphabetReversed",
  SortByOldestToNewest = "SortByOldestToNewest",
  SortByNewestToOldest = "SortByNewestToOldest",
}

export enum ErrorType {
  FillInAtLeast1Info = "FillInAtLeast1Info",
  MerchantNameMissing = "MerchantNameMissing",
}

export interface ErrorTable {
  [key: string]: ErrorType[];
}

function getTimestamp() {
  return new Date().getTime();
}

const presetFieldTranslateIdTable = {
  [MerchantSettingInfoPresetType.BrandName]:
    "merchant_settings_modal.field.brand_name",
  [MerchantSettingInfoPresetType.PhoneNumber]:
    "merchant_settings_modal.field.phone_number",
  [MerchantSettingInfoPresetType.FaxNumber]:
    "merchant_settings_modal.field.fax_number",
  [MerchantSettingInfoPresetType.Url]: "merchant_settings_modal.field.url",
} as any;

const errorTranslateIdTable = {
  [ErrorType.MerchantNameMissing]:
    "merchant_settings_modal.error.merchant_missing",
  [ErrorType.FillInAtLeast1Info]:
    "merchant_settings_modal.error.fill_at_least_one_info",
} as any;

function useMerchantNameListScrollHandler() {
  const [merchantNameListScrollIndex, setMerchantNameListScrollIndex] =
    useState<number>(0);

  const [forceUpdateKey, setForceUpdateKey] = useState<number>(0);

  const merchantNameListRootRef = useRef<HTMLDivElement>(null);

  const updateMerchantNameListScrollPosition = useCallback(() => {
    if (!merchantNameListRootRef.current) {
      return;
    }

    const childs = Array.prototype.slice.call(
      merchantNameListRootRef.current.querySelectorAll(
        ".merchant-name-list-item"
      )
    );

    const heights = childs.map(child => child.getBoundingClientRect().height);

    const offset = heights
      .slice(0, merchantNameListScrollIndex)
      .reduce((sum, v) => sum + v, 0);

    const scrollablePane = merchantNameListRootRef.current.querySelector(
      ".ms-ScrollablePane--contentContainer"
    );

    if (!scrollablePane) {
      return;
    }
    const scrollTop = scrollablePane.scrollTop;
    const containerHeight = scrollablePane.getBoundingClientRect().height;
    const unitHeight = heights[merchantNameListScrollIndex];

    if (offset < scrollTop) {
      scrollablePane.scrollTop = offset;
    }

    const itemBottomLine = offset + unitHeight;
    const scrollPaneBottomLine = scrollTop + containerHeight;

    if (itemBottomLine > scrollPaneBottomLine) {
      scrollablePane.scrollTop =
        scrollTop - scrollPaneBottomLine + itemBottomLine;
    }
  }, [merchantNameListScrollIndex]);

  useEffect(updateMerchantNameListScrollPosition, [
    updateMerchantNameListScrollPosition,
    merchantNameListScrollIndex,
    forceUpdateKey,
  ]);

  const forceUpdateMerchantNameListScroll = useCallback(() => {
    setForceUpdateKey(forceUpdateKey + 1);
  }, [forceUpdateKey]);

  return useMemo(
    () => ({
      merchantNameListScrollIndex,
      setMerchantNameListScrollIndex,
      merchantNameListRootRef,
      forceUpdateMerchantNameListScroll,
    }),
    [
      merchantNameListScrollIndex,
      setMerchantNameListScrollIndex,
      merchantNameListRootRef,
      forceUpdateMerchantNameListScroll,
    ]
  );
}

function useMerchantNameListFocusHandler() {
  const merchantNameListFocusAreaRef = useRef<HTMLDivElement>(null);

  const focusMerchantNameList = useCallback(() => {
    if (merchantNameListFocusAreaRef.current) {
      merchantNameListFocusAreaRef.current.focus();
    }
  }, []);

  return useMemo(
    () => ({
      merchantNameListFocusAreaRef,
      focusMerchantNameList,
    }),
    [merchantNameListFocusAreaRef, focusMerchantNameList]
  );
}

function useMakeContext() {
  const { form, updateCustomMerchants } = useFormEditor();

  const merchantNameListScrollHanlder = useMerchantNameListScrollHandler();
  const { setMerchantNameListScrollIndex, forceUpdateMerchantNameListScroll } =
    merchantNameListScrollHanlder;

  const merchantNameListFocusHandler = useMerchantNameListFocusHandler();

  const [rawCustomMerchants, setRawCustomMerchants] = useState<
    MerchantSetting[]
  >(
    form && form.config.custom_merchants
      ? MerchantSettingsMapper.fromResp(form.config.custom_merchants)
      : []
  );

  const [customMerchants, setCustomMerchants] =
    useState<MerchantSetting[]>(rawCustomMerchants);

  const [merchantSortingOrder, _setMerchantSortingOrder] =
    useState<SortingOrderType>(SortingOrderType.SortByNone);
  const [merchantFilter, _setMerchantFilter] = useState<string>("");

  const [merchantErrors, setMerchantErrors] = useState<ErrorTable>({});

  const [selectedMerchantId, setSelectedMerchantId] = useState<string>("");
  const [selectedMerchant, setSelectedMerchant] = useState<
    MerchantSetting | undefined
  >();

  const merchanFormNameTextFieldRef = useRef<ITextField>(null);

  const _selectMerchant = useCallback(
    (rawCustomMerchants: MerchantSetting[], id: string) => {
      if (id === "") {
        setSelectedMerchantId(id);
        setSelectedMerchant(undefined);
        return;
      }
      const index = rawCustomMerchants.findIndex(
        merchant => merchant.id === id
      );
      if (index < 0) {
        return;
      }
      setSelectedMerchantId(id);
      setSelectedMerchant(rawCustomMerchants[index]);
    },
    []
  );

  const refreshCustomMerchants = useCallback(
    (pattern: string, sortingOrder: SortingOrderType) => {
      let res = rawCustomMerchants;

      const filter = pattern.toLocaleLowerCase().trim();
      if (filter !== "") {
        res = res.filter(item => {
          const anyInfoMatched = item.infos.some(info => {
            return info.pattern.toLocaleLowerCase().indexOf(filter) >= 0;
          });
          return (
            anyInfoMatched || item.name.toLocaleLowerCase().indexOf(filter) >= 0
          );
        });
      }

      switch (sortingOrder) {
        case SortingOrderType.SortByAlphabet:
          res = [...res];
          res.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          break;
        case SortingOrderType.SortByAlphabetReversed:
          res = [...res];
          res.sort((a, b) => {
            return b.name.localeCompare(a.name);
          });
          break;
        case SortingOrderType.SortByNewestToOldest:
          res = [...res];
          res.sort((a, b) => {
            return b.updatedAt - a.updatedAt;
          });
          break;
        case SortingOrderType.SortByOldestToNewest:
          res = [...res];
          res.sort((a, b) => {
            return a.updatedAt - b.updatedAt;
          });
          break;
      }

      if (
        res.length > 0 &&
        (selectedMerchantId === "" ||
          res.findIndex(item => item.id === selectedMerchantId) < 0)
      ) {
        _selectMerchant(rawCustomMerchants, res[0].id);
      }

      if (res.length === 0 && selectedMerchantId !== "") {
        _selectMerchant(rawCustomMerchants, "");
      }

      setCustomMerchants(res);
      return res;
    },
    [rawCustomMerchants, _selectMerchant, selectedMerchantId]
  );

  const _updateMerchantInAllCustomMerchants = useCallback(
    (newMerchant: MerchantSetting) => {
      const update = (draft: any) => {
        const index = draft.findIndex(
          (merchant: any) => merchant.id === newMerchant.id
        );
        if (index < 0) {
          return;
        }
        draft[index] = newMerchant;
      };

      const newRawCustomMerchants = produce(rawCustomMerchants, update);
      const newCustomMerchants = produce(customMerchants, update);

      setRawCustomMerchants(newRawCustomMerchants);

      setCustomMerchants(newCustomMerchants);

      return {
        newRawCustomMerchants,
        newCustomMerchants,
      };
    },
    [customMerchants, rawCustomMerchants]
  );

  const _validateMerchants = useCallback(
    (rawCustomMerchants: MerchantSetting[]) => {
      const errors: ErrorTable = {};

      rawCustomMerchants.forEach(merchant => {
        const list = [];

        if (merchant.name.trim() === "") {
          list.push(ErrorType.MerchantNameMissing);
        }

        const anyFieldNonEmpty = merchant.infos.some(info => {
          return info.pattern.trim() !== "";
        });

        if (!anyFieldNonEmpty) {
          list.push(ErrorType.FillInAtLeast1Info);
        }

        if (list.length > 0) {
          errors[merchant.id] = list;
        }
      });

      if (JSON.stringify(merchantErrors) !== JSON.stringify(errors)) {
        setMerchantErrors(errors);
      }

      return errors;
    },
    [merchantErrors]
  );

  const _updateMerchantNameListScrollIndexToFirstError = useCallback(
    (customMerchants: MerchantSetting[]) => {
      if (Object.keys(merchantErrors).length === 0) {
        return;
      }
      const firstErrorIndex = customMerchants.findIndex(merchant =>
        merchantErrors.hasOwnProperty(merchant.id)
      );
      if (firstErrorIndex < 0) {
        return;
      }
      setMerchantNameListScrollIndex(firstErrorIndex);
      const merchant = customMerchants[firstErrorIndex];
      setSelectedMerchantId(merchant.id);
      setSelectedMerchant(merchant);
    },
    [setMerchantNameListScrollIndex, merchantErrors]
  );

  const _selectFirstMerchantOnce = useCallback(() => {
    if (rawCustomMerchants.length > 0) {
      _selectMerchant(rawCustomMerchants, rawCustomMerchants[0].id);
    }
  }, [rawCustomMerchants, _selectMerchant]);

  useEffect(_selectFirstMerchantOnce, []); //eslint-disable-line react-hooks/exhaustive-deps

  const setMerchantSortingOrder = useCallback(
    (sortingOrder: SortingOrderType) => {
      _setMerchantSortingOrder(sortingOrder);
      return refreshCustomMerchants(merchantFilter, sortingOrder);
    },
    [merchantFilter, refreshCustomMerchants]
  );

  const setMerchantFilter = useCallback(
    (filter: string) => {
      _setMerchantFilter(filter);
      return refreshCustomMerchants(filter, merchantSortingOrder);
    },
    [merchantSortingOrder, refreshCustomMerchants]
  );

  const addMerchant = useCallback(() => {
    const infos = (Object.entries(MerchantSettingInfoPresetType) as any).map(
      (entry: any) => ({
        type: entry[1],
        pattern: "",
        isExactMatchOnly: false,
        isPreset: true,
      })
    );

    const newMerchant: MerchantSetting = {
      id: uuid.v4(),
      name: "",
      matchMode: "best",
      updatedAt: new Date().getTime(),
      infos,
    };

    const update = (draft: any) => {
      draft.unshift(newMerchant);
    };

    const newRawCustomMerchants = produce(rawCustomMerchants, update);

    setRawCustomMerchants(newRawCustomMerchants);

    const newCustomMerchants = produce(customMerchants, update);
    setCustomMerchants(newCustomMerchants);

    _selectMerchant(newRawCustomMerchants, newMerchant.id);

    setMerchantNameListScrollIndex(0);
    forceUpdateMerchantNameListScroll();
  }, [
    rawCustomMerchants,
    _selectMerchant,
    customMerchants,
    setMerchantNameListScrollIndex,
    forceUpdateMerchantNameListScroll,
  ]);

  const removeSelectedMerchant = useCallback(() => {
    if (selectedMerchant === undefined) {
      return;
    }

    const update = (draft: any) => {
      return draft.filter((item: any) => item.id !== selectedMerchant.id);
    };

    const newRawCustomMerchants = produce(rawCustomMerchants, update);
    if (newRawCustomMerchants.length === rawCustomMerchants.length) {
      return;
    }

    const newCustomMerchants = produce(customMerchants, update);

    setRawCustomMerchants(newRawCustomMerchants);
    setCustomMerchants(newCustomMerchants);

    if (newCustomMerchants.length === 0) {
      setSelectedMerchantId("");
      setSelectedMerchant(undefined);
      return;
    }

    const index = customMerchants.findIndex(
      item => item.id === selectedMerchant.id
    );

    const newSelectedIndex =
      index >= newCustomMerchants.length
        ? newCustomMerchants.length - 1
        : index;

    const newSelectedMerchant = newCustomMerchants[newSelectedIndex];
    setSelectedMerchant(newSelectedMerchant);
    setSelectedMerchantId(newSelectedMerchant.id);

    if (Object.keys(merchantErrors).length > 0) {
      _validateMerchants(newRawCustomMerchants);
    }
  }, [
    rawCustomMerchants,
    selectedMerchant,
    merchantErrors,
    _validateMerchants,
    customMerchants,
  ]);

  const setSelectedMerchantName = useCallback(
    (name: string) => {
      if (selectedMerchant === undefined) {
        return;
      }

      const newMerchant = produce(selectedMerchant, draft => {
        draft.name = name;
        draft.updatedAt = getTimestamp();
      });

      const { newRawCustomMerchants } =
        _updateMerchantInAllCustomMerchants(newMerchant);
      setSelectedMerchant(newMerchant);

      if (Object.keys(merchantErrors).length > 0) {
        _validateMerchants(newRawCustomMerchants);
      }
    },
    [
      merchantErrors,
      selectedMerchant,
      _validateMerchants,
      _updateMerchantInAllCustomMerchants,
    ]
  );

  const setSelectedMerchantMatchMode = useCallback(
    (mode: "best" | "all") => {
      if (selectedMerchant === undefined) {
        return;
      }

      const newMerchant = produce(selectedMerchant, draft => {
        draft.matchMode = mode;
        draft.updatedAt = getTimestamp();
      });

      _updateMerchantInAllCustomMerchants(newMerchant);
      setSelectedMerchant(newMerchant);
    },
    [selectedMerchant, _updateMerchantInAllCustomMerchants]
  );

  const appendSelectedMerchantInfo = useCallback(() => {
    if (selectedMerchant === undefined) {
      return;
    }

    const newMerchant = produce(selectedMerchant, draft => {
      const index = draft.infos.findIndex(info => info.isPreset === false);
      const newInfo = {
        type: "",
        pattern: "",
        isExactMatchOnly: false,
        isPreset: false,
      };
      if (index < 0) {
        draft.infos.push(newInfo);
      } else {
        draft.infos.splice(index, 0, newInfo);
      }
      draft.updatedAt = getTimestamp();
    });

    _updateMerchantInAllCustomMerchants(newMerchant);
    setSelectedMerchant(newMerchant);
  }, [selectedMerchant, _updateMerchantInAllCustomMerchants]);

  const removeSelectedMerchantInfo = useCallback(
    (position: number) => {
      if (selectedMerchant === undefined) {
        return;
      }

      const newMerchant = produce(selectedMerchant, draft => {
        draft.infos.splice(position, 1);
        draft.updatedAt = getTimestamp();
      });

      _updateMerchantInAllCustomMerchants(newMerchant);
      setSelectedMerchant(newMerchant);
    },
    [selectedMerchant, _updateMerchantInAllCustomMerchants]
  );

  const setSelectedMerchantInfo = useCallback(
    (
      position: number,
      fields: {
        type?: string;
        pattern?: string;
        isExactMatchOnly?: boolean;
      }
    ) => {
      if (selectedMerchant === undefined) {
        return;
      }
      const newMerchant = produce(selectedMerchant, draft => {
        const info = draft.infos[position];
        if (fields.hasOwnProperty("type") && fields.type !== undefined) {
          info.type = fields.type;
        }

        if (fields.hasOwnProperty("pattern") && fields.pattern !== undefined) {
          info.pattern = fields.pattern;
        }

        if (
          fields.hasOwnProperty("isExactMatchOnly") &&
          fields.isExactMatchOnly !== undefined
        ) {
          info.isExactMatchOnly = fields.isExactMatchOnly;
        }
        draft.updatedAt = getTimestamp();
      });

      const { newRawCustomMerchants } =
        _updateMerchantInAllCustomMerchants(newMerchant);
      setSelectedMerchant(newMerchant);

      if (Object.keys(merchantErrors).length > 0) {
        _validateMerchants(newRawCustomMerchants);
      }
    },
    [
      merchantErrors,
      selectedMerchant,
      _validateMerchants,
      _updateMerchantInAllCustomMerchants,
    ]
  );

  const saveMerchantsToStore = useCallback(() => {
    const errors = _validateMerchants(rawCustomMerchants);
    if (Object.keys(errors).length > 0) {
      const newCustomMerchants = setMerchantFilter("");
      _updateMerchantNameListScrollIndexToFirstError(newCustomMerchants);
      forceUpdateMerchantNameListScroll();
      return false;
    }
    updateCustomMerchants(rawCustomMerchants);
    return true;
  }, [
    rawCustomMerchants,
    updateCustomMerchants,
    _validateMerchants,
    setMerchantFilter,
    forceUpdateMerchantNameListScroll,
    _updateMerchantNameListScrollIndexToFirstError,
  ]);

  const validateMerchants = useCallback(() => {
    _validateMerchants(rawCustomMerchants);
  }, [rawCustomMerchants, _validateMerchants]);

  const selectMerchant = useCallback(
    (id: string) => {
      _selectMerchant(rawCustomMerchants, id);
    },
    [rawCustomMerchants, _selectMerchant]
  );

  const moveSelectedMerchantByKeyEvent = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Tab") {
        return;
      }
      event.stopPropagation();
      event.preventDefault();
      const moveKeys = ["ArrowUp", "ArrowDown"];

      if (selectedMerchant === undefined) {
        return;
      }

      if (moveKeys.includes(event.key)) {
        let index = customMerchants.findIndex(
          merchant => merchant.id === selectedMerchant.id
        );
        if (index < 0) {
          return;
        }

        if (event.key === "ArrowUp") {
          index = Math.max(0, index - 1);
        } else {
          index = Math.min(index + 1, customMerchants.length - 1);
        }

        const newSelectedMerchant = customMerchants[index];

        setSelectedMerchantId(newSelectedMerchant.id);
        setSelectedMerchant(newSelectedMerchant);
        setMerchantNameListScrollIndex(index);
        forceUpdateMerchantNameListScroll();
      } else if (event.key === "ArrowRight") {
        if (merchanFormNameTextFieldRef.current) {
          merchanFormNameTextFieldRef.current.focus();
        }
      }
    },
    [
      customMerchants,
      forceUpdateMerchantNameListScroll,
      selectedMerchant,
      setMerchantNameListScrollIndex,
    ]
  );

  const getPresetFieldTranslateId = useCallback((type: string) => {
    return presetFieldTranslateIdTable.hasOwnProperty(type)
      ? presetFieldTranslateIdTable[type]
      : type;
  }, []);

  const getErrorTranslateId = useCallback((type: string) => {
    return errorTranslateIdTable.hasOwnProperty(type)
      ? errorTranslateIdTable[type]
      : type;
  }, []);

  return useMemo(
    () => ({
      customMerchants,
      rawCustomMerchants,
      addMerchant,
      removeSelectedMerchant,
      removeSelectedMerchantInfo,
      setSelectedMerchantName,
      setSelectedMerchantMatchMode,
      setMerchantSortingOrder,
      merchantSortingOrder,
      merchantFilter,
      setMerchantFilter,
      appendSelectedMerchantInfo,
      setSelectedMerchantInfo,
      saveMerchantsToStore,
      validateMerchants,
      merchantErrors,
      selectMerchant,
      selectedMerchant,
      selectedMerchantId,
      getPresetFieldTranslateId,
      getErrorTranslateId,
      moveSelectedMerchantByKeyEvent,
      merchanFormNameTextFieldRef,
      ...merchantNameListScrollHanlder,
      ...merchantNameListFocusHandler,
    }),
    [
      customMerchants,
      rawCustomMerchants,
      addMerchant,
      removeSelectedMerchant,
      setSelectedMerchantName,
      setSelectedMerchantMatchMode,
      merchantSortingOrder,
      setMerchantSortingOrder,
      removeSelectedMerchantInfo,
      merchantFilter,
      setMerchantFilter,
      appendSelectedMerchantInfo,
      setSelectedMerchantInfo,
      saveMerchantsToStore,
      validateMerchants,
      merchantErrors,
      selectMerchant,
      selectedMerchant,
      selectedMerchantId,
      getPresetFieldTranslateId,
      getErrorTranslateId,
      moveSelectedMerchantByKeyEvent,
      merchanFormNameTextFieldRef,
      merchantNameListScrollHanlder,
      merchantNameListFocusHandler,
    ]
  );
}

type MerchantSettingsContextValue = ReturnType<typeof useMakeContext>;
const MerchantSettingsContext = createContext<MerchantSettingsContextValue>(
  null as any
);

export const MerchantSettingsProvider = (props: Props) => {
  const value = useMakeContext();
  return <MerchantSettingsContext.Provider {...props} value={value} />;
};

export function useMerchantSettings() {
  return useContext(MerchantSettingsContext);
}
