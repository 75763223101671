import * as yup from "yup";

import { Language } from "../types/formConfig";
import { OCRConfig } from "../types/ocrConfig";
import { boundingBoxSchema } from "./boundingBox";

export const fieldSchema = yup.object({
  id: yup.string().defined(),
  label: yup.string().defined(),
  bbox: boundingBoxSchema.defined(),
  languages: yup
    .array(yup.mixed((_input): _input is Language => true).defined())
    .defined(),
  ocr_config: yup.mixed((_input): _input is OCRConfig => true).defined(),
});

export type Field = yup.InferType<typeof fieldSchema>;
