import { DateTime } from "luxon";
import * as yup from "yup";

import { UserFeatureFlag } from "../../constants";
import {
  ResourceOwnerSetting,
  resourceOwnerSettingSchema,
} from "../../types/resourceOwner";
import {
  PaymentMethod,
  SubscriptionData,
  UserSetting,
  paymentMethodSchema,
  subscriptionDataSchema,
} from "../../types/user";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface ResourceOwnerApiClient {
  updateResourceOwnerSetting: (
    setting: ResourceOwnerSetting,
    shouldIgnoreConflict: boolean,
    resourceOwnerId?: string
  ) => Promise<UserSetting>;
  subscribePlan: (planId: string, resourceOwnerId?: string) => Promise<string>;
  unsubscribe: (resourceOwnerId?: string) => Promise<void>;
  setPaymentMethod: (
    stripePaymentMethodId?: string,
    billingEmail?: string,
    resourceOwnerId?: string
  ) => Promise<PaymentMethod>;
  isPaymentRequired: (resourceOwnerId?: string) => Promise<boolean>;
  getSubscriptionData: (resourceOwnerId?: string) => Promise<SubscriptionData>;
  getSetting: (resourceOwnerId?: string) => Promise<ResourceOwnerSetting>;
  setTrial: (
    quota?: number,
    endAt?: Date,
    resourceOwnerId?: string,
    region?: string
  ) => Promise<void>;
  clearTrial: (resourceOwnerId?: string, region?: string) => Promise<void>;
  setEnterprise: (
    quota?: number,
    endAt?: Date,
    resourceOwnerId?: string,
    region?: string
  ) => Promise<void>;
  clearEnterprise: (resourceOwnerId?: string, region?: string) => Promise<void>;
  setTeamEnabledFeatures: (
    features: UserFeatureFlag[],
    enabledAuditLog: boolean,
    resourceOwnerId?: string,
    region?: string
  ) => Promise<void>;
  getReadmeIOAuthToken: (
    resourceOwnerId: string,
    region: string,
    apiEndpoint: string,
    workerEndpoint: string,
    formId?: string,
    workspaceId?: string
  ) => Promise<string>;
}

export function withResourceOwnerApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async updateResourceOwnerSetting(
      setting: UserSetting,
      shouldIgnoreConflict: boolean,
      resourceOwnerId?: string
    ): Promise<UserSetting> {
      const args = this.injectOptionalFields(
        {
          setting: this.injectOptionalFields(
            {},
            {
              google_service_account_key: setting.googleServiceAccountKey,
              azure_endpoint: setting.azureEndpoint,
              azure_subscription_key: setting.azureSubscriptionKey,
            }
          ),
        },
        {
          resource_owner_id: resourceOwnerId,
          retrieved_at: shouldIgnoreConflict ? undefined : setting.updatedAt,
        }
      );

      return await this.lambda(
        "resource_owner:update-setting",
        args,
        resourceOwnerSettingSchema
      );
    }

    subscribePlan(planId: string, resourceOwnerId?: string): Promise<string> {
      return this.lambda(
        "resource_owner:subscribe-plan",
        this.injectOptionalFields(
          {
            plan_id: planId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        yup.string().required()
      );
    }

    async unsubscribe(resourceOwnerId?: string): Promise<void> {
      await this.lambda(
        "resource_owner:unsubscribe",
        this.injectOptionalFields({}, { resource_owner_id: resourceOwnerId })
      );
    }

    setPaymentMethod(
      stripePaymentMethodId?: string,
      billingEmail?: string,
      resourceOwnerId?: string
    ): Promise<PaymentMethod> {
      return this.lambda(
        "resource_owner:set-payment-method",
        this.injectOptionalFields(
          {},
          {
            stripe_payment_method_id: stripePaymentMethodId,
            billing_email: billingEmail,
            resource_owner_id: resourceOwnerId,
          }
        ),
        paymentMethodSchema,
        null
      );
    }

    isPaymentRequired(resourceOwnerId?: string): Promise<boolean> {
      return this.lambda(
        "resource_owner:is-payment-required",
        this.injectOptionalFields({}, { resource_owner_id: resourceOwnerId }),
        yup.boolean().required()
      );
    }

    getSubscriptionData(resourceOwnerId?: string): Promise<SubscriptionData> {
      return this.lambda(
        "resource_owner:get-subscription-data",
        this.injectOptionalFields(
          {},
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        subscriptionDataSchema.required()
      );
    }

    getSetting(resourceOwnerId?: string): Promise<ResourceOwnerSetting> {
      return this.lambda(
        "resource_owner:get-setting",
        this.injectOptionalFields(
          {},
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        resourceOwnerSettingSchema.required()
      );
    }

    setTrial(
      quota?: number,
      endAt?: Date,
      resourceOwnerId?: string,
      region?: string
    ): Promise<void> {
      return this.lambda(
        "resource_owner:set-trial",
        this.injectOptionalFields(
          {},
          {
            resource_owner_id: resourceOwnerId,
            quota,
            trial_end_at: endAt
              ? DateTime.fromJSDate(endAt).toISODate()
              : undefined,
          }
        ),
        undefined,
        null,
        region ? { region } : undefined
      );
    }

    clearTrial(resourceOwnerId?: string, region?: string): Promise<void> {
      return this.lambda(
        "resource_owner:clear-trial",
        this.injectOptionalFields({}, { resource_owner_id: resourceOwnerId }),
        undefined,
        null,
        region ? { region } : undefined
      );
    }

    setEnterprise(
      quota?: number,
      endAt?: Date,
      resourceOwnerId?: string,
      region?: string
    ): Promise<void> {
      return this.lambda(
        "resource_owner:set-enterprise",
        this.injectOptionalFields(
          {},
          {
            resource_owner_id: resourceOwnerId,
            quota,
            enterprise_end_at: endAt
              ? DateTime.fromJSDate(endAt).toISODate()
              : undefined,
          }
        ),
        undefined,
        null,
        region ? { region } : undefined
      );
    }

    clearEnterprise(resourceOwnerId?: string, region?: string): Promise<void> {
      return this.lambda(
        "resource_owner:clear-enterprise",
        this.injectOptionalFields({}, { resource_owner_id: resourceOwnerId }),
        undefined,
        null,
        region ? { region } : undefined
      );
    }

    setTeamEnabledFeatures(
      features: UserFeatureFlag[],
      enabledAuditLog: boolean,
      resourceOwnerId?: string,
      region?: string
    ): Promise<void> {
      return this.lambda(
        "resource_owner:set-enabled-features",
        this.injectOptionalFields(
          {},
          {
            resource_owner_id: resourceOwnerId,
            enabled_features: features,
            enabled_audit_log: enabledAuditLog,
          }
        ),
        undefined,
        null,
        region ? { region } : undefined
      );
    }

    getReadmeIOAuthToken(
      resourceOwnerId: string,
      region: string,
      apiEndpoint: string,
      workerEndpoint: string,
      formId?: string,
      workspaceId?: string
    ): Promise<string> {
      return this.lambda(
        "resource_owner:get-readme-io-auth-token",
        this.injectOptionalFields(
          {
            resource_owner_id: resourceOwnerId,
            region: region,
            api_endpoint: apiEndpoint,
            worker_endpoint: workerEndpoint,
          },
          {
            form_id: formId,
            workspace_id: workspaceId,
          }
        ),
        yup.string().required()
      );
    }
  };
}
