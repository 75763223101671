import { Checkbox, Spinner, SpinnerSize } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import * as React from "react";

import { Badge, BadgeType } from "../Badge";
import { Img } from "../Img";
import { ActionButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface SelectableCardProps {
  width: number;
  height: number;
  children: React.ReactNode;
  isSelected?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  className?: string;
}

export function SelectableCard(props: SelectableCardProps) {
  const { children, width, height, onClick } = props;
  const isSelected = props.isSelected ?? false;
  const isDisabled = props.isDisabled ?? false;

  const className = cn(
    styles["selectable-card"],
    {
      [styles["selectable-card-selected"]]: isSelected,
    },
    props.className
  );

  const _onClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick?.();
    },
    [onClick]
  );

  return (
    <div
      className={className}
      style={{
        width,
        height,
        maxWidth: width,
        maxHeight: height,
      }}
      onClick={_onClick}
      {...(isDisabled ? { disabled: true } : {})}
    >
      {children}
    </div>
  );
}

interface SampleImageSelectableCardProps {
  image?: string;
  actionLabel?: string;
  isSelected?: boolean;
  filename?: string;
  uploadedAt?: Date;
  isCorrected?: boolean;
  badgeLabel?: string;
  isChecked?: boolean;
  onClick?: () => void;
  onActionClick?: () => void;
  onCheckboxChange?: (value: boolean) => void;
  isDisabled?: boolean;
  isDeleting?: boolean;
}

export function SampleImageSelectableCard(
  props: SampleImageSelectableCardProps
) {
  const {
    actionLabel,
    isSelected,
    image,
    isCorrected,
    filename,
    uploadedAt,
    onActionClick,
    onCheckboxChange,
    onClick,
  } = props;

  const badgeLabel = props.badgeLabel ?? "corrected";

  const isDisabled = props.isDisabled ?? false;
  const isChecked = props.isChecked ?? false;
  const isDeleting = props.isDeleting ?? false;

  const _onCheckboxChange = React.useCallback(
    (
      _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
      isChecked?: boolean
    ) => {
      onCheckboxChange?.(isChecked ?? false);
    },
    [onCheckboxChange]
  );

  const selectableCardStyle = cn({
    [styles["selectable-card-is-deleting"]]: isDeleting,
  });

  return (
    <div className={styles["sample-image-selectable-card"]}>
      <SelectableCard
        width={260}
        height={386}
        isSelected={isSelected}
        isDisabled={isDisabled || isDeleting}
        onClick={onClick}
        className={selectableCardStyle}
      >
        {image === undefined && actionLabel !== undefined && (
          <div className={styles["action-button-card"]}>
            <ActionButton
              textId={actionLabel}
              iconName="Add"
              onClick={onActionClick}
              disabled={isDisabled}
            />
          </div>
        )}
        {image !== undefined && (
          <>
            <div className={styles["image-card"]}>
              <Img
                alt="preview"
                src={image}
                frameClass={styles["image-preview"]}
              />
              <div className={styles["filename"]} title={filename}>
                {filename}
              </div>
              <div className={styles["uploaded-at"]}>
                <FormattedMessage id="fsl_custom_model.sample_image.uploaded_at" />
                {uploadedAt?.toLocaleDateString()}
              </div>
              <Badge
                type={isCorrected ? BadgeType.Correct : BadgeType.Pending}
                textId={badgeLabel}
                className={styles["badge"]}
              />
            </div>
          </>
        )}
      </SelectableCard>
      {image !== undefined && !isDeleting && (
        <Checkbox
          checked={isChecked}
          className={styles["checkbox"]}
          onChange={_onCheckboxChange}
        />
      )}
      {isDeleting && (
        <Spinner
          size={SpinnerSize.medium}
          className={styles["deleting-spinner"]}
        />
      )}
    </div>
  );
}
