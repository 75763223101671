import React from "react";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { WorkspaceApiSection } from "../components/WorkspaceApi";
import { WorkspaceTab } from "../components/WorkspaceNavBar";
import { useUnsafeParams } from "../hooks/params";
import { useCommonWorkspaceContainerState } from "../hooks/workspace";
import { PathParam } from "../models";
import HeaderContainer from "./Header";
import { WorkspaceNavBarLayoutContainer as WorkspaceNavBarLayout } from "./WorkspaceNavBarLayout";

function useWorkspaceApiContainer() {
  const { workspaceId } = useUnsafeParams<PathParam>();
  const { workspace } = useCommonWorkspaceContainerState(workspaceId);

  return React.useMemo(
    () => ({
      workspaceId,
      workspace,
      isLoading: workspace.state === "loading",
    }),
    [workspace, workspaceId]
  );
}

export default function WorkspaceApiContainer() {
  const props = useWorkspaceApiContainer();
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        {props.workspace.state === "error" && (
          <ErrorPlaceholder messageId="common.fail_to_fetch_workspace" />
        )}
        {props.workspace.state === "success" && (
          <WorkspaceNavBarLayout selectedTab={WorkspaceTab.Api}>
            <WorkspaceApiSection {...props} workspace={props.workspace.data} />
          </WorkspaceNavBarLayout>
        )}
        <LoadingModal isOpen={props.isLoading} />
      </Main>
    </Layout>
  );
}
