import cn from "classnames";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

interface PageHeaderProps {
  title: string;
  subtitle: string;
  dividerVisible?: boolean;
  right?: React.ReactNode;
  className?: string;
}

export function PageHeader(props: PageHeaderProps) {
  const { title, subtitle, right, className } = props;
  const dividerVisible = props.dividerVisible ?? true;
  const { localized } = useLocale();

  const pageHeaderClass = cn(
    styles["page-header"],
    dividerVisible ? styles["page-header-divider"] : "",
    className
  );

  return (
    <div className={pageHeaderClass}>
      <div className={styles["page-header-left"]}>
        <div className={styles["page-header-title"]}>{localized(title)}</div>
        <div className={styles["page-header-subtitle"]}>
          {localized(subtitle)}
        </div>
      </div>
      <div className={styles["page-header-right"]}>{right}</div>
    </div>
  );
}
