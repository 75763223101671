import HandWriting from "bundle-text:../../images/icons/hand-writing.svg";
import SipMove from "bundle-text:../../images/icons/sip-move.svg";
import Tag from "bundle-text:../../images/icons/tag.svg";

import { HeaderIconType } from "../../types/advancedTokenSetup/headerIcon";

// Developer's note: The following svg asset is exported from figma design and override the fill color with custom header color in header theme. To ensure the icon is shown correctly on the header, please make sure to select `including bounding box` option before you export the svg asset.

export const customHeaderIcons: Record<HeaderIconType, (p: any) => string> = {
  tag: (p: any) => Tag.replace(/fill="white"/i, `fill="${p.bgColor}"`),
  sip_move: (p: any) => SipMove.replace(/fill="white"/i, `fill="${p.bgColor}"`),
  hand_writing: (p: any) =>
    HandWriting.replace(/fill="white"/i, `fill="${p.bgColor}"`),
};
