import { Checkbox, IconButton, Text, TextField } from "@fluentui/react";
import cn from "classnames";
import produce from "immer";
import React from "react";

import { useLocale } from "../../../contexts/locale";
import { useTeamPermission } from "../../../hooks/permission";
import { KeyValueTokenResp } from "../../../types/keyValue";
import styles from "./styles.module.scss";

interface KeySectionProps {
  tokens: KeyValueTokenResp[];
  tokenErrorMessages: (string | null)[];
  onChange: (tokens: KeyValueTokenResp[]) => void;
  sectionErrorMessage?: string;
}

const TableHeader = React.memo(
  (props: { text: string; columnClassName: string }) => {
    const { text, columnClassName } = props;
    return (
      <div className={cn(columnClassName, styles["table-header-cell"])}>
        <Text className={styles["table-header-text"]}>{text}</Text>
      </div>
    );
  }
);

const TokenRow = React.memo(
  (props: {
    token: KeyValueTokenResp;
    onTokenChange: (token: KeyValueTokenResp) => void;
    onDeleteToken: (token: KeyValueTokenResp) => void;
    errorMessage?: string;
  }) => {
    const { token, onTokenChange, onDeleteToken, errorMessage } = props;
    const { localized } = useLocale();
    const { hasPermissionToEditResource } = useTeamPermission();

    const onTokenValueChanged = React.useCallback(
      (
        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
      ) => {
        if (newValue !== undefined) {
          onTokenChange(
            produce(token, draftToken => {
              draftToken.token = newValue;
            })
          );
        }
      },
      [token, onTokenChange]
    );

    const onUseFuzzyChanged = React.useCallback(
      (
        _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
      ) => {
        if (checked !== undefined) {
          onTokenChange(
            produce(token, draftToken => {
              draftToken.use_fuzzy_search = checked;
            })
          );
        }
      },
      [token, onTokenChange]
    );

    const onDelete = React.useCallback(() => {
      onDeleteToken(token);
    }, [onDeleteToken, token]);

    return (
      <div className={styles["token-row"]}>
        <div className={cn(styles["key-column"], styles["table-cell"])}>
          <TextField
            className={styles["key-input"]}
            placeholder={localized(
              "advance_token_setup_field_replacement_setting_panel.panel.key_value.keys.key_input.placeholder"
            )}
            onChange={onTokenValueChanged}
            value={token.token}
            errorMessage={errorMessage}
            disabled={!hasPermissionToEditResource}
          />
        </div>
        <div
          className={cn(
            styles["fuzzy-column"],
            styles["table-cell"],
            styles["fuzzy-cell"]
          )}
        >
          <Checkbox
            checked={token.use_fuzzy_search}
            onChange={onUseFuzzyChanged}
            disabled={!hasPermissionToEditResource}
          />
          <IconButton
            onClick={onDelete}
            iconProps={{
              iconName: "delete",
            }}
            styles={{
              root: {
                marginRight: 10,
                color: "#201F1E",
              },
              rootHovered: {
                color: "#201F1E",
              },
              rootPressed: {
                color: "#201F1E",
              },
            }}
          />
        </div>
      </div>
    );
  }
);

export const KeySection = React.memo((props: KeySectionProps) => {
  const { tokens, onChange, tokenErrorMessages, sectionErrorMessage } = props;
  const { localized } = useLocale();

  const onAddNew = React.useCallback(() => {
    onChange([...tokens, { token: "", use_fuzzy_search: false }]);
  }, [tokens, onChange]);

  const deleteToken = React.useCallback(
    (index: number) => {
      const newTokens = [...tokens];
      newTokens.splice(index, 1);
      onChange(newTokens);
    },
    [tokens, onChange]
  );

  const updateToken = React.useCallback(
    (index: number, token: KeyValueTokenResp) => {
      onChange(
        produce(tokens, draftTokens => {
          draftTokens[index] = token;
        })
      );
    },
    [tokens, onChange]
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["key-section"]}>
      <div className={styles["title-section"]}>
        <div className={styles["title-row"]}>
          <Text className={styles["title-text"]}>
            {localized(
              "advance_token_setup_field_replacement_setting_panel.panel.key_value.keys.title"
            )}
          </Text>
          {hasPermissionToEditResource && (
            <Text className={styles["new-key-button"]} onClick={onAddNew}>
              {localized(
                "advance_token_setup_field_replacement_setting_panel.panel.key_value.keys.add"
              )}
            </Text>
          )}
        </div>
        {sectionErrorMessage && (
          <Text className={styles["section-error"]}>{sectionErrorMessage}</Text>
        )}
      </div>
      {tokens.length > 0 && (
        <div className={styles["table-header-row"]}>
          <TableHeader
            columnClassName={styles["key-column"]}
            text={localized(
              "advance_token_setup_field_replacement_setting_panel.panel.key_value.keys.columns.key"
            )}
          />
          <TableHeader
            columnClassName={styles["fuzzy-column"]}
            text={localized(
              "advance_token_setup_field_replacement_setting_panel.panel.key_value.keys.columns.fuzzy"
            )}
          />
        </div>
      )}
      {tokens.length > 0 ? (
        tokens.map((token, index) => (
          <TokenRow
            key={index}
            token={token}
            onDeleteToken={() => deleteToken(index)}
            onTokenChange={token => updateToken(index, token)}
            errorMessage={tokenErrorMessages[index] || undefined}
          />
        ))
      ) : (
        <Text className={styles["empty-row"]}>
          {localized(
            "advance_token_setup_field_replacement_setting_panel.panel.key_value.keys.empty"
          )}
        </Text>
      )}
    </div>
  );
});
