@import "../../../styles/variables.scss";
.buttonSpinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.buttonText {
  font-weight: bold;
}

.buttonLoading {
  position: relative;

  .buttonText {
    opacity: 0;
  }
}

.destroy-action-button:not([disabled]) {
  color: $input-error-color;

  :global(.ms-Icon) {
    color: $input-error-color;
  }
}

.destroy-action-button:hover:not([disabled]) {
  opacity: 0.7;
  color: $input-error-color;

  :global(.ms-Icon) {
    color: $input-error-color;
  }
}

.destroy-action-button:active:not([disabled]) {
  opacity: 0.5;
  color: $input-error-color;

  :global(.ms-Icon) {
    color: $input-error-color;
  }
}

.table-row-action-button {
  max-height: 28px;
  background-color: #f3f2f1;
  color: #3b3a39;

  :global(.ms-Icon) {
    color: #3b3a39;
  }
}

.info-action-button {
  color: #0078d4;

  :global(.ms-Icon) {
    color: #0078d4;
  }
}
