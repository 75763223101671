import { Dropdown, IDropdownOption, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { LinkWithTeamRef } from "../../components/LinkWithTeamRef";
import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { useWorkerToken } from "../../hooks/app";
import { SampleImage } from "../../types/extractor";
import { FilePrimaryButton } from "../FileButton";
import { Img } from "../Img";
import styles from "./styles.module.scss";

interface Props {
  onSelectImage: (file?: File) => void;
  onSelectSampleImage?: (url: string) => void;
  samples?: readonly SampleImage[];
}

function ImageSelector(props: Props) {
  const samples = props.samples || [];
  const { onSelectSampleImage } = props;

  if (samples.length === 0) {
    return null;
  }

  return (
    <div className={styles["image-selector"]}>
      <div className={styles["image-selector-content"]}>
        {samples.map((sample, idx) => (
          <Img
            frameClass={styles["image-frame"]}
            key={idx}
            src={sample.preview ?? sample.src}
            onClick={() => onSelectSampleImage?.(sample.src)}
          />
        ))}
      </div>
      <div className={styles["image-selector-desc"]}>
        <FormattedMessage id="ocr_test.image_selector.desc" />
      </div>
    </div>
  );
}

export const OCRTestPlaceholder: React.FC<Props> = (props: Props) => {
  const { onSelectImage } = props;
  const { tokens, token, onSelectToken } = useWorkerToken();

  const onFiles = React.useCallback(
    (files?: File[]) => onSelectImage(files && files[0]),
    [onSelectImage]
  );

  const onAccessTokenDropdownChange = React.useCallback(
    (
      _event: React.FormEvent<unknown>,
      option?: IDropdownOption,
      _n?: number
    ) => {
      if (option && onSelectToken) {
        onSelectToken(option.key as string);
      }
    },
    [onSelectToken]
  );

  const accessTokenOptions = tokens
    ? tokens.map(t => ({
        key: t.token,
        text: t.name,
      }))
    : [];

  return (
    <div className={styles["placeholder"]}>
      <div className={styles["instructions"]}>
        <Text variant="xLarge">
          <FormattedMessage id="ocr_test.no_image.title" />
        </Text>
        <FilePrimaryButton
          textId={"ocr_test.select_a_file"}
          onFiles={onFiles}
          accept={SUPPORTED_EXTRACT_MIME.join(",")}
        />
        <ImageSelector {...props} />
        {tokens && tokens.length > 0 && (
          <div className={styles["settings"]}>
            <div>
              <FormattedMessage id="ocr_test.access_token_using" />
            </div>
            <Dropdown
              selectedKey={token}
              className={styles["settings-dropdown"]}
              options={accessTokenOptions}
              onChange={onAccessTokenDropdownChange}
              styles={{ title: { height: "39px" } }}
            />
          </div>
        )}
        {tokens && tokens.length === 0 && (
          <div className={styles["access-token"]}>
            <FormattedMessage
              id="ocr_test.no_access_token"
              values={{
                url: (
                  <LinkWithTeamRef to="/team/tokens">
                    <FormattedMessage id="ocr_test.create_access_token" />
                  </LinkWithTeamRef>
                ),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
