import { Image, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

import { DetectionRegionInspectorTargetIds } from "../../constants/layout";
import labelFieldImg from "../../images/tutorial/label-field.gif";
import FixedLayoutFormTutorialBubble from "../FixedLayoutFormTutorialBubble";
import styles from "./styles.module.scss";

const Content = React.memo(() => {
  return (
    <>
      <Image src={labelFieldImg} className={styles["image"]} />
      <div className={styles["text-content"]}>
        <Text variant="medium" className={styles["title"]}>
          <FormattedMessage id="tutorial.fixed_layout_form.label_extraction_field.title" />
        </Text>
        <Text variant="medium" className={styles["description"]}>
          <FormattedMessage id="tutorial.fixed_layout_form.label_extraction_field.description1" />
        </Text>
        <Text variant="medium" className={styles["description"]}>
          <FormattedMessage id="tutorial.fixed_layout_form.label_extraction_field.description2" />
        </Text>
      </div>
    </>
  );
});

interface FixedLayoutFormTutorialLabelExtractionFieldBubbleProps {
  proceedToNextStage: () => void;
  skipAllTutorials: () => void;
}

const FixedLayoutFormTutorialLabelExtractionFieldBubble = React.memo(
  (props: FixedLayoutFormTutorialLabelExtractionFieldBubbleProps) => {
    const { proceedToNextStage, skipAllTutorials } = props;

    const onSkipAll = useCallback(() => {
      skipAllTutorials();
    }, [skipAllTutorials]);

    const onNavigateToStep = useCallback(
      (_: number) => {
        proceedToNextStage();
      },
      [proceedToNextStage]
    );

    return (
      <FixedLayoutFormTutorialBubble
        isLastStep={true}
        currentStep={0}
        targetId={DetectionRegionInspectorTargetIds.LabelExtractionField}
        onSkipAll={onSkipAll}
        onNavigateToStep={onNavigateToStep}
        contents={[<Content />]}
      />
    );
  }
);

export default FixedLayoutFormTutorialLabelExtractionFieldBubble;
