@import "../../styles/variables.scss";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .navbar-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: top;
    margin: 8px 12px;
    flex-shrink: 0;
  }

  .navbar-bottom {
    position: relative;
    bottom: -2px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    flex-shrink: 0;

    margin: 0 12px;
    margin-top: -2px;
    height: 44px;
  }

  > hr {
    flex-shrink: 0;
    height: 2px;
    background-color: #edebe9;
    border: 0;
    margin: 0 12px;
  }

  .scrollable-view {
    flex: 1 1 0;
    overflow: auto;
  }
  .content {
    width: 100%;
    height: 100%;
  }
}
