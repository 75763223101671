import * as React from "react";
import { useNavigate } from "react-router";

import { FirstTimeGuidance } from "../components/FirstTimeGuidance";
import {
  NavBarLayout,
  useNavBarLayoutBreadcrumbItems,
} from "../components/NavBarLayout";
import { NavTab } from "../components/NavTabBar";
import { UserFeatureFlag } from "../constants";
import { ButtonId } from "../constants/buttonids";
import {
  isEditablePrebuiltExtractors,
  isPrebuiltExtractors,
} from "../constants/prebuiltExtractor";
import { useGtm } from "../hooks/gtm";
import { useUnsafeParams } from "../hooks/params";
import { useTeamPermission } from "../hooks/permission";
import { useAppSelector } from "../hooks/redux";
import { PathParam } from "../models";
import { Form } from "../types/form";

export enum FormNavTabKey {
  ManageRules = "edit",
  Formatter = "format",
  TestExtractor = "test",
  TestExtractorV1 = "test-v1",
  API = "extract",
  Settings = "setting",
}

export const FormNavTab: Record<FormNavTabKey, NavTab> = {
  [FormNavTabKey.ManageRules]: {
    id: ButtonId.ManageRulesTab,
    key: FormNavTabKey.ManageRules,
    labelId: "extractor.tab.manage_rules",
  },
  [FormNavTabKey.Formatter]: {
    key: FormNavTabKey.Formatter,
    labelId: "extractor.tab.formatter",
  },
  [FormNavTabKey.TestExtractor]: {
    key: FormNavTabKey.TestExtractor,
    labelId: "extractor.tab.test",
  },
  [FormNavTabKey.TestExtractorV1]: {
    key: FormNavTabKey.TestExtractorV1,
    labelId: "extractor.tab.test_v1",
  },
  [FormNavTabKey.API]: {
    key: FormNavTabKey.API,
    labelId: "extractor.tab.extract",
  },
  [FormNavTabKey.Settings]: {
    key: FormNavTabKey.Settings,
    labelId: "extractor.tab.setting",
  },
};

function useFormNavBarLayoutState(selectedTab: string, form?: Form) {
  const { formId } = useUnsafeParams<PathParam>();
  const { hasPermissionToEditResource } = useTeamPermission();
  const navigate = useNavigate();

  const breadcrumbItems = useNavBarLayoutBreadcrumbItems(form?.name);

  const { isV1TestTabEnabled, isFormatterEnabled } = useAppSelector(state => ({
    isV1TestTabEnabled: state.resourceOwner.isFeatureEnabled.apply(
      state.resourceOwner
    )(UserFeatureFlag.V1TestTab),
    isFormatterEnabled: state.resourceOwner.isFeatureEnabled.apply(
      state.resourceOwner
    )(UserFeatureFlag.Formatter),
  }));

  const tabs = React.useMemo(() => {
    const isPrebuiltExtractorFlag =
      form !== undefined &&
      form.config.prebuilt_extractor &&
      isPrebuiltExtractors(form.config.prebuilt_extractor);
    const shouldHideEdit =
      !hasPermissionToEditResource ||
      (isPrebuiltExtractorFlag &&
        !isEditablePrebuiltExtractors(form.config.prebuilt_extractor ?? ""));

    const shouldHideFormatter = !isFormatterEnabled;

    const shouldHideV1Tab = !isV1TestTabEnabled;

    const tabKeys = [
      !shouldHideEdit && FormNavTabKey.ManageRules,
      !shouldHideFormatter && FormNavTabKey.Formatter,
      FormNavTabKey.TestExtractor,
      !shouldHideV1Tab && FormNavTabKey.TestExtractorV1,
      FormNavTabKey.API,
      FormNavTabKey.Settings,
    ];

    return tabKeys.filter(Boolean).map(key => FormNavTab[key as FormNavTabKey]);
  }, [
    form,
    hasPermissionToEditResource,
    isFormatterEnabled,
    isV1TestTabEnabled,
  ]);

  const { pushClickedManagedRulesEvent } = useGtm();

  const onTabSelect = React.useCallback(
    (key: string) => {
      const mapTable = {
        [FormNavTabKey.ManageRules]: "/",
        [FormNavTabKey.Formatter]: "/format",
        [FormNavTabKey.TestExtractor]: "/test",
        [FormNavTabKey.TestExtractorV1]: "/test-v1",
        [FormNavTabKey.API]: "/extract",
        [FormNavTabKey.Settings]: "/setting",
      } as { [key in FormNavTabKey]: string };

      if (key === FormNavTabKey.ManageRules) {
        pushClickedManagedRulesEvent(
          FirstTimeGuidance.isManageRulesStepVisible()
        );
      }
      const path = mapTable[key as FormNavTabKey];
      navigate(`/form/${formId}${path}`);
    },
    [navigate, formId, pushClickedManagedRulesEvent]
  );

  return React.useMemo(() => {
    return {
      onTabSelect,
      tabs,
      breadcrumbItems,
      selectedTab,
    };
  }, [breadcrumbItems, onTabSelect, selectedTab, tabs]);
}

type FormNavBarLayoutProps = {
  children: React.ReactNode;
  selectedTab?: string;
  tabBarVisible?: boolean;
  form?: Form;
};

export function FormNavBarLayoutImpl(
  props: FormNavBarLayoutProps & ReturnType<typeof useFormNavBarLayoutState>
) {
  const {
    children,
    tabs,
    onTabSelect,
    breadcrumbItems,
    selectedTab,
    tabBarVisible,
  } = props;

  return (
    <>
      <NavBarLayout
        tabs={tabs}
        onTabSelect={onTabSelect}
        breadcrumbItems={breadcrumbItems}
        selectedTab={selectedTab}
        tabBarVisible={tabBarVisible}
      >
        {children}
      </NavBarLayout>
    </>
  );
}

export function FormNavBarLayout(props: FormNavBarLayoutProps) {
  const state = useFormNavBarLayoutState(
    props.selectedTab ?? FormNavTabKey.ManageRules,
    props.form
  );

  return <FormNavBarLayoutImpl {...props} {...state} />;
}
