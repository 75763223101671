.split-view {
  display: flex;
  flex-direction: row;
  height: 100%;

  .split-view-left {
    flex: 1.5 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .split-view-right {
    flex: 1 1 auto;
  }
  .split-view-divider {
    width: 2px;
    min-width: 2px;
    background-color: #f3f2f1;
  }
}
