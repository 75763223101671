import { Icon, Link, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useState } from "react";

import { useTeamPermission } from "../../hooks/permission";
import { ExtractorOptionForCombinedExtractor } from "../../types/extractor";
import { DetailFormGroup } from "../../types/formGroup";
import ShortSpinner from "../ShortSpinner";
import { FormGroupFallbackFormModal } from "./FormGroupFallbackFormModal";
import styles from "./styles.module.scss";

interface Props {
  formGroup: DetailFormGroup;
  extractorOptions: ExtractorOptionForCombinedExtractor[];
  onUpdateFallbackForm: (formId: string | null) => Promise<void>;
}

export const FormGroupFallbackForm = React.memo((props: Props) => {
  const { formGroup, extractorOptions, onUpdateFallbackForm } = props;
  const { hasPermissionToEditResource } = useTeamPermission();
  const [isFallbackFormModalOpen, setIsFallbackFormModalOpen] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const closeFallbackFormModal = useCallback(() => {
    setIsFallbackFormModalOpen(false);
  }, []);

  const saveFallbackForm = useCallback(
    async (formId: string | null) => {
      closeFallbackFormModal();
      try {
        setIsLoading(true);
        await onUpdateFallbackForm(formId);
      } finally {
        setIsLoading(false);
      }
    },
    [closeFallbackFormModal, onUpdateFallbackForm]
  );

  const onEditFallbackForm = useCallback(() => {
    setIsFallbackFormModalOpen(true);
  }, []);

  const onDeleteFallbackForm = useCallback(() => {
    saveFallbackForm(null);
  }, [saveFallbackForm]);

  if (isLoading) {
    return (
      <div className={styles["form-group-fallback-form-content"]}>
        <Text className={styles["title"]}>
          <FormattedMessage id="form_group_fallback_form_editor.title" />
        </Text>
        <div className={styles["loading-container"]}>
          <ShortSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className={styles["form-group-fallback-form-content"]}>
      <Text className={styles["title"]}>
        <FormattedMessage id="form_group_fallback_form_editor.title" />
      </Text>
      {formGroup.fallbackForm && (
        <div className={styles["fallback-form-item"]}>
          <Text className={styles["text"]}>{formGroup.fallbackForm.name}</Text>
          <div className={styles["action-container"]}>
            <Icon
              iconName="Edit"
              className={styles["action-button"]}
              onClick={onEditFallbackForm}
            />
            <Icon
              iconName="Delete"
              className={styles["action-button"]}
              onClick={onDeleteFallbackForm}
            />
          </div>
        </div>
      )}
      {!formGroup.fallbackForm && hasPermissionToEditResource && (
        <Link className={styles["link"]} onClick={onEditFallbackForm}>
          <FormattedMessage id="form_group_fallback_form_editor.select_editor" />
        </Link>
      )}
      {!formGroup.fallbackForm && !hasPermissionToEditResource && (
        <Text className={styles["text"]}>
          <FormattedMessage id="form_group_fallback_form_editor.empty" />
        </Text>
      )}
      {isFallbackFormModalOpen && (
        <FormGroupFallbackFormModal
          extractorOptions={extractorOptions}
          fallbackForm={formGroup.fallbackForm || undefined}
          onCloseModal={closeFallbackFormModal}
          onSave={saveFallbackForm}
        />
      )}
    </div>
  );
});
