import {
  BriefFormGroup,
  DetailFormGroup,
  FormGroupAnchorBase,
  FormGroupDocumentDetectionBase,
  FormGroupTokenGroupBase,
  FormGroupType,
  PaginatedBriefFormGroup,
  PaginatedWithOffsetBriefFormGroup,
  briefFormGroupSchema,
  detailFormGroupSchema,
  paginatedBriefFormGroupSchema,
  paginatedWithOffsetBriefFormGroupSchema,
} from "../../types/formGroup";
import { FormGroupConfig } from "../../types/formGroupConfig";
import {
  FormGroupAnchorMapper,
  FormGroupDocumentDetectionMapper,
  FormGroupTokenGroupMapper,
} from "../../types/mappers/formGroup";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface FormGroupApiClient {
  listFormGroups: (
    size: number,
    cursor?: string,
    resourceOwnerId?: string
  ) => Promise<PaginatedBriefFormGroup>;
  createFormGroup: (
    name: string,
    type: FormGroupType,
    resourceOwnerId?: string
  ) => Promise<BriefFormGroup>;
  deleteFormGroup: (
    formGroupId: string,
    resourceOwnerId?: string
  ) => Promise<void>;
  pinFormGroup: (
    formGroupId: string,
    resourceOwnerId?: string
  ) => Promise<void>;
  unpinFormGroup: (
    formGroupId: string,
    resourceOwnerId?: string
  ) => Promise<void>;
  getFormGroup: (
    formGroupId: string,
    resourceOwnerId?: string
  ) => Promise<DetailFormGroup>;
  updateFormGroup: (
    formGroupId: string,
    requestParams: UpdateFormGroupRequestParams,
    resourceOwnerId?: string
  ) => Promise<DetailFormGroup>;
  listFormGroupsWithOffset: (
    size: number,
    cursor?: string,
    resourceOwnerId?: string,
    region?: string
  ) => Promise<PaginatedWithOffsetBriefFormGroup>;
}

export interface UpdateFormGroupRequestParams {
  name?: string;
  type?: FormGroupType;
  tokenGroups?: FormGroupTokenGroupBase[];
  anchors?: FormGroupAnchorBase[];
  retrievedAt?: string;
  config?: FormGroupConfig;
  documentDetections?: FormGroupDocumentDetectionBase[];
  fallbackFormId?: string | null;
}

export function withFormGroupApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async listFormGroups(
      size: number,
      cursor?: string,
      resourceOwnerId?: string
    ): Promise<PaginatedBriefFormGroup> {
      return this.lambda(
        "form_group:list",
        this.injectOptionalFields(
          {
            page_args: {
              size,
              cursor: cursor || "",
            },
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        paginatedBriefFormGroupSchema,
        null
      );
    }

    async createFormGroup(
      name: string,
      type: FormGroupType,
      resourceOwnerId?: string
    ): Promise<BriefFormGroup> {
      return this.lambda(
        "form_group:create",
        this.injectOptionalFields(
          {
            name: name,
            type: type,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        briefFormGroupSchema
      );
    }

    async deleteFormGroup(
      formGroupId: string,
      resourceOwnerId?: string
    ): Promise<void> {
      return this.lambda(
        "form_group:delete",
        this.injectOptionalFields(
          {
            form_group_id: formGroupId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }

    async pinFormGroup(
      formGroupId: string,
      resourceOwnerId?: string
    ): Promise<void> {
      return this.lambda(
        "form_group:pin",
        this.injectOptionalFields(
          {
            form_group_id: formGroupId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }

    async unpinFormGroup(
      formGroupId: string,
      resourceOwnerId?: string
    ): Promise<void> {
      return this.lambda(
        "form_group:unpin",
        this.injectOptionalFields(
          {
            form_group_id: formGroupId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        )
      );
    }

    async getFormGroup(
      formGroupId: string,
      resourceOwnerId?: string
    ): Promise<DetailFormGroup> {
      return this.lambda(
        "form_group:get",
        this.injectOptionalFields(
          {
            form_group_id: formGroupId,
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        detailFormGroupSchema
      );
    }

    async updateFormGroup(
      formGroupId: string,
      requestParams: UpdateFormGroupRequestParams,
      resourceOwnerId?: string
    ): Promise<DetailFormGroup> {
      const {
        name,
        type,
        tokenGroups,
        anchors,
        documentDetections,
        retrievedAt,
        config,
        fallbackFormId,
      } = requestParams;

      return this.lambda(
        "form_group:update",
        this.injectOptionalFields(
          {
            id: formGroupId,
          },
          {
            resource_owner_id: resourceOwnerId,
            name,
            type,
            token_groups:
              tokenGroups &&
              tokenGroups.map(FormGroupTokenGroupMapper.toRequest),
            anchors: anchors && anchors.map(FormGroupAnchorMapper.toResp),
            document_detections:
              documentDetections &&
              documentDetections.map(FormGroupDocumentDetectionMapper.toResp),
            retrieved_at: retrievedAt,
            config,
            fallback_form_id: fallbackFormId,
          }
        ),
        detailFormGroupSchema
      );
    }

    async listFormGroupsWithOffset(
      size: number,
      cursor?: string,
      resourceOwnerId?: string,
      region?: string
    ): Promise<PaginatedWithOffsetBriefFormGroup> {
      return this.lambda(
        "form_group:list",
        this.injectOptionalFields(
          {
            page_args: {
              type: "offset",
              size,
              cursor: cursor || "",
            },
          },
          {
            resource_owner_id: resourceOwnerId,
          }
        ),
        paginatedWithOffsetBriefFormGroupSchema,
        null,
        region !== undefined ? { region } : undefined
      );
    }
  };
}
