import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useResourceOwnerActionCreator } from "../actions/resourceOwner";
import { useLocale } from "../contexts/locale";
import { BriefWorkerToken } from "../types/workerToken";

export const OPTION_ALL_KEY = "all";

export function useLocalizeTokenName() {
  const { localized } = useLocale();

  return useCallback(
    (token: BriefWorkerToken | null) => {
      if (!token) return "";
      if (token.isRevoked) {
        return `${token.name} ${localized("usage.table.revoked_token")}`;
      }
      return token.name;
    },
    [localized]
  );
}

export function useWorkerTokenDropdown(
  resourceOwnerId?: string,
  region?: string
) {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [options, setOptions] = React.useState<IDropdownOption[]>();
  const { getAllWorkerTokens } = useResourceOwnerActionCreator();
  const { localized } = useLocale();
  const localizeTokenName = useLocalizeTokenName();
  const [isWorkerTokensLoaded, setIsWorkerTokensLoaded] = useState(false);

  useEffect(() => {
    const loadTokens = async () => {
      try {
        const { tokens } = await getAllWorkerTokens(resourceOwnerId, region);
        const options: IDropdownOption[] = [
          {
            key: OPTION_ALL_KEY,
            text: localized("usage.dropdown.all"),
          },
          ...tokens.map(token => ({
            key: token.id,
            text: localizeTokenName(token),
            value: token.id,
          })),
        ];
        setOptions(options);
        setSelectedKeys(options.map(option => option.key as string));
        setIsWorkerTokensLoaded(true);
      } catch {}
    };
    loadTokens();
  }, [
    resourceOwnerId,
    getAllWorkerTokens,
    localized,
    localizeTokenName,
    region,
  ]);

  const onChange = useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      item?: IDropdownOption,
      _index?: number
    ): void => {
      if (!options || !item) {
        return;
      }

      let results: string[] = [];

      if (item.key === OPTION_ALL_KEY) {
        results = item.selected
          ? options.map(option => option.key as string)
          : [];
      } else {
        results = item.selected
          ? [...selectedKeys, item.key as string]
          : selectedKeys.filter(key => key !== item.key);
      }

      if (results.length < options.length) {
        results = results.filter(key => key !== OPTION_ALL_KEY);
      }

      if (
        results.length === options.length - 1 &&
        results.filter(key => key === OPTION_ALL_KEY).length === 0
      ) {
        results.push(OPTION_ALL_KEY);
      }

      setSelectedKeys(results);
    },
    [selectedKeys, options]
  );

  const selectedTokenIds = useMemo(() => {
    if (options) {
      return (
        selectedKeys
          .filter(k => k !== OPTION_ALL_KEY)
          .map(k => options.find(option => option.key === k))
          .map(option => (option as any)?.value) || null
      );
    }

    return [];
  }, [selectedKeys, options]);

  return {
    onChange,
    options,
    selectedKeys,
    selectedTokenIds,
    isWorkerTokensLoaded,
  };
}
