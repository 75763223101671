import { createAction } from "@reduxjs/toolkit";
import produce from "immer";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { AppConfig } from "../config";
import { CustomModelLabelSchemaTypeDefintion } from "../constants/customModelLabelSchema";
import { useCustomModelImageService } from "../contexts/customModelImageService";
import { ApiError, ApiErrorMap, FOCRError } from "../errors";
import { useGetCustomModelAdminURL } from "../hooks/admin";
import { useGtm } from "../hooks/gtm";
import { useAppDispatch } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { UploadingImagesState } from "../reducers/customModel";
import { RootState } from "../redux/types";
import { UploadImagesCallbacks } from "../services/customModelImage";
import {
  CustomModel,
  CustomModelConfig,
  CustomModelExtraFieldType,
  CustomModelImageExtraInfo,
  CustomModelLabelSchema,
  CustomModelNotificationType,
} from "../types/customModel";
import {
  CustomModelImage,
  PaginatedCustomModelImage,
} from "../types/customModelImage";
import { ExtractedContentSchema } from "../types/extractedContentSchema";
import { ExtractorSettings } from "../types/extractorSettings";
import { FSLModelState } from "../types/fslModelState";
import { User } from "../types/user";
import { ensureFOCRError } from "../utils/errors";
import { triggerFileSave } from "../utils/file";
import { getLabelSchemaDisplayName } from "../utils/labelSchema";
import { workerClient } from "../workerClient";
import { useConfirmModalActionCreator } from "./confirmModal";
import { FormsInvalidated } from "./form";
import { useResourceOwnerActionCreator } from "./resourceOwner";

export const CreateCustomModel = createAction<CustomModel>(
  "customModel/createCustomModel"
);

export const ResetDefaultCustomModel = createAction(
  "customModel/resetDefaultCustomModel",
  (name: string) => ({ payload: { name } })
);

export const RenameCustomModel = createAction<string>(
  "customModel/renameCustomModel"
);

export const RequestCustomModelTraining = createAction(
  "customModel/requestCustomModelTraining",
  (remark: string) => ({ payload: { remark } })
);

export const FreezeFieldsForLabelling = createAction(
  "customModel/freezeFieldsForLabelling"
);

export const TriggerSyncWithCVAT = createAction(
  "customModel/triggerSyncWithCVAT"
);

export const TriggerModelTraining = createAction(
  "customModel/triggerModelTraining"
);

export const DeployModelVersion = createAction<string>(
  "customModel/deployModelVersion"
);

export const GettingCustomModel = createAction(
  "customModel/gettingCustomModel"
);
export const GetCustomModel = createAction<CustomModel>(
  "customModel/getCustomModel"
);

export const GetCustomModelFailed = createAction<FOCRError>(
  "customModel/getCustomModelFailed"
);

export const UpdatedCustomModel = createAction(
  "customModel/updatedCustomModel",
  (updatedAt?: string) => ({ payload: { updatedAt } })
);

export const UpdatedCustomModelConfig = createAction(
  "customModel/updatedCustomModelConfig",
  (config: CustomModelConfig) => ({ payload: { config } })
);

export const DeleteCustomModel = createAction(
  "customModel/deleteCustomModel",
  (customModelId: string) => ({ payload: { customModelId } })
);

export const CustomModelPinned = createAction(
  "customModel/customModelPinned",
  (customModelId: string) => ({ payload: { customModelId, value: true } })
);
export const CustomModelUnpinned = createAction(
  "customModel/customModelUnpinned",
  (customModelId: string) => ({ payload: { customModelId, value: false } })
);

export const UpdateCustomModel = createAction<CustomModel>(
  "customModel/updateCustomModel"
);

export const GettingCustomModelImages = createAction(
  "customModel/gettingCustomModelImages"
);

export const GotCustomModelImages = createAction<PaginatedCustomModelImage>(
  "customModel/gotCustomModelImages"
);

export const GotCustomModelImagesFailed = createAction<FOCRError>(
  "customModel/gotCustomModelImagesFailed"
);

export const GotFSLCustomModelImages = createAction<PaginatedCustomModelImage>(
  "customModel/gotFSLCustomModelImages"
);

export const GotFSLCustomModelImagesFailed = createAction<FOCRError>(
  "customModel/gotFSLCustomModelImagesFailed"
);

export const GotFSLGroupedCustomModelImages = createAction(
  "customModel/gotFSLGroupedCustomModelImages",
  (paginatedCustomModelImage: PaginatedCustomModelImage, groupId: string) => ({
    payload: {
      groupId,
      paginatedCustomModelImage,
    },
  })
);

export const UploadingCustomModelImage = createAction(
  "customModel/uploadingCustomModelImage",
  (customModelId: string, count: number) => ({
    payload: {
      customModelId,
      count,
    },
  })
);

export const UploadedCustomModelImage = createAction(
  "customModel/uploadedCustomModelImage",
  (customModelId: string, customModelImage: CustomModelImage) => ({
    payload: {
      customModelId,
      customModelImage,
    },
  })
);

export const PatchedCustomModelImageInfo = createAction(
  "customModel/patchedCustomModelImageInfo",
  (customModelImageId: string, info: Record<string, any>) => ({
    payload: {
      customModelImageId,
      info,
    },
  })
);

export const UploadCustomModelImageFailed = createAction(
  "customModel/uploadCustomModelImageFailed",
  (customModelId: string, filename: string, error: FOCRError) => ({
    payload: {
      customModelId,
      filename,
      error,
    },
  })
);

export const DeletingCustomModelImage = createAction(
  "customModel/deletingCustomModelImage",
  (customModelId: string, customModelImageIds: string[]) => ({
    payload: {
      customModelId,
      customModelImageIds,
    },
  })
);

export const DeletedCustomModelImage = createAction(
  "customModel/deletedCustomModelImage",
  (customModelId: string, customModelImageId: string) => ({
    payload: {
      customModelId,
      customModelImageId,
    },
  })
);

export const DeleteCustomModelImageFailed = createAction(
  "customModel/deleteCustomModelImageFailed",
  (customModelId: string, customModelImageId: string, error: FOCRError) => ({
    payload: {
      customModelId,
      customModelImageId,
      error,
    },
  })
);

export const UpdateCustomModelLabelSchema = createAction<
  CustomModelLabelSchema[]
>("customModel/updateCustomModelLabelSchema");

export const UpdateCustomModelExtractedContentSchema =
  createAction<ExtractedContentSchema>(
    "customModel/UpdateCustomModelExtractedContentSchema"
  );

export const UpdateCustomModelFSLModelState = createAction<FSLModelState>(
  "customModel/UpdateCustomModelFSLModelState"
);

export const UpdateCustomModelIsFSLModel = createAction<boolean>(
  "customModel/UpdateCustomModelIsFSLModel"
);

export const PatchFslUploadingSampleImagesState = createAction(
  "customModel/PatchFslUploadingSampleImagesState",
  (customModelId: string, changes: UploadingImagesState, reset: boolean) => ({
    payload: {
      customModelId,
      changes,
      reset,
    },
  })
);

export const CustomModelInvalidated = createAction(
  "customModel/customModelInvalidated"
);

export type CustomModelAction =
  | ReturnType<typeof UpdateCustomModel>
  | ReturnType<typeof GetCustomModel>
  | ReturnType<typeof DeleteCustomModel>
  | ReturnType<typeof ResetDefaultCustomModel>
  | ReturnType<typeof CreateCustomModel>
  | ReturnType<typeof RenameCustomModel>
  | ReturnType<typeof RequestCustomModelTraining>
  | ReturnType<typeof FreezeFieldsForLabelling>
  | ReturnType<typeof TriggerSyncWithCVAT>
  | ReturnType<typeof TriggerModelTraining>
  | ReturnType<typeof DeployModelVersion>
  | ReturnType<typeof UpdateCustomModelLabelSchema>
  | ReturnType<typeof UpdateCustomModelExtractedContentSchema>
  | ReturnType<typeof UpdateCustomModelFSLModelState>
  | ReturnType<typeof UpdateCustomModelIsFSLModel>
  | ReturnType<typeof PatchFslUploadingSampleImagesState>
  | ReturnType<typeof CustomModelInvalidated>;

const updateCustomModelPendingState: {
  promises: {
    resolve: (customModel: CustomModel) => void;
    reject: (reason?: any) => void;
  }[];
  customModel: CustomModel | null;
  updatedAt?: string;
} = {
  promises: [],
  customModel: null,
};

export function useCustomModelActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();
  const { handleConflict } = useConfirmModalActionCreator();
  const { selectTeam } = useResourceOwnerActionCreator();
  const {
    uploadImages: triggerUploadCustomModelImages,
    deleteImages: triggerDeleteCustomModelImages,
  } = useCustomModelImageService();

  const {
    pushCustomMslRequestedTrainingEvent,
    pushCustomMslStartedTrainingEvent,
  } = useGtm();

  const getCustomModelAdminURL = useGetCustomModelAdminURL();

  const getCustomModel = useCallback(
    async (
      customModelId: string,
      extraFields?: CustomModelExtraFieldType[]
    ): Promise<CustomModel> => {
      const { resourceOwnerId } = getState().resourceOwner;

      dispatch(GettingCustomModel());
      try {
        const customModel = await apiClient.getCustomModel(
          customModelId,
          resourceOwnerId,
          undefined,
          extraFields
        );

        selectTeam(customModel.resourceOwnerId);

        dispatch(GetCustomModel(customModel));
        return customModel;
      } catch (e) {
        dispatch(GetCustomModelFailed(ensureFOCRError(e)));
        throw e;
      }
    },
    [dispatch, getState, selectTeam]
  );

  const renameCustomModel = useCallback(
    (name: string) => {
      dispatch(RenameCustomModel(name));
    },
    [dispatch]
  );

  const updateCustomModel = useCallback(
    async (
      customModel: CustomModel,
      shouldIgnoreConflict: boolean = false
    ): Promise<CustomModel> => {
      const { resourceOwnerId } = getState().resourceOwner;

      const pendingState = updateCustomModelPendingState;

      let strippedCustomModel = customModel;

      if (customModel.config.isFSLModel) {
        // Remove all the label schema for FSL model
        // because it is generated from ECS. Ref #FormX-2464
        strippedCustomModel = produce(customModel, draft => {
          draft.config.labelSchema = [];
          return draft;
        });
      }
      pendingState.customModel = strippedCustomModel;

      if (pendingState.updatedAt) {
        return await new Promise((resolve, reject) => {
          pendingState.promises.push({ resolve, reject });
        });
      }
      try {
        pendingState.updatedAt = strippedCustomModel.updatedAt;
        const model = await handleConflict(
          async () => {
            while (pendingState.customModel) {
              const customModelToUpdate = {
                ...pendingState.customModel,
                updatedAt: pendingState.updatedAt,
              };
              pendingState.customModel = null;

              const updatedCustomModel = await apiClient.updateCustomModel(
                customModelToUpdate,
                shouldIgnoreConflict,
                resourceOwnerId
              );

              if (pendingState.customModel === null) {
                return updatedCustomModel;
              } else {
                pendingState.updatedAt = updatedCustomModel.updatedAt;
              }
            }
            throw "Should not be reachable";
          },
          () =>
            apiClient.updateCustomModel(
              strippedCustomModel,
              true,
              resourceOwnerId
            ),
          {
            titleId: "custom_model_editor.modifed_prompt.title",
            messageId: "custom_model_editor.modifed_prompt.desc",
            actionId: "common.save_and_overwrite",
          }
        );

        dispatch(UpdatedCustomModel(model.updatedAt));

        pendingState.promises.forEach(p => {
          p.resolve(model);
        });

        return model;
      } catch (e) {
        pendingState.promises.forEach(p => {
          p.reject(e);
        });
        throw e;
      } finally {
        pendingState.promises = [];
        pendingState.updatedAt = undefined;
      }
    },
    [dispatch, getState, handleConflict]
  );

  const updateCustomModelExtractorSettings = useCallback(
    async (
      settings: ExtractorSettings,
      shouldIgnoreConflict: boolean = false
    ): Promise<CustomModel> => {
      const currentCustomModel = getState().customModel.currentCustomModel;
      if (currentCustomModel == null) {
        throw new Error("should not happen");
      }
      const ret = await updateCustomModel(
        produce(currentCustomModel, draft => {
          draft.name = settings.extractorName;
          const params = {
            ...draft.config.llmParameters,
            should_preserve_horizontal_whitespace:
              settings.preverseHorizontalWhiteSpace,
            should_preserve_vertial_whitespace:
              settings.preverseVerticalWhiteSpace,
            should_separate_prompt_by_fields: settings.splitPromptForLineItems,
            should_add_border_line_to_ocr: settings.addBorderLineToOCR,
            input_type: settings.inputType,
          };
          draft.config.llmParameters = params;
          return draft;
        }),
        shouldIgnoreConflict
      );

      dispatch(UpdatedCustomModelConfig(ret.config as CustomModelConfig));
      return ret;
    },
    [getState, updateCustomModel, dispatch]
  );
  const deleteCustomModel = useCallback(
    async (customModelId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.deleteCustomModel(customModelId, resourceOwnerId);

      dispatch(DeleteCustomModel(customModelId));
    },
    [dispatch, getState]
  );

  const pinCustomModel = useCallback(
    async (customModelId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.pinCustomModel(customModelId, resourceOwnerId);

      dispatch(CustomModelPinned(customModelId));
    },
    [dispatch, getState]
  );

  const unpinCustomModel = useCallback(
    async (customModelId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.unpinCustomModel(customModelId, resourceOwnerId);

      dispatch(CustomModelUnpinned(customModelId));
    },
    [dispatch, getState]
  );

  const { pushCreatedExtractorEvent } = useGtm();

  const createCustomModel = useCallback(
    async (customModel: CustomModel, remark: string): Promise<CustomModel> => {
      const { resourceOwnerId } = getState().resourceOwner;
      const returnedCustomModel = await apiClient.createCustomModel(
        customModel,
        remark,
        resourceOwnerId
      );
      pushCreatedExtractorEvent(
        returnedCustomModel.id,
        returnedCustomModel.name,
        "custom"
      );
      dispatch(CreateCustomModel(returnedCustomModel));

      return returnedCustomModel;
    },
    [dispatch, getState, pushCreatedExtractorEvent]
  );

  const triggerSyncWithCVAT = useCallback(
    async (token: string, customModelId: string) => {
      await workerClient(token).syncCvatProjectForCustomModel(customModelId);
      dispatch(TriggerSyncWithCVAT());
    },
    [dispatch]
  );

  const triggerModelTraining = useCallback(
    async (customModelId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.triggerModelTraining(customModelId, resourceOwnerId);
      pushCustomMslStartedTrainingEvent(customModelId);
      dispatch(TriggerModelTraining());
    },
    [dispatch, getState, pushCustomMslStartedTrainingEvent]
  );

  const deployModelVersion = useCallback(
    async (customModelId: string, modelVersionTag: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.deployModelVersion(
        customModelId,
        modelVersionTag,
        resourceOwnerId
      );

      dispatch(DeployModelVersion(modelVersionTag));
    },
    [dispatch, getState]
  );

  const undeployModel = useCallback(
    async (customModelId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;
      await apiClient.undeployModel(customModelId, resourceOwnerId);
    },
    [getState]
  );

  const submitCustomModelRequest = useCallback(
    async (
      type: "request_training" | "start_labelling",
      user: User,
      customModel: CustomModel
    ): Promise<boolean> => {
      if (AppConfig.zapier === undefined) {
        return true;
      }

      const noOfSampleImages = (
        getState().customModel.paginatedCustomModelImages?.pageInfo
          .totalCount || 0
      ).toString();

      const formData = new FormData();
      formData.append("type", type);
      formData.append("username", user.username || "<no name>");
      formData.append("num_sample", noOfSampleImages);
      formData.append("sample_urls", getCustomModelAdminURL(customModel));
      formData.append("email", user.email);
      formData.append("model_name", customModel.name);
      formData.append(
        "fields",
        [
          ...customModel.config.unfrozenLabelSchema,
          ...customModel.config.labelSchema,
        ]
          .flatMap(x =>
            CustomModelLabelSchemaTypeDefintion[x.type].hidden
              ? []
              : getLabelSchemaDisplayName(x)
          )
          .join(",")
      );
      formData.append("remark", customModel.config.remark);
      formData.append("environment", AppConfig.environment);

      try {
        const response = await (
          await fetch(AppConfig.zapier.createAIModelRequestUrl, {
            method: "POST",
            body: formData,
          })
        ).json();

        return response.status === "success";
      } catch {
        return false;
      }
    },
    [getCustomModelAdminURL, getState]
  );

  const listCustomModelImages = useCallback(
    async (
      customModelId: string,
      size: number,
      offset: number,
      state?: string[],
      infoFields?: string[]
    ) => {
      dispatch(GettingCustomModelImages());

      try {
        const paginatedCustomModelImage = await apiClient.listCustomModelImages(
          customModelId,
          size,
          offset,
          state,
          undefined,
          infoFields
        );

        dispatch(GotCustomModelImages(paginatedCustomModelImage));
      } catch (error) {
        dispatch(GotCustomModelImagesFailed(ensureFOCRError(error)));
      }
    },
    [dispatch]
  );

  const listFSLCustomModelImages = useCallback(
    async (
      customModelId: string,
      size: number,
      offset: number,
      state?: string[],
      infoFields?: string[]
    ) => {
      const queryFsl = true;
      try {
        const paginatedCustomModelImage = await apiClient.listCustomModelImages(
          customModelId,
          size,
          offset,
          state,
          undefined,
          infoFields,
          queryFsl
        );

        dispatch(GotFSLCustomModelImages(paginatedCustomModelImage));
      } catch (error) {
        dispatch(GotFSLCustomModelImagesFailed(ensureFOCRError(error)));
      }
    },
    [dispatch]
  );

  const listFSLGroupedCustomModelImages = useCallback(
    async (
      customModelId: string,
      groupId: string,
      size: number,
      offset: number,
      state?: string[],
      infoFields?: string[]
    ) => {
      const queryFsl = true;
      try {
        const paginatedCustomModelImage = await apiClient.listCustomModelImages(
          customModelId,
          size,
          offset,
          state,
          undefined,
          infoFields?.includes("group_item_index") !== true
            ? [...(infoFields ?? []), "group_item_index"]
            : infoFields,
          queryFsl,
          groupId
        );

        dispatch(
          GotFSLGroupedCustomModelImages(paginatedCustomModelImage, groupId)
        );
        return paginatedCustomModelImage;
      } catch (error) {
        throw ensureFOCRError(error);
      }
    },
    [dispatch]
  );

  const uploadCustomModelImages = useCallback(
    (
      customModelId: string,
      files: File[],
      info?: CustomModelImageExtraInfo,
      callbacks?: UploadImagesCallbacks
    ) => {
      dispatch(UploadingCustomModelImage(customModelId, files.length));
      triggerUploadCustomModelImages(customModelId, files, info, callbacks);
    },
    [dispatch, triggerUploadCustomModelImages]
  );

  const deleteCustomModelImages = useCallback(
    (
      customModelId: string,
      customModelImageIds: string[],
      shouldDeleteGroup?: boolean
    ) => {
      dispatch(DeletingCustomModelImage(customModelId, customModelImageIds));
      triggerDeleteCustomModelImages(
        customModelId,
        customModelImageIds,
        shouldDeleteGroup
      );
    },
    [dispatch, triggerDeleteCustomModelImages]
  );

  const patchCustomModelImageInfo = useCallback(
    async (customModelImageId: string, info: Record<string, any>) => {
      const result = await apiClient.patchCustomModelImageInfo(
        customModelImageId,
        info
      );

      const patchedInfo = {} as Record<string, string>;
      if (info["fsl_output"] !== undefined) {
        patchedInfo["fsl_output"] = info["fsl_output"];
      }

      dispatch(PatchedCustomModelImageInfo(customModelImageId, patchedInfo));
      return result;
    },
    [dispatch]
  );

  const requestCustomModelTraining = useCallback(
    async (remark: string) => {
      const user = getState().user.currentUser;
      const customModel = getState().customModel.currentCustomModel;
      if (user === undefined || customModel === undefined) {
        return;
      }

      dispatch(RequestCustomModelTraining(remark));
      await updateCustomModel(customModel);
      await submitCustomModelRequest("request_training", user, customModel);
      pushCustomMslRequestedTrainingEvent(customModel.id);
    },
    [
      dispatch,
      getState,
      updateCustomModel,
      submitCustomModelRequest,
      pushCustomMslRequestedTrainingEvent,
    ]
  );

  const updateLabelSchema = useCallback(
    async (labelSchema: CustomModelLabelSchema[]) => {
      dispatch(UpdateCustomModelLabelSchema(labelSchema));
      const { currentCustomModel } = getState().customModel;
      if (currentCustomModel) {
        await updateCustomModel(currentCustomModel);
      }
    },
    [dispatch, getState, updateCustomModel]
  );

  const freezeLabelSchema = useCallback(async () => {
    dispatch(FreezeFieldsForLabelling());
    const { currentCustomModel } = getState().customModel;
    if (currentCustomModel) {
      await updateCustomModel(currentCustomModel);
    }
  }, [dispatch, getState, updateCustomModel]);

  const { pushCustomSavedSchemaEvent } = useGtm();

  const updateCustomModelExtractedContentSchema = useCallback(
    async (extractedContentSchema: ExtractedContentSchema) => {
      dispatch(UpdateCustomModelExtractedContentSchema(extractedContentSchema));
      const { currentCustomModel } = getState().customModel;
      if (currentCustomModel) {
        await updateCustomModel(currentCustomModel);
        pushCustomSavedSchemaEvent(
          currentCustomModel.id,
          extractedContentSchema.name,
          JSON.stringify(extractedContentSchema.payload)
        );
      }
    },
    [dispatch, getState, updateCustomModel, pushCustomSavedSchemaEvent]
  );

  const updateCustomModelFSLModelState = useCallback(
    async (modelState: FSLModelState) => {
      dispatch(UpdateCustomModelFSLModelState(modelState));

      const { currentCustomModel } = getState().customModel;
      if (currentCustomModel) {
        await updateCustomModel(currentCustomModel);
      }
    },
    [dispatch, getState, updateCustomModel]
  );

  const updateCustomModelIsFSLModel = useCallback(
    async (isFSLModel: boolean) => {
      dispatch(UpdateCustomModelIsFSLModel(isFSLModel));

      const { currentCustomModel } = getState().customModel;
      if (currentCustomModel) {
        await updateCustomModel(currentCustomModel);
      }
    },
    [dispatch, getState, updateCustomModel]
  );

  const sendNotification = useCallback(
    async (
      eventType: CustomModelNotificationType,
      customModel: CustomModel
    ) => {
      const region = AppConfig.region;
      const host = window.location.host;
      const customModelUrl = `https://${host}/admin/team/${region}/${customModel.resourceOwnerId}/resources/custom-models/${customModel.id}`;

      return apiClient.sendNotification(
        eventType,
        customModel.id,
        customModelUrl
      );
    },
    []
  );

  const { pushCustomMslEnabledEvent } = useGtm();

  const enableStandardModel = useCallback(
    async (customModel: CustomModel) => {
      await apiClient.enableStandardModel(customModel.id);
      const fslModelState = produce(customModel.config.fslModelState, draft => {
        if (draft === undefined) {
          return {
            isReadyToUse: false,
            isStandardModelEnabled: true,
          };
        }
        draft.isStandardModelEnabled = true;
        return draft;
      }) as FSLModelState;

      pushCustomMslEnabledEvent(customModel.id);
      dispatch(UpdateCustomModelFSLModelState(fslModelState));
    },
    [dispatch, pushCustomMslEnabledEvent]
  );

  const patchFslUploadingSampleImagesState = useCallback(
    (customModelId: string, changes: UploadingImagesState, reset: boolean) => {
      dispatch(
        PatchFslUploadingSampleImagesState(customModelId, changes, reset)
      );
    },
    [dispatch]
  );

  const importCustomModel = useCallback(
    async (file: File, customModelId: string | null): Promise<any> => {
      const url = apiClient.getEffectiveEndpoint("/import-custom-model");
      const { resourceOwnerId } = getState().resourceOwner;

      const formData = new FormData();
      formData.append("file", file);
      if (customModelId !== null) {
        formData.append("custom_model_id", customModelId);
      }

      if (resourceOwnerId) {
        formData.append("resource_owner_id", resourceOwnerId);
      }

      const response = await apiClient.fetch(url, {
        method: "POST",
        body: formData,
      });

      const { result } = await response.json();

      if (!result || result.status !== "ok") {
        if (result?.error?.code === ApiError.ImportArchiveTypeMismatched) {
          throw ApiErrorMap[ApiError.ImportArchiveTypeMismatched];
        }
        throw result;
      }
      dispatch(CustomModelInvalidated());
      // Clear associated forms to force reload
      dispatch(FormsInvalidated());
      return result;
    },
    [getState, dispatch]
  );

  const exportCustomModel = useCallback(
    async (
      customModelId: string,
      resourceOwnerId?: string,
      region?: string
    ): Promise<void> => {
      const path =
        `/export-custom-model?custom_model_id=${customModelId}` +
        (resourceOwnerId ? `&resource_owner_id=${resourceOwnerId}` : "");
      const url = apiClient.getEffectiveEndpoint(path, region);
      const response = await apiClient.fetch(url);
      await triggerFileSave(response);
    },
    []
  );

  return useMemo(
    () => ({
      getCustomModel,
      renameCustomModel,
      updateCustomModel,
      deleteCustomModel,
      pinCustomModel,
      unpinCustomModel,
      createCustomModel,
      listCustomModelImages,
      triggerSyncWithCVAT,
      triggerModelTraining,
      deployModelVersion,
      submitCustomModelRequest,
      uploadCustomModelImages,
      deleteCustomModelImages,
      patchCustomModelImageInfo,
      requestCustomModelTraining,
      updateLabelSchema,
      updateCustomModelExtractorSettings,
      freezeLabelSchema,
      updateCustomModelExtractedContentSchema,
      updateCustomModelFSLModelState,
      updateCustomModelIsFSLModel,
      sendNotification,
      listFSLCustomModelImages,
      listFSLGroupedCustomModelImages,
      enableStandardModel,
      undeployModel,
      patchFslUploadingSampleImagesState,
      exportCustomModel,
      importCustomModel,
    }),
    [
      getCustomModel,
      renameCustomModel,
      updateCustomModel,
      updateCustomModelExtractorSettings,
      deleteCustomModel,
      pinCustomModel,
      unpinCustomModel,
      createCustomModel,
      listCustomModelImages,
      triggerSyncWithCVAT,
      triggerModelTraining,
      deployModelVersion,
      submitCustomModelRequest,
      uploadCustomModelImages,
      deleteCustomModelImages,
      requestCustomModelTraining,
      updateLabelSchema,
      freezeLabelSchema,
      updateCustomModelExtractedContentSchema,
      updateCustomModelFSLModelState,
      updateCustomModelIsFSLModel,
      patchCustomModelImageInfo,
      sendNotification,
      listFSLCustomModelImages,
      listFSLGroupedCustomModelImages,
      enableStandardModel,
      undeployModel,
      patchFslUploadingSampleImagesState,
      exportCustomModel,
      importCustomModel,
    ]
  );
}

export function useCustomModelActionCreatorForCustomModelImageService() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();
  const { error: notifyError } = useToast();
  const currentCustomModel = getState().customModel.currentCustomModel;

  const { pushCustomMslUploadedSampleEvent } = useGtm();

  const notifyCustomModelImageUploaded = useCallback(
    (customModelId: string, customModelImage: CustomModelImage) => {
      // Current state's custom model's noOfSampleImages _must_ be "up-to-date"
      // if we get it before dispatch
      // If we do it after dispatch, it'd be ambiguous/depends on redux store's reducer's
      // synchronicity
      const newNoOfSampleImages =
        currentCustomModel == null
          ? undefined
          : currentCustomModel.noOfSampleImages + 1;
      const imageInfo = customModelImage.info;
      const isFsl =
        imageInfo?.fsl_input != null ||
        imageInfo?.fsl_output != null ||
        imageInfo?.is_fsl_image === true;
      // Fsl upload sample is actually fired at uploadSampleImage
      if (!isFsl) {
        pushCustomMslUploadedSampleEvent(customModelId, newNoOfSampleImages);
      }
      dispatch(UploadedCustomModelImage(customModelId, customModelImage));
    },
    [currentCustomModel, dispatch, pushCustomMslUploadedSampleEvent]
  );

  const notifyCustomModelImageUploadFailed = useCallback(
    (customModelId: string, filename: string, error: FOCRError) => {
      dispatch(UploadCustomModelImageFailed(customModelId, filename, error));

      if (error.messageId === "error.sample_image_is_corrupted") {
        notifyError(error.messageId, undefined, error.detail);
      } else {
        notifyError("error.failed_to_upload_sample_image", undefined, {
          filename,
        });
      }
    },
    [dispatch, notifyError]
  );

  const notifyCustomModelImageDeleted = useCallback(
    (customModelId: string, customModelImageId: string) => {
      dispatch(DeletedCustomModelImage(customModelId, customModelImageId));
    },
    [dispatch]
  );

  const notifyCustomModelImageDeleteFailed = useCallback(
    (customModelId: string, customModelImageId: string, error: FOCRError) => {
      dispatch(
        DeleteCustomModelImageFailed(customModelId, customModelImageId, error)
      );
      notifyError("error.failed_to_delete_sample_image");
    },
    [dispatch, notifyError]
  );

  const notifyAdditionalCutomModelImageAddedForUpload = useCallback(
    (customModelId: string, count: number) => {
      dispatch(UploadingCustomModelImage(customModelId, count));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      notifyCustomModelImageUploaded,
      notifyCustomModelImageUploadFailed,
      notifyCustomModelImageDeleted,
      notifyCustomModelImageDeleteFailed,
      notifyAdditionalCutomModelImageAddedForUpload,
    }),
    [
      notifyCustomModelImageUploaded,
      notifyCustomModelImageUploadFailed,
      notifyCustomModelImageDeleted,
      notifyCustomModelImageDeleteFailed,
      notifyAdditionalCutomModelImageAddedForUpload,
    ]
  );
}
