import { DirectionalHint } from "@fluentui/react";
import * as React from "react";

import { ButtonId } from "../../constants/buttonids";
import { Guidance, GuidanceStep, useGuidanceHandle } from "../Guidance";
import {
  SupportRequestPopup,
  useSupportRequestPopupHandle,
} from "./SupportRequestPopup";

const START_DELAY = 500;
const SUPPORT_REQUEST_DELAY = 10000;

type Props = {
  isFrozen?: boolean;
  onSubmit: (message: string) => void;
};
enum Step {
  FieldDescriptionStep,
  AddNewFieldStep,
  SaveSchemaStep,
}
const SCRIPT = [
  {
    id: Step.FieldDescriptionStep,
    anchorIds: [ButtonId.ManageFieldsDescription],
    messageId: "manage_fields_guidance.step1",
  },
  {
    id: Step.AddNewFieldStep,
    anchorIds: [ButtonId.ManageFieldsAddNewField],
    messageId: "manage_fields_guidance.step2",
  },
  {
    id: Step.SaveSchemaStep,
    anchorIds: [ButtonId.ManageFieldsSaveSchema],
    messageId: "manage_fields_guidance.step3",
    directionHint: DirectionalHint.bottomLeftEdge,
  },
] as GuidanceStep[];

export function useManageFieldsGuidanceState(props: Props) {
  const isFrozen = props.isFrozen ?? false;
  const { onSubmit } = props;
  const isMounted = React.useRef(true);

  const submit = React.useCallback(
    (message: string) => {
      onSubmit(message);
    },
    [onSubmit]
  );

  const {
    triggerProps: supportRequestPopupTriggerProps,
    open: openSupportRequestPopup,
  } = useSupportRequestPopupHandle({
    onSubmit: submit,
  });

  const { triggerProps: guidanceTriggerProps, start } =
    useGuidanceHandle(SCRIPT);

  const startedOnce = React.useRef(false);

  React.useEffect(() => {
    if (isFrozen || startedOnce.current || !isMounted.current) {
      return;
    }
    startedOnce.current = true;
    setTimeout(() => {
      start();
    }, START_DELAY);

    setTimeout(openSupportRequestPopup, SUPPORT_REQUEST_DELAY);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFrozen]);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return React.useMemo(
    () => ({
      guidanceTriggerProps,
      supportRequestPopupTriggerProps,
    }),
    [guidanceTriggerProps, supportRequestPopupTriggerProps]
  );
}

export function ManageFieldsGuidance(props: Props) {
  const { guidanceTriggerProps, supportRequestPopupTriggerProps } =
    useManageFieldsGuidanceState(props);

  return (
    <>
      <Guidance {...guidanceTriggerProps} autoClose={true} />
      <SupportRequestPopup {...supportRequestPopupTriggerProps} />
    </>
  );
}
