.editor {
  display: flex;
  flex: 1;
  align-items: center;

  input {
    border: none;
    flex: 1;
    margin-right: 12px;
    &:focus-visible {
      outline: none;
    }
  }
}
