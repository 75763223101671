import { Label, Spinner } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { BriefForm } from "../../types/form";
import { BriefFormGroup } from "../../types/formGroup";
import { PageInfoWithOffset } from "../../types/pageInfo";
import { getPageByOffset } from "../../utils/pagination";
import Paginator from "../Paginator";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import styles from "./styles.module.scss";

function getIsEmpty(
  isFetching: boolean,
  pageInfo?: PageInfoWithOffset
): boolean {
  return !isFetching && (pageInfo?.totalCount ?? 0) === 0;
}

interface EmptyStateUIProps {
  messageId: string;
}

function EmptyStateUI(props: EmptyStateUIProps) {
  return (
    <div className={styles["empty-state-container"]}>
      <div className={styles["empty-state"]}>
        <div className={styles["empty-state-text"]}>
          <Label>
            <FormattedMessage id={props.messageId} />
          </Label>
        </div>
      </div>
    </div>
  );
}

interface FormOrGroupTableRowItemProps {
  name: string;
  type: string;
  id: string;
  exportHandler(id: string): Promise<void>;
}

function FormOrGroupTableRowItem(props: FormOrGroupTableRowItemProps) {
  const { name, type, id, exportHandler } = props;
  const [isExporting, setIsExporting] = React.useState(false);

  const onExportClicked = React.useCallback(async () => {
    setIsExporting(true);
    try {
      await exportHandler(id);
    } finally {
      setIsExporting(false);
    }
  }, [exportHandler, id]);

  return (
    <tr className={styles["resource-table-row"]}>
      <td>{name}</td>
      <td className={styles["resource-table-row-info"]}>{type}</td>
      <td className={styles["resource-table-row-info"]}>{id}</td>
      {isExporting && (
        <td className={styles["spinner"]}>
          <Spinner />
        </td>
      )}
      {!isExporting && (
        <td className={styles["download-button"]} onClick={onExportClicked}>
          <FormattedMessage id="audit.download" />
        </td>
      )}
    </tr>
  );
}

interface FormTableProps {
  offset: number;
  forms?: BriefForm[];
  isFormsFetching: boolean;
  formsPageInfo?: PageInfoWithOffset;
  pageSize: number;
  onNavigateToPage: (pageNumber: number) => void;
  exportForm: (formId: string) => Promise<void>;
}

export function _FormTable(props: FormTableProps) {
  const {
    forms,
    offset,
    isFormsFetching,
    formsPageInfo,
    pageSize,
    exportForm,
    onNavigateToPage,
  } = props;
  const { localized } = useLocale();

  const onExportClicked = React.useCallback(
    async (formId: string) => {
      await exportForm(formId);
    },
    [exportForm]
  );

  const currentPage = React.useMemo(() => {
    return getPageByOffset(offset, pageSize);
  }, [offset, pageSize]);

  const isEmpty = React.useMemo(() => {
    return getIsEmpty(isFormsFetching, formsPageInfo);
  }, [isFormsFetching, formsPageInfo]);

  if (isFormsFetching) {
    return <ShortSpinner />;
  }

  if (forms && !isEmpty) {
    return (
      <>
        <Table
          columnIds={[
            "team.detail.resources.name",
            "form_inspector.document_field.type",
            "team.detail.resources.form_id",
            "common.export",
          ]}
          className={styles["team-table"]}
        >
          {forms.map(form => (
            <FormOrGroupTableRowItem
              key={form.id}
              name={form.name}
              type={localized(`document_type.${form.documentType}`)}
              exportHandler={onExportClicked}
              id={form.id}
            />
          ))}
        </Table>
        <Paginator
          currentPage={currentPage}
          totalCount={formsPageInfo?.totalCount ?? 0}
          pageSize={pageSize}
          navigateToPage={onNavigateToPage}
        />
      </>
    );
  }

  return <EmptyStateUI messageId="team.detail.resources.forms.empty" />;
}

export const FormTable = React.memo(_FormTable);

interface FormGroupTableProps {
  offset: number;
  pageSize: number;
  formGroups?: BriefFormGroup[];
  isFormGroupsFetching: boolean;
  formGroupsPageInfo?: PageInfoWithOffset;
  exportFormGroup: (formGroupId: string) => Promise<void>;
  onNavigateToPage: (pageNumber: number) => void;
}

function _FormGroupTable(props: FormGroupTableProps) {
  const {
    offset,
    pageSize,
    formGroups,
    isFormGroupsFetching,
    formGroupsPageInfo,
    exportFormGroup,
    onNavigateToPage,
  } = props;
  const { localized } = useLocale();

  const onExportClicked = React.useCallback(
    async (formGroupId: string) => {
      await exportFormGroup(formGroupId);
    },
    [exportFormGroup]
  );

  const currentPage = React.useMemo(() => {
    return getPageByOffset(offset, pageSize);
  }, [offset, pageSize]);

  const isEmpty = React.useMemo(() => {
    return getIsEmpty(isFormGroupsFetching, formGroupsPageInfo);
  }, [isFormGroupsFetching, formGroupsPageInfo]);

  if (isFormGroupsFetching) {
    return <ShortSpinner />;
  }

  if (formGroups && !isEmpty) {
    return (
      <>
        <Table
          columnIds={[
            "team.detail.resources.name",
            "team.detail.resources.mode",
            "team.detail.resources.form_group_id",
            "common.export",
          ]}
          className={styles["team-table"]}
        >
          {formGroups.map(formGroup => (
            <FormOrGroupTableRowItem
              key={formGroup.id}
              name={formGroup.name}
              type={localized(
                `team.detail.resources.form_group_type.${formGroup.type}`
              )}
              id={formGroup.id}
              exportHandler={onExportClicked}
            />
          ))}
        </Table>
        <Paginator
          currentPage={currentPage}
          totalCount={formGroupsPageInfo?.totalCount ?? 0}
          pageSize={pageSize}
          navigateToPage={onNavigateToPage}
        />
      </>
    );
  }

  return <EmptyStateUI messageId="team.detail.resources.form_groups.empty" />;
}

export const FormGroupTable = React.memo(_FormGroupTable);

export enum TeamResourceTab {
  forms = "forms",
  formGroups = "form_groups",
  customModels = "custom_models",
}

interface ResourceTabItemProps {
  label: string;
  type: TeamResourceTab;
  isActive?: boolean;
  onItemClick: (tab: TeamResourceTab) => void;
}

function ResourceTabItem(props: ResourceTabItemProps) {
  const { type, label, isActive, onItemClick } = props;

  const onClick = React.useCallback(() => {
    onItemClick(type);
  }, [onItemClick, type]);

  return (
    <div
      className={cn(styles["resource-tab-item"], {
        [styles["active"]]: isActive,
      })}
      onClick={onClick}
    >
      {label}
    </div>
  );
}

interface Props {
  teamId: string;
  region: string;
  currentTab: TeamResourceTab;
  children?: React.ReactNode;
  onTabSelected: (tab: TeamResourceTab) => void;
}

function _TeamDetailResourcesLayout(props: Props) {
  const { currentTab, onTabSelected, children } = props;
  const { localized } = useLocale();

  return (
    <div className={styles["team-resource"]}>
      <div className={styles["tab-container"]}>
        {Object.values(TeamResourceTab).map(tab => {
          return (
            <ResourceTabItem
              key={tab}
              label={localized(`team.detail.${tab.valueOf()}`)}
              type={tab}
              isActive={currentTab === tab}
              onItemClick={onTabSelected}
            />
          );
        })}
      </div>
      <div className={styles["list-container"]}>{children}</div>
    </div>
  );
}

export const TeamDetailResourcesLayout = React.memo(_TeamDetailResourcesLayout);
export default TeamDetailResourcesLayout;
