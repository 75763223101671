import * as React from "react";

import { RegionsConfig } from "../config";
import { useGtm } from "../hooks/gtm";
import { URLParamsKey } from "./searchParamUtils";

export function useRedirectToRegionForTeam() {
  const { pushSwitchedTeamEvent } = useGtm();

  const redirectToRegionForTeam = React.useCallback(
    (region: string, teamLookupId: string, path?: string) => {
      const destination = new URL(
        path ?? "",
        RegionsConfig.endpoints[region].portal
      );
      destination.searchParams.append(URLParamsKey.team, teamLookupId);

      pushSwitchedTeamEvent(region, teamLookupId);

      window.location.href = destination.toString();
    },
    [pushSwitchedTeamEvent]
  );

  return {
    redirectToRegionForTeam,
  };
}
