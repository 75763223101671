import React from "react";

import { useAdminActionCreator } from "../actions/admin";
import ConfirmModal from "../components/ConfirmModal";
import LoadingModal from "../components/LoadingModal";
import SetPlanModal from "../components/SetPlanModal";
import TeamDetailPlan from "../components/TeamDetailPlan";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { AdminPathParam } from "../models";
import { ConfirmModalType } from "../types/confirmation";

function _AdminTeamDetailPlanContainer() {
  const { teamId, region } = useUnsafeParams<AdminPathParam>();
  const { team } = useAppSelector(state => state.admin.teamDetail);

  const [isGiveTrialModalOpened, setIsGiveTrialModalOpened] =
    React.useState<boolean>(false);
  const [isClearTrialModalOpened, setIsClearTrialModalOpened] =
    React.useState<boolean>(false);
  const [
    isUpgradeToEnterpriseModalOpened,
    setIsUpgradeToEnterpriseModalOpened,
  ] = React.useState<boolean>(false);
  const [
    isDowngradeEnterpriseModalOpened,
    setIsDowngradeEnterpriseModalOpened,
  ] = React.useState<boolean>(false);
  const { setTrial, clearTrial, setEnterprise, downgradeEnterprise } =
    useAdminActionCreator();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const toast = useToast();

  const onGiveTrialCancelled = React.useCallback(() => {
    setIsGiveTrialModalOpened(false);
  }, []);

  const onUpgradeToEnterpriseCancelled = React.useCallback(() => {
    setIsUpgradeToEnterpriseModalOpened(false);
  }, []);

  const onGiveTrial = React.useCallback(
    async (quota?: number, endTrialAt?: Date) => {
      setIsSubmitting(true);
      try {
        await setTrial(teamId, region, quota, endTrialAt);
        setIsGiveTrialModalOpened(false);
        toast.success("team.detail.plan.set_trial.success");
      } catch (e) {
        console.error("Failed to set trial: ", e);
        toast.error("error.give_trial.cannot_set_trial");
      } finally {
        setIsSubmitting(false);
      }
    },
    [teamId, region, setTrial, toast]
  );

  const onClearTrial = React.useCallback(async () => {
    setIsSubmitting(true);
    try {
      await clearTrial(teamId, region);
      setIsClearTrialModalOpened(false);
      toast.success("team.detail.plan.set_to_free.success");
    } catch (e) {
      console.error("Failed to clear trial: ", e);
      toast.error("error.give_trial.cannot_clear_trial");
    } finally {
      setIsSubmitting(false);
    }
  }, [teamId, region, clearTrial, toast]);

  const onUpgradeToEnterprise = React.useCallback(
    async (quota?: number, endAt?: Date) => {
      setIsSubmitting(true);
      try {
        await setEnterprise(teamId, region, quota, endAt);
        setIsUpgradeToEnterpriseModalOpened(false);
        toast.success("team.detail.plan.upgrade_to_enterprise.success");
      } catch (e) {
        console.error("Failed to upgrade to enterprise: ", e);
        toast.error("error.upgrade_to_enterprise.cannot_upgrade_to_enterprise");
      } finally {
        setIsSubmitting(false);
      }
    },
    [teamId, region, setEnterprise, toast]
  );

  const onDowngradeEnterprise = React.useCallback(async () => {
    setIsSubmitting(true);
    try {
      await downgradeEnterprise(teamId, region);
      setIsDowngradeEnterpriseModalOpened(false);
      toast.success("team.detail.plan.set_to_free.success");
    } catch (e) {
      console.error("Failed to downgrade enterprise plan: ", e);
      toast.error("error.give_trial.cannot_downgrade_enterprise");
    } finally {
      setIsSubmitting(false);
    }
  }, [teamId, region, downgradeEnterprise, toast]);

  const presetClearTrialModal = React.useCallback(() => {
    setIsClearTrialModalOpened(true);
    setIsGiveTrialModalOpened(false);
    setIsUpgradeToEnterpriseModalOpened(false);
    setIsDowngradeEnterpriseModalOpened(false);
  }, []);

  const presetGiveTrialModal = React.useCallback(() => {
    setIsGiveTrialModalOpened(true);
    setIsClearTrialModalOpened(false);
    setIsUpgradeToEnterpriseModalOpened(false);
    setIsDowngradeEnterpriseModalOpened(false);
  }, []);

  const presetUpgradeToEnterpriseModal = React.useCallback(() => {
    setIsUpgradeToEnterpriseModalOpened(true);
    setIsClearTrialModalOpened(false);
    setIsGiveTrialModalOpened(false);
    setIsDowngradeEnterpriseModalOpened(false);
  }, []);

  const presetDowngradeEnterpriseModal = React.useCallback(() => {
    setIsDowngradeEnterpriseModalOpened(true);
    setIsClearTrialModalOpened(false);
    setIsGiveTrialModalOpened(false);
    setIsUpgradeToEnterpriseModalOpened(false);
  }, []);

  const closeClearTrialModel = React.useCallback(() => {
    setIsClearTrialModalOpened(false);
  }, []);

  const closeDowngradeEnterpriseModel = React.useCallback(() => {
    setIsDowngradeEnterpriseModalOpened(false);
  }, []);

  return (
    <>
      {team && (
        <TeamDetailPlan
          region={region}
          team={team}
          presetGiveTrialModal={presetGiveTrialModal}
          presetClearTrialModal={presetClearTrialModal}
          presetUpgradeToEnterpriseModal={presetUpgradeToEnterpriseModal}
          presetDowngradeEnterpriseModal={presetDowngradeEnterpriseModal}
        />
      )}
      <SetPlanModal
        isOpen={isGiveTrialModalOpened}
        onCancel={onGiveTrialCancelled}
        onSetPlan={onGiveTrial}
        titleMessageId={"team.detail.plan.give_trial"}
        indefinitetlyMessageId={"team.detail.plan.trial_indefinitely"}
        planModeMessageId={"team.detail.plan.trial_model"}
      />
      <ConfirmModal
        isOpen={isClearTrialModalOpened}
        modalType={ConfirmModalType.Destory}
        titleId="team.detail.plan.clear_trial"
        messageId="team.detail.plan.are_you_sure_to_clear_trial"
        actionId="team.detail.plan.clear"
        onCancel={closeClearTrialModel}
        onConfirm={onClearTrial}
      />
      <SetPlanModal
        isOpen={isUpgradeToEnterpriseModalOpened}
        onCancel={onUpgradeToEnterpriseCancelled}
        onSetPlan={onUpgradeToEnterprise}
        titleMessageId={"team.detail.plan.upgrade_to_enterprise"}
        indefinitetlyMessageId={
          "team.detail.plan.upgrade_to_enterprise_indefinitely"
        }
        planModeMessageId={"team.detail.plan.enterprise_model"}
      />
      <ConfirmModal
        isOpen={isDowngradeEnterpriseModalOpened}
        modalType={ConfirmModalType.Destory}
        titleId="team.detail.plan.downgrade_enterprise"
        messageId="team.detail.plan.are_you_sure_to_downgrade"
        actionId="team.detail.plan.downgrade"
        onCancel={closeDowngradeEnterpriseModel}
        onConfirm={onDowngradeEnterprise}
      />
      <LoadingModal isOpen={isSubmitting} />
    </>
  );
}

export const AdminTeamDetailPlanContainer = React.memo(
  _AdminTeamDetailPlanContainer
);
export default AdminTeamDetailPlanContainer;
