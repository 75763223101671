import { SimpleAPIClient } from "../apiClient/simple";
import { AppConfig } from "../config";

export function openLabeller(customModelId: string, imageId?: string) {
  const labelUrl = new URL(AppConfig.labellerUrl);
  labelUrl.searchParams.append("customModelId", customModelId);
  if (imageId) {
    labelUrl.searchParams.append("imageId", imageId);
  }

  const simpleAuthAccessToken = window.localStorage.getItem(
    SimpleAPIClient.AccessTokenKey
  );
  if (simpleAuthAccessToken) {
    if (AppConfig.labellerAccessTokenCookieKey) {
      labelUrl.searchParams.append(
        AppConfig.labellerAccessTokenCookieKey,
        simpleAuthAccessToken
      );
    }

    if (AppConfig.environment === "dev") {
      labelUrl.searchParams.append("accessToken", simpleAuthAccessToken);
    }
  }
  window.open(labelUrl);
}
