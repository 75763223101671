import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { useMicrosoftAuthActionCreator } from "../actions/microsoftAuth";
import { useWorkspaceIntegrationActionCreator } from "../actions/workspaceIntegration";
import errors, { FOCRError } from "../errors";
import { RootState } from "../redux/types";
import type {
  GoogleSheet,
  GoogleSheetFieldMapping,
  GoogleSpreadsheet,
  WorkspaceGoogleSheetExport,
} from "../types/googleSheet";
import {
  FilePickerSharePointFile,
  WorkspaceSharePointIntegrationConfiguration,
} from "../types/workspaceIntegration";
import { PendingState, useMergeableAsyncCallback } from "./common";
import { useExtractorFieldSchema } from "./extractorFieldSchema";
import { useGoogleSheet } from "./googleSheet";
import { useGoogleAuth, type useGoogleAuthReturnValues } from "./google_auth";
import { useToast } from "./toast";

const listSpreadsheetsPendingState: PendingState<
  GoogleSpreadsheet[],
  [string, string | null]
> = {
  isRunning: false,
  promises: [],
  params: null,
};

export interface useGoogleSheetIntegrationReturnValues
  extends useGoogleAuthReturnValues {
  isFetchingGoogleSheets: boolean;
  isFetchingGoogleSpreadsheets: boolean;
  isSavingWorkspaceGoogleSheetExportIntegration: boolean;
  onClearGoogleSheetData: () => void;
  onCreateWorkspaceGoogleSheetExport: (
    worksapceId: string,
    oauthCredentialId: string,
    spreadsheetId: string,
    sheetId: string | null,
    mappings: GoogleSheetFieldMapping[],
    isUpsertMode: boolean
  ) => Promise<WorkspaceGoogleSheetExport>;
  onGetExtractorFields: (extractorId: string) => Promise<string[]>;
  onGetGoogleSheetColumns: (
    oauthCredentialId: string,
    spreadsheetId: string,
    sheetId: string
  ) => Promise<string[]>;
  onGetGoogleSheetList: (
    oauthCredentialId: string,
    spreadsheetId: string
  ) => Promise<GoogleSheet[]>;
  onGetGoogleSpreadsheetList: (
    oauthCredentialId: string,
    query: string | null
  ) => Promise<GoogleSpreadsheet[]>;
}

export function useGoogleSheetIntegration(): useGoogleSheetIntegrationReturnValues {
  const {
    googleAuthError,
    isGoogleAuthorized,
    isGoogleAuthorizing,
    onGoogleAuth,
    resetGoogleAuth,
  } = useGoogleAuth();

  const { getExtractorFieldSchema, getExtractorFieldSchemaTableByExtractorId } =
    useExtractorFieldSchema();

  const {
    isFetchingGoogleSheets,
    isFetchingGoogleSpreadsheets,
    isSavingWorkspaceGoogleSheetExportIntegration,
    clearSheetData,
    createWorkspaceGoogleSheetExport,
    getSheetColumnsBySheetId,
    getSheetList,
    getSheetsBySpreadsheetId,
    getSpreadsheetList,
    getSpreadsheetListByOAuthCredentialId,
  } = useGoogleSheet();

  const getExtractorFields = React.useCallback(
    (extractorId: string): string[] => {
      const tables = getExtractorFieldSchemaTableByExtractorId(extractorId);
      if (tables !== undefined) {
        return Object.values(tables).reduce<string[]>(
          (fields, table) => [...fields, ...table.map(({ name }) => name)],
          []
        );
      }
      return [];
    },
    [getExtractorFieldSchemaTableByExtractorId]
  );

  const onCreateWorkspaceGoogleSheetExport = React.useCallback(
    async (
      worksapceId: string,
      oauthCredentialId: string,
      spreadsheetId: string,
      sheetId: string | null,
      mappings: GoogleSheetFieldMapping[],
      isUpsertMode: boolean
    ): Promise<WorkspaceGoogleSheetExport> => {
      return await createWorkspaceGoogleSheetExport(
        worksapceId,
        oauthCredentialId,
        spreadsheetId,
        sheetId,
        mappings,
        isUpsertMode
      );
    },
    [createWorkspaceGoogleSheetExport]
  );

  const onGetExtractorFields = React.useCallback(
    async (extractorId: string): Promise<string[]> => {
      const tables = getExtractorFieldSchemaTableByExtractorId(extractorId);
      if (tables === undefined) {
        await getExtractorFieldSchema(extractorId);
      }
      return getExtractorFields(extractorId);
    },
    [
      getExtractorFields,
      getExtractorFieldSchema,
      getExtractorFieldSchemaTableByExtractorId,
    ]
  );

  const onGetGoogleSheetColumns = React.useCallback(
    async (
      oauthCredentialId: string,
      spreadsheetId: string,
      sheetId: string
    ): Promise<string[]> => {
      const columns = getSheetColumnsBySheetId(spreadsheetId, sheetId);
      if (columns === null) {
        await getSheetList(oauthCredentialId, spreadsheetId);
      }
      return getSheetColumnsBySheetId(spreadsheetId, sheetId) ?? [];
    },
    [getSheetColumnsBySheetId, getSheetList]
  );

  const onGetGoogleSheetList = React.useCallback(
    async (
      oauthCredentialId: string,
      spreadsheetId: string
    ): Promise<GoogleSheet[]> => {
      const sheets = getSheetsBySpreadsheetId(spreadsheetId);
      if (sheets === null) {
        await getSheetList(oauthCredentialId, spreadsheetId);
      }
      return getSheetsBySpreadsheetId(spreadsheetId) ?? [];
    },
    [getSheetsBySpreadsheetId, getSheetList]
  );

  const onGetGoogleSpreadsheetList = useMergeableAsyncCallback(
    () => listSpreadsheetsPendingState,
    async (
      oauthCredentialId: string,
      query: string | null
    ): Promise<GoogleSpreadsheet[]> => {
      const spreadsheets =
        getSpreadsheetListByOAuthCredentialId(oauthCredentialId);
      if (
        spreadsheets === null ||
        spreadsheets.findIndex(({ name }) => name.startsWith(query ?? "")) < 0
      ) {
        await getSpreadsheetList(oauthCredentialId, query);
      }
      return getSpreadsheetListByOAuthCredentialId(oauthCredentialId) ?? [];
    },
    [getSpreadsheetList, getSpreadsheetListByOAuthCredentialId]
  );

  return React.useMemo(
    () => ({
      googleAuthError,
      isFetchingGoogleSheets,
      isFetchingGoogleSpreadsheets,
      isGoogleAuthorized,
      isGoogleAuthorizing,
      isSavingWorkspaceGoogleSheetExportIntegration,
      onClearGoogleSheetData: clearSheetData,
      onCreateWorkspaceGoogleSheetExport,
      onGetExtractorFields,
      onGetGoogleSheetColumns,
      onGetGoogleSheetList,
      onGetGoogleSpreadsheetList,
      onGoogleAuth,
      resetGoogleAuth,
    }),
    [
      clearSheetData,
      googleAuthError,
      isFetchingGoogleSheets,
      isFetchingGoogleSpreadsheets,
      isGoogleAuthorized,
      isGoogleAuthorizing,
      isSavingWorkspaceGoogleSheetExportIntegration,
      onCreateWorkspaceGoogleSheetExport,
      onGetExtractorFields,
      onGetGoogleSheetColumns,
      onGetGoogleSheetList,
      onGetGoogleSpreadsheetList,
      onGoogleAuth,
      resetGoogleAuth,
    ]
  );
}

export interface useSharePointIntegrationReturnValues {
  isLoggingInToSharePoint: boolean;
  isLoggedInToSharePoint: boolean;
  onLoginSharePoint: (siteUrl: string) => Promise<void>;
  onSharePointSubscriptionSave: (
    workspaceId: string,
    siteUrl: string,
    selectedFolder: FilePickerSharePointFile
  ) => Promise<void>;
  onSharePointSubscriptionDisconnect: () => void;
  onSharePointIntegrationRemove: () => void;
  integrationConfigs: WorkspaceSharePointIntegrationConfiguration;
}

export function useSharePointIntegration(): useSharePointIntegrationReturnValues {
  const { login } = useMicrosoftAuthActionCreator();
  const { isLoggingIn, tokens } = useSelector(
    (state: RootState) => state.microsoftAuth
  );
  const { createWorkspaceSharePointSubscription } =
    useWorkspaceIntegrationActionCreator();
  const toast = useToast();

  // TODO - replaced by real integration configs
  const [dummyIntegrationConfigs] =
    React.useState<WorkspaceSharePointIntegrationConfiguration>({
      optionType: "import_sharepoint",
      sharePointSubscription: false,
      siteUrlSubdomain: "",
    });

  // TODO: Move create subscription to redux action
  const onSharePointSubscriptionSave: useSharePointIntegrationReturnValues["onSharePointSubscriptionSave"] =
    React.useCallback(
      async (workspaceId, siteUrl, selectedFolder) => {
        try {
          if (tokens?.refreshToken == null) {
            throw new Error("Failed to get refresh token");
          }
          await createWorkspaceSharePointSubscription({
            workspaceId,
            refreshToken: tokens.refreshToken,
            siteUrl,
            listId: selectedFolder.sharepointIds.listId,
            listItemId: selectedFolder.sharepointIds.listItemId,
          });
        } catch (e: unknown) {
          if (e instanceof FOCRError && e !== errors.UnknownError) {
            toast.error(e.messageId);
          } else {
            toast.error(
              "error.create_workspace_sharepoint_subscription.failed"
            );
          }
        }
      },
      [createWorkspaceSharePointSubscription, toast, tokens?.refreshToken]
    );

  const integrationConfigs = React.useMemo(
    () => dummyIntegrationConfigs,
    [dummyIntegrationConfigs]
  );

  const onSharePointSubscriptionDisconnect = useCallback(() => {
    alert("not implemented");
  }, []);

  const onSharePointIntegrationRemove = useCallback(() => {
    alert("not implemented");
  }, []);

  return React.useMemo(
    () => ({
      isLoggingInToSharePoint: isLoggingIn,
      isLoggedInToSharePoint: tokens != null,
      onLoginSharePoint: login,
      onSharePointSubscriptionSave,
      onSharePointSubscriptionDisconnect,
      onSharePointIntegrationRemove,
      integrationConfigs,
    }),
    [
      isLoggingIn,
      tokens,
      login,
      onSharePointSubscriptionSave,
      onSharePointSubscriptionDisconnect,
      onSharePointIntegrationRemove,
      integrationConfigs,
    ]
  );
}
