import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  IChoiceGroupProps,
  IDialogContentProps,
  IModalProps,
  Label,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useRef, useState } from "react";

import { useDetectionRegionInspector } from "../../contexts/detectionRegionInspector";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { BaseFieldSettingModalPayload } from "../../models";
import { DetectionRegionFieldParams } from "../../types/detectionRegion";
import {
  KeywordsExtractionAnchor,
  KeywordsExtractionType,
} from "../../types/keywordsExtraction";
import DetectionRegionAdvancedSetting, {
  DetectionRegionAdvancedSettingHandle,
} from "../DetectionRegionAdvancedSetting";
import { DetectionRegionWaterMarkSetting } from "../DetectionRegionWaterMarkSetting";
import styles from "./styles.module.scss";

interface Props {
  onCloseModal: () => void;
  payload: BaseFieldSettingModalPayload;
}

interface TypeChoiceGroupOption extends IChoiceGroupOption {
  key: KeywordsExtractionType;
}

function KeywordsExtractionAnchorGroup(props: {
  label: string;
  anchor: KeywordsExtractionAnchor;
  onChange: (anchor: KeywordsExtractionAnchor) => void;
}) {
  const { label, anchor, onChange } = props;

  const { localized } = useLocale();

  const onChangeText = React.useCallback(
    (_, value?: string) => {
      const text = value ? value : "";
      const newAnchor: KeywordsExtractionAnchor = { ...anchor, text };

      onChange(newAnchor);
    },
    [onChange, anchor]
  );

  const onChangeFuzzy = React.useCallback(
    (_, checked?: boolean) => {
      const fuzzy = !!checked;
      const newAnchor: KeywordsExtractionAnchor = {
        ...anchor,
        useFuzzySearch: fuzzy,
      };

      onChange(newAnchor);
    },
    [onChange, anchor]
  );

  const onChangeIgnoreWhitespace = React.useCallback(
    (_, checked?: boolean) => {
      const ignoreWhitespace = !!checked;
      const newAnchor: KeywordsExtractionAnchor = {
        ...anchor,
        isIgnoreWhiteSpace: ignoreWhitespace,
      };

      onChange(newAnchor);
    },
    [onChange, anchor]
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["anchor-group"]}>
      <Label>{label}</Label>
      <TextField
        value={anchor.text}
        onChange={onChangeText}
        disabled={!hasPermissionToEditResource}
      />
      <Checkbox
        className={styles["option-checkbox"]}
        checked={anchor.useFuzzySearch}
        label={localized(
          "form_inspector.detection_region_field_text_in_paragraph_fuzzy_match"
        )}
        onChange={onChangeFuzzy}
        disabled={!hasPermissionToEditResource}
      />
      <Checkbox
        className={styles["option-checkbox"]}
        checked={anchor.isIgnoreWhiteSpace}
        label={localized(
          "form_inspector.detection_region_field_text_in_paragraph_ignore_whitespace"
        )}
        onChange={onChangeIgnoreWhitespace}
        disabled={!hasPermissionToEditResource}
      />
    </div>
  );
}

const EditKeywordsModal = React.memo((props: Props) => {
  const { payload, onCloseModal } = props;
  const { field, index, selectedDetectionRegionId } = payload;

  const { localized } = useLocale();
  const { updateDetectionRegionField } = useDetectionRegionInspector();

  const typeOptions = React.useMemo((): TypeChoiceGroupOption[] => {
    const optionValueToLabelMap: {
      [key in KeywordsExtractionType]: string;
    } = {
      between: localized(
        "form_inspector.detection_region_field_text_in_paragraph_type.between"
      ),
      before: localized(
        "form_inspector.detection_region_field_text_in_paragraph_type.before"
      ),
      after: localized(
        "form_inspector.detection_region_field_text_in_paragraph_type.after"
      ),
    };
    return Object.entries(optionValueToLabelMap).map<TypeChoiceGroupOption>(
      ([value, label]) => ({
        key: value as KeywordsExtractionType,
        text: label,
      })
    );
  }, [localized]);

  const [selectedType, setSelectedType] =
    React.useState<KeywordsExtractionType>(() => {
      if (field && field.params && field.params.keywords) {
        return field.params.keywords.type;
      }
      return "between";
    });

  const [beforeAnchor, setBeforeAnchor] =
    React.useState<KeywordsExtractionAnchor>(() => {
      if (
        field &&
        field.params &&
        field.params.keywords &&
        field.params.keywords.before
      ) {
        return field.params.keywords.before;
      }
      return {
        text: "",
        useFuzzySearch: false,
        isIgnoreWhiteSpace: false,
      };
    });

  const [afterAnchor, setAfterAnchor] =
    React.useState<KeywordsExtractionAnchor>(() => {
      if (
        field &&
        field.params &&
        field.params.keywords &&
        field.params.keywords.after
      ) {
        return field.params.keywords.after;
      }
      return {
        text: "",
        useFuzzySearch: false,
        isIgnoreWhiteSpace: false,
      };
    });

  const onSelectedTypeChanged = React.useCallback<
    NonNullable<IChoiceGroupProps["onChange"]>
  >((_, option) => {
    if (option) {
      setSelectedType(option.key as KeywordsExtractionType);
    }
  }, []);

  const shouldBeforeExist = Array<KeywordsExtractionType>(
    "before",
    "between"
  ).includes(selectedType);

  const shouldAfterExist = Array<KeywordsExtractionType>(
    "after",
    "between"
  ).includes(selectedType);

  const [extras, setExtras] = useState<any>(field.extras);

  const advancedSettingsRef =
    useRef<DetectionRegionAdvancedSettingHandle>(null);

  const onSaveClicked = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const params: DetectionRegionFieldParams = {};
      if (advancedSettingsRef.current) {
        Object.assign(params, advancedSettingsRef.current.getParams());
      }

      params["keywords"] = {
        type: selectedType,
        after: shouldAfterExist ? afterAnchor : null,
        before: shouldBeforeExist ? beforeAnchor : null,
      };

      updateDetectionRegionField(index, {
        params: params,
        extras: extras,
      });

      onCloseModal();
    },
    [
      extras,
      onCloseModal,
      index,
      selectedType,
      afterAnchor,
      beforeAnchor,
      shouldBeforeExist,
      shouldAfterExist,
      updateDetectionRegionField,
    ]
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        "form_inspector.detection_region_field_extract_string_between_words"
      ),
    }),
    [localized]
  );

  const modalProps: IModalProps = React.useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <Dialog
      hidden={false}
      onDismiss={onCloseModal}
      minWidth={500}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <form onSubmit={onSaveClicked}>
        <div className={styles["description"]}>
          <FormattedMessage
            id="form_inspector.detection_region_field_extract_string_between_words.desc"
            values={{
              between: (
                <b>
                  <FormattedMessage id="form_inspector.detection_region_field_text_in_paragraph_type.between" />
                </b>
              ),
              startMark: (
                <b>
                  <FormattedMessage id="form_inspector.detection_region_field_text_in_paragraph_mark.start" />
                </b>
              ),
              endMark: (
                <b>
                  <FormattedMessage id="form_inspector.detection_region_field_text_in_paragraph_mark.end" />
                </b>
              ),
            }}
          />
        </div>
        <ChoiceGroup
          onChange={onSelectedTypeChanged}
          selectedKey={selectedType}
          options={typeOptions}
          label={localized(
            "form_inspector.detection_region_field_text_in_paragraph_type"
          )}
          disabled={!hasPermissionToEditResource}
        />
        {shouldAfterExist && (
          <KeywordsExtractionAnchorGroup
            label={localized(
              "form_inspector.detection_region_field_text_in_paragraph_mark.start"
            )}
            anchor={afterAnchor}
            onChange={setAfterAnchor}
          />
        )}
        {shouldBeforeExist && (
          <KeywordsExtractionAnchorGroup
            label={localized(
              "form_inspector.detection_region_field_text_in_paragraph_mark.end"
            )}
            anchor={beforeAnchor}
            onChange={setBeforeAnchor}
          />
        )}
        <div className={styles["water-mark-setting"]}>
          <DetectionRegionWaterMarkSetting
            onChange={setExtras}
            extras={field.extras}
          />
        </div>
        <DetectionRegionAdvancedSetting
          fieldType={field.type}
          fieldParams={field.params}
          selectedDetectionRegionId={selectedDetectionRegionId}
          fieldIndex={index}
          ref={advancedSettingsRef}
        />

        <DialogFooter>
          <DefaultButton
            onClick={onCloseModal}
            text={localized(
              hasPermissionToEditResource ? "common.cancel" : "common.close"
            )}
          />
          {hasPermissionToEditResource && (
            <PrimaryButton type="submit" text={localized("common.ok")} />
          )}
        </DialogFooter>
      </form>
    </Dialog>
  );
});

export default EditKeywordsModal;
