@import "../../styles/variables.scss";

.copy {
  color: $neutral-primary;
  background-color: $neutral-lighter;
  padding: 9px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

  i {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }
}
