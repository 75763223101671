import {
  Context as LocaleContext,
  ContextValue as LocaleContextValue,
  LocaleProvider as _LocaleProvider,
} from "@oursky/react-messageformat";
import React, { useContext, useMemo } from "react";

import appLocaleDataEn from "../locale-data/en.json";

export type Locale = "en";
export type Localizer = LocaleContextValue["renderToString"];

export function useLocale() {
  const { locale, renderToString } = useContext(LocaleContext);

  return useMemo(
    () => ({
      locale,
      localized: renderToString,
    }),
    [locale, renderToString]
  );
}

const messagesByLocale: { [key in Locale]: { [key: string]: string } } = {
  en: appLocaleDataEn,
};

interface Props {
  locale: Locale;
  children?: React.ReactNode;
}

function LocaleProviderImpl(props: Props) {
  const { locale } = props;
  return (
    <_LocaleProvider locale={locale} messageByID={messagesByLocale[locale]}>
      {props.children}
    </_LocaleProvider>
  );
}

export const LocaleProvider = React.memo(LocaleProviderImpl);
