import { Icon, Link, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import * as React from "react";

import multiPagePdfMultiDocumentImg from "../../images/form-extraction-modes/multi-page-multi-doc.svg";
import multiPagePdfImg from "../../images/form-extraction-modes/multi-page.svg";
import singlePageMultiDocumentImg from "../../images/form-extraction-modes/one-page-multi-doc.svg";
import singlePageImg from "../../images/form-extraction-modes/one-page.svg";
import { FormExtractionMode } from "../../types/form";
import { Img } from "../Img";
import styles from "./styles.module.scss";

const modeToImgMapping = {
  [FormExtractionMode.multiPagePdfMultiDocument]: multiPagePdfMultiDocumentImg,
  [FormExtractionMode.singlePage]: singlePageImg,
  [FormExtractionMode.multiPagePdf]: multiPagePdfImg,
  [FormExtractionMode.singlePageMultiDocument]: singlePageMultiDocumentImg,
};

interface FormExtractionModeSelectorProps {
  extractionMode: FormExtractionMode;
  onExtractionModeChanged: (extractionMode: FormExtractionMode) => void;
  onClose: () => void;
}

interface FormExtractionModeSelectorTabProps {
  onClick: () => void;
  extractionMode: keyof typeof modeToImgMapping;
  selected: boolean;
}

function FormExtractionModeSelectorTab(
  props: FormExtractionModeSelectorTabProps
) {
  const { onClick, extractionMode, selected } = props;
  return (
    <div
      onClick={onClick}
      key={extractionMode}
      className={classnames(styles["tab"], { [styles["selected"]]: selected })}
    >
      <div className={styles["inner"]}>
        <Img src={modeToImgMapping[extractionMode]} />
        <div className={styles["bottom"]}>
          <h3>
            <FormattedMessage id={`form.extraction_mode.${extractionMode}`} />
          </h3>
          <Text>
            <FormattedMessage
              id={`form.extraction_mode.${extractionMode}.desc`}
            />
          </Text>
        </div>
      </div>
    </div>
  );
}

export default function FormExtractionModeSelector(
  props: FormExtractionModeSelectorProps
) {
  const { extractionMode, onExtractionModeChanged, onClose } = props;
  return (
    <div className={styles["form-extraction-mode-selector"]}>
      <div className={styles["top"]}>
        <h3>
          <FormattedMessage id="ocr_test.extraction_mode.choose_a_file_type" />
        </h3>
        <Link onClick={onClose}>
          <Icon iconName="Cancel" />
        </Link>
      </div>
      <div className={styles["tabs"]}>
        {(
          Object.keys(modeToImgMapping) as (keyof typeof modeToImgMapping)[]
        ).map(mode => (
          <FormExtractionModeSelectorTab
            key={mode}
            extractionMode={mode}
            onClick={() => onExtractionModeChanged(mode)}
            selected={mode === extractionMode}
          />
        ))}
      </div>
    </div>
  );
}
