@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

$setting-button-color: #2ec293;

.field-extra-container {
  margin-bottom: 12px;

  .field2-container {
    float: left;
    line-height: 38px;
    margin-right: 2px;

    .field2-label {
      display: inline;
      margin-right: 8px;
    }

    .field2-text {
      color: #605e5c;
      line-height: 20px;
    }
  }

  .action-buttons-container {
    display: inline-flex;
    float: right;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: -6px;

    .setting-button {
      :global(.ms-Icon) {
        color: $setting-button-color;
      }
      color: $setting-button-color;
      font-weight: 600;
    }

    .setting-button:hover {
      opacity: 0.7;
    }

    .setting-button:active {
      opacity: 0.5;
      :global(.ms-Icon) {
        color: $setting-button-color;
      }
      color: $setting-button-color;
    }

    .action-button:disabled {
      color: rgb(180, 178, 176);
      :global(.ms-Icon) {
        color: rgb(180, 178, 176);
      }
    }

    .delete-button {
      :global(.ms-Icon) {
        color: #464646;
        margin: 0;
      }
    }
  }
}

.field-extra-container::after {
  content: " ";
  display: block;
  clear: both;
}
