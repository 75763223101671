@import "../../styles/variables.scss";

.splash-screen {
  position: fixed;
  left: 50vw;
  top: 50vh;
  margin-left: -88px;
  margin-top: -64px;

  width: 177px;
  text-align: center;
}

.logo {
  margin-top: 16px;
  display: inline-block;
}
