import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

import { WorkspaceWebhookIntegrationConfiguration } from "../../types/workspaceIntegration";
import { ConfigurationCardLayout } from "./ConfigurationCardLayout";

// TODO: Layout webhook integration configuration #2203
interface WebhookConfigurationCardProps {
  workspaceId: string;
  configuration: WorkspaceWebhookIntegrationConfiguration;
  onConfigurationRemoved: (
    configuration: WorkspaceWebhookIntegrationConfiguration
  ) => void;
}

function WebhookConfigurationCardImpl(props: WebhookConfigurationCardProps) {
  const { configuration, onConfigurationRemoved } = props;
  const { optionType } = configuration;

  // TODO: Remove webhook connection handling #2235
  const onRemoveClick = useCallback(() => {
    onConfigurationRemoved(configuration);
  }, [configuration, onConfigurationRemoved]);

  return (
    <ConfigurationCardLayout
      title={
        <FormattedMessage id="workspace.integrations.configuration.export_webhook.title" />
      }
      onRemoveClick={onRemoveClick}
    >
      {`TBD: ${optionType}`}
    </ConfigurationCardLayout>
  );
}

export const WebhookConfigurationCard = React.memo(
  WebhookConfigurationCardImpl
);
