@import "../../styles/variables.scss";

.lookup-id-text-field-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  .info-icon {
    padding-left: 5px;
    cursor: default;
  }
  :global(.ms-TooltipHost) {
    height: 14px;
  }
}

.lookup-id-section-compact {
  align-items: center;
  animation-name: fade;
  animation-duration: 1s;
  .generated-lookup-id {
    word-wrap: break-word;
  }
}

.lookup-id-section {
  animation-name: fade;
  animation-duration: 1s;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.customize-button {
  color: $theme-primary;
  cursor: pointer;
  padding-left: 8px;
}
