import { IBreadcrumbItem } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router";

import { NavBarLayout } from "../components/NavBarLayout";
import { UserFeatureFlag } from "../constants";
import { useLocale } from "../contexts/locale";
import { useGtm } from "../hooks/gtm";
import { useAppSelector } from "../hooks/redux";

export enum FSLTab {
  ManageFields = "manage_fields",
  InstantModel = "instant_model",
  StandardModel = "standard_model",
  Formatter = "formatter",
  TestExtractor = "test_extractor",
  TestExtractorV1 = "test_extractor_v1",
  API = "api",
  Settings = "settings",
}

function useFSLNavBarLayout(selectedTab: string) {
  const { localized } = useLocale();
  const { currentCustomModel: customModel } = useAppSelector(
    state => state.customModel
  );
  const navigate = useNavigate();

  const backToHome = React.useCallback(() => {
    navigate("/extractor");
  }, [navigate]);

  const customModelId = customModel?.id ?? "";
  const customModelName = customModel?.name ?? "";

  const breadcrumbItems = React.useMemo<IBreadcrumbItem[]>(
    () => [
      {
        text: localized("extractor.breadcrumb.extractors"),
        key: "custom-model",
        href: "/custom-model",
        onClick: backToHome,
      },
      {
        key: customModelId,
        text: customModelName,
      },
    ],
    [localized, backToHome, customModelId, customModelName]
  );

  const isV1TestTabEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.V1TestTab
    )
  );

  const isFormatterEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.Formatter
    )
  );

  const tabs = React.useMemo(() => {
    const list = [
      FSLTab.ManageFields,
      FSLTab.InstantModel,
      FSLTab.StandardModel,
      isFormatterEnabled && FSLTab.Formatter,
      FSLTab.TestExtractor,
      isV1TestTabEnabled && FSLTab.TestExtractorV1,
      FSLTab.API,
      FSLTab.Settings,
    ];

    return list.filter(Boolean).map(key => {
      const fslTabKey = key as FSLTab;
      return {
        key: fslTabKey,
        labelId: `fsl_custom_model.tabs.${fslTabKey}`,
      };
    });
  }, [isFormatterEnabled, isV1TestTabEnabled]);

  const { pushClickedExtractorTabEvent } = useGtm();

  const onTabSelect = React.useCallback(
    (key: string) => {
      const mapTable = {
        [FSLTab.ManageFields]: "/",
        [FSLTab.InstantModel]: "/instant-model",
        [FSLTab.StandardModel]: "/standard-model",
        [FSLTab.Formatter]: "/format",
        [FSLTab.TestExtractor]: "/test",
        [FSLTab.TestExtractorV1]: "/test-v1",
        [FSLTab.API]: "/extract",
        [FSLTab.Settings]: "/setting",
      } as { [key in FSLTab]: string };
      const tabTable = {
        [FSLTab.ManageFields]: "manage-fields",
        [FSLTab.InstantModel]: "instant-model",
        [FSLTab.StandardModel]: "standard-model",
        [FSLTab.Formatter]: "formatter",
        [FSLTab.TestExtractor]: "test-extractor",
        [FSLTab.TestExtractorV1]: "test-extractor-v1",
        [FSLTab.API]: "api",
        [FSLTab.Settings]: "settings",
      } as { [key in FSLTab]: string };

      const path = mapTable[key as FSLTab];
      const tabName = tabTable[key as FSLTab];
      navigate(`/custom-model/${customModelId}${path}`);

      pushClickedExtractorTabEvent(customModelId, tabName);
    },
    [navigate, customModelId, pushClickedExtractorTabEvent]
  );

  return React.useMemo(
    () => ({
      onTabSelect,
      tabs,
      breadcrumbItems,
      selectedTab,
    }),
    [tabs, onTabSelect, breadcrumbItems, selectedTab]
  );
}

type FSLNavBarLayoutProps = {
  children: React.ReactNode;
  selectedTab?: string;
  tabBarVisible?: boolean;
};

export function FSLNavBarLayoutImpl(
  props: FSLNavBarLayoutProps & ReturnType<typeof useFSLNavBarLayout>
) {
  const {
    children,
    tabs,
    onTabSelect,
    breadcrumbItems,
    selectedTab,
    tabBarVisible,
  } = props;

  return (
    <NavBarLayout
      tabs={tabs}
      onTabSelect={onTabSelect}
      breadcrumbItems={breadcrumbItems}
      selectedTab={selectedTab}
      tabBarVisible={tabBarVisible}
    >
      {children}
    </NavBarLayout>
  );
}

export function FSLNavBarLayout(props: FSLNavBarLayoutProps) {
  const state = useFSLNavBarLayout(props.selectedTab ?? FSLTab.ManageFields);

  return <FSLNavBarLayoutImpl {...props} {...state} />;
}
