import type { GoogleSheetFieldMapping } from "./googleSheet";

export const allWorkspaceIntegrationImportOptionType = [
  "import_sharepoint",
  "import_zapier",
  "import_email",
  "import_googleDrive",
  "import_dropbox",
] as const;

export const allWorkspaceIntegrationExportOptionType = [
  "export_zapier",
  "export_webhook",
  "export_googleSheets",
] as const;

export type WorkspaceIntegrationOptionType =
  | (typeof allWorkspaceIntegrationImportOptionType)[number]
  | (typeof allWorkspaceIntegrationExportOptionType)[number];

// TODO: Update after handle get current SharePoint subscription #2227
export interface WorkspaceSharePointIntegrationConfiguration {
  optionType: "import_sharepoint";
  siteUrlSubdomain: string;
  sharePointSubscription: unknown | null;
}

const initialSharePointIntegrationConfiguration: WorkspaceSharePointIntegrationConfiguration =
  {
    optionType: "import_sharepoint",
    siteUrlSubdomain: "",
    sharePointSubscription: null,
  } as const;

// TODO: Update after implementing google sheets export
export interface WorkspaceExportGoogleSheetsIntegrationConfiguration {
  optionType: "export_googleSheets";
  isUpsertMode: boolean;
  mappings: GoogleSheetFieldMapping[];
  oauthCredentialId: string | null;
  sheetId: string | null;
  spreadsheetId: string | null;
}

const initialExportToGoogleSheetsIntegrationConfiguration: WorkspaceExportGoogleSheetsIntegrationConfiguration =
  {
    optionType: "export_googleSheets",
    isUpsertMode: false,
    mappings: [],
    oauthCredentialId: null,
    sheetId: null,
    spreadsheetId: null,
  };

// TODO: Update after handle webhook export #2203
export interface WorkspaceWebhookIntegrationConfiguration {
  optionType: "export_webhook";
}

export interface WorkspaceZapierIntegrationConfiguration {
  optionType: "export_zapier";
  zapId: string;
  webhookId: string;
}

const initialWebhookIntegrationConfiguration: WorkspaceWebhookIntegrationConfiguration =
  {
    optionType: "export_webhook",
  } as const;

// NOTE: Placeholder for coming soon options
export interface WorkspaceUnknownIntegrationConfiguration {
  optionType:
    | "import_zapier"
    | "import_email"
    | "import_googleDrive"
    | "import_dropbox";
}

export type WorkspaceIntegrationConfiguration =
  | WorkspaceExportGoogleSheetsIntegrationConfiguration
  | WorkspaceSharePointIntegrationConfiguration
  | WorkspaceWebhookIntegrationConfiguration
  | WorkspaceUnknownIntegrationConfiguration
  | WorkspaceZapierIntegrationConfiguration;

export const initialWorkspaceIntegrationConfiguration: Record<
  Exclude<WorkspaceIntegrationOptionType, "export_zapier">,
  WorkspaceIntegrationConfiguration
> = {
  import_sharepoint: initialSharePointIntegrationConfiguration,
  import_zapier: { optionType: "import_zapier" },
  import_email: { optionType: "import_email" },
  import_googleDrive: { optionType: "import_googleDrive" },
  import_dropbox: { optionType: "import_dropbox" },
  export_googleSheets: initialExportToGoogleSheetsIntegrationConfiguration,
  export_webhook: initialWebhookIntegrationConfiguration,
} as const;

// SharePoint Integration

export interface FilePickerSharePointFile {
  id: string;
  parentReference: {
    driveId: string;
    sharepointIds: {
      listId: string;
      webId: string;
      siteId: string;
    };
  };
  sharepointIds: {
    listItemUniqueId: string;
    listItemId: string;
    listId: string;
    webId: string;
    siteId: string;
  };
  "@sharePoint.embedUrl": string;
  "@sharePoint.endpoint": string;
}

export interface PickCommandPayloadData {
  command: "pick";
  items: FilePickerSharePointFile[];
  keepSharing: boolean;
}
