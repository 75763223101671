@import "../../styles/variables.scss";

.tabs {
  display: flex;
  flex-direction: row;
  gap: 8px;

  .tab {
    cursor: pointer;
    height: 44px;
    padding-top: 2px;

    .tab-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      font-size: 14px;
      line-height: 20px;

      height: 20px;
      margin: 10px 8px;

      > i {
        text-align: center;
        width: 20px;
        height: 20px;
        margin-right: 4px;

        > svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    > hr {
      display: none;
    }
  }

  .tab:hover {
    background-color: #f3f2f1;
  }

  .tab.selected {
    font-weight: 600;

    > hr {
      position: relative;
      z-index: 1;
      display: block;
      border: 0;
      height: 2px;
      background-color: $theme-primary;
      margin: 0 8px;
      transition: all 0.3s;
    }

    &:hover {
      hr {
        margin: 0 0;
        transition: all 0.3s;
      }
    }
  }
}
