import {
  CellData,
  CellDataType,
  MenuItem,
  RowData,
  SettingCellData,
  TagAutoCompleteCellData,
  TagCellData,
  TagKey,
  TokenCellData,
  TokenCellDefaultValue,
  makeTagAutoCompleteCell,
  makeTagCell,
  makeTokenCell,
} from "../../../types/advancedTokenSetup/table";
import { CustomTokenType, InfoField } from "../../advancedPatternMatching";
import { KeyValueResp } from "../../keyValue";

class CSVRowIterator {
  cursor = 0;
  headerRow: string[];
  row: string[];
  constructor(headerRow: string[], row: string[]) {
    this.row = row;
    this.headerRow = headerRow;
  }

  private parseBoolean(text: string): boolean {
    return text.toLowerCase() === "true";
  }

  private parseKeyValuePosition(
    text: string
  ): KeyValueResp["position"] | undefined {
    if (
      ["above", "left", "right", "below"].findIndex(
        t => t === text.toLowerCase()
      ) !== -1
    ) {
      return text as KeyValueResp["position"];
    }
    return undefined;
  }

  private next(
    count: number | ((header: string) => boolean)
  ): [data: string[] | undefined, headers: string[] | undefined] {
    const _count =
      typeof count === "function"
        ? this.headerRow.slice(this.cursor).filter(count).length
        : count;
    const cur = this.cursor;
    this.cursor += _count;
    if (this.cursor > this.row.length) {
      return [undefined, undefined];
    }
    return [
      this.row.slice(cur, cur + _count),
      this.headerRow.slice(cur, cur + _count),
    ];
  }

  currentHeader(): string | undefined {
    return this.headerRow[this.cursor];
  }

  nextTagField(tagKey: TagKey): TagCellData {
    const [data] = this.next(1);
    if (data) {
      return {
        type: CellDataType.TagCellData,
        tag: data[0],
        tagKey,
      };
    }
    return {
      type: CellDataType.TagCellData,
      tag: "",
      tagKey,
    };
  }

  nextTagAutoCompleteField(tagKey: TagKey): TagAutoCompleteCellData {
    const [data] = this.next(1);
    if (data) {
      return {
        type: CellDataType.TagAutoCompleteCellData,
        tag: data[0],
        tagKey,
        options: [],
      };
    }
    return {
      type: CellDataType.TagAutoCompleteCellData,
      tag: "",
      tagKey,
      options: [],
    };
  }
  nextTokenField(type: CustomTokenType | undefined = undefined): TokenCellData {
    const [data] = this.next(3);
    if (data) {
      return {
        type: CellDataType.TokenCellData,
        token: {
          type,
          pattern: data[0],
          isClearSpace: this.parseBoolean(data[1]),
          isExactMatch: this.parseBoolean(data[2]),
          isRegex: undefined,
        },
      };
    }
    return {
      type: CellDataType.TokenCellData,
      token: {
        type,
        pattern: "",
        isClearSpace: false,
        isExactMatch: false,
        isRegex: undefined,
      },
    };
  }
  nextPatternTokenField(
    type: CustomTokenType | undefined = undefined
  ): TokenCellData {
    const [data] = this.next(4);
    if (data) {
      return {
        type: CellDataType.TokenCellData,
        token: {
          type,
          pattern: data[0],
          isClearSpace: this.parseBoolean(data[1]),
          isExactMatch: this.parseBoolean(data[2]),
          isRegex: this.parseBoolean(data[3]),
        },
      };
    }
    return {
      type: CellDataType.TokenCellData,
      token: {
        type,
        pattern: "",
        isClearSpace: false,
        isExactMatch: false,
        isRegex: false,
      },
    };
  }
  nextSettingField(
    target: SettingCellData["target"],
    modifiedAt: number
  ): SettingCellData {
    const [data] = this.next(header => {
      return header.startsWith(target);
    });
    const emptySettingField: SettingCellData = {
      type: CellDataType.SettingCellData,
      target,
    };

    if (data === undefined) {
      return emptySettingField;
    }
    if (data[0] !== "") {
      return {
        type: CellDataType.SettingCellData,
        target,
        config: {
          type: "custom_model",
          config: {
            custom_model_id: "TBC",
            field_name: data[0],
          },
        },
      };
    }
    if (data === undefined) {
      return emptySettingField;
    }
    const tokens: KeyValueResp["tokens"] = [];
    let tokenIndex = 3;
    while (true) {
      const [token, isFuzzySearch] = [data[tokenIndex], data[tokenIndex + 1]];
      if (token === undefined || token === "" || isFuzzySearch === undefined) {
        break;
      }
      tokens.push({
        token,
        use_fuzzy_search: this.parseBoolean(isFuzzySearch),
      });
      tokenIndex += 2;
    }
    if (data[1] === "" && tokens.length === 0) {
      return emptySettingField;
    }
    const keyValueConfig: KeyValueResp = {
      pattern: data[1],
      position: this.parseKeyValuePosition(data[2]) || "right",
      name: "",
      created_at: modifiedAt,
      tokens,
    };
    return {
      type: CellDataType.SettingCellData,
      target,
      config: {
        type: "key_value",
        config: keyValueConfig,
      },
    };
  }
}

const DataFields = {
  [MenuItem.TagMall]: ["mall_id"],
  [MenuItem.TagMerchant]: ["merchant_id"],
  [MenuItem.ClosestMatchRuleMall]: ["mall_id", "token"],
  [MenuItem.ClosestMatchRuleMerchant]: ["merchant_id", "token"],
  [MenuItem.ExactMatchRule]: [
    "merchant_id",
    "mall_id",
    "brand_name",
    "email",
    "phone_number",
    "fax_number",
    "url",
    "positive_token",
    "negative_token",
  ],
  [MenuItem.FieldReplacementMall]: [
    "mall_id",
    "invoice_number",
    "total_amount",
  ],
  [MenuItem.FieldReplacementMerchant]: [
    "merchant_id",
    "invoice_number",
    "total_amount",
  ],
} as Record<MenuItem, string[]>;

export type ProcessedCSVItem = {
  value: string;
  properties: Record<string, string>;
};

function parseBooleanString(text?: string): boolean | undefined {
  if (text === undefined) {
    return undefined;
  }
  return text.toLowerCase() === "true";
}

export class AdvancedMerchantPatternMatchingCSVReader {
  /* Build mapping table according to the header and possible fields
   */
  buildMappingTable(dataFields: string[], header: string[]) {
    const mappingTable = {} as Record<
      string,
      {
        value: number;
        properties: Record<string, number>;
      }[]
    >;

    dataFields.forEach(field => {
      mappingTable[field] = [];
      header.forEach((column, index) => {
        const normalizedColumn = column.toLowerCase().trim();
        if (normalizedColumn === field) {
          const newItem = {
            value: index, // The column index of the field
            properties: {},
          };
          mappingTable[field].push(newItem);
        } else if (normalizedColumn.startsWith(field)) {
          const property = normalizedColumn
            .replace(field + "_", "")
            .toLowerCase();

          // If a `field` is never set, use a dummy item which will be discard
          const lastItem = mappingTable[field].slice(-1)[0] ?? {
            properties: {},
          };
          lastItem.properties[property as string] = index; // The column index of the property
        }
      });
    });
    return mappingTable;
  }

  preprocess(
    fields: string[],
    csv: string[][]
  ): Record<string, ProcessedCSVItem[]>[] {
    const clone = [...csv];
    const header = clone.shift();
    if (header === undefined) {
      return [];
    }
    const mappingTable = this.buildMappingTable(fields, header);

    const isEmpty = !Object.values(mappingTable).some(
      items => items.length > 0
    );
    if (isEmpty) {
      return [];
    }

    const rows = clone.map(row => {
      const rowItem = {} as Record<string, ProcessedCSVItem[]>;

      fields.forEach(field => {
        const items = mappingTable[field].map(mapping => {
          const processedItem: ProcessedCSVItem = {
            value: row[mapping.value],
            properties: {},
          };
          Object.keys(mapping.properties).forEach(key => {
            processedItem.properties[key] = row[mapping.properties[key]];
          });
          return processedItem;
        });
        rowItem[field] = items;
      });

      return rowItem;
    });

    return rows;
  }

  process(tableType: MenuItem, csv: string[][], modifiedAt: number): RowData[] {
    switch (tableType) {
      case MenuItem.TagMall:
      case MenuItem.TagMerchant:
        return this.processTagTable(tableType, csv, modifiedAt);
      case MenuItem.ExactMatchRule:
        return this.processExactMatchRuleTable(csv, modifiedAt);
      case MenuItem.ClosestMatchRuleMall:
      case MenuItem.ClosestMatchRuleMerchant:
        return this.processClosestMatchRuleTable(tableType, csv, modifiedAt);
      case MenuItem.FieldReplacementMall:
      case MenuItem.FieldReplacementMerchant:
        // Not supported any more
        return [];
    }
  }

  findTagCell(row: Record<string, any>, tagKey: TagKey) {
    let tag = "";
    try {
      tag = row[tagKey][0].value;
    } catch {}
    return makeTagCell(tag, tagKey);
  }

  findTagAutoCompleteCell(row: Record<string, any>, tagKey: TagKey) {
    let tag = "";
    try {
      tag = row[tagKey][0].value;
    } catch {}
    return makeTagAutoCompleteCell(tag, tagKey);
  }

  findTokenCell(row: Record<string, any>, tokenType: CustomTokenType) {
    const infoFields = {
      type: tokenType,
      pattern: "",
      is_exact_match_only: undefined,
      is_regex: undefined,
      is_ignore_white_space: undefined,
    } as InfoField;
    try {
      const pattern = row[tokenType][0].value;
      const properties = row[tokenType][0].properties;
      infoFields.pattern = pattern;
      infoFields.is_exact_match_only = parseBooleanString(
        properties["is_exact_match"]
      );
      infoFields.is_ignore_white_space = parseBooleanString(
        properties["is_remove_space"]
      );
      infoFields.is_regex = parseBooleanString(properties["is_regex"]);
    } catch {}
    return makeTokenCell(
      tokenType === "token" ? undefined : tokenType,
      infoFields,
      TokenCellDefaultValue[tokenType]
    );
  }

  findPatternTokenFields(row: Record<string, any>, tokenType: CustomTokenType) {
    if (row[tokenType] === undefined) {
      return [];
    }

    return row[tokenType].map((item: any) => {
      const token = {
        type: tokenType,
        pattern: item.value ?? "",
        isClearSpace:
          parseBooleanString(item.properties["is_remove_space"]) ?? false,
        isExactMatch:
          parseBooleanString(item.properties["is_exact_match"]) ?? false,
        isRegex: parseBooleanString(item.properties["is_regex"]) ?? false,
      };

      return {
        type: CellDataType.TokenCellData,
        token,
      };
    });
  }

  processTagTable(tableType: MenuItem, csv: string[][], modifiedAt: number) {
    const preprocessedData = this.preprocess(DataFields[tableType], csv);
    const rows = preprocessedData.map((row, index) => {
      const rowData = {
        order: index + 1,
        modifiedAt,
        data: [],
      } as RowData;
      const tagKey = tableType === MenuItem.TagMall ? "mall_id" : "merchant_id";
      try {
        rowData.data.push(this.findTagCell(row, tagKey));
      } catch {}
      return rowData;
    });

    return rows;
  }

  processExactMatchRuleTable(csv: string[][], modifiedAt: number) {
    const preprocessedData = this.preprocess(
      DataFields[MenuItem.ExactMatchRule],
      csv
    );
    const rows = preprocessedData.map((row, index) => {
      const rowData = {
        order: index + 1,
        modifiedAt,
        data: [],
      } as RowData;
      try {
        rowData.data = [
          this.findTagAutoCompleteCell(row, "merchant_id"),
          this.findTagAutoCompleteCell(row, "mall_id"),
          this.findTokenCell(row, "brand_name"),
          this.findTokenCell(row, "email"),
          this.findTokenCell(row, "phone_number"),
          this.findTokenCell(row, "fax_number"),
          this.findTokenCell(row, "url"),
        ];

        let positiveTokens = this.findPatternTokenFields(row, "positive_token");

        if (positiveTokens.length < 2) {
          positiveTokens = positiveTokens.concat(
            new Array(2 - positiveTokens.length).fill(
              makeTokenCell("positive_token")
            )
          );
        }

        let negativeTokens = this.findPatternTokenFields(row, "negative_token");
        if (negativeTokens.length < 2) {
          negativeTokens = negativeTokens.concat(
            new Array(2 - negativeTokens.length).fill(
              makeTokenCell("negative_token")
            )
          );
        }

        rowData.data = rowData.data
          .concat(positiveTokens)
          .concat(negativeTokens);
      } catch {}
      return rowData;
    });

    return rows;
  }

  processClosestMatchRuleTable(
    tableType: MenuItem,
    csv: string[][],
    modifiedAt: number
  ) {
    const preprocessedData = this.preprocess(DataFields[tableType], csv);
    const rows = preprocessedData.map((row, index) => {
      const rowData = {
        order: index + 1,
        modifiedAt,
        data: [],
      } as RowData;
      try {
        rowData.data = [
          this.findTagAutoCompleteCell(
            row,
            tableType === MenuItem.ClosestMatchRuleMall
              ? "mall_id"
              : "merchant_id"
          ),
          this.findTokenCell(row, "token"),
        ];
      } catch {}
      return rowData;
    });

    return rows;
  }
}

export class MerchantPatternMatchingCSVReader {
  private parseTagRow(tagKey: TagKey, iterator: CSVRowIterator): CellData[] {
    return [iterator.nextTagField(tagKey)];
  }

  private parseExactMatchRuleRow(iterator: CSVRowIterator): CellData[] {
    let fields: CellData[] = [
      iterator.nextTagAutoCompleteField("merchant_id"),
      iterator.nextTagAutoCompleteField("mall_id"),
      iterator.nextTokenField("brand_name"),
      iterator.nextPatternTokenField("email"),
      iterator.nextTokenField("phone_number"),
      iterator.nextTokenField("fax_number"),
      iterator.nextTokenField("url"),
    ];

    for (const key of ["positive_token", "negative_token"]) {
      const tokenType = key as CustomTokenType;
      let tokenFields: CellData[] = [];
      while (true) {
        if (iterator.currentHeader()?.startsWith(tokenType)) {
          tokenFields.push(iterator.nextPatternTokenField(tokenType));
          continue;
        }
        break;
      }
      if (tokenFields.length < 2) {
        tokenFields = tokenFields.concat(
          new Array(2 - tokenFields.length).fill(makeTokenCell(tokenType))
        );
      }
      fields = fields.concat(tokenFields);
    }
    return fields;
  }

  private parseClosestMatchRuleRow(
    tagKey: TagKey,
    iterator: CSVRowIterator
  ): CellData[] {
    const fields: CellData[] = [iterator.nextTagAutoCompleteField(tagKey)];
    return fields.concat(iterator.nextTokenField());
  }
  private parseFieldReplacementRow(
    tagKey: TagKey,
    iterator: CSVRowIterator,
    modifiedAt: number
  ): CellData[] {
    return [
      iterator.nextTagAutoCompleteField(tagKey),
      iterator.nextSettingField("invoice_number", modifiedAt),
      iterator.nextSettingField("total_amount", modifiedAt),
    ];
  }

  parseRow(
    tableType: MenuItem,
    header: string[],
    row: string[],
    modifiedAt: number
  ): CellData[] {
    const iterator = new CSVRowIterator(header, row);
    switch (tableType) {
      case MenuItem.TagMall:
        return this.parseTagRow("mall_id", iterator);
      case MenuItem.TagMerchant:
        return this.parseTagRow("merchant_id", iterator);
      case MenuItem.ExactMatchRule:
        return this.parseExactMatchRuleRow(iterator);
      case MenuItem.ClosestMatchRuleMall:
        return this.parseClosestMatchRuleRow("mall_id", iterator);
      case MenuItem.ClosestMatchRuleMerchant:
        return this.parseClosestMatchRuleRow("merchant_id", iterator);
      case MenuItem.FieldReplacementMall:
        return this.parseFieldReplacementRow("mall_id", iterator, modifiedAt);
      case MenuItem.FieldReplacementMerchant:
        return this.parseFieldReplacementRow(
          "merchant_id",
          iterator,
          modifiedAt
        );
    }
  }
}
