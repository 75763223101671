/* tslint:disable: jsx-use-translation-function */
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";
import Highlight from "react-highlight";

import { ScriptFunctionContent, ScriptFunctionParameter } from "../../models";
import styles from "./styles.module.scss";

interface FunctionParameterProps {
  parameter: ScriptFunctionParameter;
  prefix?: string;
}

function FunctionParameter(props: FunctionParameterProps) {
  const { prefix } = props;
  const { name, type, isOptional, fields } = props.parameter;

  const parameterName = `${name} (${type})`;
  return (
    <li>
      <div className={styles["parameter-name"]}>
        {parameterName}
        {isOptional && (
          <span className={styles["optional"]}>
            <FormattedMessage id="scriptdoc.optional" />
          </span>
        )}
      </div>
      <div className={styles["parameter-description"]}>
        <FormattedMessage
          id={`scriptdoc.${prefix ? prefix + "." : ""}${name}.desc`}
        />
      </div>
      {fields && fields.length > 0 && (
        <ul>
          {fields.map((field, index) => (
            <FunctionParameter
              key={index}
              parameter={field}
              prefix={`${prefix ? prefix + "." : ""}${name}`}
            />
          ))}
        </ul>
      )}
    </li>
  );
}

function AvaiableFunction(props: ScriptFunctionContent) {
  const { name, parameters, returnValue } = props;

  const functionArguments = `(${parameters.map(x => x.name).join(", ")})`;

  return (
    <div className={styles["function-doc"]}>
      <p className={styles["function-title"]}>
        <strong>
          {name}
          {functionArguments}
        </strong>
      </p>
      <p>
        <FormattedMessage id={`scriptdoc.${name}.desc`} />
      </p>
      <div className={styles["parameters-container"]}>
        <p>
          <FormattedMessage id="scriptdoc.parameters" />
        </p>
        {parameters.map((parameter, index) => (
          <ul key={index}>
            {<FunctionParameter prefix={name} parameter={parameter} />}
          </ul>
        ))}
      </div>

      {returnValue && (
        <div className={styles["parameters-container"]}>
          <p>
            <FormattedMessage id="scriptdoc.return_value" />
          </p>
          <ul>
            <FunctionParameter
              prefix={`${name}.return_value`}
              parameter={returnValue}
            />
          </ul>
        </div>
      )}
    </div>
  );
}

function JavaScriptIoContentForDetectionRegionField() {
  const inputStructure = `interface Input = {
  text: string,
  textbox: TextBox
}`;

  const textBoxInterface = `interface TextBox {
  // TopLeft.x, TopLeft.y, TopRight.x, TopRight.y, BottomRight.x, BottomRight.y, BottomLeft.x, BottomLeft.y
  vertices: number[], 
  orientation: "Up" | "Left" | "Right" | "Down",
  lines: {
      words: Word[]
  }
}
interface Word {
  // TopLeft.x, TopLeft.y, TopRight.x, TopRight.y, BottomRight.x, BottomRight.y, BottomLeft.x, BottomLeft.y
  vertices: number[],
  value: string, // text in the word
  symbols: Symbol[],
  confidence: number | null
}
interface Symbol {
  // TopLeft.x, TopLeft.y, TopRight.x, TopRight.y, BottomRight.x, BottomRight.y, BottomLeft.x, BottomLeft.y
  vertices: number[],
  value: string // character in the symbol
}`;

  return (
    <div className={styles["tab-content"]}>
      <p>
        <span className={styles["code-word"]}>input</span> will be a global
        variable accessible in the JS context. The structure of{" "}
        <span className={styles["code-word"]}>input</span> is the following:
      </p>
      <div className={styles["code-block"]}>
        <Highlight className="TypeScript">{inputStructure}</Highlight>
      </div>
      <p>where</p>
      <div className={styles["code-block"]}>
        <Highlight className="TypeScript">{textBoxInterface}</Highlight>
      </div>
      <p>
        The script is expected to write the output to the{" "}
        <span className={styles["code-word"]}>result</span> value in the global
        scope. If not, an exception will be raised. What is assigned to{" "}
        <span className={styles["code-word"]}>result</span> will be in the{" "}
        <span className={styles["code-word"]}>content</span> field of the output
        response.
      </p>
    </div>
  );
}

function JavaScriptIoContentForPostProcessScript() {
  return (
    <div className={styles["tab-content"]}>
      <p>
        <span className={styles["code-word"]}>input</span> will be a global
        variable accessible in the JS context.
        <br />
        The structure of <span className={styles["code-word"]}>input</span> is
        the unmodified JSON object returned from the extractor.
      </p>
      <br />
      <p>
        The script is expected to write the output to the{" "}
        <span className={styles["code-word"]}>result</span> value in the global
        scope.
        <br />
        If not, an exception will be raised. What is assigned to{" "}
        <span className={styles["code-word"]}>result</span> will be in the JSON
        response from the API.
      </p>
    </div>
  );
}

export {
  AvaiableFunction,
  JavaScriptIoContentForDetectionRegionField,
  JavaScriptIoContentForPostProcessScript,
};
