import {
  Checkbox,
  Dropdown,
  ICheckboxProps,
  IDropdownOption,
  Icon,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";

import { useFormatterEditor } from "../../contexts/formatterEditor";
import { useLocale } from "../../contexts/locale";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

enum RemoveCharactersPresetType {
  Number = "numeric",
  SpecialCharacter = "special_character",
  Space = "space",
  LatinAlphabet = "latin_alphabet",
  Alphabet = "alphabet",
  NewLine = "new_line",
  Chinese = "chinese",
}

enum RemoveCharactersOperatorType {
  Preset = "preset",
  Custom = "custom",
  Regex = "regex",
}

type RemoveCharactersPresetConfig = {
  [key in RemoveCharactersPresetType]?: boolean;
};

type RemoveCharactersConfig = {
  type: RemoveCharactersOperatorType | undefined;
  value: string | RemoveCharactersPresetConfig | undefined;
};

type RemoveCharactersConfigCache = {
  preset: RemoveCharactersPresetConfig;
  custom: string;
  regex: string;
};

function _RemoveCharactersCheckboxLabel(props: {
  optionKey: string;
  label: string;
}) {
  const { optionKey, label } = props;

  const { localized } = useLocale();
  const specialCharTooltip = localized(
    "formatter.actions.remove_characters.special_char_tooltip",
    {
      curly_begin: "{",
      curly_end: "}",
    }
  );

  return (
    <>
      <span className="ms-Checkbox-label">{label}</span>
      {optionKey === RemoveCharactersPresetType.SpecialCharacter && (
        <TooltipHost
          content={specialCharTooltip}
          calloutProps={{
            isBeakVisible: true,
          }}
        >
          <Icon iconName="Info" className={styles["info-icon"]} />
        </TooltipHost>
      )}
    </>
  );
}

const RemoveCharactersCheckboxLabel = React.memo(
  _RemoveCharactersCheckboxLabel
);

function createRemoveCharactersCheckboxLabel(key: string) {
  return (props?: ICheckboxProps) => {
    const { label } = props ?? { name: "" };

    return (
      <RemoveCharactersCheckboxLabel optionKey={key} label={label ?? ""} />
    );
  };
}

function _FormatterRemoveCharactersConfigPanel() {
  const { localized } = useLocale();

  const { selectedStep, setSelectedConfig } = useFormatterEditor();

  const selectedConfig = React.useMemo<RemoveCharactersConfig>(() => {
    return (
      selectedStep?.config ?? {
        type: RemoveCharactersOperatorType.Preset,
        value: {},
      }
    );
  }, [selectedStep]);

  const [configCache, setConfigCache] =
    React.useState<RemoveCharactersConfigCache>(() => {
      const ret = {
        preset: {},
        custom: "",
        regex: "",
      };
      if (selectedConfig.type === RemoveCharactersOperatorType.Preset) {
        ret.preset =
          (selectedConfig.value as RemoveCharactersPresetConfig) ?? {};
      } else if (selectedConfig.type === RemoveCharactersOperatorType.Custom) {
        ret.custom = selectedConfig.value as string;
      } else if (selectedConfig.type === RemoveCharactersOperatorType.Regex) {
        ret.regex = selectedConfig.value as string;
      }
      return ret;
    });

  const presetItems = React.useMemo(() => {
    return Object.keys(RemoveCharactersPresetType).map(type => {
      const key =
        RemoveCharactersPresetType[
          type as keyof typeof RemoveCharactersPresetType
        ];
      const renderItem = createRemoveCharactersCheckboxLabel(key);
      return {
        key,
        label: localized(
          `formatter.actions.remove_characters.preset.${key as string}`
        ),
        isChecked:
          configCache.preset[key as RemoveCharactersPresetType] ?? false,
        renderItem,
      };
    });
  }, [configCache, localized]);

  const choices = React.useMemo(() => {
    return [
      {
        key: RemoveCharactersOperatorType.Preset,
        text: localized(
          "formatter.actions.remove_characters.filter.pre_defined_type"
        ),
      },
      {
        key: RemoveCharactersOperatorType.Custom,
        text: localized(
          "formatter.actions.remove_characters.filter.custom_value"
        ),
      },
      {
        key: RemoveCharactersOperatorType.Regex,
        text: localized("formatter.actions.remove_characters.filter.regex"),
      },
    ];
  }, [localized]);

  const type = selectedConfig.type ?? RemoveCharactersOperatorType.Preset;

  const onTypeChanged = React.useCallback(
    (_ev?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option) {
        const type = option.key as RemoveCharactersOperatorType;
        const newConfig = {
          type,
          value: configCache[type],
        };
        setSelectedConfig(newConfig);
      }
    },
    [configCache, setSelectedConfig]
  );

  const setPresetItemChecked = React.useCallback(
    (key: RemoveCharactersPresetType, checked: boolean) => {
      const preset = {
        ...configCache.preset,
        [key]: checked,
      };
      const newConfigCache = {
        ...configCache,
        preset,
      };
      setConfigCache(newConfigCache);

      const newConfig = {
        type,
        value: preset,
      };
      setSelectedConfig(newConfig);
    },
    [configCache, setSelectedConfig, type]
  );

  const onCustomValueChanged = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      const newConfigCache: RemoveCharactersConfigCache = {
        ...configCache,
        custom: value ?? "",
      };
      setConfigCache(newConfigCache);

      const newConfig = {
        type,
        value,
      };
      setSelectedConfig(newConfig);
    },
    [configCache, setSelectedConfig, type]
  );

  const onRegexValueChanged = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      const newCachedConfig: RemoveCharactersConfigCache = {
        ...configCache,
        regex: value ?? "",
      };
      setConfigCache(newCachedConfig);

      const newConfig = {
        type,
        value,
      };
      setSelectedConfig(newConfig);
    },
    [configCache, setSelectedConfig, type]
  );

  return (
    <div className={styles["remove-characters"]}>
      <Dropdown
        className={styles["filter-group"]}
        label={localized("formatter.actions.remove_characters.filter.label")}
        options={choices}
        selectedKey={type}
        onChange={onTypeChanged}
      />
      {type === RemoveCharactersOperatorType.Preset && (
        <div className={styles["grid"]}>
          {presetItems.map((item, index) => {
            return (
              <div key={index}>
                <Checkbox
                  className={styles["checkbox"]}
                  label={item.label}
                  checked={item.isChecked}
                  onRenderLabel={item.renderItem}
                  onChange={(
                    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                    checked?: boolean
                  ) => {
                    ev?.preventDefault();
                    ev?.stopPropagation();
                    setPresetItemChecked(item.key, checked ?? false);
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
      {type === RemoveCharactersOperatorType.Custom && (
        <TextField
          className={styles["custom-value"]}
          labelId="formatter.actions.remove_characters.custom.label"
          multiline
          placeholder={localized(
            "formatter.actions.remove_characters.filter.placeholder"
          )}
          value={configCache.custom}
          onChange={onCustomValueChanged}
        />
      )}
      {type === RemoveCharactersOperatorType.Regex && (
        <TextField
          labelId="formatter.actions.remove_characters.regex.label"
          value={configCache.regex}
          placeholder={localized(
            "formatter.actions.remove_characters.regex.placeholder"
          )}
          onChange={onRegexValueChanged}
        />
      )}
    </div>
  );
}

export const FormatterRemoveCharactersConfigPanel = React.memo(
  _FormatterRemoveCharactersConfigPanel
);
