import * as React from "react";
import { Navigate, useNavigate } from "react-router";

import { useFormGroupActionCreator } from "../actions/formGroup";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import FormGroupEditor from "../components/FormGroupEditor";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import ScriptEditorModal from "../components/ScriptEditorModal";
import { isPrebuiltExtractors } from "../constants/prebuiltExtractor";
import HeaderContainer from "../containers/Header";
import {
  CommonFormGroupContainerErrorState,
  CommonFormGroupContainerLoadingState,
  useExtractorOptionsForCombinedExtractor,
  useFormGroup,
} from "../hooks/form_group";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { ExtractorOptionForCombinedExtractor } from "../types/extractor";
import {
  DetailFormGroup,
  FormGroupAnchorBase,
  FormGroupDocumentDetectionBase,
  FormGroupTokenGroupBase,
} from "../types/formGroup";
import {
  FormGroupNavBarLayout,
  FormGroupNavTabKey,
} from "./FormGroupNavBarLayout";

type PathParam = {
  formGroupId: string;
};

function useTemplateSentry(formGroup: DetailFormGroup) {
  const navigate = useNavigate();
  const { formGroupId } = useUnsafeParams<PathParam>();

  React.useEffect(() => {
    if (formGroupId === formGroup.id && formGroup.isTemplate) {
      navigate(`/form-group/${formGroupId}/test`);
    }
  }, [formGroupId, formGroup, navigate]);
}

interface FormGroupEditorProps {
  formGroup: DetailFormGroup;
  updateFormGroup: ReturnType<
    typeof useFormGroupActionCreator
  >["updateFormGroup"];
  exportFormGroup: ReturnType<
    typeof useFormGroupActionCreator
  >["exportFormGroup"];
  extractorOptions: ExtractorOptionForCombinedExtractor[];
}

const FormGroupEdit = React.memo((props: FormGroupEditorProps) => {
  const { formGroup, updateFormGroup, exportFormGroup, extractorOptions } =
    props;

  const formGroupId = formGroup.id;
  const resourceOwnerId = useAppSelector(
    state => state.resourceOwner.resourceOwnerId
  );

  const toast = useToast();

  const formGroupRef = React.useRef(formGroup);
  formGroupRef.current = formGroup;

  useTemplateSentry(formGroup);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [isScriptModalOpened, setisScriptModalOpened] =
    React.useState<boolean>(false);

  const onCloseFormGroupScriptModal = React.useCallback(() => {
    setisScriptModalOpened(false);
  }, []);

  const doUpdateFormGroupAnchors = React.useCallback(
    async (anchors: FormGroupAnchorBase[]) => {
      try {
        await updateFormGroup(
          formGroup.id,
          {
            anchors: anchors,
          },
          formGroup.updatedAt
        );
      } catch (e) {
        console.error("Update form group anchor error: ", e);
        toast.error("error.fail_to_update_form_group");
      }
    },
    [formGroup.id, formGroup.updatedAt, toast, updateFormGroup]
  );

  const doUpdateDocumentDetections = React.useCallback(
    async (documentDetections: FormGroupDocumentDetectionBase[]) => {
      try {
        await updateFormGroup(
          formGroup.id,
          {
            documentDetections: documentDetections,
          },
          formGroup.updatedAt
        );
      } catch (e) {
        console.error("Update form group document detection error: ", e);
        toast.error("error.fail_to_update_form_group");
      }
    },
    [formGroup.id, formGroup.updatedAt, toast, updateFormGroup]
  );

  const doUpdateFormGroupTokenGroups = React.useCallback(
    async (tokenGroups: FormGroupTokenGroupBase[]) => {
      try {
        await updateFormGroup(
          formGroup.id,
          {
            tokenGroups: tokenGroups,
          },
          formGroup.updatedAt
        );
      } catch (e) {
        console.error("Update form group token group error: ", e);
        toast.error("error.fail_to_update_form_group");
      }
    },
    [formGroup.id, formGroup.updatedAt, toast, updateFormGroup]
  );

  const doUpdateFallbackForm = React.useCallback(
    async (formId: string | null) => {
      try {
        await updateFormGroup(
          formGroup.id,
          {
            fallbackFormId: formId,
          },
          formGroup.updatedAt
        );
      } catch (e) {
        console.error("Update form group fallback form error: ", e);
        toast.error("error.fail_to_update_form_group");
      }
    },
    [formGroup.id, formGroup.updatedAt, toast, updateFormGroup]
  );

  const onOpenFormGroupScriptModal = React.useCallback(() => {
    setisScriptModalOpened(true);
  }, []);

  const updatePostProcessScript = React.useCallback(
    async (script: string) => {
      if (formGroupRef.current.config.post_process_script !== script) {
        setIsLoading(true);
        try {
          await updateFormGroup(
            formGroup.id,
            {
              config: {
                ...formGroupRef.current.config,
                post_process_script:
                  script === undefined || script.trim() === ""
                    ? undefined
                    : script,
              },
            },
            formGroup.updatedAt
          );
        } catch (e) {
          console.error("Update form group script error: ", e);
          toast.error("error.fail_to_update_form_group");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [formGroup.id, formGroup.updatedAt, toast, updateFormGroup]
  );

  const onExport = React.useCallback(() => {
    setIsLoading(true);
    exportFormGroup(formGroupId, resourceOwnerId)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        toast.error("error.unknown_error");
        setIsLoading(false);
      });
  }, [toast, formGroupId, resourceOwnerId, exportFormGroup]);

  const isNotEditable =
    formGroup?.config.prebuilt_extractor &&
    isPrebuiltExtractors(formGroup.config.prebuilt_extractor);

  if (isNotEditable && !isLoading) {
    return <Navigate to="../test" replace />;
  }

  return (
    <Main hasTop={true}>
      <FormGroupNavBarLayout selectedTab={FormGroupNavTabKey.ManageRules}>
        <FormGroupEditor
          formGroup={formGroup}
          extractorOptions={extractorOptions}
          onExport={onExport}
          onUpdateAnchors={doUpdateFormGroupAnchors}
          onUpdateTokenGroups={doUpdateFormGroupTokenGroups}
          onOpenFormGroupScriptModal={onOpenFormGroupScriptModal}
          onUpdateDocumentDetections={doUpdateDocumentDetections}
          onUpdateFallbackForm={doUpdateFallbackForm}
        />
      </FormGroupNavBarLayout>
      {isScriptModalOpened && (
        <ScriptEditorModal
          payload={{
            scriptType: "post_process_script",
            formGroup,
            onSave: updatePostProcessScript,
          }}
          onCloseModal={onCloseFormGroupScriptModal}
        />
      )}
      <LoadingModal isOpen={isLoading} />
    </Main>
  );
});

interface FormGroupEditContainerSuccessState {
  state: "success";
  formGroup: DetailFormGroup;
  extractorOptions: ExtractorOptionForCombinedExtractor[];
}

type FormGroupEditContainerState =
  | FormGroupEditContainerSuccessState
  | CommonFormGroupContainerErrorState
  | CommonFormGroupContainerLoadingState;

function useFormGroupEditorState(formGroupId: string) {
  const { formGroup, isFailedToFetchFormGroup } = useFormGroup(formGroupId);

  const { extractorOptions, isFailedToFetchExtractorOptions } =
    useExtractorOptionsForCombinedExtractor();

  const containerState: FormGroupEditContainerState =
    isFailedToFetchExtractorOptions || isFailedToFetchFormGroup
      ? { state: "error" }
      : formGroup &&
        formGroup.id === formGroupId &&
        extractorOptions !== undefined
      ? { state: "success", formGroup, extractorOptions }
      : { state: "loading" };

  return containerState;
}

export const FormGroupEditContainer = React.memo(() => {
  const { formGroupId } = useUnsafeParams<PathParam>();
  const { updateFormGroup, exportFormGroup } = useFormGroupActionCreator();

  const containerState = useFormGroupEditorState(formGroupId);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      {containerState.state === "success" ? (
        <FormGroupEdit
          formGroup={containerState.formGroup}
          exportFormGroup={exportFormGroup}
          updateFormGroup={updateFormGroup}
          extractorOptions={containerState.extractorOptions}
        />
      ) : containerState.state === "error" ? (
        <ErrorPlaceholder messageId="common.fail_to_fetch_form_group" />
      ) : null}
    </Layout>
  );
});

export default FormGroupEditContainer;
