import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { ImageViewer } from "../ImageViewer";
import styles from "./styles.module.scss";

interface ImageAssetSectionProps {
  sliceNumber: number;
  assetUrls: string[];
  orientation?: number;
}

export function ImageAssetSection(props: ImageAssetSectionProps) {
  const { assetUrls } = props;

  const renderDocuments = React.useMemo(() => {
    return assetUrls.map(assetUrl => {
      return {
        url: assetUrl,
        rotation: props.orientation === undefined ? 0 : -props.orientation,
      };
    });
  }, [props.orientation, assetUrls]);

  return (
    <div className={styles.imageAssetContainer}>
      {assetUrls.length > 0 ? (
        <div className={styles.imageContainer}>
          <ImageViewer
            className={styles["image-viewer"]}
            src={renderDocuments}
            zoomControlEnabled={true}
          />
        </div>
      ) : (
        <div className={styles.imageAssetNotFoundText}>
          <FormattedMessage id="workspace.document_detail.asset_image.not_found" />
        </div>
      )}
    </div>
  );
}
