.badge {
  display: inline-block;
  height: 28px;
  padding: 4px 10px;
  border-radius: 2px;
}

.badge-type-correct {
  background: #effcfa;
  color: #27ae60;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.badge-type-pending {
  background: #ffede7;
  color: #d83b01;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.badge-type-in-progress {
  background: #fff2e7;

  color: #d88201;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
