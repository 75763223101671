import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { RootState } from "../redux/types";
import { AuditLogJob } from "../types/auditLog";

export function useAuditLogActionCreator() {
  const { getState } = useStore<RootState>();

  const generateAuditLogCsv = useCallback(
    async (year: number, month: number) => {
      const state = getState();
      const { resourceOwnerId } = state.resourceOwner;
      await apiClient.generateAuditLogCsv(year, month, resourceOwnerId);
    },
    [getState]
  );

  const listAuditLogJobs = useCallback(
    async (id?: string): Promise<AuditLogJob[]> => {
      if (!id) {
        const { resourceOwnerId } = getState().resourceOwner;
        id = resourceOwnerId;
      }
      return await apiClient.listAuditLogJobs(id);
    },
    [getState]
  );

  return useMemo(
    () => ({
      generateAuditLogCsv,
      listAuditLogJobs,
    }),
    [generateAuditLogCsv, listAuditLogJobs]
  );
}
