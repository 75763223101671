@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.scrollable-modal {
  :global(.ms-Dialog-title) {
    padding-bottom: 8px;
  }

  :global(.ms-Dialog-inner) {
    padding-right: 0px;
    padding-left: 0px;
  }

  .scrollable-container {
    min-height: 176px;
    width: 100%;
  }

  .scrollable-contentContainer {
    padding: 0px 24px 0px 24px;
  }

  .footer {
    padding: 0px 24px 0px 24px;
  }

  .scrollable-root {
    height: calc(100% - 50px);
  }
}
