.container {
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  font: monospace;
  white-space: pre-wrap;
  background: #faf9f8;
  word-wrap: break-word;
  color: #000;
}

.line {
  padding: 0px 20px;
  line-height: 1.8em;
  display: inline-block;
}

.highlighted-line {
  line-height: 1.8em;
  display: inline-block;
  background-color: #f4f2e8;
}

.first-highlighted-line {
  padding-top: 10px;
}

.last-highlighted-line {
  padding-bottom: 10px;
}
