import { Dialog, DialogType, IDialogContentProps, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { AppConfig } from "../../config";
import { useLocale } from "../../contexts/locale";
import { useLocalizeRegion } from "../../hooks/app";
import { useRedirectToRegionForTeam } from "../../hooks/region";
import { TeamRef } from "../../types/team";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onCancel(): void;
  teamRefs: { [Key: string]: TeamRef[] };
}

export function OnboardingConfirmModal(props: Props) {
  const { isOpen, onCancel, teamRefs } = props;
  const { localized } = useLocale();
  const currentRegion = AppConfig.region;
  const localizeRegion = useLocalizeRegion();

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("onboarding.team_exists_in_other_region.title", {
        currentRegion: localizeRegion(currentRegion),
      }),
    }),
    [currentRegion, localizeRegion, localized]
  );

  const { redirectToRegionForTeam } = useRedirectToRegionForTeam();

  return (
    <Dialog
      minWidth={360}
      hidden={!isOpen}
      onDismiss={onCancel}
      dialogContentProps={dialogContentProps}
    >
      <div className={styles["model-description"]}>
        <FormattedMessage id="onboarding.team_exists_in_other_region.desc" />
      </div>

      <div className={styles["team-area"]}>
        {Object.keys(teamRefs).map(region => {
          const teams = teamRefs[region];
          return (
            <div key={region}>
              <div className={styles["region-title"]}>
                <Text className={styles["text"]}>{region}</Text>
              </div>

              {teams.map(team => (
                <div key={team.lookupId} className={styles["team-entry"]}>
                  <Text
                    onClick={() => {
                      redirectToRegionForTeam(region, team.lookupId);
                    }}
                    className={styles["team-button"]}
                  >
                    {team.name}
                  </Text>
                </div>
              ))}
            </div>
          );
        })}
      </div>

      <Text onClick={onCancel} className={styles["action-button"]}>
        <FormattedMessage
          id={"onboarding.team_exists_in_other_region.action"}
          values={{
            currentRegion: localizeRegion(currentRegion),
          }}
        />
      </Text>
    </Dialog>
  );
}
