export function getPageByOffset(offset: number, pageSize: number = 20): number {
  if (offset < pageSize) {
    return 1;
  }
  return Math.max(Math.ceil((offset + 1) / pageSize), 1);
}

export function getOffsetByPage(
  page: number | null,
  pageSize: number = 20
): number {
  if (page === null || isNaN(page) || page <= 0) {
    return 0;
  }
  return pageSize * (page - 1);
}

export function getOffsetOfBeginningOfPage(
  offset: number,
  pageSize: number = 20
): number {
  return (getPageByOffset(offset - 1, pageSize) - 1) * pageSize;
}
