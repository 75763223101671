import { ActionButton, Checkbox } from "@fluentui/react";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { AnchorInspectorNote } from "../InspectorNote";
import styles from "./styles.module.scss";

interface Props {
  isEnabled: boolean;
  onChangeAnchorEnabled: (checked: boolean) => void;
  onDeleteAnchor: () => void;
}

const AnchorInspector = React.memo(
  ({ isEnabled, onChangeAnchorEnabled, onDeleteAnchor }: Props) => {
    const { localized } = useLocale();

    const onCheckboxChange = React.useCallback(
      (_event?: React.FormEvent<any>, value?: boolean) => {
        if (value === undefined) {
          return;
        }
        onChangeAnchorEnabled(value);
      },
      [onChangeAnchorEnabled]
    );

    const { hasPermissionToEditResource } = useTeamPermission();

    return (
      <div className={styles["anchor-inspector"]}>
        <AnchorInspectorNote />
        <div>
          <Checkbox
            name="anchor_enable"
            checked={isEnabled}
            label={localized("form_inspector.enable")}
            onChange={onCheckboxChange}
            disabled={!hasPermissionToEditResource}
          />
        </div>
        {hasPermissionToEditResource && (
          <ActionButton
            iconProps={{ iconName: "Delete" }}
            className={styles["delete-button"]}
            onClick={onDeleteAnchor}
          >
            {localized("form_inspector.delete_anchor")}
          </ActionButton>
        )}
      </div>
    );
  }
);

export default AnchorInspector;
