import { Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React from "react";

import { ValueRenderer } from "../OCRTest/ValueRenderer";
import styles from "./styles.module.scss";

interface Props {
  headerContent: React.ReactNode;
  tableContent: React.ReactNode;
}

export function formatConfidence(value: number | null | undefined) {
  return value === null || value === undefined ? null : value.toFixed(2);
}

export function HeaderCell(props: {
  headerId: string;
  columnClassName?: string;
}) {
  const { headerId, columnClassName } = props;
  return (
    <td
      className={cn(
        styles["header-cell"],
        styles["table-column"],
        columnClassName
      )}
    >
      <Text className={styles["header-text"]}>
        <FormattedMessage id={headerId} />
      </Text>
    </td>
  );
}

export function BodyCell(props: {
  text: string;
  columnClassName?: string;
  alt?: string;
}) {
  const { text, columnClassName, alt } = props;
  return (
    <td
      className={cn(
        styles["body-cell"],
        styles["table-column"],
        columnClassName
      )}
    >
      <Text title={alt} className={styles["body-text"]}>
        {text}
      </Text>
    </td>
  );
}

export function ValueCell(props: { value: any; columnClassName?: string }) {
  const { value, columnClassName } = props;

  return (
    <td
      className={cn(
        styles["body-cell"],
        styles["table-column"],
        columnClassName
      )}
    >
      <ValueRenderer value={value} />
    </td>
  );
}

interface HeaderRowProps {
  content: React.ReactNode;
}

function HeaderRow(props: HeaderRowProps) {
  const { content } = props;

  return (
    <tr className={cn(styles["table-row"], styles["header-row"])}>{content}</tr>
  );
}

interface RowProps {
  content: React.ReactNode;
}

export function Row(props: RowProps) {
  const { content } = props;

  return (
    <tr className={cn(styles["table-row"], styles["body-row"])}>{content}</tr>
  );
}

export const DetectionTestReportTable = React.memo((props: Props) => {
  const { headerContent, tableContent } = props;

  return (
    <table className={styles["report-table"]}>
      <thead>
        <HeaderRow content={headerContent} />
      </thead>
      <tbody>{tableContent}</tbody>
    </table>
  );
});
