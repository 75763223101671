import * as React from "react";

import { useFormGroupActionCreator } from "../actions/formGroup";
import { RootState } from "../redux/types";
import { ExtractorOptionForCombinedExtractor } from "../types/extractor";
import { DetailFormGroup } from "../types/formGroup";
import { WorkerToken } from "../types/workerToken";
import { useWorkerToken } from "./app";
import { useExtractorOptions } from "./extractorOption";
import { useAppSelector } from "./redux";

export function useFormGroup(formGroupId: string) {
  const { getFormGroup } = useFormGroupActionCreator();
  const formGroup = useAppSelector(
    (state: RootState) => state.formGroup.currentFormGroup
  );
  const [isFailedToFetchFormGroup, setIsFailedToFetchFormGroup] =
    React.useState(false);

  React.useEffect(() => {
    if (!formGroup || formGroup.id !== formGroupId) {
      getFormGroup(formGroupId).catch(() => {
        setIsFailedToFetchFormGroup(true);
      });
    }
  }, [formGroup, getFormGroup, formGroupId]);

  return {
    formGroup,
    isFailedToFetchFormGroup,
  };
}

export function useExtractorOptionsForCombinedExtractor() {
  const { extractorOptions, isFailedToFetchExtractorOptions } =
    useExtractorOptions();

  const { currentCombinedExtractorId, extractorIdsInUse } = useAppSelector(
    state => {
      const currentFormGroup = state.formGroup.currentFormGroup;
      if (!currentFormGroup) {
        return {
          currentCombinedExtractorId: undefined,
          extractorIdsInUse: undefined,
        };
      }
      return {
        currentCombinedExtractorId: currentFormGroup.id,
        extractorIdsInUse: [
          ...currentFormGroup.anchors.map(x => x.formId),
          ...currentFormGroup.tokenGroups.map(x => x.formId),
          ...currentFormGroup.documentDetections.flatMap(x =>
            x.targetFormId
              ? x.targetFormId
              : x.targetFormGroupId
              ? x.targetFormGroupId
              : []
          ),
        ],
      };
    }
  );

  return React.useMemo(
    () => ({
      extractorOptions:
        !extractorIdsInUse || !currentCombinedExtractorId
          ? undefined
          : extractorOptions?.flatMap<ExtractorOptionForCombinedExtractor>(
              x => {
                const extractorId = x.associatedExtractorId ?? x.id;
                return {
                  extractorId,
                  name: x.name,
                  resourceType: x.resourceType,
                  extractorType: x.extractorType,
                  hasFeatures: x.hasFeatures ?? false,
                  isInUse: extractorIdsInUse.includes(extractorId),
                  isCurrentCombinedExtractor:
                    extractorId === currentCombinedExtractorId,
                };
              }
            ),
      isFailedToFetchExtractorOptions,
    }),
    [
      extractorOptions,
      extractorIdsInUse,
      currentCombinedExtractorId,
      isFailedToFetchExtractorOptions,
    ]
  );
}

interface CommonFormGroupContainerSuccessState {
  state: "success";
  workerToken: string | undefined;
  formGroup: DetailFormGroup;
  workerTokens: WorkerToken[];
  onSelectWorkerToken: (token: string) => void;
}

export interface CommonFormGroupContainerErrorState {
  state: "error";
}

export interface CommonFormGroupContainerLoadingState {
  state: "loading";
}

export type CommonFormGroupContainerState =
  | CommonFormGroupContainerLoadingState
  | CommonFormGroupContainerErrorState
  | CommonFormGroupContainerSuccessState;

export function useCommonFormGroupContainerState(formGroupId: string) {
  const { formGroup, isFailedToFetchFormGroup } = useFormGroup(formGroupId);
  const {
    token: workerToken,
    tokens: workerTokens,
    onSelectToken: onSelectWorkerToken,
    isFailed: isFailedToGetWorkerToken,
  } = useWorkerToken();

  const containerState: CommonFormGroupContainerState =
    isFailedToGetWorkerToken || isFailedToFetchFormGroup
      ? { state: "error" }
      : formGroup !== undefined &&
        formGroup.id === formGroupId &&
        workerTokens !== undefined
      ? {
          state: "success",
          formGroup,
          workerToken,
          workerTokens,
          onSelectWorkerToken,
        }
      : { state: "loading" };

  return containerState;
}
