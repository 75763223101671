import * as React from "react";

import { useTeamActionCreator } from "../actions/team";
import { FOCRError } from "../errors";
import {
  TeamInvitationListItemStore,
  TeamInvitationStatus,
} from "../types/team";
import { useAppSelector } from "./redux";
import { useToast } from "./toast";

export interface GetTeamInvitationsFromAllRegionsLoadingState {
  type: "loading";
}

export interface GetTeamInvitationsFromAllRegionsErrorState {
  type: "error";
  error: FOCRError;
}

export interface GetTeamInvitationsFromAllRegionsSuccessState {
  type: "success";
  invitationsByRegion: { [key: string]: TeamInvitationListItemStore[] };
  hasPendingInvitation: boolean;
  invitationCount: number;
}

export type GetTeamInvitationsFromAllRegionsState =
  | GetTeamInvitationsFromAllRegionsLoadingState
  | GetTeamInvitationsFromAllRegionsErrorState
  | GetTeamInvitationsFromAllRegionsSuccessState;

export function useGetTeamInvitationsFromAllRegions() {
  const isAuthenticatedRef = React.useRef(
    useAppSelector(state => state.user.isAuthenticated)
  );
  const { listInvitations } = useTeamActionCreator();
  const { invitationsByRegion, isFetchingInvitation, fetchInvitationError } =
    useAppSelector(state => state.user);

  React.useEffect(() => {
    if (isAuthenticatedRef.current) {
      listInvitations();
    }
  }, [listInvitations, isAuthenticatedRef]);

  return React.useMemo((): GetTeamInvitationsFromAllRegionsState => {
    if (isFetchingInvitation || invitationsByRegion === undefined) {
      return { type: "loading" };
    } else if (fetchInvitationError) {
      return { type: "error", error: fetchInvitationError };
    } else {
      return {
        type: "success",
        invitationsByRegion,
        hasPendingInvitation: Object.values(invitationsByRegion)
          .flat()
          .some(invitation => {
            return invitation.status === TeamInvitationStatus.Pending;
          }),
        invitationCount: Object.values(invitationsByRegion).flat().length,
      };
    }
  }, [isFetchingInvitation, invitationsByRegion, fetchInvitationError]);
}

export function useAcceptRejectInvitation() {
  const {
    acceptTeamInvitation: acceptTeamInvitationAction,
    rejectTeamInvitation: rejectTeamInvitationAction,
  } = useTeamActionCreator();
  const toast = useToast();

  const [acceptingInvitationIds, setAcceptingInvitationIds] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [rejectingInvitationIds, setRejectingInvitationIds] = React.useState<{
    [key: string]: boolean;
  }>({});

  const acceptTeamInvitation = React.useCallback(
    async (invitationId: string, region: string) => {
      try {
        setAcceptingInvitationIds(prev => ({ ...prev, [invitationId]: true }));
        await acceptTeamInvitationAction(invitationId, region);
      } catch (e) {
        toast.error("error.invite.fail_to_accept");
        throw e;
      } finally {
        setAcceptingInvitationIds(prev => ({ ...prev, [invitationId]: false }));
      }
    },
    [toast, acceptTeamInvitationAction]
  );

  const rejectTeamInvitation = React.useCallback(
    async (invitationId: string, region: string) => {
      try {
        setRejectingInvitationIds(prev => ({ ...prev, [invitationId]: true }));
        await rejectTeamInvitationAction(invitationId, region);
      } catch (e) {
        toast.error("error.invite.fail_to_reject");
        throw e;
      } finally {
        setRejectingInvitationIds(prev => ({ ...prev, [invitationId]: false }));
      }
    },
    [toast, rejectTeamInvitationAction]
  );

  return {
    acceptTeamInvitation,
    acceptingInvitationIds,
    rejectTeamInvitation,
    rejectingInvitationIds,
  };
}
