import { Icon, IconButton, Spinner, SpinnerSize } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React, { ReactElement, useCallback, useMemo } from "react";

import errors from "../../errors";
import { UploadQueueEntry } from "../../reducers/extraction";
import { AbortControllerService } from "../../services/abortController";
import styles from "./styles.module.scss";

interface UploadListHeaderItemProps {
  content: "inProgress" | "failed";
  itemCount: number;
  onCancelAll?: () => void;
  onRetryAllFailed?: () => void;
}

const UploadListHeaderItem = (
  props: UploadListHeaderItemProps
): ReactElement => {
  const { content, itemCount, onCancelAll, onRetryAllFailed } = props;
  const onClick = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (content === "inProgress") {
        onCancelAll?.();
      } else {
        onRetryAllFailed?.();
      }
    },
    [content, onCancelAll, onRetryAllFailed]
  );

  return (
    <li
      className={cn(styles.item, styles.listHeaderItem, {
        [styles.listHeaderItemFailed]: content === "failed",
      })}
    >
      <p className={styles.itemText}>
        <FormattedMessage
          id={
            content === "inProgress"
              ? "workspace.document.uploadWidget.inProgress"
              : "workspace.document.uploadWidget.failed"
          }
          values={{
            count: itemCount,
          }}
        />
      </p>
      <button className={styles.actionButton} onClick={onClick}>
        <FormattedMessage
          id={
            content === "inProgress"
              ? "workspace.document.uploadWidget.cancelAll"
              : "workspace.document.uploadWidget.retryAll"
          }
        />
      </button>
    </li>
  );
};

interface UploadListItemProps {
  uploadQueueEntry: UploadQueueEntry;
  onRetry: (entry: UploadQueueEntry) => void;
}

const UploadListItem = (props: UploadListItemProps): ReactElement => {
  const { uploadQueueEntry, onRetry } = props;
  const onClickCancel = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      AbortControllerService.getInstance()
        .getAbortController(uploadQueueEntry.id)
        .abort();
    },
    [uploadQueueEntry.id]
  );
  const onClickRetry = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      onRetry(uploadQueueEntry);
    },
    [onRetry, uploadQueueEntry]
  );
  return (
    <li className={styles.item}>
      <div className={styles.itemLabel}>
        {uploadQueueEntry.state === "uploaded" ? (
          <Icon iconName="IconSuccessCircle" />
        ) : null}
        {uploadQueueEntry.state === "uploading" ? (
          <Spinner size={SpinnerSize.small} />
        ) : null}
        {uploadQueueEntry.state === "errored" ? (
          <Icon iconName="IconFailCircle" />
        ) : null}
        <p className={styles.itemText}>
          {uploadQueueEntry.fileName}
          {uploadQueueEntry.state === "errored" ? (
            <>
              <br />
              <span className={styles.errorText}>
                {uploadQueueEntry.error != null ? (
                  <FormattedMessage
                    id={uploadQueueEntry.error.messageId}
                    values={uploadQueueEntry.error.detail}
                  />
                ) : (
                  <FormattedMessage
                    id={errors.UnknownError.messageId}
                    values={errors.UnknownError.detail}
                  />
                )}
              </span>
            </>
          ) : null}
        </p>
      </div>
      {uploadQueueEntry.state === "uploading" ? (
        <button className={styles.actionButton} onClick={onClickCancel}>
          <FormattedMessage id="workspace.document.uploadWidget.cancel" />
        </button>
      ) : uploadQueueEntry.state === "errored" ? (
        <button className={styles.actionButton} onClick={onClickRetry}>
          <FormattedMessage id="workspace.document.uploadWidget.retry" />
        </button>
      ) : (
        <p
          className={cn(styles.status, {
            [styles.statusSuccess]: uploadQueueEntry.state === "uploaded",
          })}
        >
          {uploadQueueEntry.state === "uploaded" ? (
            <FormattedMessage id="workspace.document.uploadWidget.uploaded" />
          ) : null}
        </p>
      )}
    </li>
  );
};

interface WorkspaceDocumentUploadWidgetProps {
  className?: string;
  uploadQueue: UploadQueueEntry[];
  isUploadingForOtherWorkspaces: boolean;
  showDismissButton: boolean;
  onDismiss: () => void;
  isCollapsed: boolean;
  onToggleIsCollapsed: () => void;
  onRetry: (entry: UploadQueueEntry) => void;
  onRetryAllFailed: () => void;
}

const WorkspaceDocumentUploadWidget = (
  props: WorkspaceDocumentUploadWidgetProps
): ReactElement => {
  const {
    className,
    uploadQueue,
    showDismissButton,
    onDismiss,
    isUploadingForOtherWorkspaces,
    isCollapsed,
    onToggleIsCollapsed,
    onRetry,
    onRetryAllFailed,
  } = props;
  const uploadingEntries = useMemo(() => {
    return uploadQueue.filter(entry => entry.state === "uploading");
  }, [uploadQueue]);
  const uploadedEntries = useMemo(() => {
    return uploadQueue.filter(entry => entry.state === "uploaded");
  }, [uploadQueue]);
  const failedEntries = useMemo(() => {
    return uploadQueue.filter(entry => entry.state === "errored");
  }, [uploadQueue]);
  const onCancelAll = useCallback(() => {
    uploadingEntries.forEach(entry => {
      AbortControllerService.getInstance().getAbortController(entry.id).abort();
    });
  }, [uploadingEntries]);

  const onClickToggleOpen = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      onToggleIsCollapsed();
    },
    [onToggleIsCollapsed]
  );
  const onClickDismiss = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      onDismiss();
    },
    [onDismiss]
  );
  return (
    <div className={cn(styles.uploadWidget, className)}>
      <div className={styles.header} onClick={onClickToggleOpen}>
        <h3 className={styles.headerText}>
          {uploadingEntries.length > 0 ? (
            <FormattedMessage
              id="workspace.document.uploadWidget.header"
              values={{
                uploadedCount: uploadedEntries.length,
                totalCount: uploadQueue.length,
              }}
            />
          ) : (
            <FormattedMessage id="workspace.document.uploadWidget.header.complete" />
          )}
          {failedEntries.length > 0 ? (
            <FormattedMessage
              id="workspace.document.uploadWidget.header.failed"
              values={{
                count: failedEntries.length,
              }}
            />
          ) : null}
        </h3>
        <div className={styles.headerButtonGroup}>
          <IconButton
            iconProps={{
              iconName: isCollapsed ? "ChevronUp" : "ChevronDown",
            }}
            onClick={onClickToggleOpen}
          />
          {showDismissButton ? (
            <IconButton
              iconProps={{
                iconName: "Cancel",
              }}
              onClick={onClickDismiss}
            />
          ) : null}
        </div>
      </div>
      <div
        className={cn(styles.listAnimation, {
          [styles.listOpen]: !isCollapsed,
        })}
      >
        {isUploadingForOtherWorkspaces ? (
          <p className={styles["banner--uploading-for-other-workspace"]}>
            <FormattedMessage id="workspace.document.upload_widget.uploading_for_other_workspace" />
          </p>
        ) : null}
        <ul className={cn(styles.list)}>
          {uploadingEntries.length > 0 ? (
            <UploadListHeaderItem
              content="inProgress"
              itemCount={uploadingEntries.length}
              onCancelAll={onCancelAll}
            />
          ) : failedEntries.length > 0 ? (
            <UploadListHeaderItem
              content="failed"
              itemCount={failedEntries.length}
              onRetryAllFailed={onRetryAllFailed}
            />
          ) : null}
          {uploadQueue.map(entry => (
            <UploadListItem
              key={entry.id}
              uploadQueueEntry={entry}
              onRetry={onRetry}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WorkspaceDocumentUploadWidget;
