import { Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { Link } from "react-router-dom";

import { useTeamPermission } from "../../hooks/permission";
import { Webhook } from "../../types/webhook";
import { Table } from "../Table";
import { WebhookTableRow } from "../WebhookTableRow";
import { PrimaryButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface Props {
  webhooks: Webhook[];
  isEmpty: boolean;
}

function EmptyStateUI() {
  const { hasPermissionToCreateResource } = useTeamPermission();

  return (
    <div className={styles["empty-state-container"]}>
      <div className={styles["empty-state"]}>
        <div className={styles["empty-state-text"]}>
          <Label>
            <FormattedMessage id="webhook.no_webhook_yet" />
          </Label>
          {hasPermissionToCreateResource && (
            <Link to="/webhook/new">
              <PrimaryButton
                className={styles["add-button"]}
                textId="webhook.add"
              ></PrimaryButton>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

function _WebhookList(props: Props) {
  const { isEmpty, webhooks } = props;
  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["webhook-list"]}>
      <h3 className={styles["title"]}>
        <FormattedMessage id="webhook.title" />
      </h3>
      {isEmpty ? (
        <EmptyStateUI />
      ) : (
        <Table
          columnIds={[
            "webhook.list.webhooks",
            "webhook.list.connected_with",
            "webhook.list.action",
          ]}
        >
          {webhooks.map((webhook, index) => (
            <WebhookTableRow
              webhook={webhook}
              key={index}
              showEditButton={hasPermissionToEditResource}
            />
          ))}
        </Table>
      )}
    </div>
  );
}

export const WebhookList = React.memo(_WebhookList);
export default WebhookList;
