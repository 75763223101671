import * as React from "react";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { FSLFieldSchemaEditor } from "../components/FSLFieldSchemaEditor";
import { FSLFieldSchemaViewer } from "../components/FSLFieldSchemaViewer";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import HeaderContainer from "../containers/Header";
import {
  FSLCustomModelEditorProvider,
  Page,
  useFSLCustomModelEditor,
} from "../contexts/fslCustomModelEditor";
import { useUnsafeParams } from "../hooks/params";
import { CustomModelEditor } from "./CustomModelEditor";
import { FSLNavBarLayout } from "./FSLNavBarLayout";

type PathParam = {
  customModelId: string;
};

function FSLCustomModelEditor() {
  const {
    isLoading,
    isFSLModel,
    isFailedToFetchCustomModel,
    customModelId,
    page,
    isProcessing,
  } = useFSLCustomModelEditor();

  const shouldShowFSLCustomModel = isFSLModel === true && !isLoading;
  const shouldShowOriginalEditor = isFSLModel === false;

  return (
    <>
      {isFailedToFetchCustomModel && (
        <Main hasTop={true}>
          <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
        </Main>
      )}
      {shouldShowOriginalEditor && (
        <CustomModelEditor customModelId={customModelId} />
      )}
      {!shouldShowOriginalEditor && (
        <LoadingModal isOpen={isLoading || isProcessing} />
      )}
      {shouldShowFSLCustomModel && (
        <Main hasTop={true}>
          {page === Page.Main && (
            <FSLNavBarLayout>
              <FSLFieldSchemaViewer />
            </FSLNavBarLayout>
          )}
          {page === Page.SchemaEditor && (
            <FSLNavBarLayout>
              <FSLFieldSchemaEditor />
            </FSLNavBarLayout>
          )}
        </Main>
      )}
    </>
  );
}

function _FSLCustomModelEditorContainer() {
  const { customModelId } = useUnsafeParams<PathParam>();

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <FSLCustomModelEditorProvider
        customModelId={customModelId}
        key={customModelId}
      >
        <FSLCustomModelEditor />
      </FSLCustomModelEditorProvider>
    </Layout>
  );
}

export const FSLCustomModelEditorContainer = React.memo(
  _FSLCustomModelEditorContainer
);
export default FSLCustomModelEditorContainer;
