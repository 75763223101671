@import "../../styles/variables.scss";

.warped-image-wrapper {
  display: flex;
  background: #faf9f8;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 500px;
}

.name-column {
  min-width: 120px;
  width: 120px;
}

.confidence-column {
  min-width: 150px;
  width: 150px;
}

.bbox-column {
  min-width: 190px;
  width: 190px;
}
