import { createReducer } from "@reduxjs/toolkit";

import * as extractionAction from "../actions/extraction";
import { FOCRError } from "../errors";
import { Sort } from "../types/api";
import {
  Extraction,
  ExtractionFilterableField,
  ExtractionResult,
  ExtractionResultPageInfo,
  PaginatedExtractions,
} from "../types/extraction";

export interface UploadQueueEntry {
  id: string;
  uploadedAt: Date;
  fileName: string;
  fileKey: string;
  state: "uploading" | "uploaded" | "errored";
  error?: FOCRError;
}

export interface ListExtractionsParams {
  page: number;
  size: number;
  fileName: string;
  sort: Sort<ExtractionFilterableField>;
  // below flag is for overriding url state after we paginate list via detail screen
  shouldOverrideUrlState?: boolean;
}

export interface ExtractionState {
  readonly listParamsByWorkspace: {
    [key in string]: ListExtractionsParams;
  };
  readonly isListingByWorkspace: {
    [key in string]: boolean;
  };
  readonly paginatedExtractionsByWorkspace: {
    [key in string]: PaginatedExtractions;
  };
  readonly isInitiallyEmptyByWorkspace: {
    [key in string]: boolean;
  };
  readonly listErrorByWorkspace: {
    [key in string]: FOCRError;
  };
  readonly isListingProcessingByWorkspace: {
    [key in string]: boolean;
  };
  // in-process new extract jobs
  readonly processingExtractionsByWorkspace: {
    [key in string]: Extraction[];
  };
  // in-process re-extract jobs
  readonly processingExtractionResultsByWorkspace: {
    [key in string]: ExtractionResult[];
  };
  readonly listProcessingErrorByWorkspace: {
    [key in string]: FOCRError;
  };
  readonly uploadQueueByWorkspace: {
    [key in string]: { [key: string]: UploadQueueEntry };
  };
  // NOTE: this count is needed because uploadQueueByWorkspace contains finished upload item records
  readonly uploadingCountByWorkspace: {
    [key in string]: number;
  };
  readonly isExportingByWorkspace: {
    [key in string]: boolean;
  };
  readonly isGettingExtractionById: {
    [key in string]: boolean;
  };
  readonly extractionById: {
    [key in string]: Extraction;
  };
  readonly getExtractionErrorById: {
    [key in string]: FOCRError;
  };
  readonly isGettingExtractionResultById: {
    [key in string]: boolean;
  };
  readonly extractionResultById: {
    [key in string]: ExtractionResult;
  };
  readonly extractionResultPageInfoById: {
    [key in string]: ExtractionResultPageInfo;
  };
  readonly getExtractionResultErrorById: {
    [key in string]: FOCRError;
  };
}

const defaultState: ExtractionState = {
  listParamsByWorkspace: {},
  isListingByWorkspace: {},
  paginatedExtractionsByWorkspace: {},
  isInitiallyEmptyByWorkspace: {},
  listErrorByWorkspace: {},
  isListingProcessingByWorkspace: {},
  processingExtractionsByWorkspace: {},
  processingExtractionResultsByWorkspace: {},
  listProcessingErrorByWorkspace: {},
  uploadQueueByWorkspace: {},
  uploadingCountByWorkspace: {},
  isExportingByWorkspace: {},
  isGettingExtractionById: {},
  extractionById: {},
  getExtractionErrorById: {},
  isGettingExtractionResultById: {},
  extractionResultById: {},
  extractionResultPageInfoById: {},
  getExtractionResultErrorById: {},
};

export const extractionReducer = createReducer<ExtractionState>(
  defaultState,
  builder => {
    builder
      .addCase(extractionAction.ListExtractions, (state, action) => {
        state.isListingByWorkspace[action.payload.workspaceId] = true;
        state.listParamsByWorkspace[action.payload.workspaceId] =
          action.payload.params;
        delete state.listErrorByWorkspace[action.payload.workspaceId];
      })
      .addCase(extractionAction.ListExtractionsSuccess, (state, action) => {
        state.isListingByWorkspace[action.payload.workspaceId] = false;
        state.isInitiallyEmptyByWorkspace[action.payload.workspaceId] =
          action.payload.params.page === 1 &&
          action.payload.result.extractions.length <= 0;
        state.paginatedExtractionsByWorkspace[action.payload.workspaceId] =
          action.payload.result;
        delete state.listErrorByWorkspace[action.payload.workspaceId];
      })
      .addCase(extractionAction.ListExtractionsFailed, (state, action) => {
        state.isListingByWorkspace[action.payload.workspaceId] = false;
        delete state.paginatedExtractionsByWorkspace[
          action.payload.workspaceId
        ];
        state.listErrorByWorkspace[action.payload.workspaceId] =
          action.payload.error;
      })
      .addCase(extractionAction.ListProcessingExtractions, (state, action) => {
        state.isListingProcessingByWorkspace[action.payload.workspaceId] = true;
        delete state.listProcessingErrorByWorkspace[action.payload.workspaceId];
      })
      .addCase(
        extractionAction.ListProcessingExtractionsSuccess,
        (state, action) => {
          state.isListingProcessingByWorkspace[action.payload.workspaceId] =
            false;
          state.processingExtractionsByWorkspace[action.payload.workspaceId] =
            action.payload.extractions;
          state.processingExtractionResultsByWorkspace[
            action.payload.workspaceId
          ] = action.payload.extractionResults;
          delete state.listProcessingErrorByWorkspace[
            action.payload.workspaceId
          ];
        }
      )
      .addCase(
        extractionAction.ListProcessingExtractionsFailed,
        (state, action) => {
          state.isListingProcessingByWorkspace[action.payload.workspaceId] =
            false;
          delete state.processingExtractionsByWorkspace[
            action.payload.workspaceId
          ];
          delete state.processingExtractionResultsByWorkspace[
            action.payload.workspaceId
          ];
          state.listProcessingErrorByWorkspace[action.payload.workspaceId] =
            action.payload.error;
        }
      )
      .addCase(extractionAction.CreateExtraction, (state, action) => {
        state.uploadQueueByWorkspace = {
          ...state.uploadQueueByWorkspace,
          [action.payload.workspaceId]: {
            ...state.uploadQueueByWorkspace[action.payload.workspaceId],
            [action.payload.uploadId]: {
              id: action.payload.uploadId,
              uploadedAt: new Date(),
              fileName: action.payload.fileName,
              fileKey: action.payload.fileKey,
              state: "uploading",
            },
          },
        };
        state.uploadingCountByWorkspace[action.payload.workspaceId] =
          (state.uploadingCountByWorkspace[action.payload.workspaceId] ?? 0) +
          1;
      })
      .addCase(extractionAction.CreateExtractionSuccess, (state, action) => {
        state.uploadQueueByWorkspace = {
          ...state.uploadQueueByWorkspace,
          [action.payload.workspaceId]: {
            ...state.uploadQueueByWorkspace[action.payload.workspaceId],
            [action.payload.uploadId]: {
              ...state.uploadQueueByWorkspace[action.payload.workspaceId][
                action.payload.uploadId
              ],
              state: "uploaded",
            },
          },
        };
        state.uploadingCountByWorkspace[action.payload.workspaceId] =
          (state.uploadingCountByWorkspace[action.payload.workspaceId] ?? 1) -
          1;
        state.isInitiallyEmptyByWorkspace[action.payload.workspaceId] = false;
      })
      .addCase(extractionAction.CreateExtractionFailed, (state, action) => {
        state.uploadQueueByWorkspace = {
          ...state.uploadQueueByWorkspace,
          [action.payload.workspaceId]: {
            ...state.uploadQueueByWorkspace[action.payload.workspaceId],
            [action.payload.uploadId]: {
              ...state.uploadQueueByWorkspace[action.payload.workspaceId][
                action.payload.uploadId
              ],
              state: "errored",
              error: action.payload.error,
            },
          },
        };
        state.uploadingCountByWorkspace[action.payload.workspaceId] =
          (state.uploadingCountByWorkspace[action.payload.workspaceId] ?? 1) -
          1;
      })
      .addCase(extractionAction.CleanupUploadQueue, (state, action) => {
        const clearedUploadQueue = Object.values(
          state.uploadQueueByWorkspace[action.payload.workspaceId] ?? {}
        ).reduce((prev, curr) => {
          if (curr.state !== "uploading") {
            return prev;
          }
          return {
            ...prev,
            [curr.id]: curr,
          };
        }, {} as { [key: string]: UploadQueueEntry });

        state.uploadQueueByWorkspace = {
          ...state.uploadQueueByWorkspace,
          [action.payload.workspaceId]: clearedUploadQueue,
        };
      })
      .addCase(extractionAction.DeleteUploadQueueEntry, (state, action) => {
        state.uploadQueueByWorkspace = {
          ...state.uploadQueueByWorkspace,
          [action.payload.workspaceId]: Object.values(
            state.uploadQueueByWorkspace[action.payload.workspaceId] ?? {}
          ).reduce((prev, curr) => {
            if (curr.id === action.payload.uploadId) {
              return prev;
            }
            return {
              ...prev,
              [curr.id]: curr,
            };
          }, {} as { [key: string]: UploadQueueEntry }),
        };
      })
      .addCase(extractionAction.DeleteExtraction, (state, action) => {
        action.payload.idTuples.forEach(({ workspaceId, extractionId }) => {
          if (state.paginatedExtractionsByWorkspace[workspaceId] != null) {
            state.paginatedExtractionsByWorkspace[workspaceId].extractions =
              state.paginatedExtractionsByWorkspace[
                workspaceId
              ].extractions.filter(x => x.id !== extractionId);
          }
        });
      })
      .addCase(extractionAction.DeleteAllExtractions, (state, action) => {
        delete state.paginatedExtractionsByWorkspace[
          action.payload.workspaceId
        ];
      })
      .addCase(extractionAction.DeleteExtractionResults, (state, action) => {
        const { workspaceId, resultKeys } = action.payload;
        const resultKeyStringsByExtraction = resultKeys.reduce(
          (prev: { [key: string]: string[] }, curr) => {
            return {
              ...prev,
              [curr.extractionId]: [
                ...(prev[curr.extractionId] ?? []),
                `page_${curr.pageNumber}_slice_${curr.sliceNumber}`,
              ],
            };
          },
          {}
        );
        if (state.paginatedExtractionsByWorkspace[workspaceId] != null) {
          state.paginatedExtractionsByWorkspace[workspaceId].extractions =
            state.paginatedExtractionsByWorkspace[workspaceId].extractions.map(
              extraction => {
                if (
                  Object.keys(resultKeyStringsByExtraction).includes(
                    extraction.id
                  )
                ) {
                  extraction.results = extraction.results.filter(
                    result =>
                      !resultKeyStringsByExtraction[extraction.id].includes(
                        `page_${result.info.pageNumber}_slice_${result.info.sliceNumber}`
                      )
                  );
                }
                return extraction;
              }
            );
        }
      })
      .addCase(extractionAction.ExportExtraction, (state, action) => {
        state.isExportingByWorkspace[action.payload.workspaceId] = true;
      })
      .addCase(extractionAction.ExportExtractionComplete, (state, action) => {
        state.isExportingByWorkspace[action.payload.workspaceId] = false;
      })
      .addCase(extractionAction.ReExtractExtraction, (state, action) => {
        state.processingExtractionResultsByWorkspace[
          action.payload.workspaceId
        ] = [
          ...(state.processingExtractionResultsByWorkspace[
            action.payload.workspaceId
          ] ?? []),
          {
            ...action.payload.extractionResult,
            status: "processing",
          },
        ];
      })
      .addCase(extractionAction.ReExtractExtractionFailed, (state, action) => {
        state.processingExtractionResultsByWorkspace[
          action.payload.workspaceId
        ] = state.processingExtractionResultsByWorkspace[
          action.payload.workspaceId
        ].filter(result => result.id !== action.payload.extractionResultId);
      })
      .addCase(extractionAction.GetExtraction, (state, action) => {
        state.isGettingExtractionById[action.payload.extractionId] = true;
        delete state.getExtractionErrorById[action.payload.extractionId];
      })
      .addCase(extractionAction.GetExtractionSuccess, (state, action) => {
        state.isGettingExtractionById[action.payload.extractionId] = false;
        state.extractionById[action.payload.extractionId] =
          action.payload.extraction;
        delete state.getExtractionErrorById[action.payload.extractionId];
      })
      .addCase(extractionAction.GetExtractionFailed, (state, action) => {
        state.isGettingExtractionById[action.payload.extractionId] = false;
        delete state.extractionById[action.payload.extractionId];
        state.getExtractionErrorById[action.payload.extractionId] =
          action.payload.error;
      })
      .addCase(extractionAction.GetExtractionResult, (state, action) => {
        state.isGettingExtractionResultById[action.payload.extractionResultId] =
          true;
        delete state.getExtractionResultErrorById[
          action.payload.extractionResultId
        ];
      })
      .addCase(extractionAction.GetExtractionResultSuccess, (state, action) => {
        state.isGettingExtractionResultById[action.payload.extractionResultId] =
          false;
        state.extractionResultById[action.payload.extractionResultId] =
          action.payload.extractionResult;
        state.extractionResultPageInfoById[action.payload.extractionResultId] =
          action.payload.extractionResultPageInfo;
        delete state.getExtractionResultErrorById[
          action.payload.extractionResultId
        ];
      })
      .addCase(extractionAction.GetExtractionResultFailed, (state, action) => {
        state.isGettingExtractionResultById[action.payload.extractionResultId] =
          false;
        delete state.extractionResultById[action.payload.extractionResultId];
        delete state.extractionResultPageInfoById[
          action.payload.extractionResultId
        ];
        state.getExtractionResultErrorById[action.payload.extractionResultId] =
          action.payload.error;
      })
      .addCase(
        extractionAction.ClearListExtractionsParamsShouldOverrideUrl,
        (state, action) => {
          if (state.listParamsByWorkspace[action.payload.workspaceId] != null) {
            state.listParamsByWorkspace[
              action.payload.workspaceId
            ].shouldOverrideUrlState = false;
          }
        }
      );
  }
);
