import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  Icon,
  PrimaryButton,
  SearchBox,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";
import {
  MerchantSettingsProvider,
  SortingOrderType,
  useMerchantSettings,
} from "../../contexts/merchantSettings";
import { useTeamPermission } from "../../hooks/permission";
import MerchantForm from "../MerchantForm";
import MerchantNameList from "../MerchantNameList";
import styles from "./styles.module.scss";

interface ContentProps {
  onClose(): void;
}

const Content = (props: ContentProps) => {
  const { onClose } = props;

  const { localized } = useLocale();

  const {
    saveMerchantsToStore,
    setMerchantFilter,
    merchantFilter,
    setMerchantSortingOrder,
    merchantErrors,
  } = useMerchantSettings();

  const onFilterChange = useCallback(
    (_?: React.ChangeEvent<HTMLInputElement>, value?: string) => {
      setMerchantFilter(value || "");
    },
    [setMerchantFilter]
  );

  const onSubmit = useCallback(() => {
    if (saveMerchantsToStore()) {
      onClose();
    }
  }, [onClose, saveMerchantsToStore]);

  const translateSortingOrderType = {
    [SortingOrderType.SortByAlphabet]:
      "merchant_settings_modal.sort_by_alphabet",
    [SortingOrderType.SortByAlphabetReversed]:
      "merchant_settings_modal.sort_by_alphabet_reversed",
    [SortingOrderType.SortByOldestToNewest]:
      "merchant_settings_modal.sort_by_earlier_updated",
    [SortingOrderType.SortByNewestToOldest]:
      "merchant_settings_modal.sort_by_latest_modified",
  } as any;

  const sortingOptions = Object.keys(SortingOrderType)
    .filter(item => item !== SortingOrderType.SortByNone)
    .map(item => ({
      key: item,
      text: localized(translateSortingOrderType[item]),
    }));

  const onSortingOrderChange = useCallback(
    (
      _e: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (!option) {
        return;
      }

      const sortingOrder = option.key as string;
      setMerchantSortingOrder(sortingOrder as SortingOrderType);
    },
    [setMerchantSortingOrder]
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <>
      <div>
        <div className={styles["desc"]}>
          <FormattedMessage id="merchant_settings_modal.desc1" />
        </div>
      </div>
      <div className={styles["separator"]} />
      <div className={styles["desc"]}>
        <FormattedMessage id="merchant_settings_modal.desc2" />
      </div>

      <div className={styles["row"]}>
        <SearchBox
          className={styles["filter-text-field"]}
          onChange={onFilterChange}
          value={merchantFilter}
          placeholder={localized("merchant_settings_modal.search_placeholder")}
        />
        <Dropdown
          className={styles["sorting-dropdown"]}
          options={sortingOptions}
          onChange={onSortingOrderChange}
          placeholder={localized("merchant_settings_modal.sort_placeholder")}
        />
      </div>

      <div className={styles["row"]}>
        <MerchantNameList />
        <MerchantForm />
      </div>

      {Object.keys(merchantErrors).length > 0 && (
        <div className={styles["error"]}>
          <Icon iconName="ErrorBadge" />
          <div>
            <FormattedMessage id="merchant_settings_modal.error.unable_to_save" />
          </div>
        </div>
      )}
      <DialogFooter>
        <DefaultButton
          onClick={onClose}
          text={localized(
            hasPermissionToEditResource ? "common.cancel" : "common.close"
          )}
        />
        {hasPermissionToEditResource && (
          <PrimaryButton onClick={onSubmit} text={localized("common.ok")} />
        )}
      </DialogFooter>
    </>
  );
};

interface Props extends ContentProps {
  isOpen: boolean;
}

const MerchantSettingsModal = (props: Props) => {
  const { onClose, isOpen } = props;

  const { localized } = useLocale();

  const modalProps: IModalProps = useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("merchant_settings_modal.title"),
      titleProps: {
        className: styles["model-title"],
      },
    }),
    [localized]
  );

  return (
    <Dialog
      minWidth={854}
      hidden={!isOpen}
      onDismiss={onClose}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <MerchantSettingsProvider>
        <Content onClose={onClose} />
      </MerchantSettingsProvider>
    </Dialog>
  );
};

export default MerchantSettingsModal;

export function useMerchantSettingsModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const merchantSettingsModalPayload = useMemo(
    () => ({
      isOpen,
      onClose,
    }),
    [isOpen, onClose]
  );

  const openMerchantSettingsModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  return useMemo(
    () => ({
      merchantSettingsModalPayload,
      openMerchantSettingsModal,
    }),
    [merchantSettingsModalPayload, openMerchantSettingsModal]
  );
}
