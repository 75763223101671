import React from "react";
import { useCallback, useState } from "react";

import { useAdminActionCreator } from "../actions/admin";
import InviteTeamUserModal from "../components/InviteTeamUserModal";
import LoadingModal from "../components/LoadingModal";
import TeamDetailMemberList from "../components/TeamDetailMemberList";
import { useLocale } from "../contexts/locale";
import errors, { FOCRError } from "../errors";
import { useFetchTeamMembers, useInviteTeamUserModal } from "../hooks/admin";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { AdminPathParam } from "../models";
import { Permission, getTeamRoleMessageId } from "../types/team";

function _AdminTeamDetailMembersContainer() {
  const { teamId, region } = useUnsafeParams<AdminPathParam>();
  const { membersAndInvitations, isFetching, error } = useAppSelector(
    state => state.admin.teamDetail.membersState
  );

  const toast = useToast();

  useFetchTeamMembers(teamId, region);
  const {
    openInviteTeamUserModal,
    closeInviteTeamUserModal,
    onInviteTeamUser,
    isInviteTeamUserModalOpened,
  } = useInviteTeamUserModal(teamId, region);

  const [isInviting, setIsInviting] = useState(false);

  const onInvite = useCallback(
    async (email: string, permission: Permission) => {
      closeInviteTeamUserModal();
      setIsInviting(true);

      try {
        await onInviteTeamUser(email, permission);

        toast.success("invite.new.member.success_message", undefined, {
          email,
        });
      } catch (e) {
        if (e instanceof FOCRError) {
          toast.error(e.messageId, undefined, { email });
        } else {
          toast.error("invite.new.member.fail_message", undefined, { email });
        }
      } finally {
        setIsInviting(false);
      }
    },
    [closeInviteTeamUserModal, onInviteTeamUser, toast]
  );

  const {
    getTeamMembers,
    removeTeamInvitation,
    setTeamUserPermission,
    removeTeamUser,
  } = useAdminActionCreator();

  const removeInvitation = useCallback(
    async (invitationId: string) => {
      if (membersAndInvitations === undefined) {
        return;
      }

      try {
        await removeTeamInvitation(invitationId, region);
      } catch (e) {
        if (e instanceof FOCRError) {
          if (e === errors.TeamInvitationNotFound) {
            getTeamMembers(teamId, region);
          }
          toast.error(e.messageId);
        } else {
          toast.error("team.invitation.remove.unexpected_error");
        }
        return;
      }

      toast.success("team.invitation.remove.success_message");
    },
    [
      membersAndInvitations,
      removeTeamInvitation,
      toast,
      getTeamMembers,
      region,
      teamId,
    ]
  );

  const { localized } = useLocale();

  const updateUserPermission = useCallback(
    async (userId: string, permission: Permission) => {
      if (membersAndInvitations === undefined) {
        return;
      }

      try {
        await setTeamUserPermission(
          userId,
          permission,
          membersAndInvitations.updatedAt,
          teamId,
          region
        );

        const userEmail = membersAndInvitations.members.find(
          member => member.userId === userId
        )?.email;
        if (userEmail)
          toast.success("team.role.updated", undefined, {
            email: userEmail,
            role: localized(getTeamRoleMessageId(permission)),
          });
      } catch (e) {
        if (e !== errors.ConflictFound && e !== errors.ConfirmationRejected) {
          toast.error("team.role.failed_to_update");
        }
      }
    },
    [
      localized,
      setTeamUserPermission,
      toast,
      membersAndInvitations,
      teamId,
      region,
    ]
  );

  const removeUser = useCallback(
    async (userId: string) => {
      if (membersAndInvitations === undefined) {
        return;
      }

      try {
        await removeTeamUser(userId, teamId, region);
      } catch {
        toast.error("team.remove_user.unexpected_error");
        return;
      }

      toast.success("team.remove_user.success_message");
    },
    [membersAndInvitations, removeTeamUser, toast, teamId, region]
  );

  return (
    <>
      <TeamDetailMemberList
        isAdminPage
        teamMembersData={membersAndInvitations}
        isFetching={isFetching}
        hasError={!!error}
        setTeamUserPermission={updateUserPermission}
        removeTeamUser={removeUser}
        removeInvitation={removeInvitation}
        onInviteMember={openInviteTeamUserModal}
      />
      <InviteTeamUserModal
        isOpen={isInviteTeamUserModalOpened}
        onCancel={closeInviteTeamUserModal}
        onInvite={onInvite}
      />
      <LoadingModal isOpen={isInviting} />
    </>
  );
}

export const AdminTeamDetailMembersContainer = React.memo(
  _AdminTeamDetailMembersContainer
);
export default AdminTeamDetailMembersContainer;
