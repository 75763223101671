import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import {
  FSL_CUSTOM_MODEL_IMAGE_MAX_COUNT,
  SUPPORTED_EXTRACT_MIME,
} from "../../constants";
import { useFSLCustomModelEditor } from "../../contexts/fslCustomModelEditor";
import { useFSLInstantModelViewerContainer } from "../../contexts/fslInstantModelViewer";
import {
  useDragAndDropDefaultOptions,
  useDragAndDropFiles,
} from "../../hooks/drag_and_drop";
import { useToast } from "../../hooks/toast";
import { DangerButton } from "../DangerButton";
import { Dropzone } from "../Dropzone";
import { FSLActiveModelPicker } from "../FSLActiveModelPicker";
import { FSLSchemaNotSetWarning } from "../FSLSchemaNotSetWarning";
import { FSLSplitView } from "../FSLSplitView";
import { MissionBar, MissionBarType } from "../MissionBar";
import { PageHeader } from "../PageHeader";
import { SampleImageSelectableCard } from "../SampleImageSelectableCard";
import { ActionButton, DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

function StyledMissionBar(props: {
  type: MissionBarType;
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  right?: React.ReactElement;
}) {
  return <MissionBar {...props} className={styles["styled-mission-bar"]} />;
}

function FirstStepMissionBar() {
  return (
    <StyledMissionBar
      type={MissionBarType.Info}
      title={
        <FormattedMessage
          id="fsl_instant_model_editor.mission_bar.first_step.title"
          values={{
            progress: (
              <b>
                <FormattedMessage id="fsl_instant_model_editor.mission_bar.first_step.title_progress" />
              </b>
            ),
          }}
        />
      }
      subtitle="fsl_instant_model_editor.mission_bar.first_step.subtitle"
    />
  );
}

function LastStepMissionBar() {
  return (
    <StyledMissionBar
      type={MissionBarType.Info}
      title={
        <FormattedMessage
          id="fsl_instant_model_editor.mission_bar.last_step.title"
          values={{
            progress: (
              <b>
                <FormattedMessage id="fsl_instant_model_editor.mission_bar.last_step.title_progress" />
              </b>
            ),
          }}
        />
      }
    />
  );
}

function ReadyToUseMissionBar() {
  const { navigateToTest } = useFSLCustomModelEditor();

  return (
    <StyledMissionBar
      type={MissionBarType.Success}
      title={
        <FormattedMessage id="fsl_instant_model_editor.mission_bar.ready_to_use.title" />
      }
      subtitle="fsl_instant_model_editor.mission_bar.ready_to_use.subtitle"
      right={
        <DangerButton
          textId="fsl_instant_model_editor.mission_bar.ready_to_use.test_extractor"
          onClick={navigateToTest}
        />
      }
    />
  );
}

function DocumentTooComplexMissionBar() {
  const { triggerGetHelpFromFormXProcess, navigateToStandardModel } =
    useFSLCustomModelEditor();
  return (
    <StyledMissionBar
      type={MissionBarType.Warning}
      title="fsl_instant_model_editor.mission_bar.document_too_complex.title"
      subtitle="fsl_instant_model_editor.mission_bar.document_too_complex.subtitle"
      right={
        <>
          <DangerButton
            textId="fsl_instant_model_editor.mission_bar.try_standard_model_button"
            onClick={navigateToStandardModel}
          />
          <DefaultButton
            textId="fsl_instant_model_editor.mission_bar.get_help_button"
            onClick={triggerGetHelpFromFormXProcess}
          />
        </>
      }
    />
  );
}

export function useFSLInstantModelViewState() {
  const {
    extractedContentSchema,
    requestToDeleteCustomModelImages,
    isDocumentTooComplex,
  } = useFSLCustomModelEditor();

  const {
    isReadyToUseOnce,
    customModelImages,
    isReadyToUse,
    uploadSampleImage,
    noOfSampleImages,
  } = useFSLInstantModelViewerContainer();

  const [checkedCustomModelIds, setCheckedCustomModelIds] = React.useState<
    string[]
  >([]);

  const sampleImages = React.useMemo(() => {
    const customModelImagesCount = customModelImages?.length ?? 0;
    // Disable the 4th sample images
    const outputCount = 3;

    return Array.from(Array(outputCount).keys()).map(index => {
      const actionLabel = `fsl_instant_model_editor.sample_image_grid_${index}`;
      const isChecked = checkedCustomModelIds.includes(
        customModelImages?.[index]?.id ?? ""
      );
      const {
        image,
        filename,
        createdAt,
        reviewedAt,
        id,
        isCorrected,
        groupId,
      } =
        index < customModelImagesCount
          ? {
              isCorrected: customModelImages?.[index].state === "reviewed",
              filename: customModelImages?.[index].filename,
              image: customModelImages?.[index].url,
              createdAt: customModelImages?.[index].createdAt,
              reviewedAt: customModelImages?.[index].reviewedAt,
              id: customModelImages?.[index].id ?? "",
              groupId: customModelImages?.[index].info?.group_id,
            }
          : {
              id: "",
              filename: undefined,
              image: undefined,
              createdAt: undefined,
              reviewedAt: undefined,
              isCorrected: false,
              groupId: undefined,
            };
      const isSelected = index === customModelImagesCount;
      const isDisabled = index > customModelImagesCount;
      const badgeLabel = isCorrected
        ? "fsl_custom_model.fsl_sample_image.reviewed"
        : "fsl_custom_model.fsl_sample_image.pending_review";
      const isReviewed = reviewedAt != null;

      return {
        image,
        filename,
        actionLabel,
        createdAt,
        id,
        isDisabled,
        isChecked,
        isSelected,
        badgeLabel,
        isCorrected,
        isReviewed,
        groupId,
      };
    });
  }, [customModelImages, checkedCustomModelIds]);

  const requestToDeleteSampleImages = React.useCallback(() => {
    requestToDeleteCustomModelImages(checkedCustomModelIds, true).then(
      (res: boolean) => {
        if (res) {
          setCheckedCustomModelIds([]);
        }
      }
    );
  }, [checkedCustomModelIds, requestToDeleteCustomModelImages]);

  const setCheckedCustomModelId = React.useCallback(
    (id: string, checked: boolean) => {
      if (checked) {
        setCheckedCustomModelIds([...checkedCustomModelIds, id]);
      } else {
        setCheckedCustomModelIds(
          checkedCustomModelIds.filter(_id => _id !== id)
        );
      }
    },
    [checkedCustomModelIds]
  );

  const dragAndDropDefaultOptions = useDragAndDropDefaultOptions();

  const isFileOver =
    dragAndDropDefaultOptions.isFileOver &&
    noOfSampleImages < FSL_CUSTOM_MODEL_IMAGE_MAX_COUNT;

  const toast = useToast();

  const onSelectFiles = React.useCallback(
    (files: File[]) => {
      if (!files || files.length === 0) {
        toast.error("form.editor.no.image.missing.image");
        return;
      }

      if (noOfSampleImages < FSL_CUSTOM_MODEL_IMAGE_MAX_COUNT) {
        uploadSampleImage(files[0]);
      }
    },
    [uploadSampleImage, noOfSampleImages, toast]
  );

  useDragAndDropFiles(
    (files?: File[]) => onSelectFiles(files ?? []),
    SUPPORTED_EXTRACT_MIME,
    undefined,
    dragAndDropDefaultOptions.options
  );

  return React.useMemo(
    () => ({
      sampleImages,
      extractedContentSchema,
      setCheckedCustomModelId,
      checkedCustomModelIds,
      requestToDeleteSampleImages,
      isReadyToUse,
      isDocumentTooComplex,
      isReadyToUseOnce,
      noOfSampleImages,
      isFileOver,
    }),
    [
      sampleImages,
      extractedContentSchema,
      setCheckedCustomModelId,
      checkedCustomModelIds,
      requestToDeleteSampleImages,
      isReadyToUse,
      isDocumentTooComplex,
      isReadyToUseOnce,
      noOfSampleImages,
      isFileOver,
    ]
  );
}

export function FSLInstantModelViewerImpl(
  props: ReturnType<typeof useFSLInstantModelViewState>
) {
  const {
    sampleImages,
    extractedContentSchema,
    setCheckedCustomModelId,
    checkedCustomModelIds,
    requestToDeleteSampleImages,
    isReadyToUse,
    isReadyToUseOnce,
    isDocumentTooComplex,
    noOfSampleImages,
    isFileOver,
  } = props;
  const { reviewExtractionResult, requestToUploadSampleImage } =
    useFSLInstantModelViewerContainer();

  return (
    <div className={styles["fsl-instant-model-editor"]}>
      {isDocumentTooComplex ? (
        <DocumentTooComplexMissionBar />
      ) : isReadyToUse ? (
        <ReadyToUseMissionBar />
      ) : !isReadyToUseOnce && noOfSampleImages > 0 ? (
        <LastStepMissionBar />
      ) : !isReadyToUseOnce &&
        !isReadyToUse &&
        extractedContentSchema !== undefined ? (
        <FirstStepMissionBar />
      ) : null}
      <FSLSplitView
        className={styles["split-view"]}
        isDividerVisible={true}
        left={
          <div className={styles["fsl-instant-model-editor-left"]}>
            <PageHeader
              title="fsl_instant_model_editor.page.title"
              subtitle="fsl_instant_model_editor.page.subtitle"
              dividerVisible={false}
            />
            {extractedContentSchema === undefined ? (
              <FSLSchemaNotSetWarning />
            ) : (
              <div className={styles["fsl-instant-model-editor-left-content"]}>
                <div className={styles["fsl-instant-model-editor-toolbar"]}>
                  <ActionButton
                    isDestroy={true}
                    iconName="trash"
                    textId="fsl_instant_model_editor.delete_samples"
                    disabled={checkedCustomModelIds.length === 0}
                    onClick={requestToDeleteSampleImages}
                  />
                </div>

                <div
                  className={
                    styles["fsl-instant-model-editor-image-list-container"]
                  }
                >
                  {isFileOver && <Dropzone className={styles["dropzone"]} />}
                  <div
                    className={
                      styles["fsl-instant-model-editor-image-list-scrollable"]
                    }
                  >
                    <div
                      className={styles["fsl-instant-model-editor-image-list"]}
                    >
                      {sampleImages.map((sampleImage, index: number) => {
                        return (
                          <SampleImageSelectableCard
                            key={index}
                            actionLabel={sampleImage.actionLabel}
                            image={sampleImage.image}
                            filename={sampleImage.filename ?? ""}
                            uploadedAt={sampleImage.createdAt}
                            isCorrected={sampleImage.isCorrected}
                            badgeLabel={sampleImage.badgeLabel}
                            onClick={() => {
                              if (sampleImage.id !== "") {
                                reviewExtractionResult(
                                  sampleImage.id,
                                  sampleImage.isReviewed,
                                  sampleImage.groupId
                                );
                              } else {
                                requestToUploadSampleImage();
                              }
                            }}
                            onCheckboxChange={checked => {
                              setCheckedCustomModelId(sampleImage.id, checked);
                            }}
                            isDisabled={sampleImage.isDisabled}
                            isChecked={sampleImage.isChecked}
                            isSelected={sampleImage.isSelected}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        }
        right={<FSLActiveModelPicker />}
        rightVisible={true}
        rightMaxWidth={284}
        rightMinWidth={284}
      />
    </div>
  );
}

export function FSLInstantModelViewer() {
  const states = useFSLInstantModelViewState();
  return <FSLInstantModelViewerImpl {...states} />;
}
