import * as yup from "yup";

export type BoundingBox = yup.InferType<typeof boundingBoxSchema>;

export const boundingBoxSchema = yup.object({
  top: yup.number().defined(),
  left: yup.number().defined(),
  right: yup.number().defined(),
  bottom: yup.number().defined(),
});

// Accessor to the bounding box in number[] format
export class NumberListBoundingBoxAccessor {
  data: number[];

  constructor(data: number[]) {
    this.data = data;
  }

  isValid() {
    return (
      this.data.length === 4 && this.data.every(x => typeof x === "number")
    );
  }

  get left() {
    return this.data[0];
  }

  get top() {
    return this.data[1];
  }

  get right() {
    return this.data[2];
  }

  get bottom() {
    return this.data[3];
  }

  toFixed(fractionDigits?: number): NumberListBoundingBoxAccessor {
    this.data = this.data.map(v => parseFloat(v.toFixed(fractionDigits)));
    return this;
  }

  subRegion(boundingBox: number[]) {
    const [left, top, right, bottom] = boundingBox;
    const width = this.right - this.left;
    const height = this.bottom - this.top;
    const newLeft = this.left + left * width;
    const newTop = this.top + top * height;
    const newRight = this.left + right * width;
    const newBottom = this.top + bottom * height;
    this.data = [newLeft, newTop, newRight, newBottom];
    return this;
  }
}

export function accessNumberListBoundingBox(
  data: number[]
): NumberListBoundingBoxAccessor {
  return new NumberListBoundingBoxAccessor(data);
}
