@import "../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  overflow-y: hidden;
  margin-left: 16px;

  .separator {
    height: 1px;
    width: calc(100% - #{$tab-bar-right-space});
    background-color: $tab-bar-separator;
  }
}

.content {
  flex: 1;
  overflow-y: auto;
}

.save-button-container {
  margin-right: $tab-bar-right-space;
  border-top: 1px solid $tab-bar-separator;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  > .save-button {
    margin-top: 12px;
    // margin-right: $tab-bar-right-space;
    background-color: $attention-color;
    border-color: $attention-color;
  }
  > .save-button:hover {
    background-color: darken($color: $attention-color, $amount: 20%);
    border-color: darken($attention-color, $amount: 20%);
  }
  > .save-button:active {
    background-color: darken($color: $attention-color, $amount: 30%);
    border-color: darken($attention-color, $amount: 30%);
  }
  > .disabled {
    background-color: #f4f4f4;
    border-color: #f4f4f4;
  }
}

// .buttons {
//   text-align: center;
// }
