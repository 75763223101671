import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import React from "react";
import { useCallback, useMemo, useState } from "react";

import { useLocale } from "../../contexts/locale";
import {
  Permission,
  adminPermission,
  editorPermission,
  getTeamRoleByMessageId,
  getTeamRoleMessageId,
  readOnlyPermission,
} from "../../types/team";

interface Props {
  isOpen: boolean;
  onCancel(): void;
  onInvite(email: string, permission: Permission): Promise<void>;
}

function _InviteTeamUserModal(props: Props) {
  const { isOpen, onCancel, onInvite } = props;
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState<
    string | undefined
  >();

  const { localized } = useLocale();

  const [access, setAccess] = useState(getTeamRoleMessageId(adminPermission));

  const onEmailChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();

      if (value !== undefined) {
        setEmail(value);
        setEmailErrorMessage(undefined);
      }
    },
    []
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (email.length === 0) {
        setEmailErrorMessage("error.invite.empty_email");
      } else if (!email.includes("@")) {
        setEmailErrorMessage("error.invite.invalid_email");
      } else {
        const permission = getTeamRoleByMessageId(access);
        if (permission) {
          onInvite(email, permission);
        }
      }
    },
    [email, access, onInvite]
  );

  const onDismissed = useCallback(() => {
    setEmail("");
  }, []);

  const modalProps: IModalProps = useMemo(
    () => ({
      onDismissed,
    }),
    [onDismissed]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("invite.new.member.title"),
    }),
    [localized]
  );

  const onAccessChange = useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (typeof option?.key === "string") {
        setAccess(option.key);
      }
    },
    []
  );

  const accessOptions = useMemo((): IDropdownOption[] => {
    return [adminPermission, editorPermission, readOnlyPermission].map(
      permission => {
        const messageId = getTeamRoleMessageId(permission);
        return {
          key: messageId,
          text: localized(messageId),
        };
      }
    );
  }, [localized]);

  return (
    <Dialog
      minWidth={500}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={onSubmit}>
        <TextField
          label={localized("common.email")}
          value={email}
          onChange={onEmailChange}
          errorMessage={emailErrorMessage && localized(emailErrorMessage)}
          type="email"
        />
        <Dropdown
          label={localized("invite.new.member.access")}
          options={accessOptions}
          selectedKey={access}
          onChange={onAccessChange}
        />
        <DialogFooter>
          <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
          <PrimaryButton type="submit" text={localized("invite.new.member")} />
        </DialogFooter>
      </form>
    </Dialog>
  );
}

export const InviteTeamUserModal = React.memo(_InviteTeamUserModal);
export default InviteTeamUserModal;
