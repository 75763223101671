import { ScriptEditorModalPayload } from "../models";
import {
  DetectionRegionField,
  DetectionRegionFieldExtra,
  DetectionRegionFieldParams,
} from "./detectionRegion";
import { DetailedForm } from "./form";
import { DetailFormGroup } from "./formGroup";

export interface ScriptEditorModalPayloadForDetectionRegionField {
  field: DetectionRegionField;
  index: number;
  selectedDetectionRegionId: string;
  onSave: (
    script: string,
    detectionRegionFieldParams?: DetectionRegionFieldParams,
    extras?: DetectionRegionFieldExtra
  ) => void;
}

export type ScriptType = "post_process_script" | "transform_response_script";

export interface ScriptEditorModalPayloadForForm {
  form: DetailedForm;
  scriptType: ScriptType;
  onSave: (script: string) => void;
}

export interface ScriptEditorModalPayloadForFormGroup {
  formGroup: DetailFormGroup;
  scriptType: ScriptType;
  onSave: (script: string) => void;
}

export function isScriptEditorModalPayloadForDetectionRegionField(
  payload: ScriptEditorModalPayload
): payload is ScriptEditorModalPayloadForDetectionRegionField {
  return (
    (payload as ScriptEditorModalPayloadForDetectionRegionField).field !==
    undefined
  );
}

export function isScriptEditorModalPayloadForForm(
  payload: ScriptEditorModalPayload
): payload is ScriptEditorModalPayloadForForm {
  return (payload as ScriptEditorModalPayloadForForm).form !== undefined;
}
