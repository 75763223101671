import {
  CustomModelImage,
  customModelImageSchema,
} from "../../types/customModelImage";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface CustomModelImageApiClient {
  createCustomModelImage: (
    customModelId: string,
    assetId: string,
    info?: Record<string, any>
  ) => Promise<CustomModelImage>;

  getCustomModelImage: (
    customModelId: string,
    excludePagebox: boolean,
    excludeAnnotation: boolean,
    includeUrl?: boolean
  ) => Promise<CustomModelImage>;

  deleteCustomModelImage: (
    customModelImageId: string,
    shouldDeleteGroup?: boolean
  ) => Promise<void>;

  patchCustomModelImageInfo: (
    customModelImageId: string,
    patch: Record<string, string>
  ) => Promise<void>;
}

export function withCustomModelImageApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async createCustomModelImage(
      customModelId: string,
      assetId: string,
      info?: Record<string, any>
    ): Promise<CustomModelImage> {
      return this.lambda(
        "custom_model_image:create",
        this.injectOptionalFields(
          {
            custom_model_id: customModelId,
            asset_id: assetId,
          },
          {
            info,
          }
        ),
        customModelImageSchema
      );
    }

    async getCustomModelImage(
      customModelId: string,
      excludePagebox: boolean,
      excludeAnnotation: boolean,
      includeUrl?: boolean
    ): Promise<CustomModelImage> {
      return this.lambda(
        "custom_model_image:get",
        this.injectOptionalFields(
          {
            custom_model_image_id: customModelId,
            exclude_pagebox: excludePagebox,
            exclude_annotation: excludeAnnotation,
            include_url: includeUrl,
          },
          {}
        ),
        customModelImageSchema
      );
    }

    async deleteCustomModelImage(
      customModelImageId: string,
      shouldDeleteGroup?: boolean
    ): Promise<void> {
      return this.lambda(
        "custom_model_image:delete",

        this.injectOptionalFields(
          {
            custom_model_image_id: customModelImageId,
          },
          { should_delete_group: shouldDeleteGroup }
        )
      );
    }

    async patchCustomModelImageInfo(
      customModelImageId: string,
      patch: Record<string, string>
    ): Promise<void> {
      return this.lambda("custom_model_image:patch-info", {
        custom_model_image_id: customModelImageId,
        patch,
      });
    }
  };
}
