import {
  DefaultButton,
  DialogFooter,
  DialogType,
  Dropdown,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  PrimaryButton,
  Text,
  TextField,
} from "@fluentui/react";
import classnames from "classnames";
import produce from "immer";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { CUSTOM_FIELD_POSITION_MENU_OPTIONS } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { DetailedForm } from "../../types/form";
import {
  KeyValue,
  KeyValuePosition,
  KeyValueToken,
} from "../../types/keyValue";
import { KeyValueExample } from "../KeyValueExample";
import RegexBuilder from "../RegexBuilder";
import ScrollableModal from "../ScrollableModal";
import { KeySection } from "./KeySection";
import styles from "./styles.module.scss";

interface Props {
  mode: "edit" | "create";
  isOpen: boolean;
  keyValue?: KeyValue;
  form: DetailedForm;
  renameEnabled: boolean;

  onCancel(): void;
  onSubmit(keyValue: KeyValue, originalName?: string): void;
}

interface BaseProps extends Props {
  titleId: string;
}

const EmptyKeyValue: KeyValue = {
  name: "",
  tokens: [],
  pattern: "",
  position: "right",
  created_at: 0,
};

const ModalBase = React.memo((props: BaseProps) => {
  const {
    keyValue: defaultKeyValue,
    onSubmit,
    mode,
    onCancel,
    isOpen,
    form,
    renameEnabled,
  } = props;
  const { hasPermissionToEditResource } = useTeamPermission();
  const { localized } = useLocale();

  const [keyValue, setKeyValue] = useState<KeyValue>(EmptyKeyValue);
  const [isRegexBuilderShown, setIsRegexBuilderShown] = useState(false);

  const [keysErrorMessageId, setKeysErrorMessageId] = useState<
    string | undefined
  >();

  const [valuesErrorMessageId, setValuesErrorMessageId] = useState<
    string | undefined
  >();

  const [patternErrorMessageId, setPatternErrorMessageId] = useState<
    string | undefined
  >();

  const [tokenErrorMessages, setTokenErrorMessages] = useState<
    (string | null)[]
  >([]);

  const [labelErrorMessageId, setLabelErrorMessageId] = useState<
    string | undefined
  >();

  const resetErrorMessages = useCallback(() => {
    setPatternErrorMessageId(undefined);
    setKeysErrorMessageId(undefined);
    setValuesErrorMessageId(undefined);
    setTokenErrorMessages([]);
    setLabelErrorMessageId(undefined);
  }, []);

  useEffect(() => {
    if (defaultKeyValue) {
      setKeyValue(defaultKeyValue);
    }
  }, [defaultKeyValue]);

  const validate = useCallback(() => {
    resetErrorMessages();
    let isValid = true;
    const { keyValues } = form;
    const existingKeyValueNames = keyValues.map((x: any) => x.name);

    if (keyValue.name.trim().length === 0) {
      setLabelErrorMessageId("key_value.modal.label.empty_error");
      isValid = false;
    }
    const originalName = defaultKeyValue ? defaultKeyValue.name : undefined;

    if (
      keyValue.name.trim() !== originalName &&
      keyValue.name.trim() !== "" &&
      existingKeyValueNames.indexOf(keyValue.name.trim()) !== -1
    ) {
      setLabelErrorMessageId("key_value.modal.label.duplicated_error");
      isValid = false;
    }

    const tokenErrorMessages = keyValue.tokens.map(token => {
      if (token.token.trim().length === 0) {
        return localized("key_value.modal.key.empty_error");
      }
      try {
        new RegExp(token.token);
      } catch (err) {
        return localized("key_value.modal.key.invalid_regex_error");
      }
      return null;
    });
    setTokenErrorMessages(tokenErrorMessages);

    if (tokenErrorMessages.some(message => message != null)) {
      isValid = false;
    }

    if (!keyValue.pattern.trim() && keyValue.tokens.length === 0) {
      setKeysErrorMessageId("key_value.modal.pattern_or_key_is_required_error");
      setValuesErrorMessageId(
        "key_value.modal.pattern_or_key_is_required_error"
      );
      isValid = false;
    }

    try {
      new RegExp(keyValue.pattern);
    } catch (err) {
      setPatternErrorMessageId("key_value.modal.pattern.invalid_regex_error");
      isValid = false;
    }

    return isValid;
  }, [keyValue, resetErrorMessages, localized, defaultKeyValue, form]);

  const _onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!validate()) {
        return;
      }

      onSubmit(keyValue, defaultKeyValue ? defaultKeyValue.name : undefined);
    },
    [keyValue, defaultKeyValue, onSubmit, validate]
  );

  const onPatternChange = useCallback(
    (
      event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      if (newValue !== undefined) {
        setKeyValue(
          produce(keyValue, draftKeyValue => {
            draftKeyValue.pattern = newValue;
          })
        );
        setPatternErrorMessageId(undefined);
      }
    },
    [keyValue]
  );

  const onPositionChange = useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option) {
        setKeyValue(
          produce(keyValue, draftKeyValue => {
            draftKeyValue.position = option.key as KeyValuePosition;
          })
        );
      }
    },
    [keyValue]
  );

  const onTokensChanged = useCallback(
    (newTokens: KeyValueToken[]) => {
      setKeyValue(
        produce(keyValue, draftKeyValue => {
          draftKeyValue.tokens = newTokens;
        })
      );
    },
    [keyValue]
  );

  const onApply = useCallback(
    (regex: string) => {
      onPatternChange(undefined, regex);
      setIsRegexBuilderShown(false);
    },
    [onPatternChange]
  );

  const onClickBuilder = useCallback(() => {
    setIsRegexBuilderShown(true);
  }, []);

  const onRegexBuilderCancel = useCallback(() => {
    setIsRegexBuilderShown(false);
  }, []);

  const onDismissed = useCallback(() => {
    setKeyValue(EmptyKeyValue);
    resetErrorMessages();
    setIsRegexBuilderShown(false);
  }, [resetErrorMessages]);

  const modalProps: IModalProps = useMemo(
    () => ({
      isBlocking: true,
      onDismissed,
    }),
    [onDismissed]
  );

  const formProps = useMemo(
    () => ({
      onSubmit: _onSubmit,
    }),
    [_onSubmit]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("key_value.modal.title"),
    }),
    [localized]
  );

  const onNameChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      event.stopPropagation();
      event.preventDefault();
      if (newValue !== undefined) {
        setKeyValue(
          produce(keyValue, draftKeyValue => {
            draftKeyValue.name = newValue;
          })
        );

        setLabelErrorMessageId(undefined);
      }
    },
    [keyValue, setLabelErrorMessageId]
  );

  return (
    <ScrollableModal
      minWidth={650}
      hidden={!isOpen}
      onDismiss={onCancel}
      modalProps={modalProps}
      formProps={formProps}
      dialogContentProps={dialogContentProps}
      className={styles["key-value-modal"]}
      footerItem={
        <DialogFooter>
          <DefaultButton
            onClick={onCancel}
            text={localized(
              hasPermissionToEditResource ? "common.cancel" : "common.close"
            )}
          />
          {hasPermissionToEditResource && (
            <PrimaryButton
              type="submit"
              text={localized(`edit_custom_field.submit.${mode}`)}
            />
          )}
        </DialogFooter>
      }
    >
      <React.Fragment>
        <div className={styles["modal-desc-row"]}>
          <Text className={styles["modal-desc-text"]}>
            {localized("key_value.modal.desc")}
          </Text>
        </div>
        <KeyValueExample />
        <div className={styles["modal"]}>
          {renameEnabled && (
            <TextField
              className={styles["input-field"]}
              label={localized("key_value.modal.label")}
              onChange={onNameChange}
              value={keyValue.name}
              errorMessage={
                labelErrorMessageId && localized(labelErrorMessageId)
              }
              disabled={!hasPermissionToEditResource}
            />
          )}

          <KeySection
            tokens={keyValue.tokens}
            onChange={onTokensChanged}
            tokenErrorMessages={tokenErrorMessages}
            sectionErrorMessage={
              keysErrorMessageId && localized(keysErrorMessageId)
            }
          />
          <div className={styles["values-title-row"]}>
            <Text className={styles["values-title-text"]}>
              {localized("key_value.modal.value")}
            </Text>
            {valuesErrorMessageId && (
              <Text className={styles["values-error-text"]}>
                {localized(valuesErrorMessageId)}
              </Text>
            )}
          </div>
          <Dropdown
            className={classnames(styles["input-field"])}
            label={localized("edit_custom_field.position")}
            options={CUSTOM_FIELD_POSITION_MENU_OPTIONS.map(
              ({ key, text }) => ({
                key,
                text: localized(text),
              })
            )}
            selectedKey={keyValue.position}
            onChange={onPositionChange}
            disabled={
              keyValue.tokens.length === 0 || !hasPermissionToEditResource
            }
          />
          <div
            className={classnames(styles["input-field"], styles["pattern-row"])}
          >
            <TextField
              className={styles["regex-input"]}
              label={localized("key_value.modal.pattern")}
              onChange={onPatternChange}
              value={keyValue.pattern}
              errorMessage={
                patternErrorMessageId && localized(patternErrorMessageId)
              }
              disabled={!hasPermissionToEditResource}
            />
            {hasPermissionToEditResource && (
              <Text
                className={styles["regex-builder-button"]}
                onClick={onClickBuilder}
              >
                {localized("key_value.modal.regex_builder")}
              </Text>
            )}
          </div>
          <Text variant="small">
            {localized("key_value.modal.advance_usage")}
          </Text>
          <RegexBuilder
            isOpen={isRegexBuilderShown}
            onApply={onApply}
            onCancel={onRegexBuilderCancel}
          />
        </div>
      </React.Fragment>
    </ScrollableModal>
  );
});

const KeyValueModal = React.memo((props: Props) => {
  return <ModalBase titleId={"key_value_modal.title"} {...props} />;
});

export { KeyValueModal };
