import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { PreferenceKey, setPreference } from "../utils/preference";

export enum URLParamsKey {
  team = "team",
  region = "region",
  filterType = "filter_type",
  page = "page",
  search = "search",
  plan = "plan",
  authToken = "auth_token",
  sortColumn = "sort_column",
  sortOrder = "sort_order",
  file = "file",
  guidance = "guidance",
  previewResult = "preview_result",
}

let WorkingSearchParams = new URLSearchParams();
let _ShouldReplace = true;

export function useSearchParamUtils() {
  const setSearchParams = useSearchParams()[1];

  const setSearchParamsRef = useRef(setSearchParams);
  setSearchParamsRef.current = setSearchParams;

  const setParams = useCallback(
    (
      keyValues: Map<URLParamsKey, string | undefined>,
      isReplace: boolean = false
    ) => {
      const teamValue = keyValues.get(URLParamsKey.team);

      for (const key of keyValues.keys()) {
        const value = keyValues.get(key);

        if (value === undefined || value === "") {
          WorkingSearchParams.delete(key);
        } else {
          WorkingSearchParams.set(key, value);
        }
      }

      _ShouldReplace = _ShouldReplace && isReplace;

      setSearchParamsRef.current(WorkingSearchParams, {
        replace: _ShouldReplace,
      });

      if (teamValue) {
        setPreference(PreferenceKey.lastSelectedTeamLookupId, teamValue);
      }
    },
    []
  );

  const setParam = useCallback(
    (key: URLParamsKey, value: string, isReplace: boolean = true) => {
      setParams(new Map([[key, value]]), isReplace);
    },
    [setParams]
  );

  const getParam = useCallback(
    (key: URLParamsKey) => WorkingSearchParams.get(key),
    []
  );

  const removeParam = useCallback(
    (key: URLParamsKey, isReplace: boolean = false) => {
      WorkingSearchParams.delete(key);
      _ShouldReplace = _ShouldReplace && isReplace;

      setSearchParamsRef.current(WorkingSearchParams, {
        replace: _ShouldReplace,
      });
    },
    []
  );

  return useMemo(
    () => ({
      getParam,
      setParams,
      setParam,
      removeParam,
    }),
    [getParam, setParams, setParam, removeParam]
  );
}

/* NOTE (jasonkit):
 * We should only place ONLY ONE <WorkingSearchParamsUpdater />
 * in the whole app, and it should be the first child of
 * react-router's browser component.
 */
export function WorkingSearchParamsUpdater() {
  const searchParams = useSearchParams()[0];
  useEffect(() => {
    WorkingSearchParams = searchParams;
    _ShouldReplace = true;
  }, [searchParams]);

  return null;
}
