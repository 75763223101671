@import "../../styles/variables.scss";

.container {
  .info-container {
    padding: 12px 0;
    margin-bottom: 18px;
    font-size: 12px;
    line-height: 16px;
    color: #605e5c;
  }

  .loading-container {
    margin-top: 196px;
  }

  .no-images {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 518px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .desc {
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #c4c4c4;
      margin-bottom: 16px;
    }
  }
}
