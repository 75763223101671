@import "../../styles/variables.scss";

$text-color: #201f1e;

.danger-button {
  background-color: $attention-color;
  color: $text-color;
  border: none;
}

.danger-button:hover {
  background-color: darken($color: $attention-color, $amount: 20%);
  color: $text-color;
  border: none;
}

.danger-button:active {
  background-color: darken($color: $attention-color, $amount: 30%);
  color: $text-color;
  border: none;
}

.danger-button:disabled {
  background-color: #f4f4f4;
  color: lighten($color: $text-color, $amount: 50%);
  border: none;
}
