import { Spinner, SpinnerLabelPosition, SpinnerSize } from "@fluentui/react";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

interface Props {
  labelId?: string;
  labelPosition?: SpinnerLabelPosition;
}

const ShortSpinner = React.memo(({ labelId, labelPosition }: Props) => {
  const { localized } = useLocale();

  return (
    <div className={styles["spinner-container"]}>
      <Spinner
        size={SpinnerSize.medium}
        label={localized(labelId || "common.loading")}
        labelPosition={labelPosition || "right"}
        styles={{
          label: {
            fontSize: "14px",
          },
        }}
      />
    </div>
  );
});

export default ShortSpinner;
