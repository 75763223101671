@import "../../styles/variables.scss";

.model-title {
  font-weight: 600;
}

.desc {
  div:first-child {
    margin-bottom: 20px;
  }

  margin-bottom: 16px;
}

.filter-text-field {
  max-width: 576px;
  width: 576px;
  margin-right: 12px;
}

.filter-text-icon {
  left: 0px;
  right: auto;
}

.sorting-dropdown {
  max-width: 224px;
  width: 224px;
}

.row {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}

.error {
  background: #f5879133;
  height: 32px;
  width: 100%;
  color: #201f1e;
  display: flex;
  align-items: center;
  padding-left: 12px;

  i {
    color: #a4262c;
    margin-right: 8px;
  }
}

.separator {
  height: 1px;
  width: 100%;
  margin: 24px 0 24px;
  background-color: $separator-color-1;
}

.fallback-dropdown-label {
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  padding-right: 16px;
}

.fallback-dropdown {
  max-width: 395px;
  width: 395px;
}
