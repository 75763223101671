import DeleteSvgText from "bundle-text:../../../../images/icons/delete.svg";
import EraseSvgText from "bundle-text:../../../../images/icons/erase.svg";
import ErrorSvgText from "bundle-text:../../../../images/icons/error.svg";
import QuantitySvgText from "bundle-text:../../../../images/icons/quantity.svg";
import RegexSvgText from "bundle-text:../../../../images/icons/regex.svg";
import SettingSvgText from "bundle-text:../../../../images/icons/setting.svg";
import { parseInt } from "lodash";

export type IconName =
  | "erase"
  | "quantity"
  | "regex"
  | "delete"
  | "setting"
  | "error";

interface SvgInfo {
  width: number;
  height: number;
  path: string;
}

function loadFromSvgText(svgText: string): SvgInfo {
  return {
    width: parseInt((/width="(\d+)"/i.exec(svgText) ?? ["", "0"])[1]),
    height: parseInt((/height="(\d+)"/i.exec(svgText) ?? ["", "0"])[1]),
    path: (/<path .*d="(.*Z)"/i.exec(svgText) ?? ["", ""])[1] ?? "",
  };
}

const iconSvgInfos: Record<IconName, SvgInfo> = {
  erase: loadFromSvgText(EraseSvgText),
  quantity: loadFromSvgText(QuantitySvgText),
  regex: loadFromSvgText(RegexSvgText),
  delete: loadFromSvgText(DeleteSvgText),
  setting: loadFromSvgText(SettingSvgText),
  error: loadFromSvgText(ErrorSvgText),
};

export function renderIcon(
  icon: IconName,
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  color: string
): void {
  const svgInfo = iconSvgInfos[icon];
  context.save();
  context.fillStyle = color;
  context.translate(x, y);
  context.scale(size / svgInfo.width, size / svgInfo.height);
  context.fill(new Path2D(svgInfo.path));
  context.restore();
}
