import {
  ISpinButtonProps,
  SpinButton as MSSpinButton,
  getTheme,
} from "@fluentui/react";
import React, { useCallback, useMemo } from "react";

import { clamp } from "../../../utils/number";
import ErrorText from "../ErrorText";
import styles from "./styles.module.scss";

export interface SpinButtonProps
  extends Omit<
    ISpinButtonProps,
    "onValidate" | "onIncrement" | "onDecrement" | "onChange"
  > {
  min: number;
  max: number;
  onChange: (v: string) => void;
  value?: string;
  label?: string;
  labelPosition?: number;
  error?: string;
}

export default function SpinButton(props: SpinButtonProps) {
  const {
    min,
    max,
    step,
    onChange,
    className,
    error,
    value: actualValue,
    ...otherProps
  } = props;

  const onValidate = useCallback(
    (value: string, _e?: React.SyntheticEvent<unknown>) => {
      if (isNaN(+value)) {
        return actualValue;
      }
      const valueStr = clamp(+value, max, min).toString();
      onChange(valueStr);
      return valueStr;
    },
    [max, min, onChange, actualValue]
  );

  const onIncrement = useCallback(
    (value: string) => {
      if (isNaN(+value)) {
        return actualValue;
      }
      const newValue = +value + (step || 1);
      const valueStr = clamp(newValue, max, min).toString();
      onChange(valueStr);
      return valueStr;
    },
    [max, min, onChange, actualValue, step]
  );

  const onDecrement = useCallback(
    (value: string) => {
      if (isNaN(+value)) {
        return actualValue;
      }
      const newValue = +value - (step || 1);
      const valueStr = clamp(newValue, max, min).toString();
      onChange(valueStr);
      return valueStr;
    },
    [max, min, onChange, actualValue, step]
  );
  const theme = useMemo(() => getTheme(), []);

  const spinButtonStyles = useMemo(
    () =>
      error
        ? {
            spinButtonWrapperHovered: {
              borderColor: theme.palette.redDark,
            },
            spinButtonWrapper: { borderColor: theme.palette.redDark },
            spinButtonWrapperFocused: {
              borderColor: theme.palette.redDark,
            },
          }
        : undefined,
    [error, theme.palette.redDark]
  );

  return (
    <div className={className}>
      <MSSpinButton
        styles={spinButtonStyles}
        onValidate={onValidate}
        onIncrement={onIncrement}
        onDecrement={onDecrement}
        step={step}
        value={actualValue}
        {...otherProps}
      />
      {error && (
        <ErrorText className={styles.spinButtonError}>{error}</ErrorText>
      )}
    </div>
  );
}
