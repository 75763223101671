import { TextField } from "@fluentui/react";
import * as React from "react";

import { JSON_INDENTATION } from "../../constants/format";
import {
  ExtractDataFieldType,
  ExtractImageListData,
} from "../../types/extractDataField";
import { CroppedImage } from "../CroppedImage";
import {
  ExtractTableDataField,
  ExtractTableDataType,
} from "../ExtractTableDataField";
import { SmallIconButton } from "../SmallIconButton";
import { ActionButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface Props {
  label: string;
  value: any;
  type: ExtractDataFieldType;
  isEditable?: boolean;
  isMultiline?: boolean;
  actions?: React.ReactNode;
  onChange?: (value: any) => void;
}

function RawValue({ value }: { value: any }) {
  if (value === null) {
    return <></>;
  }

  return (
    <pre className={styles["raw-json-viewer"]}>
      {JSON.stringify(value, null, JSON_INDENTATION)}
    </pre>
  );
}

function ExtractBasicDataField(props: Props) {
  const { label, value, onChange, actions } = props;
  const isEditable = props.isEditable ?? false;
  const isMultiline = props.isMultiline ?? false;

  const _onChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      onChange?.(newValue);
    },
    [onChange]
  );

  return (
    <div className={styles["data-item"]}>
      <div className={styles["data-item-label"]}>{label}</div>
      {isEditable ? (
        <TextField value={value} onChange={_onChange} multiline={isMultiline} />
      ) : (
        <div className={styles["data-item-value-container"]}>
          <div className={styles["data-item-value"]}>
            {typeof value === "string" ? value : <RawValue value={value} />}
          </div>
          <div className={styles["data-item-value-actions"]}>{actions}</div>
        </div>
      )}
    </div>
  );
}

function ExtractListDataField(props: Props) {
  const { label, value, onChange, actions } = props;
  const isMultiline = props.isMultiline ?? false;

  const listValue = React.useMemo(() => {
    return Array.isArray(value) ? value : [value];
  }, [value]);

  const addNewItem = React.useCallback(() => {
    const newValue = Array.isArray(listValue)
      ? [...listValue, ""]
      : [listValue, ""];
    onChange?.(newValue);
  }, [listValue, onChange]);

  const removeItem = React.useCallback(
    (index: number) => {
      const newValue = [...listValue];
      newValue.splice(index, 1);
      onChange?.(newValue);
    },
    [listValue, onChange]
  );

  const updateItem = React.useCallback(
    (index: number, newValue: string) => {
      const newValueList = [...listValue];
      newValueList[index] = newValue;
      onChange?.(newValueList);
    },
    [listValue, onChange]
  );

  const isEditable = props.isEditable ?? false;
  const content = isEditable ? (
    <div className={styles["data-item-editing-list-value"]}>
      {listValue.map((item, index) => {
        return (
          <div
            className={styles["data-item-editing-list-value-item"]}
            key={`list-value/${index}`}
          >
            <TextField
              value={item}
              multiline={isMultiline}
              onChange={(
                _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                updateItem(index, newValue ?? "");
              }}
            />
            <SmallIconButton
              iconName="IconTrash"
              onClick={() => {
                removeItem(index);
              }}
            />
          </div>
        );
      })}
      <ActionButton
        className={styles["content-list-value-field-add-button"]}
        iconName="Add"
        textId="extract_data_field.new_list_item"
        onClick={addNewItem}
      />
    </div>
  ) : (
    <div className={styles["data-item-list-value"]}>
      {listValue.map((item, index) => {
        return (
          <React.Fragment key={`list-value/${index}`}>
            <div className={styles["data-item-list-value-row-bullet"]}></div>
            <div className={styles["data-item-list-value-row-content"]}>
              {typeof item === "string" ? item : <RawValue value={item} />}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );

  return (
    <div className={styles["data-item"]}>
      <div className={styles["data-item-label"]}>{label}</div>
      <div className={styles["data-item-list-value-container"]}>
        {content}
        <div className={styles["data-item-list-value-actions"]}>{actions}</div>
      </div>
    </div>
  );
}

function ExtractImageListDataField(props: Props) {
  const { label, value } = props;

  const listImage = value as ExtractImageListData;

  let content = null;

  try {
    content = listImage.map((item, index) => {
      const {
        imageUrl,
        boundingBox,
        value,
        onToggleBoundingBox,
        isToggleBoundingBoxOn,
      } = item;

      return (
        <div
          key={`list-image/${index}`}
          className={styles["data-item-value-image-row"]}
        >
          <div className={styles["data-item-value-image"]}>
            <CroppedImage src={imageUrl} boundingBox={boundingBox} />
          </div>
          <div className={styles["data-item-value"]}>
            {typeof value === "string" ? value : <RawValue value={value} />}
          </div>
          {onToggleBoundingBox && (
            <div>
              <SmallIconButton
                iconName={isToggleBoundingBoxOn ? "View" : "Hide"}
                onClick={onToggleBoundingBox}
              />
            </div>
          )}
        </div>
      );
    });
  } catch (e) {}

  return (
    <div className={styles["data-item"]}>
      <div className={styles["data-item-label"]}>{label}</div>
      <div className={styles["data-item-value-container"]}>
        <div className={styles["data-item-value-image-list"]}>{content}</div>
      </div>
    </div>
  );
}

export function ExtractDataField(props: Props) {
  const { type } = props;

  switch (type) {
    case ExtractDataFieldType.Basic:
      return <ExtractBasicDataField {...props} />;
    case ExtractDataFieldType.List:
      return <ExtractListDataField {...props} />;
    case ExtractDataFieldType.ListTable:
      return (
        <ExtractTableDataField
          {...props}
          type={ExtractTableDataType.ListTable}
        />
      );
    case ExtractDataFieldType.HTMLTable:
      return (
        <ExtractTableDataField
          {...props}
          type={ExtractTableDataType.HTMLTable}
        />
      );
    case ExtractDataFieldType.ListImage:
      return <ExtractImageListDataField {...props} />;
    default:
      return <ExtractBasicDataField {...props} />;
  }
}
