import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import * as appAction from "../actions/app";
import * as resourceOwnerAction from "../actions/resourceOwner";
import * as teamAction from "../actions/team";
import * as userAction from "../actions/user";
import { UserInfo } from "../apiClient/authGear";
import { AppConfig } from "../config";
import { FOCRError } from "../errors";
import {
  BriefTeam,
  Permission,
  TeamInvitationListItemStore,
  TeamInvitationStatus,
  TeamRef,
} from "../types/team";
import { User } from "../types/user";

export interface UserState {
  readonly isAuthenticated: boolean;
  readonly isLoggingOut: boolean;
  readonly userInfo?: UserInfo;
  readonly currentUser?: User;

  readonly invitationsByRegion?: {
    [key: string]: TeamInvitationListItemStore[];
  };
  readonly isFetchingInvitation: boolean;
  readonly fetchInvitationError?: FOCRError;

  readonly teamRefsByRegion: { [Key: string]: TeamRef[] };
  readonly regionsWithTeamRefsFetched: string[];
}

const defaultState: UserState = {
  teamRefsByRegion: {},
  isAuthenticated: false,
  isLoggingOut: false,
  isFetchingInvitation: false,
  regionsWithTeamRefsFetched: [AppConfig.region],
};

export const userReducer = createReducer<UserState>(defaultState, builder => {
  builder
    .addCase(appAction.Authenticated, state => {
      state.isAuthenticated = true;
    })
    .addCase(userAction.GotUserInfo, (state, action) => {
      state.userInfo = action.payload;
    })
    .addCase(userAction.UserCreated, (state, action) => {
      state.currentUser = action.payload;
    })
    .addCase(userAction.UserLogin, (state, action) => {
      state.isAuthenticated = true;
      state.currentUser = action.payload.user;
    })
    .addCase(userAction.UserLoggingOut, state => {
      state.isLoggingOut = true;
    })
    .addCase(userAction.UserLogout, _state => ({ ...defaultState }))
    .addCase(teamAction.GotTeamRefs, (state, action) => {
      state.teamRefsByRegion[action.payload.region] = action.payload.refs;
    })
    .addCase(teamAction.FetchedTeamRefs, (state, action) => {
      state.regionsWithTeamRefsFetched = [
        ...new Set([
          ...state.regionsWithTeamRefsFetched,
          action.payload.region,
        ]),
      ];
    })
    .addCase(resourceOwnerAction.PlanUpdated, (state, action) => {
      const { teamId, plan, planId } = action.payload;
      if (state.currentUser?.resourceOwner) {
        if (!teamId) {
          state.currentUser.resourceOwner.plan = plan;
          state.currentUser.resourceOwner.planId = planId;
        } else {
          state.currentUser.teams = state.currentUser.teams.map(team =>
            team.id === teamId ? { ...team, plan, planId } : team
          );
        }
      }
    })
    .addCase(teamAction.TeamUserRemoved, (state, action) => {
      const { teamId, currentUser, removedUserId } = action.payload;
      if (removedUserId === currentUser.id && state.currentUser) {
        state.currentUser.teams = state.currentUser.teams.filter(
          team => team.id !== teamId
        );
        state.currentUser.permissions = state.currentUser.permissions.filter(
          permission => permission.teamId !== teamId
        );
      }
    })
    .addCase(teamAction.TeamDeleted, (state, action) => {
      const { teamId } = action.payload;
      if (state.currentUser) {
        state.currentUser.teams = state.currentUser.teams.filter(
          team => team.id !== teamId
        );
        state.currentUser.permissions = state.currentUser.permissions.filter(
          permission => permission.teamId !== teamId
        );
      }
    })
    .addCase(teamAction.TeamRenamed, (state, action) => {
      const { teamId, name } = action.payload;
      if (state.currentUser) {
        state.currentUser.teams = state.currentUser.teams.map(team =>
          team.id === teamId
            ? {
                ...team,
                name,
              }
            : team
        );
      }
    })
    .addCase(teamAction.StartedFetchingTeamInvitations, (state, _action) => {
      state.isFetchingInvitation = true;
      state.fetchInvitationError = undefined;
    })
    .addCase(teamAction.GotTeamInvitations, (state, action) => {
      state.isFetchingInvitation = false;
      state.invitationsByRegion = action.payload;
    })
    .addCase(teamAction.FetchTeamInvitationsFailed, (state, action) => {
      state.isFetchingInvitation = false;
      state.fetchInvitationError = action.payload;
    })
    .addCase(teamAction.TeamInvitationAccepted, (state, action) => {
      const {
        team: { id: teamId },
        region,
        invitationId,
      } = action.payload;
      if (state.invitationsByRegion && state.invitationsByRegion[region]) {
        const index = state.invitationsByRegion[region].findIndex(
          x => x.id === invitationId
        );
        if (index >= 0) {
          state.invitationsByRegion[region][index].status =
            TeamInvitationStatus.Accepted;
          state.invitationsByRegion[region][index].teamId = teamId;
        }
      }
    })
    .addCase(teamAction.TeamInvitationRemoved, (state, action) => {
      const { region, invitationId } = action.payload;
      if (state.invitationsByRegion && state.invitationsByRegion[region]) {
        const index = state.invitationsByRegion[region].findIndex(
          x => x.id === invitationId
        );
        if (index >= 0) {
          state.invitationsByRegion[region][index].status =
            TeamInvitationStatus.Deleted;
        }
      }
    })
    .addCase(userAction.UserSurveySubmitted, (state, action) => {
      const surveyForm = action.payload;
      if (state.userInfo) {
        state.userInfo.customAttributes["survey_form"] =
          JSON.stringify(surveyForm);
      }
    })
    .addMatcher(
      isAnyOf(teamAction.CreateTeam, teamAction.TeamInvitationAccepted),
      (
        state,
        action: {
          payload: {
            team: BriefTeam;
            permission: Permission;
          };
        }
      ) => {
        const { team, permission } = action.payload;
        if (state.currentUser) {
          state.currentUser.teams.push(team);
          state.currentUser.permissions.push({
            teamId: team.id,
            permission: permission,
          });
        }
      }
    );
});
