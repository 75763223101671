import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { useTeamPermission } from "../../hooks/permission";
import { FormGroupTokenGroup } from "../../types/formGroup";
import { TokenGroupImage, TokenGroupText } from "../../types/tokenGroup";
import { FormGroupTokenGroupContent } from "./FormGroupTokenGroupContent";
import styles from "./styles.module.scss";

interface Props {
  tokenGroup: FormGroupTokenGroup;
  onAddTextToken: (
    tokenGroup: FormGroupTokenGroup,
    textToken: TokenGroupText
  ) => void;
  onUpdateTextToken: (
    tokenGroup: FormGroupTokenGroup,
    textToken: TokenGroupText
  ) => void;
  onDeleteTextToken: (
    tokenGroup: FormGroupTokenGroup,
    textTokenId: string
  ) => void;
  onAddImageToken: (
    tokenGroup: FormGroupTokenGroup,
    imageToken: TokenGroupImage
  ) => void;
  onUpdateImageToken: (
    tokenGroup: FormGroupTokenGroup,
    imageToken: TokenGroupImage
  ) => void;
  onDeleteImageToken: (
    tokenGroup: FormGroupTokenGroup,
    imageTokenId: string
  ) => void;
  onMoveItem: (item: FormGroupTokenGroup, direction: 1 | -1) => Promise<void>;
  onEditTokenGroup: (tokenGroup: FormGroupTokenGroup) => void;
  onRemoveTokenGroup: (tokenGroupId: string) => Promise<void>;
}

export function FormGroupTokenGroupTableRow(props: Props) {
  const {
    tokenGroup,
    onAddTextToken,
    onDeleteImageToken,
    onUpdateImageToken,
    onDeleteTextToken,
    onUpdateTextToken,
    onAddImageToken,
    onMoveItem,
    onEditTokenGroup,
    onRemoveTokenGroup,
  } = props;

  const { hasPermissionToEditResource } = useTeamPermission();

  const handleMoveUp = () => onMoveItem(tokenGroup, -1);
  const handleMoveDown = () => onMoveItem(tokenGroup, 1);
  const onClickEdit = () => {
    onEditTokenGroup(tokenGroup);
  };
  const onClickDelete = () => onRemoveTokenGroup(tokenGroup.id);

  return (
    <tr className={styles["form-group-token-group-table-row"]}>
      <td>
        <FormGroupTokenGroupContent
          tokenGroup={tokenGroup}
          onDeleteTextToken={onDeleteTextToken}
          onDeleteImageToken={onDeleteImageToken}
          onAddTextToken={onAddTextToken}
          onUpdateTextToken={onUpdateTextToken}
          onAddImageToken={onAddImageToken}
          onUpdateImageToken={onUpdateImageToken}
        />
      </td>
      <td>
        {tokenGroup.matchMode === "all" ? (
          <FormattedMessage
            id={"form_group_token_group_editor.match_mode.all"}
          />
        ) : (
          <FormattedMessage
            id={"form_group_token_group_editor.match_mode.any"}
          />
        )}
      </td>
      {hasPermissionToEditResource && (
        <>
          <td>
            <div className={styles["action-container"]}>
              <Icon
                iconName="ChevronUp"
                className={styles["action-button"]}
                onClick={handleMoveUp}
              />
              <Icon
                iconName="ChevronDown"
                className={styles["action-button"]}
                onClick={handleMoveDown}
              />
            </div>
          </td>
          <td>
            <div className={styles["action-container"]}>
              <Icon
                iconName="Edit"
                className={styles["action-button"]}
                onClick={onClickEdit}
              />
              <Icon
                iconName="Delete"
                className={styles["action-button"]}
                onClick={onClickDelete}
              />
            </div>
          </td>
        </>
      )}
    </tr>
  );
}
