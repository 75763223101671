.audit-log-job-table {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.audit-log-job-table > * {
  width: 575px;
  height: 43px;
  border-bottom: 1px solid #ededed;
  padding-left: 12px;
}

.table-header {
  display: flex;
}

.table-header > * {
  align-self: center;
  font-weight: 600;
}

.available-csv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.csv-date {
  width: 75%;
}

.csv-download {
  color: #25d0b1 !important;
  font-weight: 600;
  font-size: 12px;
}

.generating {
  color: #605e5c !important;
  font-size: 12px;
}
