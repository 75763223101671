import { CustomField } from "../../types/receiptGroup";
import { KeyValue, KeyValueResp } from "../keyValue";

export const KeyValueMapper = {
  fromCustomField: (field: CustomField): KeyValue => ({
    name: field.name,
    position: field.position,
    pattern: field.pattern,
    created_at: 0,
    tokens: [
      {
        token: field.token,
        useFuzzySearch: field.use_fuzzy_search,
      },
    ],
  }),
  toCustomField: (keyValue: KeyValue): CustomField => {
    if (keyValue.tokens.length < 1) {
      throw new Error("Cannot convert KeyValue without token to CustomField");
    }
    return {
      name: keyValue.name,
      position: keyValue.position,
      pattern: keyValue.pattern,
      token: keyValue.tokens[0].token,
      use_fuzzy_search: keyValue.tokens[0].useFuzzySearch,
    };
  },
  toResp: (keyValues: KeyValue[]): KeyValueResp[] =>
    keyValues.map(keyValue => ({
      name: keyValue.name,
      position: keyValue.position,
      pattern: keyValue.pattern,
      created_at: keyValue.created_at,
      tokens: keyValue.tokens.map(token => {
        return {
          token: token.token,
          use_fuzzy_search: token.useFuzzySearch,
        };
      }),
      ...(keyValue.extra !== undefined
        ? {
            extra: keyValue.extra,
          }
        : {}),
    })),
};
