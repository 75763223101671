.example {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-self: flex-start;
  margin-bottom: 10px;

  .example-key-value-table {
    display: flex;
    flex-direction: row;
  }

  .example-column {
    display: flex;
    flex-direction: column;

    & + .example-column {
      margin-left: 5.9px;
    }
  }

  .example-title-text {
    font-size: 12px;
    color: #0078d4;
    margin-bottom: 4px;
  }

  .example-cell-text {
    font-weight: 600;
    font-size: 16px;
    color: #201f1e;
    padding: 5px;

    &.key-cell {
      background: #dcfcbd;
    }

    &.value-cell {
      background: #ffe2b7;
    }
  }

  .example-desc-text {
    display: block;
    font-size: 12px;
    color: #0078d4;
    margin-left: 10px;
  }
}
