import * as React from "react";

import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { FSLInstantModelViewer } from "../components/FSLInstantModelViewer";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import {
  MultiChoiceModal,
  useMultiChoiceModalAcceptButton,
} from "../components/MultiChoiceModal";
import {
  DefaultButton,
  PrimaryButton,
} from "../components/WrappedMSComponents/Buttons";
import {
  FSLCustomModelEditorProvider,
  useFSLCustomModelEditor,
} from "../contexts/fslCustomModelEditor";
import {
  ActionType,
  FSLInstantModelViewerProvider,
  useFSLInstantModelViewerContainer,
} from "../contexts/fslInstantModelViewer";
import { useUnsafeParams } from "../hooks/params";
import { FSLNavBarLayout, FSLTab } from "./FSLNavBarLayout";
import HeaderContainer from "./Header";

type PathParam = {
  customModelId: string;
};

function FSLInstantModelViewerLayout() {
  const { isFSLModel, isFailedToFetchCustomModel } = useFSLCustomModelEditor();

  const { isProcessing, isLoading, enableStandardModalHandle } =
    useFSLInstantModelViewerContainer();

  const shouldShowFSLCustomModel = isFSLModel === true && !isLoading;
  const shouldShowOriginalEditor = isFSLModel === false;

  const onContactButtonClick = useMultiChoiceModalAcceptButton(
    enableStandardModalHandle.props,
    ActionType.ContactFormX
  );
  const onUseStandardModelButtonClick = useMultiChoiceModalAcceptButton(
    enableStandardModalHandle.props,
    ActionType.UseStandardModel
  );

  return (
    <>
      {(isFailedToFetchCustomModel || shouldShowOriginalEditor) && (
        <Main hasTop={true}>
          <ErrorPlaceholder messageId="common.fail_to_fetch_custom_model" />
        </Main>
      )}
      {!shouldShowOriginalEditor && (
        <LoadingModal isOpen={isLoading || isProcessing} />
      )}
      {shouldShowFSLCustomModel && (
        <Main hasTop={true}>
          <FSLNavBarLayout selectedTab={FSLTab.InstantModel}>
            <FSLInstantModelViewer />
          </FSLNavBarLayout>
        </Main>
      )}
      <MultiChoiceModal
        {...enableStandardModalHandle.props}
        titleId="fsl_instant_model_editor.support_single_image_modal.title"
        messageId="fsl_instant_model_editor.support_single_image_modal.desc"
      >
        <DefaultButton
          textId="fsl_instant_model_editor.support_single_image_modal.contact"
          onClick={onContactButtonClick}
        />
        <PrimaryButton
          textId="fsl_instant_model_editor.support_single_image_modal.use_standard_model"
          onClick={onUseStandardModelButtonClick}
        />
      </MultiChoiceModal>
    </>
  );
}

function _FSLInstantModelViewerContainer() {
  const { customModelId } = useUnsafeParams<PathParam>();

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <FSLCustomModelEditorProvider
        customModelId={customModelId}
        key={customModelId}
      >
        <FSLInstantModelViewerProvider>
          <FSLInstantModelViewerLayout />
        </FSLInstantModelViewerProvider>
      </FSLCustomModelEditorProvider>
    </Layout>
  );
}

export const FSLInstantModelViewerContainer = React.memo(
  _FSLInstantModelViewerContainer
);
export default FSLInstantModelViewerContainer;
