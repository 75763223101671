import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  IDialogContentProps,
  PrimaryButton,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useFormActionCreator } from "../../actions/form";
import {
  DATE_INPUT_FORMAT_OPTIONS,
  DEFAULT_DATE_INPUT_FORMAT,
} from "../../constants";
import { buildFormSettings } from "../../contexts/formEditor";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { RootState } from "../../redux/types";
import { DateInputFormatType } from "../../types/formConfig";
import styles from "./styles.module.scss";

export function useDateFormatSettingModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openDateFormatSettingsModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const form = useSelector((state: RootState) => state.form.currentForm);
  const { updateForm } = useFormActionCreator();

  const onConfirm = useCallback(
    (dateInputFormat: string) => {
      if (!form) {
        return;
      }
      const settings = buildFormSettings(form, {
        dateInputFormat: dateInputFormat as DateInputFormatType,
      });
      updateForm(settings);
      onClose();
    },
    [form, onClose, updateForm]
  );

  const dateFormatSettingsModalPayload = useMemo(
    () => ({
      format: form?.config?.date_input_format || DEFAULT_DATE_INPUT_FORMAT,
      isOpen,
      onClose,
      onConfirm,
    }),
    [form?.config?.date_input_format, isOpen, onClose, onConfirm]
  );

  return useMemo(
    () => ({
      dateFormatSettingsModalPayload,
      openDateFormatSettingsModal,
    }),
    [dateFormatSettingsModalPayload, openDateFormatSettingsModal]
  );
}

interface Props {
  format: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (format: string) => void;
}

export function DateFormatSettingsModal(props: Props) {
  const { localized } = useLocale();
  const { isOpen, onClose, onConfirm, format } = props;

  const [dateFormatKey, setDateFormatKey] = React.useState(format);

  const onFormatChange = (
    _ev: React.FormEvent<HTMLInputElement | HTMLElement> | undefined,
    option?: IChoiceGroupOption
  ): void => {
    if (option) {
      setDateFormatKey(option.key);
    }
  };

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("date_format.settings.modal.title"),
    }),
    [localized]
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  const options = useMemo(
    () =>
      DATE_INPUT_FORMAT_OPTIONS.map(key => ({
        key,
        text: localized(`date_input_format.${key}`),
      })),
    [localized]
  );

  return (
    <Dialog
      minWidth={405}
      hidden={!isOpen}
      onDismiss={onClose}
      dialogContentProps={dialogContentProps}
    >
      <ChoiceGroup
        options={options}
        label={localized("date_format.settings.modal.date_input_format.title")}
        selectedKey={dateFormatKey}
        onChange={onFormatChange}
        disabled={!hasPermissionToEditResource}
      />

      <div className={styles["description"]}>
        <FormattedMessage id="date_format.settings.modal.date_input_format.desc" />
      </div>

      <DialogFooter>
        <DefaultButton
          onClick={onClose}
          text={localized(
            hasPermissionToEditResource ? "common.cancel" : "common.close"
          )}
        />
        {hasPermissionToEditResource && (
          <PrimaryButton
            onClick={() => {
              onConfirm(dateFormatKey);
            }}
            text={localized("common.ok")}
          />
        )}
      </DialogFooter>
    </Dialog>
  );
}
