import { Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import React, { useCallback, useMemo, useState } from "react";

import { DATE_INPUT_FORMAT_OPTIONS } from "../../../constants/formConfig";
import { useLocale } from "../../../contexts/locale";
import { LLMConfig } from "../../../types/advancedTokenSetup/table";
import { DateInputFormatType } from "../../../types/formConfig";

type BaseProps = ReturnType<
  typeof useLLMFieldReplacementViewHandle
>["triggerProps"];

type Props = BaseProps;

const EmptyLLMConfig: LLMConfig = {
  llm_settings_id: "default",
};

export function useLLMFieldReplacementViewHandle(
  defaultLLMConfig?: LLMConfig,
  shouldShowDateInputDropdown?: boolean
) {
  const [llmConfig, setLLMConfig] = useState<LLMConfig>(
    defaultLLMConfig ?? EmptyLLMConfig
  );
  const onFieldDescriptionChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      if (newValue !== undefined) {
        setLLMConfig(prev => ({
          ...prev,
          field_description: newValue,
        }));
      }
    },
    []
  );

  const onDateInputFormatChange = useCallback(
    (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option && option.id) {
        setLLMConfig(prev => ({
          ...prev,
          date_input_format: option.id as DateInputFormatType,
        }));
      }
    },
    []
  );

  const submit = useCallback((): LLMConfig => {
    return llmConfig;
  }, [llmConfig]);

  return useMemo(
    () => ({
      triggerProps: {
        llmConfig,
        onFieldDescriptionChange,
        onDateInputFormatChange,
        shouldShowDateInputDropdown,
      },
      submit,
    }),
    [
      llmConfig,
      onFieldDescriptionChange,
      onDateInputFormatChange,
      shouldShowDateInputDropdown,
      submit,
    ]
  );
}

const LLMFieldReplacementView = React.memo<Props>(props => {
  const {
    llmConfig,
    onFieldDescriptionChange,
    onDateInputFormatChange,
    shouldShowDateInputDropdown,
  } = props;

  const { localized } = useLocale();
  const dateInputFormatOptions: IDropdownOption[] = useMemo(() => {
    return DATE_INPUT_FORMAT_OPTIONS.map(f => ({
      id: f,
      key: f,
      text: localized(`date_input_format.${f}`),
    }));
  }, [localized]);

  return (
    <div>
      <div>
        <TextField
          label={localized(
            "advance_token_setup_field_replacement_setting_panel.panel.llm.field_description"
          )}
          onChange={onFieldDescriptionChange}
          value={llmConfig.field_description}
        />
      </div>
      {shouldShowDateInputDropdown && (
        <Dropdown
          label={localized(
            "advance_token_setup_field_replacement_setting_panel.panel.change_date_input.convert_date_order"
          )}
          selectedKey={
            llmConfig.date_input_format || DATE_INPUT_FORMAT_OPTIONS[0]
          }
          onChange={onDateInputFormatChange}
          options={dateInputFormatOptions}
        />
      )}
    </div>
  );
});

export default LLMFieldReplacementView;
