.editor {
  display: flex;
  flex: 1;
  width: 0;

  input {
    border: none;
    &:focus {
      outline: none;
    }
  }
}
