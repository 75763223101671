import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import sampleFileInput from "../../images/sample/workspace-sample-file-input.svg";
import { WorkspaceCsvOutputSampleTable } from "../WorkspaceCsvOutputSampleTable";
import styles from "./styles.module.scss";

interface WorkspaceSettingMultipleDocumentsTutorialProps {
  onCloseClick?: () => void;
}

export function useWorkspaceSettingMultipleDocumentsTutorial(
  args: WorkspaceSettingMultipleDocumentsTutorialProps
) {
  const { onCloseClick } = args;

  const multiplePagesCsvOutputSampleColumns = React.useMemo(
    () => [
      { key: "fileName" as const, header: "file_name" },
      { key: "page" as const, header: "page", highlighed: true },
      { key: "accountNumber" as const, header: "account_number" },
      { key: "holderName" as const, header: "holder_name" },
    ],
    []
  );

  const multiplePagesCsvOutputSampleItems = React.useMemo(
    () => [
      {
        key: "0",
        fileName: "sample.pdf",
        page: "1",
        accountNumber: "123-456-899",
        holderName: "Peter Chan",
      },
      {
        key: "1",
        fileName: "sample.pdf",
        page: "2",
        accountNumber: "123-322-165",
        holderName: "Sam Sung",
      },
    ],
    []
  );

  const multipleDocumentsCsvOutputSampleColumns = React.useMemo(
    () => [
      { key: "fileName" as const, header: "file_name" },
      { key: "slice" as const, header: "slice" },
      { key: "documentType" as const, header: "document_type" },
    ],
    []
  );

  const multipleDocumentsCsvOutputSampleItems = React.useMemo(
    () => [
      {
        key: "0",
        fileName: "sample.pdf",
        slice: "1",
        documentType: "ID",
      },
      {
        key: "1",
        fileName: "sample.pdf",
        slice: "2",
        documentType: "Passport",
      },
    ],
    []
  );

  return React.useMemo(
    () => ({
      onCloseClick,
      multiplePagesCsvOutputSampleColumns,
      multiplePagesCsvOutputSampleItems,
      multipleDocumentsCsvOutputSampleColumns,
      multipleDocumentsCsvOutputSampleItems,
    }),
    [
      multipleDocumentsCsvOutputSampleColumns,
      multipleDocumentsCsvOutputSampleItems,
      multiplePagesCsvOutputSampleColumns,
      multiplePagesCsvOutputSampleItems,
      onCloseClick,
    ]
  );
}

export function WorkspaceSettingMultipleDocumentsTutorialImpl(
  props: ReturnType<typeof useWorkspaceSettingMultipleDocumentsTutorial>
) {
  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <div className={styles["header"]}>
          <div className={styles["title"]}>
            <FormattedMessage id="workspace.setting.tutorial.multiple_documents.title" />
          </div>
          <div className={styles["close-button"]} onClick={props.onCloseClick}>
            <Icon className={styles["icon"]} iconName="Cancel" />
          </div>
        </div>
        <div className={styles["sections"]}>
          <div className={styles["section"]}>
            <div className={styles["info"]}>
              <div className={styles["title"]}>
                <FormattedMessage id="workspace.setting.tutorial.multiple_documents.section.multiple_pages.title" />
              </div>
              <div className={styles["description"]}>
                <FormattedMessage id="workspace.setting.tutorial.multiple_documents.section.multiple_pages.description" />
              </div>
            </div>
            <div className={styles["samples"]}>
              <div className={styles["sample"]}>
                <div className={styles["label"]}>
                  <FormattedMessage id="workspace.setting.tutorial.label.csv_output_sample" />
                </div>
                <div className={styles["table"]}>
                  <WorkspaceCsvOutputSampleTable
                    columns={props.multiplePagesCsvOutputSampleColumns}
                    items={props.multiplePagesCsvOutputSampleItems}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles["section"]}>
            <div className={styles["info"]}>
              <div className={styles["title"]}>
                <FormattedMessage id="workspace.setting.tutorial.multiple_documents.section.multiple_documents.title" />
              </div>
              <div className={styles["description"]}>
                <FormattedMessage id="workspace.setting.tutorial.multiple_documents.section.multiple_documents.description" />
              </div>
            </div>
            <div className={styles["samples"]}>
              <div className={styles["sample"]}>
                <div className={styles["label"]}>
                  <FormattedMessage id="workspace.setting.tutorial.label.file_input_sample" />
                </div>
                <div className={styles["image"]}>
                  <img src={sampleFileInput} />
                </div>
              </div>
              <div className={styles["sample"]}>
                <div className={styles["label"]}>
                  <FormattedMessage id="workspace.setting.tutorial.label.csv_output_sample" />
                </div>
                <div className={styles["table"]}>
                  <WorkspaceCsvOutputSampleTable
                    columns={props.multipleDocumentsCsvOutputSampleColumns}
                    items={props.multipleDocumentsCsvOutputSampleItems}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function WorkspaceSettingMultipleDocumentsTutorial(
  args: WorkspaceSettingMultipleDocumentsTutorialProps
) {
  const props = useWorkspaceSettingMultipleDocumentsTutorial(args);
  return <WorkspaceSettingMultipleDocumentsTutorialImpl {...props} />;
}
