import { configureStore, isPlain } from "@reduxjs/toolkit";

import { AppConfig } from "../config";
import { FOCRError } from "../errors";
import { adminReducer } from "../reducers/admin";
import { appReducer } from "../reducers/app";
import { confirmModalReducer } from "../reducers/confirmModal";
import { customModelReducer } from "../reducers/customModel";
import { extractionReducer } from "../reducers/extraction";
import { extractorReducer } from "../reducers/extractor";
import { formReducer } from "../reducers/form";
import { formGroupReducer } from "../reducers/formGroup";
import { googleAuthReducer } from "../reducers/googleAuth";
import { googleSheetReducer } from "../reducers/googleSheet";
import { microsoftAuthReducer } from "../reducers/microsoftAuth";
import { oauthReducer } from "../reducers/oauth";
import { receiptGroupReducer } from "../reducers/receiptGroup";
import { resourceOwnerReducer } from "../reducers/resourceOwner";
import { userReducer } from "../reducers/user";
import { webhookReducer } from "../reducers/webhook";
import { workspaceReducer } from "../reducers/workspace";
import { workspaceIntegrationReducer } from "../reducers/workspaceIntegration";
import { workspaceWebhookReducer } from "../reducers/workspaceWebhook";

export default function createStore() {
  return configureStore({
    reducer: {
      app: appReducer,
      user: userReducer,
      oauth: oauthReducer,
      resourceOwner: resourceOwnerReducer,
      form: formReducer,
      receiptGroup: receiptGroupReducer,
      formGroup: formGroupReducer,
      customModel: customModelReducer,
      webhook: webhookReducer,
      admin: adminReducer,
      confirmModal: confirmModalReducer,
      extractor: extractorReducer,
      workspace: workspaceReducer,
      googleAuth: googleAuthReducer,
      googleSheet: googleSheetReducer,
      microsoftAuth: microsoftAuthReducer,
      extraction: extractionReducer,
      workspaceIntegration: workspaceIntegrationReducer,
      workspaceWebhook: workspaceWebhookReducer,
    },
    devTools: AppConfig.environment === "dev",
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          isSerializable: (value: any) =>
            isPlain(value) ||
            value instanceof Date ||
            value instanceof FOCRError ||
            value instanceof Function,
        },
      }),
  });
}
