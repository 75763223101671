import {
  IButtonProps,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { Values } from "@oursky/react-messageformat";
import cntl from "cntl";
import React from "react";

import { useLocale } from "../../../contexts/locale";

export interface ButtonProps extends IButtonProps {
  textId?: string;
  textValues?: Values;
  isLoading?: boolean;
  type: "primary" | "secondary" | "outline" | "borderless";
}

const ButtonStyles = {
  primary: cntl`
    text-button-label-primary
    bg-primary-200
    hover:bg-primary-300 
    active:bg-primary-400 
    border-0 
    hover:border-0
    disabled:bg-button-disable
    disabled:text-button-label-disable
  `,
  secondary: cntl`
    text-button-label-primary
    bg-secondary-200 
    hover:bg-secondary-300 
    active:bg-secondary-400 
    border-0 
    hover:border-0
    disabled:bg-button-disable
    disabled:text-button-label-disable    
    `,
  outline: cntl`
    bg-white 
    text-button-label-secondary 
    hover:bg-grey-100 
    hover:text-button-label-secondary 
    active:bg-button-secondary-pressed 
    border-button-secondary
    disabled:bg-white
    disabled:bg-button-disable
    disabled:text-button-label-disable
    hover:enabled:border-0
    `,
  borderless: cntl`
    bg-transparent 
    text-button-label-secondary 
    hover:bg-button-tertiary-hovered
    hover:text-button-label-secondary 
    active:bg-button-secondary-pressed 
    border-transparent
    disabled:bg-white
    disabled:bg-button-disable
    disabled:text-button-label-disable
    hover:enabled:border-0
    `,
};

export function Button(props: ButtonProps) {
  const { localized } = useLocale();
  const { className, textId, textValues, text, type, ...restProps } = props;
  const { disabled } = restProps;

  const label = textId ? localized(textId, textValues) : text;
  const isLoading = props.isLoading ?? false;

  const classes = cntl`
  text-label-medium
  rounded-[8px]
  h-[40px]
  ${ButtonStyles[type]}
  ${className}
`;

  const customStyles = React.useMemo(() => {
    return {
      root: [
        {
          whiteSpace: "nowrap",
        },
        classes,
      ],
    };
  }, [classes]);

  const spinnerStyles = React.useMemo(() => {
    if (disabled) {
      return {
        circle: {
          borderColor: "transparent #909593 #909593 #909593",
        },
      };
    }

    return {};
  }, [disabled]);

  return (
    <PrimaryButton text={label} styles={customStyles} {...restProps}>
      {isLoading && (
        <Spinner size={SpinnerSize.medium} styles={spinnerStyles} />
      )}
    </PrimaryButton>
  );
}
