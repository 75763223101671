import { createReducer } from "@reduxjs/toolkit";

import * as googleSheetAction from "../actions/googleSheet";
import type { GoogleSheet, GoogleSpreadsheet } from "../types/googleSheet";

export interface GoogleSheetState {
  readonly sheets: {
    [key in string]: {
      [key in string]: GoogleSheet;
    };
  };
  readonly spreadsheets: {
    [key in string]: GoogleSpreadsheet[];
  };
}

const defaultState: GoogleSheetState = {
  sheets: {},
  spreadsheets: {},
};

export const googleSheetReducer = createReducer<GoogleSheetState>(
  defaultState,
  builder => {
    builder
      .addCase(googleSheetAction.ClearSheetData, () => {
        return { ...defaultState };
      })
      .addCase(googleSheetAction.GotSpreadsheetList, (state, action) => {
        const {
          data: { spreadsheets },
          oauthCredentialId,
        } = action.payload;

        state.spreadsheets[oauthCredentialId] = Object.values(
          spreadsheets.reduce<Record<string, GoogleSpreadsheet>>(
            (spreadsheets, spreadsheet) => {
              if (!(spreadsheet.id in spreadsheets)) {
                spreadsheets[spreadsheet.id] = spreadsheet;
              }
              return spreadsheets;
            },
            {}
          )
        );
      })
      .addCase(googleSheetAction.GotSpreadsheetColumnList, (state, action) => {
        const {
          data: { sheets },
          spreadsheetId,
        } = action.payload;

        if (!(spreadsheetId in state.sheets)) {
          state.sheets[spreadsheetId] = {};
        }

        sheets.forEach(sheet => {
          state.sheets[spreadsheetId][sheet.id] = sheet;
        });
      });
  }
);
