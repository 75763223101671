import { Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { KeyValueExample } from "../KeyValueExample";
import styles from "./styles.module.scss";

interface Props {
  notes: string[];
}

export const InspectorNote: React.FC<Props> = ({ notes }) => {
  return (
    <div className={styles["inspector-note"]}>
      {notes.map((note, index) => (
        <Text key={index} variant="small">
          <FormattedMessage id={note} />
        </Text>
      ))}
    </div>
  );
};

export const AnchorInspectorNote: React.FC<unknown> = () => (
  <InspectorNote
    notes={[
      "form_inspector.anchor.area",
      "form_inspector.anchor.description",
      "form_inspector.anchor.hints",
    ]}
  />
);

export const DetectionRegionInspectorNote: React.FC<unknown> = () => (
  <InspectorNote
    notes={[
      "form_inspector.detection_region.area",
      "form_inspector.detection_region.reminder",
      "form_inspector.detection_region.hints",
    ]}
  />
);

export const KeyValuePaneNote: React.FC<unknown> = () => (
  <>
    <InspectorNote
      notes={[
        "form_inspector.key_value.description",
        "form_inspector.key_value.hints",
      ]}
    />
    <KeyValueExample />
  </>
);

export const TokenGroupPaneNote: React.FC<unknown> = () => (
  <InspectorNote
    notes={[
      "form_inspector.token_group.description",
      "form_inspector.token_group.hints",
    ]}
  />
);
