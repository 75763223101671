@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.team-list {
  overflow-y: auto;
  background-color: #fff;
  padding: 32px;
  margin-bottom: 48px;

  .title {
    margin-bottom: 8px;
  }

  .search-filter {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .search-input-field {
    width: 300px;
    padding-top: 10px;

    input {
      padding-left: 36px;
      padding-right: 8px;
    }
  }

  .search-icon {
    position: absolute;
    bottom: 6px;
    left: 12px;
    top: auto;
    font-size: 16px;
    line-height: 18px;
    color: $theme-primary;
  }

  .plan-filter-text,
  .plan-filter,
  .search-by-text,
  .search-by-dropdown {
    padding-top: 10px;
  }

  .search-button {
    margin-left: 16px;
    margin-bottom: -10px;
  }

  .plan-filter-text {
    margin-left: 16px;
    margin-right: 8px;
  }

  .search-by-text {
    margin-right: 8px;
  }

  .plan-filter,
  .search-by-dropdown {
    width: 120px;
  }

  .search-by-dropdown {
    margin-right: 16px;
  }

  .tab-bar {
    margin-left: -10px;
    margin-bottom: 24px;
  }
}
