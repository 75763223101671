import {
  IDropdownOption,
  Icon,
  PrimaryButton,
  SearchBox,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import * as React from "react";

import { EXTRACTOR_PAGE_SIZE } from "../../constants/layout";
import {
  PrebuiltExtractorDefinition,
  PrebuiltExtractors,
  isPrebuiltExtractors,
} from "../../constants/prebuiltExtractor";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import {
  Extractor,
  extractorTypeOptions,
  mapExtractorTypeToMessageId,
} from "../../types/extractor";
import { ExtractorTypesDropdown } from "../ExtractorTypesDropdown";
import { Img } from "../Img";
import Paginator from "../Paginator";
import styles from "./styles.module.scss";

function SkeletonExtractorBlock() {
  return (
    <div className={`${styles["grid-item"]} ${styles["skeleton"]}`}>
      <div className={styles["item-info"]}>
        <div className={styles["large-header"]}>
          <div className={styles.icon}></div>
          <span className={styles["inline-header"]}></span>
        </div>

        <div className={styles["description"]}></div>

        <div className={styles["alt-description"]}></div>
      </div>
    </div>
  );
}

function CreateExtractorBlock(props: { onClick: () => void }) {
  const { onClick } = props;

  return (
    <div
      className={classnames(styles["grid-item"], styles["create"])}
      onClick={onClick}
    >
      <Icon iconName="Add" className={styles["icon"]} />
      <div className={styles["text"]}>
        <FormattedMessage id="extractor.list.list.create_new_extractor" />
      </div>
    </div>
  );
}

function ExtractorBigIcon(props: {
  extractorType: Extractor["extractorType"];
  resourceType: Extractor["resourceType"];
}) {
  const { extractorType, resourceType } = props;
  const iconToUse = isPrebuiltExtractors(extractorType)
    ? PrebuiltExtractorDefinition[extractorType].extractorCardIcon
    : {
        form: "IconExtractorCardFixed",
        form_group: "IconExtractorCardCombine",
        custom_model: "IconExtractorCardCustomModelExtractor",
      }[resourceType];

  return (
    <div className={styles["extractor-card-big-icon"]}>
      <Icon iconName={iconToUse} />
    </div>
  );
}

interface BlockProps {
  onClick: (extractor: Extractor) => void;
  onDelete: (extractor: Extractor) => Promise<void>;
  onPin: (extractor: Extractor) => Promise<void>;
  extractor: Extractor;
}

export function ExtractorBlock(props: BlockProps) {
  const { extractor, onClick, onDelete, onPin } = props;
  const { extractorType, resourceType, updatedAt, name } = props.extractor;
  const { hasPermissionToRemoveResource } = useTeamPermission();

  const { iconName, iconClass } = React.useMemo(() => {
    if (isPrebuiltExtractors(extractorType)) {
      return {
        iconName: PrebuiltExtractorDefinition[extractorType].icon,
        iconClass: styles["prebuilt"],
      };
    } else if (extractorType === "combine") {
      return {
        iconName: "IconPuzzle",
        iconClass: styles["combined"],
      };
    } else {
      if (resourceType === "custom_model") {
        return {
          iconName: "IconBoxModel",
          iconClass: styles["custom-model"],
        };
      }
      return {
        iconName: "IconFixedLayout",
        iconClass: styles["fixed-layout"],
      };
    }
  }, [extractorType, resourceType]);

  const messageIdForExtractorType = React.useMemo(() => {
    if (isPrebuiltExtractors(extractorType)) {
      return mapExtractorTypeToMessageId(extractorType);
    } else {
      return {
        form: "extractor.custom.fixed_layout_extractor",
        form_group: "extractor.custom.combine",
        custom_model: "extractor.custom.trained_with_sample",
      }[resourceType];
    }
  }, [extractorType, resourceType]);

  const onBlockClicked = React.useCallback(() => {
    onClick(extractor);
  }, [onClick, extractor]);

  const onDeleteButtonClicked = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onDelete(extractor);
    },
    [onDelete, extractor]
  );

  const onPinButtonClicked = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onPin(extractor);
    },
    [onPin, extractor]
  );

  return (
    <div className={styles["grid-item"]} onClick={onBlockClicked}>
      <div className={styles["item-info"]}>
        <div className={styles["large-header"]}>
          <div className={classnames(styles["icon"], iconClass)}>
            <Icon iconName={iconName} />
          </div>
          <span className={styles["inline-header"]}>
            {messageIdForExtractorType && (
              <FormattedMessage id={messageIdForExtractorType} />
            )}
          </span>
          <Icon
            iconName={extractor.isPinned ? "PinSolid12" : "Pin"}
            className={
              extractor.isPinned ? styles["pinned"] : styles["unpinned"]
            }
            onClick={onPinButtonClicked}
          />
        </div>

        <div className={styles["description"]} title={name}>
          {name}
        </div>

        <div className={styles["alt-description"]}>
          <span>
            <FormattedMessage
              id="extractor.list.last_edited"
              values={{
                date: new Date(updatedAt).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }),
              }}
            />
          </span>
          {hasPermissionToRemoveResource && (
            <Icon iconName="IconTrash" onClick={onDeleteButtonClicked} />
          )}
        </div>
      </div>
      <div className={styles["item-image"]}>
        {extractor.image ? (
          <Img frameClass={styles["item-image-frame"]} src={extractor.image} />
        ) : (
          <ExtractorBigIcon
            resourceType={extractor.resourceType}
            extractorType={extractor.extractorType}
          />
        )}
      </div>
    </div>
  );
}

export interface Loading {
  type: "loading";
}

export interface NoResultFound {
  type: "noResultFound";
}

export interface ShowExtractorGrid {
  type: "showExtractorGrid";
  extractors: Extractor[];
  totalCount: number;
  currentPage: number;
  onOpenExtractor: (extractor: Extractor) => void;
  onDeleteExtractor: (extractor: Extractor) => Promise<void>;
  onPinExtractor: (extractor: Extractor) => Promise<void>;
  onNavigateToPage: (page: number) => void;
}

//@deprecated
export interface ShowCreateExtractor {
  type: "showCreateExtractor";
}

export type ExtractorListState =
  | Loading
  | ShowExtractorGrid
  | ShowCreateExtractor
  | NoResultFound;

interface Props {
  state: ExtractorListState;
  filter: string[];
  onDropdownChange: (
    _event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    _index?: number
  ) => void;
  onCreate: () => void;
  availablePrebuiltExtractors: typeof PrebuiltExtractors;
  searchText?: string;
  setSearchText: (text: string) => void;
  onSubmitSearch: () => void;
  onClearSearch: () => void;
}

export function ExtractorListSection(props: Props) {
  const {
    state,
    filter,
    onDropdownChange,
    onCreate,
    availablePrebuiltExtractors,
    searchText,
    setSearchText,
    onSubmitSearch,
    onClearSearch,
  } = props;

  const selectedAll =
    filter.length ===
    availablePrebuiltExtractors.length + extractorTypeOptions.length;

  const { localized } = useLocale();
  const { hasPermissionToCreateResource } = useTeamPermission();

  const options: IDropdownOption[] = React.useMemo(() => {
    const controlOptions = [
      {
        key: "select_all",
        text: localized("extractor.list.filter.select_all"),
      },
    ];
    const prebuiltExtractorOptions = availablePrebuiltExtractors.map(type => ({
      key: type,
      text: localized(mapExtractorTypeToMessageId(type)),
    }));
    const otherExtractorOptions = extractorTypeOptions.map(type => ({
      key: type,
      text: localized(`extractor.typeOption.${type}`),
    }));
    return [
      ...controlOptions,
      ...prebuiltExtractorOptions,
      ...otherExtractorOptions,
    ];
  }, [localized, availablePrebuiltExtractors]);

  const onSearchTextChange = React.useCallback(
    (_, newValue?: string) => {
      setSearchText(newValue ?? "");
    },
    [setSearchText]
  );

  const onSearch = React.useCallback(() => {
    onSubmitSearch();
  }, [onSubmitSearch]);

  const onSearchClear = React.useCallback(() => {
    onClearSearch();
  }, [onClearSearch]);

  return (
    <div className={styles["container"]}>
      <div className={styles["control"]}>
        {hasPermissionToCreateResource && (
          <PrimaryButton
            text="Create new extractor"
            iconProps={{ iconName: "Add" }}
            onClick={onCreate}
            className={styles["button"]}
          />
        )}

        <div className={styles["filters"]}>
          <ExtractorTypesDropdown
            options={options}
            onChange={onDropdownChange}
            selectedKeys={[...filter, ...(selectedAll ? ["select_all"] : [])]}
          />
          <SearchBox
            placeholder={localized("extractor.list.search")}
            value={searchText}
            onChange={onSearchTextChange}
            onSearch={onSearch}
            onClear={onSearchClear}
          />
        </div>
      </div>

      {state.type === "loading" && (
        <div className={styles["grid"]}>
          <SkeletonExtractorBlock />
          <SkeletonExtractorBlock />
        </div>
      )}

      {state.type === "showExtractorGrid" && (
        <>
          <div className={styles["grid"]}>
            {state.extractors.map(extractor => {
              return (
                <ExtractorBlock
                  extractor={extractor}
                  onClick={state.onOpenExtractor}
                  onDelete={state.onDeleteExtractor}
                  onPin={state.onPinExtractor}
                  key={extractor.id}
                />
              );
            })}
          </div>
          <div className={styles["paginator"]}>
            <Paginator
              currentPage={state.currentPage}
              totalCount={state.totalCount}
              pageSize={EXTRACTOR_PAGE_SIZE}
              navigateToPage={state.onNavigateToPage}
            />
          </div>
        </>
      )}

      {(state.type === "noResultFound" ||
        (state.type === "showCreateExtractor" &&
          !hasPermissionToCreateResource)) && (
        <div className={styles["empty"]}>
          <FormattedMessage id="extractor.list.no_extractor_found" />
        </div>
      )}

      {state.type === "showCreateExtractor" &&
        hasPermissionToCreateResource && (
          <div className={styles["grid"]}>
            <CreateExtractorBlock onClick={onCreate} />
          </div>
        )}
    </div>
  );
}
