import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import {
  ChoiceGroup,
  IChoiceGroupOption,
} from "@fluentui/react/lib/ChoiceGroup";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useRef, useState } from "react";

import {
  DATE_FORMAT_OPTIONS,
  PREVIOUS_DEFAULT_DATE_FORMAT,
} from "../../constants";
import { useDetectionRegionInspector } from "../../contexts/detectionRegionInspector";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { BaseFieldSettingModalPayload } from "../../models";
import { DetectionRegionFieldType } from "../../types/detectionRegion";
import DetectionRegionAdvancedSetting, {
  DetectionRegionAdvancedSettingHandle,
} from "../DetectionRegionAdvancedSetting";
import { DetectionRegionWaterMarkSetting } from "../DetectionRegionWaterMarkSetting";
import styles from "./styles.module.scss";

interface Props {
  payload: BaseFieldSettingModalPayload;
  onCloseModal(): void;
}

const DateTimeFieldModal = React.memo((props: Props) => {
  const { onCloseModal, payload } = props;
  const { index, field, selectedDetectionRegionId } = payload;
  const { localized } = useLocale();
  const { updateDetectionRegionField } = useDetectionRegionInspector();
  const [fieldKey, setFieldKey] = useState<DetectionRegionFieldType>(
    field.type
  );
  const [extras, setExtras] = useState<any>(field.extras);
  const advancedSettingsRef =
    useRef<DetectionRegionAdvancedSettingHandle>(null);

  const { hasPermissionToEditResource } = useTeamPermission();

  const [dateFormatKey, setDateFormatKey] = useState(
    (extras && extras["date_format"]) ?? PREVIOUS_DEFAULT_DATE_FORMAT
  );

  const onSave = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const option = { key: fieldKey } as IDropdownOption;
      let params;
      if (advancedSettingsRef.current) {
        params = advancedSettingsRef.current.getParams();
      }

      updateDetectionRegionField(index, {
        type: option.key as DetectionRegionFieldType,
        params: params,
        extras: {
          ...extras,
          date_format: dateFormatKey,
        },
      });

      onCloseModal();
    },
    [
      fieldKey,
      updateDetectionRegionField,
      index,
      extras,
      dateFormatKey,
      onCloseModal,
    ]
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        "form_inspector.detection_region_field_date_time_setting_editor.title"
      ),
    }),
    [localized]
  );

  const modalProps: IModalProps = React.useMemo(
    () => ({
      isBlocking: true,
      className: styles["date-time-field-modal"],
    }),
    []
  );

  const options: IChoiceGroupOption[] = [
    {
      key: "date",
      text: localized(
        "form_inspector.detection_region_field_date_time_setting_editor.date_option"
      ),
    },
    {
      key: "time",
      text: localized(
        "form_inspector.detection_region_field_date_time_setting_editor.time_option"
      ),
    },
  ];

  const _onFieldKeyChange = (
    _ev: React.FormEvent<HTMLInputElement | HTMLElement> | undefined,
    option?: IChoiceGroupOption
  ): void => {
    if (option) {
      setFieldKey(option.key as DetectionRegionFieldType);
    }
  };

  const onFormatChange = (
    _ev: React.FormEvent<HTMLInputElement | HTMLElement> | undefined,
    option?: IChoiceGroupOption
  ): void => {
    if (option) {
      setDateFormatKey(option.key);
    }
  };

  return (
    <Dialog
      minWidth={405}
      hidden={false}
      onDismiss={onCloseModal}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <form onSubmit={onSave}>
        <div className={styles["description"]}>
          <FormattedMessage id="form_inspector.detection_region_field_date_time_setting_editor.desc" />
        </div>

        <ChoiceGroup
          defaultSelectedKey={field.type}
          options={options}
          onChange={_onFieldKeyChange}
          label={localized(
            "form_inspector.detection_region_field_date_time_setting_editor.option_title"
          )}
          disabled={!hasPermissionToEditResource}
        />

        {fieldKey === "date" && (
          <ChoiceGroup
            options={DATE_FORMAT_OPTIONS}
            label={localized(
              "form_inspector.detection_region_field_date_time_setting_editor.date_fornat_option"
            )}
            selectedKey={dateFormatKey}
            onChange={onFormatChange}
          />
        )}

        <div className={styles["water-mark-setting"]}>
          <DetectionRegionWaterMarkSetting
            onChange={setExtras}
            extras={field.extras}
          />
        </div>

        <DetectionRegionAdvancedSetting
          fieldType={fieldKey}
          fieldParams={field.params}
          selectedDetectionRegionId={selectedDetectionRegionId}
          fieldIndex={index}
          ref={advancedSettingsRef}
        />
        <DialogFooter>
          <DefaultButton
            onClick={onCloseModal}
            text={localized(
              hasPermissionToEditResource ? "common.cancel" : "common.close"
            )}
          />
          {hasPermissionToEditResource && (
            <PrimaryButton type="submit" text={localized("common.ok")} />
          )}
        </DialogFooter>
      </form>
    </Dialog>
  );
});

export default DateTimeFieldModal;
