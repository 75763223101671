@import "../../styles/variables.scss";

$gray-color: #a19f9d;

.paginator-container {
  margin-top: 10px;
  .page-navigator {
    pointer-events: none;
    font-size: 14px;
    color: $gray-color;
    margin-right: 8px;

    &.active {
      pointer-events: all;
      cursor: pointer;
      color: $theme-primary;
    }
  }

  .page-index {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;

    &.current-page {
      pointer-events: none;
      color: $gray-color;
    }
  }
}
