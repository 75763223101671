.info-note {
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #f3f2f1;
  position: relative;

  > span {
    margin: 6px 0;
  }

  margin-bottom: 16px;

  .icon {
    position: absolute;
    font-size: 14px;
    top: 12px;
    left: 12px;
  }
}
