import { FormattedMessage } from "@oursky/react-messageformat";
import cntl from "cntl";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import Logo from "jsx:../../../images/logo/logo-v2-black.svg";
import * as React from "react";
import { useNavigate } from "react-router";

import { AppConfig } from "../../../config";
import { useLocale } from "../../../contexts/locale";
import { IPAPI_URL } from "../../../hooks/geoip";
import { useGtm } from "../../../hooks/gtm";
import { useTeamRefsByRegion } from "../../../hooks/teamRefs";
import { SurveyForm, SurveyUseCase } from "../../../types/survey";
import { focusTextField } from "../../../utils/components";
import { validateTeamName } from "../../../utils/validation";
import { Button } from "../Button";
import { Chip } from "../Chip";
import { TextField } from "../TextField";
import { Content } from "./Content";
import { ExtractorCreationPage } from "./ExtractorCreationPage";
import {
  TeamCreationPage,
  useTeamCreationPageHandle,
} from "./TeamCreationPage";
import { TeamInvitationPage } from "./TeamInvitationPage";

export enum Page {
  NameInput = "name",
  CompanyInput = "company",
  UsagesInput = "use_cases",
  MobileInput = "phone",
  TeamCreationPage = "team_creation",
  TeamInvitationPage = "team_invitation",
  ExtractorCreationPage = "extractor_create",
}

function normalizePhoneNumber(phoneNumber: string, dialCode: string) {
  if (phoneNumber.trim().startsWith("+")) {
    return phoneNumber;
  }

  return `+${dialCode}${phoneNumber.trim()}`;
}

type Props = {
  surveyForm: SurveyForm;
  updateSurveyForm: (form: SurveyForm) => void;
  submitSurvey?: () => void;
  page?: string;
  countryCode: string | null;
  isSurveyFormSubmitted: boolean;
  hasPendingInvitation: boolean;
  onConfirmTeamCreation: (
    name: string,
    region: string,
    lookupId: string
  ) => Promise<void>;
  isLoading: boolean;
  onViewTeam: (teamId: string, region: string) => void;
};

export function normalizeTeamName(name: string) {
  return name.trim().replace(/ +/g, " ");
}

export function useOnboardingWizardState(props: Props) {
  const [currentPage, setCurrentPage] = React.useState<Page>(Page.NameInput);
  const {
    surveyForm,
    updateSurveyForm,
    countryCode,
    page,
    isSurveyFormSubmitted,
    hasPendingInvitation,
    onConfirmTeamCreation,
    onViewTeam,
  } = props;
  const navigate = useNavigate();
  const {
    pushSurveyClickedBackEvent,
    pushSurveySetName,
    pushSurveySetCompany,
    pushSurveySetUseCases,
  } = useGtm();

  const {
    isUserHasTeamInOtherRegions,
    isTeamRefsByRegionFetchedOnce,
    isNewUser,
    teamRefsByRegion,
  } = useTeamRefsByRegion();

  const isLoading = !isTeamRefsByRegionFetchedOnce || props.isLoading;

  const triggerSurveyUseCase = React.useCallback(
    (item: SurveyUseCase) => {
      const enabled = surveyForm.useCases.includes(item);
      const surveyUsages = enabled
        ? surveyForm.useCases.filter(i => i !== item)
        : [...surveyForm.useCases, item];

      updateSurveyForm({
        ...surveyForm,
        useCases: surveyUsages,
      });
    },
    [surveyForm, updateSurveyForm]
  );

  const updateName = React.useCallback(
    (name: string) => {
      updateSurveyForm({
        ...surveyForm,
        name,
      });
    },
    [surveyForm, updateSurveyForm]
  );

  const updateCompanyName = React.useCallback(
    (companyName: string) => {
      updateSurveyForm({
        ...surveyForm,
        companyName,
      });
    },
    [surveyForm, updateSurveyForm]
  );

  const updateMobileNumber = React.useCallback(
    (phoneNumber: string) => {
      updateSurveyForm({
        ...surveyForm,
        phoneNumber,
      });
    },
    [surveyForm, updateSurveyForm]
  );

  const next = React.useCallback(() => {
    if (currentPage === Page.MobileInput) {
      return;
    }

    switch (currentPage as Page) {
      case Page.NameInput:
        pushSurveySetName(surveyForm.name);
        break;
      case Page.CompanyInput:
        pushSurveySetCompany(surveyForm.companyName);
        break;
      case Page.UsagesInput:
        pushSurveySetUseCases(surveyForm.useCases);
        break;
      default:
        break;
    }

    const currentPageIndex = Object.values(Page).indexOf(currentPage);
    const nextPage = Object.values(Page)[currentPageIndex + 1];

    navigate(`/onboarding/${nextPage}`);
  }, [
    currentPage,
    pushSurveySetName,
    surveyForm,
    pushSurveySetCompany,
    pushSurveySetUseCases,
    navigate,
  ]);

  const back = React.useCallback(() => {
    if (currentPage === Page.NameInput) {
      return;
    }

    const currentPageIndex = Object.values(Page).indexOf(currentPage);
    const prevPage = Object.values(Page)[currentPageIndex - 1];

    pushSurveyClickedBackEvent();
    navigate(`/onboarding/${prevPage}`);
  }, [currentPage, pushSurveyClickedBackEvent, navigate]);

  React.useEffect(() => {
    if (Object.values(Page).includes((page ?? "") as Page)) {
      setCurrentPage(page as Page);
    } else {
      if (hasPendingInvitation) {
        setCurrentPage(Page.TeamInvitationPage);
      } else if (
        isSurveyFormSubmitted ||
        AppConfig.skipOnboardingSurvey ||
        isNewUser === false
      ) {
        setCurrentPage(Page.TeamCreationPage);
      } else {
        setCurrentPage(Page.NameInput);
      }
    }
    // Trigger only on page&isLoading properties change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isLoading]);

  const submitSurvey = React.useCallback(() => {
    props.submitSurvey?.();
  }, [props]);

  const { localized } = useLocale();

  const defaultTeamName = React.useMemo(() => {
    const surveyFormCompanyName = normalizeTeamName(surveyForm.companyName);
    if (validateTeamName(surveyFormCompanyName) !== undefined) {
      return localized("onboarding.default_team_name");
    }
    return surveyFormCompanyName;
  }, [localized, surveyForm.companyName]);

  const teamCreationPageProps = useTeamCreationPageHandle({
    defaultTeamName,
    onConfirmTeamCreation,
    isUserHasTeamInOtherRegions,
    isTeamRefsByRegionFetchedOnce,
    isNewUser,
    teamRefsByRegion,
  });

  return React.useMemo(
    () => ({
      currentPage,
      triggerSurveyUseCase,
      updateName,
      updateCompanyName,
      updateMobileNumber,
      surveyForm,
      back,
      next,
      submitSurvey,
      countryCode,
      onConfirmTeamCreation,
      onViewTeam,
      isLoading,
      defaultTeamName,
      teamCreationPageProps,
    }),
    [
      currentPage,
      triggerSurveyUseCase,
      updateName,
      surveyForm,
      updateCompanyName,
      updateMobileNumber,
      back,
      next,
      submitSurvey,
      countryCode,
      onConfirmTeamCreation,
      onViewTeam,
      isLoading,
      defaultTeamName,
      teamCreationPageProps,
    ]
  );
}
export function OnboardingWizard(props: Props) {
  const states = useOnboardingWizardState(props);

  return <OnboardingWizardImpl {...states} />;
}

export function NameInputPage(
  props: ReturnType<typeof useOnboardingWizardState>
) {
  const { localized } = useLocale();
  const { updateName } = props;
  const name = props.surveyForm.name;

  const nextDisabled = name.trim() === "";

  const onChange = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.stopPropagation();
      event.preventDefault();
      if (value !== undefined) {
        updateName(value);
      }
    },
    [updateName]
  );

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (nextDisabled) return;
      if (event.key === "Enter") {
        props.next();
      }
    },
    [props, nextDisabled]
  );

  return (
    <Content
      sizeConstraint="max-w-[728px] min-w-[728px]"
      footer={
        <Button
          type="primary"
          textId="common.next"
          disabled={nextDisabled}
          onClick={props.next}
        />
      }
    >
      <div className="flex flex-col justify-center items-center pb-[48px]">
        <div className="text-headline-medium text-primary pt-[48px]">
          <FormattedMessage id="onboarding.name_input.title" />
        </div>
        <div className="text-title-medium text-text-secondary pt-[48px]">
          <FormattedMessage id="onboarding.name_input.message" />
        </div>

        <TextField
          className="pt-[32px] max-w-[310px] w-[310px]"
          placeholder={localized("onboarding.name_input.placeholder")}
          componentRef={focusTextField}
          value={props.surveyForm.name}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      </div>
    </Content>
  );
}

export function CompanyInputPage(
  props: ReturnType<typeof useOnboardingWizardState>
) {
  const { localized } = useLocale();
  const companyName = props.surveyForm.companyName;
  const { updateCompanyName } = props;
  const nextDisabled = companyName.trim() === "";

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (nextDisabled) {
        return;
      }

      if (event.key === "Enter") {
        props.next();
      }
    },
    [props, nextDisabled]
  );

  const onChange = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.stopPropagation();
      event.preventDefault();
      if (value !== undefined) {
        updateCompanyName(value);
      }
    },
    [updateCompanyName]
  );

  return (
    <Content
      sizeConstraint="max-w-[728px] min-w-[728px]"
      footer={
        <>
          <Button type="outline" textId="common.back" onClick={props.back} />
          <Button
            type="primary"
            textId="common.next"
            disabled={nextDisabled}
            onClick={props.next}
          />
        </>
      }
    >
      <div className="flex flex-col justify-center items-center pb-[48px]">
        <div className="text-headline-medium text-primary pt-[48px]">
          <FormattedMessage
            id="onboarding.company_input.title"
            values={{
              name: props.surveyForm.name,
            }}
          />
        </div>
        <div className="text-title-medium text-text-secondary pt-[48px]">
          <FormattedMessage id="onboarding.company_input.message" />
        </div>

        <TextField
          className="pt-[32px] max-w-[310px] w-[310px]"
          placeholder={localized("onboarding.company_input.placeholder")}
          value={props.surveyForm.companyName}
          componentRef={focusTextField}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      </div>
    </Content>
  );
}

export function UsagesInputPage(
  props: ReturnType<typeof useOnboardingWizardState>
) {
  const { surveyForm } = props;

  const nextDisabled = surveyForm.useCases.length === 0;

  return (
    <Content
      sizeConstraint="max-w-[728px] min-w-[728px]"
      footer={
        <>
          <Button type="outline" textId="common.back" onClick={props.back} />
          <Button
            type="primary"
            textId="common.next"
            disabled={nextDisabled}
            onClick={props.next}
          />
        </>
      }
    >
      <div className="flex flex-col justify-center items-center pb-[24px]">
        <div className="text-headline-medium text-primary pt-[48px]">
          <FormattedMessage id="onboarding.reason_input.title" />
        </div>
        <div className="text-title-medium text-text-secondary pt-[48px] flex flex-col justify-center items-center">
          <FormattedMessage id="onboarding.reason_input.message1" />
          <div>
            <FormattedMessage id="onboarding.reason_input.message2" />
          </div>
        </div>
        <div className="pt-[32px] my-[20px] flex flex-row flex-wrap	justify-center gap-[16px]">
          {Object.keys(SurveyUseCase).map(key => {
            const value = SurveyUseCase[key as keyof typeof SurveyUseCase];
            const isActive = surveyForm.useCases.includes(value);
            return (
              <Chip
                key={key}
                isActive={isActive}
                textId={`onboarding.reason_input.survey.${value}`}
                onClick={() => {
                  props.triggerSurveyUseCase(value);
                }}
              />
            );
          })}
        </div>
      </div>
    </Content>
  );
}

export function MobileInputPage(
  props: ReturnType<typeof useOnboardingWizardState>
) {
  const { updateMobileNumber, submitSurvey, countryCode } = props;
  const state = React.useRef<any>({
    iti: null,
  });

  const defaultMobileNumber = props.surveyForm.phoneNumber;

  const inputRef = React.useRef<HTMLInputElement>(null);

  const submit = React.useCallback(() => {
    const text = inputRef.current?.value ?? "";
    if (text === "") {
      updateMobileNumber("");
    } else {
      const phoneNumber = normalizePhoneNumber(
        text,
        state.current.iti.getSelectedCountryData().dialCode
      );
      updateMobileNumber(phoneNumber);
    }
    submitSurvey();
  }, [submitSurvey, updateMobileNumber]);
  const submitRef = React.useRef(submit);
  submitRef.current = submit;

  React.useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const onKey = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.stopPropagation();
        event.preventDefault();
        submitRef.current();
      }
    };

    const iti = intlTelInput(inputRef.current, {
      initialCountry: countryCode ?? "auto",
      geoIpLookup: (callback: any) => {
        // It won't trigger if the initialCountry is not "auto"
        fetch(IPAPI_URL)
          .then(function (res) {
            return res.json();
          })
          .then(function (data) {
            callback(data.country_code);
          })
          .catch(function () {});
      },
    });
    state.current.iti = iti;
    iti.setPlaceholderNumberType("UNKNOWN");

    inputRef.current.focus();

    inputRef.current.addEventListener("keydown", onKey);
    const input = inputRef.current;
    return () => {
      input.removeEventListener("keydown", onKey);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { localized } = useLocale();
  const placeholder = localized("onboarding.mobile_input.placeholder");

  return (
    <Content
      sizeConstraint="max-w-[728px] min-w-[728px]"
      footer={
        <>
          <Button type="outline" textId="common.back" onClick={props.back} />
          <Button type="primary" textId="common.next" onClick={submit} />
        </>
      }
    >
      <div className="flex flex-col justify-center items-center pb-[92px]">
        <div className="text-headline-medium text-primary pt-[48px]">
          <FormattedMessage id="onboarding.mobile_input.title" />
        </div>
        <div className="text-title-medium text-text-secondary pt-[48px] flex flex-col justify-center items-center">
          <FormattedMessage id="onboarding.mobile_input.message1" />
          <div>
            <FormattedMessage id="onboarding.mobile_input.message2" />
          </div>
        </div>

        <div
          className={cntl`pt-[32px] 
           [&_input]:border-border-primary 
           [&_input]:focus:border-border-primary
           [&_input]:focus-within:border-border-primary
          `}
        >
          <input
            ref={inputRef}
            defaultValue={defaultMobileNumber}
            placeholder={placeholder}
            className={cntl`max-w-[310px] w-[310px] h-[48px]
                        rounded-[8px]
                        focus-visible:border-border-primary
                        focus-visible:outline-none
                        outline-none
                        text-body-medium border-solid`}
          ></input>
        </div>
      </div>
    </Content>
  );
}

export function OnboardingWizardImpl(
  props: ReturnType<typeof useOnboardingWizardState>
) {
  return (
    <div className="w-full h-full bg-surface-background relative">
      <Logo className="absolute top-[6px] left-[12px]" />
      {props.currentPage === Page.NameInput && <NameInputPage {...props} />}
      {props.currentPage === Page.CompanyInput && (
        <CompanyInputPage {...props} />
      )}
      {props.currentPage === Page.UsagesInput && <UsagesInputPage {...props} />}
      {props.currentPage === Page.MobileInput && <MobileInputPage {...props} />}
      {props.currentPage === Page.TeamCreationPage && (
        <TeamCreationPage {...props.teamCreationPageProps} />
      )}
      {props.currentPage === Page.ExtractorCreationPage && (
        <ExtractorCreationPage />
      )}
      {props.currentPage === Page.TeamInvitationPage && (
        <TeamInvitationPage onViewTeam={props.onViewTeam} />
      )}
    </div>
  );
}
