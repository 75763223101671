import {
  CustomCell,
  CustomRenderer,
  GridCellKind,
  Item,
  ProvideEditorComponent,
  Rectangle,
  getMiddleCenterBias,
} from "@glideapps/glide-data-grid";
import React, { useCallback, useMemo, useState } from "react";
import { MenuProps, components } from "react-select";
import CreatableSelect from "react-select/creatable";

import { renderErrorState } from "./ErrorState";
import { truncatStr } from "./Utils/Text";

type CustomMenuProps = MenuProps<any>;

const CustomMenu: React.FC<CustomMenuProps> = p => {
  const { Menu } = components;
  const { children, ...rest } = p;
  return <Menu {...rest}>{children}</Menu>;
};

interface AutoCompleteCellData {
  value: string;
  onCreateNewOption: (newOption: string, item: Item) => void;
  options: string[];
}

interface AutoCompleteCellProps {
  readonly kind: "auto-complete-cell";
  data: AutoCompleteCellData;
  error?: string;
  item: Item;
}

export type AutoCompleteCell = CustomCell<AutoCompleteCellProps>;

export function isAutoCompleteCell(cell: CustomCell): cell is AutoCompleteCell {
  return (cell.data as any).kind === "auto-complete-cell";
}

const AutoCompleteCellEditor: ProvideEditorComponent<
  AutoCompleteCell
> = props => {
  const { value: cell, onFinishedEditing, initialValue } = props;
  const { data, readonly } = cell;
  const [inputValue, setInputValue] = useState(initialValue ?? "");
  const [value, setValue] = useState(data.data.value);
  const onCreateNewOption = useCallback(
    (newValue: string) => {
      data.data.onCreateNewOption(newValue, data.item);
      onFinishedEditing({
        ...cell,
        data: {
          ...cell.data,
          data: {
            ...cell.data.data,
            value: newValue,
            options: data.data.options.concat(newValue),
          },
        },
      });
    },
    [onFinishedEditing, data, cell]
  );
  const onChange = useCallback(
    async e => {
      if (e === null) return;
      setValue(e.value);
      await new Promise(r => window.requestAnimationFrame(r));
      onFinishedEditing({
        ...cell,
        data: {
          ...cell.data,
          data: {
            ...cell.data.data,
            value: e.value,
          },
        },
      });
    },
    [onFinishedEditing, cell]
  );
  const options = useMemo(
    () =>
      data.data.options.map(o => ({
        label: o,
        value: o,
      })),
    [data.data.options]
  );
  const selectedValue = options.find(x => x.value === value);
  return readonly ? (
    <div className={"flex flex-1 items-center mx-2.5"}>
      <input
        className={"flex-1 border-none focus:outline-none"}
        name="input"
        type="text"
        value={value}
        readOnly={readonly}
      />
    </div>
  ) : (
    <CreatableSelect
      styles={{
        input: base => ({
          ...base,
          padding: 0,
        }),
        option: base => ({
          ...base,
          fontSize: 12,
        }),
        control: base => ({
          ...base,
          border: 0,
          boxShadow: "none",
        }),
      }}
      menuPlacement={"auto"}
      inputValue={inputValue}
      onInputChange={setInputValue}
      value={selectedValue}
      menuPortalTarget={document.getElementById("portal")}
      options={options}
      autoFocus={true}
      openMenuOnFocus={true}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Menu: props => (
          <CustomMenu className={"click-outside-ignore"} {...props} />
        ),
      }}
      onCreateOption={onCreateNewOption}
      onChange={onChange}
    />
  );
};

export const AutoCompleteCellRenderer: CustomRenderer<AutoCompleteCell> = {
  kind: GridCellKind.Custom,
  isMatch: isAutoCompleteCell,
  draw: (args, cell) => {
    const { ctx, theme, rect } = args;
    const { data, error } = cell.data;

    let textOffset = 0;
    if (error) {
      renderErrorState(args, error);
      textOffset += 20;
    }
    const drawArea: Rectangle = {
      x: rect.x + theme.cellHorizontalPadding,
      y: rect.y + theme.cellVerticalPadding,
      width: rect.width - 2 * theme.cellHorizontalPadding,
      height: rect.height - 2 * theme.cellVerticalPadding,
    };
    ctx.font = "12px";
    ctx.fillStyle = "black";
    const bias = getMiddleCenterBias(ctx, `12px ${theme.fontFamily}`);

    const [displayText] = truncatStr(data.value, ctx, 171 - textOffset);
    ctx.fillText(
      displayText,
      textOffset + drawArea.x,
      drawArea.y + drawArea.height / 2 + bias
    );
  },
  provideEditor: () => ({
    disablePadding: true,
    editor: AutoCompleteCellEditor,
  }),
};
