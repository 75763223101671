import { ActionButton, Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import styles from "./styles.module.scss";

interface AddAnchorReminderProps {
  onRemindButtonClicked(): void;
  onCloseReminder(): void;
}

const AddAnchorReminder = React.memo((props: AddAnchorReminderProps) => {
  const { onRemindButtonClicked, onCloseReminder } = props;

  return (
    <div className={styles["container"]}>
      <div className={styles["title-container"]}>
        <h3 className={styles["title"]}>
          <Icon className={styles["title-icon"]} iconName="Lightbulb" />
          <FormattedMessage id="tutorial.fixed_layout_form.anchor_reminder.title" />
        </h3>
        <ActionButton
          className={styles["close-button"]}
          onClick={onCloseReminder}
        >
          <Icon iconName="Clear" />
        </ActionButton>
      </div>
      <div className={styles["description"]}>
        <FormattedMessage
          id="tutorial.fixed_layout_form.anchor_reminder.description"
          values={{
            anchor: (
              <span className={styles["highlight"]}>
                <FormattedMessage id="tutorial.fixed_layout_form.anchor_reminder.description.hightlight" />
              </span>
            ),
          }}
        />
        <div
          onClick={onRemindButtonClicked}
          className={styles["remind-button"]}
        >
          <FormattedMessage id="tutorial.fixed_layout_form.anchor_reminder.remind" />
        </div>
      </div>
    </div>
  );
});

export default AddAnchorReminder;
