import { Checkbox, Spinner, SpinnerSize } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React, { useCallback } from "react";

import { Img } from "../Img";
import styles from "./styles.module.scss";

interface Props {
  imageId: string;
  name: string | undefined | null;
  url: string;
  isEditable?: boolean;
  checked?: boolean;
  isDeleting?: boolean;
  disabled?: boolean;
  date: Date;
  onSelect?: (imageId: string) => void;
  onClickPreview?: (imageId: string) => void;
}

export default function CustomModelImageGridItem(props: Props) {
  const {
    imageId,
    name,
    url,
    isEditable,
    checked,
    disabled,
    date,
    onSelect,
    onClickPreview,
    isDeleting,
  } = props;
  const onCheckboxChanged = useCallback(() => {
    if (onSelect) {
      onSelect(imageId);
    }
  }, [imageId, onSelect]);

  const onImageClicked = useCallback(() => {
    if (onClickPreview) {
      onClickPreview(imageId);
    }
  }, [imageId, onClickPreview]);

  return (
    <div
      className={classnames(styles["grid-item"], {
        [styles["deleting"]]: isDeleting,
      })}
    >
      {isDeleting && (
        <Spinner
          size={SpinnerSize.medium}
          className={styles["deleting-spinner"]}
        />
      )}
      <Img
        alt="preview"
        src={url}
        frameClass={styles["image-preview"]}
        onClick={onImageClicked}
      />
      {isEditable && !isDeleting && (
        <Checkbox
          checked={checked}
          disabled={disabled}
          className={styles["checkbox"]}
          onChange={onCheckboxChanged}
        />
      )}

      <div className={styles["label"]}>
        {name ? (
          name
        ) : (
          <FormattedMessage id="custom_model_editor.image_grid.item.unnamed" />
        )}
      </div>
      <div className={styles["date"]}>
        <FormattedMessage
          id="custom_model_editor.image_grid.item.upload_at"
          values={{ date: date.toLocaleDateString() }}
        />
      </div>
    </div>
  );
}
