import * as React from "react";
import { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import SplashScreen from "../components/SplashScreen";
import UnsupportedBrowserDialog from "../components/UnsupportedBrowserDialog";
import { AppConfig } from "../config";
import { PAYMENT_REQUIRED_TOAST_ID, UserFeatureFlag } from "../constants";
import NavigationStackProvider from "../contexts/navigationStack";
import { useAutoHideLeftBar, useAutoShowLeftBar } from "../hooks/autoLeftBar";
import { useGtm } from "../hooks/gtm";
import { useTeamPermission } from "../hooks/permission";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import AdminContainer from "./Admin";
import AdvanceTokenSetup from "./AdvanceTokenSetup";
import App from "./App";
import AuditLogContainer from "./AuditLog";
import CustomModelAdvanceTokenSetup from "./CustomModelAdvancedTokenSetup";
import CustomModelFormatterContainer from "./CustomModelFormatter";
import CustomModelLabelContainer from "./CustomModelLabel";
import CustomModelModelContainer from "./CustomModelModel";
import CustomModelSettingContainer from "./CustomModelSetting";
import DetectMultiDocumentExtractContainer from "./DetectMultiDocumentExtract";
import DetectMultiDocumentTestContainer from "./DetectMultiDocumentTest";
import DetectPIIExtractContainer from "./DetectPIIExtract";
import DetectPIITestContainer from "./DetectPIITest";
import ExtractorCreateContainer from "./ExtractorCreate";
import ExtractorListContainer from "./ExtractorList";
import FSLCustomModelEditorContainer from "./FSLCustomModelEditor";
import FSLCustomModelExtractContainer from "./FSLCustomModelExtract";
import FSLCustomModelTestContainer from "./FSLCustomModelTest";
import FSLCustomModelTestContainerV1 from "./FSLCustomModelTestV1";
import FSLInstantModelViewerContainer from "./FSLInstantModelViewer";
import FSLInstantSamplEditorContaienr from "./FSLInstantSampleEditor";
import FSLStandardModelEditorContainer from "./FSLStandardModelEditor";
import FormExtractContainer from "./FormAPI";
import FormEditorContainer from "./FormEditor";
import FormGroupEditContainer from "./FormGroupEdit";
import FormGroupExtractContainer from "./FormGroupExtract";
import FormGroupFormatterContainer from "./FormGroupFormatter";
import FormGroupSettingContainer from "./FormGroupSetting";
import FormGroupTestContainer from "./FormGroupTest";
import FormGroupTestContainerV1 from "./FormGroupTestV1";
import FormSettingContainer from "./FormSetting";
import FormTestContainer from "./FormTest";
import FormTestContainerV1 from "./FormTestV1";
import FormatterContainer from "./Formatter";
import InvitationContainer from "./Invitation";
import LoginContainer from "./Login";
import OAuthContainer from "./OAuth";
import OnboardingContainer from "./Onboarding";
import PaymentContainer from "./Payment";
import SettingContainer from "./Setting";
import SharepointAuthContainer from "./SharepointAuth";
import SignUpContainer from "./SignUp";
import TeamContainer from "./Team";
import UsageContainer from "./Usage";
import WebhookCreateContainer from "./WebhookCreate";
import WebhookEditContainer from "./WebhookEdit";
import WebhookListContainer from "./WebhookList";
import WorkspaceApiContainer from "./WorkspaceApi";
import WorkspaceCreateContainer from "./WorkspaceCreate";
import WorkspaceDocumentContainer from "./WorkspaceDocument";
import WorkspaceDocumentResultContainer from "./WorkspaceDocumentResult";
import WorkspaceIntegrationContainer from "./WorkspaceIntegration";
import WorkspaceListContainer from "./WorkspaceList";
import WorkspaceSettingContainer from "./WorkspaceSetting";

const PaymentRequiredToastPresenter = React.memo(
  (props: { onToastClicked: () => void }) => {
    const { onToastClicked } = props;
    const toast = useToast();

    React.useEffect(() => {
      toast.error("app.toast.payment_required", {
        id: PAYMENT_REQUIRED_TOAST_ID,
        autoDismiss: false,
        onDismiss: (_id: string) => {
          onToastClicked();
        },
      });
    }, [toast, onToastClicked]);

    return null;
  }
);

const FormRoute = React.memo(() => {
  useAutoHideLeftBar();

  const isFormatterEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.Formatter
    )
  );
  const isV1TestTabEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.V1TestTab
    )
  );

  return (
    <Routes>
      <Route path="edit" element={<FormEditorContainer />} />
      <Route path="edit/advanceMerchantSetup" element={<AdvanceTokenSetup />} />
      {isFormatterEnabled && (
        <Route path="format" element={<FormatterContainer />} />
      )}
      <Route path="extract" element={<FormExtractContainer />} />
      <Route path="test" element={<FormTestContainer />} />
      {isV1TestTabEnabled && (
        <Route path="test-v1" element={<FormTestContainerV1 />} />
      )}
      <Route path="setting" element={<FormSettingContainer />} />
      <Route index element={<Navigate to="edit" replace />} />
      <Route path="*" element={<Navigate to="edit" replace />} />
    </Routes>
  );
});

const RedirectToHome = React.memo(() => {
  useAutoShowLeftBar();

  return <Navigate to={"/extractor"} replace />;
});

const AppRoute = React.memo(() => {
  const isCurrentUserExist = useAppSelector(
    state => state.user.currentUser !== undefined
  );
  const isNoResourceOwnerExists = useAppSelector(
    state => state.resourceOwner.resourceOwnerId === undefined
  );
  const isAuthenticated = useAppSelector(state => state.user.isAuthenticated);
  const currentUser = useAppSelector(state => state.user.currentUser);
  const isAdmin = currentUser?.isAdmin;

  const { pathname } = useLocation();
  const { pushPageViewEvent } = useGtm();

  useEffect(() => {
    pushPageViewEvent();
  }, [pathname, pushPageViewEvent]);

  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route path={"invitation"} element={<InvitationContainer />} />
        {isAuthenticated && (
          <>
            <Route path="onboarding/:page?" element={<OnboardingContainer />} />
          </>
        )}

        {isAuthenticated && isNoResourceOwnerExists && (
          <Route path="*" element={<Navigate to="onboarding" replace />} />
        )}

        {isAdmin && <Route path={"admin/*"} element={<AdminContainer />} />}

        {isCurrentUserExist ? (
          <Route path="*" element={<MainRoute />} />
        ) : !AppConfig.authGear ? (
          <>
            <Route path="signup" element={<SignUpContainer />} />
            <Route path="login" element={<LoginContainer />} />
            <Route path="*" element={<Navigate to="login" replace />} />
          </>
        ) : (
          <Route path="*" element={<SplashScreen />} />
        )}
      </Route>
    </Routes>
  );
});

const MainRoute = React.memo(() => {
  const isPaymentRequired = useAppSelector(
    state => state.resourceOwner.isPaymentRequired
  );
  const isCustomPlan = useAppSelector(
    state => state.resourceOwner.plan && state.resourceOwner.plan === "custom"
  );
  const isTeam = useAppSelector(state => state.resourceOwner.isTeam);
  const enabledAuditLog = useAppSelector(
    state => state.resourceOwner.enabledAuditLog
  );
  const { hasPermissionToViewMembership, hasPermissionToViewAuditLog } =
    useTeamPermission();
  const isCustomModelTrainingEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.CustomModelTraining
    )
  );

  // #3048 - Custom Model Laballing is always enabled
  const isCustomModelLabellingEnabled = true;

  const isV1TestTabEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.V1TestTab
    )
  );
  const isFormatterEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.Formatter
    )
  );
  const isWebhookV1Enabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.WebhookV1
    )
  );

  const shouldShowPaymentRequiredToast = useAppSelector(
    state => state.app.shouldShowPaymentRequiredToast
  );
  const canCreateResource = useAppSelector(
    state => state.resourceOwner.permissions.createResource
  );
  const canEditResource = useAppSelector(
    state => state.resourceOwner.permissions.editResource
  );

  const isUsingAuthGear = !!AppConfig.authGear;

  const navigate = useNavigate();
  const redirectToPayment = React.useCallback(() => {
    navigate("/payment");
  }, [navigate]);

  return (
    <>
      <Routes>
        <Route path="*" element={<RedirectToHome />} />

        {!isUsingAuthGear && (
          <Route path="signup" element={<SignUpContainer />} />
        )}

        <Route path="workspace" element={<WorkspaceListContainer />} />
        <Route path="workspace/create" element={<WorkspaceCreateContainer />} />
        <Route path="workspace/:workspaceId">
          <Route path="document" element={<WorkspaceDocumentContainer />} />
          <Route
            path="result/:extractionResultId"
            element={<WorkspaceDocumentResultContainer />}
          />
          <Route
            path="integration"
            element={<WorkspaceIntegrationContainer />}
          />
          <Route path="api" element={<WorkspaceApiContainer />} />
          <Route path="setting" element={<WorkspaceSettingContainer />} />
          <Route index element={<Navigate to="document" replace />} />
          <Route path="*" element={<Navigate to="document" replace />} />
        </Route>

        <Route path="oauth" element={<OAuthContainer />} />
        <Route path="sharepoint-auth" element={<SharepointAuthContainer />} />
        <Route path="extractor" element={<ExtractorListContainer />} />
        <Route path="extractor/create" element={<ExtractorCreateContainer />} />

        <Route path="form/:formId/*" element={<FormRoute />} />

        <Route path="form-group/:formGroupId">
          <Route path="edit" element={<FormGroupEditContainer />} />
          {isFormatterEnabled && (
            <Route path="format" element={<FormGroupFormatterContainer />} />
          )}
          <Route path="extract" element={<FormGroupExtractContainer />} />
          <Route path="test" element={<FormGroupTestContainer />} />
          {isV1TestTabEnabled && (
            <Route path="test-v1" element={<FormGroupTestContainerV1 />} />
          )}
          <Route path="setting" element={<FormGroupSettingContainer />} />
          <Route index element={<Navigate to="edit" replace />} />
          <Route path="*" element={<Navigate to="edit" replace />} />
        </Route>

        {isWebhookV1Enabled && (
          <>
            {canCreateResource && (
              <Route path="webhook/new" element={<WebhookCreateContainer />} />
            )}
            {canEditResource && (
              <Route
                path="webhook/:webhookId/edit"
                element={<WebhookEditContainer />}
              />
            )}
            <Route path="webhook" element={<WebhookListContainer />} />
          </>
        )}

        <Route path="detect-documents">
          <Route
            path="extract"
            element={<DetectMultiDocumentExtractContainer />}
          />
          <Route path="test" element={<DetectMultiDocumentTestContainer />} />
          <Route index element={<Navigate to="test" replace />} />
          <Route path="*" element={<Navigate to="test" replace />} />
        </Route>

        <Route path="detect-pii">
          <Route path="test" element={<DetectPIITestContainer />} />
          <Route path="extract" element={<DetectPIIExtractContainer />} />
          <Route index element={<Navigate to="test" replace />} />
          <Route path="*" element={<Navigate to="test" replace />} />
        </Route>

        <Route path="custom-model/:customModelId">
          <Route path="setup" element={<FSLCustomModelEditorContainer />} />
          <Route
            path="edit/advanceMerchantSetup"
            element={<CustomModelAdvanceTokenSetup />}
          />
          <Route index element={<Navigate to="setup" replace />} />
          <Route
            path="instant-model/:customModelImageId"
            element={<FSLInstantSamplEditorContaienr />}
          />
          <Route
            path="instant-model"
            element={<FSLInstantModelViewerContainer />}
          />
          <Route
            path="standard-model"
            element={<FSLStandardModelEditorContainer />}
          />
          {isFormatterEnabled && (
            <Route path="format" element={<CustomModelFormatterContainer />} />
          )}
          <Route path="*" element={<Navigate to="setup" replace />} />
          <Route path="extract" element={<FSLCustomModelExtractContainer />} />
          <Route path="test" element={<FSLCustomModelTestContainer />} />
          {isV1TestTabEnabled && (
            <Route path="test-v1" element={<FSLCustomModelTestContainerV1 />} />
          )}
          <Route path="setting" element={<CustomModelSettingContainer />} />
          {(isCustomModelLabellingEnabled || isCustomModelTrainingEnabled) && (
            <Route path="label" element={<CustomModelLabelContainer />} />
          )}
          {isCustomModelTrainingEnabled && (
            <Route path="model" element={<CustomModelModelContainer />} />
          )}
        </Route>

        <Route path="usage" element={<UsageContainer />} />
        {isCustomPlan ? (
          <Route path="setting" element={<SettingContainer />} />
        ) : (
          <Route path="payment" element={<PaymentContainer />} />
        )}

        {isTeam && hasPermissionToViewMembership && (
          <Route path="team/*" element={<TeamContainer />} />
        )}

        <Route path="invitation" element={<InvitationContainer />} />

        {enabledAuditLog && hasPermissionToViewAuditLog && (
          <Route path="audit-log" element={<AuditLogContainer />} />
        )}
      </Routes>

      <Routes>
        <Route path="payment" element={<></>} />
        {shouldShowPaymentRequiredToast && isPaymentRequired && (
          <Route
            path="*"
            element={
              <PaymentRequiredToastPresenter
                onToastClicked={redirectToPayment}
              />
            }
          />
        )}
      </Routes>

      <Routes>
        <Route path="signup" element={<></>} />
        <Route path="*" element={<UnsupportedBrowserDialog />} />
      </Routes>
    </>
  );
});

export const MainRouter = React.memo(() => {
  const router = React.useMemo(() => {
    return createBrowserRouter(
      createRoutesFromElements(
        <Route
          path="*"
          element={
            <NavigationStackProvider>
              <AppRoute />
            </NavigationStackProvider>
          }
        />
      )
    );
  }, []);

  return <RouterProvider router={router} />;
});
