import {
  Abbyy,
  AzureComputerVision,
  GoogleCloudVision,
  SymbolDetector,
  Tesseract,
  ZBar,
} from "../types/ocrConfig";

export const DEFAULT_TESSERACT_CONFIG = {
  engine: Tesseract as typeof Tesseract,
  param: {
    psm: 7,
    oem: 3,
  },
};

export const DEFAULT_ZBAR_CONFIG = {
  engine: ZBar as typeof ZBar,
  param: {},
};

export const DEFAULT_SYMBOL_DETECTOR_CONFIG = {
  engine: SymbolDetector as typeof SymbolDetector,
  param: {},
};

export const DEFAULT_GOOGLE_CLOUD_VISION_CONFIG = {
  engine: GoogleCloudVision as typeof GoogleCloudVision,
  param: {
    mode: "Printed",
  },
};

export const DEFAULT_AZURE_COMPUTER_VISION_CONFIG = {
  engine: AzureComputerVision as typeof AzureComputerVision,
  param: {
    mode: "Printed",
  },
};

export const DEFAULT_ABBYY_CONFIG = {
  engine: Abbyy as typeof Abbyy,
  param: {},
};
