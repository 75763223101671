import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import * as formAction from "../actions/form";
import * as formGroupAction from "../actions/formGroup";
import * as resourceOwnerAction from "../actions/resourceOwner";
import * as teamAction from "../actions/team";
import * as userAction from "../actions/user";
import * as workspaceAction from "../actions/workspace";
import { BriefWorkspace } from "../types/briefWorkspace";
import { DetailFormGroup } from "../types/formGroup";
import { BriefWorkspaceMapper } from "../types/mappers/workspace";

export interface FormGroupState {
  readonly currentFormGroup: DetailFormGroup | undefined;
}

const defaultState: FormGroupState = {
  currentFormGroup: undefined,
};

export const formGroupReducer = createReducer<FormGroupState>(
  defaultState,
  builder => {
    builder
      .addCase(formGroupAction.FormGroupsInvalidated, state => {
        state.currentFormGroup = undefined;
      })
      .addCase(formAction.FormSaved, (state, action) => {
        const form = action.payload;

        if (state.currentFormGroup) {
          const anchorIndex = state.currentFormGroup.anchors.findIndex(
            x => x.formId === form.id
          );
          const tokenGroupIndex = state.currentFormGroup.tokenGroups.findIndex(
            x => x.formId === form.id
          );

          if (anchorIndex >= 0) {
            state.currentFormGroup.anchors[anchorIndex].form = { ...form };
          }

          if (tokenGroupIndex >= 0) {
            state.currentFormGroup.tokenGroups[tokenGroupIndex].form = {
              ...form,
            };
          }
        }
      })
      .addCase(formAction.FormRemoved, (state, action) => {
        const { formId } = action.payload;

        if (state.currentFormGroup) {
          const anchorIndex = state.currentFormGroup.anchors.findIndex(
            x => x.formId === formId
          );
          const tokenGroupIndex = state.currentFormGroup.tokenGroups.findIndex(
            x => x.formId === formId
          );
          if (anchorIndex >= 0) {
            state.currentFormGroup.anchors.splice(anchorIndex, 1);
          }

          if (tokenGroupIndex >= 0) {
            state.currentFormGroup.tokenGroups.splice(tokenGroupIndex, 1);
          }
        }
      })
      .addCase(teamAction.TeamUserRemoved, (state, action) => {
        const { removedUserId, currentUser } = action.payload;
        if (removedUserId === currentUser.id) {
          return {
            ...defaultState,
          };
        } else {
          return state;
        }
      })
      .addCase(workspaceAction.CreateWorkspaceSuccess, (state, action) => {
        if (
          state.currentFormGroup != null &&
          state.currentFormGroup.id === action.payload.formGroupId
        ) {
          state.currentFormGroup.workspaces = [
            ...state.currentFormGroup.workspaces,
            BriefWorkspaceMapper.fromWorkspace(action.payload.workspace),
          ];
        }
      })
      .addCase(workspaceAction.DeleteWorkspaceSuccess, (state, action) => {
        if (state.currentFormGroup != null) {
          state.currentFormGroup.workspaces =
            state.currentFormGroup.workspaces.filter(
              (w: BriefWorkspace) => w.id !== action.payload.workspaceId
            );
        }
      })
      .addMatcher(
        isAnyOf(formGroupAction.GotFormGroup, formGroupAction.FormGroupUpdated),
        (state, action) => {
          const formGroup = action.payload;
          state.currentFormGroup = formGroup;
        }
      )
      .addMatcher(isAnyOf(userAction.UserLogin, userAction.UserLogout), _ => ({
        ...defaultState,
      }))
      .addMatcher(
        isAnyOf(
          resourceOwnerAction.SelectTeam,
          teamAction.CreateTeam,
          teamAction.TeamInvitationAccepted,
          teamAction.TeamDeleted
        ),
        _state => ({
          ...defaultState,
        })
      );
  }
);
