.nav-bar {
  display: flex;
  align-items: center;
  .breadcrumb {
    margin: 0;
  }
  .button-group {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.tooltip-text {
  font-size: 12px;

  &--highlight {
    color: #0078d4;
    font-size: 12px;
    font-weight: 600;
  }
}
