import * as React from "react";

import { FOCRError } from "../errors";
import { RootState } from "../redux/types";
import { useAppSelector } from "./redux";
import { useToast } from "./toast";

export function useNotifyError(
  selector: (state: RootState) => FOCRError | undefined
) {
  const error = useAppSelector(selector);
  const readyToNotifyError = React.useRef(error === undefined);
  const toast = useToast();
  React.useEffect(() => {
    if (readyToNotifyError.current && error) {
      toast.error(error.messageId, undefined, error.detail);
    }

    if (!readyToNotifyError.current && error === undefined) {
      readyToNotifyError.current = true;
    }
  }, [error, toast]);
}
