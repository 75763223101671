import { FormattedMessage } from "@oursky/react-messageformat";
import { DateTime } from "luxon";
import * as React from "react";
import { useEffect, useState } from "react";

import { useLocale } from "../../contexts/locale";

interface Props {
  labelId?: string;
  baseDate: Date;
}

function _AutoRefreshUpdatedAt(props: Props) {
  const { labelId, baseDate } = props;

  const { localized } = useLocale();

  const convertedBaseDate = React.useMemo(() => {
    return DateTime.fromJSDate(baseDate);
  }, [baseDate]);

  const getAgo = React.useCallback(
    () =>
      convertedBaseDate.diffNow("minutes").minutes < -1
        ? convertedBaseDate.toRelative()
        : localized("auto_refresh_update_at.less_than_one_minute"),
    [convertedBaseDate, localized]
  );

  const [ago, setAgo] = useState<string | null>(getAgo());

  useEffect(() => {
    const timerId = setInterval(() => {
      setAgo(getAgo());
    }, 60 * 1000);
    return () => clearInterval(timerId);
  }, [getAgo]);

  if (ago !== null)
    return (
      <span title={baseDate.toString()}>
        <FormattedMessage
          id={labelId || "auto_refresh_updated_at.label"}
          values={{ ago }}
        />
      </span>
    );
  else return null;
}

export const AutoRefreshUpdatedAt = React.memo(_AutoRefreshUpdatedAt);
export default AutoRefreshUpdatedAt;
