.link-group {
  padding: 7px 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #edebe9;

  .label {
    font-weight: 700;
    font-size: 10px;
    margin-left: 8px;
  }
}

.menu-link {
  &--error {
    color: #a4262c;
    background-color: #fde7e9;

    &:hover {
      color: #a4262c;
    }
  }
}
