@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.text-content {
  padding: 20px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  color: $even-darker-primary-color;
  width: 100%;
  display: block;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #605e5c;
  width: 100%;
  margin-top: 10px;
  display: block;
}

.separator {
  width: 100%;
  border-top: 1px solid #f3f2f1;
}

.navigate-step-content {
  padding: 12px 20px;
}

.step-title {
  font-weight: 400;
  font-size: 14px;
  color: #201f1e;
}

.step-button {
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  background: #faf9f8;
  padding: 8px 16px;
  margin-top: 12px;
  color: #201f1e;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    opacity: 0.7;
  }

  &.disabled,
  &:hover.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.step-arrow {
  font-weight: 700;
}

.buttons {
  padding: 28px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox {
  max-width: 50%;

  :global(.ms-Checkbox-label) {
    align-items: center;
  }
}
