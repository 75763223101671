import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import * as React from "react";

import styles from "./styles.module.scss";

export interface NavTab {
  id?: string;
  key: string;
  labelId: string;
}

interface NavTabBarProps {
  tabs: NavTab[];
  selectedKey?: string;
  onSelect?: (key: string) => void;
}

export function NavTabBar(props: NavTabBarProps) {
  const { tabs, onSelect, selectedKey } = props;

  return (
    <div className={styles["tabs"]}>
      {tabs.map(tab => (
        <div
          key={tab.key}
          id={tab.id ? tab.id : `${tab.key}_tab`}
          className={classnames(styles["tab"], {
            [styles["selected"]]: selectedKey === tab.key,
          })}
          onClick={() => {
            onSelect?.(tab.key);
          }}
        >
          <div className={styles["tab-content"]}>
            <span>
              <FormattedMessage id={tab.labelId} />
            </span>
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
}
