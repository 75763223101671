import produce, { castDraft } from "immer";

import { FOCRError } from "../errors";

// AsyncResult holding the data and state which need to be fetched from server
export type AsyncResult<T> = {
  result: T | undefined;
  isFetching: boolean;
  error: FOCRError | undefined;
};

// PaginatedAsyncResult hold a list of AsyncResult for each page
export type PaginatedAsyncResult<T> = {
  pages: Record<number, AsyncResult<T>>;
  currentPageParams?: {
    page: number;
    filter: string;
    search?: string;
  };
};

export class PaginatedAsyncResultAccessor<T> {
  data: PaginatedAsyncResult<T>;

  constructor(data: PaginatedAsyncResult<T>) {
    this.data = data;
  }

  isSameCurrentPageParams(page: number, filter: string, search?: string) {
    return (
      this.data.currentPageParams?.page === page &&
      this.data.currentPageParams?.filter === filter &&
      this.data.currentPageParams?.search === search
    );
  }

  getCurrentResult() {
    const page = this.data.currentPageParams?.page;
    if (page === undefined) {
      return undefined;
    }
    return this.data.pages[page]?.result;
  }

  getCurrentIsFetching() {
    const page = this.data.currentPageParams?.page;
    if (page === undefined) {
      return false;
    }

    return this.data.pages[page]?.isFetching ?? false;
  }

  getCurrentError() {
    const page = this.data.currentPageParams?.page;
    if (page === undefined) {
      return undefined;
    }

    return this.data.pages[page]?.error;
  }

  getCachedResult(page: number) {
    return this.data.pages[page]?.result;
  }

  setFetchingPage(page: number, filter: string, search?: string) {
    this.data = produce(this.data, draft => {
      const currentFilter = this.data.currentPageParams?.filter ?? "";
      const currentSearch = this.data.currentPageParams?.search ?? "";

      draft.currentPageParams = {
        page,
        filter,
        search,
      };

      if (currentFilter !== filter || currentSearch !== search) {
        draft.pages = {};
      }

      draft.pages[page] = {
        ...draft.pages[page],
        isFetching: true,
        error: undefined,
      };

      return draft;
    });
    return this;
  }

  setSuccessFetchedPaged(page: number, data: T) {
    if (page !== this.data.currentPageParams?.page) {
      return this;
    }

    this.data = produce(this.data, draft => {
      draft.pages[page] = castDraft({
        result: data,
        isFetching: false,
        error: undefined,
      });
    });

    return this;
  }

  setFailedFetchedPaged(page: number, error: FOCRError) {
    if (page !== this.data.currentPageParams?.page) {
      return this;
    }

    this.data = produce(this.data, draft => {
      draft.pages[page] = castDraft({
        result: undefined,
        isFetching: false,
        error,
      });
    });

    return this;
  }

  reset() {
    this.data = produce(this.data, draft => {
      draft.pages = {};
      draft.currentPageParams = undefined;
      return draft;
    });
    return this;
  }
}

export function accessPaginatedAsyncResult<T>(data?: PaginatedAsyncResult<T>) {
  return new PaginatedAsyncResultAccessor<T>(
    data ?? {
      pages: {},
      currentPageParams: undefined,
    }
  );
}
