import { Rectangle } from "@glideapps/glide-data-grid";
import { BaseDrawArgs } from "@glideapps/glide-data-grid/dist/ts/data-grid/cells/cell-types";

import { renderIcon } from "./Utils/Icon";

export function renderErrorState(args: BaseDrawArgs, error: string) {
  const { ctx, theme, rect } = args;
  const drawArea: Rectangle = {
    x: rect.x + theme.cellHorizontalPadding,
    y: rect.y + theme.cellVerticalPadding,
    width: rect.width - 2 * theme.cellHorizontalPadding,
    height: rect.height - 2 * theme.cellVerticalPadding,
  };
  ctx.fillStyle = "#FDE7E9";
  ctx.fillRect(rect.x, rect.y, rect.width, rect.height);

  if (error) {
    renderIcon(
      "error",
      ctx,
      drawArea.x,
      drawArea.y + (drawArea.height - 12),
      12,
      "#A4262C"
    );
  }
}

export function showErrorTooltip() {}
