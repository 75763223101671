import { AddToast } from "react-toast-notifications";

import { ToastOptions } from "../models";

export class ToastController {
  add?: AddToast;
  dismiss?: () => void;

  info(content: string, options?: ToastOptions) {
    if (!this.add) return;
    this.add(content, { appearance: "info", ...options });
  }

  success(content: string, options?: ToastOptions) {
    if (!this.add) return;
    this.add(content, { appearance: "success", ...options });
  }

  warn(content: string, options?: ToastOptions) {
    if (!this.add) return;
    this.add(content, { appearance: "warning", ...options });
  }

  error(content: string, options?: ToastOptions) {
    if (!this.add) return;
    this.add(content, { appearance: "error", ...options });
  }
}

export const toast = new ToastController();
