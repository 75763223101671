import { IconButton } from "@fluentui/react";
import {
  CustomCell,
  CustomRenderer,
  EditableGridCell,
  GridCellKind,
  Item,
  ProvideEditorComponent,
  Rectangle,
  measureTextCached,
} from "@glideapps/glide-data-grid";
import React, { useCallback, useEffect } from "react";

import { TextToken } from "../../../types/advancedTokenSetup/table";
import { CellIcon } from "./CellIcon";
import styles from "./EditCell.module.scss";
import { renderErrorState } from "./ErrorState";
import { renderIcon } from "./Utils/Icon";
import { drawText, truncatStr } from "./Utils/Text";
import { contains } from "./Utils/math";

interface EditCellProps {
  readonly kind: "edit-cell";
  data: TextToken;
  showEmptyCharatorHint?: boolean;
  item: Item;
  onUpdate: (cell: Item, newValue: EditableGridCell) => void;
  clickOnDrawItem: boolean;
  error?: string;
}

export type EditCell = CustomCell<EditCellProps>;

export function isEditCell(cell: CustomCell): cell is EditCell {
  return (cell.data as any).kind === "edit-cell";
}

const EditCellEditor: ProvideEditorComponent<EditCell> = props => {
  const { value, onChange, initialValue = "" } = props;
  const { data, readonly } = value;
  const onToggleRegex = useCallback(() => {
    onChange({
      ...value,
      data: {
        ...value.data,
        data: {
          ...value.data.data,
          isRegex: !value.data.data.isRegex,
        },
      },
    });
  }, [onChange, value]);
  const onToggleClearSpace = useCallback(() => {
    onChange({
      ...value,
      data: {
        ...value.data,
        data: {
          ...value.data.data,
          isClearSpace: !value.data.data.isClearSpace,
        },
      },
    });
  }, [onChange, value]);
  const onToggleExactMatch = useCallback(() => {
    onChange({
      ...value,
      data: {
        ...value.data,
        data: {
          ...value.data.data,
          isExactMatch: !value.data.data.isExactMatch,
        },
      },
    });
  }, [onChange, value]);
  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...value,
        data: {
          ...value.data,
          data: {
            ...value.data.data,
            pattern: e.target.value,
          },
        },
      });
    },
    [onChange, value]
  );
  useEffect(() => {
    onChange({
      ...value,
      data: {
        ...value.data,
        data: {
          ...value.data.data,
          pattern: value.data.data.pattern + initialValue,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles["editor"]}>
      <input
        name="input"
        type="text"
        value={data.data.pattern}
        onChange={onTextChange}
        onInput={onTextChange}
        autoFocus
        readOnly={readonly}
      />
      {data.data.isRegex !== undefined && (
        <IconButton
          style={{ width: 20, height: 20 }}
          styles={{
            icon: {
              fontSize: 12,
            },
          }}
          iconProps={{
            iconName: "Regex",
            styles: {
              root: {
                selectors: {
                  path: {
                    fill: data.data.isRegex ? "#0078D4" : "#A19F9D",
                  },
                },
              },
            },
          }}
          onClick={readonly ? undefined : onToggleRegex}
        />
      )}
      {data.data.isClearSpace !== undefined && (
        <IconButton
          style={{ width: 20, height: 20 }}
          styles={{
            icon: {
              color: data.data.isClearSpace ? "#0078D4" : "#A19F9D",
              fontSize: 12,
            },
          }}
          iconProps={{ iconName: "EraseTool" }}
          onClick={readonly ? undefined : onToggleClearSpace}
        />
      )}
      {data.data.isExactMatch !== undefined && (
        <IconButton
          style={{ width: 20, height: 20 }}
          styles={{
            icon: {
              color: data.data.isExactMatch ? "#0078D4" : "#A19F9D",
              fontSize: 12,
            },
          }}
          iconProps={{ iconName: "Quantity" }}
          onClick={readonly ? undefined : onToggleExactMatch}
        />
      )}
    </div>
  );
};

type EditCellIcon = CellIcon<EditCell>;

const textMarginRight = 8;
const iconMarginRight = 8;
const lastIconMarginRight = 0;
export const icons: EditCellIcon[] = [
  {
    icon: "quantity",
    isActived: (cell: EditCell) => cell.data.data.isExactMatch === true,
    getDrawingBox: (cell: EditCell, bounds: Rectangle) => {
      const bbox: Rectangle = { x: 0, y: 0, width: 12, height: 12 };
      if (cell.data.data.isExactMatch !== undefined) {
        return {
          x: bounds.x + bounds.width - bbox.width - lastIconMarginRight,
          y: bounds.y + (bounds.height - bbox.height) / 2,
          width: bbox.width,
          height: bbox.height,
        };
      }
      return undefined;
    },
    onClick: (cell: EditCell) => ({
      ...cell,
      data: {
        ...cell.data,
        data: {
          ...cell.data.data,
          isExactMatch: !cell.data.data.isExactMatch,
        },
      },
    }),
  },
  {
    icon: "erase",
    isActived: (cell: EditCell) => cell.data.data.isClearSpace === true,
    getDrawingBox: (cell: EditCell, bounds: Rectangle) => {
      const bbox: Rectangle = { x: 0, y: 0, width: 12, height: 12 };
      if (cell.data.data.isClearSpace !== undefined) {
        const rightIcon = icons[0].getDrawingBox(cell, bounds);
        return {
          x:
            bounds.x +
            bounds.width -
            bbox.width -
            iconMarginRight -
            (rightIcon ? rightIcon.width + lastIconMarginRight : 0),
          y: bounds.y + (bounds.height - bbox.height) / 2,
          width: bbox.width,
          height: bbox.height,
        };
      }
      return undefined;
    },
    onClick: (cell: EditCell) => ({
      ...cell,
      data: {
        ...cell.data,
        data: {
          ...cell.data.data,
          isClearSpace: !cell.data.data.isClearSpace,
        },
      },
    }),
  },
  {
    icon: "regex",
    isActived: (cell: EditCell) => cell.data.data.isRegex === true,
    getDrawingBox: (cell: EditCell, bounds: Rectangle) => {
      const bbox: Rectangle = { x: 0, y: 0, width: 16, height: 16 };
      if (cell.data.data.isRegex !== undefined) {
        const rightIcon1 = icons[0].getDrawingBox(cell, bounds);
        const rightIcon2 = icons[1].getDrawingBox(cell, bounds);
        return {
          x:
            bounds.x +
            bounds.width -
            bbox.width -
            iconMarginRight -
            (rightIcon2 ? rightIcon2.width + iconMarginRight : 0) -
            (rightIcon1 ? rightIcon1.width + lastIconMarginRight : 0),
          y: bounds.y + (bounds.height - bbox.height) / 2,
          width: bbox.width,
          height: bbox.height,
        };
      }
      return undefined;
    },
    onClick: (cell: EditCell) => ({
      ...cell,
      data: {
        ...cell.data,
        data: {
          ...cell.data.data,
          isRegex: !cell.data.data.isRegex,
        },
      },
    }),
  },
];

function parseEmptyCharGroups(text: string): { start: number; end: number }[] {
  let groups: { start: number; end: number }[] = [];
  let index = 0;
  let prevChar: string = "";
  let start: number = -1;
  for (const i of text) {
    if (prevChar !== " " && i === " ") {
      start = index;
    }
    if (prevChar === " " && i !== " ") {
      groups = groups.concat([
        {
          start,
          end: index,
        },
      ]);
      start = -1;
    }
    prevChar = i;
    index++;
  }
  if (start >= 0) {
    groups = groups.concat([
      {
        start,
        end: index,
      },
    ]);
  }
  return groups;
}

export const EditCellRenderer: CustomRenderer<EditCell> = {
  kind: GridCellKind.Custom,
  isMatch: isEditCell,
  draw: (args, cell) => {
    const { ctx, theme, rect } = args;
    const { data, error, showEmptyCharatorHint = true } = cell.data;

    let textOffset = 0;
    if (error) {
      renderErrorState(args, error);
      textOffset += 20;
    }
    const drawArea: Rectangle = {
      x: rect.x + theme.cellHorizontalPadding,
      y: rect.y + theme.cellVerticalPadding,
      width: rect.width - 2 * theme.cellHorizontalPadding,
      height: rect.height - 2 * theme.cellVerticalPadding,
    };
    const mostLeftIconPosX = icons
      .map(icon => icon.getDrawingBox(cell, drawArea))
      .map(b => b?.x ?? 0)
      .filter(w => w > 0)
      .sort()[0];

    const iconGroupWidth = drawArea.width - (mostLeftIconPosX - drawArea.x);
    const emptyHintChar = "·";

    // NOTE: we use "*" charator to approximate the size of "·" in bold font style
    ctx.font = `12px ${theme.fontFamily}`;
    const [displayText, truncatedAt] = truncatStr(
      showEmptyCharatorHint ? data.pattern.replaceAll(" ", "*") : data.pattern,
      ctx,
      drawArea.width - iconGroupWidth - textMarginRight
    );
    if (showEmptyCharatorHint) {
      let displayIndex = 0;
      let displayOffset = textOffset + drawArea.x;
      const emptyCharGroups = parseEmptyCharGroups(
        data.pattern.substring(0, truncatedAt)
      );
      for (const group of emptyCharGroups) {
        const substr = displayText.substring(displayIndex, group.start);
        drawText(
          ctx,
          substr,
          displayOffset,
          drawArea.y + drawArea.height / 2,
          `12px ${theme.fontFamily}`
        );
        displayOffset += measureTextCached(substr, ctx).width;
        const emptyHintStr = new Array(group.end - group.start)
          .fill(emptyHintChar)
          .join("");

        drawText(
          ctx,
          emptyHintStr,
          displayOffset,
          drawArea.y + drawArea.height / 2,
          `700 16px ${theme.fontFamily}`,
          "#0078D4"
        );
        displayOffset += measureTextCached(emptyHintStr, ctx).width;
        displayIndex = group.end;
      }
      drawText(
        ctx,
        displayText.substring(displayIndex),
        displayOffset,
        drawArea.y + drawArea.height / 2,
        `12px ${theme.fontFamily}`
      );
    } else {
      drawText(
        ctx,
        displayText,
        textOffset + drawArea.x,
        drawArea.y + drawArea.height / 2,
        `12px ${theme.fontFamily}`
      );
    }

    for (const item of icons) {
      const drawingBox = item.getDrawingBox(cell, drawArea);
      if (drawingBox) {
        renderIcon(
          item.icon,
          ctx,
          drawingBox.x,
          drawingBox.y,
          drawingBox.width,
          item.isActived(cell) ? "#0078D4" : "#A19F9D"
        );
      }
    }
  },
  provideEditor: () => {
    return EditCellEditor;
  },
  onClick: args => {
    const { preventDefault, cell, posX, posY, bounds, theme } = args;
    if (!cell.data.clickOnDrawItem) {
      return;
    }

    const drawArea: Rectangle = {
      x: bounds.x + theme.cellHorizontalPadding,
      y: bounds.y + theme.cellVerticalPadding,
      width: bounds.width - 2 * theme.cellHorizontalPadding,
      height: bounds.height - 2 * theme.cellVerticalPadding,
    };

    const [posXInDrawingCoord, posYInDrawingCord] = [
      bounds.x + posX,
      bounds.y + posY,
    ];
    for (const item of icons) {
      const drawingBox = item.getDrawingBox(cell, drawArea);
      if (
        !cell.readonly &&
        drawingBox &&
        contains(
          [drawingBox.x, drawingBox.y, drawingBox.width, drawingBox.height],
          posXInDrawingCoord,
          posYInDrawingCord
        )
      ) {
        const newCell = item.onClick(cell);
        preventDefault();
        return newCell;
      }
    }
    return;
  },
};
