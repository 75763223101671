.bottomBar {
  border-top: 1px solid #edebe9;
  padding: 10px 20px 20px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.pagination {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.pageNumber {
  color: #323130;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.paginationButtonGroup {
  display: flex;
  gap: 8px;
}

.paginationButton {
  width: 32px;
  height: 32px;
}

.actionButtonGroup {
  display: flex;
  gap: 8px;
}

.processing {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.processingText {
  color: var(--type-colors-primary, #323130);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
