import { ILayerProps, IconButton, Modal, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { CustomModelImage } from "../../types/customModelImage";
import { Img } from "../Img";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

function getImageIndexByID(images: CustomModelImage[], id: string): number {
  return images.findIndex(x => x.id === id);
}

interface HeaderProps {
  onDismiss: () => void;
  onClickLabellerButton?: () => void;
}

const Header = (props: HeaderProps) => {
  const { onDismiss, onClickLabellerButton } = props;

  return (
    <div className={styles["header"]}>
      <Text variant="mediumPlus" className={styles["title"]}>
        <FormattedMessage id="custom_model_editor.lightbox_modal.header.title" />
      </Text>
      {onClickLabellerButton ? (
        <DefaultButton
          className={styles["labeller-button"]}
          textId="custom_model_editor.lightbox_modal.header.open_in_labeller_button"
          onClick={onClickLabellerButton}
        />
      ) : null}
      <IconButton
        className={styles["close-button"]}
        iconProps={{ iconName: "Cancel" }}
        onClick={onDismiss}
      />
    </div>
  );
};

interface ImagePreviewProps {
  imageURL: string;
  imageUploadAt: Date;
}

const ImagePreview = (props: ImagePreviewProps) => {
  const { imageURL, imageUploadAt } = props;

  return (
    <div className={styles["image-preview-container"]}>
      <Img alt="preview" src={imageURL} frameClass={styles["image"]} />
      <div className={styles["footer"]}>
        <Text variant="smallPlus">
          <FormattedMessage
            id="custom_model_editor.lightbox_modal.footer.upload_at"
            values={{ date: imageUploadAt.toLocaleDateString() }}
          />
        </Text>
      </div>
    </div>
  );
};

interface Props {
  images: CustomModelImage[];
  imageID?: string;
  onDismiss: () => void;
  onOpenLabeller?: (imageId: string) => void;
}

function useKeyboardNavigation(
  selectedImageIndex: number | undefined,
  totalImageInPage: number
) {
  const [displayImageIndex, setDisplayImageIndex] =
    useState(selectedImageIndex);

  const totalImageInPageRef = useRef(totalImageInPage);
  totalImageInPageRef.current = totalImageInPage;

  const onKeydown = useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      case "ArrowLeft":
      case "PageUp":
        setDisplayImageIndex(curIndex =>
          curIndex !== undefined ? Math.max(curIndex - 1, 0) : undefined
        );
        break;
      case "ArrowRight":
      case "PageDown":
        setDisplayImageIndex(curIndex =>
          curIndex !== undefined
            ? Math.min(curIndex + 1, totalImageInPageRef.current - 1)
            : undefined
        );
        break;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", onKeydown);

    return () => {
      window.removeEventListener("keydown", onKeydown);
    };
  }, [onKeydown]);

  useEffect(() => {
    setDisplayImageIndex(selectedImageIndex);
  }, [selectedImageIndex]);

  return useMemo(
    () => ({
      displayImageIndex,
    }),
    [displayImageIndex]
  );
}

const ModalLayerProps: ILayerProps = { eventBubblingEnabled: true };

const CustomModelImageLightboxModal = (props: Props) => {
  const { images, imageID, onDismiss, onOpenLabeller } = props;

  const selectedImageIndex = useMemo(() => {
    if (imageID === undefined) {
      return undefined;
    }
    const index = getImageIndexByID(images, imageID);
    return index < 0 ? undefined : index;
  }, [imageID, images]);

  const { displayImageIndex } = useKeyboardNavigation(
    selectedImageIndex,
    images.length
  );

  const openLabeller = useCallback(() => {
    if (displayImageIndex !== undefined && onOpenLabeller) {
      onOpenLabeller(images[displayImageIndex].id);
    }
  }, [displayImageIndex, images, onOpenLabeller]);

  if (displayImageIndex === undefined) {
    return null;
  }

  return (
    <Modal
      layerProps={ModalLayerProps}
      isOpen={true}
      onDismiss={onDismiss}
      containerClassName={styles["container"]}
    >
      <Header
        onClickLabellerButton={
          onOpenLabeller !== undefined ? openLabeller : undefined
        }
        onDismiss={onDismiss}
      />
      <ImagePreview
        imageURL={images[displayImageIndex].url}
        imageUploadAt={images[displayImageIndex].updatedAt}
      />
    </Modal>
  );
};

export default CustomModelImageLightboxModal;
