import {
  ChoiceGroup,
  Dialog,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  Label,
  Text,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import { CustomModelPreset } from "../../types/customModelPreset";
import { PrebuiltTemplatedExtractor } from "../../types/extractor";
import { FormGroupType } from "../../types/formGroup";
import { DefaultButton, PrimaryButton } from "../WrappedMSComponents/Buttons";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

export type ExtractorType = "form" | "form_group" | "custom_model";

export enum ExtractorCreateModalResponseType {
  Accepted,
  Cancelled,
}

export interface ExtractorCreateModalResult {
  name: string;
  type: ExtractorType | PrebuiltTemplatedExtractor;
  formGroupType?: FormGroupType;
  selectedPrebuiltCustomModel?: CustomModelPreset;
}

export interface ExtractorCreateModalResponse {
  type: ExtractorCreateModalResponseType;
  accpetedValue?: ExtractorCreateModalResult;
}

export function useExtractorCreateModalHandle() {
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

  const [callback, setCallback] =
    React.useState<
      (response: ExtractorCreateModalResponse) => void | undefined
    >();
  const [type, setStype] = React.useState<
    ExtractorType | PrebuiltTemplatedExtractor
  >("form");
  const [nameDefaultValue, setNameDefaultValue] = React.useState<
    string | undefined
  >(undefined);

  const open = React.useCallback(
    async (
      type: ExtractorType | PrebuiltTemplatedExtractor,
      nameDefaultValue?: string
    ): Promise<ExtractorCreateModalResponse> => {
      setIsDialogOpen(true);
      setStype(type);
      setNameDefaultValue(nameDefaultValue);

      return new Promise(resolve => {
        setCallback(() => resolve);
      });
    },
    [setIsDialogOpen, setCallback]
  );

  const close = React.useCallback(() => {
    setIsDialogOpen(false);
    callback?.({
      type: ExtractorCreateModalResponseType.Cancelled,
    });
    setCallback(undefined);
  }, [setIsDialogOpen, setCallback, callback]);

  const confirm = React.useCallback(
    (
      name: string,
      type: ExtractorType | PrebuiltTemplatedExtractor,
      formGroupType?: FormGroupType,
      selectedPrebuiltCustomModel?: CustomModelPreset
    ) => {
      callback?.({
        type: ExtractorCreateModalResponseType.Accepted,
        accpetedValue: {
          name,
          type,
          formGroupType,
          selectedPrebuiltCustomModel,
        },
      });
      setCallback(undefined);
      setIsDialogOpen(false);
    },
    [callback, setCallback]
  );

  const props = React.useMemo(() => {
    return {
      isDialogOpen,
      close,
      confirm,
      type,
      nameDefaultValue,
    };
  }, [isDialogOpen, close, confirm, type, nameDefaultValue]);

  const methods = React.useMemo(
    () => ({
      open,
    }),
    [open]
  );

  return React.useMemo(() => {
    return {
      props,
      methods,
    };
  }, [props, methods]);
}

function extractorLocalizationKey(
  type: ExtractorType | PrebuiltTemplatedExtractor
) {
  switch (type) {
    case "form":
      return "extractor.fixed_layout_extractor";
    case "form_group":
      return "extractor.form_group";
    case "custom_model":
      return "extractor.custom_model";
    default:
      return "extractor.prebuilt." + type;
  }
}
const DialogContentProps = {
  type: DialogType.normal,
  title: (
    <span className={styles["header"]}>
      <FormattedMessage id="extractor.create_modal.title" />
    </span>
  ),
};

export function ExtractorCreateModal(
  props: ReturnType<typeof useExtractorCreateModalHandle>["props"]
) {
  const { type, isDialogOpen, close, confirm, nameDefaultValue } = props;
  const { localized } = useLocale();

  const [name, setName] = React.useState(
    nameDefaultValue ?? localized(extractorLocalizationKey(type))
  );
  React.useEffect(() => {
    setName(nameDefaultValue ?? localized(extractorLocalizationKey(type)));
  }, [localized, type, nameDefaultValue]);

  const [formGroupType, setFormGroupType] =
    React.useState<FormGroupType>("document_detection");

  const confirmEnabled = name.trim() !== "";

  const onNameChange = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value) {
        setName(value as string);
      } else {
        setName("");
      }
    },
    []
  );

  const onSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (!name) {
        return;
      }

      if (type === "form_group") {
        confirm(name, type, formGroupType);
      } else {
        confirm(name, type, undefined);
      }
      close();
    },
    [type, close, confirm, name, formGroupType]
  );

  interface FormGroupTypeOption extends IChoiceGroupOption {
    key: FormGroupType;
  }

  const generateFormGroupTypeOption = React.useMemo(
    () =>
      (formGroupType: FormGroupType): FormGroupTypeOption => {
        return {
          key: formGroupType,
          text: "",
          onRenderLabel: () => (
            <div className={styles["form-group-type-option"]}>
              <div className={styles["form-group-type-option-title"]}>
                <Text>
                  <FormattedMessage
                    id={`create_form_group.form_group_mode.${formGroupType}.title`}
                  />
                </Text>
              </div>
              <div>
                <Text>
                  <FormattedMessage
                    id={`create_form_group.form_group_mode.${formGroupType}.desc`}
                  />
                </Text>
              </div>
            </div>
          ),
        };
      },
    []
  );

  const formGroupTypeOptions = React.useMemo(
    (): FormGroupTypeOption[] =>
      (["document_detection", "anchor", "token"] as FormGroupType[]).map(t =>
        generateFormGroupTypeOption(t)
      ),
    [generateFormGroupTypeOption]
  );

  const onFormGroupTypeChange = React.useCallback(
    (
      _?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      if (option !== undefined) {
        setFormGroupType((option as FormGroupTypeOption).key);
      }
    },
    []
  );

  return (
    <Dialog
      minWidth={464}
      hidden={!isDialogOpen}
      dialogContentProps={DialogContentProps}
      modalProps={{
        isBlocking: false,
      }}
    >
      <form onSubmit={onSubmit}>
        <div className={styles["extractor-type"]}>
          <Label>
            <FormattedMessage id="extractor.create_modal.type" />
          </Label>
          <Text>
            <FormattedMessage id={extractorLocalizationKey(type)} />
          </Text>
        </div>
        <TextField
          labelId={"extractor.create_modal.title"}
          value={name}
          onChange={onNameChange}
        />

        {type === "form_group" && (
          <>
            <Label className={styles["form-group-mode-title"]}>
              <FormattedMessage id="create_form_group.form_group_mode" />
            </Label>
            <Text className={styles["form-group-mode-desc"]}>
              <FormattedMessage id="create_form_group.form_group_mode.desc" />
            </Text>
            <ChoiceGroup
              className={styles["form-group-mode-choice-group"]}
              options={formGroupTypeOptions}
              selectedKey={formGroupType}
              onChange={onFormGroupTypeChange}
            />
          </>
        )}

        <DialogFooter className={styles["footer"]}>
          <DefaultButton onClick={close} textId={"common.cancel"} />

          <PrimaryButton
            type="submit"
            textId={"common.create"}
            disabled={!confirmEnabled}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
}
