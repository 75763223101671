import { Icon, IconButton } from "@fluentui/react";
import cn from "classnames";
import React from "react";

import styles from "./styles.module.scss";

export interface IconTagProps {
  iconName?: string;
  text: string;
  onDismiss?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  hasError?: boolean;
}

const MAX_CHARACTER_COUNT = 30;

export function IconTag(props: IconTagProps) {
  const { iconName, text, onDismiss, hasError } = props;

  const tagText =
    text.length > MAX_CHARACTER_COUNT
      ? text.substring(0, MAX_CHARACTER_COUNT) + "..."
      : text;

  return (
    <span
      className={cn(styles["container"], {
        [styles["container-error"]]: hasError,
      })}
    >
      <span className={styles["icon-label-group"]}>
        {iconName && (
          <span
            className={cn(styles["icon"], { [styles["icon-error"]]: hasError })}
          >
            <Icon iconName={iconName} />
          </span>
        )}
        <span className={styles["label"]}>{tagText}</span>
      </span>
      <IconButton
        onClick={onDismiss}
        iconProps={{ iconName: "Cancel" }}
        className={cn(styles["cancel-button"], {
          [styles["icon-error"]]: hasError,
        })}
      />
    </span>
  );
}
