import cntl from "cntl";
import * as React from "react";

type Props = {
  children: React.ReactNode;
  sizeConstraint?: string;
  footer?: React.ReactNode;
};

export function Content(props: Props) {
  const { sizeConstraint } = props;

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div
        className={cntl`
                  ${sizeConstraint}
                  max-h-[calc(100%-48px)]
                   card flex flex-col justify-center items-center
                   m-[48px]
              `}
      >
        <div className="flex-1 max-w-full overflow-x-hidden overflow-y-auto flex flex-col max-h-full">
          {props.children}
          {props.footer && (
            <div className="h-[40px] mb-[48px] w-full">
              <div className="flex flex-row justify-center items-center gap-[16px]">
                {props.footer}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
