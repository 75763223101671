import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import * as resourceOwnerAction from "../actions/resourceOwner";
import * as teamAction from "../actions/team";
import * as userAction from "../actions/user";
import * as webhookAction from "../actions/webhook";
import { Webhook } from "../types/webhook";

export interface WebhookState {
  readonly webhooks: Webhook[];
  readonly isFetching: boolean;
  readonly currentWebhook?: Webhook;
}

const defaultState: WebhookState = {
  webhooks: [],
  isFetching: false,
};

export const webhookReducer = createReducer<WebhookState>(
  defaultState,
  builder => {
    builder
      .addCase(webhookAction.StartedFetchingWebhookList, state => {
        state.isFetching = true;
      })
      .addCase(webhookAction.GotWebhookList, (state, action) => {
        state.currentWebhook = undefined;
        state.webhooks = action.payload;
        state.isFetching = false;
      })
      .addCase(webhookAction.WebhookCreated, (state, action) => {
        state.currentWebhook = action.payload;
        state.webhooks.push(action.payload);
      })
      .addCase(webhookAction.GotWebhook, (state, action) => {
        state.currentWebhook = action.payload;
      })
      .addCase(webhookAction.WebhookUpdated, (state, action) => {
        const webhook = action.payload;
        const indexOfCurrentWebhook = state.webhooks.findIndex(
          w => w.id === webhook.id
        );

        if (indexOfCurrentWebhook === -1) {
          state.webhooks.push(webhook);
        } else {
          state.webhooks[indexOfCurrentWebhook] = webhook;
        }
      })
      .addCase(webhookAction.WebhookRemoved, (state, action) => {
        const { webhookId } = action.payload;
        if (state.currentWebhook?.id === webhookId) {
          state.currentWebhook = undefined;
        }
        state.webhooks = state.webhooks.filter(w => w.id !== webhookId);
      })
      .addCase(teamAction.TeamUserRemoved, (state, action) => {
        const { removedUserId, currentUser } = action.payload;
        if (removedUserId === currentUser.id) {
          return {
            ...defaultState,
          };
        } else {
          return state;
        }
      })
      .addMatcher(
        isAnyOf(
          teamAction.TeamDeleted,
          teamAction.CreateTeam,
          teamAction.TeamInvitationAccepted,
          userAction.UserLogin,
          userAction.UserLogout,
          resourceOwnerAction.SelectTeam
        ),
        _ => ({ ...defaultState })
      );
  }
);
