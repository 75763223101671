import { Dropdown, IDropdownProps, Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

type ExtractorTypesDropdownProps = Pick<
  IDropdownProps,
  "options" | "onChange" | "selectedKeys"
> & {
  dropdownClassName?: string;
  msDropdownClassName?: string;
  label?: React.ReactNode | string;
  placeholder?: string;
};
export function ExtractorTypesDropdown(props: ExtractorTypesDropdownProps) {
  const {
    options,
    onChange,
    dropdownClassName,
    msDropdownClassName,
    placeholder,
    label,
    ...otherProps
  } = props;

  const { localized } = useLocale();

  return (
    <div className={classnames(styles["dropdown"], dropdownClassName)}>
      <Label>
        {label ?? <FormattedMessage id="extractor.list.filter.label" />}
      </Label>
      <Dropdown
        onRenderTitle={options => (
          <div>
            {options ? (
              options?.length === 1 ? (
                options[0].text
              ) : (
                `${options.length} ${localized("extractor.list.filter.types")}`
              )
            ) : (
              <FormattedMessage id="extractor.list.filter.all" />
            )}
          </div>
        )}
        placeholder={placeholder ?? localized("extractor.list.filter.all")}
        multiSelect
        options={options}
        className={classnames(styles["ms-dropdown"], msDropdownClassName)}
        onChange={onChange}
        {...otherProps}
      />
    </div>
  );
}
