%title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

.custom-model-config {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  .model-id-title {
    @extend %title;
  }

  .model-id-value {
    font-size: 14px;
  }

  .replace-with-field-title {
    @extend %title;
    margin-top: 16px;
  }
  .change-date-format-dropdown {
    margin-top: 12px;
  }
}
