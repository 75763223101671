import { IconButton as FluentIconButton, IButtonProps } from "@fluentui/react";
import * as React from "react";

import cssStyles from "./styles.module.scss";

export enum IconButtonStyleType {
  Normal,
}

type Props = IButtonProps & {
  iconName?: string;
  styleType?: IconButtonStyleType;
};

export function IconButton(props: Props) {
  const { iconProps, styles, className } = React.useMemo(() => {
    // If more styles are needed, add them here

    return {
      className: cssStyles["normal-icon-button"],
      iconProps: {
        iconName: props.iconName,
      },
      styles: {
        icon: {
          color: "#323130",
        },
      },
    };
  }, [props.iconName]);

  return (
    <FluentIconButton
      className={className}
      iconProps={iconProps}
      styles={styles}
      {...props}
    />
  );
}
