@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.checkbox {
  margin-top: 10px;
}

.separator {
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.section-header {
  color: #25d0b1;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 16px;
}

.feature-flags-box {
  padding: 16px 16px 24px 16px;
  border: 1px solid var(--border-colors-divider, #edebe9);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
