import * as React from "react";
import { useCallback } from "react";

import { useGoogleSheetActionCreator } from "../actions/googleSheet";
import type {
  GoogleSheet,
  GoogleSheetFieldMapping,
  GoogleSpreadsheet,
} from "../types/googleSheet";
import { useAppSelector } from "./redux";

export function useGoogleSheet() {
  const {
    clearSheetData,
    createWorkspaceIntegration,
    listSheetColumns,
    listSpreadsheets,
  } = useGoogleSheetActionCreator();

  const { sheets, spreadsheets } = useAppSelector(state => state.googleSheet);

  const [isFetchingGoogleSheets, setIsFetchingGoogleSheets] =
    React.useState<boolean>(false);
  const [isFetchingGoogleSpreadsheets, setIsFetchingGoogleSpreadsheets] =
    React.useState<boolean>(false);
  const [isSavingIntegration, setIsSavingIntegration] =
    React.useState<boolean>(false);

  const createWorkspaceGoogleSheetExport = useCallback(
    async (
      worksapceId: string,
      oauthCredentialId: string,
      spreadsheetId: string,
      sheetId: string | null,
      mappings: GoogleSheetFieldMapping[],
      isUpsertMode: boolean
    ) => {
      setIsSavingIntegration(true);
      try {
        const response = await createWorkspaceIntegration(
          worksapceId,
          oauthCredentialId,
          spreadsheetId,
          sheetId,
          mappings,
          isUpsertMode
        );
        return response;
      } catch (error) {
        throw error;
      } finally {
        setIsSavingIntegration(false);
      }
    },
    [createWorkspaceIntegration]
  );

  const getSheetColumnsBySheetId = useCallback(
    (spreadsheetId: string, sheetId: string): string[] | null => {
      if (spreadsheetId in sheets && sheetId in sheets[spreadsheetId]) {
        return sheets[spreadsheetId][sheetId].columns;
      }
      return null;
    },
    [sheets]
  );

  const getSheetsBySpreadsheetId = useCallback(
    (spreadsheetId: string): GoogleSheet[] | null => {
      if (spreadsheetId in sheets) {
        return Object.values(sheets[spreadsheetId]);
      }
      return null;
    },
    [sheets]
  );

  const getSpreadsheetBySpreadsheetId = useCallback(
    (
      oauthCredentialId: string,
      spreadsheetId: string
    ): GoogleSpreadsheet | null => {
      if (oauthCredentialId in spreadsheets) {
        return (
          spreadsheets[oauthCredentialId].find(
            ({ id }) => id === spreadsheetId
          ) ?? null
        );
      }
      return null;
    },
    [spreadsheets]
  );

  const getSheetList = useCallback(
    async (oauthCredentialId: string, spreadsheetId: string) => {
      setIsFetchingGoogleSheets(true);
      await listSheetColumns(oauthCredentialId, spreadsheetId);
      setIsFetchingGoogleSheets(false);
    },
    [listSheetColumns]
  );

  const getSpreadsheetList = useCallback(
    async (oauthCredentialId: string, query: string | null) => {
      if (query) {
        await listSpreadsheets(oauthCredentialId, query);
      } else {
        setIsFetchingGoogleSpreadsheets(true);
        await listSpreadsheets(oauthCredentialId, null);
        setIsFetchingGoogleSpreadsheets(false);
      }
    },
    [listSpreadsheets]
  );

  const getSpreadsheetListByOAuthCredentialId = useCallback(
    (oauthCredentialId: string): GoogleSpreadsheet[] | null => {
      if (oauthCredentialId in spreadsheets) {
        return spreadsheets[oauthCredentialId];
      }
      return null;
    },
    [spreadsheets]
  );

  return React.useMemo(
    () => ({
      isFetchingGoogleSheets,
      isFetchingGoogleSpreadsheets,
      isSavingWorkspaceGoogleSheetExportIntegration: isSavingIntegration,
      sheets,
      spreadsheets,
      clearSheetData,
      createWorkspaceGoogleSheetExport,
      getSheetColumnsBySheetId,
      getSheetList,
      getSheetsBySpreadsheetId,
      getSpreadsheetBySpreadsheetId,
      getSpreadsheetList,
      getSpreadsheetListByOAuthCredentialId,
    }),
    [
      isFetchingGoogleSheets,
      isFetchingGoogleSpreadsheets,
      isSavingIntegration,
      sheets,
      spreadsheets,
      clearSheetData,
      createWorkspaceGoogleSheetExport,
      getSheetColumnsBySheetId,
      getSheetList,
      getSheetsBySpreadsheetId,
      getSpreadsheetBySpreadsheetId,
      getSpreadsheetList,
      getSpreadsheetListByOAuthCredentialId,
    ]
  );
}
