.data-item {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
  background: #faf9f8;
  border: 1px solid #edebe9;
  border-radius: 4px;
}

.data-item-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #323130;

  opacity: 0.5;
  min-width: 190px;
  padding-top: 6px;
}

.data-item-value {
  word-break: break-word;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #201f1e;
  padding: 8px 6px;
  flex-grow: 1;
  min-height: 36px;
  white-space: pre-wrap;
}

.data-item-value-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  gap: 10px;
}

.data-item-value-image-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.data-item-value-image-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.data-item-value-image {
  background: #fff;
  border: 1px solid #edebe9;
  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    max-width: 100px;
    max-height: 100px;
  }
}
.data-item-list-value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #201f1e;
  flex-grow: 1;
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 4px;
  background-color: #ffffff;
  margin-top: 6px;
}

.data-item-list-value-row-bullet::before {
  content: " • ";
}

.data-item-list-value-row-bullet {
  border-right: #faf9f8 solid 2px;
  padding: 8px 6px;
}

.data-item-list-value-row-content {
  padding: 8px 6px;
  background-color: #ffffff;
}

.data-item-list-value-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: flex-start;
  gap: 8px;
}

.data-item-list-value-actions {
  padding-top: 6px;
}
.raw-json-viewer {
  white-space: pre-wrap;
}

.data-item-editing-list-value {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.data-item-editing-list-value-item {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.data-item :global(.ms-TextField) {
  flex: 1;
}
