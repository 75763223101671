import * as React from "react";
import { useSelector } from "react-redux";

import { useTeamActionCreator } from "../actions/team";
import { AppConfig, RegionsConfig } from "../config";
import { RootState } from "../redux/types";
import { TeamRef } from "../types/team";

export function useTeamRefsByRegion() {
  const teams = useSelector(
    (state: RootState) => state.user.currentUser?.teams ?? []
  );

  const isTeamRefsByRegionFetchedOnce = useSelector(
    (state: RootState) =>
      state.user.regionsWithTeamRefsFetched.length ===
      Object.keys(RegionsConfig.endpoints).length
  );
  const endPointTeamRefs = useSelector(
    (state: RootState) => state.user.teamRefsByRegion
  );
  const { listTeamRefs } = useTeamActionCreator();
  React.useEffect(() => {
    if (isTeamRefsByRegionFetchedOnce) {
      return;
    }
    const regions = Object.keys(RegionsConfig.endpoints).filter(
      key => key !== AppConfig.region
    );
    if (regions.length === Object.keys(endPointTeamRefs).length) {
      return;
    }
    regions.forEach(region => {
      listTeamRefs(region).catch(() => {});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const teamRefsByRegion = React.useMemo(() => {
    const currentRegionTeamRefs = teams.map(team => {
      return {
        name: team.name,
        lookupId: team.lookupId,
      } as TeamRef;
    });
    return {
      ...endPointTeamRefs,
      [AppConfig.region]: currentRegionTeamRefs,
    };
  }, [teams, endPointTeamRefs]);

  const isUserHasTeamInOtherRegions = useSelector(
    (state: RootState) =>
      Object.keys(state.user.teamRefsByRegion).find(
        x => x !== AppConfig.region
      ) !== undefined
  );

  const isNewUser = React.useMemo(() => {
    if (!isTeamRefsByRegionFetchedOnce) {
      return null;
    }

    return !Object.values(teamRefsByRegion).some(teamRefs => {
      return teamRefs.length > 0;
    });
  }, [isTeamRefsByRegionFetchedOnce, teamRefsByRegion]);

  return {
    isTeamRefsByRegionFetchedOnce,
    teamRefsByRegion,
    isUserHasTeamInOtherRegions,
    isNewUser,
  };
}
