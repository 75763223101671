@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.container {
  display: flex;
  border: solid 1px $separator-color-2;
  padding: 12px;
  width: 386px;

  &.clickable {
    cursor: pointer;
  }

  .icon {
    width: 60px;
    height: 60px;
  }

  .icon-container {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }

  .info {
    margin-right: 12px;

    .heading {
      display: block;
      line-height: 18px;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 4px;
      padding: 0;
      color: #323130;
    }

    .desc {
      display: block;
      line-height: 18px;
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 4px;
      color: #605e5c;
    }
  }

  &.disabled {
    .info {
      .heading,
      .desc {
        color: #a19f9d;
      }
    }
  }

  .right {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    width: 37px;

    .add-text {
      color: $theme-primary;
      font-weight: 600;
    }

    .added-text {
      color: #a19f9d;
      font-weight: 400;
    }
  }
}
