import {
  DefaultButton,
  IBreadcrumbItem,
  IContextualMenuItem,
  IContextualMenuProps,
  ITooltipHostStyles,
  Text,
  TooltipHost,
  isMac,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import * as React from "react";
import { useNavigate } from "react-router";

import { useLocale } from "../../contexts/locale";
import { Form } from "../../types/form";
import AppBreadcrumb from "../AppBreadcrumb";
import { PrimaryButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface AdvanceTokenSetupNavBarProps {
  className?: string;
  form: Form;
  onSave: () => void;
  onSaveAndTest: () => void;
  extractorBreadcrumbLink?: IBreadcrumbItem;
  isSnapshotVersionEnabled?: boolean;
  isSnapshotVersionButtonEnabled?: boolean;
  onSaveAndBookmarkPressed: () => void;
  onShowSnapshotHistory: () => void;
}

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block", marginLeft: 8 },
};
const saveSplitButtonStyles = {
  splitButtonMenuIcon: {
    color: "#201F1E",
  },
  splitButtonMenuButtonExpanded: {
    color: "#201F1E",
    backgroundColor: "#FFD633",
    ":hover": {
      color: "#201F1E",
      backgroundColor: "#CCAB29",
    },
  },
  splitButtonMenuButton: {
    color: "#201F1E",
    backgroundColor: "#FFD633",
    ":hover": {
      color: "#201F1E",
      backgroundColor: "#CCAB29",
    },
  },
  splitButtonDivider: {
    backgroundColor: "#C8C6C4",
  },
};

const AdvanceTokenSetupNavBar = React.memo(
  (props: AdvanceTokenSetupNavBarProps) => {
    const {
      className,
      form,
      onSave,
      onSaveAndTest,
      isSnapshotVersionEnabled = false,
      isSnapshotVersionButtonEnabled = false,
      onShowSnapshotHistory,
      onSaveAndBookmarkPressed,
    } = props;
    const { localized } = useLocale();
    const navigate = useNavigate();

    const onBreadcrumbItemClick = React.useCallback(
      (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
        if (ev === undefined || item === undefined || item.href === undefined) {
          return;
        }
        ev.preventDefault();
        navigate(item.href);
      },
      [navigate]
    );

    const breadcrumbItems: IBreadcrumbItem[] = React.useMemo(() => {
      const extractorBreadcrumbLink = {
        ...{
          text: form.name,
          key: form.id,
          href: `/form/${form.id}/edit`,
          onClick: onBreadcrumbItemClick,
        },
        ...props.extractorBreadcrumbLink,
      };

      return [
        {
          text: localized("extractor.breadcrumb.extractors"),
          key: "extractor",
          href: "/extractor",
          onClick: onBreadcrumbItemClick,
        },
        extractorBreadcrumbLink,
        {
          key: "advance-merchant-setup",
          text: localized("advance_token_setup.editor.title"),
        },
      ];
    }, [localized, onBreadcrumbItemClick, form, props.extractorBreadcrumbLink]);

    const hideSaveAndTestButton = true;

    const onSaveOptionClick = React.useCallback(
      (
        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        item?: IContextualMenuItem
      ) => {
        if (ev && item && item.key === "save_and_bookmark") {
          ev.preventDefault();
          onSaveAndBookmarkPressed();
          return true;
        }
        return false;
      },
      [onSaveAndBookmarkPressed]
    );
    const saveMenu = React.useMemo<IContextualMenuProps | undefined>(() => {
      if (isSnapshotVersionEnabled) {
        return {
          items: [
            {
              key: "save_and_bookmark",
              name: localized(
                "advance_token_setup.editor.action_option.save_and_bookmark"
              ),
            },
          ],
          onItemClick: onSaveOptionClick,
        };
      }
      return undefined;
    }, [isSnapshotVersionEnabled, onSaveOptionClick, localized]);

    return (
      <div className={classNames(styles["nav-bar"], className)}>
        <AppBreadcrumb
          className={styles["breadcrumb"]}
          items={breadcrumbItems}
        />
        <div className={styles["button-group"]}>
          {isSnapshotVersionEnabled && (
            <DefaultButton
              disabled={!isSnapshotVersionButtonEnabled}
              className={classNames(
                " w-8 h-8 min-w-8 text-[#201f1e] bg-white border-white rounded hover:bg-[#e1dfdd] ml-2",
                {
                  "bg-[#e1dfdd] border-[#e1dfdd]":
                    !isSnapshotVersionButtonEnabled,
                }
              )}
              iconProps={{
                iconName: "History",
                className: "hover:bg-[#e1dfdd] hover:border-[#e1dfdd]",
              }}
              onClick={onShowSnapshotHistory}
            />
          )}
          {hideSaveAndTestButton ? null : (
            <TooltipHost
              content={
                <Text className={styles["tooltip-text"]}>
                  <FormattedMessage
                    id="advance_token_setup.editor.tooltip.quick_test"
                    values={{
                      modifierKey: isMac() ? "⌘+Shift" : "Ctrl+Shift",
                      highlightTextClassName: styles["tooltip-text--highlight"],
                    }}
                  />
                </Text>
              }
              calloutProps={calloutProps}
              styles={hostStyles}
            >
              <DefaultButton
                disabled={true}
                text={localized(
                  "advance_token_setup.editor.action.save_and_test"
                )}
                onClick={onSaveAndTest}
              />
            </TooltipHost>
          )}
          <TooltipHost
            content={
              <Text className={styles["tooltip-text"]}>
                <FormattedMessage
                  id="advance_token_setup.editor.tooltip.save"
                  values={{
                    highlightTextClassName: styles["tooltip-text--highlight"],
                    modifierKey: isMac() ? "⌘" : "Ctrl",
                  }}
                />
              </Text>
            }
            calloutProps={calloutProps}
            styles={hostStyles}
          >
            <PrimaryButton
              className={
                "text-[#201F1E] bg-[#FFD633]  border-[#FFD633] hover:bg-[#CCAB29] hover:border-[#CCAB29] hover:text-[#201F1E] disabled:bg-[#F3F2F1] disabled:text-[#A19F9D] active:bg-[#CCAB29] active:text-[#201F1E]"
              }
              styles={saveSplitButtonStyles}
              textId="advance_token_setup.editor.action.save"
              onClick={onSave}
              split={saveMenu !== undefined}
              menuProps={saveMenu}
            />
          </TooltipHost>
        </div>
      </div>
    );
  }
);

export default AdvanceTokenSetupNavBar;
