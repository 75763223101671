import { IBreadcrumbItem } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router";

import { NavBarLayout } from "../components/NavBarLayout";
import { NavTab } from "../components/NavTabBar";
import { useLocale } from "../contexts/locale";

export enum DetectMultiDocumentNavTabKey {
  TestExtractor = "test",
  API = "extract",
}

export const DetectMultiDocumentNavTab: Record<
  DetectMultiDocumentNavTabKey,
  NavTab
> = {
  [DetectMultiDocumentNavTabKey.TestExtractor]: {
    key: DetectMultiDocumentNavTabKey.TestExtractor,
    labelId: "extractor.tab.test",
  },
  [DetectMultiDocumentNavTabKey.API]: {
    key: DetectMultiDocumentNavTabKey.API,
    labelId: "extractor.tab.extract",
  },
};

function useDetectMultiDocumentNavBarLayoutState(selectedTab: string) {
  const navigate = useNavigate();
  const { localized } = useLocale();

  const backToHome = React.useCallback(() => {
    navigate("/extractor");
  }, [navigate]);

  const breadcrumbItems = React.useMemo<IBreadcrumbItem[]>(
    () => [
      {
        text: localized("header.detect_documents"),
        key: "extractor",
        href: "/extractor",
        onClick: backToHome,
      },
    ],
    [backToHome, localized]
  );

  const tabs = React.useMemo(() => {
    const tabKeys = [
      DetectMultiDocumentNavTabKey.TestExtractor,
      DetectMultiDocumentNavTabKey.API,
    ];
    return tabKeys
      .filter(Boolean)
      .map(
        key => DetectMultiDocumentNavTab[key as DetectMultiDocumentNavTabKey]
      );
  }, []);

  const onTabSelect = React.useCallback(
    (key: string) => {
      const mapTable = {
        [DetectMultiDocumentNavTabKey.TestExtractor]: "test",
        [DetectMultiDocumentNavTabKey.API]: "extract",
      } as { [key in DetectMultiDocumentNavTabKey]: string };

      const path = mapTable[key as DetectMultiDocumentNavTabKey];
      navigate(`/detect-documents/${path}`);
    },
    [navigate]
  );
  return React.useMemo(
    () => ({
      breadcrumbItems,
      onTabSelect,
      tabs,
      selectedTab,
    }),
    [breadcrumbItems, onTabSelect, tabs, selectedTab]
  );
}

type DetectMultiDocumentNavBarLayoutProps = {
  children: React.ReactNode;
  selectedTab?: string;
  tabBarVisible?: boolean;
};

export function DetectMultiDocumentNavBarLayoutImpl(
  props: DetectMultiDocumentNavBarLayoutProps &
    ReturnType<typeof useDetectMultiDocumentNavBarLayoutState>
) {
  const {
    children,
    tabs,
    onTabSelect,
    breadcrumbItems,
    selectedTab,
    tabBarVisible,
  } = props;

  return (
    <>
      <NavBarLayout
        tabs={tabs}
        onTabSelect={onTabSelect}
        breadcrumbItems={breadcrumbItems}
        selectedTab={selectedTab}
        tabBarVisible={tabBarVisible}
      >
        {children}
      </NavBarLayout>
    </>
  );
}

export function DetectMultiDocumentNavBarLayout(
  props: DetectMultiDocumentNavBarLayoutProps
) {
  const state = useDetectMultiDocumentNavBarLayoutState(
    props.selectedTab ?? DetectMultiDocumentNavTabKey.TestExtractor
  );

  return <DetectMultiDocumentNavBarLayoutImpl {...props} {...state} />;
}
