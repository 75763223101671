import React, { useCallback, useEffect, useState } from "react";

import AddAnchorReminder from "../components/AddAnchorReminder";
import FixedLayoutFormTutorialAllSetdBubble from "../components/FixedLayoutFormTutorialAllSetBubble";
import FixedLayoutFormTutorialAnchorBubble from "../components/FixedLayoutFormTutorialAnchorBubble";
import FixedLayoutFormTutorialConfigureDetectionRegionBubble from "../components/FixedLayoutFormTutorialConfigureDetectionRegionBubble";
import FixedLayoutFormTutorialDetectionRegionBubble from "../components/FixedLayoutFormTutorialDetectionRegionBubble";
import FixedLayoutFormTutorialLabelExtractionFieldBubble from "../components/FixedLayoutFormTutorialLabelExtractionFieldBubble";
import { useFormEditor } from "../contexts/formEditor";
import { DetailedForm } from "../types/form";
import {
  PreferenceKey,
  getPreference,
  setPreference,
} from "../utils/preference";

export enum TutorialStep {
  anchor = "anchor",
  detectionRegion = "detectionRegion",
  configureDetectionRegion = "configureDetectionRegion",
}

export enum TutorialStage {
  showAnchorTutorial = "showAnchorTutorial",
  showAnchorReminder = "showAnchorReminder",
  anchorReminderDismissed = "anchorReminderDismissed",
  showDetectionRegionTutorial = "showDetectionRegionTutorial",
  detectionRegionTutorialDismissed = "detectionRegionTutorialDismissed",
  showConfigureDetectionRegionTutorial = "showConfigureDetectionRegionTutorial",
  configureDetectionRegionTutorialDismissed = "configureDetectionRegionTutorialDismissed",
  showLabelExtractionFieldTutorial = "showLabelExtractionFieldTutorial",
  showYoureAllSetTutorial = "showYoureAllSetTutorial",
  finished = "finished",
}

const NEXT_STAGE_DICT = {
  [TutorialStage.showAnchorTutorial]: TutorialStage.showAnchorReminder,
  [TutorialStage.showAnchorReminder]: TutorialStage.anchorReminderDismissed,
  [TutorialStage.anchorReminderDismissed]:
    TutorialStage.showDetectionRegionTutorial,
  [TutorialStage.showDetectionRegionTutorial]:
    TutorialStage.detectionRegionTutorialDismissed,
  [TutorialStage.detectionRegionTutorialDismissed]:
    TutorialStage.showConfigureDetectionRegionTutorial,
  [TutorialStage.showConfigureDetectionRegionTutorial]:
    TutorialStage.configureDetectionRegionTutorialDismissed,
  [TutorialStage.configureDetectionRegionTutorialDismissed]:
    TutorialStage.showLabelExtractionFieldTutorial,
  [TutorialStage.showLabelExtractionFieldTutorial]:
    TutorialStage.showYoureAllSetTutorial,
  [TutorialStage.showYoureAllSetTutorial]: TutorialStage.finished,
  [TutorialStage.finished]: undefined,
};

const WAITING_FOR_ENOUGH_ANCHOR_STAGES = [
  TutorialStage.showAnchorTutorial,
  TutorialStage.showAnchorReminder,
  TutorialStage.anchorReminderDismissed,
];

function useAutoProceedWhenEnoughAnchorAreDrawn(
  isRevisitingTutorial: boolean,
  tutorialStage: TutorialStage,
  setTutorialStage: (stage: TutorialStage) => void,
  form?: DetailedForm
) {
  useEffect(() => {
    if (isRevisitingTutorial) {
      return;
    }
    if (
      (form?.anchors?.length ?? 0) >= 3 &&
      WAITING_FOR_ENOUGH_ANCHOR_STAGES.includes(tutorialStage)
    ) {
      setTutorialStage(TutorialStage.showDetectionRegionTutorial);
      return;
    }
  }, [form?.anchors, tutorialStage, setTutorialStage, isRevisitingTutorial]);
}

const WAITING_FOR_DETECTION_REGION_STAGES = [
  TutorialStage.showDetectionRegionTutorial,
  TutorialStage.detectionRegionTutorialDismissed,
];

function useAutoProceedWhenDetectionRegionIsDrawn(
  isRevisitingTutorial: boolean,
  tutorialStage: TutorialStage,
  setTutorialStage: (stage: TutorialStage) => void,
  selectedDetectionRegionId?: string
) {
  useEffect(() => {
    if (isRevisitingTutorial) {
      return;
    }
    if (
      selectedDetectionRegionId !== undefined &&
      WAITING_FOR_DETECTION_REGION_STAGES.includes(tutorialStage)
    ) {
      setTutorialStage(TutorialStage.showConfigureDetectionRegionTutorial);
      return;
    }
    if (
      selectedDetectionRegionId === undefined &&
      tutorialStage === TutorialStage.showConfigureDetectionRegionTutorial
    ) {
      setTutorialStage(TutorialStage.detectionRegionTutorialDismissed);
      return;
    }
  }, [
    tutorialStage,
    setTutorialStage,
    selectedDetectionRegionId,
    isRevisitingTutorial,
  ]);
}

const WAITING_FOR_LABEL_EXTRACTION_STAGES = [
  TutorialStage.showDetectionRegionTutorial,
  TutorialStage.configureDetectionRegionTutorialDismissed,
];

function useAutoProceedWhenExtractionFieldIsAdded(
  isRevisitingTutorial: boolean,
  tutorialStage: TutorialStage,
  setTutorialStage: (stage: TutorialStage) => void,
  form?: DetailedForm,
  selectedDetectionRegionId?: string
) {
  useEffect(() => {
    if (
      isRevisitingTutorial &&
      tutorialStage === TutorialStage.showDetectionRegionTutorial
    ) {
      return;
    }
    if (
      selectedDetectionRegionId !== undefined &&
      WAITING_FOR_LABEL_EXTRACTION_STAGES.includes(tutorialStage)
    ) {
      const selectedDetectionRegion = form?.detectionRegions.find(
        ({ id }) => id === selectedDetectionRegionId
      );
      if (
        !selectedDetectionRegion ||
        selectedDetectionRegion.config.fields.length === 0
      ) {
        return;
      }
      setTutorialStage(TutorialStage.showLabelExtractionFieldTutorial);
      return;
    }
    if (
      selectedDetectionRegionId === undefined &&
      tutorialStage === TutorialStage.showLabelExtractionFieldTutorial
    ) {
      setTutorialStage(TutorialStage.configureDetectionRegionTutorialDismissed);
      return;
    }
  }, [
    tutorialStage,
    setTutorialStage,
    selectedDetectionRegionId,
    form,
    isRevisitingTutorial,
  ]);
}

function useAutoToggleInfoIconDisplay(
  tutorialStage: TutorialStage,
  setEnableInfoIcon: (show: boolean) => void
) {
  useEffect(() => {
    setEnableInfoIcon(
      tutorialStage === TutorialStage.showYoureAllSetTutorial ||
        tutorialStage === TutorialStage.finished
    );
  }, [tutorialStage, setEnableInfoIcon]);
}

function getTutorialStage(form?: DetailedForm): TutorialStage {
  if (getShouldSkipTutorial()) {
    return TutorialStage.finished;
  }
  if ((form?.anchors.length ?? 0) < 3) {
    return TutorialStage.showAnchorTutorial;
  }
  if (form?.detectionRegions.length === 0) {
    return TutorialStage.showDetectionRegionTutorial;
  }
  if (
    (form?.detectionRegions.length ?? 0) > 0 &&
    form?.detectionRegions[0].config.fields.length === 0
  ) {
    return TutorialStage.detectionRegionTutorialDismissed;
  }
  return TutorialStage.finished;
}

function getShouldSkipTutorial() {
  const hasGoneThroughTutorial = getPreference(
    PreferenceKey.shouldSkipTutorial
  );
  return hasGoneThroughTutorial === null
    ? false
    : JSON.parse(hasGoneThroughTutorial);
}

interface FixedLayoutFormTutorialContainerProps {
  showAllSetTutorial: boolean;
  setShowAllSetTutorial: (show: boolean) => void;
  setEnableInfoIcon: (show: boolean) => void;
}

const FixedLayoutFormTutorialContainer = React.memo(
  (props: FixedLayoutFormTutorialContainerProps) => {
    const { showAllSetTutorial, setShowAllSetTutorial, setEnableInfoIcon } =
      props;
    const { form, selectedDetectionRegionId } = useFormEditor();
    const [tutorialStage, setTutorialStage] = useState<TutorialStage>(
      getTutorialStage(form)
    );
    const [isRevisitingTutorial, setIsRevisitingtutorial] = useState(false);

    const skipAllTutorials = useCallback(() => {
      setTutorialStage(TutorialStage.finished);
      setPreference(PreferenceKey.shouldSkipTutorial, "true");
    }, []);

    const proceedToNextStage = useCallback(() => {
      setShowAllSetTutorial(false);
      if (
        isRevisitingTutorial &&
        tutorialStage !== TutorialStage.showConfigureDetectionRegionTutorial
      ) {
        setTutorialStage(TutorialStage.showYoureAllSetTutorial);
        return;
      }
      const nextStage = NEXT_STAGE_DICT[tutorialStage];
      if (nextStage !== undefined) {
        setTutorialStage(nextStage);
      }
    }, [setShowAllSetTutorial, tutorialStage, isRevisitingTutorial]);

    const onRemindAnchorButtonClicked = useCallback(() => {
      setTutorialStage(TutorialStage.showAnchorTutorial);
    }, []);

    const navigateToStep = useCallback(
      (step: TutorialStep) => {
        setIsRevisitingtutorial(true);
        setShowAllSetTutorial(false);
        switch (step) {
          case TutorialStep.anchor:
            setTutorialStage(TutorialStage.showAnchorTutorial);
            return;
          case TutorialStep.detectionRegion:
            setTutorialStage(TutorialStage.showDetectionRegionTutorial);
            return;
          case TutorialStep.configureDetectionRegion:
            setTutorialStage(
              TutorialStage.showConfigureDetectionRegionTutorial
            );
            return;
        }
      },
      [setShowAllSetTutorial]
    );

    const finishTutorial = useCallback(
      (skipAll: boolean) => {
        setShowAllSetTutorial(false);
        setTutorialStage(TutorialStage.finished);
        if (skipAll) {
          setPreference(PreferenceKey.shouldSkipTutorial, "true");
        } else {
          setPreference(PreferenceKey.shouldSkipTutorial, "false");
        }
      },
      [setShowAllSetTutorial]
    );

    useAutoProceedWhenEnoughAnchorAreDrawn(
      isRevisitingTutorial,
      tutorialStage,
      setTutorialStage,
      form
    );
    useAutoProceedWhenDetectionRegionIsDrawn(
      isRevisitingTutorial,
      tutorialStage,
      setTutorialStage,
      selectedDetectionRegionId
    );
    useAutoProceedWhenExtractionFieldIsAdded(
      isRevisitingTutorial,
      tutorialStage,
      setTutorialStage,
      form,
      selectedDetectionRegionId
    );
    useAutoToggleInfoIconDisplay(tutorialStage, setEnableInfoIcon);

    switch (tutorialStage) {
      case TutorialStage.showAnchorTutorial:
        return (
          <FixedLayoutFormTutorialAnchorBubble
            proceedToNextStage={proceedToNextStage}
            skipAllTutorials={skipAllTutorials}
          />
        );
      case TutorialStage.showAnchorReminder:
        return (
          <AddAnchorReminder
            onRemindButtonClicked={onRemindAnchorButtonClicked}
            onCloseReminder={proceedToNextStage}
          />
        );
      case TutorialStage.showDetectionRegionTutorial:
        return (
          <FixedLayoutFormTutorialDetectionRegionBubble
            proceedToNextStage={proceedToNextStage}
            skipAllTutorials={skipAllTutorials}
          />
        );
      case TutorialStage.showConfigureDetectionRegionTutorial:
        return (
          <FixedLayoutFormTutorialConfigureDetectionRegionBubble
            proceedToNextStage={proceedToNextStage}
            skipAllTutorials={skipAllTutorials}
          />
        );
      case TutorialStage.showLabelExtractionFieldTutorial:
        return (
          <FixedLayoutFormTutorialLabelExtractionFieldBubble
            proceedToNextStage={proceedToNextStage}
            skipAllTutorials={skipAllTutorials}
          />
        );
      case TutorialStage.showYoureAllSetTutorial:
        return (
          <FixedLayoutFormTutorialAllSetdBubble
            shouldSkipAllTutorial={getShouldSkipTutorial()}
            finishTutorial={finishTutorial}
            navigateToStep={navigateToStep}
            disableConfigureDetectionRegionButton={
              selectedDetectionRegionId === undefined
            }
          />
        );
      default:
        if (showAllSetTutorial) {
          return (
            <FixedLayoutFormTutorialAllSetdBubble
              shouldSkipAllTutorial={getShouldSkipTutorial()}
              finishTutorial={finishTutorial}
              navigateToStep={navigateToStep}
              disableConfigureDetectionRegionButton={
                selectedDetectionRegionId === undefined
              }
            />
          );
        }
        return <></>;
    }
  }
);

export default FixedLayoutFormTutorialContainer;
