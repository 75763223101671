@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
  .site-url-input {
    margin-bottom: 12px;
  }

  .site-url-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
  }

  .sharepoint-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 100%;
    height: 51px;
    padding: 8px;
    margin-bottom: 12px;
    border: none;
    font-weight: 600;

    &:disabled {
      color: #a19f9d;
    }

    // Workaround span in button has position: relative style
    // breaking absolute position of icon
    &:active > span {
      position: static;
    }

    .sharepoint-button-icon {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 35px;
      height: 35px;
      background-color: $white;

      > svg {
        width: 35px;
        height: 35px;
      }
    }
  }

  .login-spinner {
    margin: 16px;
  }

  .file-picker-section {
    margin-bottom: 12px;

    .file-picker-label {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }

    .file-picker-desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 12px;
    }

    .sharepoint-file-picker {
      width: 100%;
      padding: 10px 16px;
      border: solid 1px #8a8886;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #323130;
      background-color: $white;

      .file-picker-icon {
        font-size: 14px;
        line-height: 18px;
      }

      .file-picker-not-selected {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .select-text {
          margin-right: 10px;
        }
      }

      .file-picker-selected {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .file-picker-folder-icon {
          margin-right: 10px;
        }

        .folder-name-text {
          flex: 1 0 0%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .file-picker-deselect-button {
          height: 20px;
          line-height: 20px;
          border: none;

          &,
          &:hover,
          &:active {
            color: $theme-primary;
          }
        }
      }
    }
  }
}
