import { Formatter, FormatterStep } from "../formatter";

export const FormatterStepMapper = {
  toStorage(step: Formatter["steps"][0]) {
    return {
      id: step.id,
      action: step.action,
      input_selection: step.inputSelection,
      config: step.config,
      output_as: step.outputAs,
      matching_condition: step.matchingCondition,
    };
  },
  fromStorage(obj: any): FormatterStep {
    return {
      id: obj.id,
      action: obj.action,
      inputSelection: obj.input_selection,
      config: obj.config,
      outputAs: obj.output_as,
      matchingCondition: obj.matching_condition,
    };
  },
};

export type FormatterStepStorage = ReturnType<
  typeof FormatterStepMapper.toStorage
>;

export const FormatterMapper = {
  toStorage(formatter?: Formatter) {
    if (formatter === undefined) {
      return undefined;
    }

    const ret = {
      version: formatter.version,
      steps: formatter.steps.map(step => {
        return FormatterStepMapper.toStorage(step);
      }),
    };
    return ret;
  },
  fromStorage(obj: any): Formatter | undefined {
    if (obj === undefined) {
      return undefined;
    }
    const formatter = {
      version: obj.version,
      steps: obj.steps.map((step: FormatterStepStorage) => {
        return FormatterStepMapper.fromStorage(step);
      }),
    };
    return formatter as Formatter;
  },
};

export type FormatterStorage = ReturnType<typeof FormatterMapper.toStorage>;
