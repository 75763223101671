import {
  DefaultButton,
  Icon,
  Spinner,
  SpinnerSize,
  Text,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { DateTime } from "luxon";
import React from "react";

import styles from "./styles.module.scss";

interface Props {
  isTrainingInProgress: boolean;
  isTrainingRequested: boolean;
  openLabeller: () => void;
  triggerModelTraining: () => void;
  isTrainingFailed: boolean;
  startTrainingAt: number | null;
}

function _CustomModelLabel(props: Props) {
  const {
    isTrainingInProgress,
    isTrainingRequested,
    triggerModelTraining,
    openLabeller,
    isTrainingFailed,
    startTrainingAt,
  } = props;

  return (
    <div className={styles["container"]}>
      <Text variant="xxLarge">
        <FormattedMessage id="custom_model_label.title" />
      </Text>

      <div className={styles["step"]}>
        <div className={styles["title"]}>
          <Text variant="large">
            <FormattedMessage id="custom_model_label.label.title" />
          </Text>
        </div>
        <div className={styles["description"]}>
          <Text>
            <FormattedMessage
              id="custom_model_label.label.desc"
              values={{ documentLayout: <code>{"document.layout"}</code> }}
            />
          </Text>
        </div>
        <div className={styles["description"]}>
          <Text>
            <FormattedMessage id="custom_model_label.label.desc2" />
          </Text>
        </div>
        <DefaultButton
          className={styles["action-button"]}
          onClick={openLabeller}
        >
          <FormattedMessage id="custom_model_label.label.button" />
        </DefaultButton>
      </div>
      <div className={styles["step"]}>
        <div className={styles["title"]}>
          <Text variant="large">
            <FormattedMessage id="custom_model_label.train.title" />
          </Text>
        </div>
        <div className={styles["description"]}>
          <Text>
            <FormattedMessage id="custom_model_label.train.desc" />
          </Text>
        </div>
        <DefaultButton
          className={styles["action-button"]}
          disabled={isTrainingInProgress || isTrainingRequested}
          onClick={triggerModelTraining}
        >
          {isTrainingInProgress ? (
            <>
              <Spinner size={SpinnerSize.small} />
              <FormattedMessage id="custom_model_label.train.button.in_progress" />
            </>
          ) : isTrainingRequested ? (
            <FormattedMessage id="custom_model_label.train.button.requested" />
          ) : (
            <FormattedMessage id="custom_model_label.train.button" />
          )}
        </DefaultButton>
      </div>

      {isTrainingFailed && startTrainingAt && !isTrainingInProgress && (
        <div className={styles["info"]}>
          <h3>
            <Icon iconName="Info" />
            <FormattedMessage id="custom_model_label.training_failed.title" />
          </h3>
          <FormattedMessage
            id="custom_model_label.training_failed.message"
            values={{
              email: <a href="mailto:hello@formx.ai">hello@formx.ai</a>,
              datetime: DateTime.fromMillis(startTrainingAt).toFormat(
                "yyyy/MM/dd HH:mm:ss"
              ),
            }}
          />
        </div>
      )}
    </div>
  );
}

export const CustomModelLabel = React.memo(_CustomModelLabel);
export default CustomModelLabel;
