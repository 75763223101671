import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { AppConfig, RegionsConfig } from "../config";
import errors, { FOCRError } from "../errors";
import { useGtm } from "../hooks/gtm";
import { useAppDispatch } from "../hooks/redux";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { RootState } from "../redux/types";
import { GtmEventType, GtmServiceInstance } from "../services/gtmService";
import { ConfirmModalType } from "../types/confirmation";
import {
  BriefTeam,
  Permission,
  TeamInvitationIsValidResp,
  TeamInvitationListItemStore,
  TeamInvitationStatus,
  TeamMembersAndInvitations,
  TeamRef,
  TeamRenameResp,
  TeamSetUserPermissionResp,
  adminPermission,
  generateTeamLookupId,
  getTeamRole,
} from "../types/team";
import { User } from "../types/user";
import { ensureFOCRError } from "../utils/errors";
import { useConfirmModalActionCreator } from "./confirmModal";
import { useUserActionCreator } from "./user";

export const TeamInvitationRemoved = createAction(
  "team/invitationRemoved",
  (invitationId: string, region: string) => ({
    payload: { invitationId, region },
  })
);

export const TeamUserRemoved = createAction(
  "team/teamUserRemoved",
  (teamId: string, removedUserId: string, currentUser: User) => ({
    payload: {
      teamId,
      removedUserId,
      currentUser,
    },
  })
);

export const CreateTeam = createAction(
  "team/createTeam",
  (team: BriefTeam, permission: Permission) => ({
    payload: {
      team,
      permission,
    },
  })
);

export const UserPermissionUpdated = createAction(
  "team/userPermissionUpdated",
  (permission: Permission, teamId: string, isCurrentUser: boolean) => ({
    payload: {
      permission,
      teamId,
      isCurrentUser,
    },
  })
);

export const TeamInvitationAccepted = createAction(
  "team/teamInvitationAccepted",
  (
    team: BriefTeam,
    permission: Permission,
    invitationId: string,
    region: string
  ) => ({
    payload: {
      team,
      permission,
      invitationId,
      region,
    },
  })
);

export const TeamRenamed = createAction(
  "team/teamRenamed",
  (teamId: string, name: string) => ({
    payload: {
      teamId,
      name,
    },
  })
);

export const GotTeamRefs = createAction(
  "team/gotTeamRefs",
  (region: string, refs: TeamRef[]) => ({
    payload: {
      region,
      refs,
    },
  })
);

export const FetchedTeamRefs = createAction(
  "team/fetchedTeamRefs",
  (region: string) => ({ payload: { region } })
);

export const TeamDeleted = createAction(
  "team/teamDeleted",
  (teamId: string, currentUser: User) => ({
    payload: {
      teamId,
      currentUser,
    },
  })
);

export const GotTeamInvitations = createAction<{
  [key: string]: TeamInvitationListItemStore[];
}>("team/gotInvitations");

export const StartedFetchingTeamInvitations = createAction(
  "team/startedFetchingInvitations"
);

export const FetchTeamInvitationsFailed = createAction<FOCRError>(
  "/team/fetchTeamInvitationsFailed"
);

export function getCurrentTeamId(getState: () => RootState): Promise<string> {
  const { resourceOwnerId, isTeam } = getState().resourceOwner;

  if (isTeam && resourceOwnerId) {
    return Promise.resolve(resourceOwnerId);
  } else {
    return Promise.reject();
  }
}

export type TeamAction =
  | ReturnType<typeof CreateTeam>
  | ReturnType<typeof UserPermissionUpdated>
  | ReturnType<typeof TeamUserRemoved>
  | ReturnType<typeof TeamInvitationAccepted>
  | ReturnType<typeof TeamRenamed>
  | ReturnType<typeof TeamDeleted>
  | ReturnType<typeof GotTeamRefs>
  | ReturnType<typeof FetchedTeamRefs>
  | ReturnType<typeof GotTeamInvitations>
  | ReturnType<typeof StartedFetchingTeamInvitations>
  | ReturnType<typeof TeamInvitationRemoved>;

export const TEAM_CREATION_RETRY_COUNT = 3;

export function useTeamActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();

  const { handleConflict, requestUserConfirmation } =
    useConfirmModalActionCreator();
  const { createUser, checkIsUserCreatedAtRegion } = useUserActionCreator();
  const { setParam } = useSearchParamUtils();

  const createTeamRetryable = useCallback(
    async (
      name: string,
      region: string,
      defaultLookupId: string,
      maxRetryCount: number = TEAM_CREATION_RETRY_COUNT
    ): Promise<BriefTeam> => {
      const isCurrentUserExist = getState().user.currentUser !== undefined;
      const { region: currentRegion } = AppConfig;

      try {
        if (!isCurrentUserExist || region !== currentRegion) {
          await createUser(region);
        }
      } catch (e) {
        if (!(e instanceof FOCRError && e === errors.UserAlreadyExists)) {
          throw e;
        }
      }

      let lookupId = defaultLookupId;
      let retryCount = 0;
      while (true) {
        try {
          const team = await apiClient.createTeam(name, region, lookupId);
          GtmServiceInstance.push({
            event: GtmEventType.CreatedTeam,
            event_data: {
              new_team_id: [AppConfig.region, defaultLookupId].join("."),
              new_team_region: region,
              new_team_name: name,
            },
          });

          setParam(URLParamsKey.team, team.lookupId);

          dispatch(CreateTeam(team, adminPermission));
          return team;
        } catch (e) {
          if (
            e instanceof FOCRError &&
            e === errors.TeamLookupIDAlreadyExists
          ) {
            lookupId = generateTeamLookupId(name);
            retryCount++;
            if (retryCount >= maxRetryCount) {
              throw e;
            }
          } else {
            throw e;
          }
        }
      }
    },
    [dispatch, getState, createUser, setParam]
  );

  const createTeam = useCallback(
    async (
      name: string,
      region: string,
      lookupId: string
    ): Promise<BriefTeam> => {
      return await createTeamRetryable(name, region, lookupId, 0);
    },
    [createTeamRetryable]
  );

  const listTeamRefs = useCallback(
    async (region: string): Promise<TeamRef[]> => {
      try {
        const refs = await apiClient.listTeamRefs(region);

        if (refs.length > 0) {
          dispatch(GotTeamRefs(region, refs));
        }
        return refs;
      } finally {
        dispatch(FetchedTeamRefs(region));
      }
    },
    [dispatch]
  );

  const listTeamMembers = useCallback(
    async (teamId?: string): Promise<TeamMembersAndInvitations> => {
      if (!teamId) {
        teamId = await getCurrentTeamId(getState);
      }
      return await apiClient.listTeamMembers(teamId);
    },
    [getState]
  );

  const setTeamUserPermission = useCallback(
    async (
      userId: string,
      permission: Permission,
      retrievedAt: string,
      teamId?: string
    ): Promise<TeamSetUserPermissionResp> => {
      const currentUserId = getState().user.currentUser?.id;

      if (currentUserId === userId) {
        await requestUserConfirmation({
          titleId: "team.change.permission.title",
          actionId: "common.confirm",
          messageId: "team.change.admin_permission.message",
          type: ConfirmModalType.Destory,
        });
      }

      const resp = await handleConflict(
        async () => {
          if (!teamId) {
            teamId = await getCurrentTeamId(getState);
          }
          return apiClient.setTeamUserPermission(
            teamId,
            userId,
            permission,
            retrievedAt
          );
        },
        async () => {
          if (!teamId) {
            teamId = await getCurrentTeamId(getState);
          }
          return apiClient.setTeamUserPermission(
            teamId,
            userId,
            permission,
            undefined
          );
        },
        {
          titleId: "team.modified_prompt.title",
          messageId: "team.modified_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );

      const currentUser = getState().user.currentUser;
      if (!teamId) {
        teamId = await getCurrentTeamId(getState);
      }

      dispatch(
        UserPermissionUpdated(permission, teamId, currentUser?.id === userId)
      );

      return resp;
    },
    [dispatch, getState, handleConflict, requestUserConfirmation]
  );

  const removeTeamUser = useCallback(
    async (userId: string, teamId?: string) => {
      if (!teamId) {
        teamId = await getCurrentTeamId(getState);
      }
      await apiClient.removeTeamUser(teamId, userId);

      const currentUser = getState().user.currentUser;

      if (!currentUser) {
        return Promise.reject();
      }

      dispatch(TeamUserRemoved(teamId, userId, currentUser));

      return Promise.resolve();
    },
    [dispatch, getState]
  );

  const { pushInvitedMemberEvent } = useGtm();

  const inviteTeamUser = useCallback(
    async (email: string, permission: Permission, teamId?: string) => {
      if (!teamId) {
        teamId = await getCurrentTeamId(getState);
      }
      const result = await apiClient.inviteTeamUser(teamId, email, permission);
      pushInvitedMemberEvent(email, getTeamRole(permission));
      return result;
    },
    [getState, pushInvitedMemberEvent]
  );

  const invitationIsValid = useCallback(
    async (invitationCode: string): Promise<TeamInvitationIsValidResp> => {
      return apiClient.invitationIsValid(invitationCode);
    },
    []
  );

  const acceptTeamInvitation = useCallback(
    async (invitationCode: string, region: string): Promise<BriefTeam> => {
      const isUserExists =
        region === AppConfig.region
          ? getState().user.currentUser !== undefined
          : await checkIsUserCreatedAtRegion(region);

      if (!isUserExists) {
        await createUser(region);
      }

      const result = await apiClient.acceptTeamInvitation(
        invitationCode,
        region
      );

      dispatch(
        TeamInvitationAccepted(
          result.team,
          result.permission,
          invitationCode,
          region
        )
      );

      return result.team;
    },
    [getState, checkIsUserCreatedAtRegion, dispatch, createUser]
  );

  const removeTeamInvitation = useCallback(
    async (invitationCode: string, region: string) => {
      await apiClient.removeInvitation(invitationCode, region);

      dispatch(TeamInvitationRemoved(invitationCode, region));
    },
    [dispatch]
  );

  const rejectTeamInvitation = useCallback(
    async (invitationCode: string, region: string) => {
      await apiClient.rejectInvitation(invitationCode, region);

      dispatch(TeamInvitationRemoved(invitationCode, region));
    },
    [dispatch]
  );

  const renameTeam = useCallback(
    async (
      name: string,
      retrievedAt: string,
      teamId?: string
    ): Promise<TeamRenameResp> => {
      const resp = await handleConflict(
        async () => {
          if (!teamId) {
            teamId = await getCurrentTeamId(getState);
          }
          return await apiClient.renameTeam(teamId, name, retrievedAt);
        },
        async () => {
          if (!teamId) {
            teamId = await getCurrentTeamId(getState);
          }
          return await apiClient.renameTeam(teamId, name, undefined);
        },
        {
          titleId: "team.modified_prompt.title",
          messageId: "team.modified_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );

      if (!teamId) {
        teamId = await getCurrentTeamId(getState);
      }

      dispatch(TeamRenamed(teamId, name));

      return resp;
    },
    [dispatch, getState, handleConflict]
  );

  const deleteTeam = useCallback(
    async (teamId?: string): Promise<void> => {
      if (!teamId) {
        teamId = await getCurrentTeamId(getState);
      }
      const currentUser = getState().user.currentUser;
      if (!currentUser) {
        return Promise.reject();
      }

      await apiClient.deleteTeam(teamId);

      dispatch(TeamDeleted(teamId, currentUser));
    },
    [dispatch, getState]
  );

  const listInvitations = useCallback(async () => {
    const { isFetchingInvitation, invitationsByRegion } = getState().user;

    if (isFetchingInvitation || invitationsByRegion !== undefined) {
      return;
    }

    dispatch(StartedFetchingTeamInvitations());

    const regionsToFetch = Object.keys(RegionsConfig.endpoints);

    try {
      const result = await Promise.all(
        regionsToFetch.map(async region => ({
          invitations: await apiClient.listInvitation(region),
          region,
        }))
      );

      dispatch(
        GotTeamInvitations(
          result.reduce(
            (acc, { invitations, region }) => ({
              ...acc,
              [region]: invitations.map(x => ({
                ...x,
                status: TeamInvitationStatus.Pending,
              })),
            }),
            {}
          )
        )
      );
    } catch (e) {
      dispatch(FetchTeamInvitationsFailed(ensureFOCRError(e)));
    }
  }, [dispatch, getState]);

  return useMemo(
    () => ({
      createTeam,
      createTeamRetryable,
      listTeamRefs,
      listTeamMembers,
      setTeamUserPermission,
      removeTeamUser,
      inviteTeamUser,
      invitationIsValid,
      acceptTeamInvitation,
      removeTeamInvitation,
      rejectTeamInvitation,
      renameTeam,
      deleteTeam,
      listInvitations,
    }),
    [
      createTeam,
      createTeamRetryable,
      listTeamRefs,
      listTeamMembers,
      setTeamUserPermission,
      removeTeamUser,
      inviteTeamUser,
      invitationIsValid,
      acceptTeamInvitation,
      removeTeamInvitation,
      rejectTeamInvitation,
      renameTeam,
      deleteTeam,
      listInvitations,
    ]
  );
}
