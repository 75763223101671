import {
  ChoiceGroup,
  DefaultButton,
  IChoiceGroupOption,
  Panel,
  PanelType,
  PrimaryButton,
} from "@fluentui/react";
import classNames from "classnames";
import React, { useCallback, useMemo } from "react";

import { useLocale } from "../../contexts/locale";
import { SettingConfig } from "../../types/advancedTokenSetup/table";
import ChangeInputDateFormatView, {
  useChangeInputDateFormatViewHandle,
} from "./ChangeInputDateFormatView";
import CustomModelConfigView, {
  useCustomModelConfigViewHandle,
} from "./CustomModelConfigView";
import KeyValuesConfigView, {
  useKeyValuesConfigViewHandle,
} from "./KeyValueConfigView";
import LLMFieldReplacementView, {
  useLLMFieldReplacementViewHandle,
} from "./LLMFieldReplacementView";
import styles from "./styles.module.scss";

export enum FieldReplacementOption {
  NoReplacement = "noReplacement",
  ReplaceWithKeyValuePair = "replaceWithKeyValuePair",
  ReplaceWithCustomModelConfig = "replaceWithCustomModelConfig",
  ReplaceWithLLM = "replaceWithLLM",
  ChangeInputDateFormat = "changeInputDateFormat",
}

export interface OptionData extends Record<string, any> {
  config?: SettingConfig;
  target: string;
}

interface Props {
  isOpen: boolean;
  isReadOnly?: boolean;
  selectedOption: FieldReplacementOption;
  onSelectOption: (option: FieldReplacementOption) => void;
  onClose: () => void;
  onDone: (newOptionData: OptionData) => void;
  data?: OptionData;
}

const AdvanceTokenSetupFieldReplacementSettingPanel: React.FC<
  Props
> = props => {
  const {
    isOpen,
    onClose,
    selectedOption,
    onSelectOption,
    onDone,
    data,
    isReadOnly = false,
  } = props;
  const { localized } = useLocale();

  const { triggerProps: keyValuesProps, submit: submitKeyValues } =
    useKeyValuesConfigViewHandle(
      data?.config?.type === "key_value" ? data.config.config : undefined,
      data?.target === "date"
    );
  const {
    triggerProps: customModelConfigProps,
    submit: submitCustomModelConfig,
  } = useCustomModelConfigViewHandle(
    ["invoice_number", "phone"],
    "cd6edab4-1715-48bb-853b-17e94488e207",
    undefined,
    undefined,
    data?.target === "date"
  );

  const {
    triggerProps: llmFieldReplacementConfigProps,
    submit: submitLLMFieldReplacementConfig,
  } = useLLMFieldReplacementViewHandle(
    data?.config?.type === "llm" ? data?.config?.config : undefined,
    data?.target === "date"
  );

  const {
    triggerProps: changeInputDateFormatConfigProps,
    submit: submitInputDateFormatConfig,
  } = useChangeInputDateFormatViewHandle(
    data?.config?.type === "change_date_input"
      ? data.config.config.date_input_format
      : undefined
  );

  const onSave = useCallback(() => {
    let config: SettingConfig | undefined;
    switch (selectedOption) {
      case FieldReplacementOption.ReplaceWithKeyValuePair:
        const newKeyValueConfig = submitKeyValues();
        if (!newKeyValueConfig) {
          return;
        }
        config = {
          type: "key_value",
          config: newKeyValueConfig,
        };
        break;
      case FieldReplacementOption.ReplaceWithCustomModelConfig:
        const newModelConfig = submitCustomModelConfig();
        if (!newModelConfig) {
          return;
        }
        config = {
          type: "custom_model",
          config: newModelConfig,
        };
        break;
      case FieldReplacementOption.ReplaceWithLLM:
        const newLLMFieldReplacementConfig = submitLLMFieldReplacementConfig();
        if (!newLLMFieldReplacementConfig) {
          return;
        }
        config = {
          type: "llm",
          config: newLLMFieldReplacementConfig,
        };
        break;
      case FieldReplacementOption.ChangeInputDateFormat:
        const newInputDateFormat = submitInputDateFormatConfig();
        if (!newInputDateFormat) {
          return;
        }
        config = {
          type: "change_date_input",
          config: newInputDateFormat,
        };
        break;
    }

    onDone({
      ...data,
      config,
    } as OptionData);
  }, [
    onDone,
    selectedOption,
    data,
    submitKeyValues,
    submitCustomModelConfig,
    submitInputDateFormatConfig,
    submitLLMFieldReplacementConfig,
  ]);
  const options = useMemo((): IChoiceGroupOption[] => {
    return [
      {
        key: FieldReplacementOption.NoReplacement,
        text: localized(
          "advance_token_setup_field_replacement_setting_panel.option1.no_replacement"
        ),
      },
      {
        key: FieldReplacementOption.ReplaceWithKeyValuePair,
        text: localized(
          "advance_token_setup_field_replacement_setting_panel.option2.replace_with_key_value_pair"
        ),
      },
      {
        key: FieldReplacementOption.ReplaceWithCustomModelConfig,
        text: localized(
          "advance_token_setup_field_replacement_setting_panel.option3.replace_with_custom_model_config"
        ),
      },
      {
        key: FieldReplacementOption.ReplaceWithLLM,
        text: localized(
          "advance_token_setup_field_replacement_setting_panel.option4.replace_with_llm"
        ),
      },
      ...(data?.target === "date"
        ? [
            {
              key: FieldReplacementOption.ChangeInputDateFormat,
              text: localized(
                "advance_token_setup_field_replacement_setting_panel.option5.change_input_date_format"
              ),
            },
          ]
        : []),
    ];
  }, [localized, data?.target]);
  const onRenderFooter = useCallback(() => {
    return (
      <div className={styles["footer"]}>
        <DefaultButton
          text={localized(
            "advance_token_setup_field_replacement_setting_panel.footer.cancel"
          )}
          onClick={onClose}
        />
        {!isReadOnly && (
          <PrimaryButton
            text={localized(
              "advance_token_setup_field_replacement_setting_panel.footer.done"
            )}
            onClick={onSave}
          />
        )}
      </div>
    );
  }, [localized, onClose, onSave, isReadOnly]);

  const onOptionChange = useCallback(
    (
      _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      if (option) {
        onSelectOption(option.key as FieldReplacementOption);
      }
    },
    [onSelectOption]
  );

  return (
    <Panel
      type={PanelType.custom}
      customWidth="450px"
      className={styles["panel"]}
      isOpen={isOpen}
      headerText={
        data?.target
          ? localized(
              "advance_token_setup_field_replacement_setting_panel.title_with_target",
              { target: data?.target }
            )
          : localized(
              "advance_token_setup_field_replacement_setting_panel.title"
            )
      }
      onRenderFooter={onRenderFooter}
      hasCloseButton={false}
      isFooterAtBottom={true}
      styles={{
        header: {
          paddingLeft: 16,
          paddingRight: 16,
        },
        content: {
          paddingTop: 20,
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: 16,
          marginRight: 16,
        },
      }}
    >
      <ChoiceGroup
        className={isReadOnly ? "pointer-events-none" : undefined}
        options={options}
        selectedKey={selectedOption}
        onChange={onOptionChange}
      />
      <div
        className={classNames(styles["option-detail"], {
          "pointer-events-none": isReadOnly,
        })}
      >
        {selectedOption === FieldReplacementOption.ReplaceWithKeyValuePair && (
          <KeyValuesConfigView
            className={styles["key-value-config-view"]}
            {...keyValuesProps}
          />
        )}
        {selectedOption ===
          FieldReplacementOption.ReplaceWithCustomModelConfig && (
          <CustomModelConfigView {...customModelConfigProps} />
        )}
        {selectedOption === FieldReplacementOption.ReplaceWithLLM && (
          <LLMFieldReplacementView {...llmFieldReplacementConfigProps} />
        )}
        {selectedOption === FieldReplacementOption.ChangeInputDateFormat && (
          <ChangeInputDateFormatView {...changeInputDateFormatConfigProps} />
        )}
      </div>
    </Panel>
  );
};

export default AdvanceTokenSetupFieldReplacementSettingPanel;
