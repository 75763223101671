@import "../../styles/variables.scss";

.sticky {
  position: sticky;
  top: 0;
}

.container {
  height: 100%;
  overflow-y: auto;

  .inner-content {
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    margin: 24px auto auto auto;

    .sections > div:not(:last-child) {
      border-bottom: 1px solid $neutral-light;
    }

    .sections {
      margin-bottom: 80px;
      width: calc(min(100%, 100vw - 4px));
    }

    .navigate-bar {
      border-left: 1px solid $neutral-light;

      .navigate-item {
        padding: 15px 12px;
        font-size: 14px;
        width: 216px;
      }

      .selected {
        background-color: #f3f2f1;
        color: white;
        font-weight: bold;
        border-left: 2px solid $theme-primary;
      }

      a {
        color: black;
      }
    }

    @media screen and (max-width: 800px) {
      .navigate-bar {
        display: none;
      }
    }
  }
}

.section-selector {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-bottom: 22px;
  margin-top: 16px;
  gap: 16px;

  .section {
    border: 1px solid #e1dfdd;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
    font-size: 20px;
  }

  .selected {
    color: white;
    background-color: #000000;
  }
}

.copy:hover {
  background-color: $neutral-light;
}

.code-samples {
  padding-bottom: 24px;

  .pivot {
    margin-bottom: 12px;

    svg {
      vertical-align: middle;
    }

    :global(.ms-Pivot-text) {
      vertical-align: middle;
    }
  }

  .code-section {
    position: relative;

    .copy-button {
      position: absolute;
      right: 0px;
      top: 0px;
    }

    > pre > code {
      padding: 16px;
      font-size: 14px;
      font-family: Menlo, monospace;
      background-color: #faf9f8;
    }
  }
}

.info-block {
  background-color: #f6fdfc;
  border-left: 3px solid $theme-dark;
  border-radius: 2px;
  padding: 8px;
  display: inline-block;
  font-size: 14px;
}

.endpoint-url-block {
  font-size: 14px;
  display: flex;
  width: fit-content;
  margin-bottom: 24px;

  .main {
    padding: 9px 2px 9px 8px;
    width: calc(min(564px, 100vw - 124px));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: $neutral-lighter-alt;

    .method {
      color: $theme-dark;
      margin-right: 8px;
    }

    .url {
      color: $neutral-primary-alt;
    }

    &.token-text {
      width: min(410px, 100vw - 278px);
      margin-left: 4px;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 7px;
        display: block;
        height: 14px;
      }
    }
  }

  .main > * {
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono,
      DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  }

  .copy-wrapper {
    background-color: $neutral-lighter-alt;
    padding: 4px 8px;
    .copy {
      padding: 5px 6px 6px 2px;
      font-size: 12px;
      i {
        margin-right: 2px;
      }
    }
  }

  .token-dropdown {
    width: 150px;

    :global(.ms-Dropdown-title) {
      background-color: $neutral-lighter-alt;
      border: none;
    }
    :global(.ms-Dropdown:focus::after) {
      border: none;
    }
    :global(.ms-Dropdown-caretDownWrapper),
    :global(.ms-Dropdown-title) {
      height: 39px;
      line-height: 39px;
    }
  }

  .no-token {
    background-color: $neutral-lighter-alt;
    width: min(636px, 100vw - 52px);
    height: 39px;
    color: $neutral-primary-alt;
    padding: 8px;
    line-height: 23px;
    a {
      color: $theme-dark-alt;
    }
  }
}

.endpoint-url-block:last-child {
  margin-bottom: 0;
}

.title {
  margin: 0px 20px;
  font-weight: 600;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 32px;
}

.section {
  margin: 0px 20px;
  padding: 24px 0;

  h1 {
    font-size: 28px;
    line-height: 24px;
    font-weight: 700;
    padding-bottom: 24px;
    color: #3b3a39;
  }

  h3 {
    padding-bottom: 12px;
    font-weight: 600;
    font-size: 14px;

    display: flex;
    align-items: center;

    i {
      margin-right: 8px;
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    padding-bottom: 8px;
  }

  .copy-section {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .info-block {
    > .subtitle {
      padding-top: unset;
    }
  }

  .steps {
    li:not(:last-child) {
      margin-bottom: 24px;
    }

    li {
      font-size: 14px;
      line-height: 16.8px;
      list-style-position: inside;
      padding-left: 4px;
      padding-right: 4px;

      .copy-button {
        display: inline-block;
        height: 30px;
        padding: 0 4px;
        margin: 0 4px;
        position: relative;
        top: 2px;

        > i {
          position: relative;
          top: -2px;
        }

        > i > svg {
          position: relative;
          top: 6px;
        }

        > span {
          position: relative;
          top: -2px;
          padding-right: 4px;
        }
      }
    }

    .download-buttons {
      :global(.ms-Image) {
        display: inline-block;
        margin-right: 16px;
        cursor: pointer;
      }
      height: 40px;
      margin-top: 12px;
    }
  }

  .description {
    font-size: 14px;
    white-space: pre-wrap;
  }
}

.open-app-button {
  border-color: $theme-dark;
  color: $theme-dark;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px;
  margin: 0 4px;
}

.workspace-list {
  margin-top: 24px;
  padding: 20px;
  background: #faf9f8;
}

.workspace-list-header {
  padding-bottom: 8px !important;

  color: var(--grey-palette-grey-190, #201f1e) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important; /* 142.857% */
}

.workspace-list-item {
  max-width: 486px;

  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid var(--border-colors-divider, #edebe9);
}

.workspace-list-item-name {
  flex: 1 1 0%;

  padding-bottom: unset !important;
  color: var(--grey-palette-grey-190, #201f1e) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
}

.workspace-create-button {
  margin-top: 28px;
  :global(.ms-Button-label) {
    color: #201f1e !important;
  }
  :global(.ms-Button-icon) {
    color: #201f1e !important;
  }
}
