import {
  Dropdown,
  IDropdownOption,
  Icon,
  Link,
  Pivot,
  PivotItem,
  Text,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import copy from "copy-to-clipboard";
import * as React from "react";
import Highlight from "react-highlight";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import { LinkWithTeamRef } from "../../components/LinkWithTeamRef";
import { AppConfig } from "../../config";
import codeExemples from "../../constants/codeExemples";
import { useToast } from "../../hooks/toast";
import { BriefWorkspace } from "../../types/briefWorkspace";
import { WorkerToken } from "../../types/workerToken";
import { CopyBlock } from "../CopyBlock";
import { CopyButton } from "../CopyButton";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface CopyTokenBlockProps {
  token?: string;
  tokens?: WorkerToken[];
  onSelectToken: (token: string) => void;
}

function CopyTokenBlockProps(props: CopyTokenBlockProps) {
  const { token, tokens, onSelectToken } = props;

  const toast = useToast();

  const options = tokens
    ? tokens.map(t => ({
        key: t.token,
        text: t.name,
      }))
    : [];

  const onDropdownChange = React.useCallback(
    (
      _event: React.FormEvent<unknown>,
      option?: IDropdownOption,
      _n?: number
    ) => {
      if (option && onSelectToken) {
        copy(option.key as string);
        toast.success("common.copied_to_clipboard");
        onSelectToken(option.key as string);
      }
    },
    [onSelectToken, toast]
  );

  return (
    <div className={styles["endpoint-url-block"]}>
      {token ? (
        <>
          <Dropdown
            selectedKey={token}
            className={styles["token-dropdown"]}
            options={options}
            onChange={onDropdownChange}
            styles={{ title: { height: "39px" } }}
          />

          <div className={classnames(styles["main"], styles["token-text"])}>
            <Text className={styles["url"]}>
              ***************************************
            </Text>
          </div>
          <div className={styles["copy-wrapper"]}>
            <CopyButton
              className={styles["copy"]}
              text={token}
              showText={true}
            />
          </div>
        </>
      ) : (
        <div className={styles["no-token"]}>
          <FormattedMessage
            id="extract.batch.no_token"
            values={{
              url: (
                <LinkWithTeamRef to="/team/tokens">
                  <FormattedMessage id="extract.batch.no_token.create_here" />
                </LinkWithTeamRef>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
}

function InfoBlock(props: { children: React.ReactNode }) {
  return <div className={styles["info-block"]}>{props.children}</div>;
}

interface CodeSamplesProps {
  samples: {
    title: string;
    language: string;
    code: string;
    icon: string;
  }[];
}

function CodeSamples(props: CodeSamplesProps) {
  const { samples } = props;
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    samples[0].language
  );
  const [code, setCode] = React.useState(samples[0].code);

  const onPivotItemClick = React.useCallback(
    (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
      if (!ev || !item || !item.props.itemKey) {
        console.log(ev, item, item?.props.itemKey);

        return;
      }
      ev.preventDefault();
      ev.stopPropagation();
      setSelectedLanguage(item.props.itemKey);
      const sample = samples.find(s => s.language === item.props.itemKey);
      if (sample) setCode(sample.code);
    },
    [samples]
  );

  return (
    <div className={styles["code-samples"]}>
      <Pivot
        defaultSelectedKey={selectedLanguage}
        onLinkClick={onPivotItemClick}
        className={styles["pivot"]}
      >
        {samples.map(sample => (
          <PivotItem
            headerText={sample.title}
            itemKey={sample.language}
            key={sample.language}
            itemIcon={sample.icon}
          />
        ))}
      </Pivot>
      <div className={styles["code-section"]}>
        <CopyButton
          text={code}
          className={styles["copy-button"]}
          showText={true}
        />
        <Highlight key={selectedLanguage} className={selectedLanguage}>
          {code}
        </Highlight>
      </div>
    </div>
  );
}

interface WorkspaceSectionProps {
  extractorName: string;
  workspaces: BriefWorkspace[];
  id?: string;
  onCreateWorkspace: () => void;
  isCreating: boolean;
}

function WorkspaceSection(props: WorkspaceSectionProps) {
  const { id, extractorName, workspaces, onCreateWorkspace, isCreating } =
    props;

  const onClickCreate = React.useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      onCreateWorkspace();
    },
    [onCreateWorkspace]
  );

  return (
    <div className={styles["section"]} id={id}>
      <h1>
        <FormattedMessage id="extract.workspace.title" />
      </h1>
      <div className={styles["content"]}>
        <div className={styles["description"]}>
          <FormattedMessage id="extract.workspace.description" />
        </div>
        <ul className={styles["workspace-list"]}>
          <h3 className={styles["workspace-list-header"]}>
            <FormattedMessage
              id="extract.workspace.list_header"
              values={{
                name: extractorName,
              }}
            />
          </h3>
          {workspaces.map(workspace => (
            <li className={styles["workspace-list-item"]} key={workspace.id}>
              <p className={styles["workspace-list-item-name"]}>
                {workspace.name}
              </p>
              <RouterLink
                className="text-sm text-[#0078d4] font-normal flex items-center gap-[6px] leading-5 visited:text-[#0078d4]"
                to={`/workspace/${workspace.id}`}
              >
                <span>
                  <FormattedMessage id="extract.workspace.go_to_workspace" />
                </span>
                <Icon
                  styles={{
                    root: {
                      width: 12,
                      height: 20,
                    },
                  }}
                  iconName="Forward"
                />
              </RouterLink>
            </li>
          ))}
          {workspaces.length <= 0 ? (
            <li className={styles["workspace-list-item"]}>
              <p className={styles["workspace-list-item-name"]}>
                <FormattedMessage id="extract.workspace.no_connected_workspace" />
              </p>
            </li>
          ) : null}
          <DefaultButton
            className={styles["workspace-create-button"]}
            onClick={onClickCreate}
            textId="extract.workspace.create_workspace"
            iconName="Forward"
            reverseIconPosition={true}
            loading={isCreating}
            disabled={isCreating}
          />
        </ul>
      </div>
    </div>
  );
}

interface APISectionProps {
  endpoint: {
    url: string;
    method: string;
  };
  overviewParagraphIds: string[];
  codeSamples: {
    title: string;
    language: string;
    code: string;
    icon: string;
  }[];
  accessToken: string;
  formId?: string;
  workspaceId?: string;
  id?: string;
  tokens?: WorkerToken[];
  onSelectToken: (token: string) => void;
  onOpenReference: () => void;
}

function APISection(props: APISectionProps) {
  const {
    endpoint,
    overviewParagraphIds,
    codeSamples,
    formId,
    workspaceId,
    accessToken,
    id,
    tokens,
    onSelectToken,
    onOpenReference,
  } = props;

  return (
    <div className={styles["section"]} id={id}>
      <h1>
        <FormattedMessage id="extract.api.title" />
      </h1>
      {overviewParagraphIds.map(id => (
        <p key={id}>
          <FormattedMessage id={id} />
        </p>
      ))}

      <div className={styles["copy-section"]}>
        <h3>
          <FormattedMessage id="extract.api.http_endpoint" />
        </h3>
        <CopyBlock
          className={styles["endpoint-url-block"]}
          content={endpoint.url}
          method={endpoint.method}
        />

        {formId && (
          <>
            <h3>
              <FormattedMessage id="extract.api.extractor_id" />
            </h3>
            <CopyBlock
              className={styles["endpoint-url-block"]}
              content={formId}
            />
          </>
        )}

        {workspaceId && (
          <>
            <h3>
              <FormattedMessage id="extract.api.workspace_id" />
            </h3>
            <CopyBlock
              className={styles["endpoint-url-block"]}
              content={workspaceId}
            />
          </>
        )}

        <h3>
          <FormattedMessage id="extract.api.access_token" />
        </h3>
        <CopyTokenBlockProps
          token={accessToken}
          tokens={tokens}
          onSelectToken={onSelectToken}
        />
      </div>

      <h3>
        <FormattedMessage id="extract.api.code_samples" />
      </h3>

      <CodeSamples samples={codeSamples} />

      <InfoBlock>
        <h3>
          <Icon iconName="Lightbulb" />
          <FormattedMessage id="extract.api.info.title" />
        </h3>
        <div className={styles["description"]}>
          <FormattedMessage
            id="extract.api.info.desc"
            values={{
              url: (
                <Link onClick={onOpenReference}>
                  <FormattedMessage id="extract.api.info.desc.reference" />
                </Link>
              ),
            }}
          />
        </div>
      </InfoBlock>
    </div>
  );
}

interface ContactUsSectionProps {
  id?: string;
}

function ContactUsSection(props: ContactUsSectionProps) {
  const { id } = props;

  return (
    <div className={styles["section"]} id={id}>
      <h1>
        <FormattedMessage id="extract.contact_us" />
      </h1>
      <div className={styles["content"]}>
        <div className={styles["description"]}>
          <FormattedMessage
            id="extract.contact_us.desc"
            values={{
              url: (
                <Link href="https://www.formx.ai/talk-with-us">
                  https://www.formx.ai/talk-with-us
                </Link>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

interface NavigateBarProps {
  sections: {
    titleId: string;
    id: string;
  }[];
}

function NavigateBar(props: NavigateBarProps) {
  const { sections } = props;
  const { hash } = useLocation();
  const [selectedSection, setSelectedSection] = React.useState(sections[0].id);

  React.useEffect(() => {
    const hashId = hash.replace("#", "");
    if (hashId) {
      setSelectedSection(hashId);
    }
  }, [hash]);

  return (
    <div className={styles["sticky"]}>
      {sections.map(section => (
        <div
          key={section.id}
          className={classnames(styles["navigate-item"], {
            [styles["selected"]]: section.id === selectedSection,
          })}
        >
          <Link href={`#${section.id}`}>
            <FormattedMessage id={section.titleId} />
          </Link>
        </div>
      ))}
    </div>
  );
}

interface DetectionNoteProps {
  token: string;
  tokens?: WorkerToken[];
  onSelectToken: (token: string) => void;
  onOpenReference: () => void;
}

export function DetectionNote(props: DetectionNoteProps) {
  const { token, tokens, onSelectToken, onOpenReference } = props;

  return (
    <div className={styles["container"]}>
      <div className={styles["inner-content"]}>
        <div className={styles["sections"]}>
          <APISection
            endpoint={{
              url: AppConfig.worker.endpoint + "detect-documents",
              method: "POST",
            }}
            overviewParagraphIds={[
              "detect_documents.desc.1",
              "detect_documents.desc.2",
            ]}
            codeSamples={[
              {
                title: "cURL",
                language: "bash",
                code: codeExemples.detectDocuments.curl,
                icon: "IconCurl",
              },
            ]}
            accessToken={token ?? ""}
            id="api"
            onSelectToken={onSelectToken}
            onOpenReference={onOpenReference}
            tokens={tokens}
          />
          <ContactUsSection id="contact-us" />
        </div>
        <div className={styles["navigate-bar"]}>
          <NavigateBar
            sections={[
              {
                titleId: "extract.api.title",
                id: "api",
              },
              {
                titleId: "extract.contact_us",
                id: "contact-us",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

interface DetectionPIINoteProps {
  token: string;
  tokens?: WorkerToken[];
  onSelectToken: (token: string) => void;
  onOpenReference: () => void;
}

export function DetectionPIINote(props: DetectionPIINoteProps) {
  const { token, tokens, onSelectToken, onOpenReference } = props;

  return (
    <div className={styles["container"]}>
      <div className={styles["inner-content"]}>
        <div className={styles["sections"]}>
          <APISection
            endpoint={{
              url: AppConfig.worker.endpoint + "detect-pii",
              method: "POST",
            }}
            overviewParagraphIds={["detect_pii.desc.1", "detect_pii.desc.2"]}
            codeSamples={[
              {
                title: "cURL",
                language: "bash",
                code: codeExemples.detectPII.curl,
                icon: "IconCurl",
              },
            ]}
            accessToken={token ?? ""}
            id="api"
            onSelectToken={onSelectToken}
            onOpenReference={onOpenReference}
            tokens={tokens}
          />
          <ContactUsSection id="contact-us" />
        </div>
        <div className={styles["navigate-bar"]}>
          <NavigateBar
            sections={[
              {
                titleId: "extract.api.title",
                id: "api",
              },
              {
                titleId: "extract.contact_us",
                id: "contact-us",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

interface WorkspaceNoteProps {
  token: string;
  tokens?: WorkerToken[];
  workspaceId: string;
  onSelectToken: (token: string) => void;
  onOpenReference: () => void;
}

export function WorkspaceNote(props: WorkspaceNoteProps) {
  const { workspaceId, token, tokens, onSelectToken, onOpenReference } = props;

  const codeSampleSet = codeExemples.workspace;

  return (
    <div className={styles["container"]}>
      <div className={styles["inner-content"]}>
        <div className={styles["sections"]}>
          <APISection
            endpoint={{
              url: AppConfig.worker.endpoint + "v2/workspace",
              method: "POST",
            }}
            overviewParagraphIds={[
              "workspace.api.desc.1",
              "workspace.api.desc.2",
            ]}
            codeSamples={[
              {
                title: "cURL",
                language: "bash",
                code: codeSampleSet.curl,
                icon: "IconCurl",
              },
              {
                title: "Python",
                language: "Python",
                code: codeSampleSet.python,
                icon: "IconPython",
              },
              {
                title: "Node.js",
                language: "Javascript",
                code: codeSampleSet.node,
                icon: "IconJS",
              },
              {
                title: "Go",
                language: "Go",
                code: codeSampleSet.go,
                icon: "IconGo",
              },
              {
                title: "PHP",
                language: "PHP",
                code: codeSampleSet.php,
                icon: "IconPHP",
              },
            ]}
            accessToken={token ?? ""}
            id="api"
            workspaceId={workspaceId}
            onSelectToken={onSelectToken}
            onOpenReference={onOpenReference}
            tokens={tokens}
          />
          <ContactUsSection id="contact-us" />
        </div>
      </div>
    </div>
  );
}

interface Props {
  extractorId: string;
  token?: string;
  tokens?: WorkerToken[];
  onSelectToken: (token: string) => void;
  onOpenReference: () => void;
  id?: string;
  onMacOsAppClick: () => void;
  onWindowsAppClick: () => void;
  onOpenInDesktopApp: () => void;
  showNavigateBar?: boolean;
  workspaceId?: string;
  workspaceSectionProps?: WorkspaceSectionProps;
}

function _ExtractNote(props: Props) {
  const {
    extractorId,
    token,
    tokens,
    onSelectToken,
    onOpenReference,
    showNavigateBar = true,
    workspaceId,
    workspaceSectionProps,
  } = props;

  const codeSampleSet =
    workspaceId != null ? codeExemples.workspace : codeExemples.extract;

  return (
    <div className={styles["container"]}>
      <div className={styles["inner-content"]}>
        <div className={styles["sections"]}>
          <APISection
            endpoint={{
              url: AppConfig.worker.endpoint + "v2/extract",
              method: "POST",
            }}
            overviewParagraphIds={["extract.api.desc.1", "extract.api.desc.2"]}
            codeSamples={[
              {
                title: "cURL",
                language: "bash",
                code: codeSampleSet.curl,
                icon: "IconCurl",
              },
              {
                title: "Python",
                language: "Python",
                code: codeSampleSet.python,
                icon: "IconPython",
              },
              {
                title: "Node.js",
                language: "Javascript",
                code: codeSampleSet.node,
                icon: "IconJS",
              },
              {
                title: "Go",
                language: "Go",
                code: codeSampleSet.go,
                icon: "IconGo",
              },
              {
                title: "PHP",
                language: "PHP",
                code: codeSampleSet.php,
                icon: "IconPHP",
              },
            ]}
            formId={extractorId}
            workspaceId={workspaceId}
            accessToken={token ?? ""}
            id="api"
            tokens={tokens}
            onSelectToken={onSelectToken}
            onOpenReference={onOpenReference}
          />
          {workspaceSectionProps != null ? (
            <WorkspaceSection id="workspace" {...workspaceSectionProps} />
          ) : null}
          <ContactUsSection id="contact-us" />
        </div>

        {showNavigateBar ? (
          <div className={styles["navigate-bar"]}>
            <NavigateBar
              sections={[
                {
                  titleId: "extract.api.title",
                  id: "api",
                },
                ...(workspaceSectionProps != null
                  ? [
                      {
                        titleId: "extract.workspace.title",
                        id: "workspace",
                      },
                    ]
                  : []),
                {
                  titleId: "extract.contact_us",
                  id: "contact-us",
                },
              ]}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export const ExtractNote = React.memo(_ExtractNote);
