import detectDocumentsSampleCodeCurl from "bundle-text:../../codeExamples/detectDocuments/curl.sh";
import detectPIISampleCodeCurl from "bundle-text:../../codeExamples/detectPII/curl.sh";
import extractSampleCodeCurl from "bundle-text:../../codeExamples/extract/curl.sh";
import extractSampleCodeGo from "bundle-text:../../codeExamples/extract/example.go";
import extractSampleCodeJs from "bundle-text:../../codeExamples/extract/example.js_";
import extractSampleCodePhp from "bundle-text:../../codeExamples/extract/example.php";
import extractSampleCodePy from "bundle-text:../../codeExamples/extract/example.py";
import workspaceSampleCodeCurl from "bundle-text:../../codeExamples/workspace/curl.sh";
import workspaceSampleCodeGo from "bundle-text:../../codeExamples/workspace/example.go";
import workspaceSampleCodeJs from "bundle-text:../../codeExamples/workspace/example.js_";
import workspaceSampleCodePhp from "bundle-text:../../codeExamples/workspace/example.php";
import workspaceSampleCodePy from "bundle-text:../../codeExamples/workspace/example.py";

export default {
  extract: {
    python: extractSampleCodePy,
    node: extractSampleCodeJs,
    go: extractSampleCodeGo,
    php: extractSampleCodePhp,
    curl: extractSampleCodeCurl,
  },
  workspace: {
    python: workspaceSampleCodePy,
    node: workspaceSampleCodeJs,
    go: workspaceSampleCodeGo,
    php: workspaceSampleCodePhp,
    curl: workspaceSampleCodeCurl,
  },
  detectDocuments: {
    curl: detectDocumentsSampleCodeCurl,
  },
  detectPII: {
    curl: detectPIISampleCodeCurl,
  },
};
