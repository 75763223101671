import { IDropdownOption } from "@fluentui/react";
import produce from "immer";
import { pick } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_FIELD_LABEL,
  DEFAULT_SCRIPT,
} from "../constants";
import {
  useDetectionRegionFieldTypeEngineMap,
  useDetectionRegionFieldTypeHierarchy,
  useDetectionRegionFieldTypeOptions,
} from "../hooks/detection_region_field";
import {
  DetectionRegion,
  DetectionRegionAdvancedSettingsVisibilities,
  DetectionRegionField,
  DetectionRegionFieldEngine,
  DetectionRegionFieldExtra,
  DetectionRegionFieldForUpdate,
  DetectionRegionFieldParams,
  DetectionRegionFieldType,
} from "../types/detectionRegion";
import { useFormEditor } from "./formEditor";
import { useLocale } from "./locale";

const DetectionRegionsUpdated = "DetectionRegionsUpdated";
interface DetectionRegionsUpdatedAction {
  readonly type: typeof DetectionRegionsUpdated;
  readonly payload: {
    detectionRegions: DetectionRegion[];
  };
}

const DetectionRegionFieldRemoved = "DetectionRegionFieldRemoved";
interface DetectionRegionFieldRemovedAction {
  readonly type: typeof DetectionRegionFieldRemoved;
  readonly payload: {
    id: string;
    index: number;
  };
}

const DetectionRegionFieldAdded = "DetectionRegionFieldAdded";
interface DetectionRegionFieldAddedAction {
  readonly type: typeof DetectionRegionFieldAdded;
  readonly payload: {
    id: string;
  };
}

const DetectionRegionFieldAdvancedSettingsToggled =
  "DetectionRegionFieldAdvancedSettingsToggled";
interface DetectionRegionFieldAdvancedSettingsToggledAction {
  readonly type: typeof DetectionRegionFieldAdvancedSettingsToggled;
  readonly payload: {
    id: string;
    index: number;
  };
}

type AdvanceSettingVisibilitiesActions =
  | DetectionRegionsUpdatedAction
  | DetectionRegionFieldRemovedAction
  | DetectionRegionFieldAddedAction
  | DetectionRegionFieldAdvancedSettingsToggledAction;

function advanceSettingVisibilitiesReducer(
  state: DetectionRegionAdvancedSettingsVisibilities,
  action: AdvanceSettingVisibilitiesActions
) {
  switch (action.type) {
    case DetectionRegionsUpdated:
      const { detectionRegions } = action.payload;

      const stateWithDetectionRegionsAdded = produce(state, draftState => {
        detectionRegions
          .filter(x => !(x.id in state))
          .forEach(detectionRegionToAdd => {
            draftState[detectionRegionToAdd.id] =
              detectionRegionToAdd.config.fields.map(_ => false);
          });
      });

      return pick(
        stateWithDetectionRegionsAdded,
        detectionRegions.map(({ id }) => id)
      );

    case DetectionRegionFieldAdded:
      return produce(state, draftState => {
        const { id } = action.payload;
        draftState[id].push(false);
      });
    case DetectionRegionFieldRemoved:
      return produce(state, draftState => {
        const { id, index } = action.payload;
        draftState[id].splice(index, 1);
      });
    case DetectionRegionFieldAdvancedSettingsToggled:
      return produce(state, draftState => {
        const { id, index } = action.payload;
        draftState[id][index] = !state[id][index];
      });
  }
}

function useDetectionRegionAdvanceSettingVisibility() {
  const { form } = useFormEditor();

  const [
    advancedSettingsVisibilities,
    dispatchAdvancedSettingsVisibilitiesAction,
  ] = useReducer(advanceSettingVisibilitiesReducer, {});

  useEffect(() => {
    if (!form) {
      return;
    }
    const { detectionRegions } = form;
    dispatchAdvancedSettingsVisibilitiesAction({
      type: DetectionRegionsUpdated,
      payload: {
        detectionRegions,
      },
    });
  }, [form]);

  const onAdvancedSettingsToggle = useCallback(
    (detectionRegionId: string, index: number) => () => {
      dispatchAdvancedSettingsVisibilitiesAction({
        type: DetectionRegionFieldAdvancedSettingsToggled,
        payload: {
          id: detectionRegionId,
          index,
        },
      });
    },
    []
  );

  return {
    advancedSettingsVisibilities,
    onAdvancedSettingsToggle,
    dispatchAdvancedSettingsVisibilitiesAction,
  };
}

function createDetectionRegionField(
  engineByFieldTypeMap: {
    [x: string]: DetectionRegionFieldEngine[] | undefined;
  },
  type: string
) {
  const engineOptions =
    engineByFieldTypeMap[type] || ([] as DetectionRegionFieldEngine[]);

  let defaultEngine = engineOptions[0];
  if (engineOptions.includes("inherit")) {
    defaultEngine = "inherit";
  } else if (engineOptions.includes("google")) {
    defaultEngine = "google";
  }

  const defaultParams: DetectionRegionField["params"] = {};
  if (defaultEngine) {
    defaultParams.engine = defaultEngine;
  }

  const defaultFieldsByType: { [key in DetectionRegionFieldType]?: any } = {
    script: {
      code: DEFAULT_SCRIPT,
    },
  };

  const defaultFields = defaultFieldsByType[type as DetectionRegionFieldType];
  if (defaultFields) {
    Object.entries(defaultFields).forEach(([key, value]) => {
      (defaultParams as any)[key] = value;
    });
  }

  const defaultExtrasByType: { [key in DetectionRegionFieldType]?: any } = {
    date: {
      date_format: DEFAULT_DATE_FORMAT,
    },
    checkbox: {
      use_classifier: "checkbox",
    },
  };
  const defaultExtras = defaultExtrasByType[type as DetectionRegionFieldType];

  return {
    label: DEFAULT_FIELD_LABEL,
    type: type as DetectionRegionFieldType,
    params: defaultParams,
    ...(defaultExtras
      ? {
          extras: defaultExtras,
        }
      : {}),
  };
}

function useDetectionRegionField(
  dispatchAdvancedSettingsVisibilitiesAction: React.Dispatch<AdvanceSettingVisibilitiesActions>
) {
  const { selectedDetectionRegionId, form, updateDetectionRegion } =
    useFormEditor();

  useDetectionRegionFieldTypeEngineMap();

  const _updateDetectionRegion = useCallback(
    (updater: (detectionRegion: DetectionRegion) => boolean) => {
      if (!selectedDetectionRegionId || !form) {
        return;
      }

      const detectionRegion = form.detectionRegions.find(
        ({ id }) => id === selectedDetectionRegionId
      );

      if (detectionRegion) {
        let shouldUpdate = true;
        const updatedDetectionRegion = produce(
          detectionRegion,
          (draft: DetectionRegion) => {
            shouldUpdate = updater(draft);
          }
        );
        if (shouldUpdate) {
          updateDetectionRegion(updatedDetectionRegion);
        }
      }
    },
    [selectedDetectionRegionId, form, updateDetectionRegion]
  );

  const detectionRegionFieldTypeEngineMap =
    useDetectionRegionFieldTypeEngineMap();

  const { findDetectionRegionFieldTypeHierarchySubTypeKeys } =
    useDetectionRegionFieldTypeHierarchy();

  const addDetectionRegionField = useCallback(
    (selectedDetectionRegionId: string, detectionFieldRootType: string) => {
      _updateDetectionRegion(detectionRegion => {
        const isLabelTaken = (field: any) =>
          detectionRegion.config.fields.findIndex(({ label }) => {
            return label === field.label;
          }) >= 0;

        const fieldType = findDetectionRegionFieldTypeHierarchySubTypeKeys(
          detectionFieldRootType
        )[0];

        const newField = createDetectionRegionField(
          detectionRegionFieldTypeEngineMap,
          fieldType
        );

        let index = 1;
        while (isLabelTaken(newField)) {
          index++;
          const newLabel = `${DEFAULT_FIELD_LABEL} (${index})`;
          newField.label = newLabel;
        }

        detectionRegion.config.fields.unshift(newField);
        return true;
      });
      dispatchAdvancedSettingsVisibilitiesAction({
        type: DetectionRegionFieldAdded,
        payload: {
          id: selectedDetectionRegionId,
        },
      });
    },
    [
      _updateDetectionRegion,
      dispatchAdvancedSettingsVisibilitiesAction,
      detectionRegionFieldTypeEngineMap,
      findDetectionRegionFieldTypeHierarchySubTypeKeys,
    ]
  );

  const deleteDetectionRegionField = useCallback(
    (selectedDetectionRegionId: string, index: number) => {
      _updateDetectionRegion(detectionRegion => {
        detectionRegion.config.fields.splice(index, 1);
        return true;
      });

      dispatchAdvancedSettingsVisibilitiesAction({
        type: DetectionRegionFieldRemoved,
        payload: {
          id: selectedDetectionRegionId,
          index,
        },
      });
    },
    [_updateDetectionRegion, dispatchAdvancedSettingsVisibilitiesAction]
  );

  const updateDetectionRegionField = useCallback(
    (fieldIndex: number, field: DetectionRegionFieldForUpdate) => {
      const { type, label, extras, params } = field;

      _updateDetectionRegion(detectionRegion => {
        const field = detectionRegion.config.fields[fieldIndex];

        if (params) {
          Object.keys(params).forEach(key => {
            const objectKey = key as keyof DetectionRegionFieldParams;
            if (params[objectKey] === undefined) {
              delete params[objectKey];
            }
          });

          field.params = {
            ...field.params,
            ...params,
          };
        } else if (params === null) {
          delete field.params;
        }

        if (extras) {
          Object.keys(extras).forEach(key => {
            const objectKey = key as keyof DetectionRegionFieldExtra;
            if (extras[objectKey] === undefined) {
              delete extras[objectKey];
            }
          });

          field.extras = {
            ...field.extras,
            ...extras,
          };
        } else if (extras === null) {
          delete field.extras;
        }

        if (type) {
          field.type = type;
        }

        if (label !== undefined) {
          field.label = label;
        }

        return true;
      });
    },
    [_updateDetectionRegion]
  );

  return {
    addDetectionRegionField,
    deleteDetectionRegionField,
    updateDetectionRegionField,
  };
}

function useDetectionRegionHelpers() {
  const { localized } = useLocale();

  const { selectedDetectionRegionId, form } = useFormEditor();

  const isDetectionRegionFieldLabelDuplicated = useCallback(
    (fieldIndex: number) => {
      if (!form) {
        return false;
      }
      const detectionRegion = form.detectionRegions.find(
        ({ id }) => id === selectedDetectionRegionId
      );

      if (!detectionRegion) {
        return false;
      }
      const field = detectionRegion.config.fields[fieldIndex];
      const trimmedlabel = field.label.trim();

      return detectionRegion.config.fields.some(({ label }, index) => {
        return index !== fieldIndex && label.trim() === trimmedlabel;
      });
    },
    [form, selectedDetectionRegionId]
  );

  const detectDetectionRegionFieldLabelError = useCallback(
    (fieldIndex: number) => {
      if (!form) {
        return "";
      }

      const detectionRegion = form.detectionRegions.find(
        ({ id }) => id === selectedDetectionRegionId
      );

      if (!detectionRegion) {
        return "";
      }

      const field = detectionRegion.config.fields[fieldIndex];

      if (field.label.trim() === "") {
        return localized(
          "form_inspector.detection_region_field.error.field_label_empty"
        );
      }

      if (field.label.includes("'")) {
        return localized(
          "form_inspector.detection_region_field.error.field_label_invalid"
        );
      }

      if (isDetectionRegionFieldLabelDuplicated(fieldIndex)) {
        return localized(
          "form_inspector.detection_region_field.error.field_label_unique"
        );
      }

      return "";
    },
    [
      form,
      selectedDetectionRegionId,
      isDetectionRegionFieldLabelDuplicated,
      localized,
    ]
  );

  return {
    detectDetectionRegionFieldLabelError,
  };
}

function useDetectionRegionFieldAddHelpers() {
  const { localized } = useLocale();

  const [
    detectDetectionRegionExtractionType,
    setDetectDetectionRegionExtractionType,
  ] = useState<string>("");

  const [
    detectDetectionRegionExtractionErrorMessage,
    setDetectDetectionRegionExtractionErrorMessage,
  ] = useState<string | undefined>();

  const onDetectDetectionRegionExtractionTypeChange = useCallback(
    (
      _event: React.FormEvent<unknown>,
      option?: IDropdownOption,
      _n?: number
    ) => {
      if (option === undefined) {
        return;
      }

      setDetectDetectionRegionExtractionErrorMessage(undefined);
      setDetectDetectionRegionExtractionType(option.key as string);
    },
    []
  );

  const checkAndAddDetectionRegionField = useCallback(
    (
      selectedDetectionRegionId: string,
      addDetectionRegionField: (
        selectedDetectionRegionId: string,
        detectDetectionRegionExtractionType: string
      ) => void
    ) => {
      if (detectDetectionRegionExtractionType === "") {
        setDetectDetectionRegionExtractionErrorMessage(
          localized("form_inspector.choose_extraction_type_warning")
        );
        return;
      }

      addDetectionRegionField(
        selectedDetectionRegionId,
        detectDetectionRegionExtractionType
      );
    },
    [detectDetectionRegionExtractionType, localized]
  );

  return {
    detectDetectionRegionExtractionType,
    onDetectDetectionRegionExtractionTypeChange,
    detectDetectionRegionExtractionErrorMessage,
    checkAndAddDetectionRegionField,
  };
}

function useMakeContext() {
  const detectionRegionFieldTypeOptions = useDetectionRegionFieldTypeOptions();
  const detectionRegionFieldTypeEngineMap =
    useDetectionRegionFieldTypeEngineMap();
  const {
    advancedSettingsVisibilities,
    onAdvancedSettingsToggle,
    dispatchAdvancedSettingsVisibilitiesAction,
  } = useDetectionRegionAdvanceSettingVisibility();

  const {
    detectDetectionRegionExtractionType,
    onDetectDetectionRegionExtractionTypeChange,
    detectDetectionRegionExtractionErrorMessage,
    checkAndAddDetectionRegionField,
  } = useDetectionRegionFieldAddHelpers();

  const updateDetectionRegionFunctions = useDetectionRegionField(
    dispatchAdvancedSettingsVisibilitiesAction
  );

  const { detectDetectionRegionFieldLabelError } = useDetectionRegionHelpers();

  return useMemo(
    () => ({
      detectionRegionFieldTypeOptions,
      advancedSettingsVisibilities,
      detectionRegionFieldTypeEngineMap,
      onAdvancedSettingsToggle,
      ...updateDetectionRegionFunctions,
      detectDetectionRegionFieldLabelError,

      detectDetectionRegionExtractionType,
      onDetectDetectionRegionExtractionTypeChange,
      detectDetectionRegionExtractionErrorMessage,
      checkAndAddDetectionRegionField,
    }),
    [
      detectionRegionFieldTypeOptions,
      advancedSettingsVisibilities,
      detectionRegionFieldTypeEngineMap,
      onAdvancedSettingsToggle,
      updateDetectionRegionFunctions,
      detectDetectionRegionFieldLabelError,

      detectDetectionRegionExtractionType,
      onDetectDetectionRegionExtractionTypeChange,
      detectDetectionRegionExtractionErrorMessage,
      checkAndAddDetectionRegionField,
    ]
  );
}

type DetectionRegionInspectorContextValue = ReturnType<typeof useMakeContext>;
const DetectionRegionInspectorContext =
  createContext<DetectionRegionInspectorContextValue>(null as any);

interface Props {
  children: React.ReactNode;
}

export const DetectionRegionInspectorProvider = (props: Props) => {
  const value = useMakeContext();
  return <DetectionRegionInspectorContext.Provider {...props} value={value} />;
};

export function useDetectionRegionInspector() {
  return useContext(DetectionRegionInspectorContext);
}
