@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.placeholder {
  pointer-events: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #faf9f8;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 20px;
    left: 20px;
    bottom: 20px;
    right: 20px;
    border: 2px dashed #d2d0ce;
  }

  > div {
    margin: 0 10px;
    pointer-events: auto;
  }

  .instructions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 6px;

    button {
      margin: 8px;
    }
  }

  .settings {
    color: $neutral-primary-alt;
    font-size: 14px;
    display: flex;
    align-items: center;

    .settings-dropdown {
      font-size: 14px;

      :global(.ms-Dropdown-title) {
        background-color: $neutral-lighter-alt;
        border: none;
      }
      :global(.ms-Dropdown:focus::after) {
        border: none;
      }
      :global(.ms-Dropdown-caretDownWrapper),
      :global(.ms-Dropdown-title) {
        height: 20px;
        line-height: 20px;
      }
    }

    a {
      color: $theme-dark-alt;
      margin-left: 5px;
    }
  }
}

.image-selector {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 20px 40px 20px;

  .image-selector-content {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .image-frame {
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 5px;
  }

  img {
    width: 100%;
    height: 100%;
    transform: scale(1);
    /* stylelint-disable-next-line */
    transition: all 0.15s ease;
    cursor: pointer;
    z-index: auto;
    /* stylelint-disable-next-line */
    object-fit: cover;
    border-radius: 5px;
  }

  img:hover {
    position: relative;
    /* stylelint-disable-next-line */
    transition: all 0.15s ease;
    transform: scale(1.5);
    z-index: 1000;
  }

  .image-selector-desc {
    color: #323130;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
