@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.setting-form {
  margin: 30px;

  h1 {
    padding: 10px 0;
    font-size: 18px;
    margin: 10px 0;
    border-bottom: 1px solid $tab-bar-separator;
  }

  pre {
    font-size: 12pt;
    line-height: 2em;
  }

  ul.references {
    margin: 20px;

    li {
      margin: 10px;
    }
  }

  .textfield {
    width: 700px;
    margin-bottom: 12px;
  }

  .google-key-textfield-container {
    width: 700px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .google-key-textfield {
      flex: 1;
    }
  }

  .bottom-padding {
    height: 80px;
  }

  .file-section {
    margin-top: 12px;
    width: 700px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .file-name-container {
      position: relative;
      display: inline-block;
      border-bottom: 1px solid $text-field-border-color;
      height: 30px;
      width: 472px;
      padding-bottom: 2px;

      > span {
        width: 220px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: absolute;
        bottom: 4px;
      }
    }
  }
}
