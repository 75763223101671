import { ActionButton, Icon, PrimaryButton, TextField } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useLocale } from "../../contexts/locale";
import { useAppSelector } from "../../hooks/redux";
import { useSharePointFilePicker } from "../../hooks/useSharePointFilePicker";
import { RootState } from "../../redux/types";
import {
  FilePickerSharePointFile,
  WorkspaceSharePointIntegrationConfiguration,
} from "../../types/workspaceIntegration";
import { InfoBlock } from "../InfoBlock";
import { PrimaryLoadingButton } from "../LoadingButton";
import LoadingModal from "../LoadingModal";
import { ConfigurationCardLayout } from "./ConfigurationCardLayout";
import styles from "./SharePointConfigurationCard.module.scss";

interface SharePointButtonProps {
  textId: string;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}

function SharePointButtonImpl(props: SharePointButtonProps) {
  const { textId, onClick, disabled, isLoading = false } = props;
  return (
    <PrimaryLoadingButton
      className={styles["sharepoint-button"]}
      onClick={onClick}
      isLoading={isLoading}
      disabled={disabled}
    >
      <Icon
        iconName="IconSharePoint"
        className={styles["sharepoint-button-icon"]}
      />
      <span>
        <FormattedMessage id={textId} />
      </span>
    </PrimaryLoadingButton>
  );
}

const SharePointButton = React.memo(SharePointButtonImpl);

interface SharePointFilePickerProps {
  siteUrl: string;
  selectedFile: FilePickerSharePointFile | null;
  onSelectedFile: (file: FilePickerSharePointFile) => void;
  onDeselectFile: () => void;
}

function SharePointFilePicker(props: SharePointFilePickerProps) {
  const { siteUrl, selectedFile, onSelectedFile, onDeselectFile } = props;
  const { tokens } = useSelector((state: RootState) => state.microsoftAuth);

  // FIXME update upstream interface
  const getAccessToken = useCallback(async () => {
    return tokens?.accessToken ?? undefined;
  }, [tokens?.accessToken]);

  const openFilePicker = useSharePointFilePicker(
    siteUrl,
    getAccessToken,
    onSelectedFile
  );

  if (selectedFile == null) {
    return (
      <button
        className={styles["sharepoint-file-picker"]}
        onClick={openFilePicker}
      >
        <div className={styles["file-picker-not-selected"]}>
          <span className={styles["select-text"]}>
            <FormattedMessage id="workspace.integrations.configuration.sharepoint.folder_picker.button" />
          </span>
          <Icon className={styles["file-picker-icon"]} iconName="Forward" />
        </div>
      </button>
    );
  }

  return (
    <div className={styles["sharepoint-file-picker"]}>
      <div className={styles["file-picker-selected"]}>
        <Icon
          iconName="FabricFolder"
          className={cn(
            styles["file-picker-icon"],
            styles["file-picker-folder-icon"]
          )}
        />
        <span className={styles["folder-name-text"]}>{selectedFile?.id}</span>

        <ActionButton
          className={styles["file-picker-deselect-button"]}
          onClick={onDeselectFile}
        >
          <FormattedMessage id="workspace.integrations.configuration.sharepoint.folder_picker.deselect" />
        </ActionButton>
      </div>
    </div>
  );
}

export function useSharePointConfigurationCard(
  args: SharePointConfigurationCardProps
) {
  const {
    configuration,
    onConfigurationRemoved,
    workspaceId,
    isLoggedIn,
    isLoggingIn,
    onLogin,
    onSave,
    onFolderSelect,
    selectedFolder,
    onDisconnect,
  } = args;
  const { siteUrlSubdomain } = configuration;

  const isCreatingSubscription = useAppSelector(
    state => state.workspaceIntegration.isCreatingSharePointSubscription
  );

  const [siteUrlInput, setSiteUrlInput] = useState(siteUrlSubdomain);

  const siteUrlPrefix = "https://";
  const siteUrlSuffix = ".sharepoint.com";

  const siteUrl = useMemo(() => {
    return siteUrlPrefix + siteUrlInput + siteUrlSuffix;
  }, [siteUrlInput]);

  const handleSiteUrlChange = useCallback(
    (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSiteUrlInput(ev.currentTarget.value);
    },
    []
  );

  const onLoginClick = useCallback(async () => {
    if (!isLoggingIn) {
      onLogin(siteUrl);
    }
  }, [isLoggingIn, onLogin, siteUrl]);

  const onDisconnectClick = useCallback(() => {
    onDisconnect();
  }, [onDisconnect]);

  // TODO: Remove SharePoint connection handling #2235
  const onRemoveClick = useCallback(() => {
    onConfigurationRemoved(configuration);
  }, [configuration, onConfigurationRemoved]);

  const onDeselectFolder = useCallback(() => {
    alert("Not available in dummy flow");
    // TODO - uncomment when dummy flow is removed
    // onFolderSelect(null);
  }, []);

  const canSave = isLoggedIn && selectedFolder != null && siteUrlInput;

  const onSaveClick = useCallback(() => {
    if (!isLoggedIn || !siteUrlInput || selectedFolder == null) {
      // Unexpected, as button should be disabled if neccesary information is missing
      throw new Error(
        "Expect both `microsoftUser` and `selectedFolder` to be non null and `siteUrlInput` to be non-empty"
      );
    }

    onSave(workspaceId, siteUrl, selectedFolder);
  }, [isLoggedIn, siteUrlInput, selectedFolder, onSave, workspaceId, siteUrl]);

  return useMemo(
    () => ({
      siteUrl,
      siteUrlPrefix,
      siteUrlSuffix,
      siteUrlInput,
      handleSiteUrlChange,
      onLoginClick,
      onSaveClick,
      onDisconnectClick,
      onRemoveClick,
      selectedFolder,
      onSelectedFolder: onFolderSelect,
      onDeselectFolder,
      canSave,
      isCreatingSubscription,
      isLoggedIn,
      isLoggingIn,
    }),
    [
      siteUrl,
      siteUrlInput,
      handleSiteUrlChange,
      onLoginClick,
      onSaveClick,
      onDisconnectClick,
      onRemoveClick,
      selectedFolder,
      onFolderSelect,
      onDeselectFolder,
      canSave,
      isCreatingSubscription,
      isLoggedIn,
      isLoggingIn,
    ]
  );
}

// TODO: Layout SharePoint integration configuration, this is UI for unblock backend development #2227
interface SharePointConfigurationCardProps {
  workspaceId: string;
  configuration: WorkspaceSharePointIntegrationConfiguration;
  onSave: (
    workspaceId: string,
    siteUrl: string,
    selectedFolder: FilePickerSharePointFile
  ) => Promise<void>;
  onDisconnect: () => void;
  onLogin: (siteUrl: string) => Promise<void>;
  onFolderSelect: (folder: FilePickerSharePointFile | null) => void;
  selectedFolder: FilePickerSharePointFile | null;
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  onConfigurationRemoved: (
    configuration: WorkspaceSharePointIntegrationConfiguration
  ) => void;
}

function SharePointConfigurationCardImpl(
  props: SharePointConfigurationCardProps
) {
  const { sharePointSubscription } = props.configuration;
  const { localized } = useLocale();
  const {
    siteUrl,
    siteUrlPrefix,
    siteUrlSuffix,
    siteUrlInput,
    handleSiteUrlChange,
    onLoginClick,
    onSaveClick,
    onDisconnectClick,
    onRemoveClick,
    selectedFolder,
    onSelectedFolder,
    onDeselectFolder,
    canSave,
    isCreatingSubscription,
    isLoggingIn,
    isLoggedIn,
  } = useSharePointConfigurationCard(props);

  return (
    <ConfigurationCardLayout
      title={
        <FormattedMessage id="workspace.integrations.configuration.sharepoint.title" />
      }
      contentClassName={styles["container"]}
      onRemoveClick={onRemoveClick}
    >
      <div className={styles["info-block-container"]}>
        <InfoBlock
          labelId="workspace.integrations.configuration.sharepoint.instruction.label"
          descriptionId="workspace.integrations.configuration.sharepoint.instruction.desc"
        />
      </div>
      <TextField
        className={styles["site-url-input"]}
        label={localized(
          "workspace.integrations.configuration.sharepoint.site_url.label"
        )}
        prefix={siteUrlPrefix}
        suffix={siteUrlSuffix}
        onChange={handleSiteUrlChange}
        value={siteUrlInput}
      />
      <p className={styles["site-url-desc"]}>
        <FormattedMessage id="workspace.integrations.configuration.sharepoint.site_url.desc" />
      </p>
      {!isLoggedIn && (
        <>
          <SharePointButton
            textId="workspace.integrations.configuration.sharepoint.login_button"
            onClick={onLoginClick}
            isLoading={isLoggingIn}
            disabled={!siteUrlInput}
          />
        </>
      )}
      {isLoggedIn && !!sharePointSubscription && (
        <SharePointButton
          textId="workspace.integrations.configuration.sharepoint.disconnect_button"
          onClick={onDisconnectClick}
        />
      )}
      {isLoggedIn && (
        <section className={styles["file-picker-section"]}>
          <h5 className="file-picker-label">
            <FormattedMessage id="workspace.integrations.configuration.sharepoint.folder_picker.label" />
          </h5>
          <p className={styles["file-picker-desc"]}>
            <FormattedMessage id="workspace.integrations.configuration.sharepoint.folder_picker.desc" />
          </p>
          <SharePointFilePicker
            siteUrl={siteUrl}
            selectedFile={selectedFolder}
            onSelectedFile={onSelectedFolder}
            onDeselectFile={onDeselectFolder}
          />
        </section>
      )}
      <LoadingModal
        isOpen={isCreatingSubscription}
        messageId={
          "workspace.integrations.configuration.sharepoint.creating_subscription"
        }
      />
      <PrimaryButton onClick={onSaveClick} disabled={!canSave}>
        <FormattedMessage id="workspace.integrations.configuration.sharepoint.save" />
      </PrimaryButton>
    </ConfigurationCardLayout>
  );
}

export const SharePointConfigurationCard = React.memo(
  SharePointConfigurationCardImpl
);
