import {
  ExtractedContentSchema,
  ExtractedContentSchemaAccessor,
  ExtractedContentSchemaType,
  normalizeExtractedContentSchemaFieldName,
} from "../types/extractedContentSchema";

export type ExtractedContentSchemaPayloadValidationResult = {
  id: string;
  name: string;
  hasError: boolean;
  isInvalidFormatName: boolean;
  isDuplicatedFieldName: boolean;
  isEmptyFieldName: boolean;
  duplicatedSubFieldNames: string[];
  invalidFormatSubFieldNames: string[];
};

export type ExtractedContentSchemaValidationResult = {
  hasError: boolean;
  emptyName: boolean;
  missingPayload: boolean;
  invalidFormatName: boolean;
  payload: ExtractedContentSchemaPayloadValidationResult[];
};

export class ExtractedContentSchemaValidationResultAccessor {
  data: ExtractedContentSchemaValidationResult;
  constructor(data: ExtractedContentSchemaValidationResult) {
    this.data = data;
  }

  getPayloadResultFromId(id: string) {
    return (this.data.payload.find(result => result.id === id) || {
      hasError: false,
      isInvalidFormatName: false,
      isDuplicatedFieldName: false,
      isEmptyFieldName: false,
      duplicatedSubFieldNames: [],
      invalidFormatSubFieldNames: [],
    }) as ExtractedContentSchemaPayloadValidationResult;
  }

  static create(data?: Partial<ExtractedContentSchemaValidationResult>) {
    return new ExtractedContentSchemaValidationResultAccessor({
      hasError: false,
      emptyName: false,
      missingPayload: false,
      invalidFormatName: false,
      payload: [],
      ...data,
    });
  }

  static fromSchema(schema: ExtractedContentSchema) {
    const payload = schema.payload.map(field => {
      return {
        id: field.id,
        name: field.name,
        hasError: false,
        isInvalidFormatName: false,
        isDuplicatedFieldName: false,
        isEmptyFieldName: false,
        duplicatedSubFieldNames: [],
        invalidFormatSubFieldNames: [],
      };
    }) as ExtractedContentSchemaPayloadValidationResult[];

    return new ExtractedContentSchemaValidationResultAccessor({
      hasError: false,
      emptyName: false,
      missingPayload: false,
      invalidFormatName: false,
      payload,
    });
  }
}

export class ExtractedContentSchemaValidator {
  normalizeFieldName(name: string) {
    return normalizeExtractedContentSchemaFieldName(name);
  }

  validateExtractedContentSchema(
    schema?: ExtractedContentSchema
  ): ExtractedContentSchemaValidationResult {
    if (schema === undefined) {
      return ExtractedContentSchemaValidationResultAccessor.create().data;
    }
    const accessor = new ExtractedContentSchemaAccessor(schema);
    const validSchemaNameRegex = /^[A-Za-z][ A-Za-z_0-9]*$/;

    const validFieldNameRegex = /^[A-Za-z][A-Za-z0-9 _-]*$/;

    const createFieldNameCounter = (names: string[]) => {
      const nameCounter = {} as Record<string, number>;
      const normalizedNameCounter = {} as Record<string, number>;

      names.forEach(name => {
        const normalizedName = this.normalizeFieldName(name);
        nameCounter[name] =
          nameCounter[name] === undefined ? 1 : nameCounter[name] + 1;
        normalizedNameCounter[normalizedName] =
          normalizedNameCounter[normalizedName] === undefined
            ? 1
            : normalizedNameCounter[normalizedName] + 1;
      });

      return [nameCounter, normalizedNameCounter];
    };
    const [fieldNameCounter, fieldNormalizedNameCounter] =
      createFieldNameCounter(schema.payload.map(field => field.name));

    let hasError = false;
    let payloadHasError = false;
    let emptyName = false;
    let missingField = false;
    let invalidFormatName = false;

    if (schema.name === undefined || schema.name.trim().length === 0) {
      emptyName = true;
      hasError = true;
    }

    if (!schema.name.match(validSchemaNameRegex)) {
      hasError = true;
      invalidFormatName = true;
    }

    if (schema.payload.length === 0) {
      missingField = true;
      payloadHasError = true;
    }

    const payloadResult = schema.payload.map(field => {
      const normalizeFieldName = this.normalizeFieldName(field.name);
      let hasError = false;
      let isInvalidFormatName = false;
      let isDuplicatedFieldName = false;
      let isEmptyFieldName = false;
      const duplicatedSubFieldNames = [] as string[];
      const invalidFormatSubFieldNames = [] as string[];

      if (field.name.trim().length === 0) {
        isEmptyFieldName = true;
        hasError = true;
      }

      if (
        fieldNameCounter[field.name] > 1 ||
        fieldNormalizedNameCounter[normalizeFieldName] > 1
      ) {
        isDuplicatedFieldName = true;
        hasError = true;
      }

      if (
        !field.name.trim().match(validFieldNameRegex) ||
        normalizeFieldName.trim().length === 0
      ) {
        isInvalidFormatName = true;
        hasError = true;
      }

      if (
        field.type === ExtractedContentSchemaType.FieldGroup &&
        field.definitionId !== undefined
      ) {
        const definition = accessor.findDefinitionById(field.definitionId);
        if (definition !== undefined) {
          const [definitionNameCounter, definitionNormalizedNameCounter] =
            createFieldNameCounter(definition.fields.map(field => field.name));

          definition.fields.forEach(subField => {
            if (
              definitionNameCounter[subField.name] > 1 ||
              definitionNormalizedNameCounter[
                this.normalizeFieldName(subField.name)
              ] > 1
            ) {
              duplicatedSubFieldNames.push(subField.name);
              hasError = true;
            }

            if (
              !subField.name.trim().match(validFieldNameRegex) ||
              subField.name.trim().length === 0
            ) {
              invalidFormatSubFieldNames.push(subField.name);
              hasError = true;
            }
          });
        }
      }

      return {
        id: field.id,
        name: field.name,
        hasError,
        isInvalidFormatName,
        isDuplicatedFieldName,
        isEmptyFieldName,
        duplicatedSubFieldNames,
        invalidFormatSubFieldNames,
      };
    }) as ExtractedContentSchemaPayloadValidationResult[];

    payloadHasError =
      payloadHasError || payloadResult.some(result => result.hasError);

    return {
      hasError: hasError || payloadHasError,
      emptyName,
      missingPayload: missingField,
      invalidFormatName,
      payload: payloadResult,
    };
  }

  stripExtractedContentSchema(schema: ExtractedContentSchema) {
    // Remove definitionId if it is not a table
    const payload = schema.payload.map(field => {
      if (field.type !== ExtractedContentSchemaType.FieldGroup) {
        return {
          ...field,
          definitionId: undefined,
        };
      }
      return field;
    });

    const definitions = schema.definitions.filter(definition => {
      const hasPayload = payload.some(
        field => field.definitionId === definition.id
      );
      return hasPayload;
    });

    return {
      ...schema,
      definitions,
      payload,
    };
  }
}
