@import "../../styles/variables.scss";

.login-form {
  position: absolute;
  width: 350px;

  top: 50vh;
  left: 50vw;
  margin-top: -176px;
  margin-left: -175px;

  @media only screen and (max-width: 350px) {
    width: calc(100% - 20px); // stylelint-disable-line
    margin-left: 10px;
    margin-right: 10px;
    left: 0;
  }

  .logo {
    background-image: url("/logo-color.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 36px;
    margin-bottom: 20px;
  }

  .link {
    text-align: right;
    font-size: 14px;
    margin-bottom: 14px;
  }

  > :global(.ms-TextField) {
    height: 82px;
  }

  .button {
    text-align: right;
  }
}
