.split-view {
  flex: 1 1 0;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
}

.split-view-left {
  flex: 2 1 0;
  display: flex;
  flex-direction: column;
}
.page-header {
  padding: 20px 20px 20px 20px;
}

.command-bar {
  display: flex;
  flex-direction: row;
}

.command-bar > button {
  margin-left: 6px;
}

.info-button {
  margin-right: 8px;
}
.content {
  flex: 1 1 0;
  padding: 16px 0px;
  overflow-y: auto;
  margin-left: 20px;
  margin-right: 20px;
}

.image-view-container {
  flex: 1;
  background: #faf9f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 200px;
}
.image-viewer {
  flex: 1;
}
.extraction-reviewer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-list-value-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .content-list-value-field-row {
    display: flex;
    flex-direction: row;
  }

  .content-list-value-field-add-button {
    margin-top: 8px;
    margin-left: -4px;
    padding-left: 0px;
  }
}

.teaching-bubble-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
