import {
  EXPORT_MAXIMUM_POLLING_TIME_PER_EXTRACTION_RESULT,
  EXPORT_POLLING_INTERVAL,
} from "../../constants/layout";
import errors from "../../errors";
import { Sort } from "../../types/api";
import {
  ExtractionFilterableField,
  GetExtractionResp,
  GetExtractionResultResp,
  ListAdditionalImagesResp,
  ListProcessingExtractionsResp,
  PaginatedExtractions,
  exportExtractionsRespSchema,
  getExtractionRespSchema,
  getExtractionResultRespSchema,
  listAdditionalImagesRespSchema,
  listProcessingExtractionsRespSchema,
  paginatedExtractionsSchema,
} from "../../types/extraction";
import { ApiClientConstructor, _BaseApiClient } from "../base";

type ListExtractionArgs = {
  workspaceId: string;
  size: number;
  offset: number;
  fileName?: string;
  sort?: Sort<ExtractionFilterableField>[];
};

type ListProcessingExtractionsArgs = {
  workspaceId: string;
};

const ApiSortFieldByExtractionsFilterableField: Record<
  ExtractionFilterableField,
  string
> = {
  createdAt: "created_at",
  fileName: "file_name",
};

type GetExtractionArgs = {
  extractionId: string;
};

type GetExtractionResultArgs = {
  extractionResultId: string;
};

type ExportExtractionsArgs = {
  workspaceId: string;
  resultIndexesByExtractionId: Record<string, number[]>;
};

type ExportAllExtractionsArgs = {
  workspaceId: string;
};

type DeleteExtractionsArgs = {
  extractionIds: string[];
};

type DeleteAllExtractionsArgs = {
  workspaceId: string;
};

type DeleteExtractionResultsInInfoArgs = {
  resultKeys: {
    extractionId: string;
    pageNumber: number;
    sliceNumber: number;
  }[];
};

type DeleteExtractionResultsArgs = {
  extractionResultIds: string[];
};

type ListAdditionalImagesArgs = {
  extractionResultId: string;
  size: number;
  offset: number;
};

export interface ExtractionApiClient {
  listExtractions: (args: ListExtractionArgs) => Promise<PaginatedExtractions>;
  listProcessingExtractions: (
    args: ListProcessingExtractionsArgs
  ) => Promise<ListProcessingExtractionsResp>;
  getExtraction: (args: GetExtractionArgs) => Promise<GetExtractionResp>;
  getExtractionResult: (
    args: GetExtractionResultArgs
  ) => Promise<GetExtractionResultResp>;
  exportExtractions: (args: ExportExtractionsArgs) => Promise<Response>;
  exportAllExtractions: (args: ExportAllExtractionsArgs) => Promise<Response>;
  deleteExtractions: (args: DeleteExtractionsArgs) => Promise<void>;
  deleteAllExtractions: (args: DeleteAllExtractionsArgs) => Promise<void>;
  deleteExtractionResultsInInfo: (
    args: DeleteExtractionResultsInInfoArgs
  ) => Promise<void>;
  deleteExtractionResults: (args: DeleteExtractionResultsArgs) => Promise<void>;
  listAdditionalImages: (
    args: ListAdditionalImagesArgs
  ) => Promise<ListAdditionalImagesResp>;
}

export function withExtractionApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async listExtractions(
      args: ListExtractionArgs
    ): Promise<PaginatedExtractions> {
      const { workspaceId, size, offset, fileName, sort } = args;
      return this.lambda(
        "extraction:list",
        {
          workspace_id: workspaceId,
          file_name: fileName,
          page_args: {
            size,
            offset,
          },
          sort: sort?.map(({ field, order }) => ({
            field: ApiSortFieldByExtractionsFilterableField[field],
            order,
          })),
        },
        paginatedExtractionsSchema
      );
    }

    async listProcessingExtractions(
      args: ListProcessingExtractionsArgs
    ): Promise<ListProcessingExtractionsResp> {
      const { workspaceId } = args;
      return this.lambda(
        "workspace:list-processing",
        {
          workspace_id: workspaceId,
        },
        listProcessingExtractionsRespSchema
      );
    }

    async getExtraction(args: GetExtractionArgs): Promise<GetExtractionResp> {
      const { extractionId } = args;
      return this.lambda(
        "extraction:get",
        {
          extraction_id: extractionId,
        },
        getExtractionRespSchema
      );
    }

    async getExtractionResult(
      args: GetExtractionResultArgs
    ): Promise<GetExtractionResultResp> {
      const { extractionResultId } = args;
      return this.lambda(
        "extraction_result:get",
        {
          extraction_result_id: extractionResultId,
        },
        getExtractionResultRespSchema
      );
    }

    async exportExtractions(args: ExportExtractionsArgs): Promise<Response> {
      return this._exportExtractions({
        workspace_id: args.workspaceId,
        extraction_results: args.resultIndexesByExtractionId,
      });
    }

    async exportAllExtractions(
      args: ExportAllExtractionsArgs
    ): Promise<Response> {
      return this._exportExtractions({
        workspace_id: args.workspaceId,
      });
    }

    async _exportExtractions(rawArgs: any): Promise<Response> {
      const { downloadUrl, extractionResultCount } = await this.lambda(
        "extraction:export",
        rawArgs,
        exportExtractionsRespSchema,
        null
      );
      let timeElapsed = 0;
      const maxPollingTime =
        EXPORT_MAXIMUM_POLLING_TIME_PER_EXTRACTION_RESULT *
        extractionResultCount;
      while (true) {
        const response = await fetch(downloadUrl);
        if (response.ok) {
          return response;
        }
        if (timeElapsed > maxPollingTime) {
          throw errors.ExportTimeout;
        }
        await new Promise(r => setTimeout(r, EXPORT_POLLING_INTERVAL));
        timeElapsed += EXPORT_POLLING_INTERVAL;
      }
    }

    async deleteExtractions(args: DeleteExtractionsArgs): Promise<void> {
      const { extractionIds } = args;

      await this.lambda(
        "extraction:delete",
        this.injectOptionalFields(
          {
            extraction_ids: extractionIds,
          },
          {}
        )
      );
    }

    async deleteAllExtractions(args: DeleteAllExtractionsArgs): Promise<void> {
      const { workspaceId } = args;

      await this.lambda(
        "extraction:delete-all",
        this.injectOptionalFields(
          {
            workspace_id: workspaceId,
          },
          {}
        )
      );
    }

    // NOTE: this is api for deleting results stored in extraction info json (old data)
    async deleteExtractionResultsInInfo(
      args: DeleteExtractionResultsInInfoArgs
    ): Promise<void> {
      const { resultKeys } = args;

      await this.lambda(
        "extraction:delete-results",
        this.injectOptionalFields(
          {
            result_keys: resultKeys.map(key => ({
              extraction_id: key.extractionId,
              page_number: key.pageNumber,
              slice_number: key.sliceNumber,
            })),
          },
          {}
        )
      );
    }

    // NOTE: this is api for deleting results stored in extraction result model (new data)
    async deleteExtractionResults(
      args: DeleteExtractionResultsArgs
    ): Promise<void> {
      const { extractionResultIds } = args;

      await this.lambda(
        "extraction_result:delete",
        this.injectOptionalFields(
          {
            extraction_result_ids: extractionResultIds,
          },
          {}
        )
      );
    }
    async listAdditionalImages(
      args: ListAdditionalImagesArgs
    ): Promise<ListAdditionalImagesResp> {
      const { extractionResultId, size, offset } = args;
      return this.lambda(
        "extraction_result:list-additional-images",
        {
          extraction_result_id: extractionResultId,
          page_args: {
            size,
            offset,
          },
        },
        listAdditionalImagesRespSchema
      );
    }
  };
}
