import { BoundingBox } from "../types/boundingBox";
import { Vertex } from "../types/vertex";
import { AnchorToolHandler } from "./anchor_tool";
import { DetectionRegionToolHandler } from "./detection_region_tool";
import { FieldToolHandler } from "./field_tool";
import { SelectToolHandler } from "./select_tool";
import { CanvasStore } from "./store";
import { bbox2polygon } from "./utils";

export class BaseInteractionHandler {
  ref: HTMLElement | null = null;
  store: CanvasStore | null = null;
  get origin(): Vertex {
    if (this.ref) {
      const bbox = this.ref.getBoundingClientRect();
      return { x: bbox.left, y: bbox.top };
    } else {
      return { x: 0, y: 0 };
    }
  }

  install = (canvas: HTMLCanvasElement) => {
    this.ref = canvas;
    canvas.style.cursor = this.cursorStyle();
    canvas.addEventListener("touchstart", this.handleMouseDown);
    canvas.addEventListener("touchend", this.handleMouseUp);
    canvas.addEventListener("touchmove", this.handleMouseMove);
    canvas.addEventListener("mousedown", this.handleMouseDown);
    canvas.addEventListener("mouseup", this.handleMouseUp);
    canvas.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("keydown", this.handleKeyDown);
  };

  uninstall(canvas: HTMLCanvasElement) {
    this.ref = null;
    canvas.style.cursor = "default";
    canvas.removeEventListener("touchstart", this.handleMouseDown);
    canvas.removeEventListener("touchend", this.handleMouseUp);
    canvas.removeEventListener("touchmove", this.handleMouseMove);
    canvas.removeEventListener("mousedown", this.handleMouseDown);
    canvas.removeEventListener("mouseup", this.handleMouseUp);
    canvas.removeEventListener("mousemove", this.handleMouseMove);
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  setStore = (store: CanvasStore) => {
    this.store = store;
  };

  handleMouseUp = (_: any) => {};
  handleMouseDown = (_: any) => {};
  handleMouseMove = (_: any) => {};
  handleKeyDown = (_: any) => {};

  cursorStyle = () => "default";

  getCoord = (e: MouseEvent): Vertex => {
    return {
      x: e.clientX - this.origin.x,
      y: e.clientY - this.origin.y,
    };
  };

  isVertexInPolygon = (vertex: Vertex, polygon: Vertex[]): boolean => {
    let inside = false;
    const { x, y } = vertex;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i].x,
        yi = polygon[i].y;
      const xj = polygon[j].x,
        yj = polygon[j].y;

      const intersect =
        // eslint-disable-next-line no-mixed-operators
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) {
        inside = !inside;
      }
    }

    return inside;
  };

  isVertexInBbox = (vertex: Vertex, bbox: BoundingBox): boolean => {
    return this.isVertexInPolygon(vertex, bbox2polygon(bbox));
  };

  isVertexNearPoint = (
    vertex: Vertex,
    center: Vertex,
    radius: number
  ): boolean => {
    return Math.hypot(vertex.x - center.x, vertex.y - center.y) <= radius;
  };
}

export type InteractionHandler =
  | SelectToolHandler
  | AnchorToolHandler
  | FieldToolHandler
  | DetectionRegionToolHandler;
