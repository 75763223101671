import * as Sentry from "@sentry/browser";
import * as React from "react";

import { AppConfig } from "../../config";
import ErrorPlaceholder from "../ErrorPlaceholder";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  eventId?: string;
}

export default class SentryBoundary extends React.PureComponent<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    if (AppConfig.sentry.dsn) {
      Sentry.init({
        ...AppConfig.sentry,
        environment: "portal",
      });
    }
  }

  componentDidCatch(error: Error, errorInfo: { [key: string]: any }) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  onReportClicked = () => {
    const { eventId } = this.state;
    Sentry.showReportDialog({ eventId });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <ErrorPlaceholder messageId="sentry.error_occurred">
          <DefaultButton
            className={styles["report-error-button"]}
            onClick={this.onReportClicked}
            iconProps={{ iconName: "ReportWarning" }}
            textId={"sentry.report"}
          />
        </ErrorPlaceholder>
      );
    }
    return children;
  }
}
