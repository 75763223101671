import * as yup from "yup";

import { AuditLogJob, auditLogJobSchema } from "../../types/auditLog";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface AuditLogApiClient {
  listAuditLogJobs: (resourceOwnerId?: string) => Promise<AuditLogJob[]>;
  generateAuditLogCsv: (
    year: number,
    month: number,
    resourceOwnerId?: string
  ) => Promise<void>;
}

export function withAuditLogApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async generateAuditLogCsv(
      year: number,
      month: number,
      resourceOwnerId?: string
    ): Promise<void> {
      return this.lambda("audit-log:generate", {
        year: year,
        month: month,
        resource_owner_id: resourceOwnerId,
      });
    }

    async listAuditLogJobs(resourceOwnerId?: string): Promise<AuditLogJob[]> {
      return this.lambda(
        "audit-log:list",
        { resource_owner_id: resourceOwnerId },
        yup.array(auditLogJobSchema.defined()).defined()
      );
    }
  };
}
