import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { ButtonId } from "../../constants/buttonids";
import { IconButton } from "../IconButton";
import styles from "./styles.module.scss";

export function OverlayPaginator(props: {
  currentPage: number;
  totalPage: number;
  onClickPrevPage: () => void;
  onClickNextPage: () => void;
  pageNumberLabelId: string;
}) {
  const {
    currentPage,
    totalPage,
    onClickPrevPage,
    onClickNextPage,
    pageNumberLabelId,
  } = props;

  return (
    <div className={styles.container}>
      <IconButton
        id={ButtonId.OverlayPaginatorPrevButton}
        iconName="ChevronLeft"
        onClick={onClickPrevPage}
        disabled={currentPage === 1}
      />
      <div className={styles.text}>
        <div>
          <FormattedMessage
            id={pageNumberLabelId}
            values={{
              current: currentPage,
              total: totalPage,
            }}
          />
        </div>
      </div>
      <IconButton
        iconName="ChevronRight"
        onClick={onClickNextPage}
        disabled={currentPage === totalPage}
      />
    </div>
  );
}
