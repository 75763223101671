.styled-mission-bar {
  margin-left: -20px;
  margin-right: -20px;
}
.split-view {
  width: 100%;
  height: 100%;
}

.fsl-schema-viewer {
  padding: 0px 20px;
}

.no-field-panel {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  padding-top: 40px;

  .no-field-panel-help-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #605e5c;
  }
}
