.swap-month-day {
  .choice-group {
    :global(.ms-ChoiceField) {
      border: 1px solid #d2d0ce;
      border-radius: 5px;

      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 10px;
    }
  }
}

.keep-only-one-language {
  .choice-group {
    margin-bottom: 8px;
    :global(.ms-ChoiceFieldGroup-flexContainer) {
      justify-content: space-between;
      display: flex;
    }

    :global(.ms-ChoiceField) {
      display: inline-block;
      border: 1px solid #d2d0ce;
      border-radius: 5px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 10px;
      width: 232px;
    }
  }

  .hint-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #a19f9d;
  }
}
