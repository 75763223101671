import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import {
  DetectMultiDocumentDocument,
  DetectMultiDocumentReport,
} from "../../models";
import { DetectionTest } from "../DetectionTest";
import {
  BodyCell,
  DetectionTestReportTable,
  HeaderCell,
  Row,
  ValueCell,
  formatConfidence,
} from "../DetectionTestReportTable";
import styles from "./styles.module.scss";

interface Props {
  onSelectImage: (file?: File) => void;
  testReport?: DetectMultiDocumentReport;
}

function convertToJSON(testReport: DetectMultiDocumentReport) {
  return {
    status: "ok",
    documents: testReport.documents,
  };
}

function HeaderContent() {
  return (
    <>
      <HeaderCell
        headerId="detect_documents_test_table.header.type"
        columnClassName={styles["name-column"]}
      />
      <HeaderCell headerId="detect_documents_test_table.header.bbox" />
      <HeaderCell
        headerId="detect_documents_test_table.header.type_score"
        columnClassName={styles["confidence-column"]}
      />
      <HeaderCell
        headerId="detect_documents_test_table.header.bbox_score"
        columnClassName={styles["confidence-column"]}
      />
    </>
  );
}

interface RowContentProps {
  document: DetectMultiDocumentDocument;
}

function RowContent(props: RowContentProps) {
  const { document } = props;

  return (
    <>
      <BodyCell text={document.type} columnClassName={styles["name-column"]} />
      <ValueCell value={`[${document.bbox.join(", ")}]`} />
      <ValueCell
        value={formatConfidence(document.type_score)}
        columnClassName={styles["confidence-column"]}
      />
      <ValueCell
        value={formatConfidence(document.bbox_score)}
        columnClassName={styles["confidence-column"]}
      />
    </>
  );
}

interface TableContentProps {
  documents?: DetectMultiDocumentDocument[];
}

function TableContent(props: TableContentProps) {
  const { documents } = props;

  return (
    <>
      {documents &&
        documents.map((document, index) => (
          <Row
            content={
              <>
                <RowContent document={document} />
              </>
            }
            key={`field/${index}`}
          />
        ))}
    </>
  );
}

function _DetectMultiDocumentTest(props: Props) {
  const { onSelectImage, testReport } = props;

  const { reportTable, previewContent } = React.useMemo(() => {
    const headerContent = (
      <>
        <HeaderContent />
      </>
    );

    const tableContent = (
      <>
        <TableContent documents={testReport?.documents} />
      </>
    );
    const previewContent = (
      <>
        <PreviewContent warpedImage={testReport?.warped_image ?? ""} />
      </>
    );

    const reportTable = (
      <>
        <DetectionTestReportTable
          headerContent={headerContent}
          tableContent={tableContent}
        />
      </>
    );

    return { reportTable, previewContent };
  }, [testReport]);

  const reportVisible = testReport !== undefined;
  const jsonContent = testReport
    ? JSON.stringify(convertToJSON(testReport), null, 2)
    : "";

  const childProps = {
    onSelectImage,
    previewContent,
    reportVisible,
    reportTable,
    jsonContent,
  };

  return <DetectionTest {...childProps} />;
}

interface PreviewContentProps {
  warpedImage: string;
}

function PreviewContent(props: PreviewContentProps) {
  const { warpedImage } = props;

  return (
    <>
      <h1>
        <FormattedMessage id="ocr_test.warped_image" />
      </h1>
      <div className={styles["warped-image-wrapper"]}>
        <img src={warpedImage} alt="preview" />
      </div>
    </>
  );
}

export const DetectMultiDocumentTest = React.memo(_DetectMultiDocumentTest);
export default DetectMultiDocumentTest;
