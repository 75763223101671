import * as React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { useAppActionCreator } from "../actions/app";
import { RootState } from "../redux/types";

export function useTextFieldChange(cb: (value: string) => void) {
  return React.useCallback(
    (
      _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      cb(value || "");
    },
    [cb]
  );
}

export function useLeftBar() {
  const isLeftBarCollapsed = useSelector(
    (state: RootState) => state.app.isLeftBarCollapsed
  );
  const isLeftBarCollapsedSmallScreen = useSelector(
    (state: RootState) => state.app.isLeftBarCollapsedSmallScreen
  );
  const isSmallScreen = useMediaQuery({ query: "(max-width: 848px)" });

  const { toggleLeftBarCollapsed, toggleLeftBarCollapsedSmallScreen } =
    useAppActionCreator();

  const isCollapsed = isSmallScreen
    ? isLeftBarCollapsedSmallScreen
    : isLeftBarCollapsed;

  const toggleCollapsed = isSmallScreen
    ? toggleLeftBarCollapsedSmallScreen
    : toggleLeftBarCollapsed;

  return React.useMemo(
    () => ({
      isCollapsed,
      toggleCollapsed,
      isSmallScreen,
    }),
    [isCollapsed, toggleCollapsed, isSmallScreen]
  );
}
