import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { useFormatterEditor } from "../../contexts/formatterEditor";
import styles from "./styles.module.scss";

interface FormatterActionCardProps {
  name: string;
  title: string;
  desc: string;
}

function _FormatterActionCard(props: FormatterActionCardProps) {
  const { title, desc, name } = props;
  const { addStep } = useFormatterEditor();

  const onClick = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation();
      addStep(name);
    },
    [name, addStep]
  );

  return (
    <div className={styles["formatter-step-card"]} onClick={onClick}>
      <div className={styles["title"]}>
        <FormattedMessage id={title} />
      </div>
      <div className={styles["desc"]}>
        <FormattedMessage id={desc} />
      </div>
    </div>
  );
}

export const FormatterActionCard = React.memo(_FormatterActionCard);

function _FormatterActionSelector() {
  const { supportedActions } = useFormatterEditor();

  return (
    <div className={styles["formatter-step-selector"]}>
      <div className={styles["heading"]}>
        <FormattedMessage id="formatter.step_selector.title" />
      </div>
      <div className={styles["grid"]}>
        {supportedActions.map((action, index) => (
          <FormatterActionCard
            key={index}
            name={action.action}
            title={action.title}
            desc={action.desc}
          />
        ))}
      </div>
    </div>
  );
}

const FormatterActionSelector = React.memo(_FormatterActionSelector);

export default FormatterActionSelector;
