import { findLastIndex } from "lodash";
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  Location,
  NavigationType,
  To,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";

interface NavigationStackContextValue {
  pop: () => void;
  popUntil: (
    predicate: (location: Location) => boolean,
    fallbackTo?: To
  ) => void;
  stack: Location[];
}

const NavigationStackContext = React.createContext<NavigationStackContextValue>(
  null as any
);

interface NavigationStackProviderProps {
  children?: ReactNode;
}

const NavigationStackProvider = (
  props: NavigationStackProviderProps
): ReactElement => {
  const { children } = props;

  const stack = useRef<Location[]>([]);

  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    // catch duplicate effect
    if (
      stack.current.length > 0 &&
      stack.current[stack.current.length - 1].key === location.key
    ) {
      return;
    }
    switch (navigationType) {
      case NavigationType.Pop: {
        // catch forward case
        if (
          stack.current.length <= 1 ||
          stack.current[stack.current.length - 2].key !== location.key
        ) {
          stack.current.push(location);
          break;
        }
        // actual back case:
        stack.current.pop();
        break;
      }
      case NavigationType.Push: {
        stack.current.push(location);
        break;
      }
      case NavigationType.Replace: {
        stack.current.pop();
        stack.current.push(location);
        break;
      }
    }
  }, [location, navigationType]);

  const navigate = useNavigate();
  const pop = useCallback(() => {
    if (stack.current.length > 1) {
      // previous page is still our app
      navigate(-1);
      return;
    }
    // previous page is not our app, go home
    navigate("/", { replace: true });
  }, [navigate]);

  const popUntil = useCallback(
    (predicate: (location: Location) => boolean, fallbackTo?: To) => {
      // FIXME: use Array.findLastIndex once we upgrade typescript and can use ES2023
      const targetIdx = findLastIndex(stack.current.slice(0, -1), predicate);
      if (targetIdx < 0) {
        // not found, go to fallback
        stack.current = [];
        navigate(fallbackTo ?? "/");
        return;
      }
      // pop to target
      const dist = stack.current.length - 1 - targetIdx;
      stack.current = stack.current.slice(0, targetIdx);
      navigate(-dist);
    },
    [navigate, stack]
  );

  const contextValue = useMemo((): NavigationStackContextValue => {
    return {
      pop,
      popUntil,
      stack: stack.current,
    };
  }, [pop, popUntil, stack]);

  return (
    <NavigationStackContext.Provider value={contextValue}>
      {children}
    </NavigationStackContext.Provider>
  );
};

export default NavigationStackProvider;

export const useNavigationStack = (): NavigationStackContextValue => {
  return React.useContext(NavigationStackContext);
};
