@import "../../styles/variables.scss";

$border-color: #edebe9;

.container {
  padding: 20px;
  display: flex;
  flex-direction: row;
  color: #201f1e;

  table.model-version-table {
    flex-grow: 1 2;
    text-align: left;
    border-collapse: collapse;
    width: calc(100% - 389px);

    tr {
      border-bottom: 1px solid $border-color;
    }

    th {
      font-size: 14px;
      font-weight: 600;
      height: 51px;

      &.created-at-column {
        > span {
          cursor: pointer;
          > i {
            font-size: 12px;
            margin-left: 4px;
            color: #605e5c;
          }
        }
      }

      &.actions-column {
        padding-left: 10px;
      }
    }

    td {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &.tag-column {
        max-width: 296px;
        width: 296px;
      }
      &.created-at-column {
        max-width: 216px;
        width: 216px;
      }

      &.labels-column {
        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 51px;
          margin-right: 24px;

          > div {
            display: -webkit-box;
            overflow: hidden;
            line-height: 20px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }

      &.actions-column {
        width: 159px;
      }

      button {
        width: 159px;
        font-size: 14px;
        font-weight: 600;

        :global(i.ms-Icon) {
          margin-right: 8px;
        }
      }

      button:disabled {
        border: 0;

        :global(div.ms-Spinner) {
          margin-right: 8px;
          filter: grayscale(1);
        }
      }
    }
  }

  div.info {
    flex-shrink: 0;
    margin-top: 4px;
    margin-left: 24px;
    width: 365px;
  }
}

.no-interaction {
  pointer-events: none;
}
