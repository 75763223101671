import { Plan, planListSchema } from "../../types/plan";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface PlanAPIClient {
  listPlan: () => Promise<Plan[]>;
}

export function withPlanApi<TBase extends ApiClientConstructor<_BaseApiClient>>(
  Base: TBase
) {
  return class extends Base {
    async listPlan(): Promise<Plan[]> {
      return this.lambda("plan:list", {}, planListSchema);
    }
  };
}
