import * as yup from "yup";

import { vertexSchema } from "./vertex";

export const anchorSchema = yup.object({
  id: yup.string().defined(),
  vertices: yup.array(vertexSchema).defined(),
  is_enabled: yup.boolean().defined(),
});

export type Anchor = yup.InferType<typeof anchorSchema>;
