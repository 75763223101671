import {
  Checkbox,
  ChoiceGroup,
  Dropdown,
  IChoiceGroupOption,
  IDropdown,
  IDropdownOption,
  IRenderFunction,
  ISelectableDroppableTextProps,
  Icon,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import {
  FormatterDropdownOptionField,
  useFormatterEditor,
} from "../../contexts/formatterEditor";
import { useLocale } from "../../contexts/locale";
import { useExtractorFieldSchema } from "../../hooks/extractorFieldSchema";
import {
  FormatterActionType,
  FormatterMatchingConditionOperator,
  FormatterStep,
} from "../../types/formatter";
import FormatterActionConfigPanel from "../FormatterActionConfigPanel";
import { IconTag, IconTagProps } from "../IconTag";
import ShortSpinner from "../ShortSpinner";
import styles from "./styles.module.scss";

interface FormatterFieldSelectorSectionProps {
  handle: FormatterStepEditorHandle;
  nextHandle?: FormatterStepEditorHandle;
}

function onRenderDropdownList(
  props?: ISelectableDroppableTextProps<IDropdown, HTMLDivElement>,
  defaultRender?: IRenderFunction<
    ISelectableDroppableTextProps<IDropdown, HTMLDivElement>
  >
) {
  if (props !== undefined) {
    const { options } = props;
    if (
      options === undefined ||
      (Array.isArray(options) && options.length === 0)
    ) {
      return (
        <div className={styles["formatter-dropdown-field-list"]}>
          <p className={styles["empty"]}>
            <FormattedMessage id="formatter.step_editor.section.field_section.dropdown.empty" />
          </p>
        </div>
      );
    }
  }
  return (
    <div>
      <span>{defaultRender?.(props)}</span>
    </div>
  );
}

function onRenderDropdownError(
  tryAgainText?: string,
  onTryAgainClick?: () => void
) {
  return () => (
    <div className={styles["formatter-dropdown-field-list"]}>
      <p className={styles["error"]}>
        <FormattedMessage
          id="formatter.step_editor.section.field_section.dropdown.error"
          values={{
            try_again: (
              <button onClick={onTryAgainClick} className={styles["try-again"]}>
                {tryAgainText}
              </button>
            ),
          }}
        />
      </p>
    </div>
  );
}

function onRenderDropdownLoading() {
  return (
    <div className={styles["formatter-dropdown-field-list"]}>
      <ShortSpinner
        labelId="formatter.step_editor.section.field_section.dropdown.loading"
        labelPosition="bottom"
      />
    </div>
  );
}

enum SelectionFieldTagIconName {
  Formatter = "Repair",
  Prebuilt = "Database",
  Unavailable = "Warning",
}

export function onRenderSelectionFieldTags(tagProps?: IconTagProps[]) {
  return (
    <div className={styles["formatter-selection-field-tag-container"]}>
      {tagProps &&
        tagProps.map(props => {
          return <IconTag {...props} />;
        })}
    </div>
  );
}

export function useFormatterStepEditorSection() {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return React.useMemo(
    () => ({
      isExpanded,
      setIsExpanded,
    }),
    [isExpanded, setIsExpanded]
  );
}

type FormatterStepEditorHandle = ReturnType<
  typeof useFormatterStepEditorSection
>;

interface FormatterStepEditorSectionProps {
  title: string;
  desc: string;
  index: number;
  hideNextButton?: boolean;
  handle: FormatterStepEditorHandle;
  nextHandle?: FormatterStepEditorHandle;
  children: React.ReactNode;
  hasError?: boolean;
}

function _FormatterStepEditorSection(props: FormatterStepEditorSectionProps) {
  const {
    title,
    desc,
    index,
    children,
    hideNextButton,
    handle,
    nextHandle,
    hasError,
  } = props;
  const { isExpanded, setIsExpanded } = handle;
  const { localized } = useLocale();

  const onExpandButtonClicked = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation();
      setIsExpanded(!isExpanded);
    },
    [setIsExpanded, isExpanded]
  );

  const onNextButtonClicked = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation();
      setIsExpanded(false);
      if (nextHandle) {
        nextHandle.setIsExpanded(true);
      }
    },
    [setIsExpanded, nextHandle]
  );

  const baseClasses = React.useMemo(() => {
    const classes = [styles["formatter-step-editor-section"]];
    if (hasError) {
      classes.push(styles["formatter-step-editor-section-error"]);
    }
    if (!isExpanded) {
      classes.push(styles["cursor-pointer"]);
    }
    return classes.join(" ");
  }, [hasError, isExpanded]);

  return (
    <div
      className={baseClasses}
      onClick={(ev: React.MouseEvent<HTMLDivElement>) => {
        if (!isExpanded) {
          onExpandButtonClicked(ev);
        }
      }}
    >
      <div className={styles["section-header"]} onClick={onExpandButtonClicked}>
        <div className={styles["section-header-text"]}>
          <span> {index + 1}. </span>
          <FormattedMessage id={title} />
        </div>
        <Icon
          iconName={isExpanded ? "ChevronUp" : "ChevronDown"}
          className={styles["section-header-expand-button"]}
        />
      </div>
      <div className={styles["section-desc"]}>
        <FormattedMessage
          id={desc}
          values={{
            learn_more: (
              <a
                href="https://help.formx.ai/docs/output-formatter"
                target="_blank"
                rel="noopener noreferrer"
              >
                {localized(
                  "formatter.step_editor.section.condition_matcher.desc.learn_more"
                )}
              </a>
            ),
          }}
        />
      </div>
      {isExpanded && (
        <div className={styles["section-content"]}>
          {children}
          <div className={styles["section-footer"]}>
            {!hideNextButton && (
              <PrimaryButton
                text={localized("common.next")}
                onClick={onNextButtonClicked}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export const FormatterStepEditorSection = React.memo(
  _FormatterStepEditorSection
);

export function useFormatterFieldSelectorSection(
  props: FormatterFieldSelectorSectionProps
) {
  const { localized } = useLocale();
  const { handle, nextHandle } = props;
  const {
    extractorId,
    addInputSelection,
    removeInputSelection,
    selectedStep,
    selectedStepIndex,
    getInputFieldsDropdownOptions,
    validationResult,
  } = useFormatterEditor();
  const {
    isFailedToGetExtractorFieldSchema,
    getExtractorFieldSchemaTableByExtractorId,
    getExtractorFieldSchema,
  } = useExtractorFieldSchema();

  const hasExtractorFieldSchemaError = React.useMemo(
    () => isFailedToGetExtractorFieldSchema(extractorId),
    [extractorId, isFailedToGetExtractorFieldSchema]
  );

  const extractorFieldSchemaTable = React.useMemo(
    () => getExtractorFieldSchemaTableByExtractorId(extractorId),
    [extractorId, getExtractorFieldSchemaTableByExtractorId]
  );

  const inputSelectionFields = React.useMemo(() => {
    return selectedStep?.inputSelection.fields ?? [];
  }, [selectedStep]);

  const selectors = React.useMemo(() => {
    return inputSelectionFields.map(field => field.selector) ?? [];
  }, [inputSelectionFields]);

  const selectorsOnLoad = React.useRef<string[]>(selectors);

  const options = React.useMemo(() => {
    return getInputFieldsDropdownOptions(selectedStepIndex, {
      withSelectors: selectorsOnLoad.current,
    });
  }, [getInputFieldsDropdownOptions, selectedStepIndex]);

  const onChange = React.useCallback(
    (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option?.selected) {
        addInputSelection(option.key as string);
      } else {
        removeInputSelection(option?.key as string);
      }
    },
    [addInputSelection, removeInputSelection]
  );

  const isLoadingExtractorFieldSchema =
    !hasExtractorFieldSchemaError && extractorFieldSchemaTable === undefined;

  const onTryAgainClick = React.useCallback(() => {
    getExtractorFieldSchema(extractorId);
  }, [extractorId, getExtractorFieldSchema]);

  const hasValidationError = React.useMemo(() => {
    return (
      validationResult.steps[selectedStepIndex]?.missingInputFields.length >
        0 ?? false
    );
  }, [selectedStepIndex, validationResult.steps]);

  const { action } = selectedStep ?? {};

  const onRenderDropdownTitle = React.useCallback(
    (iDropdownOptions?: FormatterDropdownOptionField[]) => {
      const tagProps = iDropdownOptions?.map(dropdownOption => {
        const { key, text, isFormatterOutput } = dropdownOption;
        const hasError =
          validationResult.steps[selectedStepIndex]?.missingInputFields.find(
            selector => key === selector
          ) !== undefined;
        const iconName = hasError
          ? SelectionFieldTagIconName.Unavailable
          : isFormatterOutput
          ? SelectionFieldTagIconName.Formatter
          : SelectionFieldTagIconName.Prebuilt;
        const onDismiss = (event: React.MouseEvent<HTMLButtonElement>) => {
          removeInputSelection(key as string);
          event.stopPropagation();
        };
        return {
          iconName,
          text,
          onDismiss,
          hasError,
        } as IconTagProps;
      });
      return onRenderSelectionFieldTags(tagProps);
    },
    [removeInputSelection, selectedStepIndex, validationResult.steps]
  );

  const onRenderDropdownOption = React.useCallback(
    (
      props?: FormatterDropdownOptionField,
      defaultRender?: IRenderFunction<FormatterDropdownOptionField>
    ) => {
      if (typeof props !== "undefined") {
        const isUnavailable = validationResult.steps[
          selectedStepIndex
        ].missingInputFields.includes(props.key as string);
        if (isUnavailable) {
          const unavailableOptionText = localized(
            "formatter.step_editor.section.field_section.dropdown.unavailable_option",
            { text: props.text }
          );
          return (
            <>
              {defaultRender?.({
                ...props,
                text: unavailableOptionText,
              })}
            </>
          );
        }
      }
      return <>{defaultRender?.(props)}</>;
    },
    [localized, selectedStepIndex, validationResult.steps]
  );

  return React.useMemo(
    () => ({
      handle,
      nextHandle,
      action,
      hasValidationError,
      selectors,
      onChange,
      options,
      hasExtractorFieldSchemaError,
      isLoadingExtractorFieldSchema,
      onTryAgainClick,
      onRenderDropdownTitle,
      onRenderDropdownOption,
    }),
    [
      action,
      handle,
      hasValidationError,
      hasExtractorFieldSchemaError,
      isLoadingExtractorFieldSchema,
      nextHandle,
      onChange,
      onTryAgainClick,
      options,
      selectors,
      onRenderDropdownTitle,
      onRenderDropdownOption,
    ]
  );
}

export function FormatterFieldSelctorSectionImpl(
  props: ReturnType<typeof useFormatterFieldSelectorSection>
) {
  const { localized } = useLocale();
  const {
    action,
    hasValidationError,
    selectors,
    onChange,
    options,
    hasExtractorFieldSchemaError,
    isLoadingExtractorFieldSchema,
    onTryAgainClick,
    onRenderDropdownTitle,
    onRenderDropdownOption,
    ...rest
  } = props;
  return (
    <FormatterStepEditorSection
      title="formatter.step_editor.section.field_section.title"
      desc={`formatter.actions.${action}.field_selection.desc`}
      index={0}
      hasError={
        (hasValidationError || hasExtractorFieldSchemaError) &&
        !isLoadingExtractorFieldSchema
      }
      {...rest}
    >
      <>
        <div className={styles["formatter-field-selector"]}>
          <Dropdown
            label={localized(
              "formatter.step_editor.section.field_section.dropdown.title"
            )}
            options={options}
            onChange={onChange}
            multiSelect
            selectedKeys={selectors}
            styles={{
              dropdownItemHeader: {
                backgroundColor: "#F0FBF9",
                color: "#323130",
              },
            }}
            placeholder={localized(
              "formatter.step_editor.section.field_section.dropdown.placeholder"
            )}
            onRenderList={
              hasExtractorFieldSchemaError
                ? onRenderDropdownError(
                    localized(
                      "formatter.step_editor.section.field_section.dropdown.try_again"
                    ),
                    onTryAgainClick
                  )
                : isLoadingExtractorFieldSchema
                ? onRenderDropdownLoading
                : onRenderDropdownList
            }
            onRenderTitle={onRenderDropdownTitle}
            onRenderOption={onRenderDropdownOption}
          />
          {hasValidationError && !isLoadingExtractorFieldSchema && (
            <div className={styles["error"]}>
              <FormattedMessage id="formatter.step_editor.section.field_section.error" />
            </div>
          )}
        </div>
      </>
    </FormatterStepEditorSection>
  );
}

export function FormatterFieldSelectorSection(
  args: FormatterFieldSelectorSectionProps
) {
  const props = useFormatterFieldSelectorSection(args);
  return <FormatterFieldSelctorSectionImpl {...props} />;
}

function _FormatterActionSetupSection(props: {
  handle: FormatterStepEditorHandle;
  nextHandle?: FormatterStepEditorHandle;
}) {
  const { selectedStep } = useFormatterEditor();
  const action = selectedStep?.action ?? "";

  return (
    <FormatterStepEditorSection
      title="formatter.step_editor.section.action_setup.title"
      desc={`formatter.actions.${action}.action_setup.desc`}
      index={1}
      {...props}
    >
      <FormatterActionConfigPanel action={action as FormatterActionType} />
    </FormatterStepEditorSection>
  );
}

const FormatterActionSetupSection = React.memo(_FormatterActionSetupSection);

function _FormatterConditionMatcherSection(props: {
  handle: FormatterStepEditorHandle;
  nextHandle?: FormatterStepEditorHandle;
}) {
  const { localized } = useLocale();
  const {
    selectedStep,
    setSelectedMatchingConditionEnabled,
    getInputFieldsDropdownOptions,
    setSelectedMatchingConditionRule,
    validationResult,
    selectedStepIndex,
  } = useFormatterEditor();

  const enabled = selectedStep?.matchingCondition.enabled ?? false;

  const rule = React.useMemo(() => {
    const defaultRule = {
      selector: "",
      property: undefined,
      operator: FormatterMatchingConditionOperator.ExactMatch,
      value: "",
    };
    if (
      selectedStep === undefined ||
      selectedStep.matchingCondition.rules.length === 0
    ) {
      return defaultRule;
    }

    return selectedStep.matchingCondition.rules[0];
  }, [selectedStep]);
  const { selector, value, operator } = rule;

  const selectorsOnLoad = React.useRef<string[]>([selector]);

  const availableInputFields = React.useMemo(() => {
    return getInputFieldsDropdownOptions(selectedStepIndex, {
      withoutMultipleValueType: true,
      withSelectors: selectorsOnLoad.current,
    });
  }, [getInputFieldsDropdownOptions, selectedStepIndex]);

  const onEnabledChanged = React.useCallback(
    (
      ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ) => {
      ev?.stopPropagation();
      setSelectedMatchingConditionEnabled(checked ?? false);
    },
    [setSelectedMatchingConditionEnabled]
  );

  const availableOperators = React.useMemo(() => {
    const keys = Object.keys(FormatterMatchingConditionOperator);
    return keys.map(key => {
      const operator =
        FormatterMatchingConditionOperator[
          key as keyof typeof FormatterMatchingConditionOperator
        ];
      return {
        key: operator,
        text: localized(
          `formatter.step_editor.section.condition_matcher.operators.${operator}`
        ),
      };
    });
  }, [localized]);

  const onFieldChanged = React.useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option) {
        setSelectedMatchingConditionRule(option.key as string, operator, value);
      }
    },
    [operator, value, setSelectedMatchingConditionRule]
  );

  const onOperatorChanged = React.useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option) {
        setSelectedMatchingConditionRule(selector, option.key as string, value);
      }
    },
    [selector, value, setSelectedMatchingConditionRule]
  );

  const onValueChanged = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value !== undefined) {
        setSelectedMatchingConditionRule(selector, operator, value);
      }
    },
    [selector, operator, setSelectedMatchingConditionRule]
  );

  const isMultilineOperator = [
    FormatterMatchingConditionOperator.InList,
    FormatterMatchingConditionOperator.NotInList,
  ].includes(operator as FormatterMatchingConditionOperator);

  const hasError = React.useMemo(() => {
    return (
      validationResult.steps[selectedStepIndex]?.missingMatchingConditionFields
        .length > 0 ?? false
    );
  }, [selectedStepIndex, validationResult.steps]);

  const errorMessage = React.useMemo(() => {
    if (!hasError) {
      return;
    }

    return localized(
      "formatter.step_editor.section.condition_matcher.field_missing_error"
    );
  }, [localized, hasError]);

  return (
    <FormatterStepEditorSection
      title="formatter.step_editor.section.condition_matcher.title"
      desc="formatter.step_editor.section.condition_matcher.desc"
      index={2}
      hasError={hasError}
      {...props}
    >
      <div className={styles["formatter-condition-matcher"]}>
        <Checkbox
          label={localized(
            "formatter.step_editor.section.condition_matcher.enable_checkbox"
          )}
          checked={enabled}
          onChange={onEnabledChanged}
        />
        <div className={styles["condition-matcher-desc"]}>
          <FormattedMessage id="formatter.step_editor.section.condition_matcher.desc2" />
        </div>
        <Dropdown
          label={localized(
            "formatter.step_editor.section.condition_matcher.field_selection.label"
          )}
          placeholder={localized(
            "formatter.step_editor.section.condition_matcher.field_selection.placeholder"
          )}
          options={availableInputFields}
          selectedKey={selector}
          disabled={!enabled}
          onChange={onFieldChanged}
          errorMessage={errorMessage}
        />

        <Dropdown
          label={localized(
            "formatter.step_editor.section.condition_matcher.operator.label"
          )}
          placeholder={localized(
            "formatter.step_editor.section.condition_matcher.operator.placeholder"
          )}
          options={availableOperators}
          selectedKey={operator}
          disabled={!enabled}
          onChange={onOperatorChanged}
        />

        <TextField
          disabled={!enabled}
          placeholder={localized(
            "formatter.step_editor.section.condition_matcher.condition_value.placeholder"
          )}
          label={localized(
            isMultilineOperator
              ? "formatter.step_editor.section.condition_matcher.condition_value.list_label"
              : "formatter.step_editor.section.condition_matcher.condition_value.label"
          )}
          value={value}
          rows={7}
          multiline={isMultilineOperator}
          onChange={onValueChanged}
        />
      </div>
    </FormatterStepEditorSection>
  );
}

export const FormatterConditionMatcherSection = React.memo(
  _FormatterConditionMatcherSection
);

function _FormatterOutputSettingsSection(props: {
  handle: FormatterStepEditorHandle;
}) {
  const { localized } = useLocale();
  const {
    selectedStep,
    setSelectedOutputAsEnabled,
    selectedOutputAsFields,
    localizeSelector,
    setSelectedOutputAsFieldName,
    selectedStepIndex,
    validationResult,
  } = useFormatterEditor();

  const options = React.useMemo(
    () => [
      {
        key: "keep",
        text: localized(
          "formatter.step_editor.section.output_settings.keep_original_field_option"
        ),
      },
      {
        key: "new",
        text: localized(
          "formatter.step_editor.section.output_settings.new_field_option"
        ),
      },
    ],
    [localized]
  );

  const enabled = selectedStep?.outputAs?.enabled ?? false;

  const selectedKey = enabled ? "new" : "keep";

  const onFieldChoiceChanged = React.useCallback(
    (
      _?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      if (option) {
        setSelectedOutputAsEnabled(option.key === "new");
      }
    },
    [setSelectedOutputAsEnabled]
  );

  const hasError = React.useMemo(() => {
    return (
      (validationResult.steps[selectedStepIndex]?.duplicatedOutputFields
        .length > 0 ||
        validationResult.steps[selectedStepIndex]?.missingInputFields.length >
          0) ??
      false
    );
  }, [selectedStepIndex, validationResult.steps]);

  return (
    <FormatterStepEditorSection
      title="formatter.step_editor.section.output_settings.title"
      desc="formatter.step_editor.section.output_settings.desc"
      index={3}
      hideNextButton={true}
      hasError={hasError}
      {...props}
    >
      <div className={styles["formatter-output-settings"]}>
        <ChoiceGroup
          className={styles["output-choice-group"]}
          options={options}
          selectedKey={selectedKey}
          onChange={onFieldChoiceChanged}
        />
        <div className={styles["output-desc"]}>
          <FormattedMessage id="formatter.step_editor.section.output_settings.desc2" />
        </div>
        {selectedOutputAsFields.map(
          (
            { input, output }: { input: string; output: string },
            index: number
          ) => {
            return (
              <div className={styles["new-field"]} key={index}>
                <TextField
                  label={localized(
                    "formatter.step_editor.section.output_settings.new_field_label",
                    { source: localizeSelector(input) }
                  )}
                  value={output}
                  disabled={!enabled}
                  onChange={(
                    _event: React.FormEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                    value?: string
                  ) => {
                    setSelectedOutputAsFieldName(index, value as string);
                  }}
                  errorMessage={
                    validationResult.steps[
                      selectedStepIndex
                    ]?.duplicatedOutputFields.includes(output)
                      ? localized(
                          "formatter.step_editor.section.output_settings.duplicated_field_error"
                        )
                      : validationResult.steps[
                          selectedStepIndex
                        ].invalidOutputFields.includes(output)
                      ? localized(
                          "formatter.step_editor.section.output_settings.invalid_field_error"
                        )
                      : undefined
                  }
                />
              </div>
            );
          }
        )}
      </div>
    </FormatterStepEditorSection>
  );
}

export const FormatterOutputSettingsSection = React.memo(
  _FormatterOutputSettingsSection
);

interface FormatterStpeEditorProps {
  step: FormatterStep;
}

function _FormatterStepEditor(props: FormatterStpeEditorProps) {
  const { step } = props;
  const { localized } = useLocale();
  const { selectStep } = useFormatterEditor();

  const onDoneClicked = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation();
      selectStep(-1);
    },
    [selectStep]
  );

  const fieldSelectorHandle = useFormatterStepEditorSection();

  const { setIsExpanded: setFieldSelectorIsExpanded } = fieldSelectorHandle;

  React.useEffect(() => {
    setFieldSelectorIsExpanded(true);
  }, [setFieldSelectorIsExpanded]);

  const actionSetupHandle = useFormatterStepEditorSection();

  const conditionMatcherHandle = useFormatterStepEditorSection();
  const outputSettingsHandle = useFormatterStepEditorSection();

  return (
    <div className={styles["formatter-step-editor"]}>
      <div className={styles["editor-header"]}>
        <div className={styles["editor-header-text"]}>
          <FormattedMessage id={`formatter.actions.${step.action}.title`} />
        </div>
        <PrimaryButton
          type="submit"
          text={localized("common.done")}
          onClick={onDoneClicked}
        />
      </div>
      <div className={styles["sections"]}>
        <FormatterFieldSelectorSection
          handle={fieldSelectorHandle}
          nextHandle={actionSetupHandle}
        />
        <FormatterActionSetupSection
          handle={actionSetupHandle}
          nextHandle={conditionMatcherHandle}
        />
        <FormatterConditionMatcherSection
          handle={conditionMatcherHandle}
          nextHandle={outputSettingsHandle}
        />
        <FormatterOutputSettingsSection handle={outputSettingsHandle} />
      </div>
    </div>
  );
}

const FormatterStepEditor = React.memo(_FormatterStepEditor);
export default FormatterStepEditor;
