import * as React from "react";

import { useExtractorActionCreator } from "../actions/extractor";
import { useAppSelector } from "./redux";

export function useExtractorOptions() {
  const resourceOwnerId = useAppSelector(
    state => state.resourceOwner.resourceOwnerId
  );
  const { listExtractorOptions } = useExtractorActionCreator();
  const { extractorOptions, extractorOptionsError, isListingExtractorOptions } =
    useAppSelector(state => state.extractor);

  const isListingExtractorOptionsRef = React.useRef(isListingExtractorOptions);
  isListingExtractorOptionsRef.current = isListingExtractorOptions;

  React.useEffect(() => {
    if (
      resourceOwnerId &&
      extractorOptions === undefined &&
      isListingExtractorOptionsRef.current === false
    ) {
      listExtractorOptions(resourceOwnerId);
    }
  }, [resourceOwnerId, extractorOptions, listExtractorOptions]);

  return React.useMemo(
    () => ({
      extractorOptions,
      isFailedToFetchExtractorOptions: extractorOptionsError !== undefined,
    }),
    [extractorOptions, extractorOptionsError]
  );
}
