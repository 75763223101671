.container {
  padding: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.placeholder {
  flex: 1;
  display: flex;
  flex-flow: column;
  margin: 20px;
}
.options-panel {
  margin-top: 20px;
}

.extraction-mode {
  display: inline-block;
  padding: 8px;
  background: #effcfa;

  h3 {
    padding-bottom: 0;
    font-size: 16px;
    margin-right: 16px;
    display: inline;
  }

  button {
    font-size: 12px;
    color: #53b2a1;
  }
}

.data-container {
  flex: 1;
  display: flex;
}

.json-content {
  flex: 2;
  height: 100%;
  padding: 15px 20px 20px 20px;
  display: flex;
  flex-direction: column;
}

.image-content {
  flex: 1;
  display: flex;
  min-width: 100px;
  height: 100%;
  background-color: #faf9f8;
  position: relative;
}

.image-not-found {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.llm-prompt-container {
  overflow-y: auto;
  flex: 1 1 0;

  pre {
    padding: 20px;
    background: #faf9f8;
    margin-bottom: 10px;
    position: relative;
    white-space: pre-wrap;

    .copy-button {
      top: 0px;
      right: 0px;
      position: absolute;
    }
  }
}

.raw-json-container {
  overflow-y: auto;
  flex: 1 1 0;
}
.key-value-json-container {
  overflow-y: auto;
  flex: 1 1 0;
}
.action-button-group {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
