import { IDropdownOption, Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";

import { useConfirmModalActionCreator } from "../../actions/confirmModal";
import { WORKSPACE_PAGE_SIZE } from "../../constants/layout";
import { PrebuiltExtractors } from "../../constants/prebuiltExtractor";
import { WorkspaceListType } from "../../containers/WorkspaceList";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import WorkspaceFlowChart from "../../images/workspace-flow-chart.svg";
import { ConfirmModalType } from "../../types/confirmation";
import {
  extractorTypeOptions,
  mapExtractorTypeToMessageId,
} from "../../types/extractor";
import { Workspace } from "../../types/workspace";
import BetaTag from "../BetaTag";
import { DangerButton } from "../DangerButton";
import { ExtractorTypesDropdown } from "../ExtractorTypesDropdown";
import Paginator from "../Paginator";
import styles from "./styles.module.scss";

function SkeletonWorkspaceBlock() {
  return (
    <div className={`${styles["grid-item"]} ${styles["skeleton"]}`}>
      <div className={styles["item-header"]}></div>
      <div className={styles["item-body"]}></div>

      <div className={styles["item-footer"]}></div>
    </div>
  );
}

interface WorkspaceBlockProps {
  onClick: (workspace: Workspace) => void;
  onDelete: (workspace: Workspace) => Promise<void>;
  workspace: Workspace;
}

export function WorkspaceBlock(props: WorkspaceBlockProps) {
  const { workspace, onClick, onDelete } = props;
  const {
    config: { name },
    updatedAt,
    extractor,
  } = workspace;

  const onBlockClicked = React.useCallback(() => {
    onClick(workspace);
  }, [onClick, workspace]);

  const onDeleteButtonClicked = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onDelete(workspace);
    },
    [onDelete, workspace]
  );

  return (
    <div className={styles["grid-item"]} onClick={onBlockClicked}>
      <span className={styles["item-header"]}>{name}</span>

      <div className={styles["item-body"]}>
        <span className={styles["item-body-label"]}>
          <FormattedMessage id="workspace.list.item.extractor" />
        </span>
        <span className={styles["item-body-text"]}>{extractor?.name}</span>
      </div>

      <div className={styles["item-footer"]}>
        <span>
          <FormattedMessage
            id="workspace.list.last_edited"
            values={{
              date: new Date(updatedAt).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              }),
            }}
          />
        </span>
        <Icon iconName="IconTrash" onClick={onDeleteButtonClicked} />
      </div>
    </div>
  );
}

export interface WorkspaceListSectionProps {
  workspaceListType: WorkspaceListType;
  workspaces: Workspace[];
  totalCount: number;
  currentPage: number;
  onOpenWorkspace: (workspace: Workspace) => void;
  onDeleteWorkspace: (workspace: Workspace) => Promise<void>;
  onNavigateToPage: (page: number) => void;
  availablePrebuiltExtractors: typeof PrebuiltExtractors;
  selectedExtractorTypes: string[];
  onDropdownChange: (
    _event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    _index?: number
  ) => void;
}

export function useWorkspaceListSection(args: WorkspaceListSectionProps) {
  const {
    currentPage,
    totalCount,
    workspaces,
    workspaceListType,
    availablePrebuiltExtractors,
    onOpenWorkspace,
    onDeleteWorkspace,
    onDropdownChange,
    onNavigateToPage,
    selectedExtractorTypes,
  } = args;

  const navigate = useNavigate();

  const { hasPermissionToCreateResource } = useTeamPermission();

  const selectedAll =
    selectedExtractorTypes.length ===
    availablePrebuiltExtractors.length + extractorTypeOptions.length;

  const { requestUserConfirmation } = useConfirmModalActionCreator();
  const onCreateClick = useCallback(() => {
    if (!hasPermissionToCreateResource) {
      requestUserConfirmation(
        {
          titleId:
            "workspace.list.create_new_workspace.no_permission_modal.title",
          messageId:
            "workspace.list.create_new_workspace.no_permission_modal.message",
          actionId:
            "workspace.list.create_new_workspace.no_permission_modal.action",
          type: ConfirmModalType.Notify,
        },
        false
      );
      return;
    }
    navigate("/workspace/create");
  }, [hasPermissionToCreateResource, navigate, requestUserConfirmation]);

  return React.useMemo(
    () => ({
      workspaces,
      workspaceListType,
      currentPage,
      totalCount,
      onOpenWorkspace,
      selectedExtractorTypes,
      selectedAll,
      hasPermissionToCreateResource,
      onCreateClick,
      availablePrebuiltExtractors,
      onDropdownChange,
      onDeleteWorkspace,
      onNavigateToPage,
    }),
    [
      workspaces,
      workspaceListType,
      currentPage,
      totalCount,
      onOpenWorkspace,
      selectedExtractorTypes,
      selectedAll,
      hasPermissionToCreateResource,
      onCreateClick,
      availablePrebuiltExtractors,
      onDropdownChange,
      onDeleteWorkspace,
      onNavigateToPage,
    ]
  );
}

export function WorkspaceListSectionImpl(
  props: ReturnType<typeof useWorkspaceListSection>
) {
  const {
    workspaces,
    workspaceListType,
    currentPage,
    totalCount,
    onOpenWorkspace,
    selectedExtractorTypes,
    selectedAll,
    hasPermissionToCreateResource,
    onCreateClick,
    availablePrebuiltExtractors,
    onDropdownChange,
    onDeleteWorkspace,
    onNavigateToPage,
  } = props;

  const { localized } = useLocale();

  const options: IDropdownOption[] = React.useMemo(() => {
    const controlOptions = [
      {
        key: "select_all",
        text: localized("extractor.list.filter.select_all"),
      },
    ];
    const prebuiltExtractorOptions = availablePrebuiltExtractors.map(type => ({
      key: type,
      text: localized(mapExtractorTypeToMessageId(type)),
    }));
    const otherExtractorOptions = extractorTypeOptions.map(type => ({
      key: type,
      text: localized(`extractor.typeOption.${type}`),
    }));
    return [
      ...controlOptions,
      ...prebuiltExtractorOptions,
      ...otherExtractorOptions,
    ];
  }, [localized, availablePrebuiltExtractors]);

  return (
    <div className={styles["container"]}>
      <div className={styles["header"]}>
        <div className={styles["title"]}>
          <p>
            <FormattedMessage id="workspace.list.title" />
          </p>
          <BetaTag />
        </div>
        <p className={styles["subtitle"]}>
          <FormattedMessage id="workspace.list.subtitle" />
        </p>
        {workspaceListType !== WorkspaceListType.ShowCreateWorkspace ? (
          <div className={styles["control"]}>
            {hasPermissionToCreateResource && (
              <DangerButton
                textId="workspace.list.button.create.label"
                iconProps={{ iconName: "Add" }}
                onClick={onCreateClick}
                className={styles["button"]}
              />
            )}

            <ExtractorTypesDropdown
              options={options}
              onChange={onDropdownChange}
              selectedKeys={[
                ...selectedExtractorTypes,
                ...(selectedAll ? ["select_all"] : []),
              ]}
            />
          </div>
        ) : null}
      </div>

      {workspaceListType === WorkspaceListType.Loading && (
        <div className={styles["grid"]}>
          <SkeletonWorkspaceBlock />
          <SkeletonWorkspaceBlock />
        </div>
      )}

      {workspaceListType === WorkspaceListType.ShowCreateWorkspace ? (
        <div className={styles["empty-view"]}>
          <p className={styles["empty-view-title"]}>
            <FormattedMessage id="workspace.list.empty_view.title" />
          </p>
          <p className={styles["empty-view-message"]}>
            <FormattedMessage id="workspace.list.empty_view.message" />
          </p>
          <DangerButton
            iconProps={{ iconName: "Add" }}
            className={styles["empty-view-create-button"]}
            textId="workspace.list.empty_view.create_button"
            onClick={onCreateClick}
          />
          <img
            className={styles["empty-view-flow-chart"]}
            src={WorkspaceFlowChart}
          />
        </div>
      ) : null}

      {workspaceListType === WorkspaceListType.NoResultFound && (
        <div className={styles["not-found"]}>
          <FormattedMessage id="workspace.list.no_workspace_found" />
        </div>
      )}

      {workspaceListType === WorkspaceListType.ShowWorkspaceGrid && (
        <>
          <div className={styles["grid"]}>
            {workspaces.map((workspace, index) => (
              <WorkspaceBlock
                key={workspace.id + index}
                workspace={workspace}
                onClick={onOpenWorkspace}
                onDelete={onDeleteWorkspace}
              />
            ))}
          </div>
          <div className={styles["paginator"]}>
            <Paginator
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={WORKSPACE_PAGE_SIZE}
              navigateToPage={onNavigateToPage}
            />
          </div>
        </>
      )}
    </div>
  );
}
export function WorkspaceListSection(args: WorkspaceListSectionProps) {
  const props = useWorkspaceListSection(args);
  return <WorkspaceListSectionImpl {...props} />;
}
