import cn from "classnames";
import React from "react";

import styles from "./styles.module.scss";

type WorkspaceCsvOutputSampleTableItem<T extends string> = {
  key: string;
} & Record<T, string>;

type WorkspaceCsvOutputSampleTableColumn<T extends string> = {
  key: T;
  header: string;
  highlighed?: boolean;
};

interface WorkspaceCsvOutputSampleTableProps<T extends string> {
  columns: WorkspaceCsvOutputSampleTableColumn<T>[];
  items: WorkspaceCsvOutputSampleTableItem<T>[];
}

export function useWorkspaceCsvOutputSampleTable<T extends string>(
  args: WorkspaceCsvOutputSampleTableProps<T>
) {
  const { columns, items } = args;
  return React.useMemo(
    () => ({
      columns,
      items,
    }),
    [columns, items]
  );
}

export function WorkspaceCsvOutputSampleTableImpl<T extends string>(
  props: ReturnType<typeof useWorkspaceCsvOutputSampleTable<T>>
) {
  const { columns, items } = props;
  return (
    <div className={styles["container"]}>
      <div
        className={styles["table"]}
        style={{ gridTemplateColumns: `repeat(${columns.length}, 1fr)` }}
      >
        {columns.map(column => (
          <div
            key={`header-column-${column.key}`}
            className={cn(styles["cell"], styles["header"], {
              [styles["highlighted"]]: column.highlighed,
            })}
          >
            {column.header}
          </div>
        ))}
        {items.map(item => (
          <React.Fragment key={`item-${item.key}`}>
            {columns.map(column => (
              <div
                key={`item-${item.key}-column-${column.key}`}
                className={cn(styles["cell"], styles["item"])}
              >
                {item[column.key]}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export function WorkspaceCsvOutputSampleTable<T extends string>(
  args: WorkspaceCsvOutputSampleTableProps<T>
) {
  const props = useWorkspaceCsvOutputSampleTable(args);
  return <WorkspaceCsvOutputSampleTableImpl {...props} />;
}
