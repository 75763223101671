import { v4 as uuidv4 } from "uuid";

export class AbortControllerService {
  protected abortControllers = new Map<string, AbortController>();

  private static instance: AbortControllerService;

  static getInstance() {
    if (!this.instance) {
      this.instance = new AbortControllerService();
    }
    return this.instance;
  }

  getAbortController(key: string) {
    let _abortController = this.abortControllers.get(key);
    if (_abortController == null) {
      _abortController = new AbortController();
      this.abortControllers.set(key, _abortController);
    }
    return _abortController;
  }

  deleteAbortController(key: string) {
    this.abortControllers.delete(key);
  }

  withAbortController<T>(
    wrapped: (abortController: AbortController) => Promise<T>
  ): [string, () => Promise<T>] {
    const key = uuidv4();
    const abortController = this.getAbortController(key);
    const execute = async () => {
      try {
        const res = await wrapped(abortController);
        this.deleteAbortController(key);
        return res;
      } catch (e) {
        this.deleteAbortController(key);
        throw e;
      }
    };
    return [key, execute];
  }
}
