import cn from "classnames";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

export enum BadgeType {
  Correct = "correct",
  Pending = "pending",
  InProgress = "in-progress",
}

interface BadgeProps {
  textId?: string;
  text?: string;
  type: BadgeType;
  className?: string;
}

export function Badge(props: BadgeProps) {
  const { localized } = useLocale();

  const { textId, text, type, className } = props;

  const classes = cn(styles["badge"], styles["badge-type-" + type], className);
  const label = textId ? localized(textId) : text ? text : "";

  return <div className={classes}>{label}</div>;
}
