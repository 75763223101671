import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  PrimaryButton,
  Text,
} from "@fluentui/react";
import React from "react";

import { useLocale } from "../../contexts/locale";
import { ExtractorOptionForCombinedExtractor } from "../../types/extractor";
import { FormGroupAnchor } from "../../types/formGroup";
import styles from "./styles.module.scss";

interface Props {
  extractorOptions: ExtractorOptionForCombinedExtractor[];
  anchor: FormGroupAnchor | undefined;
  onCloseModal: () => void;
  onSave: (anchorId: string | undefined, formId: string) => void;
}

const FormGroupAnchorModal = React.memo((props: Props) => {
  const { anchor, onSave, onCloseModal, extractorOptions } = props;

  const isEdit = anchor !== undefined;

  const { localized } = useLocale();

  const extractorDropdownOptions = React.useMemo((): IDropdownOption[] => {
    return extractorOptions
      .map(x => ({
        key: x.extractorId,
        text:
          x.resourceType === "form_group"
            ? localized("form_group_editor.combined_extractor", {
                name: x.name,
              })
            : x.isInUse
            ? localized("form_group_editor.in_use", { name: x.name })
            : !x.hasFeatures
            ? localized("form_group_anchor_modal.no_anchor", { name: x.name })
            : x.name,
        disabled:
          !x.hasFeatures || x.isInUse || x.resourceType === "form_group",
      }))
      .sort((a, b) =>
        !a.disabled && b.disabled ? -1 : a.disabled && !b.disabled ? 1 : 0
      );
  }, [extractorOptions, localized]);

  const [isFormIdInvalid, setIsFormIdInvalid] = React.useState<boolean>(false);
  const [formId, setFormId] = React.useState<string | undefined>(
    anchor ? anchor.formId : undefined
  );

  const onSaveClicked = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!formId) {
        setIsFormIdInvalid(true);
        return;
      }
      onSave(anchor && anchor.id, formId);
      onCloseModal();
    },
    [onSave, formId, anchor, onCloseModal]
  );

  const onSelectedFormChange = React.useCallback(
    (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (!option) return;
      setFormId(option.key as string);
    },
    []
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        isEdit
          ? "form_group_anchor_modal.title.edit"
          : "form_group_anchor_modal.title.add"
      ),
    }),
    [localized, isEdit]
  );

  const modalProps: IModalProps = React.useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  return (
    <Dialog
      hidden={false}
      onDismiss={onCloseModal}
      minWidth={400}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <form onSubmit={onSaveClicked}>
        <Dropdown
          selectedKey={formId}
          options={extractorDropdownOptions}
          onChange={onSelectedFormChange}
          label={localized("form_group_anchor_modal.extractor_to_be_matched")}
          placeholder={localized("form_group_anchor_modal.select_an_extractor")}
          errorMessage={
            isFormIdInvalid
              ? localized("form_group_anchor_modal.select_an_extractor")
              : undefined
          }
        />
        <div className={styles["anchor-modal-description"]}>
          <Text>{localized("form_group_anchor_modal.description")}</Text>
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={onCloseModal}
            text={localized("common.cancel")}
          />
          <PrimaryButton type="submit" text={localized("common.save")} />
        </DialogFooter>
      </form>
    </Dialog>
  );
});

export { FormGroupAnchorModal };
