import { Text, getTheme } from "@fluentui/react";
import React from "react";

interface ErrorTextProps {
  children: React.ReactNode;
  className?: string;
}

export default function ErrorText({ children, className }: ErrorTextProps) {
  const theme = getTheme();
  return (
    <div style={{ color: theme.palette.redDark }} className={className}>
      <Text variant={"small"}>{children}</Text>
    </div>
  );
}
