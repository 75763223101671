import * as React from "react";
import { useSelector } from "react-redux";

import { UserFeatureFlag } from "../../constants";
import { TemplatedInstantModelExtractorDefinition } from "../../constants/templatedInstantModelExtractor";
import { useExtractTest, useExtractTestV2 } from "../../hooks/extract_test";
import { useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/types";
import { CustomModel } from "../../types/customModel";
import { ProcessingMode } from "../../types/processingMode";
import { ExtractorTestPanel } from "../ExtractorTestPanel";
import { FSLActiveModelPicker } from "../FSLActiveModelPicker";
import { FSLSchemaNotSetWarning } from "../FSLSchemaNotSetWarning";
import { FSLSplitView } from "../FSLSplitView";
import LoadingModal from "../LoadingModal";
import OCRTest from "../OCRTest";
import styles from "./styles.module.scss";

export enum State {
  SchemaNotSetState,
  ReadyToTestState,
}

export function useFSLOCRTest(customModel: CustomModel, useV1API?: boolean) {
  const state =
    customModel.config.extractedContentSchema !== undefined
      ? State.ReadyToTestState
      : State.SchemaNotSetState;

  const isFeatureEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()
  );

  const { currentForm: underlyingForm } = useSelector(
    (state: RootState) => state.form
  );

  const hasTransformResponseScript =
    customModel?.formID === underlyingForm?.id
      ? !!underlyingForm.config.transform_response_script?.trim()
      : false;

  const multipleDocumentsPerPage =
    customModel?.formID === underlyingForm?.id
      ? underlyingForm.config.processing_mode ===
        ProcessingMode.MultipleDocumentsPerPage
      : false;

  const v2Handle = useExtractTestV2({
    extractorId: customModel?.formID ?? undefined,
    options: {
      shouldOutputLLMPrompt: isFeatureEnabled(UserFeatureFlag.InspectPrompt),
      shouldOutputOcr: true,
      shouldOutputOrientation: true,
    },
    hasTransformResponseScript,
    multipleDocumentsPerPage,
  });

  const v1Handle = useExtractTest(customModel?.formID ?? undefined);

  const { extractIfQueryKeySet } = v2Handle;

  const documentTypeForSample = customModel.config.documentTypeForSample;

  const sampleImages = React.useMemo(() => {
    return (
      TemplatedInstantModelExtractorDefinition[
        documentTypeForSample as keyof typeof TemplatedInstantModelExtractorDefinition
      ]?.sampleImages ?? []
    );
  }, [documentTypeForSample]);

  React.useEffect(() => {
    if (!useV1API) {
      extractIfQueryKeySet({
        extractorId: customModel.formID ?? customModel.id,
        extractorType: "custom",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.useMemo(
    () => ({
      v1Handle,
      v2Handle,
      customModel,
      state,
      useV1API: useV1API ?? false,
      hasTransformResponseScript,
      sampleImages,
    }),
    [
      v1Handle,
      v2Handle,
      customModel,
      state,
      useV1API,
      hasTransformResponseScript,
      sampleImages,
    ]
  );
}

export function FSLOCRTestImpl(props: ReturnType<typeof useFSLOCRTest>) {
  const { v1Handle, v2Handle, useV1API, state, customModel, sampleImages } =
    props;

  const isRecognizing = useV1API
    ? v1Handle.isRecognizing
    : v2Handle.isRecognizing;
  const isUploading = useV1API ? v1Handle.isUploading : v2Handle.isUploading;
  const isStandardModelAvailable =
    state !== State.SchemaNotSetState && customModel.modelVersions.length > 0;

  return (
    <>
      <FSLSplitView
        left={
          <>
            {state === State.SchemaNotSetState ? (
              <div className={styles["schema-not-set-warning"]}>
                <FSLSchemaNotSetWarning />{" "}
              </div>
            ) : (
              <div className={styles["ocrtest"]}>
                {useV1API ? (
                  <OCRTest
                    onSelectImage={v1Handle.extractWithFile}
                    onSelectSampleImage={v1Handle.extractWithURL}
                    extractionMode={v1Handle.extractionMode}
                    onChangeExtractionModeClicked={() => {}}
                    changeExtractionModeVisible={false}
                    ocrTestReport={v1Handle.testReport}
                    resourceName={customModel?.name ?? ""}
                  />
                ) : (
                  <ExtractorTestPanel
                    onSelectImage={v2Handle.extractWithFile}
                    onSelectSampleImage={v2Handle.extractWithURL}
                    extractResult={v2Handle.extractResult}
                    extractImageSource={v2Handle.extractImageSource}
                    resourceName={customModel?.name ?? ""}
                    disableExtractTableDataBottomSheet={true}
                    hasTransformResponseScript={
                      props.hasTransformResponseScript
                    }
                    samples={sampleImages}
                    // Disable the bottom sheet and hold it inside the FSLCustomModelTest container
                  />
                )}
              </div>
            )}
            {isRecognizing && (
              <LoadingModal
                messageId="ocr_test.extracting"
                isOpen={isRecognizing}
              />
            )}
            {isUploading && (
              <LoadingModal messageId="common.uploading" isOpen={isUploading} />
            )}
          </>
        }
        right={isStandardModelAvailable && <FSLActiveModelPicker />}
        rightVisible={isStandardModelAvailable}
        isDividerVisible={true}
        rightMaxWidth={284}
        rightMinWidth={284}
      />
    </>
  );
}

interface FSLOCRTestProps {
  customModel: CustomModel;
  useV1API?: boolean;
}

export function FSLOCRTest(props: FSLOCRTestProps) {
  const { customModel, useV1API } = props;
  const states = useFSLOCRTest(customModel, useV1API);
  return <FSLOCRTestImpl {...states} />;
}
