import {
  SharePointSubscription,
  sharePointSubscriptionSchema,
} from "../../types/workspace";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface WorkspaceSharePointSubscriptionApiClient {
  createSharePointSubscription: (
    workspaceId: string,
    siteUrl: string,
    listId: string,
    listItemId: string,
    refreshToken: string
  ) => Promise<SharePointSubscription>;
}

export function withWorkspaceSharePointSubscriptionApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async createSharePointSubscription(
      workspaceId: string,
      siteUrl: string,
      listId: string,
      listItemId: string,
      refreshToken: string
    ): Promise<SharePointSubscription> {
      return this.lambda(
        "workspace_sharepoint_subscription:create",
        {
          workspace_id: workspaceId,
          site_url: siteUrl,
          list_id: listId,
          list_item_id: listItemId,
          refresh_token: refreshToken,
        },
        sharePointSubscriptionSchema.defined()
      );
    }
  };
}
