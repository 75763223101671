import * as yup from "yup";

import { RegionsConfig } from "../../config";
import { PermissionMapper } from "../../types/mappers/permission";
import {
  AdminGetTeamDetailResp,
  AdminTeamsUsages,
  BriefTeam,
  PaginatedTeams,
  Permission,
  TeamLookup,
  TeamMembersAndInvitations,
  TeamRef,
  TeamRenameResp,
  TeamSetUserPermissionResp,
  adminGetTeamDetailRespSchema,
  adminTeamsUsagesSchema,
  briefTeamSchema,
  paginatedTeamsSchema,
  teamLookupEntrySchema,
  teamMembersAndInvitationsSchema,
  teamRefSchema,
  teamRenameRespSchema,
  teamSetUserPermissionRespSchema,
} from "../../types/team";
import { UsageRange } from "../../types/usage";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface TeamApiClient {
  createTeam: (
    name: string,
    region: string,
    lookupId: string
  ) => Promise<BriefTeam>;
  listTeamRefs: (region: string) => Promise<TeamRef[]>;
  listTeamMembers: (
    teamId: string,
    region?: string
  ) => Promise<TeamMembersAndInvitations>;
  setTeamUserPermission: (
    teamId: string,
    userId: string,
    permission: Permission,
    retrievedAt?: string,
    region?: string
  ) => Promise<TeamSetUserPermissionResp>;
  removeTeamUser: (
    teamId: string,
    userId: string,
    region?: string
  ) => Promise<void>;
  inviteTeamUser: (
    teamId: string,
    email: string,
    permission: Permission,
    region?: string
  ) => Promise<string>;
  renameTeam: (
    teamId: string,
    name: string,
    retrievedAt?: string
  ) => Promise<TeamRenameResp>;
  lookupTeam: (lookupId: string) => Promise<TeamLookup>;
  deleteTeam: (teamId: string) => Promise<void>;
  listTeam: (
    region: string,
    size: number,
    offset: number,
    options?: ListTeamOptions
  ) => Promise<PaginatedTeams>;
  getTeam: (teamId: string, region: string) => Promise<AdminGetTeamDetailResp>;
  listTeamsUsages: (
    range: UsageRange,
    limit: number,
    region: string
  ) => Promise<AdminTeamsUsages>;
}

export interface ListTeamOptions {
  searchString?: string;
  fieldToSearch?: string;
  plan?: string;
  planId?: string;
  minCreatedAt?: string;
  includeTotalUsage?: boolean;
}

export function withTeamApi<TBase extends ApiClientConstructor<_BaseApiClient>>(
  Base: TBase
) {
  return class extends Base {
    async createTeam(
      name: string,
      region: string,
      lookupId: string
    ): Promise<BriefTeam> {
      return this.lambda(
        "team:create",
        {
          name: name,
          lookup_id: lookupId,
        },
        briefTeamSchema.defined(),
        undefined,
        {
          region,
        }
      );
    }

    async listTeamRefs(region: string): Promise<TeamRef[]> {
      return this.lambda(
        "team:get-team-refs",
        {},
        yup.array(teamRefSchema.defined()).defined(),
        undefined,
        {
          region,
        }
      );
    }

    async listTeamMembers(
      teamId: string,
      region?: string
    ): Promise<TeamMembersAndInvitations> {
      return this.lambda(
        "team:list-members",
        {
          team_id: teamId,
        },
        teamMembersAndInvitationsSchema,
        null,
        region !== undefined ? { region } : undefined
      );
    }

    async setTeamUserPermission(
      teamId: string,
      userId: string,
      permission: Permission,
      retrievedAt?: string,
      region?: string
    ): Promise<TeamSetUserPermissionResp> {
      return this.lambda(
        "team:set-user-permission",
        {
          permission: PermissionMapper.toResp(permission),
          team_id: teamId,
          user_id: userId,
          retrieved_at: retrievedAt,
        },
        teamSetUserPermissionRespSchema,
        undefined,
        region !== undefined ? { region } : undefined
      );
    }

    async removeTeamUser(
      teamId: string,
      userId: string,
      region?: string
    ): Promise<void> {
      return this.lambda(
        "team:remove-user",
        {
          team_id: teamId,
          user_id: userId,
        },
        undefined,
        undefined,
        region !== undefined ? { region } : undefined
      );
    }

    async inviteTeamUser(
      teamId: string,
      email: string,
      permission: Permission,
      region?: string
    ): Promise<string> {
      return this.lambda(
        "team:invite-user",
        {
          team_id: teamId,
          email: email,
          permission: PermissionMapper.toResp(permission),
        },
        yup.string().defined(),
        undefined,
        region !== undefined ? { region } : undefined
      );
    }

    async renameTeam(
      teamId: string,
      name: string,
      retrievedAt?: string
    ): Promise<TeamRenameResp> {
      return this.lambda(
        "team:rename",
        this.injectOptionalFields(
          {
            name: name,
            team_id: teamId,
          },
          {
            retrieved_at: retrievedAt,
          }
        ),
        teamRenameRespSchema,
        null
      );
    }

    async lookupTeam(lookupId: string): Promise<TeamLookup> {
      return await this.lambda(
        "team-lookup:get",
        {
          lookup_id: lookupId,
        },
        teamLookupEntrySchema,
        undefined,
        { region: RegionsConfig.teamLookupRegion }
      );
    }

    async deleteTeam(teamId: string): Promise<void> {
      return this.lambda("team:delete", {
        team_id: teamId,
      });
    }

    async listTeam(
      region: string,
      size: number,
      offset: number,
      options?: ListTeamOptions
    ): Promise<PaginatedTeams> {
      const args = this.injectOptionalFields(
        {
          page_args: {
            size,
            offset,
          },
        },
        {
          search_string: options?.searchString,
          field_to_search: options?.fieldToSearch,
          plan: options?.plan,
          plan_id: options?.planId,
          min_created_at: options?.minCreatedAt,
          include_total_usage: options?.includeTotalUsage,
        }
      );

      return this.lambda("team:list", args, paginatedTeamsSchema, undefined, {
        region,
      });
    }

    async getTeam(
      teamId: string,
      region: string
    ): Promise<AdminGetTeamDetailResp> {
      return this.lambda(
        "team:get",
        {
          team_id: teamId,
        },
        adminGetTeamDetailRespSchema.defined(),
        null,
        {
          region,
        }
      );
    }

    async listTeamsUsages(
      range: UsageRange,
      limit: number,
      region: string
    ): Promise<AdminTeamsUsages> {
      return this.lambda(
        "team:list-ranking",
        {
          start_date: range.start,
          end_date: range.end,
          limit,
        },
        adminTeamsUsagesSchema,
        null,
        {
          region,
        }
      );
    }
  };
}
