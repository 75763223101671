import * as yup from "yup";

import { pageInfoSchema } from "./pageInfo";

export const customFieldSchema = yup.object({
  name: yup.string().defined(),
  token: yup.string().defined(),
  pattern: yup.string().defined(),
  position: yup.string().oneOf(["above", "left", "right", "below"]).defined(),
  use_fuzzy_search: yup.boolean().defined(),
});

export const receiptGroupSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  tokens: yup.array(yup.string().defined()).defined(),
  custom_fields: yup.array(customFieldSchema).defined(),
});

export const paginatedReceiptGroupSchema = yup
  .object({
    pageInfo: pageInfoSchema.defined(),
    receiptGroups: yup.array(receiptGroupSchema).defined(),
  })
  .camelCase();

export type PaginatedReceiptGroup = yup.InferType<
  typeof paginatedReceiptGroupSchema
>;
export type CustomField = yup.InferType<typeof customFieldSchema>;
export type ReceiptGroup = yup.InferType<typeof receiptGroupSchema>;
export type ReceiptGroupWithType = ReceiptGroup & {
  type: "receipt_group";
};

export enum ReceiptGroupSection {
  Edit,
  Test,
  API,
}
