import React, { useCallback } from "react";

import { README_IO_PAGE_URL } from "../../constants/readmeIO";
import { useWorkerToken } from "../../hooks/app";
import { useReadmeIO } from "../../hooks/readmeIO";
import { Workspace } from "../../types/workspace";
import { WorkspaceNote } from "../ExtractNote";
import styles from "./styles.module.scss";

interface WorkspaceApiSectionProps {
  workspace: Workspace;
}

export function useWorkspaceApiSection(args: WorkspaceApiSectionProps) {
  const { workspace } = args;

  const { token, tokens, onSelectToken } = useWorkerToken();

  const { onRedirectToReadmeIO } = useReadmeIO();
  const workspaceId = workspace.id;

  const onOpenReference = useCallback(() => {
    onRedirectToReadmeIO(
      README_IO_PAGE_URL.referenceDocumentExtractionToWorkspace,
      {
        parameters: { workspaceId },
      }
    );
  }, [onRedirectToReadmeIO, workspaceId]);

  return React.useMemo(
    () => ({
      workspace,
      token,
      tokens,
      onSelectToken,
      onOpenReference,
    }),
    [onOpenReference, onSelectToken, token, tokens, workspace]
  );
}

export function WorkspaceApiSectionImpl(
  props: ReturnType<typeof useWorkspaceApiSection>
) {
  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <WorkspaceNote
          token={props.token || ""}
          tokens={props.tokens}
          onSelectToken={props.onSelectToken}
          workspaceId={props.workspace.id}
          onOpenReference={props.onOpenReference}
        />
      </div>
    </div>
  );
}

export function WorkspaceApiSection(args: WorkspaceApiSectionProps) {
  const props = useWorkspaceApiSection(args);
  return <WorkspaceApiSectionImpl {...props} />;
}
