import {
  API,
  DetectionRegionFieldAdvancedSetting,
  DetectionRegionFieldAdvancedSettingByEngine,
  DetectionRegionFieldEngine,
} from "../types/detectionRegion";
import {
  Abbyy,
  AzureComputerVision,
  GoogleCloudVision,
  Inherit,
  Tesseract,
} from "../types/ocrConfig";

export const GOOGLE_ENGINE_API_OPTIONS = [
  {
    label: "text_detection",
    value: "text_detection",
  },
  {
    label: "document_text_detection",
    value: "document_text_detection",
  },
];

export const AZURE_ENGINE_API_OPTIONS = [
  {
    label: "ocr",
    value: "ocr",
  },
  {
    label: "read",
    value: "read",
  },
];

export interface DetectionRegionFieldTypeConfig {
  label: string;
  possibleEngines?: DetectionRegionFieldEngine[];
  advancedSettings?:
    | DetectionRegionFieldAdvancedSetting[]
    | DetectionRegionFieldAdvancedSettingByEngine;
}

const DefaultOCREnginesInPreference: DetectionRegionFieldEngine[] = [
  Inherit,
  GoogleCloudVision,
  AzureComputerVision,
  Tesseract,
  Abbyy,
];

export interface DetectionRegionFieldTypeHierarchySubType
  extends DetectionRegionFieldTypeConfig {
  key: string;
}

export interface DetectionRegionFieldTypeHierarchyType {
  key: string;
  label: string;
  options: DetectionRegionFieldTypeHierarchySubType[];
  isNoSetting?: boolean;
}

export const DetectionRegionFieldTypeHierarchy: DetectionRegionFieldTypeHierarchyType[] =
  [
    {
      key: "text",
      label: "form_inspector.text",
      options: [
        {
          key: "single-line-eng",
          possibleEngines: DefaultOCREnginesInPreference,
          label: "form_inspector.single_line_eng",
        },
        {
          key: "single-line-chi",
          label: "form_inspector.single_line_chi",
          possibleEngines: DefaultOCREnginesInPreference,
        },
        {
          key: "single-line-eng-chi",
          label: "form_inspector.single_line_eng_chi",
          possibleEngines: DefaultOCREnginesInPreference,
        },
        {
          key: "block-eng",
          label: "form_inspector.block_eng",
          possibleEngines: DefaultOCREnginesInPreference,
          advancedSettings: [API],
        },
        {
          key: "block-chi",
          label: "form_inspector.block_chi",
          possibleEngines: DefaultOCREnginesInPreference,
          advancedSettings: [API],
        },
        {
          key: "block-eng-chi",
          label: "form_inspector.block_eng_chi",
          possibleEngines: DefaultOCREnginesInPreference,
          advancedSettings: [API],
        },
        {
          key: "handwritten-eng",
          label: "form_inspector.handwritten_eng",
          possibleEngines: [GoogleCloudVision, AzureComputerVision],
        },
        {
          key: "handwritten-chi",
          label: "form_inspector.handwritten_chi",
          possibleEngines: [GoogleCloudVision, AzureComputerVision],
        },
        {
          key: "handwritten-eng-chi",
          label: "form_inspector.handwritten_eng_chi",
          possibleEngines: [GoogleCloudVision, AzureComputerVision],
        },
      ],
    },
    {
      key: "barcode",
      label: "form_inspector.barcode",
      options: [
        {
          key: "barcode",
          label: "form_inspector.barcode",
        },
      ],
      isNoSetting: true,
    },
    {
      key: "checkbox",
      label: "form_inspector.checkbox",
      options: [
        {
          key: "checkbox",
          label: "form_inspector.checkbox",
        },
      ],
      isNoSetting: true,
    },
    {
      key: "script",
      label: "form_inspector.script",
      options: [
        {
          key: "script",
          label: "form_inspector.script",
          possibleEngines: DefaultOCREnginesInPreference,
          advancedSettings: [API],
        },
      ],
    },
    {
      key: "name",
      label: "form_inspector.name",
      options: [
        {
          key: "name",
          label: "form_inspector.name",
          possibleEngines: DefaultOCREnginesInPreference,
          advancedSettings: [API],
        },
      ],
    },
    {
      key: "address",
      label: "form_inspector.address",
      options: [
        {
          key: "address",
          label: "form_inspector.address",
          possibleEngines: DefaultOCREnginesInPreference,
          advancedSettings: [API],
        },
      ],
    },
    {
      key: "date-time",
      label: "form_inspector.date_time",
      options: [
        {
          key: "date",
          label: "form_inspector.date",
          possibleEngines: DefaultOCREnginesInPreference,
          advancedSettings: [API],
        },
        {
          key: "time",
          label: "form_inspector.time",
          possibleEngines: DefaultOCREnginesInPreference,
          advancedSettings: [API],
        },
      ],
    },
    {
      key: "total-amount",
      label: "form_inspector.total_amount",
      options: [
        {
          key: "total-amount",
          label: "form_inspector.total_amount",
          possibleEngines: DefaultOCREnginesInPreference,
          advancedSettings: [API],
        },
      ],
    },
    {
      key: "text-in-paragraph",
      label: "form_inspector.text_in_paragraph",
      options: [
        {
          key: "text-in-paragraph",
          label: "form_inspector.text_in_paragraph",
          possibleEngines: DefaultOCREnginesInPreference,
          advancedSettings: [API],
        },
      ],
    },
    {
      key: "table",
      label: "form_inspector.table",
      options: [
        {
          key: "table",
          label: "form_inspector.table",
          possibleEngines: DefaultOCREnginesInPreference,
          advancedSettings: [API],
        },
      ],
    },
    {
      key: "llm-completion",
      label: "form_inspector.llm_completion",
      options: [
        {
          key: "llm-completion",
          label: "form_inspector.llm_completion",
        },
      ],
    },
  ];

export const DetectionRegionFieldTypeMap: {
  [key in string]: DetectionRegionFieldTypeConfig;
} = (() => {
  const object: { [key: string]: DetectionRegionFieldTypeConfig } = {};

  const options = DetectionRegionFieldTypeHierarchy.map(item => {
    return item.options;
  }).reduce((acc: Array<any>, val: Array<any>) => acc.concat(val), []);

  options.forEach(option => {
    const {
      key,
      label,
      possibleEngines,
      advancedSettings,
    }: {
      key: string;
      label: string;
      possibleEngines?: DetectionRegionFieldEngine[];
      advancedSettings?:
        | DetectionRegionFieldAdvancedSetting[]
        | DetectionRegionFieldAdvancedSettingByEngine;
    } = option;

    const config: DetectionRegionFieldTypeConfig = {
      label,
      possibleEngines: possibleEngines as DetectionRegionFieldEngine[],
      advancedSettings: advancedSettings as
        | DetectionRegionFieldAdvancedSetting[]
        | DetectionRegionFieldAdvancedSettingByEngine,
    };

    object[key] = config;
  });

  return object;
})();

export const EngineSpecificAdvanceSettingMap: {
  [key in DetectionRegionFieldEngine]: string[];
} = {
  tesseract: [],
  google: [API],
  azure: [API],
  abbyy: [],
  inherit: [],
};

export const DETECTION_REGION_FIELD_TYPE_OPTIONS = Object.entries(
  DetectionRegionFieldTypeMap
).map(([fieldType, config]) => ({ label: config.label, value: fieldType }));
