@import "../../styles/variables.scss";

.report-table {
  margin: 20px;
  border-collapse: collapse;
  table-layout: fixed;
  width: calc(100% - 40px);

  .table-row {
    vertical-align: top;

    td {
      padding: 12px 12px 12px 0;
    }

    &.header-row {
      border-top: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;

      .header-text {
        font-weight: 600;
        font-size: 14px;
        color: $dark-color;
      }
    }

    &.body-row {
      border-bottom: 1px solid #e9e9e9;

      .body-cell {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-wrap;
      }

      .body-text {
        font-size: 14px;
        color: $dark-color;

        pre {
          font-family: monospace;
          padding: 10px;
          background-color: #ddd;
          overflow-x: scroll;
        }

        span.title {
          margin-right: 8px;
          font-style: italic;
        }
      }
    }
  }
}
