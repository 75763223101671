import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import * as React from "react";

import { CustomModelLabelSchemaTypeDefintion } from "../../constants/customModelLabelSchema";
import { CustomModel } from "../../types/customModel";
import { PaginatedCustomModelImage } from "../../types/customModelImage";
import { getLabelSchemaDisplayName } from "../../utils/labelSchema";
import CustomModelImageGrid from "../CustomModelImageGrid";
import ShortSpinner from "../ShortSpinner";
import Link from "../WrappedMSComponents/Link";
import styles from "./styles.module.scss";

interface FieldItemProps {
  content: string;
  isFrozen?: boolean;
}

function FieldItem(props: FieldItemProps) {
  const { content, isFrozen } = props;

  if (content === "") {
    return <></>;
  }

  return (
    <div
      className={classNames(styles["custom-model-detail-field"], {
        [styles["custom-model-detail-field-frozen"]]: isFrozen,
      })}
    >
      {content}
    </div>
  );
}

interface Props {
  previousPageLink?: string;
  previousPageMessageId?: string;
  customModel?: CustomModel;
  isLoading?: boolean;
  isGettingCustomModelImage: boolean;
  paginatedCustomModelImages?: PaginatedCustomModelImage;
  imageGridCurrentPage: number;
  navigateImageGridToPage: (page: number) => void;
  openLabeller: (imageId: string) => void;
}

function _TeamDetailResourcesCustomModelDetailLayout(props: Props) {
  const {
    previousPageLink,
    previousPageMessageId,
    customModel,
    isLoading,
    isGettingCustomModelImage,
    paginatedCustomModelImages,
    navigateImageGridToPage,
    imageGridCurrentPage,
    openLabeller,
  } = props;

  if (isLoading || paginatedCustomModelImages === undefined) {
    return <ShortSpinner />;
  }

  return (
    <div className={styles["custom-model-detail"]}>
      {previousPageLink && (
        <Link
          className={styles["custom-model-list-link"]}
          to={previousPageLink}
        >
          <FormattedMessage id={previousPageMessageId ?? ""} />
          <Icon
            iconName="ChevronRight"
            className={styles["custom-model-list-link-arrow"]}
          />
        </Link>
      )}
      <div className={styles["custom-model-detail-name"]}>
        {customModel?.name}
      </div>
      <div className={styles["custom-model-detail-fields"]}>
        {[
          ...(customModel?.config.labelSchema || []),
          ...(customModel?.config.unfrozenLabelSchema || []),
        ].flatMap(field =>
          CustomModelLabelSchemaTypeDefintion[field.type].hidden ? (
            []
          ) : (
            <FieldItem
              key={field.name}
              content={getLabelSchemaDisplayName(field)}
              isFrozen={true}
            />
          )
        )}
      </div>
      <CustomModelImageGrid
        isLoading={isGettingCustomModelImage}
        paginatedCustomModelImage={paginatedCustomModelImages}
        navigateToPage={navigateImageGridToPage}
        currentPage={imageGridCurrentPage}
        openLabeller={openLabeller}
      />
    </div>
  );
}

export const TeamDetailResourcesCustomModelDetailLayout = React.memo(
  _TeamDetailResourcesCustomModelDetailLayout
);
export default TeamDetailResourcesCustomModelDetailLayout;
