import * as React from "react";

export const IPAPI_URL = "https://ipapi.co/json/";

export function useGeoIP() {
  const [ip, setIp] = React.useState<string | null>(null);
  const [countryCode, setCountryCode] = React.useState<string | null>(null);

  React.useEffect(() => {
    fetch(IPAPI_URL)
      .then(response => response.json())
      .then(data => {
        setIp(data.ip);
        setCountryCode(data.country_code);
      })
      .catch(e => {
        console.error(e);
      });
  }, []);

  return {
    ip,
    countryCode,
  };
}
