import { useCallback, useEffect, useRef } from "react";

import {
  FilePickerSharePointFile,
  PickCommandPayloadData,
} from "../types/workspaceIntegration";

const FILE_PICKER_CHANNEL_ID = "sharepoint-file-picker-channel";

export function useSharePointFilePicker(
  siteUrl: string,
  getAccessToken: () => Promise<string | undefined>,
  onSelectedFile: (file: FilePickerSharePointFile) => void
): () => Promise<void> {
  const messagePortRef = useRef<MessagePort | null>(null);
  const filePickerWindowRef = useRef<Window | null>(null);

  const handleFilePickerFolderSelected = useCallback(
    (payloadData: PickCommandPayloadData) => {
      onSelectedFile(payloadData.items[0]);
      filePickerWindowRef.current?.close();
    },
    [onSelectedFile]
  );

  const filePickerMessageListener = useCallback(
    async (message: MessageEvent) => {
      const messagePort = messagePortRef.current;
      if (messagePort == null) {
        console.error("No message port registered");
        return;
      }
      const payload = message.data;

      switch (payload.type) {
        case "notification":
          // payload.data.notification, eg. "page-loaded"
          break;
        case "command":
          // all commands must be acknowledged
          messagePort.postMessage({
            type: "acknowledge",
            id: message.data.id,
          });

          switch (payload.data.command) {
            case "authenticate":
              // Handle authenticate request. This command will be issued any time the picker requires a token
              try {
                const token = await getAccessToken();
                if (!token) {
                  throw new Error("Unable to obtain a token.");
                }

                // we report a result for the authentication via the previously established port
                messagePort.postMessage({
                  type: "result",
                  id: message.data.id,
                  data: {
                    result: "token",
                    token: token,
                  },
                });
              } catch (error: unknown) {
                messagePort.postMessage({
                  type: "result",
                  id: message.data.id,
                  data: {
                    result: "error",
                    error: {
                      code: "unableToObtainToken",
                      message:
                        error instanceof Error
                          ? error.message
                          : "Unknown error",
                    },
                  },
                });
              }
              break;
            case "close":
              filePickerWindowRef.current?.close();
              break;
            case "pick":
              try {
                handleFilePickerFolderSelected(payload.data);
                // let the picker know that the pick command was handled (required)
                messagePort.postMessage({
                  type: "result",
                  id: message.data.id,
                  data: {
                    result: "success",
                  },
                });
              } catch (error) {
                messagePort.postMessage({
                  type: "result",
                  id: message.data.id,
                  data: {
                    result: "error",
                    error: {
                      code: "unusableItem",
                      message: payload.data.message,
                    },
                  },
                });
              }
              break;
            default:
              // Always send a reply, if if that reply is that the command is not supported.
              messagePort.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "error",
                  error: {
                    code: "unsupportedCommand",
                    message: payload.data.command,
                  },
                },
              });
              break;
          }
          break;
      }
    },
    [getAccessToken, handleFilePickerFolderSelected]
  );

  const initializeFilePickerMessageListener = useCallback(
    (event: MessageEvent) => {
      const message = event.data;
      if (
        message.type === "initialize" &&
        message.channelId === FILE_PICKER_CHANNEL_ID
      ) {
        // get port and add an event listener to the port
        const port = event.ports[0];
        messagePortRef.current = port;
        port.addEventListener("message", filePickerMessageListener);
        port.start();

        // tell the picker to activate
        port.postMessage({
          type: "activate",
        });
      }
    },
    [filePickerMessageListener]
  );

  useEffect(() => {
    window.addEventListener("message", initializeFilePickerMessageListener);
    return () => {
      window.removeEventListener(
        "message",
        initializeFilePickerMessageListener
      );
    };
  }, [initializeFilePickerMessageListener]);

  const openFilePicker = useCallback(async () => {
    try {
      new URL(siteUrl);
    } catch {
      alert("Please enter a valid SharePoint site url");
      return;
    }

    const popupWindow = window.open("", "Picker", "width=1080,height=680");
    if (popupWindow == null) {
      throw new Error("failed to open SharePoint file picker");
    }
    filePickerWindowRef.current = popupWindow;

    const options = {
      sdk: "8.0",
      messaging: {
        channelId: FILE_PICKER_CHANNEL_ID,
        origin: window.location.origin,
      },
      entry: {
        sharePoint: {
          byPath: {
            // list: Full URL or path segement to identity a List,
            // folder: Path segment to a folder within a list, or a server-relative URL to a folder,
          },
        },
      },
      search: {
        enabled: true,
      },
      typesAndSources: {
        // "files" | "folders" | "all"
        // default: "all"
        mode: "folders",
        // Item types should be lowercase and come from the following set:
        // photo, video, audio, folder, file
        filters: ["folder"],
      },
      selection: {
        // "single" | "multiple" | "pick"
        mode: "single",
      },
    };

    const queryString = new URLSearchParams({
      filePicker: JSON.stringify(options),
      locale: "en-us",
    });

    const accessToken = await getAccessToken();
    if (accessToken == null) {
      throw new Error("Failed to get access token");
    }

    // we create the absolute url by combining the base url, appending the _layouts path, and including the query string
    const url = siteUrl + `/_layouts/15/FilePicker.aspx?${queryString}`;

    // Send post request to post token to popup / iframe
    const form = popupWindow.document.createElement("form");
    form.setAttribute("action", url);
    form.setAttribute("method", "POST");

    // This optional when using a popup window but required when using an iframe.
    const tokenInput = popupWindow.document.createElement("input");

    tokenInput.setAttribute("type", "hidden");
    tokenInput.setAttribute("name", "access_token");
    tokenInput.setAttribute("value", accessToken);

    form.appendChild(tokenInput);
    popupWindow.document.body.append(form);

    // submit the form, this will load the picker page
    form.submit();
  }, [siteUrl, getAccessToken]);

  return openFilePicker;
}
