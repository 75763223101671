import {
  ActionButton,
  INavButtonProps,
  INavLinkGroup,
  IRenderGroupHeaderProps,
  Icon,
  Label,
  INavLink as MSINavLink,
  Nav,
} from "@fluentui/react";
import classNames from "classnames";
import React, { useCallback, useMemo } from "react";

import { useLocale } from "../../contexts/locale";
import { MenuItem } from "../../types/advancedTokenSetup/table";
import styles from "./styles.module.scss";

enum MenuGroup {
  Tag,
  ExactMatchRule,
  ClosestMatchRule,
  FieldReplacement,
}

const menuGroups = {
  [MenuGroup.Tag]: [MenuItem.TagMerchant, MenuItem.TagMall],
  [MenuGroup.ExactMatchRule]: [MenuItem.ExactMatchRule],
  [MenuGroup.ClosestMatchRule]: [
    MenuItem.ClosestMatchRuleMerchant,
    MenuItem.ClosestMatchRuleMall,
  ],
  [MenuGroup.FieldReplacement]: [
    MenuItem.FieldReplacementMerchant,
    MenuItem.FieldReplacementMall,
  ],
};

interface INavLink extends MSINavLink {
  item: MenuItem;
  hasError: boolean;
}

interface Props {
  className?: string;
  onMenuItemClick?: (item: MenuItem) => void;
  selectedMenuItem: MenuItem;
  errorState?: MenuItem[];
}

const CustomMenuLink: React.FC<INavButtonProps> = props => {
  const { link: _link, className, ...restProps } = props;

  const link = _link as INavLink | undefined;

  return (
    <ActionButton
      {...restProps}
      className={classNames(className, {
        [styles["menu-link--error"]]: link?.hasError ?? false,
      })}
      styles={{
        root: {
          fontSize: 12,
        },
      }}
    />
  );
};

const AdvanceTokenSetupNavMenu: React.FC<Props> = props => {
  const {
    className,
    onMenuItemClick,
    selectedMenuItem,
    errorState: errorMenus = [],
  } = props;
  const { localized } = useLocale();
  const selectedKey = useMemo(() => {
    return selectedMenuItem.toString();
  }, [selectedMenuItem]);
  const onClickNavLink = useCallback(
    (_: any, item?: MSINavLink) => {
      if (onMenuItemClick && item) {
        onMenuItemClick((item as INavLink).item);
      }
    },
    [onMenuItemClick]
  );
  const getLocalizedName = useCallback(
    (item: MenuItem) => {
      switch (item) {
        case MenuItem.TagMerchant:
        case MenuItem.FieldReplacementMerchant:
        case MenuItem.ClosestMatchRuleMerchant:
          return localized("advance_token_setup.nav_menu.tag.merchant_id");
        case MenuItem.TagMall:
        case MenuItem.FieldReplacementMall:
        case MenuItem.ClosestMatchRuleMall:
          return localized("advance_token_setup.nav_menu.tag.mall_id");
        case MenuItem.ExactMatchRule:
          return localized(
            "advance_token_setup.nav_menu.group.exact_match_rule.title"
          );
        default:
          throw Error(`Unknown menu item: ${item}`);
      }
    },
    [localized]
  );
  const toNavLink = useCallback(
    (item: MenuItem): INavLink => {
      return {
        key: item.toString(),
        url: "",
        name: getLocalizedName(item),
        onClick: onClickNavLink,
        item,
        hasError: errorMenus.findIndex(e => e === item) !== -1,
      };
    },
    [getLocalizedName, onClickNavLink, errorMenus]
  );

  const groups = useMemo<INavLinkGroup[]>(() => {
    return [
      {
        name: localized("advance_token_setup.nav_menu.group.tag_groups.title"),
        groupData: {
          icon: "Tag",
        },
        links: menuGroups[MenuGroup.Tag].map(toNavLink),
      },
      {
        name: localized(
          "advance_token_setup.nav_menu.group.exact_match_rule.title"
        ),
        groupData: {
          icon: "SIPMove",
        },
        links: menuGroups[MenuGroup.ExactMatchRule].map(toNavLink),
        isExpanded: true,
      },
      {
        name: localized(
          "advance_token_setup.nav_menu.group.closest_match_rule.title"
        ),
        groupData: {
          icon: "SIPMove",
        },
        links: menuGroups[MenuGroup.ClosestMatchRule].map(toNavLink),
        isExpanded: true,
      },
      {
        name: localized(
          "advance_token_setup.nav_menu.group.field_replacement.title"
        ),
        groupData: {
          icon: "Handwriting",
        },
        links: menuGroups[MenuGroup.FieldReplacement].map(toNavLink),
        isExpanded: true,
      },
    ];
  }, [localized, toNavLink]);

  const renderCustomGroupHeader = useCallback(
    (props?: IRenderGroupHeaderProps) => {
      const { icon } = props?.groupData;
      return (
        <div className={styles["link-group"]}>
          <Icon iconName={icon} />
          <Label className={styles["label"]}>{props?.name}</Label>
        </div>
      );
    },
    []
  );
  return (
    <Nav
      className={className}
      styles={{
        root: {
          minWidth: 200,
        },
        link: {
          fontSize: 12,
          color: "#323130",
        },
        groupContent: {
          marginBottom: 10,
        },
      }}
      groups={groups}
      onRenderGroupHeader={renderCustomGroupHeader}
      linkAs={CustomMenuLink}
      selectedKey={selectedKey}
    />
  );
};

export default AdvanceTokenSetupNavMenu;
