import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import * as React from "react";

import styles from "./styles.module.scss";

export enum MissionBarType {
  Info = "info",
  Warning = "warning",
  Success = "success",
}

const MissionBarIconMapping = {
  [MissionBarType.Info]: "🚀",
  [MissionBarType.Warning]: "🚨",
  [MissionBarType.Success]: "🎉",
};

interface MissionBarProps {
  className?: string;
  icon?: string | React.ReactNode;
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  right?: React.ReactNode;
  type: MissionBarType;
}

export function MissionBar(props: MissionBarProps) {
  const { title, subtitle, right, type, className } = props;

  const icon = React.useMemo(() => {
    if (props.icon === undefined) {
      return MissionBarIconMapping[type];
    }
    return props.icon;
  }, [props.icon, type]);

  const classes = classNames(
    styles["mission-bar"],
    styles["mission-bar-type-" + type],
    className
  );

  return (
    <div className={classes}>
      <div className={styles["mission-bar-left"]}>
        <div className={styles["mission-bar-icon"]}>{icon}</div>

        <div className={styles["mission-bar-text"]}>
          <div className={styles["mission-bar-title"]}>
            {React.isValidElement(title) ? (
              title
            ) : (
              <FormattedMessage id={title} />
            )}
          </div>
          <div className={styles["mission-bar-subtitle"]}>
            {subtitle == null || React.isValidElement(subtitle) ? (
              subtitle
            ) : (
              <FormattedMessage id={subtitle} />
            )}
          </div>
        </div>
      </div>
      <div className={styles["mission-bar-right"]}>{right}</div>
    </div>
  );
}
