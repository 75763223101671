import React, { useCallback } from "react";

import { DetectionNote } from "../components/ExtractNote";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { README_IO_PAGE_URL } from "../constants";
import { useWorkerToken } from "../hooks/app";
import { useReadmeIO } from "../hooks/readmeIO";
import {
  DetectMultiDocumentNavBarLayout,
  DetectMultiDocumentNavTabKey,
} from "./DetectMultiDocumentNavLayout";
import HeaderContainer from "./Header";

function _DetectMultiDocumentExtractContainer() {
  const { token, tokens, onSelectToken } = useWorkerToken();
  const { onRedirectToReadmeIO } = useReadmeIO();

  const onOpenReference = useCallback(() => {
    onRedirectToReadmeIO(README_IO_PAGE_URL.referenceDetectDocuments);
  }, [onRedirectToReadmeIO]);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        {tokens === undefined ? (
          <LoadingModal isOpen={true} />
        ) : (
          <DetectMultiDocumentNavBarLayout
            selectedTab={DetectMultiDocumentNavTabKey.API}
          >
            <DetectionNote
              token={token || ""}
              tokens={tokens}
              onSelectToken={onSelectToken}
              onOpenReference={onOpenReference}
            />
          </DetectMultiDocumentNavBarLayout>
        )}
      </Main>
    </Layout>
  );
}

export const DetectMultiDocumentExtractContainer = React.memo(
  _DetectMultiDocumentExtractContainer
);
export default DetectMultiDocumentExtractContainer;
