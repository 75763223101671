import { IBreadcrumbItem, Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React, { useCallback } from "react";

import styles from "./styles.module.scss";

export interface CustomBreadcrumbItem extends IBreadcrumbItem {
  textId?: string;
}

interface Props {
  className?: string;
  items: CustomBreadcrumbItem[];
}

interface ItemProps {
  item: CustomBreadcrumbItem;
  onClick?: (
    ev?: React.MouseEvent<HTMLElement>,
    item?: IBreadcrumbItem
  ) => void;
}

const BreadcrumbItem = (props: ItemProps) => {
  const { item, onClick } = props;

  const onClickItem = useCallback(
    ev => {
      if (onClick) {
        onClick(ev, item);
      }
    },
    [item, onClick]
  );

  return (
    <div
      className={classNames({ [styles["pressable"]]: onClick !== undefined })}
      onClick={onClickItem}
    >
      {item.textId ? <FormattedMessage id={item.textId} /> : item.text}
    </div>
  );
};

const AppBreadcrumb = (props: Props) => {
  const { className, items } = props;
  return (
    <ul className={classNames(styles["breadcrumb"], className)}>
      {(items || []).map((item, index) => (
        <li key={index}>
          {index > 0 && <Icon iconName="ChevronRight" />}
          <BreadcrumbItem item={item} onClick={item.onClick} />
        </li>
      ))}
    </ul>
  );
};

export default AppBreadcrumb;
