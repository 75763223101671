import {
  DefaultButton,
  Dialog,
  DialogType,
  IDialogContentProps,
  IModalProps,
  PrimaryButton,
} from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router";
import { unstable_useBlocker as useBlocker } from "react-router-dom";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

interface Props {
  isDataChanged: boolean;
  titleTextId: string;
  messageTextId: string;
  backTextId: string;
  continueTextId: string;
}

function useDataNotSavedPrompt(props: Props) {
  const { isDataChanged } = props;
  const { localized } = useLocale();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [nextPath, setNextPath] = useState<string>("");
  const [allowedPath, setAllowedPath] = useState<string>("");

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
    setNextPath("");
  }, [setIsDialogOpen, setNextPath]);

  const closeDialogThenNavToNextPath = useCallback(() => {
    setIsDialogOpen(false);
    setAllowedPath(nextPath);
  }, [nextPath]);

  const onDialogDismissed = useCallback(() => {
    if (nextPath === "") {
      return;
    }

    navigate(nextPath);
  }, [nextPath, navigate]);

  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent): void | string => {
      if (isDataChanged) {
        event.preventDefault();
        // Most modern browsers ignore this message
        const promptMessage = localized(
          "advance_token_setup.editor.form_not_saved_prompt.save_warning"
        );
        event.returnValue = promptMessage;
        return promptMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, [localized, isDataChanged]);

  const blocker = useCallback(
    (args: { nextLocation: Location }) => {
      const { nextLocation } = args;

      const nextPathname = nextLocation.pathname;

      const notAllowed =
        isDataChanged &&
        nextPathname !== pathname &&
        nextPathname !== allowedPath;

      if (notAllowed) {
        setIsDialogOpen(true);
        setNextPath(nextPathname);
        return true;
      }

      setAllowedPath("");
      return false;
    },
    [isDataChanged, allowedPath, pathname]
  );

  useBlocker(blocker);

  return {
    isDialogOpen,
    closeDialog,
    onDialogDismissed,
    closeDialogThenNavToNextPath,
  };
}

const DataNotSavedPrompt = (props: Props) => {
  const { titleTextId, messageTextId, backTextId, continueTextId } = props;
  const {
    isDialogOpen,
    closeDialog,
    onDialogDismissed,
    closeDialogThenNavToNextPath,
  } = useDataNotSavedPrompt(props);
  const { localized } = useLocale();

  const modalProps: IModalProps = useMemo(
    () => ({
      isBlocking: true,
      onDismissed: onDialogDismissed,
      className: styles["data-non-saved-prompt-dialog"],
    }),
    [onDialogDismissed]
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(titleTextId),
      subText: localized(messageTextId),
    }),
    [localized, messageTextId, titleTextId]
  );

  return (
    <div>
      <Dialog
        minWidth={405}
        hidden={!isDialogOpen}
        onDismiss={closeDialog}
        modalProps={modalProps}
        dialogContentProps={dialogContentProps}
      >
        <div className={styles["button-actions"]}>
          <DefaultButton
            onClick={closeDialog}
            className={styles["dont-save-button"]}
            text={localized(backTextId)}
          />
          <PrimaryButton
            onClick={closeDialogThenNavToNextPath}
            text={localized(continueTextId)}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default DataNotSavedPrompt;
