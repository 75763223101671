import { CustomModel } from "../customModel";
import {
  BriefExtractor,
  ResourceType,
  ExtractorType as _ExtractorType,
  isPrebuiltExtractors,
} from "../extractor";
import { DetailedForm } from "../form";
import { DetailFormGroup } from "../formGroup";

type ExtractorType = NonNullable<_ExtractorType>;

function deriveExtractorTypeFromPrebuiltExtractor(
  extractor: string = "",
  fallback: ExtractorType
): ExtractorType {
  return isPrebuiltExtractors(extractor) ? extractor : fallback;
}

export const BriefExtractorMapper = {
  fromDetailForm: (form: DetailedForm): BriefExtractor => {
    return {
      id: form.id,
      name: form.name,
      resourceType: ResourceType.Form,
      extractorType: deriveExtractorTypeFromPrebuiltExtractor(
        form.config.prebuilt_extractor,
        "custom"
      ),
      associatedExtractorId: undefined,
    };
  },
  fromDetailFormGroup: (formGroup: DetailFormGroup): BriefExtractor => {
    return {
      id: formGroup.id,
      name: formGroup.name,
      resourceType: ResourceType.FormGroup,
      extractorType: deriveExtractorTypeFromPrebuiltExtractor(
        formGroup.config.prebuilt_extractor,
        "combine"
      ),
      associatedExtractorId: undefined,
    };
  },
  fromCustomModel: (customModel: CustomModel): BriefExtractor => {
    return {
      id: customModel.id,
      name: customModel.name,
      resourceType: ResourceType.CustomModel,
      extractorType: "custom",
      associatedExtractorId: customModel.formID,
    };
  },
};
