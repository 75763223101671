import * as React from "react";
import { useCallback } from "react";

import {
  CreateWorkspaceModal,
  useCreateWorkspaceModalHandle,
} from "../components/CreateWorkspaceModal";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { ExtractNote } from "../components/ExtractNote";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { README_IO_PAGE_URL } from "../constants";
import { useDesktopApp } from "../hooks/desktopApp";
import { useCommonFormGroupContainerState } from "../hooks/form_group";
import { useUnsafeParams } from "../hooks/params";
import { useReadmeIO } from "../hooks/readmeIO";
import { BriefExtractorMapper } from "../types/mappers/extractor";
import {
  FormGroupNavBarLayout,
  FormGroupNavTabKey,
} from "./FormGroupNavBarLayout";
import HeaderContainer from "./Header";

type PathParam = {
  formGroupId: string;
};

export const FormGroupExtractContainer = React.memo(() => {
  const { formGroupId } = useUnsafeParams<PathParam>();

  const containerState = useCommonFormGroupContainerState(formGroupId);

  const { workerToken, formId, formName } =
    containerState.state === "success"
      ? {
          workerToken: containerState.workerToken,
          formId: containerState.formGroup.id,
          formName: containerState.formGroup.name,
        }
      : { workerToken: undefined, formId: undefined, formName: undefined };

  const { onMacOsAppClick, onWindowsAppClick, onOpenInDesktopApp } =
    useDesktopApp(workerToken, formId, formName);

  const { onRedirectToReadmeIO } = useReadmeIO();

  const onOpenReference = useCallback(() => {
    onRedirectToReadmeIO(README_IO_PAGE_URL.referenceDocumentExtraction, {
      parameters: {
        formId,
      },
    });
  }, [formId, onRedirectToReadmeIO]);

  const {
    props: createWorkspaceModalProps,
    isCreating: isCreatingWorkspace,
    onCreateExtractorWorkspaceClick,
  } = useCreateWorkspaceModalHandle(
    containerState.state === "success"
      ? BriefExtractorMapper.fromDetailFormGroup(containerState.formGroup)
      : null
  );

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={containerState.state === "loading"} />
      <Main hasTop={true}>
        {containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form_group" />
        ) : containerState.state === "success" ? (
          <FormGroupNavBarLayout selectedTab={FormGroupNavTabKey.API}>
            <ExtractNote
              token={containerState.workerToken}
              tokens={containerState.workerTokens}
              onSelectToken={containerState.onSelectWorkerToken}
              extractorId={containerState.formGroup.id}
              onMacOsAppClick={onMacOsAppClick}
              onWindowsAppClick={onWindowsAppClick}
              onOpenInDesktopApp={onOpenInDesktopApp}
              onOpenReference={onOpenReference}
              workspaceSectionProps={{
                extractorName: containerState.formGroup.name,
                workspaces: containerState.formGroup.workspaces,
                onCreateWorkspace: onCreateExtractorWorkspaceClick,
                isCreating: isCreatingWorkspace,
              }}
            />
          </FormGroupNavBarLayout>
        ) : null}
        <CreateWorkspaceModal {...createWorkspaceModalProps} />
      </Main>
    </Layout>
  );
});

export default FormGroupExtractContainer;
