import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { useAppActionCreator } from "../actions/app";
import { useResourceOwnerActionCreator } from "../actions/resourceOwner";
import { SHOULD_RELOAD_COOKIE_POLLING_INTERVAL } from "../constants";
import { useLocale } from "../contexts/locale";
import messages from "../locale-data/en.json";
import { RootState } from "../redux/types";
import { capitalizeFirstLetter } from "../utils/capitalize";
import { CookieKey, getCookie } from "../utils/cookies";
import {
  PreferenceKey,
  getPreference,
  setPreference,
} from "../utils/preference";
import { useAppSelector } from "./redux";
import { URLParamsKey, useSearchParamUtils } from "./searchParamUtils";

export function useEnsureCrossRegionLogout() {
  const isAuthenticated = useSelector<RootState, boolean>(
    state => state.user.isAuthenticated
  );

  React.useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const intervalId = window.setInterval(() => {
      if (getCookie(CookieKey.shouldReload)) {
        window.location.reload();
      }
    }, SHOULD_RELOAD_COOKIE_POLLING_INTERVAL);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [isAuthenticated]);
}

export function useEnsureTeamLookupIdIsInURL() {
  const user = useSelector((state: RootState) => state.user.currentUser);
  const resourceOwnerId = useSelector(
    (state: RootState) => state.resourceOwner.resourceOwnerId
  );

  const { pathname } = useLocation();
  const { setParam, getParam } = useSearchParamUtils();

  React.useEffect(() => {
    if (!user || !resourceOwnerId) {
      return;
    }
    const lookupId = user.teams.find(
      team => team.id === resourceOwnerId
    )?.lookupId;
    const currentTeamValue = getParam(URLParamsKey.team);
    if (
      lookupId &&
      !pathname.startsWith("/admin") &&
      currentTeamValue !== lookupId
    ) {
      setParam(URLParamsKey.team, lookupId, true);
    }
  }, [pathname, resourceOwnerId, user, setParam, getParam]);
}

export function useWorkerToken() {
  const { getWorkerTokens, selectWorkerToken } =
    useResourceOwnerActionCreator();
  const [isFailed, setIsFailed] = React.useState(false);
  const tokens = useSelector(
    (state: RootState) => state.resourceOwner.workerTokens
  );

  const token = useAppSelector(
    state => state.resourceOwner.selectedWorkerToken
  );

  React.useEffect(() => {
    if (!tokens) {
      getWorkerTokens().catch(e => {
        console.warn(e);
        setIsFailed(true);
      });
    } else if (tokens.length > 0) {
      const lastSelectedWorkerToken = getPreference(
        PreferenceKey.lastSelectedWorkerToken
      );
      selectWorkerToken(
        (tokens.find(t => t.token === lastSelectedWorkerToken) || tokens[0])
          .token
      );
    }
  }, [tokens, getWorkerTokens, selectWorkerToken]);

  const onSelectToken = React.useCallback(
    (token: string) => {
      setPreference(PreferenceKey.lastSelectedWorkerToken, token);
      selectWorkerToken(token);
    },
    [selectWorkerToken]
  );

  return React.useMemo(
    () => ({
      token,
      tokens,
      isFailed,
      onSelectToken,
    }),
    [token, tokens, isFailed, onSelectToken]
  );
}

export function useLocalizeRegion() {
  const { localized } = useLocale();

  const localizeRegion = React.useCallback(
    (region: string) => {
      const localeId = `multi.region.${region}`;
      if (localeId in messages) {
        return localized(localeId);
      } else {
        return capitalizeFirstLetter(region);
      }
    },
    [localized]
  );

  return localizeRegion;
}

export function useFetchPlans() {
  const { listPlan } = useAppActionCreator();
  React.useEffect(
    () => {
      listPlan();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listPlan]
  );
}
