import { Checkbox } from "@fluentui/react";
import React, { useCallback, useState } from "react";

import { changeWaterMarkSetting } from "../../contexts/detectionRegionInspector/extraAccessor/waterMark";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { DetectionRegionFieldExtra } from "../../types/detectionRegion";
import styles from "./styles.module.scss";

interface DetectionRegionWaterMarkSettingProps {
  onChange: (value: any) => void;
  extras?: DetectionRegionFieldExtra;
}

function _DetectionRegionWaterMarkSetting(
  props: DetectionRegionWaterMarkSettingProps
) {
  const { onChange, extras } = props;
  const [isRemoveWaterMark, setIsRemoveWaterMark] = useState<boolean>(
    extras?.image_processing?.threshold_trunc !== undefined
  );
  const { hasPermissionToEditResource } = useTeamPermission();
  const { localized } = useLocale();

  const _onChange = useCallback(
    (
      _e?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
      checked?: boolean | undefined
    ) => {
      const newValue = checked || false;
      setIsRemoveWaterMark(newValue);

      const newExtra = changeWaterMarkSetting(newValue, extras);

      onChange(newExtra);
    },
    [onChange, extras]
  );

  return (
    <Checkbox
      className={styles["watermark-toggle"]}
      checked={isRemoveWaterMark}
      onChange={_onChange}
      label={localized(
        "form_inspector.detection_region_field_text_setting_editor.remove_watermark"
      )}
      disabled={!hasPermissionToEditResource}
    />
  );
}

export const DetectionRegionWaterMarkSetting = React.memo(
  _DetectionRegionWaterMarkSetting
);
