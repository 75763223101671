import { Values as MessageIdValues } from "@oursky/react-messageformat";
import classnames from "classnames";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import styles from "./styles.module.scss";

interface Props {
  columnIds: string[];
  columnIdValues?: MessageIdValues[];
  className?: string;
  children: React.ReactNode;
  isHeadless?: boolean;
}

export function Table(props: Props) {
  const { columnIds, children, className, columnIdValues, isHeadless } = props;
  const { localized } = useLocale();

  return (
    <table
      className={classnames(styles["table-container"], {
        [className ?? "table-container"]: className !== undefined,
      })}
    >
      <thead>
        {!isHeadless && (
          <tr>
            {columnIds.map((columnId, index) => (
              <th key={columnId}>
                {columnId && localized(columnId, columnIdValues?.[index])}
              </th>
            ))}
          </tr>
        )}
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}
