import {
  DefaultButton,
  IButtonProps,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Text,
} from "@fluentui/react";
import { FormattedMessage, Values } from "@oursky/react-messageformat";
import React from "react";

import { DangerButton, DangerButtonProps } from "../DangerButton";
import styles from "./styles.module.scss";

interface LoadingButtonProps extends Omit<IButtonProps, "text"> {
  isLoading: boolean;
  compact?: boolean;
  loadingMessageId?: string;
  loadingMessageValues?: Values;
  children?: React.ReactNode;
}

function LoadingContent(props: {
  labelId?: string;
  labelValues?: Values;
  compact?: boolean;
}) {
  const { labelId, labelValues, compact } = props;
  return (
    <div className={styles["loading-content"]}>
      <div className={styles["spinner-container"]}>
        <Spinner size={SpinnerSize.medium} />
      </div>
      {!compact && (
        <Text className={styles["loading-label"]}>
          <FormattedMessage
            id={labelId ?? "common.loading"}
            values={labelValues}
          />
        </Text>
      )}
    </div>
  );
}

export const PrimaryLoadingButton = React.forwardRef<
  PrimaryButton,
  LoadingButtonProps
>((props, ref) => {
  const {
    compact,
    isLoading,
    loadingMessageId,
    loadingMessageValues,
    children,
    disabled,
    ...restProps
  } = props;

  return (
    <PrimaryButton ref={ref} {...restProps} disabled={disabled || isLoading}>
      {isLoading ? (
        <LoadingContent
          compact={compact}
          labelId={loadingMessageId}
          labelValues={loadingMessageValues}
        />
      ) : (
        children
      )}
    </PrimaryButton>
  );
});

export const DefaultLoadingButton = React.forwardRef<
  DefaultButton,
  LoadingButtonProps
>((props, ref) => {
  const {
    compact,
    isLoading,
    loadingMessageId,
    loadingMessageValues,
    children,
    disabled,
    ...restProps
  } = props;

  return (
    <DefaultButton ref={ref} {...restProps} disabled={disabled || isLoading}>
      {isLoading ? (
        <LoadingContent
          compact={compact}
          labelId={loadingMessageId}
          labelValues={loadingMessageValues}
        />
      ) : (
        children
      )}
    </DefaultButton>
  );
});

export function DangerLoadingButton(
  props: DangerButtonProps & LoadingButtonProps
) {
  const { isLoading, disabled, compact, loadingMessageId } = props;

  const buttonProps = React.useMemo(() => {
    if (isLoading) {
      return {
        textId: undefined,
        textValues: undefined,
      };
    } else {
      return {
        textId: props.textId,
        textValues: props.textValues,
      };
    }
  }, [isLoading, props.textId, props.textValues]);

  return (
    <DangerButton {...props} {...buttonProps} disabled={disabled || isLoading}>
      {isLoading && (
        <LoadingContent compact={compact} labelId={loadingMessageId} />
      )}
    </DangerButton>
  );
}
