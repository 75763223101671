import { DocumentDetectionType } from "../types/formGroup";

export const DOCUMENT_DETECTION_TYPES: DocumentDetectionType[] = [
  "receipt",
  "passport_id",
  "id_card",
  "payment_card",
  "medical_card",
  "other",
];
