import { Modal } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { DefaultButton, PrimaryButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

export function useCannotDeleteExtractorModalHandle() {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = React.useCallback(() => {
    setIsOpen(true);
  }, []);
  const dismiss = React.useCallback(() => {
    setIsOpen(false);
  }, []);
  const props = React.useMemo(
    () => ({
      isOpen,
      dismiss,
    }),
    [dismiss, isOpen]
  );
  const methods = React.useMemo(
    () => ({
      open,
    }),
    [open]
  );
  return React.useMemo(() => ({ props, methods }), [methods, props]);
}

interface CannotDeleteExtractorModalProps {
  isOpen: boolean;
  goToWorkspacesHref: string;
  dismiss: () => void;
}

export function CannotDeleteExtractorModal(
  props: CannotDeleteExtractorModalProps
) {
  return (
    <Modal isOpen={props.isOpen}>
      <div className={styles["content"]}>
        <div className={styles["header"]}>
          <FormattedMessage id="extractor.list.modal.cannot_delete_extractor_connected_to_workspace.title" />
        </div>
        <div className={styles["field"]}>
          <FormattedMessage id="extractor.list.modal.cannot_delete_extractor_connected_to_workspace.description" />
        </div>
        <div className={styles["footer"]}>
          <DefaultButton
            onClick={props.dismiss}
            textId={
              "extractor.list.modal.cannot_delete_extractor_connected_to_workspace.button.got_it"
            }
          />
          <PrimaryButton
            href={props.goToWorkspacesHref}
            textId={
              "extractor.list.modal.cannot_delete_extractor_connected_to_workspace.button.go_to_workspaces"
            }
          />
        </div>
      </div>
    </Modal>
  );
}
