import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";

import { useCustomModelActionCreator } from "../actions/customModel";
import CustomModelEditorRightBar from "../components/CustomModelEditorRightBar";
import CustomModelRemarkModal from "../components/CustomModelRemarkModal";
import {
  DEFAULT_CUSTOM_MODEL_REMARK,
  DefaultCustomModelLabelSchema,
  UserFeatureFlag,
} from "../constants";
import errors from "../errors";
import { useTeamPermission } from "../hooks/permission";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { CustomModel, CustomModelLabelSchema } from "../types/customModel";
import { isCustomModelPreset } from "../types/customModelPreset";
import { getLabelSchemaDisplayName } from "../utils/labelSchema";

interface CustomModelRemarkModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (remark: string) => void;
}

function validateUnfrozenLabelSchema(
  customModel: CustomModel
): (string | null)[] {
  const nameCount = new Map<string, number>();

  customModel.config.labelSchema.forEach(x => {
    const key = getLabelSchemaDisplayName(x);
    nameCount.set(key, 1);
  });

  customModel.config.unfrozenLabelSchema.forEach(x => {
    const key = x.name.trim();
    nameCount.set(key, (nameCount.get(key) || 0) + 1);
  });

  return customModel.config.unfrozenLabelSchema.map(field => {
    if (field.name.trim().length === 0)
      return "custom_model_editor.right_bar.label.error.empty";

    if (/^[a-z0-9_]+$/.test(field.name) === false)
      return "custom_model_editor.right_bar.label.error.invalid_format";

    if ((nameCount.get(field.name.trim()) || 0) > 1) {
      return "custom_model_editor.right_bar.label.error.duplicated";
    }

    return null;
  });
}

export function useLabelFields(customModel: CustomModel | undefined) {
  const customModelRef = useRef(customModel);
  customModelRef.current = customModel;

  const { error: notifyError } = useToast();

  const [labelFieldErrors, setLabelFieldErrors] = useState<(null | string)[]>(
    customModel?.config.unfrozenLabelSchema?.map(_ => null) || []
  );

  const { currentUser } = useAppSelector(state => state.user);

  const { submitCustomModelRequest, updateLabelSchema, freezeLabelSchema } =
    useCustomModelActionCreator();

  const appendLabelField = useCallback(() => {
    updateLabelSchema([
      DefaultCustomModelLabelSchema,
      ...(customModelRef.current?.config.unfrozenLabelSchema || []),
    ]).catch(e => {
      if (e !== errors.ConflictFound) {
        notifyError("error.custom_model.fail_to_save_custom_model");
      }
    });

    setLabelFieldErrors(errors => [null, ...errors]);
  }, [updateLabelSchema, setLabelFieldErrors, notifyError]);

  const updateLabelField = useCallback(
    (index: number, newField: CustomModelLabelSchema) => {
      updateLabelSchema(
        (customModelRef.current?.config.unfrozenLabelSchema || []).map((v, i) =>
          i === index ? newField : v
        )
      ).catch(e => {
        if (e !== errors.ConflictFound) {
          notifyError("error.custom_model.fail_to_save_custom_model");
        }
      });

      setLabelFieldErrors(errors =>
        errors.map((v, i) => (i === index ? null : v))
      );
    },
    [updateLabelSchema, notifyError]
  );

  const deleteLabelField = useCallback(
    (index: number) => {
      const labelSchema =
        customModelRef.current?.config.unfrozenLabelSchema || [];

      updateLabelSchema(
        labelSchema.slice(0, index).concat(labelSchema.slice(index + 1))
      ).catch(e => {
        if (e !== errors.ConflictFound) {
          notifyError("error.custom_model.fail_to_save_custom_model");
        }
      });

      setLabelFieldErrors(errors =>
        errors.slice(0, index).concat(errors.slice(index + 1))
      );
    },
    [updateLabelSchema, notifyError]
  );

  const validateLabelFields = useCallback(() => {
    if (customModelRef.current === undefined) {
      return false;
    }
    const labelFieldErrors = validateUnfrozenLabelSchema(
      customModelRef.current
    );
    setLabelFieldErrors(labelFieldErrors);

    return labelFieldErrors.every(x => x === null);
  }, []);

  const freezeFieldsForLabelling = useCallback(async (): Promise<boolean> => {
    if (customModelRef.current === undefined) {
      return false;
    }
    const customModel = customModelRef.current;

    if (!validateLabelFields()) {
      return false;
    }

    if (!customModel.config.labellingStarted && currentUser !== undefined) {
      await submitCustomModelRequest(
        "start_labelling",
        currentUser,
        customModel
      );
    }

    try {
      await freezeLabelSchema();
      return true;
    } catch (e) {
      if (e !== errors.ConflictFound) {
        notifyError("error.custom_model.fail_to_save_custom_model");
      }
      return false;
    }
  }, [
    currentUser,
    submitCustomModelRequest,
    freezeLabelSchema,
    validateLabelFields,
    notifyError,
  ]);

  return useMemo(
    () => ({
      appendLabelField,
      updateLabelField,
      deleteLabelField,
      freezeFieldsForLabelling,
      validateLabelFields,
      labelFieldErrors,
    }),
    [
      appendLabelField,
      updateLabelField,
      deleteLabelField,
      freezeFieldsForLabelling,
      validateLabelFields,
      labelFieldErrors,
    ]
  );
}

export function useRequestCustomModelTraining(customModel: CustomModel) {
  const customModelRef = useRef(customModel);
  customModelRef.current = customModel;

  const { error: notifyError, success: notifySuccess } = useToast();

  const [customModelRemarkModalProps, setCustomModelRemarkModalProps] =
    useState<CustomModelRemarkModalProps>({
      isOpen: false,
      onCancel: () => {},
      onSubmit: () => {},
    });

  const { requestCustomModelTraining } = useCustomModelActionCreator();

  const initialTrainingRequested = (customModel?.config.remark ?? "") !== "";

  const isReadyForTraining =
    useAppSelector(
      state =>
        (state.customModel.paginatedCustomModelImages?.pageInfo.totalCount ||
          0) > 0
    ) &&
    customModel &&
    customModel.config.unfrozenLabelSchema.length > 0;

  const canRequestTraining =
    isReadyForTraining &&
    (!initialTrainingRequested
      ? true
      : customModel?.config.trainingRequested !== true);

  const requestTraining = useCallback(() => {
    if (customModelRef.current === undefined) {
      return;
    }

    const customModel = customModelRef.current;

    const request = async (remark: string) => {
      try {
        await requestCustomModelTraining(remark);
        notifySuccess("create_ai_form.info.submitted_form");
      } catch (e) {
        if (e !== errors.ConflictFound) {
          notifyError("error.custom_model.fail_to_save_custom_model");
        } else {
          notifyError("create_ai_form.error.fail_to_submit_form");
        }
      }
    };

    if (!initialTrainingRequested) {
      const closeModal = () => {
        setCustomModelRemarkModalProps({
          isOpen: false,
          onCancel: () => {},
          onSubmit: () => {},
        });
      };

      setCustomModelRemarkModalProps({
        isOpen: true,
        onCancel: closeModal,
        onSubmit: (remark: string) => {
          closeModal();
          request(remark === "" ? DEFAULT_CUSTOM_MODEL_REMARK : remark);
        },
      });
    } else {
      request(customModel.config.remark);
    }
  }, [
    initialTrainingRequested,
    notifyError,
    notifySuccess,
    requestCustomModelTraining,
  ]);

  return useMemo(
    () => ({
      requestTraining,
      customModelRemarkModalProps,
      canRequestTraining,
      initialTrainingRequested,
    }),
    [
      requestTraining,
      customModelRemarkModalProps,
      canRequestTraining,
      initialTrainingRequested,
    ]
  );
}

interface Props {
  customModel: CustomModel;
}

function _CustomModelEditorRightBarContainer(props: Props) {
  const { customModel } = props;

  const {
    freezeFieldsForLabelling,
    appendLabelField,
    updateLabelField,
    deleteLabelField,
    labelFieldErrors,
    validateLabelFields,
  } = useLabelFields(customModel);

  const {
    initialTrainingRequested,
    canRequestTraining,
    requestTraining,
    customModelRemarkModalProps,
  } = useRequestCustomModelTraining(customModel);

  // #3048 - Custom Model Laballing is always enabled
  const isCustomModelTrainingOrLabellingEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.CustomModelTraining)
  );
  const { hasPermissionToEditResource } = useTeamPermission();
  const navigate = useNavigate();

  const customModelId = customModel.id;

  const onRequestTraining = useCallback(() => {
    if (validateLabelFields()) {
      requestTraining();
    }
  }, [validateLabelFields, requestTraining]);

  const onNext = useCallback(async () => {
    if ((await freezeFieldsForLabelling()) === true) {
      navigate(`/custom-model/${customModelId}/label`);
    }
  }, [freezeFieldsForLabelling, navigate, customModelId]);

  const hasFieldToFreeze = customModel.config.unfrozenLabelSchema.length > 0;

  const preset = isCustomModelPreset(customModel.config.basedOn)
    ? customModel.config.basedOn
    : undefined;

  const canNext =
    (hasFieldToFreeze ||
      (preset !== undefined && !customModel.config.labellingStarted)) &&
    customModel.noOfSampleImages > 0;

  return (
    <>
      <CustomModelEditorRightBar
        preset={preset}
        labelSchema={customModel.config.labelSchema}
        unfrozenLabelSchema={customModel.config.unfrozenLabelSchema}
        appendLabelField={appendLabelField}
        updateLabelField={updateLabelField}
        deleteLabelField={deleteLabelField}
        labelFieldErrors={labelFieldErrors}
        canEdit={hasPermissionToEditResource}
        isCustomModelTrainingOrLabellingEnabled={
          isCustomModelTrainingOrLabellingEnabled
        }
        initialTrainingRequested={initialTrainingRequested}
        canRequestTraining={canRequestTraining}
        onRequestTraining={onRequestTraining}
        canNext={canNext}
        onNext={onNext}
      />
      <CustomModelRemarkModal {...customModelRemarkModalProps} />
    </>
  );
}

export const CustomModelEditorRightBarContainer = React.memo(
  _CustomModelEditorRightBarContainer
);
export default CustomModelEditorRightBarContainer;
