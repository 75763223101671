@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.connect-container {
  border: 1px solid #edebe9;
  border-radius: 2px;
  padding: 0 10px;
  height: 160px;
  overflow-y: auto;

  &.error {
    border-color: $input-error-color;
  }

  .connect-list-header {
    margin-top: 5px;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 13px;
  }

  .row {
    margin-bottom: 8px;
  }
}

.error-message {
  font-size: 12px;
  font-weight: 400;
  color: $input-error-color;
}
