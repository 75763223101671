import { DatePicker, Dropdown } from "@fluentui/react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

import { useLocale } from "../../contexts/locale";
import { OPTION_ALL_KEY } from "../../hooks/usage";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

interface Props {
  onDateRangeChange: (dateFrom: Date, dateTo: Date) => void;
  onDateRangeSearch: () => void;
  dateFrom: Date;
  dateTo: Date;
  minDate: Date;
  showTitle?: boolean;
  tokenDropdownOptions: IDropdownOption[] | undefined;
  onTokenDropdownChange: (
    _event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    _index?: number
  ) => void;
  tokenDropdownSelectedKey: string[];
}

function _UsageDateRangeSelector(props: Props) {
  const {
    dateFrom,
    dateTo,
    minDate,
    showTitle,
    tokenDropdownOptions,
    onTokenDropdownChange,
    tokenDropdownSelectedKey,
  } = props;

  const onDateFromChange = useCallback(
    (date?: Date | null) => {
      if (date) props.onDateRangeChange(date, props.dateTo);
    },
    [props]
  );

  const onDateToChange = useCallback(
    (date?: Date | null) => {
      if (date) props.onDateRangeChange(props.dateFrom, date);
    },
    [props]
  );

  const formatDate = useCallback((date?: Date) => {
    return date?.toLocaleDateString("en-GB") ?? "";
  }, []);

  const { localized } = useLocale();

  const onRenderDropdownTitle = (options?: IDropdownOption[]) => {
    let text = "";
    if (options) {
      if (options.find(option => option.key === OPTION_ALL_KEY)) {
        text = localized("usage.dropdown.all");
      } else {
        text = options.map(option => option.text).join(", ");
      }
    }
    return <div className={styles["dropdown-title"]}>{text}</div>;
  };

  return (
    <div className={styles["usage-date-range-selector"]}>
      {showTitle && (
        <h1>
          <FormattedMessage id={"usage.usage_view.title"} />
        </h1>
      )}
      <div className={styles["date-picker-row"]}>
        <div className={styles["date-picker-selectors"]}>
          <DatePicker
            label={localized("usage.date_range_selector.from")}
            onSelectDate={onDateFromChange}
            value={dateFrom}
            className={styles["date-picker"]}
            minDate={minDate}
            maxDate={dateTo as any}
            formatDate={formatDate}
          />
          <DatePicker
            label={localized("usage.date_range_selector.to")}
            onSelectDate={onDateToChange}
            value={dateTo}
            className={styles["date-picker"]}
            minDate={dateFrom as any}
            maxDate={new Date()}
            formatDate={formatDate}
          />
          {tokenDropdownOptions && (
            <Dropdown
              placeholder={localized(
                "usage.date_range_selector.select_access_token"
              )}
              label={localized("usage.date_range_selector.access_token")}
              selectedKeys={tokenDropdownSelectedKey}
              onChange={onTokenDropdownChange}
              options={tokenDropdownOptions}
              className={styles["dropdown"]}
              onRenderTitle={onRenderDropdownTitle}
              multiSelect
            />
          )}
        </div>
        <DefaultButton
          className={styles["search-button"]}
          textId="usage.date_range_selector.search"
          onClick={props.onDateRangeSearch}
        />
      </div>
    </div>
  );
}

export const UsageDateRangeSelector = React.memo(_UsageDateRangeSelector);
export default UsageDateRangeSelector;
