import { ExtractorField } from "../types/extractor";
import { DetailedForm } from "../types/form";
import {
  Formatter,
  FormatterActionSelectorType,
  FormatterStep,
  formatterSchema,
} from "../types/formatter";
import { FormatterMapper } from "../types/mappers/formatter";

export type FormatterSelector = {
  type: string;
  name: string;
  property?: string;
};

export function normalizeSelector(selector: string) {
  return selector.replace(/\'/g, "");
}

const SELECTOR_PATTERN = /^([^\'\.]*)\.(\'[^\']*\'|[^\.\']*)\.?([^\'\.]+)?$/;

export function createFormatterSelector(
  selector: string,
  noProperty?: boolean
): FormatterSelector {
  const selectorTokens = SELECTOR_PATTERN.exec(selector);
  if (selectorTokens === null) {
    return {
      type: "",
      name: "",
    };
  }
  const tokens = selectorTokens.slice(1);
  const type = tokens.shift() ?? "";
  const [name, property] = tokens;
  if (noProperty !== undefined && noProperty) {
    return {
      type,
      name: [normalizeSelector(name), property].filter(Boolean).join("."),
    };
  }
  return {
    type,
    name: normalizeSelector(name),
    property,
  };
}

export type FormatterStepValidationResult = {
  hasError: boolean;
  missingInputFields: string[];
  missingMatchingConditionFields: string[];
  duplicatedOutputFields: string[];
  invalidOutputFields: string[];
};

export type FormatterValidationResult = {
  hasError: boolean;
  steps: FormatterStepValidationResult[];
};

export function isFormatterOutputField(
  formatterSteps: FormatterStep[],
  fieldName: string
) {
  return (
    formatterSteps.some(step => {
      return (
        step.outputAs.enabled &&
        step.outputAs.fields.some(field => field.name === fieldName)
      );
    }) ?? false
  );
}

export class FormatterValidator {
  isFieldExisted(
    formatter: Formatter,
    selector: string,
    stepIndex: number,
    existingInputFields?: ExtractorField[]
  ): boolean {
    const { type, name, property } = createFormatterSelector(selector);
    const { name: formatterOutputName } = createFormatterSelector(
      selector,
      true
    );

    switch (type) {
      case FormatterActionSelectorType.Field: {
        return (
          isFormatterOutputField(
            formatter.steps.slice(0, stepIndex),
            formatterOutputName
          ) ||
          (property === undefined &&
            (existingInputFields ?? []).some(field => field.name === name))
        );
      }
      case FormatterActionSelectorType.Value: {
        return (
          isFormatterOutputField(
            formatter.steps.slice(0, stepIndex),
            formatterOutputName
          ) ||
          (existingInputFields ?? []).some(field => {
            return (
              field.name === name &&
              ((property !== undefined &&
                field.properties?.includes(property)) ||
                (property === undefined && field.properties === undefined))
            );
          })
        );
      }
      default:
        return false;
    }
  }

  validateStep(
    formatter: Formatter,
    step: FormatterStep,
    stepIndex: number,
    existingInputFields?: ExtractorField[],
    existingOutputFieldNames?: string[]
  ): FormatterStepValidationResult {
    let hasError = false;

    const outputFields: { [key: string]: boolean | number } = {};
    const duplicatedOutputFields: string[] = [];
    const invalidOutputFields: string[] = [];
    const existingInputFieldNames = (existingInputFields ?? []).map(
      field => field.name
    );

    if (step.outputAs.enabled) {
      step.outputAs.fields.forEach(field => {
        if (
          (Object.hasOwn(outputFields, field.name) ||
            existingOutputFieldNames?.includes(field.name) ||
            existingInputFieldNames.includes(field.name)) &&
          !duplicatedOutputFields.includes(field.name)
        ) {
          duplicatedOutputFields.push(field.name);
          hasError = true;
        } else if (field.name.includes("'")) {
          invalidOutputFields.push(field.name);
          hasError = true;
        } else {
          outputFields[field.name] = true;
        }
      });
    }

    const missingInputFields: string[] = [];
    step.inputSelection.fields.forEach(field => {
      const { selector } = field;
      if (
        !this.isFieldExisted(
          formatter,
          selector,
          stepIndex,
          existingInputFields
        )
      ) {
        missingInputFields.push(selector);
        hasError = true;
      }
    });

    const missingMatchingConditionFields: string[] = [];
    if (step.matchingCondition.enabled) {
      step.matchingCondition.rules.forEach(rule => {
        const { selector } = rule;
        if (
          !this.isFieldExisted(
            formatter,
            selector,
            stepIndex,
            existingInputFields
          )
        ) {
          missingMatchingConditionFields.push(selector);
          hasError = true;
        }
      });
    }

    return {
      hasError,
      missingInputFields,
      missingMatchingConditionFields,
      duplicatedOutputFields,
      invalidOutputFields,
    };
  }

  validateFormatter(
    formatter: Formatter,
    existingInputFields?: ExtractorField[]
  ): FormatterValidationResult {
    const stepOutputFieldNames = formatter.steps.map((step, _) =>
      step.outputAs.enabled ? step.outputAs.fields.map(field => field.name) : []
    );
    const steps = formatter.steps.map((step, index) => {
      const existingOutputFieldNames = stepOutputFieldNames
        .filter((_, stepIndex) => stepIndex !== index)
        .flat();
      return this.validateStep(
        formatter,
        step,
        index,
        existingInputFields,
        existingOutputFieldNames
      );
    });
    const hasError = steps.some(step => step.hasError);

    return {
      hasError,
      steps,
    };
  }
  /* Given a DetailedForm, validate the workflow object
     inside FormConfig stored in snake case field name*/

  validateForm(
    form?: DetailedForm,
    existingInputFields?: ExtractorField[]
  ): FormatterValidationResult {
    if (form === undefined) {
      return { hasError: false, steps: [] };
    }
    const formatter = FormatterMapper.fromStorage(form.config?.formatter);
    if (formatter === undefined) {
      return { hasError: false, steps: [] };
    }
    return this.validateFormatter(formatter as Formatter, existingInputFields);
  }

  /* Validate and strip unknown fields from workflow object.
     If the schema validation failed, it will return a default object
   */
  stripFormatter(formatter: any): Formatter {
    try {
      const strippedFormatter = formatterSchema.validateSync(formatter, {
        stripUnknown: true,
        recursive: true,
      }) as Formatter;

      strippedFormatter.steps = strippedFormatter.steps.map(
        (step: FormatterStep) => {
          const { inputSelection, outputAs } = step;
          if (inputSelection.fields.length !== outputAs.fields.length) {
            const count = Math.min(
              inputSelection.fields.length,
              outputAs.fields.length
            );
            inputSelection.fields = inputSelection.fields.slice(0, count);
            outputAs.fields = outputAs.fields.slice(0, count);
          }
          return step;
        }
      );

      return strippedFormatter;
    } catch {
      return { version: "1", steps: [] };
    }
  }

  /* Validate and strip unknown fields from the Workflow config inside a 
     DetailedForm object.
   */

  stripForm(form: DetailedForm): DetailedForm {
    const formatter = FormatterMapper.fromStorage(form.config?.formatter);
    if (formatter !== undefined) {
      form.config.formatter = FormatterMapper.toStorage(
        this.stripFormatter(formatter)
      );
    }
    return form;
  }
}
