import {
  PaginatedReceiptGroup,
  ReceiptGroup,
  paginatedReceiptGroupSchema,
  receiptGroupSchema,
} from "../../types/receiptGroup";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface ReceiptGroupApiClient {
  listReceiptGroup: (
    size: number,
    cursor?: string
  ) => Promise<PaginatedReceiptGroup>;
  getReceiptGroup: (receiptGroupId: string) => Promise<ReceiptGroup>;
  deleteReceiptGroup: (receiptGroupId: string) => Promise<void>;
  updateReceiptGroup: (
    receiptGroupId: string,
    updateParams: ReceiptGroupUpdateParams
  ) => Promise<ReceiptGroup>;
}

export type ReceiptGroupUpdateParams = Partial<Omit<ReceiptGroup, "id">>;

export function withReceiptGroupApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async listReceiptGroup(
      size: number,
      cursor?: string
    ): Promise<PaginatedReceiptGroup> {
      return this.lambda(
        "receipt_group:list",
        {
          page_args: {
            size,
            cursor: cursor || "",
          },
        },
        paginatedReceiptGroupSchema,
        null
      );
    }

    async getReceiptGroup(receiptGroupId: string): Promise<ReceiptGroup> {
      return this.lambda<ReceiptGroup>(
        "receipt_group:get",
        {
          receipt_group_id: receiptGroupId,
        },
        receiptGroupSchema
      );
    }

    async deleteReceiptGroup(receiptGroupId: string): Promise<void> {
      return this.lambda("receipt_group:delete", {
        receipt_group_id: receiptGroupId,
      });
    }

    async updateReceiptGroup(
      receiptGroupId: string,
      updateParams: ReceiptGroupUpdateParams
    ): Promise<ReceiptGroup> {
      const args = {
        receipt_group_id: receiptGroupId,
        ...updateParams,
      };
      return this.lambda<ReceiptGroup>(
        "receipt_group:update",
        args,
        receiptGroupSchema
      );
    }
  };
}
