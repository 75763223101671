import * as React from "react";

import { SampleImage } from "../../types/extractor";
import { Img } from "../Img";
import styles from "./styles.module.scss";

interface Props {
  images: readonly SampleImage[];
  onSelectImage(url: string): void;
}

function _ImageSelector(props: Props) {
  const { onSelectImage } = props;
  const { images } = props;

  return (
    <div className={styles["image-selector"]}>
      {images.map((x, idx) => (
        <Img
          frameClass={styles["image-frame"]}
          key={idx}
          src={x.preview ?? x.src}
          onClick={() => onSelectImage(x.src)}
        />
      ))}
    </div>
  );
}

export const ImageSelector = React.memo(_ImageSelector);
export default ImageSelector;
