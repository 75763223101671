import { Icon } from "@fluentui/react";
import * as React from "react";

import styles from "./styles.module.scss";

interface SmallIconButtonProps {
  iconName: string;
  disabled?: boolean;
  onClick?: () => void;
}

function _SmallIconButton(props: SmallIconButtonProps) {
  const { iconName, onClick } = props;

  const onClickHandler = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation();
      onClick && onClick();
    },
    [onClick]
  );

  return (
    <div className={styles["small-icon-button"]} onClick={onClickHandler}>
      <Icon iconName={iconName} />
    </div>
  );
}

export const SmallIconButton = React.memo(_SmallIconButton);
