.placeholder {
  background: #faf9f8;
  color: #323130;
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 52px;
  padding: 10px 10px;
}
.container {
  container-type: inline-size;

  :global(.ms-Button-label) {
    @container (max-width: 400px) {
      display: none;
    }
  }
}
.action-button-bar {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.callout-image-frame {
  width: 100%;

  img {
    width: 100%;
    object-fit: contain;
  }
}
