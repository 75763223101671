import {
  type OAuthCredentialList,
  oauthCredentialListSchema,
} from "../../types/oauth";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface OAuthCredentialApiClient {
  deleteOAuthCredential: (id: string) => Promise<void>;
  listOAuthCredentials: (
    resourceServer: string
  ) => Promise<OAuthCredentialList>;
}

export function withOAuthCredentialApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async deleteOAuthCredential(id: string): Promise<void> {
      return this.lambda("oauth_credential:delete", { id });
    }

    async listOAuthCredentials(
      resourceServer: string
    ): Promise<OAuthCredentialList> {
      return this.lambda(
        "oauth_credential:list",
        { resource_server: resourceServer },
        oauthCredentialListSchema,
        null
      );
    }
  };
}
