.fsl-instant-model-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.fsl-instant-model-editor-left {
  padding-right: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.fsl-instant-model-editor-left-content {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.fsl-instant-model-editor-toolbar {
  padding: 8px 0px;
}

.fsl-instant-model-editor-image-list-container {
  position: relative;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.fsl-instant-model-editor-image-list-scrollable {
  overflow-y: auto;
  flex: 1 1 0;
  position: relative;
}

.fsl-instant-model-editor-image-list {
  flex: 1 1 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.styled-mission-bar {
  margin-left: 0px;
  margin-right: 0px;
}

.dropzone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.split-view {
  margin: 0px 20px;
}
