import {
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  TextField as MSTextField,
} from "@fluentui/react";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import { PrimaryButton } from "../WrappedMSComponents/Buttons";
import TextField from "../WrappedMSComponents/TextField";

export enum BookmarkResponseType {
  Accepted,
  Cancelled,
}

type BookmarkResponse =
  | {
      type: BookmarkResponseType.Cancelled;
    }
  | {
      type: BookmarkResponseType.Accepted;
      acceptedValue: {
        title: string;
        note?: string;
      };
    };

export function useBookmarkSnapshotModalHandle() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [title, setTitle] = React.useState<string | undefined>();
  const [note, setNote] = React.useState<string | undefined>();
  const [isEdit, setIsEdit] = React.useState<boolean>(false);

  const [callback, setCallback] =
    React.useState<(response: BookmarkResponse) => void | undefined>();

  const open = React.useCallback(
    async (request?: {
      defaultTitle?: string;
      defaultNote?: string;
    }): Promise<BookmarkResponse> => {
      setIsOpen(true);

      setTitle(request?.defaultTitle);
      setNote(request?.defaultNote);
      setIsEdit(request?.defaultTitle !== undefined);

      return new Promise(resolve => {
        setCallback(() => resolve);
      });
    },
    [setCallback]
  );

  const onCancel = React.useCallback(() => {
    setIsOpen(false);
    callback?.({
      type: BookmarkResponseType.Cancelled,
    });
  }, [callback]);

  const onSave = React.useCallback(
    (title: string, note?: string) => {
      setIsOpen(false);
      callback?.({
        type: BookmarkResponseType.Accepted,
        acceptedValue: {
          title,
          note,
        },
      });
    },
    [callback]
  );

  const triggerProps = React.useMemo(() => {
    return {
      isOpen,
      callback,
      onCancel,
      onSave,
      title,
      note,
      isEdit,
      setTitle,
      setNote,
    };
  }, [
    isOpen,
    callback,
    onCancel,
    onSave,
    title,
    note,
    setTitle,
    setNote,
    isEdit,
  ]);

  return React.useMemo(() => {
    return {
      open,
      triggerProps,
    };
  }, [triggerProps, open]);
}

interface Props {
  isEdit: boolean;
  isOpen: boolean;
  onCancel: () => void;
  onSave: (title: string, note?: string) => void;
  title?: string;
  note?: string;
  setTitle: (s?: string) => void;
  setNote: (n?: string) => void;
}

const BookmarkSnapshotModal = React.memo((props: Props) => {
  const { isOpen, onCancel, onSave, title, setTitle, note, setNote, isEdit } =
    props;
  const { localized } = useLocale();

  const onTitleChanged = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      if (value === undefined) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      setTitle(value);
    },
    [setTitle]
  );
  const onNoteChanged = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      if (value === undefined) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      setNote(value);
    },
    [setNote]
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(
        isEdit ? "bookmark_modal.edit.title" : "bookmark_modal.add.title"
      ),
      showCloseButton: true,
    }),
    [localized, isEdit]
  );
  const _clear = React.useCallback(() => {
    setTitle(undefined);
    setNote(undefined);
  }, [setTitle, setNote]);
  const _onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (title) {
        onSave(title, note);
      }

      return;
    },
    [title, note, onSave]
  );
  const buttonDisable = title === undefined || title.trim().length === 0;
  return (
    <Dialog
      minWidth={464}
      hidden={!isOpen}
      onDismiss={onCancel}
      dialogContentProps={dialogContentProps}
      onDismissed={_clear}
    >
      <form onSubmit={_onSubmit}>
        <TextField
          labelId="bookmark_modal.title.label"
          onChange={onTitleChanged}
          required={true}
          value={title}
        />
        <MSTextField
          className="my-6"
          multiline={true}
          rows={5}
          resizable={false}
          onChange={onNoteChanged}
          placeholder={localized("bookmark_modal.note.placeholder")}
          value={note}
        />
        <DialogFooter>
          <PrimaryButton
            disabled={buttonDisable}
            type="submit"
            textId={"bookmark_modal.save.button"}
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
});

export default BookmarkSnapshotModal;
