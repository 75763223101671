import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import {
  NEW_TEAMS_CREATED_AT_DAYS,
  RANKING_LIST_DAY_RANGE,
  RANKING_LIST_LIMIT,
} from "../../constants";
import { AdminReportState } from "../../reducers/admin";
import { BriefCustomModel } from "../../types/customModel";
import CustomModelTable from "../CustomModelTable";
import RegionTabBar from "../RegionTabBar";
import ShortSpinner from "../ShortSpinner";
import TeamRankingList from "../TeamRankingList";
import TeamTable from "../TeamTable";
import { TeamTableRowInfo } from "../TeamTableRow";
import styles from "./styles.module.scss";

function getListStates(regionKey: string, reportState: AdminReportState) {
  return {
    teamRankingList: reportState.teamRankingListByRegion[regionKey],
    newTeamList: reportState.newTeamListByRegion[regionKey],
    newCustomModelList: reportState.newCustomModelByRegion[regionKey],
  };
}

interface AdminReportLayoutProps {
  regionOptions: { key: string; label: string }[];
  selectedRegionKey: string;
  onSelectedRegionKeyChange: (key: string) => void;
  reportState: AdminReportState;
  newTeamListOffset: number;
  onNewTeamListNavigateToPage: (page: number) => void;
  newTeamListPageSize: number;
  newCustomModelListOffset: number;
  onNewCustomModelListNavigateToPage: (page: number) => void;
  newCustomModelPageSize: number;
  onCustomModelItemClick: (customModel: BriefCustomModel) => void;
}

function _AdminReportLayout(props: AdminReportLayoutProps) {
  const {
    selectedRegionKey,
    onSelectedRegionKeyChange,
    regionOptions,
    reportState,
    newTeamListOffset,
    onNewTeamListNavigateToPage,
    newTeamListPageSize,
    newCustomModelListOffset,
    onNewCustomModelListNavigateToPage,
    newCustomModelPageSize,
    onCustomModelItemClick,
  } = props;

  const { teamRankingList, newTeamList, newCustomModelList } =
    React.useMemo(() => {
      return getListStates(selectedRegionKey, reportState);
    }, [selectedRegionKey, reportState]);

  return (
    <div className={styles["report"]}>
      <h3 className={styles["title"]}>
        <FormattedMessage id="report.title" />
      </h3>
      <div className={styles["tab-bar"]}>
        <RegionTabBar
          selectedRegion={selectedRegionKey}
          setSelectedRegion={onSelectedRegionKeyChange}
          regionOptions={regionOptions}
        />
      </div>
      <h4 className={styles["section-title"]}>
        <FormattedMessage
          id="report.ranking.title"
          values={{
            size: RANKING_LIST_LIMIT,
            days: RANKING_LIST_DAY_RANGE,
          }}
        />
      </h4>
      {teamRankingList ? (
        <TeamRankingList
          isLoading={teamRankingList.isFetching}
          teamsUsages={teamRankingList.teamsUsages ?? []}
          regionKey={selectedRegionKey}
          error={teamRankingList.error}
        />
      ) : (
        <ShortSpinner />
      )}
      <h4 className={styles["section-title"]}>
        <FormattedMessage
          id="report.new_teams.title"
          values={{
            days: NEW_TEAMS_CREATED_AT_DAYS,
          }}
        />
      </h4>
      {newTeamList ? (
        <TeamTable
          isEmpty={
            !newTeamList.isFetching &&
            (newTeamList.pageInfo?.totalCount ?? 0) === 0
          }
          error={newTeamList.error}
          regionKey={selectedRegionKey}
          offset={newTeamListOffset}
          pageSize={newTeamListPageSize}
          totalCount={newTeamList.pageInfo?.totalCount ?? 0}
          teams={newTeamList.teams ?? []}
          onNavigateToPage={onNewTeamListNavigateToPage}
          isLoading={newTeamList.isFetching}
          displayInfos={[
            TeamTableRowInfo.ContactEmail,
            TeamTableRowInfo.CreatedAt,
            TeamTableRowInfo.TotalUsage,
          ]}
          showTotalCount={true}
        />
      ) : (
        <ShortSpinner />
      )}
      <h4 className={styles["section-title"]}>
        <FormattedMessage id="header.custom_models" />
      </h4>
      {newCustomModelList ? (
        <CustomModelTable
          offset={newCustomModelListOffset}
          customModels={newCustomModelList.customModels}
          isCustomModelsFetching={newCustomModelList.isFetching}
          customModelsPageInfo={newCustomModelList.pageInfo}
          pageSize={newCustomModelPageSize}
          onNavigateToPage={onNewCustomModelListNavigateToPage}
          onItemClick={onCustomModelItemClick}
          error={newCustomModelList.error}
        />
      ) : (
        <ShortSpinner />
      )}
    </div>
  );
}

export const AdminReportLayout = React.memo(_AdminReportLayout);
export default AdminReportLayout;
