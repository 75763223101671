import { Dropdown, IDropdownOption } from "@fluentui/react";
import React, { useCallback, useMemo, useState } from "react";

import { DATE_INPUT_FORMAT_OPTIONS } from "../../../constants/formConfig";
import { useLocale } from "../../../contexts/locale";
import { ChangeDateInputFormatConfig } from "../../../types/advancedTokenSetup/table";
import { DateInputFormatType } from "../../../types/formConfig";
import styles from "./styles.module.scss";

type BaseProps = ReturnType<
  typeof useChangeInputDateFormatViewHandle
>["triggerProps"];

type Props = BaseProps;

export function useChangeInputDateFormatViewHandle(
  defaultDateInputFormat?: DateInputFormatType
) {
  const [selectedValue, setSelectedValue] = useState<DateInputFormatType>(
    defaultDateInputFormat || DATE_INPUT_FORMAT_OPTIONS[0]
  );
  const onChange = useCallback(
    (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option && option.id) {
        setSelectedValue(option.id as DateInputFormatType);
      }
    },
    []
  );

  const submit = useCallback(
    (): ChangeDateInputFormatConfig => ({
      date_input_format: selectedValue,
    }),
    [selectedValue]
  );
  return useMemo(
    () => ({
      triggerProps: {
        selectedValue,
        onChange,
      },
      submit,
    }),
    [selectedValue, onChange, submit]
  );
}

const ChangeInputDateFormatView = React.memo<Props>(props => {
  const { selectedValue, onChange } = props;

  const { localized } = useLocale();
  const options: IDropdownOption[] = useMemo(() => {
    return DATE_INPUT_FORMAT_OPTIONS.map(f => ({
      id: f,
      key: f,
      text: localized(`date_input_format.${f}`),
    }));
  }, [localized]);

  return (
    <div className={styles["change-date-input-config"]}>
      <Dropdown
        label={localized(
          "advance_token_setup_field_replacement_setting_panel.panel.change_date_input.convert_date_order"
        )}
        selectedKey={selectedValue}
        onChange={onChange}
        options={options}
      />
    </div>
  );
});

export default ChangeInputDateFormatView;
