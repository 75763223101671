import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IChoiceGroupOption,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  IconButton,
  PrimaryButton,
  Slider,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import produce from "immer";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useFormActionCreator } from "../../actions/form";
import {
  AllLLMModels,
  DefaultLLMModel,
  LLMModelsWithVision,
} from "../../constants/llmModel";
import { buildFormSettings } from "../../contexts/formEditor";
import { useLocale } from "../../contexts/locale";
import { useTeamPermission } from "../../hooks/permission";
import { RootState } from "../../redux/types";
import {
  DefaultLLMInputType,
  LLMCompletion,
  LLMInputType,
} from "../../types/llmCompletion";
import styles from "./styles.module.scss";

const DefaultTopP = 0.5;

interface Props {
  llmCompletions?: { [key: string]: LLMCompletion };
  onClose: () => void;
  onConfirm: (setting?: { [key: string]: LLMCompletion }) => void;
  isOpen: boolean;
  shouldHideTaskName?: boolean;
}

const AddNewPromptKey = "#add";
const ModelDropdownOptions = AllLLMModels.map(x => ({ key: x, text: x }));

export function useLLMCompletionSettingsModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openLLMCompletionSettingsModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const form = useSelector((state: RootState) => state.form.currentForm);
  const llmCompletions = useSelector(
    (state: RootState) => state.form.currentForm?.config.llm_completions
  );

  const { updateForm } = useFormActionCreator();

  const onConfirm = useCallback(
    (llmCompletions?: { [key: string]: LLMCompletion }) => {
      if (!form) {
        return;
      }
      const settings = buildFormSettings(form, {
        llmCompletions,
      });
      updateForm(settings);
      onClose();
    },
    [form, onClose, updateForm]
  );

  const llmCompletionSettingsPayload = useMemo(
    () => ({
      llmCompletions,
      isOpen,
      onClose,
      onConfirm,
    }),
    [isOpen, llmCompletions, onClose, onConfirm]
  );

  return useMemo(
    () => ({
      llmCompletionSettingsPayload,
      openLLMCompletionSettingsModal,
    }),
    [llmCompletionSettingsPayload, openLLMCompletionSettingsModal]
  );
}

function LLMCompletionInputTypeOptionLabel(props: { text: string }) {
  const { text } = props;
  return (
    <div className="pl-7">
      <div className="text-gray-900">{text}</div>
    </div>
  );
}

export function LLMCompletionSettingsModal(props: Props) {
  const {
    llmCompletions: existingLLMCompletions,
    onClose,
    isOpen,
    onConfirm,
    shouldHideTaskName,
  } = props;
  const { localized } = useLocale();
  const { hasPermissionToEditResource } = useTeamPermission();

  const firstTaskName = existingLLMCompletions
    ? Object.keys(existingLLMCompletions)[0]
    : undefined;

  const [selectedLLMCompletion, setSelectedLLMCompletion] = useState<
    LLMCompletion | undefined
  >(
    existingLLMCompletions && firstTaskName
      ? existingLLMCompletions[firstTaskName]
      : undefined
  );

  const [taskName, setTaskName] = useState<string | undefined>(firstTaskName);
  const [taskNameError, setTaskNameError] = useState<string | undefined>();
  const [selectedTaskName, setSelectedTaskName] = useState<string>(
    firstTaskName || AddNewPromptKey
  );
  const [taskNamesToDelete, setTaskNamesToDelete] = useState<string[]>([]);

  const modalProps: IModalProps = useMemo(
    () => ({
      isBlocking: true,
    }),
    []
  );

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("llm_completion_settings_modal.title"),
    }),
    [localized]
  );

  const promptDropdownOptions: IDropdownOption[] = useMemo(
    () => [
      {
        key: AddNewPromptKey,
        text: localized("llm_completion_settings_modal.add_new_prompt"),
      },
      ...(existingLLMCompletions !== undefined
        ? Object.keys(existingLLMCompletions)
            .filter(x => !taskNamesToDelete.includes(x))
            .map(taskName => ({
              key: taskName,
              text: taskName,
            }))
        : []),
    ],
    [existingLLMCompletions, taskNamesToDelete, localized]
  );

  const inputTypeOptions = useMemo<IChoiceGroupOption[]>(
    () =>
      Object.values(LLMInputType).map(inputType => ({
        key: inputType,
        text: localized(
          `llm_completion_settings_modal.input_type.${inputType}.label`
        ),
        onRenderLabel: props =>
          props != null ? (
            <LLMCompletionInputTypeOptionLabel text={props.text} />
          ) : null,
      })),
    [localized]
  );

  const onSubmit = useCallback(() => {
    if (
      !taskName &&
      !prompt &&
      existingLLMCompletions !== undefined &&
      Object.keys(existingLLMCompletions).length === taskNamesToDelete.length
    ) {
      onConfirm({});
      setTaskNamesToDelete([]);
      return;
    }

    if (!taskName || !taskName.match(/^[A-Za-z_0-9]+$/)) {
      setTaskNameError("error.llm_completion_settings_modal.invalid_task_name");
      return;
    }

    if (taskName && selectedLLMCompletion) {
      const settingsToKeep = Object.fromEntries(
        existingLLMCompletions !== undefined
          ? Object.entries(existingLLMCompletions).filter(
              x =>
                ![
                  ...taskNamesToDelete,
                  ...(selectedTaskName === AddNewPromptKey
                    ? []
                    : [selectedTaskName]),
                ].includes(x[0])
            )
          : []
      );

      if (Object.keys(settingsToKeep).includes(taskName)) {
        setTaskNameError(
          "error.llm_completion_settings_modal.duplicated_task_name"
        );
        return;
      }

      onConfirm({
        ...settingsToKeep,
        [taskName]: selectedLLMCompletion,
      });

      if (selectedTaskName !== taskName) {
        setSelectedTaskName(taskName);
      }
      setTaskNamesToDelete([]);
    }
  }, [
    taskName,
    selectedLLMCompletion,
    onConfirm,
    selectedTaskName,
    existingLLMCompletions,
    taskNamesToDelete,
  ]);

  const onTaskNameChange = useCallback(
    (
      _e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue !== undefined) {
        setTaskName(newValue);
        setTaskNameError(undefined);
      } else {
        setTaskName(undefined);
      }
    },
    []
  );

  const onPromptChange = useCallback(
    (
      _e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue !== undefined) {
        setSelectedLLMCompletion(prev =>
          prev === undefined
            ? { prompt: newValue }
            : produce(prev, draft => {
                draft.prompt = newValue;
              })
        );
      } else {
        setSelectedLLMCompletion(undefined);
      }
    },
    []
  );

  const onSelectPrompt = useCallback(
    taskNameToSelect => {
      setSelectedTaskName(taskNameToSelect);
      if (
        existingLLMCompletions !== undefined &&
        taskNameToSelect in existingLLMCompletions
      ) {
        setTaskName(taskNameToSelect);
        setSelectedLLMCompletion(existingLLMCompletions[taskNameToSelect]);
      } else {
        setTaskName("");
        setSelectedLLMCompletion({
          prompt: "",
          parameters: {
            top_p: 0.5,
          },
        });
      }
    },
    [existingLLMCompletions]
  );

  const onPromptSelectionChanged = useCallback(
    (
      _event: React.FormEvent<unknown>,
      option?: IDropdownOption,
      _n?: number
    ) => {
      if (option === undefined) {
        return;
      }

      const taskName = option.key as string;
      onSelectPrompt(taskName);
    },
    [onSelectPrompt]
  );

  const onDeletePrompt = useCallback(() => {
    const taskNameToDelete = selectedTaskName;
    setTaskNamesToDelete(prev => [...prev, taskNameToDelete]);

    const remainingKeys = (
      existingLLMCompletions !== undefined
        ? Object.keys(existingLLMCompletions)
        : []
    ).filter(x => ![...taskNamesToDelete, taskNameToDelete].includes(x));

    onSelectPrompt(remainingKeys.length ? remainingKeys[0] : AddNewPromptKey);
  }, [
    selectedTaskName,
    existingLLMCompletions,
    taskNamesToDelete,
    onSelectPrompt,
  ]);

  const setParameter = useCallback((key: string, value: any) => {
    setSelectedLLMCompletion(prev =>
      prev === undefined
        ? {
            prompt: "",
            parameters: {
              [key]: value,
            },
          }
        : produce(prev, draft => {
            if (draft.parameters) {
              (draft.parameters as any)[key] = value;
            } else {
              draft.parameters = {
                [key]: value,
              };
            }
          })
    );
  }, []);

  const onShouldOutputPromptToggle = useCallback(
    (
      _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ) => {
      setParameter("should_output_prompt", checked);
    },
    [setParameter]
  );

  const onShouldSplitPromptToggle = useCallback(
    (
      _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ) => {
      setParameter("should_split_prompt", checked);
    },
    [setParameter]
  );

  const onShouldAddBorderLineToOCRToggle = useCallback(
    (
      _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ) => {
      setParameter("should_add_border_line_to_ocr", checked);
    },
    [setParameter]
  );

  const onShouldPreserveHorizontalWhitespaceToggle = useCallback(
    (
      _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ) => {
      setParameter("should_preserve_horizontal_whitespace", checked);
    },
    [setParameter]
  );

  const onShouldPreserveVerticalWhitespaceToggle = useCallback(
    (
      _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ) => {
      setParameter("should_preserve_vertial_whitespace", checked);
    },
    [setParameter]
  );

  const onChangeLLMInputType = useCallback(
    (
      _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      if (option != null) {
        setParameter("input_type", option.key as LLMInputType);
      }
    },
    [setParameter]
  );

  const onTopPChanged = useCallback(
    (newValue: number) => {
      setParameter("top_p", newValue);
    },
    [setParameter]
  );

  const onPresencePenaltyChanged = useCallback(
    (newValue: number) => {
      setParameter("presence_penalty", newValue);
    },
    [setParameter]
  );

  const onFrequencyPenaltyChanged = useCallback(
    (newValue: number) => {
      setParameter("frequency_penalty", newValue);
    },
    [setParameter]
  );

  const onModelChanged = useCallback(
    (
      _event: React.FormEvent<unknown>,
      option?: IDropdownOption,
      _n?: number
    ) => {
      if (option === undefined) {
        return;
      }

      const model = option.key as string;
      setParameter("model", model);
      if (!LLMModelsWithVision.includes(model)) {
        setParameter("input_type", LLMInputType.OCROnly);
      }
    },
    [setParameter]
  );

  const onDismiss = useCallback(() => {
    setTaskNamesToDelete([]);
    onSelectPrompt(
      (existingLLMCompletions !== undefined
        ? Object.keys(existingLLMCompletions)
        : [])[0] || AddNewPromptKey
    );
    onClose();
  }, [onClose, onSelectPrompt, existingLLMCompletions]);

  const canSubmit =
    (taskName && prompt) ||
    (existingLLMCompletions !== undefined &&
      Object.keys(existingLLMCompletions).length === taskNamesToDelete.length);

  const selectedModel =
    selectedLLMCompletion?.parameters?.model ?? DefaultLLMModel;

  return (
    <Dialog
      minWidth={854}
      hidden={!isOpen}
      onDismiss={onDismiss}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      {!shouldHideTaskName && (
        <>
          <div className={styles["prompt-selection"]}>
            <Dropdown
              className={styles["prompt-selection-dropdown"]}
              disabled={
                existingLLMCompletions === undefined ||
                Object.keys(existingLLMCompletions).length === 0
              }
              selectedKey={selectedTaskName}
              options={promptDropdownOptions}
              onChange={onPromptSelectionChanged}
            />
            <IconButton
              className={styles["delete-prompt-button"]}
              iconProps={{ iconName: "IconTrash" }}
              onClick={onDeletePrompt}
              disabled={selectedTaskName === AddNewPromptKey}
            />
          </div>
          <div className={styles["task-name"]}>
            <TextField
              value={taskName}
              description={localized("llm_completion_settings_modal.taskName")}
              onChange={onTaskNameChange}
              errorMessage={taskNameError && localized(taskNameError)}
            />
          </div>
        </>
      )}

      <div className={styles["prompt"]}>
        <TextField
          value={selectedLLMCompletion?.prompt}
          description={localized("llm_completion_settings_modal.prompt")}
          rows={30}
          multiline
          onChange={onPromptChange}
        />
      </div>

      <div className={styles["settings"]}>
        <Checkbox
          label={localized(
            "llm_completion_settings_modal.should_output_prompt"
          )}
          onChange={onShouldOutputPromptToggle}
          checked={!!selectedLLMCompletion?.parameters?.should_output_prompt}
        />
        <div>
          <Checkbox
            label={localized(
              "llm_completion_settings_modal.should_split_prompt"
            )}
            onChange={onShouldSplitPromptToggle}
            checked={!!selectedLLMCompletion?.parameters?.should_split_prompt}
          />
          <div className={styles["explaination"]}>
            <FormattedMessage id="llm_completion_settings_modal.should_split_prompt.explaination" />
          </div>
        </div>
        <div>
          <Checkbox
            label={localized(
              "llm_completion_settings_modal.should_add_border_line_to_ocr"
            )}
            onChange={onShouldAddBorderLineToOCRToggle}
            checked={
              !!selectedLLMCompletion?.parameters?.should_add_border_line_to_ocr
            }
          />
          <div className={styles["explaination"]}>
            <FormattedMessage id="llm_completion_settings_modal.should_add_border_line_to_ocr.explaination" />
          </div>
        </div>
        <Checkbox
          label={localized(
            "llm_completion_settings_modal.should_preserve_horizontal_whitespace"
          )}
          onChange={onShouldPreserveHorizontalWhitespaceToggle}
          checked={
            !!selectedLLMCompletion?.parameters
              ?.should_preserve_horizontal_whitespace
          }
        />
        <Checkbox
          label={localized(
            "llm_completion_settings_modal.should_preserve_vertial_whitespace"
          )}
          onChange={onShouldPreserveVerticalWhitespaceToggle}
          checked={
            !!selectedLLMCompletion?.parameters
              ?.should_preserve_vertial_whitespace
          }
        />
        <div>
          <Dropdown
            label={localized("llm_completion_settings_modal.model")}
            selectedKey={selectedModel}
            options={ModelDropdownOptions}
            onChange={onModelChanged}
          />

          {LLMModelsWithVision.includes(selectedModel) && (
            <ChoiceGroup
              className="mt-[5px]"
              options={inputTypeOptions}
              selectedKey={
                selectedLLMCompletion?.parameters?.input_type ??
                DefaultLLMInputType
              }
              onChange={onChangeLLMInputType}
            />
          )}
        </div>

        <div>
          <Slider
            label={localized("llm_completion_settings_modal.top_p")}
            min={0.05}
            max={1.0}
            step={0.05}
            showValue
            snapToStep
            value={selectedLLMCompletion?.parameters?.top_p ?? DefaultTopP}
            onChange={onTopPChanged}
          />
          <div className={styles["explaination"]}>
            <FormattedMessage id="llm_completion_settings_modal.top_p.explaination" />
          </div>
        </div>
        <div>
          <Slider
            label={localized("llm_completion_settings_modal.presence_penalty")}
            min={-2.0}
            max={2.0}
            step={0.1}
            showValue
            snapToStep
            value={selectedLLMCompletion?.parameters?.presence_penalty}
            onChange={onPresencePenaltyChanged}
          />
          <div className={styles["explaination"]}>
            <FormattedMessage id="llm_completion_settings_modal.presence_penalty.explaination" />
          </div>
        </div>
        <div>
          <Slider
            label={localized("llm_completion_settings_modal.frequency_penalty")}
            min={-2.0}
            max={2.0}
            step={0.1}
            showValue
            snapToStep
            value={selectedLLMCompletion?.parameters?.frequency_penalty}
            onChange={onFrequencyPenaltyChanged}
          />
          <div className={styles["explaination"]}>
            <FormattedMessage id="llm_completion_settings_modal.frequency_penalty.explaination" />
          </div>
        </div>
      </div>
      <DialogFooter>
        <DefaultButton
          onClick={onDismiss}
          text={localized(
            hasPermissionToEditResource ? "common.cancel" : "common.close"
          )}
        />
        {hasPermissionToEditResource && (
          <PrimaryButton
            onClick={onSubmit}
            text={localized("common.ok")}
            disabled={!canSubmit}
          />
        )}
      </DialogFooter>
    </Dialog>
  );
}
