import {
  CustomCell,
  CustomRenderer,
  GridCellKind,
  Item,
  Rectangle,
  getMiddleCenterBias,
} from "@glideapps/glide-data-grid";

import { SettingConfig } from "../../../types/advancedTokenSetup/table";
import { renderErrorState } from "./ErrorState";
import { renderIcon } from "./Utils/Icon";
import { truncatStr } from "./Utils/Text";
import { contains } from "./Utils/math";

interface SettingCellProps {
  readonly kind: "setting-cell";
  name: string;
  item: Item;
  config?: SettingConfig;
  onClick?: (item: Item, cell: SettingCell) => void;
  error?: string;
  target: string;
}

export type SettingCell = CustomCell<SettingCellProps>;

export function isSettingCell(cell: CustomCell): cell is SettingCell {
  return (cell.data as any).kind === "setting-cell";
}

const bbox = [0, 0, 16, 16];

export const SettingCellRenderer: CustomRenderer<SettingCell> = {
  kind: GridCellKind.Custom,
  isMatch: isSettingCell,
  draw: args => {
    const { ctx, theme, rect, cell } = args;
    const { error } = cell.data;

    let textOffset = 0;
    if (error) {
      renderErrorState(args, error);
      textOffset += 20;
    }
    const drawArea: Rectangle = {
      x: rect.x + theme.cellHorizontalPadding,
      y: rect.y + theme.cellVerticalPadding,
      width: rect.width - 2 * theme.cellHorizontalPadding,
      height: rect.height - 2 * theme.cellVerticalPadding,
    };
    ctx.font = "12px";
    ctx.fillStyle = "black";
    const bias = getMiddleCenterBias(ctx, `12px ${theme.fontFamily}`);

    const [displayText] = truncatStr(cell.data.name, ctx, 166 - textOffset);
    ctx.fillText(
      displayText,
      textOffset + drawArea.x,
      drawArea.y + drawArea.height / 2 + bias
    );
    renderIcon(
      "setting",
      ctx,
      drawArea.x + (drawArea.width - bbox[2]),
      drawArea.y + (drawArea.height - bbox[3]) / 2,
      bbox[2],
      "#25D0B1"
    );
  },
  onClick: args => {
    const { preventDefault, cell, posX, posY, bounds, theme } = args;
    const drawArea: Rectangle = {
      x: bounds.x + theme.cellHorizontalPadding,
      y: bounds.y + theme.cellVerticalPadding,
      width: bounds.width - 2 * theme.cellHorizontalPadding,
      height: bounds.height - 2 * theme.cellVerticalPadding,
    };

    if (
      contains(
        [
          drawArea.x + drawArea.width - bbox[2],
          drawArea.y + (drawArea.height - bbox[3]) / 2,
          bbox[2],
          bbox[3],
        ],
        bounds.x + posX,
        bounds.y + posY
      )
    ) {
      if (cell.data.onClick) {
        cell.data.onClick(cell.data.item, cell);
      }
      preventDefault();
      return cell;
    }
    return undefined;
  },
};
