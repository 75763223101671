import * as React from "react";

import { TeamDetail } from "../../types/team";
import LoadingModal from "../LoadingModal";
import TeamDetailBasicContent, {
  TeamDetailTab,
} from "../TeamDetailBasicContent";
import styles from "./styles.module.scss";

interface Props {
  region: string;
  team?: TeamDetail;
  currentTab: TeamDetailTab;
  isLoading?: boolean;
  onTabSelected: (tab: TeamDetailTab) => void;
  children?: React.ReactNode;
}

function _TeamDetailLayout(props: Props) {
  const { region, team, isLoading, currentTab, onTabSelected, children } =
    props;

  return (
    <>
      {team && (
        <div className={styles["team-detail"]}>
          <TeamDetailBasicContent
            team={team}
            region={region}
            currentTab={currentTab}
            onTabSelected={onTabSelected}
          />
          <div className={styles["tab"]}>{children}</div>
        </div>
      )}
      <LoadingModal isOpen={isLoading ?? false} />
    </>
  );
}

export const TeamDetailLayout = React.memo(_TeamDetailLayout);
export default TeamDetailLayout;
