import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { UserFeatureFlag } from "../../constants";
import { useAppSelector } from "../../hooks/redux";
import { Usage } from "../../types/usage";
import { Table } from "../Table";
import styles from "./styles.module.scss";

interface Props {
  usages: Usage[];
}

const obsoletedApiList = ["extract_fields", "extract_receipt_info"];
const noEntityApiList = ["detect_documents", "detect_pii"];

function EntityTable(props: UsageSectionProps) {
  const { usage } = props;

  const [expandedRows, setExpandedRows] = React.useState<Set<number>>(
    new Set()
  );

  const onClickUsageRow = React.useCallback(
    (i: number) => {
      if (expandedRows.has(i)) {
        expandedRows.delete(i);
      } else {
        expandedRows.add(i);
      }
      setExpandedRows(new Set(expandedRows));
    },
    [expandedRows]
  );

  React.useEffect(() => {
    setExpandedRows(new Set());
  }, [usage]);

  return (
    <Table
      columnIds={[
        "usage.table.name",
        "usage.table.entity_id",
        "usage.table.unclassified_count",
        "usage.table.sync_count",
        "usage.table.async_count",
        "usage.table.total_count",
      ]}
      className={styles["table"]}
      isHeadless={false}
    >
      {usage.counts.map((usage, i) => (
        <React.Fragment key={i}>
          <tr
            className={styles["usage-row"]}
            onClick={() => onClickUsageRow(i)}
          >
            <td>
              <Icon
                iconName={expandedRows.has(i) ? "ChevronUp" : "ChevronDown"}
                className={styles["chevron"]}
              />
              {usage.name}
            </td>
            <td>{usage.entityId}</td>
            <td>{usage.unclassifiedCount}</td>
            <td>{usage.syncCount}</td>
            <td>{usage.asyncCount}</td>
            <td>{usage.totalCount}</td>
          </tr>
          {expandedRows.has(i) && (
            <>
              <tr className={styles["token-title-row"]}>
                <td>
                  <FormattedMessage id="team.tokens.access_token" />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {usage.countByToken.map(token => (
                <tr key={token.id} className={styles["token-row"]}>
                  <td>
                    {token.name}{" "}
                    {token.isRevoked && (
                      <FormattedMessage id="usage.table.revoked_token" />
                    )}
                  </td>
                  <td></td>
                  <td>{token.unclassifiedCount}</td>
                  <td>{token.syncCount}</td>
                  <td>{token.asyncCount}</td>
                  <td>{token.totalCount}</td>
                </tr>
              ))}
            </>
          )}
        </React.Fragment>
      ))}
    </Table>
  );
}

function NonEntityTable(props: UsageSectionProps) {
  const { usage } = props;

  return (
    <Table
      columnIds={[
        "",
        "",
        "usage.table.unclassified_count",
        "usage.table.sync_count",
        "usage.table.async_count",
        "usage.table.total_count",
      ]}
      className={styles["table"]}
      isHeadless={false}
    >
      {usage.counts.map((usage, i) => (
        <React.Fragment key={i}>
          <>
            <tr className={styles["token-title-row"]}>
              <td>
                <FormattedMessage id="team.tokens.access_token" />
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            {usage.countByToken.map(token => (
              <tr key={token.id} className={styles["token-row"]}>
                <td>
                  {token.name}{" "}
                  {token.isRevoked && (
                    <FormattedMessage id="usage.table.revoked_token" />
                  )}
                </td>
                <td></td>
                <td>{token.unclassifiedCount}</td>
                <td>{token.syncCount}</td>
                <td>{token.asyncCount}</td>
                <td>{token.totalCount}</td>
              </tr>
            ))}
          </>
        </React.Fragment>
      ))}
    </Table>
  );
}

export interface UsageSectionProps {
  usage: Usage;
}

export function UsageSection(props: UsageSectionProps) {
  const { usage } = props;
  const hasEntity = !noEntityApiList.includes(usage.api);

  return (
    <div className={styles["usage"]}>
      <div className={styles["api-name-label"]}>
        <label>
          <FormattedMessage id={`usage.api.${usage.api}`} />
        </label>
        <label className={styles["total-label"]}>{usage.total}</label>
      </div>

      {hasEntity ? (
        <EntityTable usage={usage} />
      ) : (
        <NonEntityTable usage={usage} />
      )}

      <div className={styles["table_total"]}>
        <FormattedMessage
          id={"usage.table.total"}
          values={{ count: usage.total }}
        />
      </div>
    </div>
  );
}

function _UsageView(props: Props) {
  const { usages } = props;

  const apisToIgnore = useAppSelector(state => [
    ...obsoletedApiList,
    ...(state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.PII)
      ? []
      : ["detect_pii"]),
  ]);

  return (
    <div className={styles["usage-history"]}>
      {usages
        .filter(usage => !apisToIgnore.includes(usage.api) || usage.total > 0)
        .map((usage: Usage, usageIndex: number) => (
          <UsageSection usage={usage} key={usageIndex} />
        ))}
    </div>
  );
}

export const UsageView = React.memo(_UsageView);
export default UsageView;
