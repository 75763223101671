import { Toggle } from "@fluentui/react";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { DateTime } from "luxon";
import React from "react";

import { AppConfig } from "../../config";
import { useLocale } from "../../contexts/locale";
import { PageInfoWithOffset } from "../../types/pageInfo";
import { RequestLog, Usage } from "../../types/usage";
import {
  PreferenceKey,
  getPreference,
  setPreference,
} from "../../utils/preference";
import RequestLogsView from "../RequestLogList";
import ShortSpinner from "../ShortSpinner";
import UsageView from "../Usage";
import UsageDateRangeSelector from "../UsageDateRangeSelector";
import styles from "./styles.module.scss";

export interface DateRange {
  start: Date;
  end: Date;
}

interface Props {
  usages?: Usage[];
  requestLogs?: RequestLog[];
  pageInfo?: PageInfoWithOffset;
  isFetchingUsages: boolean;
  isFetchingRequestLogs: boolean;
  pageSize: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  dateRange: DateRange;
  onDateRangeChange: (fromDate: Date, toDate: Date) => void;
  searchDateRange: () => void;
  startFromRef: React.MutableRefObject<Date>;
  tokenDropdownOptions: IDropdownOption[] | undefined;
  onTokenDropdownChange: (
    _event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
    _index?: number
  ) => void;
  tokenDropdownSelectedKey: string[];
}

interface Loading {
  type: "loading";
}

interface ShowUsages {
  type: "showUsage";
  usages: Usage[];
}

interface ShowRequestLogs {
  type: "showRequestLogs";
  requestLogs: RequestLog[];
  totalCount: number;
}

type TeamDetailUsageState = Loading | ShowUsages | ShowRequestLogs;

function getShouldShowFullLog() {
  const shouldShowFullLog = getPreference(PreferenceKey.shouldShowFullLog);
  return shouldShowFullLog === null ? false : JSON.parse(shouldShowFullLog);
}

function useTeamDetailUsageState(props: Props) {
  const {
    usages,
    isFetchingRequestLogs,
    isFetchingUsages,
    requestLogs,
    pageInfo,
  } = props;

  const [shouldShowFullLog, setShouldShowFullLog] = React.useState<boolean>(
    getShouldShowFullLog()
  );

  const showUsage: ShowUsages | null =
    !shouldShowFullLog && !isFetchingUsages
      ? {
          type: "showUsage",
          usages: usages || [],
        }
      : null;

  const showRequestLogs: ShowRequestLogs | null =
    shouldShowFullLog && !isFetchingRequestLogs && pageInfo !== undefined
      ? {
          type: "showRequestLogs",
          requestLogs: requestLogs || [],
          totalCount: pageInfo.totalCount,
        }
      : null;

  const teamDetailUsageState: TeamDetailUsageState = showUsage ||
    showRequestLogs || { type: "loading" };

  return { teamDetailUsageState, shouldShowFullLog, setShouldShowFullLog };
}

function _TeamDetailUsage(props: Props) {
  const {
    pageSize,
    currentPage,
    setCurrentPage,
    dateRange,
    onDateRangeChange,
    searchDateRange,
    startFromRef,
    tokenDropdownOptions,
    onTokenDropdownChange,
    tokenDropdownSelectedKey,
  } = props;

  const { teamDetailUsageState, shouldShowFullLog, setShouldShowFullLog } =
    useTeamDetailUsageState(props);

  const onNavigateToPage = React.useCallback(
    (pageNum: number) => {
      setCurrentPage(pageNum);
    },
    [setCurrentPage]
  );

  const minDate = React.useMemo(
    () =>
      DateTime.now()
        .minus({ days: AppConfig.usageDateRangeSelectorMinDate || 365 })
        .toJSDate(),
    []
  );
  const toggleFullLog = React.useCallback(
    (_e: React.MouseEvent, checked?: boolean) => {
      setShouldShowFullLog(!!checked);
      setPreference(PreferenceKey.shouldShowFullLog, JSON.stringify(!!checked));
    },
    [setShouldShowFullLog]
  );

  const { localized } = useLocale();
  return (
    <>
      <div className={styles["team-usage"]}>
        <div className={styles["date-range-selector-with-toggle"]}>
          <UsageDateRangeSelector
            onDateRangeChange={onDateRangeChange}
            onDateRangeSearch={searchDateRange}
            dateFrom={dateRange.start}
            dateTo={dateRange.end}
            minDate={minDate}
            tokenDropdownOptions={tokenDropdownOptions}
            onTokenDropdownChange={onTokenDropdownChange}
            tokenDropdownSelectedKey={tokenDropdownSelectedKey}
          />
          <div className={styles["toggle-container"]}>
            <Toggle
              label={localized("team.detail.toggle.full_log")}
              onText={localized("team.detail.toggle.on")}
              offText={localized("team.detail.toggle.off")}
              checked={shouldShowFullLog}
              onChange={toggleFullLog}
              inlineLabel
            />
          </div>
        </div>

        {teamDetailUsageState.type === "loading" && <ShortSpinner />}
        {teamDetailUsageState.type === "showUsage" && (
          <UsageView usages={teamDetailUsageState.usages} />
        )}
        {teamDetailUsageState.type === "showRequestLogs" && (
          <RequestLogsView
            requestLogs={teamDetailUsageState.requestLogs}
            startFrom={startFromRef.current}
            currentPage={currentPage}
            pageSize={pageSize}
            onNavigateToPage={onNavigateToPage}
            totalCount={teamDetailUsageState.totalCount}
          />
        )}
      </div>
    </>
  );
}

export const TeamDetailUsage = React.memo(_TeamDetailUsage);
export default TeamDetailUsage;
