import {
  ActionButton,
  Checkbox,
  ChoiceGroup,
  IChoiceGroupOption,
  IconButton,
  ScrollablePane,
  TextField,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import React, { useCallback, useMemo } from "react";

import { useLocale } from "../../contexts/locale";
import {
  ErrorType,
  useMerchantSettings,
} from "../../contexts/merchantSettings";
import { useTeamPermission } from "../../hooks/permission";
import {
  MerchantSettingInfo,
  MerchantSettingInfoPresetType,
} from "../../types/merchantSetting";
import styles from "./styles.module.scss";

interface MerchantInfoRowProps {
  info: MerchantSettingInfo;
  position: number;
}

const presetFieldPlaceholderMappingTable = {
  [MerchantSettingInfoPresetType.BrandName]:
    "merchant_settings_modal.field.brand_name_placeholder",
  [MerchantSettingInfoPresetType.PhoneNumber]:
    "merchant_settings_modal.field.phone_number_placeholder",
  [MerchantSettingInfoPresetType.FaxNumber]:
    "merchant_settings_modal.field.fax_number_placeholder",
  [MerchantSettingInfoPresetType.Url]:
    "merchant_settings_modal.field.url_placeholder",
} as any;

function MerchantInfoRow(props: MerchantInfoRowProps) {
  const { info, position } = props;
  const { localized } = useLocale();

  const {
    setSelectedMerchantInfo,
    removeSelectedMerchantInfo,
    getPresetFieldTranslateId,
  } = useMerchantSettings();

  const setPattern = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedMerchantInfo(position, {
        pattern: value,
      });
    },
    [position, setSelectedMerchantInfo]
  );

  const setExactMatchOnly = useCallback(
    (
      event?: React.FormEvent<HTMLInputElement | HTMLElement>,
      checked?: boolean
    ) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (checked === undefined) {
        return;
      }
      setSelectedMerchantInfo(position, {
        isExactMatchOnly: checked,
      });
    },
    [position, setSelectedMerchantInfo]
  );

  const setType = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedMerchantInfo(position, {
        type: value,
      });
    },
    [position, setSelectedMerchantInfo]
  );

  const removeInfo = useCallback(() => {
    removeSelectedMerchantInfo(position);
  }, [position, removeSelectedMerchantInfo]);

  const showExactMatchCheckbox =
    (info.isPreset &&
      ([MerchantSettingInfoPresetType.BrandName] as string[]).includes(
        info.type
      )) ||
    !info.isPreset;

  const patternPlaceholder = info.isPreset
    ? localized(presetFieldPlaceholderMappingTable[info.type])
    : undefined;

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["row"]}>
      <div>
        {info.isPreset ? (
          <div>
            <FormattedMessage id={getPresetFieldTranslateId(info.type)} />
          </div>
        ) : (
          <div>
            <TextField
              className={styles["type-field"]}
              value={info.type}
              onChange={setType}
            />
          </div>
        )}
      </div>
      <div>
        <TextField
          className={styles["pattern-field"]}
          value={info.pattern}
          onChange={setPattern}
          placeholder={patternPlaceholder}
          disabled={!hasPermissionToEditResource}
        />
      </div>
      <div>
        {showExactMatchCheckbox && (
          <Checkbox
            checked={info.isExactMatchOnly}
            onChange={setExactMatchOnly}
            disabled={!hasPermissionToEditResource}
          />
        )}
      </div>
      {!info.isPreset && (
        <div>
          <IconButton iconProps={{ iconName: "Delete" }} onClick={removeInfo} />
        </div>
      )}
    </div>
  );
}

export default function MerchantForm() {
  const merchantSettings = useMerchantSettings();
  const {
    selectedMerchant,
    setSelectedMerchantName,
    setSelectedMerchantMatchMode,
    appendSelectedMerchantInfo,
    merchantErrors,
    merchanFormNameTextFieldRef,
    focusMerchantNameList,
  } = merchantSettings;

  const { localized } = useLocale();

  const matchModeOptions = useMemo(
    () => [
      {
        key: "best",
        text: localized("merchant_settings_modal.form.match_one"),
      },
      { key: "all", text: localized("merchant_settings_modal.form.match_all") },
    ],
    [localized]
  );

  const onNameChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (value !== undefined) {
        setSelectedMerchantName(value);
      }
    },
    [setSelectedMerchantName]
  );

  const onMatchModeChanged = React.useCallback(
    (
      _?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      if (!option) return;
      setSelectedMerchantMatchMode(option.key as "best" | "all");
    },
    [setSelectedMerchantMatchMode]
  );

  const { nameFieldError, matchError } = useMemo(() => {
    if (
      selectedMerchant === undefined ||
      merchantErrors === undefined ||
      !merchantErrors.hasOwnProperty(selectedMerchant.id)
    ) {
      return {};
    }

    const nameFieldError = merchantErrors[selectedMerchant.id].includes(
      ErrorType.MerchantNameMissing
    )
      ? localized("merchant_settings_modal.error.merchant_missing")
      : undefined;

    const matchError = merchantErrors[selectedMerchant.id].includes(
      ErrorType.FillInAtLeast1Info
    )
      ? "merchant_settings_modal.error.fill_at_least_one_info"
      : undefined;

    return {
      nameFieldError,
      matchError,
    };
  }, [selectedMerchant, merchantErrors, localized]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter") {
        event.stopPropagation();
        event.preventDefault();
        focusMerchantNameList();
      }
    },
    [focusMerchantNameList]
  );

  const { hasPermissionToEditResource } = useTeamPermission();

  return (
    <div className={styles["container"]} onKeyDown={onKeyDown}>
      {selectedMerchant && (
        <div className={styles["content"]}>
          <div className={styles["name-field-row"]}>
            <div className={classnames(styles["field"], styles["name-field"])}>
              <FormattedMessage id="merchant_settings_modal.form.merchant_id" />
            </div>
            <TextField
              className={styles["name-text-field"]}
              value={selectedMerchant.name}
              onChange={onNameChange}
              errorMessage={nameFieldError}
              componentRef={merchanFormNameTextFieldRef}
              disabled={!hasPermissionToEditResource}
            />
          </div>

          <div className={classnames(styles["field"], styles["tips"])}>
            <FormattedMessage id="merchant_settings_modal.form.tips" />
          </div>

          <ChoiceGroup
            className={styles["match-mode-choice-group"]}
            options={matchModeOptions}
            selectedKey={selectedMerchant.matchMode}
            onChange={onMatchModeChanged}
            disabled={!hasPermissionToEditResource}
          />

          {matchError && (
            <div className={styles["error"]}>
              <FormattedMessage id={matchError} />
            </div>
          )}

          <div className={styles["divider1"]} />

          <div className={styles["header-bar"]}>
            <div
              className={classnames(styles["header"], styles["type-header"])}
            >
              <FormattedMessage id="merchant_settings_modal.form.type_header" />
            </div>

            <div
              className={classnames(styles["header"], styles["info-header"])}
            >
              <FormattedMessage id="merchant_settings_modal.form.info_header" />
            </div>
            <div
              className={classnames(
                styles["header"],
                styles["exact-match-header"]
              )}
            >
              <FormattedMessage id="merchant_settings_modal.form.exact_match" />
            </div>
            {hasPermissionToEditResource && (
              <div
                className={classnames(
                  styles["header"],
                  styles["action-header"]
                )}
              >
                <FormattedMessage id="merchant_settings_modal.form.action" />
              </div>
            )}
          </div>
          <div className={styles["divider2"]} />

          <div className={styles["list-container"]}>
            <ScrollablePane className={styles["list"]}>
              {selectedMerchant.infos.map((info, index) => (
                <MerchantInfoRow
                  info={info}
                  key={`info_${index}`}
                  position={index}
                />
              ))}
              {hasPermissionToEditResource && (
                <div className={styles["sticky"]}>
                  <ActionButton
                    className={styles["add-new-info-button"]}
                    onClick={appendSelectedMerchantInfo}
                  >
                    {localized("merchant_settings_modal.add_new_information")}
                  </ActionButton>
                </div>
              )}
            </ScrollablePane>
          </div>
        </div>
      )}
    </div>
  );
}
