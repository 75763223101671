import { createReducer } from "@reduxjs/toolkit";

import * as appAction from "../actions/app";
import { Plan } from "../types/plan";

export interface AppState {
  readonly isInitialized: boolean;
  readonly shouldShowPaymentRequiredToast: boolean;
  readonly isLeftBarCollapsed: boolean;
  readonly isLeftBarCollapsedSmallScreen: boolean;
  readonly isUpgradeBarDismissed: boolean;
  readonly plans: Plan[];
  readonly shouldIgnoreInvitationInOnboarding: boolean;
  readonly invitationCode?: string;
}

export const defaultState = {
  isInitialized: false,
  shouldShowPaymentRequiredToast: true,
  isLeftBarCollapsed: false,
  isLeftBarCollapsedSmallScreen: true,
  isUpgradeBarDismissed: false,
  plans: [],
  isTeam: false,
  shouldIgnoreInvitationInOnboarding: false,
  invitationCode: undefined,
};

export const appReducer = createReducer<AppState>(defaultState, builder => {
  builder
    .addCase(appAction.Initialized, state => {
      state.isInitialized = true;
    })
    .addCase(appAction.DisablePaymentRequiredToast, state => {
      state.shouldShowPaymentRequiredToast = false;
    })
    .addCase(appAction.ToggleLeftBarCollapsed, (state, action) => {
      state.isLeftBarCollapsed =
        action.payload === undefined
          ? !state.isLeftBarCollapsed
          : action.payload;
    })
    .addCase(appAction.ToggleLeftBarCollapsedSmallScreen, (state, action) => {
      state.isLeftBarCollapsedSmallScreen =
        action.payload === undefined
          ? !state.isLeftBarCollapsedSmallScreen
          : action.payload;
    })
    .addCase(appAction.UpgradeBarDismissed, state => {
      state.isUpgradeBarDismissed = true;
    })
    .addCase(appAction.GotPlanList, (state, action) => {
      state.plans = action.payload.plans;
    })
    .addCase(appAction.GotInvitationCode, (state, action) => {
      state.invitationCode = action.payload;
    })
    .addCase(appAction.InvitationsIgnoredInOnboarding, state => {
      state.shouldIgnoreInvitationInOnboarding = true;
    });
});
