import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";

import { apiClient } from "../apiClient";
import { useAppDispatch } from "../hooks/redux";
import { type OAuthCredentialList } from "../types/oauth";

export const OAuthCredentialDeleted = createAction(
  "oauth/credentialDeleted",
  (id: string) => ({ payload: { id } })
);

export const OAuthGotCredentialList = createAction<OAuthCredentialList>(
  "oauth/gotCredentialList"
);

export type UserAction =
  | ReturnType<typeof OAuthCredentialDeleted>
  | ReturnType<typeof OAuthGotCredentialList>;

export interface OAuthError {
  error: string;
  errorDescription: string | null;
}

export interface OAuthCallback {
  error: string | null;
  errorDescription: string | null;
  nonce: string | null;
}

export function useOAuthActionCreator() {
  const dispatch = useAppDispatch();

  const deleteOAuthCredential = useCallback(
    async (id: string) => {
      await apiClient.deleteOAuthCredential(id);
      dispatch(OAuthCredentialDeleted(id));
    },
    [dispatch]
  );

  const listOAuthCredentials = useCallback(
    async (resourceServer: string) => {
      const oauthCredentials = await apiClient.listOAuthCredentials(
        resourceServer
      );
      dispatch(OAuthGotCredentialList(oauthCredentials));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      listOAuthCredentials,
      deleteOAuthCredential,
    }),
    [deleteOAuthCredential, listOAuthCredentials]
  );
}
