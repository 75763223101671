import { Icon, IconButton } from "@fluentui/react";
import cn from "classnames";
import React, { useState } from "react";

import styles from "./ConfigurationCardLayout.module.scss";

interface Props {
  containerClassName?: string;
  contentClassName?: string;
  title: React.ReactNode;
  children: React.ReactNode;
  onRemoveClick: () => void;
}

function ConfigurationCardLayoutImpl(props: Props) {
  const {
    containerClassName,
    contentClassName,
    title,
    children,
    onRemoveClick,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={cn(styles["container"], containerClassName)}>
      <div className={styles["card-header"]}>
        <div
          className={styles["card-header-left"]}
          role="button"
          onClick={toggleCollapse}
        >
          <span
            className={cn(styles["card-arrow"], {
              [styles["card-arrow-expanded"]]: !isCollapsed,
            })}
          >
            <Icon iconName="ChevronDown" />
          </span>
          <h3 className={styles["card-title"]}>{title}</h3>
        </div>
        <div className={styles["card-header-right"]}>
          <IconButton
            className={styles["card-delete-button"]}
            iconProps={{
              iconName: "Trash",
            }}
            size={38}
            onClick={onRemoveClick}
          />
        </div>
      </div>
      {!isCollapsed && (
        <div className={cn(styles["card-content"], contentClassName)}>
          {children}
        </div>
      )}
    </div>
  );
}

export const ConfigurationCardLayout = React.memo(ConfigurationCardLayoutImpl);
