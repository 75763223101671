@import "../../styles/variables.scss";

.container {
  display: block;

  .table {
    display: grid;
    gap: 1px;
    background-color: #d3d3d3;
    border: 1px solid #d3d3d3;

    .cell {
      padding: 6px 8px;
      background: #fff;
      color: black;
      font-size: 10px;
      line-height: 20px;
    }

    .header.cell {
      background: #edebe9;
      font-weight: 600;
    }

    .header.cell.highlighted {
      background: #d9f4d7;
    }

    .item.cell {
      background: #fff;
      font-weight: 400;
    }
  }
}
