import { MessageBar, MessageBarType } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";
import * as React from "react";
import {
  DefaultToastContainer as RTNToastContainer,
  ToastContainerProps as RTNToastContainerProps,
  ToastProvider as RTNToastProvider,
  ToastProps,
  ToastProviderProps,
  useToasts,
} from "react-toast-notifications";

import {
  ToastStyle,
  ToastStyleProvider,
  useToastStyle,
} from "../../contexts/toastStyle";
import { LocalizerValue } from "../../models";
import { toast } from "../../utils/toast";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

const ToastAdapter: React.FC<unknown> = () => {
  const { addToast, removeAllToasts } = useToasts();

  toast.add = addToast;
  toast.dismiss = removeAllToasts;

  return null;
};

const AppearanceTypesToMessageBarType: {
  [key: string]: MessageBarType;
} = {
  info: MessageBarType.info,
  success: MessageBarType.success,
  warning: MessageBarType.warning,
  error: MessageBarType.error,
};

const MessageBarToast: React.FC<ToastProps> = (props: ToastProps) => {
  const { appearance, children, autoDismiss, onDismiss, transitionState } =
    props;

  const onToastDismiss = React.useCallback(
    (_ev?: React.MouseEvent<unknown>) => {
      onDismiss();
    },
    [onDismiss]
  );

  return (
    <div className={styles["toast"]}>
      <MessageBar
        className={classnames({
          [styles["fade-in"]]: transitionState === "entering",
          [styles["fade-out"]]: transitionState === "exiting",
        })}
        messageBarType={AppearanceTypesToMessageBarType[appearance]}
        isMultiline={true}
        onDismiss={autoDismiss ? undefined : onToastDismiss}
      >
        {children}
      </MessageBar>
    </div>
  );
};

export interface ActionToastProps {
  messageId: string;
  detail?: LocalizerValue;
  actionId: string;
  onClick: () => void;
}
export function ActionToastContent(props: ActionToastProps) {
  const { messageId, detail, actionId, onClick } = props;
  return (
    <div className={styles["action-content"]}>
      <FormattedMessage id={messageId} values={detail} />
      <DefaultButton textId={actionId} onClick={onClick} />
    </div>
  );
}

const ToastContainer: React.FC<RTNToastContainerProps> = (
  props: RTNToastContainerProps
) => {
  const { toastStyle } = useToastStyle();

  return (
    <div
      className={classnames({
        [styles["login-toast-container"]]: toastStyle === ToastStyle.Login,
        [styles["signup-toast-container"]]: toastStyle === ToastStyle.Signup,
        [styles["toast-container"]]: toastStyle === ToastStyle.Default,
      })}
    >
      <RTNToastContainer {...props} />
    </div>
  );
};

export const ToastProvider: React.FC<ToastProviderProps> = React.memo(
  (props: ToastProviderProps) => (
    <ToastStyleProvider>
      <RTNToastProvider
        {...props}
        components={{
          Toast: MessageBarToast,
          ToastContainer,
        }}
        transitionDuration={3000}
      >
        <ToastAdapter />
        {props.children}
      </RTNToastProvider>
    </ToastStyleProvider>
  )
);

// Set toast style and reset to default when unmount
export function ToastStyleUpdater(props: { toastStyle: ToastStyle }) {
  const { toastStyle } = props;
  const { setToastStyle } = useToastStyle();

  React.useEffect(() => {
    setToastStyle(toastStyle);
    return () => {
      setToastStyle(ToastStyle.Default);
    };
  }, [toastStyle, setToastStyle]);
  return <></>;
}
