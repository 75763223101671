@import "../../styles/mixin.scss";
@import "../../styles/variables.scss";

.detection-region-insepctor {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 24px 10px 8px;

  .add_extraction_field {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4px;

    .add_extraction_field_label {
      margin-right: 4px;
      font-size: 16px;
    }

    i {
      font-size: 14px;
    }
  }

  .sub-content {
    width: 100%;
    padding-right: 10px;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    margin-top: 8px;
    margin-bottom: 15px;
  }

  .delete-button {
    font-size: 11px;
    margin-left: 20px;

    :global(.ms-Icon) {
      color: #464646;
    }
  }

  .delete-button:hover {
    font-size: 11px;
    :global(.ms-Icon) {
      color: #2ec293;
    }
  }

  .delete-button:active {
    font-size: 11px;
    :global(.ms-Icon) {
      color: #464646;
    }
  }
}
