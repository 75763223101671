import { AppConfig, RegionsConfig } from "./config";
import {
  EXTRACTION_MAXIMUM_POLLING_TIME,
  EXTRACTION_POLLING_INTERVAL,
} from "./constants";
import {
  WORKER_CLIENT_RATE_LIMIT_BACKOFF_MS,
  WORKER_CLIENT_RATE_LIMIT_RETRY_COUNT,
} from "./constants/workerClient";
import errors, { FOCRError, WorkerError } from "./errors";
import {
  DetectExtractedContentSchemaReport,
  DetectMultiDocumentReport,
  DetectPIIReport,
  ExtractAPIV2Response,
  ExtractAPIV2SuccessResponse,
  OCRTestReportMultipleDocument,
  OCRTestReportSingleDocument,
} from "./models";
import { FormExtractionMode } from "./types/form";
import { getWorkerRequestError } from "./utils/errors";

export interface WorkerResponseSuccess {
  status: "ok";
}

export type WorkerResponseFailure = {
  status: "failed";
  error: WorkerResponseError;
};

export type WorkerResponseError = {
  code: number;
  message: string;
};

export type WorkerResponse = WorkerResponseSuccess | WorkerResponseFailure;

interface OCRTestResponseSingleDocument
  extends WorkerResponseSuccess,
    OCRTestReportSingleDocument {}

interface OCRTestResponseMultipleDocument
  extends WorkerResponseSuccess,
    OCRTestReportMultipleDocument {}

type OCRTestResponse =
  | OCRTestResponseSingleDocument
  | OCRTestResponseMultipleDocument;

interface OCRTestJobCreatedResponse extends WorkerResponseSuccess {
  job_id: string;
}

export interface DetectMultiDocumentResponse
  extends WorkerResponseSuccess,
    DetectMultiDocumentReport {}

export interface DetectPIIResponse
  extends WorkerResponseSuccess,
    DetectPIIReport {}

export interface DetectExtractedContentSchemaResponse
  extends WorkerResponseSuccess,
    DetectExtractedContentSchemaReport {}

export interface V2AsyncExtractionResponse extends WorkerResponseSuccess {
  job_id: string;
  request_id: string;
}

export interface ExtractV2Options {
  shouldOutputLLMPrompt?: boolean;
  shouldOutputOrientation?: boolean;
  shouldOutputOcr?: boolean;
  origin?: string;
}

export class WorkerClient {
  endpoint: string;
  accessToken: string;

  static accessTokenHeader = "X-WORKER-TOKEN";

  constructor(
    accessToken: string,
    endpoint: string = RegionsConfig.endpoints[AppConfig.region].worker
  ) {
    this.accessToken = accessToken;
    this.endpoint = endpoint;
  }

  prefixEndpoint(endpoint: string): string {
    return new URL(endpoint, this.endpoint).href;
  }

  async fetch(resource: RequestInfo, init?: RequestInit): Promise<Response> {
    const request = new Request(resource, init);
    request.headers.set(WorkerClient.accessTokenHeader, this.accessToken);
    return fetch(request);
  }

  async syncCvatProjectForCustomModel(customModelId: string): Promise<void> {
    const response = (await (
      await this.fetch(
        this.prefixEndpoint("/sync-cvat-project-for-custom-model"),
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            custom_model_id: customModelId,
          }),
        }
      )
    ).json()) as WorkerResponse;

    if (response.status !== "ok") {
      throw response.error;
    }
  }

  async extractFieldInTestMode(
    entityId: string,
    image: File,
    extractionMode: FormExtractionMode,
    startsRecognizing: () => void,
    useAsync: boolean = true,
    options?: {
      shouldOutputOcr?: boolean | "preserve-whitespace";
    }
  ): Promise<OCRTestResponse> {
    const formData = new FormData();
    const shouldUseAsync = useAsync && !AppConfig.worker.shouldAsyncDisabled;
    formData.append("form_id", entityId);
    formData.append("image", image);
    formData.append("test_mode", "json");
    formData.append("show_confidence", "true");
    formData.append("async", shouldUseAsync ? "true" : "false");
    if (options?.shouldOutputOcr != null && options.shouldOutputOcr !== false) {
      formData.append(
        "output_ocr",
        options.shouldOutputOcr === true ? "true" : options.shouldOutputOcr
      );
    }

    switch (extractionMode) {
      case FormExtractionMode.multiPagePdf:
        formData.append("multi_page_pdf", "true");
        break;
      case FormExtractionMode.singlePageMultiDocument:
        formData.append("detect_multi_document", "true");
        break;
      case FormExtractionMode.combineMultiPagePdf:
        formData.append("experimental_concat_pdf", "true");
        break;
      case FormExtractionMode.multiPagePdfMultiDocument:
        formData.append("multi_page_pdf", "true");
        formData.append("detect_multi_document", "true");
        break;
    }

    try {
      const response = (await (
        await this.fetch(this.prefixEndpoint("/extract"), {
          method: "POST",
          body: formData,
        })
      ).json()) as WorkerResponse;

      if (response.status === "failed") {
        throw getWorkerRequestError(response.error);
      }

      if (!shouldUseAsync) {
        startsRecognizing();
        return response as OCRTestResponse;
      }

      const jobId = (response as OCRTestJobCreatedResponse).job_id;

      startsRecognizing();

      let timeElapsed = 0;

      while (true) {
        const response = await this.fetch(
          this.prefixEndpoint(`/extract/jobs/${jobId}`)
        );
        if (response.status !== 201) {
          const result = await response.json();
          if (result.status === "failed") {
            throw getWorkerRequestError(result.error);
          }

          return result as OCRTestResponse;
        }
        if (timeElapsed > EXTRACTION_MAXIMUM_POLLING_TIME) {
          throw errors.ExtractionTimeout;
        }
        await new Promise(r => setTimeout(r, EXTRACTION_POLLING_INTERVAL));
        timeElapsed += EXTRACTION_POLLING_INTERVAL;
      }
    } catch (e) {
      if (e instanceof FOCRError) {
        throw e;
      }
      throw getWorkerRequestError(e);
    }
  }

  async detectMultiDocument(image: File): Promise<DetectMultiDocumentResponse> {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("should_output_debug_image", "yes");

    const response = (await (
      await this.fetch(this.prefixEndpoint("/detect-documents"), {
        method: "POST",
        body: formData,
      })
    ).json()) as WorkerResponse;

    if (response.status === "failed") {
      throw getWorkerRequestError(response.error);
    }

    return response as DetectMultiDocumentResponse;
  }

  async detectPII(image: File): Promise<DetectPIIResponse> {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("mode", "redact");
    formData.append("should_output_debug_image", "yes");

    const response = (await (
      await this.fetch(this.prefixEndpoint("/detect-pii"), {
        method: "POST",
        body: formData,
      })
    ).json()) as WorkerResponse;

    if (response.status === "failed") {
      throw getWorkerRequestError(response.error);
    }

    return response as DetectPIIResponse;
  }

  async detectExtractedContentSchema(
    image: File
  ): Promise<DetectExtractedContentSchemaResponse> {
    const formData = new FormData();
    formData.append("image", image);

    const response = (await (
      await this.fetch(
        this.prefixEndpoint("/detect-extracted-content-schema"),
        {
          method: "POST",
          body: formData,
        }
      )
    ).json()) as WorkerResponse;

    if (response.status === "failed") {
      throw getWorkerRequestError(response.error);
    }

    return response as DetectExtractedContentSchemaResponse;
  }

  async extractForWorkspace(
    workspaceId: string,
    file: File,
    abortSignal?: AbortSignal,
    options?: ExtractV2Options,
    rateLimitRetryCount: number = WORKER_CLIENT_RATE_LIMIT_RETRY_COUNT
  ): Promise<V2AsyncExtractionResponse> {
    const formData = new FormData();
    formData.append("workspace_id", workspaceId);
    formData.append("workspace_file_name", file.name);
    formData.append("image", file);

    if (options?.shouldOutputOrientation) {
      formData.append("output_orientation", "true");
    }

    if (options?.origin) {
      formData.append("origin", options.origin);
    }

    try {
      const response = (await (
        await this.fetch(this.prefixEndpoint("/v2/workspace"), {
          method: "POST",
          body: formData,
          signal: abortSignal,
        })
      ).json()) as WorkerResponse;

      if (response.status === "failed") {
        if (
          response.error.code === WorkerError.TooManyRequest &&
          rateLimitRetryCount > 0
        ) {
          await new Promise((res, _rej) => {
            setTimeout(res, WORKER_CLIENT_RATE_LIMIT_BACKOFF_MS);
          });
          return this.extractForWorkspace(
            workspaceId,
            file,
            abortSignal,
            options,
            rateLimitRetryCount - 1
          );
        }
        throw getWorkerRequestError(response.error);
      }

      return response as V2AsyncExtractionResponse;
    } catch (e) {
      if (e instanceof FOCRError) {
        throw e;
      }
      throw getWorkerRequestError(e);
    }
  }

  async reExtractForWorkspace(
    workspaceId: string,
    extractionResultIds: string[] | null, // re-extract all if null
    isFailedOnly: boolean = true, // re-extract both success and fail if false
    rateLimitRetryCount: number = WORKER_CLIENT_RATE_LIMIT_RETRY_COUNT
  ): Promise<WorkerResponseSuccess> {
    try {
      const response = (await (
        await this.fetch(this.prefixEndpoint("/re-extract"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            workspace_id: workspaceId,
            ...(extractionResultIds != null
              ? {
                  extraction_result_ids: extractionResultIds,
                }
              : {}),
            is_failed_only: isFailedOnly,
          }),
        })
      ).json()) as WorkerResponse;

      if (response.status === "failed") {
        if (
          response.error.code === WorkerError.TooManyRequest &&
          rateLimitRetryCount > 0
        ) {
          await new Promise((res, _rej) => {
            setTimeout(res, WORKER_CLIENT_RATE_LIMIT_BACKOFF_MS);
          });
          return this.reExtractForWorkspace(
            workspaceId,
            extractionResultIds,
            isFailedOnly,
            rateLimitRetryCount - 1
          );
        }
        throw getWorkerRequestError(response.error);
      }

      return response as WorkerResponseSuccess;
    } catch (e) {
      if (e instanceof FOCRError) {
        throw e;
      }
      throw getWorkerRequestError(e);
    }
  }

  async extractV2(
    extractorId: string,
    image: File,
    options: ExtractV2Options,
    startsRecognizing: () => void,
    useAsync: boolean = true
  ): Promise<ExtractAPIV2Response> {
    const formData = new FormData();
    const shouldUseAsync = useAsync && !AppConfig.worker.shouldAsyncDisabled;

    formData.append("extractor_id", extractorId);
    formData.append("image", image);
    formData.append("test_mode", "json");
    formData.append("async", shouldUseAsync ? "true" : "false");

    if (options.shouldOutputLLMPrompt) {
      formData.append("output_llm_prompt", "true");
    }

    if (options.shouldOutputOrientation) {
      formData.append("output_orientation", "true");
    }

    if (options.shouldOutputOcr) {
      formData.append("output_ocr", "true");
    }

    if (options?.origin) {
      formData.append("origin", options.origin);
    }

    try {
      const response = (await (
        await this.fetch(this.prefixEndpoint("/v2/extract"), {
          method: "POST",
          body: formData,
        })
      ).json()) as WorkerResponse;

      if (response.status === "failed") {
        throw getWorkerRequestError(response.error);
      }

      if (!shouldUseAsync) {
        startsRecognizing();
        return response as ExtractAPIV2SuccessResponse;
      }

      const jobId = (response as V2AsyncExtractionResponse).job_id;

      startsRecognizing();

      let timeElapsed = 0;

      while (true) {
        const response = await this.fetch(
          this.prefixEndpoint(`/v2/extract/jobs/${jobId}`)
        );
        if (response.status !== 201) {
          const result = await response.json();
          if (result.status === "failed") {
            throw getWorkerRequestError(result.error);
          }

          return result as ExtractAPIV2SuccessResponse;
        }
        if (timeElapsed > EXTRACTION_MAXIMUM_POLLING_TIME) {
          throw errors.ExtractionTimeout;
        }
        await new Promise(r => setTimeout(r, EXTRACTION_POLLING_INTERVAL));
        timeElapsed += EXTRACTION_POLLING_INTERVAL;
      }
    } catch (e) {
      if (e instanceof FOCRError) {
        throw e;
      }
      throw getWorkerRequestError(e);
    }
  }
}

export function workerClient(accessToken: string): WorkerClient {
  return new WorkerClient(accessToken);
}
