import { v4 as uuidv4 } from "uuid";

import * as constants from "../constants";
import { deepClone } from "../utils/deepClone";
import { BaseInteractionHandler } from "./handler";

export class FieldToolHandler extends BaseInteractionHandler {
  handleMouseDown = (e: MouseEvent) => {
    if (!this.store) return;

    const cursorCoord = this.getCoord(e);
    this.store.setCreatingField({
      id: uuidv4(),
      label: constants.DEFAULT_FIELD_LABEL,
      languages: [...constants.DEFAULT_FIELD_LANGUAGES],
      ocr_config: deepClone(constants.DEFAULT_FIELD_OCR_CONFIG),
      bbox: {
        left: cursorCoord.x,
        top: cursorCoord.y,
        right: cursorCoord.x + constants.MIN_FIELD_SIZE,
        bottom: cursorCoord.y + constants.MIN_FIELD_SIZE,
      },
    });
  };

  handleMouseMove = (e: MouseEvent) => {
    if (!this.store) return;

    const cursorCoord = this.getCoord(e);
    const { creatingField } = this.store;

    if (creatingField) {
      this.store.setCreatingField({
        ...creatingField,
        bbox: {
          ...creatingField.bbox,
          right: Math.max(
            creatingField.bbox.left + constants.MIN_FIELD_SIZE,
            cursorCoord.x
          ),
          bottom: Math.max(
            creatingField.bbox.top + constants.MIN_FIELD_SIZE,
            cursorCoord.y
          ),
        },
      });
    }
  };

  handleMouseUp = (_e: MouseEvent) => {
    if (!this.store) return;
    if (this.store.creatingField) {
      this.store.upsertField(this.store.creatingField);
      this.store.setCreatingField(undefined);
    }
  };

  cursorStyle = () => {
    return "crosshair";
  };
}
