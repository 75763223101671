import { AppConfig } from "./config";
import { Palette } from "./types/palette";

const defaultTheme: Palette = {
  themePrimary: "#25d0b1",
  themeLighterAlt: "#f5fdfc",
  themeLighter: "#d7f8f2",
  themeLight: "#b6f1e6",
  themeTertiary: "#74e3cf",
  themeSecondary: "#3cd7ba",
  themeDarkAlt: "#22bca0",
  themeDark: "#1d9f87",
  themeDarker: "#157563",
  neutralLighterAlt: "#faf9f8",
  neutralLighter: "#f3f2f1",
  neutralLight: "#edebe9",
  neutralQuaternaryAlt: "#e1dfdd",
  neutralQuaternary: "#d0d0d0",
  neutralTertiaryAlt: "#c8c6c4",
  neutralTertiary: "#b4b2b0",
  neutralSecondary: "#9b9997",
  neutralPrimaryAlt: "#83817e",
  neutralPrimary: "#201f1e",
  neutralDark: "#52504e",
  black: "#3a3836",
  white: "#ffffff",
};

const theme = {
  palette: {
    ...defaultTheme,
    ...(AppConfig.theme || {}),
  },
};

export default theme;
