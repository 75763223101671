@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
  border: 1px solid $separator-color-2;
  border-radius: 4px;
  padding: 16px;
  background-color: $white;
}

.card-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.card-header-left {
  display: flex;
  flex: 1 0 0%;
  align-items: center;
}

.card-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 0;
  margin-left: 10px;
}

.card-arrow {
  padding: 10px;
  font-size: 18px;
  line-height: 18px;
  transition: transform 0.3s ease;

  &.card-arrow-expanded {
    transform: rotate(-90deg);
  }
}

.card-delete-button {
  width: 38px;
  height: 38px;
  font-size: 18px;
  line-height: 18px;

  &,
  &:hover,
  &:active {
    color: #323130;
  }
}

.card-content {
  margin-top: 16px;
}
