import { Label } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";

import { FOCRError } from "../../errors";
import { BriefCustomModel } from "../../types/customModel";
import { PageInfoWithOffset } from "../../types/pageInfo";
import { getPageByOffset } from "../../utils/pagination";
import Paginator from "../Paginator";
import ShortSpinner from "../ShortSpinner";
import { Table } from "../Table";
import styles from "./styles.module.scss";

function getIsEmpty(
  isFetching: boolean,
  pageInfo?: PageInfoWithOffset
): boolean {
  return !isFetching && (pageInfo?.totalCount ?? 0) === 0;
}

interface EmptyStateUIProps {
  messageId?: string;
}

function EmptyStateUI(props: EmptyStateUIProps) {
  return (
    <div className={styles["empty-state-container"]}>
      <div className={styles["empty-state"]}>
        <div>
          <Label className={styles["empty-state-text"]}>
            <FormattedMessage
              id={
                props.messageId ?? "team.detail.resources.custom_models.empty"
              }
            />
          </Label>
        </div>
      </div>
    </div>
  );
}

interface CustomModelTableRowProps {
  model: BriefCustomModel;
  onItemClick: (model: BriefCustomModel) => void;
}

function CustomModelTableRowItem(props: CustomModelTableRowProps) {
  const { model, onItemClick } = props;

  const onClick = useCallback(() => {
    onItemClick(model);
  }, [onItemClick, model]);

  return (
    <tr onClick={onClick} className={styles["resource-table-row"]}>
      <td>{model.name}</td>
      <td className={styles["resource-table-row-info"]}>
        {model.noOfSampleImages}
      </td>
      <td className={styles["resource-table-row-info"]}>{model.id}</td>
      <td className={styles["resource-table-row-info"]}>
        {model.createdAt != null &&
          `${model.createdAt.toLocaleDateString()} ${model.createdAt.toLocaleTimeString()}`}
      </td>
      <td className={styles["resource-table-row-info"]}>
        <FormattedMessage
          id={`team.detail.resources.custom_model.status.${model.status}`}
        />
      </td>
    </tr>
  );
}

interface CustomModelTableProps {
  offset: number;
  customModels?: BriefCustomModel[];
  isCustomModelsFetching: boolean;
  customModelsPageInfo?: PageInfoWithOffset;
  pageSize: number;
  onNavigateToPage: (pageNumber: number) => void;
  onItemClick: (model: BriefCustomModel) => void;
  error?: FOCRError;
}

function _CustomModelTable(props: CustomModelTableProps) {
  const {
    offset,
    customModels,
    isCustomModelsFetching,
    customModelsPageInfo,
    pageSize,
    onNavigateToPage,
    onItemClick,
    error,
  } = props;

  const currentPage = React.useMemo(() => {
    return getPageByOffset(offset, pageSize);
  }, [offset, pageSize]);

  const isEmpty = React.useMemo(() => {
    return getIsEmpty(isCustomModelsFetching, customModelsPageInfo);
  }, [isCustomModelsFetching, customModelsPageInfo]);

  if (isCustomModelsFetching) {
    return <ShortSpinner />;
  }

  if (customModels && !isEmpty) {
    return (
      <>
        <Table
          columnIds={[
            "team.detail.resources.name",
            "team.detail.resources.no_of_picture_uploaded",
            "team.detail.resources.model_id",
            "team.detail.resources.created_at",
            "team.detail.resources.status",
          ]}
          className={styles["team-table"]}
        >
          {customModels.map(customModel => (
            <CustomModelTableRowItem
              key={customModel.id}
              model={customModel}
              onItemClick={onItemClick}
            />
          ))}
        </Table>
        <Paginator
          currentPage={currentPage}
          totalCount={customModelsPageInfo?.totalCount ?? 0}
          pageSize={pageSize}
          navigateToPage={onNavigateToPage}
        />
      </>
    );
  }

  return <EmptyStateUI messageId={error?.messageId} />;
}

const CustomModelTable = React.memo(_CustomModelTable);

export default CustomModelTable;
