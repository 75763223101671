import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import copy from "copy-to-clipboard";
import React from "react";

import { useToast } from "../../hooks/toast";
import styles from "./styles.module.scss";

interface CopyButtonProps {
  text: string;
  titleId?: string;
  showText?: boolean;
  className?: string;
}

export function useCopyButton(args: CopyButtonProps) {
  const { className, text, titleId, showText } = args;
  const toast = useToast();

  const onCopy = React.useCallback(() => {
    copy(text);
    toast.success("common.copied_to_clipboard");
  }, [text, toast]);

  return React.useMemo(
    () => ({
      className,
      text,
      titleId,
      showText,
      onCopy,
    }),
    [className, onCopy, showText, text, titleId]
  );
}

export function CopyButtonImpl(props: ReturnType<typeof useCopyButton>) {
  return (
    <div className={cn(styles["copy"], props.className)} onClick={props.onCopy}>
      <Icon iconName="IconCopy2" />
      <span>
        {props.showText && (
          <FormattedMessage id={props.titleId ?? "common.copy"} />
        )}
      </span>
    </div>
  );
}

export function CopyButton(args: CopyButtonProps) {
  const props = useCopyButton(args);
  return <CopyButtonImpl {...props} />;
}
