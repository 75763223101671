.fsl-active-model-picker {
  width: 284px;
  padding: 16px 16px;
}

.fsl-active-model-picker-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #323130;
}

.fsl-active-model-picker-desc {
  color: #605e5c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 4px;
}

.fsl-active-model-picker-choice-group {
  margin-top: 12px;
}

.fsl-active-model-picker-model-version {
  margin-top: 12px;
}

.fsl-active-model-picker-model-button {
  margin-top: 20px;

  :global(.ms-Button) {
    width: 100%;
  }
}
