@import "../../styles/variables.scss";

.container {
  font-size: 14px;
  display: flex;

  .main {
    padding: 9px 2px 9px 8px;
    width: calc(min(564px, 100vw - 124px));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: $neutral-lighter-alt;

    .method {
      color: $theme-dark;
      margin-right: 8px;
    }

    .url {
      color: $neutral-primary-alt;
    }
  }

  .main > * {
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono,
      DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  }

  .copy-wrapper {
    background-color: $neutral-lighter-alt;
    padding: 4px 8px;

    .copy {
      padding: 5px 6px 6px 2px;
      font-size: 12px;
      i {
        margin-right: 2px;
      }
    }
  }
}
