import * as React from "react";

import {
  GotExtractors,
  ListExtractorsFailed,
  ListingExtractors,
} from "../actions/extractor";
import { apiClient } from "../apiClient";
import { EXTRACTOR_PAGE_SIZE } from "../constants/layout";
import { ensureFOCRError } from "../utils/errors";
import {
  BackpressureMode,
  isAbortError,
  useAbortable,
  useBackpressure,
} from "./asyncguard/asyncguard";
import { useAppDispatch, useAppSelector } from "./redux";

type ExtractorListParms = {
  resourceOwnerId: string;
  page: number;
  filter: string[];
  search?: string;
  size?: number;
};

const INTERVAL_TIME = 300; /// 300ms

export function useExtractorListResource() {
  const dispatch = useAppDispatch();

  const { abort } = useAbortable();

  const abortableQuery = React.useCallback(
    async (params: ExtractorListParms) => {
      const { newSignal } = abort();
      // The new rule of using async resource does not allow to
      // call the query funciton triggered by the property change.
      // Therefore, the same argument check is not necessary and
      // it should be the duty of caller to prevent it if needed.

      const { resourceOwnerId, page, filter, search } = params;
      const size = params.size ?? EXTRACTOR_PAGE_SIZE;

      return await apiClient.listExtractor(
        size,
        (page - 1) * size,
        resourceOwnerId,
        filter,
        search,
        newSignal
      );
    },
    [abort]
  );

  const debouncedQuery = useBackpressure(
    abortableQuery,
    BackpressureMode.throttleLatest(INTERVAL_TIME)
  );

  const query = React.useCallback(
    async (params: ExtractorListParms) => {
      const { page, filter, search } = params;

      dispatch(ListingExtractors(page - 1, filter.join(","), search));

      try {
        const result = await debouncedQuery(params);
        dispatch(GotExtractors({ page: page - 1, result }));
      } catch (e) {
        if (isAbortError(e)) {
          return;
        }
        dispatch(
          ListExtractorsFailed({ page: page - 1, error: ensureFOCRError(e) })
        );
      }
    },
    [debouncedQuery, dispatch]
  );

  const resource = useAppSelector(
    state => state.extractor.paginatedExtractorList
  );

  return React.useMemo(
    () => ({
      query,
      resource,
    }),
    [query, resource]
  );
}
