@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 400px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
  @include no-select();

  .icon-and-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    > i {
      font-size: 40px;
      margin-right: 12px;
    }

    > label {
      font-weight: 500;
      font-size: 14pt;
    }
  }
}
